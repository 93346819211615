<template>
  <div class="icon-class" @click="clickEvent">
    <svg class="icon" aria-hidden="true">
      <use :xlink:href="iconName" />
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    iconClass: {
      type: String,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {
    iconName() {
      return `#${this.iconClass}`
    }
  },
  created() {},
  mounted() {},
  methods: {
    clickEvent() {
      this.$emit('click')
    }
  }
}
</script>
<style lang="less" scoped>
.icon-class {
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
