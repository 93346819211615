<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="100%"
    class="apply-demo-dialog"
    :append-to-body="true"
    :modal-append-to-body="true"
    @close="closeDialog"
  >
    <div class="remind-wrap">
      <a
        href="https://mp.weixin.qq.com/s/POc4c-oDyIUD9MSzVmHiWw"
        target="_blank"
        @click="closeDialog"
      >
        <img src="../../../../../../assets/imgs/header/doubleEleven.png" alt />
      </a>
    </div>
  </el-dialog>
</template>
<script>
export default {
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  props: {
  },
  data() {
    return {
      dialogVisible: true
    }
  },
  computed: {},
  watch: {
    dialogShow: {
      handler(val) {
        this.dialogVisible = true
      }
    }
  },
  created() {
  },
  methods: {
    closeDialog() {
      this.$emit('change', false)
      this.$emit('close', false)
      this.dialogVisible = false
    }
  }
}
</script>
<style lang="less" scoped>
.apply-demo-dialog {
  justify-content: center !important;
  z-index: 9999999 !important;
  background: rgba(0, 0, 0, 0.5);
}
.remind-wrap {
  margin: 0px auto;
  text-align: center;
  img {
    max-width: 1100px;
    width: 50%;
  }
}
::v-deep .el-dialog__body {
  padding: 0px 32px 32px 32px;
}
::v-deep .el-dialog__header {
  // display: none;
  padding: 16px 0px 0px 0px;
}
::v-deep .el-dialog__headerbtn {
  top: 20px;
  .el-dialog__close {
    color: #fff;
    border: 1px solid #fff;
    border-radius: 17px;
    &:hover {
      color: #3a78f6;
      border-radius: 17px;
    }
  }
}
::v-deep .el-dialog {
  border-radius: 24px !important;
  background: none;
  box-shadow: none;
}
</style>
