<template>
  <div class="kpi-verhicle-steps">
    <div
      v-for="(item, index) in steps"
      :key="item.id"
      :class="Number(activeStep) < item.id ? '' : 'active-step'"
      class="step-item"
    >
      <div class="step-item-title">
        {{ item.name }}
      </div>
      <div
        class="step-line"
        :class="Number(activeStep) === item.id ? 'active-step-line' : ''"
      >
        <div
          class="step-split-line"
          :class="index === 0 ? 'no-show-line' : ''"
        ></div>
        <div class="step-circle" @click="clickStep(item)"></div>
        <div
          class="step-split-line"
          :class="index === steps.length - 1 ? 'no-show-line' : ''"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  model: {
    prop: 'activeStep',
    event: 'change'
  },
  props: {
    steps: {
      type: Array,
      default: () => {
        return [{ id: 0 }, { id: 1 }, { id: 2 }]
      }
    },
    activeStep: {
      type: String,
      default: '2'
    }
  },
  data() {
    return {
      clickMenu: this.activeStep
    }
  },
  watch: {
    activeStep: {
      handler(val) {
        this.clickMenu = val
      }
    }
  },
  methods: {
    clickStep(obj) {
      if (this.activeStep > obj.id) {
        this.$emit('change', obj.id)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.kpi-verhicle-steps {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .step-item {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #a0a3bd;
    .step-item-title {
      width: 16px;
      word-break: break-word;
      line-height: 18px;
    }
    .step-line {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 22px;
      .step-circle {
        cursor: not-allowed;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #d9dbe9;
        box-shadow: 0px 2px 4px 0px rgba(0, 53, 183, 0.1);
      }
      .step-split-line {
        flex: 1;
        width: 1px;
        background: #f7f7fc;
      }
      .no-show-line {
        flex: 1;
        width: 0;
      }
    }
  }
  .active-step {
    color: #005eff;
    .step-line {
      .step-circle {
        // cursor: pointer;
        width: 15px;
        height: 15px;
        background: #005eff;
        border: 3px solid #ffffff;
      }
      .step-split-line {
        background: #005eff;
      }
    }
    .active-step-line {
      .step-split-line:last-of-type {
        background: #f7f7fc;
      }
    }
  }
}
</style>
