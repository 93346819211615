export default [
  {
  path: '/pm',
  name: 'pm',
  component: () => {
    return import(/* webpackChunkName: "pmList" */ '../../views/pm/page/pmList')
  }
},
{
  path: '/pm/pmDetail',
  name: 'pmDetail',
  component: () => {
    return import(
      /* webpackChunkName: "pmDetail" */
      '../../views/pm/page/pmDetail'
    )
  }
},
{
  path: '/pm/analyse-detail',
  name: '/pm/analyseDetail',
  component: () => {
    return import(
      /* webpackChunkName: "pmAnalyseDetail" */
      '../../views/analyse/details'
    )
  }
}
]
