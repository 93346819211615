<template>
  <div class="global-box-wrap">
    <div class="at-container">
      <at
        v-if="!atDisable"
        ref="at"
        :members="atUserList"
        name-key="name"
        suffix
        :atAll="atAll"
        :cycleId="`${quarterId || $store.state.common.cycle.quarterId || ''}`"
        :searchName="name"
        @input="atContentChange"
        @at="getMemeber"
        @nameInput="nameInput"
        @insert="insertUser"
        @loadMoreList="loadMoreListData"
        @membersRefresh="membersRefresh"
        @closePanelRefresh="closePanelRefresh"
        @departAdd="departAdd"
      >
        <!-- 自定义样式-at组件的slot,不要动 -->
        <template #embeddedItem="s">
          <span>
            <span
              class="embedded-item at-name"
              :data-id="s.current && s.current.userId"
              :data-name="s.current && s.current.name"
            >
              @
              <wx-open-data
                :id="s.current && s.current.name"
                :nameData="{name:s.current && s.current.name}"
              />&nbsp;
            </span>
          </span>
        </template>
        <!-- at组件写入内容的位置 -->
        <el-popover
          id="at-popover"
          ref="selectedPopover"
          v-model="isShowPopover"
          placement="top"
          width="200"
        >
          <div class="colors-div">
            <Icon
              icon-class="icon-lan"
              class="text-color-lan"
              @click="setparttextcolor('#dfe9fe')"
            />
            <Icon
              icon-class="icon-hong"
              class="text-color-hong"
              @click="setparttextcolor('#fcdeea')"
            />
            <Icon
              icon-class="icon-huang"
              class="text-color-huang"
              @click="setparttextcolor('#ffefd6')"
            />
            <Icon icon-class="icon-qingchu" class="text-color-clear" @click="cleartextcolor()" />
          </div>
        </el-popover>

        <div
          v-if="isBgColor"
          id="at-content"
          ref="at-content"
          style="-webkit-user-select: text"
          class="at-content"
          contenteditable
          @input="atContentChange"
          @keydown.enter="enterEvent"
          @keyup="enterCtrlA"
          @click.stop="focus"
          @blur="blurEvent"
          @mouseup="handleselecttext"
          v-html="contentHtml"
          @compositionstart="handleCompositionStart"
          @compositionend="handleCompositionEnd"
        ></div>
        <div
          v-else
          id="at-content"
          ref="at-content"
          class="at-content"
          contenteditable
          @input="atContentChange"
          @keydown.enter="enterEvent"
          @click.stop="focus"
          @blur="blurEvent"
          v-html="contentHtml"
          @compositionstart="handleCompositionStart"
          @compositionend="handleCompositionEnd"
          @keydown="keydownContent"
          @keyup="keyupContent"
        ></div>
        <!-- 模拟placeholder -->
        <span
          v-if="!focusState"
          ref="placeholder"
          class="placeholder"
          aria-readonly="true"
          contenteditable="false"
          @click.stop="focus($event)"
        >{{ placeholder }}</span>
        <!-- 文字长度 -->
        <!-- <div class="text-length" @click.stop="focus($event)">
          {{ contentLength }}/{{ contentMaxLen }}
        </div>-->
      </at>
      <!-- @paste="pasteEvent" -->
      <div v-else class="atwho-wrap">
        <div
          id="at-content"
          ref="at-content"
          class="at-content"
          contenteditable
          @input="atContentChange"
          @blur="blurEvent"
          @click.stop="focus"
          @keydown.enter="enterEvent"
          @compositionstart="handleCompositionStart"
          @compositionend="handleCompositionEnd"
          v-html="contentHtml"
          @keydown="keydownContent"
          @keyup="keyupContent"
        ></div>
        <!-- 模拟placeholder -->
        <span
          v-if="!focusState"
          ref="placeholder"
          class="placeholder"
          aria-readonly="true"
          contenteditable="false"
          @click.stop="focus($event)"
        >{{ placeholder }}</span>
      </div>
    </div>
    <!-- 按组织选择 -->
    <depart-picker
      v-if="departUserVisible"
      v-model="departUserVisible"
      :canDelete="true"
      :type="multipleChoice=== false ? 'person': 'departPerson'"
      fromType="at"
      :multipleChoice="multipleChoice"
      :limit="50"
      @saveResult="chargeUserPicker"
      @cancel="departUserCancel"
      @change="departVisibleChange"
    />
  </div>
</template>
<script>
import at from './vue-at/At.vue'
export default {
  components: { at },
  model: {
    prop: 'html',
    event: 'change'
  },
  props: {
    source: {
      type: String,
      default: ''
    },
    isBgColor: {
      type: Boolean,
      default: false
    },
    // 传入默认文字
    placeholder: {
      type: String,
      default: ''
    },
    // 输入框内默认内容
    html: {
      type: String,
      default: ''
    },
    // 内容最大长度
    limit: {
      type: Number,
      default: 150
    },
    members: {
      type: Array,
      default: () => []
    },
    // 周期id
    quarterId: {
      type: String,
      default: ''
    },
    // 查询全部成员
    atAll: {
      type: Boolean,
      default: false
    },
    enterAble: {
      // 是否回车直接发送
      type: Boolean,
      required: false,
      default: false
    },
    atDisable: {
      // @功能是否禁用
      type: Boolean,
      required: false,
      default: false
    },
    needAdvice: {
      // 判断是否低质量
      type: Boolean,
      default: false
    },
    autoFocus: {
      default: false
    },
    lineFeed: { // 是否能换行
      default: false
    },
    multipleChoice: {
      default: true
    }
  },
  data() {
    return {
      focusState: false,
      contentLength: 0, // 实际输入内容长度
      contentDom: null, // 内容dom
      atDom: null, // at组件dom
      atUserList: [], // at成员列表
      contentHtml: this.html, // 输入框值
      initAtUserList: [], // 初始成员列表

      copytitle: '',
      isShowPopover: false,
      arr: [],
      selectedtext: '', // 选中内容
      selectedhtml: '', // 选中内容html格式
      ignoreClick: false, // 忽略OKR点击
      hasComposition: false, // 判断中文是否输入完成
      keyDownKey: '',
      pageNum: 1,
      pageSize: 10,
      total: 0,
      getDataState: true,
      name: '',
      departUserVisible: false,
      businessScope: 1
    }
  },
  created() {
    this.contentHtml = this.html
    // 隐藏placeholder
    if (this.html !== '') {
      this.focusState = true
    }
    // 如果传进来成员那就不需要请求了
    if (this.members.length === 0) {
      if (!this.atDisable) {
        // if (this.atAll) {
        // // 可@全部成员
        //   this.getAllMembers(1)
        // } else {
        //   this.getMemeberByCycleId(1)
        // }
        this.atAll = true
        this.getAllMembers(1)
      }
    } else {
      this.atUserList = this.members
      this.initAtUserList = this.members
    }
  },
  mounted() {
    // 初始化一次dom
    this.contentDom = this.$refs['at-content']
    if (this.autoFocus) {
      this.cursorInPosition()
    }
    // this.cursorInPosition()
    this.atDom = this.$refs['at']
    // 监听：只要点击了非at组件输入区域，关闭成员列表
    document.addEventListener('click', this.closeMembersPannel)
    // 监听粘贴事件，只要内容，不要html以及样式
    this.contentDom.addEventListener('paste', this.pasteEvent)
    // 监听拖拽释放事件
    this.contentDom.addEventListener('drop', this.dragDropEvent)
    // 监听拖拽进入区域
    this.contentDom.addEventListener('dragenter', this.dragEnter)
    // 监听拖拽移出区域
    this.contentDom.addEventListener('dragleave', this.dragLeave)
    this.bus(this).$on('ignoreClick', () => {
      this.ignoreClick = true
    })
  },
  destroyed() {
    // 释放监听
    document.removeEventListener('click', this.closeMembersPannel)
    // 释放粘贴事件监听
    this.contentDom.removeEventListener('paste', this.pasteEvent)
    // 释放拖拽事件监听
    this.contentDom.removeEventListener('drop', this.dragDropEvent)
    // 释放拖拽进入事件监听
    this.contentDom.removeEventListener('dragenter', this.dragEnter)
    // 释放拖拽离开事件监听
    this.contentDom.removeEventListener('dragleave', this.dragLeave)
  },

  methods: {
    departVisibleChange(state) {
      this.$emit('departVisibleChange', state || false)
    },
    closePanelRefresh(members) {
      this.pageNum = 2
      this.atUserList = members
      this.initAtUserList = members
      this.getDataState = true
    },
    keydownContent(e) {
      if (e.key === 'Shift' || e.key === 'Control') {
        this.keyDownKey = 'Shift'
      } else if (this.keyDownKey !== 'Shift') {
        this.keyDownKey = ''
      }
    },
    keyupContent(e) {
      if (e.key === 'Shift' || e.key === 'Control') {
        this.keyDownKey = ''
      }
    },
    // 选中
    handleselecttext(e) {
      const selObj = window.getSelection().getRangeAt(0)
      const docFragment = window.getSelection().getRangeAt(0).cloneContents()
      var testDiv = document.createElement('div')
      testDiv.appendChild(docFragment)
      this.selectedhtml = testDiv.innerHTML
      this.selectedtext = testDiv.innerText

      if (this.selectedhtml !== '') {
        if (
          this.selectedtext.indexOf('@') >= 0 ||
          selObj.commonAncestorContainer.parentNode.className ===
            'embedded-item at-name'
        ) {
          if (this.$refs.selectedPopover) {
            this.$refs.selectedPopover.doClose()
          }
          this.selectedhtml = ''
          this.selectedtext = ''
        } else {
          this.$refs.selectedPopover.doShow()
        }
      } else {
        if (this.$refs.selectedPopover) {
          this.$refs.selectedPopover.doClose()
        }
        this.selectedhtml = ''
        this.selectedtext = ''
      }
    },
    // 设置颜色
    setparttextcolor(color) {
      let title = this.$refs['at-content'].innerHTML
      if (this.selectedtext.indexOf('@') >= 0) {
        const reg =
          /(.*?)<span data-at-embedded="" contenteditable="false"> <span class="embedded-item at-name" data-id="(\d+)">(@.*?)<\/span> <\/span>(.*?) ?/g
        const html = this.selectedhtml.replace(reg, '$1')
        const html2 = this.selectedhtml.replace(reg, '$4')
        let html1 = html.replace(html2, '')
        html1 = html1.replace('&nbsp;', '')
        const text = this.$utils.tagFilter(this.selectedhtml.replace(reg, '$1'))

        const text2 = this.$utils.tagFilter(
          this.selectedhtml.replace(reg, '$4')
        )
        const text1 = text.replace(text2, '')
        const text1Arr = text1 ? text1.split('') : []
        const text2Arr = text2 ? text2.split('') : []
        html1 = html1.replace('&nbsp;', '')

        if (text1Arr.length > 0) {
          text1Arr.forEach((item, index) => {
            if (index === text1Arr.length - 1) {
              text1Arr[index] =
                '<span class="at-text-bg" data-type="1" style="background:' +
                color +
                ' !important;">' +
                item +
                '</span>&nbsp;'
            } else {
              text1Arr[index] =
                '<span class="at-text-bg" data-type="1" style="background:' +
                color +
                ' !important;">' +
                item +
                '</span>'
            }
          })
          title = title.replace(html1, text1Arr.join(''))
        }
        if (text2Arr.length > 0) {
          text2Arr.forEach((item, index) => {
            if (index === text2Arr.length - 1) {
              text2Arr[index] =
                '<span class="at-text-bg" data-type="1" style="background:' +
                color +
                ' !important;">' +
                item +
                '</span>&nbsp;'
            } else {
              text2Arr[index] =
                '<span class="at-text-bg" data-type="1" style="background:' +
                color +
                ' !important;">' +
                item +
                '</span>'
            }
          })
          title = title.replace(html2, text2Arr.join(''))
        }
      } else {
        this.arr = this.selectedtext.split('')
        if (this.arr.length > 0) {
          this.arr.forEach((item, index) => {
            if (item) {
              if (index === this.arr.length - 1) {
                this.arr[index] =
                  '<span class="at-text-bg" data-type="1" style="background:' +
                  color +
                  ' !important;">' +
                  item +
                  '</span>'
              } else {
                this.arr[index] =
                  '<span class="at-text-bg" data-type="1" style="background:' +
                  color +
                  ' !important;">' +
                  item +
                  '</span>'
              }
            }
          })
        }
        title = title.replace(this.selectedhtml, this.arr.join(''))
      }
      this.contentHtml = title
      this.blurEvent()
      this.cursorInPosition()
      if (this.$refs.selectedPopover) {
        this.$refs.selectedPopover.doClose()
      }
      this.atContentChange()
      this.selectedtext = ''
      this.selectedhtml = ''
    },
    // cleartextcolor() {
    //   let title = this.$refs['at-content'].innerHTML
    //   if (this.selectedtext.indexOf('@') >= 0) {
    //     '@', this.selectedhtml
    //     const reg =
    //       /(.*?)<span data-at-embedded="" contenteditable="false"> <span class="embedded-item at-name" data-id="(\d+)">(@.*?)<\/span> <\/span>(.*?) ?/g
    //     let html1 = this.selectedhtml.replace(reg, '$1')
    //     html1 = html1.replace('&nbsp;', '')
    //     const text1 = this.$utils.tagFilter(
    //       this.selectedhtml.replace(reg, '$1')
    //     )
    //     const text2 = this.$utils.tagFilter(
    //       this.selectedhtml.replace(reg, '$4')
    //     )
    //     const at =
    //       '<span data-at-embedded="" contenteditable="false"> <span class="embedded-item at-name" data-id="$2">$3</span> </span>'
    //     title = title.replace(reg, text1 + at + text2)
    //     console.log(12121)
    //   } else {
    //     title = title.replace(this.selectedhtml, this.selectedtext)
    //   }
    //   this.contentHtml = title
    //   this.blurEvent()
    //   this.cursorInPosition()
    //   this.$refs.selectedPopover.doClose()
    //   this.atContentChange()
    // },
    // 清除
    cleartextcolor() {
      let title = this.$refs['at-content'].innerHTML
      if (this.selectedtext.indexOf('@') >= 0) {
        '@', this.selectedhtml
        const reg =
          /(.*?)<span data-at-embedded="" contenteditable="false"> <span class="embedded-item at-name" data-id="(\d+)">(@.*?)<\/span> <\/span>(.*?) ?/g
        const html = this.selectedhtml.replace(reg, '$1')
        const html2 = this.selectedhtml.replace(reg, '$4')
        let html1 = html.replace(html2, '')
        html1 = html1.replace('&nbsp;', '')
        const text = this.$utils.tagFilter(this.selectedhtml.replace(reg, '$1'))

        const text2 = this.$utils.tagFilter(
          this.selectedhtml.replace(reg, '$4')
        )
        const text1 = text.replace(text2, '')
        const text1Arr = text1 ? text1.split('') : []
        const text2Arr = text2 ? text2.split('') : []

        if (text1Arr.length > 0) {
          text1Arr.forEach((item, index) => {
            if (index === text1Arr.length - 1) {
              text1Arr[index] = item
            } else {
              text1Arr[index] = item
            }
          })
          console.log(text1Arr.join(''))
          title = title.replace(html1, text1Arr.join(''))
          console.log('title', title)
        }
        if (text2Arr.length > 0) {
          text2Arr.forEach((item, index) => {
            if (index === text2Arr.length - 1) {
              text2Arr[index] = item
            } else {
              text2Arr[index] = item
            }
          })
          title = title.replace(html2, text1Arr.join(''))
        }

        console.log(121221, title)
      } else {
        this.arr = this.selectedtext.split('')
        if (this.arr.length > 0) {
          this.arr.forEach((item, index) => {
            this.arr[index] = item
          })
        }
        console.log(this.arr.join(''), '拆分--清楚')
        title = title.replace(this.selectedhtml, this.arr.join(''))
      }
      this.contentHtml = title
      this.blurEvent()
      this.cursorInPosition()
      if (this.$refs.selectedPopover) {
        this.$refs.selectedPopover.doClose()
      }
      this.atContentChange()
      this.selectedhtml = ''
      this.selectedtext = ''
    },
    closeMembersPannel(e) {
      if (
        e && e.target.className && e.target.className !== 'atwho-view' &&
        e.target.className !== 'atwho-li atwho-cur'
      ) {
        this.atDom = this.$refs['at']
        if (this.atDom) {
          this.atDom.closePanel()
        }
      }
    },
    dragEnter() {
      if (!this.focusState) {
        this.focusState = true
      }
      this.contentDom.style.backgroundColor = '#C0C0C0'
    },
    dragLeave() {
      this.contentDom.style.backgroundColor = 'white'
      this.emptyShowPlaceholder()
    },
    dragDropEvent(e) {
      this.contentDom.style.backgroundColor = 'white'
      this.emptyShowPlaceholder()
      // 阻止默认事件
      e.preventDefault()
      e.stopPropagation()
      const df = e.dataTransfer
      // 存放文件
      let dragFiles = {}
      // 获取拖拽的文件
      if (df.items !== undefined) {
        const item = df.items[0]
        // 不允许目录上传
        if (item.kind === 'file' && item.webkitGetAsEntry().isFile) {
          const file = item.getAsFile()
          dragFiles = file
        }
      } else {
        dragFiles = df.files[0]
      }
      try {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(dragFiles.slice(0, 3))
        fileReader.addEventListener('load', (e) => {
          // 上传
          const formData = new FormData()
          formData.append('file', dragFiles)
          console.log('formData就是上传内容', formData)
          this.$emit('fileUpload', formData)
        })
        fileReader.addEventListener(
          'error',
          (r) => {
            // 信息提示记得修改
            console.log('不能上传文件夹')
          },
          false
        )
      } catch {
        // 信息提示记得修改
        console.log('不能上传文件夹')
        return
      }
    },
    async pasteEvent(e) {
      // 绑定粘贴事件e
      console.log('绑定粘贴事件e', e)
      if (e.clipboardData) {
        var dragFiles = e.clipboardData.files[0]
        var clipboardData = e.clipboardData
        // 只要文本
        var text = clipboardData.getData('text')
        // 阻止默认行为
        e.preventDefault()
        // 限制字数
        const type = await this.limitTextCount()
        if (!type) return
        if (window.getSelection && text !== '' && text !== null) {
          // 创建文本节点
          var textNode = document.createTextNode(text)
          // 在当前的光标处插入文本节点
          var range = window.getSelection().getRangeAt(0)
          // 先删除文本
          range.deleteContents()
          // 在插入文本
          range.insertNode(textNode)
          // 隐藏placeholder
          if (text !== '') {
            this.focusState = true
          }
          // 复制完成后再校验一次字符长度
          await this.limitTextCount()
          this.cursorInPosition()
          this.$emit('input', this.queryAtIds())
        }
        try {
          const fileReader = new FileReader()
          fileReader.readAsDataURL(dragFiles.slice(0, 3))
          fileReader.addEventListener('load', (e) => {
            // 上传
            const formData = new FormData()
            formData.append('file', dragFiles)
            console.log('formData就是上传内容', formData)
            this.$emit('fileUpload', formData)
          })
          fileReader.addEventListener(
            'error',
            (r) => {
              // 信息提示记得修改
              console.log('不能上传文件夹')
            },
            false
          )
        } catch {
          // 信息提示记得修改
          console.log('不能上传文件夹')
          return
        }
        this.cursorInPosition()
      }
    },
    enterCtrlA(e) {
      const selObj = window.getSelection().getRangeAt(0)
      if (this.$utils.tagFilter(e.target.innerText) == '') {
        this.focusState = false
        this.clearComment()
        if (this.$refs.selectedPopover) {
          this.$refs.selectedPopover.doClose()
        }
      } else {
        if (e.keyCode == 91) {
          if (
            this.selectedtext.indexOf('@') >= 0 ||
            selObj.commonAncestorContainer.parentNode.className ==
              'embedded-item at-name'
          ) {
            if (this.$refs.selectedPopover) {
              this.$refs.selectedPopover.doClose()
            }
          } else {
            this.$refs.selectedPopover.doShow()
            this.selectedhtml = e.target.innerHTML
            this.selectedtext = e.target.innerText
          }
        }
      }
    },
    handleCompositionStart() {
      this.hasComposition = true
    },
    handleCompositionEnd() {
      setTimeout(() => {
        this.hasComposition = false
      }, 100)
    },
    // 回车事件
    enterEvent(e, state) {
      const atContainer = document.getElementsByClassName('atwho-panel')
      if (
        !this.hasComposition &&
        this.enterAble &&
        (!atContainer || atContainer.length === 0) && (this.lineFeed && this.keyDownKey !== 'Shift' || !this.lineFeed) || state
      ) {
        this.contentDom.blur()
        // 浏览器阻止默认事件兼容
        if (e && e.preventDefault) {
          e.preventDefault()
        } else {
          window.event.value = false
        }
        this.$emit('enter', this.queryAtIds())
      }
    },
    // 失去焦点事件
    blurEvent() {
      const atContainer = document.getElementsByClassName('atwho-panel')
      if (
        ((!atContainer && !this.isShowPopover) ||
          (atContainer.length === 0 && !this.isShowPopover)) &&
        !this.ignoreClick
      ) {
        setTimeout(() => {
          if (this.ignoreClick) {
            this.ignoreClick = false
            this.cursorInPosition()
          } else {
            this.$emit('blur', this.queryAtIds())
          }
        }, 300)
      }
    },
    loadMoreListData(name, type, openState) {
      console.log('loadMoreListData')
      if (type === 1) {
        if (this.name !== name || openState === 'open') {
          this.atUserList = []
          this.initAtUserList = []
          this.pageNum = 1
          this.name = name
          if (this.atAll) {
          // 可@全部成员
            this.getAllMembers(1, true)
          } else {
            this.getMemeberByCycleId(1, true)
          }
        }
      } else {
        this.loadMoreList()
      }
    },
    loadMoreList() {
      if (parseInt(this.atUserList.length) < parseInt(this.total) && this.getDataState) {
        this.getDataState = false
        if (this.atAll) {
        // 可@全部成员
          this.getAllMembers('', true)
        } else {
          this.getMemeberByCycleId('', true)
        }
      }
    },
    membersRefresh(params, members, total) {
      this.total = total
      this.pageNum = 2
      this.name = params.name || params.userName
      this.atUserList = members
      this.initAtUserList = members
      this.getDataState = true
      this.businessScope = 0
      // this.$refs.at.setAtwhoList()
    },
    // 根据周期id获取成员列表
    getMemeberByCycleId(type, nameSearch) {
      if (type === 1) {
        this.atUserList = []
        this.initAtUserList = []
        this.pageNum = 1
      }
      const cycleId =
        this.quarterId || this.$store.state.common.cycle.quarterId || ''
      this.$apis
        .CYCLEATMEMBER(cycleId, {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          userName: this.name,
          cycleInfoId: cycleId
        })
        .then((rest) => {
          const list = rest.data.rows
          for (let i = 0; i < list.length; i++) {
            list[i].name = list[i].username?list[i].username:list[i].userName
            list[i].saasname = list[i].userName
            list[i].avatar = this.$isQw?list[i].avatar:list[i].userAvatar
            list[i].userId = list[i].id?list[i].id:list[i].userId
          }
          this.atUserList = this.atUserList.concat(list)
          this.initAtUserList = this.initAtUserList.concat(list)
          this.total = rest.data.total
          this.pageNum = this.pageNum + 1
          this.getDataState = true
          if (nameSearch && list.length === 0) {
            // this.$refs.at.closePanel()
          }
          if (nameSearch) {
            this.$nextTick(() => {
              this.$refs.at.setAtwhoList()
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    //
    getAllMembers(type, nameSearch) {
      if (type === 1) {
        this.atUserList = []
        this.initAtUserList = []
        this.pageNum = 1
      }
      const _obj = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        name: this.name,
        searchType: 0,
        businessScope: this.businessScope // 0: 全部人员 1:推荐人员
      }
      this.$apis.QUERYALLMEMBERSINCOMPANYV2('', _obj).then((res) => {
        if (res.status === 200) {
          const list = res.data.rows || []
          list.forEach((item) => {
            item.userId = item.id
            item.saasname = item.name
            if (this.$isQw) {
              item.name = item.username
              item.userName = item.username
            }
          })
          this.atUserList = this.atUserList.concat(list)
          this.initAtUserList = this.initAtUserList.concat(list)
          this.total = res.data.total
          this.pageNum = this.pageNum + 1
          this.getDataState = true
          if (nameSearch && list.length === 0) {
            // this.$refs.at.closePanel()
          }
          if (nameSearch) {
            this.$nextTick(() => {
              this.$refs.at.setAtwhoList()
            })
          }
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    async atContentChange() {
      // 只要输入了，隐藏placeholder
      var regbr = new RegExp('<br>', 'g')

      this.focusState = true

      // 统计文本长度
      const text = this.contentDom.innerHTML.replace(/<.*?>|\s|[&nbsp;]/g, '')

      this.contentLength = text.length
      // 最大150字符
      if (this.contentLength > this.limit) {
        // 超过提示并给提示
        this.$message({
          msg: `输入内容不能超过 ${this.limit} 个字符哦!`,
          type: 'warn'
        })
        this.contentDom.innerHTML = this.html
        this.contentDom.innerHTML = this.contentDom.innerHTML.substring(
          0,
          this.limit
        )
        this.cursorInPosition()
        return false
      }
      await this.limitTextCount()
      if (!this.lineFeed) {
        this.editorText = this.contentDom.innerHTML.replace(regbr, '')
      } else {
        this.editorText = this.contentDom.innerHTML.replace(
          '<div><br></div>',
          '\n'
        )
      }
      this.$emit('input', this.queryAtIds())
      // 如果内容为空，展示placeholder
      this.emptyShowPlaceholder()
    },
    // 限制文本字数
    limitTextCount() {
      const text = this.contentDom.innerHTML.replace(/<.*?>|\s|[&nbsp;]/g, '')
      if (text.length > this.limit) {
        const message_ = `输入内容不能超过` + this.limit + `个字符哦!`
        // this.$message({
        //   msg: message_,
        //   type: 'warning'
        // })
        this.contentDom.innerHTML = this.html.replace('<div><br></div>', '\n')
        this.contentDom.innerHTML = this.contentDom.innerHTML.substring(
          0,
          this.limit
        )
        this.cursorInPosition()
        return false
      } else {
        // this.html = this.contentDom.innerHTML
        this.html = this.contentDom.innerHTML.replace('<div><br></div>', '\n')
        // if (!this.hasComposition && this.lineFeed && this.keyDownKey === 'Shift') { // 解决换行状态下中文无法输入
        //   this.contentDom.innerHTML = this.html
        //   this.cursorInPosition()
        // }
      }
      return true
    },
    // 返回at成员
    getMemeber(member) {
      this.$emit('input', this.queryAtIds())
    },
    // 点击重新focus
    focus(e) {
      // 如果是placeholder或者统计字数dom点进来的，让编辑区域focus
      if (e && e.target.className && e.target.className === 'placeholder') {
        this.contentDom.focus()
        return
      }
      if (e && e.target.className && e.target.className === 'embedded-item at-name') {
        this.cursorInPosition()
        return
      }
      const range = window.getSelection() // 创建range
      this.cursorPosition = range.getRangeAt(0).startOffset
    },
    // 内容为空时展示placeholder
    emptyShowPlaceholder() {
      if (this.contentDom.innerText.length < 1) {
        this.focusState = false
      }
      if (this.$refs.selectedPopover) {
        this.$refs.selectedPopover.doClose()
      }
    },
    // 获取输入框内容及@成员
    queryAtIds() {
      // var html = this.contentDom.innerHTML.replace('<div><br></div>', '<div class="lineFeed">\n</div>')
      var regHtml = /<div(?:(?!).|\n)*?>/gm
      var html = this.contentDom.innerHTML
      html = html.replace(/<\/div>/g, '')
      html = html.replace(regHtml, '\n')
      let reg =
      /<span data-at-embedded="" contenteditable="false"> <span class="embedded-item at-name" data-id="(\d+)"> @ <ww-open-data type="userName" openid="(.*?)"><\/ww-open-data><\/span> <\/span> ?/g
      if (!this.$isQw) {
        reg =
      /<span data-at-embedded="" contenteditable="false"> <span class="embedded-item at-name" data-id="(\d+)">(@.*?)<\/span> <\/span> ?/g
      }

      const embeddedItemsEl = html.match(reg)
      const ids = []
      if (embeddedItemsEl) {
        embeddedItemsEl.forEach((item) => {
          const changeId = '$1'
          const id = item.replace(reg, changeId)
          if (id) {
            ids.push(id)
          }
        })
      }
      this.atIds = Array.from(new Set(ids))
      if (!this.lineFeed) {
        html = html.replace(/(\n|\r|\r\n|↵)/g, '')
      }
      return {
        html,
        atIds: this.atIds
      }
    },
    // 外部添加@成员
    addAtMember(html, type) {
      if (html) {
        this.focusState = true
        if (type === 'depart') {
          this.contentDom.innerHTML = this.contentDom.innerHTML.substring(0, this.contentDom.innerHTML.length - 1)
        }
        this.contentDom.innerHTML += html + ' '
        this.$emit('input', this.queryAtIds())
      }
    },
    // 光标到文字最后
    cursorInPosition() {
      // var range = document.createRange()
      // range.selectNodeContents(this.contentDom)
      // range.collapse(false)
      // var sel = window.getSelection()
      // // 判断光标位置，如不需要可删除
      // if (sel.anchorOffset !== 0) {
      //   return
      // }
      // sel.removeAllRanges()
      // sel.addRange(range)
      // this.contentDom.focus()
      var obj = this.$refs['at-content']
      if (window.getSelection) {
        // ie11 10 9 ff safari
        obj.focus() // 解决ff不获取焦点无法定位问题
        const range = window.getSelection() // 创建range
        range.selectAllChildren(obj) // range 选择obj下所有子内容
        range.collapseToEnd() // 光标移至最后
      } else if (document.selection) {
        // ie10 9 8 7 6 5
        const range = document.selection.createRange() // 创建选择对象
        range.moveToElementText(obj) // range定位到obj
        range.collapse(false) // 光标移至最后
        range.select()
      }
    },
    initHtmlValue(html) {
      this.contentDom.innerHTML = html + ' '
      this.cursorInPosition()
      this.contentDom.focus()
    },
    clearComment() {
      this.$refs['at-content'].innerHTML = ''
      this.contentDom = this.$refs['at-content']
      this.cursorInPosition()
      this.atDom = this.$refs['at']
      this.atContentChange()
    },
    nameInput(name) {
      if (name.length > 0) {
        var list = []
        // 用户数据
        this.initAtUserList.forEach((item, index) => {
          if (/^[\u4e00-\u9fa5]+$/i.test(name)) {
            // 全中文
            if (item.name.indexOf(name) >= 0) {
              list.push(item)
            }
          } else {
            if (
              this.$utils
                .stringToPinyin(item.name)
                .indexOf(this.$utils.stringToPinyin(name)) >= 0
            ) {
              list.push(item)
            }
          }
        })
        this.atUserList = list
        this.$nextTick(() => {
          this.$refs.at.setAtwhoList()
        })
      } else {
        this.atUserList = this.initAtUserList
        this.$nextTick(() => {
          this.$refs.at.setAtwhoList()
        })
      }
    },
    // 遍历所有的at，在标签后添加一个空白的文档节点
    atSpanHandle() {
      console.log(document.getElementById('at-content').childNodes)
      console.dir(document.getElementById('at-content'))
      const atNode = document.getElementById('at-content')
      const childNodes = atNode.childNodes
      childNodes.forEach((node) => {
        console.log(node)
        if (node.contentEditable === 'false' && node.nodeName === 'SPAN') {
          // AT标签
          const t = document.createTextNode('')
          this.insertAfter(t, node)
        }
      })
      console.dir(document.getElementById('at-content'))
    },
    insertAfter(insert_element, target_element) {
      var parent = target_element.parentNode
      // 最后一个子节点 lastElementChild兼容其他浏览器 lastChild  兼容ie678;
      var last_element = parent.lastElementChild || parent.lastChild
      // 兄弟节点同样也是有兼容性
      var target_sibling =
        target_element.nextElementSibling || target_element.nextSibling
      if (last_element === target_element) {
        // 先判断目标节点是不是父级的最后一个节点，如果是的话，直接给父级加子节点就好
        parent.appendChild(insert_element)
      } else {
        // 不是最好后一个节点  那么插入到目标元素的下一个兄弟节点之前（就相当于目标元素的insertafter）
        parent.insertBefore(insert_element, target_sibling)
      }
    },
    insertUser(userItem) {
      this.$emit('insertUser', userItem)
    },
    deleteContent(userItem) {
      var html = this.$isQw?`<span data-at-embedded="" contenteditable="false"> <span data-at-embedded="" contenteditable="false"> <span class="embedded-item at-name" data-id="${userItem.userId}"> @ <ww-open-data type="userName" openid="${userItem.name}"></ww-open-data></span> </span> </span>&nbsp;`:`<span data-at-embedded="" contenteditable="false"> <span class="embedded-item at-name" data-id="${userItem.userId}">@${userItem.name}</span> </span>&nbsp;`
      this.contentHtml = this.$refs['at-content'].innerHTML.replace(html, '')
      this.$refs['at-content'].innerHTML = this.contentHtml
      this.$emit('input', this.queryAtIds())
      this.$nextTick(() => {
        this.cursorInPosition()
      })
      // this.$refs.at.handleDelete('conclusionDelete') // safari 不兼容 使用上面方式删除
    },
    departAdd() {
      this.$refs.at.closePanel()
      this.departUserVisible = true
    },
    chargeUserPicker(list) {
      var inputHtml = ''
      list.forEach((item) => {
        item.id = (item.id || item.userId)
        item.userId = (item.id || item.userId)
        inputHtml += (this.$isQw ? `<span data-at-embedded="" contenteditable="false"> <span data-at-embedded="" contenteditable="false"> <span class="embedded-item at-name" data-id="${item.id || item.userId}"> @ <ww-open-data type="userName" openid="${item.name}"></ww-open-data></span> </span> </span>&nbsp;` : `<span data-at-embedded="" contenteditable="false"> <span class="embedded-item at-name" data-id="${item.id || item.userId}">@${item.name}</span> </span>&nbsp;`)
      })
      this.addAtMember(inputHtml, 'depart')
      if (list.length > 0) {
        this.$emit('departInsertUser', list)
      }
      this.cursorInPosition()
    },
    departUserCancel() {
      // this.$refs.at.openPanel()
      this.chargeUserPicker([])
    }
  }
}
</script>
<style lang="less">
::v-deep {
  span {
    display: inline-block;
    min-width: 1px;
  }
}
.colors-div {
  display: flex;
  flex-direction: row;
  .text-color-lan {
    cursor: pointer;
    font-size: 34px;
    border: 2px #fff solid;
    margin-right: 10px;
    border-radius: 10px;
    &:hover {
      border: 2px #3a78f6 solid;
    }
  }
  .text-color-hong {
    cursor: pointer;
    font-size: 34px;
    border: 2px #fff solid;
    border-radius: 10px;
    margin-right: 10px;
    &:hover {
      border: 2px #ed2e7e solid;
    }
  }
  .text-color-huang {
    cursor: pointer;
    font-size: 34px;
    border: 2px #fff solid;
    border-radius: 10px;
    margin-right: 10px;
    &:hover {
      border: 2px #ff9900 solid;
    }
  }
  .text-color-clear {
    cursor: pointer;
    font-size: 34px;
    color: #a0a3bd;
    &:hover {
      color: #444558;
    }
  }
}
#at-popover {
  position: absolute;
  top: -80px;
  left: 10px;
  z-index: 999999;
}
.global-box-wrap {
  height: 100%;
  .at-container {
    position: relative;
    white-space: pre-wrap;
    height: 100%;
    .atwho-panel {
      height: 200px !important;
      width: 300px !important;
    }
    .atwho-wrap {
      border-radius: 4px;
      height: 100%;
      .at-content {
        overflow-y: auto;
        height: 100%;
        border: none;
        outline: none;
        padding: 8px;
        word-break: break-word;
        .atName::before {
          color: red !important;
        }
      }
      .advice-content {
        text-decoration: underline;
        text-decoration-color: #ff9900;
        -moz-text-decoration-color: #ff9900;
      }
      .placeholder {
        position: absolute;
        top: 8px;
        left: 8px;
        color: #a0a3bd;
        font-size: 14px;
        line-height: 20px;
      }
      .text-length {
        text-align: right;
        position: absolute;
        right: 0;
        bottom: 0px;
        padding-right: 4px;
        color: #cccccc;
        width: calc(10% - 4px);
        font-size: 11px;
        height: 16px;
      }
    }
  }
}
</style>
