// 引入工具包
import utils from '@/utils/index.js'
// 基础模块
import base from './base'
// 公共模块
import common from './common'
// 周期模块
import cycle from './cycle'
// okr模块
import okr from './okr'
// todo模块
import todo from './todo'
// header模块
import header from './header'
// admin 后台管理
import admin from './admin'
// 统计分析模块
import analyse from './analyse'
// 日历
import calendar from './calendar'
// 项目
import pm from './pm'
// 工作台
import workbench from './workbench'
// 会议
import meeting from './meeting'
// 总结
import conclusion from './conclusion'
// 绩效
import kpi from './kpi'
// 训练营
import classroom from './classroom'
// 绩效2
import kpiV2 from './kpiV2'
const funcs = [
  ...base,
  ...common,
  ...cycle,
  ...okr,
  ...todo,
  ...header,
  ...admin,
  ...analyse,
  ...calendar,
  ...pm,
  ...workbench,
  ...meeting,
  ...conclusion,
  ...kpi,
  ...classroom,
  ...kpiV2
]
export default utils.arrToObj(funcs)
