<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="680px"
    :append-to-body="true"
    title
    :show-close="false"
    @close="closeDialog"
  >
    <div class="main-content">
      <Icon icon-class="icon-guanbi2" class="icon-close" @click="closeDialog" />
      <img src="@/assets/imgs/header/schoolImg.png" alt />
      <!-- <button class="blue-button" @click="confirm">了解详情</button>-->
      <img src="@/assets/imgs/header/schoolBtn.png" @click="confirm" alt class="school-btn" />
    </div>
  </el-dialog>
</template>
<script>
export default {
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  props: {},
  data() {
    return {
      dialogVisible: true,
      activityStep: 1
    }
  },
  computed: {},
  watch: {
    dialogShow: {
      handler(val) {
        this.dialogVisible = true
      }
    }
  },
  created() {},
  methods: {
    closeDialog() {
      // const activityLocalStorage = JSON.parse(localStorage.getItem('activityLocalStorage')) || ''
      // activityLocalStorage.visible = false
      // localStorage.setItem('activityLocalStorage', JSON.stringify(activityLocalStorage))
      this.dialogVisible = false
      this.$emit('change', false)
    },
    confirm() {
      this.closeDialog()
      // openSysBrowser('https://doc.okrt.com/4725.html')
      // this.$router.push('/classroom')
      var url = `https://app.okrt.com/helpH5/publicclass`
      if (this.$isDD) {
        url = `https://page.dingtalk.com/wow/dingtalk/act/serviceconversation?ddtab=true&wh_biz=tm&showmenu=false&goodsCode=DT_GOODS_881655784491241&corpId=${this.commonCorpId}&token=199f876c080bd26313560b8515f10454`
      }
      if (this.$isQw || this.$isDD) {
        window.open(url, '_blank')
      } else {
        openSysBrowser(url)
      }
    }
  }
}
</script>
<style scoped lang="less">
::v-deep {
  .el-dialog {
    background: none;
    box-shadow: none;
  }
}
::v-deep .el-dialog__header {
  // padding: 12px 20px;
  // border-bottom: 1px solid #d9dbe9;
}
::v-deep .el-dialog__body {
  padding: 0px;
}
::v-deep .el-dialog__headerbtn {
  top: 8px;
}

.main-content {
  margin: 0px auto;
  text-align: center;
  position: relative;
  .icon-close {
    position: absolute;
    right: -32px;
    top: -16px;
    border: 1px solid #fff;
    font-size: 20px;
    width: 32px;
    height: 32px;
    color: #fff;
    border-radius: 16px;
    cursor: pointer;
    &:hover {
      border: 1px solid #3a78f6;
      color: #3a78f6;
    }
  }
  .school-btn {
    margin-top: 16px;
    height: 40px;
    width: auto;
    cursor: pointer;
  }
  img {
    width: 100%;
  }
  .blue-button {
    padding: 0px 72px;
    height: 40px;
    background: #3a70ff;
    font-size: 15px;
    cursor: pointer;
    box-shadow: 0px 10px 24px -7px rgba(58, 120, 246, 0.5);
    color: #fff;
    border-radius: 20px;
    margin: 24px 0px 4px 0px;
    &:hover {
      opacity: 0.87;
    }
  }
}

// .main-content {
//   margin: 0px auto;
//   text-align: center;
//   border-radius: 16px;
//   height: 465px;
//   position: relative;
//   .close-activity {
//     position: absolute;
//     color: #eaeaf5;
//     bottom: -60px;
//     left: 50%;
//     transform: translate(-50%, 0);
//     -webkit-transform: translate(-50%, 0);
//     width: 40px;
//     height: 40px;
//     cursor: pointer;
//   }
//   .setting-title {
//     font-size: 20px;
//     font-weight: bold;
//     color: #14142b;
//     line-height: 20px;
//     margin: -38px 0px 24px 0px;
//   }
//   img {
//     width: 100%;
//     height: auto;
//     border-radius: 16px;
//   }
//   p {
//     font-size: 16px;
//     font-weight: 400;
//     color: #444558;
//     line-height: 24px;
//     margin: 24px 0px 0px 0px;
//     text-align: left;
//   }
//   .button {
//     cursor: pointer;
//     font-size: 16px;
//     font-weight: 500;
//     color: #ffffff;
//     height: 40px;
//     width: 260px;
//     box-shadow: 0px 13px 32px -10px rgba(58, 120, 246, 0.5);
//     border-radius: 16px;
//     background: #3a70ff;
//     margin-top: 24px;
//     &:hover {
//       background: #3a78f6;
//     }
//   }
// }
// ::v-deep {
//   .el-dialog__headerbtn {
//     display: none !important;
//   }
//   .el-dialog__header {
//     padding: 0 !important;
//   }
//   .el-dialog__body {
//     padding: 0 !important;
//   }
//   .el-dialog {
//     height: 465px !important;
//   }
// }
</style>
