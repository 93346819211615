var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "comm-check-form",
          attrs: {
            title: "设置填写提醒",
            visible: _vm.dialogVisible,
            width: "550px",
            "append-to-body": true,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "dialog-content comm-input" }, [
            _c("div", { staticClass: "remind-day-form" }, [
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c("div", { staticClass: "item-title" }, [
                    _vm._v("开始前第"),
                  ]),
                  _c("day-select", {
                    staticClass: "form-select",
                    model: {
                      value: _vm.form.startBeforeDay,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "startBeforeDay", $$v)
                      },
                      expression: "form.startBeforeDay",
                    },
                  }),
                  _vm._v(" 天 "),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c("div", { staticClass: "item-title" }, [
                    _vm._v("开始后第"),
                  ]),
                  _c("day-select", {
                    staticClass: "form-select",
                    model: {
                      value: _vm.form.startAfterDay,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "startAfterDay", $$v)
                      },
                      expression: "form.startAfterDay",
                    },
                  }),
                  _vm._v(" 天 "),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "form-item" },
              [
                _c("div", { staticClass: "item-title" }, [_vm._v("时间")]),
                _c("el-time-select", {
                  staticClass: "form-select",
                  attrs: {
                    editable: false,
                    "picker-options": {
                      start: "00:00",
                      step: "00:30",
                      end: "23:30",
                    },
                    placeholder: "选择时间",
                    clearable: false,
                  },
                  model: {
                    value: _vm.form.remindTime,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "remindTime", $$v)
                    },
                    expression: "form.remindTime",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "remind-text" }, [
              _vm._v(" 提醒时间：" + _vm._s(_vm.remindText) + " "),
              _c("span", { staticClass: "remind-time" }, [
                _vm._v(_vm._s(_vm.form.remindTime)),
              ]),
            ]),
            _c("div", { staticClass: "bottom-step-buttons" }, [
              _c(
                "button",
                { staticClass: "left-button", on: { click: _vm.handleClose } },
                [_vm._v("取消")]
              ),
              _c("button", { on: { click: _vm.save } }, [_vm._v("确定")]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }