<template>
  <div class="task-text">
    <div v-if="!showContent" class="show-btn" @click="clickShow">未填写</div>
    <el-input
      v-if="showContent"
      :ref="`input${fieldInfo.id}`"
      v-model="inputVal"
      :placeholder="placeholder"
      :disabled="disabled"
      :maxlength="20"
      :autofocus="autofocus"
      @input="inputChange"
      @blur="blurInput"
    />
  </div>
</template>
<script>
export default {
  // 20
  props: {
    value: {
      type: Number,
      default: 0
    },
    isShow: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      default: '请输入数字'
    },
    fieldInfo: {
      type: String,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      inputVal: this.value ? this.value : '',
      showContent: this.isShow,
      max: 0,
      min: 0
    }
  },
  watch: {
    value: {
      handler(val) {
        this.inputVal = val
      },
      deep: true
    }
  },
  methods: {
    clickShow() {
      if (this.disabled) return
      this.showContent = true
      this.$nextTick(() => {
        const refname = `input${this.fieldInfo.id}`
        this.$refs[refname].focus()
      })
    },
    inputChange(val) {
      let inputVal = val
        .replace(/[^\d.]/g, '')
        .toString()
        .replace(/\.{2,}/g, '.')
        .replace('.', '$#$')
        .replace(/\./g, '')
        .replace('$#$', '.') // 只能输入一个小数点
      if (
        inputVal.length > 1 &&
        inputVal.substr(0, 1) === '0' &&
        inputVal.substr(1, 1) !== '.'
      ) {
        inputVal = inputVal.substr(1, 1)
      }
      if (inputVal.substr(0, 1) === '.') {
        inputVal = ''
      }
      // 只能输入数字
      inputVal =
        inputVal.indexOf('.') > -1
          ? inputVal.slice(0, inputVal.indexOf('.') + 4)
          : inputVal
      this.inputVal = inputVal
      this.$emit('change', this.inputVal, this.fieldInfo)
    },
    blurInput() {
      if (!this.inputVal || this.inputVal === '') {
        this.showContent = false
      } else {
        this.$emit('inputBlur', this.inputVal, this.fieldInfo)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.task-text {
  .show-btn {
    color: #a0a3bd;
    cursor: pointer;
    &:hover {
      color: #3a78f6;
    }
  }
  ::v-deep {
    .el-input__inner {
      border: none !important;
      resize: none;
      color: #14142b;
      width: 250px;
      padding: 0;
      text-align: left;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
}
</style>
