var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "append-to-body": true,
        center: "",
        size: 360,
        "modal-append-to-body": true,
        "before-close": _vm.dialogClose,
        "custom-class": "set-workhours-dialog",
        "show-close": false,
        title: _vm.title,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "set-workhours-main" }, [
        _c(
          "div",
          { staticClass: "set-workhours-data" },
          [
            _c("input-number", {
              attrs: { source: "stopdoing", step: 1, point: 1 },
              model: {
                value: _vm.workHours,
                callback: function ($$v) {
                  _vm.workHours = $$v
                },
                expression: "workHours",
              },
            }),
            _c("span", { staticClass: "set-workhours-unit" }, [_vm._v("小时")]),
          ],
          1
        ),
        _c("div", { staticClass: "cumulative-div" }, [
          _c("div", { staticClass: "cumulative-data" }, [
            _vm._v(" 当前累计 "),
            _c("span", { staticClass: "value" }, [
              _vm._v(_vm._s(_vm.cumulativeValue ? _vm.cumulativeValue : 0)),
            ]),
            _vm._v(" 小时 "),
          ]),
          _c(
            "div",
            {
              staticClass: "look-detailed",
              on: {
                click: function ($event) {
                  return _vm.lookDetailed()
                },
              },
            },
            [_vm._v("查看明细")]
          ),
        ]),
        _vm.title == "实际工时"
          ? _c(
              "div",
              { staticClass: "date-div" },
              [
                _c("el-date-picker", {
                  staticClass: "workhours-date",
                  attrs: {
                    type: "datetimerange",
                    "range-separator": "-",
                    format: "yyyy-MM-dd HH:mm",
                    "value-format": "yyyy-MM-dd HH:mm:ss",
                    "start-placeholder": "开始日期",
                    "end-placeholder": "结束日期",
                    "default-time": ["10:00:00", "19:00:00"],
                  },
                  on: { change: _vm.changeDate },
                  model: {
                    value: _vm.dateValue,
                    callback: function ($$v) {
                      _vm.dateValue = $$v
                    },
                    expression: "dateValue",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "executor-div" }, [
          _c("div", { staticClass: "executor-label" }, [_vm._v("负责人")]),
          _c(
            "div",
            { staticClass: "executor-content" },
            [
              _c(
                "user-picker",
                {
                  attrs: { taskId: _vm.form.id, pageType: "workHours" },
                  on: { pick: _vm.toriginatorPicker },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "originator-box to-edit-input-not",
                      on: {
                        mouseover: function ($event) {
                          _vm.isColorCloseIcon = false
                        },
                        mouseout: function ($event) {
                          _vm.isColorCloseIcon = true
                        },
                      },
                    },
                    [
                      _vm.originator.originatorName === null
                        ? _c("div", [
                            _c("i", { staticClass: "head-icon" }),
                            _c("span", { staticClass: "head-icon-label" }, [
                              _vm._v("待认领"),
                            ]),
                          ])
                        : _c(
                            "div",
                            [
                              _c("el-image", {
                                staticClass:
                                  "avatar-img pointer border-radius-20",
                                attrs: {
                                  fit: "cover",
                                  src:
                                    _vm.originator.originatorAvatar ||
                                    require("@/assets/imgs/common/advar.png"),
                                  alt: "",
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "m-l-10 pointer username" },
                                [
                                  _c("wx-open-data", {
                                    attrs: {
                                      id: _vm.originator.originatorName,
                                      nameData: {
                                        name: _vm.originator.originatorName,
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "executor-div" }, [
          _c("div", { staticClass: "executor-label" }, [_vm._v("工作描述")]),
          _c(
            "div",
            { staticClass: "executor-content" },
            [
              _c("el-input", {
                staticClass: "desc-textarea",
                attrs: {
                  type: "textarea",
                  rows: 4,
                  maxlength: 500,
                  placeholder: "请输入内容",
                },
                on: { input: _vm.changeDesc },
                model: {
                  value: _vm.workDesc,
                  callback: function ($$v) {
                    _vm.workDesc = $$v
                  },
                  expression: "workDesc",
                },
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "button-div" },
          [
            _c(
              "el-button",
              {
                staticClass: "cancel-button",
                attrs: { type: "primary", plain: "" },
                on: { click: _vm.dialogClose },
              },
              [_vm._v("取消")]
            ),
            _c(
              "el-button",
              {
                staticClass: "confirm-button",
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.submitWorkHours()
                  },
                },
              },
              [_vm._v("确定")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }