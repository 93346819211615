const versionNumber = 'v1' // 版本
const versionNumber2 = 'v2' // 版本
// 工作台模块api
export default [
  // 用户工作台视图条件查询，修改
  {
    workBenchCondition: `/api/ranloTask/${versionNumber}/customconditions/viewDetail/`
  },
  // 查询任务自定义条件字典
  {
    workBenchConditionList: `/api/ranloTask/${versionNumber}/customconditions`
  },
  // 获取用户个人视图列表
  {
    workBenchMyview: `/api/ranloTask/${versionNumber}/customconditions/getWorkViewTitle`
  },
  // 获取全部视图模板
  {
    workBenchTemplateList: `/api/ranloTask/${versionNumber}/customconditions/getViewTemplateList`
  },
  // 获取视图内任务列表
  {
    workBenchViewTasks: `/api/project/${versionNumber}/task/getViewTask`
  },
  // 获取视图内目标列表
  {
    workBenchViewObjs: `/api/ranloWeb/${versionNumber2}/objectives/workbench/okr/list`
  },
  // 添加、编辑视图
  {
    workBenchAddView: `/api/ranloTask/${versionNumber}/customconditions/saveViewTemplate`
  },
  // 删除视图
  {
    workBenchDelView: `/api/ranloTask/${versionNumber}/customconditions/removeView/`
  },
  // 风险预警提醒列表（我收到的）
  {
    receiveList: `/api/notify/${versionNumber}/notify/warning/remind/receiveList`
  },
  // 风险预警提醒历史
  {
    receiveHistoryList: `/api/notify/${versionNumber}/notify/warning/remind/history/receiveList`
  },
  // 工作台用户视图列表
  {
    workbench: `/api/ranloTask/${versionNumber}/workbench`
  },
  // 工作台角色列表
  {
    workbenchRole: `/api/ranloTask/${versionNumber}/workbench/role`
  },
  // 工作台视图模版列表(编辑工作台添加模版调用此接口)
  {
    workbenchView: `/api/ranloTask/${versionNumber}/workbench/view`
  },
  // 工作台关注项目列表
  {
    followProject: `/api/ranloTask//${versionNumber}/workbench/follow/project`
  },
  // 工作台关注任务列表
  {
    followTask: `/api/ranloTask/${versionNumber}/workbench/follow/task`
  },
  // 工作台关注目标列表
  {
    followOkr: `/api/ranloWeb/${versionNumber}/objectives/workbench/okr/list/care`
  },
  // 工作台目标列表
  {
    workOkrList: `/api/ranloWeb/v2/objectives/workbench/list/condition/menu`
  },
  // 工作台我的项目
  {
    myProject: `/api/ranloTask/${versionNumber}/workbench/project`
  },
  // 工作台工作日历
  {
    workbenchCalendar: `/api/ranloTask/${versionNumber}/workbench/calendar`
  },
  // 工作台未规划任务
  {
    workbenchUnplannedTask: `/api/ranloTask/${versionNumber}/workbench/unplanned/task`
  },
  // 查询里程碑工作台列表
  {
    milepostWorkbenchList: `/api/ranloTask/${versionNumber}/project/milepost/workbench/list`
  },
  // 工作台工作任务
  {
    workbenchWorkTask: `/api/ranloTask/${versionNumber}/workbench/work/task`
  },
  // 分页查询未显示的项目
  {
    milepostWorkbenchProjectPageList: `/api/ranloTask/${versionNumber}/project/milepost/workbench/project/page/list`
  },
  // 添加项目的里程碑工作台显示
  {
    milepostWorkbenchSave: `/api/ranloTask/${versionNumber}/project/milepost/workbench/save`
  },
  // 删除 项目的里程碑工作台显示
  {
    milepostWorkbenchDelete: `/api/ranloTask/${versionNumber}/project/milepost/workbench/delete`
  }
]
