const state = {
    workTaskTab: "kr",
    workTaskViewTab: true,
    workTaskViewSelect: 0,
    modelView: [],
}
const actions = {
    _setWorkTaskViewSelect(context, val) {
        context.commit('setWorkTaskViewSelect', val)
    },
    _setModelView(context, val) {
        context.commit('setModelView', val)
    },
    _setOneModelView(context, val) {
        context.commit('setOneModelView', val)
    },
    _setWorkTaskTab(context, val) {
        context.commit('setWorkTaskTab', val)
    },
    _setWorkTaskViewTab(context, val) {
        context.commit('setWorkTaskViewTab', val)
    }
}
const mutations = {
    setWorkTaskViewSelect(state, val) {
        state.workTaskViewSelect = val
    },
    setModelView(state, val) {
        state.modelView = val
    },
    setOneModelView(state, val) {
        state.modelView.unshift(val)
    },
    setWorkTaskTab(state, val) {
        state.workTaskTab = val
    },
    setWorkTaskViewTab(state, val) {
        state.workTaskViewTab = val
    }
}
export default {
    namespaced: true,
    state,
    actions,
    mutations
}
