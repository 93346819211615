<template>
  <div>
    <div class="box">
      <template v-if="type === 'add'">
        <span>
          <span
            class="time-text"
            :class="startHover && form.startDate ? 'time-text-hover' : ''"
            @click="startPicker"
          >
            <span v-if="form.startDate" class="time-text-yes">{{
              form.startDate
            }}</span>
            <span v-else class="time-text-no">开始时间</span>
            <Icon
              v-if="form.startDate"
              icon-class="icon-a-Group719-1"
              class="close"
              @mouseenter.native="startHover = true"
              @mouseleave.native="startHover = false"
              @click.native.stop="timeClear('start')"
            />
          </span>
          <template>-</template>
          <span
            class="time-text"
            :class="endHover && form.endDate ? 'time-text-hover' : ''"
            @click="endPicker"
          >
            <span v-if="form.endDate" class="time-text-yes">
              {{
                classifytype == 'todo' ? form.endDate + ' 截止' : form.endDate
              }}
            </span>
            <span v-else class="time-text-no">截止时间</span>
            <Icon
              v-if="form.endDate"
              icon-class="icon-a-Group719-1"
              class="close"
              @mouseenter.native="endHover = true"
              @mouseleave.native="endHover = false"
              @click.native.stop="timeClear('end')"
            />
          </span>
        </span>
        <el-date-picker
          ref="startDateTask"
          v-model="form.startDate"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm"
          :default-time="defaultStart"
          type="datetime"
          placeholder="开始日期"
          class="datetime hide-date-border"
          :picker-options="pickerOptionsStart"
          popper-class="addTimeClass"
          @change="startDateChange"
        />
        <span class="m-l-r-10" style="display: none">—</span>
        <el-date-picker
          ref="endDateTask"
          v-model="form.endDate"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm"
          :default-time="defaultEnd"
          type="datetime"
          placeholder="截止日期"
          class="hide-date-border end-date"
          :picker-options="pickerOptionsEnd"
          popper-class="addTimeClass"
          @change="endDateChange"
        />
      </template>
      <template v-else>
        <date-time-picker
          :obj="form"
          :disabled="disabled"
          @change="dateTimeChange"
        />
      </template>
      <span class="line"></span>
      <!-- 设置任务周期 -->
      <!-- v-if="row.fatherTid === null || row.fatherTid === '0'" -->
      <el-tooltip
        v-if="!row.fatherTid"
        :open-delay="300"
        :content="cycleDateFmt"
      >
        <task-cycle
          :id="form.cycleDateId"
          :task-id="form.id"
          :disabled="disabled"
          @save="cycleSave"
        />
      </el-tooltip>
      <!-- 设置提醒时间 -->
      <span class="line"></span>
      <el-tooltip
        :content="remindDateFmt"
        :open-delay="300"
        placement="top"
        effect="light"
      >
        <Icon
          v-if="type_remind === 0 || !form.remindDateId"
          icon-class="icon-remind"
          :class="{ 'c-azure-radiance': form.remindDateId, disabled: disabled }"
          class="time-icon"
          @click="remindTimeClick"
        />
        <Icon
          v-else
          icon-class="icon-remind"
          :class="{ 'c-azure-radiance': form.remindDateId, disabled: disabled }"
          class="time-icon active-icon"
          @click="remindTimeClick"
        />
      </el-tooltip>
    </div>
    <!-- 设置提醒时间dialog -->
    <!-- <transition name="fade" mode="out-in"> -->
    <task-remind-time
      v-if="remindTimeVisible"
      :id="form.remindDateId"
      :visible.sync="remindTimeVisible"
      :task-id="form.id"
      :type="type"
      @save="remindTimeSave"
    />
    <!-- </transition> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import formMixin from '@/utils/mixins/form'
import taskUtil from '@/utils/task.js'
import TaskCycle from './TaskCycle' // 任务重复周期
import TaskRemindTime from './TaskRemindTime' // 设置提醒时间
export default {
  components: { TaskCycle, TaskRemindTime },
  mixins: [formMixin],
  props: {
    // 表单对象
    row: {
      type: Object,
      required: false
    },
    // 类型
    type: {
      type: String,
      required: false
    },
    // 是否禁用
    disabled: {
      required: false
    },
    classifytype: {
      // todo: 待办 kr：目标 project：项目
      default: 'kr'
    }
  },
  data() {
    const _this = this
    return {
      pickerOptionsStart: {
        // 开始日期范围限制
        disabledDate(time) {
          let disabled
          if (_this.form.endDate) {
            // disabled =
            //   time.getTime() >
            //   _this.$moment(_this.form.endDate).startOf('day').format('x')
            disabled = new Date(time) > new Date(_this.form.endDate)
          }
          return disabled
        }
      },
      pickerOptionsEnd: {
        // 结束日期范围限制
        disabledDate(time) {
          let disabled
          if (_this.form.startDate) {
            disabled =
              time.getTime() <
              _this.$moment(_this.form.startDate).startOf('day').format('x')
            // disabled = new Date(time) < new Date(_this.form.startDate)
          }
          return disabled
        }
      },
      remindTimeVisible: false, // 设置提醒时间显示
      type_remind: '',
      defaultStart: '',
      defaultEnd: '',
      startHover: false,
      endHover: false
      // taskAddObject: ''
    }
  },
  created() {
    // 初始化
    // this.taskAddObject = this.$store.state.commom.taskAddObject
    this.init()
  },
  computed: {
    ...mapState('common', {
      taskAddObject: 'taskAddObject'
    }),
    // 提醒时间转化
    remindDateFmt() {
      if (this.disabled) {
        return '没有权限编辑'
      }
      return taskUtil.remindDateFmt(this.row.remindDate)
    },
    // 重复周期转化
    cycleDateFmt() {
      if (this.disabled) {
        return '没有权限编辑'
      }
      return taskUtil.cycleDateFmt(this.row.cycleDate)
    },
    // 是否设置时间
    isSetTime() {
      const { startDate, endDate } = this.form
      return startDate && endDate
    }
  },
  methods: {
    // 初始化
    init() {
      if (this.companyCalendarInfo.amStartTime) {
        this.defaultStart =
          this.companyCalendarInfo.amStartTime.substring(11, 19) || ''
      }
      if (this.companyCalendarInfo.pmEndTime) {
        this.defaultEnd =
          this.companyCalendarInfo.pmEndTime.substring(11, 19) || ''
      }
      console.log(this.defaultStart, this.defaultEnd)
      this.form = JSON.parse(JSON.stringify(this.row))
      if (this.type === 'edit' && this.form.remindDate != null) {
        this.type_remind = this.form.remindDate.dateType
      }
      if (this.taskAddObject.default === '1') {
        this.form.remindDateId = this.taskAddObject.remindDateId
        this.type_remind = this.taskAddObject.type_remind
        this.row.remindDateId = this.taskAddObject.remindDateId
      }
    },
    // 开始时间变化
    startDateChange() {
      const startTime = new Date(this.form.startDate) || 0
      const endTime = new Date(this.form.endDate) || 0
      if (
        Number(startTime) > Number(endTime) &&
        this.form.startDate &&
        this.form.endDate
      ) {
        this.$message({ msg: '开始时间不能大于截止时间', type: 'warn' })
        this.form.startDate = ''
      } else {
        this.row.startDate = this.form.startDate
        this.taskAddObject.startDate = this.form.startDate
        this.$store.commit('common/saveTaskAddObject', this.taskAddObject)
      }
    },
    // 结束时间变化
    endDateChange() {
      const startTime = new Date(this.form.startDate) || 0
      const endTime = new Date(this.form.endDate) || 0
      if (
        Number(startTime) > Number(endTime) &&
        this.form.endDate &&
        this.form.startDate
      ) {
        this.$message({ msg: '截止时间不能小于开始时间', type: 'warn' })
        this.form.endDate = ''
      } else {
        this.row.endDate = this.form.endDate
        this.taskAddObject.endDate = this.form.endDate
        this.$store.commit('common/saveTaskAddObject', this.taskAddObject)
      }
    },
    // 设置提醒时间点击事件
    remindTimeClick() {
      if (this.disabled) {
        return
      }
      this.remindTimeVisible = true
    },
    // 任务重复周期保存成功事件
    cycleSave(row) {
      this.form.cycleDateId = row.id
      this.row.cycleDateId = row.id
      this.taskAddObject.cycleDateId = row.id
      this.row.cycleDate = row.data
      this.row = JSON.parse(JSON.stringify(this.row))
      this.$store.commit('common/saveTaskAddObject', this.taskAddObject)
      this.$emit('global:taskDetailRefresh')
    },
    // 提醒时间保存成功事件
    remindTimeSave(row) {
      this.form.remindDateId = row.id
      this.row.remindDateId = row.id
      this.type_remind = row.dateType
      this.taskAddObject.remindDateId = row.id
      this.taskAddObject.type_remind = row.dateType
      this.row.remindDate = row.data
      this.row = JSON.parse(JSON.stringify(this.row))
      this.$store.commit('common/saveTaskAddObject', this.taskAddObject)
      this.$emit('global:taskDetailRefresh')
    },
    // 日期时间选择
    dateTimeChange(row) {
      this.$emit('change', row)
    },
    // 时间清除
    timeClear(type) {
      this.form.type = type
      if (type === 'start') {
        this.form.startDate = ''
        this.row.startDate = ''
        this.startHover = false
      } else {
        this.form.endDate = ''
        this.row.endDate = ''
        this.endHover = false
      }
    },
    // 出现日期面板（开始日期选择）
    startPicker() {
      this.$refs.startDateTask.$el.focus()
      const el = this.$refs.startDateTask.$el
      el.children[0].focus()
    },
    // 出现日期面板（结束日期选择）
    endPicker() {
      this.$refs.endDateTask.$el.focus()
      const el = this.$refs.endDateTask.$el
      el.children[0].focus()
    }
  }
}
</script>

<style lang="scss" scoped>
.line {
  border-left: 1px solid #d9dbe9;
  display: inline-block;
  width: 1px;
  height: 12px;
  margin: 0 12px;
}
::v-deep {
  .cycle-icon {
    margin-left: 0 !important;
  }
}
.box {
  display: flex;
  align-items: center;
  position: relative;
  .date-box {
    // width: 170px !important;
  }
  .time-icon {
    cursor: pointer;
    margin-top: 1px;
    width: 20px;
    height: 20px;
    font-size: 20px;
    color: #a0a3bd;
    &:hover {
      color: #3a78f6;
    }
  }
  .active-icon {
    color: #ff9900;
  }
  .cycle-icon {
    cursor: pointer;
    margin-left: 4px;
    width: 22px;
    height: 22px;
    font-size: 22px;
    // margin-top: 4px;
    &:hover {
      color: #3a78f6;
    }
  }

  .time-text {
    display: inline-block;
    padding: 4px 8px;
    position: relative;
    color: #a0a3bd;
    border: 1px solid #fff;
    border-radius: 16px;
    height: 32px;
    line-height: 22px;
    cursor: pointer;
    .time-text-yes {
      color: #444558;
    }
    .time-text-no {
      color: #a0a3bd;
    }
    .close {
      border-radius: 100% 100%;
      font-size: 18px;
      display: none;
      position: absolute;
      right: -4px;
      top: -8px;
      cursor: pointer;
      color: #a0a3bd;
      width: 18px;
      height: 18px;
      background: #fff;
      border-radius: 50%;
      &:hover {
        color: #ff9900;
      }
    }
    &:hover {
      border: 1px solid #f5f6fa;
      background-color: #f5f6fa;
      .close {
        display: block;
      }
    }
  }
  .time-text-hover {
    border: 1px solid #ff9900;
    background-color: transparent;
    &:hover {
      background-color: transparent;
      border: 1px solid #ff9900;
    }
    .close {
      color: #ff9900;
    }
  }
  .hide-date-border {
    left: -20px;
    opacity: 0;
    position: absolute;
    top: -20px;
    width: 20px;
    z-index: -10;
  }
  .end-date {
    left: auto;
    right: 50px;
  }
}
.disabled {
  cursor: not-allowed !important;
}
</style>
<style lange="scss">
.addTimeClass {
  z-index: 3000 !important;
}
</style>
