<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="380px"
    :modal="false"
    :append-to-body="true"
    :close-on-press-escape="false"
    :before-close="handleClose"
    :close-on-click-modal="type == 'upload' ? false : true"
    class="comm-check-form process-dialog-wrap"
  >
    <div v-if="type == 'upload'" class="upload-content">
      <el-progress :text-inside="true" :stroke-width="16" :percentage="rate"></el-progress>
      <div class="text">{{ dialogTxt }}</div>
    </div>
    <div v-else-if="type === 'applyDemo'" class="dialog-content">
      <img src="@/assets/imgs/okr/finishOperate.png" />
      <div class="dialog-text" v-html="dialogTxt"></div>
      <div class="bottom-btn" @click="handleClose(true)">{{ btn }}</div>
    </div>
    <div v-else-if="type !== '1'" class="dialog-content">
      <img src="@/assets/imgs/okr/finishOperate.png" />
      <div>{{ dialogTxt }}完成</div>
      <div class="bottom-btn" @click="toFinishPage">{{ btn }}</div>
    </div>
    <div v-else class="dialog-content">
      <img src="@/assets/imgs/okr/finishOperate.png" />
      <div>项目复制成功</div>
      <div class="step-close" @click="handleClose">
        <Icon class="step-close-img" icon-class="icon-close" @click="addStepClose" />
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { mapState } from 'vuex'
export default {
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  props: {
    dialogShow: {
      type: Boolean,
      default: false
    },
    rate: {
      // 进度百分比
      type: Number,
      default: 0
    },
    btn: {
      type: String,
      default: '查看新目标'
    },
    dialogTxt: {
      type: String
    },
    type: {
      // 1项目
      type: String
    },
    loadingParam: {
      // 点击按钮时传递的参数
      type: Object
    }
  },
  watch: {
    dialogShow: {
      handler(val) {
        this.dialogVisible = val
      }
    },
    rate: {
      handler(val) {
        if (val === 100) {
          this.loading = false
        }
      }
    }
  },
  data() {
    return {
      dialogVisible: this.dialogShow,
      loading: true
    }
  },
  created() {},
  methods: {
    handleClose(type) {
      this.dialogVisible = false
      this.$emit('change', false)
      this.$emit('close', type)
    },
    toFinishPage() {
      this.$emit('toFinishPage', this.loadingParam)
      this.dialogVisible = false
      this.$emit('change', false)
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../assets/styles/dialog.less';
.process-dialog-wrap {
  z-index: 9999999 !important;
  background: rgba(0, 0, 0, 0.5);
}
::v-deep .el-dialog__body {
  padding: 0 32px;
}
::v-deep .el-progress-bar__innerText {
  margin: -5px 5px 0px 5px;
}
.upload-content {
  font-size: 16px;
  color: #444558;
  line-height: 24px;
  font-weight: 500;
  padding: 64px 0px 32px 0px;
  .text {
    text-align: center;
    margin-top: 38px;
  }
}
.dialog-content {
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  color: #444558;
  position: relative;
  line-height: 24px;
  font-weight: 500;
  .dialog-text {
    text-align: center;
    ::v-deep .text {
      color: #a0a3bd;
      font-size: 12px;
    }
  }
  img {
    width: 64px;
    margin-bottom: 16px;
  }
  .bottom-btn {
    cursor: pointer;
    margin-top: 24px;
    background: #3a78f6;
    color: #fff;
    font-size: 14px;
    line-height: 14px;
    padding: 12px 40px;
    border-radius: 16px;
    &:hover {
      background: #548af7;
    }
  }
  .step-close {
    cursor: pointer;
    position: absolute;
    border-radius: 4px;
    right: 0;
    top: 16px;
    .step-close-img {
      width: 28px;
      height: 28px;
      font-size: 24px;
    }
    &:hover {
      background: #f5f6fa;
    }
  }
}
::v-deep {
  .el-dialog__header {
    display: none;
  }
}
</style>
