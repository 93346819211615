<template>
  <!-- 企信迁移后新增 -->
  <div class="okr-detail-wrap">
    <el-dialog
      :visible.sync="dialogVisible"
      :append-to-body="true"
      :before-close="handleClose"
      class="okr-detail-dialog"
    >
      <transition name="fade" mode="out-in">
        <div v-vueloading="loading" class="objective-detail" @click="clickNoFocus">
          <!-- v-show="!loading"  -->
          <div class="objective-detail-header">
            <div class="flex-item cycle-info">
              <el-tooltip
                content="点击修改目标类型"
                :disabled="
                  userInfo.id === objectiveObj.createUserid ? false : true
                "
                placement="top"
              >
                <objective-label
                  page-type="okrDetail"
                  :okr-data="objectiveObj"
                  :can-edit="
                    userInfo.id === objectiveObj.createUserid ? true : false
                  "
                  :label-type="objectiveObj.labelType"
                  :share-list="objectiveObj.objectivesSharedUserList"
                  @refresh="queryObjectiveDetail(objectiveObj.id, '', 'refresh')"
                >
                  <div class="info-box">
                    <Icon icon-class="icon-mainpage_tab_okr" class="target-icon" />
                    {{ labelTypeObj[objectiveObj.labelType] }}目标
                  </div>
                </objective-label>
              </el-tooltip>
              <div class="info-box">{{ showCycleTitle }}</div>
              <div v-if="objPathData.length > 1" class="okr-path">
                <okr-detail-path :obj-path-data="objPathData" @getTargetReturn="getTargetReturn" />
              </div>
            </div>
            <div class="flex-item obj-actions">
              <approveTypeLabel
                v-if="
                  objectiveObj.processState === 1 &&
                  objectiveObj.objectivesProcessDataDto
                "
                :obj-details="objectiveObj"
                :business-type="
                  objectiveObj.objectivesProcessDataDto &&
                  objectiveObj.objectivesProcessDataDto.businessType
                "
              />
              <div
                v-if="
                  objectiveObj.canBeScored === 1 &&
                  (objectiveObj.score || objectiveObj.score === 0)
                "
                class="action-item score-item"
              >
                <img src="@/assets/imgs/okr/okrScore.png" />
                {{ `${objectiveObj.score}分` }}
              </div>
              <div class="action-item">
                <confidence-select
                  v-model="objectiveObj.confidence"
                  :top-members-beans="objectiveObj"
                  @change="changeConfidence"
                />
              </div>
              <div class="action-item" @click.stop="showKpiIndex()">
                <Icon icon-class="icon-jixiao" class="icon-kpi" />
                <div class="laud-count">{{ objectiveObj.performanceNum || 0 }}</div>
              </div>
              <div class="action-item list-animocon-wrap">
                <button
                  class="icobutton icobutton--thumbs-up opacityNumber"
                  :class="'okrDetailId' + objectiveObj.id"
                  @click="getTargetLink(objectiveObj)"
                >
                  <span
                    class="fa fa-thumbs-up"
                    :class="
                      objectiveObj.isLaud === 1
                        ? 'fa_style_state'
                        : 'fa_style_state_2'
                    "
                  ></span>
                </button>
                <el-image
                  fit="cover"
                  v-if="objectiveObj.laudUserAvatar"
                  class="kr-right-avatar border-radius-20"
                  :src="
                    objectiveObj.laudUserAvatar ||
                    require('@/assets/imgs/common/avatar.png')
                  "
                />
                <div
                  v-if="showLikeRedpoint"
                  class="common-red-point"
                  :class="objectiveObj.laudUserAvatar ? '' : 'no-avatar-point'"
                ></div>
                <div class="laud-count">{{ objectiveObj.laudCount | numberHandle }}</div>
              </div>
              <div class="action-item list-target-wrap">
                <view-target icon="icon-chakan" :obj-info="objectiveObj" />
              </div>
              <div
                :class="`okr-care okr-care-type-${objectiveObj.careState}`"
                @click="okrCareClick(objectiveObj)"
              >
                <el-tooltip
                  placement="top"
                  :open-delay="300"
                  :content="
                    objectiveObj.careState === 1 ? '已关注OKR' : '关注OKR'
                  "
                >
                  <Icon
                    :icon-class="
                      objectiveObj.careState === 1
                        ? 'icon-collected'
                        : 'icon-collect'
                    "
                    class="icon"
                  />
                </el-tooltip>
              </div>
              <div
                v-if="userInfo.id === objectiveObj.createUserid ? true : false"
                class="action-item close-icon-box"
              >
                <more-menu
                  ref="okrDetailMenu"
                  :items="menuItems"
                  placement="bottom-start"
                  @menuClick="operateTarget"
                >
                  <Icon icon-class="icon-more-row" class="kr-right-icon" />
                </more-menu>
              </div>
              <div class="action-item close-icon-box">
                <Icon
                  icon-class="icon-close"
                  class="kr-right-icon icon-close"
                  @click="handleClose"
                />
              </div>
            </div>
          </div>
          <!-- v-show="!loading"  -->
          <div class="objective-detail-content">
            <div v-if="!objDeleteState" class="obj-content" @scroll="objContentScroll">
              <div
                class="top-content"
                :class="
                  objectiveObj.automationState === 1 ? 'progress-auto' : ''
                "
              >
                <top-members
                  ref="topMember"
                  :top-members-beans="objectiveObj"
                  top-members-state="1"
                  class="top-member"
                  :details-id="objectiveObj.id"
                  @backData="backData"
                  @delteAlignObj="queryObjectiveDetail(objectiveObj.id, '', 'refresh')"
                  @updateObjTitle="queryObjectiveDetail(objectiveObj.id)"
                  @showShareList="showShareList"
                  @mapRefresh="mapRefreshState(true, $event)"
                />
                <top-progress :top-members-beans="objectiveObj" top-members-state="3">
                  <div class="top-progress-auto">
                    <autoProgressPopver
                      v-if="objectiveObj.automationState === 1"
                      :okr-data="objectiveObj"
                      :disabled="objectiveObj.automationState !== 1"
                      :type="1"
                    >
                      <div class="top-progress-content blue">
                        <!-- <Icon icon-class="icon-zhinengtuijin" class="icon" /> -->
                        <img
                          src="../../../../assets/imgs/common/circle-progress.gif"
                          class="icon"
                          alt
                        />
                      </div>
                    </autoProgressPopver>
                  </div>
                </top-progress>
                <div
                  v-if="userInfo.id === objectiveObj.createUserid &&
                    (objectiveObj.objectivesProcessDataDto &&
                      objectiveObj.objectivesProcessDataDto.businessType) !== 1"
                  class="add-keyresult"
                >
                  <transition name="bottom-slide" mode="out-in">
                    <key-results
                      v-if="leftAddkeyState"
                      :top-members-bean="objectiveObj"
                      @getAddkeyList="getAddkeyList"
                    />
                  </transition>
                  <transition name="bottom-slide" mode="out-in">
                    <key-results-Add
                      v-if="!leftAddkeyState"
                      :is-share="objectiveObj.isShare"
                      :obj-id="objectiveObj.id"
                      :okr-data-obj="objectiveObj"
                      @getkeyResultsAddRefresh="getkeyResultsAddRefresh"
                    />
                  </transition>
                </div>
              </div>
              <div class="okr-kr-list">
                <draggable
                  v-model="keyResultsList"
                  handle=".key_result_dragger_icon_box"
                  :disabled="
                    !(
                      userInfo.id === objectiveObj.createUserid &&
                      draggableStatus
                    )
                  "
                  @update="drop"
                >
                  <div v-for="(item, index) in keyResultsList" :key="item.id">
                    <div class="okr-kr-item">
                      <!-- :align-member="true" -->
                      <key-results-list
                        :key-results-li="item"
                        :target-object="objectiveObj"
                        :key-results-li-number="index"
                        :show-border="true"
                        :align-member="true"
                        @draggableSetStatus="draggableSetStatus"
                        @showTaskByKey="clickSelectedKr(item.id)"
                        @getTargetDetailsKeywordDetele="
                          getTargetDetailsKeywordDetele
                        "
                        @getKeyResultsListTitles="
                          getKeyResultsListTitles(index)
                        "
                        @changeObjectiveTitle="changeObjectiveTitle"
                        @getKeyResultsListWeights="
                          getKeyResultsListWeights(index)
                        "
                        @getKeyHandleChange="getKeyHandleChange"
                        @getKeyHandleChangeBlur="getKeyHandleChangeBlur"
                        @showProcessList="showProcessList"
                        @setAutoProcess="keyResultsAutoClick(item)"
                        @showKeyResultScore="showKeyResultScore(index)"
                        @getKeyScoreChangeBlur="getKeyScoreChangeBlur"
                        @getKeyScoreChange="getKeyScoreChange"
                        @okrDetail="getTopMembersAlignmentSelect"
                        @toOKRList="getTopMembersFatherList"
                      />
                    </div>
                  </div>
                </draggable>
              </div>
            </div>
            <div v-else class="obj-content">
              <noData />
            </div>
            <div class="obj-tabs">
              <div>
                <detail-tabs
                  v-model="selectTabValue"
                  :show-comment-redpoint="showCommentRedpoint"
                  :comment-user-avatar="objectiveObj.commentUserAvatar"
                  @change="getTargetSelect"
                />
              </div>
              <div v-if="selectTabValue === 1" class="tab-item">
                <div class="top-select-options">
                  <!-- 筛选项 -->
                  <div class="blod-select">
                    <el-select
                      v-model="selectKrOptionValue"
                      style="width: 116px"
                      class="select_Kr_Option"
                      @change="getTargetTaskList()"
                      @visible-change="closePop"
                    >
                      <el-option label="全部任务" value />
                      <el-option
                        v-for="(val, key) in objectiveObj.keyresultsList"
                        :key="val.id"
                        :label="'KR' + (key + 1) + ' 任务'"
                        :value="val.id"
                      />
                    </el-select>
                  </div>
                  <div class="normal-select">
                    <el-select
                      v-model="createTimeOptionValue"
                      style="width: 140px"
                      @change="getTargetTaskList()"
                      @visible-change="closePop"
                    >
                      <el-option
                        v-for="(val, key) in createTimeOption"
                        :key="key"
                        :label="val"
                        :value="parseInt(key)"
                      />
                    </el-select>
                    <!-- <el-select
                      v-model="stateOptionValue"
                      style="width: 100px"
                      @change="getTargetTaskList()"
                    >
                      <el-option
                        v-for="item in stateOption"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>-->
                    <el-select
                      v-if="userInfo.id === objectiveObj.createUserid"
                      v-model="roleTypeOptionValue"
                      style="width: 100px"
                      @change="getTargetTaskList()"
                      @visible-change="closePop"
                    >
                      <el-option
                        v-for="item in roleTypeOption"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </div>
                </div>
                <!-- (userInfo.id === objectiveObj.createUserid || isSharer) && -->
                <div
                  v-if="
                    (objectiveObj.objectivesProcessDataDto &&
                      objectiveObj.objectivesProcessDataDto.businessType) !== 1
                  "
                  class="task-add-box"
                  @click.stop
                >
                  <add-target-task
                    v-if="userInfo.id === objectiveObj.createUserid || isSharer"
                    ref="addTask"
                    :select-kr-array-value="selectKrOptionValue"
                    :kr-option="selectKrArray"
                    @addTargetTask="addTargetTaskRequest"
                    @addTargetView="addTargetViewStateChange"
                  />
                  <attach-project
                    v-if="selectKrOptionValue && !addTargetViewState"
                    :is-edit="userInfo.id === objectiveObj.createUserid"
                    class="bottom-attach"
                    :key-id="selectKrOptionValue"
                    :obj-id="objectiveObj.id"
                  />
                </div>
                <transition name="fade" mode="out-in">
                  <div
                    v-if="!taskArrayState && taskArray.length === 0"
                    class="no-data-show-wrap no-data-show-wrap1"
                  >
                    <div v-if="userInfo.id === objectiveObj.createUserid" class="no-data-show-wrap">
                      <Img src="/todo/todoNull.png" class="no-data-show-icon" />
                      <div class="no-data-show-text">马上添加工作任务推进OKR的达成吧，你也可以关联项目哦!</div>
                    </div>
                    <no-data v-else />
                  </div>
                </transition>
                <template v-vueloading="taskArrayState">
                  <!-- 任务列表 -->
                  <transition-group
                    id="transitionGroup"
                    ref="task"
                    v-scroll-bottom="loadMore"
                    name="fade"
                    tag="div"
                    data-scroll-type="task"
                    class="draggable-box task_content"
                  >
                    <task-item
                      v-for="(items, index) in taskArray"
                      :key="items.id"
                      :row="items"
                      :data-id="items.id"
                      :priority-visible="true"
                      :len-arr-length="taskArray.length"
                      :index-num="index"
                      class="draggable-item"
                      @deleteCard="queryObjectiveDetail(objectiveObj.id)"
                    />
                  </transition-group>
                </template>
              </div>
              <div v-show="selectTabValue === 2" v-vueloading="trendArrayState" class="tab-item">
                <div class="top-select-options">
                  <el-select
                    key="trend"
                    v-model="trendOptionValue"
                    class="select-bar select-dynamic blod-select"
                    @change="getTrendList()"
                    @visible-change="closePop"
                  >
                    <el-option
                      v-for="(val, key) in trendOption"
                      :key="key"
                      :label="val"
                      :value="parseInt(key)"
                      :disabled="key == 0 && trendOptionPermission()"
                    />
                  </el-select>
                  <div v-if="trendTotal > 10">
                    <!-- 动态列表切换显示菜单 -->
                    <div
                      v-if="trendTotal - trendArray.length != 0 && !oldVisible"
                      class="dynamic_menu"
                      @click="showOld"
                    >
                      <Icon icon-class="icon-more-row" class="trend_change_icon" />
                      <template>
                        显示较早
                        {{ trendTotal - trendArray.length }} 条
                      </template>
                    </div>
                    <div v-else class="dynamic_menu" @click="showOld">
                      <Icon icon-class="icon-more-row" class="trend_change_icon" />
                      <template>隐藏较早的动态</template>
                    </div>
                  </div>
                </div>
                <transition name="fade" mode="out-in">
                  <div
                    v-if="!trendArrayState && trendArray.length === 0"
                    class="no-data-show-wrap no-data-show-wrap2"
                  >
                    <no-data />
                  </div>
                </transition>
                <template v-vueloading="trendArrayState">
                  <transition-group
                    id="transitionGroupTrend"
                    ref="trendItem"
                    v-scroll-bottom="loadMoreTrendArray"
                    name="fade"
                    tag="div"
                    data-scroll-type="trendItem"
                    class="draggable-box trend_box"
                  >
                    <trend-item
                      v-for="item in trendArray"
                      :key="item.id"
                      class="trend_item"
                      :trend-info="item"
                      :obj-id="objId"
                      @refreshTrend="refreshTrend"
                    />
                  </transition-group>
                </template>
                <div
                  v-if="selectTabValue === 2"
                  class="comment_box"
                  :class="blurComment ? 'blur-box' : ''"
                >
                  <comment-box
                    ref="trendCommentBox"
                    placeholder="填写对目标的评论  ↵"
                    :blur-release="false"
                    :line-feed="true"
                    @release="addTrendComment"
                    @focus="focusComment(true)"
                    @blur="focusComment(false)"
                  />
                </div>
              </div>
              <div v-if="selectTabValue === 3" v-vueloading="processArrayState" class="tab-item">
                <div class="top-select-options">
                  <el-select
                    key="process"
                    v-model="krOptionValue"
                    class="select-bar blod-select"
                    @change="getProcessList()"
                    @visible-change="closePop"
                  >
                    <el-option label="全部进度" value />
                    <el-option
                      v-for="val in selectKrArray"
                      :key="val.id"
                      :label="val.name"
                      :value="val.id"
                    />
                  </el-select>

                  <div
                    v-if="
                      (userInfo.id === objectiveObj.createUserid || isSharer) &&
                      krOptionValue !== ''
                    "
                    class="top-select-right"
                  >
                    <key-results-alert
                      :obj-data="selectKRInfo"
                      type="okr"
                      okr-type="map"
                      @getKeyResultsAlertClose="updateKrProcess"
                    >
                      <div class="okr-process-add">+ 手动更新进度</div>
                    </key-results-alert>
                    <!-- (userInfo.companyId === '1165952161026109440' ||
                    userInfo.companyId === '1166538611434151936')-->
                    <div
                      v-if="
                        selectKRInfo.quantificationType !== 1 &&
                        userInfo.id === objectiveObj.createUserid
                      "
                      class="okr-process-add okr-process-auto"
                      @click="keyResultsAutoClick(selectKRInfo)"
                    >
                      <Icon icon-class="icon-zhinenggengxinjindu" class="icon-auto" />智能更新进度
                    </div>
                  </div>
                </div>
                <transition name="fade" mode="out-in">
                  <div
                    v-if="!processArrayState && processArray.length === 0"
                    class="no-data-show-wrap no-data-show-wrap3"
                  >
                    <no-data />
                  </div>
                </transition>
                <div v-if="processArray.length > 0" class="process_box">
                  <transition-group name="fade">
                    <process-item
                      v-for="item in processArray"
                      :key="item.id"
                      class="trend_item"
                      :process-info="item"
                      @refreshProcess="getProcessList"
                    />
                  </transition-group>
                </div>
              </div>
              <div v-if="selectTabValue === 4" class="filelist_box">
                <FileList
                  :obj-id="objId"
                  :cycle-info-id="cycleInfoId"
                  :is-dialog="false"
                  :is-sharer="isSharer"
                  :disabled="
                    userInfo.id === objectiveObj.createUserid ? false : true
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </transition>
    </el-dialog>
    <task-detail
      v-model="detailVisible"
      :type="2"
      :task-id="taskId"
      notice-type="okrDetail"
      :emit-state="true"
      @todoSubTask="emitTaskTodoSubTask"
    />
    <!-- 子任务详情 -->
    <sub-task-detail
      v-model="subdetailVisible"
      :append-to-body="true"
      :type="2"
      :task-id="subTaskId"
      :sub-object-father-id="subObjectFatherId"
      notice-type="okrDetail"
    />
    <!-- ---update -->
    <copy-objective
      v-if="copyObjectiveVisible"
      v-model="copyObjectiveVisible"
      :data="objectiveObj"
      @startLoading="startLoading($event, '目标复制')"
    />
    <move-objective
      v-if="moveObjectiveVisible"
      v-model="moveObjectiveVisible"
      :data="objectiveObj"
      @startLoading="startLoading($event, '目标移动')"
    />
    <make-over-objective
      v-if="makeOverObjectiveVisible"
      v-model="makeOverObjectiveVisible"
      :data="objectiveObj"
      @refreshData="closeTip"
      @startLoading="startLoading($event, '转让目标')"
    />
    <process-dialog
      v-if="copyMoveloading"
      v-model="copyMoveloading"
      :dialog-txt="loadingTxt"
      :loading-param="loadingParam"
      @toFinishPage="toFinishPage"
      @close="closeTip"
    />
    <obj-permission
      v-if="closePermissionsStatus"
      v-model="closePermissionsStatus"
      :okr-id="objectiveObj.id"
      @refresh="queryObjectiveDetail(objectiveObj.id)"
    />
    <keyResultsAutoProcess
      v-if="keyResultsAutoProcessVisible"
      v-model="keyResultsAutoProcessVisible"
      :okr-data="objectiveObj"
      :item="autoProcessKey"
      @refresh="queryObjectiveDetail(objectiveObj.id)"
    />
    <!-- 目标共享列表 -->
    <OkrShare
      v-model="isShowShare"
      :obj-id="objectiveObj.id"
      :disabled="userInfo.id === objectiveObj.createUserid ? false : true"
      @refresh="queryObjectiveDetail(objectiveObj.id)"
    />
    <kpiIndex
      v-if="kpiIndexVisible"
      v-model="kpiIndexVisible"
      :okrId="objectiveObj.id"
      @refresh="queryObjectiveDetail(objectiveObj.id)"
    />
    <!-- ---update -->
  </div>
</template>
<script>
import { mapState } from 'vuex'
import topMembers from '../../components/singleOkrItem/topMembers.vue'
import okrDetailPath from './components/okrDetailPath.vue'
import detailTabs from './components/detailTabs.vue'
import topProgress from '../../components/singleOkrItem/topProgress.vue'
import confidenceSelect from '../../components/singleOkrItem/confidenceSelect.vue'
import keyResults from '../../components/singleOkrItem/keyResults.vue'
import keyResultsAdd from '../../components/singleOkrItem/keyResultsAdd.vue'
import keyResultsList from '../../components/singleOkrItem/keyResultsList.vue'
import keyResultsAlert from '../../components/singleOkrItem/keyResultsAlert.vue'
import taskItem from '../../components/singleOkrItem/TaskItem.vue'
import trendItem from '../../components/singleOkrItem/TrendItem.vue'
import processItem from '../../components/singleOkrItem/ProcessItem.vue'
import addTargetTask from './components/addTargetTask.vue'
import taskEnum from '@/utils/enums/task.js' // 任务字典
import noData from '../../components/noData.vue'
import Animocon from '@/utils/animocon/index.js' // 点赞
import viewTarget from '@/views/okr/components/singleOkrItem/viewTarget.vue' // 谁看过我
import CommentBox from '@/components/common/CommentBox.vue' // 回复评论框
import copyObjective from '@/views/okr/components/CopyObjective' // 复制目标
import moveObjective from '@/views/okr/components/MoveObjective' // 移动目标
import makeOverObjective from '@/views/okr/components/MakeOverObjective' // 转让目标
import processDialog from '@/components/global/ProcessDialog' // 复制弹窗
import attachProject from '../../components/AttachProject' // 关联项目
import FileList from '../../components/fileList.vue' // 关联项目

import draggable from 'vuedraggable'

import db from '@/utils/info-center/database'
import redpointFun from '@/utils/info-center'
import objPermission from '@/views/okr/dialog/okrPermission'
import keyResultsAutoProcess from '@/views/okr/components/singleOkrItem/keyResultsAutoProcess'
import autoProgressPopver from '@/views/okr/components/singleOkrItem/autoProgressPopver'
import OkrShare from '@/views/okr/components/OkrShare'
import approveTypeLabel from '@/views/okr/components/approveTypeLabel'
import kpiIndex from '@/views/okr/components/kpiIndex'
export default {
  filters: {
    numberHandle(num) {
      if (Number(num) > 99) {
        return '99+'
      }
      return num
    }
  },
  components: {
    topMembers,
    okrDetailPath,
    confidenceSelect,
    detailTabs,
    topProgress,
    keyResults,
    keyResultsAdd,
    keyResultsList,
    keyResultsAlert,
    noData,
    taskItem,
    trendItem,
    processItem,
    addTargetTask,
    viewTarget,
    CommentBox,
    copyObjective,
    moveObjective,
    makeOverObjective,
    processDialog,
    attachProject,
    draggable,
    objPermission,
    FileList,
    keyResultsAutoProcess,
    autoProgressPopver,
    OkrShare,
    approveTypeLabel,
    kpiIndex
  },
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  props: {
    dialogShow: {
      type: Boolean,
      default: true
    },
    objId: {
      type: String
    }
  },
  props: {
    dialogShow: {
      type: Boolean,
      default: true
    },
    objId: {
      type: String
    }
  },
  data() {
    return {
      kpiIndexVisible: false,
      keyResultsAutoProcessVisible: false,
      cycleInfoId: '',
      copyObjectiveVisible: false,
      moveObjectiveVisible: false,
      makeOverObjectiveVisible: false,
      loadingTxt: '',
      copyMoveloading: false, // 复制/移动后加载
      loadingParam: {},
      detailVisible: false, // 任务详情显示
      subdetailVisible: false, // 子任务详情页展示
      loading: true,
      dialogVisible: this.dialogShow,
      detailsId: '', // 目标详情ID
      objectiveObj: {
        createUserid: '',
        keyresultsList: []
      }, // 目标详情信息
      keyResultsList: [], // 关键结果列表
      objPathData: [], // 目标查看路径
      showCycleTitle: '', // 目标周期名称
      cycleList: [], // 周期列表
      selectTabValue: 1, // 选中的tab页
      objDeleteState: false, // 目标是否已删除

      leftAddkeyState: true, // 关键结果新增状态
      currMoveItem: null, // 拖拽保存当前数据
      currMoveIndex: null, // 移动保存当前数据
      selectKrValue: '', // 当前选中的kr
      draggableStatus: true, // 是否能够拖拽
      confidenceValue: '', // 信心值
      targetIsPublic: true, // 目标是否公开
      topMembersSelectBeans: [],
      selectKrArray: [], // KR list
      krObjOption: {}, // KR对象
      createTimeOptionValue: 6, // 创建时间
      createTimeOption: taskEnum.TIMESTATETYPE, // 时间段字典
      stateOptionValue: '', // 状态
      stateOption: [
        {
          value: '',
          label: '所有状态'
        },
        {
          value: '1',
          label: '非常紧急'
        },
        {
          value: '2',
          label: '紧急'
        },
        {
          value: '3',
          label: '普通'
        }
      ], // 状态字典
      roleTypeOptionValue: '', // 角色
      roleTypeOption: [
        {
          value: '',
          label: '所有角色'
        },
        {
          value: '0',
          label: '我负责的'
        },
        {
          value: '1',
          label: '我委派的'
        },
        {
          value: '2',
          label: '我协作的'
        }
      ], // 角色字典

      taskArray: [], // 任务列表
      taskArrayState: false,
      taskSize: 15, // 任务一页展示条数
      taskTotal: 0, // 任务总数
      taskPage: 1, // 任务页数

      trendOptionValue: 2, // 动态筛选值
      trendOption: {
        0: '所有动态',
        2: '仅评论'
        //   3: '仅附件',
      }, // 动态筛选项
      oldVisible: false, // 是否显示较早动态
      trendArray: [], // 动态列表
      trendArrayState: true,
      trendSize: 10, // 动态一页展示条数
      trendPage: 1, // 动态页数
      trendTotal: 0, // 动态总数

      krOptionValue: '', // kr筛选值
      selectKRInfo: {}, // 选中的kr数据

      processArray: [], // 进度列表
      processArrayState: true,
      selectKrOptionValue: '',
      selectOptionKrArray: [],
      addTargetViewState: false, // 新建任务状态

      blurComment: false, // 评论框是否有焦点
      commentRedpoints: [], // 评论红点数据
      commentRedpointObj: {}, // 评论红点数据
      likeRedpoints: [], // 点赞红点数据
      likeLoading: false,
      labelTypeObj: {
        400: '个人',
        200: '部门',
        100: '公司',
        300: '团队'
      },
      writeRules: [],
      closePermissionsStatus: false,
      tableHeight: '',
      dialogFileVisible: false,
      dialogPreviewVisible: false,
      isShowShare: false,
      isSharer: false, // 是否是共享人
      mapRefresh: false, // 新版地图是否需要刷新
      mapRefreshData: {} // 新版地图传递的数据
    }
  },
  computed: {
    ...mapState('task', {
      taskTodoOrKrDict: 'taskTodoOrKrDict' // 任务字典
    }),
    menuItems() {
      let publicItem = {
        icon: 'icon-simi',
        title: '目标权限',
        func: 'public'
      }
      if (
        this.objectiveObj.objectivesPower &&
        this.objectiveObj.objectivesPower.lookRulesType === 0
      ) {
        publicItem = {
          icon: 'icon-public',
          title: '目标权限',
          func: 'public'
        }
      }
      return [
        {
          icon: 'icon-gongxiang',
          title: '目标共享',
          func: 'share'
        },
        publicItem,
        {
          icon: 'icon-fuzhi',
          title: '复制目标',
          func: 'copy'
        },
        {
          icon: 'icon-yidong',
          title: '移动目标',
          func: 'move'
        },
        {
          icon: 'icon-yidong',
          title: '转让目标',
          func: 'makeOver'
        },
        {
          icon: 'icon-shanchu-xian',
          title: '删除目标',
          func: 'delete',
          color: '#FF572B'
        }
      ]
    },
    showCommentRedpoint() {
      // 评论红点
      const state = this.commentRedpoints.some((item) => {
        if (item.busicId === this.objectiveObj.id) {
          this.commentRedpointObj = item
          return true
        }
        return false
      })
      if (!state) {
        this.commentRedpointObj = {}
      }
      return state
    },
    showLikeRedpoint() {
      // 点赞红点
      const state = this.likeRedpoints.some((item) => {
        if (item.busicId === this.objectiveObj.id) {
          if (!this.likeLoading) {
            this.likeLoading = true
            setTimeout(() => {
              this.likeLoading = false
              redpointFun.removePoint(item.behaviorType, item.busicId, this)
            }, 5000)
          }
          return true
        }
        return false
      })
      return state
    }
  },
  watch: {
    dialogShow: {
      handler(val) {
        this.dialogVisible = val
      }
    }
  },
  provide() {
    return {
      writeRules: () => this.writeRules
    }
  },
  created() {
    this.$nextTick(() => {
      if (this.$refs.multipleTable) {
        this.tableHeight = this.$refs.multipleTable.offsetHeight - 25 + 'px'
      }
    })
    this.queryCycleList()
    // this.getHomeGiveAnimocon(this.objectiveObj.isLaud !== 0)
    // 监听任务刷新事件
    this.bus(this).$on('taskNeedRefresh', () => {
      this.taskArrayState = true
      this.getTargetTaskList()
      this.queryObjectiveDetail(this.objectiveObj.id)
    })
    // 监听由主任务详情到子任务详情
    this.bus(this).$on('global:todoSubTask', (item) => {
      if (item.noticeType === 'okrDetail') {
        this.TaskTodoSubTask(item)
        if (item.fatherTid === -1) {
          this.subObjectFatherId = -1
        }
      }
    })
    // 监听由子任务详情到主任务详情
    this.bus(this).$on('global:taskMainDetail', (item, type) => {
      if (item.noticeType === 'okrDetail' || type === 'okrDetail') {
        if (item === -1) {
          this.subdetailVisible = false
          this.subObjectFatherId = ''
          this.taskArrayState = true
          this.getTargetTaskList()
        } else {
          this.TaskTodomainTask(item)
        }
      }
    })
    // 子任务关闭任务刷新
    this.bus(this).$on('global:subContenttaskRefresh', () => {
      this.subdetailVisible = false
    })
    // 监听目标详情目标面包屑
    this.bus(this).$on('topMembersSelectBeans', (magDate) => {
      this.objPathData = magDate
      this.objDataHandle(magDate[magDate.length - 1])
    })

    // 评论
    this.$nextTick(() => {
      db.read('2002', (res) => {
        this.commentRedpoints = res
      })
    })
    // 点赞
    this.$nextTick(() => {
      db.read('2001', (res) => {
        this.likeRedpoints = res
      })
    })
    // 监听红点数据-评论
    this.bus(this).$on('okrCommentRedpoint', (list) => {
      this.commentRedpoints = list
    })
    // 监听红点数据-点赞
    this.bus(this).$on('okrLikeRedPoint', (list) => {
      this.likeRedpoints = list
    })
    const rules = this.$store.state.okr.okrWriteRules
    if (!rules || rules.length === 0) {
      this.queryWriteRules()
    } else {
      this.writeRules = rules
    }
    // 监听审批提交
    this.bus(this).$on('approveRemindRefresh', (data) => {
      this.queryObjectiveDetail(this.objectiveObj.id, '', 'refresh')
    })
  },
  mounted() {
    const that = this
    window.onresize = () => {
      return (() => {
        var heightStyle = ''
        if (that.$refs.multipleTable) {
          heightStyle = that.$refs.multipleTable.offsetHeight - 25 + 'px'
        }
        that.tableHeight = heightStyle
      })()
    }
    this.$mixpanelUtil.track('View OKRDetails')
  },
  methods: {
    mapRefreshState(state, data) {
      this.mapRefresh = state
      this.mapRefreshData = JSON.parse(JSON.stringify(data))
    },
    emitTaskTodoSubTask(item) {
      this.TaskTodoSubTask(item)
      if (item.fatherTid === -1) {
        this.subObjectFatherId = -1
      }
    },
    objContentScroll() {
      this.bus(this).$emit('alignMemberCardHide')
    },
    getTopMembersAlignmentSelect(data) {
      this.$refs.topMember.getTopMembersAlignmentSelect(data)
    },
    getTopMembersFatherList(data) {
      this.$refs.topMember.getTopMembersFatherList(data)
    },
    // 显示共享目标列表
    setOkrShare() {
      this.isShowShare = true
    },
    showShareList() {
      this.isShowShare = true
    },
    keyResultsAutoClick(item) {
      this.autoProcessKey = item
      this.keyResultsAutoProcessVisible = true
    },
    closePreview() {
      this.isShowPreview = false
    },
    trendOptionPermission() {
      if (this.objectiveObj.isVisible === 1) {
        return true
      }
      var isVisible = false
      this.objectiveObj.keyresultsList.forEach((item) => {
        if (item.isVisible === 1 && !isVisible) {
          isVisible = true
        }
      })
      return isVisible
    },
    closePop() {
      if (this.$refs.okrDetailMenu) {
        this.$refs.okrDetailMenu.closePopView()
      }
    },
    // 获取填写建议字段
    queryWriteRules() {
      const params = {
        businessType: -1
      }
      this.$apis.OKRWRITERULES(params).then((res) => {
        if (res && res.status === 200) {
          this.writeRules = res.data
          this.$store.commit('okr/okrWriteRules', this.writeRules)
        }
      })
    },
    addTargetViewStateChange(type) {
      this.addTargetViewState = type
    },
    // 打开子任务抽屉
    TaskTodoSubTask(item) {
      this.subTaskId = item.id
      this.subObjectFatherId = item.fatherTid
      this.subdetailVisible = true
      setTimeout(() => {
        this.detailVisible = false // 任务详情显示
      }, 200)
    },
    // 主任务
    TaskTodomainTask(item) {
      this.taskId = item
      this.detailVisible = true // 任务详情显示
      setTimeout(function () {
        this.subdetailVisible = false // 任务详情显示
      }, 200)
    },
    // 获取周期列表
    async queryCycleList(id) {
      await this.$apis.CYCLIST().then((rest) => {
        if (rest.data.length > 0) {
          ;(rest.data || []).forEach((list) => {
            ;(list.cycleInfoList || []).forEach((item) => {
              if ((list.type === 1 || list.type === 2) && item.type === 0) {
                item.name = `${item.startDate.substring(0, 4)}年 ${item.name}`
              }
            })
          })
          this.cycleList = rest.data
          this.queryObjectiveDetail(this.objId, 1)
        }
      })
    },
    backData(val) {
      this.$emit('backData', val)
    },
    // 获取周期名称
    cycleTitle(id) {
      this.cycleList.some((itemX) => {
        return itemX.cycleInfoList.some((itemY) => {
          if (itemY.id === id) {
            this.showCycleTitle = itemY.name
            return true
          }
        })
      })
    },
    // 提交查看信息
    async viewInfoSubmit(objInfo, view) {
      this.$apis
        .OKRVIEWRECORDSAVE({
          cycleInfoId: objInfo.cycleInfoId,
          objId: objInfo.id
        })
        .then((rest) => {
          this.queryObjectiveDetail(objInfo.id, 2)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 获取目标详情
    queryObjectiveDetail(objId, type, refresh) {
      this.$apis
        .OKROBJINFO(objId)
        .then((rest) => {
          if (refresh === 'refresh') {
            this.mapRefreshState(true, rest.data)
          }
          this.$emit('backData', rest.data)
          this.cycleInfoId = rest.data.cycleInfoId
          this.loading = false
          if (rest.status === 62001) {
            this.$nextTick(() => {
              this.objDeleteState = true
            })
            this.$message.error(rest.message)
            return
          }
          if (type === 2) {
            this.objectiveObj = rest.data || {}
          } else {
            this.objDataHandle(rest.data)
          }
          // 默认存储头部面包屑
          if (type === 1) {
            const data = this.objectiveObj
            data.content = this.$utils.changeToHtml(this.objectiveObj.content)
          }
          if (rest.data.objectivesSharedUserList.length > 0) {
            this.isSharer = rest.data.objectivesSharedUserList.some(
              (item) => item.user.id === this.userInfo.id
            )
          }
        })
        .catch((err) => {
          this.loading = false
          this.$nextTick(() => {
            this.objDeleteState = true
          })
          this.$message.error('目标已被删除或不存在')
        })
    },
    objDataHandle(data) {
      this.objectiveObj = data || {}
      if (this.objectiveObj.createUserid !== this.userInfo.id) {
        this.viewInfoSubmit(this.objectiveObj)
      }
      this.detailsId = this.objectiveObj.id || ''
      if (data) {
        this.keyresultsListHandle(this.objectiveObj.keyresultsList)
        this.getTargetKRList()
        this.getTargetSelect(this.selectTabValue)
        this.bus(this).$emit('InitializeData', data)
      }
      this.cycleTitle(this.objectiveObj.cycleInfoId)
      this.getHomeGiveAnimocon(this.objectiveObj.isLaud !== 0)
    },
    // 顶部tab切换
    getTargetSelect(val) {
      this.selectTabValue = val
      if (val === 1) {
        this.taskArrayState = true
        this.getTargetTaskList()
      }
      if (val === 2) {
        this.getTrendList()
      }
      if (val === 3) {
        this.getProcessList()
      }
      if (val === 4) {
      }
    },
    // 关键结果处理
    keyresultsListHandle(data) {
      this.keyResultsList = []
      data.forEach((item) => {
        const obj = Object.assign(item, {
          canBeScored: this.objectiveObj.canBeScored,
          cycleInfoId: this.objectiveObj.cycleInfoId,
          titleStates: true,
          weightStates: true,
          scoreStates: true,
          comment: item.title,
          comment2: item.title
        })
        this.keyResultsList.push(obj)
      })
    },
    // 返回上一级
    getTargetReturn() {
      this.objPathData.pop()
      const length = this.objPathData.length
      if (length > 0) {
        const index = length - 1
        this.queryObjectiveDetail(this.objPathData[index].id)
      } else {
        this.queryObjectiveDetail(this.objId)
      }
    },
    operateTarget(type) {
      const operateFn = {
        share: this.setOkrShare,
        public: this.publicTarget,
        copy: this.copyTarget,
        move: this.moveTarget,
        makeOver: this.makeOverTarget,
        delete: this.deleteTarget
      }
      operateFn[type]()
    },
    // 删除目标
    async deleteTarget() {
      if (
        this.objectiveObj.objectivesProcessDataDto &&
        this.objectiveObj.objectivesProcessDataDto.businessType === 1
      ) {
        this.$message.warn('审批中的目标无法删除哦！')
        return
      }
      if (
        this.objectiveObj.processState === 1 &&
        this.$store.state.user.approvalCheckData.onOff === 0 &&
        this.objectiveObj.objectivesProcessDataDto.businessType !== 1 &&
        this.objectiveObj.objectivesProcessDataDto
          .businessApprovalPassHistory === 1
      ) {
        this.$store.commit('okr/approveRemindObj', {
          visible: true,
          objDetail: {
            id: this.detailsId,
            ...this.objectiveObj
          },
          businessData: this.detailsId,
          businessActionType: 3
        })
      } else {
        this.$confirm('是否确认删除该目标?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'confirmBox'
        }).then(() => {
          this.$apis
            .OKRDELETE(this.detailsId)
            .then((rest) => {
              if (rest.status === 200) {
                this.$message({
                  msg: '删除目标成功',
                  type: 'success'
                })
                this.mapRefresh = false
                this.handleClose()
                this.$emit('objDelete')
              } else {
                this.$message.error(rest.message)
              }
            })
            .catch((err) => {
              console.log(err)
            })
        })
      }
    },
    // 修改信心值
    changeConfidence(val) {
      const dataBeans = {
        id: this.objectiveObj.id,
        confidence: val
      }
      this.$apis.OKRCONFIDENCEUPDATE(dataBeans).then((res) => {
        this.$mixpanelUtil.track('Update OKR')
        this.queryObjectiveDetail(this.objectiveObj.id)
      })
    },
    // 设为公开/私密
    async publicTarget() {
      this.closePermissionsStatus = true
      // const beans = {
      //   id: this.objectiveObj.id,
      //   isShare: this.objectiveObj.isShare ? 0 : 1 // 0 公开 1 私密
      // }
      // if (
      //   this.objectiveObj.fatherObjectives === null &&
      //   this.objectiveObj.childObjList.length === 0
      // ) {
      //   this.$apis.OKRPUBLICSTATECHANGE(beans).then((res) => {
      //     this.$message({
      //       msg: this.objectiveObj.isShare ? '设置公开成功' : '设置私密成功',
      //       type: 'success'
      //     })
      //     this.queryObjectiveDetail(this.objectiveObj.id)
      //   })
      // } else {
      //   this.$message({
      //     msg: '您的目标有对齐关系，无法设为隐私',
      //     type: 'warning'
      //   })
      // }
    },
    showKpiIndex() {
      this.kpiIndexVisible = true
    },
    // 目标点赞
    async getTargetLink() {
      this.$mixpanelUtil.track('OKR Likes', {
        is_new: this.objectiveObj.isLaud === 0,
        objid: this.objectiveObj.id,
        view_type: 'map'
      })
      if (this.objectiveObj.isLaud === 1) {
        // 已经点赞
        this.$apis
          .OKRDELETELAUD(this.objectiveObj.id)
          .then((rest) => {
            this.queryObjectiveDetail(this.objectiveObj.id)
          })
          .catch((err) => {
            console.log(err)
          })
      } else if (this.objectiveObj.isLaud === 0) {
        // 未点赞
        this.$apis
          .OKRADDLAUD({
            objId: this.objectiveObj.id
          })
          .then((rest) => {
            this.queryObjectiveDetail(this.objectiveObj.id)
            this.bus(this).$emit('inquireStatus', {
              type: 1,
              name: 'OKR'
            })
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    // 点击新增关键结构按钮
    getAddkeyList(state) {
      if (state === '编辑') {
        this.leftAddkeyState = false
        for (let i = 0; i < this.keyResultsList.length; i++) {
          this.keyResultsList[i].titleStates = true
          this.keyResultsList[i].weightStates = true
          this.keyresultsList[i].scoreStates = true
        }
      } else {
        this.leftAddkeyState = true
      }
    },
    // 关键结果添加刷新状态
    getkeyResultsAddRefresh(state) {
      if (state === '刷新') {
        this.queryObjectiveDetail(this.objectiveObj.id, '', 'refresh')
        this.leftAddkeyState = true
      } else if (state === '不刷') {
        this.leftAddkeyState = true
      }
    },
    // 关闭kr编辑状态
    closeKrEditState() {
      const keyResultsList = this.keyResultsList
      this.keyResultsList = []
      keyResultsList.forEach((item) => {
        item.titleStates = true
        item.weightStates = true
        item.scoreStates = true
      })
      this.leftAddkeyState = true
      this.$nextTick(() => {
        this.keyResultsList = keyResultsList
      })
    },
    // 设置拖拽状态
    draggableSetStatus(state) {
      this.draggableStatus = state
    },

    // 处理编辑关键结果名称
    getKeyResultsListTitles(indexs) {
      if (this.userInfo.id === this.objectiveObj.createUserid) {
        const lists = this.keyResultsList
        this.closeKrEditState()
        this.draggableStatus = false
        lists[indexs].titleStates = false
        this.keyResultsList = JSON.parse(JSON.stringify(lists))
      }
    },

    // 处理关键结果内容保存展现
    changeObjectiveTitle(state) {
      if (state === '修改成功') {
        this.queryObjectiveDetail(this.detailsId, '', 'refresh')
      } else {
        this.closeKrEditState()
      }
    },

    // 处理权重方法
    getKeyResultsListWeights(index) {
      if (this.keyResultsList.length - 1 !== index) {
        if (this.userInfo.id === this.objectiveObj.createUserid) {
          const lists = this.keyResultsList
          let state = '暂无'
          for (let i = 0; i < lists.length; i++) {
            if (lists[i].titleStates === false) {
              state = '关键结果名称'
              break
            }
            if (lists[i].weightStates === false) {
              state = '关键结果权重'
              break
            }
          }
          if (state === '关键结果名称') {
            return
          }
          if (state === '关键结果权重') {
            return
          }
          this.draggableStatus = false
          lists[index].weightStates = false
          this.keyResultsList = JSON.parse(JSON.stringify(lists))
        }
      } else {
        this.$message({
          msg: '最后一个关键结果需要调整前面的进行编辑',
          type: 'warn'
        })
      }
    },

    // 处理权重上下级
    getKeyHandleChange(index) {
      const dataBeans = this.keyResultsList
      let numbers = 100
      // let sum =
      //   this.keyResultsList[index].weight +
      //   this.keyResultsList[this.keyResultsList.length - 1].weight
      for (let i = 0; i < dataBeans.length; i++) {
        if (i !== index && dataBeans.length - 1 !== i) {
          numbers -= dataBeans[i].weight
        }
      }
      const content = this.keyResultsList[index].weight
      this.keyResultsList[index].weight = content.replace(/\D/g, '')

      if (this.keyResultsList[index].weight > numbers) {
        this.$nextTick(() => {
          this.keyResultsList[index].weight = parseInt(numbers)
          this.keyResultsList[this.keyResultsList.length - 1].weight = 0
        })
      } else {
        this.$nextTick(() => {
          this.keyResultsList[this.keyResultsList.length - 1].weight =
            numbers - this.keyResultsList[index].weight
        })
      }
    },
    // 处理权重移除保存值
    async getKeyHandleChangeBlur(index, noChange) {
      if (noChange) {
        this.keyResultsList[index].weightStates = true
        return
      }
      const dataList = []
      if (this.keyResultsList[index].weight === '') {
        this.keyResultsList[index].weight = 0
      }
      for (let i = 0; i < this.keyResultsList.length; i++) {
        dataList.push({
          id: this.keyResultsList[i].id,
          objId: this.keyResultsList[i].objId,
          weight: this.keyResultsList[i].weight,
          progressRete: this.keyResultsList[i].progressRete
        })
      }
      this.$apis
        .OKRUPDATEWEIGHT(dataList)
        .then((rest) => {
          this.$mixpanelUtil.track('Update OKR Proportion', {
            objid: dataList[0].objId,
            view_type: 'map'
          })
          this.$message({
            msg: '修改成功',
            type: 'success'
          })
          this.bus.$emit('getKeyResultsAlertCloseBus')
          this.queryObjectiveDetail(this.detailsId)
          if (rest.status === 200) {
            if (
              localStorage.getItem('approveRemindState') !== 'true' &&
              this.objectiveObj.processState === 1 &&
              this.$store.state.user.approvalCheckData.onOff === 0
            ) {
              this.$store.commit('okr/approveRemindObj', {
                visible: true,
                objDetail: {
                  id: this.objectiveObj.id,
                  ...this.objectiveObj
                }
              })
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 评分
    showKeyResultScore(index) {
      const lists = this.keyResultsList
      this.draggableStatus = false
      lists[index].scoreStates = false
      this.$nextTick(() => {
        this.keyResultsList = JSON.parse(JSON.stringify(lists))
      })
    },
    getKeyScoreChange(index, inputVal) {
      this.keyResultsList[index].score = inputVal
    },
    // 修改KR评分
    getKeyScoreChangeBlur(index, noChange) {
      if (noChange) {
        this.keyResultsList[index].scoreStates = true
        return
      }
      const params = {
        id: this.keyResultsList[index].id,
        score: this.keyResultsList[index].score
      }
      this.$apis
        .UPDATEKRSCORE(params)
        .then((rest) => {
          this.$mixpanelUtil.track('Add OKR Score')
          this.$message({
            msg: '评分成功',
            type: 'success'
          })
          this.queryObjectiveDetail(this.detailsId)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 点击关键结果进度
    showProcessList(id) {
      this.selectTabValue = 3
      this.krOptionValue = id
      this.getProcessList()
    },
    drop() {
      if (this.userInfo.id === this.objectiveObj.createUserid) {
        this.getUpdataKeywordRank()
      }
    },

    // 关键字排序
    async getUpdataKeywordRank() {
      const beans = []
      for (let i = 0; i < this.keyResultsList.length; i++) {
        beans.push({
          id: this.keyResultsList[i].id,
          sort: i + 1
        })
      }
      this.$apis.OKRUPDATESORT(beans).then((res) => {
        if (res.status === 200) {
          this.$message({
            msg: '修改排序成功',
            type: 'success'
          })
          this.getTargetDetailsKeywordDetele()
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // kr删除触发
    getTargetDetailsKeywordDetele() {
      // this.bus.$emit('getKeyResultsAlertCloseBus')
      this.queryObjectiveDetail(this.detailsId, '', 'refresh')
    },
    // 详情页点击事件
    clickNoFocus() {
      if (this.$refs.addTask) {
        this.$refs.addTask.closeAddTaskBox()
      }
    },
    // 获取目标内KR列表
    getTargetKRList() {
      const dataBeans = this.keyResultsList
      this.selectKrArray = []
      this.krObjOption = {}
      dataBeans.forEach((item, index) => {
        this.selectKrArray.push({
          name: `KR${index + 1}进度`,
          id: item.id // kr id
        })
        this.$set(this.krObjOption, item.id, `KR${index + 1}`)
      })
      if (this.selectKrOptionValue) {
        const val = this.selectKrArray.some((item) => {
          return item.id === this.selectKrOptionValue
        })
        if (!val) {
          this.selectKrOptionValue = ''
        }
      }
      if (this.krOptionValue) {
        const nval = this.selectKrArray.some((item) => {
          return item.id === this.krOptionValue
        })
        if (!nval) {
          this.krOptionValue = ''
        }
      }
    },

    // 添加关键结果任务-提交
    async addTargetTaskRequest(formData) {
      const beans = {
        objId: this.detailsId,
        keyId: formData.selectKrArrayValue,
        fieldCategoriesId: this.taskTodoOrKrDict.OKR.id,
        endDate: formData.endTime,
        title: formData.name,
        remindDateId: formData.remindTime,
        priority: this.taskTodoOrKrDict.priorityDictionaryList[0].id,
        originatorId: this.userInfo.id,
        cycleId:
          this.objectiveObj.cycleInfoId || this.$store.state.common.periodId,
        businessType: 3,
        taskCollaboratorDtoList: formData.taskCollaboratorDtoList || []
      }
      this.$apis.TASKADDE(beans).then((res) => {
        if (res.status === 200) {
          this.$message.success('新增任务成功')
          // 数据埋点
          this.$mixpanelUtil.track('Create Task', {
            ascription: 'okr',
            is_public: 0,
            task_id: res.data.id || '',
            is_child: 0
          })
          this.taskArrayState = true
          this.getTargetTaskList()
          this.$nextTick(() => {
            this.$refs.addTask.clearData()
          })

          this.queryObjectiveDetail(this.detailsId)
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },

    // 变更评论框状态
    focusComment(type) {
      this.blurComment = type
    },

    // 点击关键结果任务
    clickSelectedKr(id) {
      this.selectKrOptionValue = id
      this.selectTabValue = 1
      this.taskArrayState = true
      this.getTargetTaskList()
    },

    // 获取目标下的任务
    getTargetTaskList(type) {
      if (!this.detailsId) {
        this.taskArray = []
        this.taskArrayState = false
        return
      }
      if (type === 'loadMore') {
        this.taskPage++
      } else {
        this.taskArrayState = true
        this.taskPage = 1
      }

      const beans = {
        keyId: this.selectKrOptionValue,
        dateSort: this.createTimeOptionValue,
        priority: this.stateOptionValue,
        roleType: this.roleTypeOptionValue,
        objId: this.detailsId,
        pageSize: this.taskSize,
        pageNum: this.taskPage
      }
      this.$apis
        .OKRQUERYTASKBYKEYIDNEWV2(beans)
        .then((rest) => {
          const dataBean = rest.data.rows
          if (type === 'loadMore') {
            this.taskArray = this.taskArray.concat(dataBean || [])
          } else {
            this.taskArray = dataBean || []
          }
          this.taskTotal = parseInt(rest.data.total)
          this.taskArrayState = false
        })
        .catch((err) => {
          this.taskArrayState = false
        })
    },

    updateKrProcess() {
      this.getProcessList()
      this.queryObjectiveDetail(this.detailsId, '', 'refresh')
    },

    // 是否展示较早动态
    showOld() {
      this.oldVisible = !this.oldVisible
      if (this.oldVisible && this.trendTotal - this.trendArray.length !== 0) {
        this.getTrendList('loadMore')
      } else {
        this.getTrendList()
      }
    },
    // 获取动态列表
    getTrendList(type) {
      if (type === 'loadMore') {
        this.trendPage++
      } else {
        this.trendPage = 1
      }
      const beans = {
        pageSize: this.trendSize,
        pageNum: this.trendPage,
        objId: this.detailsId,
        type: this.trendOptionValue
      }
      this.$apis
        .OKRDYNAMICLIST(beans)
        .then((rest) => {
          const dataBean = rest.data.rows.map((item) => {
            return Object.assign(item, {
              comment: this.$utils.changeToHtml(item.comment)
            })
          })
          if (type === 'loadMore') {
            this.trendArray = this.trendArray.concat(dataBean || [])
          } else {
            this.trendArray = dataBean
          }
          this.trendTotal = parseInt(rest.data.total)
          if (this.trendTotal - this.trendArray.length === 0) {
            this.oldVisible = true
          }
          this.trendArrayState = false
        })
        .catch((err) => {
          console.log(err)
          this.trendArrayState = false
        })
      // 如果有红点， 消除
      if (this.showCommentRedpoint) {
        redpointFun.removePoint(
          this.commentRedpointObj.behaviorType,
          this.commentRedpointObj.busicId,
          this
        )
      }
    },

    // 动态中发布评论
    async addTrendComment(comment, userId, fileList) {
      if (comment.trim() === '' && fileList.length === 0) {
        this.$message({
          msg: '内容不能为空！',
          type: 'warn'
        })
        return
      }
      let commentValue = ''
      if ((comment || '') === '' && fileList.length > 0) {
        commentValue = '查看附件'
      } else {
        commentValue = this.$utils.changeToOkrtFormat(comment)
      }
      const dataBean = {
        collaboratorStr: userId, // @人员ID
        objComment: commentValue, // 发送内容
        objId: this.detailsId, // 目标id
        type: 0,
        ossFileEntityList: fileList.length > 0 ? fileList : null
      }
      let is_to_member = false
      if (dataBean.objComment.search('@') > -1) {
        is_to_member = true
      }

      this.$mixpanelUtil.track('OKR Comment', {
        is_to_member,
        objid: this.detailsId,
        view_type: 'map'
      })
      this.$apis
        .OKRADDDYNAMICCOMMENT(dataBean)
        .then((rest) => {
          // this.$message({
          //   msg: '发布成功',
          //   type: 'success'
          // })
          if (this.$refs.trendCommentBox) {
            this.$refs.trendCommentBox.clearComment()
          }
          // this.queryObjectiveDetail(this.detailsId)
          this.getTrendList()
          this.bus.$emit('clearFile')
          this.bus(this).$emit('inquireStatus', {
            type: 1,
            name: 'OKR'
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },

    refreshTrend() {
      this.queryObjectiveDetail(this.detailsId)
      this.getTrendList()
    },

    // 获取进度列表
    async getProcessList() {
      this.processArrayState = true
      if (this.krOptionValue !== '') {
        this.selectKRInfo = this.keyResultsList.find((item) => {
          return item.id === this.krOptionValue
        })
      }
      const beans = {
        objId: this.detailsId,
        keyId: this.krOptionValue
      }
      const krObj = {}
      this.selectKrArray.forEach((item, index) => {
        krObj[item.id] = `KR${index + 1}`
      })
      this.$apis
        .OKRPROCESSLIST(beans)
        .then((rest) => {
          const dataBeans = rest.data
          this.processArray = dataBeans.map((item) => {
            item.explain = item.explain.replace(/\n/gm, '<br/>')
            item.krName = krObj[item.keyId]
            return item
          })
          this.processArrayState = false
        })
        .catch((err) => {
          console.log(err)
          this.processArrayState = false
        })
    },

    // 加载更多
    loadMore({ type }) {
      var that = this
      const methodMap = {
        task: that.getTargetTaskList,
        trendItem: that.getTrendList
      }
      let page_end = 0
      let page = 1
      let loadMoreArr = []
      let loadTotal = 0
      // 滚动时判断是否加载更多
      if (type === 'task') {
        page_end = Math.ceil(that.taskTotal / that.taskSize)
        page = that.taskPage
        loadMoreArr = that.taskArray
        loadTotal = that.taskTotal
      } else if (type === 'trendItem') {
        page_end = Math.ceil(that.trendTotal / that.trendSize)
        page = that.trendPage
        loadMoreArr = that.trendArray
        loadTotal = that.trendTotal
      }
      // 满足条件加载更多
      if (loadMoreArr.length < loadTotal && page <= page_end) {
        methodMap[type]('loadMore')
      }
    },
    loadMoreTrendArray() {
      var that = this
      let page_end = 0
      let page = 1
      let loadMoreArr = []
      let loadTotal = 0
      page_end = Math.ceil(that.trendTotal / that.trendSize)
      page = that.trendPage
      loadMoreArr = that.trendArray
      loadTotal = that.trendTotal
      if (loadMoreArr.length < loadTotal && page <= page_end) {
        that.getTrendList('loadMore')
      }
    },
    // 关闭启动状态
    handleClose() {
      this.dialogVisible = false
      this.$emit('change', false)
      this.$emit('refresh')
      if (this.mapRefresh) {
        this.$emit('mapRefresh', this.mapRefreshData)
      }
    },
    // 绑定所有的点赞状态
    getHomeGiveAnimocon(state) {
      /* eslint-disable */
      setTimeout(() => {
        const getElement = '.okrDetailId' + this.objectiveObj.id
        const el3 = document.querySelector(getElement)
        new Animocon(
          el3,
          {
            tweens: [
              // burst animation
              new mojs.Burst({
                parent: el3,
                duration: 1500,
                delay: 300,
                shape: 'circle',
                fill: [
                  '#988ADE',
                  '#DE8AA0',
                  '#8AAEDE',
                  '#8ADEAD',
                  '#DEC58A',
                  '#8AD1DE'
                ],
                x: '-31px',
                y: '36%',
                opacity: 0.6,
                radius: { 25: 40 },
                count: 6,
                isRunLess: true,
                easing: mojs.easing.bezier(0.1, 1, 0.3, 1)
              }),
              // ring animation
              new mojs.Transit({
                parent: el3,
                duration: 750,
                type: 'circle',
                radius: { 0: 25 },
                fill: 'transparent',
                stroke: '#988ADE',
                strokeWidth: { 15: 0 },
                opacity: 0.6,
                x: '-25px',
                y: '13px',
                isRunLess: true,
                easing: mojs.easing.bezier(0, 1, 0.5, 1)
              })
            ]
          },
          state
        )
      }, 1500)
    },
    copyTarget() {
      this.copyObjectiveVisible = true
    },
    moveTarget() {
      this.moveObjectiveVisible = true
    },
    makeOverTarget() {
      this.makeOverObjectiveVisible = true
    },
    startLoading(val, txt) {
      this.loadingTxt = txt
      this.copyMoveloading = true
      this.loadingParam = {
        quarterId: val[0],
        periodId: val[1]
      }
    },
    toFinishPage(loadingParam) {
      this.$router.push({ path: '/okr', query: loadingParam })
      this.handleClose()
    },
    closeTip() {
      this.handleClose()
    },
    // 修改关注
    okrCareClick() {
      var url = this.$apis.OKRCARECONFIRM
      if (this.objectiveObj.careState === 1) {
        url = this.$apis.OKRCARECANCEL
      }
      const dataBeans = {
        id: this.objectiveObj.id,
        userId: this.userInfo.id,
        businessType: 1,
        businessId: this.objectiveObj.id
      }
      url(dataBeans).then((res) => {
        this.$message.success('修改成功')
        // this.objectiveObj.careState = (this.objectiveObj.careState === 1 ? 0 : 1)
        this.queryObjectiveDetail(this.objectiveObj.id)
      })
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../assets/styles/dialog.less';
::v-deep .comment_box .comment-input-box {
  // max-height: 100px;
  // overflow-y: auto;
  .atwho-panel {
    bottom: 90px;
    top: auto !important;
    .atwho-view {
      bottom: 0px !important;
      min-height: 200px;
    }
  }
}
::v-deep .progress-auto {
  .el-progress {
    padding-right: 20px;
  }
  .process-zero-bar-outer {
    margin-right: 20px;
    width: auto;
  }
}
.top-progress-auto {
  position: absolute;
  z-index: 99;
  right: 0;
  top: 3px;
  ::v-deep span {
    width: 100%;
    display: block;
  }
  .top-progress-content {
    .flex-l();
    min-width: 18%;
    justify-content: flex-end;
  }
  .icon {
    color: #3296fa;
    cursor: pointer;
    font-size: 16px;
    width: 32px;
    height: auto;
    margin: -8px -8px 0px 0px;
  }
}
::v-deep {
  .el-dialog__wrapper {
    overflow: hidden !important;
  }
  .preview-null {
    margin: 5% auto;
    text-align: center;
    .file-svg {
      font-size: 88px;
      width: 88px;
      height: 88px;
      margin: 0 auto;
    }
    p {
      margin-top: 10px;
      font-weight: 500;
      text-align: CENTER;
      color: #6e7491;
      line-height: 24px;
      font-size: 14px;
    }
  }
  .el-dialog__title {
    display: inline-block;
    width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
  }
}
.laud-count {
  min-width: 15px;
}
.dynamic_menu {
  font-size: 14px;
  color: #6e7491;
  line-height: 20px;
  .flex-r();
  cursor: pointer;
  .trend_change_icon {
    font-size: 20px;
    margin-right: 8px;
  }
  &:hover {
    color: #0d84eb;
  }
}
.list-animocon-wrap {
  position: relative;
  /* Icons button */
  .icobutton {
    font-size: 25px;
    position: relative;
    margin: 0;
    padding: 0;
    // color: #bbc1d5;
    color: #444558;
    border: 0;
    background: none;
    overflow: visible;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    cursor: pointer;
    float: left;
    margin-right: 8px;
  }

  .icobutton .fa {
    display: block;
    padding: 0 0.1em;
  }
  .icobutton .fa_style_state {
    width: 22px;
    height: 22px;
    background: url(../../../../assets/imgs/okr/target_top_right_system1_icon2.png)
      center center no-repeat;
    background-size: 22px auto;
    &:before {
      content: '';
    }
  }
  .icobutton .fa_style_state_2 {
    width: 22px;
    height: 22px;
    background: url(../../../../assets/imgs/okr/target_list_like1.png) center
      center no-repeat;
    background-size: 22px auto;
    &:before {
      content: '';
    }
  }
  /* fix for mo.js */
  .icobutton svg {
    left: 0;
  }

  .icobutton:hover,
  .icobutton:focus {
    outline: none;
  }
}
.objective-detail {
  height: 100%;
  width: 100%;
  .flex-item {
    display: flex;
    align-items: center;
  }
  .objective-detail-header {
    display: flex;
    justify-content: space-between;
    padding: 16px 32px;
    border-bottom: 1px solid #d9dbe9;
    .cycle-info {
      .info-box {
        display: flex;
        align-items: center;
        height: 28px;
        padding: 6px 8px;
        background: rgba(50, 150, 250, 0.1);
        border-radius: 5px;
        font-size: 16px;
        font-weight: 500;
        color: #005eff;
        margin-right: 8px;
        .target-icon {
          font-size: 20px;
          margin-right: 8px;
        }
      }
      .okr-path {
        margin-left: 16px;
      }
    }
    .obj-actions {
      .action-item {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 24px;
        font-size: 18px;
        font-weight: 700;
        color: #444558;
        cursor: pointer;
        position: relative;
        .icon-kpi {
          font-size: 20px;
          margin-right: 8px;
        }
        .kr-right-icon {
          font-size: 26px;
          margin-right: 8px;
        }
        .icon-close {
          font-size: 20px;
        }
        .kr-right-avatar {
          position: absolute;
          top: -7px;
          left: 12px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
        }
        .common-red-point {
          position: absolute;
          box-sizing: border-box;
          width: 8px;
          height: 8px;
          background: #ed2e7e;
          border: 2px solid #fcfcfc;
          border-radius: 50%;
          left: 28px;
          top: 13px;
        }
        .no-avatar-point {
          width: 10px;
          height: 10px;
          left: 20px;
          top: 23px;
        }
      }
      .score-item {
        font-size: 16px;
        line-height: 25px;
        font-weight: bold;
        img {
          width: 25px;
          margin-right: 8px;
        }
      }
      .okr-care {
        margin-left: 24px;
        .icon {
          font-size: 20px;
          cursor: pointer;
          &:hover {
            color: #3a70ff;
          }
        }
      }
      .okr-care-type-1 {
        color: #ff9900;
      }
      .close-icon-box {
        width: 28px;
        height: 28px;
        border-radius: 4px;
        font-weight: 700;

        .kr-right-icon {
          margin-right: 0;
          font-size: 20px;
          font-weight: 700;
        }
        &:hover {
          background: #f5f6fa;
        }
      }
    }
  }
  .objective-detail-content {
    display: flex;
    height: calc(100% - 73px);
    .obj-content {
      flex: 1;
      border-right: 1px solid #d9dbe9;
      padding: 32px 16px;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      overflow-y: auto;
      overflow-x: hidden;
      .s-b-y();
      .top-content {
        padding: 0 16px;
        margin-left: 4px;
        .top_progress {
          height: 20px;
          margin-left: 64px;
          margin-bottom: 24px;
          width: 100%;
          max-width: 270px;
        }
        .add-keyresult {
          padding-top: 6px;
          margin-bottom: 14px;
        }
      }
      .okr-kr-list {
        margin-left: -8px;
        // overflow-y: auto;
        // flex: 1;
      }
    }
    .obj-tabs {
      width: 525px;
      .tab-item {
        height: calc(100% - 50px);
        min-height: 400px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow-y: auto;
        .top-select-options {
          display: flex;
          padding: 16px;
          align-content: center;
          justify-content: space-between;
          .top-select-right {
            display: flex;
            align-content: center;
            justify-content: flex-end;
          }
          ::v-deep {
            .el-input.is-active .el-input__inner,
            .el-input__inner:focus {
              box-shadow: unset !important;
            }
            .el-input__inner {
              border: none !important;
              text-align: right;
              background: transparent;
            }
            .el-input__icon {
              width: 20px;
            }
            .el-input--suffix .el-input__inner {
              padding-right: 25px;
            }

            .normal-select {
              .el-input__inner {
                color: #6e7491 !important;
                &:hover {
                  color: rgba(50, 150, 250, 1) !important;
                }
              }
            }

            .blod-select {
              .el-input__inner {
                font-size: 18px;
                padding-left: 0;
                color: #14142b !important;
                text-align: left;
              }
              .el-select__caret {
                color: #14142b !important;
                font-size: 18px;
                font-weight: 700;
              }
            }
          }
          .select-bar {
            width: 116px;
          }
          .select-dynamic {
            width: 100px;
          }
          .okr-process-add {
            color: #ff9900;
            cursor: pointer;
            font-size: 14px;
            font-weight: 500;
            // width: 80px;
            line-height: 38px;
            margin-left: 20px;
            &:hover {
              color: #ffcc80;
            }
          }
          .okr-process-auto {
            display: flex;
            justify-content: flex-start;
          }
        }
        .task-add-box {
          padding: 0 16px;
          display: flex;
          flex-shrink: 0;
          align-items: center;
          width: 100%;
          .bottom-attach {
            border: 1px solid #fff;

            height: 36px;
            line-height: 26px;
            padding: 4px 5px;
            border-radius: 12px;
            margin-left: 8px;
            ::v-deep {
              .attach-btn-text {
                &:hover {
                  color: #ff9900 !important;
                }
                .attach-icon {
                  display: block;
                }
                .hover-icon {
                  display: none;
                }
              }
            }
            &:hover {
              border: 1px solid #fef8ed;
              background: #fef8ed;
            }
            &:active {
              background: #fff;
              border: 1px solid #ffe9bf;
            }
          }
        }
        .task_content {
          margin-top: 16px;
          padding: 0 16px;
          flex: 1;
          overflow-y: auto;
        }
        .no-data-show-wrap {
          height: calc(100vh - 340px) !important;
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-top: -20px;
        }
        .no-data-show-wrap1 {
          // height: calc(100vh - 160px);
          min-height: 160px;
        }
        .no-data-show-wrap2 {
          height: calc(100vh - 100px);
        }
        .no-data-show-wrap3 {
          height: calc(100vh - 350px);
        }
        .no-data-show-icon {
          margin: 16px auto;
          width: 274px;
        }
        .no-data-show-text {
          text-align: center;
          color: #6e7491;
        }
        .trend_box {
          .s-b-y();
          flex: 1;
          padding: 0 16px 0 4px;
          overflow-y: auto;
          ::v-deep .trend-single-box {
            &:hover {
              background: #f5f6fa;
            }
          }
        }
        .process_box {
          .s-b-y();
          flex: 1;
          padding: 0 16px 0 8px;
          overflow-y: auto;
        }
        .filelist_box {
          flex: 1;
          position: relative;
        }
        .comment_box {
          border-top: 1px solid #d9dbe9;
          .comment-box {
            height: auto !important;
            min-height: 130px;
          }
        }
      }
    }
  }
}
.common-red-point {
  position: absolute;
  box-sizing: border-box;
  width: 8px;
  height: 8px;
  background: #ed2e7e;
  border: 2px solid #fcfcfc;
  border-radius: 50%;
  left: 23px;
  top: 2px;
}
.no-avatar-point {
  width: 10px;
  height: 10px;
  left: 15px;
  top: 12px;
}
.okr-detail-dialog {
  overflow-y: hidden;
  ::v-deep {
    .el-input__inner {
      border-radius: 8px;
    }
    .el-dialog__header {
      display: none !important;
    }
    .el-dialog__body {
      padding: 0 !important;
      height: 100%;
      margin: 0px 20px;
    }
    .el-dialog {
      width: 95%;
      max-width: 1600px;
      min-width: 1240px;
      height: calc(100vh - 90px);
      min-height: 550px;
      margin-top: 40px !important;
      background: none;
      box-shadow: none;
      .el-dialog__body {
        border-radius: 16px;
        background: #fff;
        box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
      }
    }
  }
}
</style>
