<template>
  <div>
    <div class="add_align_ul">
      <div class="add_align_ul_title clearfix" style="position: relative">
        <div
          v-if="targetDetailsAlignState"
          style="
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0px;
            top: 0px;
          "
        ></div>
        <div class="add_align_ul_title_right clearfix flex-new">
          <div class="ul_title_right_img">
            <div class="ul_title_right_img_icon"></div>
          </div>
          <div
            class="ul_title_right_text"
            :class="comment != '' ? '' : 'ul_title_right_text-empty'"
          >{{ comment }}</div>
        </div>
      </div>

      <div
        v-for="(item, index) in keyresultsList"
        :key="'key' + index"
        class="add_align_li clearfix flex-new"
      >
        <Img
          v-show="index != keyresultsList.length - 1"
          src="/common/target_add_bor_icon1.png"
          class="add_align_li_bor3"
        />
        <Img
          v-show="index != keyresultsList.length - 1"
          src="/common/target_add_bor_icon1.png"
          class="add_align_li_bor3"
        />
        <Img src="/common/target_add_bor_icon2.png" class="add_align_li_bor4" />
        <div class="add_align_li_text">KR{{ index + 1 }}</div>
        <div class="add_align_li_text1 flex-new flex-new-direction-column" style="width: 100%">
          <p
            class="add-align-text"
            :class="item.title ? '' : 'add-align-text-empty'"
          >{{ item.title }}</p>
          <p v-if="item.title_ || pageBoolean" class="add-align-text-2">{{ item.title_ }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // mixins: [formMixin],
  props: {
    keyresultsList: {
      type: Array,
      require: false
    },
    comment: {
      type: String,
      require: false,
      default: ''
    },
    pageBoolean: {
      type: Boolean,
      require: false
    }
  },
  data() {
    return {
      targetDetailsAlignState: true
    }
  },
  computed: {
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.add_align_ul {
  width: 100%;
  min-height: 60px;
  .add_align_ul_title {
    width: 100%;
    height: 60px;
    .add_align_ul_title_img {
      width: 40px;
      height: 100%;
      float: left;
      .title_img_icon {
        width: 29px;
        height: 29px;
        margin-top: 7.5px;
        border-radius: 100% 100%;
      }
    }
    .add_align_ul_title_right {
      width: 100%;
      height: 60px;
      border-radius: 3px;
      display: flex;
      flex-direction: row;
      .ul_title_right_img {
        width: 40px;
        height: 100%;
        margin-right: 6px;
        .ul_title_right_img_icon {
          width: 40px;
          height: 30px;
          background: rgba(48, 104, 255, 1);
          margin-top: 20px;
          background: url('../../assets/imgs/okr/objective.png') no-repeat left
            top;
          background-size: 100% 100%;
        }
      }
      .ul_title_right_text {
        height: 100%;
        flex: 1;
        font-size: 16px;
        font-weight: normal;
        color: #333;
        padding-top: 16px;
        padding-bottom: 13px;
        line-height: 22px;
        text-align: left;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        padding-left: 4px;
        &:before {
          content: '';
          display: block;
        }
      }
      .ul_title_right_text-empty {
        background: #f5f6fa;
        border-radius: 10px;
        padding: 0;
        height: 22px;
        margin-top: 25px;
      }
    }
  }
  .add_align_li {
    width: 100%;
    height: 50px;
    margin-left: 24px;
    position: relative;
    display: flex;
    flex-direction: row;
    .add_align_li_bor {
      height: 24px;
      width: 2px;
      position: absolute;
      left: 0px;
      top: -22px;
    }
    .add_align_li_bor1 {
      width: 20px;
      height: 1px;
      background: rgba(163, 189, 255, 1);
      border-radius: 0px 0px 0px 75px;
      position: absolute;
      top: 18px;
      left: 0px;
    }
    .add_align_li_bor2 {
      width: 30px;
      height: 30px;
      position: absolute;
      left: 0px;
      top: 0px;
    }
    .add_align_li_bor3 {
      height: 100%;
      width: 2px;
      position: absolute;
      left: 0px;
      top: 0px;
    }
    .add_align_li_text {
      width: 56px;
      height: 100%;
      line-height: 44px;
      text-align: right;
      font-size: 11px;
      font-weight: normal;
      color: rgba(163, 189, 255, 1);
      float: left;
      margin-right: 14px;
      margin-left: 12px;
    }
    .add_align_li_text1 {
      height: 100%;
      line-height: 20px;
      float: left;
      font-size: 12px;
      font-weight: normal;
      color: rgba(51, 51, 51, 1);
      padding-top: 12px;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:before {
        content: '';
        display: block;
      }
      .add-align-text {
        margin-bottom: 4px;
        color: #444558;
        font-size: 12px;
        text-indent: 6px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 400px;
        &:before {
          content: '';
          display: block;
        }
      }
      .add-align-text-2 {
        background: #f5f6fa;
        color: #6e7491;
        font-size: 12px;
        text-indent: 6px;
        border-radius: 4px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 300px;
        &:before {
          content: '';
          display: block;
        }
      }
      .add-align-text-empty {
        background: #f5f6fa;
        color: #6e7491;
        font-size: 12px;
        text-indent: 6px;
        border-radius: 4px;
        height: 24px;
        border-radius: 10px;
      }
      .colorStyle {
        color: rgba(48, 104, 255, 1);
      }
    }
  }
  .add_align_li_hover:hover {
    background: #e0e3ec;
    .add_align_li_text {
      background: #fff;
    }
  }
  .add_align_li_style {
    margin-top: 20px;
  }

  .add_align_li_bor3 {
    height: 24px;
    width: 2px;
    // background: #a3bdff;
    position: absolute;
    left: 0px;
    top: -22px;
  }
  .add_align_li_bor4 {
    width: 30px;
    height: 30px;
    position: absolute;
    left: 0px;
    top: 0px;
  }
  .add_align_li_bor3_3 {
    height: 100%;
    width: 2px;
    position: absolute;
    left: 0px;
    top: 0px;
  }
}
</style>
