import taskDict from '@/utils/enums/task' // 任务字典
const state = {
  cooperateVuexList: {
    selectProjectId: '', // 当前项目id
    selectProjectData: {
      permission: 0 // 0 成员  1: 所有者 2:管理员 3-查看者
    }, // 当前项目数据
    projectAddNullVisible: false, // 新建空白模版弹窗
    projectEditVisible: false, // 编辑项目弹框
    projectAddTemplateVisible: false, // 新建模版项目弹窗
    projectAddTemplateId: '', // 新建模版id
    projectPeopleList: [], // 项目成员
    tabSelectId: 0, // 0:概述 1:列表 2:看板
    cooperateAddInputList: {
      id: '', // 流程id或者项目id
      visible: false, // 弹窗开关
      type: 1, // 1:工作流新增 2:工作流编辑 3:项目名称编辑
      index: '', // 流程列排序值
      orderNum: '', // 排序值
      random: '',
      name: '' // 名称
    }, // 新增编辑流程存值
    selectDialogList: {
      roleOptionValue: [], // 角色
      roleOption: taskDict.COOPERATEROLETYPE,
      startTimeArithmeticOperator: 0, // 开始时间
      endTimeArithmeticOperator: 0, // 截止时间
      arithmeticOperator: taskDict.COOPERATESELECTLIST,
      checkListValue: [],
      checkOptionList: taskDict.TASKSTATUS,
      firstOptionValue: [], // 优先级
      firstOptionlist: taskDict.EMERGENCYSTATE,
      completeValue: '',
      completeRadioList: taskDict.COMPLETERADIOLIST,
      workOptionValue: '全部任务',
      hide: null,
      workOptionList: taskDict.WORKTYPE,
      boardWorkOptionList: taskDict.BOARDWORKTYPE,
      createTimerOptionValue: 6, // 创建时间
      createTimerOption: taskDict.TIMESTATETYPE, // 时间段字典
      selectMore: false // 筛选弹窗的显示
    },
    taskDetailData: {}
  },
  copyTaskSelectDialogList: {
    roleOptionValue: [], // 角色
    roleOption: taskDict.COOPERATEROLETYPE,
    startTimeArithmeticOperator: 0, // 开始时间
    endTimeArithmeticOperator: 0, // 截止时间
    arithmeticOperator: taskDict.COOPERATESELECTLIST,
    checkListValue: [],
    checkOptionList: taskDict.TASKSTATUS,
    firstOptionValue: [], // 优先级
    firstOptionlist: taskDict.EMERGENCYSTATE,
    completeValue: '',
    completeRadioList: taskDict.COMPLETERADIOLIST,
    workOptionValue: '全部任务',
    hide: null,
    workOptionList: taskDict.WORKTYPE,
    boardWorkOptionList: taskDict.BOARDWORKTYPE,
    createTimerOptionValue: 6, // 创建时间
    createTimerOption: taskDict.TIMESTATETYPE, // 时间段字典
    selectMore: false // 筛选弹窗的显示
  }
}
const actions = {}
const mutations = {
  copyTaskSelectDialogList(state, val) {
    state.copyTaskSelectDialogList = val
  },
  taskDetailData(state, val) {
    state.cooperateVuexList.taskDetailData = val
  },
  // 当前新增编辑的流程值
  cooperateAddInputList(state, val) {
    state.cooperateVuexList.cooperateAddInputList = val
  },
  // 当前tab值
  tabSelectId(state, val) {
    state.cooperateVuexList.tabSelectId = val
  },
  // 当前项目人员
  projectPeopleList(state, val) {
    state.cooperateVuexList.projectPeopleList = val
  },
  // 当前项目数据
  selectProjectData(state, val) {
    state.cooperateVuexList.selectProjectData = val
  },
  // 当前前项目id
  selectProjectId(state, val) {
    state.cooperateVuexList.selectProjectId = val
  },
  // 新建空白项目弹窗
  projectAddNullVisible(state, val) {
    state.cooperateVuexList.projectAddNullVisible = val
  },

  // 编辑项目弹窗
  projectEditVisible(state, val) {
    state.cooperateVuexList.projectEditVisible = val
  },
  // 新建模版项目弹窗
  projectAddTemplateVisible(state, val) {
    state.cooperateVuexList.projectAddTemplateVisible = val
  },
  // 新建模版项目弹窗
  projectAddTemplateId(state, val) {
    state.cooperateVuexList.projectAddTemplateId = val
  },
  // 筛选保留数据
  selectDialogList(state, val) {
    state.cooperateVuexList.selectDialogList = val
  }
}
export default {
  namespaced: true,
  state,
  actions,
  mutations
}
