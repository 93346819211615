var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
    !_vm.loading
      ? _c(
          "div",
          [
            _c(
              "div",
              {
                staticClass: "quantity-kr",
                class: _vm.direction === "col" ? "col-quantity-kr" : "",
              },
              [
                _c("div", { staticClass: "kr-unit-item" }, [
                  _c("div", { staticClass: "unit-label" }, [
                    _vm._v("量化条件"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "unit-select",
                      style: `${
                        _vm.unitForm.quantificationType === 0
                          ? "width:78px;"
                          : ""
                      }`,
                    },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.unitForm.quantificationType,
                            callback: function ($$v) {
                              _vm.$set(_vm.unitForm, "quantificationType", $$v)
                            },
                            expression: "unitForm.quantificationType",
                          },
                        },
                        _vm._l(_vm.typeItems, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: {
                              label: item.text,
                              value: item.value,
                              disabled: _vm.disabled,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.unitForm.quantificationType === 0,
                        expression: "unitForm.quantificationType === 0",
                      },
                    ],
                    staticClass: "kr-unit-item",
                  },
                  [
                    _c("div", { staticClass: "unit-label" }, [_vm._v("单位")]),
                    _c(
                      "div",
                      { staticClass: "unit-select-unit" },
                      [
                        _c(
                          "el-select",
                          {
                            on: { change: _vm.changeUnit },
                            model: {
                              value: _vm.unitSelectValue,
                              callback: function ($$v) {
                                _vm.unitSelectValue = $$v
                              },
                              expression: "unitSelectValue",
                            },
                          },
                          _vm._l(_vm.units, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: {
                                label: item.text,
                                value: item.value,
                                disabled: _vm.disabled,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.unitForm.quantificationType === 0,
                        expression: "unitForm.quantificationType === 0",
                      },
                    ],
                    staticClass: "kr-unit-item",
                  },
                  [
                    _c("div", { staticClass: "unit-label" }, [
                      _vm._v("初始值"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "unit-value" },
                      [
                        _c("input-number", {
                          attrs: {
                            point: 2,
                            disabled: _vm.disabled,
                            positiveNumber: false,
                          },
                          model: {
                            value: _vm.unitForm.quantificationStart,
                            callback: function ($$v) {
                              _vm.$set(_vm.unitForm, "quantificationStart", $$v)
                            },
                            expression: "unitForm.quantificationStart",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.unitForm.quantificationType === 0,
                        expression: "unitForm.quantificationType === 0",
                      },
                    ],
                    staticClass: "kr-unit-item",
                  },
                  [
                    _c("div", { staticClass: "unit-label" }, [
                      _vm._v("目标值"),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "unit-value",
                        staticStyle: { "margin-right": "0" },
                      },
                      [
                        _c("input-number", {
                          attrs: {
                            point: 2,
                            disabled: _vm.disabled,
                            positiveNumber: false,
                          },
                          model: {
                            value: _vm.unitForm.quantificationEnd,
                            callback: function ($$v) {
                              _vm.$set(_vm.unitForm, "quantificationEnd", $$v)
                            },
                            expression: "unitForm.quantificationEnd",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.unitForm.quantificationType === 1,
                        expression: "unitForm.quantificationType === 1",
                      },
                    ],
                    staticClass: "finish-state",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "unselect-label select-label" },
                      [
                        _c("Icon", {
                          staticClass: "select-icon",
                          attrs: { "icon-class": "icon-not-finish" },
                        }),
                        _vm._v("未完成 "),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "select-label" },
                      [
                        _c("Icon", {
                          staticClass: "select-icon",
                          attrs: { "icon-class": "icon-finished" },
                        }),
                        _vm._v("已完成 "),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "el-dialog",
              {
                staticClass: "comm-check-form",
                attrs: {
                  visible: _vm.unitDialog,
                  width: "300px",
                  "before-close": _vm.cancelEdit,
                  "append-to-body": true,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.unitDialog = $event
                  },
                },
              },
              [
                _c("div", { staticClass: "kr-unit-item unit-dialog" }, [
                  _c("div", { staticClass: "unit-label" }, [_vm._v("单位")]),
                  _c(
                    "div",
                    { staticClass: "unit-value" },
                    [
                      _c("el-input", {
                        attrs: { maxlength: 3, placeholder: "请输入" },
                        model: {
                          value: _vm.unitValue,
                          callback: function ($$v) {
                            _vm.unitValue = $$v
                          },
                          expression: "unitValue",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "unit-btns" }, [
                  _c(
                    "div",
                    {
                      staticClass: "bottoms-comm-dialog-btns cancel-btn",
                      on: { click: _vm.cancelEdit },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "bottoms-comm-dialog-btns save-btn",
                      on: { click: _vm.saveUnit },
                    },
                    [_vm._v("保存")]
                  ),
                ]),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }