<template>
  <!-- 企信pc迁移后新增 -->
  <div>
    <el-dialog
      title="新建目标"
      :visible.sync="dialogVisible"
      width="880px"
      :append-to-body="true"
      :before-close="handleClose"
    >
      <div class="add-target-dialog">
        <div>
          <add-step :steps="steps" :active-step="activeStep" />
          <div v-if="targetDetailsAlignState && activeStep !== 3" class="align-obj">
            <objective-show
              :align-hide="addType === '2' ? true : false"
              :obj-data="alignObjectiveData"
              :alignType="alignType"
              type="align"
            />
          </div>
          <div v-show="activeStep === 1">
            <div class="objective-add-input">
              <div class="input-box">
                <AdviceInput
                  v-model="titlePopShow"
                  :html="objectiveData.content"
                  :type="1"
                  :ignore-rule-id-list="objectiveData.ignoreRuleIdList"
                  @addIgnore="addIgnoreRule"
                >
                  <at-input
                    v-if="activeStep === 1"
                    ref="titleInpurRef"
                    :html="objectiveData.content"
                    :enter-able="true"
                    :isBgColor="false"
                    :atstate="true"
                    limit="500"
                    :quarter-id="quarterId"
                    :placeholder="objPlaceholder"
                    @enter="getTargetAddNext"
                    @input="getTargetAddComment"
                  />
                </AdviceInput>
              </div>
              <div class="bottom-actions">
                <at-user-dialog :quarter-id="quarterId" @selectPerson="atPerson">
                  <div class="example-text">@ 邀请对齐</div>
                </at-user-dialog>
                <div class="example-text" @click="showExampleList">OKR示例</div>
              </div>
            </div>
            <div class="cycle-text">
              <div v-if="addType !== '2'" class="cycle-info-box">
                所属周期
                <div class="cycle-box">{{ periodTextContent }}</div>
              </div>
            </div>
          </div>
          <div v-show="activeStep === 2" class="add-key-result">
            <objective-show
              :obj-data="objectiveData"
              :edit-state="true"
              :align-hide="addType === '2' ? true : false"
              :step="activeStep"
              @keyResultChange="keyResultChange"
              @deleteKr="deleteKr"
              @ignorekr="ignorekr"
              @addIgnoreRule="addIgnoreRule"
            />
          </div>
          <div v-show="activeStep === 3" class="add-key-result">
            <objective-show
              :align-obj-data="alignObjectiveData"
              :obj-data="objectiveData"
              :align-hide="addType === '2' ? true : false"
              :align-state="!targetDetailsAlignState && addType != 2"
              :step="activeStep"
              @changeKrWeight="changeKrWeight"
              @changeAlignData="changeAlignData"
              @ignorekr="ignorekr"
              @addIgnoreRule="addIgnoreRule"
            />
          </div>
          <div class="bottom-content">
            <div>
              <div v-if="activeStep === 1" class="look-add-wrap">
                <span class="look-add-okr" type="text" @click="openVideo">
                  <Icon icon-class="icon-Video" class="add-view-img" />30秒了解如何添加OKR
                </span>
                <span class="look-add-okr look-add-school" type="text" @click="openSchool">
                  <Icon icon-class="icon-xunlianying" class="add-view-img" />OKR线下训练营
                  <Icon icon-class="icon-HOT" class="add-view-img icon-hot" />
                </span>
              </div>
              <div v-show="activeStep === 2" class="kr-tips-list">
                <div class="kr-tips">
                  <div class="dot red-dot"></div>至少一个关键结果
                </div>
                <div class="kr-tips">
                  <div class="dot"></div>不要超过5个关键结果
                </div>
                <div class="kr-tips">
                  <div class="dot"></div>通过指标量化关键结果
                </div>
              </div>
              <div class="okr-type-wrap" v-if="activeStep === 3">
                目标类型:
                <label-select v-model="labelType" class="label-select" />
              </div>
              <div class="approve-detail-wrap" v-if="approveDetailVisible">
                <img src="@/assets/imgs/meeting/addTargetInfo.png" class="info-icon" />
                <div class="descripe">OKR 已开启审批，请制定完成后提交给上级或主管进行审批</div>
                <div class="user" v-if="approveDetailData && approveDetailData.appointUserName">
                  审批人：
                  <wx-open-data
                    :id="approveDetailData.appointUserName"
                    :nameData="{name:approveDetailData.appointUserName}"
                  />
                </div>
              </div>
            </div>
            <div class="bottom-step-buttons medium-btns">
              <el-button class="left-button" @click="handleClose">取消</el-button>
              <el-button v-show="activeStep !== 1" class="left-button" @click="prevStep">上一步</el-button>
              <el-button v-show="activeStep !== 3" @click="nextStep">下一步</el-button>
              <L-button
                v-show="activeStep === 3"
                :loading="addloading"
                @clickEvt="finishAdd"
                style="margin-left: 8px"
              >完成</L-button>
            </div>
          </div>
        </div>
      </div>
      <example-list
        v-if="exampleListVisible"
        v-model="exampleListVisible"
        @getOkrFlashListSubmit="getOkrFlashListSubmit"
      />
      <look-add-okr :video-dialog-show="videoDialogShow" @closeVideoDialog="closeVideoDialog" />
    </el-dialog>
  </div>
</template>
<script>
import addStep from '../../components/addStep.vue'
import exampleList from '../exampeList/exampleList.vue'
import objectiveShow from '../../components/objectiveShow.vue'
import atUserDialog from '../../components/singleOkrItem/atUserDialog.vue'
import lookAddOkr from '../lookAddOkr/lookAddOkr.vue'
import labelSelect from '../../components/LabelTypeSelect'
import AdviceInput from '../../components/AdviceInput'
const targetAddTitle = require('./addTargetTitle.json')
export default {
  components: {
    addStep,
    atUserDialog,
    exampleList,
    objectiveShow,
    lookAddOkr,
    labelSelect,
    AdviceInput
  },
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  props: {
    dialogShow: {
      type: Boolean,
      default: false
    },
    targetDetailsAlign: {
      type: Object,
      default: () => {
        return {}
      }
    },
    addType: {
      // 1.周期内添加 2.会议内添加
      type: String,
      default: '1'
    },
    quarterId: {
      // 1.周期id
      type: Number
    }
  },
  data() {
    return {
      addloading: false,
      videoDialogShow: false,
      dialogVisible: this.dialogShow,
      exampleListVisible: false, // okr示例列表弹窗
      sliderModuleState: true,
      activeStep: 1,
      steps: [
        { name: '设定目标', id: 1 },
        { name: '添加关键结果', id: 2 },
        { name: '预览目标', id: 3 }
      ],
      objectiveData: {
        atIds: '',
        content: '', // 目标标题
        keyresultsList: [],
        ignoreRuleIdList: [], // 已忽略的规则id
        publicState: true // 是否公开
      },
      titlePopShow: false,
      alignObjectiveData: {}, // 对齐的目标信息
      objPlaceholder: '',
      krPlaceHolder: '',
      targetDetailsAlignState: false, // 是否是添加对齐目标
      targetAligningList: [], // 添加时对齐的目标
      periodTextContent: localStorage.getItem('periodText'),

      labelType: 400, // 标签类型
      alignType: 'O',
      labelTypeName: {
        400: '个人',
        200: '部门',
        100: '公司',
        300: '团队'
      },
      approveDetailVisible: false,
      approveDetailData: ''
    }
  },
  watch: {
    dialogShow: {
      handler(val) {
        this.dialogVisible = val
      }
    }
  },
  created() {
    if (this.targetDetailsAlign.objId !== undefined) {
      this.getTargetDetailsAlign()
    }
    this.initAdd()
    if (this.addType === '1') {
      this.$mixpanelUtil.track('View AddOKR')
    }
    this.getApprovalCheck()
  },
  methods: {
    getApprovalCheck() {
      this.approveDetailData = this.$store.state.user.approvalCheckData
      if (this.approveDetailData.onOff === 0) {
        this.approveDetailVisible = true
      } else {
        this.approveDetailVisible = false
      }
    },
    // kr忽略规则
    ignorekr(id, index) {
      const list =
        this.objectiveData.keyresultsList[index].ignoreRuleIdList || []
      list.push(id)
      this.$set(
        this.objectiveData.keyresultsList[index],
        'ignoreRuleIdList',
        list
      )
    },
    // 忽略规则
    addIgnoreRule(id) {
      this.objectiveData.ignoreRuleIdList.push(id)
    },
    //  v2.1.5 0815 修改
    openVideo() {
      this.videoDialogShow = true
      this.$mixpanelUtil.track('View AddOKR Video')
    },
    openSchool() {
      var url = `https://app.okrt.com/helpH5/publicclass`
      if (this.$isDD) {
        url = `https://page.dingtalk.com/wow/dingtalk/act/serviceconversation?ddtab=true&wh_biz=tm&showmenu=false&goodsCode=DT_GOODS_881655784491241&corpId=${this.commonCorpId}&token=199f876c080bd26313560b8515f10454`
      }
      if (this.$isQw || this.$isDD) {
        window.open(url, '_blank')
      } else {
        openSysBrowser(url)
      }
    },
    // v2.1.5 0815 修改
    closeVideoDialog() {
      this.videoDialogShow = false
    },
    initAdd() {
      const index = this.getRandNum(10)
      this.objPlaceholder = targetAddTitle.objects[index] + ' ↵'
    },
    getTargetAddComment(data) {
      this.objectiveData.content = data.html
      this.objectiveData.atIds = data.atIds.toString()
    },
    // 添加数据
    getTargetAddNext(data) {
      if (this.activeStep === 1) {
        // 添加目标
        this.objectiveData.content = data.html
        this.objectiveData.atIds = data.atIds.toString()
        if ((this.objectiveData.objectivesPower || '') === '') {
          this.objectiveData.objectivesPower = {
            businessType: 1,
            lookRulesType: 0,
            objectivesPowerCustomizeList: []
          }
        }
      }
      this.titlePopShow = false
      this.addStep()
    },
    // 添加@人
    atPerson(addHtml, userInfo) {
      this.$refs.titleInpurRef.addAtMember(addHtml)
    },
    // 关键结果修改
    keyResultChange(data, index) {
      const keyResultData = Object.assign(data.unitForm, {
        progressRete: 0, // 进度比例
        weight:
          index === 'add' ? 0 : this.objectiveData.keyresultsList[index].weight,
        comment: data.obj.html,
        title: data.obj.html,
        titleSearch: this.$utils.tagFilter(data.obj.html),
        collaboratorStr: data.obj.atIds.toString(),
        ignoreRuleIdList: data.ignoreRuleIdList,
        objectivesPower: {
          businessType: 2,
          lookRulesType: 0,
          objectivesPowerCustomizeList: []
        }
      })
      if (index === 'add') {
        // 新增
        this.objectiveData.keyresultsList.push(keyResultData)
        this.weightHandle()
      } else {
        this.$set(this.objectiveData.keyresultsList, index, keyResultData)
      }
    },
    weightHandle() {
      const length = this.objectiveData.keyresultsList.length
      const weight = parseInt(100 / length)
      this.objectiveData.keyresultsList.forEach((item, index) => {
        if (index === length - 1) {
          item.weight = 100 - weight * (length - 1)
        } else {
          item.weight = weight
        }
      })
    },
    // 关键结果删除
    async deleteKr(index) {
      const status = await this.$element.confirm(`是否删除关键结果？`)
      if (status) {
        this.objectiveData.keyresultsList.splice(index, 1)
        this.weightHandle()
      }
    },
    // 修改关键结果权重
    changeKrWeight(data, index) {
      if (!data) {
        data = 0
        this.objectiveData.keyresultsList[index].weight = 0
      }
      let numbers = 100
      const dataBeans = this.objectiveData.keyresultsList
      for (let i = 0; i < dataBeans.length; i++) {
        if (i !== index && dataBeans.length - 1 !== i) {
          numbers -= dataBeans[i].weight
        }
      }
      if (data > numbers) {
        this.$nextTick(() => {
          this.objectiveData.keyresultsList[index].weight = parseInt(numbers)
          this.objectiveData.keyresultsList[dataBeans.length - 1].weight = 0
        })
      } else {
        this.$nextTick(() => {
          this.objectiveData.keyresultsList[dataBeans.length - 1].weight =
            numbers - this.objectiveData.keyresultsList[index].weight
        })
      }
    },
    // 获取随机数
    getRandNum(n) {
      return Math.floor(Math.random() * n + 1)
    },
    // 修改对齐
    changeAlignData(data) {
      this.targetAligningList = data
    },
    // 获取邀请对齐数据
    async getTargetDetailsAlign() {
      this.$apis.OKRALIGNOBJINFO({
        businessId: this.targetDetailsAlign.businessId,
        type: this.targetDetailsAlign.type
      }).then((rest) => {
        if (rest.status === 200) {
          if (rest.data.type === 0) {
            this.alignType = 'O'
          } else {
            this.alignType = 'KR'
          }
          const objInfo = rest.data
          objInfo.objectivesDto.typeIsVisible = objInfo.objectivesDto.isVisible
          objInfo.objectivesDto.businessId = rest.data.businessId
          objInfo.objectivesDto.content = this.$utils.changeToHtml(
            objInfo.objectivesDto.content
          )
          objInfo.objectivesDto.fatherType = this.alignType
          objInfo.objectivesDto.keyresultsList.forEach((item) => {
            if (item.id === rest.data.businessId) {
              item.alignState = true
              objInfo.objectivesDto.title = this.$utils.changeToHtml(item.title)
              objInfo.objectivesDto.typeIsVisible = item.isVisible
            }
            item.title = this.$utils.changeToHtml(item.title)
          })
          this.targetDetailsAlignState = true
          this.alignObjectiveData = Object.assign(objInfo.objectivesDto, {
            keyresultsList: objInfo.objectivesDto.keyresultsList
          })
        } else {
          this.$message.error(rest.message)
        }
      })
    },
    // 打开示例列表
    showExampleList() {
      this.exampleListVisible = true
    },
    // 使用示例数据
    getOkrFlashListSubmit(data) {
      this.$set(this.objectiveData, 'content', data.content)
      this.objectiveData.objectivesPower = {
        businessType: 1,
        lookRulesType: 0,
        objectivesPowerCustomizeList: []
      }
      const dataList = []
      const okrList = data.keyresultsList
      for (let i = 0; i < okrList.length; i++) {
        const beans = {
          collaboratorStr: '', // @人id  例如 ： "1,2"
          progressRete: 0, // 进度比例
          comment: okrList[i].title,
          title: okrList[i].title, // 结果描述
          titleSearch: this.$utils.tagFilter(okrList[i].title), // 结果描述不包含标签
          weight: 0, // 权重
          quantificationType: 0, // 量化条件
          quantificationUnit: '%', // 单位
          quantificationStart: 0,
          quantificationEnd: 100,
          quantificationActualValue: 0,
          quantificationStatus: 0,
          objectivesPower: {
            businessType: 2,
            lookRulesType: 0,
            objectivesPowerCustomizeList: []
          }
        }
        dataList.push(beans)
      }
      let numberData
      if (dataList.length > 1) {
        numberData = (100 / dataList.length).toFixed(0)
      } else {
        numberData = 100 / dataList.length
      }

      let numerical = 0
      for (let i = 0; i < dataList.length; i++) {
        dataList[i].weight = numberData
        numerical += Number(numberData)
      }
      const weightNumber =
        Number(dataList[dataList.length - 1].weight) + (100 - numerical)
      if (dataList.length > 1) {
        dataList[dataList.length - 1].weight = weightNumber.toFixed(0)
        dataList[dataList.length - 1].weight = Number(
          dataList[dataList.length - 1].weight
        )
      }
      this.objectiveData.keyresultsList = dataList
      this.objectiveData.atIds = ''
      this.activeStep = 2
      this.okrFlashListState = false
    },
    changePublicState(type) {
      if (this.targetAligningList.length > 0) {
        this.$message({
          msg: '你已经有对齐的目标，不能设为私密哦',
          type: 'warn'
        })
        return
      }
      this.objectiveData.publicState = type
    },
    // 校验数据
    validData() {
      if (this.activeStep === 1) {
        if (this.objectiveData.content.trim() === '') {
          this.$message({
            msg: '您填写的目标名称不能为空',
            type: 'warn'
          })
          return false
        }
      }
      if (this.activeStep === 2) {
        if (this.objectiveData.keyresultsList.length === 0) {
          this.$message({
            msg: '请添加至少一个关键结果',
            type: 'warn'
          })
          return false
        }
      }
      return true
    },
    // 上一步
    prevStep() {
      this.activeStep -= 1
      if (this.activeStep === 1) {
        this.$nextTick(() => {
          this.$refs.titleInpurRef.initHtmlValue(this.objectiveData.content)
        })
      }
    },
    // 下一步
    nextStep() {
      // 判断内容填写
      if (this.activeStep === 1) {
        this.$refs.titleInpurRef.enterEvent()
      } else {
        this.addStep()
      }
    },
    addStep() {
      const valid = this.validData()
      if (!valid) return
      this.activeStep += 1
    },
    // 确认新增
    finishAdd() {
      this.addloading = true
      const keyresultsList = JSON.parse(
        JSON.stringify(this.objectiveData.keyresultsList)
      )
      keyresultsList.forEach((item) => {
        item.title = this.$utils.changeToOkrtFormat(item.comment)
      })

      var objectivesAssociationList = []
      if (this.targetDetailsAlignState) {
        objectivesAssociationList.push({
          beAlignedObjId: this.alignObjectiveData.id,
          businessType: this.alignType === 'O' ? 0 : 1,
          businessId: this.alignObjectiveData.businessId
        })
      } else if (this.targetAligningList.length > 0) {
        this.targetAligningList.forEach((alignItem) => {
          objectivesAssociationList = objectivesAssociationList.concat(alignItem.childObjectivesAssociationList)
        })
      } else {
        objectivesAssociationList = []
      }
      const dataBeans = {
        collaboratorStr: this.objectiveData.atIds, // 第一阶段@人id  例如 ： "1,2"
        content: this.$utils.changeToOkrtFormat(this.objectiveData.content).replace(/&amp;/g, '&'), // 目标内容
        contentSearch: this.$utils.tagFilter(this.objectiveData.content), // 目标内容不包含标签
        cycleId: this.$store.state.common.cycle.quarterId,
        cycleInfoId: this.targetDetailsAlignState
          ? this.alignObjectiveData.cycleInfoId
          : this.$store.state.common.cycle.periodId, // 周期ID
        confidence: this.objectiveData.confidence, // 信心值
        // fatherOid, // 对齐目标id
        objectivesAssociationList,
        keyresultsList: keyresultsList, // 关键结果列表
        isShare: this.objectiveData.publicState ? 0 : 1,
        labelType: this.labelType, // 目标标签
        ignoreRuleIdList: this.objectiveData.ignoreRuleIdList,
        objectivesPower: this.objectiveData.objectivesPower,
        processState: this.approveDetailVisible ? 1 : 0
      }
      if (this.addType === '2') {
        this.$emit('getTargetAddSubmit', dataBeans)
      } else {
        this.$apis
          .OKRADD(dataBeans)
          .then((rest) => {
            if (rest.status === 200) {
              // 埋点
              const is_okrto_member = dataBeans.contentSearch.search('@') > -1
              let is_krto_member = false
              dataBeans.keyresultsList.forEach((item) => {
                if (item.titleSearch.search('@') > -1) {
                  is_krto_member = true
                }
              })
              if (dataBeans.fatherOid) {
                this.$mixpanelUtil.track('Create Invite OKR', {
                  kr_count: dataBeans.keyresultsList.length,
                  is_public: dataBeans.isShare !== 1,
                  okr_id: JSON.parse(rest.data).id || JSON.parse(rest.data).objId,
                  confidence: dataBeans.confidence,
                  cycle: dataBeans.cycleInfoId,
                  is_okrto_member,
                  is_krto_member,
                  type: this.labelTypeName[this.labelType]
                })
              } else {
                this.$mixpanelUtil.track('Create OKR', {
                  kr_count: dataBeans.keyresultsList.length,
                  is_public: dataBeans.isShare !== 1,
                  okr_id: JSON.parse(rest.data).id || JSON.parse(rest.data).objId,
                  confidence: dataBeans.confidence,
                  cycle: dataBeans.cycleInfoId,
                  is_okrto_member,
                  is_krto_member,
                  type: this.labelTypeName[this.labelType]
                })
              }
              this.$message({
                msg: '添加目标成功',
                type: 'success'
              })
              // 创建OKR目标 使用感受
              this.bus.$emit('inquireStatus', 'OKR')
              if (this.targetDetailsAlignState) {
                this.$emit('getTargetAddSubmit', this.alignObjectiveData)
              } else {
                this.$emit(
                  'getTargetAddSubmit',
                  this.targetAligningList.length > 0
                    ? this.targetAligningList[0]
                    : {}
                )
              }
              if (localStorage.getItem('approveRemindState') !== 'true' && this.approveDetailVisible === true) {
                this.$store.commit('okr/approveRemindObj', {
                  visible: true,
                  objDetail: {
                    id: JSON.parse(rest.data).id || JSON.parse(rest.data).objId
                  },
                  businessActionType: 1
                })
              }
              this.closeDialog()
            } else if (rest.status === 62003) {
              this.$message({
                msg: '私有目标，不能@人',
                type: 'error'
              })
            } else {
              this.$message({
                msg: rest.message,
                type: 'error'
              })
            }
            this.addloading = false
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    // 关闭时进行二次确认
    async handleClose() {
      if (this.objectiveData.content.trim() === '') {
        this.closeDialog()
        return
      }
      const status = await this.$element.confirm(
        '有已填写的内容，是否关闭弹窗？',
        '是否关闭'
      )
      if (status) {
        this.closeDialog()
      }
    },
    closeDialog() {
      this.dialogVisible = false
      this.$emit('change', false)
      this.$emit('gettargetAddClose')
    },
    save() {}
  }
}
</script>
<style lang="less" scoped>
@import '../../../../assets/styles/dialog.less';
.approve-detail-wrap {
  .flex-l();
  font-size: 12px;
  font-weight: 400;
  color: #6e7491;
  line-height: 20px;
  margin-top: 12px;
  .info-icon {
    width: 20px;
  }
  .descripe {
    margin: 0px 12px 0px 8px;
  }
  .user {
  }
}
.okr-type-wrap {
  font-size: 14px;
  font-weight: 500;
  color: #444558;
  .flex-l();
  height: 32px;
}
.look-add-okr {
  padding: 0;
  margin: 0;
  font-size: 14px;
  color: #ff9900;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  line-height: 34px;
  height: 34px;
  .add-view-img {
    width: 20px;
    height: 18px;
    margin-right: 6px;
    font-size: 20px;
    color: #ff9900;
  }
}
.look-add-wrap {
  .flex-l();
  .look-add-school {
    margin-left: 24px;
    .icon-hot {
      margin-left: 4px;
    }
  }
}
.look-add-okr:hover {
  font-size: 14px;
  color: #ff9900;
  opacity: 0.54;
}
::v-deep {
  .el-dialog__header {
    display: none;
  }
}
.add-target-dialog {
  padding: 40px 0 16px;
  .align-obj {
    margin-top: 32px;
    margin-bottom: 16px;
  }
  .objective-add-input {
    width: 816px;
    height: 160px;
    background: #f5f6fa;
    border-radius: 16px;
    margin: 32px auto 24px auto;
    padding: 16px;
    padding-top: 0px;
    box-sizing: border-box;
    .input-box {
      height: 120px;
      padding: 8px 0;
      ::v-deep {
        .at-container .editor {
          line-height: 26px;
          // height: 110px;
        }
      }
    }
    .bottom-actions {
      display: flex;
    }
  }
  .example-text {
    display: inline;
    height: 20px;
    line-height: 20px;
    cursor: pointer;
    color: #3a78f6;
    margin-right: 16px;
    &:hover {
      color: #005eff;
    }
  }
  .cycle-text {
    display: flex;
    align-items: center;
    .cycle-info-box {
      display: flex;
      align-items: center;
      color: #a0a3bd;
      margin-right: 16px;
      .cycle-box {
        height: 28px;
        padding: 6px 10px;
        box-sizing: border-box;
        background: #ebf5ff;
        color: #3a78f6;
        border-radius: 5px;
        margin-left: 8px;
      }
      .label-select {
        margin-left: 8px;
      }
    }
  }
  .add-key-result {
    margin-top: 16px;
    min-height: 220px;
    .weight-sliding {
      margin-top: 32px;
      color: #14142b;
      display: flex;
      .slide-title {
        font-size: 16px;
        font-weight: 700;
        margin-right: 16px;
        margin-top: 6px;
      }
    }
  }
  .bottom-content {
    margin: 40px 0 0px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .kr-tips-list {
      display: flex;
      align-items: center;
    }
    .kr-tips {
      margin-right: 16px;
      color: #a0a3bd;
      line-height: 20px;
      display: flex;
      align-items: center;
      .dot {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        margin-right: 8px;
        background: #00ba88;
      }
      .red-dot {
        background: #ed2e7e;
      }
    }
    .privateOrpublic {
      display: flex;
      align-items: center;
      color: #6e7491;
      cursor: pointer;
      .private-icon {
        font-size: 40px;
        margin-right: 8px;
      }
    }
    .bottom-step-buttons {
      margin: 0;
      .left-button {
        margin: 0 0 0 8px;
      }
    }
  }
}
</style>
