var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "智能更新进度设置",
            visible: _vm.dialogVisible,
            width: "880px",
            "append-to-body": true,
            "before-close": _vm.handleClose,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            click: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "adver-wrap" },
            [
              _c("advertisement", {
                attrs: { type: "autoProgress", styleType: 2 },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "vueloading",
                  rawName: "v-vueloading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "set-content",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c("div", { staticClass: "set-type-wrap" }, [
                _c(
                  "div",
                  { staticClass: "set-type-title" },
                  [
                    _c("span", { staticClass: "line" }),
                    _vm._v("按「对齐我的OKR」完成情况自动更新 "),
                    _c("el-switch", {
                      attrs: { width: "42", "active-color": "#005EFF" },
                      model: {
                        value: _vm.alignOkrSwitch,
                        callback: function ($$v) {
                          _vm.alignOkrSwitch = $$v
                        },
                        expression: "alignOkrSwitch",
                      },
                    }),
                  ],
                  1
                ),
                _vm.alignOkrSwitch
                  ? _c("div", [
                      _vm.selectOkrList.length > 0
                        ? _c("div", { staticClass: "select-okr-wrap" }, [
                            _c("div", {
                              staticClass: "select-okr-left",
                              domProps: {
                                innerHTML: _vm._s(_vm.selectOkrHtml()),
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "select-okr-right" },
                              [
                                _c("Icon", {
                                  staticClass: "icon-edit",
                                  attrs: { "icon-class": "icon-bianji" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setTypeClick(
                                        "alignOkrVisible",
                                        true
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _c(
                            "div",
                            {
                              staticClass: "set-type-button",
                              on: {
                                click: function ($event) {
                                  return _vm.setTypeClick(
                                    "alignOkrVisible",
                                    true
                                  )
                                },
                              },
                            },
                            [_vm._v("设置条件")]
                          ),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "set-type-wrap" }, [
                _c(
                  "div",
                  { staticClass: "set-type-title" },
                  [
                    _c("span", { staticClass: "line" }),
                    _vm._v("按「我的任务」完成情况自动更新 "),
                    _c("el-switch", {
                      attrs: { width: "42", "active-color": "#005EFF" },
                      model: {
                        value: _vm.taskTypeSwitch,
                        callback: function ($$v) {
                          _vm.taskTypeSwitch = $$v
                        },
                        expression: "taskTypeSwitch",
                      },
                    }),
                  ],
                  1
                ),
                _vm.taskTypeSwitch
                  ? _c("div", { staticClass: "set-task-content" }, [
                      _c(
                        "div",
                        { staticClass: "set-task-type" },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 1 },
                              model: {
                                value: _vm.taskTypeRadio,
                                callback: function ($$v) {
                                  _vm.taskTypeRadio = $$v
                                },
                                expression: "taskTypeRadio",
                              },
                            },
                            [_vm._v("平均分配")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 2 },
                              model: {
                                value: _vm.taskTypeRadio,
                                callback: function ($$v) {
                                  _vm.taskTypeRadio = $$v
                                },
                                expression: "taskTypeRadio",
                              },
                            },
                            [_vm._v("自定义分配")]
                          ),
                        ],
                        1
                      ),
                      _vm.taskTypeRadio === 1
                        ? _c(
                            "div",
                            { staticClass: "task-average-wrap" },
                            [
                              _c(
                                "el-input",
                                {
                                  staticClass: "input-focus",
                                  attrs: { placeholder: "0" },
                                  on: {
                                    change: _vm.taskAveragePercentageChange,
                                  },
                                  model: {
                                    value: _vm.taskAveragePercentage,
                                    callback: function ($$v) {
                                      _vm.taskAveragePercentage = $$v
                                    },
                                    expression: "taskAveragePercentage",
                                  },
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.item.quantificationUnit) +
                                        " "
                                    ),
                                  ]),
                                ],
                                2
                              ),
                              _vm._v("的进度将由任务完成后自动更新 "),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            {
                              staticClass: "set-type-button",
                              on: {
                                click: function ($event) {
                                  return _vm.setTypeClick(
                                    "taskFinishVisible",
                                    true
                                  )
                                },
                              },
                            },
                            [_vm._v("设置条件")]
                          ),
                    ])
                  : _vm._e(),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "bottom-step-buttons",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c("div", { staticClass: "percentage-text" }, [
                _vm._v(" 剩余可设置自动更新进度目标值 "),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.percentageAll) +
                      _vm._s(_vm.item.quantificationUnit)
                  ),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "btn-list",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "left-button",
                      on: { click: _vm.handleClose },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "L-button",
                    {
                      attrs: { loading: _vm.addLoading },
                      on: {
                        clickEvt: function ($event) {
                          return _vm.submit("提交")
                        },
                      },
                    },
                    [_vm._v("确认")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _vm.alignOkrVisible
        ? _c("alignOkr", {
            attrs: {
              list: _vm.selectOkrList,
              okrData: _vm.okrData,
              item: _vm.item,
              maxPercentage: _vm.alignOkrMaxPercentage(),
            },
            on: { refresh: _vm.alignOkrRefresh },
            model: {
              value: _vm.alignOkrVisible,
              callback: function ($$v) {
                _vm.alignOkrVisible = $$v
              },
              expression: "alignOkrVisible",
            },
          })
        : _vm._e(),
      _vm.taskFinishVisible
        ? _c("taskFinish", {
            attrs: {
              taskList: _vm.taskList,
              okrData: _vm.okrData,
              item: _vm.item,
              maxPercentage: _vm.percentageAll - _vm.alignOkrPercentage,
            },
            on: { refresh: _vm.taskFinishRefresh },
            model: {
              value: _vm.taskFinishVisible,
              callback: function ($$v) {
                _vm.taskFinishVisible = $$v
              },
              expression: "taskFinishVisible",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }