<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :append-to-body="true"
    center
    :size="360"
    :modal-append-to-body="true"
    :before-close="dialogClose"
    custom-class="set-workhours-dialog"
    :show-close="false"
    :title="title"
  >
    <div class="set-workhours-main">
      <div class="set-workhours-data">
        <input-number source="stopdoing" v-model="workHours" :step="1" :point="1" />
        <span class="set-workhours-unit">小时</span>
      </div>
      <div class="cumulative-div">
        <div class="cumulative-data">
          当前累计
          <span class="value">{{ cumulativeValue ? cumulativeValue : 0 }}</span>
          小时
        </div>
        <div @click="lookDetailed()" class="look-detailed">查看明细</div>
      </div>
      <div class="date-div" v-if="title == '实际工时'">
        <el-date-picker
          class="workhours-date"
          v-model="dateValue"
          type="datetimerange"
          range-separator="-"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['10:00:00', '19:00:00']"
          @change="changeDate"
        ></el-date-picker>
      </div>
      <div class="executor-div">
        <div class="executor-label">负责人</div>
        <div class="executor-content">
          <user-picker :taskId="form.id" pageType="workHours" @pick="toriginatorPicker">
            <div
              class="originator-box to-edit-input-not"
              @mouseover="isColorCloseIcon = false"
              @mouseout="isColorCloseIcon = true"
            >
              <div v-if="originator.originatorName === null">
                <i class="head-icon"></i>
                <span class="head-icon-label">待认领</span>
              </div>
              <div v-else>
                <el-image
                  fit="cover"
                  :src="originator.originatorAvatar || require('@/assets/imgs/common/advar.png')"
                  alt
                  class="avatar-img pointer border-radius-20"
                />
                <div class="m-l-10 pointer username">
                  <wx-open-data
                    :id="originator.originatorName"
                    :nameData="{name:originator.originatorName}"
                  />
                </div>
                <!-- <Icon
                  :icon-class="isColorCloseIcon ? '' : 'icon-a-guanbi1'"
                  class="close-icon"
                  v-if="originator.originatorName !== null"
                  @click.native.stop="clearToriginatorPicker(fieldItem)"
                />-->
              </div>
            </div>
          </user-picker>
        </div>
      </div>
      <div class="executor-div">
        <div class="executor-label">工作描述</div>
        <div class="executor-content">
          <el-input
            class="desc-textarea"
            type="textarea"
            :rows="4"
            :maxlength="500"
            placeholder="请输入内容"
            v-model="workDesc"
            @input="changeDesc"
          ></el-input>
        </div>
      </div>
      <div class="button-div">
        <el-button class="cancel-button" @click="dialogClose" type="primary" plain>取消</el-button>
        <el-button @click="submitWorkHours()" class="confirm-button" type="primary">确定</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { mapState } from 'vuex'
import InputNumber from '@/views/okr/components/singleOkrItem/InputNumber.vue'
import '@/utils/day.js'
export default {
  computed: {
    ...mapState('user', {
      userInfo: 'userInfo' // 用户信息
    })
  },
  components: {
    InputNumber
  },
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  props: {
    source: {
      type: String,
      default: 'task'
    },
    type: {
      type: String,
      default: 'add'
    },
    form: {
      type: Object,
      default: function () {
        return {}
      }
    },
    dialogShow: {
      type: Boolean
    },
    title: {
      type: String,
      default: '计划工时'
    },
    editData: {
      type: Object,
      default: function () {
        return {}
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    form: {
      handler(val) {
        console.log('form----', val)
        if (this.title == '计划工时' && val.taskWorkingHoursStatistics) {
          this.cumulativeValue = val.taskWorkingHoursStatistics.planHourTotal
        }
        if (this.title == '实际工时' && val.taskWorkingHoursStatistics) {
          this.cumulativeValue = val.taskWorkingHoursStatistics.actualHourTotal
        }
      },
      deep: true,
      immediate: true
    },
    editData: {
      handler(val) {
        console.log('编辑数据---', val)
        if (this.type == 'edit') {
          this.workHoursId = val.id
          if (this.title == '计划工时') {
          }
          if (this.title == '实际工时') {
            if (val.hourStartDate && val.hourEndDate) {
              this.dateValue = [val.hourStartDate, val.hourEndDate]
            }
          }
          this.workDesc = val.hourDescribe
          this.workHours = val.hourNum
          this.editWorkHours = val.hourNum
        }
      },
      deep: true,
      immediate: true
    }
  },

  data() {
    return {
      dialogVisible: this.dialogShow,
      workHours: 0, // 工时
      cumulativeValue: 0, //累计几小时
      isColorCloseIcon: true,
      // 负责人
      originator: {
        originatorName: null,
        originatorAvatar: null,
        originatorId: null
      },
      workDesc: '', // 工作描述
      dateValue: [],
      workHoursId: '',
      editWorkHours: 0,
      taskId: '',
      totalPlanHour: 0,
      totalActualHour: 0,
      hourTypeData: 1,
      isRefresh: false
    }
  },
  mounted() {
    console.log('用户信息--', this.userInfo)
    this.originator.originatorName = this.userInfo.name
    this.originator.originatorAvatar = this.userInfo.avatar
    this.originator.originatorId = this.userInfo.id
    console.log(
      '小时差',
      this.$day.diffHours('2022-11-01 10:00', '2022-11-02 12:00')
    )
  },
  methods: {
    changeDesc(val) {
      if (val.length > 500) {
        this.$message.warn(`输入最大长度不能超过500个字符哦！`)
        this.workDesc = val.substring(0, 500)
      }
    },
    changeDate(val) {
      console.log('日期---', val)
    },
    lookDetailed() {
      this.bus(this).$emit('lookDetailed')
    },
    // 提交工时
    submitWorkHours() {
      if (!this.workHours) {
        this.$message.warn('工时不能为空！')
        return
      }
      var hoursNumTotal =
        Number(this.workHours) + Number(this.cumulativeValue) + ''
      if (this.type == 'edit') {
        if (Number(this.workHours) > Number(this.editWorkHours)) {
          hoursNumTotal =
            Number(this.workHours) -
            Number(this.editWorkHours) +
            Number(this.cumulativeValue) +
            ''
        } else {
          hoursNumTotal =
            Number(this.cumulativeValue) -
            Number(this.editWorkHours) -
            Number(this.workHours) +
            ''
        }
      }
      var hoursNumTotalArr = null
      if (hoursNumTotal.indexOf('.') >= 0) {
        hoursNumTotalArr = hoursNumTotal.split('.')
      }
      if (
        this.title == '计划工时' &&
        hoursNumTotalArr &&
        hoursNumTotalArr.length > 0 &&
        hoursNumTotalArr[0].length > 5
      ) {
        this.$message.warn('累计工时不能超过99999！')
        return
      }
      if (
        this.title == '计划工时' &&
        !hoursNumTotalArr &&
        hoursNumTotal.length > 5
      ) {
        this.$message.warn('累计工时不能超过99999！')
        return
      }
      if (this.title == '实际工时' && this.dateValue.length == 0) {
        this.$message.warn('时间不能为空！')
        return
      }
      if (this.title == '实际工时' && this.dateValue.length > 1) {
        var hours = this.$day.diffHours(this.dateValue[0], this.dateValue[1])
        console.log(222, hours)
        if (this.workHours > hours) {
          this.$message.warn('不能超过实际工时！')
          return
        }
      }
      const path =
        this.type == 'edit' ? 'UPDATETASKWORKHOURS' : 'ADDTASKWORKHOURS'

      const params = {
        id: this.workHoursId ? this.workHoursId : null,
        taskId: this.form.id,
        userId: this.originator.originatorId,
        hourType: this.title == '计划工时' ? 1 : 2,
        hourNum: this.workHours,
        hourDescribe: this.workDesc
      }
      if (this.title == '实际工时') {
        params['hourStartDate'] =
          this.dateValue.length > 1 ? this.dateValue[0] : null
        params['hourEndDate'] =
          this.dateValue.length > 1 ? this.dateValue[1] : null
      }
      console.log(43434, path, this.type)
      this.$apis[path](params).then((res) => {
        if (res.status == 200) {
          this.taskId = res.data.taskId
          this.hourTypeData = res.data.hourType
          this.totalPlanHour = res.data.totalPlanHour
          this.totalActualHour = res.data.totalActualHour
          this.isRefresh = true
          // this.bus(this).$emit(
          //   'global:setWorkHoursRefresh',
          //   this.source,
          //   res.data.taskId,
          //   res.data.hourType,
          //   res.data.totalPlanHour
          //     ? res.data.totalPlanHour
          //     : res.data.totalActualHour
          // )
          this.$message({
            msg: res.message,
            type: 'success'
          })
          this.dialogClose()
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 负责人选择
    toriginatorPicker(row) {
      console.log('负责人选择', row)
      this.originator.originatorId = row.id
      this.originator.originatorAvatar = row.avatar
      this.originator.originatorName = row.name || row.username
    },
    changeWorkHours() {},
    // 弹窗关闭
    dialogClose() {
      if (this.isRefresh) {
        let totalHour = this.totalPlanHour
          ? this.totalPlanHour
          : this.totalActualHour
        this.bus.$emit(
          'global:setWorkHoursRefresh',
          this.source,
          this.taskId,
          this.hourTypeData,
          totalHour
        )
      }
      this.dialogVisible = false
      this.$emit('change', false)
      this.isRefresh = false
      this.taskId = ''
      this.hourTypeData = 0
      this.totalPlanHour = 0
      this.totalActualHour = 0
      this.bus(this).$emit('closeSetWorkHours')
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep {
  .input-number input {
    text-align: center !important;
  }
  .desc-textarea.el-textarea {
    background: #f5f6fa !important;
    border-radius: 16px 16px 16px 16px !important;
    border: none !important;
    .el-textarea__inner {
      padding: 16px !important;
    }
  }
  .el-dialog.set-workhours-dialog {
    width: 360px !important;
    .el-dialog__header {
      padding: 24px 24px 0 24px !important;
      text-align: left;
      .el-dialog__title {
        font-size: 20px;
        font-weight: bold;
        color: #14142b;
      }
    }
    .el-dialog__body {
      padding: 0 24px 24px 24px !important;
    }
  }
}
.set-workhours-main {
  margin-top: 16px;
  .set-workhours-data {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    height: 40px;
    justify-content: space-between;
    .range-input {
      width: 48px;
      margin: 0px 4px;
      ::v-deep {
        .el-input__inner {
          height: 32px;
          padding-left: 5px;
          padding-right: 5px;
          border-radius: 10px;
          border-color: #d9dbe9;
        }
        .el-input.is-active .el-input__inner,
        .el-input__inner:focus {
          border-color: #3a78f6 !important;
          box-shadow: 0px -3px 10px 0px rgba(50, 150, 250, 0.14) inset;
        }
      }
    }
    ::v-deep {
      .input-number {
        width: 100%;
        height: 40px !important;
        input {
          height: 38px !important;
        }
        .right-num-action {
          height: 36px !important;
          .num-action {
            height: 18px;
          }
        }
      }
    }
    .set-workhours-unit {
      display: inline-block;
      font-size: 16px;
      font-weight: 500;
      color: #444558;
      margin-left: 16px;
      width: 40px;
    }
  }
  .cumulative-div {
    display: flex;
    flex-direction: row;
    margin-top: 12px;
    .cumulative-data {
      flex: 1;
      font-size: 12px;
      font-weight: 400;
      color: #6e7491;
      .value {
        display: inline-block;
        padding: 0 6px;
      }
    }
    .look-detailed {
      font-size: 12px;
      font-weight: 400;
      color: #3a70ff;
      width: 50px;
      cursor: pointer;
    }
  }
  .date-div {
    margin-top: 16px;

    ::v-deep {
      .el-date-editor--datetimerange.el-input,
      .el-date-editor--datetimerange.el-input__inner {
        width: 100% !important;
        border: 1px solid #f5f6fa !important;
      }
      .el-input__icon.el-range__icon.el-icon-time {
        display: none;
      }
      .el-date-editor .el-range-input {
        width: 44% !important;
        margin-left: 10px !important;
      }
    }
  }
  .executor-div {
    margin-top: 24px;
    .executor-label {
      font-size: 14px;
      font-weight: 400;
      color: #29334d;
    }
    .executor-content {
      margin-top: 12px;
      .originator-box {
        padding: 8px 8px 8px 8px;
        cursor: pointer;
        position: relative;
        background: #fff;
        border-radius: 12px;
        border: 1px solid #f5f6fa;
        div {
          display: flex;
          align-items: center;
        }

        .close-icon {
          position: relative;
          right: 0px;
          top: 0px;
        }
        .head-icon {
          display: inline-block;
          width: 20px;
          height: 20px;
          background: url('~@/assets/imgs/pm/head-icon.png') no-repeat;
          background-size: 100% 100%;
        }
        .head-icon-label {
          margin-left: 5px;
          font-weight: 500;
          color: #a0a3bd;
        }
        .username {
          font-size: 14px;
          font-weight: 500;
          color: #444558;
          flex: 1;
        }
        .avatar-img {
          border-radius: 50%;
          height: 28px;
          width: 28px;
          overflow: hidden;
          margin-right: 8px;
        }
      }
      .originator-box {
        height: 40px;
        line-height: 38px;
        padding: 0px 8px;
        &:hover {
          background: #fff;
          border: 1px solid #3a70ff;
        }
      }
    }
  }
  .button-div {
    display: flex;
    flex-direction: row;
    margin-top: 22px;
    justify-content: flex-end;
    ::v-deep {
      .el-button.cancel-button {
        width: 77px;
        height: 32px;
        border-radius: 12px 12px 12px 12px;
        border: 1px solid #3a70ff !important;
        color: #3a70ff !important;
        background: #fff !important;
        &:hover {
          background: rgba(58, 120, 246, 0.06) !important;
        }
      }
      .el-button.confirm-button {
        width: 77px;
        height: 32px;
        background: #3a70ff;
        border-radius: 12px 12px 12px 12px;
        border: none;
        color: #fff;
        &:hover {
          background: rgba(58, 120, 246, 0.87) !important;
        }
      }
    }
  }
}
</style>
