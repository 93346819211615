var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: {
        "visible-arrow": false,
        placement: "right-start",
        "popper-class": "kr-process-update",
        trigger: "click",
        disabled: _vm.disabled,
      },
      on: { show: _vm.initData },
      model: {
        value: _vm.krProcessUpdateShow,
        callback: function ($$v) {
          _vm.krProcessUpdateShow = $$v
        },
        expression: "krProcessUpdateShow",
      },
    },
    [
      _c("div", { staticClass: "key_results_alert" }, [
        _c("div", { staticClass: "key_results_alert_box" }, [
          _c("div", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.objData.quantificationType !== 1,
                    expression: "objData.quantificationType !== 1",
                  },
                ],
                staticClass: "alert_box_title",
              },
              [
                _c("div", [
                  _c("span", [
                    _vm._v(
                      " 初始值" +
                        _vm._s(_vm.objData.quantificationStart) +
                        _vm._s(_vm.objData.quantificationUnit) +
                        "-目标值" +
                        _vm._s(_vm.objData.quantificationEnd) +
                        _vm._s(_vm.objData.quantificationUnit) +
                        " "
                    ),
                  ]),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "alert_box_input" },
              [
                _c(
                  "el-input",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.objData.quantificationType !== 1,
                        expression: "objData.quantificationType !== 1",
                      },
                    ],
                    attrs: { type: "text", size: "mini" },
                    on: { blur: _vm.getNewKeywordInput },
                    model: {
                      value: _vm.from2.rate,
                      callback: function ($$v) {
                        _vm.$set(_vm.from2, "rate", $$v)
                      },
                      expression: "from2.rate",
                    },
                  },
                  [
                    _c("template", { slot: "append" }, [
                      _c("div", { staticClass: "append-unit" }, [
                        _vm._v(_vm._s(_vm.objData.quantificationUnit)),
                      ]),
                    ]),
                  ],
                  2
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.objData.quantificationType === 1,
                        expression: "objData.quantificationType === 1",
                      },
                    ],
                    staticClass: "status-select-box",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "select-item",
                        on: {
                          click: function ($event) {
                            _vm.objData.quantificationStatus = 0
                          },
                        },
                      },
                      [
                        _c("Icon", {
                          staticClass: "select-icon",
                          attrs: {
                            "icon-class":
                              _vm.objData.quantificationStatus === 1
                                ? "icon-weiwancheng"
                                : "icon-finished",
                          },
                        }),
                        _vm._v("未完成 "),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "select-item",
                        on: {
                          click: function ($event) {
                            _vm.objData.quantificationStatus = 1
                          },
                        },
                      },
                      [
                        _c("Icon", {
                          staticClass: "select-icon",
                          attrs: {
                            "icon-class":
                              _vm.objData.quantificationStatus !== 1
                                ? "icon-weiwancheng"
                                : "icon-finished",
                          },
                        }),
                        _vm._v("已完成 "),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm.objData.automationState && _vm.krAutoProgressList
              ? _c("div", { staticClass: "auto-progress-value" }, [
                  _vm._v(
                    "有「" +
                      _vm._s(_vm.krAutoProgressList.autoAllNum) +
                      _vm._s(_vm.krAutoProgressList.unit) +
                      "」已被设置为智能进度"
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "alert_box_title",
                staticStyle: { "margin-top": "16px" },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.objData.quantificationType === 1
                      ? "完成情况说明"
                      : "进度说明"
                  )
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "alert_box_textarea" },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    placeholder: "请输入进度反馈说明",
                  },
                  on: { input: _vm.inputBack },
                  model: {
                    value: _vm.from2.explain,
                    callback: function ($$v) {
                      _vm.$set(_vm.from2, "explain", $$v)
                    },
                    expression: "from2.explain",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "alert_box_submit clearfix" },
              [
                _c(
                  "div",
                  {
                    staticClass: "alert_box_submit_button1",
                    on: { click: _vm.getKeyResultsAlertClose },
                  },
                  [_vm._v("取消")]
                ),
                _c(
                  "L-button",
                  {
                    staticClass: "alert_box_submit_button2",
                    attrs: { loading: _vm.loading },
                    on: { clickEvt: _vm.getKeyResultsAlertSubmit },
                  },
                  [_vm._v("保存")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c(
        "div",
        { attrs: { slot: "reference" }, slot: "reference" },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }