<template>
  <el-popover
    v-model="popShow"
    popper-class="task-type-pop"
    trigger="manual"
    placement="bottom-start"
  >
    <!-- 待办 -->
    <div
      v-if="type == 'stopdoing'"
      slot="reference"
      class="stopdoing-box"
      :style="disabled ? 'cursor: default;' : ''"
      @click.stop="showPop"
    >
      <Icon icon-class="icon-biangengguishu" class="classify-icon" />变更所属
    </div>
    <div
      v-else
      slot="reference"
      class="classify-box"
      :style="disabled ? 'cursor: default;' : ''"
      @click.stop="showPop"
    >
      <Icon :icon-class="activeObj.icon" class="classify-icon" />
      {{ activeObj.name }}
      <Icon v-if="!disabled" icon-class="icon-xiangxia" class="arrow-icon" />
    </div>
    <div v-if="!showProject" class="task-type-list">
      <div class="pop-title">
        {{ type == 'stopdoing' ? '变更所属' : '任务分类' }}
        <Icon icon-class="icon-guanbi2" class="close-icon" @click="closePop" />
      </div>
      <!-- <div
        v-if="type != 'stopdoing'"
        class="task-type-item"
        @click="selectConfidence(todoObj)"
      >
        <div class="classify-box classify-box-select">
          <Icon :icon-class="todoObj.icon" class="classify-icon" />
          <span>{{ todoObj.name }}</span>
          <Icon
            v-if="activeObj.val === todoObj.val"
            icon-class="icon-duigou"
            class="check-icon"
          />
        </div>
      </div>-->
      <taskKr
        ref="taskKrPopRef"
        :source="type"
        :custom-slot="true"
        placement="right"
        :select-kr="selectKr"
        @save="saveKr"
      >
        <div class="task-type-item" @click="selectConfidence(krObj)">
          <div class="classify-box classify-box-select">
            <Icon :icon-class="krObj.icon" class="classify-icon" />
            <span>
              {{ type == 'stopdoing' ? '变更为关键结果KR任务' : krObj.name }}{{
              }}
            </span>
            <Icon v-if="activeObj.val === krObj.val" icon-class="icon-duigou" class="check-icon" />
          </div>
        </div>
      </taskKr>
      <div v-if="type == 'stopdoing'" class="task-type-item">
        <el-popover ref="taskPorjects" placement="right" trigger="click">
          <div class="pm-content-div">
            <div class="pm-title">变更为项目任务</div>
            <div
              style="margin-top: 12px"
              v-scroll-bottom="loadMore"
              data-scroll-type="projectList"
              class="project-list"
            >
              <div
                v-for="item in projectList"
                :key="item.id"
                class="project-item"
                @click="selectProjectItem(item)"
              >
                <img :src="item.logo" :style="`background:${item.colour}`" />
                <div class="project-name">{{ item.name }}</div>
                <Icon
                  v-if="selectProject.id === item.id"
                  icon-class="icon-duigou"
                  class="check-icon"
                />
              </div>
              <div v-if="projectList.length === 0" class="no-project">
                <Img src="/common/commentNull.png" />暂无数据
              </div>
            </div>
            <div class="project-cont-footer">
              <button class="cancel-primary common_btn_next_add" @click.stop="cancelHidden">取 消</button>
              <button class="next-primary common_btn_next_add" @click.stop="saveProject">完 成</button>
            </div>
          </div>
          <!-- <el-button slot="reference">click 激活</el-button> -->
          <div
            slot="reference"
            @click="selectConfidence(projectObj)"
            class="classify-box classify-box-select"
          >
            <Icon :icon-class="projectObj.icon" class="classify-icon" />
            <span>变更为项目任务</span>
            <Icon
              v-if="activeObj.val === projectObj.val"
              icon-class="icon-duigou"
              class="check-icon"
            />
          </div>
        </el-popover>
      </div>
      <div v-if="type != 'stopdoing'" class="task-type-item" @click="selectConfidence(projectObj)">
        <div class="classify-box classify-box-select">
          <Icon :icon-class="projectObj.icon" class="classify-icon" />
          <span>{{ projectObj.name }}</span>
          <Icon
            v-if="activeObj.val === projectObj.val"
            icon-class="icon-duigou"
            class="check-icon"
          />
        </div>
      </div>
    </div>
    <div v-if="showProject" class="task-type-list">
      <div class="pop-title">
        <Icon icon-class="icon-back-arrow" class="close-icon" @click="backMain" />项目任务
        <Icon icon-class="icon-guanbi2" class="close-icon" @click="closePop" />
      </div>
      <div class="project-select">
        项目
        <el-popover
          ref="taskPorjects"
          trigger="click"
          popper-class="task-project-pop"
          :offset="23"
          placement="bottom-end"
        >
          <div slot="reference" class="select-item">
            {{ selectProject.name || '请选择' }}
            <Icon icon-class="icon-xiangxia" class="arrow-icon" />
          </div>
          <div v-scroll-bottom="loadMore" data-scroll-type="projectList" class="project-list">
            <div
              v-for="item in projectList"
              :key="item.id"
              class="project-item"
              @click="selectProjectItem(item)"
            >
              <img :src="item.logo" :style="`background:${item.colour}`" />
              <div class="project-name">{{ item.name }}</div>
              <Icon
                v-if="selectProject.id === item.id"
                icon-class="icon-duigou"
                class="check-icon"
              />
            </div>
            <div v-if="projectList.length === 0" class="no-project">
              <Img src="/common/commentNull.png" />暂无数据
            </div>
          </div>
        </el-popover>
      </div>
      <el-button type="primary" class="confirm-btn" @click="saveProject">确定</el-button>
    </div>
  </el-popover>
</template>
<script>
import taskKr from '../TaskKr'
export default {
  components: { taskKr },
  model: {
    prop: 'classifyVal',
    event: 'change'
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    taskId: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    classifyVal: {
      // kr,todo,project
      type: String,
      default: 'todo'
    },
    typeData: {
      // 当前的KR或项目信息
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      popShow: false,
      todoObj: { name: '待办', val: 'todo', icon: 'icon-linshirenwu' },
      krObj: { name: 'KR任务', val: 'kr', icon: 'icon-task-kr' },
      projectObj: {
        name: '项目任务',
        val: 'project',
        icon: 'icon-xiangmu'
      },

      activeObj: {
        name: '待办',
        val: 'todo',
        icon: 'icon-linshirenwu'
      },
      typeForm: {},
      selectKr: {},
      selectProject: {},
      showProject: false,
      projectList: [],
      proPageSize: 10,
      proPageNum: 1,
      proTotal: 0
    }
  },
  watch: {
    classifyVal: {
      handler(val) {
        if (val) {
          this.activeObj = this[`${val}Obj`]
          console.log(89800, this.activeObj)
        }
      },
      deep: true,
      immediate: true
    },
    typeData: {
      handler(val) {
        if (val.objId && val.keyId) {
          this.selectProject = {}
          this.selectKr = {
            keyId: val.keyId,
            objId: val.objId,
            cycleId: val.cycleId,
            keyTitle: val.keyTitle
          }
        } else if (val.projectId) {
          this.selectKr = {}
          this.selectProject = {
            id: val.projectId,
            projectId: val.projectId,
            keyTitle: val.projectName,
            name: val.projectName
          }
        } else {
          this.selectProject = {}
          this.selectKr = {}
        }
      },
      deep: true
    }
  },
  created() {
    // 点击详情空白关闭
    this.bus(this).$on('clickTaskDetail', () => {
      this.popShow = false
    })
  },
  methods: {
    cancelHidden() {
      if (this.$refs.taskPorjects) {
        this.$refs.taskPorjects.doClose()
      }
    },
    showPop() {
      if (!this.disabled) {
        this.popShow = !this.popShow
      }
    },
    closePop() {
      this.popShow = false
      this.showProject = false
      this.closeProjectPop()
    },
    backMain() {
      this.closeProjectPop()
      this.showProject = false
    },
    saveProject() {
      console.log(this.selectProject)
      if (this.selectProject.id) {
        this.typeForm = {
          keyTitle: this.selectProject.name,
          projectId: this.selectProject.id
        }
        this.confirmChange(this.projectObj, '2')
      } else {
        this.$message.warn('请选择项目！')
      }
    },
    selectProjectItem(item) {
      this.selectProject = item
      if (this.type != 'stopdoing') {
        this.closeProjectPop()
      }
    },
    closeProjectPop() {
      if (this.$refs.taskPorjects) {
        this.$refs.taskPorjects.doClose()
      }
    },
    // 项目加载更多
    loadMore() {
      if (this.proTotal - this.proPageSize * this.proPageNum > 0) {
        this.getProjectList(true)
      }
    },
    getProjectList(flag) {
      if (flag && flag == true) {
        this.proPageNum++
      } else {
        this.proPageNum = 1
      }
      const params = {
        pageSize: this.proPageSize,
        pageNum: this.proPageNum
      }
      this.$apis.PROJECTRECOMMENDLIST(params).then((res) => {
        console.log('项目列表--', res)
        this.$nextTick(() => {
          if (res.data.rows && res.data.rows.length >= 0) {
            if (flag && flag == true) {
              res.data.rows.forEach((item) => {
                this.projectList.push(item)
              })
            } else {
              this.projectList = res.data.rows
            }
          }
          // this.projectList.forEach((item, index) => {
          //   this.projectList[index]['checked'] = false
          //   if (this.project && this.project.id) {
          //     if (item.id == this.project.id) {
          //       this.projectList[index]['checked'] = true
          //     }
          //   }
          // })

          this.proTotal = res.data.total
        })
      })
    },
    saveKr(data) {
      if (data.keyId) {
        this.typeForm = data
        this.confirmChange(this.krObj, '1')
      }
    },
    selectConfidence(item) {
      console.log(2121, item)
      if (item.val === 'todo') {
        if (this.activeObj.val === 'todo') return
        this.confirmChange(item, 3)
        this.$refs.taskKrPopRef.cancelHidden()
        this.getProjectList()
      } else if (item.val === 'kr') {
        this.cancelHidden()
        this.$refs.taskKrPopRef.getOkrSearchTitleList()
      } else if (this.type == 'stopdoing' && item.val === 'project') {
        this.$refs.taskKrPopRef.cancelHidden()
        this.getProjectList()
      } else if (item.val === 'project') {
        this.$refs.taskKrPopRef.cancelHidden()
        this.showProject = true
        this.getProjectList()
      }
    },
    confirmChange(item, type) {
      this.$confirm(
        '每种任务分类的字段也许不一致，切换任务分类可能会导致这些内容被清空哦～',
        '是否要切换任务分类？',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'confirmBox'
        }
      )
        .then(async () => {
          this.activeObj = item
          if (type === 3) {
            // 临时任务
            await this.$apis.TASKDELETEKR(this.taskId).then((res) => {
              if (res.status === 200) {
                this.$message.success('修改成功')
                this.$emit('changeClassify')
                this.closePop()
                this.selectKr = {}
                this.selectProject = {}
              } else if (res.status === 71100) {
                this.$message({
                  msg: '没有操作权限',
                  type: 'error'
                })
              } else {
                this.$message({
                  msg: '网络错误,稍后重试!',
                  type: 'error'
                })
              }
            })
          } else {
            const form = {
              id: this.taskId,
              objId: this.typeForm.objId || null,
              keyId: this.typeForm.keyId || null,
              cycleId: this.typeForm.cycle_id || null,
              keyTitle: this.typeForm.keyTitle,
              projectId: this.typeForm.projectId || null,
              type
            }
            this.$apis.TASKBELONGUPDATE(form).then((res) => {
              if (res.status === 200) {
                this.$message({
                  msg: '修改成功',
                  type: 'success'
                })
                this.$emit('changeClassify')
                this.closePop()
              } else {
                this.$message({
                  msg: res.message,
                  type: 'error'
                })
              }
            })
          }
        })
        .catch(() => {})
    },
    dragstartEvent(e) {
      // 禁止拖拽
      e.preventDefault()
    }
  }
}
</script>
<style lang="scss" scoped>
.project-cont-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 12px 20px;
  border-top: 1px solid rgba(17, 17, 17, 0.08);
  .cancel-primary {
    height: 32px;
    width: 96px;
    border-radius: 12px;
    margin-right: 8px;
    background: #fff;
    color: #3a78f6;
    border: 1px rgba(58, 120, 246, 1) solid;
    &:hover {
      background: #f3f7fe !important;
      color: #548af7 !important;
    }
  }
  .next-primary {
    height: 32px;
    width: 96px;
    border-radius: 12px;
    background: rgba(58, 120, 246, 1) !important;
    border: 1px rgba(58, 120, 246, 1) solid;
    &:hover {
      background: rgba(58, 120, 246, 0.87) !important;
      border: 1px rgba(58, 120, 246, 0.87) solid;
    }
  }
}
.pm-content-div {
  width: 476px;
  .pm-title {
    padding-left: 12px;
    font-size: 20px;
    font-weight: bold;
    color: #14142b;
    line-height: 32px;
  }
}
.task-type-list {
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 208px;
  .pop-title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #14142b;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    position: relative;
    text-align: center;
    margin: 2px 8px 4px;
    padding-bottom: 14px;
    border-bottom: 1px solid #f0f1f6;
    .close-icon {
      position: absolute;
      color: #6e7491;
      width: 28px;
      height: 28px;
      border-radius: 8px;
      cursor: pointer;
      left: 0;
      top: 0;
      &:last-of-type {
        margin-left: auto;
        left: unset;
        right: 0;
      }
      &:hover {
        background: #f5f6fa;
      }
    }
  }
  .project-select {
    display: flex;
    height: 46px;
    font-size: 14px;
    align-items: center;
    justify-content: space-between;
    color: #a0a3bd;
    padding: 0 12px;
    .select-item {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #14142b;
      max-width: 150px;
      .arrow-icon {
        color: #a0a3bd;
        margin-left: 6px;
        font-size: 16px;
      }
    }
  }
  .confirm-btn {
    width: 166px;
    height: 32px;
    padding: 0;
    border-radius: 12px;
    margin: 48px 24px 12px;
  }
}
.classify-box {
  display: flex;
  font-size: 14px;
  width: 120px;
  height: 28px;
  line-height: 28px;
  background: rgba(50, 150, 250, 0.1);
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  color: #005eff;
  cursor: pointer;
  .classify-icon {
    font-size: 16px;
    margin-right: 8px;
  }
  .arrow-icon {
    font-size: 20px;
    margin-left: 6px;
  }
  .confidence-text {
    font-weight: 500;
    font-size: 14px;
  }
  .check-icon {
    font-size: 20px;
    color: #3a78f6;
    margin-left: auto;
  }
}
.stopdoing-box {
  height: 34px;
  line-height: 34px;
  padding: 0 12px;
  font-size: 14px;
  font-weight: 500;
  color: #3a70ff;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px #3a70ff solid;
  cursor: pointer;
  border-radius: 33px;
  .classify-icon {
    font-size: 20px;
    color: #3a70ff;
    margin-right: 8px;
  }
  &:hover {
    border: 1px #548af7 solid;
    color: #548af7;
    .status-icon {
      color: #548af7;
    }
  }
}
.classify-box-select {
  width: 200px;
  height: 46px;
  background: transparent;
  justify-content: flex-start;
  color: #6e7491;
  .classify-icon {
    font-size: 18px;
  }
  &:hover {
    color: #444558;
  }
}
.task-type-item {
  display: flex;
  font-size: 14px;
  color: #6e7491;
  line-height: 24px;
  padding: 2px 12px;
  align-items: center;
  border-radius: 8px;
  span {
    color: #14142b;
  }
  &:hover {
    background: #f5f6fa;
  }
}
</style>
<style lang="scss">
.project-list {
  height: 300px;
  padding: 12px 4px;
  overflow-y: auto;
  overflow-x: hidden;
  .project-item {
    display: flex;
    align-items: center;
    // width: 220px;
    height: 46px;
    border-radius: 8px;
    color: #000;
    font-size: 14px;
    line-height: 24px;
    padding: 0 12px;
    cursor: pointer;
    img {
      width: 24px;
      height: 24px;
      border-radius: 5px;
      margin-right: 8px;
    }
    .project-name {
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &:hover {
      background: #f5f6fa;
    }
    .check-icon {
      font-size: 20px;
      color: #3a78f6;
      margin-left: auto;
      margin-right: 8px;
    }
  }
  .no-project {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
    img {
      width: 120px;
    }
  }
}
.task-type-pop {
  padding: 12px 4px;
}
.task-kr-pop {
  width: 500px;
}
.task-project-pop {
  width: 232px;
  min-height: 200px;
  max-height: 300px;
  padding: 0;
}
</style>
