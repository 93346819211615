// 统计分析模块方法库
import apis from '../apis_moudles/index'
import req from '../request'
var $isQw =
  window.location.host.includes('qywx') || window.location.host.includes('qw')
// 获取个人统计分析报表
const PERSONREPORTLIST = (params) => {
  return req.get(apis.personReportList, {
    params
  })
}
// 获取单个报表统计
const GETREPORTS = (params) => {
  return req.get(apis.getReports, {
    params
  })
}
// 报表排序
const REPORTSORT = (params) => {
  return req.post(apis.reportSort, params)
}
// 删除单个报表
const REPORTDELETE = (params) => {
  return req.post(apis.reportDelete, params)
}
// 获取所有报表
const REPORSTLIST = (params) => {
  return req.get(apis.reportList, {
    params
  })
}
// 添加报表
const REPORTADD = (params) => {
  return req.post(apis.reportAdd, params)
}
// 更新报表
const REPORTUPDATE = (params) => {
  return req.post(apis.reportUpdate, params)
}
// 周期人员部门列表
const DEPARTLIST = (params) => {
  return req.get(apis.departList, {
    params
  })
}
// 获取统计报表详情
const REPORTDETAIL = (params) => {
  return req.post(apis.reportDetail, params)
}
// 获取统计报表详情-分页
const REPORTDETAILPAGE = (params) => {
  return req.post(apis.reportDetailPage, params)
}
// 下载报表
const REPORTDOWNLOAD = (params) => {
  return req.post(apis.reportDownload, params, {
    responseType: $isQw ? '' : 'arraybuffer'
  })
}
// 获取企业统计分析报表列表
const REPORTCOMPANYLIST = (params) => {
  return req.get(apis.reportCompanyList, {
    params
  })
}
// 获取企业统计分析单个报表概览
const REPORTCOMPANYVIEW = (params) => {
  return req.post(apis.reportCompanyView, params)
}
// 获取企业统计分析单个报表概览
const REPORTCOMPANYINFO = (params) => {
  return req.post(apis.reportCompanyInfo, params)
}
// 风险预警提醒列表（我收到的）
const REPORTREMINDSEND = (params) => {
  return req.post(apis.reportRemindSend, params)
}
// 获取企业统计报表详情-分页
const REPORTCOMPANYDETAILPAGE = (params) => {
  return req.post(apis.reportCompanyDetailPage, params)
}
// 预警提醒设置列表
const REMINDSETLIST = (params) => {
  return req.get(apis.remindSetList, {
    params
  })
}
// 预警提醒设置
const REMINDSET = (params) => {
  return req.post(apis.remindSet, params)
}
// 预警提醒设置
const REMINDDISPOSEHANDLE = (params) => {
  return req.post(apis.remindDisposeHandle, params)
}
// 获取部门统计分析报表列表
const REPORTDEPARTLIST = (params) => {
  return req.get(apis.reportDepartList, {
    params
  })
}
// 获取部门统计分析单个报表概览
const REPORTDEPARTVIEW = (params) => {
  return req.post(apis.reportDepartView, params)
}
// 获取部门统计分析单个报表概览
const REPORTDEPARTINFO = (params) => {
  return req.post(apis.reportDepartInfo, params)
}
// 获取部门统计报表详情-分页
const REPORTDEPARTDETAILPAGE = (params) => {
  return req.post(apis.reportDepartDetailPage, params)
}
// 获取统计分析成员详情数据-分页
const REPORTREMINDUSERINFOTABLE = (params) => {
  return req.post(apis.reportRemindUserInfoTable, params)
}
// 根据index查询模版默认 标题 内容
const REMINDSETDEFAULT = (params) => {
  return req.get(apis.remindSetDefault, {
    params
  })
}
// 根据index查询模版默认 标题 内容
const REMINDSETIGNORE = (params) => {
  return req.post(apis.remindSetIgnore, params)
}
// 工作台风险预警成员详情页-分页
const REMINDWORKBENCHUSER = (params) => {
  return req.post(apis.remindWorkbenchUser, params)
}
// 部门下载报表
const DEPARTMENTDOWNLOAD = (params) => {
  return req.post(apis.departmentDownload, params, {
    responseType: $isQw ? '' : 'arraybuffer'
  })
}
// 企业下载报表
const COMPANYDOWNLOAD = (params) => {
  return req.post(apis.companyDownload, params, {
    responseType: $isQw ? '' : 'arraybuffer'
  })
}
// 成员详情页-2-下载成员详情数据
const DEPARTUSERINFODOWNLOAD = (params) => {
  return req.post(apis.departmentUserInfoDownload, params, {
    responseType: $isQw ? '' : 'arraybuffer'
  })
}
// 工作台风险预警2-下载成员详情数据
const WORKUSERINFODOWNLOAD = (params) => {
  return req.post(apis.workUserInfoDownload, params, {
    responseType: $isQw ? '' : 'arraybuffer'
  })
}
// 根据周期id和人员id 查询目标列表
const TABLEPERSONOKRLIST = (params) => {
  return req.get(apis.tablePersonOkrList, {
    params
  })
}
// 企微-查询个人各类统计报表导出(通讯录转译)数据列表-分页
const REPORTDATAEXPORTLIST = (params) => {
  return req.get(apis.reportDataExportList, {
    params
  })
}
// 组织架构人员--导出
const ADMINDEPARTMENTUSEREXPORT = (params) => {
  return req.post(`${apis.adminDepartmentUserExport}`, params, {
    responseType: ''
  })
}
export default [
  {
  ADMINDEPARTMENTUSEREXPORT
},
{
  REPORTDATAEXPORTLIST
},
{
  TABLEPERSONOKRLIST
},
{
  WORKUSERINFODOWNLOAD
},
{
  DEPARTUSERINFODOWNLOAD
},
{
  COMPANYDOWNLOAD
},
{
  DEPARTMENTDOWNLOAD
},
{
  REMINDWORKBENCHUSER
},
{
  REMINDSETIGNORE
},
{
  REMINDSETDEFAULT
},
{
  REPORTREMINDUSERINFOTABLE
},
{
  REPORTDEPARTDETAILPAGE
},
{
  REPORTDEPARTINFO
},
{
  REPORTDEPARTVIEW
},
{
  REPORTDEPARTLIST
},
{
  REMINDDISPOSEHANDLE
},
{
  REMINDSET
},
{
  REMINDSETLIST
},
{
  REPORTCOMPANYDETAILPAGE
},
{
  REPORTREMINDSEND
},
{
  REPORTCOMPANYINFO
},
{
  REPORTCOMPANYVIEW
},
{
  REPORTCOMPANYLIST
},
{
  PERSONREPORTLIST
},
{
  GETREPORTS
},
{
  REPORTSORT
},
{
  REPORTDELETE
},
{
  REPORSTLIST
},
{
  REPORTADD
},
{
  REPORTUPDATE
},
{
  DEPARTLIST
},
{
  REPORTDETAIL
},
{
  REPORTDETAILPAGE
},
{
  REPORTDOWNLOAD
}
]
