export default {
  path: '/authOld',
  redirect: '/loginOld',
  name: 'authOld',
  component: () => {
    return import(
      /* webpackChunkName: "authOld" */
      '../../views/global/auth_old/index.vue'
    )
  },
  children: [
    {
    path: '/loginOld',
    name: 'loginOld',
    component: () => {
      return import(
        /* webpackChunkName: "authOld" */
        '../../views/global/auth_old/login.vue'
      )
    }
  },
  {
    path: '/registerOld',
    name: 'registerOld',
    component: () => {
      return import(
        /* webpackChunkName: "authOld" */
        '../../views/global/auth_old/register.vue'
      )
    }
  },
  {
    path: '/invitationOld',
    name: 'invitationOld',
    component: () => {
      return import(
        /* webpackChunkName: "authOld" */
        '../../views/global/auth_old/invitation.vue'
      )
    }
  },
  {
    path: '/resetPasswordOld',
    name: 'resetPasswordOld',
    component: () => {
      return import(
        /* webpackChunkName: "authOld" */
        '../../views/global/auth_old/resetPassword.vue'
      )
    }
  },
  {
    path: '/successNoticeOld',
    name: 'successNoticeOld',
    component: () => {
      return import(
        /* webpackChunkName: "authOld" */
        '../../views/global/auth_old/successNotice.vue'
      )
    }
  }
  ]
}
