<template>
  <div
    class="kr-item-box"
    :class="item.checked ? 'selected' : ''"
    @click.stop="krClickTask(item, 'recommended')"
  >
    <div class="flex-new flex-new-direction-column flex-1">
      <div class="kr-title flex-new flex-1">
        <span class="kr-span">KR:</span>
        <p class="task-back-p ellipsis-1-0" v-html="item.title"></p>
      </div>
      <div class="flex-new task-back-txt-box">
        <p class="task-back-txt ellipsis-1-0">
          O：
          <span v-html="item.content">{{ item.content }}</span>
        </p>
      </div>
    </div>
    <div class="checked-div">
      <Icon v-if="item.checked" icon-class="icon-draw_checked" class="task-svg-icon" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  methods: {
    krClickTask() {
      this.$emit('check', this.item)
    }
  }
}
</script>
<style lang="scss" scoped>
.flex-new {
  display: flex;
}
.flex-new-direction-column {
  flex-direction: column;
  width: calc(100% - 30px);
}
.flex-space-between {
  align-items: center;
  justify-content: space-between;
}
.justify-end {
  justify-content: flex-end;
}
.flex-1 {
  flex: 1;
}
.checked-div {
  width: 30px;
  line-height: 30px;
  margin-left: 14px;
}
.kr-item-box {
  line-height: 26px;
  display: flex;
  padding: 8px 16px 8px 16px;
  font-weight: 400;
  cursor: pointer;
  color: #6e7491;
  align-items: center;
  .kr-title {
    display: flex;
    align-items: center;
    .kr-span {
      width: 32px;
    }
  }
  .task-back-p {
    max-width: 440px;
    font-size: 14px;
    color: #444558;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-word;
    &:before {
      content: '';
      display: block;
    }
    &.task-detail-p {
      max-width: 480px;
    }
  }
  .task-back-txt-box {
    padding-left: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 24px;
    word-break: break-word;
    span {
      line-height: 16px;
    }
    .task-back-txt {
      color: #6e7491;
      line-height: 16px;
      font-size: 12px;
      max-width: 580px;
      &.task-detail-txt {
        max-width: 470px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-word;
        &:before {
          content: '';
          display: block;
        }
      }
    }
  }
  .task-top-right-box-project {
    padding: 4px;
    .task-project-svg-icon {
      margin-right: 10px;
      border-radius: 4px;
    }
  }
  .task-project-svg-icon {
    width: 26px;
    height: 26px;
    background: var(--color) !important;
  }
  .task-svg-icon {
    width: 20px;
    height: 20px;
    font-size: 20px;
    margin-right: 5px;
    margin-top: 8px;
  }
  &:hover {
    background: rgba(231, 233, 240, 0.5);
  }
  &.selected {
    background: #f5f6fa;
  }
}
</style>
