export default [{
    path: '/kpiV2',
    component: () => {
      return import( /* webpackChunkName: "kpi" */ '../../views/kpiV2')
    },
    children: [{
        path: '/',
        name: 'myWait',
        component: () => {
          return import(
            /* webpackChunkName: "kpimyWait" */
            '../../views/kpiV2/myWait'
          )
        }
      },
      {
        path: '/kpiV2/myKpi',
        name: 'myKpi',
        component: () => {
          return import(
            /* webpackChunkName: "kpimyKpi" */
            '../../views/kpiV2/myKpi'
          )
        }
      },
      {
        path: '/kpiV2/myDepart',
        name: 'myDepart',
        component: () => {
          return import(
            /* webpackChunkName: "kpimymyDepart" */
            '../../views/kpiV2/myDepart'
          )
        }
      },
      {
        path: '/kpiV2/kpiPlan',
        name: 'kpiList',
        component: () => {
          return import(
            /* webpackChunkName: "kpiList" */
            '../../views/kpiV2/kpiPlan'
          )
        }
      },
      {
        path: '/kpiV2/kpiAnalyse',
        name: 'kpiAnalyse',
        component: () => {
          return import(
            /* webpackChunkName: "kpiAnalyse" */
            '../../views/kpiV2/kpiAnalyse'
          )
        }
      }
    ]
  },
  {
    path: '/kpiV2/setting',
    component: () => {
      return import(
        /* webpackChunkName: "kpisetting" */
        '../../views/kpiV2/setting'
      )
    },
    children: [{
        path: '/',
        name: 'setting',
        component: () => {
          return import(
            /* webpackChunkName: "settingindexBase" */
            '../../views/kpiV2/setting/indexBase'
          )
        }
      },
      {
        path: 'kpiGrade',
        name: 'kpiGrade',
        component: () => {
          return import(
            /* webpackChunkName: "settingindexBase" */
            '../../views/kpiV2/setting/kpiGrade'
          )
        }
      },
      {
        path: 'kpiRelation',
        name: 'kpiRelation',
        component: () => {
          return import(
            /* webpackChunkName: "settingkpiRelation" */
            '../../views/kpiV2/setting/kpiRelation'
          )
        }
      },
      {
        path: 'kpiTemplate',
        name: 'kpiTemplate',
        component: () => {
          return import(
            /* webpackChunkName: "settingkpiTemplate" */
            '../../views/kpiV2/setting/kpiTemplate'
          )
        }
      },
      {
        path: 'kpiField',
        name: 'kpiField',
        component: () => {
          return import(
            /* webpackChunkName: "settingkpiField" */
            '../../views/kpiV2/setting/kpiField'
          )
        }
      }
    ]
  },
  // 添加考评关系
  {
    path: '/kpiV2/setting/kpiRelation/addRelation',
    component: () => {
      return import(
        /* webpackChunkName: "addRelation" */
        '../../views/kpiV2/setting/kpiRelation/addRelation'
      )
    }
  },
  // 考核计划详情
  {
    path: '/kpiV2/kpiPlan/kpiDetail',
    component: () => {
      return import(
        /* webpackChunkName: "addRelation" */
        '../../views/kpiV2/kpiPlan/kpiDetail'
      )
    }
  },
  // 新建考核计划-基本信息
  {
    path: '/kpiV2/kpiPlan/addKpi',
    component: () => {
      return import(
        /* webpackChunkName: "wholeResultConfirm" */
        '../../views/kpiV2/kpiPlan/addKpi'
      )
    }
  },
  // 新建考核计划-考核人员与模版
  {
    path: '/kpiV2/kpiPlan/addKpi/kpiUserTemplate',
    component: () => {
      return import(
        /* webpackChunkName: "wholeResultConfirm" */
        '../../views/kpiV2/kpiPlan/addKpi/kpiUserTemplate'
      )
    }
  },
  // 整体结果校准
  {
    path: '/kpiV2/kpiPlan/wholeResultConfirm',
    component: () => {
      return import(
        /* webpackChunkName: "wholeResultConfirm" */
        '../../views/kpiV2/kpiPlan/wholeResultConfirm'
      )
    }
  },
  // 添加考评关系
  {
    path: '/kpiV2/setting/kpiRelation/addRelation',
    component: () => {
      return import(
        /* webpackChunkName: "addRelation" */
        '../../views/kpiV2/setting/kpiRelation/addRelation'
      )
    }
  },
  // 考核计划详情
  {
    path: '/kpiV2/kpiPlan/kpiDetail',
    component: () => {
      return import(
        /* webpackChunkName: "kpiDetail" */
        '../../views/kpiV2/kpiPlan/kpiDetail'
      )
    }
  },
  // 新建考核计划-基本信息
  {
    path: '/kpiV2/kpiPlan/addKpi',
    component: () => {
      return import(
        /* webpackChunkName: "addKpi" */
        '../../views/kpiV2/kpiPlan/addKpi'
      )
    }
  },
  // 新建考核计划-考核人员与模版
  {
    path: '/kpiV2/kpiPlan/addKpi/kpiUserTemplate',
    component: () => {
      return import(
        /* webpackChunkName: "kpiUserTemplate" */
        '../../views/kpiV2/kpiPlan/addKpi/kpiUserTemplate'
      )
    }
  },
  // 新建考核计划-考核流程
  {
    path: '/kpiV2/kpiPlan/addKpi/kpiProcess',
    component: () => {
      return import(
        /* webpackChunkName: "kpiProcess" */
        '../../views/kpiV2/kpiPlan/addKpi/kpiProcess'
      )
    }
  },
  // 新建考核计划-考核等级设置
  {
    path: '/kpiV2/kpiPlan/addKpi/kpiGrade',
    component: () => {
      return import(
        /* webpackChunkName: "kpiGrade" */
        '../../views/kpiV2/kpiPlan/addKpi/kpiGrade'
      )
    }
  },
  // 整体结果校准
  {
    path: '/kpiV2/kpiPlan/wholeResultConfirm',
    component: () => {
      return import(
        /* webpackChunkName: "wholeResultConfirm" */
        '../../views/kpiV2/kpiPlan/wholeResultConfirm'
      )
    }
  },
  {
    path: '/kpiV2/myWait/kpiDetail',
    component: () => {
      return import(
        /* webpackChunkName: "addRelation" */
        '../../views/kpiV2/myWait/kpiDetail'
      )
    }
  },
  // 添加考评关系
  {
    path: '/kpiV2/setting/kpiRelation/addRelation',
    component: () => {
      return import(
        /* webpackChunkName: "addRelation" */
        '../../views/kpiV2/setting/kpiRelation/addRelation'
      )
    }
  },
  // 考核计划详情
  {
    path: '/kpiV2/kpiPlan/kpiDetail',
    component: () => {
      return import(
        /* webpackChunkName: "addRelation" */
        '../../views/kpiV2/kpiPlan/kpiDetail'
      )
    }
  },
  // 新建考核计划-基本信息
  {
    path: '/kpiV2/kpiPlan/addKpi',
    component: () => {
      return import(
        /* webpackChunkName: "wholeResultConfirm" */
        '../../views/kpiV2/kpiPlan/addKpi'
      )
    }
  },
  // 新建考核计划-考核人员与模版
  {
    path: '/kpiV2/kpiPlan/addKpi/kpiUserTemplate',
    component: () => {
      return import(
        /* webpackChunkName: "wholeResultConfirm" */
        '../../views/kpiV2/kpiPlan/addKpi/kpiUserTemplate'
      )
    }
  },
  // 绩效-我的待办详情
  {
    path: '/kpiV2/myWait/kpiDetail',
    component: () => {
      return import(
        /* webpackChunkName: "addRelation" */
        '../../views/kpiV2/myWait/kpiDetail/index'
      )
    }
  },
  // 添加考评关系
  {
    path: '/kpiV2/setting/kpiRelation/addRelation',
    component: () => {
      return import(
        /* webpackChunkName: "addRelation" */
        '../../views/kpiV2/setting/kpiRelation/addRelation'
      )
    }
  },
  // 考核计划详情
  {
    path: '/kpiV2/kpiPlan/kpiDetail',
    component: () => {
      return import(
        /* webpackChunkName: "kpiDetail" */
        '../../views/kpiV2/kpiPlan/kpiDetail'
      )
    }
  },
  // 新建考核计划-基本信息
  {
    path: '/kpiV2/kpiPlan/addKpi',
    component: () => {
      return import(
        /* webpackChunkName: "addKpi" */
        '../../views/kpiV2/kpiPlan/addKpi'
      )
    }
  },
  // 新建考核计划-考核人员与模版
  {
    path: '/kpiV2/kpiPlan/addKpi/kpiUserTemplate',
    component: () => {
      return import(
        /* webpackChunkName: "kpiUserTemplate" */
        '../../views/kpiV2/kpiPlan/addKpi/kpiUserTemplate'
      )
    }
  },
  // 新建考核计划-考核流程
  {
    path: '/kpiV2/kpiPlan/addKpi/kpiProcess',
    component: () => {
      return import(
        /* webpackChunkName: "kpiProcess" */
        '../../views/kpiV2/kpiPlan/addKpi/kpiProcess'
      )
    }
  },
  // 新建考核计划-考核等级设置
  {
    path: '/kpiV2/kpiPlan/addKpi/kpiGrade',
    component: () => {
      return import(
        /* webpackChunkName: "kpiGrade" */
        '../../views/kpiV2/kpiPlan/addKpi/kpiGrade'
      )
    }
  },
  // 整体结果校准
  {
    path: '/kpiV2/kpiPlan/wholeResultConfirm',
    component: () => {
      return import(
        /* webpackChunkName: "wholeResultConfirm" */
        '../../views/kpiV2/kpiPlan/wholeResultConfirm'
      )
    }
  },
  // 行业指标库
  {
    path: '/kpiV2/setting/indexBase/industryIndex',
    component: () => {
      return import(
        /* webpackChunkName: "industryIndex" */
        '../../views/kpiV2/setting/indexBase/industryIndex'
      )
    }
  },
  // 行业指标库
  {
    path: '/test',
    component: () => {
      return import(
        /* webpackChunkName: "industryIndex" */
        '../../views/test'
      )
    }
  }
]
