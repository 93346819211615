<template>
  <el-dropdown trigger="click" :hide-on-click="false" @command="clickItem">
    <div class="day-select">
      <span v-if="selectVals.length > 0">{{ selectVals.join(',') }}</span>
      <span v-else>请选择</span>
    </div>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item
        v-for="(val, index) in dayOption"
        :key="index"
        :command="index"
      >
        <div class="day-option" :class="val ? 'active-option' : ''">
          <div>{{ index + 1 }}</div>
          <i v-show="val" class="el-icon-check"></i>
        </div>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>
<script>
export default {
  model: {
    prop: 'daySelectsVal',
    event: 'change'
  },
  props: {
    daySelectsVal: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      selectVals: this.daySelectsVal,
      dayOption: Array(7).fill(false)
    }
  },
  watch: {
    daySelectsVal: {
      handler(val) {
        this.dayOption = Array(7).fill(false)
        if (!val) return
        val.forEach(item => {
          this.dayOption[item - 1] = true
        })
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    clickItem(index) {
      console.log(index)
      const list = []
      this.dayOption[index] = !this.dayOption[index]
      this.dayOption.forEach((item, index) => {
        if (item) {
          list.push(index + 1)
        }
      })
      this.selectVals = list
      this.$emit('change', this.selectVals)
    }
  }
}
</script>
<style lang="scss" scoped>
.day-select {
  width: 120px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #F5F6FA;
  border-radius: 8px;
  padding: 0 8px;
}
.day-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  i {
    font-size: 16px;
    color: #409efe;
  }
}
.active-option {
  color: #409efe;
}
</style>
