var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "custom-class": "insertlink-dialog",
            title: "添加链接",
            visible: _vm.isShow,
            "before-close": _vm.closeInsertLink,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShow = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "linkForm",
              attrs: { model: _vm.linkForm, rules: _vm.linkFormRules },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "链接文字" },
                    model: {
                      value: _vm.linkForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.linkForm, "name", $$v)
                      },
                      expression: "linkForm.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "link" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "链接地址" },
                    model: {
                      value: _vm.linkForm.link,
                      callback: function ($$v) {
                        _vm.$set(_vm.linkForm, "link", $$v)
                      },
                      expression: "linkForm.link",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "submit-link",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitLinkForm("linkForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }