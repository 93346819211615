const versionNumber = 'v1' // 版本
// 总结模块api
export default [
  // 获取模版管理列表
  {
    conclusionManageList: `/api/ranloWeb/${versionNumber}/summarize/custom/template/manage/list`
  },
  // 新建模版
  {
    conclusionAddTemplate: `/api/ranloWeb/${versionNumber}/summarize/custom/template`
  },
  // 复制模版
  {
    conclusionCopyTemplate: `/api/ranloWeb/${versionNumber}/summarize/custom/template/coppy/`
  },
  // 删除模版
  {
    conclusionDeleteTemplate: `/api/ranloWeb/${versionNumber}/summarize/custom/template/`
  },
  // 保存模版权限
  {
    conclusionTemplatePerConfirm: `/api/ranloWeb/${versionNumber}/summarize/custom/template/authority`
  },
  // 获取模版权限
  {
    conclusionTemplatePerGet: `/api/ranloWeb/${versionNumber}/summarize/custom/template/authority/`
  },
  // 使用模板列表
  {
    conclusionTemplateChoiceList: `/api/ranloWeb/${versionNumber}/summarize/custom/template/choice/list`
  },
  // 模板详情
  {
    conclusionTemplateDetail: `/api/ranloWeb/${versionNumber}/summarize/custom/template`
  },

  // 总结2.0
  // 查询总结列表（分页）
  {
    allSummarizeList: `/api/ranloWeb/${versionNumber}/summarize/page/list`
  },
  // 添加总结共享人
  {
    addSummarizeShare: `/api/ranloWeb/${versionNumber}/summarize/receiver/user`
  },
  // 撤回总结
  {
    undoSummarize: `/api/ranloWeb/${versionNumber}/summarize/withdraw/`
  },
  // 删除总结
  {
    delSummarize: `/api/ranloWeb/${versionNumber}/summarize/`
  },
  // 查询总结详情
  {
    summarizeDetail: `/api/ranloWeb/${versionNumber}/summarize/info/`
  },
  // 添加总结
  {
    addSummarize: `/api/ranloWeb/${versionNumber}/summarize/save`
  },
  // 添加总结评论
  {
    addSummarizeComment: `/api/ranloWeb/${versionNumber}/summarize/comment/save`
  },
  // 查询总结评论列表（分页）
  {
    summarizeCommentList: `/api/ranloWeb/${versionNumber}/summarize/comment/page/list`
  },
  // 查询某人的总结列表
  {
    userSummarizeList: `/api/ranloWeb/${versionNumber}/summarize/page/list/user`
  },
  // 总结选择任务列表
  {
    conclusionSummarizeTaskList: `/api/ranloTask/${versionNumber}/task/getSummarizeTaskList`
  },
  // 薛 - 总结统计规则
  // 总结模版规则列表（我需要提交的）
  {
    conclusionRuleList: `/api/ranloWeb/${versionNumber}/summarize/statistics/rule/write/list`
  },
  // 获取规则开始时间列表
  {
    ruleStartList: `/api/ranloWeb/${versionNumber}/summarize/statistics/rule/select/start/list`
  },
  // 获取规则结束时间列表
  {
    ruleEndList: `/api/ranloWeb/${versionNumber}/summarize/statistics/rule/select/end/list`
  },
  // 总结规则列表（我管理的）
  {
    conclusionRuleAdminList: `/api/ranloWeb/${versionNumber}/summarize/statistics/rule/admin/list`
  },
  // 总结规则详情
  {
    conclusionRuleDetails: `/api/ranloWeb/${versionNumber}/summarize/statistics/rule/info`
  },
  // 选择模版列表
  {
    selTemplateList: `/api/ranloWeb/${versionNumber}/summarize/custom/template/manage/list`
  },
  // 总结新增规则
  {
    addConclusionRule: `/api/ranloWeb/${versionNumber}/summarize/statistics/rule/`
  },
  // 总结规则删除
  {
    delConclusionRule: `/api/ranloWeb/${versionNumber}/summarize/statistics/rule/`
  },
  // 总结统计编辑回显
  {
    editConclusionRule: `/api/ranloWeb/${versionNumber}/summarize/statistics/rule/echo`
  },
  // 总结列表 筛选
  {
    conclusionScreen: `/api/ranloWeb/${versionNumber}/summarize/page/list/condition`
  },
  // 总结统计-根据条件查询统计总结
  {
    conclusionAnalyseList: `/api/analysis/${versionNumber}/report/summarize/list/`
  },
  // 总结统计-根据条件统计导出总结
  {
    conclusionExportAll: `/api/analysis/${versionNumber}/report/summarize/export/`
  },
  // 总结统计-根据总结统计列表中勾选数据导出表格
  {
    conclusionExportSelect: `/api/analysis/${versionNumber}/report/summarize/list/export/`
  },
  // 删除总结评论
  {
    conclusionDeleteComment: `/api/ranloWeb/${versionNumber}/summarize/comment/`
  },
  // 评论我的
  {
    commentPageListUser: `/api/ranloWeb/${versionNumber}/summarize/comment/page/list/user`
  },
  // 退回
  {
    summarizeFallback: `/api/ranloWeb/${versionNumber}/summarize/fallback`
  },
  // 总结设置查询
  {
    summarizeSetSelect: `/api/ranloWeb/${versionNumber}/summarize/set/select`
  },
  // 总结设置保存/修改
  {
    summarizeSetUpdate: `/api/ranloWeb/${versionNumber}/summarize/set/update`
  },
  // 总结统计规则--复制
  {
    summarizeStatisticsRuleCopy: `/api/ranloWeb/${versionNumber}/summarize/statistics/rule/copy`
  },
  // 总结取消点赞
  {
    summarizeLaudDelete: `/api/ranloWeb/${versionNumber}/summarize/laud/delete`
  },
  // 总结点赞
  {
    summarizeLaudSave: `/api/ranloWeb/${versionNumber}/summarize/laud/save`
  },
  // 查询总结点赞列表
  {
    summarizeLaudList: `/api/ranloWeb/${versionNumber}/summarize/laud/list`
  },
  // 总结统计规则--批量发送提醒
  {
    summarizeStatisticsRuleSendNotify: `/api/ranloWeb/${versionNumber}/summarize/statistics/rule/sendNotify`
  },
  // 根据模版id,查询总结草稿
  {
    summarizeInfoTemplate: `/api/ranloWeb/${versionNumber}/summarize/info/template`
  },
  // (new)查询是否是总结管理员
  {
    summarizeSetSelectIsAdmin: `/api/ranloWeb/${versionNumber}/summarize/set/selectIsAdmin`
  },
  // 我需要提交数量
  {
    summarizeRuleTemplateCount: `/api/ranloWeb/${versionNumber}/summarize/custom/template/rule/template/count`
  },
  // (new)总结提交规则-根据选中明细导出
  {
    submitRuleInfoIdsExport: `/api/analysis/${versionNumber}/report/summarize/submitRuleInfo/ids/export/`
  },
  // (new)总结提交规则-明细导出
  {
    submitRuleInfoExport: `/api/analysis/${versionNumber}/report/summarize/submitRuleInfo/export/`
  },
  // (new)总结提交规则-明细查询
  {
    submitRuleInfoList: `/api/analysis/${versionNumber}/report/summarize/submitRuleInfo/list/`
  }
]
