<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :append-to-body="true"
    center
    :size="360"
    :modal-append-to-body="true"
    :before-close="dialogClose"
    custom-class="workhours-static-dialog"
    :show-close="true"
    title="工时统计"
  >
    <div class="workhours-static-main">
      <div @click="lookDetailed()" class="look-detailed">查看明细</div>
      <div class="static-div">
        <div class="static-left">
          <div class="static-plan">
            <div class="plan-title-div">
              <div class="title">计划工时</div>
              <div v-if="!disabled" class="operation" @click="openSetWorkHours('1')">+ 预估</div>
            </div>
            <div class="plan-data-div">
              <span class="num">{{ planHourTotal }}</span>
              <span class="unit">小时</span>
            </div>
          </div>
          <div class="static-reality">
            <div class="plan-title-div">
              <div class="title">实际工时</div>
              <div v-if="!disabled" class="operation" @click="openSetWorkHours('2')">+ 登记</div>
            </div>
            <div class="plan-data-div">
              <span class="num">{{ actualHourTotal }}</span>
              <span class="unit">小时</span>
            </div>
          </div>
        </div>
        <div class="static-right">
          <div class="static-title">工时</div>
          <Echart ref="base" />
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('user', {
      userInfo: 'userInfo' // 用户信息
    })
  },
  components: {},
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  props: {
    dialogShow: {
      type: Boolean
    },
    taskId: {
      type: Number
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: this.dialogShow,
      planHourTotal: 0, // 预计工时
      actualHourTotal: 0, // 实际工时
      startDate: '',
      endDate: ''
    }
  },
  mounted() {
    console.log('用户信息--', this.userInfo)
    this.echartInit()
    this.bus(this).$on(
      'global:setWorkHoursRefresh',
      (source, taskId, hourType, totalHour) => {
        this.echartInit()
      }
    )
  },
  methods: {
    openSetWorkHours(type) {
      let title = type == 1 ? '计划工时' : '实际工时'
      this.bus(this).$emit('setWorkHours', title, 'add')
    },
    lookDetailed() {
      this.bus(this).$emit('lookDetailed')
    },
    echartInit() {
      const params = {
        taskId: this.taskId
      }
      this.$apis.TASKWORKHOURSINFOLISTTOTAL(params).then((res) => {
        if (res.status == 200) {
          console.log(233, res)
          this.planHourTotal = res.data.planHourTotal
          this.actualHourTotal = res.data.actualHourTotal
          const option = {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            legend: {
              itemWidth: 10, //标志图形的长度
              itemHeight: 10, //标志图形的宽度
              bottom: -5,
              left: 'center'
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '30px',
              top: '16px',
              containLabel: true
            },
            xAxis: {
              type: 'value',
              boundaryGap: [0, 0.01]
            },
            yAxis: {
              type: 'category',
              data: []
            },
            series: [
              {
                name: '计划工时      ',
                type: 'bar',
                color: 'rgba(32, 204, 158, 1)',
                barWidth: 60, //柱图宽度
                data: [this.planHourTotal]
              },
              {
                name: '实际工时',
                type: 'bar',
                color: 'rgba(84, 138, 247, 1)',
                barWidth: 60, //柱图宽度
                data: [this.actualHourTotal]
              }
            ]
          }
          option.selfStyle = 'width:100%;height:280px;margin:0 auto'
          // optionList = taskStay
          this.$nextTick(() => {
            this.$refs['base'] && this.$refs['base'].renderChart(option)
          })
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 弹窗关闭
    dialogClose() {
      this.dialogVisible = false
      this.$emit('change', false)
      this.bus(this).$emit('closeWorkHoursStatic')
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep {
  .el-dialog.workhours-static-dialog {
    width: 680px !important;
    .el-dialog__header {
      padding: 24px 24px 0 24px !important;
      text-align: left;
      .el-dialog__title {
        font-size: 20px;
        font-weight: bold;
        color: #14142b;
      }
    }
    .el-dialog__headerbtn .el-dialog__close {
      position: absolute;
      top: -10px;
      right: 0px;
      z-index: 9;
    }
    .el-dialog__body {
      padding: 0 24px 24px 24px !important;
    }
  }
}
.workhours-static-main {
  position: relative;
  .look-detailed {
    width: 80px;
    text-align: right;
    font-size: 14px;
    font-weight: 400;
    color: #3a70ff;
    position: absolute;
    right: 12px;
    top: -16px;
    cursor: pointer;
  }
  .static-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    .static-left {
      width: 180px;
      margin-right: 24px;
      .plan-data-div {
        width: 100%;
        text-align: center;
        margin-top: 14px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        span.num {
          font-size: 26px;
          font-weight: bold;
          color: #444558;
          display: inline-block;
          flex-wrap: nowrap;
        }
        span.unit {
          display: inline-block;
          font-size: 14px;
          font-weight: 400;
          color: #444558;
          margin-left: 8px;
          margin-bottom: 4px;
          min-width: 30px;
        }
      }
      .static-plan {
        width: 166px;
        height: 104px;
        border-radius: 8px;
        border: 1px solid #d9dbe9;
        padding: 16px;
        margin-top: 16px;

        .plan-title-div {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .title {
            font-size: 14px;
            font-weight: 400;
            color: #a0a3bd;
          }
          .operation {
            font-size: 14px;
            font-weight: 400;
            color: #548af7;
            cursor: pointer;
          }
        }
      }
      .static-reality {
        width: 166px;
        height: 104px;
        border-radius: 8px;
        border: 1px solid #d9dbe9;
        padding: 16px;
        margin-top: 16px;
        .plan-title-div {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .title {
            font-size: 14px;
            font-weight: 400;
            color: #a0a3bd;
          }
          .operation {
            font-size: 14px;
            font-weight: 400;
            color: #548af7;
            cursor: pointer;
          }
        }
      }
    }
    .static-right {
      flex: 1;
      height: 300px;
      position: relative;
      .static-title {
        width: 26px;
        height: 14px;
        background: #fff;
        font-size: 12px;
        font-weight: 400;
        color: #6e7491;
        position: absolute;
        bottom: 48px;
        left: 0;
        z-index: 9;
      }
    }
  }
}
</style>
