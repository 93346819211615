<template>
  <div class="task-edit-form-box">
    <div class="form-row" :key="index">
      <!-- 单选 -->
      <el-select
        v-if="infoItem.fieldAttribute == 'singleChoice'"
        v-model="infoForm[infoItem.id]"
        class="input-width input-radio"
        placeholder="未选择"
        clearable
        :disabled="disabled"
        @change="submitVal(infoItem.id, infoForm[infoItem.id])"
      >
        <el-option
          v-for="item in infoItem.fieldsCustomfieldsChoices"
          :key="item.id"
          :label="item.value"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <!-- 多选 -->
      <div
        class="task-select"
        v-else-if="infoItem.fieldAttribute == 'multipleChoice'"
      >
        <el-select
          class="input-width"
          v-model="infoForm[infoItem.id]"
          multiple
          :disabled="disabled"
          placeholder="未选择"
          style="width: 570px"
          @change="submitVal(infoItem.id, infoForm[infoItem.id])"
        >
          <el-option
            v-for="item in infoItem.fieldsCustomfieldsChoices"
            :key="item.id"
            :label="item.value"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>

      <!-- 文本 -->
      <el-input
        :autosize="{ minRows: 1, maxRows: 4 }"
        type="textarea"
        class="input-width"
        :disabled="disabled"
        v-else-if="infoItem.fieldAttribute == 'text'"
        v-model="infoForm[infoItem.id]"
        @input="inputChange(infoItem, infoForm[infoItem.id])"
        @blur="submitVal(infoItem.id, infoForm[infoItem.id])"
        placeholder="未填写"
      ></el-input>
      <!-- 日期 -->
      <el-date-picker
        class="input-width input-date"
        :disabled="disabled"
        v-else-if="infoItem.fieldAttribute == 'date'"
        v-model="infoForm[infoItem.id]"
        type="datetime"
        format="yyyy-MM-dd HH:mm"
        value-format="yyyy-MM-dd HH:mm"
        placeholder="选择日期时间"
        @change="submitVal(infoItem.id, infoForm[infoItem.id])"
        @keyup.enter.native="submitVal(infoItem.id, infoForm[infoItem.id])"
      >
      </el-date-picker>
      <div v-else-if="infoItem.fieldAttribute == 'number'" class="number-div">
        <taskNumber
          :field-info="infoItem"
          :disabled="disabled"
          :value="infoForm[infoItem.id]"
          :isShow="true"
          @change="changeVal"
          @inputBlur="inputBlur"
        ></taskNumber>
      </div>
      <!-- 数字 -->

      <!-- 文件 -->
      <div
        v-else-if="infoItem.fieldAttribute == 'file'"
        style="margin-left: 12px; margin-top: 2px"
      >
        <TaskFile
          :infoItem="infoItem"
          :fileList="infoForm[infoItem.id]"
          :width="300"
          :disabled="disabled"
          @fileFresh="fileFresh"
        ></TaskFile>
      </div>
    </div>
  </div>
</template>
<script>
import TaskFile from '../CustomField/taskFile.vue'
import taskNumber from '../CustomField/taskNumber.vue'
export default {
  components: {
    TaskFile,
    taskNumber
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    infoItem: {
      type: Object,
      default: function () {
        return {}
      }
    },
    infoForm: {
      type: Object,
      default: function () {
        return {}
      }
    },
    labelData: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  watch: {
    // infoForm: {
    //   handler(val) {
    //     console.log('object', val.length)
    //   },
    //   deep: true
    // }
  },
  data() {
    return {
      showContent: false
    }
  },
  mounted() {},
  methods: {
    inputBlur(val, fieldInfo) {
      this.infoForm[fieldInfo.id] = val
      this.$emit('setInfoForm', fieldInfo.id, val, fieldInfo)
    },
    changeVal(val, fieldInfo) {
      this.infoForm[fieldInfo.id] = val
    },
    inputChange(item, val) {
      let inputVal
      if (item.fieldAttribute == 'text' && val.length > 150) {
        this.$message({
          msg: '最多可输入 150 个字符哦',
          type: 'warn'
        })
        this.infoForm[item.id] = val.substring(0, 150)
        return
      }

      if (item.fieldAttribute == 'number') {
        inputVal = val
          .replace(/[^\d.]/g, '')
          .toString()
          .replace(/\.{2,}/g, '.')
          .replace('.', '$#$')
          .replace(/\./g, '')
          .replace('$#$', '.') // 只能输入一个小数点
        if (
          inputVal.length > 1 &&
          inputVal.substr(0, 1) === '0' &&
          inputVal.substr(1, 1) !== '.'
        ) {
          inputVal = inputVal.substr(1, 1)
        }
        if (inputVal.substr(0, 1) === '.') {
          inputVal = ''
        }
        // 只能输入数字
        inputVal =
          inputVal.indexOf('.') > -1
            ? inputVal.slice(0, inputVal.indexOf('.') + 4)
            : inputVal
        this.infoForm[item.id] = inputVal.substring(0, 20)
      }
      if (item.fieldAttribute == 'number' && inputVal.length > 20) {
        this.$message({
          msg: '最多可输入 20 个字符哦',
          type: 'warn'
        })
        this.infoForm[item.id] = inputVal.substring(0, 20)
        return
      }
    },
    fileFresh(fileList, infoItem) {
      console.log('文件提交--', fileList, infoItem)
      this.$emit('setInfoForm', infoItem.id, fileList, this.infoItem)
    },
    setLabelColor(item) {
      let style = {
        border: '1px ' + item.colour + ' solid !important',
        color: item.colour + ' !important',
        borderRadius: '12px !important'
      }
      return style
    },
    // 提交选择的值
    submitVal(id, val) {
      console.log(111, this.infoItem, val)
      // 判断必填项
      if (
        this.infoItem.fieldAttribute == 'multipleChoice' &&
        this.infoItem.required == 0 &&
        val.length == 0
      ) {
        this.$message({
          msg: this.infoItem.name + '字段为必填项！',
          type: 'warn'
        })
        this.$emit('updateDetails')
        return
      }
      if (this.infoItem.required == 0 && !val) {
        this.$message({
          msg: this.infoItem.name + '字段为必填项！',
          type: 'warn'
        })

        this.$emit('updateDetails')
        return
      }
      if (!val || val === '') {
        this.infoForm[id] = ''
      } else {
        this.infoForm[id] = val
      }
      this.infoForm = JSON.parse(JSON.stringify(this.infoForm))
      this.$emit('setInfoForm', id, val, this.infoItem)
    }
  }
}
</script>
<style scoped lang="scss">
@import '../../../../assets/styles/variable.scss';
::v-deep {
  .upload-btn {
    margin-top: 8px !important;
  }
  .form-row {
    .el-input__icon.el-icon-circle-close {
      transition: all 0s !important;
      font-size: 18px !important;
    }
    .el-input__inner::-webkit-input-placeholder,
    .el-textarea__inner:-webkit-input-placeholder {
      font-size: 14px;
      color: #a0a3bd !important;
    }

    .el-input__inner::-moz-placeholder,
    .el-textarea__inner::-moz-placeholder {
      font-size: 14px;
      color: #a0a3bd !important;
    }

    .el-input__inner::-ms-input-placeholder,
    .el-textarea__inner::-ms-input-placeholder {
      font-size: 14px;
      color: #a0a3bd !important;
    }
    &:hover {
      .el-input__icon.el-icon-circle-close {
        transition: all 0s !important;
        font-size: 18px !important;
        color: #3a78f6 !important;
      }
      .el-input__inner::-webkit-input-placeholder,
      .el-textarea__inner::-webkit-input-placeholder {
        font-size: 14px;
        color: #3a78f6 !important;
      }

      .el-input__inner::-moz-placeholder,
      .el-textarea__inner::-moz-placeholder {
        font-size: 14px;
        color: #3a78f6 !important;
      }

      .el-input__inner::-ms-input-placeholder,
      .el-textarea__inner::-ms-input-placeholder {
        font-size: 14px;
        color: #3a78f6 !important;
      }
    }
  }
}
.input-number {
  ::v-deep {
    .el-input__inner {
      border: none !important;
      resize: none;
      color: #14142b;
      width: 250px;
      text-align: left;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
}

::v-deep {
  .input-width.el-textarea .el-textarea__inner {
    line-height: 30px !important;
    min-height: 30px !important;
  }
  .input-width.el-textarea,
  .input-width.el-textarea .el-textarea__inner {
    border-radius: 12px;
    width: 400px;
    background: none !important;
    font-size: 14px !important;
    border: 1px #fff solid !important;
    resize: none !important;
  }
  .input-width.el-textarea:focus,
  .input-width.el-textarea .el-textarea__inner:focus {
    background: #f5f6fa !important;
    color: #a0a3bd !important;
  }
  .el-input--prefix .el-input__inner,
  .input-number.el-input .el-input__inner {
    padding-left: 12px !important;
  }
  .el-date-editor.input-width.el-input {
    .el-input__prefix {
      display: none !important;
    }
  }
  .el-select__tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-x: hidden;
    overflow-y: auto;
    @include scroll-beautify;
  }
  .el-select__tags::-webkit-scrollbar {
    // display: none;
    @include scroll-beautify;
  }
  .el-input .el-input__inner,
  .el-input__inner:hover {
    border: 1px #fff solid !important;
  }
  .el-input.is-disabled .el-input__inner {
    background: #fff !important;
  }
  .input-width {
    min-width: 198px !important;
  }
  .el-select.input-radio:hover .el-input__inner,
  .input-date:hover .el-input__inner {
    border: 1px #f5f6fa solid !important;
    transition: all 0s !important;
  }
  .el-button.add-label-button {
    padding: 0;
    margin: 0;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
  }
  .comfirm-button.el-button {
    padding: 0 !important;
    width: 100% !important;
    height: 32px !important;
    line-height: 32px !important;
    color: #fff;
    border: none;
    background: #3a78f6;
    font-size: 14px;
    border-radius: 12px;
  }
}
.label-div {
  display: flex;
  flex-direction: row;
  .checked-label-div {
    margin-right: 14px;
    .checked-label-list {
      padding: 6px 12px;
      display: flex;
      flex-direction: row;
      min-width: 0;
      .name {
      }
      .icon-delete {
        visibility: hidden;
        margin-left: 10px;
        cursor: pointer;
      }
    }
    &:hover {
      .icon-delete {
        visibility: visible;
      }
    }
  }
}

.label-add-div {
  .label-list-div {
    height: 200px;
    overflow-y: auto;
    margin-bottom: 14px;
    .label-list {
      .label-list-title {
        font-size: 12px;
        color: #a0a3bd;
        height: 20px;
      }
      .label-list-row {
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        color: #14142b;
        display: flex;
        flex-direction: row;
        min-width: 0;
        align-items: center;
        .name {
          flex: 1;
          height: 40px;
          line-height: 40px;
          font-size: 16px;
          color: #14142b;
          font-weight: 400;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          &:before {
            content: '';
            display: block;
          }
        }
        .icon {
          height: 40px;
          width: 40px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          .icon-select {
            color: #3a78f6;
            font-size: 20px;
            font-weight: 700;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep {
  .el-input__inner {
    padding: 0 12px !important;
  }
  .el-textarea__inner {
    padding: 0 12px !important;
  }
}
.task-select {
  ::v-deep {
    .el-input__inner {
      border: none !important;
      padding-left: 14px;
      width: 500px;
    }
    .el-select__tags {
      padding-left: 8px;
    }
    .el-tag.el-tag--info {
      background-color: #fff;
      border-color: #a0a3bd;
      color: #a0a3bd;
    }
    .el-select .el-tag {
      border-radius: 16px;
      background-color: #fff;
      font-size: 14px !important;
    }
    .el-tag .el-icon-close {
      width: 12px;
      right: -2px;
    }
    .el-select .el-tag__close.el-icon-close {
      background: transparent;
      &:hover {
        background: transparent;
        color: #444558;
      }
    }
  }
}
</style>
