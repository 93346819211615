<template>
  <div>
    <el-popover
      popper-class="progress-popover"
      ref="popover"
      trigger="hover"
      :visible-arrow="false"
      :width="480"
      :disabled="disabled"
      :open-delay="300"
      @show="getData"
    >
      <div class="progress-wrap" :class="`progress-wrap-${getDataState}`" @click.stop>
        <div class="progress-title">
          <div class="title-left">智能推进</div>
          <div class="title-right">
            <Icon
              v-if="type === 0 && userInfo.id === okrData.createUserid"
              icon-class="icon-edit-simple"
              class="icon-edit"
              @click="progressSetClick()"
            />
          </div>
        </div>
        <div class="progress-list-wrap" v-if="data.advanceTo.length > 0">
          <div class="progress-type">
            <div class="type-icon">
              <Icon icon-class="icon-xiangshangtuidong" class="icon" />
            </div>
            <!-- advanceTo -->
            <div class="name">
              <wx-open-data :id="okrData.userName" :nameData="{name:okrData.userName}" />
              推进其他同事*{{
              data.advanceTo.length
              }}
            </div>
          </div>
          <div class="progress-list-content">
            <div class="progress-list">
              <div class="infor-list" v-html="listData(4, data.advanceTo)"></div>
            </div>
          </div>
        </div>
        <div class="progress-list-wrap progress-list-span-none" v-if="data.advanceFrom.length > 0">
          <div class="progress-type">
            <span class="type-icon">
              <Icon icon-class="icon-xiangxiatuidong" />
            </span>
            <div class="name">
              同事推进
              <wx-open-data :id="okrData.userName" :nameData="{name:okrData.userName}" />
              *
              {{ data.advanceFrom.length }}
            </div>
          </div>
          <div class="progress-list-content">
            <div class="progress-list">
              <div
                class="infor-list"
                v-if="data.advanceFrom"
                v-html="listData(1, data.advanceFrom)"
              ></div>
            </div>
          </div>
        </div>
        <div
          class="progress-list-wrap progress-list-span-none"
          v-if="data.advanceFromTask.length > 0 || (data.taskMaxValue || 0) > 0"
        >
          <div class="progress-type">
            <span class="line"></span>
            <div class="name">由任务推进 * {{ data.advanceFromTask.length }}</div>
          </div>
          <div class="progress-list-content">
            <div class="progress-list">
              <div
                class="infor-list"
                v-if="data.advanceFromTask.length > 0"
                v-html="listData(2, data.advanceFromTask)"
              ></div>
              <div
                class="infor-list"
                v-if="
                  data.advanceFromTask.length === 0 &&
                  (data.taskMaxValue || 0) > 0
                "
                v-html="listData(3, data.advanceFromTask)"
              ></div>
            </div>
          </div>
        </div>
        <noData v-if="!loading && dataNullState" />
        <div v-else class="detail-btn" @click="autoDetail">查看详情</div>
      </div>
      <slot slot="reference"></slot>
    </el-popover>
    <auto-progress-detail
      v-if="autoProgressDetailVisible"
      v-model="autoProgressDetailVisible"
      :okrData="okrData"
      :item="item"
      :type="type"
      :data="data"
      @edit="popoverVisible"
      @refresh="getData"
    />
  </div>
</template>
<script>
/* eslint-disable */
import noData from '@/views/okr/components/noData.vue'
import autoProgressDetail from './autoProgressDetail'
export default {
  components: {
    noData,
    autoProgressDetail
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    okrData: {},
    item: {},
    type: {
      // 0: 关键结果 1:目标
      default: 0
    }
  },
  data() {
    return {
      visible: this.popVisible,
      data: {
        advanceTo: [],
        advanceFrom: [],
        advanceFromTask: [],
        taskMaxValue: 0
      },
      loading: true,
      dataNullState: false,
      autoProgressDetailVisible: false,
      getDataState: false
    }
  },
  methods: {
    popoverVisible() {
      this.$refs.popover.doShow()
    },
    autoDetail() {
      this.autoProgressDetailVisible = true
    },
    dataNull() {
      var state = false
      if (
        this.data.advanceTo.length === 0 &&
        this.data.advanceFrom.length === 0 &&
        (this.data.taskMaxValue || 0) === 0
      ) {
        state = true
      }
      this.dataNullState = state
    },
    accAdd(arg1,arg2) {
      var r1,r2,m;
      try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
      try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
      m=Math.pow(10,Math.max(r1,r2))
      return (arg1*m+arg2*m)/m
    },
    listData(type, data) {
      var autoType = 'O'
      var unint = '%'
      if (this.type === 0) {
        autoType = 'KR'
        unint = this.item.quantificationUnit
      }
      if (type === 1) {
        // 推进我 目标 kr
        var html = `${this.okrData.userName}的 ${autoType} `
        if (this.$isQw) {
           html = `<ww-open-data type="userName" openid="${this.okrData.userName}"></ww-open-data>的 ${autoType} `
        }
        var pushTo = 0
        var userListHtml = ''
        data.forEach((listItem) => {
          var userName = ''
          var krNum = 0
          var oNum = 0
          listItem.forEach((item) => {
            if (item.businessType === 0 || item.businessType === 1) {
              pushTo += item.pushTo
              userName = this.$isQw?`<ww-open-data type="userName" openid="${item.userName}"></ww-open-data>`:item.userName
            }
            if (item.businessType === 0) {
              ++oNum
            } else {
              ++krNum
            }
          })
          if (krNum > 0) {
            userListHtml += `【${userName}的 ${krNum} 个 KR】`
          }
          if (oNum > 0) {
            userListHtml += `【${userName}的 ${oNum} 个 O】`
          }
        })
        html += `<span>${pushTo}${unint}</span>进度由<span>${userListHtml}</span>推进`
        return html
      } else if (type === 2) {
        // 推进我 任务
        var pushTo = 0
        data.forEach((item) => {
          pushTo = this.accAdd(pushTo, item.pushTo)
        })
        if (this.$isQw) {
          return `<ww-open-data type="userName" openid="${this.okrData.userName}"></ww-open-data>的 ${autoType}<span>${this.$utils.floatToFixedDown(
          pushTo
        )}${unint}</span>进度由<span>【${data.length}个KR任务】</span>推进`
        } else {
          return `${this.okrData.userName}的 ${autoType}<span>${this.$utils.floatToFixedDown(
          pushTo
        )}${unint}</span>进度由<span>【${data.length}个KR任务】</span>推进`
        }
        
      } else if (type === 3) {
        //任务 平均
        if (this.$isQw) {
          return `<ww-open-data type="userName" openid="${this.okrData.userName}"></ww-open-data>的 ${autoType}<span>${this.data.taskMaxValue}${unint}</span>进度由 KR任务 自动平均推进`
        } else {
          return `${this.okrData.userName}的 ${autoType}<span>${this.data.taskMaxValue}${unint}</span>进度由 KR任务 自动平均推进`
        }
      } else if (type === 4) {
        // 我推进
        if (this.$isQw) {
          return `<ww-open-data type="userName" openid="${this.okrData.userName}"></ww-open-data>的 ${autoType} 推进<span>【<ww-open-data type="userName" openid="${data[0][0].userName}"></ww-open-data> 1 个 KR 】 </span>${data[0][0].pushTo}${data[0][0].unit} 进度`
        } else {
          return `${this.okrData.userName}的 ${autoType} 推进<span>【${data[0][0].userName} 1 个 KR 】 </span>${data[0][0].pushTo}${data[0][0].unit} 进度`
        }
      }
    },
    getData() {
      const data = {
        automationBusinessId: this.type === 1 ? this.okrData.id : this.item.id,
        automationBusinessType: this.type
      }
      this.$apis.OKRAUTOPROGRESSLIST(data).then((res) => {
        if (res.status === 200) {
          this.data = res.data
          this.$nextTick(() => {
            this.dataNull()
            this.getDataState = true
          })
        } else {
          this.$message.error(res.message)
        }
        this.loading = false
      })
    },
    progressSetClick() {
      this.$emit('setAutoProcessClick')
    }
  }
}
</script>
<style lang="less" scoped>
.progress-wrap-true {
  min-height: auto !important;
}
.progress-wrap {
  min-height: 360px;
  .detail-btn {
    font-size: 14px;
    font-weight: 500;
    color: #548af7;
    margin: 16px 0px 8px 0px;
    &:hover {
      cursor: pointer;
      opacity: 0.54;
    }
  }
  .progress-title {
    .flex-bt();
    .title-left {
      font-size: 16px;
      font-weight: bold;
      color: #14142b;
    }
    .title-right {
      .flex-l();
      justify-content: flex-end;
      .icon-edit {
        color: #a0a3bd;
        margin-left: 16px;
        font-size: 16px;
        padding: 8px;
        border-radius: 8px;
        cursor: pointer;
        &:hover {
          color: #3a78f6;
          background: rgba(58, 120, 246, 0.08);
        }
      }
    }
  }
  .progress-list-wrap {
    padding: 16px 16px 0px 16px;
    border: 1px solid #f5f6fa;
    border-radius: 12px;
    position: relative;
    margin-top: 12px;
    .progress-type {
      font-weight: 500;
      color: #14142b;
      font-size: 14px;
      .flex-l();
      margin-bottom: 16px;
      .name {
        .text-n(1);
        flex: 1;
      }
      .line {
        width: 2px;
        height: 16px;
        background: #3a70ff;
        border-radius: 2px;
        margin-right: 8px;
        display: block;
      }
      .type-icon {
        font-size: 20px;
        margin-right: 8px;
      }
    }
    .my-progress {
      background: linear-gradient(180deg, #ffc371 0%, #f89501 100%);
    }
    .progress-list-content {
      .progress-list {
        .person-list {
          .flex-l();
          margin-bottom: 12px;
          img {
            width: 24px;
            height: 24px;
            border-radius: 100%;
            margin-right: 8px;
          }
          .title {
            font-size: 14px;
            font-weight: 500;
            color: #434458;
            line-height: 24px;
          }
        }
        .infor-list {
          font-size: 14px;
          font-weight: bold;
          color: #434458;
          line-height: 22px;
          padding: 12px 24px;
          background: rgba(58, 120, 246, 0.08);
          border-radius: 12px;
          margin-bottom: 12px;
          ::v-deep span {
            color: #3a78f6;
            margin: 0px 8px;
          }
        }
      }
    }
  }
}
</style>
