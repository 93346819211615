var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "global-box-wrap" },
    [
      _c(
        "div",
        { staticClass: "at-container" },
        [
          !_vm.atDisable
            ? _c(
                "at",
                {
                  ref: "at",
                  attrs: {
                    members: _vm.atUserList,
                    "name-key": "name",
                    suffix: "",
                    atAll: _vm.atAll,
                    cycleId: `${
                      _vm.quarterId ||
                      _vm.$store.state.common.cycle.quarterId ||
                      ""
                    }`,
                    searchName: _vm.name,
                  },
                  on: {
                    input: _vm.atContentChange,
                    at: _vm.getMemeber,
                    nameInput: _vm.nameInput,
                    insert: _vm.insertUser,
                    loadMoreList: _vm.loadMoreListData,
                    membersRefresh: _vm.membersRefresh,
                    closePanelRefresh: _vm.closePanelRefresh,
                    departAdd: _vm.departAdd,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "embeddedItem",
                        fn: function (s) {
                          return [
                            _c("span", [
                              _c(
                                "span",
                                {
                                  staticClass: "embedded-item at-name",
                                  attrs: {
                                    "data-id": s.current && s.current.userId,
                                    "data-name": s.current && s.current.name,
                                  },
                                },
                                [
                                  _vm._v(" @ "),
                                  _c("wx-open-data", {
                                    attrs: {
                                      id: s.current && s.current.name,
                                      nameData: {
                                        name: s.current && s.current.name,
                                      },
                                    },
                                  }),
                                  _vm._v("  "),
                                ],
                                1
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1453411811
                  ),
                },
                [
                  _c(
                    "el-popover",
                    {
                      ref: "selectedPopover",
                      attrs: {
                        id: "at-popover",
                        placement: "top",
                        width: "200",
                      },
                      model: {
                        value: _vm.isShowPopover,
                        callback: function ($$v) {
                          _vm.isShowPopover = $$v
                        },
                        expression: "isShowPopover",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "colors-div" },
                        [
                          _c("Icon", {
                            staticClass: "text-color-lan",
                            attrs: { "icon-class": "icon-lan" },
                            on: {
                              click: function ($event) {
                                return _vm.setparttextcolor("#dfe9fe")
                              },
                            },
                          }),
                          _c("Icon", {
                            staticClass: "text-color-hong",
                            attrs: { "icon-class": "icon-hong" },
                            on: {
                              click: function ($event) {
                                return _vm.setparttextcolor("#fcdeea")
                              },
                            },
                          }),
                          _c("Icon", {
                            staticClass: "text-color-huang",
                            attrs: { "icon-class": "icon-huang" },
                            on: {
                              click: function ($event) {
                                return _vm.setparttextcolor("#ffefd6")
                              },
                            },
                          }),
                          _c("Icon", {
                            staticClass: "text-color-clear",
                            attrs: { "icon-class": "icon-qingchu" },
                            on: {
                              click: function ($event) {
                                return _vm.cleartextcolor()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm.isBgColor
                    ? _c("div", {
                        ref: "at-content",
                        staticClass: "at-content",
                        staticStyle: { "-webkit-user-select": "text" },
                        attrs: { id: "at-content", contenteditable: "" },
                        domProps: { innerHTML: _vm._s(_vm.contentHtml) },
                        on: {
                          input: _vm.atContentChange,
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.enterEvent.apply(null, arguments)
                          },
                          keyup: _vm.enterCtrlA,
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.focus.apply(null, arguments)
                          },
                          blur: _vm.blurEvent,
                          mouseup: _vm.handleselecttext,
                          compositionstart: _vm.handleCompositionStart,
                          compositionend: _vm.handleCompositionEnd,
                        },
                      })
                    : _c("div", {
                        ref: "at-content",
                        staticClass: "at-content",
                        attrs: { id: "at-content", contenteditable: "" },
                        domProps: { innerHTML: _vm._s(_vm.contentHtml) },
                        on: {
                          input: _vm.atContentChange,
                          keydown: [
                            function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.enterEvent.apply(null, arguments)
                            },
                            _vm.keydownContent,
                          ],
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.focus.apply(null, arguments)
                          },
                          blur: _vm.blurEvent,
                          compositionstart: _vm.handleCompositionStart,
                          compositionend: _vm.handleCompositionEnd,
                          keyup: _vm.keyupContent,
                        },
                      }),
                  !_vm.focusState
                    ? _c(
                        "span",
                        {
                          ref: "placeholder",
                          staticClass: "placeholder",
                          attrs: {
                            "aria-readonly": "true",
                            contenteditable: "false",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.focus($event)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.placeholder))]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _c("div", { staticClass: "atwho-wrap" }, [
                _c("div", {
                  ref: "at-content",
                  staticClass: "at-content",
                  attrs: { id: "at-content", contenteditable: "" },
                  domProps: { innerHTML: _vm._s(_vm.contentHtml) },
                  on: {
                    input: _vm.atContentChange,
                    blur: _vm.blurEvent,
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.focus.apply(null, arguments)
                    },
                    keydown: [
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.enterEvent.apply(null, arguments)
                      },
                      _vm.keydownContent,
                    ],
                    compositionstart: _vm.handleCompositionStart,
                    compositionend: _vm.handleCompositionEnd,
                    keyup: _vm.keyupContent,
                  },
                }),
                !_vm.focusState
                  ? _c(
                      "span",
                      {
                        ref: "placeholder",
                        staticClass: "placeholder",
                        attrs: {
                          "aria-readonly": "true",
                          contenteditable: "false",
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.focus($event)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.placeholder))]
                    )
                  : _vm._e(),
              ]),
        ],
        1
      ),
      _vm.departUserVisible
        ? _c("depart-picker", {
            attrs: {
              canDelete: true,
              type: _vm.multipleChoice === false ? "person" : "departPerson",
              fromType: "at",
              multipleChoice: _vm.multipleChoice,
              limit: 50,
            },
            on: {
              saveResult: _vm.chargeUserPicker,
              cancel: _vm.departUserCancel,
              change: _vm.departVisibleChange,
            },
            model: {
              value: _vm.departUserVisible,
              callback: function ($$v) {
                _vm.departUserVisible = $$v
              },
              expression: "departUserVisible",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }