<template>
  <el-popover
    v-model="popShow"
    trigger="hover"
    :disabled="editState || adviceDisable || !adviceObj.id"
    :open-delay="300"
    popper-class="advice-pop"
    :placement="placement"
  >
    <!-- :trigger="editState ? 'manual':'hover'" -->
    <div class="advice-content">
      <div class="advice-head">
        <div class="head-title">填写建议</div>
        <div class="ignore-btn" @click="ignoreAdvice">忽略</div>
      </div>
      <div class="advice-item">
        {{ adviceObj.text || '' }}
      </div>
    </div>
    <div
      v-if="editState"
      slot="reference"
      class="at-box"
      @mouseenter="mouseEnter"
      @mouseleave="mouseleave"
    >
      <slot> </slot>
    </div>
    <span
      v-else
      slot="reference"
      :class="adviceDisable || !adviceObj.id ? '' : 'content-html'"
      @click="clickEvent"
      v-html="html"
    ></span>
  </el-popover>
</template>
<script>
import { handleAdvice } from '../../handle'
export default {
  inject: ['writeRules'],
  model: {
    prop: 'show',
    event: 'change'
  },
  props: {
    html: {
      type: String,
      default: ''
    },
    // 匹配信息
    dictInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    show: {
      type: Boolean,
      default: false
    },
    ignoreRuleIdList: {
      // 已忽略的规则id列表
      type: Array,
      default: () => {
        return []
      }
    },
    type: {
      // 1o 2kr
      type: Number,
      default: 1
    },
    // 是否是编辑
    editState: {
      type: Boolean,
      default: true
    },
    // 是否调用接口忽略
    editIgnore: {
      type: Boolean,
      default: false
    },
    // kr数量
    krLength: {
      type: Number,
      default: 0
    },
    placement: {
      type: String,
      default: 'bottom-start'
    },
    // 是否显示建议
    adviceDisable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      popShow: false,
      advices: []
    }
  },
  computed: {
    adviceObj() {
      const obj =
        handleAdvice(
          this.html,
          this.type,
          this.krLength,
          this.ignoreRuleIdList
        ) || {}
      // console.log('匹配的规则', JSON.parse(JSON.stringify(obj)))
      return obj || {}
    }
  },
  watch: {
    show: {
      handler(val) {
        this.popShow = val
      }
    }
  },
  methods: {
    mouseleave() {
      this.popShow = this.editState
      this.$emit('change', this.popShow)
    },
    mouseEnter() {
      this.popShow = !this.editState
      this.$emit('change', this.popShow)
    },
    // 忽略
    ignoreAdvice() {
      if (this.editIgnore) {
        this.bus.$emit('ignoreClick')
        const params = {
          businessType: this.type, // 业务类型：1：o ，2:kr
          cycleInfoId: this.dictInfo.cycleInfoId,
          objId: this.type === 1 ? this.dictInfo.id : this.dictInfo.objId,
          keyId: this.type === 1 ? '' : this.dictInfo.id,
          writeRuleId: this.adviceObj.id
        }
        this.$apis.OKRIGNOREWRITERULE(params).then((res) => {
          if (res && res.status === 200) {
            this.$emit('addIgnore', this.adviceObj.id)
          }
        })
      } else {
        this.$emit('addIgnore', this.adviceObj.id)
      }
    },
    clickEvent() {
      this.$emit('clickOn')
    }
  }
}
</script>
<style lang="scss" scoped>
.at-box {
  height: 100%;
}
.content-html {
  position: relative;
  padding-top: 2px;
  // border-bottom: 1px solid #ff9900;
  &:hover {
    background: #fdf1df;
  }
}
.content-html::before {
  content: '';
  position: absolute;
  bottom: -3px;
  width: 100%;
  height: 0.3em;

  background: linear-gradient(
      135deg,
      transparent,
      transparent 45%,
      #ff9900,
      transparent 55%,
      transparent 100%
    ),
    linear-gradient(
      45deg,
      transparent,
      transparent 45%,
      #ff9900,
      transparent 55%,
      transparent 100%
    );
  background-size: 0.6em 0.6em;
  background-repeat: repeat-x, repeat-x;
}
</style>
<style lang="scss">
.advice-pop {
  width: 450px;
  height: auto;
  padding: 24px;
  box-shadow: 0px 12px 56px 0px rgba(17, 17, 17, 0.1);
  .advice-content {
    .advice-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
      .head-title {
        color: #444558;
        font-weight: 700;
        font-size: 16px;
      }
      .ignore-btn {
        color: #ff9900;
        cursor: pointer;
        font-size: 12px;
        &:hover {
          opacity: 0.54;
        }
      }
    }
    .advice-item {
      width: 400px;
      color: #444558;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 4px;
    }
  }
}
</style>