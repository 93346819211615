<template>
  <el-drawer
    :visible.sync="dialogVisible"
    :size="750"
    :destroy-on-close="false"
    :modal-append-to-body="true"
    :before-close="handleClose"
    :append-to-body="appendToBody"
    direction="rtl"
    class="sub-task-detail"
    v-on="$listeners"
  >
    <div class="task-detail-header">
      <div class="sub-left-title">
        <div class="task-header">
          <!-- <TaskType
            :classify-val="
              parentForm.objId
                ? 'kr'
                : parentForm.projectId
                ? 'project'
                : 'todo'
            "
            :disabled="true"
            :task-id="taskId"
            :type-data="parentForm"
            @changeClassify="changeClassify"
          />-->
          <Icon icon-class="icon-linshirenwu" class="task-icon" />子任务
          <!-- <Icon
            :icon-class="
              parentForm.objId
                ? 'icon-task-kr'
                : parentForm.projectId
                ? 'icon-xiangmu'
                : 'icon-renwu'
            "
            class="task-icon"
          />-->
          <!-- <template v-if="form."> KR任务 </template> -->
          <!-- <template v-if="parentForm.objId">KR任务</template>
          <template v-else-if="parentForm.projectId">项目任务</template>
          <template v-else>临时任务</template>-->
        </div>
        <!-- <template v-if="subObjectFatherId !== -1"> -->
        <div class="return-icon" @click="returnFather()">
          <Icon icon-class="icon-drawer_return" class="subTaskBack" />
          <span class="title-txt-back title-txt">返回上级</span>
        </div>
        <el-tooltip :content="form.fatherTaskTitle">
          <span class="title-txt title-txt-father">
            {{
            form.fatherTaskTitle
            }}
          </span>
        </el-tooltip>
        <!-- </template> -->
        <!-- <template v-else>
          <Icon icon-class="icon-left" class="m-r-10 pointer" />
          <span v-if="subObjectFatherId === -1" class="title-txt"
            >子任务详情</span
          >
        </template>-->
      </div>
      <div class="header-right">
        <div v-if="form.isShare === 1" class="privacy-div">
          <Icon icon-class="icon-simi" class="task-privacy" />隐私
        </div>
        <div :class="`okr-care okr-care-type-${form.follow}`" @click="taskCareClick(form)">
          <el-tooltip
            placement="top"
            :open-delay="300"
            :content="form.follow === 1 ? '已关注任务' : '关注任务'"
          >
            <Icon
              :icon-class="
                form.follow === 1 ? 'icon-collected' : 'icon-collect'
              "
              class="icon"
            />
          </el-tooltip>
        </div>
        <!-- <div
          v-if="!this.isDisabledEdit || this.isLeader || this.isCreate"
          @click="delSubTask"
        >
          <Icon icon-class="icon-shanchu1" class="task-operate" />
        </div>-->
        <div v-if="!isDisabledEdit || isLeader || isCreate">
          <more-menu :items="menuItems" placement="bottom" @menuClick="operateTarget">
            <Icon icon-class="icon-gengduo-yuandianheng" class="task-operate" />
          </more-menu>
        </div>
        <Icon icon-class="icon-close" class="task-operate task-close-icon" @click="handleClose" />
      </div>
    </div>
    <div class="task-content">
      <div ref="loadMoreMain" class="task-main-content" @scroll="handleScroll">
        <div v-if="progressList && progressList.processDynamic" class="approve-type-wrap">
          <approve-type-detail
            :progress-list="progressList"
            :task-detail="form"
            @approvalRefresh="getApprovalProcessRecord"
          />
        </div>
        <div v-for="(fieldItem, index) in fieldDictionaryList" :key="index" class="task-form">
          <!-- 标题 -->
          <div v-if="fieldItem.fieldType == 'title'" ref="taskContMain" class="task-title">
            <el-tooltip :open-delay="300" :content="isDisabledEdit ? '没有权限编辑' : '点击编辑任务名称'">
              <el-input
                v-model.lazy="form.title"
                type="textarea"
                autosize
                :disabled="isDisabledEdit"
                class="to-edit-input-textarea w-100"
                :class="isDisabledEdit ? '' : 'input-textarea-hover'"
                @focus="titleIsFocus = true"
                @blur="titleBlur"
                @keydown.enter.native="enterToBlur"
                @input="keyUpTitle"
              />
            </el-tooltip>
          </div>
          <div class="task-items">
            <!-- 设置状态 -->
            <div v-if="fieldItem.fieldType == 'status'" class="top-item" style="margin-top: 0">
              <span v-if="fieldItem.required == 0" class="star">*</span>
              <div class="top-item-describe">
                <Icon icon-class="icon-taskstatus" class="svg-icons" />
                <div class="text-box">{{ fieldItem.name }}:</div>
              </div>
              <el-tooltip
                style="margin-left: 12px"
                :open-delay="300"
                :content="
                  isDisabledEdit
                    ? '没有权限编辑'
                    : form.status === 3
                    ? '点击编辑任务状态'
                    : '点击编辑任务状态'
                "
              >
                <state-picker
                  :status="form.status"
                  :disabled="isDisabledEdit"
                  :item="form"
                  @changeStatus="changeStatusApproval"
                />
              </el-tooltip>
            </div>
            <!-- 设置负责人 -->
            <div v-if="fieldItem.fieldType == 'executor'" class="top-item">
              <span v-if="fieldItem.required == 0" class="star">*</span>
              <div class="top-item-describe">
                <Icon icon-class="icon-charge-user" class="svg-icons" />
                <div class="text-box">{{ fieldItem.name }}:</div>
              </div>
              <div class="flex-1">
                <el-tooltip
                  :open-delay="300"
                  :content="
                    isOriginatorDisabledEdit || form.submitterType === 1 ||
                          form.isDel === 2 ||
                          form.isClose === 1
                      ? '没有权限编辑'
                      : '点击编辑任务负责人'
                  "
                  :disabled="isOriginatorDisabledEdit"
                >
                  <user-picker
                    :disabled="
                      isOriginatorDisabledEdit ||
                      form.taskOperationPermission == 0 ||
                      form.submitterType === 1 ||
                          form.isDel === 2 ||
                          form.isClose === 1
                    "
                    @pick="toriginatorPicker"
                    @click.native.stop
                  >
                    <div
                      class="originator-box"
                      :class="
                        isOriginatorDisabledEdit || form.submitterType === 1 ||
                          form.isDel === 2 ||
                          form.isClose === 1
                          ? `to-edit-input-not`
                          : `o-edit-input-allow originator-${originatorHover}`
                      "
                      @mouseover="isColorCloseIcon = false"
                      @mouseout="isColorCloseIcon = true"
                    >
                      <template v-if="form.originatorName === null">
                        <Icon class="head-icon border-radius-20" icon-class="icon-kong-touxiang" />
                        <span class="head-icon-label">待认领</span>
                      </template>
                      <template v-else>
                        <el-image
                          fit="cover"
                          :src="
                            form.originatorAvatar ||
                            require('@/assets/imgs/common/advar.png')
                          "
                          alt
                          class="avatar-img pointer border-radius-20"
                        />
                        <div class="m-l-10 pointer username">
                          <wx-open-data
                            :id="form.originatorName"
                            :name-data="{ name: form.originatorName }"
                          />
                        </div>
                        <Icon
                          v-if="
                            form.originatorName !== null &&
                            !isOriginatorDisabledEdit &&
                            form.submitterType !== 1 &&
                          form.isDel !== 2 &&
                          form.isClose !== 1
                          "
                          :icon-class="
                            isColorCloseIcon ? '' : 'icon-a-Group719-1'
                          "
                          class="close-icon"
                          @mouseenter.native="originatorHover = true"
                          @mouseleave.native="originatorHover = false"
                          @click.native.stop="clearToriginatorPicker(fieldItem)"
                        />
                      </template>
                    </div>
                  </user-picker>
                </el-tooltip>
              </div>
            </div>
            <!-- 协作人 -->
            <div v-if="fieldItem.fieldType == 'involves'" class="top-item">
              <span v-if="fieldItem.required == 0" class="star">*</span>
              <div class="top-item-describe-start">
                <Icon icon-class="icon-charge-user" class="svg-icons" />
                <div class="text-box">{{ fieldItem.name }} ({{ chargeUserListNew.length || 0 }})</div>
              </div>
              <div class="flex-1 charge-user-list">
                <template v-for="item in chargeUserListInit">
                  <div
                    v-if="item"
                    :key="item.id"
                    class="charge-avatar-box"
                    :class="
                      isDisabledEdit
                        ? 'to-edit-input-not'
                        : 'to-edit-input-allow'
                    "
                  >
                    <el-image
                      fit="cover"
                      :src="
                        item.avatar || require('@/assets/imgs/common/advar.png')
                      "
                      class="avatar-img border-radius-20"
                    />
                    <i
                      v-if="!isDisabledEdit"
                      class="el-icon-error"
                      @click="chargeUserDelete(item.id, fieldItem)"
                    ></i>
                  </div>
                </template>
                <el-tooltip
                  :open-delay="300"
                  :content="
                    isDisabledEdit
                      ? '您不是此任务的负责人或协作人，无法操作哦'
                      : '点击添加协作人'
                  "
                >
                  <user-picker-checked
                    :select-people-list="chargeUserListNew"
                    :checked="true"
                    :length="40"
                    :disabled="isDisabledEdit"
                    @pick="chargeUserPicker"
                  >
                    <Icon
                      icon-class="icon-tianjia-xuxian"
                      class="cooperation-add"
                      :class="isDisabledEdit ? 'cooperation-add-disbled' : ''"
                    />
                  </user-picker-checked>
                </el-tooltip>
              </div>
            </div>
            <!-- 时间 -->
            <div v-if="fieldItem.fieldType == 'startDate'" class="top-item">
              <span v-if="fieldItem.required == 0" class="star">*</span>
              <div class="top-item-describe" style="margin-top: 10px">
                <Icon icon-class="icon-taskcooperationnew" class="svg-icons" />
                <div class="text-box">时间:</div>
              </div>
              <div class="flex-1 time-right">
                <set-date-time-detail
                  type="edit"
                  :row="form"
                  :field-item="fieldItem"
                  :disabled="isDisabledEdit"
                  @refresh="needRefreshState"
                  @change="dateTimeChange"
                />
              </div>
            </div>
            <!-- 标签 -->
            <div v-if="fieldItem.fieldType == 'tag'" class="top-item">
              <span v-if="fieldItem.required == 0" class="star" style="top: 12px">*</span>
              <div class="top-item-describe-start">
                <Icon icon-class="icon-biaoqian" class="svg-icons" />
                <div class="text-box">{{ fieldItem.name }}:</div>
              </div>
              <div class="flex-1">
                <TaskLabel
                  :disabled="isDisabledEdit"
                  :label-options="labelData"
                  :select-options="form.labelArray"
                  :field-item="fieldItem"
                  @save="saveLabels"
                  @update="updateTaskLabel"
                />
              </div>
            </div>
            <!-- 优先级 -->
            <div v-if="fieldItem.fieldType == 'priority'" class="top-item">
              <span v-if="fieldItem.required == 0" class="star" style="top: 12px">*</span>
              <div class="top-item-describe">
                <Icon icon-class="icon-taskprioritynew" class="svg-icons" />
                <div class="text-box">{{ fieldItem.name }}:</div>
              </div>
              <div class="flex-1">
                <el-tooltip
                  :open-delay="300"
                  :content="isDisabledEdit ? '没有权限编辑' : '点击编辑优先级'"
                  :placement="'top'"
                  :placement-class="'priorityTooltip'"
                >
                  <!-- v-if="form.priority && form.priority.id" -->
                  <!-- :class="priorityDictionaryList[form.priority.id].class" -->

                  <el-select
                    ref="taskPrioritySelect"
                    v-model="priorityVal"
                    :disabled="isDisabledEdit"
                    class="task-detail-priority-box"
                    placeholder="请选择"
                    style="width: 90px"
                    :style="`color: ${priorityInfo.colour} !important`"
                    @change="priorityChange(fieldItem, priorityVal)"
                  >
                    <!-- <template v-for="item in priorityDictionaryList"> -->
                    <el-option
                      v-for="(item, key) in priorityDictionaryList"
                      :key="key"
                      :class="key"
                      :label="item.value"
                      :value="item.value"
                    >
                      <!--  -->
                      <span
                        :style="`color: ${
                          priorityDictionaryList[key].colour || ''
                        }`"
                      >{{ item.value }}</span>
                    </el-option>
                    <!-- </template> -->
                  </el-select>
                </el-tooltip>
              </div>
            </div>
            <!-- 详情描述 -->
            <div v-if="fieldItem.fieldType == 'note'" class="top-item">
              <span v-if="fieldItem.required == 0" class="star star-note">*</span>
              <div class="top-item-describe" style="margin-top: 15px">
                <Icon icon-class="icon-process" class="svg-icons" />
                <div class="text-box">{{ fieldItem.name }}:</div>
              </div>
              <div class="flex-1 top-item-box disc-right">
                <div class="total_edit">
                  <div class="edit-con" :class="'editState' + editorFocus">
                    <el-tooltip :content="describeContent" placement="top" open-delay="300">
                      <div class="content-fixed">
                        <!-- v-html="filtersText(form.information) || '待添加'" -->
                        <div
                          v-show="!editorSubClick"
                          id="htmlContent"
                          ref="htmlContent"
                          :class="
                            isDisabledEdit
                              ? 'htmlContent htmlContentNo'
                              : 'htmlContent'
                          "
                          @click="ContentClick"
                          v-html="
                            filtersHtml(form.information)
                              ? form.information
                              : '待添加'
                          "
                        ></div>
                        <div
                          v-if="!editorSubClick && isShowMore"
                          class="show-more"
                          @click="showMore"
                        >{{ isEditorShow ? '收起描述' : '显示更多' }}</div>
                        <div id="showMore"></div>
                      </div>
                    </el-tooltip>
                    <transition name="bottom-slide" mode="out-in">
                      <Editor
                        v-if="editorSubClick"
                        v-model="form.information"
                        :height="440"
                        @onFocus="EditorFocus"
                        @onBlur="EditorBlur(arguments, fieldItem)"
                      />
                    </transition>
                    <transition name="bottom-slide" mode="out-in">
                      <div v-if="editorFocus && !isDisabledEdit" class="editor-Btn">
                        <div class="cancel btn" @click="editorChange(1, fieldItem)">取消</div>
                        <div
                          id="saveEditor"
                          class="confirm btn"
                          @click="editorChange(2, fieldItem)"
                        >确定</div>
                      </div>
                    </transition>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="fieldItem.fieldType == 'workingHours'"
              class="task-items"
              style="width: 100%"
            >
              <div class="top-item">
                <span v-if="fieldItem.required == 0" class="star">*</span>
                <div class="top-item-describe">
                  <Icon icon-class="icon-gongshi" class="svg-icons" />
                  <div class="text-box">{{ fieldItem.name }}:</div>
                </div>
                <div class="flex-1 workingHours-right">
                  <span class="workhours-static" @click="openWorkHoursStatic()">工时统计</span>
                </div>
              </div>
              <div class="top-items workingHours-div">
                <div
                  v-for="(wItem, wIndex) in workingHoursDictionaryList"
                  :key="wIndex"
                  class="workingHours-list"
                >
                  <div class="workingHours-label">{{ wItem.workingHoursName }}</div>
                  <div class="workingHours-content">
                    <span
                      v-if="
                        !form.taskWorkingHoursStatistics[
                          wItem.workingHoursNameValue
                        ]
                      "
                      class="work-hours-0"
                      :class="isDisabledEdit ? 'work-hours-disabled' : ''"
                      @click.stop="!isDisabledEdit ? setWorkHours(wItem) : ''"
                    >+</span>
                    <span
                      v-else
                      class="work-hours"
                      :class="isDisabledEdit ? 'work-hours-disabled' : ''"
                      @click.stop="!isDisabledEdit ? setWorkHours(wItem) : ''"
                    >
                      {{
                      form.taskWorkingHoursStatistics &&
                      wItem.workingHoursNameValue
                      ? form.taskWorkingHoursStatistics[
                      wItem.workingHoursNameValue
                      ]
                      : 0
                      }}
                    </span>
                    <span
                      :class="
                        !form.taskWorkingHoursStatistics[
                          wItem.workingHoursNameValue
                        ]
                          ? 'work-hours-unit-no'
                          : 'work-hours-unit'
                      "
                    >小时</span>
                  </div>
                </div>
                <div class="workinghours-progress">
                  <el-progress
                    class="hours-progress"
                    :stroke-width="10"
                    color="#3A70FF"
                    :percentage="getWorkhours()"
                  />
                </div>
              </div>
            </div>
            <!-- 自定义字段 -->
            <div v-if="fieldItem.fieldAttribute" class="top-item">
              <span v-if="fieldItem.required == 0" class="star">*</span>
              <div class="top-item-describe" style="margin-top: 11px">
                <Icon
                  v-if="fieldItem.fieldAttribute == 'singleChoice'"
                  icon-class="icon-danxuan1"
                  class="svg-icons"
                />
                <Icon
                  v-else-if="fieldItem.fieldAttribute == 'multipleChoice'"
                  icon-class="icon-duoxuan"
                  class="svg-icons"
                />
                <Icon
                  v-else-if="fieldItem.fieldAttribute == 'date'"
                  icon-class="icon-kaishishijian"
                  class="svg-icons"
                />
                <Icon
                  v-else-if="fieldItem.fieldAttribute == 'number'"
                  icon-class="icon-shuzi"
                  class="svg-icons"
                />
                <Icon
                  v-else-if="fieldItem.fieldAttribute == 'text'"
                  icon-class="icon-wenben"
                  class="svg-icons"
                />
                <Icon
                  v-else-if="fieldItem.fieldAttribute == 'file'"
                  icon-class="icon-wenjian"
                  class="svg-icons"
                />
                <Icon v-else icon-class="icon-zidingyiziduan" class="svg-icons" />
                <div v-if="fieldItem.name.length <= 4" class="text-box">{{ fieldItem.name }}:</div>
                <el-tooltip
                  v-else
                  class="item"
                  effect="dark"
                  :content="fieldItem.name"
                  placement="top-start"
                >
                  <div class="text-box">{{ fieldItem.name }}:</div>
                </el-tooltip>
              </div>
              <div class="flex-1 custom-right">
                <el-form ref="customInfoForm" :model="customInfoForm" @submit.native.prevent>
                  <EditForm
                    ref="childForm"
                    :disabled="isDisabledEdit"
                    :info-item="fieldItem"
                    :info-form="customInfoForm"
                    :label-data="labelData"
                    @setInfoForm="setInfoForm"
                    @updateDetails="updateDetails"
                  />
                </el-form>
              </div>
            </div>
          </div>
        </div>
        <div class="task-dynamic">
          <dynamic-container
            ref="dynamic"
            :task-id="taskId"
            :progress-list="progressList"
            :task-detail="form"
            @approvalRefresh="getApprovalProcessRecord"
          />
        </div>
      </div>
      <div class="task-comment" style="border: 1px #f7f7fc solid">
        <comment-add
          ref="commentAdd"
          :task-id="taskId"
          :taskdetail-boolean="false"
          :line-feed="true"
          @save="commentSave"
        />
      </div>
    </div>
    <div v-if="false" class="go-back-block" @click="goTop">
      <i class="el-icon-upload2 avatar-img"></i>
    </div>
    <div v-if="gobottomStatus == true" class="go-back-block-arrow" @click="goStatusBottom">
      <p class="back-block-arrow">动态</p>
      <i class="el-icon-arrow-down avatar-img_arrow"></i>
    </div>
    <SetWorkHours
      v-if="isShowSetWorkHours"
      v-model="isShowSetWorkHours"
      source="sonTask"
      :edit-data="editData"
      :title="setWorkHoursTitle"
      :form="form"
      :type="setWorkHoursType"
      :disabled="isDisabledEdit"
    />
    <WorkHoursStatic
      v-if="isShowWorkHoursStatic"
      ref="WorkHoursStatic"
      v-model="isShowWorkHoursStatic"
      :task-id="taskId"
      :disabled="isDisabledEdit"
    />
    <WorkHoursDetailed
      v-if="isShowWorkHoursDetailed"
      ref="WorkHoursDetailed"
      v-model="isShowWorkHoursDetailed"
      source="sonTask"
      :task-id="taskId"
      :disabled="isDisabledEdit"
    />
  </el-drawer>
</template>
<script>
import { mapState } from 'vuex'
import statePicker from '@/views/todo/components/TaskStatePicker'
import SetDateTimeDetail from '../../components/TaskTimeSet/SetDateTimeDetail'
import DynamicContainer from '../../components/DynamicContainer.vue'
import CommentAdd from '../../components/CommentAdd.vue'
import taskEnum from '@/utils/enums/task'
import EditForm from '../../components/EditForm/index.vue'
import TaskLabel from '../../components/Label/index.vue'
import TaskType from '../../components/TaskTypeSelect' // 任务分类
import SetWorkHours from '../../components/SetWorkhours/index.vue' // 设置工时
import WorkHoursStatic from '../../components/WorkHoursStatic/index.vue' // 工时统计
import WorkHoursDetailed from '../../components/WorkHoursDetailed/index.vue' // 工时明细
import approveTypeDetail from '@/views/todo/components/approveTypeDetail'
import getPmApproveState from '@/utils/mixins/getPmApproveState'
export default {
  components: {
    statePicker,
    SetDateTimeDetail,
    DynamicContainer,
    CommentAdd,
    EditForm,
    TaskLabel,
    TaskType,
    SetWorkHours,
    WorkHoursStatic,
    WorkHoursDetailed,
    approveTypeDetail
  },
  mixins: [getPmApproveState],
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  props: {
    dialogShow: {
      type: Boolean,
      default: true
    },
    taskId: {
      type: String
    },
    // 页面类型
    viewType: {
      type: String,
      required: false
    },
    // -1 子任务直接打开
    subObjectFatherId: {
      required: false,
      type: String,
      default: ''
    },
    formPage: {
      type: String,
      required: false
    },
    noticeType: {
      type: String,
      required: false,
      default: ''
    },
    // 解决弹窗不会嵌入body 1:不嵌入
    type: {
      type: Number,
      default: 1
    },
    appendToBody: {
      default: false
    }
  },
  data() {
    return {
      parentForm: {},
      priorityVal: '', // 优先级值
      taskTypes: [
        {
          id: '1',
          name: '待办'
        },
        {
          id: '2',
          name: 'okr任务'
        }
      ],
      taskTypeVal: '1',
      labelData: [], // 标签数组
      // 自定义form
      customInfoForm: {},
      // 任务字典
      fieldDictionaryList: [],
      titleIsFocus: false,
      dialogVisible: this.dialogShow,
      taskDetailId: '',
      needRefreshList: false, // 关闭弹窗时是否要刷新数据
      taskData: {}, // 任务数据
      isDisabledEdit: false,
      isLeader: false,
      isCreate: false,
      form: {
        title: '目标标题',
        priority: 1
      },
      originalForm: {},
      statusDict: taskEnum.TASKSTATUS, // 状态字典
      priorityDict: taskEnum.PRIOTITY, // 优先级字典
      krVisible: false, // 关联kr是否显示
      closeHoverBoolean: false, // 小图标滑过
      closeBoolean: false, // 小图标滑过
      btnHoverBoolean: false, // 按钮滑过
      propsUpdateKrObj: {}, // kr对象
      editorSubClick: false, // 描述
      editorFocus: false,
      editorVisible: false, // 富文本编辑器是否显示
      editorToolbar: false, // 编辑器工具栏(默认不显示)
      isInformationEdit: false, // 评论是否处于编辑状态
      goBackStatus: false, // 回顶部按钮是否显示
      gobottomStatus: false, // 向下箭头是否显示
      chargeUserListNew: [],
      chargeUserListInit: [],
      chargeUserPickerVisible: false, // 协作人选择
      over: false, // 接口请求是否完成
      priorityObj: {
        1: {
          name: '非常紧急',
          color: '#E83626',
          class: 'very-emergency'
        },
        2: {
          name: '紧急',
          color: '#FA972B',
          class: 'emergency'
        },
        3: {
          name: '普通',
          color: '#999999',
          class: 'normal'
        }
      },
      goToBooleans: true, // 是否点击到达底部
      add_icons_: false, // 添加图片切换
      initTitle: '',
      priorityInfo: {},
      isEditorShow: false,
      describeContent: '点击即可编辑',
      isShowMore: false,
      editorFlag: false,
      isColorCloseIcon: true,
      isOriginatorDisabledEdit: false, // 负责人权限
      stopdoingEndTime: null,
      fieldItem: {},
      remindTimeVisible: false,
      workingHoursDictionaryList: [], // 工时列表
      isShowSetWorkHours: false, // 设置工时显隐
      setWorkHoursTitle: '', // 工时弹框标题
      isShowWorkHoursStatic: false, // 工时统计显隐
      isShowWorkHoursDetailed: false, // 工时明细显隐
      setWorkHoursType: 'add', // 新增工时/编辑工时
      editData: {}, // 编辑工时回显数据
      isBlur: true,
      progressList: [],
      menuItems: [{
        icon: 'icon-move',
        title: '转化为独立任务',
        func: 'transformAloneTask'
      },
      {
        icon: 'icon-delete-icon',
        title: '删除任务',
        func: 'delete',
        color: '#eb0d0d'
      }],
    }
  },
  computed: {
    menuItems() {
      let publicItem = {
        icon: 'icon-public',
        title: '已设为公开',
        func: 'public'
      }
      if (this.taskData.isShare === 0) {
        publicItem = {
          icon: 'icon-private',
          title: '已设为私密',
          func: 'public'
        }
      }
      return [
        publicItem,
        { icon: 'icon-delete-icon', title: '删除任务', func: 'delete' }
      ]
    },
    // 背景颜色的改变
    bgColorstate() {
      if (this.form.status === '1') {
        return 'left-box-todo-not'
      } else if (this.form.status === '2') {
        return 'left-box-todo-ing'
      } else if (this.form.status === '3') {
        return 'left-box-todo-done'
      }
      return ''
    },
    ...mapState('cooperate', {
      cooperateVuexList: 'cooperateVuexList'
    }),
    ...mapState('task', {
      taskTodoOrKrDict: 'taskTodoOrKrDict' // 任务字典
    })
  },
  watch: {
    dialogShow: {
      handler(val) {
        this.dialogVisible = val
        if (this.dialogVisible) {
          this.initTask('approvalDetail')
        }
      }
    }
  },
  created() {
    this.cooperateVuexList = this.$store.state.pm.cooperateVuexList
    // if (this.type === 1) {
    // this.initTask()
    // }
    if (this.taskId) {
      this.noticeSetRead()
      this.initTask('approvalDetail')
    }
  },
  mounted() {
    this.$nextTick(() => {
      // 初始化时监测定位动态箭头是否显示
      document.documentElement.addEventListener(
        'resize',
        this.handlegoStatusFunction
      )
      // 初始化时监测定位动态箭头是否显示
      this.handlegoStatusFunction()
      // 点击空白处添加人员列表关闭
      document.documentElement.addEventListener(
        'click',
        this.closeAllModel,
        false
      )
      document.body &&
        document.body.addEventListener('mouseover', (e) => {
          if (e.target.nodeName == 'A' || e.target.nodeName === 'IMG') {
            this.describeContent = '点击直接预览'
          }
        })
      document.body &&
        document.body.addEventListener('mouseout', (e) => {
          if (e.target.nodeName == 'A') {
            if (this.isDisabledEdit) {
              this.describeContent = '没有权限编辑'
            } else {
              this.describeContent = '点击即可编辑'
            }
          }
        })
      document.body &&
        document.body.addEventListener('click', (e) => {
          if (
            e &&
            e.target.className &&
            e.target.nodeName == 'A' &&
            e.target.className == 'insertfile'
          ) {
            const fileUrl = e.target.href
            e.preventDefault()
            this.lookEditorImg(fileUrl, 'file')
          }
        })
      document.body &&
        document.body.addEventListener('click', (e) => {
          if (
            (e &&
              e.target.className &&
              e.target.nodeName === 'A' &&
              e.target.className == 'insertlink') ||
            (e &&
              e.target.className &&
              e.target.nodeName === 'A' &&
              e.target.className != 'insertlink' &&
              e.target.className != 'insertfile')
          ) {
            window.open(e.target.href, '_blank')
          }
        })
      this.bus(this).$on(
        'global:setWorkHoursRefresh',
        (source, taskId, hourType, totalHour) => {
          if (source == 'sonTask') {
            if (hourType == 1 && totalHour) {
              this.form.taskWorkingHoursStatistics['planHourTotal'] = totalHour
            } else if (hourType == 2 && totalHour) {
              this.form.taskWorkingHoursStatistics['actualHourTotal'] =
                totalHour
            }
            this.form = JSON.parse(JSON.stringify(this.form))
            this.$nextTick(() => {
              if (this.$refs.WorkHoursDetailed) {
                this.$refs.WorkHoursDetailed.getWorkHourDetaileTotal()
              }
              if (this.$refs.WorkHoursStatic) {
                this.$refs.WorkHoursStatic.echartInit()
              }
            })
          }
        }
      )
      // 监听打开工时明细
      this.bus(this).$on('lookDetailed', async (arr) => {
        if (this.isShowWorkHoursDetailed == true) {
          this.isShowWorkHoursDetailed = false
        }
        this.$nextTick(() => {
          this.isShowWorkHoursDetailed = true
        })
      })
      // 监听打开编辑工时
      this.bus(this).$on('setWorkHours', async (title, type, row) => {
        console.log('打开设置工时', title, type, this.isShowSetWorkHours)
        this.setWorkHoursTitle = title
        this.setWorkHoursType = type
        this.editData = {}
        if (type == 'edit') {
          this.editData = row
        }
        this.isShowSetWorkHours = false
        this.$nextTick(() => {
          this.isShowSetWorkHours = true
        })
      })
      // 监听点击描述取消
      document.body &&
        document.body.addEventListener('click', (e) => {
          if (e.target.className == 'cancel btn') {
            this.isBlur = false
          } else if (e.target.className == 'confirm btn') {
            this.isBlur = false
          } else {
            this.isBlur = true
          }
        })
    })
    // 监听工时设置关闭
    this.bus(this).$on('closeSetWorkHours', async () => {
      this.isShowSetWorkHours = false
    })
    // 监听工时明细关闭
    this.bus(this).$on('closeWorkHoursDetailed', async () => {
      this.isShowWorkHoursDetailed = false
    })
    this.bus(this).$emit('closeWorkHoursStatic', async () => {
      this.isShowWorkHoursDetailed = false
    })
    this.bus(this).$on('taskApproveRemindRefresh', () => {
      if (
        this.$store.state.task.taskApproveRemindObj.pageType === 'taskDetail'
      ) {
        this.getApprovalProcessRecord('', true)
      }
    })
  },
  destroyed() {
    document.documentElement.removeEventListener('click', this.closeAllModel)
    document.documentElement.removeEventListener(
      'resize',
      this.handlegoStatusFunction
    )
  },
  methods: {
    recycleTask() {
      this.$apis.UPDATETASKISDELLISTBYIDS(this.form.isDel === 2 ? 0 : 2, [this.taskId]
      ).then((res) => {
        if (res.status === 200) {
          this.$message({
            msg: res.message,
            type: 'success'
          })
          this.needRefreshList = true
          this.getTaskDetail()
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    closeTask() {
      this.$apis.UPDATETASKISCLOSELISTBYIDS(this.form.isClose === 1 ? 0 : 1, [this.taskId]).then((res) => {
        if (res.status === 200) {
          this.$message({
            msg: res.message,
            type: 'success'
          })
          this.needRefreshList = true
          this.getTaskDetail()
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 审批流详情
    getApprovalProcessRecord(deleteState, needRefreshList) {
      if (deleteState) {
        // 删除任务审批
        this.needRefreshList = true
        this.handleClose()
        return
      }
      this.$apis
        .APPROVALPROCESSRECORD({
          businessId: this.form.projectId,
          businessExtId: this.taskId,
          businessType: 3
        })
        .then((rest) => {
          this.progressList = rest.data
          var approvalStatus = this.form.submitterType === 1
          if (approvalStatus) {
            // 目标共享人 = 项目成员 = 查看者
            this.isDisabledEdit = true
            this.isLeader = false
            this.isCreate = false
            this.describeContent = '没有权限编辑'
          } else {
            const permission = this.form.taskOperationPermission
            if (permission == 0) {
              this.isOriginatorDisabledEdit = true
            }
            if (permission == 0 || permission == 5 || permission == 8 || this.form.isDel === 2 || this.form.isClose === 1) {
              // 目标共享人 = 项目成员 = 查看者
              this.isDisabledEdit = true
              this.isLeader = false
              this.isCreate = false
              this.describeContent = '没有权限编辑'
            } else if (permission == 2 || permission == 7) {
              // 任务负责人 = 项目管理员
              this.isLeader = true
              this.isDisabledEdit = false
              this.isCreate = false
              this.describeContent = '点击即可编辑'
            } else if (permission == 3 || permission == 4 || permission == 6) {
              // 任务创建人 = 目标创建人 = 项目创建人
              this.isLeader = false
              this.isCreate = true
              this.isDisabledEdit = false
              this.describeContent = '点击即可编辑'
            }
          }
          this.needRefreshList = needRefreshList || false
          if (needRefreshList === true) {
            this.getTaskDetail()
          }
        })
    },
    closeSetWorkHours() {
      this.isShowSetWorkHours = false
    },
    // 工时统计
    openWorkHoursStatic() {
      this.isShowWorkHoursStatic = true
    },
    setWorkHours(wItem) {
      console.log('设置工时--', wItem)
      this.editData = {}
      this.setWorkHoursType = 'add'
      this.setWorkHoursTitle = wItem.workingHoursName
      this.isShowSetWorkHours = true
    },
    getWorkhours() {
      let percentage = 0
      if (
        this.form.taskWorkingHoursStatistics &&
        this.form.taskWorkingHoursStatistics['planHourTotal'] &&
        this.form.taskWorkingHoursStatistics['actualHourTotal']
      ) {
        percentage =
          (this.form.taskWorkingHoursStatistics['actualHourTotal'] /
            this.form.taskWorkingHoursStatistics['planHourTotal']) *
          100
      } else {
        percentage = 0
      }
      return percentage.toFixed(2)
    },
    showMore() {
      const dom = document.getElementById('htmlContent')
      this.isEditorShow = !this.isEditorShow
      if (this.isEditorShow) {
        dom.style =
          'height:880px !important;max-height:880px !important;overflow-y: auto !important;'
      } else {
        dom.style =
          'height:440px !important;max-height:440px !important;overflow-y: hidden !important;'
      }
    },
    lookEditorImg(imgURl, type) {
      this.bus(this).$emit('global:Editor:filePreview', imgURl, type)
    },
    updateDetails() {
      this.getTaskDetail()
    },
    needRefreshState() {
      this.needRefreshList = true
    },
    // 提交自定义字段
    setInfoForm(id, value, infoItem) {
      const params = {
        fieldId: id,
        value:
          infoItem.fieldAttribute == 'multipleChoice'
            ? value.join(',')
            : infoItem.fieldAttribute == 'file'
            ? value.length == 0
              ? ''
              : JSON.stringify(value)
            : value,
        fieldType: infoItem.fieldType,
        fieldAttribute: infoItem.fieldAttribute ? infoItem.fieldAttribute : ''
      }
      this.submitTaskUpdate(params)
    },
    updateTaskLabel(labelList) {
      const params = {
        id: this.taskId,
        labelList: labelList
      }
      this.$apis.UPDATETASKLABEL(params).then((res) => {
        if (res.status == 200) {
          this.$message({
            msg: res.message,
            type: 'success'
          })
          this.needRefreshList = true
          this.getTaskDetail()
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 获取选中的biaoq
    saveLabels(tags, fieldItem) {
      const value = tags.map((item) => {
        return item.id
      })
      const params = {
        fieldId: fieldItem.id,
        value: value.join(','),
        fieldType: fieldItem.fieldType,
        fieldAttribute: fieldItem.fieldAttribute ? fieldItem.fieldAttribute : ''
      }
      if (!params.value) {
        this.$message({
          msg: `请选择${fieldItem.name}!`,
          type: 'warn'
        })
        return
      }
      this.submitTaskUpdate(params)
    },
    // 获取任务字典
    getTaskDictionary(id) {
      const params = {
        id: id
      }
      this.$apis.TASKDICTIONARY(params).then((res) => {
        if (res.status === 200) {
          this.fieldDictionaryList = res.data.fieldDictionaryList

          this.fieldDictionaryList.forEach((itemc, indexc) => {
            if (itemc.fieldType == 'endDate') {
              this.fieldDictionaryList.splice(indexc, 1)
            }
          })
          this.fieldDictionaryList.sort(
            (a, b) => Number(a.sort) - Number(b.sort)
          )
          this.priorityDictionaryList = res.data.priorityDictionaryList
          this.workingHoursDictionaryList = res.data.workingHoursDictionaryList
          this.labelData = res.data.labelDictionaryList
          setTimeout(() => {
            if (
              this.$refs.htmlContent &&
              this.$refs.htmlContent[0] &&
              this.$refs.htmlContent[0].scrollHeight > 454
            ) {
              this.isShowMore = true
            } else {
              this.isShowMore = false
            }
          }, 200)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 返回顶部
    goTop() {
      const box = this.$refs.loadMoreMain
      let top = box.scrollTop
      const timeTop = setInterval(() => {
        box.scrollTop = top -= 5
        if (top <= 0) {
          this.goBackStatus = false
          clearInterval(timeTop)
        }
      }, 1)
    },
    // 定位到动态底部
    goStatusBottom() {
      const that = this
      const box = that.$refs.loadMoreMain
      if (document.querySelector('.dynamic-container') && that.goToBooleans) {
        that.goToBooleans = false
        const offsetTop =
          document.querySelector('.dynamic-container').offsetTop +
          document.querySelector('.dynamic-container').offsetHeight -
          80
        let _bottom = 0
        that.timeTop = setInterval(() => {
          box.scrollTop = _bottom += 5
          if (_bottom >= offsetTop - 50) {
            that.gobottomStatus = false
            that.goToBooleans = true
            clearInterval(that.timeTop)
          }
        }, 1)
      }
    },
    // 监听滚动条 事件
    handleScroll() {
      const box = this.$refs.loadMoreMain
      const taskContMain = this.$refs.taskContMain
      if (document.querySelector('.dynamic-container') && taskContMain) {
        const offsetHeight =
          document.querySelector('.dynamic-container').offsetHeight
        if (box.scrollTop > 380) {
          this.goBackStatus = true
          this.gobottomStatus = false
        } else {
          this.goBackStatus = false
          if (box.scrollTop > taskContMain.offsetHeight) {
            this.gobottomStatus = true
          }
        }
      }
    },
    // 计算初始时 动态位置是否显示定位箭头
    handlegoStatusFunction() {
      setTimeout(() => {
        const box = this.$refs.loadMoreMain
        const taskContMain = this.$refs.taskContMain
        if (
          document.querySelector('.dynamic-container') &&
          taskContMain &&
          box
        ) {
          var offsetHeight =
            document.querySelector('.dynamic-container').offsetHeight

          if (offsetHeight + taskContMain.offsetHeight > box.offsetHeight) {
            this.gobottomStatus = true
          } else {
            this.gobottomStatus = false
          }
        }
      }, 2500)
    },
    // 设置消息为已读 ---update
    noticeSetRead() {
      const _obj = {
        busicId: this.taskId,
        busicType: 2
      }
      this.$apis.CLEARREDPOINTMSG(_obj).then((res) => {})
    },
    keyUpTitle() {
      const message_ = this.form.title.replace(/\s*/g, '') || ''
      if (message_.replace(/\s*/g, '').length > 150) {
        this.$message.warn('标题最多可输入 150 个字符哦!')
        this.form.title = message_.substring(0, 150)
      }
    },
    initTask(approvalDetail) {
      // 埋点
      this.$mixpanelUtil.track('View Task Details')
      this.getTaskDetail(approvalDetail)
    },
    // 父任务详情
    getParentDetails(id) {
      this.$apis.TASKDETAILNEW({}, id).then((rest) => {
        if (rest.status === 200) {
          this.parentForm = rest.data
        }
      })
    },
    // 获取任务详情
    getTaskDetail(approvalDetail) {
      this.customInfoForm = {}
      this.over = false
      // TASKDETAIL
      this.$apis.TASKDETAILNEW({}, this.taskId).then((rest) => {
        if (rest.status !== 200) {
          this.$message.error(rest.message)
          setTimeout(() => {
            this.returnFather(true)
          }, 3000)
        }
        this.form = rest.data
        // this.menuItems = [{
        //   icon: 'icon-recycle',
        //   title: this.form.isDel === 2 ? '转出回收站' : '放入回收站',
        //   func: 'recycleTask'
        // },
        // {
        //   icon: 'icon-wenjian2',
        //   title: this.form.isClose !== 1 ? '归档任务' : '取消归档',
        //   func: 'closeTask'
        // }].concat(this.menuItems)
        if (approvalDetail === 'approvalDetail' && this.form.projectId) {
          this.getApprovalProcessRecord()
        }
        this.initTitle = this.form.title
        this.taskDetailId = rest.data.id || '' // 任务id
        this.form.keyTitle = this.$utils.tagFilter(
          this.$utils.changeToHtml(rest.data.keyTitle)
        )

        // 新窗口打开
        const filea = document.querySelectorAll('.attachment a')
        filea.forEach((item) => {
          item['target'] = '_blank'
        })
        const peopleList = rest.data.taskCollaboratorList
        this.chargeUserListNew = peopleList
        this.chargeUserListInit = []
        rest.data.taskCollaboratorList.forEach((item) => {
          this.chargeUserListInit.push(item)
        })
        // 接口请求完成
        // 开始时间   =====   结束时间处理
        this.form.startDateString = rest.data.startDate
          ? this.$utils.formatRecentTime(rest.data.startDate, 'detail')
          : ''
        this.form.endDateString = rest.data.endDate
          ? this.$utils.formatRecentTime(rest.data.endDate, 'detail')
          : ''
        this.over = true
        this.keyId = rest.data.keyId || ''
        this.cycleId = rest.data.cycleId || '' // 周期id
        // this.form.information = this.$utils.tagFilter(
        //   this.$utils.changeToHtml(rest.data.information)
        // )
        this.initContent = this.form.information || ''
        // 每次刷新 将组件隐藏
        this.chargeUserPickerVisible = false
        this.SubOriginatorPickerVisible = false

        // 判断任务权限 0查看 1任务协作人 2任务负责人 3任务创建人 4目标创建人，5共享人，6项目创建人，7项目管理员，8项目成员
        const permission = rest.data.taskOperationPermission
        if (permission == 0) {
          this.isOriginatorDisabledEdit = true
        }
        if (
          permission == 0 ||
          permission == 5 ||
          permission == 8 ||
          rest.data.submitterType === 1
        ) {
          // 目标共享人 = 项目成员 = 查看者
          this.isDisabledEdit = true
          this.isLeader = false
          this.isCreate = false
          this.describeContent = '没有权限编辑'
        } else if (permission == 2 || permission == 7) {
          // 任务负责人 = 项目管理员
          this.isLeader = true
          this.isDisabledEdit = false
          this.isCreate = false
          this.describeContent = '点击即可编辑'
        } else if (permission == 3 || permission == 4 || permission == 6) {
          // 任务创建人 = 目标创建人 = 项目创建人
          this.isLeader = false
          this.isCreate = true
          this.isDisabledEdit = false
          this.describeContent = '点击即可编辑'
        }
        // this.isDisabledEdit = permission === 0 // 是否禁止编辑
        // this.isLeader = permission !== 0 // 是否负责人 创建人 负责人 协作人
        // this.isCreate = permission !== 0 // 创建人
        // this.collaborator = permission !== 0 // 协作人
        // 获取任务评论
        // this.$refs.dynamic.getTopList()
        if (rest.data.projectId) {
          // 获取项目工作流
          this.getProjectProcessList(rest.data.projectId)
        }
        if (rest.data.priority) {
          this.priorityInfo = rest.data.priority
        }
        // customInfoForm
        if (rest.data.fieldValueList && rest.data.fieldValueList.length > 0) {
          rest.data.fieldValueList.forEach((item) => {
            if (item.fieldId) {
              if (item.fieldAttribute == 'multipleChoice') {
                if (item.value) {
                  this.customInfoForm[item.fieldId] = item.value.split(',')
                } else {
                  this.customInfoForm[item.fieldId] = []
                }
              } else if (item.fieldAttribute == 'file') {
                let file = []
                if (item.value) {
                  file = JSON.parse(item.value)
                }
                this.customInfoForm[item.fieldId] = file
              } else if (item.fieldAttribute == 'number') {
                this.customInfoForm[item.fieldId] = parseInt(item.value)
              } else {
                this.customInfoForm[item.fieldId] = item.value
              }
            }
          })
          this.customInfoForm = JSON.parse(JSON.stringify(this.customInfoForm))
        }
        if (rest.data.priority && rest.data.priority.value) {
          this.priorityVal = rest.data.priority.value
        }
        if (rest.data.fatherTid) {
          this.subObjectFatherId = rest.data.fatherTid
          // this.getParentDetails(rest.data.fatherTid)
          this.getTaskDictionary(rest.data.fatherTid)
        }
        this.$refs.dynamic.getComment()
        // this.getLabelList()
      })
    },
    // 详情页图片处理
    taskImgHandle() {
      // 等比缩放
      const imgs = document.querySelectorAll('.htmlContent img')
      imgs.forEach((item) => {
        if (item.width > 500) {
          const perc = 500 / item.width
          item.width = 500
          item.height = item.height * perc
        }
      })
    },
    // 回车失去焦点事件
    enterToBlur(event) {
      if (event.keyCode === 13) {
        this.titleBlur() // 发送文本
        event.preventDefault() // 阻止浏览器默认换行操作
        return false
      }
    },
    //  标题失去焦点，修改任务标题
    async titleBlur() {
      this.titleIsFocus = true
      const title = this.form.title
      if (title === this.originalForm.title) {
        return
      }
      if (title === this.initTitle) {
        this.titleIsFocus = false
        // this.$message.warn('任务标题不能同以前一样哦！')
        return
      }
      if (!title || (!title && title.replace(/\s*/g, '').length === 0)) {
        this.$message.error('任务标题不能为空')
        this.form.title = this.originalForm.title
        return
      }
      const _obj = {}
      _obj.id = this.form.id
      _obj.title = title.replace(/[\r\n]/g, '')
      await this.$apis.TASKTITLEUPDATE(_obj).then((res) => {
        if (res.status === 200) {
          this.$message.success('标题修改成功')
          this.needRefreshList = true
          this.getTaskDetail()
          // 数据埋点c
          this.$mixpanelUtil.track('Update Task', {
            type: '标题',
            task_id: _obj.id
          })
          setTimeout(() => {
            this.titleIsFocus = false
          }, 500)
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    async changeStatusApproval(status) {
      if (status == 3 && this.form.projectId) {
        var checkData = await this.getPmApprovalCheck(
          {
            businessType: 3,
            businessId: this.form.projectId,
            businessExtId: this.form.id
          },
          1
        )
        if (checkData.state === true) {
          this.$store.commit('task/taskApproveRemindObj', {
            visible: true,
            taskDetail: this.form,
            businessData: JSON.stringify({
              id: this.form.id,
              title: this.form.title,
              status
            }),
            businessActionType: 1,
            approvalCheckData: checkData.data,
            pageType: 'taskDetail'
          })
        } else {
          this.changeStatus(status)
        }
      } else {
        this.changeStatus(status)
      }
    },
    // 修改任务状态
    changeStatus(status) {
      if (this.isDisabledEdit) {
        return
      }
      const id = this.taskDetailId
      const _obj = {
        id,
        title: this.form.title,
        status
      }
      this.$apis.TASKSTATECHANGE(_obj).then((res) => {
        if (res.status === 200) {
          if (_obj.status === 1) {
            this.$message.success('状态已修改为待处理!')
          } else if (_obj.status === 2) {
            this.$message.success('状态已修改为进行中!')
          } else if (_obj.status === 3) {
            this.$message.success('状态已修改为已完成!')
            // 数据埋点c
            this.$mixpanelUtil.track('Done Task', { task_id: _obj.id })
          } else if (_obj.status === 4) {
            this.$message.success('状态已修改为搁置!')
          }
          this.needRefreshList = true
          this.getTaskDetail()
          // 数据埋点c
          this.$mixpanelUtil.track('Update Task', {
            type: '状态',
            task_id: _obj.id
          })
        } else if (res.status === 71001 || res.status === 71005) {
          this.$message.error(res.message)
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    //  清除负责人
    clearToriginatorPicker(fieldItem) {
      if (this.form.taskOperationPermission == 0) {
        this.$message({
          msg: '查看者不可删除',
          type: 'warn'
        })
        return
      }
      // 判断必填项
      if (fieldItem.required == 0) {
        this.$message({
          msg: fieldItem.name + '字段为必填项！',
          type: 'warn'
        })
        return
      }
      this.form.originatorName = null
      this.toriginatorPicker({ id: null })
    },
    // 负责人选择
    toriginatorPicker(row) {
      const form = {
        originatorId: row.id
        // title: this.form.title,
        // id: this.form.id
      }

      this.$apis.TASKLEADER(form, this.form.id).then((res) => {
        if (res.status === 200) {
          this.$message.success('负责人修改成功')
          this.needRefreshList = true
          this.getTaskDetail()
          // 数据埋点c
          this.$mixpanelUtil.track('Update Task', {
            type: '负责人',
            task_id: this.form.id
          })
        } else if (res.status === 70000) {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    needRefreshState() {
      this.needRefreshList = true
    },
    // 日期时间修改
    dateTimeChange(row, fieldItem) {
      if (!row.startDate && !row.endDate && fieldItem.required == 0) {
        this.$message({
          msg: '时间字段为必填项！',
          type: 'warn'
        })
        return
      }
      const form = {
        id: this.form.id,
        title: this.form.title
      }
      const typeMap = {
        start: 1, // 开始时间
        end: 2 // 结束时间
      }
      form.type = typeMap[row.type]
      if (row.type === 'start') {
        form.startDate = row.startDate
        this.$mixpanelUtil.track('Update Task', {
          type: '开始时间',
          task_id: this.form.id
        })
      } else {
        form.endDate = row.endDate
        this.$mixpanelUtil.track('Update Task', {
          type: '截止时间',
          task_id: this.form.id
        })
      }
      if (row.isDelete) {
        // 删除任务时间判断依赖
        this.$apis.TASKGANTTRELATION(this.form.id).then((res) => {
          if (res.status === 200) {
            if (res.data > 0) {
              this.$confirm(
                `此任务设置的有依赖关系，清除时间后将解除依赖关系，请确认是否继续？`,
                '提示',
                {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'none',
                  customClass: 'confirmBox',
                  lockScroll: false
                }
              ).then(
                () => {
                  this.deleteTaskTime(form)
                },
                () => {
                  return
                }
              )
            } else {
              this.deleteTaskTime(form)
            }
          } else {
            this.$message({
              msg: res.message,
              type: 'error'
            })
          }
        })
      } else {
        this.$apis.TASKTIMEUPDATE(form).then((res) => {
          if (res.status === 200) {
            this.needRefreshList = true
            this.$message.success('时间修改成功')
            this.getTaskDetail()
          } else {
            this.$message({
              msg: res.message,
              type: 'error'
            })
            this.getTaskDetail()
          }
        })
      }
      return
    },
    deleteTaskTime(form) {
      this.$apis.TASKTIMEDELETE(form).then((res) => {
        if (res.status === 200) {
          this.needRefreshList = true
          this.$message.success('时间删除成功')
          this.getTaskDetail()
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
          this.getTaskDetail()
        }
      })
    },
    // 任务优先级变更
    priorityChange(fieldItem, val) {
      // 判断必填项
      if (fieldItem.required == 0 && !val) {
        this.$message({
          msg: fieldItem.name + '字段为必填项！',
          type: 'warn'
        })
        return
      }
      const valObj = this.priorityDictionaryList.find((item) => {
        return val == item.value
      })
      this.priorityInfo = valObj
      const params = {
        fieldId: fieldItem.id,
        value: valObj.id,
        fieldType: fieldItem.fieldType,
        fieldAttribute: fieldItem.fieldAttribute ? fieldItem.fieldAttribute : ''
      }
      this.submitTaskUpdate(params)
    },
    submitTaskUpdate(params) {
      this.$apis.TASKUPDATE(params, this.taskId).then((res) => {
        if (res.status === 200) {
          this.$message({
            msg: res.message,
            type: 'success'
          })
          this.needRefreshList = true
          this.getTaskDetail()
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    async krClick(row) {
      this.krClickStatus = true
      // 埋点
      // this.$mixpanelUtil.track('修改关联关键结果')
    },
    // kr标题显示的点击事件
    krTitleClick() {
      if (!this.isLeader || this.form.taskOperationPermission !== 3) {
        return
      } else {
        this.doucmentShadowClose() // 提前执行所有弹框状态重置后 根据条件进行更改
        this.propsUpdateKrObj.cycle_id = this.form.cycleId || null
        this.propsUpdateKrObj.objId = this.form.objId || null
        this.propsUpdateKrObj.keyId = this.form.keyId || null
        this.propsUpdateKrObj.title = this.form.keyTitle || ''
        this.propsUpdateKrObj.projectId = this.form.projectId || ''
        this.propsUpdateKrObj.formPage = 'taskDetails'
        this.propsUpdateKrObj.readyOnly = false
        this.propsUpdateKrObj.default = 1
        if (!this.propsUpdateKrObj.projectId) {
          this.propsUpdateKrObj.selectBooleans = 1
        } else {
          this.propsUpdateKrObj.selectBooleans = 0
        }

        this.krVisible = true
        // 埋点
        // this.$mixpanelUtil.track('点击关联关键结果')
      }
    },
    // 取消kr
    closeKr() {
      this.krVisible = false
    },
    // 保存Kr
    async saveKrMainDetail(obj) {
      var form
      if (obj.type === 2) {
        form = {
          id: this.form.id,
          projectId: obj.projectId,
          keyTitle: obj.keyTitle,
          objId: null,
          keyId: null,
          cycleId: null,
          type: '2'
        }
      } else if (obj.type === 1) {
        form = {
          id: this.form.id,
          objId: obj.objId,
          keyId: obj.keyId,
          cycleId: obj.cycle_id,
          keyTitle: obj.keyTitle,
          projectId: null,
          type: '1'
        }
      }
      this.$apis.TASKBELONGUPDATE(form).then((res) => {
        if (res.status === 200) {
          this.needRefreshList = true
          this.form.keyTitle = this.$utils.tagFilter(obj.keyTitle)
          this.$message.success('更新成功')
          this.getTaskDetail()
          this.krVisible = false
          // 数据埋点c
          this.$mixpanelUtil.track('Update Task', {
            type: '任务所属',
            task_id: this.form.id
          })
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
          this.krVisible = false
        }
      })
    },
    // 删除Kr
    TaskDetailMiaoshuDelete() {
      this.deleteKr()
    },
    // 删除kr 图标灰色显示 划过
    cancelenter() {
      this.closeHoverBoolean = true
      this.closeBoolean = false
    },
    // 删除kr 图标蓝色显示 划入
    blueEnter() {
      this.closeBoolean = false
    },
    // 删除kr 图标蓝色显示 划出
    blueLeave() {
      this.closeHoverBoolean = false
      if (this.btnHoverBoolean) {
        this.closeBoolean = true
      } else {
        this.closeBoolean = false
      }
    },
    // 协作人选择
    async chargeUserPicker(row, fieldItem) {
      var chargeUserIdList = ''
      if (row.length == 0 && fieldItem.required == 0) {
        this.$message({
          msg: fieldItem.name + '字段为必填项！',
          type: 'warn'
        })
        return
      }
      if (row.length > 40) {
        this.$message({
          msg: '最多可以选择 40 个协作人哦!',
          type: 'warn'
        })
        return
      }
      if (row.length > 0) {
        row.forEach((item, index) => {
          chargeUserIdList += item.id + ','
        })
      }
      const form = {
        id: this.form.id,
        title: this.form.title,
        originatorId: '',
        chargeUserId: chargeUserIdList
      }
      this.$apis.TASKCHARGEUSERUPDATE(form).then((res) => {
        if (res.status === 200) {
          this.needRefreshList = true
          this.$message.success('协作人更新成功')
          this.getTaskDetail()
          // 数据埋点c
          this.$mixpanelUtil.track('Update Task', {
            type: '协作人',
            task_id: this.form.id
          })
        } else if (res.status === 70000) {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 协作人删除
    async chargeUserDelete(id, fieldItem) {
      if (this.chargeUserListInit.length == 1 && fieldItem.required == 0) {
        this.$message({
          msg: fieldItem.name + '字段为必填项！',
          type: 'warn'
        })
        return
      }
      const form = {
        id: this.form.id,
        title: this.form.title,
        chargeUserId: id
      }
      this.$apis.TASKCHARGEUSERDELETE(form).then((res) => {
        if (res.status === 200) {
          this.needRefreshList = true
          this.$message.success('协作人删除成功')
          this.getTaskDetail()
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 任务描述点击
    informationClick() {
      // 埋点
      // this.$mixpanelUtil.track('点击任务详情描述')
    },
    editorChange(type, fieldItem) {
      this.isEditorShow = false
      const dom = document.getElementById('htmlContent')

      if (
        this.$refs.htmlContent &&
        this.$refs.htmlContent[0] &&
        this.$refs.htmlContent[0].scrollHeight > 454
      ) {
        dom.style =
          'height:440px !important;max-height:440px !important;overflow-y: hidden !important;'
      } else {
        dom.style =
          'height:auto !important;max-height:440px !important;overflow-y: hidden !important;'
      }

      if (type === 1) {
        // 取消
        this.form.information = this.initContent
        this.editorFocus = false
        this.editorSubClick = false
        this.editorFlag = false
      } else {
        this.informationBlur(fieldItem)
      }
    },
    EditorFocus(e, flag) {
      this.editorFlag = flag
    },
    EditorBlur(val, fieldItem) {
      console.log('失去焦点-------', val[1], fieldItem)
      setTimeout(() => {
        if (this.isBlur === true) {
          // 如果是添加链接就不要失去焦点保存
          if (val[1] === true) {
            return
          }
          this.informationBlur(fieldItem)
        } else {
          this.editorChange(1, fieldItem)
        }
      }, 500)
    },
    onFocus() {
      // if (
      //   this.cooperateVuexList.selectProjectData.list.status === 1 ||
      //   this.userInfo.id !==
      //     this.cooperateVuexList.selectProjectData.list.createUserid
      // ) {
      //   this.$message.warn('已归档或非项目负责人暂时没有权限修改哦！')
      // } else {
      this.editorFocus = true
      if (this.filtersText(this.form.information)) {
        const content = this.filtersText(this.form.information)
        if (content.length > 0) {
          this.contentFlag = true
        }
      }

      // this.$refs.selectProcess.blur()
      // }
    },
    ContentClick(e) {
      if (e && e.target.className && e.target.className == 'insertimage') {
        const fileUrl = e.target.currentSrc
        this.lookEditorImg(fileUrl, 'img')
        return
      }
      if (
        (e && e.target.className && e.target.className == 'insertlink') ||
        (e &&
          e.target.nodeName &&
          e.target.nodeName == 'A' &&
          e.target.className != 'insertlink' &&
          e.target.className != 'insertfile')
      ) {
        window.open(e.target.href, '_blank')
        return
      }
      if (e && e.target.localName && e.target.localName == 'img') {
        const fileUrl = e.target.currentSrc
        this.lookEditorImg(fileUrl, 'img')
        return
      }
      if (
        e &&
        e.target.className &&
        e.target.nodeName == 'A' &&
        e.target.className == 'insertfile'
      ) {
        const fileUrl = e.target.href
        e.preventDefault()
        this.lookEditorImg(fileUrl, 'file')
        return
      } else {
        const fileUrl = e.target.href
        e.preventDefault()
        this.lookEditorImg(fileUrl, 'file')
      }
      if (
        e &&
        e.target.localName &&
        e.target.localName == 'a' &&
        e.target.className == 'insertfile'
      ) {
        const fileUrl = e.target.href
        e.preventDefault()
        this.lookEditorImg(fileUrl, 'file')
        return
      }
      if (this.isDisabledEdit) {
        return
      }

      this.editorFocus = true

      this.editorSubClick = true
    }, // 点击描述切换编辑状态
    mainTaskDetailScroll() {
      this.$refs.taskPrioritySelect.blur() // 优先级滚动隐藏
      // this.$refs.selectProcess.blur()// 工作流滚动隐藏
    },
    // 任务描述取消
    informationCancel() {
      this.isInformationEdit = false
      this.editorToolbar = false
      this.editorVisible = false
      this.form.information = this.originalForm.information
    },
    // 显示编辑器
    showEditor() {
      this.editorVisible = true
      this.$nextTick(() => {
        this.$refs.simditor.setFocus()
      })
    },
    // 任务描述获得焦点
    informationFocus() {
      this.isInformationEdit = true
      this.editorToolbar = 'remark'
      this.$nextTick(() => {
        this.$refs.simditor.setFocus()
      })
      // 埋点
      // this.$mixpanelUtil.track('点击任务详情描述')
    },
    // 任务描述保存
    async informationSave(val) {
      const information = this.$refs.simditor.getValue()
      if (
        information &&
        this.$utils.tagFilter(information.replace(/\s*/g, '')).length > 5000
      ) {
        this.$message.warn('详情描述最多可输入 5000 个字符哦!')
        this.form.information = information.substring(0, 5000)
        return
      }
      // if (information === this.originalForm.information) {
      //   this.$message.warn('内容与之前相同哦!')
      //   return
      // }
      const _obj = {}
      _obj.id = this.form.id
      _obj.information = information
      _obj.title = this.form.title
      this.$apis.TASKINFORMATIONUPDATE(_obj).then((res) => {
        if (res.status === 200) {
          this.needRefreshList = true
          this.isInformationEdit = false
          this.editorToolbar = false
          this.$message.success('任务描述修改成功')
          this.getTaskDetail()
          // 数据埋点c
          this.$mixpanelUtil.track('Update Task', {
            type: '描述',
            task_id: this.form.id
          })
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
      // 埋点
      // this.$mixpanelUtil.track('修改任务详情描述')
    },
    informationInput() {
      const { information } = this.form
      if (
        information &&
        this.$utils.tagFilter(information.replace(/\s*/g, '')).length > 5000
      ) {
        this.$message.warn('详情描述最多可输入 5000 个字符哦!')
        this.form.information = information.substring(0, 5000)
      }
    },
    // 判断富文本是否有内容
    filtersHtml(information) {
      if ((information || '').length > 0) {
        information = information.replace(/&amp;/g, '&')
        information = information.replace(/&lt;/g, '<')
        information = information.replace(/&gt;/g, '>')
        information = information.replace(/&nbsp;/g, ' ')
        information = information.replace(/'/g, "'")
        information = information.replace(/"/g, '"')
        information = information.replace(/<style>[\s\S]*?<\/style>/gi, '')
        information = information.replace(/[ | ]*\n/g, '\n') // 去除行尾空白
        information = information.replace(/\n[\s| | ]*\r/g, '\n') // 去除多余空行
        information = information.replace(/&nbsp;/gi, '') // 去掉&nbsp;
        return (
          (information.includes('<img') ||
            information.replace(/<[^>]+>/g, '').replace(/\s/g, '') ||
            '') !== ''
        )
      } else {
        return false
      }
    },
    // 过滤富文本内容判断
    filtersText(information) {
      if (information && information.length > 0) {
        information = information.replace(/&amp;/g, '&')
        information = information.replace(/&lt;/g, '<')
        information = information.replace(/&gt;/g, '>')
        information = information.replace(/&nbsp;/g, ' ')
        information = information.replace(/&ldquo;/g, '“')
        information = information.replace(/&rdquo;/g, '”')
        information = information.replace(/&hellip;/g, '...')
        information = information.replace(/&mdash;/g, '——')
        information = information.replace(/'/g, "'")
        information = information.replace(/"/g, '"')
        // var informationS = information.replace(/<style>[\s\S]*?<\/style>/gi, '')
        // var informationN = informationS.replace(
        //   /(^\s*)|(\s*$)|[\r\n]|&nbsp;/g,
        //   ''
        // )
        // var informationP = informationN.replace(/<\/?p[^>]*>/gi, '')
        // var informationK = informationP.replace(/\s+/g, '')
        var informationK = this.$utils.tagFilter(information)
        if (informationK && informationK.length > 5000) {
          this.$message.warn('详情描述最多可输入 5000 个字符哦!')
          return informationK
        } else if (informationK.length === 0) {
          return true
        } else if (
          informationK &&
          !this.$utils.tagFilter(informationK).trim() > 0 &&
          !informationK.includes('<img')
        ) {
          return informationK
        } else {
          return informationK
        }
      } else {
        return true
      }
    },
    // 任务描述失去焦点
    async informationBlur(fieldItem) {
      if (fieldItem.required == 0 && !this.form.information) {
        this.$message({
          msg: fieldItem.name + '字段为必填项！',
          type: 'warn'
        })
        return
      }
      const { information } = this.form
      if (this.filtersText(information) || this.contentFlag) {
        const _obj = {}
        _obj.id = this.form.id
        _obj.information = this.form.information
        _obj.title = this.form.title
        this.$apis.TASKINFORMATIONUPDATE(_obj).then((res) => {
          if (res.status === 200) {
            // if (information) {
            this.needRefreshList = true
            this.$message.success('任务描述修改成功')
            this.editorFlag = false
            this.getTaskDetail()
            // 数据埋点c
            this.$mixpanelUtil.track('Update Task', {
              type: '描述',
              task_id: this.form.id
            })
            // }
          } else {
            this.$message({
              msg: res.message,
              type: 'error'
            })
          }
        })
      } else if (this.filtersText(information, 1) == false) {
        this.form.information = this.form.information.substring(0, 5000)
      }
      // else {
      //   this.form.information = ''
      // }
      this.editorFocus = false
      this.editorSubClick = false
      this.contentFlag = false
    },
    // 获取项目工作流列表
    async getProjectProcessList(projectId) {
      const obj_ = {
        projectId: projectId
      }
      this.$apis.PROJECTPROCESSLIST(obj_).then((res) => {
        if (res.status === 200) {
          this.processListData = res.data
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 打开子任务抽屉
    todoSubTask(item) {
      item.noticeType = this.noticeType
      this.bus(this).$emit('global:todoSubTask', item)
    },
    // 子任务负责人选择
    async subToriginatorPicker(row) {
      this.doucmentShadowClose() // 提前执行所有弹框状态重置后 根据条件进行更改
      this.sub_obj.originatorId = row.id
      this.sub_obj.originatorAvatar = row.avatar
      this.SubOriginatorPickerVisible = false
    },
    // 取消子任务提交
    closedialog() {
      this.sub_obj.originatorId = this.form.originatorId
      this.sub_obj.originatorAvatar = this.form.originatorAvatar
      this.sub_obj.status = 1
      this.sub_obj.title = ''
      this.sub_obj.startTime = []
      this.subTaskVisible = false
    },
    doucmentShadowClose() {},
    returnFather(state) {
      if (state) {
        this.dialogVisible = false
        this.$emit('change', false)
      }
      this.needRefreshList = true
      // 有复制文字 禁止关闭
      const sel = this.$utils.getSelectedClick()
      if (sel) {
        return
      }
      if (this.titleIsFocus) {
        return
      }
      console.log(4444, this.subObjectFatherId)
      // 关闭弹框
      if (this.needRefreshList) {
        if (this.subObjectFatherId === -1) {
          this.bus(this).$emit('taskNeedRefresh')
        } else {
          this.bus(this).$emit('global:taskDetailsRefresh', this.form.fatherTid)
          this.bus(this).$emit(
            'global:taskMainDetail',
            this.form.fatherTid,
            this.noticeType
          )
          this.bus(this).$emit('taskNeedRefresh')
        }
      }
      this.dialogVisible = false
      this.$emit('change', false)
    },
    // 弹窗关闭
    handleClose() {
      if (this.editorFlag) {
        this.$confirm('是否保存描述？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'confirmBox',
          closeOnClickModal: false
        })
          .then(() => {
            var dom = document.getElementById('saveEditor')
            dom.click()
          })
          .catch(() => {
            this.editorFlag = false
            this.handleClose()
          })
        return
      }
      // 有复制文字 禁止关闭
      const sel = this.$utils.getSelectedClick()
      if (sel) {
        return
      }
      if (this.titleIsFocus) {
        return
      }
      console.log(`taskNeedRefresh0:${this.needRefreshList}`)
      // 关闭弹框
      if (this.needRefreshList) {
        if (this.subObjectFatherId === -1) {
          console.log(`taskNeedRefresh1`)
          this.bus(this).$emit('taskNeedRefresh')
        } else {
          console.log(`taskNeedRefresh2`)
          this.bus(this).$emit('global:taskDetailsRefresh', this.form.fatherTid)
          this.bus(this).$emit('taskNeedRefresh')
        }
      }
      this.dialogVisible = false
      this.$emit('change', false)
    },
    delSubTask() {
      // this.$emit('delete')
      this.deleteTask()
      // this.$emit('global:taskMainDetail', this.form.fatherTid)
    },
    operateTarget(type) {
      const operateFn = {
        transformAloneTask: this.transformAloneTask,
        transformFatherTask: this.transformFatherTask,
        delete: this.delSubTask,
        recycleTask: this.recycleTask,
        closeTask: this.closeTask
      }
      operateFn[type]()
    },
    transformAloneTask() {
      this.$apis.PMTASKUPDATETASKFATHER({
        id: this.form.id,
        // fatherTid: this.form.fatherTid
      }).then((res) => {
        if (res.status === 200) {
          this.$message.success('修改成功')
          this.needRefreshList = true
          this.handleClose()
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    transformFatherTask() {
    },
    // 评论保存
    commentSave() {
      this.getTaskDetail()
      this.$refs.commentAdd.commentFefresh()
    },
    // 设置任务公开/私密
    publicTask() {},
    // 删除任务
    async deleteTask() {
      const form = {
        id: this.form.id,
        title: this.form.title
      }
      if (!form.id) {
        this.$message.error('id不能为空')
        return
      }
      if (this.form.projectId) {
        var checkData = await this.getPmApprovalCheck(
          {
            businessType: 3,
            businessId: this.form.projectId,
            businessExtId: this.form.id
          },
          3
        )
        if (checkData.state === true) {
          this.$store.commit('task/taskApproveRemindObj', {
            visible: true,
            taskDetail: this.form,
            businessData: JSON.stringify(form),
            businessActionType: 3,
            approvalCheckData: checkData.data,
            pageType: 'taskDetail'
          })
        } else {
          this.deleteTaskSubmit(form)
        }
      } else {
        this.deleteTaskSubmit(form)
      }
    },
    async deleteTaskSubmit(form) {
      const status = await this.$element.confirm('确定删除该任务?')
      if (!status) {
        return false
      }
      this.doucmentShadowClose()
      this.$apis.TASKDELETE(form).then((res) => {
        if (res.status === 200) {
          this.$message.success('删除成功')
          this.needRefreshList = true
          this.handleClose()
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    downloadImg(url) {
      const link = document.createElement('a')
      link.href = url
      link.target = '_blank'
      link.click() // 下载文件
    },
    // 修改关注
    taskCareClick() {
      var url = this.$apis.TASKCARECHANGE
      const dataBeans = {
        id: this.form.id,
        operation: this.form.follow === 1 ? 'cancel' : 'follow'
      }
      url(dataBeans).then((res) => {
        this.$message.success('修改成功')
        this.needRefreshState()
        this.form.follow = this.form.follow === 1 ? 0 : 1
      })
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../assets/styles/dialog.less';
@import '../../../../assets/styles/common.less';
::v-deep {
  .atwho-panel {
    bottom: 60px;
    top: auto !important;
    .atwho-view {
      bottom: 0px !important;
      min-height: 200px;
    }
  }
  .show-more {
    font-size: 14px;
    color: #3a78f6;
    margin-left: 10px;
    margin-top: 10px;
    cursor: pointer;
  }
  .htmlContent {
    font-size: 14px;
    line-height: 1.5;
    font-weight: 300;
    box-sizing: border-box;
    cursor: pointer;
    overflow-x: hidden !important;
    height: auto !important;
    max-height: 440px !important;
    overflow-y: hidden !important;
    color: #000 !important;
    .attachment {
      width: 260px;
      display: flex !important;
      flex-direction: row !important;
      align-items: center !important;
      background: #fff !important;
      border-radius: 8px !important;
      margin-bottom: 10px !important;
      padding: 4px 14px !important;
      flex-wrap: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      word-break: break-all;
      border: 1px #f5f6fa solid !important;
      a {
        min-width: 0;
        color: #6e7391 !important;
        text-decoration: none !important;
        font-size: 14px !important;
        margin-left: 10px !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        word-break: break-all;
        &:hover {
          color: #3a78f6 !important;
        }
      }
    }
    ::v-deep {
      img {
        max-width: 500px !important;
        height: auto;
      }
      p {
        margin: 0;
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        color: #606266 !important;
      }
    }
    width: 500px !important;
    .min-w(560);
    .lh(24);
    .bd-rd(4);
    padding: 12px;
    &:hover {
      background-color: #f5f6fa;
    }
  }
  .htmlContentNo {
    cursor: not-allowed !important;
  }
  .task-header-select.el-select .el-input .el-select__caret {
    color: #005eff !important;
  }
  .el-select.task-header-select {
    .el-input .el-input__inner,
    .el-input__inner:hover {
      border-color: transparent !important;
      background: transparent !important;
      color: #005eff !important;
      padding: 0 15px 0 8px !important;
    }
  }
}
::v-deep .to-edit-input-textarea {
  border: none !important;
}
.star {
  display: inline-block;
  padding: 0 4px;
  position: absolute;
  top: 14px;
  left: -16px;
  color: #a0a3bd;
}
.star-note {
  top: 18px !important;
}
.go-back-block {
  padding: 10px;
  position: absolute;
  right: 30px;
  bottom: 200px;
  cursor: pointer;
  border-radius: 50%;
  background: #fff;
  .avatar-img {
    width: 30px;
    height: 28px;
  }
}
.go-back-block-arrow {
  padding: 10px;
  position: absolute;
  right: 30px;
  top: 100px;
  z-index: 99;
  cursor: pointer;
  border-radius: 50%;
  background: #f0f5ff;
  border: 1px solid rgba(48, 104, 255, 0.4);
  box-shadow: 0px 3px 15px 0px rgba(0, 24, 87, 0.1);
  text-align: center;
  p.back-block-arrow {
    line-height: 20px;
    color: #1666ff;
    font-size: 14px;
    text-align: center;
    font-weight: 600;
  }
  .avatar-img_arrow {
    height: 7px;
    display: block;
    margin: 0 auto;
    color: #3a78f6;
    // transform: rotate(180deg);
  }
}
.task-detail-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  height: 54px;
  border-bottom: 1px solid #eaebf3;
  .sub-left-title {
    display: flex;
    align-items: center;
    .return-icon {
      display: flex;
      align-items: center;
      cursor: pointer;
      flex-shrink: 0;
      .subTaskBack {
        margin-right: 8px;
      }
    }
  }
  .header-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    .privacy-div {
      margin-right: 14px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #a0a3bd;
      width: 76px;
      height: 28px;
      background: #f5f6fa;
      border-radius: 36px 36px 36px 36px;
      font-weight: 400;
      .task-privacy {
        font-size: 14px;
        margin-right: 8px;
      }
    }
    .okr-care {
      margin-right: 16px;
      .icon {
        font-size: 22px;
        cursor: pointer;
        &:hover {
          color: #3a70ff;
        }
      }
    }
    .okr-care-type-1 {
      color: #ff9900;
    }
  }
  .task-header {
    width: 120px !important;
    height: 28px;
    font-size: 14px;
    background: rgba(50, 150, 250, 0.1);
    border-radius: 8px;
    color: #005eff;
    display: flex;
    justify-content: center;
    align-items: center;
    // font-weight: 500;
    // flex-shrink: 0;
    margin-right: 24px;
    .task-icon {
      font-size: 16px;
      margin-right: 8px;
    }
  }
  .task-operate {
    color: #444558;
    font-size: 20px;
    font-weight: 500;
    width: 33px;
    height: 33px;
    line-height: 33px;
    margin-right: 16px;
    cursor: pointer;
    &:hover {
      background: rgba(58, 120, 246, 0.06);
      border-radius: 7px;
      color: #3a78f6;
    }
  }
  .title-txt {
    color: #444558;
    padding: 0 16px 0px 0px;
    font-size: 16px;
    font-weight: normal;
  }
  .title-txt-back {
    border-right: 1px solid #d9dbe9;
    cursor: pointer;
    font-size: 16px;
  }
  .title-txt-father {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 330px;
    display: block;
    font-size: 16px;
    color: #6e7491;
    margin-left: 16px;
    cursor: auto !important;
    &:before {
      content: '';
      display: block;
    }
  }
}
.task-content {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 54px);
  .task-main-content {
    flex: 1;
    overflow-y: auto;
    // background: #f7f7fc;
    background: #fff;
    ::v-deep {
      .list-container {
        background: #fff !important;
      }
    }
  }
  .task-form {
    background: #fff;
    padding: 0px 24px;
    .task-title {
      margin: 16px 0;
      ::v-deep {
        .el-textarea,
        .el-textarea__inner {
          // border: none !important;
        }
        .to-edit-input-textarea .el-textarea__inner {
          font-size: 20px !important;
          font-weight: bolder;
          border: none !important;
          line-height: 26px;
          min-height: 26px;
          padding: 10px;
          font-weight: 700;
          color: #14142b;
          background: #f5f6fa !important;
          background: #fff !important;
          border-radius: 8px;
          padding-bottom: 16px;
          max-height: 200px;
          resize: none;
          // border: none !important;
        }
        .input-textarea-hover:hover {
          color: #3068ff !important;
        }
        .to-edit-input-textarea .el-textarea__inner:hover {
          color: #3068ff !important;
          background: #f5f6fa !important;
        }
        .to-edit-input-textarea .el-textarea__inner:active {
          color: #3068ff !important;
          background: #f5f6fa !important;
        }
        .to-edit-input-textarea .el-textarea__inner:focus {
          color: #3068ff !important;
          background: #f5f6fa !important;
        }
      }
    }
    .task-items {
      font-size: 14px;
      display: flex;
      align-items: flex-start;
      // margin-top: 10px;
      position: relative;
      flex-direction: column;
      .top-item {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 10px;
        position: relative;
        min-height: 40px;
        position: relative;
      }
      .top-item-describe {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 120px;
        min-width: 120px;
        color: #a0a3bd;
        align-self: flex-start;
        margin-top: 10px;
        line-height: 20px;
        .text-box {
          width: 100px;
          // align-items: center;
          // align-self: center;
          color: #a0a3bd;
          font-size: 14px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          word-break: break-all;
          padding-right: 10px;
        }

        .svg-icons {
          flex-shrink: 0;
          width: 20px;
          font-size: 18px !important;
          margin-right: 8px;
          height: 20px;
        }
      }
      .top-item-describe-start {
        display: flex;
        width: 120px;
        min-width: 120px;
        color: #969fbe;
        align-self: flex-start;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 10px;
        margin-top: 10px;
        line-height: 20px;
        .text-box {
          width: 100px;
          // align-items: center;
          // align-self: center;
          color: #a0a3bd;
          font-size: 14px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          word-break: break-all;
          padding-right: 10px;
        }
        .svg-icons {
          width: 20px;
          font-size: 18px !important;
          margin-right: 8px;
          height: 20px;
        }
      }
      .flex-1 {
        display: flex;
        align-items: center;
        flex: 1;
        padding-left: 12px !important;
        .close-icon {
          display: inline-block;
          width: 18px;
          height: 18px;
          font-size: 16px;
          margin-top: -9px;
          cursor: pointer;
        }
        .originator-box {
          padding: 0 12px 0 6px;
          height: 36px;
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          position: relative;
          background: #fff;
          max-width: 120px;
          border-radius: 38px 38px 38px 38px;
          border: 1px solid #fff;
          margin-left: -12px;
          .avatar-img.pointer {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin-right: 12px;
          }
          .close-icon {
            position: absolute;
            right: -2px;
            top: 0px;
            color: #a0a3bd;
            font-size: 18px;
            width: 18px;
            height: 18px;
            background: #fff;
            border-radius: 50%;
            display: none;
            &:hover {
              color: #ff9900 !important;
            }
          }
          .close-icon.icon-a-guanbi1 {
            background: #fff;
            width: 18px;
            height: 18px;
            border-radius: 50%;
          }
          .head-icon {
            // display: inline-block;
            width: 24px;
            height: 24px;
            font-size: 24px;
            // margin-top: -2px;
            background: #d4d5e0;
          }
          .head-icon-label {
            margin-left: 5px;
            font-weight: 500;
            color: #a0a3bd;
            font-size: 14px;
            padding-right: 12px;
          }
          .username {
            flex: 1;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            font-size: 14px;
            font-weight: 500;
            color: #444558;
          }
          &:hover {
            background: #f5f6fa;
            .close-icon {
              display: block;
            }
          }
        }
        .originator-box {
          &:hover {
            background: #f5f6fa;
            border: 1px solid #f5f6fa;
            .close-icon {
              &:hover {
                color: #ff9900 !important;
              }
            }
          }
        }
        .originator-box.originator-true {
          background: #fff !important;
          border: 1px solid #ff9900 !important;
          &:hover {
            background: #fff !important;
            border: 1px solid #ff9900 !important;
          }
        }
      }
      .disc-right {
        padding-left: 0 !important;
      }
      .workingHours-right {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        .workhours-static {
          font-size: 14px;
          font-weight: 400;
          color: #3a70ff;
          cursor: pointer;
        }
      }
      .custom-right {
        padding-left: 0px !important;
      }
      .time-right {
        padding-left: 8px !important;
      }
      .avatar-img {
        border-radius: 50%;
        height: 24px;
        width: 24px;
        overflow: hidden;
        margin-right: 8px;
      }
      .avatar-img + .avatar-img {
        margin-left: 5px;
      }
      .charge-user-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex-shrink: 0;
        .charge-avatar-box {
          // margin-right: 10px;
          position: relative;
          margin-top: 5px;
          .charge-avatar {
            border-radius: 50%;
            height: 25px;
            width: 25px;
          }
          .el-icon-error {
            cursor: pointer;
            display: none;
            position: absolute;
            right: 0px;
            top: -5px;
            width: 16px;
            height: 16px;
            font-size: 18px;
            color: #ff9900;
            z-index: 1;
          }
          &:hover {
            .el-icon-error {
              display: block;
            }
          }
        }
        .charge-avatar-box + .charge-avatar-box {
          margin-left: 0px;
        }
        .cooperation-add {
          cursor: pointer;
          color: #a0a3bd;
          font-size: 24px;
          &:hover {
            color: #3068ff;
          }
        }
        .cooperation-add-disbled {
          cursor: not-allowed;
          &:hover {
            color: #a0a3bd;
          }
        }
      }
      .task-belong-to {
        .top-item-right-box {
          display: inline-block;
          padding: 8px 16px;
          background: #f5f6fa;
          border-radius: 20px;
          position: relative;
          cursor: pointer;
          border: 1px solid #fff;
          p.top-item-right-box-txt {
            margin: 0;
            max-width: 530px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 16px;
            &:before {
              content: '';
              display: block;
            }
            .top-item-span {
              font-size: 12px;
              background: #005eff;
              width: 42px;
              height: 20px;
              line-height: 20px;
              display: inline-block;
              text-align: center;
              color: #fff;
              border-radius: 12px;
              margin-right: 9px;
            }
            &.top-item-right-box-txt-blue {
              color: #005eff;
            }
          }
          .task-cen-icon {
            cursor: pointer;
            position: absolute;
            right: -5px;
            top: -5px;
            font-size: 20px;
            width: 20px;
            height: 20px;
            color: #a0a3bd;
            &:hover {
              color: #ff9900;
            }
          }
        }
        .top-item-right-box-kr {
          padding: 8px 16px 8px 0px;
          color: #3a78f6;
          line-height: 16px;
          cursor: pointer;
          font-size: 14px;
          display: flex;
          align-items: center;
        }
        .task-cen-btns-blue {
          border: 1px solid #ff9900 !important;
        }
        .top-item-right-box:hover {
          background: #f5f6fa;
        }
      }
      .kr-cont {
        position: absolute;
        left: 0;
        top: 50px;
        width: 100%;
        background: #fff;
        z-index: 10;
        .right-box {
          text-align: right;
        }
        .avatar-img {
          border-radius: 50%;
          margin-right: 20px;
          height: 30px;
          width: 30px;
        }
      }
      .descDisabled .edit-con,
      ::v-deep .tox-tinymce--disabled {
        cursor: not-allowed;
      }
      .edit-con {
        position: relative;

        .editor-Btn {
          margin: 16px 8px 0 0;
          text-align: center;
          .flex-r();
          .btn {
            width: 96px;
            // height: 32px;
            line-height: 32px;
            border-radius: 14px;
            border: 1px solid #005eff;
            font-size: 14px;
            margin: 0px 0px 0px 8px;
            cursor: pointer;
          }

          .cancel {
            color: #005eff;
          }
          .confirm {
            color: #f7f7fc;
            background: #3a78f6 !important;
            border-color: #3a78f6 !important;
            &:hover {
              background: rgba(58, 120, 246, 0.87) !important;
              border-color: rgba(58, 120, 246, 0.87) !important;
            }
          }
        }
      }
      .workingHours-div {
        margin-left: 28px;
        .workingHours-list {
          display: flex;
          flex-direction: row;
          height: 32px;
          align-items: center;
          margin-top: 8px;
          .workingHours-label {
            width: 140px;
            text-align: left;
            font-size: 14px;
            font-weight: 400;
            color: #a0a3bd;
          }
          .workingHours-content {
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 32px;
            color: #444558;
            .work-hours {
              display: inline-block;
              width: 73px;
              height: 32px;
              line-height: 32px;
              border-radius: 12px;
              border: 1px solid #fff;
              text-align: center;
              margin-right: 8px;
              color: #444558;
              cursor: pointer;
              &:hover {
                border: 1px solid #3a70ff;
              }
            }
            .work-hours-0 {
              width: 73px;
              height: 32px;
              line-height: 32px;
              background: #fff;
              color: #a0a3bd;
              margin-right: 8px;
              border-radius: 12px;
              text-align: center;
              cursor: pointer;
              &:hover {
                background: #f5f6fa;
                color: #3a70ff;
              }
            }
            .work-hours-unit-no {
              color: #a0a3bd;
            }
            .work-hours-unit {
              color: #444558;
            }
            .work-hours-disabled {
              cursor: not-allowed;
              &:hover {
                border: 1px solid #fff;
              }
            }
          }
        }
        .workinghours-progress {
          margin-top: 20px;
          width: 400px;
          ::v-deep {
            .el-progress.hours-progress {
              display: flex;
              flex-direction: row;
              align-items: center;
              .el-progress__text {
                margin-left: 24px;
                color: #a0a3bd;
              }
            }
          }
        }
      }
      ::v-deep {
        .task-detail-priority-box {
          .el-input__inner {
            -webkit-box-shadow: 0 0 20px 20px #fff inset;
            box-shadow: 0 0 20px 20px #fff inset;
            border: 1px solid #f5f6fa !important;
            font-size: 14px;
            border-radius: 9px;
            height: 36px;
            line-height: 36px;
            padding: 0px 10px;
            color: inherit !important;
          }
          .el-input__suffix {
            display: none;
          }
          &:hover {
            .el-input__inner {
              background: rgba(217, 219, 233, 0.24) !important;
              box-shadow: none !important;
            }
          }
        }
        .very-emergency {
          .el-input__inner {
            color: #e83626 !important;
          }
        }
        .emergency {
          .el-input__inner {
            color: #fa972b !important;
          }
        }
        .normal {
          .el-input__inner {
            color: #999 !important;
          }
        }
      }
    }
    &:nth-last-child(2) {
      padding-bottom: 20px;
    }
  }
  .to-edit-input-not {
    cursor: not-allowed !important;
    .pointer {
      cursor: not-allowed !important;
    }
  }
  .to-edit-input-not-add {
    cursor: not-allowed !important;
    background: #f5f5f5;
    .pointer {
      cursor: not-allowed !important;
    }
  }
  .to-edit-input-not-edit {
    cursor: not-allowed;
  }
  .flex-new {
    display: flex;
    align-items: center;
  }
}
//待办
.stop-doing-box {
  height: calc(100vh - 456px);
  background: #fff;
  padding: 0px 24px;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  .stop-dictionayry-row {
    flex: 0 0 100%;
  }
  .stop-dictionayry-row-50 {
    flex: 0 0 auto;
    display: inline;
  }
  .stop-doing-list {
    display: flex;
    flex-direction: row;
    .stopdoing-priority {
      margin-left: 12px;
      ::v-deep {
        .el-select.task-detail-priority-box {
          width: 150px !important;
          .el-input__inner {
            border: 1px solid #d9dbe9 !important;
            border-radius: 38px;
          }
        }
      }
    }
    .stopdoing-note {
      width: 664px;
      border: 1px #d9dbe9 solid;
      border-radius: 12px 12px 12px 12px;
      margin-top: 22px;
      .edit-con {
        position: relative;
        .editor-Btn {
          margin: 16px 8px 16px 0;
          text-align: center;
          .flex-r();
          .btn {
            width: 78px;
            // height: 32px;
            line-height: 32px;
            border-radius: 14px;
            // border: 1px solid #005eff;
            border: 1px solid #d9dbe9;
            font-size: 14px;
            margin: 0px 0px 0px 8px;
            cursor: pointer;
          }

          .cancel {
            // color: #005eff;
            color: #d9dbe9;
            &:hover {
              color: #a0a3bd !important;
              border: 1px solid #d9dbe9 !important;
            }
          }
          .confirm {
            color: #548af7;
            background: #fff !important;
            border-color: #548af7 !important;
            &:hover {
              color: #3a70ff !important;
              border-color: #3a70ff !important;
            }
          }
        }
      }
      ::v-deep {
        .htmlContent:hover {
          background: none !important;
        }
        .tox .tox-toolbar-overlord {
          background: none !important;
        }
        .detail-editor .tox .tox-edit-area__iframe {
          border-radius: 0 !important;
          background: none !important;
        }
        .detail-editor .tox .tox-toolbar,
        .detail-editor .tox .tox-toolbar__overflow,
        .detail-editor .tox .tox-toolbar__primary {
          border-bottom: 1px #d9dbe9 solid !important;
        }
      }
    }
    .stopdoing-time {
      position: relative;
      height: 40px;
      width: 180px;
      cursor: pointer;
      .el-date-editor.end-time {
        width: 80px !important;
        ::v-deep {
          .el-input__inner {
            border: none !important;
            padding-left: 8px !important;
            width: 80px !important;
          }
          .el-input__prefix {
            display: none !important;
          }
        }
      }
      .endData-div {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        // width: 180px;
        padding: 0 12px;
        height: 38px;
        flex-direction: row;
        align-items: center;
        border: 1px #d9dbe9 solid;
        background: #fff;
        border-radius: 31px;
        padding: 0 12px;
        width: 180px;
        z-index: 1;
        .time {
          font-size: 14px;
          font-weight: 400;
          color: #444558;
          margin-left: 8px;
          flex: 1;
        }
        .placeholder {
          color: #a0a3bd;
          font-size: 14px;
          font-weight: 400;
          margin-left: 8px;
          flex: 1;
        }
        .clear-icon {
          color: #a0a3bd;
          display: none;
        }
        &:hover {
          .clear-icon {
            display: block;
          }
        }
      }
    }
    .stopdoing-remind-time {
      padding: 0 12px;
      height: 38px;
      background: #f5f6fa;
      border-radius: 39px 39px 39px 39px;
      border: 1px solid #d9dbe9;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 16px;
      .remind-icon {
        color: #444558;
        font-size: 16px;
        margin-right: 8px;
      }
      .placeholder {
        font-size: 14px;
        font-weight: 400;
        color: #a0a3bd;
      }
      .remind-time {
        font-size: 14px;
        font-weight: 400;
        color: #444558;
      }
    }
    .stopdoing-remind-time-you {
      background: #fff;
    }
  }
  //标题
  .task-title {
    padding: 16px 0;
    ::v-deep {
      .to-edit-input-textarea .el-textarea__inner {
        font-size: 20px !important;
        line-height: 26px;
        min-height: 26px;
        padding: 10px;
        font-weight: 700;
        color: #14142b;
        background: #f5f6fa !important;
        background: #fff !important;
        border-radius: 8px;
        padding-bottom: 16px;
        max-height: 200px;
        resize: none;
        border: none !important;
      }
      .input-textarea-hover:hover {
        color: #3068ff !important;
      }
      .to-edit-input-textarea .el-textarea__inner:hover {
        color: #3068ff !important;
        background: #f5f6fa !important;
      }
      .to-edit-input-textarea .el-textarea__inner:active {
        color: #3068ff !important;
        background: #f5f6fa !important;
      }
      .to-edit-input-textarea .el-textarea__inner:focus {
        color: #3068ff !important;
        background: #f5f6fa !important;
      }
    }
  }
  //头像
  .originator-box.stopdoing-originator {
    padding: 4px 8px 4px 8px;
    cursor: pointer;
    position: relative;
    background: #fff;
    width: 120px;
    border-radius: 38px 38px 38px 38px;
    border: 1px solid #d9dbe9;
    margin-right: 16px;
    .avatar-img.pointer {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 12px;
    }
    div {
      display: flex;
      align-items: center;
    }

    .close-icon {
      position: relative;
      right: -5px;
      top: -10px;
    }
    .head-icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url('~@/assets/imgs/pm/head-icon.png') no-repeat;
      background-size: 100% 100%;
    }
    .head-icon-label {
      margin-left: 5px;
      font-weight: 500;
      color: #a0a3bd;
    }
    .username {
      font-size: 14px;
      font-weight: 500;
      color: #444558;
    }
  }
  .originator-box {
    &:hover {
      background: #fff;
      border: 1px solid #ff9900;
    }
  }
}
.task-dynamic {
  // margin-top: 24px;
  flex: 1;
}
::v-deep {
  .el-drawer__header {
    border-bottom: 1px solid #eaebf3;
    display: none;
  }
}
</style>

<style lang="scss">
.htmlContent {
  p > img {
    width: auto;
    max-width: 90%;
    height: auto;
    cursor: pointer;
  }
}
::v-deep {
  .tox .tox-edit-area__iframe {
    border-radius: 16px;
    background: #f5f6fa !important;
  }
}
</style>
