<template>
  <div class="comment-box" :style="`height: ${height}px`" @drop="upLoadFile" @mousedown="clickBox">
    <div class="comment-input-box">
      <at-input
        :lineFeed="lineFeed"
        :atDisable="!atAble"
        ref="atCommentBoxinput"
        :html="inputHtml"
        :limit="500"
        :enter-able="true"
        :placeholder="
          $utils.tagFilter(inputHtml + '<div></div>') === '' ? placeholder : ''
        "
        :atAll="atAll"
        :multipleChoice="multipleChoice"
        @input="getComment"
        @enter="enterInput"
        @focus="focus"
        @blur="blurInput"
        @compositionstart="handleCompositionStart"
        @compositionend="handleCompositionEnd"
        @insertUser="insertUser"
        @departInsertUser="departInsertUser"
        @departVisibleChange="departVisibleChange"
      />
      <!-- <el-input
        v-if="!atAble"
        ref="CommentBoxinput"
        v-model.lazy="inputHtml"
        type="textarea"
        :limit="501"
        :placeholder="placeholder"
        @focus="focus"
        @input="inputBack"
        @blur="blurInput"
        @keyup.enter.native="enterInput($event)"
        @compositionstart="handleCompositionStart"
        @compositionend="handleCompositionEnd"
      />-->
    </div>
    <div class="upload-comment-wrap">
      <div v-if="!fileUploadDisabled" class="file-upload-wrap">
        <file-upload :input-drag-file-list="dragFile" @fileFresh="fileFreshList" />
      </div>
      <div class="comment-release-btn">
        <div class="release-btn">
          <span
            v-if="inputHtml === '' && uploadFileList.length === 0"
            class="disabled"
            :loading="loading"
          >发布</span>
          <span v-else class="submit" :loading="loading" @click.stop="releaseComment">发布</span>
          <!-- @click="hideBox"  -->
          <Icon class="release-icon" icon-class="icon-arrow-down" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    atAble: {
      // 评论是否可以@
      type: Boolean,
      default: true
    },
    placeholder: {
      // 提示
      type: String,
      default: '想法,意见都可以提出哦，@提及他人 ↵'
    },
    blurRelease: {
      // 失去焦点自动发送
      type: Boolean,
      default: true
    },
    height: {
      // 回复框高度
      type: [Number, String],
      default: 130
    },
    width: {
      // 回复框高度
      type: Number,
      default: 485
    },
    fileUploadDisabled: {
      type: Boolean,
      default: false,
      required: false
    },
    atAll: {
      default: false
    },
    lineFeed: {
      default: false
    },
    multipleChoice: {
      default: true
    }
  },
  computed: {},
  mounted() {
    if (this.atAble === false) {
      this.$nextTick(() => {
        this.$refs.atCommentBoxinput.$refs['at-content'].focus()
      })
    }
  },
  watch: {
    visibleShow: {
      handler(val) {
        this.visible = val
      }
    }
  },
  data() {
    return {
      dragFile: {},
      inputHtml: '',
      userId: '',
      noBlur: false,
      uploadFileList: [],
      hasComposition: false, // 判断中文是否输入完成
      departUserVisible: false
    }
  },
  methods: {
    departVisibleChange(state) {
      this.departUserVisible = state
    },
    upLoadFile(e) {
      e.preventDefault()
      e.stopPropagation()
      const df = e.dataTransfer
      let dragFiles = {} // 存储拖拽的文件对象
      if (df.items !== undefined) {
        // 谷歌
        const item = df.items[0]
        if (item.kind === 'file' && item.webkitGetAsEntry().isFile) {
          const file = item.getAsFile()
          dragFiles = file
        }
      } else {
        // safari 以及其他浏览器
        dragFiles = df.files[0]
      }
      try {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(dragFiles.slice(0, 3))
        fileReader.addEventListener('load', (e) => {
          // 上传
          const formData = new FormData()
          formData.append('file', dragFiles)
          this.$apis.FILEUPLOAD(formData).then((res) => {
            if (res.status === 200) {
              // 上传成功
              this.dragFile = res.data
              this.uploadFileList.push(res.data)
            } else {
              // 上传失败
              this.$message.warn('上传失败，请重试！')
            }
          })
        })
        fileReader.addEventListener(
          'error',
          (r) => {
            this.$message.warn('不能上传文件夹')
          },
          false
        )
      } catch {
        this.$message.warn('不能上传文件夹')
        return
      }
    },
    async fileFreshList(val) {
      this.uploadFileList = val
    },
    clickBox() {
      this.noBlur = true
    },
    // 字数超出显示提示
    inputBack() {
      const message_ = this.inputHtml.replace(/\s*/g, '') || ''
      if (message_.replace(/\s*/g, '').length > 500) {
        this.$message({
          msg: '您填写的回复不能超过 500 个字符哦!',
          type: 'warn'
        })
        this.inputHtml = message_.substring(0, 500)
      }
    },
    // 获取输入的评论
    getComment(obj) {
      this.inputHtml = obj.html
      this.userId = obj.atIds.toString()
    },
    handleCompositionStart() {
      this.hasComposition = true
    },
    handleCompositionEnd() {
      setTimeout(() => {
        this.hasComposition = false
      }, 100)
    },
    enterInput(e) {
      // 浏览器阻止默认事件兼容
      // if (e.preventDefault){
      //   e.preventDefault();
      // } else {
      //   window.event.value = false;
      // }
      this.releaseComment()
    },
    releaseComment() {
      this.blurRelease = false
      if (
        !this.inputHtml &&
        !this.fileUploadDisabled &&
        this.uploadFileList.length === 0 &&
        this.departUserVisible === false
      ) {
        this.$message({
          msg: '内容不能为空',
          type: 'warn'
        })
        return
      }
      if (
        this.inputHtml.trim() === '' &&
        !this.fileUploadDisabled &&
        this.uploadFileList.length === 0
      ) {
        this.$message({
          msg: '内容不能为空',
          type: 'warn'
        })
        return
      }
      this.$emit('release', this.inputHtml, this.userId, this.uploadFileList)
    },
    blurInput() {
      if (this.noBlur) {
        if (this.atAble) {
          // this.$refs.atCommentBoxinput.cursorInPosition()
        } else {
          // this.$refs.CommentBoxinput.focus()
          this.$refs.atCommentBoxinput.$refs['at-content'].focus()
        }
      } else {
        if (this.blurRelease) {
          this.$emit('release', this.inputHtml)
        } else {
          this.$emit('blur')
        }
      }
      this.noBlur = false
    },
    focus() {
      this.noBlur = false
      this.$emit('focus')
    },
    clearComment() {
      this.inputHtml = ''
      this.userId = ''
      this.$nextTick(() => {
        console.log(11111, this.$refs)
        if (this.$refs.atCommentBoxinput) {
          this.$refs.atCommentBoxinput.clearComment()
        }
        // this.$refs.atCommentBoxinput.clearComment()
      })
    },
    hideBox() {
      this.$emit('hideComment')
    },
    insertUser(userItem) {
      this.$emit('insertUser', userItem)
    },
    departInsertUser(userItem) {
      this.$emit('departInsertUser', userItem)
    },
    deleteContent(userItem) {
      this.$refs.atCommentBoxinput.deleteContent(userItem)
    }
  }
}
</script>
<style lang="scss">
.upload-comment-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 16px 20px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
}
.upload-comment-wrap .upload-demo {
  display: flex;
  align-content: space-between;
  align-items: center;
  flex-wrap: wrap-reverse;
}
.upload-comment-wrap .el-upload {
  width: 100%;
}
.upload-comment-wrap .el-upload-list {
  width: 100%;
  max-height: 180px;
  overflow-y: auto;
}
.upload-comment-wrap .el-upload-list__item {
  height: 44px;
  line-height: 44px;
  border: 1px solid #f5f6fa;
  border-radius: 9px;
  margin: 0px 0px 8px 0px;
}
.upload-comment-wrap .el-upload-list__item-name {
  padding-left: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.upload-comment-wrap .el-icon-close {
  font-size: 16px;
  top: 0px;
  right: 10px;
  line-height: 44px;
}
.upload-comment-wrap .el-icon-close:hover {
  color: #3a78f6;
}
.upload-comment-wrap {
  .el-upload-list__item-status-label {
    right: 10px;
    .el-icon-circle-check {
      font-size: 16px;
    }
  }
  .el-icon-document {
    width: 30px;
    height: 44px;
    // background: url(../../../../../../../assets/icons/common/fileCommon.png)
    //   center center no-repeat;
    background-size: 30px auto;
    &:before {
      content: '';
    }
  }
  .el-progress--line {
    top: auto;
    bottom: -5px;
  }
}
</style>
<style lang="scss" scoped>
.file-upload-wrap {
  width: 100%;
}
.comment-box {
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 0 rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column;

  ::v-deep.el-textarea,
  ::v-deep.el-textarea__inner {
    border: 0 !important;
    border-radius: 0;
    background: #fff !important;
    padding: 2px 0;
    resize: none;
  }

  .comment-input-box {
    padding: 6px 20px 0 20px;
    flex: 1;
    height: calc(100% - 18px);
    ::v-deep {
      .el-textarea {
        background: none !important;
        resize: none;
      }
    }
    ::v-deep .at-container {
      height: 100%;
      .atwho-wrap {
        height: calc(100% - 18px);
        .editor {
          min-height: 30px;
          height: 100%;
          overflow-x: hidden;
          overflow-y: auto;
          margin-top: -2px;
        }
      }
    }
  }

  .comment-release-btn {
    // padding: 0 20px;
    display: flex;
    justify-content: space-between;
    margin-top: 0px;
    color: #3a78f6;
    font-size: 14px;
    position: absolute;
    right: 20px;
    bottom: 20px;
    .disabled {
      color: #6e7491;
    }
    .release-btn {
      display: flex;
      align-content: center;
      cursor: pointer;

      .release-hover-icon {
        display: none;
      }

      .release-icon {
        font-size: 18px;
        color: #a0a3bd;
        &:hover {
          color: #6e7491;
        }
      }
      &:hover {
        color: #005eff;
      }
    }
  }
}
</style>
