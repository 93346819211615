var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "480px",
        "append-to-body": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "dialog-title",
          attrs: { slot: "title" },
          slot: "title",
        },
        [_vm._v("新功能上线")]
      ),
      _c("div", { staticClass: "remind-wrap" }, [
        _c("img", {
          attrs: {
            src: require("../../../../../../assets/imgs/header/upGradeImg.png"),
            alt: "",
          },
        }),
        _c("div", { staticClass: "up-grade-wrap" }, [
          _c("img", {
            staticClass: "up-grade-hand",
            attrs: {
              src: require("../../../../../../assets/imgs/header/upGradeHand.png"),
              alt: "",
            },
          }),
          _c("div", { staticClass: "title" }, [
            _vm._v("新版绩效重磅上线，超多功能等你来体验～"),
          ]),
          _c("ul", [
            _c("li", [
              _vm._v(
                "1.更丰富的考核流程：指标制定、指标确认、结果校准、绩效沟通等超多流程，每个环节都可指定角色处理，考核流程更加严谨"
              ),
            ]),
            _c("li", [
              _vm._v(
                "2.支持更多内容设置：指标库、等级设置、考评关系、考核模板、字段管理等均可设置"
              ),
            ]),
            _c("li", [
              _vm._v(
                "3.更加灵活的考评关系，逐级评分、360度环评、还可定义可见考评范围"
              ),
            ]),
            _c("li", [
              _vm._v("4.支持更多评分方式：按指标评分、整体评分、整体评等级"),
            ]),
          ]),
        ]),
        _c(
          "button",
          {
            directives: [
              { name: "preventReClick", rawName: "v-preventReClick" },
            ],
            staticClass: "edit-btn",
            attrs: { type: "button" },
            on: { click: _vm.confirm },
          },
          [_vm._v("查看更多")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }