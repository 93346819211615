<template>
  <div class="attach-project">
    <el-popover
      ref="attachProjectListRef"
      popper-class="attach-project-pop attach-project-list-pop"
      :visible-arrow="false"
      placement="right"
    >
      <div class="back-btn" @click="backFun">
        <i class="el-icon-arrow-left back-icon"></i>
        返回
      </div>
      <div class="project-list">
        <div
          v-for="item in allProjectList"
          :key="item.id"
          class="project-item"
          @click="addSelect(item)"
        >
          <div class="project-content">
            <div class="project-icon" :style="`background:${item.colour}`">
              <!-- <img :src="item.logo" /> -->
              <el-image :src="item.logo || require('@/assets/imgs/common/avatar.png')" fit="cover" />
            </div>
            <div class="project-name">{{ item.name }}</div>
          </div>
          <div v-if="item.checked" class="check-icon">
            <i class="el-icon-check"></i>
          </div>
        </div>
      </div>
      <div slot="reference" class="add-attach-btn" v-show="isEdit">
        <Icon icon-class="icon-a-tianjia2" class="add-icon" />关联新项目
      </div>
    </el-popover>
  </div>
</template>
<script>
export default {
  props: {
    isEdit: {
      type: Boolean,
      default: true
    },
    allProjectList: {
      type: Array
    },
    selectProjects: {
      type: Array
    }
  },
  data() {
    return {}
  },
  watch: {
    selectProjects: {
      handler(val) {
        this.allProjectList.forEach((item) => {
          const hasCheck = val.some((selectItem) => {
            return item.id === selectItem.projectBaseDto.id
          })
          this.$set(item, 'checked', hasCheck)
        })
        this.$forceUpdate()
      },
      immediate: true,
      deep: true
    },
    allProjectList: {
      handler(val) {
        this.allProjectList.forEach((item) => {
          const hasCheck = this.selectProjects.some((selectItem) => {
            return item.id === selectItem.projectBaseDto.id
          })
          item.checked = hasCheck
          this.$set(item, 'checked', hasCheck)
          this.$forceUpdate()
        })
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    // 添加关联项目
    addSelect(item) {
      if (item.checked) {
        return
      }
      this.$emit('addSelect', item)
      this.backFun()
    },
    // 返回
    backFun() {
      if (this.$refs.attachProjectListRef) {
        this.$refs.attachProjectListRef.doClose()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.attach-project {
  margin-left: 16px;
}
.project-icon {
  .el-image {
    width: 32px;
    height: 32px;
    border-radius: 4px;
  }
}
.add-attach-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 12px;
  .add-icon {
    font-size: 22px;
    width: 22px;
    height: 22px;
    margin-right: 8px;
  }
  color: #3a78f6;
}
</style>
<style lang="scss">
.attach-project-list-pop {
  margin-left: 20px !important;
}
</style>
