<template>
  <div>
    <div class="dropdown-wrap">
      <el-dropdown trigger="click" ref="dropdown" :hide-on-click="false">
        <span class="permission-text">
          <Icon
            :iconClass="
              item && item.lookRulesType == 0 ? 'icon-public' : 'icon-simi'
            "
            class="icon-privite"
          />
          {{ lookRulesName[(item && item.lookRulesType) || 0] }}
          <i
            class="el-icon-arrow-down el-icon--right"
          ></i>
        </span>
        <el-dropdown-menu slot="dropdown" v-if="!customRangeVisible">
          <div class="permission-select">
            <div class="select-content">
              <div
                class="select-type-list"
                @click="selectTypeClick(index)"
                v-for="(item, index) in lookRulesName"
                :key="index"
              >
                <div
                  :class="`select-type select-type-${
                    ((selectItem && selectItem.lookRulesType) || 0) ==
                    index.toString()
                  }`"
                ></div>
                {{ item }}
              </div>
              <div class="person-select-wrap" v-if="selectItem && selectItem.lookRulesType == '2'">
                <div class="range-select">
                  <div>
                    <div class="select-item" @click="personTypeClick('departSelect')">
                      本部门成员可见
                      <Icon
                        icon-class="icon-draw_checked"
                        class="checked-img"
                        v-show="selectForm.departSelect"
                      />
                    </div>
                    <div
                      v-if="selectForm.departSelect"
                      :class="`person-son-type person-son-type-${selectForm.subCheck}`"
                    >
                      <el-checkbox v-model="selectForm.subCheck" />包含所有子部门成员
                    </div>
                  </div>
                  <div>
                    <div class="select-item" @click="personTypeClick('upSelect')">
                      直属上级可见
                      <Icon
                        icon-class="icon-draw_checked"
                        class="checked-img"
                        v-show="selectForm.upSelect"
                      />
                    </div>
                    <div
                      v-if="selectForm.upSelect"
                      :class="`person-son-type person-son-type-${selectForm.colCheck}`"
                    >
                      <el-checkbox v-model="selectForm.colCheck" />相同上级的同事可见
                    </div>
                  </div>
                  <div class="select-item" @click="personTypeClick('chargeSelect')">
                    部门负责人可见
                    <Icon
                      icon-class="icon-draw_checked"
                      class="checked-img"
                      v-show="selectForm.chargeSelect"
                    />
                  </div>
                </div>
                <div @click="addRange" class="person-add">+ 自定义可见范围</div>
                <div
                  v-if="
                    customDepartList.length > 0 || customPersonList.length > 0
                  "
                  class="customList"
                >
                  已选：
                  <span v-for="(item, index) in customDepartList" :key="item.id">
                    <wx-open-data :id="item.name" :nameData="{name:item.name}" :type="2" />
                    <span
                      v-if="
                        index !== customPersonList.legnth - 1 ||
                        customPersonList.length > 0
                      "
                    >、</span>
                  </span>
                  <span v-for="(item, index) in customPersonList" :key="item.id">
                    <wx-open-data :id="item.username" :nameData="{name:item.name}" />
                    <span v-if="index !== customPersonList.length - 1">、</span>
                  </span>
                </div>
              </div>
            </div>
            <div class="confirm-btn" @click="confirmSave">确定</div>
          </div>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <customRange
      v-if="customRangeVisible"
      v-model="customRangeVisible"
      :customPersonList="customPersonList"
      :customDepartList="customDepartList"
      @refresh="okrRefresh"
      @confirm="customRangeConfirm"
      @click.stop
    />
  </div>
</template>
<script>
import customRange from './customRange.vue'
export default {
  components: { customRange },
  props: {
    item: {
      type: Object
    },
    type: {
      // 1:okr 2:kr
      type: Number,
      default: 1
    },
    index: {
      // kr下标
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      selectForm: {
        departSelect: false, // 本部门成员可见
        subCheck: false, // 包含子部门
        upSelect: false, // 直属上级可见
        colCheck: false, // 相同上级的同事可见
        chargeSelect: false // 部门负责人可见
      },
      selectItem: {},
      // 0:用户 1：部门 2:角色 3：部门成员可见  4：部门负责人可见 5：直属上级可见  6：子部门成员可见 7：相同上级的同事可见
      lookRulesName: {
        0: '公开',
        1: '仅自己可见',
        2: '部分人可见'
      },
      selectFormType: {
        departSelect: 3,
        chargeSelect: 4,
        upSelect: 5,
        subCheck: 6,
        colCheck: 7,
        3: 'departSelect',
        4: 'chargeSelect',
        5: 'upSelect',
        6: 'subCheck',
        7: 'colCheck'
      },
      selectRange: [], // 已选可见范围
      selectVal: '公开',
      visible: false, // 弹窗展示
      customRangeVisible: false,
      customDepartList: [],
      customPersonList: []
    }
  },
  mounted() {
    this.selectItemInit()
    // document.body.addEventListener('click', (e) => {
    //   console.log('stopPropagation')
    //   e.stopPropagation()
    // })
  },
  watch: {
    item: function (val) {
      this.selectItemInit()
    },
    customRangeVisible: function (val) {
      if (!this.customRangeVisible) {
        this.$refs.dropdown.show()
      }
    }
  },
  methods: {
    selectItemInit() {
      this.selectItem = {
        index: this.index,
        type: this.type,
        lookRulesType: (this.item && this.item.lookRulesType) || 0,
        objectivesPowerCustomizeList:
          (this.item && this.item.objectivesPowerCustomizeList) || []
      }
      this.$nextTick(() => {
        this.selectFormInit()
      })
    },
    selectFormInit() {
      if (this.selectItem.lookRulesType === 2) {
        this.selectItem.objectivesPowerCustomizeList.forEach((item) => {
          if (item.businessType === 0) {
            const list = []
            item.objectivesPowerCustomizeDataList.forEach((item) => {
              item.user.userId = item.user.id
              list.push(item.user)
            })
            this.customPersonList = list
          } else if (item.businessType === 1) {
            const list = []
            item.objectivesPowerCustomizeDataList.forEach((item) => {
              list.push(item.depart)
            })
            this.customDepartList = list
          } else {
            this.selectForm[this.selectFormType[item.businessType]] = true
          }
        })
        console.log('customDepartList', this.customDepartList)
        console.log('customPersonList', this.customPersonList)
      }
    },
    // 选择大类
    selectTypeClick(index) {
      this.selectItem.lookRulesType = index
    },
    // 选择自定义类
    personTypeClick(name) {
      this.selectForm[name] = !this.selectForm[name]
    },
    customRangeList() {
      if (this.selectItem === 0 || this.selectItem === 1) {
        this.objectivesPowerCustomizeList = []
      } else {
        const list = []
        for (var i in this.selectForm) {
          if (this.selectForm[i]) {
            list.push({
              businessType: this.selectFormType[i],
              businessIdList: []
            })
          }
        }
        if (this.customDepartList.length !== 0) {
          const idList = []
          this.customDepartList.forEach((item) => {
            idList.push(item.id)
          })
          list.push({
            businessType: 1,
            businessIdList: idList
          })
        }
        if (this.customPersonList.length !== 0) {
          const idList = []
          this.customPersonList.forEach((item) => {
            idList.push(item.id)
          })
          list.push({
            businessType: 0,
            businessIdList: idList
          })
        }
        this.selectItem.objectivesPowerCustomizeList = list
      }
    },
    // 确定修改权限
    confirmSave() {
      this.customRangeList()
      console.log('selectItem', this.selectItem)
      if (
        this.selectItem.lookRulesType == 2 &&
        this.selectItem.objectivesPowerCustomizeList.length === 0
      ) {
        this.$message.warn('选择的数据不能为空哦')
        return
      }
      this.$emit('confirm', this.selectItem)
      this.$refs.dropdown.hide()
    },
    customRangeConfirm(val) {
      this.customPersonList = val.customPersonList
      this.customDepartList = val.customDepartList
      // this.$refs.dropdown.show()
    },
    // 选择自定义范围
    addRange() {
      this.customRangeVisible = true
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .el-dropdown-menu {
  z-index: 2005 !important;
}
.permission-text {
  color: #14142b;
  font-weight: normal;
  cursor: pointer;
  .flex-l();
  justify-content: flex-end;
  .icon-privite {
    margin: 0px 4px;
    color: #a0a3bd;
  }
  .el-icon-arrow-down {
    margin-left: 4px;
  }
  &:hover {
    color: #3a78f6;
  }
}
.show-permission {
  border-radius: 8px;
  background: #f5f6fa;
  color: #14142b;
}
.permission-select {
  width: 240px;
  padding-bottom: 12px;
  display: flex;
  flex-direction: column;
  height: 260px;
  overflow-y: auto;
  .select-content {
    max-height: 380px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    flex: 1;
    .select-type-list {
      .flex-bt();
      justify-content: flex-start;
      padding: 12px;
      color: #14142b;
      line-height: 16px;
      font-size: 14px;
      cursor: pointer;
      .select-type {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background: #fff;
        border: 3px solid rgba(245, 246, 250, 0);
        margin-right: 8px;
      }
      .select-type-true {
        border: 3px solid #3a78f6;
      }
      .checked-img {
        color: #3a78f6;
        font-size: 16px;
      }
      &:hover {
        background: #f5f6fa;
        .select-type {
          background: none;
        }
      }
    }
    .person-select-wrap {
      margin-left: 30px;
      .select-item,
      .person-son-type,
      .person-add {
        min-height: 40px;
        .flex-bt();
        padding: 0px 12px;
        cursor: pointer;
        .checked-img {
          color: #3a78f6;
          font-size: 16px;
        }

        &:hover {
          background: rgba(245, 246, 250, 0.56);
        }
      }
      .customList {
        padding: 0px 12px;
        line-height: 21px;
      }
      .person-son-type {
        margin-left: 12px;
        .flex-l();
        color: #a0a3bd;
        label {
          margin-right: 8px;
        }
      }
      .person-son-type-true {
        color: #14142b;
      }
      .person-add {
        font-size: 14px;
        font-weight: 400;
        color: #3a78f6;
        cursor: pointer;
      }
    }
  }
  .confirm-btn {
    background: #3a78f6;
    color: #fff;
    margin: 12px 12px 0px 12px;
    height: 40px;
    border-radius: 16px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    &:hover {
      background: #548af7;
    }
  }
}
</style>
