var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "attach-project" },
    [
      _c(
        "el-popover",
        {
          ref: "attachProjectListRef",
          attrs: {
            "popper-class": "attach-project-pop attach-project-list-pop",
            "visible-arrow": false,
            placement: "right",
          },
        },
        [
          _c("div", { staticClass: "back-btn", on: { click: _vm.backFun } }, [
            _c("i", { staticClass: "el-icon-arrow-left back-icon" }),
            _vm._v(" 返回 "),
          ]),
          _c(
            "div",
            { staticClass: "project-list" },
            _vm._l(_vm.allProjectList, function (item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "project-item",
                  on: {
                    click: function ($event) {
                      return _vm.addSelect(item)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "project-content" }, [
                    _c(
                      "div",
                      {
                        staticClass: "project-icon",
                        style: `background:${item.colour}`,
                      },
                      [
                        _c("el-image", {
                          attrs: {
                            src:
                              item.logo ||
                              require("@/assets/imgs/common/avatar.png"),
                            fit: "cover",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "project-name" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                  ]),
                  item.checked
                    ? _c("div", { staticClass: "check-icon" }, [
                        _c("i", { staticClass: "el-icon-check" }),
                      ])
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isEdit,
                  expression: "isEdit",
                },
              ],
              staticClass: "add-attach-btn",
              attrs: { slot: "reference" },
              slot: "reference",
            },
            [
              _c("Icon", {
                staticClass: "add-icon",
                attrs: { "icon-class": "icon-a-tianjia2" },
              }),
              _vm._v("关联新项目 "),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }