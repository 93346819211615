var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "apply-demo-dialog",
      attrs: {
        visible: _vm.dialogVisible,
        width: "100%",
        "append-to-body": true,
        "modal-append-to-body": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c("div", { staticClass: "remind-wrap" }, [
        _c(
          "a",
          {
            attrs: {
              href: "https://mp.weixin.qq.com/s/POc4c-oDyIUD9MSzVmHiWw",
              target: "_blank",
            },
            on: { click: _vm.closeDialog },
          },
          [
            _c("img", {
              attrs: {
                src: require("../../../../../../assets/imgs/header/doubleEleven.png"),
                alt: "",
              },
            }),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }