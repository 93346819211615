export default [
  {
  path: '/members',
  name: 'members',
  component: () => {
    /* webpackChunkName: "members" */
    return import('../../views/global/layout/header/members/index')
  }
},
{
  path: '/feedBack/okr/whatOkr',
  name: 'whatOkr',
  component: () => {
    /* webpackChunkName: "feedBackWhatOkr" */
    return import('../../views/feedBack/okr/whatOkr')
  }
},
{
  path: '/feedBack/okr/setOkr',
  name: 'setOkr',
  component: () => {
    /* webpackChunkName: "feedBackSetOkr" */
    return import('../../views/feedBack/okr/setOkr')
  }
},
{
  path: '/approveList',
  name: 'approveList',
  component: () => {
    /* webpackChunkName: "approveList" */
    return import('../../views/global/layout/header/approveList/index')
  }
}
]
