var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "task-edit-form-box" }, [
    _c(
      "div",
      { key: _vm.index, staticClass: "form-row" },
      [
        _vm.infoItem.fieldAttribute == "singleChoice"
          ? _c(
              "el-select",
              {
                staticClass: "input-width input-radio",
                attrs: {
                  placeholder: "未选择",
                  clearable: "",
                  disabled: _vm.disabled,
                },
                on: {
                  change: function ($event) {
                    return _vm.submitVal(
                      _vm.infoItem.id,
                      _vm.infoForm[_vm.infoItem.id]
                    )
                  },
                },
                model: {
                  value: _vm.infoForm[_vm.infoItem.id],
                  callback: function ($$v) {
                    _vm.$set(_vm.infoForm, _vm.infoItem.id, $$v)
                  },
                  expression: "infoForm[infoItem.id]",
                },
              },
              _vm._l(_vm.infoItem.fieldsCustomfieldsChoices, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.value, value: item.value },
                })
              }),
              1
            )
          : _vm.infoItem.fieldAttribute == "multipleChoice"
          ? _c(
              "div",
              { staticClass: "task-select" },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "input-width",
                    staticStyle: { width: "570px" },
                    attrs: {
                      multiple: "",
                      disabled: _vm.disabled,
                      placeholder: "未选择",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.submitVal(
                          _vm.infoItem.id,
                          _vm.infoForm[_vm.infoItem.id]
                        )
                      },
                    },
                    model: {
                      value: _vm.infoForm[_vm.infoItem.id],
                      callback: function ($$v) {
                        _vm.$set(_vm.infoForm, _vm.infoItem.id, $$v)
                      },
                      expression: "infoForm[infoItem.id]",
                    },
                  },
                  _vm._l(
                    _vm.infoItem.fieldsCustomfieldsChoices,
                    function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.value, value: item.value },
                      })
                    }
                  ),
                  1
                ),
              ],
              1
            )
          : _vm.infoItem.fieldAttribute == "text"
          ? _c("el-input", {
              staticClass: "input-width",
              attrs: {
                autosize: { minRows: 1, maxRows: 4 },
                type: "textarea",
                disabled: _vm.disabled,
                placeholder: "未填写",
              },
              on: {
                input: function ($event) {
                  return _vm.inputChange(
                    _vm.infoItem,
                    _vm.infoForm[_vm.infoItem.id]
                  )
                },
                blur: function ($event) {
                  return _vm.submitVal(
                    _vm.infoItem.id,
                    _vm.infoForm[_vm.infoItem.id]
                  )
                },
              },
              model: {
                value: _vm.infoForm[_vm.infoItem.id],
                callback: function ($$v) {
                  _vm.$set(_vm.infoForm, _vm.infoItem.id, $$v)
                },
                expression: "infoForm[infoItem.id]",
              },
            })
          : _vm.infoItem.fieldAttribute == "date"
          ? _c("el-date-picker", {
              staticClass: "input-width input-date",
              attrs: {
                disabled: _vm.disabled,
                type: "datetime",
                format: "yyyy-MM-dd HH:mm",
                "value-format": "yyyy-MM-dd HH:mm",
                placeholder: "选择日期时间",
              },
              on: {
                change: function ($event) {
                  return _vm.submitVal(
                    _vm.infoItem.id,
                    _vm.infoForm[_vm.infoItem.id]
                  )
                },
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.submitVal(
                    _vm.infoItem.id,
                    _vm.infoForm[_vm.infoItem.id]
                  )
                },
              },
              model: {
                value: _vm.infoForm[_vm.infoItem.id],
                callback: function ($$v) {
                  _vm.$set(_vm.infoForm, _vm.infoItem.id, $$v)
                },
                expression: "infoForm[infoItem.id]",
              },
            })
          : _vm.infoItem.fieldAttribute == "number"
          ? _c(
              "div",
              { staticClass: "number-div" },
              [
                _c("taskNumber", {
                  attrs: {
                    "field-info": _vm.infoItem,
                    disabled: _vm.disabled,
                    value: _vm.infoForm[_vm.infoItem.id],
                    isShow: true,
                  },
                  on: { change: _vm.changeVal, inputBlur: _vm.inputBlur },
                }),
              ],
              1
            )
          : _vm.infoItem.fieldAttribute == "file"
          ? _c(
              "div",
              { staticStyle: { "margin-left": "12px", "margin-top": "2px" } },
              [
                _c("TaskFile", {
                  attrs: {
                    infoItem: _vm.infoItem,
                    fileList: _vm.infoForm[_vm.infoItem.id],
                    width: 300,
                    disabled: _vm.disabled,
                  },
                  on: { fileFresh: _vm.fileFresh },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }