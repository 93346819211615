import remindEnum from '@/utils/enums/remind.js' // 提醒
import repeatEnum from '@/utils/enums/repeat.js' // 重复
import taskEnum from '@/utils/enums/task.js' // 重复
const dateTypeEnum = remindEnum.DATETYPENUM // 日期类型枚举

class Task {
  // 提醒时间转化
  remindDateFmt(row) {
    if (row && row.dateType !== 1) {
      const { dateType, number, numberType } = row
      const msgMap = {}
      msgMap[dateTypeEnum.not] = '不提醒'
      msgMap[dateTypeEnum.start] = '任务开始时'
      msgMap[dateTypeEnum.end] = '任务截止时'
      if (msgMap[dateType]) {
        console.log(msgMap[dateType])
        return msgMap[dateType]
      }
      // 自定义时间
      if (dateTypeEnum.custom === dateType) {
        return row.customDate.substring(0, 16)
      }
      const dateTypeText = remindEnum.DATETYPE[dateType] // 日期类型文本
      const numberText = remindEnum.NUMBER[number] // 数量文本
      const numberTypeText = remindEnum.NUMBERTYPE[numberType] // 数量类型文本
      return `${dateTypeText} ${numberText} ${numberTypeText}`
    } else if (row && row.dateType === 1) {
      return '任务开始时'
    } else {
      return '设置提醒'
    }
  }
  // 周期转化
  cycleDateFmt(row) {
    if (!row) {
      return '设置重复'
    }
    // 非自定义重复
    if (row.type !== repeatEnum.CYCLENUMTYPE.custom) {
      return repeatEnum.REPEATTYPE[row.type]
    }
    const { customType } = row
    // 自定义重复类型枚举
    const customTypeEnum = repeatEnum.CYCLECUSTOMTYPE
    // 每天重复
    if (customType === customTypeEnum.daily) {
      return this.dailyCycleFmt(row)
    }
    // 每周重复
    if (customType === customTypeEnum.weekly) {
      return this.weeklyCycleFmt(row)
    }
    // 每月重复
    if (customType === customTypeEnum.month) {
      return this.monthCycleFmt(row)
    }
  }
  // 每天重复
  dailyCycleFmt(row) {
    if (row.customInterval === 1) {
      return '每天重复'
    } else {
      return `每${row.customInterval}天重复`
    }
  }
  // 每周重复
  weeklyCycleFmt(row) {
    let customInterval = row.customInterval
    const customCycleNum = row.customCycleNum
    customInterval = customInterval === 1 ? '' : customInterval
    let customCycleNumArr = customCycleNum.split(',')
    if (customCycleNumArr.length === 0) {
      return `每${customInterval}周重复`
    }
    const weekToCnmap = {
      1: '周一',
      2: '周二',
      3: '周三',
      4: '周四',
      5: '周五',
      6: '周六',
      7: '周日'
    }
    customCycleNumArr = customCycleNumArr.map((item) => {
      return weekToCnmap[item]
    })
    return `每${customInterval}周 ${customCycleNumArr.toString()} 重复`
  }
  // 每月重复
  monthCycleFmt(row) {
    let customInterval = row.customInterval
    const customCycleNum = row.customCycleNum
    customInterval = customInterval === 1 ? '' : customInterval
    const customCycleNumArr = customCycleNum.split(',')
    if (customCycleNumArr.length === 0) {
      return `每${customInterval}月重复`
    }
    return `每${customInterval}月 ${customCycleNumArr.toString()} 重复`
  }
  // 优先级颜色
  priorityColor(val) {
    const priorityEnum = taskEnum.PRIOTITYCOLOR // 优先级枚举
    const map = {}
    map[priorityEnum.hight] = 'c-cinnabar'
    map[priorityEnum.normal] = 'c-sea-buckthorn'
    map[priorityEnum.low] = 'c-dusty-gray'
    return map[val]
  }
}

export default new Task()
