import db from './ocrud'

// 拿到推送的数据
let setData = (type, data) => {
  db.update(type, data)
}

let add = db.add
let read = db.read
let update = db.update
let open = db.initRedPointDB
let close = db.closeRedPointDB

export default { setData, update, open, close, read }
