// 周期枚举
const CYCLETYPE = {
  0: '周',
  1: {
    1: '单月',
    2: '双月',
    6: '半年'
  },
  2: '季度',
  3: '年度',
  4: '自定义'
}
// 周期类型
const TYPE = {
  not: 1, // 不重复
  custom: 7 // 自定义时间
}
// 自定义重复类型
const CUSTOMTYPE = {
  // not: 1, // 不重复
  daily: 1, // 每天重复
  weekly: 2, // 每周重复
  month: 3 // 每月重复
}
export default {
  CYCLETYPE,
  TYPE,
  CUSTOMTYPE
}
