<template>
  <el-dialog
    title="移动目标"
    :visible.sync="dialogVisible"
    width="520px"
    :append-to-body="true"
    :before-close="handleClose"
    class="comm-check-form"
  >
    <div class="dialog-content">
      <div class="form-title">
        移动到周期
        <el-cascader
          v-model="cycleId"
          class="cycle-select"
          :options="cycleList"
          :props="{
            expandTrigger: 'hover',
            value: 'id',
            label: 'name',
            children: 'cycleInfoList'
          }"
        />
      </div>
      <p class="info">将会把原内容都移动到所选周期内（目标对齐关系除外），</p>
      <p class="info">包含目标、KR及KR任务及子任务等，请谨慎操作。</p>
      <div class="bottom-step-buttons">
        <button @click="handleClose" class="left-button">取消</button>
        <button @click="save">确定</button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { mapState } from 'vuex'
import respons from '@/api/request'
export default {
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  props: {
    dialogShow: {
      type: Boolean,
      default: true
    },
    data: {
      type: Object
    }
  },
  watch: {
    dialogShow: {
      handler(val) {
        this.dialogVisible = val
      }
    }
  },
  data() {
    return {
      dialogVisible: this.dialogShow,
      hasTask: false,
      cycleList: [],
      cycleId: ''
    }
  },
  computed: {
    ...mapState('user', {
      userInfo: 'userInfo', // 用户信息
      versionNumber: 'versionNumber' // 版本控制
    })
  },
  created() {
    // 获取周期列表
    this.queryCycleList()
    // 监听审批提交
    this.bus(this).$on('approveRemindRefresh', (data) => {
      this.handleClose()
    })
  },
  methods: {
    // 获取周期列表
    async queryCycleList() {
      const url = `/api/ranloWeb/${this.versionNumber}/setcycle/list/info/list`
      await respons.get(url).then((rest) => {
        if (rest.data.length > 0) {
          (rest.data || []).forEach((list) => {
            (list.cycleInfoList || []).forEach((item) => {
              if ((list.type === 1 || list.type === 2) && item.type === 0) {
                item.name = `${item.startDate.substring(0, 4)}年 ${item.name}`
              }
            })
          })
          this.cycleList = rest.data
        }
      })
    },
    handleClose() {
      this.dialogVisible = false
      this.$emit('change', false)
    },

    async save() {
      if (!this.cycleId || this.cycleId.length === 0) {
        this.$message.warn('周期不能为空')
        return
      }
      if (this.data.cycleInfoId === this.cycleId[1]) {
        this.$message.warn('不能移动到当前周期哦')
        return
      }
      var obj = {
        id: this.data.id, // 目标id
        cycleInfoId: this.cycleId[1] // 周期id
      }
      if (this.data.objectivesProcessDataDto && this.data.objectivesProcessDataDto.businessType === 1) {
        this.$message.warn('审批中的目标无法移动哦！')
        return
      }
      if (this.data.processState === 1 && this.$store.state.user.approvalCheckData.onOff === 0 && this.data.objectivesProcessDataDto && this.data.objectivesProcessDataDto.businessType !== 1 && this.data.objectivesProcessDataDto.businessApprovalPassHistory === 1) {
        this.$store.commit('okr/approveRemindObj', {
          visible: true,
          objDetail: {
            id: this.data.id,
            ...this.data
          },
          businessData: JSON.stringify(obj),
          businessActionType: 5
        })
      } else {
        this.$apis.OKRMOVE(obj).then((res) => {
          if (res.status === 200) {
            this.$emit('startLoading', this.cycleId)
            this.$nextTick(() => {
              this.handleClose()
            })
          }
        })
      }
    }
  },
  components: {}
}
</script>
<style lang="less" scoped>
@import '../../../../assets/styles/dialog.less';
.dialog-content {
  padding-bottom: 8px;
  padding-left: 24px;
  .form-title {
    font-size: 16px;
    color: #444558;
    margin-top: 24px;
    font-weight: 500;
    margin-bottom: 16px;
  }
  .info {
    font-size: 12px;
    color: #ff9900;
    font-weight: 300;
    line-height: 18px;
  }
  .bottom-step-buttons {
    margin-top: 100px;
  }
  .cycle-select {
    margin-left: 8px;
    width: 200px;
    ::v-deep {
      .el-input__inner {
        border: 1px solid #f5f6fa;
        height: 40px;
        line-height: 40px;
        border-radius: 8px;
      }
    }
  }
}
</style>
