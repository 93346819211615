/* eslint-disable */
export default {
  data() {
    return {
      ticketAgentUrl: '',
      signData: ''
    }
  },
  created() {
    this.ticketAgentUrl = this.$utils.ticketAgentUrl()
  },
  methods: {
    // 微信签名信息获取
    async getJsapiTicketAgent(url) {
      let isQw =
        window.location.host.includes('qywx') ||
        window.location.host.includes('qw')
      if (!isQw) {
        return
      }
      if (this.$utils.is_weixin()) {
        var ticketAgentUrl = window.location.href.split('#')[0]
      } else {
        //`https://${window.location.host}/`
        var ticketAgentUrl = `https://${window.location.host}/`
      }
      var isMac = /macintosh|mac os x/i.test(navigator.userAgent)
      if (isMac && navigator.appVersion.indexOf('wxwork/3.1.18') !== -1) {
        ticketAgentUrl = `${ticketAgentUrl}?`
      }
      const params = {
        url: ticketAgentUrl
      }
      await this.$apis.GETTICKETAGENT(params).then((res) => {
        if (res.status === 200) {
          // this.$message.success('签名调用成功')
          console.log('签名调用成功', res)
          this.signData = res.data
          this.$store.commit('user/setRouterChange', false)
          this.jsAgentConfig()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 调用权限验证
    async jsAgentConfig() {
      if (/MicroMessenger/i.test(navigator.userAgent)) {
        await this.getJsSdkConfig()
      }
      await this.getAgentConfig()
    },
    // js-sdk权限验证
    async getJsSdkConfig() {
      var data = this.signData.jsConfigVo
      var config = {
        beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.appId, // 必填，企业微信的corpID
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
        jsApiList: [
          'agentConfig',
          'chooseImage',
          'thirdPartyOpenPage',
          'getCurExternalContact'
        ] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
      }
      return new Promise((resolve, reject) => {
        /* eslint-disable-next-line */
        wx.config(config)
        /* eslint-disable-next-line */
        wx.ready(resolve)
        /* eslint-disable-next-line */
        wx.error(reject)
      }).then(
        (res) => {
          // this.$message.success('js-sdk权限验证通过')
          console.log('js-sdk权限验证通过', res)
        },
        (error) => {
          // this.$message.error('js-sdk权限验证失败')
          console.log('js-sdk权限验证失败', error)
          // if (error.err_msg === 'invalid corpid more info at https://open.work.weixin.qq.com/devtool/query?e=40013') {
          //   this.$utils.redirectUrl()
          // }
          throw error
        }
      )
    },
    // 应用的权限
    async getAgentConfig() {
      var data = this.signData.jsAgentConfigVo
      console.log('应用的权限-----', data)
      var config = {
        corpid: data.corpId, // 必填，企业微信的corpid，必须与当前登录的企业一致
        agentid: data.agentId, // 必填，企业微信的应用id （e.g. 1000247）
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
        jsApiList: [
          'agentConfig',
          'selectExternalContact',
          'openUserProfile',
          'navigateToAddCustomer',
          'thirdPartyOpenPage',
          'getCurExternalContact'
        ]
      }
      return new Promise((success, fail) => {
        wx.agentConfig({
          ...config,
          success,
          fail
        })
      }).then(
        (res) => {
          // this.$message.success('应用权限验证通过')
          console.log('应用权限验证通过', res)
          return res
        },
        (error) => {
          if (
            !this.$store.state.user.redirectUrlState &&
            !this.$utils.is_weixin()
          ) {
            this.$store.commit('user/setRedirectUrlState', true)
            if ((this.$utils.getQueryStringHash('token') || '') === '') {
              this.$utils.redirectUrl()
            }
          } else {
            // this.$router.push({
            //   path: "/loginError",
            // })
            // if (error.err_msg === 'not allow to cross corp more info at https://open.work.weixin.qq.com/devtool/query?e=92002') {
            //   this.$utils.redirectUrl()
            // } else {
            //   this.$message.error('应用权限验证失败')
            // }
          }
          console.log('应用权限验证失败', error)
          throw error
        }
      )
    },
    async wxCanvasData(items) {
      if (items && items.length > 0) {
        if (WWOpenData.initCanvas) {
          WWOpenData.initCanvas()
        }
        return await new Promise((resolve, reject) => {
          WWOpenData.prefetch(
            {
              items
            },
            (err, data) => {
              // console.log('prefetchItems', items)
              if (err) {
                // console.log('prefetchError', err)
                return reject(err)
              }
              console.log('prefetchSuccess', data)
              // return data.items
              resolve(data.items)
            }
          )
        })
      } else {
        return []
      }
    }
  }
}
