var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "OKR示例",
        visible: _vm.dialogVisible,
        width: "660px",
        center: "",
        "before-close": _vm.getOkrFlashListClose,
        "append-to-body": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "okr-flash-list",
        },
        [
          _c(
            "div",
            { staticClass: "okr-flash-list-select" },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.getOkrFlashListSelect },
                  model: {
                    value: _vm.okrListValue,
                    callback: function ($$v) {
                      _vm.okrListValue = $$v
                    },
                    expression: "okrListValue",
                  },
                },
                _vm._l(_vm.gradeList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.value, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "okr-flash-list-overflow" },
            _vm._l(_vm.okrListBeans, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "okr-flash-list-ul" },
                [
                  _c("div", { staticClass: "okr-flash-list-ul-name" }, [
                    _c("img", {
                      staticClass: "okr-flash-list-ul-name-icon",
                      attrs: {
                        src: require("../../../../assets/imgs/okr/objective.png"),
                      },
                    }),
                    _c("div", { staticClass: "okr-flash-list-ul-name-text" }, [
                      _vm._v(_vm._s(item.content)),
                    ]),
                  ]),
                  _vm._l(item.keyresultsList, function (itemz, indexz) {
                    return _c(
                      "div",
                      {
                        key: indexz,
                        staticClass: "okr-flash-list-ul-li",
                        class: indexz === 0 ? "okr-flash-list-ul-li-style" : "",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "okr-flash-list-ul-li-text" },
                          [_vm._v("KR" + _vm._s(indexz + 1))]
                        ),
                        _c(
                          "div",
                          { staticClass: "okr-flash-list-ul-li-text2" },
                          [_vm._v(_vm._s(itemz.title))]
                        ),
                      ]
                    )
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "okr-flash-list-ul-button",
                      on: {
                        click: function ($event) {
                          return _vm.getOkrFlashListSubmit(item)
                        },
                      },
                    },
                    [_vm._v("使用示例OKR")]
                  ),
                ],
                2
              )
            }),
            0
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }