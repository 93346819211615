const versionNumber = 'v1' // 版本
const versionNumber2 = 'v2' // 版本
// 项目
export default [
  // 获取项目工作流列表
  {
    projectProcessList: `/api/project/${versionNumber}/project/process/list`
  },
  // 修改项目流程
  {
    projectUpdateProcess: `/api/project/${versionNumber}/project/task/process`
  },
  {
    // 获取项目描述
    getProDesc: `/api/project/${versionNumber}/project/`
  },
  // 查询推荐的项目列表
  {
    projectRecommendList: `/api/ranloTask/${versionNumber}/project/recommendProjectList`
  },
  {
    // 项目列表
    projectList: `/api/project/${versionNumber}/project/list`
  },
  {
    // 项目模版列表
    proTemList: `/api/project/${versionNumber}/project/template/list`
  },
  {
    // 项目任务负责人修改
    proTaskChargeUserUpdate: `/api/project/${versionNumber}/task/chargeUser/update`
  },
  {
    // 项目任务状态修改
    proTaskStatusUpdate: `/api/project/${versionNumber}/task/updateStatus`
  },
  {
    // 项目任务排序
    proTaskFieldUpdate: `/api/project/${versionNumber}/project/task/field/`
  },
  {
    // 获取个人任务列信息
    proTaskFieldList: `/api/project/${versionNumber}/project/task/field`
  },
  {
    // 项目任务删除
    proTaskDel: `/api/project/${versionNumber}/task/delete`
  },
  {
    // 单个增加项目人员
    proAddPeopleOne: `/api/project/${versionNumber}/project/user`
  },
  {
    // 项目人员删除
    proPeopleDel: `/api/project/${versionNumber}/project/user/`
  },
  {
    // 修改项目概述
    proDescEdit: `/api/project/${versionNumber}/project/`
  },
  {
    // 新增流程
    proProcessAdd: `/api/project/${versionNumber}/project/process`
  },
  {
    // 编辑流程
    proProcessEdit: `/api/project/${versionNumber}/project/process/`
  },
  {
    // 删除流程
    proProcessDel: `/api/project/${versionNumber}/project/process/`
  },
  {
    // 项目动态
    proCommentList: `/api/project/${versionNumber}/project/comment/list`
  },
  {
    // 项目删除
    proDel: `/api/project/${versionNumber}/project/`
  },
  {
    // 项目归档
    proClose: `/api/project/${versionNumber}/project/close/`
  },
  {
    // 项目恢复
    proRecovery: `/api/project/${versionNumber}/project/recovery/`
  },
  {
    // 编辑项目名称
    proNameEdit: `/api/project/${versionNumber}/project/`
  },
  {
    // 新增项目
    proAdd: `/api/project/${versionNumber}/project/add`
  },
  {
    // 获取选择模版详情数据
    proTemDetail: `/api/project/${versionNumber}/project/template/`
  },
  {
    // 获取选择模版类型
    proTemType: `/api/project/${versionNumber}/project/type`
  },
  {
    // 多选添加项目人员
    proAddPeopleNumber: `/api/project/${versionNumber}/project/user/addList`
  },
  {
    // 获取项目人员
    proPeopleList: `/api/project/${versionNumber}/project/user/list`
  },
  {
    // 获取今日任务完成数
    proTaskTodayFinishCount: `/api/project/${versionNumber}/project/task/todayFinishCount`
  },
  {
    // 查询当前项目关联的KR列表
    proAttachKrList: `/api/ranloWeb/${versionNumber}/keyresults/project/user/kr/list`
  },
  {
    // 复制项目
    proCopy: `/api/project/${versionNumber}/project/coppyProject`
  },
  {
    // 星标项目
    getStarProject: `/api/project/${versionNumber}/project/getStarProjectList`
  },
  {
    // 我的项目
    getMyProject: `/api/project/${versionNumber2}/project/getMyProjectList`
  },
  {
    // 企业项目
    getCompanyProject: `/api/project/${versionNumber2}/project/getCompanyProjectList`
  },
  {
    // 归档项目
    getCloseProject: `/api/project/${versionNumber2}/project/getCloseProjectList`
  },
  {
    // 收藏项目
    projectStar: `/api/project/${versionNumber}/project/star`
  },
  {
    // 项目资源
    getProjectFileList: `/api/project/${versionNumber}/project/resources`
  },
  {
    // 项目状态
    editProjectStatus: `/api/project/${versionNumber}/project/status`
  },
  {
    // 项目到期日期
    editProjectEndDate: `/api/project/${versionNumber}/project/date`
  },
  {
    // 我浏览的项目
    getBrowseProject: `/api/project/${versionNumber}/project/browse/project`
  },
  {
    // 退出项目
    projectQuit: `/api/project/${versionNumber}/project/quit/`
  },
  {
    // 设置取消管理员
    setProjectAdmin: `/api/project/${versionNumber}/project/user`
  },
  {
    // 获取项目统计列表
    getProAnalyseList: `/api/analysis/${versionNumber}/project/person/listAll`
  },
  {
    // 获取单个统计概览信息
    getProAnalyseView: `/api/analysis/${versionNumber}/project/condition/view/`
  },
  {
    // 获取单个报表详情
    getProAnalyseInfo: `/api/analysis/${versionNumber}/project/condition/info/`
  },
  {
    // 获取项目标签列表
    getProjectLable: `/api/ranloTask/${versionNumber}/project/label`
  },
  {
    // 获取单个报表详情分页数据
    getProDetailPage: `/api/analysis/${versionNumber}/project/condition/info/page/`
  },
  {
    // 甘特图任务列表
    ganttTaskList: `/api/ranloTask/${versionNumber2}/project/ganttchart/task?projectId=`
  },
  {
    // 设置依赖类型
    ganttLinkTypeSet: `/api/ranloTask/${versionNumber}/project/ganttchart/`
  },
  {
    // 甘特图添加依赖
    ganttLinkAdd: `/api/ranloTask/${versionNumber}/project/ganttchart/task/relation/`
  },
  {
    // 甘特图删除依赖
    ganttLinkDel: `/api/ranloTask/${versionNumber}/project/ganttchart/task/`
  },
  {
    // 甘特图任务拖拽
    ganttTaskDrag: `/api/ranloTask/${versionNumber}/project/ganttchart/date/`
  },
  {
    // 项目数量统计
    pmProNum: `/api/project/${versionNumber}/project/getProjectStatisticsNum`
  },
  {
    // 添加项目分类
    pmAddCategories: `/api/project/${versionNumber}/project/categories`
  },
  {
    // 项目分类列表
    pmCategoriesList: `/api/project/${versionNumber}/project/categories/list`
  },
  {
    // 项目分类关联项目列表
    pmRelationProjectList: `/api/project/${versionNumber}/project/getRelationProjectList/`
  },
  {
    // 根据分类id获取分类下面所有项目id列表
    projectIdListByCategoriesId: `/api/project/${versionNumber}/project/getProjectIdListByCategoriesId/`
  },
  {
    // 分类添加项目（建立分类和项目关联关系）
    addProjectCategoriesRelation: `/api/project/${versionNumber}/project/categories/addProjectCategoriesRelation/`
  },
  {
    // 项目分类下项目列表
    getClassProject: `/api/project/${versionNumber2}/project/getProjectListByCategoriesId`
  },
  {
    // 置顶项目
    projectTop: `/api/project/${versionNumber}/project/top`
  },
  {
    // 查询里程碑
    projectMilepostList: `/api/ranloTask/${versionNumber}/project/milepost/list`
  },
  {
    // 添加里程碑
    projectMilepostSave: `/api/ranloTask/${versionNumber}/project/milepost/save`
  },
  {
    // 修改里程碑
    projectMilepostUpdate: `/api/ranloTask/${versionNumber}/project/milepost/update`
  },
  {
    // 分页查询里程碑动态列表
    milepostDynamicPageList: `/api/ranloTask/${versionNumber}/project/milepost/dynamic/page/list`
  },
  {
    // 添加里程碑评论
    milepostDynamicSave: `/api/ranloTask/${versionNumber}/project/milepost/dynamic/save`
  },
  {
    // 根据评论id,删除里程碑评论
    milepostDynamicDelete: `/api/ranloTask/${versionNumber}/project/milepost/dynamic/delete`
  },
  {
    // 删除里程碑
    milepostDelete: `/api/ranloTask/${versionNumber}/project/milepost/delete`
  },
  {
    // 项目任务搜索
    projectTaskSearch: `/api/project/${versionNumber}/project/task/search`
  },
  {
    // 项目搜索
    projectSearchShortCut: `/api/project/${versionNumber}/project/search`
  },
  {
    // 获取项目信息（精简）
    projectSimplifyInfo: `/api/project/${versionNumber}/project/simplify/`
  },
  {
    // 时间轴导出表格
    pmGanttDownload: `/api/ranloTask/${versionNumber}/project/ganttchart/exportTask?projectId=`
  },
  {
    // 项目移交所有者
    pmTransfer: `/api/ranloTask/${versionNumber}/project/transfer`
  },
  {
    // 项目导入任务
    pmImportTask: `/api/ranloTask/${versionNumber2}/task/operate/importProjectTask`
  },
  {
    // 项目导出任务
    pmExportTask: `/api/analysis/${versionNumber}/project/task/export/`
  },
  {
    // 项目任务导出管理
    pmExportTaskList: `/api/ranloTask/${versionNumber}/table/data/list`
  },
  {
    // 项目任务审批列表
    pmApprovalPageList: `/api/ranloTask/${versionNumber}/task/process/data/page/list`
  },
  {
    // 项目任务-提交人撤销/提交流程
    pmProcessDataSubmitt: `/api/ranloTask/${versionNumber}/task/process/data/submitter`
  },
  {
    // 项目任务催办
    pmTaskApprovalDataRemind: `/api/ranloTask/${versionNumber}/task/process/data/remind`
  },
  {
    // 变更父任务
    pmTaskUpdateTaskFather: `/api/ranloTask/${versionNumber}/task/updateTaskFather`
  },
  {
    // 复制项目流程中的任务
    pmProjectProcessCopyTask: `/api/ranloTask/${versionNumber}/project/process/copyTask`
  }
]
