<template>
  <el-tooltip
    placement="top"
    :open-delay="500"
    :content="content"
    :disabled="content === '' ? true : false"
  >
    <div
      class="avatar-img"
      :style="`width:${size}px;height:${size}px;margin-right:${marginRight}px`"
      :class="canClick ? 'avatar-click' : ''"
      @click="clickAvatar"
    >
      <el-image
        :style="`width:${size}px;height:${size}px;`"
        fit="cover"
        v-if="avatar"
        :src="avatar"
        class="border-radius-20"
      />
      <el-image
        :style="`width:${size}px;height:${size}px;`"
        fit="cover"
        v-else
        src="/common/avatar.png"
        class="border-radius-20"
      />
      <div v-if="locked" class="avatar-lock-box">
        <img src="../../../assets/imgs/common/private-close.png" class="locked-icon" />
      </div>
    </div>
  </el-tooltip>
</template>
<script>
export default {
  props: {
    avatar: {
      // 头像地址
      type: String
    },
    size: {
      type: [String, Number],
      default: 20
    },
    locked: {
      type: Boolean,
      default: false
    },
    canClick: {
      type: Boolean,
      default: false
    },
    marginRight: {
      type: Number,
      default: 0
    },
    content: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  methods: {
    clickAvatar() {
      if (this.canClick) {
        this.$emit('clickAvatar')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.avatar-img {
  border-radius: 50%;
  flex-shrink: 0;
  position: relative;
  .el-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  &:hover {
    // .avatar-lock-box {
    //   display: none;
    // }
  }
  .avatar-lock-box {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    bottom: -4px;
    right: -4px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    .locked-icon {
      width: 100%;
      max-width: 16px;
      height: auto;
    }
  }
}
.avatar-click {
  cursor: pointer;
}
</style>
