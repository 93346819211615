import 'element-ui/lib/theme-chalk/index.css'
import {
  Button,
  Select,
  Option,
  Loading,
  Form,
  FormItem,
  Input,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  MessageBox,
  Dialog,
  Tooltip,
  Popover,
  Drawer,
  Carousel,
  CarouselItem,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Progress,
  InputNumber,
  Image,
  Message,
  Upload,
  Card,
  Table,
  TableColumn,
  Radio,
  DatePicker,
  TimeSelect,
  TimePicker,
  Pagination,
  RadioGroup,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Switch,
  Col,
  Tree,
  Calendar,
  Badge,
  Cascader,
  Tabs,
  TabPane,
  Steps,
  Step,
  Collapse,
  CollapseItem
} from 'element-ui'
import Vue from 'vue'

export default () => {
  Vue.use(Checkbox)
  Vue.use(CheckboxButton)
  Vue.use(CheckboxGroup)
  Vue.use(Button)
  Vue.use(Select)
  Vue.use(Option)
  Vue.use(Loading)
  Vue.use(Form)
  Vue.use(FormItem)
  Vue.use(Input)
  Vue.use(Checkbox)
  Vue.use(CheckboxButton)
  Vue.use(CheckboxGroup)
  Vue.use(Dialog)
  Vue.use(Tooltip)
  Vue.use(Popover)
  Vue.use(Drawer)
  Vue.use(Carousel)
  Vue.use(CarouselItem)
  Vue.use(Dropdown)
  Vue.use(DropdownMenu)
  Vue.use(DropdownItem)
  Vue.use(Progress)
  Vue.use(InputNumber)
  Vue.use(Image)
  Vue.use(Card)
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Radio)
  Vue.use(Upload)
  Vue.use(DatePicker)
  Vue.use(TimePicker)
  Vue.use(TimeSelect)
  Vue.use(Pagination)
  Vue.use(RadioGroup)
  Vue.use(Menu)
  Vue.use(Submenu)
  Vue.use(MenuItem)
  Vue.use(MenuItemGroup)
  Vue.use(Switch)
  Vue.use(Col)
  Vue.use(Tree)
  Vue.use(Calendar)
  Vue.use(Badge)
  Vue.use(Cascader)
  Vue.use(Tabs)
  Vue.use(TabPane)
  Vue.use(Steps)
  Vue.use(Step)
  Vue.use(Collapse)
  Vue.use(CollapseItem)

  Vue.prototype.$confirm = MessageBox.confirm
  Vue.prototype.$message = Message
}
