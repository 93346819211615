<template>
  <!-- 企信pc迁移后新增 -->
  <el-popover popper-class="view-target-pop" @show="showViewRecord">
    <div slot="reference">
      <el-tooltip
        placement="top"
        :open-delay="300"
        :content="
          objInfo.createUserid === userInfo.id ? '谁看过我' : '谁看过TA'
        "
      >
        <div class="kr-item">
          <Icon icon-class="icon-check-record" class="kr-right-icon" />
          <img
            v-if="localRecord && objInfo.createUserid !== userInfo.id"
            :id="`${objInfo.id}animateAvatar`"
            :src="userInfo.avatar"
            class="kr-right-avatar border-radius-20"
          />
          <img
            v-else-if="objInfo.objectivesLookRecordingDto"
            :src="objInfo.objectivesLookRecordingDto.userDto.avatar"
            class="kr-right-avatar border-radius-20"
          />
          {{ Number(lookCount || 0) > 99 ? '99+' : ((JSON.stringify(localRecord) !=='{}' && objInfo.createUserid !== userInfo.id && !hasDayRecord) ? Number(lookCount || 0) + 1 : lookCount ) || 0 }}
        </div>
      </el-tooltip>
    </div>
    <div v-if="viewRecordList.length > 0" class="view-record-list">
      <div class="view-record-num">近7天有 {{ lastDayLookNum }}人 查看过目标</div>
      <transition-group
        id="transitionGroup"
        ref="task"
        v-scroll-bottom="loadMore"
        tag="div"
        data-scroll-type="task"
        class="draggable-box view-record-lists"
      >
        <div v-for="record in viewRecordList" :key="record.lastLookTime" class="view-record-item">
          <div class="view-record-time">{{ record.lastLookTime }}</div>
          <div
            v-for="person in record.objectivesLookRecordingDtoList"
            :key="person.id"
            class="view-person"
          >
            <div v-if="person.userDto" class="person-info">
              <img
                class="border-radius-20"
                :src="person.userDto.avatar || require('@/assets/imgs/common/avatar.png')"
              />
              <div class="person-name">
                <wx-open-data :id="person.userDto.name" :nameData="{name:person.userDto.name}" />&nbsp;
              </div>
            </div>
            <div
              class="person-time"
            >{{ $utils.formatRecentTime(person.lastLookTime || '', 'detail') }}</div>
          </div>
        </div>
      </transition-group>
    </div>
    <div v-else class="no-data-show">
      <div class="global-content-null-wrap">
        <img src="@/assets/imgs/common/commentNull.png" class="img" />
      </div>
      <div class="no-data-show-text">暂无数据</div>
    </div>
  </el-popover>
</template>
<script>
import '@/utils/day.js'
export default {
  props: {
    objInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    icon: {
      type: String,
      default: 'icon-chakan'
    },
    localRecord: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      viewRecordData: {},
      recordUsers: [],
      lookCount: 0,
      pageNum: 1,
      firstCall: true,
      maxPage: 1,
      initRecordList: [],
      viewRecordList: [], // 查看记录
      lastDayLookNum: 0,
      hasDayRecord: false
    }
  },
  computed: {},
  created() {
    this.hasDayRecordState()
  },
  watch: {
    objInfo: function () {
      this.hasDayRecordState()
    },
    localRecord: {
      handler(val) {
        // console.log('123456', val)
      },
      deep: true,
      immediate: true
    },
    'localRecord.time': {
      handler(val) {
        if (!val) return
        this.$nextTick(() => {
          const domEl = document.getElementById(
            `${this.objInfo.id}animateAvatar`
          )
          domEl.className = 'kr-right-avatar border-radius-20 show-animate'
          setTimeout(() => {
            domEl.className = 'kr-right-avatar border-radius-20'
          }, 1000)

          const obj = this.objInfo.objectivesLookRecordingDto
          if (
            obj &&
            (obj.lastLookTime.split(' ')[0] !== this.$day.getDate(new Date()) ||
              obj.userId !== this.userInfo.id)
          ) {
            this.lookCount = Number(obj.lookCount) + 1
          }
          if (Number(this.lookCount) === 0) {
            this.lookCount = 1
          }
        })
      },
      immediate: true
    },
    'objInfo.objectivesLookRecordingDto.lookCount': {
      handler(val) {
        this.lookCount = val
      },
      immediate: true
    }
  },
  methods: {
    hasDayRecordState() {
      if ((this.objInfo.objectivesLookRecordingDto || '') !== '') {
        if (this.$day.getDate(new Date()) === this.objInfo.objectivesLookRecordingDto.lastLookTime.substring(0, 10) && this.objInfo.objectivesLookRecordingDto.userId === this.userInfo.id) {
          this.hasDayRecord = true
        }
      } else {
        this.hasDayRecord = false
      }
    },
    async showViewRecord(type) {
      if (this.pageNum > this.maxPage && !this.firstCall) {
        return
      }
      const params = {
        pageNum: this.pageNum,
        pageSize: 10,
        dayNum: 7,
        objId: this.objInfo.id
      }
      await this.$apis
        .OKRVIEWRECORD(params)
        .then((rest) => {
          this.initRecordList = (rest.data.rows || [])
          let localRecordObj = {}
          if (this.localRecord) {
            localRecordObj = {
              id: this.userInfo.id,
              lastLookTime: this.localRecord.time || new Date(),
              userDto: {
                avatar: this.userInfo.avatar,
                id: this.userInfo.id,
                name: this.userInfo.name
              }
            }
          }
          if (this.pageNum === 1) {
            this.lastDayLookNum = rest.data.lookNum
          }
          if (type !== 'more') {
            this.viewRecordList = []
          }
          if (this.firstCall) {
            this.maxPage = Math.ceil((Number(rest.data.total) || 0) / 10)
          }
          if (rest.data && rest.data.rows.length > 0) {
            this.recordUsers = []
            rest.data.rows.forEach((item) => {
              if (this.$day.getDate(new Date()) === item.lastLookTime) {
                item.lastLookTime = '今天'
              } else if (this.$day.getDate(new Date()) === item.lastLookTime) {
                item.lastLookTime = '昨天'
                if (this.localRecord) {
                  this.recordUserHandle(item.objectivesLookRecordingDtoList)
                }
              } else {
                const isNowYear =
                  item.lastLookTime.split('-')[0] === new Date().getFullYear()
                item.lastLookTime = this.$day.getDate(
                  item.lastLookTime,
                  isNowYear ? 'M月D日' : 'YYYY年M月D日'
                )
                if (this.localRecord) {
                  this.recordUserHandle(item.objectivesLookRecordingDtoList)
                }
              }
              const hasDayRecord = this.viewRecordList.some((rItem, index) => {
                if (rItem.lastLookTime === item.lastLookTime) {
                  this.viewRecordList[index].objectivesLookRecordingDtoList =
                    this.viewRecordList[
                      index
                    ].objectivesLookRecordingDtoList.concat(
                      item.objectivesLookRecordingDtoList
                    )
                  return true
                }
              })
              // this.hasDayRecord = hasDayRecord
              if (!hasDayRecord) {
                this.viewRecordList.push(item)
              }
            })
            if (this.localRecord) {
              const hasTodayRecord = this.viewRecordList.some((item) => {
                if (item.lastLookTime === '今天') {
                  let hasRecord = false
                  const userIndex = []
                  item.objectivesLookRecordingDtoList.forEach(
                    (record, userIndexNum) => {
                      this.recordUsers.push(record.userDto.id)
                      if (record.userDto.id === this.userInfo.id) {
                        record.lastLookTime =
                          this.localRecord.time || new Date()
                        hasRecord = true
                        userIndex.push(userIndexNum)
                      }
                    }
                  )
                  if (this.objInfo.createUserid !== this.userInfo.id) {
                    if (userIndex.length > 1) {
                      item.objectivesLookRecordingDtoList.splice(
                        userIndex[1],
                        1
                      )
                    }
                    if (!hasRecord && userIndex.length === 0) {
                      item.objectivesLookRecordingDtoList.unshift(
                        localRecordObj
                      )
                    }
                  }
                  return true
                }
              })
              if (this.objInfo.createUserid !== this.userInfo.id) {
                if (!hasTodayRecord) {
                  this.viewRecordList.unshift({
                    lastLookTime: '今天',
                    objectivesLookRecordingDtoList: [localRecordObj]
                  })
                }
                if (this.recordUsers.indexOf(this.userInfo.id) === -1) {
                  this.lastDayLookNum = Number(rest.data.lookNum) + 1
                }
              }
              this.recordUsers = Array.from(new Set(this.recordUsers))
            }
            this.viewRecordData = rest.data || {}
          } else if (
            this.localRecord &&
            this.viewRecordList.length === 0 &&
            this.objInfo.createUserid !== this.userInfo.id
          ) {
            this.lastDayLookNum = 1
            this.viewRecordData = {
              lookNum: 1,
              rows: []
            }
            this.viewRecordList = []
            this.viewRecordList.push({
              lastLookTime: '今天',
              objectivesLookRecordingDtoList: [localRecordObj]
            })
          }
          this.firstCall = false
        })
        .catch((err) => {
          console.log(err)
        })
    },
    loadMore() {
      this.pageNum += 1
      this.showViewRecord('more')
    },
    recordUserHandle(arr) {
      const userArr = JSON.parse(JSON.stringify(arr))
      userArr.forEach((user) => {
        this.recordUsers.push(user.userDto.id)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.kr-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  .kr-right-icon {
    width: 22px;
    height: 22px;
    font-size: 22px;
    margin-right: 8px !important;
  }
  .kr-right-avatar {
    position: absolute;
    top: -7px;
    left: 12px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    object-fit: cover;
  }
  .show-animate {
    animation: remind_rotate 1s;
  }
  @keyframes remind_rotate {
    0% {
      top: -10px;
    }
    20% {
      top: -7px;
    }
    40% {
      top: -9px;
    }
    60% {
      top: -7px;
    }
    80% {
      top: -8px;
    }
    100% {
      top: -7px;
    }
  }
}
</style>
<style lang="scss">
.view-target-pop {
  width: 330px;
  max-height: 425px;
  border-radius: 16px;
  padding: 24px 0;
  .view-record-list {
    .view-record-num {
      padding: 0 16px;
      font-size: 12px;
      color: #3a78f6;
    }
    .view-record-lists {
      padding: 0 16px;
      max-height: 330px;
      min-height: 200px;
      overflow-y: auto;
      .view-record-item {
        .view-record-time {
          font-size: 12px;
          color: #6e7491;
          margin: 16px 0 8px;
        }
        .view-person {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-left: 8px;
          padding: 6px 0;
          .person-info {
            display: flex;
            align-items: center;
            color: #444558;
            flex-shrink: 0;
            img {
              width: 28px;
              height: 28px;
              border-radius: 50%;
              overflow: hidden;
              margin-right: 8px;
            }
            .person-name {
              max-width: 110px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              &:before {
                content: '';
                display: block;
              }
            }
          }
          .person-time {
            flex-shrink: 0;
            color: #a0a3bd;
          }
        }
      }
    }
  }
  .no-data-show {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .no-data-show-icon {
      margin: 16px auto;
      width: 116px;
    }
    .no-data-show-text {
      text-align: center;
      color: #6e7491;
    }
  }
}
</style>
