<template>
  <div>
    <el-dropdown v-if="!disabled" trigger="click" @command="handleCommand" placement="bottom-start">
      <div
        v-if="type === 1"
        class="status-box"
        :style="`background: ${stateDictBgColor[status]}`"
        @click="slotStatusClick"
      >
        <div
          :class="`status-checked status-task-type-${
            item && item.businessType
          }`"
          :style="`border: 1px solid ${stateDictColor[status]}`"
        >
          <i v-if="status == 3" class="el-icon-check checked"></i>
          <i
            v-else-if="!disabled"
            class="el-icon-caret-bottom hover"
            :style="`color: ${stateDictColor[status]}`"
          ></i>
        </div>
        <div class="status-text" :style="`color: ${stateDictColor[status]}`">{{ stateDict[status] }}</div>
      </div>
      <div
        v-else-if="type === 3"
        class="status-box status-box-3"
        :style="`background: ${stateDictBgColor[status]}`"
        @click="slotStatusClick"
      >
        <div
          :class="`status-checked status-task-type-${
            item && item.businessType
          }`"
          :style="`border: 1px solid ${stateDictColor[status]}`"
        >
          <i v-if="status == 3" class="el-icon-check checked"></i>
          <i
            v-else-if="!disabled"
            class="el-icon-caret-bottom hover"
            :style="`color: ${stateDictColor[status]}`"
          ></i>
        </div>
        <!-- <div class="status-text" :style="`color: ${stateDictColor[status]}`">
        {{ stateDict[status] }}
        </div>-->
      </div>
      <div v-else class="task-status-wrap">
        <slot></slot>
      </div>
      <el-dropdown-menu slot="dropdown" v-if="item && item.businessType === 1" />
      <el-dropdown-menu slot="dropdown" v-else>
        <el-dropdown-item
          v-for="(val, key) in item && item.businessType === 1
            ? stateDictWait
            : stateDict"
          :key="key"
          :command="key"
          class="picker-item"
        >
          <div
            class="status-item"
            :style="`color: ${stateDictColor[key]};background: ${stateDictBgColor[key]}`"
          >{{ val }}</div>
          <i v-if="status === parseInt(key)" class="el-icon-check color-icon-check-working"></i>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <div v-else>
      <div
        v-if="type === 1"
        class="status-box disabled-status-box"
        :style="`background: ${stateDictBgColor[status]}`"
      >
        <div
          class="status-checked"
          :class="
            disabled
              ? `disabled-status-box status-task-type-${
                  item && item.businessType
                }`
              : `status-task-type-${item && item.businessType}`
          "
          :style="`border: 1px solid ${stateDictColor[status]}`"
        >
          <i v-if="status == 3" class="el-icon-check checked"></i>
          <i
            v-else-if="!disabled"
            class="el-icon-caret-bottom hover"
            :style="`color: ${stateDictColor[status]}`"
          ></i>
        </div>
        <div class="status-text" :style="`color: ${stateDictColor[status]}`">{{ stateDict[status] }}</div>
      </div>
      <div
        v-else-if="type === 3"
        class="status-box status-box-3 disabled-status-box"
        :class="disabled ? 'disabled-status-box' : ''"
        :style="`background: ${stateDictBgColor[status]}`"
      >
        <div
          :class="`status-checked status-task-type-${
            item && item.businessType
          }`"
          :style="`border: 1px solid ${stateDictColor[status]}`"
        >
          <i v-if="status == 3" class="el-icon-check checked"></i>
          <i
            v-else-if="!disabled"
            class="el-icon-caret-bottom hover"
            :style="`color: ${stateDictColor[status]}`"
          ></i>
        </div>
        <!-- <div class="status-text" :style="`color: ${stateDictColor[status]}`">
        {{ stateDict[status] }}
        </div>-->
      </div>
      <div v-else class="task-status-wrap disabled-status-box">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
import taskEnums from '@/utils/enums/task.js'
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    status: {
      type: String
    },
    type: {
      // 1 任务详情 2 任务列表 3 不显示文字
      type: Number,
      default: 1
    },
    item: {
      // 当前数据
      type: Object
    }
  },
  data() {
    return {
      stateDict: taskEnums.TASKSTATUS,
      stateDictWait: taskEnums.TASKSTATUSWAIT,
      stateDictColor: taskEnums.TASKSTATUSCOLOR,
      stateDictBgColor: taskEnums.TASKSTATUSBGCOLOR
    }
  },
  created() {},
  methods: {
    handleCommand(val) {
      this.$emit('changeStatus', val, this.item)
      console.log(`handleCommand${val}`)
    },
    slotStatusClick() {
      var selectStatus = 3
      if (this.item && this.item.businessType === 1 && !this.disabled) {
        // 待办
        if (this.status === 3) {
          // 已完成
          selectStatus = 1
        }
        this.handleCommand(selectStatus)
        // this.$store.commit('task/taskApproveRemindObj', {
        //   visible: true,
        //   taskDetail: {
        //   },
        //   businessData: '',
        //   businessActionType: 1
        // })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.color-icon-check-working {
  font-size: 20px;
}
.status-box {
  display: flex;
  align-items: center;
  // width: 92px;
  height: 32px;
  padding: 6px 8px;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  // margin-top: 8px;
  .status-checked {
    min-width: 16px;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    border-radius: 4px;
    .checked {
      color: #00ba88;
      font-weight: 600;
      font-size: 10px;
    }
    .inner-icon {
    }
    .arrow-icon {
      display: none;
    }
    .hover {
      visibility: hidden;
    }
    &:hover {
      .inner-icon {
        display: none;
      }
      .arrow-icon {
        display: block;
      }
      .hover {
        visibility: visible;
        // display: block;
      }
    }
  }
  .status-task-type-1.status-checked {
    border-radius: 50%;
  }
  .status-text {
    font-size: 14px;
    font-weight: 500;
  }
}
.status-box-3 {
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  .status-text {
    display: none;
  }
  .status-checked {
    margin-right: 0;
  }
}
.status-box-4 {
  width: 104px;
  height: 34px;
  border-radius: 33px;
  border: 1px solid #3a70ff;
}
.picker-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px !important;
  width: 150px;
  margin: 0px 4px !important;
  border-radius: 6px;
  &:hover {
    background: #f7f7fc;
  }
}
.status-item {
  display: flex;
  align-items: center;
  padding: 0px 12px;
  border-radius: 8px;
  height: 32px;
  line-height: 32px;
  min-width: 66px;
}
.disabled-status-box {
  cursor: not-allowed;
}
::v-deep {
  .el-dropdown-menu {
    padding: 8px 0;
    border-radius: 16px;
    width: 160px;
  }
  .el-popper {
    padding: 8px 0;
    border-radius: 16px;
    width: 160px;
  }
}
</style>
