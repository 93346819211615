export default {
  path: '/video',
  name: 'video',
  component: () => {
    return import(
      /* webpackChunkName: "video" */
      '../../views/kpi/components/video'
    )
  }
}
