var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    _vm._g(
      {
        staticClass: "peoject-add-dialog",
        attrs: {
          title: "同步至绩效",
          visible: _vm.dialogVisible,
          width: "680px",
          "close-on-click-modal": false,
          "append-to-body": true,
          "before-close": _vm.handleClose,
        },
        on: {
          "update:visible": function ($event) {
            _vm.dialogVisible = $event
          },
        },
      },
      _vm.$listeners
    ),
    [
      _c("div", { staticClass: "add-content-wrap" }, [
        _c("div", { staticClass: "add-content" }, [
          _c("div", { staticClass: "add-content-left" }, [
            _c(
              "div",
              { staticClass: "search-list-content" },
              [
                _c("div", { staticClass: "search-list-wrap" }, [
                  _c(
                    "div",
                    { staticClass: "select-wrap select-date-wrap" },
                    [
                      _vm._v(" 年份: "),
                      _c("el-date-picker", {
                        attrs: {
                          type: "year",
                          placeholder: "请选择年份",
                          format: "yyyy 年",
                          "value-format": "yyyy",
                          clearable: false,
                        },
                        on: { change: _vm.searchInput },
                        model: {
                          value: _vm.cycleYear,
                          callback: function ($$v) {
                            _vm.cycleYear = $$v
                          },
                          expression: "cycleYear",
                        },
                      }),
                      _c("div", {
                        staticClass: "user-arrow el-icon-arrow-down",
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "select-wrap" },
                    [
                      _vm._v(" 周期: "),
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择", "collapse-tags": "" },
                          on: { change: _vm.searchInput },
                          model: {
                            value: _vm.cycleType,
                            callback: function ($$v) {
                              _vm.cycleType = $$v
                            },
                            expression: "cycleType",
                          },
                        },
                        _vm._l(_vm.kpiCycleTypeList, function (item) {
                          return _c("el-option", {
                            key: item.cycleType,
                            attrs: { label: item.name, value: item.cycleType },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("el-input", {
                  staticClass: "user-el-input input-focus",
                  attrs: {
                    "prefix-icon": "el-icon-search",
                    placeholder: "搜索考核计划名称",
                  },
                  on: { input: _vm.searchInput },
                  model: {
                    value: _vm.examineName,
                    callback: function ($$v) {
                      _vm.examineName = $$v
                    },
                    expression: "examineName",
                  },
                }),
              ],
              1
            ),
            _vm.kpiList.length > 0 && !_vm.pageLoading
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "vueloading",
                        rawName: "v-vueloading",
                        value: _vm.pageLoading,
                        expression: "pageLoading",
                      },
                    ],
                    staticClass: "kpi-list-wrap-content",
                  },
                  _vm._l(_vm.kpiList, function (item, index) {
                    return _c(
                      "kpi-item",
                      {
                        key: item.examineId,
                        class: `select-${
                          item.selectTemplateDimensionId ? true : false
                        }`,
                        attrs: {
                          item: item.examineDto,
                          "kpi-detail": item,
                          "from-type": "myKpi",
                        },
                        on: {
                          toDetail: function ($event) {
                            return _vm.checkboxChange(item, index)
                          },
                        },
                      },
                      [
                        item.selectTemplateDimensionId
                          ? _c(
                              "template",
                              { slot: "kpiItemRight" },
                              [
                                _c("Icon", {
                                  staticClass: "icon-check",
                                  attrs: { "icon-class": "icon-duigou" },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        item.templateDto.templateDimensionDtoList &&
                        item.selectTemplateDimensionId
                          ? _c(
                              "div",
                              {
                                staticClass: "dimension-list-wrap",
                                attrs: { slot: "kpiProcess" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                  },
                                },
                                slot: "kpiProcess",
                              },
                              [
                                _c("div", { staticClass: "dimension-list" }, [
                                  _vm._v("选择维度："),
                                ]),
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: item.selectTemplateDimensionId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          item,
                                          "selectTemplateDimensionId",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "item.selectTemplateDimensionId",
                                    },
                                  },
                                  _vm._l(
                                    item.templateDto.templateDimensionDtoList,
                                    function (dimensionItem) {
                                      return _c(
                                        "el-tooltip",
                                        {
                                          key: dimensionItem.templateDimensionId,
                                          attrs: {
                                            content: `已设为「不允许导入」或okr维度,不可同步`,
                                            disabled:
                                              dimensionItem.importIndexPower ===
                                                1 && dimensionItem.type !== 2
                                                ? true
                                                : false,
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            {
                                              attrs: {
                                                label:
                                                  dimensionItem.templateDimensionId,
                                                value: dimensionItem.name,
                                                disabled:
                                                  dimensionItem.importIndexPower ===
                                                    1 &&
                                                  dimensionItem.type !== 2
                                                    ? false
                                                    : true,
                                              },
                                            },
                                            [_vm._v(_vm._s(dimensionItem.name))]
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      2
                    )
                  }),
                  1
                )
              : _vm._e(),
            _vm.kpiList.length === 0 && !_vm.pageLoading
              ? _c(
                  "div",
                  { staticClass: "kpi-list-wrap-content" },
                  [_c("no-data")],
                  1
                )
              : _vm._e(),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "bottom-step-buttons" },
          [
            _c(
              "button",
              { staticClass: "left-button", on: { click: _vm.handleClose } },
              [_vm._v("取消")]
            ),
            _c(
              "el-button",
              {
                directives: [
                  { name: "preventReClick", rawName: "v-preventReClick" },
                ],
                on: {
                  click: function ($event) {
                    return _vm.saveBtn()
                  },
                },
              },
              [_vm._v("同步")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }