<template>
  <div class="kpi-verhicle-steps">
    <div
      v-for="(item, index) in steps"
      :key="item.id"
      :class="Number(activeStep) < item.id ? '' : 'active-step'"
      class="step-item"
    >
      <div
        class="step-line"
        :class="Number(activeStep) === item.id ? 'active-step-line' : ''"
      >
        <div
          class="step-split-line"
          :class="index === 0 ? 'no-show-line' : ''"
        ></div>
        <div class="step-circle" @click="clickStep(item)"></div>
        <div
          class="step-split-line"
          :class="index === steps.length - 1 ? 'no-show-line' : ''"
        ></div>
      </div>
      <div class="step-item-title">
        {{ item.name }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  model: {
    prop: 'activeStep',
    event: 'change'
  },
  props: {
    steps: {
      type: Array
    },
    activeStep: {
      type: Number
    }
  },
  data() {
    return {
      clickMenu: this.activeStep
    }
  },
  watch: {
    activeStep: {
      handler(val) {
        this.clickMenu = val
      }
    }
  },
  methods: {
    clickStep(obj) {
      if (this.activeStep > obj.id) {
        this.$emit('change', obj.id)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.kpi-verhicle-steps {
  width: 450px;
  margin: 0 auto;
  height: 50px;
  display: flex;
  justify-content: center;
  .step-item {
    flex: 1;
    color: #d9dbe9;
    .step-item-title {
      line-height: 18px;
      text-align: center;
    }
    .step-line {
      width: 100%;
      display: flex;
      align-items: center;
      height: 22px;
      margin-bottom: 12px;
      .step-circle {
        flex-shrink: 0;
        cursor: not-allowed;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #f7f7fc;
        box-shadow: 0px 2px 4px 0px rgba(0, 53, 183, 0.1);
      }
      .step-split-line {
        flex: 1;
        height: 3px;
        background: #f7f7fc;
      }
      .no-show-line {
        flex: 1;
        height: 0;
      }
    }
  }
  .active-step {
    color: #005eff;
    .step-line {
      .step-circle {
        cursor: pointer;
        width: 16px;
        height: 16px;
        background: #005eff;
        border: 3px solid #ffffff;
      }
      .step-split-line {
        background: #005eff;
      }
    }
    .active-step-line {
      .step-split-line:last-of-type {
        background: #f7f7fc;
      }
    }
  }
}
</style>
