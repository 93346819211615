<template>
  <div class="apply-demo-wrap" @click="applyDemo">
    <Img :src="'/header/applyImg.gif'" />
    <applyDialog v-if="applyDialogVisible" v-model="applyDialogVisible" />
  </div>
</template>
<script>
import applyDialog from '@/views/global/layout/header/components/applyDemo/applyDialog'
export default {
  components: {
    applyDialog
  },
  props: {
  },
  data() {
    return {
      applyDialogVisible: false
    }
  },
  created() {
  },
  computed: {},
  methods: {
    applyDemo() {
      this.applyDialogVisible = true
    }
  }
}
</script>
<style lang="less" scoped>
.apply-demo-wrap {
  position: fixed;
  right: 0px;
  bottom: 0px;
  cursor: pointer;
  img {
    width: 110px;
  }
}
</style>
