// 周期模块方法库
import apis from '../apis_moudles/index'
import req from '../request'
// 查询周期列表
const CYCLIST = (params) => {
  return req.get(apis.cyclist, { params })
}

// 查询周期内容
const CYCLEINFOBYID = (appendUrl, params) => {
  return req.get(`${apis.cyclistInfoByid}${appendUrl}`, { params })
}

// 设置默认周期
const CYCLESETDEFAULT = (params) => {
  return req.post(apis.cycleSetDefault, params)
}
// 根据部门或成员名称获取周期成员
const CYCLEMEMBERLISTBYDEPART = (appendUrl, params) => {
  return req.get(`${apis.cycleMemberListByDepart}${appendUrl}`, { params })
}

// 获取周期内我关注的成员
const CYCLEATTENTIONMEMBERLIST = (appendUrl, params) => {
  return req.get(`${apis.cycleAttentionMemberList}${appendUrl}`, { params })
}

// 添加周期内关注成员
const CYCLEADDATTENTIONMEMBER = (params) => {
  return req.post(apis.cycleAddAttentionMember, params)
}

// 取消关注
const CYCLEDELATTENTIONMEMBER = (params) => {
  return req.post(apis.cycleDelAttentionMember, params)
}

// 删除周期内部门或成员
const CYCLEDELMEMBERDEPARTORID = (appendUrl, params) => {
  return req.get(`${apis.cycleDelMemberDepartOrId}${appendUrl}`, { params })
}

// 获取周期内成员列表
const CYCLEMEMBERLIST = (params) => {
  return req.get(apis.cycleMemberList, { params })
}

// 部门加入周期
const CYCLEINVITEDEPART = (params) => {
  return req.post(apis.cycleInviteDepart, params)
}
// 成员加入周期
const CYCLEINVITEUSER = (params) => {
  return req.post(apis.cycleInviteUser, params)
}

// 删除邀请成员
const CYCLEDELETEUSER = (appendUrl, params) => {
  return req.get(`${apis.cycleDeleteUser}${appendUrl}`, { params })
}

// 获取周期内可@的成员
const CYCLEATMEMBER = (appendUrl, params) => {
  return req.get(`${apis.cycleAtMember}${appendUrl}`, { params })
}
// 周期内查询部门人员列表
const CYCLEDEPARTUSERLIST = (appendUr, params) => {
  return req.get(`${apis.cycleDepartUserList}${appendUr}`, { params })
}
export default [
  { CYCLIST },
  { CYCLEINFOBYID },
  { CYCLESETDEFAULT },
  { CYCLEMEMBERLISTBYDEPART },
  { CYCLEATTENTIONMEMBERLIST },
  { CYCLEADDATTENTIONMEMBER },
  { CYCLEDELATTENTIONMEMBER },
  { CYCLEDELMEMBERDEPARTORID },
  { CYCLEMEMBERLIST },
  { CYCLEINVITEDEPART },
  { CYCLEINVITEUSER },
  { CYCLEDELETEUSER },
  { CYCLEATMEMBER },
  { CYCLEDEPARTUSERLIST }
]
