<template>
  <div class="kr_select">
    <el-dropdown trigger="click">
      <div class="el-dropdown-link">
        <div class="confidence_icon">KR</div>
        <div class="kr-type">
          <span v-show="krSelectedValue">{{ krSelectedValue }}</span>
          <!-- v-show="!krSelectedValue" -->
          <Icon icon-class="icon-arrow-down" class="icon-arrow-down" />
        </div>
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="(item, index) in krOption"
          :key="item.id"
          class="confidence_content"
          @click.native="selectKrvalue(item.id, index)"
        >
          <span>KR {{ index + 1 }}</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
export default {
  model: {
    prop: 'krSelectedValueId',
    event: 'change'
  },
  props: {
    krSelectedValueId: {
      type: String,
      default: ''
    },
    krOption: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      krSelectedValue: '', // 选中的kr序号
      krSelectedId: this.krSelectedValueId // kr id
    }
  },
  watch: {
    krSelectedValueId: {
      handler(val) {
        this.krSelectedId = val
        this.krOption.forEach((item, index) => {
          if (item.id === val) {
            this.krSelectedValue = index + 1
          }
        })
        if (!val) {
          this.krSelectedValue = ''
        }
      }
    },
    krOption: {
      handler(val) {
        this.krOption.forEach((item, index) => {
          if (item.id === this.krSelectedValueId) {
            this.krSelectedValue = index + 1
          }
        })
      }
    }
  },
  methods: {
    selectKrvalue(id, index) {
      this.krSelectedValue = index + 1
      this.krSelectedId = id
      this.$emit('change', id)
    }
  }
}
</script>
<style lang="less" scoped>
.kr_select {
  cursor: pointer;
  .el-dropdown {
    font-size: 16px;
    font-weight: 500;
    text-align: LEFT;
    color: #a0a3bd;
    .el-dropdown-link {
      display: flex;
      line-height: 28px;
      &:hover {
        color: #a0a3bd;
      }
      .confidence_icon {
        margin-right: 2px;
        position: relative;
        font-weight: normal;
      }
      .icon-arrow-down {
        height: 28px;
      }
      .kr-type {
        .flex-l();
      }
    }
  }
}
.el-dropdown-menu__item {
  display: flex;
  font-size: 14px;
  font-weight: 400;
  text-align: LEFT;
  color: #a0a3bd;
  line-height: 36px;
  padding: 4px 15px;
  &:hover {
    background: #f5f6fa;
    color: #444558;
  }
  .confidence_icon {
    margin-right: 8px;
  }
}
</style>
>
