<template>
  <div :class="avatarClass" @click="clickAvatar">
    <div class="avatar-img" v-if="wxType === 1">
      <avatar-locked :avatar="avatar" :size="size[0]" :locked="locked" />
    </div>
    <div class="avatar-img depart-name" v-else>
      <wx-open-data :id="name" :type="wxType" :nameData="{name:name}" />
    </div>
    <div class="name-wrap">
      <div v-if="name" class="avatar-name" :style="`font-size: ${size[1]}px;`">
        <wx-open-data :id="name" :nameData="{name:name}" :type="wxType" />
      </div>
      <slot name="text"></slot>
      <div
        v-if="(userState || '') !== '' && userState !== 1"
        class="user-state"
      >{{userStateList[userState]}}</div>
      <slot name="name-wrap"></slot>
    </div>

    <div
      v-if="canDelete"
      class="close-icon"
      @mouseenter="hoverStateChange(true)"
      @mouseleave="hoverStateChange(false)"
      @click.stop="deleteItem"
    >
      <Icon icon-class="icon-guanbianniu" />
    </div>
    <slot></slot>
  </div>
</template>
<script>
import avatarLocked from '../AvatarLocked'
export default {
  components: { avatarLocked },
  props: {
    avatar: {
      type: String
    },
    name: {
      type: String
    },
    flexDirection: {
      // 头像名字方向 column 垂直 row 水平
      type: String,
      default: 'row'
    },
    canDelete: {
      // 是否带删除按钮
      type: Boolean,
      default: false
    },
    size: {
      // [头像大小， 名字字体大小]
      type: Array,
      default: () => {
        return [30, 14]
      }
    },
    // 头像是否上锁
    locked: {
      type: Boolean,
      default: false
    },
    wxType: {
      default: 1
    },
    userState: { // 企业人员状态 0 待激活  1正常  2停用  3不在可见范围 4离职
      default: 1
    }
  },
  data() {
    return {
      hoverDelete: false // 鼠标停在关闭按钮上
    }
  },
  computed: {
    avatarClass() {
      let className = 'avatar-box'
      if (this.flexDirection === 'column') {
        className += ' columns-avatar-box'
      }
      if (this.canDelete) {
        className += ' delete-border-box'
      }
      if (this.hoverDelete) {
        className += ' hover-avatar-box'
      }
      return className
    }
  },
  methods: {
    hoverStateChange(type) {
      this.hoverDelete = type
    },
    clickAvatar() {
      this.$emit('clickAvatar')
    },
    deleteItem() {
      this.$emit('deleteItem')
    }
  }
}
</script>
<style lang="less">
.avatar-box {
  display: flex;
  align-items: center;
  .name-wrap {
    .flex-l();
    .user-state {
      font-size: 10px;
      color: #ff572b;
      border-radius: 4px;
      background: #fff3f8;
      padding: 2px 4px;
      margin-left: 8px;
    }
  }
  .depart-name {
    background: #3068ff;
    letter-spacing: 30px;
    padding-left: 7px;
    overflow: hidden;
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 100%;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    align-items: flex-start;
  }
  .avatar-img {
    display: flex;
    align-items: center;
    margin-right: 8px;
  }
  .avatar-name {
    max-width: 80px;
    overflow: hidden;
    line-height: 1.2;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:before {
      content: '';
      display: block;
    }
  }
}
.columns-avatar-box {
  flex-direction: column;
  justify-content: center;
  .avatar-img {
    border-radius: 50%;
    margin-right: 0;
    margin-bottom: 4px;
  }
  .avatar-name {
  }
}
.delete-border-box {
  padding: 6px 16px 6px 8px;
  border-radius: 40px;
  position: relative;
  box-sizing: border-box;
  border: 1px solid #fff;
  .close-icon {
    position: absolute;
    cursor: pointer;
    top: -3px;
    right: -3px;
    display: none;
    font-size: 18px;
    color: #a0a3bd;
    background: #fff;
    border-radius: 50%;
    width: 18px;
  }
  &:hover {
    .close-icon {
      display: block;
      &:hover {
        color: #ff9900;
      }
    }
    background: #f5f6fa;
    // border: 1px solid #f5f6fa;
  }
}
.hover-avatar-box {
  border: 1px solid #ff9900 !important;
  &:hover {
    background: #fff;
  }
}
</style>
