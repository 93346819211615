var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "680px",
        "append-to-body": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "dialog-title",
          attrs: { slot: "title" },
          slot: "title",
        },
        [_vm._v("元旦快乐")]
      ),
      _c("div", { staticClass: "remind-wrap" }, [
        _c("img", {
          attrs: {
            src: require("../../../../../../assets/imgs/header/festivalImg.png"),
            alt: "",
          },
        }),
        _c(
          "button",
          {
            directives: [
              { name: "preventReClick", rawName: "v-preventReClick" },
            ],
            staticClass: "edit-btn",
            attrs: { type: "button" },
            on: { click: _vm.closeDialog },
          },
          [_vm._v("知道了")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }