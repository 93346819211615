const versionNumber = 'v1' // 版本
// 基础模块api
export default [
  {
  // 获取用户信息
  getUserInfo: `/api/admin/${versionNumber}/user/info`
},
{
  // 获取验证码
  sendSms: `/api/admin/verifiCode/${versionNumber}/sendSms`
},
{
  // 验证码登录
  codeLogin: '/api/auth/jwt/tokenCode'
},
{
  // 密码登录
  pwdLogin: '/api/auth/jwt/token'
},
{
  // 注册
  register: '/api/auth/jwt/tokenRegisterCode'
},
{
  // 重置密码
  resetPwdCode: '/api/auth/jwt/validatePwCode'
},
{
  // 重置密码提交
  resetPwdSubmit: '/api/auth/jwt/validatePwCodeUpdate'
},
{
  // 版本升级请求
  releaseConsult: `/api/admin/${versionNumber}/consult/`
},
{
  // 获取版本人员数量
  queryMemberLimit: `/api/admin/${versionNumber}/company/user/limit`
},
{
  // 获取登录方式
  loginType: `/api/admin/${versionNumber}/user/verification`
},
{
  // 查询最后操作的可用企业信息及待审核的企业信息
  screenlist: `/api/admin/${versionNumber}/company/screenlist`
},
{
  // 查询指定企业信息
  companyInfo: `/api/admin/${versionNumber}/company/`
},
{
  // 查看引导流程
  userRoleProgress: `/api/admin/${versionNumber}/guidance/queryCurrentProcess`
},
// 创建企业
{
  addCompany: `/api/admin/${versionNumber}/company/add`
},
// 获取邀请信息 （有token）
{
  invitationInfoToken: `/api/admin/${versionNumber}/user/getInvitationRegisterInfo/`
},
// 获取邀请信息 （无token）
{
  invitationInfoNoToken: `/api/admin/webAccess/${versionNumber}/getInvitationRegisterInfo/`
},
// 邀请加入团地
{
  applyAddCompany: `/api/admin/${versionNumber}/user/addInvitationRegisterUser`
},
// 邀请登录
{
  invitationLogin: `/api/auth/jwt/tokenInvitationApply`
},
// 新建周期
{
  addCycle: `/api/ranloWeb/${versionNumber}/setcycle/addCycle/first`
},
// 企业微信code
{
  loginWxCode: `/api/admin/${versionNumber}/user/loginAuthorize`
},
// 获取js应用签名
{
  getTicketAgent: `/api/admin/getJsapiTicketAgent_v1`
},
// 获取微信通讯录
{
  getWxUserList: `/api/admin/${versionNumber}/user/companyUsers`
},
// 扫码code
{
  loginWxScanCode: `/api/admin/${versionNumber}/user/loginScan`
},
// 新注册
{
  newRegister: `/api/auth/jwt/tokenRegisterCodeAndName`
},
// 获取图片验证码
{
  sendImgSms: `/api/auth/jwt/captcha`
}
]
