<template>
  <div
    v-if="ossFileEntityList.length > 0"
    :class="'file-list-' + formPage"
    class="file-list-wrap"
  >
    <div
      class="file-list"
      v-for="(item, index) in ossFileEntityList"
      :key="item.documentId"
    >
      <div class="file-list-left" @click="fileListClick(item)">
        <el-image
          fit="contain"
          class="file-img"
          :src="item.downloadUrl"
          alt=""
          v-if="
            item.mimeType == 'png' ||
            item.mimeType == 'jpg' ||
            item.mimeType == 'jpeg' ||
            item.mimeType == 'gif' ||
            item.mimeType == 'svg'
          "
        />
        <Icon
          v-else-if="
            item.mimeType == 'docx' ||
            item.mimeType == 'doc' ||
            item.mimeType == 'DOCX' ||
            item.mimeType == 'DOC'
          "
          class="file-svg"
          icon-class="icon-DOCX"
        />
        <Icon
          v-else-if="item.mimeType == 'mp3' || item.mimeType == 'MP3'"
          class="file-svg"
          icon-class="icon-MP3"
        />
        <Icon
          v-else-if="item.mimeType == 'mp4' || item.mimeType == 'MP4'"
          class="file-svg"
          icon-class="icon-MP4"
        />
        <Icon
          v-else-if="item.mimeType == 'pdf' || item.mimeType == 'PDF'"
          class="file-svg"
          icon-class="icon-PDF"
        />
        <Icon
          v-else-if="item.mimeType == 'ppt' || item.mimeType == 'PPT'"
          class="file-svg"
          icon-class="icon-PPT"
        />
        <Icon
          v-else-if="item.mimeType == 'txt' || item.mimeType == 'TXT'"
          class="file-svg"
          icon-class="icon-filetxt"
        />
        <Icon
          v-else-if="
            item.mimeType == 'XLS' ||
            item.mimeType == 'xls' ||
            item.mimeType == 'XLSX' ||
            item.mimeType == 'xlsX'
          "
          class="file-svg"
          icon-class="icon-XLS"
        />
        <Icon v-else class="file-svg" icon-class="icon-fileCommon" />
        <div class="name">{{ item.name }}</div>
      </div>
      <div class="file-list-right">
        {{ item.fileSizeNew }}KB
        <Icon
          v-if="!disabled"
          icon-class="icon-close"
          class="close"
          @click="filedelete(index, item.name)"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    ossFileEntityList: {
      required: false,
      type: Array,
      default: () => {
        return []
      }
    },
    formPage: {
      required: false,
      type: String,
      default: ''
    },
    // 是否需要上传后预览
    preview: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogImageUrl: '',
      dialogImageType: '',
      dialogVisible: false,
      dialogPreviewVisible: false,
      dialogImageName: ''
    }
  },
  created() {
    // if(this.ossFileEntityList.length>0){
    //   this.ossFileEntityList.forEach(item => {
    //     item.fileSizeNew = (item.fileSize/1024).toFixed(2)
    //   })
    // }
  },
  watch: {
    ossFileEntityList: {
      handler() {
        if (this.ossFileEntityList.length > 0) {
          this.ossFileEntityList.forEach((item) => {
            item.fileSizeNew = (item.fileSize / 1024).toFixed(2)
          })
        }
      },
      immediate: true
    }
  },
  methods: {
    fileListClick(item) {
      this.bus(this).$emit('global:TODO:filePreview', item)
    },
    async filedelete(index, name) {
      var that = this
      const status = await that.$element.confirm(`确定移除 ${name}？`)
      if (status) {
        this.ossFileEntityList.splice(index, 1)
        this.$emit('delete', this.ossFileEntityList)
      }
    },
    getMessageNotificationClose() {
      this.dialogVisible = false
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../assets/styles/common.less';
::v-deep {
  .el-dialog__title {
    display: inline-block;
    width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
  }
}
.file-list-wrap {
  width: 100%;
  max-height: 230px;
  overflow-y: auto;
  .file-list {
    background: #fff;
    cursor: pointer;
    .flex-bt();
    height: 48px;
    line-height: 48px;
    border: 1px solid #f5f6fa;
    border-radius: 9px;
    margin-bottom: 10px;
    font-size: 14px;
    color: #6e7491;
    font-weight: 500;
    padding: 0px 10px;
    &:hover {
      .file-list-right {
        .close {
          display: flex;
        }
      }
    }
    .file-list-left {
      .flex-l();
      .file-img {
        width: 38px;
        height: 38px;
        border-radius: 4px;
      }
      .file-svg {
        width: 30px;
        height: 30px;
        font-size: 30px;
      }
      .name {
        max-width: 220px;
        padding-right: 10px;
        .text-overOne();
        margin-left: 10px;
      }
    }
    .file-list-right {
      font-size: 12px;
      color: #a0a3bd;
      min-width: 70px;
      text-align: right;
      .flex-r();
      .close {
        display: none;
        font-size: 16px;
        width: 16px;
        height: 16px;
        margin-left: 10px;
        &:hover {
          color: #3a78f6;
        }
      }
    }
  }
}
.file-list-okrList {
  .flex-l();
  margin-right: 20px;
  flex-wrap: wrap;
  .file-list {
    width: 365px !important;
    margin-left: 20px;
  }
}
.preview-null {
  margin: 5% auto;
  .file-svg {
    font-size: 88px;
    width: 88px;
    height: 88px;
  }
  p {
    margin-top: 10px;
    font-weight: 500;
    text-align: CENTER;
    color: #6e7491;
    line-height: 24px;
    font-size: 14px;
  }
}
::v-deep .preview {
  background-color: red;
}

::v-deep .el-dialog {
  border-radius: 16px;
  background: #f5f6fa;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #14142b;
  line-height: 64px;
  font-weight: 500;
  max-width: calc(100% - 160px);
  .text-overOne();
  display: block;
}
::v-deep .el-dialog__headerbtn {
  top: 17px;
}
::v-deep .el-dialog__close {
  width: 28px;
  height: 28px;
  background: url(../../../assets/icons/common/dialogClose.png);
  background-size: 28px auto;
  cursor: pointer !important;
  border-radius: 4px;
  &:before {
    content: '';
  }
  &:hover {
    width: 28px !important;
    height: 28px !important;
    background: #f5f6fa url(../../../assets/icons/common/dialogClose.png) !important;
    background-size: 28px auto !important;
  }
}
.downloadImg {
  .flex-l();
  position: absolute;
  height: 64px;
  line-height: 64px;
  top: 0px;
  right: 74px;
  cursor: pointer;
  width: 70px;
  &:before {
    content: '';
  }
  .icon-download {
    transform: rotate(90deg);
    font-size: 24px;
    width: 30px;
    height: 30px;
    padding: 4px;
    border-radius: 4px;
  }
  &:hover {
    .icon-download {
      background: #f5f6fa;
    }
  }
}
.img-wrap-box {
  margin: 0px auto;
  text-align: center;
  vertical-align: middle;
  .dialog-img {
    width: auto;
    max-width: 100%;
    max-height: calc(80vh - 150px);
    overflow-y: auto;
  }
}
::v-deep .marge-img-box .el-dialog {
  width: 80%;
  margin: 52px 10% !important;
}
</style>
<style>
.file-list-wrap .el-dialog__wrapper {
  z-index: 9999 !important;
}
</style>
