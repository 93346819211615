var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      on: {
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c(
        "el-popover",
        {
          ref: "userPickerCheckedPop",
          attrs: {
            "popper-class": "user-picker-atdialog-pop",
            "visible-arrow": false,
            placement: "bottom-start",
            disabled: _vm.disabled,
          },
          on: {
            show: function ($event) {
              return _vm.init("show")
            },
            hide: _vm.hide,
          },
        },
        [
          _c("div", { staticClass: "user-picker-out" }, [
            _vm.pageFrom !== "kpiNotAdjustUser" && _vm.pageFrom !== "kpiV2"
              ? _c(
                  "div",
                  { staticClass: "search-box flex-new" },
                  [
                    _c("el-input", {
                      staticClass: "user-el-input",
                      attrs: {
                        "prefix-icon": "el-icon-search",
                        placeholder: "搜索成员",
                      },
                      on: { input: _vm.input },
                      model: {
                        value: _vm.name,
                        callback: function ($$v) {
                          _vm.name = $$v
                        },
                        expression: "name",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.checked
              ? _c(
                  "div",
                  {
                    staticClass: "select-people-num",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.selectPeopleClick()
                      },
                    },
                  },
                  [
                    !_vm.selectPeopleState
                      ? _c("div", { staticClass: "left" }, [
                          _vm._v(
                            "已选" + _vm._s(_vm.currentPeopleList.length) + "位"
                          ),
                        ])
                      : _c(
                          "div",
                          { staticClass: "select-people-wrap" },
                          [
                            _c("Icon", {
                              staticClass: "arrow-icon",
                              attrs: { "icon-class": "icon-left" },
                            }),
                            _vm._v("返回 "),
                          ],
                          1
                        ),
                    !_vm.selectPeopleState
                      ? _c(
                          "div",
                          { staticClass: "select-people-wrap" },
                          [
                            _c("Icon", {
                              staticClass: "arrow-icon",
                              attrs: { "icon-class": "icon-right" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
            _c("div", { staticClass: "people-title" }, [_vm._v("成员")]),
            _c("div", { staticClass: "list-box" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "scroll-bottom",
                      rawName: "v-scroll-bottom",
                      value: _vm.$loadMoreList,
                      expression: "$loadMoreList",
                    },
                  ],
                  staticClass: "list-box-wrap",
                },
                [
                  !_vm.selectPeopleState
                    ? _vm._l(_vm.list, function (item, index) {
                        return _c(
                          "div",
                          {
                            staticClass: "list-item",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.pickClick(item, index)
                              },
                            },
                          },
                          [
                            _c("el-image", {
                              staticClass:
                                "avatar-img person-img border-radius-20",
                              attrs: {
                                src:
                                  item.avatar ||
                                  require("@/assets/imgs/common/avatar.png"),
                                fit: "cover",
                              },
                            }),
                            _c("div", { staticClass: "name-wrap" }, [
                              _c(
                                "div",
                                { staticClass: "p-l-10" },
                                [
                                  _c("wx-open-data", {
                                    attrs: {
                                      id: item.username,
                                      "name-data": {
                                        name: item.name
                                          ? item.name
                                          : item.userName,
                                      },
                                    },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.userState == 4 ? "(离职)" : ""
                                      ) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                              item.departName || item.jobTitle
                                ? _c("div", { staticClass: "departName" }, [
                                    _c(
                                      "span",
                                      [
                                        _c("wx-open-data", {
                                          attrs: {
                                            id: item.departName,
                                            type: 2,
                                            "name-data": {
                                              name: item.departName,
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" " + _vm._s(item.jobTitle) + " "),
                                  ])
                                : _vm._e(),
                            ]),
                            item.checked
                              ? _c("div", { staticClass: "imgSelect" }, [
                                  _c("i", {
                                    staticClass: "el-icon-check task-svg-icon",
                                  }),
                                ])
                              : _vm._e(),
                          ],
                          1
                        )
                      })
                    : _vm._l(_vm.selectList, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: item.id || item.userId,
                            staticClass: "list-item",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.pickClick(item, index)
                              },
                            },
                          },
                          [
                            _c("el-image", {
                              staticClass:
                                "avatar-img person-img border-radius-20",
                              attrs: {
                                src:
                                  item.avatar ||
                                  require("@/assets/imgs/common/avatar.png"),
                                fit: "cover",
                              },
                            }),
                            _c("div", { staticClass: "name-wrap" }, [
                              _c(
                                "div",
                                { staticClass: "p-l-10" },
                                [
                                  _c("wx-open-data", {
                                    attrs: {
                                      id: item.username || "" || item.name,
                                      "name-data": {
                                        name: item.userName || "" || item.name,
                                      },
                                    },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.userState == 4 ? "(离职)" : ""
                                      ) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                              item.departName || item.jobTitle
                                ? _c("div", { staticClass: "departName" }, [
                                    _c(
                                      "span",
                                      [
                                        _c("wx-open-data", {
                                          attrs: {
                                            id: item.departName,
                                            type: 2,
                                            "name-data": {
                                              name: item.departName,
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" " + _vm._s(item.jobTitle) + " "),
                                  ])
                                : _vm._e(),
                            ]),
                            item.checked
                              ? _c("div", { staticClass: "imgSelect" }, [
                                  _c("i", {
                                    staticClass: "el-icon-check task-svg-icon",
                                  }),
                                ])
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                ],
                2
              ),
              _vm.pageFrom !== "kpiNotAdjustUser" && _vm.pageFrom !== "kpiV2"
                ? _c(
                    "div",
                    {
                      staticClass: "list-item peopeAdd",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.departAdd()
                        },
                      },
                    },
                    [
                      _c("Icon", {
                        staticClass: "avatar-img",
                        attrs: { "icon-class": "icon-a-tianjia2" },
                      }),
                      _c("div", { staticClass: "p-l-10" }, [
                        _vm._v("按组织选择"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _c(
              "div",
              {
                staticClass: "confirm-btn",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.confirmClick()
                  },
                },
              },
              [_vm._v("确定")]
            ),
          ]),
          _vm._t("default", null, { slot: "reference" }),
        ],
        2
      ),
      _vm.closeInviterCodeBoolean === true
        ? _c("invite-by-code", {
            on: { closeInviterCode: _vm.closeInviterCode },
          })
        : _vm._e(),
      _vm.departUserVisible
        ? _c("depart-picker", {
            attrs: {
              "can-delete": true,
              type: "departPerson",
              "selected-person": _vm.currentPeopleList,
              "is-include-me": _vm.pageType == "taskSummary" ? 1 : 0,
              "page-from": _vm.pageFrom,
              "created-user": _vm.createdUser,
              limit: _vm.length,
              userScope: _vm.userScope,
            },
            on: { saveResult: _vm.chargeUserPicker },
            model: {
              value: _vm.departUserVisible,
              callback: function ($$v) {
                _vm.departUserVisible = $$v
              },
              expression: "departUserVisible",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }