<template>
  <el-dialog
    :title="`审批意见`"
    :visible.sync="dialogVisible"
    width="640px"
    :append-to-body="true"
    @close="handleClose"
  >
    <div class="main-content">
      <div class="detail-content-wrap">
        <div class="title">驳回原因</div>
        <el-input
          v-model.lazy="approveRxplain"
          type="textarea"
          :placeholder="'请描述驳回原因'"
          :maxlength="500"
          :rows="3"
        />
      </div>
      <div class="bottom-step-buttons">
        <button class="left-button" @click="handleClose">取消</button>
        <L-button :loading="saveLoading" @clickEvt="approvePassSubmit(2)">确定驳回</L-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  props: {
    dialogShow: {
      type: Boolean,
      default: true
    },
    taskDetail: {

    },
    progressList: {}
  },
  data() {
    return {
      saveLoading: false,
      dialogVisible: this.dialogShow,
      approveRxplain: ''
    }
  },
  mounted() {
  },
  created() {},
  methods: {
    // 关闭内容
    handleClose() {
      this.dialogShow = false
      this.$emit('change', false)
    },
    // 审批通过驳回
    approvePassSubmit(operationStatus, deleteState) {
      if (operationStatus === 2 && this.approveRxplain.replace(/(\s*$)/g, "") === '') {
        this.$message.warn('驳回说明不能为空哦！')
        return
      }
      this.$apis
        .APPROVALPROCESSOPERATION({
          spNo: this.progressList.processDynamic.spNo,
          businessType: 3,
          businessId: this.taskDetail.projectId,
          operationStatus,
          operationContent: operationStatus === 2 ? '驳回了审批' : `通过了审批 ，${this.progressList.processDynamic.actionContent}`,
          content: this.approveRxplain,
          businessExtId: this.taskDetail.id
        })
        .then((rest) => {
          if (rest.status === 200) {
            this.$message.success('修改成功')
            this.$emit('approvalRefresh', deleteState)
            this.handleClose()
          } else {
            this.$message.error(rest.message)
          }
        })
    }
  }
}
</script>
<style scoped lang="less">
@import '../../../../assets/styles/dialog.less';
.main-content {
  .detail-content-wrap {
    .title {
      font-size: 16px;
      color: #444558;
      margin: 32px 0px 12px 0px;
    }
    ::v-deep .el-textarea {
      border: none !important;
      background: #f5f6fa !important;
      border-radius: 16px;
      .el-textarea__inner {
        padding: 12px 16px !important;
        border: none !important;
        border-radius: 16px !important;
      }
    }
  }
  .bottom-step-buttons {
    margin: 60px 0px 0px 0px;
  }
}
</style>
