var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "kpi-item-wrap",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.toDetail.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "kpi-item-content" }, [
        _c("div", { staticClass: "kpi-item-left" }, [
          _c(
            "div",
            { class: `title-wrap title-wrap-${_vm.fromType !== "kpiPlan"}` },
            [
              _vm.fromType === "myDepart" && _vm.kpiDetail.userDto
                ? _c("avatar-img", {
                    attrs: {
                      avatar: _vm.kpiDetail.userDto.avatar,
                      "can-delete": false,
                      name: _vm.kpiDetail.userDto.name,
                      size: [24, 18],
                    },
                  })
                : _vm._e(),
              _c("el-tooltip", { attrs: { content: _vm.item.name } }, [
                _c("div", { staticClass: "name" }, [
                  _vm._v(_vm._s(_vm.item.name)),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "status-wrap" },
                [
                  _c("kpi-status", {
                    attrs: {
                      type:
                        _vm.fromType === "kpiPlan"
                          ? _vm.item.status
                          : _vm.kpiDetail.status,
                      classType: _vm.fromType === "kpiPlan" ? "kpiPlan" : "kpi",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi-detail-wrap" },
            [
              _c(
                "el-tooltip",
                {
                  attrs: {
                    content: `周期：${_vm.item.cycleYear} ${
                      _vm.item.cycleId &&
                      _vm.kpiCycleTypeList[_vm.item.cycleId].name
                    }`,
                    disabled: true,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "list" },
                    [
                      _c("Icon", {
                        staticClass: "icon",
                        attrs: { "icon-class": "icon-zhouqi" },
                      }),
                      _vm._v(
                        " 周期：" +
                          _vm._s(_vm.item.cycleYear) +
                          " " +
                          _vm._s(
                            _vm.item.cycleId &&
                              _vm.kpiCycleTypeList[_vm.item.cycleId].name
                          ) +
                          " "
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-tooltip",
                {
                  attrs: {
                    content: `考核时间：${_vm.$utils.dateToYYYYMMDD(
                      _vm.item.startDate
                    )}-${_vm.$utils.dateToYYYYMMDD(_vm.item.endDate)}`,
                    disabled: true,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "list" },
                    [
                      _c("Icon", {
                        staticClass: "icon",
                        attrs: { "icon-class": "icon-biaoge-shijian" },
                      }),
                      _vm._v(
                        " 考核时间：" +
                          _vm._s(
                            _vm.$utils.dateToYYYYMMDD(_vm.item.startDate)
                          ) +
                          "-" +
                          _vm._s(_vm.$utils.dateToYYYYMMDD(_vm.item.endDate)) +
                          " "
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm.fromType === "kpiPlan"
                ? _c(
                    "div",
                    { staticClass: "list list-count" },
                    [
                      _c("Icon", {
                        staticClass: "icon",
                        attrs: { "icon-class": "icon-biaoge-xiezuoren" },
                      }),
                      _vm._v(" 考核人数：" + _vm._s(_vm.item.userNum) + "人 "),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "list list-user" },
                [
                  _c("Icon", {
                    staticClass: "icon",
                    attrs: { "icon-class": "icon-fuzeren3" },
                  }),
                  _vm._v("负责人： "),
                  _vm._l(
                    _vm.item.examinePowerDtoList,
                    function (userItem, userIndex) {
                      return _c(
                        "div",
                        { key: userItem.userId },
                        [
                          _c("wx-open-data", {
                            attrs: {
                              id: userItem.userInfo && userItem.userInfo.name,
                              "name-data": {
                                name:
                                  userItem.userInfo && userItem.userInfo.name,
                              },
                            },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(
                                userIndex !==
                                  _vm.item.examinePowerDtoList.length - 1
                                  ? "、"
                                  : ""
                              ) +
                              " "
                          ),
                        ],
                        1
                      )
                    }
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "kpi-item-right" },
          [_vm._t("kpiItemRight")],
          2
        ),
      ]),
      _vm._t("kpiProcess"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }