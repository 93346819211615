<template>
  <div>
    <el-popover
      ref="popoverOkrAlign"
      width="700"
      popper-class="popover-okr-align"
      :placement="placement"
      :disabled="disabled"
      @hide="hide"
      @show="getTargetAligningShow"
    >
      <div :class="`popover-okr-wrap target_aligning target_aligning-${type}`">
        <div class="target_aligning_search">
          <div class="cycle-wrap">
            <div>当前周期</div>
            <div v-if="okrCycleList !== null" class="cycle-infor">
              {{ okrCycleList.largaeCycle.name }}
              <template v-if="selectSmallCycle !== null">
                {{
                selectSmallCycle.name
                }}
              </template>
            </div>
            <div v-if="selectKeyList.length === 0" class="cycle-select">
              <el-select
                v-model="smallCycleId"
                class="select-cycle"
                popper-class="popover-okr-align"
              >
                <div class="select-cycle-wrap" @click.stop>
                  <div class="cycle-large">
                    <div
                      v-for="(val, key) in okrCycleList.allCycleList"
                      :key="val.id"
                      :label="val.name"
                      :value="val.id"
                      :class="largeCycleId === val.id ? 'select' : ''"
                      @click.stop="selectLargeCycleChange(key)"
                    >{{ val.name }}</div>
                  </div>
                  <div class="cycle-small">
                    <el-option
                      v-for="(val, key) in okrCycleList.smallCycle"
                      :key="val.id"
                      :label="val.name"
                      :value="val.id"
                      @click.native="selectCycleChange(key)"
                    >{{ val.name }}</el-option>
                  </div>
                </div>
                <slot slot="prefix">更改</slot>
              </el-select>
            </div>
          </div>
          <div v-if="selectKeyList.length === 0" class="aligning_search_box clearfix">
            <div class="aligning_search_box_input">
              <el-input
                ref="nameFocus"
                v-model="searchContent"
                v-focus
                prefix-icon="el-icon-search"
                class="search-input input_shadow_inset"
                placeholder="请输入姓名或标题搜索成员目标"
                maxlength="151"
                @input="getTargetAligningInput"
              />
            </div>
          </div>
        </div>
        <div v-if="selectKeyList.length !== 0" class="back-wrap" @click="back">
          <Icon icon-class="icon-left" class="back" />返回
        </div>
        <div v-if="selectKeyList.length === 0">
          <div v-if="targetAligningBeans.length > 0">
            <div
              class="target_aligning_list"
              style="overflow-x: hidden"
              @click.stop
              @scroll="listScroll()"
            >
              <div class="aligning_list_ul">
                <div
                  v-for="(item, userIndex) in targetAligningBeans"
                  :key="item.id"
                  class="aligning_list_li clearfix"
                >
                  <div class="person-infor-wrap">
                    <img
                      :src="
                        (item.chargeUser && item.chargeUser.avatar) ||
                        require('@/assets/imgs/common/avatar.png')
                      "
                      class="aligning_list_li_icon2 border-radius-20"
                    />
                    <div class="aligning_list_li_name">
                      <wx-open-data
                        :id="item.chargeUser && item.chargeUser.name"
                        :name-data="{
                          name: item.chargeUser && item.chargeUser.name
                        }"
                      />
                    </div>
                    <div class="job-title">
                      <wx-open-data
                        :id="item.chargeUser && item.chargeUser.departName"
                        :type="2"
                        :name-data="{
                          name: item.chargeUser && item.chargeUser.departName
                        }"
                      />
                    </div>
                    <div
                      v-if="item.chargeUser && item.chargeUser.departmentCall"
                      :class="`user-type user-type-${
                        item.chargeUser &&
                        item.chargeUser.departmentCall === '部门负责人'
                      }`"
                    >
                      {{
                      item.chargeUser &&
                      item.chargeUser.departmentCall === '部门负责人'
                      ? '部门负责人'
                      : '直属上级'
                      }}
                    </div>
                  </div>
                  <div v-for="(objItem, objIndex) in item.objectivesNewDtoList" :key="objItem.id">
                    <div class="cycle-list-wrap" :class="objItem.state ? 'cycle-list-select' : ''">
                      <div class="cycle-infor-left">
                        <div class="obj-num">O{{ objIndex + 1 }}</div>
                        <el-tooltip
                          placement="top"
                          popper-class="popover-okr-align-tooltip"
                          :open-delay="300"
                          :disabled="tooltipDisabled"
                          :content="$utils.tagFilter(objItem.content)"
                        >
                          <div
                            class="okr-list-content"
                            :class="`okr-list-content-${
                              objItem.isVisible === 1
                            }`"
                          >
                            <div class="okr-title" v-html="objItem.content"></div>
                            <privateOkr
                              :create-user="
                                userInfo.id === objItem.createUserid
                              "
                              :visible="objItem.isVisible === 1"
                              :content="
                                objItem.isVisible === 1
                                  ? '对方已设置为不可见'
                                  : ''
                              "
                              :type="1"
                            />
                          </div>
                        </el-tooltip>
                      </div>
                      <div class="cycle-infor-right">
                        <!-- 多对齐修改 -->
                        <div class="hover-block" @click="selectKeyClick(objItem)">查看KR</div>
                        <div v-if="objItem.state" class="align-state">
                          <div class="hover-hidden">已对齐</div>
                          <div
                            class="hover-block"
                            @click="
                              getTargetAligningSelect(
                                userIndex,
                                objIndex,
                                objItem.state
                              )
                            "
                          >取消对齐</div>
                        </div>
                        <div v-else class="align-o">
                          <div
                            class="hover-block"
                            @click="
                              getTargetAligningSelect(
                                userIndex,
                                objIndex,
                                objItem.state
                              )
                            "
                          >选择对齐O</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="targetAligningBeans.length === 0 && !loading">
            <div class="global-content-null-wrap global-content-gray">
              <img src="@/assets/imgs/common/commentNull.png" class="img" />
              <div class="null-text">暂无数据</div>
            </div>
          </template>
        </div>
        <div v-else>
          <div class="okr-key-list">
            <div
              v-for="(itemList, index) in selectKeyList"
              :key="itemList.id"
              class="key-list-wrap"
              :class="itemList.state ? 'cycle-list-select' : ''"
            >
              <div class="key-list">
                <div class="key-list-name">KR{{ index + 1 }}</div>
                <el-tooltip
                  popper-class="popover-okr-align-tooltip"
                  placement="top"
                  :open-delay="300"
                  :content="$utils.tagFilter(itemList.title)"
                >
                  <div class="key-list-content">
                    <div class="key-list-title" v-html="itemList.title"></div>
                    <privateOkr
                      :create-user="userInfo.id === itemList.createUserid"
                      :visible="itemList.isVisible === 1"
                      :content="
                        itemList.isVisible === 1 ? '对方已设置为不可见' : ''
                      "
                      :type="2"
                    />
                  </div>
                </el-tooltip>
              </div>
              <div class="cycle-infor-right">
                <div v-if="itemList.state" class="align-state">
                  <div class="hover-hidden">已对齐</div>
                  <div class="hover-block" @click="getKrAligningSelect(index, itemList.state)">取消对齐</div>
                </div>
                <div v-else class="align-o">
                  <div
                    class="hover-block"
                    @click="getKrAligningSelect(index, itemList.state)"
                  >选择对齐KR</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 多对齐修改 -->
        <div v-if="type === 'add'" class="bottom-step-buttons">
          <button class="left-button" @click="getTargetAligningClose">取消</button>
          <button @click="save">完成</button>
        </div>
      </div>
      <slot slot="reference" name="guidepop"></slot>
    </el-popover>
  </div>
</template>
<script>
import privateOkr from '@/views/okr/components/PrivateOkr'
import cycleEnum from '@/utils/enums/cycle.js'
import debounce from 'lodash/debounce' // 局部引入防抖
export default {
  components: {
    privateOkr
  },
  props: {
    targetAligningShow: {
      type: Boolean,
      default: false
    },
    targetAligningList: {
      // 已对齐数据
      type: Array,
      default: () => {
        return []
      }
    },
    targetAligningObj: {
      // 当前目标数据
      type: Object,
      default: () => {
        return {}
      }
    },
    placement: {
      type: String,
      default: 'bottom-start'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    okrListIndex: {
      type: Number,
      default: 0
    },
    type: {
      default: 'edit'
    }
  },
  data() {
    return {
      loading: true,
      targetAligningShowz: false,
      searchContent: '',
      targetAligningBeans: [], // 获取对齐数据
      targetAligningList1: [], // 对齐数据列表
      targetAligningObjz: {},
      targetAligningBeansState: false,
      selectKeyList: [],
      okrCycleList: null,
      CYCLETYPE: cycleEnum.CYCLETYPE,
      selectSmallCycle: null,
      largaeCycle: null,
      smallCycleId: '',
      largeCycleId: '',
      selectKeyObjItem: {},
      tooltipDisabled: false
    }
  },
  created() {
    // 周期
    this.init()
  },
  methods: {
    listScroll() {
      this.tooltipDisabled = true
      setTimeout(() => {
        this.tooltipDisabled = false
      }, 300)
    },
    hide() {
      this.$store.commit('common/setScrollBgVisible', false)
    },
    // 显示状态
    getTargetAligningShow() {
      this.init()
      this.$store.commit('common/setScrollBgVisible', true)
      var obj = document.getElementsByClassName('global-bg-style')
      if (obj) {
        obj[0].style.zIndex = '3333'
      }
      this.getTargetAligningClear()
      this.targetAligningShowz = this.targetAligningShow
      this.targetAligningList1 = this.targetAligningList
      this.targetAligningObjz = this.targetAligningObj
      setTimeout(() => {
        this.$nextTick(() => {
          this.$refs.nameFocus.focus()
        })
      }, 500)
      this.$nextTick(() => {
        this.getTargetAligning()
      })
    },
    init() {
      this.okrCycleList = this.$store.state.common.okrCycleList
      if (
        this.okrCycleList.smallCycle &&
        this.okrCycleList.smallCycle.length > 0
      ) {
        this.okrCycleList.smallCycle.forEach((item) => {
          if (item.defSelected === 1) {
            this.selectSmallCycle = item
            this.smallCycleId = item.id
            this.largeCycleId = item.cycleId
            this.largaeCycle = this.okrCycleList.largaeCycle
          }
        })
      }
    },
    // 清楚默认数据
    getTargetAligningClear() {
      this.targetAligningShowz = false
      this.searchContent = ''
      this.targetAligningBeans = [] // 获取对齐数据
      this.targetAligningList1 = [] // 对齐数据列表
      this.targetAligningObjz = {}
      this.selectKeyList = []
    },
    getTargetAligningInput: debounce(function () {
      this.getTargetAligning()
    }, 200),
    // 搜索对齐内容
    async getTargetAligning() {
      this.targetAligningBeans = []
      this.loading = true
      const message_ = this.searchContent.replace(/\s*/g, '') || ''
      if (message_.replace(/\s*/g, '').length > 150) {
        this.$message({
          msg: '搜索内容不能超过 150 个字符哦!',
          type: 'warn'
        })
        this.searchContent = message_.substring(0, 150)
      }
      let beans
      if (!this.targetAligningObjz) {
        beans = {
          searchString: this.searchContent,
          cycleInfoId:
            this.selectSmallCycle.id || localStorage.getItem('periodId') // this.targerInCycle
        }
      } else {
        beans = {
          searchString: this.searchContent,
          cycleInfoId:
            this.selectSmallCycle.id || localStorage.getItem('periodId'),
          objId: this.targetAligningObjz.id
        }
      }
      await this.$apis
        .GETOKRALIGNLIST(beans)
        .then((rest) => {
          if (rest.status === 200) {
            rest.data.forEach((item) => {
              item.objectivesNewDtoList.forEach((objItem) => {
                objItem.content = this.$utils.changeToHtml(objItem.content)
                objItem.keyresultsList.forEach((keyItem) => {
                  keyItem.title = this.$utils.changeToHtml(keyItem.title)
                })
              })
            })
            const dataBean = rest.data
            this.getTargetAligningDispose(dataBean, 'O')
            this.targetAligningBeansState = true
          } else {
            this.$message.error(rest.message)
          }
          this.loading = false
        })
        .catch((err) => {
          this.targetAligningBeansState = true
          this.loading = false
        })
    },
    // 数据处理
    getTargetAligningDispose(data, type) {
      if (data.length > 0) {
        if (type === 'O') {
          for (let j = 0; j < data.length; j++) {
            for (let i = 0; i < data[j].objectivesNewDtoList.length; i++) {
              if (this.targetAligningList.length > 0) {
                this.targetAligningList.forEach((alignItem) => {
                  if (
                    (
                      alignItem.objectivesAssociationList ||
                      alignItem.childObjectivesAssociationList ||
                      []
                    ).length > 0
                  ) {
                    ;(
                      alignItem.objectivesAssociationList ||
                      alignItem.childObjectivesAssociationList ||
                      []
                    ).forEach((childItem) => {
                      if (
                        childItem.businessId ===
                        data[j].objectivesNewDtoList[i].id
                      ) {
                        data[j].objectivesNewDtoList[i].state = true
                      }
                    })
                  }
                })
              } else {
                data[j].objectivesNewDtoList[i].state = false
              }
            }
          }
          this.targetAligningBeans = JSON.parse(JSON.stringify(data))
        } else {
          data.forEach((krItem) => {
            if (this.targetAligningList.length > 0) {
              this.targetAligningList.forEach((alignItem) => {
                if (
                  (
                    alignItem.objectivesAssociationList ||
                    alignItem.childObjectivesAssociationList ||
                    []
                  ).length > 0
                ) {
                  ;(
                    alignItem.objectivesAssociationList ||
                    alignItem.childObjectivesAssociationList ||
                    []
                  ).forEach((childItem) => {
                    if (childItem.businessId === krItem.id) {
                      krItem.state = true
                    }
                  })
                }
              })
            } else {
              krItem.state = false
            }
          })
          this.selectKeyList = JSON.parse(JSON.stringify(data))
        }
      }
    },
    // 关闭启动状态
    getTargetAligningClose() {
      this.searchContent === ''
      this.targetAligningBeans = []
      this.selectKeyList = []
      this.hide()
      if (this.$refs.popoverOkrAlign) {
        this.$refs.popoverOkrAlign.doClose()
      }
    },
    selectKeyClick(objItem) {
      this.selectKeyObjItem = objItem // 存储当前关键结果的目标信息
      this.$apis
        .OKRIDKEYLIST({
          objId: objItem.id
        })
        .then((rest) => {
          if (rest.status === 200) {
            rest.data.forEach((keyItem) => {
              keyItem.title = this.$utils.changeToHtml(keyItem.title)
            })
            this.getTargetAligningDispose(rest.data, 'KR')
          } else {
            this.$message.error(rest.message)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getTargetAligningSelect(userIndex, objIndex, state) {
      this.targetAligningBeans[userIndex].objectivesNewDtoList[objIndex].state =
        !state
      if (this.type === 'add') {
        this.getTargetAligningList(
          this.targetAligningBeans[userIndex].objectivesNewDtoList[objIndex],
          'O',
          !state
        )
      } else {
        this.$emit(
          'getTargetAligningSubmit',
          this.targetAligningBeans[userIndex].objectivesNewDtoList[objIndex],
          {
            businessType: 0,
            type: !state ? 'add' : 'delete'
          }
        )
        this.getTargetAligningClose()
      }
    },
    getKrAligningSelect(index, state) {
      this.selectKeyList[index].state = !state
      this.selectKeyList = JSON.parse(JSON.stringify(this.selectKeyList))
      if (this.type === 'add') {
        this.getTargetAligningList(this.selectKeyList[index], 'KR', !state)
      } else {
        this.$emit('getTargetAligningSubmit', this.selectKeyList[index], {
          businessType: 1,
          type: !state ? 'add' : 'delete'
        })
        this.getTargetAligningClose()
      }
    },
    // 多选保存删除数据
    getTargetAligningList(data, type, state) {
      var objId = type === 'KR' ? data.objId : data.id
      var businessId = data.id
      var insertState = false
      var insertObjIndex = 0
      var insertObjList = {}
      this.targetAligningList.forEach((alignItem, alignItemIndex) => {
        if (objId === alignItem.id) {
          insertState = true
          insertObjIndex = alignItemIndex
        }
        alignItem.childObjectivesAssociationList.forEach(
          (selectItem, selectItemIndex) => {
            if (!state && businessId === selectItem.businessId) {
              alignItem.childObjectivesAssociationList.splice(
                selectItemIndex,
                1
              )
            }
          }
        )
        if ((alignItem.childObjectivesAssociationList || []).length === 0) {
          this.targetAligningList.splice(alignItemIndex, 1)
        }
      })
      if (state) {
        var childObj = {
          activeAlignObjId: '',
          beAlignedObjId: objId,
          businessType: type === 'KR' ? 1 : 0,
          businessId
        }
        if (type === 'KR') {
          childObj = Object.assign(data, childObj)
        }
        if (!insertState) {
          insertObjList = type === 'KR' ? this.selectKeyObjItem : data
          insertObjList.childObjectivesAssociationList = []
          insertObjList.childObjectivesAssociationList.push(childObj)
          this.targetAligningList.push(insertObjList)
        } else {
          this.targetAligningList[
            insertObjIndex
          ].childObjectivesAssociationList.push(childObj)
        }
      }
      // 多对齐修改
      // this.save()
    },
    back() {
      this.selectKeyList = []
    },
    selectLargeCycleChange(index) {
      this.okrCycleList.smallCycle =
        this.okrCycleList.allCycleList[index].cycleInfoList
      this.largeCycleId = this.okrCycleList.allCycleList[index].id
      this.largaeCycle = this.okrCycleList.allCycleList[index]
      this.okrCycleList = JSON.parse(JSON.stringify(this.okrCycleList))
    },
    selectCycleChange(index) {
      this.selectSmallCycle = this.okrCycleList.smallCycle[index]
      this.okrCycleList.largaeCycle = this.largaeCycle
      console.log('selectCycleChange', this.okrCycleList)
      this.getTargetAligning()
    },
    save() {
      this.$emit('getTargetAligningSubmit', this.targetAligningList)
      this.getTargetAligningClose()
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../assets/styles/dialog.less';
.select-cycle-wrap {
  width: 400px;
  .flex-l();
  height: 240px;
  align-items: flex-start;
  .cycle-large,
  .cycle-small {
    max-height: 240px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .cycle-small {
    flex: 1;
  }
  .cycle-large {
    div {
      height: 40px;
      line-height: 40px;
      margin: 0px 8px;
      min-width: 100px;
      padding: 0px 16px;
      max-width: 160px;
      .text-n(1);
      cursor: pointer;
      &:hover {
        color: #3a70ff;
        background: #f5f6fa;
        border-radius: 8px;
      }
    }
    div.select {
      color: #3a70ff;
      background: #f5f6fa;
      border-radius: 8px;
    }
  }
  .cycle-small {
  }
}
.confidence_content {
  height: 32px;
  .text-n(1);
}
.confidence_content.checked {
  background: #f5f6fa !important;
}
.okr-list-content,
.key-list-content {
  position: relative;
  flex: 1;
  min-width: 0;
}
.okr-list-content-true .okr-title {
  min-width: 200px;
}
.okr-list-content .flur-wrap {
  height: 26px;
  top: 7px;
}
.key-list-content .flur-wrap {
  left: 8px;
  right: 50px;
  width: auto;
}
::v-deep .global-content-null-wrap {
  margin: 30px auto;
  height: 160px !important;
}
.target_aligning_list {
  height: calc(100vh - 140px);
  max-height: 220px;
}
.okr-key-list {
  height: calc(100vh - 180px);
  max-height: 180px;
  overflow-y: auto;
}
// 多对齐修改
.target_aligning-add {
  .target_aligning_list {
    height: calc(100vh - 190px);
    max-height: 170px;
  }
  .okr-key-list {
    height: calc(100vh - 230px);
    max-height: 130px;
    overflow-y: auto;
  }
}
.target_aligning_list {
  .person-infor-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 16px 0px 0px 12px;
    .aligning_list_li_icon2 {
      width: 24px;
      height: 24px;
      min-width: 24px;
      border-radius: 100%;
      margin-right: 12px;
    }
    .aligning_list_li_name {
      font-size: 16px;
      font-weight: 700;
      color: #14142b;
      line-height: 28px;
      max-width: calc(50% - 70px);
      .text-n(1);
    }
    .job-title {
      font-size: 12px;
      color: #a0a3bd;
      line-height: 22px;
      margin-left: 8px;
      max-width: calc(50% - 70px);
      .text-n(1);
    }
    .user-type {
      margin-left: 8px;
      padding: 0px 4px;
      font-size: 12px;
      height: 20px;
      border-radius: 8px;
      color: #3a70ff;
      background: rgba(58, 120, 246, 0.06);
      border: 1px solid rgba(58, 120, 246, 0.24);
    }
    .user-type-true {
      color: #ed2e7e;
      border: 1px solid rgba(237, 46, 126, 0.24);
      background: rgba(195, 0, 82, 0.06);
    }
  }
  .cycle-list-wrap {
    .flex-bt();
    padding: 0px 12px;
    height: 40px;
    line-height: 40px;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    margin: 4px 0px 4px 36px;
    .obj-label-position {
      right: 0;
      top: 0;
    }
    .okr-title {
      font-size: 14px;
      color: #444558;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 390px;
      &:before {
        content: '';
        display: block;
      }
    }
    .cycle-infor-left {
      .flex-l();
      .obj-num {
        width: 24px;
        height: 24px;
        border-radius: 12px;
        background: #548af7;
        margin-right: 8px;
        font-size: 12px;
        font-weight: bold;
        line-height: 24px;
        color: #fff;
        text-align: center;
      }
    }

    &:hover {
      background: rgba(245, 246, 250, 0.7);
      .hover-block {
        display: block;
      }
      .hover-hidden {
        display: none;
      }
    }
  }
  .cycle-list-select {
    background: #f5f6fa;
  }
}
.okr-key-list {
  .key-list-wrap {
    .flex-l();
    justify-content: space-between;
    padding: 0px 8px;
    height: 40px;
    line-height: 40px;
    border-radius: 10px;
    cursor: pointer;
    margin: 4px 0px;
    .key-list {
      .flex-l();
      flex: 1;
      font-size: 14px;
      .key-list-name {
        font-weight: 500;
        padding: 0 8px;
        height: 24px;
        line-height: 24px;
        border-radius: 12px;
        margin: 0px 8px 0px 0px;
        font-size: 14px;
        background: rgba(58, 120, 246, 0.12);
        color: #3a70ff;
      }
      .key-list-title {
        color: #444558;
        // .text-n(1);
        max-width: 480px;
        margin-left: 8px;
        height: 40px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &:before {
          content: '';
          display: block;
        }
      }
    }
    &:hover {
      background: rgba(245, 246, 250, 0.7);
      .hover-block {
        display: block;
      }
      .hover-hidden {
        display: none;
      }
    }
  }

  .cycle-list-select {
    background: #f5f6fa;
  }
}
.cycle-infor-right {
  .flex-r();
  font-size: 12px;
  font-weight: 400;
  color: #548af7;
  .hover-hidden {
    color: #ff9900;
  }
  .align-o {
    .hover-block {
      border: 1px solid #3a70ff;
    }
  }
  .hover-block {
    padding: 0 8px;
    border-radius: 16px;
    border: 1px solid #fff;
    font-weight: 500;
    cursor: pointer;
    margin-left: 8px;
    height: 24px;
    line-height: 22px;
    background: #fff;
    display: none;
    &:hover {
      border: 1px solid #548af7;
      background: #f3f7fe;
      color: #548af7;
    }
  }
  .align-state {
    .hover-block {
      padding: 0px;
      height: 40px;
      line-height: 40px;
      border: none;
      color: #ff572b;
      background: none;
    }
  }
}
</style>
<style lang="less" scoped>
.el-dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}
.target_aligning {
  left: 0px;
  top: 0px;
  padding: 4px 12px;
  box-sizing: border-box;
  height: calc(100vh - 100px);
  max-height: 270px;
  .target_aligning_search {
    width: 100%;
    .flex-l();
    justify-content: space-between;
    height: 40px;
    .cycle-wrap {
      .flex-l();
      flex: 1;
      font-size: 20px;
      font-weight: bold;
      color: #14142b;
      .cycle-infor {
        padding: 0 16px;
        height: 32px;
        line-height: 32px;
        border-radius: 16px;
        margin: 0px 8px;
        font-size: 14px;
        background: rgba(58, 120, 246, 0.12);
        color: #3a70ff;
        font-weight: normal;
        max-width: 220px;
        .text-n(1);
        word-break: break-all;
      }
      .cycle-select {
        .el-dropdown {
          display: block;
        }
        .el-dropdown-link {
          color: #a0a3bd;
          font-size: 12px;
          font-weight: normal;
          cursor: pointer;
          &:hover {
            color: #3a70ff;
          }
        }
      }
    }
    .aligning_search_box {
      width: 240px;
      height: 40px;
      line-height: 40px;
      background: #f5f6fa;
      border-radius: 20px;
      margin: 0px auto 0px auto;
      .aligning_search_box_input {
        width: 240px;
        height: 100%;
        float: left;
      }
    }
  }
  .back-wrap {
    .flex-l();
    cursor: pointer;
    font-size: 14px;
    color: #6e7491;
    margin: 12px 0px 8px 0px;
    height: 20px;
    .back {
      font-size: 16px;
      margin-right: 4px;
    }
    &:hover {
      color: #3a70ff;
    }
  }
  .bottom-step-buttons {
    margin-top: 16px;
    button {
      border-radius: 12px;
    }
  }
  .target_aligning_from {
    width: 100%;
    height: 65px;
    margin: 22px auto 0px auto;
    display: flex;
    justify-content: flex-end;
    .target_aligning_from_button1 {
      width: 92px;
      height: 32px;
      line-height: 32px;
      background: #3a78f6;
      border-radius: 12px;
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      text-align: center;
      cursor: pointer;
      &:hover {
        opacity: 0.87;
      }
    }
    .target_aligning_from_button2 {
      width: 92px;
      height: 32px;
      line-height: 32px;
      border: 1px solid #3a78f6;
      border-radius: 12px;
      font-size: 14px;
      font-weight: 500;
      color: #3a78f6;
      text-align: center;
      margin-right: 8px;
      cursor: pointer;
      &:hover {
        opacity: 0.87;
      }
    }
  }
}

::v-deep .search-input .el-input__inner {
  height: 40px !important;
  line-height: 40px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #14142b !important;
  padding: 0px 8px 0px 30px;
  border-radius: 20px;
}
::v-deep .select-cycle {
  .el-input__inner {
    padding: 0px;
    width: 40px;
    border: none;
  }
  .el-input__prefix {
    color: #a0a3bd;
    font-size: 12px;
    font-weight: normal;
    cursor: pointer;
    line-height: 40px;
    background: #fff;
    display: block;
    left: 0px;
    right: 0px;
    &:hover {
      color: #3a70ff;
    }
  }
  .el-input__suffix {
    display: none;
  }
}
::v-deep .el-popover {
  padding: 0px 24px !important;
}
.el-select-dropdown {
  z-index: 9999 !important;
}
</style>
<style>
.popover-okr-align {
  z-index: 9999 !important;
}
.el-tooltip__popper.popover-okr-align-tooltip {
  z-index: 99999 !important;
}
</style>
