var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新建目标",
            visible: _vm.videoDialogShow,
            width: "880px",
            "append-to-body": true,
            "before-close": _vm.handleClose,
            "custom-class": "add-okr-dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.videoDialogShow = $event
            },
            close: _vm.closeDialog,
          },
        },
        [
          _c("span", {
            staticClass: "el-icon-close",
            on: { click: _vm.closeDialog },
          }),
          _c("div", { staticClass: "video-div" }, [
            _c(
              "video",
              {
                attrs: {
                  id: "video",
                  controls: "",
                  autoplay: "",
                  disablepictureinpicture: "",
                },
              },
              [
                _c("source", {
                  attrs: {
                    type: "audio/mp4",
                    src:
                      _vm.videoUrl || require(`@/assets/mp4/add_okr_vedio.mp4`),
                  },
                }),
              ]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }