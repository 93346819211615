<template>
  <!-- 企信pc迁移后新增 -->
  <div class="video-list-wrap" @click.stop>
    <el-popover placement="bottom-end" width="330" trigger="click">
      <div class="video-content">
        <div class="title">观看视频介绍，快速上手源目标</div>
        <div class="video-list-content">
          <div class="video-list-wrap" v-for="item in videoList" :key="item.list">
            <div class="video-title">{{item.title}}</div>
            <div
              class="video-list"
              v-for="list in item.list"
              :key="list.title"
              @click="openVideo(list)"
            >
              <Icon icon-class="icon-bofang1" class="icon" />
              <div class="name">{{list.title}}</div>
            </div>
          </div>
        </div>
      </div>
      <div slot="reference">
        <el-popover
          v-model="$store.state.common.videoPopoverVisible"
          trigger="manual"
          :width="285"
          :visible-arrow="false"
          popper-class="video-popover animate-style-bottom"
        >
          <div class="video-popover-content">
            <div class="content">
              <div class="content-left">点击可查看更多 视频内容。</div>
              <div class="content-right">
                <div class="btn" @click="popoverVisibleChange">我知道了</div>
              </div>
            </div>
          </div>
          <div
            :class="`image-wrap image-wrap-${$store.state.common.videoPopoverVisible}`"
            slot="reference"
            @click="popoverVisibleChange"
          >
            <div class="img animate-style-bottom" v-if="$store.state.common.videoPopoverVisible">
              <Img :src="'/advertisement/hand.png'" />
            </div>
          </div>
        </el-popover>
      </div>
    </el-popover>
    <div v-if="videoDialogShow">
      <look-add-okr
        :videoUrl="videoUrl"
        :videoName="videoName"
        :recordTime="true"
        :video-dialog-show="videoDialogShow"
        @closeVideoDialog="closeVideoDialog"
      />
    </div>
  </div>
</template>
<script>
import lookAddOkr from '@/views/okr/dialog/lookAddOkr/lookAddOkr.vue'
export default {
  data() {
    return {
      videoList: [{
        title: 'OKR',
        list: [{
          title: '一个视频了解源目标OKR',
          url: 'https://videos.okrt.com/okrt/allin/productPromotion.mp4'
        },
        // {
        //   title: '快速上手源目标OKR',
        //   url: 'https://videos.okrt.com/okrt/allin/10.mp4'
        // }
        ]
      },
      {
        title: '任务/todo',
        list: [{
          title: '一分钟搞懂任务分类',
          url: 'https://videos.okrt.com/okrt/allin/02.mp4'
        },
        {
          title: '一分钟上手制定任务',
          url: 'https://videos.okrt.com/okrt/allin/01.mp4'
        },
        {
          title: '快速了解Todo待办事项',
          url: 'https://videos.okrt.com/okrt/allin/03.mp4'
        }]
      },
      {
        title: '工作台',
        list: [{
          title: '打造你的高效工作台',
          url: 'https://videos.okrt.com/okrt/allin/06.mp4'
        }]
      },
      {
        title: '项目',
        list: [{
          title: '一个视频快速了解项目',
          url: 'https://videos.okrt.com/okrt/allin/04.mp4'
        }]
      },
      // {
      //   title: '统计分析',
      //   list: [{
      //     title: '1分钟读懂数据看板',
      //     url: 'https://videos.okrt.com/okrt/kpi/1.mp4'
      //   }]
      // },
      {
        title: '总结',
        list: [{
          title: '玩转总结，开启高效复盘',
          url: 'https://videos.okrt.com/okrt/allin/08.mp4'
        }]
      },
      // {
      //   title: '咪厅',
      //   list: [{
      //     title: '如何用咪厅贯穿PDAC工作流？',
      //     url: 'https://videos.okrt.com/okrt/kpi/1.mp4'
      //   }]
      // },
      // {
      //   title: '绩效',
      //   list: [{
      //     title: '快速上手绩效考管理',
      //     url: 'https://videos.okrt.com/okrt/kpi/1.mp4'
      //   }]
      // },
      {
        title: '管理员手册',
        list: [{
          title: '快速搭建团队',
          url: 'https://videos.okrt.com/okrt/allin/05.mp4'
        },
        // {
        //   title: '初始化企业管理后台配置',
        //   url: 'https://videos.okrt.com/okrt/allin/09.mp4'
        // }
        ]
      }],
      videoUrl: '',
      videoName: '',
      videoDialogShow: false
    }
  },
  components: {
    lookAddOkr
  },
  mounted() {
    document.addEventListener('click', () => {
      this.popoverVisibleChange()
    })
  },
  methods: {
    popoverVisibleChange() {
      this.$store.commit('common/setVideoPopoverVisible', false)
    },
    openVideo(item) {
      this.videoUrl = item.url
      this.videoName = item.title
      this.videoDialogShow = true
    },
    closeVideoDialog() {
      this.videoDialogShow = false
    }
  }
}
</script>
<style lang="less" scoped>
.video-popover-content {
  padding: 12px;
  .content {
    .flex-bt();
    .content-left {
      width: 98px;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
      line-height: 21px;
    }
    .content-right {
      .btn {
        width: 94px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        border-radius: 16px;
        line-height: 32px;
        border: 1px solid #fff;
        cursor: pointer;
      }
    }
  }
}
.video-content {
  .title {
    font-size: 14px;
    color: #548af7;
  }
  .video-list-content {
    max-height: 50vh;
    overflow-y: auto;
    .video-list-wrap {
      .video-title {
        font-size: 16px;
        font-weight: 800;
        color: #14142b;
        margin-top: 16px;
      }
      .video-list {
        line-height: 44px;
        height: 44px;
        background: #f5f6fa;
        border-radius: 8px;
        margin-top: 8px;
        cursor: pointer;
        padding: 0px 16px;
        .flex-l();
        .icon {
          font-size: 20px;
          color: #c0d4fc;
          margin: 0px 12px 0px 0px;
        }
        .name {
          font-size: 14px;
          font-weight: 500;
          color: #444558;
          .text-n(1);
          flex: 1;
        }
        &:hover {
          .icon {
            color: #3a70ff;
          }
          background: rgba(245, 246, 250, 0.5);
        }
      }
    }
  }
}
.image-wrap {
  border-radius: 4px;
  width: 24px;
  height: 24px;
  background: url('../../../../../../assets/imgs/advertisement/videoList.png')
    no-repeat center center;
  background-size: 24px auto;
  cursor: pointer;
  position: relative;
  .img {
    position: absolute;
    top: 14px;
    left: 0px;
    right: 0px;
    text-align: center;
    img {
      width: 28px;
      height: auto;
    }
  }
  &:hover {
    background: #eef6fd;
    color: #3068ff;
    background: #eef6fd
      url('../../../../../../assets/imgs/advertisement/videoListHover.png')
      no-repeat center center;
    background-size: 24px auto;
  }
}
.image-wrap-true {
  background: #eef6fd;
  color: #3068ff;
  background: #eef6fd
    url('../../../../../../assets/imgs/advertisement/videoListHover.png')
    no-repeat center center;
  background-size: 24px auto;
}
</style>
<style lang="less">
.video-popover {
  top: 45px !important;
  z-index: 98 !important;
  background: linear-gradient(91deg, #3a70ff 0%, #6d3aff 100%) !important;
}
</style>
