var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-drawer",
        {
          staticClass: "okr-share-drawer",
          attrs: {
            visible: _vm.okrShareVisible,
            direction: "rtl",
            "before-close": _vm.handleClose,
            "append-to-body": true,
            size: 584,
            "modal-append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.okrShareVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "dialog-header",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c("div", { staticClass: "dialog-title" }, [
                _vm._v(
                  "目标共享人（" + _vm._s(_vm.okrShareList.length || 0) + "）"
                ),
              ]),
              !_vm.disabled
                ? _c(
                    "div",
                    { staticClass: "dialog-button" },
                    [
                      _c(
                        "user-picker-checked",
                        {
                          attrs: {
                            "select-people-list": _vm.chargeUserListNew,
                            checked: true,
                            length: 40,
                            pageFrom: "okrShare",
                            createdUser:
                              _vm.okrShareList.length > 0
                                ? _vm.okrShareList[0].userId
                                : "",
                          },
                          on: { pick: _vm.chargeUserPicker },
                        },
                        [
                          _c("Icon", {
                            staticClass: "cooperation-add",
                            attrs: { "icon-class": "icon-a-tianjia2" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _c("div", { staticClass: "share-dialog-div" }, [
            !_vm.isShowDetails
              ? _c(
                  "div",
                  { staticClass: "okr-share-div" },
                  _vm._l(_vm.okrShareList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "okr-share-list",
                        on: {
                          click: function ($event) {
                            return _vm.showDetails(item)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "share-title" }, [
                          _c("div", { staticClass: "title-left" }, [
                            _c(
                              "div",
                              { staticClass: "avatar-div" },
                              [
                                item.user.avatar
                                  ? _c("el-image", {
                                      staticClass:
                                        "avatar-img border-radius-20",
                                      attrs: {
                                        fit: "cover",
                                        src:
                                          item.user.avatar ||
                                          require("@/assets/imgs/common/avatar.png"),
                                        alt: "",
                                      },
                                    })
                                  : _c("el-image", {
                                      staticClass:
                                        "avatar-img border-radius-20",
                                      attrs: {
                                        fit: "cover",
                                        src: require("@/assets/imgs/common/avatar.png"),
                                      },
                                    }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "username" },
                              [
                                _c("wx-open-data", {
                                  attrs: {
                                    id: item.user.name,
                                    nameData: { name: item.user.name },
                                  },
                                }),
                              ],
                              1
                            ),
                            index == 0
                              ? _c("div", { staticClass: "okr-creator" }, [
                                  _vm._v("目标创建人"),
                                ])
                              : _vm._e(),
                            item.objProgressRete
                              ? _c(
                                  "div",
                                  { staticClass: "data-row data-row-top" },
                                  [
                                    _vm._v(" 推动目标进度提升 "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          item.objProgressRete
                                            ? item.objProgressRete
                                            : 0
                                        )
                                      ),
                                    ]),
                                    _vm._v(" % "),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]),
                        item.keyProgressCount
                          ? _c("div", { staticClass: "data-row" }, [
                              _vm._v(" 更新KR进度总次数 "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    item.keyProgressCount
                                      ? item.keyProgressCount
                                      : 0
                                  )
                                ),
                              ]),
                              _vm._v(" 次 "),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "data-row-task-div" }, [
                          _c("div", { staticClass: "task-row" }, [
                            _vm._v(" 待处理任务 "),
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.task.taskPendingCount
                                      ? item.task.taskPendingCount
                                      : 0
                                  ) +
                                  " "
                              ),
                            ]),
                            _vm._v(" 项 "),
                          ]),
                          _c("div", { staticClass: "task-row" }, [
                            _vm._v(" 进行中任务 "),
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.task.taskConductCount
                                      ? item.task.taskConductCount
                                      : 0
                                  ) +
                                  " "
                              ),
                            ]),
                            _vm._v(" 项 "),
                          ]),
                          _c("div", { staticClass: "task-row" }, [
                            _vm._v(" 完成任务 "),
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.task.taskFinishCount
                                      ? item.task.taskFinishCount
                                      : 0
                                  ) +
                                  " "
                              ),
                            ]),
                            _vm._v(" 项 "),
                          ]),
                          _c("div", { staticClass: "task-row" }, [
                            _vm._v(" 预期未完成任务 "),
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.task.taskProgressCount
                                      ? item.task.taskProgressCount
                                      : 0
                                  ) +
                                  " "
                              ),
                            ]),
                            _vm._v(" 项 "),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm.isShowDetails
              ? _c("div", { staticClass: "okr-share-details" }, [
                  _c(
                    "div",
                    {
                      staticClass: "details-return",
                      on: { click: _vm.returnlist },
                    },
                    [
                      _c("Icon", {
                        staticClass: "return-icon",
                        attrs: { "icon-class": "icon-xiangzuo" },
                      }),
                      _vm._v("返回 "),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "details-operation" },
                    [
                      _c(
                        "div",
                        { staticClass: "select-div" },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.userAvatar,
                                expression: "userAvatar",
                              },
                            ],
                            staticClass: "select-img border-radius-20",
                            staticStyle: {
                              width: "30px",
                              "min-width": "30px",
                              height: "30px",
                              "margin-right": "10px",
                              "border-radius": "50%",
                            },
                            attrs: {
                              src:
                                _vm.userAvatar ||
                                require("@/assets/imgs/common/avatar.png"),
                            },
                          }),
                          _c(
                            "el-select",
                            {
                              staticClass: "user-select",
                              attrs: {
                                placeholder: "请输入",
                                "popper-append-to-body": true,
                                "popper-class": "user-popper-select",
                              },
                              on: { change: _vm.selUser },
                              model: {
                                value: _vm.userValue,
                                callback: function ($$v) {
                                  _vm.userValue = $$v
                                },
                                expression: "userValue",
                              },
                            },
                            [
                              _vm._l(_vm.userOptions, function (item) {
                                return _c(
                                  "el-option",
                                  {
                                    key: item.userId,
                                    attrs: {
                                      label: item.userName,
                                      value: item.userId,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center",
                                          "min-width": "0",
                                        },
                                      },
                                      [
                                        item.avatar
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "align-items": "center",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                  },
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  staticClass:
                                                    "border-radius-20",
                                                  staticStyle: {
                                                    width: "30px",
                                                    "min-width": "30px",
                                                    height: "30px",
                                                    "margin-right": "10px",
                                                    "border-radius": "50%",
                                                  },
                                                  attrs: {
                                                    src:
                                                      item.avatar ||
                                                      require("@/assets/imgs/common/avatar.png"),
                                                  },
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          { staticClass: "label-text" },
                                          [
                                            _c("wx-open-data", {
                                              attrs: {
                                                id: item.userName,
                                                nameData: {
                                                  name: item.userName,
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              _vm._t(
                                "default",
                                function () {
                                  return [
                                    _c("wx-open-data", {
                                      attrs: {
                                        id: _vm.selUserName,
                                        nameData: { name: _vm.selUserName },
                                      },
                                    }),
                                  ]
                                },
                                { slot: "prefix" }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-select",
                        {
                          staticClass: "dynamic-select",
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.changeType },
                          model: {
                            value: _vm.dynamicType,
                            callback: function ($$v) {
                              _vm.dynamicType = $$v
                            },
                            expression: "dynamicType",
                          },
                        },
                        _vm._l(_vm.dynamicOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.dynamicList.length > 0
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading",
                            },
                            {
                              name: "scroll-bottom",
                              rawName: "v-scroll-bottom",
                              value: _vm.loadMore,
                              expression: "loadMore",
                            },
                          ],
                          staticClass: "details-dynamic-content",
                        },
                        _vm._l(_vm.dynamicList, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "details-dynamic-list" },
                            [
                              _c(
                                "div",
                                { staticClass: "details-dynamic-time" },
                                [_vm._v(_vm._s(item.time))]
                              ),
                              _vm._l(item.content, function (itemc, indexc) {
                                return _c(
                                  "div",
                                  {
                                    key: indexc,
                                    staticClass: "details-dynamic-row",
                                  },
                                  [
                                    _c("div", { staticClass: "dynamic-main" }, [
                                      _c("div", { staticClass: "main-top" }, [
                                        _c(
                                          "div",
                                          { staticClass: "img-avatar" },
                                          [
                                            _c("img", {
                                              staticClass: "border-radius-20",
                                              attrs: {
                                                src:
                                                  itemc.user.avatar ||
                                                  require("@/assets/imgs/common/avatar.png"),
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "dynamic-text" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "created-name" },
                                              [
                                                _c("wx-open-data", {
                                                  attrs: {
                                                    id: itemc.user.name,
                                                    nameData: {
                                                      name: itemc.user.name,
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(itemc.dynamicContent) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "dynamic-created" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$utils.globalTime(
                                                  itemc.createDate
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("div", { staticClass: "task-div" }, [
                                        _vm._v(
                                          " “" +
                                            _vm._s(itemc.businessTitle) +
                                            "” "
                                        ),
                                      ]),
                                    ]),
                                  ]
                                )
                              }),
                            ],
                            2
                          )
                        }),
                        0
                      )
                    : _c(
                        "div",
                        { staticClass: "no-data-div" },
                        [_c("no-data", { attrs: { text: "暂无动态" } })],
                        1
                      ),
                ])
              : _vm._e(),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }