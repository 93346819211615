var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "comment-box-wrap-new",
      class: `comment-box-${_vm.dropVisible}`,
      attrs: { contenteditable: _vm.dropVisible },
      on: {
        dragover: function ($event) {
          return _vm.dragevent(true)
        },
        mouseleave: function ($event) {
          return _vm.dragevent(false)
        },
        click: function ($event) {
          return _vm.dragevent(false)
        },
        drop: _vm.upLoadFile,
      },
    },
    [
      _c("div", { staticClass: "comment-box bottom-box" }, [
        _c(
          "div",
          {
            staticClass: "at-input-box comment-at-box",
            staticStyle: { "padding-left": "10px" },
          },
          [
            _c("at-input", {
              ref: "atCommentBoxinput",
              staticClass: "f-z-14",
              attrs: {
                "at-all": _vm.atAll,
                "taskdetail-boolean": _vm.taskdetailBoolean,
                html: _vm.form.comment,
                limit: _vm.limit,
                placeholder: _vm.placeholder,
                "sub-task": _vm.subTask,
                "enter-able": true,
                atDisable: _vm.atDisable,
                editable: _vm.isEditInput,
                autoFocus: _vm.autoFocus,
                lineFeed: _vm.lineFeed,
              },
              on: {
                input: _vm.getComment,
                enter: _vm.addComment,
                fileUpload: _vm.fileUpload,
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "upload-comment-wrap" }, [
          _c(
            "div",
            { staticClass: "file-upload-wrap" },
            [
              _vm.source != "stopdoing"
                ? _c("file-upload", {
                    attrs: {
                      "input-drag-file-list": _vm.dragFile,
                      imgVisible: true,
                    },
                    on: { fileFresh: _vm.fileFreshList },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "comment-release-btn" }, [
            _c(
              "div",
              { staticClass: "release-btn" },
              [
                !_vm.form.comment && _vm.uploadFileList.length === 0
                  ? _c(
                      "span",
                      {
                        staticClass: "disabled",
                        attrs: { loading: _vm.loading },
                      },
                      [_vm._v("发布")]
                    )
                  : _c(
                      "span",
                      {
                        attrs: { loading: _vm.loading },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.addComment.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("发布")]
                    ),
                _c("Icon", {
                  class:
                    _vm.form.comment || _vm.uploadFileList.length > 0
                      ? "release-icon release-icon-blue"
                      : "release-icon",
                  attrs: { "icon-class": "icon-arrow-down" },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm.dropVisible
        ? _c("div", { staticClass: "drop-wrap" }, [
            _vm._v("拖拽文件到这里，直接上传"),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }