var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      directives: [{ name: "preventReClick", rawName: "v-preventReClick" }],
      staticClass: "load-btn",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.clickBtn.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "span",
        { style: _vm.loading ? "visibility: hidden !important;" : "" },
        [_vm._t("default")],
        2
      ),
      _vm.loading
        ? _c("div", { staticClass: "ball-clip-rotate" }, [
            _c("div", { style: `border-color: ${_vm.color}` }),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }