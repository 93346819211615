<template>
  <div class="set-task-time">
    <el-dialog
      title="自定义重复"
      :visible="true"
      width="500px"
      :append-to-body="true"
      @close="$dialogClose"
    >
      <!-- 重复类型 -->
      <div class="m-b-20">
        <span>重复：</span>
        <el-select
          v-model="customType"
          placeholder="请选择"
          class="m-r-20"
          @change="customTypeChange"
        >
          <el-option
            v-for="(val, key) in customTypeDict"
            :key="key"
            :label="val"
            :value="parseInt(key)"
          />
        </el-select>
      </div>
      <!-- 每天重复 -->
      <template v-if="isDaily">
        <div>
          <span>间隔：</span>
          <el-select
            v-model="customInterval"
            placeholder="请选择"
            class="m-r-20"
          >
            <el-option
              v-for="item in 7"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
          <span>天</span>
        </div>
        <p class="f-z-16 m-t-20">每{{ dailyText }}重复</p>
      </template>
      <!-- 每周重复 -->
      <template v-if="isWeekly">
        <div>
          <span>间隔：</span>
          <el-select
            v-model="customInterval"
            placeholder="请选择"
            class="m-r-20"
          >
            <el-option
              v-for="item in 7"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
          <span>周</span>
        </div>
        <!-- 每周重复显示周的选择 -->
        <el-checkbox-group
          v-model="customCycleNum"
          class="m-t-20"
          @change="customCycleNumChange"
        >
          <el-checkbox-button v-for="item in 7" :key="item" :label="item">
            {{ weekToCn(item) }}
          </el-checkbox-button>
        </el-checkbox-group>
        <p class="f-z-16 m-t-20">
          <span class="p-r-5">
            每
            <span v-if="customInterval !== 1"> {{ customInterval }} </span>周
          </span>
          <template v-for="(item, index) in customCycleNum">
            <span :key="item">
              周{{ weekToCn(item) }}
              <!-- 最后一个元素不显示逗号 -->
              <template v-if="isLastIndex(index)">,</template>
            </span>
          </template>
          <span class="p-l-5">重复</span>
        </p>
      </template>
      <!-- 每月重复 -->
      <template v-if="isMonth">
        <div>
          <span>间隔：</span>
          <el-select
            v-model="customInterval"
            placeholder="请选择"
            class="m-r-20"
          >
            <el-option
              v-for="item in 7"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
          <span>月</span>
        </div>
        <ul class="day-box">
          <li
            v-for="item in day"
            :key="item"
            :class="dayActiveClass(item)"
            class="item"
            @click="dayClick(item)"
          >
            {{ item }}
          </li>
        </ul>
        <p class="f-z-16 m-t-20">
          <span class="p-r-5">
            每
            <span v-if="customInterval !== 1"> {{ customInterval }} </span>月
          </span>
          <template v-for="(item, index) in customCycleNum">
            <span :key="item">
              {{ item }}
              <!-- 最后一个元素不显示逗号 -->
              <template v-if="isLastIndex(index)">,</template>
            </span>
          </template>
          <span class="p-l-5">重复</span>
        </p>
      </template>
      <div slot="footer" class="flex-new justify-end footer">
        <el-button class="cancel-btns" @click="cancelDialog()">取 消</el-button>
        <button
          :loading="loading"
          class="common_btn_next common_btn_next_able"
          @click="save"
        >
          确 定
        </button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import formMixin from '@/utils/mixins/form'
import repeatEnum from '@/utils/enums/repeat' // 周期字典
const customTypeEnum = repeatEnum.CYCLECUSTOMTYPE // 自定义重复类型
export default {
  mixins: [formMixin],
  props: {
    // 页面数据
    row: {
      type: Object,
      required: false
    },
    // 任务id
    taskId: {
      required: false
    },
    // 无需调接口
    noRequest: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      customTypeDict: repeatEnum.CUSTOMTYPE, // 自定义重复类型
      customType: customTypeEnum.daily, // 重复类型(默认每周重复)
      customInterval: 1, // 间隔数(默认为1))
      customCycleNum: [], // 间隔天数后任务重复日期
      day: 31 // 日期天数
    }
  },
  computed: {
    // 是否每天重复
    isDaily() {
      return this.customType === customTypeEnum.daily
    },
    // 是否每周重复
    isWeekly() {
      return this.customType === customTypeEnum.weekly
    },
    // 是否每月重复
    isMonth() {
      return this.customType === customTypeEnum.month
    },
    // 每天重复的文字展示
    dailyText() {
      const customInterval = this.customInterval
      if (customInterval === 1 || !customInterval) {
        return '天'
      } else {
        return `${customInterval}天`
      }
    },
    // 每月重复的文字展示
    monthText() {
      const customInterval = this.customInterval
      if (customInterval === 1 || !customInterval) {
        return '月'
      } else {
        return `${customInterval}月`
      }
    }
  },
  created() {
    // 初始化
    this.init()
  },
  methods: {
    cancelDialog() {
      this.$emit('getCustomType', '')
      this.$dialogClose()
    },
    // 初始化
    init() {
      if (this.row.id) {
        this.form = this.$utils.clone(this.row)
        // 重复类型(默认每周重复)
        console.log('object', this.row)
        if (this.row.cycleDate) {
          if (!this.row.cycleDate.customType) {
            this.customType = customTypeEnum.weekly
          } else {
            this.customType = this.row.cycleDate.customType
          }
          // 间隔数(默认为1))
          if (!this.customInterval) {
            this.customInterval = 1
          } else {
            this.customInterval = this.row.cycleDate.customInterval
          }
          // 间隔天数后任务重复日期(默认为空数组)
          let customCycleNum = this.row.cycleDate.customCycleNum
          if (customCycleNum) {
            customCycleNum = customCycleNum.split(',')
            this.customCycleNum = this.$utils.arrToInt(customCycleNum)
          } else {
            this.customCycleNum = []
          }
        }
      }
    },
    // 保存
    async save() {
      // console.log('保存--', this.row.cycleDate, this.form)
      const path = this.form.cycleDateId ? 'TASKREPEATUPDATE' : 'TASKREPEATADD'
      const obj = {
        customInterval: this.customInterval,
        customType: this.customType,
        taskId: this.taskId,
        customCycleNum: this.customCycleNum.toString(),
        id:
          this.row.cycleDate && this.row.cycleDate.id
            ? this.row.cycleDate.id
            : '',
        type: repeatEnum.CYCLENUMTYPE.custom
      }
      if (!this.noRequest) {
        this.submitDetail(path, obj)
      } else {
        this.$emit('save', obj)
        this.$dialogClose()
      }
    },
    async submitDetail(path, _obj) {
      this.$apis[path](_obj).then((res) => {
        if (res.status === 200) {
          this.loading = false
          if (res.data) {
            this.id = res.data.id
            this.row = res.data
            this.$emit('save', _obj)
            this.$message.success('修改成功')
            this.$dialogClose()
          }
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 重复类型选择
    customTypeChange() {
      this.customInterval = 1
      this.customCycleNum = []
    },
    // 每周重复显示周的选择
    customCycleNumChange() {
      // 选择的数据正序
      this.customCycleNumSort()
    },
    // 每月重复的日期选择
    dayClick(val) {
      const customCycleNum = this.customCycleNum
      const exit = customCycleNum.includes(val)
      if (exit) {
        const index = customCycleNum.findIndex((item) => item === val)
        customCycleNum.splice(index, 1)
      } else {
        customCycleNum.push(val)
      }
      // 选择的数据正序
      this.customCycleNumSort()
    },
    // 选择的数据正序
    customCycleNumSort() {
      // 正序排列
      this.customCycleNum = this.customCycleNum.sort((a, b) => a - b)
    },
    // 是否是最后一个元素
    isLastIndex(index) {
      return index + 1 !== this.customCycleNum.length
    },
    // 每月重复的日期是否选中
    dayActiveClass(val) {
      const exit = this.customCycleNum.includes(val)
      return exit && 'active'
    },
    // 周转成汉字
    weekToCn(val) {
      const map = {
        1: '一',
        2: '二',
        3: '三',
        4: '四',
        5: '五',
        6: '六',
        7: '日'
      }
      return map[val]
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../../../assets/styles/variable.scss';
::v-deep .el-dialog__body {
  padding: 0px 24px 30px 24px !important;
}
.select-list {
  justify-content: space-between;
}
.day-box {
  border-top: 1px solid #dedede;
  border-left: 1px solid #dedede;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  width: 246px;
  .item {
    border-bottom: 1px solid #dedede;
    border-right: 1px solid #dedede;
    cursor: pointer;
    text-align: center;
    height: 35px;
    line-height: 35px;
    width: 35px;
  }
  .active {
    background-color: #0d84eb;
    color: #fff;
  }
}
.footer {
  button {
    width: 24%;
  }
  .cancel-btns {
    height: 40px;
    width: 120px;
    border-radius: 18px;
    margin-right: 14px;
  }
  .common_btn_next {
    height: 40px;
    line-height: 40px;
    padding: 0;
    width: 120px;
    border-radius: 18px;
  }
}
</style>
