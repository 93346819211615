import INDEX from '../../views/global/mobile/index.vue'
export default [
  {
  path: '/mobile',
  redirect: '/mobile/successNotice',
  name: 'Mobile',
  component: INDEX
  // children: [
  //   {
  //     path: '/mobile/successNotice',
  //     name: 'successNotice',
  //     component: () => {
  //       return import(
  //         /* webpackChunkName: "mobile" */ '../../views/global/mobile/index.vue'
  //       )
  //     }
  //   }
  // ]
},
{
  path: '/mobile/pay',
  name: 'pay',
  component: () => {
    return import(
      /* webpackChunkName: "mobilePay" */
      '../../views/global/mobile/pay.vue'
    )
  }
},
{
  path: '/mobile/courseOverview',
  name: 'courseOverview',
  component: () => {
    return import(
      /* webpackChunkName: "mobileCourseOverview" */
      '../../views/global/mobile/courseOverview.vue'
    )
  }
}
]
