<template>
  <div class="detail-tabs">
    <div
      class="box-select-li"
      :class="activeTab == 1 ? 'box-select-li-style' : ''"
      @click="getTargetSelect(1)"
    >
      <Icon icon-class="icon-task" class="kr-right-icon" />
      <div class="box-select-li-text box-select-li-color">任务</div>
    </div>
    <div
      class="box-select-li clearfix"
      :class="activeTab == 2 ? 'box-select-li-style' : ''"
      @click="getTargetSelect(2)"
    >
      <Icon icon-class="icon-comment" class="kr-right-icon" />
      <el-image
        fit="cover"
        v-if="commentUserAvatar"
        class="kr-right-avatar border-radius-20"
        :src="commentUserAvatar"
      />
      <div
        v-if="showCommentRedpoint"
        class="common-red-point"
        :class="commentUserAvatar ? '' : 'no-avatar-point'"
      ></div>
      <div class="box-select-li-text box-select-li-color">动态</div>
    </div>
    <div
      class="box-select-li clearfix"
      :class="activeTab == 3 ? 'box-select-li-style' : ''"
      @click="getTargetSelect(3)"
    >
      <!-- <mini-process class="box-li-icon box-select-li-icon3" /> -->
      <Icon icon-class="icon-jindu1" class="kr-right-icon" />
      <div class="box-select-li-text box-select-li-color">进度</div>
    </div>
    <div
      class="box-select-li"
      :class="activeTab == 4 ? 'box-select-li-style' : ''"
      @click="getTargetSelect(4)"
    >
      <Icon icon-class="icon-wenjian3" class="kr-right-icon" />
      <div class="box-select-li-text box-select-li-color">文件</div>
    </div>
  </div>
</template>
<script>
import miniProcess from '../../../components/singleOkrItem/miniProcess'
export default {
  components: {
    miniProcess
  },
  model: {
    prop: 'activeTab',
    event: 'change'
  },
  props: {
    activeTab: {
      type: Number,
      default: 1
    },
    commentUserAvatar: {
      type: String
    },
    showCommentRedpoint: {
      type: Boolean
    }
  },
  data() {
    return {}
  },
  methods: {
    // tab页选择
    getTargetSelect(type) {
      this.$emit('change', type)
    }
  }
}
</script>
<style lang="less" scoped>
.detail-tabs {
  width: 100%;
  height: 48px;
  display: flex;
  border-bottom: 1px solid #d9dbe9;
  .box-select-li {
    height: 100%;
    margin: 0 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    color: #6e7491;
    &:hover {
      color: #14142b;
    }
    .kr-right-icon {
      color: #6e7491;
      font-size: 20px !important;
    }
    .kr-right-avatar {
      position: absolute;
      top: 3px;
      left: 12px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
    }
    .box-select-li-icon3 {
      width: 20px;
      height: 8px;
    }
    .box-select-li-text {
      height: 100%;
      font-size: 14px;
      line-height: 48px;
      margin-left: 8px;
    }
    .common-red-point {
      position: absolute;
      box-sizing: border-box;
      width: 8px;
      height: 8px;
      background: #ed2e7e;
      border: 2px solid #fcfcfc;
      border-radius: 50%;
      left: 23px;
      top: 2px;
    }
    .no-avatar-point {
      width: 10px;
      height: 10px;
      left: 15px;
      top: 12px;
    }
  }

  .box-select-li-style {
    border-bottom: 3px solid #005eff;
    color: #14142b;
  }
}
</style>
