<template>
  <div
    v-if="InitializeDates != null && state"
    class="top_progress clearfix"
    :class="
      topMembersState === '2'
        ? `top_progress_style key-number-${keyNumberVisible}`
        : `key-number-${keyNumberVisible}`
    "
  >
    <slot></slot>
    <el-progress
      v-if="InitializeDates.progressRete > 0 && InitializeDates.progressRete <= 100"
      :percentage="InitializeDates.progressRete"
      :color="stateObj[InitializeDates.status] && stateObj[InitializeDates.status].color || '#005EFF'"
      :text-inside="true"
    />
    <!-- 进度大于100特殊处理 -->
    <div
      v-else-if="InitializeDates.progressRete > 100"
      class="process-zero-bar-outer process-max-number"
    >
      <div class="process-zero-bar-inner process-max-number-inner">{{InitializeDates.progressRete}}%</div>
    </div>
    <!-- 进度为0%特殊处理 -->
    <div v-else class="process-zero-bar-outer">
      <div class="process-zero-bar-inner">{{InitializeDates.progressRete}}%</div>
    </div>

    <div v-if="keyNumberVisible" class="top_progress_text">
      KR
      <div
        class="top_progress_text_radius"
        :class="
          InitializeDates.status === 0
            ? 'top_progress_text_radius_color1'
            : InitializeDates.status === 1
            ? 'top_progress_text_radius_color2'
            : InitializeDates.status === 2
            ? 'top_progress_text_radius_color3'
            : 'top_progress_text_radius_color4'
        "
      >
        {{
        topMembersState === '4'
        ? InitializeDates.keyCount
        : InitializeDates.keyresultsList.length
        }}
      </div>
    </div>
    <div class="li_box1_schedule_suspension">
      <div
        class="li_box1_schedule_suspension_box"
        :class="`li_box1_schedule_suspension_bng${InitializeDates.status}`"
      >
        <Icon :icon-class="`icon-tip-box${InitializeDates.status}`" class="tip-icon" />
        <div class="tip-text">
          {{
          InitializeDates.status >= 0
          ? stateObj[InitializeDates.status].txt
          : `暂无说明`
          }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    topMembersBeans: {
      type: Object,
      required: false,
      default: () => {
        return {
          keyresultsList: []
        }
      }
    },
    topMembersState: {
      type: String,
      required: false,
      default: '1' // 1.目标详情，2.目标hover列表 3.目标列表 4.目标地图列表
    },
    keyNumberVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      InitializeDates: null,
      state: false,
      stateObj: {
        0: {
          txt: '进度正常',
          color: '#005EFF'
        },
        1: {
          txt: '进度超预期',
          color: '#00BA88'
        },
        2: {
          txt: '进度预警',
          color: '#ff9900'
        },
        3: {
          txt: '未在进度',
          color: '#ED2E7E'
        }
      }
    }
  },
  watch: {
    topMembersBeans: {
      handler(val) {
        this.InitializeDates = this.topMembersBeans
      }
    }
  },
  mounted() {
    this.state = true
  },
  created() {
    const that = this
    if (this.topMembersState === '1') {
      // 详情
      this.bus(this).$on('InitializeData', (magDate) => {
        that.InitializeDates = magDate
      })
    } else if (
      this.topMembersState === '2' ||
      this.topMembersState === '3' ||
      this.topMembersState === '4'
    ) {
      // 目标列表
      this.InitializeDates = this.topMembersBeans
    }
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
// .el-progress-circle__track {
//   stroke: #eee;
// }
.key-number-true {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.top_progress {
  position: relative;
  // border: 1px solid red;
  .top_progress_text {
    height: 100%;
    line-height: 30px;
    font-size: 20px;
    font-weight: 700;
    text-align: LEFT;
    color: #6e7191;
    margin: 0px 20px 0px 10px;
    position: relative;
    .top_progress_text_radius {
      width: 18px;
      height: 18px;
      border-radius: 100% 100%;
      position: absolute;
      right: -15px;
      top: 0px;
      text-align: center;
      line-height: 100%;
      font-size: 14px;
      text-align: center;
      line-height: 18px;
      color: #ffffff;
    }
    .top_progress_text_radius_color1 {
      background: #005eff;
    }
    .top_progress_text_radius_color2 {
      background: #00ba88;
    }
    .top_progress_text_radius_color3 {
      background: #ff9900;
    }
    .top_progress_text_radius_color4 {
      background: #ed2e7e;
    }
  }
  .li_box1_schedule_suspension {
    width: 270px;
    height: 20px;
    position: absolute;
    top: 4px;
    left: 0;
    .li_box1_schedule_suspension_box {
      width: 94px;
      height: 38px;
      position: absolute;
      left: 50%;
      margin-left: -47px;
      top: -36px;
      text-align: center;
      line-height: 32px;
      font-size: 14px;
      font-weight: 500;
      display: none;
      .tip-icon {
        font-size: 94px;
        position: absolute;
        left: 0;
        top: -36px;
      }
      .tip-text {
        position: absolute;
        width: 100%;
        text-align: center;
        top: -8px;
      }
    }
    .li_box1_schedule_suspension_bng0 {
      color: #005eff;
    }
    .li_box1_schedule_suspension_bng1 {
      color: #00ba88;
    }
    .li_box1_schedule_suspension_bng2 {
      color: #ff9900;
    }
    .li_box1_schedule_suspension_bng3 {
      color: #ed2e7e;
    }
  }
  .li_box1_schedule_suspension:hover {
    .li_box1_schedule_suspension_box {
      display: block;
    }
  }

  .process-zero-bar-outer {
    width: 100%;
    max-width: 270px;
    height: 20px;
    background: #ebeef5;
    border-radius: 100px;
    .process-zero-bar-inner {
      background: #a0a3bd;
      border-radius: 100px;
      width: 40px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      color: #fff;
      font-size: 12px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    .process-max-number-inner {
      width: 100%;
      background: #00ba88;
      text-align: right;
      padding-right: 8px;
    }
  }
}
.top_process_style {
  .process-zero-bar-outer {
    width: 100%;
    max-width: 270px;
    height: 20px;
    margin-top: 11px;
  }
}
.top_progress ::v-deep .el-progress {
  width: 100%;
  max-width: 270px;
  height: 20px;
  float: left;
  // margin-top: 6px;
  line-height: 20px;
}
.top_progress ::v-deep .el-progress-bar__outer {
  height: 18px !important;
}
.top_progress ::v-deep .el-progress-bar__innerText {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #fff !important;
}
.top_progress_style ::v-deep .el-progress-bar__innerText {
  line-height: 12px;
  margin: -4px 5px 0 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.top_progress_style ::v-deep .el-progress {
  width: 270px;
  height: 20px;
  float: left;
  margin-top: 11px;
}
.top_progress_style ::v-deep .el-progress-bar__outer {
  height: 14px !important;
}
</style>
