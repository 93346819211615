<template>
  <div>
    <el-drawer
      :visible.sync="okrShareVisible"
      direction="rtl"
      :before-close="handleClose"
      :append-to-body="true"
      :size="584"
      :modal-append-to-body="false"
      class="okr-share-drawer"
    >
      <div slot="title" class="dialog-header">
        <div class="dialog-title">目标共享人（{{ okrShareList.length || 0 }}）</div>
        <div class="dialog-button" v-if="!disabled">
          <user-picker-checked
            :select-people-list="chargeUserListNew"
            :checked="true"
            :length="40"
            :pageFrom="'okrShare'"
            :createdUser="okrShareList.length > 0 ? okrShareList[0].userId : ''"
            @pick="chargeUserPicker"
          >
            <Icon icon-class="icon-a-tianjia2" class="cooperation-add" />
          </user-picker-checked>
          <!-- <Icon icon-class="icon-a-tianjia2" class="okr-operate" /> -->
        </div>
      </div>
      <div class="share-dialog-div">
        <div class="okr-share-div" v-if="!isShowDetails">
          <div
            class="okr-share-list"
            v-for="(item, index) in okrShareList"
            :key="index"
            @click="showDetails(item)"
          >
            <div class="share-title">
              <div class="title-left">
                <div class="avatar-div">
                  <el-image
                    fit="cover"
                    class="avatar-img border-radius-20"
                    :src="item.user.avatar || require('@/assets/imgs/common/avatar.png')"
                    alt
                    v-if="item.user.avatar"
                  />
                  <el-image
                    fit="cover"
                    :src="require('@/assets/imgs/common/avatar.png')"
                    class="avatar-img border-radius-20"
                    v-else
                  />
                </div>
                <div class="username">
                  <wx-open-data :id="item.user.name" :nameData="{name:item.user.name}" />
                </div>

                <div class="okr-creator" v-if="index == 0">目标创建人</div>
                <div class="data-row data-row-top" v-if="item.objProgressRete">
                  推动目标进度提升
                  <span>{{ item.objProgressRete ? item.objProgressRete : 0 }}</span>
                  %
                </div>
              </div>
            </div>
            <div class="data-row" v-if="item.keyProgressCount">
              更新KR进度总次数
              <span>{{ item.keyProgressCount ? item.keyProgressCount : 0 }}</span>
              次
            </div>
            <div class="data-row-task-div">
              <div class="task-row">
                待处理任务
                <span>
                  {{
                  item.task.taskPendingCount ? item.task.taskPendingCount : 0
                  }}
                </span>
                项
              </div>
              <div class="task-row">
                进行中任务
                <span>
                  {{
                  item.task.taskConductCount ? item.task.taskConductCount : 0
                  }}
                </span>
                项
              </div>
              <div class="task-row">
                完成任务
                <span>
                  {{
                  item.task.taskFinishCount ? item.task.taskFinishCount : 0
                  }}
                </span>
                项
              </div>
              <div class="task-row">
                预期未完成任务
                <span>
                  {{
                  item.task.taskProgressCount ? item.task.taskProgressCount : 0
                  }}
                </span>
                项
              </div>
            </div>
          </div>
        </div>
        <div class="okr-share-details" v-if="isShowDetails">
          <div class="details-return" @click="returnlist">
            <Icon icon-class="icon-xiangzuo" class="return-icon" />返回
          </div>
          <div class="details-operation">
            <div class="select-div">
              <img
                v-show="userAvatar"
                class="select-img border-radius-20"
                :src="userAvatar || require('@/assets/imgs/common/avatar.png')"
                style="
                  width: 30px;
                  min-width: 30px;
                  height: 30px;
                  margin-right: 10px;
                  border-radius: 50%;
                "
              />

              <el-select
                v-model="userValue"
                placeholder="请输入"
                :popper-append-to-body="true"
                class="user-select"
                popper-class="user-popper-select"
                @change="selUser"
              >
                <el-option
                  :label="item.userName"
                  :value="item.userId"
                  v-for="item in userOptions"
                  :key="item.userId"
                >
                  <div style="display: flex; align-items: center; min-width: 0">
                    <div v-if="item.avatar" @click.stop style="display: flex; align-items: center">
                      <img
                        :src="item.avatar || require('@/assets/imgs/common/avatar.png')"
                        style="
                          width: 30px;
                          min-width: 30px;
                          height: 30px;
                          margin-right: 10px;
                          border-radius: 50%;
                        "
                        class="border-radius-20"
                      />
                    </div>
                    <div class="label-text">
                      <wx-open-data :id="item.userName" :nameData="{name:item.userName}" />
                    </div>
                  </div>
                </el-option>
                <slot slot="prefix">
                  <wx-open-data :id="selUserName" :nameData="{name:selUserName}" />
                </slot>
              </el-select>
            </div>
            <el-select
              class="dynamic-select"
              v-model="dynamicType"
              placeholder="请选择"
              @change="changeType"
            >
              <el-option
                v-for="item in dynamicOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div
            class="details-dynamic-content"
            v-loading="loading"
            v-scroll-bottom="loadMore"
            v-if="dynamicList.length > 0"
          >
            <div class="details-dynamic-list" v-for="(item, index) in dynamicList" :key="index">
              <div class="details-dynamic-time">{{ item.time }}</div>
              <div
                class="details-dynamic-row"
                v-for="(itemc, indexc) in item.content"
                :key="indexc"
              >
                <div class="dynamic-main">
                  <div class="main-top">
                    <div class="img-avatar">
                      <img
                        :src="itemc.user.avatar || require('@/assets/imgs/common/avatar.png')"
                        alt
                        class="border-radius-20"
                      />
                    </div>
                    <div class="dynamic-text">
                      <span class="created-name">
                        <wx-open-data :id="itemc.user.name" :nameData="{name:itemc.user.name}" />
                      </span>
                      {{ itemc.dynamicContent }}
                    </div>
                    <div class="dynamic-created">{{ $utils.globalTime(itemc.createDate) }}</div>
                  </div>
                  <div class="task-div">
                    <!-- <span class="task-line"></span> -->
                    “{{ itemc.businessTitle }}”
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="no-data-div">
            <no-data text="暂无动态" />
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import noData from '@/views/okr/components/noData.vue'
import { mapState } from 'vuex'
export default {
  components: {
    noData
  },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    objId: {
      type: String
    },
    visible: {
      type: Boolean,
      default: false
    },
    // okrData: {
    //   type: Object
    // },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      okrShareVisible: this.visible,
      chargeUserListNew: [],
      okrShareList: [],
      userValue: 0,
      userAvatar: '',
      selUserName:"",
      userOptions: [
        // {
        //   label: '213213213213213213213213213213213213213213213',
        //   value: '阿胶哦我佛偈饿哦分',
        //   avatar: require('../../../../assets/imgs/okr/iscreator.png')
        // }
      ],
      dynamicType: 0,
      dynamicOptions: [
        {
          label: '全部动态',
          value: 0
        },
        {
          label: 'KR',
          value: 1
        },
        {
          label: '任务',
          value: 2
        }
      ],
      isShowDetails: false, // 是否显示详情
      dynamicList: [
        // {
        //   time: '本周',
        //   content: [
        //     {
        //       avatar: require('../../../../assets/imgs/okr/iscreator.png'),
        //       name: '薛风云',
        //       text: '更新了任务状态为「进行中」更新了任务状态为今夕金',
        //       created: '2022年01月06日 18:00',
        //       taskName: '任务名称xxxx'
        //     }
        //   ]
        // },
        // {
        //   time: '本周',
        //   content: [
        //     {
        //       avatar: require('../../../../assets/imgs/okr/iscreator.png'),
        //       name: '薛风云',
        //       text: '更新了任务状态为「进行中」更新了任务状态为今夕金',
        //       created: '2022年01月06日 18:00',
        //       taskName: '任务名称xxxx'
        //     }
        //   ]
        // }
      ],
      dynamicListArr: [],
      pageNum: 0,
      pageSize: 10,
      total: 0,
      loading: false,
      refreshState: false
    }
  },
  watch: {
    visible(val) {
      this.okrShareVisible = val
      if (this.okrShareVisible) {
        this.getOkrShareList()
      }
    }
  },
  mounted() {
  },
  methods: {
    loadMore() {
      if (this.dynamicListArr.length < this.total) {
        this.getOkrShareDynamic(this.userValue, this.dynamicType, 'loadMore')
      }
    },
    changeType(val) {
      this.dynamicType = val
      this.pageNum = 0
      this.pageSize = 10
      this.total = 0
      this.getOkrShareDynamic(this.userValue, this.dynamicType)
    },
    selUser(userId) {
      this.pageNum = 0
      this.pageSize = 10
      this.total = 0
      const selItem = this.userOptions.find((item) => {
        return item.userId == userId
      })
      this.userAvatar = selItem.avatar
      this.userValue = selItem.userId
      this.selUserName= selItem.userName
      this.getOkrShareDynamic(this.userValue, this.dynamicType)
    },
    returnlist() {
      this.isShowDetails = false
    },
    showDetails(item) {
      this.userValue = item.user.id
      this.userAvatar = item.user.avatar
      this.selUserName= item.user.name
      this.isShowDetails = true
      this.dynamicType = 0
      this.pageNum = 0
      this.pageSize = 10
      this.total = 0
      this.getOkrShareDynamic(this.userValue)
    },
    getOkrShareDynamic(userId, dynamicType, type) {
      this.loading = true
      if (type === 'loadMore') {
        this.pageNum++
      } else {
        this.pageNum = 1
      }
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        objId: this.objId,
        userId: userId != 0 ? userId : null,
        businessType: dynamicType != 0 ? dynamicType : null //1：kr,2:任务 全选不传
      }
      this.$apis.OKRSHAREDYNAMIC(params).then((res) => {
        if (res.status === 200) {
          var d = new Date()
          var year = d.getFullYear() //获取年
          var month = d.getMonth() + 1 //获取月，从 Date 对象返回月份 (0 ~ 11)，故在此处+1
          var day = d.getDay() //获取日
          var days = d.getDate() //获取日期
          var todayTime =
            year +
            '-' +
            (month < 10 ? '0' + month : month) +
            '-' +
            (days < 10 ? '0' + days : days)
          if (type === 'loadMore') {
            res.data.rows.forEach((item) => {
              this.dynamicListArr.push(item)
            })
          } else {
            this.dynamicListArr = res.data.rows
          }

          this.dynamicListArr.forEach((item, index) => {
            this.dynamicListArr[index]['time'] = this.getDatys(
              item.createDate,
              todayTime
            )
          })
          let tempArr = []
          let Data = []
          for (let i = 0; i < this.dynamicListArr.length; i++) {
            if (tempArr.indexOf(this.dynamicListArr[i].time) === -1) {
              Data.push({
                time: this.dynamicListArr[i].time,
                content: [this.dynamicListArr[i]]
              })
              tempArr.push(this.dynamicListArr[i].time)
            } else {
              for (let j = 0; j < Data.length; j++) {
                if (Data[j].time == this.dynamicListArr[i].time) {
                  Data[j].content.push(this.dynamicListArr[i])
                  break
                }
              }
            }
          }
          this.dynamicList = Data
          this.total = res.data.total
          this.loading = false
        } else {
          this.$message.warn(res.message)
          this.loading = false
        }
      })
    },
    getOkrShareList() {
      this.$apis.OKRSHARELIST(this.objId).then((res) => {
        if (res.status === 200) {
          this.userOptions = [
            {
              userName: '所有人',
              userId: 0
            }
          ]
          this.okrShareList = res.data
          //  筛选最佳贡献者
          // let b = Math.max.apply(
          //   Math,
          //   this.okrShareList.map((item, index) => {
          //     return item.objProgressRete
          //   })
          // )
          // for (var keys in this.okrShareList) {
          //   if (this.okrShareList[keys].objProgressRete == b) {
          //     console.log(this.okrShareList[keys])
          //     this.okrShareList[keys]['bigPay'] = 1
          //   }
          // }
          // console.log(1111, this.okrShareList)
          res.data.forEach((item) => {
            this.userOptions.push({
              userId: item.userId,
              userName: item.user.name,
              avatar: item.user.avatar
            })
          })
          this.chargeUserListNew = []
          res.data.forEach((item) => {
            this.chargeUserListNew.push(item.user)
          })
        } else {
          this.$message.warn(res.message)
        }
      })
    },
    handleClose() {
      this.okrShareVisible = false
      this.isShowDetails = false
      this.$emit('change', false)
      this.$emit('refresh', this.refreshState)
    },
    // 协作人选择
    async chargeUserPicker(row) {
      var chargeUserIdList = []
      // if (row.length > 40) {
      //   this.$message({
      //     msg: '最多可以选择 40 个协作人哦!',
      //     type: 'warn'
      //   })
      //   return
      // }
      if (row.length > 0) {
        row.forEach((item, index) => {
          chargeUserIdList.push(item.id)
        })
      }
      const params = {
        objId: this.objId,
        userIdList: chargeUserIdList
      }
      this.$apis.CHANGEOKRSHARE(params).then((res) => {
        if (res.status === 200) {
          this.$message.success(res.message)
          this.getOkrShareList()
          this.refreshState = true
        } else {
          this.$message.warn(res.message)
        }
      })
    },
    getTime(n) {
      var now = new Date()
      var year = now.getFullYear()
      //因为月份是从0开始的,所以获取这个月的月份数要加1才行
      var month = now.getMonth() + 1
      var date = now.getDate()
      var day = now.getDay()
      //判断是否为周日,如果不是的话,就让今天的day-1(例如星期二就是2-1)
      if (day !== 0) {
        n = n + (day - 1)
      } else {
        n = n + day
      }
      if (day) {
        //这个判断是为了解决跨年的问题
        if (month > 1) {
          month = month
        }
        //这个判断是为了解决跨年的问题,月份是从0开始的
        else {
          year = year - 1
          month = 12
        }
      }
      now.setDate(now.getDate() - n)
      year = now.getFullYear()
      month = now.getMonth() + 1
      date = now.getDate()
      var s =
        year +
        '-' +
        (month < 10 ? '0' + month : month) +
        '-' +
        (date < 10 ? '0' + date : date)
      return s
    },
    getDatys(createdTime, todayTime) {
      /***参数都是以周一为基准的***/
      //上周的开始时间
      var prevWeekStart = this.getTime(7)
      //上周的结束时间
      var prevWeekEnd = this.getTime(1)
      //本周的开始时间
      var currentWeekStart = this.getTime(0)
      //本周的结束时间
      var currentWeekEnd = this.getTime(-6)
      var date = createdTime.substring(0, 10)
      var string
      if (date == todayTime) {
        string = '今天'
      } else if (
        Date.parse(createdTime) > Date.parse(currentWeekStart) &&
        Date.parse(createdTime) < Date.parse(currentWeekEnd) &&
        date != todayTime
      ) {
        string = '本周'
      } else if (
        Date.parse(createdTime) > Date.parse(prevWeekStart) &&
        Date.parse(createdTime) < Date.parse(prevWeekEnd)
      ) {
        string = '上周'
      } else if (Date.parse(createdTime) < Date.parse(prevWeekStart)) {
        string = '更早'
      }
      return string
    }
  }
}
</script>
<style scoped lang="less">
@import '../../../../assets/styles/dialog.less';
::v-deep {
  .el-input__prefix {
    left: 0px;
    width: 100%;
    text-align: left;
    line-height: 40px;
    background: #fff;
    color: #14142b;
  }
  .no-data-show .no-data-show-icon {
    width: 120px !important;
  }
  .no-data-show .no-data-show-text {
    margin-top: 0 !important;
  }
  .label-text {
    width: 140px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
  }
  .dynamic-select.el-select {
    width: 98px;
    .el-input__inner,
    .el-input__inner:hover {
      color: #3a78f6 !important;
      width: 58px;
      border: none !important;
      padding: 0 !important;
    }
  }
  .user-select.el-select {
    width: 100%;
    display: flex;
    flex-direction: row;
    .el-input__inner,
    .el-input__inner:hover {
      width: 110px;
      border: none !important;
      padding: 0 !important;
    }
  }
  .marge-img-box .el-dialog__header {
    padding: 32px 32px 0 32px !important;
  }
  .preview-null {
    margin: 5% auto;
    text-align: center;
    .file-svg {
      font-size: 88px;
      width: 88px;
      height: 88px;
      margin: 0 auto;
    }
    p {
      margin-top: 10px;
      font-weight: 500;
      text-align: CENTER;
      color: #6e7491;
      line-height: 24px;
      font-size: 14px;
    }
  }
  .okr-share-drawer {
    .el-drawer {
      width: 584px !important;
    }
  }
  .el-dialog__title {
    display: inline-block;
    width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
  }
  .marge-img-box .el-dialog {
    width: 80%;
    margin: 52px 10% !important;
  }
  .el-drawer__close-btn {
    display: none !important;
  }
  .el-drawer__header {
    padding: 10px 24px !important;
    border-bottom: 1px #eaebf3 solid;
  }

  .batch-upload-button.el-button {
    width: 77px;
    height: 32px;
    background: #3a78f6 !important;
    border-color: #3a78f6 !important;
    border-radius: 16px;
    &:hover,
    &:active,
    &:focus {
      background: rgba(58, 120, 246, 0.87) !important;
      border-color: rgba(58, 120, 246, 0.87) !important;
    }
  }
}
.dialog-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .dialog-title {
    line-height: 32px;
    font-size: 20px;
    color: #14142b;
    font-weight: 700;
  }
  .dialog-button {
    padding-right: 14px;
    .cooperation-add {
      color: #3a78f6;
      font-size: 20px;
      cursor: pointer;
    }
  }
}
.share-dialog-div {
  height: calc(100vh - 60px);
  padding: 12px;
  .okr-share-div {
    .okr-share-list {
      background: #f5f6fa;
      border-radius: 16px;
      border: 1px #f5f6fa solid;
      padding: 16px 16px 16px 16px;
      margin-top: 8px;
      .share-title {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-bottom: 12px;
        align-items: center;
        justify-content: space-between;
        .title-left {
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
          .avatar-div {
            .avatar-img {
              width: 32px;
              height: 32px;
              border-radius: 50%;
              margin-right: 12px;
            }
            // .contributor-img {
            //   width: 32px;
            //   height: 32px;
            //   position: absolute;
            //   top: -10px;
            //   right: 0em;
            // }
          }
          .username {
            font-weight: bold;
            color: #444558;
            font-size: 16px;
            margin-right: 4px;
          }
          .okr-creator {
            text-align: center;
            width: 89px;
            height: 24px;
            line-height: 22px;
            background: rgba(110, 116, 145, 0.06);
            border-radius: 8px 8px 8px 8px;
            border: 1px solid rgba(110, 116, 145, 0.24);
            font-size: 14px;
            font-weight: 400;
            color: #6e7491;
            margin-left: 8px;
          }
          .share-sign {
            text-align: center;
            width: 89px;
            height: 24px;
            line-height: 22px;
            background: rgba(195, 0, 82, 0.06);
            border-radius: 8px 8px 8px 8px;
            font-size: 14px;
            font-weight: 400;
            color: #ed2e7e;
            border: 1px solid rgba(237, 46, 126, 0.24);
            margin-left: 8px;
          }
        }
        .title-right {
          // min-width: 80px;
          width: 110px;
          text-align: left;
          font-size: 12px;
          font-weight: 400;
          color: #ff9900;
          span {
            display: inline-block;
            margin-left: 8px;
            font-size: 28px;
            font-weight: bold;
            color: #ff9900;
          }
        }
      }
      .data-row {
        margin-left: 45px;
        font-size: 14px;
        font-weight: 400;
        color: #444558;
        line-height: 16px;
        margin-bottom: 6px;
      }
      .data-row-top {
        margin-left: 16px;
        margin-bottom: 0;
      }
      .data-row-task-div {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 45px;
        margin-top: 6px;
        .task-row {
          flex: 0 0 50%;
          font-size: 14px;
          font-weight: 400;
          color: #444558;
          line-height: 16px;
          margin-bottom: 6px;
        }
      }
      &:hover {
        border: 1px solid rgba(58, 120, 246, 0.11999999731779099);
        background: rgba(58, 120, 246, 0.07999999821186066);
        .data-row,
        .task-row {
          span {
            color: #3a78f6;
          }
        }
      }
    }
  }
}
.okr-share-details {
  .details-return {
    width: 60px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    color: #6e7491;
    height: 34px;
    line-height: 34px;
    align-items: center;
    .return-icon {
      font-size: 28px;
      margin-right: -4px;
      margin-top: 2px;
    }
  }
  .details-operation {
    margin-top: 16px;
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .select-div {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 200px;
      border: 1px solid #f5f6fa;
      border-radius: 10px;
      padding-left: 10px;
    }
  }
  .details-dynamic-content {
    padding: 0 10px;
    margin-top: 20px;
    height: calc(100vh - 200px);
    overflow-y: auto;
    .details-dynamic-list {
      .details-dynamic-time {
        font-size: 16px;
        font-weight: bold;
        color: #000000;
        margin: 16px 0 14px;
      }
      .details-dynamic-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        .img-avatar {
          width: 30px;
          height: 30px;
          margin-right: 8px;
          img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
          }
        }
        .dynamic-main {
          flex: 1;
          min-width: 0;
          .main-top {
            display: flex;
            flex-direction: row;
            align-items: center;
            .dynamic-text {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              word-break: break-all;
              color: #6e7491;
              font-size: 14px;
              flex: 1;
              .created-name {
                display: inline-block;
                font-size: 14px;
                font-weight: 500;
                color: #14142b;
                margin-right: 8px;
              }
            }
          }
          .task-div {
            // margin-top: 6px;
            color: #444558;
            font-size: 14px;
            font-weight: 400;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            word-break: break-all;
            height: 38px;
            line-height: 38px;
            padding-left: 40px;
            margin-top: 4px;
            .task-line {
              display: inline-block;
              width: 2px;
              height: 10px;
              background: #3a78f6;
              margin-right: 8px;
            }
            &:hover {
              background: #f5f6fa;
              border-radius: 8px;
              height: 38px;
              line-height: 38px;
            }
          }
        }
        .dynamic-created {
          min-width: 120px;
          width: 120px;
          margin-left: 8px;
          text-align: right;
          color: #6e7491;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
  .no-data-div {
    height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -40px;
  }
}
</style>

<style>
.el-select-dropdown.el-popper.user-popper-select {
  margin-left: -50px !important;
  width: 200px !important;
}
</style>
