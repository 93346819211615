var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "添加工作任务",
        visible: _vm.dialogVisible,
        width: "640px",
        "show-close": false,
        "custom-class": "add-task-dialog",
        "append-to-body": true,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.cancle,
      },
    },
    [
      _c(
        "div",
        { staticClass: "task-title", attrs: { slot: "title" }, slot: "title" },
        [
          _c(
            "div",
            { staticClass: "task-title-left" },
            [
              _c("span", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.propsAddObj && _vm.propsAddObj.taskType
                        ? "添加" + _vm.propsAddObj.taskType
                        : "创建"
                    ) +
                    " "
                ),
              ]),
              _c("ClassifySelect", {
                attrs: {
                  "is-son-task":
                    _vm.propsAddObj.taskType &&
                    _vm.propsAddObj.taskType == "子任务"
                      ? true
                      : false,
                  source:
                    _vm.propsAddObj.formPage == "pm"
                      ? "pm"
                      : this.taskAddObject.type == 1
                      ? "kr"
                      : "group",
                },
                on: {
                  change: function ($event) {
                    return _vm.queryTaskParams($event, false)
                  },
                },
                model: {
                  value: _vm.classifytype,
                  callback: function ($$v) {
                    _vm.classifytype = $$v
                  },
                  expression: "classifytype",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "adver-wrap" },
            [
              _c("advertisement", {
                attrs: { type: "addTask", "style-type": 2 },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "add-task-bd-textarea" },
        [
          _c("el-input", {
            attrs: {
              autosize: { minRows: 4, maxRows: 5 },
              type: "textarea",
              size: "medium",
              placeholder:
                "请输入具体的工作任务(例：每天拜访50个客户/每周邀约面试30人)",
            },
            on: {
              input: function ($event) {
                return _vm.judge(false)
              },
            },
            nativeOn: {
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.enterToBlur.apply(null, arguments)
              },
            },
            model: {
              value: _vm.form.title,
              callback: function ($$v) {
                _vm.$set(_vm.form, "title", $$v)
              },
              expression: "form.title",
            },
          }),
        ],
        1
      ),
      _c(
        "ul",
        { staticClass: "task-info" },
        [
          _c("li", { staticClass: "task-info-item" }, [
            _c(
              "div",
              {
                staticClass: "describe",
                class: _vm.moreParamShow ? "" : "simple-descirbe",
              },
              [
                _c("Icon", {
                  staticClass: "m-r-10 svg-icons",
                  attrs: { slot: "title", "icon-class": "icon-fuzeren1" },
                  slot: "title",
                }),
                _vm.moreParamShow
                  ? _c("span", { staticClass: "pointer" }, [_vm._v("负责人")])
                  : _vm._e(),
                _vm.sysParamInfo["executor"] &&
                _vm.sysParamInfo["executor"].required === 0
                  ? _c("span", { staticClass: "required" }, [_vm._v("*")])
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "originator-box",
                class: _vm.originatorHover ? "originator-box-hover" : "",
                on: {
                  mouseover: function ($event) {
                    _vm.isColorCloseIcon = false
                  },
                  mouseout: function ($event) {
                    _vm.isColorCloseIcon = true
                  },
                },
              },
              [
                _c("user-picker", { on: { pick: _vm.toriginatorPicker } }, [
                  _c("div", { staticClass: "charge-person" }, [
                    _vm.originatorName === null
                      ? _c("div", [
                          _c(
                            "span",
                            { staticClass: "head-icon-label" },
                            [
                              _vm.moreParamShow
                                ? _c("Icon", {
                                    staticClass: "avatar-icon border-radius-20",
                                    attrs: {
                                      "icon-class": "icon-kong-touxiang",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v("待认领 "),
                            ],
                            1
                          ),
                        ])
                      : _c(
                          "div",
                          [
                            _c("el-image", {
                              staticClass: "charge-avatar",
                              attrs: {
                                src:
                                  _vm.originatorAvatar ||
                                  require("@/assets/imgs/common/advar.png"),
                              },
                            }),
                            _c(
                              "span",
                              { staticClass: "p-l-5" },
                              [
                                _c("wx-open-data", {
                                  attrs: {
                                    id: _vm.originatorName,
                                    "name-data": { name: _vm.originatorName },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                  ]),
                ]),
                _vm.originatorName !== null
                  ? _c("Icon", {
                      staticClass: "close-icon",
                      attrs: {
                        "icon-class": _vm.isColorCloseIcon
                          ? ""
                          : "icon-a-Group719-1",
                      },
                      nativeOn: {
                        mouseenter: function ($event) {
                          _vm.originatorHover = true
                        },
                        mouseleave: function ($event) {
                          _vm.originatorHover = false
                        },
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.clearToriginatorPicker(
                            _vm.sysParamInfo["executor"]
                          )
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm.classifytype !== "todo"
            ? _c(
                "li",
                { staticClass: "task-info-item task-info-item-between" },
                [
                  _c("div", { staticClass: "charge-user-box" }, [
                    _c(
                      "div",
                      { staticClass: "describe involves-describe" },
                      [
                        _c("Icon", {
                          staticClass: "svg-icons",
                          attrs: { "icon-class": "icon-xiezuoren1" },
                        }),
                        _c("div", [
                          _vm._v(
                            "协作人 (" +
                              _vm._s(_vm.chargeUserListInit.length || 0) +
                              ")"
                          ),
                        ]),
                        _vm.sysParamInfo["involves"] &&
                        _vm.sysParamInfo["involves"].required === 0
                          ? _c("span", { staticClass: "required" }, [
                              _vm._v("*"),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex-a-c-center flex-new-wrap" },
                      [
                        _vm._l(_vm.chargeUserListInit, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: item.id,
                              staticClass: "charge-avatar-box",
                              class:
                                _vm.chargeUserId == item.id
                                  ? "charge-avatar-box-hover"
                                  : "",
                            },
                            [
                              _c("el-image", {
                                staticClass: "charge-avatar border-radius-20",
                                attrs: { fit: "cover", src: item.avatar },
                              }),
                              _c("Icon", {
                                staticClass: "close-icon",
                                attrs: { "icon-class": "icon-a-Group719-1" },
                                nativeOn: {
                                  mouseenter: function ($event) {
                                    _vm.chargeUserId = item.id
                                  },
                                  mouseleave: function ($event) {
                                    _vm.chargeUserId = ""
                                  },
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.chargeUserDelete(index)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        _c(
                          "user-picker-checked",
                          {
                            attrs: {
                              "select-people-list": _vm.chargeUserList,
                              length: 40,
                            },
                            on: { pick: _vm.chargeUserPicker },
                            nativeOn: {
                              click: function ($event) {
                                $event.stopPropagation()
                              },
                            },
                          },
                          [
                            _c("Icon", {
                              staticClass: "cooperation-add",
                              attrs: { "icon-class": "icon-tianjia-xuxian" },
                            }),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _c(
            "li",
            { staticClass: "task-info-item" },
            [
              _c(
                "div",
                {
                  class: `describe ${
                    _vm.moreParamShow ? "time-describe" : "simple-descirbe"
                  }`,
                },
                [
                  _c("Icon", {
                    staticClass: "m-r-10 svg-icons",
                    attrs: { "icon-class": "icon-rili" },
                  }),
                  _vm.moreParamShow
                    ? _c("span", { staticClass: "pointer m-r-10" }, [
                        _vm._v("时间"),
                      ])
                    : _vm._e(),
                  _vm.sysParamInfo["startDate"] &&
                  _vm.sysParamInfo["startDate"].required === 0
                    ? _c("span", { staticClass: "required" }, [_vm._v("*")])
                    : _vm._e(),
                ],
                1
              ),
              _vm.setDateTimeVisible
                ? _c("set-date-time", {
                    key: _vm.setDateTimeVisible,
                    staticClass: "set-date-time",
                    attrs: {
                      type: "add",
                      classifytype: _vm.classifytype,
                      row: _vm.form,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm.classifytype === "kr" && _vm.propsAddObj.taskType != "子任务"
            ? _c(
                "li",
                { staticClass: "task-info-item" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "describe kr-describe",
                      class: _vm.moreParamShow ? "" : "simple-descirbe",
                    },
                    [
                      _c("Icon", {
                        staticClass: "m-r-10 svg-icons",
                        attrs: { "icon-class": "icon-guishuyu" },
                      }),
                      _vm.moreParamShow
                        ? _c("span", [_vm._v("任务所属")])
                        : _vm._e(),
                      _c("span", { staticClass: "required" }, [_vm._v("*")]),
                    ],
                    1
                  ),
                  _c("task-kr", {
                    staticStyle: { "margin-left": "0px" },
                    attrs: {
                      "select-kr": _vm.copyProps,
                      "todo-add": false,
                      "is-okr-shortcut": _vm.isOkrShortcut,
                    },
                    on: {
                      save: _vm.krSave,
                      userPickerHidden: _vm.userPickerHidden,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.classifytype === "project" && _vm.propsAddObj.taskType != "子任务"
            ? _c(
                "li",
                { staticClass: "task-info-item" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "describe project-describe",
                      class: _vm.moreParamShow ? "" : "simple-descirbe",
                    },
                    [
                      _c("Icon", {
                        staticClass: "m-r-10 svg-icons",
                        attrs: { "icon-class": "icon-guishuyu" },
                      }),
                      _vm.moreParamShow
                        ? _c("span", [_vm._v("任务归属")])
                        : _vm._e(),
                      _c("span", { staticClass: "required" }, [_vm._v("*")]),
                    ],
                    1
                  ),
                  _vm.copyProps.formPage === "pm"
                    ? _c("TaskProject", {
                        attrs: {
                          project: _vm.projectInfo,
                          "process-id": _vm.copyProps.processId,
                          "is-pm-shortcut": _vm.isPmShortcut,
                          disabled: _vm.isPmShortcut ? false : true,
                        },
                        on: {
                          change: _vm.changeProcess,
                          changeProject: _vm.changeProject,
                        },
                      })
                    : _c("TaskProject", {
                        attrs: {
                          source: "add",
                          project: _vm.projectInfo,
                          disabled: false,
                        },
                        on: {
                          change: _vm.changeProcess,
                          changeProject: _vm.changeProject,
                        },
                      }),
                ],
                1
              )
            : _vm._e(),
          _vm.moreParamShow
            ? _vm._l(_vm.paramList, function (param) {
                return param.fieldType != "workingHours"
                  ? _c("li", { key: param.id, staticClass: "task-info-item" }, [
                      _c(
                        "div",
                        { staticClass: "flex-new" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "describe",
                              class: `${
                                param.fieldType == "customField"
                                  ? param.fieldAttribute
                                  : param.fieldType
                              }-describe`,
                            },
                            [
                              _c("Icon", {
                                staticClass: "m-r-10 svg-icons",
                                attrs: {
                                  "icon-class":
                                    _vm.paramDict[
                                      param.fieldType !== "customField"
                                        ? param.fieldType
                                        : param.fieldAttribute
                                    ],
                                },
                              }),
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content: param.name,
                                    "open-delay": 300,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "param-name-text" },
                                    [_vm._v(_vm._s(param.name))]
                                  ),
                                ]
                              ),
                              param.required === 0
                                ? _c("span", { staticClass: "required" }, [
                                    _vm._v("*"),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                          param.fieldType === "priority"
                            ? [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "priority-box",
                                    style: `color: ${_vm.priorityInfo.colour} !important`,
                                    attrs: { placeholder: "请选择" },
                                    on: { change: _vm.selectClick },
                                    model: {
                                      value: _vm.form.priorityId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "priorityId", $$v)
                                      },
                                      expression: "form.priorityId",
                                    },
                                  },
                                  [
                                    _vm._l(_vm.priorityDict, function (item) {
                                      return [
                                        _c("el-option", {
                                          key: item.id,
                                          style: `color: ${item.colour}`,
                                          attrs: {
                                            label: item.value,
                                            value: item.id,
                                          },
                                        }),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ]
                            : param.fieldType === "tag"
                            ? [
                                _c("TaskLabel", {
                                  attrs: {
                                    "label-options": _vm.tagList,
                                    "select-options": _vm.selectTag,
                                  },
                                  on: {
                                    save: _vm.saveLabels,
                                    update: _vm.saveLabels,
                                  },
                                }),
                              ]
                            : [
                                _c("CustomField", {
                                  attrs: {
                                    "field-type": param.fieldAttribute,
                                    "field-info": param,
                                    value: param.value,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.changeParamVal($event, param)
                                    },
                                  },
                                  model: {
                                    value: param.value,
                                    callback: function ($$v) {
                                      _vm.$set(param, "value", $$v)
                                    },
                                    expression: "param.value",
                                  },
                                }),
                              ],
                        ],
                        2
                      ),
                    ])
                  : _vm._e()
              })
            : _vm._e(),
          !_vm.moreParamShow
            ? _c("li", { staticClass: "task-info-item" }, [
                _c(
                  "div",
                  {
                    staticClass: "describe more-btn",
                    on: {
                      click: function ($event) {
                        _vm.moreParamShow = true
                      },
                    },
                  },
                  [
                    _c("Icon", {
                      staticClass: "m-r-10 svg-icons",
                      attrs: { "icon-class": "icon-more-row" },
                    }),
                    _c("span", [_vm._v("更多")]),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "footer flex-new",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "div",
            [
              _c(
                "el-tooltip",
                {
                  attrs: {
                    "open-delay": 300,
                    content: _vm.switchValue
                      ? "全员可见"
                      : _vm.classifytype == "todo"
                      ? "仅自己可见"
                      : "仅参与人可见",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex-new footer-box",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          _vm.switchValue = !_vm.switchValue
                        },
                      },
                    },
                    [
                      _vm.switchValue
                        ? _c("Icon", {
                            staticClass: "task-add-img",
                            attrs: { "icon-class": "icon-chakan" },
                          })
                        : _vm._e(),
                      !_vm.switchValue
                        ? _c("Icon", {
                            staticClass: "task-not-img",
                            attrs: { "icon-class": "icon-yincang" },
                          })
                        : _vm._e(),
                      _c("p", { staticClass: "footer-lf" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.switchValue
                                ? "全员可见"
                                : _vm.classifytype == "todo"
                                ? "仅自己可见"
                                : "仅参与人可见"
                            ) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "align-center" },
            [
              _vm.loading || _vm.nextLoading
                ? _c(
                    "button",
                    {
                      staticClass:
                        "next-primary common_btn_next_add common_btn_next_add_unable",
                    },
                    [_vm._v("取 消")]
                  )
                : _c(
                    "button",
                    {
                      directives: [
                        { name: "preventReClick", rawName: "v-preventReClick" },
                      ],
                      staticClass:
                        "next-primary common_btn_next_add common_btn_next_add_able",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.cancle.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
              _vm.disabledSubmit || _vm.loading
                ? _c(
                    "button",
                    {
                      staticClass:
                        "next-btns common_btn_next_add common_btn_next_add_unable",
                    },
                    [_vm._v("完成并创建下一个")]
                  )
                : _c(
                    "L-button",
                    {
                      staticClass:
                        "next-btns common_btn_next_add common_btn_next_add_able",
                      attrs: { loading: _vm.nextLoading, color: "#3068ff" },
                      on: {
                        clickEvt: function ($event) {
                          return _vm.save("next")
                        },
                      },
                    },
                    [_vm._v("完成并创建下一个")]
                  ),
              _vm.disabledSubmit || _vm.nextLoading
                ? _c(
                    "button",
                    {
                      staticClass:
                        "next-primary common_btn_next_add common_btn_next_add_unable",
                    },
                    [_vm._v("完 成")]
                  )
                : _c(
                    "L-button",
                    {
                      staticClass:
                        "next-primary common_btn_next_add common_btn_next_add_able_blue",
                      attrs: { loading: _vm.loading },
                      on: { clickEvt: _vm.save },
                    },
                    [_vm._v("完成")]
                  ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }