<template>
  <div class="task-project">
    <div v-if="canSelect && !disabled"></div>
    <div v-else class="project-item">
      <el-popover
        v-model="projectVisible"
        placement="bottom"
        width="200"
        trigger="click"
        :disabled="disabled"
        @hide="closeProject"
      >
        <div
          v-if="projectList.length > 0"
          v-scroll-bottom="loadMoreGetProjectList"
          class="project-div"
        >
          <div
            v-for="(item, index) in projectList"
            :key="index"
            class="project-row"
            @click="checkedProject(item, index)"
          >
            <div class="project-img" :style="`background: ${item.colour}`">
              <!-- <img :src="item.logo" /> -->
              <el-image :src="item.logo || require('@/assets/imgs/common/avatar.png')" fit="cover" />
            </div>
            <div class="project-name">{{ item.name }}</div>
            <div class="project-checked">
              <i v-if="activeProject == item.id" class="el-icon-check checked-icon"></i>
            </div>
          </div>
        </div>
        <div v-else class="no-data">
          <img src="@/assets/imgs/common/commentNull.png" class="kr-count-empty-img" />
          <p class="kr-count-p">暂无内容</p>
        </div>
        <div
          slot="reference"
          class="project-show"
          :style="
            disabled
              ? 'cursor: not-allowed; !important'
              : 'cursor: pointer; !important'
          "
        >
          <div v-if="!projectObj.id" class="add-project">+选择任务归属于</div>
          <template v-else>
            <div class="project-img" :style="`background: ${projectObj.colour}`">
              <!-- <img :src="project.logo" /> -->
              <el-image
                :src="
                  projectObj.logo || require('@/assets/imgs/common/avatar.png')
                "
                fit="cover"
              />
            </div>
            <div class="project-name">
              {{ projectObj.name }}
              <i
                v-if="!projectDisabled && !disabled"
                :class="
                  projectVisible ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
                "
              ></i>
            </div>
          </template>
        </div>
      </el-popover>
    </div>
    <span v-show="flowId && isFlowSelect && project.id">/</span>
    <el-select
      v-show="flowId && isFlowSelect && isShowFlow && project.id"
      v-model="flowId"
      :disabled="projectDisabled"
      class="flow-select"
      @change="changeVal"
    >
      <el-option v-for="item in projectFlows" :key="item.id" :label="item.name" :value="item.id" />
    </el-select>
  </div>
</template>
<script>
export default {
  props: {
    isPmShortcut: {
      type: Boolean,
      default: false
    },
    // 工作流是否可编辑
    projectDisabled: {
      type: Boolean,
      default: false
    },
    isFlowSelect: {
      type: Boolean,
      default: true
    },
    canSelect: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    project: {
      type: Object,
      default: () => {
        return {}
      }
    },
    // 流程id
    processId: {
      type: String,
      default: ''
    },
    source: {
      type: String,
      default: 'details'
    }
  },
  data() {
    return {
      flowId: this.processId,
      porjectList: [],
      projectFlows: [],
      projectList: [], // 项目列表
      proTotal: '',
      proPageNum: 0,
      proPageSize: 10,
      activeProject: '',
      projectObj: {},
      projectVisible: false,
      isShowFlow: false
    }
  },
  watch: {
    processId: {
      handler(val) {
        this.flowId = val
      },
      deep: true,
      immediate: true
    },
    project: {
      handler(val) {
        this.projectObj = JSON.parse(JSON.stringify(val))
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.projectObj = JSON.parse(JSON.stringify(this.project))
    this.activeProject = this.projectObj.id
    if (this.isPmShortcut) {
      this.getProjectInfo(this.projectObj.id)
    } else {
      this.queryProjectFlows()
      this.getProjectList()
    }
  },
  methods: {
    getProjectInfo(projectId) {
      this.$apis.PROJECTSIMPLIFYINFO(projectId).then((res) => {
        if (res.status == 200) {
          console.log('项目信息---', res)
          this.projectObj = res.data
          this.queryProjectFlows(projectId, this.projectObj)
          this.getProjectList()
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    closeProject() {
      this.projectVisible = false
    },
    // 更改选中状态
    checkedProject(item, index) {
      console.log(323, item, index)
      this.projectObj = item
      this.activeProject = item.id
      this.projectFlows = item.processArray
      this.flowId = ''
      this.queryProjectFlows(this.activeProject, item)
      this.closeProject()
    },
    loadMoreGetProjectList() {
      if (this.projectList.length < this.proTotal) {
        this.getProjectList(true)
      }
    },
    getProjectList(flag) {
      if (flag && flag == true) {
        this.proPageNum++
      } else {
        this.proPageNum = 1
      }
      const params = {
        pageSize: this.proPageSize,
        pageNum: this.proPageNum
      }
      this.$apis.PROJECTRECOMMENDLIST(params).then((res) => {
        this.$nextTick(() => {
          if (res.data.rows && res.data.rows.length >= 0) {
            if (flag && flag == true) {
              res.data.rows.forEach((item) => {
                this.projectList.push(item)
              })
            } else {
              this.projectList = res.data.rows
            }
          }
          // this.projectList.forEach((item) => {
          //   if (item.id == this.project.id) {
          //     this.projectFlows = item.processArray
          //   }
          // })
          // this.projectList.forEach((item, index) => {
          //   this.projectList[index]['checked'] = false
          //   if (this.project && this.project.id) {
          //     if (item.id == this.project.id) {
          //       this.projectList[index]['checked'] = true
          //     }
          //   }
          // })

          this.proTotal = res.data.total
        })
      })
    },
    // 查询项目下工作流
    queryProjectFlows(id, item) {
      if (!this.projectObj.id && !id) return
      const obj_ = {
        projectId: id || this.projectObj.id
      }
      this.$apis.PROJECTPROCESSLIST(obj_).then((res) => {
        if (res.status === 200) {
          this.projectFlows = res.data
          if (!this.flowId) {
            this.flowId = this.projectFlows[0].id
            this.changeVal(this.flowId, obj_.projectId)
          }
          if (this.source == 'add' || id) {
            this.$emit('changeProject', this.activeProject, item, this.flowId)
          }
          this.isShowFlow = false
          this.$nextTick(() => {
            this.isShowFlow = true
          })
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 修改工作流id
    changeVal(id, projectId) {
      this.$emit('change', id, projectId || this.project.id)
    }
  }
}
</script>
<style lang="scss" scoped>
.no-data {
  width: 100%;
  height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img.kr-count-empty-img {
    width: 110px;
    align-items: center;
    align-self: center;
  }
  p.kr-count-p {
    line-height: 36px;
    text-align: center;
    font-size: 14px;
  }
}
::v-deep {
  .el-input.is-disabled .el-input__inner {
    background: none !important;
  }
  .el-select .el-input .el-select__caret {
    color: #444558;
  }
  .el-input__inner {
    color: #444558;
  }
}
.project-div {
  max-height: 200px;
  overflow-y: auto;
}
.project-row {
  display: flex;
  flex-direction: row;
  height: 40px;
  align-items: center;
  border-radius: 8px;
  &:hover {
    background: #f5f6fa;
  }
}
.project-show {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  .add-project {
    font-size: 14px;
    font-weight: 400;
    color: #a0a3bd;
    line-height: 16px;
    &:hover {
      color: #3a70ff;
    }
  }
}
.task-project {
  display: flex;
  align-items: center;
  .flow-select {
    width: 160px;
    ::v-deep {
      .el-input__inner {
        border: none;
        color: #434458 !important;
      }
      &:hover {
        .el-input__inner {
          color: #3a78f6;
        }
        .el-input__prefix,
        .el-input__suffix {
          color: #3a78f6 !important;
        }
        .el-select .el-input .el-select__caret {
          color: #3a78f6 !important;
        }
      }
    }
  }
}
.project-img {
  width: 24px;
  height: 24px;
  background: #ff7b02;
  border-radius: 6px;
  margin-right: 6px;
  .el-image {
    width: 24px;
    height: 24px;
  }
}
.project-name {
  flex: 1;
  font-size: 14px;
  font-weight: 500;
  color: #444558;
  line-height: 22px;
  max-width: 120px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &:hover {
    color: #3a78f6;
  }
}
.project-checked {
  width: 30px;
  i {
    font-weight: 700;
  }
}
.project-item {
  display: flex;
  align-items: center;
  margin-right: 8px;
  cursor: pointer;
}
</style>
