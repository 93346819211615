<template>
  <div>
    <div class="management">
      <div class="management_title flex-new">
        <div class="management_title_top">
          <div class="center-box flex-a-c-center">
            <div class="search-box m-r-18 rel">
              <Icon icon-class="icon-Search" class="search-icon" />
              <el-input
                v-model="searchContent"
                class="search-input"
                placeholder="请输入搜索目标或任务"
                @input="searchObjectiveOrTask"
                @blur="searchBlur"
                @focus="searchResultVisible = true"
              />
              <!-- 搜索内容列表 -->
              <div
                v-if="
                  searchResultVisible &&
                  (searchObjectiveList.length > 0 || searchTaskList.length > 0)
                "
                v-scroll-bottom="headerLoadMore"
                class="search-result-list abs"
              >
                <div class="objective-box">
                  <div class="p-t-b-10">目标</div>
                  <!-- 目标列表 -->
                  <ul v-if="searchObjectiveList.length" class="objective-list">
                    <li
                      v-for="item in searchObjectiveList"
                      :key="item"
                      class="search-item pointer ellipsis-1"
                      @click="showObjectiveDetail(item)"
                    >
                      <div v-html="item.content"></div>
                      <privateOkr
                        :createUser="userInfo.id === item.createUserid"
                        :visible="item.isVisible === 1"
                        :content="
                          item.isVisible === 1 ? '对方已设置为不可见' : ''
                        "
                        :type="1"
                      />
                    </li>
                  </ul>
                </div>
                <div class="task-box">
                  <div class="p-t-b-10">任务</div>
                  <!-- 任务列表 -->
                  <ul v-if="searchTaskList.length" class="task-list">
                    <li
                      v-for="item in searchTaskList"
                      :key="item"
                      class="search-item pointer ellipsis-1"
                      @click.stop="showTaskDetail(item)"
                    >{{ item.title }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Icon
          icon-class="icon-close"
          class="close-icon management_close_icon"
          @click="closeManagement"
        />
      </div>
    </div>
    <div class="management-wrap" @click="closeManagement"></div>
  </div>
</template>
<script>
/* eslint-disable */
import privateOkr from '@/views/okr/components/PrivateOkr'
export default {
  components: { privateOkr },
  props: {},
  data() {
    return {
      searchResultVisible: false,
      objectiveDetailVisible: false,
      taskDetailVisible: false,
      searchContent: '', // 搜索内容
      searchObjectiveList: [],
      searchTaskList: [],
      taskId: '',
      objId: '',
      targetDetailOpenState: '',
      taskPage: 1, //任务页码
      ObjectivePage: 1, //目标页码
      TaskListCount: 0, //任务数量总数
      ObjectiveListCount: 0 //目标数量总数
    }
  },
  created() {},
  computed: {},
  methods: {
    // 搜索框失去焦点
    searchBlur() {
      setTimeout(() => {
        this.searchResultVisible = false
      }, 200)
    },
    // 查询目标或任务
    async searchObjectiveOrTask() {
      this.taskPage = 1 //任务页码
      this.ObjectivePage = 1 //目标页码
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        // 搜索框无内容
        if (!this.searchContent) {
          this.searchObjectiveList = []
          this.searchTaskList = []
          return
        }
        if (this.searchContent.replace(/\s*/g, '').length > 200) {
          let searchContent_ = this.searchContent
          this.$message({
            msg: '目标或任务最多可以输入 200 个字符哦!',
            type: 'warn'
          })
          this.searchContent = searchContent_.substring(0, 200)
        } else {
          this.getSearchList('input')
        }
      }, 300)
    },
    // 获取搜索结果列表
    getSearchList(types) {
      let _this = this
      if (types === 'input') {
        _this.searchObjectiveList = []
        _this.searchTaskList = []
      }
      _this.getObjectiveList(types)
      _this.getTaskList(types)
    },
    //加载目标列表
    getObjectiveList(types) {
      let _this = this
      this.$apis
        .HEADEROBJLISTSEARCH({
          contentSearch: _this.searchContent, //目标的标题
          pageSize: 10,
          page: _this.ObjectivePage
        })
        .then((rest) => {
          if (
            rest.status == 200 &&
            rest.data != null &&
            rest.data.rows.length > 0
          ) {
            let dataBean = []
            if (rest.data.rows.length > 0) {
              dataBean = rest.data.rows.map((item) => {
                return Object.assign(item, {
                  content: this.$utils.changeToHtml(item.content)
                })
              })
            }

            if (types == 'input') {
              _this.searchObjectiveList = [...dataBean]
              _this.ObjectiveListCount = rest.data.total || 0
            } else if (types == 'loaderMore') {
              _this.searchObjectiveList = _this.searchObjectiveList.concat(
                dataBean || []
              )
            }
            _this.ObjectivePage += 1
          }
        })
        .catch((err) => {})
    },
    //加载任务列表
    getTaskList(types) {
      let _this = this
      this.$apis
        .HEADERTASKLISTSEARCH({
          title: _this.searchContent, //目标的标题
          pageSize: 10,
          page: _this.taskPage
        })
        .then((rest) => {
          if (
            rest.status == 200 &&
            rest.data != null &&
            rest.data.rows.length > 0
          ) {
            if (types == 'input') {
              _this.searchTaskList = [...rest.data.rows]
              _this.TaskListCount = rest.data.total || 0
            } else if (types == 'loaderMore') {
              _this.searchTaskList = _this.searchTaskList.concat(
                rest.data.rows || []
              )
            }
            _this.taskPage += 1
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    //加载更多
    headerLoadMore() {
      let taskPageEnd = 0,
        objectivePageEnd = 0,
        that = this
      taskPageEnd = Math.ceil(Number(that.TaskListCount) / 10) //任务页码 最大值
      objectivePageEnd = Math.ceil(Number(that.ObjectiveListCount) / 10) //目标页码 最大值
      //任务加载更多
      if (
        that.taskPage <= taskPageEnd &&
        Number(that.TaskListCount) > that.searchTaskList.length
      ) {
        that.getTaskList('loaderMore')
      }
      //目标加载更多
      if (
        that.ObjectivePage <= objectivePageEnd &&
        Number(that.ObjectiveListCount) > that.searchObjectiveList.length
      ) {
        that.getObjectiveList('loaderMore')
      }
    },
    // 展示目标详情
    showObjectiveDetail(item) {
      this.targetDetailOpenState = '展开'
      this.searchResultVisible = true
      this.objId = item
      this.objectiveDetailVisible = true
      let obj = {}
      obj.searchResultVisible = true
      obj.item = item
      obj.objectiveDetailVisible = true
      obj.targetDetailOpenState = '展开'
      this.$emit('objectiveDetailVisible', obj)
    },
    // 展示任务详情
    showTaskDetail(item) {
      this.searchResultVisible = true
      this.taskId = item.id
      this.taskDetailVisible = true
      let obj = {}
      obj.searchResultVisible = true
      obj.taskId = item.id
      obj.taskDetailVisible = true
      this.$emit('taskDetailVisible', obj)
    },
    // 目标详情点击关闭调整
    getTargetDetailsClose() {
      this.objectiveDetailVisible = false
      setTimeout(() => {
        // this.searchResultVisible = false
        this.targetDetailOpenState = ''
      }, 500)
    },
    //关闭弹框
    closeManagement() {
      this.$emit('closeHeaderSearch', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../../assets/styles/variable.scss'; // 定义的变量
::v-deep .el-input__inner {
  border: none;
  height: 30px;
  line-height: 30px;
  font-weight: bold;
}
.management-wrap {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1500;
  filter: Alpha(opacity=50); /* IE */
  -moz-opacity: 0.5; /* FF 主要是为了兼容老版本的FF */
  opacity: 0.5; /* FF */
  background: rgba(0, 0, 0, 0.5);
}
.management {
  left: 50%;
  top: 80px;
  width: 320px;
  transform: translatex(-50%);
  position: fixed;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0px 0px 20px 0px rgba(0, 24, 87, 0.1);
  z-index: 1550;
  .management_triangle_icon {
    width: 18px;
    height: 18px;
    align-self: center;
    transform: rotate(90deg);
    -ms-transform: rotate(90deg); /* IE 9 */
    -moz-transform: rotate(90deg); /* Firefox */
    -webkit-transform: rotate(90deg); /* Safari 和 Chrome */
    -o-transform: rotate(90deg); /* Opera */
  }
  .management_title {
    position: relative;
    height: 50px;
    width: 100%;
    border-bottom: 1px solid #d8d8d8;
    .management_close_icon {
      width: 18px;
      height: 18px;
      font-size: 18px;
      align-self: center;
      margin-right: 10px;
      color: #606266;
      cursor: pointer;
      &:hover {
        color: $hover-color;
      }
    }
    .management_back {
      padding: 18px 0 16px 16px;
      line-height: 18px;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
    }
    .management_back_collect {
      width: 20px;
      height: 20px;
      margin: 16px 0 16px 16px;
    }
    .management_back_collect_center {
      width: 20px;
      height: 20px;
      position: absolute;
      margin-left: 4px;
      margin-top: -3px;
    }
    .management_arrow {
      width: 0;
      height: 0;
      border-width: 12px;
      border-style: solid;
      border-color: transparent #fff transparent transparent;
      transform: rotate(90deg); /*顺时针旋转90°*/
      position: absolute;
      left: 22px;
      top: -22px;
    }
    .management_title_top {
      padding: 8px 0;
      font-size: 17px;
      color: $mine-shaft;
      margin: 0 auto;
      font-weight: 600;
      position: relative;
      .search-box {
        border: 1px solid $alto;
        background: #fff;
        border-radius: 6px;
        display: flex;
        align-items: center;
        padding-left: 15px;
        width: 260px;
        height: 36px;
        .search-icon {
          height: 14px;
          width: 14px;
          margin-right: -10px;
          z-index: 10;
          font-size: 20px;
          color: #606266;
        }
        .search-input {
          border: none;
          width: 90%;
          font-size: 14px;
        }
        .search-result-list {
          left: -8px;
          top: 40px;
          padding: 10px;
          background: #fff;
          border: 1px solid $alto;
          width: 320px;
          border-radius: 4px;
          z-index: 9999;
          max-height: 350px;
          @include scroll-beautify;
          .objective-box {
            // border-bottom: 1px solid $alto;
          }
          .search-item {
            line-height: 40px;
            font-size: 14px;
            color: #333;
            padding: 0 5px;
            position: relative;
            box-sizing: border-box;
            ::v-deep .at-name {
              color: #333;
            }
            .flur-wrap {
              left: 0px;
              right: 0px;
            }
            &:hover {
              background: $gallery;
            }
          }
        }
      }
    }
  }
}
</style>
