<template>
  <div class="field-com">
    <component
      :is="CUSTOMCOMPONENTS[fieldType]"
      :value="value"
      :field-info="fieldInfo"
      :disabled="disabled"
      @change="changeVal"
    />
  </div>
</template>
<script>
import { COMPONENTS, CUSTOMCOMPONENTS } from './index'
export default {
  components: COMPONENTS,
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    // 单选：singleChoice 多选：multipleChoice 日期：date 数字：number 文本：text 文件：file 成员单选：singleMember 成员多选：multipleMember
    fieldType: {
      type: String,
      default: 'text'
    },
    fieldInfo: {
      type: String,
      default: () => {
        return {}
      }
    },
    // 自定义字段的值
    value: {},
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      CUSTOMCOMPONENTS,
      fieldVal: this.value
    }
  },
  watch: {
    value: {
      handler(val) {
        this.fieldVal = val
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    changeVal(val) {
      this.fieldVal = val
      this.$emit('change', val)
    }
  }
}
</script>
<style lang="scss" scoped>
.field-com {
  display: flex;
  align-content: center;
}
</style>
