<template>
  <!-- 企信pc迁移后新增 -->
  <div class="advertisement-wrap">
    <div class="title-wrap" v-if="styleType === 2" @click="openVideo">
      <img :src="require(`@/assets/imgs/kpi/videoIcon.png`)" class="icon" />
      <div class="title">{{videoImgList[type]}}</div>
    </div>
    <div class="img-style-wrap" v-else>
      <div class="img" @click="openVideo">
        <img :src="videoImgList[type]" alt />
      </div>
      <div class="close-btn" @click.stop>
        <Icon icon-class="icon-close" @click.native.stop="close" />
      </div>
    </div>
    <div v-if="videoDialogShow">
      <look-add-okr
        :videoUrl="videoUrl"
        :videoName="videoName"
        :recordTime="true"
        :video-dialog-show="videoDialogShow"
        @closeVideoDialog="closeVideoDialog"
      />
    </div>
  </div>
</template>
<script>
import lookAddOkr from '@/views/okr/dialog/lookAddOkr/lookAddOkr.vue'
export default {
  components: {
    lookAddOkr
  },
  props: {
    type: {
      default: ''
    },
    imgUrl: {
      default: ''
    },
    videoUrl: {
      default: ''
    },
    styleType: { // 1: 图片 2:文字
      default: 1
    }
  },
  data() {
    return {
      videoDialogShow: false,
      videoUrlList: {
        'workbench': 'https://videos.okrt.com/okrt/allin/06.mp4',
        'okr': 'https://videos.okrt.com/okrt/allin/10.mp4',
        'todo': 'https://videos.okrt.com/okrt/allin/03.mp4',
        'pm': 'https://videos.okrt.com/okrt/allin/04.mp4',
        'conclusion': 'https://videos.okrt.com/okrt/allin/08.mp4',
        'addTask': 'https://videos.okrt.com/okrt/allin/01.mp4',
        'autoProgress': 'https://videos.okrt.com/okrt/allin/07.mp4'
      },
      videoImgList: {
        'workbench': require(`@/assets/imgs/advertisement/workbench.png`),
        'okr': require(`@/assets/imgs/advertisement/okr.png`),
        'todo': require(`@/assets/imgs/advertisement/todo.png`),
        'pm': require(`@/assets/imgs/advertisement/pm.png`),
        'conclusion': require(`@/assets/imgs/advertisement/conclusion.png`),
        'addTask': '观看视频如何制定任务',
        'autoProgress': '点击这里，学习如何设置智能进度'
      },
      videoNameList: {
        'workbench': '打造你的高效工作台',
        'okr': '快速上手源目标OKR',
        'todo': '快速了解Todo待办事项',
        'pm': '一个视频快速了解项目',
        'conclusion': '玩转总结，开启高效复盘',
        'addTask': '观看视频如何制定任务',
        'autoProgress': '点击这里，学习如何设置智能进度',
      }
    }
  },
  created() {
    this.videoUrl = this.videoUrlList[this.type]
    this.videoName = this.videoNameList[this.type]
  },
  mounted() {
  },
  methods: {
    openVideo() {
      this.videoDialogShow = true
    },
    closeVideoDialog() {
      this.videoDialogShow = false
    },
    close() {
      console.log('close')
      this.$store.commit('common/setVideoPopoverVisible', true)
      localStorage.setItem(`${this.type}VideoState`, true)
      this.$emit('close')
    }
  }
}
</script>
<style lang="less" scoped>
.advertisement-wrap {
  position: relative;
  width: 100%;
  .title-wrap {
    cursor: pointer;
    .flex-l();
    .icon {
      width: 24px;
      height: auto;
    }
    .title {
      margin-left: 6px;
      font-size: 14px;
      font-weight: 500;
      color: #ff9900;
    }
    &:hover {
      opacity: 0.54;
    }
  }
  .img-style-wrap {
    .img {
      cursor: pointer;
      width: 100%;
      img {
        width: 100%;
      }
    }
    .close-btn {
      cursor: pointer;
      color: #6e7491;
      font-size: 18px;
      position: absolute;
      top: 16px;
      right: 16px;
      &:hover {
        color: #3a70ff;
      }
    }
  }
}
</style>
