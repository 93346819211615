export default {
  path: '/kpi',
  component: () => {
    return import(/* webpackChunkName: "kpi" */ '../../views/kpi')
  },
  children: [
    {
      path: '/',
      name: 'myCheck',
      component: () => {
        return import(
          /* webpackChunkName: "kpiMyCheck" */
          '../../views/kpi/myCheck'
        )
      }
    },
    {
      path: '/kpi/myHandle',
      name: 'myHandle',
      component: () => {
        return import(
          /* webpackChunkName: "kpiMyHandle" */
          '../../views/kpi/myHandle'
        )
      }
    },
    {
      path: '/kpi/publicCheck',
      name: 'publicCheck',
      component: () => {
        return import(
          /* webpackChunkName: "kpiPublicCheck" */
          '../../views/kpi/publicCheck'
        )
      }
    },
    {
      path: '/kpi/checkManage',
      name: 'checkManage',
      component: () => {
        return import(
          /* webpackChunkName: "kpiCheckManage" */
          '../../views/kpi/checkManage'
        )
      }
    },
    {
      path: '/kpi/templeteManage',
      name: 'templeteManage',
      component: () => {
        return import(
          /* webpackChunkName: "kpiTempleteManage" */
          '../../views/kpi/templeteManage'
        )
      }
    },
    {
      path: '/kpi/templeteEdit',
      name: 'templeteEdit',
      component: () => {
        return import(
          /* webpackChunkName: "kpiTempleteEdit" */
          '../../views/kpi/templeteManage/addTemplete'
        )
      }
    },
    {
      path: '/kpi/templeteDetail',
      name: 'templeteDetail',
      component: () => {
        return import(
          /* webpackChunkName: "kpiTempleteDetail" */
          '../../views/kpi/templeteManage/detailTemplete'
        )
      }
    },
    {
      path: '/myCheck/checkDetail',
      name: 'checkDetail',
      component: () => {
        return import(
          /* webpackChunkName: "kpiCheckDetail" */
          '../../views/kpi/myCheck/checkDetail/checkDetail.vue'
        )
      }
    },
    {
      path: '/publicCheck/checkDetail',
      name: 'assessmentDetail',
      component: () => {
        return import(
          /* webpackChunkName: "kpiPublicCheckDetail" */
          '../../views/kpi/publicCheck/checkDetail/checkDetail.vue'
        )
      }
    }
  ]
}
