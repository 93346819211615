<template>
  <transition name="fade" mode="out-in">
    <div>
      <el-dialog
        title="周期设置"
        :visible.sync="dialogVisible"
        width="680px"
        :append-to-body="true"
        :before-close="handleClose"
        class="comm-check-form"
      >
        <div class="dialog-content">
          <div class="form-item form-item-cycle">
            <div class="form-title" style="margin:0">周期名称:</div>
            <div class="cycle-name-input">
              <el-input
                v-model="cycleName"
                class="inp"
                auto-complete="off"
                placeholder="请输入周期名称"
                @blur="cycleNameBlue"
              />
            </div>
          </div>
          <div class="form-item">
            <div class="form-title">可见范围设置</div>
            <div class="set-radios">
              <div class="radio-item" @click="changeRangeType(0)">
                <div class="radio-type-icon" :class="form.dataRange === 0 ? 'select-radio' : ''"></div>全部成员可见
              </div>
              <div class="radio-item" @click="changeRangeType(1)">
                <div class="radio-type-icon" :class="form.dataRange === 1 ? 'select-radio' : ''"></div>自定义范围
              </div>
            </div>
            <div v-if="form.dataRange === 1">
              <div class="range-btns">
                <div class="add-btn" @click="showUserDialog">+ 按组织架构添加</div>
                <el-popover
                  ref="roleCyclePop"
                  popper-class="cycle-setting-rolepop"
                  @show="queryRoleList"
                >
                  <div class="role-list">
                    <div
                      v-for="role in roleLists"
                      :key="role.id"
                      class="role-item"
                      @click="selectRole(role)"
                    >
                      <div class="role-div">{{ role.name.slice(0, 1) }}</div>
                      {{ role.name }}（{{ role.useNum || 0 }}人）
                      <Icon v-if="role.checked" icon-class="icon-draw_checked" class="check-icon" />
                    </div>
                  </div>
                  <div class="confirm-btn" @click="confirmAddRoleRange">确认</div>
                  <div slot="reference" class="add-btn">+ 按角色添加</div>
                </el-popover>
              </div>
              <div class="user-content">
                <div class="user-info">
                  企业内 {{ rangeInfo.departNum }}部门，
                  {{ rangeInfo.userNum }}成员
                </div>
                <div v-for="(item, index) in rangeUsers" :key="item.id" class="user-item">
                  <template v-if="item.type === 0">
                    <img
                      v-if="(item.avatar || '') !== ''"
                      class="user-img border-radius-20"
                      :src="item.avatar"
                    />
                    <img
                      v-else
                      class="user-img border-radius-20"
                      :src="require('@/assets/imgs/common/avatar.png')"
                    />
                  </template>
                  <div
                    v-else
                    class="user-img depart-img"
                    :class="item.type === 2 ? 'role-img' : ''"
                  >
                    <wx-open-data :type="2" :id="item.name" :nameData="{name:item.name}" />
                  </div>
                  <div class="user-name">
                    <wx-open-data
                      v-if="item.type !== 2"
                      :type="item.type === 0 ? 1 : 2"
                      :id="item.userName || item.name"
                      :nameData="{name:item.userName || item.name}"
                    />
                    <span v-else>{{ item.name }}</span>
                  </div>
                  <div class="permission">
                    <permission-select
                      v-if="item.owner === 0"
                      v-model="item.businessPower"
                      @change="updateCyclePermission($event, item)"
                      @delItem="delItem(index)"
                    />
                    <div v-else class="owner-text">所有者</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-item stage-set">
            <div class="form-title">
              周期阶段设置
              <span class="edit-btn edit-btn-true" @click="editCycleStage">编辑</span>
            </div>
            <div v-for="item in stageSetInfo" :key="item.name" class="stage-item">
              <div class="stage-name">{{ item.name }}</div>
              <div class="stage-item-name">
                <span class="satge-text">填写阶段</span>
                {{ item.writeStage.remindText }}
              </div>
              <div class="stage-item-name">
                <span class="satge-text">推进阶段</span>
                {{ item.pushStage.remindText }}
              </div>
              <div class="stage-item-name">
                <span class="satge-text">复盘阶段</span>
                {{ item.reviewStage.remindText }}
              </div>
            </div>
          </div>
          <div class="form-item">
            <div class="form-title">评分设置</div>
            <div class="score-config">
              <div class="config-switch">
                <span>评分</span>
                <el-switch v-model="scoreConfig" active-color="#3a78f6" @change="cycleScoreSet" />
              </div>
              <div class="config-info">可在OKR复盘阶段给OKR打分</div>
            </div>
          </div>
          <div class="form-item remind-form">
            <div class="form-title">提醒设置</div>
            <div v-for="item in reminArr" :key="item.name" class="remind-item">
              <div class="remind-title">
                <div class="left">
                  {{ item.name }}
                  <span
                    :class="`edit-btn edit-btn-${item.notifyState === true}`"
                    @click="openRemind(item)"
                  >编辑</span>
                </div>
                <el-switch
                  v-model="item.notifyState"
                  active-color="#3a78f6"
                  @change="remindswitchSet(item)"
                />
              </div>
              <div
                class="remind-time"
                v-if="item.notifyState === true"
              >提醒时间：{{ item.remindText || '暂未设置提醒' }}</div>
            </div>
          </div>
        </div>
        <cycle-member
          v-if="userMemberVisible"
          v-model="userMemberVisible"
          :selected-person="editUserList"
          :owner-user-id="ownerUserId"
          @saveResult="saveUser"
        />
        <add-remind
          v-if="addRemindVisible"
          v-model="addRemindVisible"
          :remind-data="reminArr[0].remindObj"
          @remindSet="setCycleRemind"
        />
        <update-remind
          v-if="updateRemindVisible"
          v-model="updateRemindVisible"
          :remind-data="reminArr[1].remindObj"
          @remindSet="setCycleRemind"
        />
        <feedback-remind
          v-if="feedbackVisible"
          v-model="feedbackVisible"
          :remind-data="reminArr[2].remindObj"
          @remindSet="setCycleRemind"
        />
        <cycleStage
          v-if="stageVisible"
          v-model="stageVisible"
          :stage-set-info="stageSetInfo"
          :cycle-info="cycleInfo"
          @stageSet="stageInfoSet"
        />
      </el-dialog>
    </div>
  </transition>
</template>
<script>
import cycleMember from '../CycleMember'
import permissionSelect from '../components/permissionSelect'
import addRemind from '../components/addRemind'
import updateRemind from '../components/updateRemind'
import feedbackRemind from '../components/feedbackRemind'
import cycleStage from '../components/cycleStage'
export default {
  components: {
    cycleMember,
    permissionSelect,
    addRemind,
    updateRemind,
    feedbackRemind,
    cycleStage
  },
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  props: {
    dialogShow: {
      type: Boolean,
      default: false
    },
    cycleInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      dialogVisible: this.dialogShow,
      userMemberVisible: false,
      form: {
        dataRange: 0 // 可见范围
      },
      rangeUsers: [], // 可见范围列表
      departRange: [],
      roleRange: [],
      editUserList: [], // 传入选择弹窗
      rangeInfo: {
        userNum: null,
        departNum: null
      },
      changeState: false, // 是否更改过范围类型
      ownerUserId: '', // 周期拥有者id
      reminArr: [
        {
          name: '填写提醒（仅提醒未填写成员）',
          dialogTitle: 'addRemindVisible',
          remindText: '',
          remindObj: [],
          notifyType: 0,
          notifyState: 1
        },
        {
          name: '更新进度提醒（仅提醒已填OKR成员）',
          dialogTitle: 'updateRemindVisible',
          remindText: '',
          remindObj: [],
          notifyType: 0,
          notifyState: 1
        },
        {
          name: '复盘提醒（仅提醒已填OKR成员）',
          dialogTitle: 'feedbackVisible',
          remindText: '',
          remindObj: [],
          notifyType: 0,
          notifyState: 1
        }
      ],
      remindData: [],
      addRemindVisible: false, // 填写提醒弹窗
      updateRemindVisible: false, // 更新进度提醒弹窗
      feedbackVisible: false, // 复盘提醒弹窗
      roleLists: [], // 角色列表
      selectedRoles: [], // 已添加的角色列表
      stageVisible: false, // 周期阶段设置弹窗
      cycleName:"",
      stageSetInfo: [
        {
          name: '阶段设置',
          writeStage: {
            id: '',
            remindText: '暂未设置',
            stageDataStartNum: 1,
            stageDataStartNumType: 1
          },
          pushStage: {
            id: '',
            remindText: '暂未设置',
            stageDataStartNum: 1,
            stageDataStartNumType: 1,
            stageDataEndNum: 1,
            stageDataEndNumType: 1
          },
          reviewStage: {
            id: '',
            remindText: '暂未设置',
            stageDataEndNum: 1,
            stageDataEndNumType: 1
          }
        },
        {
          name: '年度阶段设置',
          writeStage: {
            id: '',
            remindText: '暂未设置',
            stageDataEndNum: 1,
            stageDataEndNumType: 1
          },
          pushStage: {
            id: '',
            remindText: '暂未设置',
            stageDataStartNum: 1,
            stageDataStartNumType: 1,
            stageDataEndNum: 1,
            stageDataEndNumType: 1
          },
          reviewStage: {
            id: '',
            remindText: '暂未设置',
            stageDataStartNum: 1,
            stageDataStartNumType: 1
          }
        }
      ], // 周期阶段设置
      dateType: {
        0: '天',
        1: '周',
        2: '月'
      },
      scoreConfig: false // 是否开启评分
    }
  },
  watch: {
    dialogShow: {
      handler(val) {
        this.dialogVisible = val
      }
    }
  },
  created() {
    this.form.dataRange = this.cycleInfo.dataRange
    this.ownerUserId = this.cycleInfo.createUserid
    this.scoreConfig = (this.cycleInfo && this.cycleInfo.scoreStatus === 1)
    this.cycleName=this.cycleInfo.name
    this.queryCycleRange()
    this.querySettingInfo()
    this.queryStageInfo()
    this.querySettingStateInfo()
  },
  methods: {
    //修改周期名称
    cycleNameBlue(){
      if (this.cycleName.length===0) {
        this.$message.warn("周期名称不能为空")
        return
      }
      this.$apis.SETCYCLENAME({
        id:this.cycleInfo.id,
        name:this.cycleName
      }).then((res) => {
        if (res.status == '200') {
         this.$message.success('修改成功')
         this.$emit('changeUpdate')
        }
      })
    },
    // 设置周期阶段
    editCycleStage() {
      this.stageVisible = true
    },
    // 查询周期可见范围
    queryCycleRange() {
      const params = {
        cycleId: this.cycleInfo.id,
        dataRange: this.form.dataRange
      }
      this.$apis.GETCYLCERANGE(params).then((res) => {
        this.rangeInfo = {
          userNum: 0,
          departNum: 0
        }
        this.rangeUsers = []
        this.departRange = []
        this.roleRange = []
        this.editUserList = []
        if (!res.data) return
        const typeObj = {
          0: 'userDto',
          1: 'departDTO',
          2: 'groupDTO'
        }
        res.data.forEach((item) => {
          const type = item.cycleInfoUserDto.businessType
          const obj = item[typeObj[type]]
          if (!obj) return
          obj.businessPower = item.cycleInfoUserDto.businessPower
          obj.dataId = item.cycleInfoUserDto.id
          obj.owner = item.owner
          obj.type = type
          if (type === 0) {
            this.rangeInfo.userNum += 1
          } else if (type === 1) {
            this.rangeInfo.departNum += 1
          }

          const dataObj = {
            businessId: item.cycleInfoUserDto.businessId, // （部门id，用户id，角色id)
            businessType: type, // 0:用户，1：部门 2:角色
            businessPower: item.cycleInfoUserDto.businessPower // 权限 1:可管理2：不可见 3：仅查看 4：可编辑
          }
          if (type === 2) {
            this.roleRange.push(dataObj)
          } else {
            this.departRange.push(dataObj)
          }

          const rangeObj = {
            userId: obj.id,
            isAdmin: 0,
            departmentTitle: type === 0 ? '' : obj.name.substring(0, 1),
            avatar: obj.avatar || '',
            name: obj.name || obj.userName,
            userCount: obj.userNum || 1,
            departPath: obj.departPath,
            businessPower: obj.owner === 1 ? 1 : obj.businessPower
          }
          if (item.owner === 1) {
            this.rangeUsers.unshift(obj)
            if (type !== 2) {
              this.editUserList.unshift(rangeObj)
            }
          } else {
            this.rangeUsers.push(obj)
            if (type !== 2) {
              this.editUserList.push(rangeObj)
            }
          }
        })
      })
    },
    // 查询提醒设置信息
    querySettingInfo() {
      const params = {
        setCycleId: this.cycleInfo.id
      }
      this.$apis.CYCLESETREMINDLIST(params).then((res) => {
        if (res.status === 200) {
          // 0填写提醒 1更新进度提醒 2复盘提醒
          const data = res.data
          if (data) {
            this.remindData = []
            const list = Object.keys(data)
            if (list.length === 0) return
            list.forEach((item) => {
              this.reminArr[item].remindObj = data[item]
              this.remindData = this.remindData.concat(data[item])
            })
            const dataObj = {
              1: '周期开始前',
              2: '周期开始后',
              3: '周期结束前',
              4: '周期结束后'
            }
            this.reminArr.forEach((item, index) => {
              if (item.remindObj.length > 0) {
                if (index !== 1) {
                  if (item.remindObj.length === 2) {
                    item.remindObj.sort((a, b) => {
                      return a.notifyTimeType - b.notifyTimeType
                    })
                    item.remindText = `${
                      dataObj[item.remindObj[0].notifyTimeType]
                    }第${item.remindObj[0].notifyDataNum
                      .split(',')
                      .join('、')}天 和 ${
                      dataObj[item.remindObj[1].notifyTimeType]
                    }第${item.remindObj[1].notifyDataNum
                      .split(',')
                      .join('、')}天
                      ${item.remindObj[0].notifyDataTimeNum}`
                  } else {
                    item.remindText = `${
                      dataObj[item.remindObj[0].notifyTimeType]
                    }第${item.remindObj[0].notifyDataNum
                      .split(',')
                      .join('、')}天 ${item.remindObj[0].notifyDataTimeNum}`
                  }
                }
                if (index === 1) {
                  if (item.remindObj[0].notifyDataType === 0) {
                    item.remindText = `每天 ${item.remindObj[0].notifyDataTimeNum}`
                  }
                  if (item.remindObj[0].notifyDataType === 1) {
                    const weekObj = {
                      1: '周日',
                      2: '周一',
                      3: '周二',
                      4: '周三',
                      5: '周四',
                      6: '周五',
                      7: '周六'
                    }
                    const str = item.remindObj[0].notifyDataNum
                      .split(',')
                      .map((item) => {
                        return weekObj[Number(item)]
                      })
                      .join('、')
                    item.remindText = `每${str} ${item.remindObj[0].notifyDataTimeNum}`
                  }
                  if (item.remindObj[0].notifyDataType === 2) {
                    item.remindText = `每 ${item.remindObj[0].notifyDataNum
                      .split(',')
                      .map((item) => `${item}日`)
                      .join('、')} ${item.remindObj[0].notifyDataTimeNum}`
                  }
                }
              }
            })
          }
        }
      })
    },
    // 查询提醒设置开关信息
    querySettingStateInfo() {
      const params = {
        setCycleId: this.cycleInfo.id
      }
      this.$apis.QUERYSETTINGSTATEINFO(params).then((res) => {
        if (res.status === 200) {
          // 0填写提醒 1更新进度提醒 2复盘提醒
          const data = res.data
          data.forEach((item) => {
            this.reminArr[item.notifyType].notifyState = ((item.notifyState === 0 ? true : false) || false)
            this.reminArr[item.notifyType].notifyId = item.id
          })
        }
      })
    },
    remindswitchSet(item) {
      const params = {
        setCycleId: this.cycleInfo.id,
        id: item.notifyId,
        notifyType: item.notifyType,
        notifyState: item.notifyState === true ? 0 : 1
      }
      this.$apis.QUERYSETCYCLESTATE(params).then((res) => {
        if (res.status === 200) {
          this.$message.success('修改成功')
        } else {
          this.$message.error(res.message)
          this.reminArr[item.notifyType].notifyState = (item.notifyState === true ? 1 : 0)
        }
      })
    },
    // 查询周期阶段设置
    queryStageInfo() {
      const params = {
        setCycleId: this.cycleInfo.id
      }
      this.$apis.QUERYCYCLESTAGE(params).then((res) => {
        if (res.status === 200) {
          if (res.data && res.data.length > 0) {
            res.data.forEach((item) => {
              // 填写阶段
              if (item.stageType === 0) {
                this.stageSetInfo[item.cycleInfoType].writeStage =
                  Object.assign(item, {
                    remindText: `截止于周期开始后${item.stageDataEndNum}${
                      this.dateType[item.stageDataEndNumType]
                    }`
                  })
              }
              // 推进阶段
              if (item.stageType === 1) {
                this.stageSetInfo[item.cycleInfoType].pushStage = Object.assign(
                  item,
                  {
                    remindText: `周期开始后第${item.stageDataStartNum}${
                      this.dateType[item.stageDataStartNumType]
                    }至周期结束前第${item.stageDataEndNum}${
                      this.dateType[item.stageDataEndNumType]
                    }`
                  }
                )
              }
              // 复盘阶段
              if (item.stageType === 2) {
                this.stageSetInfo[item.cycleInfoType].reviewStage =
                  Object.assign(item, {
                    remindText: `开始于周期结束前${item.stageDataStartNum}${
                      this.dateType[item.stageDataStartNumType]
                    }`
                  })
              }
            })
            // console.log('this.stageSetInfo', this.stageSetInfo)
          }
        }
      })
    },
    // 周期阶段设置
    stageInfoSet(list) {
      list.forEach((item) => {
        delete item.remindText
      })
      this.$apis.CYCLESTAGESET(list).then((res) => {
        if (res.status === 200) {
          this.stageVisible = false
          this.changeState = true
          this.queryStageInfo()
        }
      })
    },
    // 周期评分设置
    cycleScoreSet() {
      const params = {
        id: this.cycleInfo.id,
        scoreStatus: this.scoreConfig ? 1 : 0
      }
      this.$apis.CYCLESCORESET(params).then((res) => {
        if (res.status !== 200) {
          this.scoreConfig = !this.scoreConfig
        } else {
          this.changeState = true
        }
      })
    },
    // 查询角色列表
    queryRoleList() {
      this.$apis.ALLROLELIST().then((res) => {
        this.roleLists =
          res.data.map((item) => {
            item.checked = this.roleRange.some((role) => {
              return role.businessId === item.id
            })
            return item
          }) || []
      })
    },
    // 设置提醒
    async setCycleRemind(data) {
      // console.log(data)
      if (data.length > 0) {
        data.forEach((item) => {
          item.setCycleId = this.cycleInfo.id
        })
      }
      const paramData = data
      this.$apis.CYCLESETREMIND(paramData).then((res) => {
        this.querySettingInfo()
      })
    },
    // 设置周期可见范围
    async updateCycleRange() {
      const params = {
        id: this.cycleInfo.id,
        dataRange: this.form.dataRange
      }
      await this.$apis.SETCYLCERANGE(params).then((res) => {
        if (res.status === 200) {
          this.changeState = true
          if (this.form.dataRange === 1) {
            this.queryCycleRange()
          }
        }
      })
    },
    // 在周期中自己权限被更改
    delSelfHandle() {
      this.$emit('delSelf')
    },
    // 设置成员
    async updateCycleUser(range) {
      let needHandle = false
      const freshState = range.some((item) => {
        if (item.businessId === this.userInfo.id && item.businessPower !== 1) {
          needHandle = true
        }
        return item.businessId === this.userInfo.id
      })
      if (!freshState) {
        needHandle = true
      }
      const params = {
        id: this.cycleInfo.id,
        dataRange: this.form.dataRange,
        cycleInfoUserDtoList: range
      }
      await this.$apis.SETCYLCEPERSON(params).then((res) => {
        if (needHandle) {
          this.delSelfHandle()
        }
        this.queryCycleRange()
      })
    },
    // 设置成员权限
    async updateCyclePermission(businessPower, item) {
      const params = {
        id: item.dataId,
        businessPower: businessPower // 1:可管理2：不可见 3：仅查看 4：可编辑
      }
      await this.$apis.SETCYLCEPERSONPERMISSION(params).then((res) => {
        if (res.status === 200) {
          if (item.id === this.userInfo.id && businessPower !== 1) {
            this.delSelfHandle()
          }
        }
        if (res.status === 500) {
          this.$message({
            msg: '网络错误,稍后重试!',
            type: 'error'
          })
        } else if (res.status !== 200) {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 打开成员选择弹窗
    showUserDialog() {
      this.userMemberVisible = true
      // this.$emit('showUserDialog')
    },
    // 更改成员范围类型
    changeRangeType(type) {
      if (this.form.dataRange === type) return
      const typeText = type === 0 ? '全部成员可见' : '自定义范围'
      this.$confirm(`是否更改为${typeText}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'none',
        customClass: 'confirmBox',
        lockScroll: false
      }).then(() => {
        this.form.dataRange = type
        this.updateCycleRange()
      })
    },
    // 选择角色
    selectRole(role) {
      role.checked = !role.checked
    },
    // 确定选择角色成员
    confirmAddRoleRange() {
      if (this.$refs.roleCyclePop) {
        this.$refs.roleCyclePop.doClose()
      }
      const range = []
      this.roleLists.forEach((item) => {
        if (item.checked) {
          const obj = {
            businessId: item.id, // （部门id，用户id，角色id)
            businessType: 2, // 0:用户，1：部门 2:角色
            businessPower: 4 // 权限 1:可管理2：不可见 3：仅查看 4：可编辑
          }
          range.push(obj)
        }
      })
      this.updateCycleUser(this.departRange.concat(range))
    },
    // 确定选择周期成员
    saveUser(data) {
      // console.log(data)
      const range = data.map((item) => {
        return {
          businessId: item.userId, // （部门id，用户id，角色id)
          businessType: (item.departmentTitle || '') === '' ? 0 : 1, // 0:用户，1：部门 2:角色
          businessPower: item.businessPower // 权限 1:可管理2：不可见 3：仅查看 4：可编辑
        }
      })
      this.updateCycleUser(range.concat(this.roleRange))
    },
    delItem(rangeIndex) {
      const range = []
      this.rangeUsers.forEach((item, index) => {
        if (rangeIndex !== index) {
          const obj = {
            businessId: item.id, // （部门id，用户id，角色id)
            businessType: item.type, // 0:用户，1：部门 2:角色
            businessPower: item.businessPower // 权限 1:可管理2：不可见 3：仅查看 4：可编辑
          }
          range.push(obj)
        }
      })
      this.updateCycleUser(range)
    },
    // 打开提醒弹窗
    openRemind(data) {
      console.log('openRemind', data)
      if (data.notifyState === 0 || data.notifyState === true) {
        this[data.dialogTitle] = true
      }
    },
    handleClose() {
      if (this.changeState) {
        this.$emit('changeUpdate')
      }
      this.dialogVisible = false
      this.$emit('change', false)
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../assets/styles/dialog.less';
::v-deep .el-input__inner:hover {
  border-color: #3a70ff !important;
}
.dialog-content {
  padding: 0 16px 16px;
  height: 420px;
  overflow-y: auto;
  .form-item-cycle {
    display: flex;
    align-items: center;
  }
  .form-item {
    margin-top: 24px;
    .form-title {
      font-size: 16px;
      font-weight: 500;
      color: #14142b;
      margin-bottom: 16px;
    }
    .cycle-name-input {
      width: 324px;
      margin-left: 20px;
    }
    .set-radios {
      display: flex;
      align-items: center;
      .radio-item {
        cursor: pointer;
        margin-right: 40px;
        display: flex;
        align-items: center;
        color: #6e7491;
        .radio-type-icon {
          width: 16px;
          height: 16px;
          border: 2px solid #a0a3bd;
          border-radius: 50%;
          margin-right: 12px;
        }
        .select-radio {
          border: 3px solid #3a78f6;
        }
      }
    }
    .range-btns {
      margin-top: 24px;
      display: flex;
      align-items: center;
      .add-btn {
        cursor: pointer;
        color: #3a78f6;
        margin-right: 32px;
        &:hover {
          color: #95b6fa;
        }
      }
      .add-disable {
        opacity: 0.54;
      }
    }
    .user-content {
      margin-top: 8px;
      padding-left: 24px;
      max-height: 300px;
      overflow-y: auto;
      .s-b-y();
      .user-info {
        color: #a0a3bd;
        font-size: 12px;
        margin-bottom: 12px;
      }
      .user-item {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        color: #6e7491;
        .user-img {
          width: 32px;
          height: 32px;
          border-radius: 100% 100%;
          margin-right: 8px;
        }
        .depart-img {
          background: #3a78f6;
          color: #fff;
          font-weight: 700;
          letter-spacing: 10px;
          padding-left: 9px;
          overflow: hidden;
          line-height: 32px;
        }
        .role-img {
          background: #f89500;
        }
        .user-name {
          width: 120px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &:before {
            content: '';
            display: block;
          }
        }
        .permission {
          color: #444558;
          .owner-text {
            margin-left: 15px;
          }
        }
      }
    }
    .edit-btn {
      margin-left: 16px;
      color: #a0a3bd;
      cursor: not-allowed;
    }
    .edit-btn-true {
      color: #3a78f6;
      cursor: pointer;
      margin-left: 16px;
      &:hover {
        color: #005eff;
      }
    }
    .score-config {
      margin-left: 30px;
      .config-switch {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 500;
        color: #14142b;
      }
      .config-info {
        font-size: 12px;
        color: #6e7491;
        margin-top: 10px;
      }
    }
  }
  .remind-form {
    height: 220px;
    .remind-item {
      line-height: 16px;
      margin-bottom: 24px;
      margin-left: 30px;
      .remind-title {
        color: #444558;
        margin-bottom: 10px;
        .flex-bt();
      }
      .remind-time {
        color: #6e7491;
      }
    }
  }
  .stage-set {
    .stage-item {
      line-height: 16px;
      margin-left: 30px;
      &:last-of-type {
        margin-top: 16px;
      }
      .stage-name {
        font-weight: 500;
        color: #14142b;
      }
      .stage-item-name {
        margin-top: 12px;
        color: #6e7491;
        .satge-text {
          margin-right: 24px;
        }
      }
    }
    .edit-btn {
      font-size: 12px;
    }
  }
}
</style>
<style lang="less">
.cycle-setting-rolepop {
  .role-list {
    padding: 0 8px;
    max-height: 200px;
    .s-b-y();
    .role-item {
      width: 232px;
      height: 44px;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #14142b;
      border-radius: 8px;
      padding: 0 8px;
      cursor: pointer;
      .role-div {
        height: 32px;
        width: 32px;
        border-radius: 100% 100%;
        background: #f89500;
        color: #fff;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        margin-right: 8px;
      }
      .check-icon {
        font-size: 16px;
        margin-left: auto;
        color: #3a78f6;
      }
      &:hover {
        background: #f5f6fa;
      }
    }
  }
  .confirm-btn {
    width: 228px;
    height: 40px;
    cursor: pointer;
    background: #3a78f6;
    border-radius: 16px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    text-align: CENTER;
    line-height: 40px;
    margin-top: 8px;
    &:hover {
      background: #005eff;
    }
  }
}
</style>
