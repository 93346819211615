var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "key_results_li clearfix",
        class: _vm.showBorder ? "" : "key_results_li_no_border",
      },
      [
        _c("div", { staticClass: "key_results_li_left_box" }, [
          _c(
            "div",
            { staticClass: "key_result_dragger_icon_box" },
            [
              _c("Icon", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.targetObject.userRoleType === 0,
                    expression: "targetObject.userRoleType === 0",
                  },
                ],
                staticClass: "key_result_dragger_icon",
                attrs: { "icon-class": "icon-targetkrdragger" },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "li_key_results_ul_li_okr" },
            [
              _vm._v(" KR" + _vm._s(_vm.keyResultsLiNumber + 1) + " "),
              _vm.keyResultsLi.isVisible === 1 ||
              (_vm.keyResultsLi.objectivesPower &&
                _vm.keyResultsLi.objectivesPower.lookRulesType !== 0)
                ? _c("privateOkr", {
                    attrs: {
                      content: _vm.privateOkrContent(
                        _vm.targetObject.userRoleType === 0,
                        _vm.keyResultsLi,
                        1
                      ),
                      createUser: _vm.targetObject.userRoleType === 0,
                      visible:
                        _vm.keyResultsLi.isVisible === 1 ||
                        (_vm.keyResultsLi.objectivesPower &&
                          _vm.keyResultsLi.objectivesPower.lookRulesType !== 0),
                      type: 3,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "key_results_li_right_box" }, [
          _c("div", { staticClass: "li_key_results_ul_li_text" }, [
            _vm.keyResultsLi.titleStates
              ? _c(
                  "div",
                  {
                    staticClass: "li_key_results_ul_li_text1",
                    class:
                      _vm.targetObject.userRoleType === 0
                        ? "cursor-text"
                        : "cursor-no",
                    on: {
                      click: function ($event) {
                        return _vm.getKeyResultsListTitles()
                      },
                    },
                  },
                  [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          placement: "top",
                          "open-delay": 300,
                          content: "点击编辑",
                          disabled: _vm.targetObject.userRoleType !== 0,
                        },
                      },
                      [
                        _c("advice-html", {
                          attrs: {
                            html: !_vm.alignMember
                              ? _vm.$utils.changeToHtml(_vm.keyResultsLi.title)
                              : _vm.$utils.changeToHtmlUser(
                                  _vm.$utils.changeToOkrtFormat(
                                    _vm.keyResultsLi.title
                                  ),
                                  _vm.keyResultsLi,
                                  _vm.targetObject.childObjectivesAssociationUserList.concat(
                                    _vm.targetObject.objectivesCollaboratorList
                                  )
                                ),
                            show: _vm.targetObject.userRoleType === 0,
                            "dict-info":
                              _vm.keyResultsLi.objectivesRuleMatchDto,
                            listType: _vm.alignMember,
                            isOkr: _vm.pageType === "okrList",
                            businessType: 1,
                            dataDetail: _vm.keyResultsLi,
                          },
                          on: {
                            ignore: function ($event) {
                              return _vm.changeObjectiveTitle("修改成功")
                            },
                            okrDetail: _vm.getTopMembersAlignmentSelect,
                            toOKRList: _vm.getTopMembersFatherList,
                          },
                        }),
                        _vm.keyResultsLi.isVisible === 1
                          ? _c("privateOkr", {
                              attrs: {
                                createUser: _vm.targetObject.userRoleType === 0,
                                visible: _vm.keyResultsLi.isVisible === 1,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.keyResultsLi.titleStates
              ? _c(
                  "div",
                  { staticClass: "li_key_results_ul_li_text2" },
                  [
                    _c("key-results-add", {
                      attrs: {
                        "is-share": _vm.targetObject.isShare,
                        "is-edit": true,
                        "edit-info": _vm.keyResultsLi,
                        okrDataObj: _vm.targetObject,
                        fromType: _vm.fromType,
                      },
                      on: {
                        changeObjectiveTitle: _vm.changeObjectiveTitle,
                        getkeyResultsAddRefresh: _vm.getkeyResultsAddRefresh,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm.keyResultsLi.titleStates
            ? _c(
                "div",
                {
                  staticClass: "key_results_li_ul key-results-popver clearfix",
                },
                [
                  _c(
                    "autoProgressPopver",
                    {
                      attrs: {
                        okrData: _vm.targetObject,
                        item: _vm.keyResultsLi,
                        disabled: _vm.keyResultsLi.automationState !== 1,
                      },
                      on: { setAutoProcessClick: _vm.setAutoProcessClick },
                    },
                    [
                      _vm.keyResultsLi.quantificationType !== 1
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "key_results_li_ul_li key_results_li_ul_li3 clearfix",
                              class:
                                _vm.userInfo.id ===
                                _vm.targetObject.createUserid
                                  ? "key_results_li_ul_li_style"
                                  : "",
                              attrs: {
                                id:
                                  "keyResultsList" + _vm.keyResultsLi.id + "s",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.getHomeListMouseover(
                                    _vm.keyResultsLi.id
                                  )
                                },
                              },
                            },
                            [
                              _c("mini-process", {
                                attrs: {
                                  type: "circle",
                                  automationState:
                                    _vm.keyResultsLi.automationState,
                                  rate: _vm.keyResultsLi.progressRete,
                                },
                              }),
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    placement: "top",
                                    "open-delay": 300,
                                    content:
                                      _vm.targetObject.userRoleType === 0
                                        ? "KR进度"
                                        : `初始值${_vm.keyResultsLi.quantificationStart}${_vm.keyResultsLi.quantificationUnit}-目标值${_vm.keyResultsLi.quantificationEnd}${_vm.keyResultsLi.quantificationUnit}`,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "key_results_li_ul_li_text",
                                      class: _vm.keyResultsAlertState
                                        ? "active_text"
                                        : "",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.keyResultsLi
                                              .quantificationActualValue
                                          ) +
                                          _vm._s(
                                            _vm.keyResultsLi.quantificationUnit
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "key_results_li_ul_li key_results_li_ul_li3 clearfix",
                              class:
                                _vm.targetObject.userRoleType === 0
                                  ? "key_results_li_ul_li_style"
                                  : "",
                              attrs: {
                                id:
                                  "keyResultsList" + _vm.keyResultsLi.id + "s",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.getHomeListMouseover(
                                    _vm.keyResultsLi.id
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    placement: "top",
                                    "open-delay": 300,
                                    content:
                                      _vm.targetObject.userRoleType === 0
                                        ? "KR进度"
                                        : "未完成-已完成",
                                  },
                                },
                                [
                                  _c("span", [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.keyResultsLi
                                                .quantificationStatus !== 1,
                                            expression:
                                              "keyResultsLi.quantificationStatus !== 1",
                                          },
                                        ],
                                        staticClass: "key-result-style",
                                      },
                                      [
                                        _c("Icon", {
                                          staticClass: "select-icon",
                                          attrs: {
                                            "icon-class": "icon-not-finish",
                                          },
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "key_results_li_ul_li_text",
                                            class: _vm.keyResultsAlertState
                                              ? "active_text"
                                              : "",
                                          },
                                          [_vm._v("未完成")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.keyResultsLi
                                                .quantificationStatus === 1,
                                            expression:
                                              "keyResultsLi.quantificationStatus === 1",
                                          },
                                        ],
                                        staticClass: "key-result-style",
                                      },
                                      [
                                        _c("Icon", {
                                          staticClass: "select-icon",
                                          attrs: {
                                            "icon-class": "icon-finished",
                                          },
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "key_results_li_ul_li_text",
                                            class: _vm.keyResultsAlertState
                                              ? "active_text"
                                              : "",
                                          },
                                          [_vm._v("已完成")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                    ]
                  ),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        placement: "top",
                        "open-delay": 300,
                        content: "KR权重",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "key_results_li_ul_li2" },
                        [
                          _vm.keyResultsLi.weightStates
                            ? _c(
                                "div",
                                {
                                  staticClass: "key_results_li_ul_li clearfix",
                                  class:
                                    _vm.targetObject.userRoleType === 0
                                      ? "key_results_li_ul_li_style"
                                      : "",
                                  staticStyle: { width: "100%" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getKeyResultsListWeights(
                                        _vm.keyResultsLiNumber
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("Icon", {
                                    staticClass: "key_results_li_ul_li_icon",
                                    attrs: { "icon-class": "icon-weight" },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "key_results_li_ul_li_text",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.keyResultsLi.weight) + "%"
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _c("el-input", {
                                ref:
                                  "keyResultsListInput" + _vm.keyResultsLi.id,
                                staticClass: "input-focus",
                                attrs: { type: "text", autofocus: "" },
                                on: {
                                  blur: function ($event) {
                                    return _vm.getKeyHandleChangeBlur(
                                      _vm.keyResultsLiNumber
                                    )
                                  },
                                  input: function ($event) {
                                    return _vm.getKeyHandleChange(
                                      _vm.keyResultsLiNumber
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.keyResultsLi.weight,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.keyResultsLi, "weight", $$v)
                                  },
                                  expression: "keyResultsLi.weight",
                                },
                              }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "span",
                    { staticStyle: { width: "22%" } },
                    [
                      (_vm.pageType === "okrList" &&
                        _vm.objIndex === 0 &&
                        _vm.keyResultsLiNumber === 0) ||
                      (_vm.pageType === "okrMap" &&
                        _vm.keyResultsLiNumber === 0)
                        ? _c(
                            "guide-popover",
                            {
                              ref: "nextTargetPop",
                              attrs: {
                                "pop-key":
                                  _vm.pageType === "okrList"
                                    ? "okrGuideListPopover"
                                    : "okrGuideMapPopover",
                                title: "KR任务",
                                "hide-click-emit": true,
                                "animate-state": true,
                                "inner-text": "点击查看关联KR的任务",
                                placement: "bottom",
                              },
                              on: {
                                hideClick: function ($event) {
                                  return _vm.showTaskList(_vm.keyResultsLi.id)
                                },
                              },
                            },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    slot: "guidepop",
                                    placement: "top",
                                    "open-delay": 300,
                                    content: "KR任务",
                                  },
                                  slot: "guidepop",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "key_results_li_ul_li key_results_li_ul_li3 key_results_li_ul_li_style",
                                      class:
                                        _vm.selectKrValue ===
                                        _vm.keyResultsLi.id
                                          ? ""
                                          : "active-li",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.showTaskList(
                                            _vm.keyResultsLi.id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("Icon", {
                                        staticClass:
                                          "key_results_li_ul_li_icon",
                                        attrs: { "icon-class": "icon-task" },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "key_results_li_ul_li_text2",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.keyResultsLi
                                                  .taskFinishCount || 0
                                              ) +
                                              "/" +
                                              _vm._s(
                                                _vm.keyResultsLi.taskCount || 0
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "el-tooltip",
                            {
                              attrs: {
                                placement: "top",
                                "open-delay": 300,
                                content: "KR任务",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "key_results_li_ul_li key_results_li_ul_li3 key_results_li_ul_li_style",
                                  class:
                                    _vm.selectKrValue === _vm.keyResultsLi.id
                                      ? ""
                                      : "active-li",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.showTaskList(
                                        _vm.keyResultsLi.id
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("Icon", {
                                    staticClass: "key_results_li_ul_li_icon",
                                    attrs: { "icon-class": "icon-task" },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "key_results_li_ul_li_text2",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.keyResultsLi.taskFinishCount ||
                                              0
                                          ) +
                                          "/" +
                                          _vm._s(
                                            _vm.keyResultsLi.taskCount || 0
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                    ],
                    1
                  ),
                  _vm.targetObject.canBeScored === 1
                    ? _c(
                        "el-tooltip",
                        {
                          attrs: {
                            placement: "top",
                            "open-delay": 300,
                            content: "KR评分",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "key_results_li_ul_li2" },
                            [
                              _vm.keyResultsLi.scoreStates
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "key_results_li_ul_li clearfix",
                                      class:
                                        _vm.targetObject.userRoleType === 0
                                          ? "key_results_li_ul_li_style"
                                          : "",
                                      staticStyle: { width: "100%" },
                                      on: { click: _vm.showKeyResultScore },
                                    },
                                    [
                                      _c("Icon", {
                                        staticClass:
                                          "key_results_li_ul_li_icon",
                                        attrs: {
                                          "icon-class": "icon-pingfen1",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "key_results_li_ul_li_text",
                                          class:
                                            _vm.targetObject.userRoleType === 0
                                              ? "score-text"
                                              : "",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.keyResultsLi.score ||
                                                  _vm.keyResultsLi.score === 0
                                                  ? `${_vm.keyResultsLi.score}`
                                                  : "待评分"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _c("el-input", {
                                    ref:
                                      "keyResultsListScore" +
                                      _vm.keyResultsLi.id,
                                    staticClass: "input-focus",
                                    attrs: {
                                      autofocus: "",
                                      placeholder: "0-10",
                                      type: "text",
                                    },
                                    on: {
                                      blur: function ($event) {
                                        return _vm.getKeyScoreChangeBlur(
                                          _vm.keyResultsLiNumber
                                        )
                                      },
                                      input: function ($event) {
                                        return _vm.getKeyScoreChange(
                                          _vm.keyResultsLiNumber
                                        )
                                      },
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.getKeyScoreChangeBlur(
                                          _vm.keyResultsLiNumber
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.keyResultsLi.score,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.keyResultsLi, "score", $$v)
                                      },
                                      expression: "keyResultsLi.score",
                                    },
                                  }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]),
        _vm.targetObject.userRoleType === 0
          ? _c("div", { staticClass: "key_results_li_detele" }, [
              _c("i", {
                staticClass: "iconfont ymbguanbianniu",
                on: {
                  click: function ($event) {
                    return _vm.getTargetDetailsKeywordDetele(_vm.itemKeyresults)
                  },
                },
              }),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }