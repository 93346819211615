const versionNumber = 'v1' // 版本
// 统计分析模块api
export default [
  {
  // 获取个人统计分析报表
  personReportList: `/api/analysis/${versionNumber}/report/person/list`
},
{
  // 获取单个报表统计
  getReports: `/api/analysis/${versionNumber}/report/condition/`
},
{
  // 报表排序
  reportSort: `/api/analysis/${versionNumber}/report/sort`
},
{
  // 删除单个报表
  reportDelete: `/api/analysis/${versionNumber}/report/delete`
},
{
  // 添加报表
  reportAdd: `/api/analysis/${versionNumber}/report/add`
},
{
  // 获取可用的报表列表
  reportList: `/api/analysis/${versionNumber}/report/list`
},
{
  // 下载报表
  reportDownload: `/api/analysis/${versionNumber}/report/condition/download`
},
{
  // 更新报表
  reportUpdate: `/api/analysis/${versionNumber}/report/condition/update`
},
{
  // 获取周期内人员部门列表
  departList: `/api/admin/${versionNumber}/depart/list`
},
{
  // 获取报表详情
  reportDetail: `/api/analysis/${versionNumber}/report/condition/info/`
},
{
  // 获取报表详情-带分页功能
  reportDetailPage: `/api/analysis/${versionNumber}/report/condition/info/page`
},
{
  // 获取企业统计分析报表列表
  reportCompanyList: `/api/analysis/${versionNumber}/report/company/list/`
},
{
  // 获取企业统计分析单个报表概览
  reportCompanyView: `/api/analysis/${versionNumber}/report/company/view/`
},
{
  // 获取企业统计分析单个报表详情
  reportCompanyInfo: `/api/analysis/${versionNumber}/report/company/info/`
},
{
  // 风险预警提醒列表（我收到的）
  reportRemindSend: `/api/notify/${versionNumber}/notify/warning/remind/send`
},
{
  // 获取企业报表详情-带分页功能
  reportCompanyDetailPage: `/api/analysis/${versionNumber}/report/company/info/page/`
},
{
  // 预警提醒设置列表
  remindSetList: `/api/notify/${versionNumber}/notify/remind/set/list`
},
{
  // 预警提醒设置
  remindSet: `/api/notify/${versionNumber}/notify/remind/set/`
},
{
  // 处理提醒
  remindDisposeHandle: `/api/notify/${versionNumber}/notify/warning/remind/dispose`
},
{
  // 获取部门统计分析报表列表
  reportDepartList: `/api/analysis/${versionNumber}/report/department/list/`
},
{
  // 获取部门统计分析单个报表概览
  reportDepartView: `/api/analysis/${versionNumber}/report/department/view/`
},
{
  // 获取部门统计分析单个报表详情
  reportDepartInfo: `/api/analysis/${versionNumber}/report/department/info/`
},
{
  // 获取部门报表详情-带分页功能
  reportDepartDetailPage: `/api/analysis/${versionNumber}/report/department/info/page/`
},
{
  // 获取统计分析成员详情数据-分页
  reportRemindUserInfoTable: `/api/analysis/${versionNumber}/report/company/departmentUserInfo/list/`
},
{
  // 根据index查询模版默认 标题 内容
  remindSetDefault: `/api/notify/${versionNumber}/notify/remind/set/default`
},
{
  // 预警忽略
  remindSetIgnore: `/api/notify/${versionNumber}/notify/remind/set/ignore`
},
{
  // 工作台风险预警成员详情页-分页
  remindWorkbenchUser: `/api/analysis/${versionNumber}/report/company/riskWarning/departmentUserInfo/list/`
},
{
  // 部门下载报表
  departmentDownload: `/api/analysis/${versionNumber}/report/department/download/`
},
{
  // 企业下载报表
  companyDownload: `/api/analysis/${versionNumber}/report/company/download/`
},
{
  // 成员详情页-2-下载成员详情数据
  departmentUserInfoDownload: `/api/analysis/${versionNumber}/report/company/departmentUserInfo/download/`
},
{
  // 工作台风险预警2-下载成员详情数据
  workUserInfoDownload: `/api/analysis/${versionNumber}/report/company/riskWarning/departmentUserInfo/download/`
},
{
  // 根据周期id和人员id 查询目标列表
  tablePersonOkrList: `/api/ranloWeb/${versionNumber}/objectives/analysis/obj/list`
},
{
  // 企微-查询个人各类统计报表导出(通讯录转译)数据列表-分页
  reportDataExportList: `/api/analysis/${versionNumber}/report/dataExport/list/`
},
{
  // 组织架构人员--导出
  adminDepartmentUserExport: `/api/analysis/${versionNumber}/admin/department/user/export/`
}
]
