<template>
  <div
    :class="`type-wrap`"
    :style="{
      background: classType === 'kpiPlan' ? kpiPlanStatusList[type].bgColor : kpiStatusList[type].bgColor
    }"
  >{{classType === 'kpiPlan' ? kpiPlanStatusList[type].name : kpiStatusList[type].name}}</div>
</template>
<script>
import { KPIPLANSTATUSLIST, KPISTATUSLIST } from '@/views/kpiV2/dict.js'
export default {
  components: {
  },
  props: {
    type: {
      default: 0
    },
    classType: {
      default: 'kpiPlan'
    }
  },
  data() {
    return {
      kpiPlanStatusList: KPIPLANSTATUSLIST,
      kpiStatusList: KPISTATUSLIST
    }
  },
  computed: {},
  created() {
  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
.type-wrap {
  font-size: 12px;
  height: 22px;
  line-height: 22px;
  border-radius: 11px;
  // padding: 0px 12px;
  text-align: center;
  color: #fff;
  min-width: 60px;
}
</style>
