import vueloading from './loading'
import DefaultPage from './default-page'
import pageloading from './pageloading'
import scrollBottom from './scroll-bottom'
import preventReClick from './preventReClick'
import clearTabIndex from './clearTabIndex'
import selectScrollBottom from './select-scroll-bottom'
export default [
  vueloading,
  DefaultPage,
  pageloading,
  scrollBottom,
  preventReClick,
  clearTabIndex,
  selectScrollBottom
]
