<template>
  <el-dialog
    title="转化为子任务"
    :visible.sync="isShow"
    :before-close="close"
    append-to-body
    :width="'720px'"
  >
    <div class="search-wrap" slot="title">
      <div class="title">转化为子任务</div>
      <el-input
        placeholder="请输入任务标题 搜索任务"
        prefix-icon="el-icon-search"
        v-model="searchName"
        @keyup.enter.native="searchEnter"
      />
      <el-select v-model="projectId" placeholder="请选择项目" width="200">
        <el-option
          v-for="item in allProjectList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
    </div>
    <div class="main-wrap">
      <div class="task-list-wrap" v-vueloading="loading" v-scroll-bottom="loadMoreList">
        <task-item
          v-for="(item, index) in taskList"
          :key="item.id + item.checked"
          :index="index"
          :item="item"
          @selectClick="clickChange"
        />
        <div v-if="taskList.length === 0 && !loading" class="no-content">
          <noData />
        </div>
      </div>
      <div class="bottom-step-buttons">
        <button @click="close" class="left-button">取消</button>
        <button @click="save">完成</button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import taskItem from '@/views/conclusion/components/ConclusionDetail/taskItem.vue'
export default {
  components: { taskItem },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isShow: this.visible,
      searchName: '',
      loading: false,
      allProjectList: [],
      projectId: '',
      taskList: [],
      filterData: {
        status: '',
        keyword: '',
        pageNum: 1,
        pageSize: 20,
        dateSort: 6
      },
      currentTaskList: [],
      getDataState: true
    }
  },
  computed: {},
  watch: {},
  created() {
    this.queryProjectList()
    this.filterData.pageNum = 1
    var url = `?pageNum=${this.filterData.pageNum}&pageSize=20`
    this.getList(url, this.filterData)
  },
  mounted() {},
  methods: {
    save() {

    },
    close() {
      this.visible = false
      this.$emit('change', false)
    },
    searchEnter() {
    },
    // 获取项目列表
    async queryProjectList() {
      this.$apis
        .KRATTACHALLPROJECTS()
        .then((res) => {
          this.loading = true
          if (res.status === 200) {
            this.allProjectList = res.data.filter((item) => {
              return item.status === 0 // 状态（0 正常 1 归档）
            })
            this.allProjectList = JSON.parse(JSON.stringify(this.allProjectList))
          } else {
            this.$message({
              msg: '网络错误,稍后重试!',
              type: 'error'
            })
          }
        })
        .catch(() => {
          this.loading = true
        })
    },
    loadMoreList() {
      if (this.taskList.length < this.taskTotal && !this.loading) {
        var url = `?pageNum=${this.filterData.pageNum}&pageSize=20`
        this.loading = true
        this.getList(url, this.filterData)
      }
    },
    // 获取列表
    getList(url, data) {
      this.getDataState = false
      this.$apis.CONCLUSIONSUMMARIZETASKLIST(url, data).then((res) => {
        if (res.status === 200) {
          this.currentTaskList.forEach((selectItem) => {
            res.data.rows.forEach((item) => {
              if (item.id === selectItem.id) {
                item.checked = true
              }
            })
          })
          if (this.filterData.pageNum === 1) {
            this.taskList = []
          }
          this.taskList = JSON.parse(JSON.stringify(this.taskList.concat(res.data.rows || [])))
          this.taskTotal = res.data.total || 0
          this.filterData.pageNum = ++this.filterData.pageNum
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
        this.loading = false
        this.getDataState = true
      })
    },
    // 点击任务
    clickChange(index, item) {
      this.taskList.forEach((list, index) => {
        list.checked = false
      })
      this.currentTaskList = [item]
      item.checked = true
      this.taskList[index] = JSON.parse(JSON.stringify(item))
      this.taskList = JSON.parse(JSON.stringify(this.taskList))
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../../assets/styles/dialog.less';
.bottom-step-buttons {
  margin-top: 32px;
}
::v-deep {
  .el-dialog__body {
    padding: 8px 24px 32px 24px;
  }
  .el-dialog__headerbtn {
    top: 35px !important;
  }
}
.search-wrap {
  .flex-bt();
  .title {
    font-weight: bold;
    color: #14142b;
    font-size: 24px;
    line-height: 32px;
  }
  ::v-deep {
    .el-input--prefix {
      width: 220px;
    }
    .el-select {
      margin: 0px 40px 0px 16px;
      width: 220px;
    }
  }
}
.main-wrap {
  height: calc(70vh - 60px);
  display: flex;
  flex-direction: column;
  .task-list-wrap {
    flex: 1;
    overflow-y: auto;
  }
}
</style>

