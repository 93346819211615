/* eslint-disable */
import Vue from 'vue'
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
dayjs.extend(weekday)
class Day {
  // 根据星期几获取日期
  getDateByWeek(val, fmt = 'YYYY-MM-DD') {
    val = val || dayjs().day()
    return dayjs().weekday(val).format(fmt)
  }
  // 获取日期
  getDate(val = Date(), fmt = 'YYYY-MM-DD') {
    return dayjs(val).format(fmt)
  }
  // 一个时间是否在另一个时间之前
  isBefore(date1, date2) {
    return dayjs(date1).isBefore(dayjs(date2))
  }
  // 时间差（天数）
  diffDay(date1, date2) {
    return dayjs(date1).diff(dayjs(date2), 'day')
  }
  // 时间差（小时）
  diffHours(date1, date2) {
    return dayjs(date2).diff(dayjs(date1), 'hours')
  }

  // 获取今天凌晨时间戳
  getDawn(date = new Date()) {
    return dayjs(date).startOf('day').valueOf()
  }
  // 查询这个月的最后一天
  monthLastDay(date = new Date()) {
    const handleDate = new Date(date)
    const year = handleDate.getFullYear()
    let month = handleDate.getMonth()
    console.log(month)
    var new_year = year // 取当前的年份
    var new_month = month++ // 取下一个月的第一天，方便计算（最后一天不固定）
    if (month > 12) {
      new_month -= 12 // 月份减
      new_year++ // 年份增
    }
    var new_date = new Date(new_year, new_month, 1) // 取当年当月中的第一天
    return new Date(new_date.getTime() - 1000 * 60 * 60 * 24).getDate() // 获取当月最后一天日期
  }
}

Vue.prototype.$day = new Day()

export default new Day()
