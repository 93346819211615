import Vue from 'vue'
import 'xe-utils'
import {
  // VXETable,
  Table,
  Column,
  Colgroup
} from 'vxe-table'

// 表格模块
Vue.use(Column)
Vue.use(Colgroup)
// 安装表格
Vue.use(Table)
// import Vue from 'vue'
// import 'xe-utils'
// import VXETable from 'vxe-table'
// import 'vxe-table/lib/style.css'
// Vue.use(VXETable)
