import Vue from 'vue'
import { MessageBox } from 'element-ui' // element-ui独立修改

const element = {
  // 确认框
  confirm(msg, title, confirmButtonText, cancelButtonText) {
    return new Promise((resolve) => {
      MessageBox.confirm(msg, title || '提示', {
        confirmButtonText: confirmButtonText || '确定',
        cancelButtonText: cancelButtonText || '取消',
        type: 'none',
        customClass: 'confirmBox',
        lockScroll: false
      })
        .then(() => {
          resolve(true)
        })
        .catch(() => {
          resolve(false)
        })
    })
  }
}

Vue.prototype.$element = element
