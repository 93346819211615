var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    _vm._g(
      {
        staticClass: "apply-demo-dialog",
        attrs: {
          visible: _vm.centerDialogVisible,
          width: _vm.width,
          top: _vm.top,
          "append-to-body": true,
          "close-on-click-modal": false,
          "destroy-on-close": true,
          "show-close": _vm.showClo,
        },
        on: {
          "update:visible": function ($event) {
            _vm.centerDialogVisible = $event
          },
          "update:showClose": function ($event) {
            _vm.showClo = $event
          },
          "update:show-close": function ($event) {
            _vm.showClo = $event
          },
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "div",
        {
          staticClass: "dialog-title-box",
          attrs: { slot: "title" },
          slot: "title",
        },
        [
          _c("div", { staticClass: "flex-a-c-center-one" }, [
            _vm.companyHistoryState
              ? _c(
                  "div",
                  {
                    staticClass: "return",
                    on: {
                      click: function ($event) {
                        return _vm.companyHistoryClick(false)
                      },
                    },
                  },
                  [
                    _c("Icon", {
                      staticClass: "icon",
                      attrs: { "icon-class": "icon-fanhuijiantou" },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c("p", {
              staticClass: "title-txt",
              domProps: {
                textContent: _vm._s(
                  _vm.companyHistoryState ? _vm.historyTitle : _vm.title
                ),
              },
            }),
          ]),
        ]
      ),
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        !_vm.companyHistoryState
          ? _c("div", { key: _vm.companyHistoryState }, [
              _c("div", { staticClass: "home_box" }, [
                _vm.statusPassSms == 2
                  ? _c("div", { staticClass: "register_box" }, [
                      _c("p", { staticClass: "register_text" }, [
                        _vm._v(" 你的帐号已加入以下 "),
                        _c("label", { staticStyle: { color: "#4c86ff" } }, [
                          _vm._v(_vm._s(_vm.enterpriseArrslength)),
                        ]),
                        _vm._v("个企业，点击可直接进入 "),
                      ]),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "vueloading",
                              rawName: "v-vueloading",
                              value: !_vm.enterpriseArrslengthState,
                              expression: "!enterpriseArrslengthState",
                            },
                          ],
                          staticClass: "register_enterprise_out",
                        },
                        [
                          _c(
                            "transition",
                            {
                              attrs: { name: "fade-transform", mode: "out-in" },
                            },
                            [
                              _vm.enterpriseArrslength > 0
                                ? _c(
                                    "ul",
                                    _vm._l(
                                      _vm.enterpriseArrs,
                                      function (item, index) {
                                        return _c(
                                          "li",
                                          {
                                            key: item.id,
                                            staticClass: "ellipsis-1",
                                            class: {
                                              li_hover: index == _vm.hoverIndex,
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.focusTogether(
                                                  item.id,
                                                  item.name,
                                                  1
                                                )
                                              },
                                              mouseover: function ($event) {
                                                _vm.hoverIndex = index
                                              },
                                              mouseout: function ($event) {
                                                _vm.hoverIndex = -1
                                              },
                                            },
                                          },
                                          [
                                            _c("div", { staticClass: "left" }, [
                                              _c(
                                                "div",
                                                { staticClass: "logo" },
                                                [
                                                  _c("el-image", {
                                                    staticClass: "img",
                                                    attrs: {
                                                      src:
                                                        item.logo ||
                                                        require("@/assets/imgs/common/avatar.png"),
                                                      fit: "cover",
                                                    },
                                                  }),
                                                  _vm.type !== 2 &&
                                                  item.notifyNoReadCount > 0
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass: "number",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.notifyNoReadCount >
                                                                  99
                                                                  ? "···"
                                                                  : item.notifyNoReadCount
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "name",
                                                  class:
                                                    item.userState === 0
                                                      ? "name-color"
                                                      : "",
                                                },
                                                [_vm._v(_vm._s(item.name))]
                                              ),
                                            ]),
                                            _c(
                                              "div",
                                              { staticClass: "right" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.userState === 0
                                                        ? "待激活"
                                                        : item.userState === 2
                                                        ? "已停用"
                                                        : item.userState === 3
                                                        ? "不在可见范围"
                                                        : item.userState === 4
                                                        ? "已离职"
                                                        : "已加入"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.enterpriseArrslength <= 0 &&
                      _vm.enterpriseArrslengthState
                        ? _c(
                            "div",
                            { staticClass: "companyNull" },
                            [
                              _c("noData"),
                              _c("p", { staticClass: "title" }, [
                                _vm._v(
                                  "当前还未加入任何组织，您可以创建属于自己的组织"
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm.statusPassSms == 3 || _vm.statusPassSms == 4
                  ? _c("div", { staticClass: "register_box" }, [
                      _vm.fromPage !== 2
                        ? _c(
                            "p",
                            {
                              staticClass: "register_text_back",
                              on: {
                                click: function ($event) {
                                  return _vm.next_go_back(2)
                                },
                              },
                            },
                            [
                              _c("Icon", {
                                staticClass: "icon-left",
                                attrs: { "icon-class": "icon-left" },
                              }),
                              _vm._v("返回 "),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("p", { staticClass: "register_title" }, [
                        _vm._v("创建团队"),
                      ]),
                      _c("p", { staticClass: "register_text" }, [
                        _vm._v(
                          "团队可以是公司，也可以是项目组或其他协作组织。为方便大家与你联系，请务必填写真实信息。"
                        ),
                      ]),
                      _c(
                        "fieldset",
                        { staticClass: "register_fieldset_ctrate" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.realGroupName,
                                expression: "realGroupName",
                              },
                            ],
                            staticClass: "register_input",
                            attrs: {
                              type: "text",
                              placeholder: "请输入团队名称",
                            },
                            domProps: { value: _vm.realGroupName },
                            on: {
                              input: [
                                function ($event) {
                                  if ($event.target.composing) return
                                  _vm.realGroupName = $event.target.value
                                },
                                _vm.teamInput,
                              ],
                              blur: _vm.realGroupNameBlur,
                            },
                          }),
                          _c(
                            "p",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.status_groupName == true,
                                  expression: "status_groupName == true",
                                },
                              ],
                              staticClass: "register_message",
                            },
                            [_vm._v(_vm._s(_vm.status_message_groupName))]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "div",
                {
                  staticStyle: { overflow: "hidden", "padding-top": "10px" },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("div", { staticClass: "foot-title" }, [
                    _c("span", [_vm._v("OR")]),
                  ]),
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.statusPassSms == 2,
                          expression: "statusPassSms == 2",
                        },
                      ],
                      staticClass:
                        "register_next common_btn_next common_btn_next_able",
                      attrs: { type: "button" },
                      on: { click: _vm.create_enterprise_btn },
                    },
                    [_vm._v("创建企业")]
                  ),
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.statusPassSms == 3,
                          expression: "statusPassSms == 3",
                        },
                      ],
                      staticClass:
                        "register_next common_btn_next common_btn_next_able",
                      attrs: { type: "button" },
                      on: { click: _vm.enter_ranle_btn },
                    },
                    [_vm._v("创建团队并进入")]
                  ),
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.statusPassSms == 4,
                          expression: "statusPassSms == 4",
                        },
                      ],
                      staticClass:
                        "register_next common_btn_next common_btn_next_unable",
                      attrs: { type: "button" },
                    },
                    [_vm._v("创建团队并进入")]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.statusPassSms == 2,
                          expression: "statusPassSms == 2",
                        },
                      ],
                      staticClass: "company-history",
                      on: {
                        click: function ($event) {
                          return _vm.companyHistoryClick(true)
                        },
                      },
                    },
                    [_vm._v("申请加入企业历史")]
                  ),
                ]
              ),
            ])
          : _vm._e(),
        _vm.companyHistoryState
          ? _c(
              "div",
              {
                key: _vm.companyHistoryState,
                staticClass: "home_box company-history-wrap",
              },
              [
                _c("div", { staticClass: "register_box" }, [
                  _vm.companyHistoryList.length > 0
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading",
                            },
                            {
                              name: "scroll-bottom",
                              rawName: "v-scroll-bottom",
                              value: _vm.$loadMoreList,
                              expression: "$loadMoreList",
                            },
                          ],
                          staticClass: "register_enterprise_out",
                        },
                        [
                          _c(
                            "ul",
                            _vm._l(_vm.companyHistoryList, function (item) {
                              return _c(
                                "li",
                                { key: item.id, staticClass: "ellipsis-1" },
                                [
                                  _c("div", { staticClass: "left" }, [
                                    _c(
                                      "div",
                                      { staticClass: "logo" },
                                      [
                                        _c("el-image", {
                                          staticClass: "img",
                                          attrs: {
                                            src:
                                              item.logo ||
                                              require("@/assets/imgs/common/avatar.png"),
                                            fit: "cover",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "name" }, [
                                      _vm._v(_vm._s(item.companyName)),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "right" }, [
                                    _c("div", { staticClass: "date" }, [
                                      _vm._v(_vm._s(item.createDate)),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "state",
                                        class: `state${item.approveType}`,
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.approveType === 0
                                                ? "待审核"
                                                : item.approveType === 1
                                                ? "已通过"
                                                : item.approveType === 2
                                                ? "已拒绝"
                                                : "已加入"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.companyHistoryList.length <= 0 &&
                  _vm.companyHistoryListState
                    ? _c(
                        "div",
                        { staticClass: "companyNull" },
                        [
                          _c("noData"),
                          _c("p", { staticClass: "title" }, [
                            _vm._v("暂无申请加入历史记录"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }