<template>
  <div ref="chart" class="baseChart" :style="styles"></div>
</template>
<script>
import { addListener, removeListener } from 'resize-detector'
import { debounce } from 'lodash'
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
// import * as echarts from 'echarts/core'
// 水波插件
// import 'echarts-liquidfill'
// 引入柱状图图表，图表后缀都为 Chart
// import { BarChart, LineChart, PieChart } from 'echarts/charts'
// // 引入提示框，标题，直角坐标系组件，组件后缀都为 Component
// import {
//   TitleComponent,
//   TooltipComponent,
//   GridComponent,
//   LegendComponent,
//   ToolboxComponent,
//   MarkLineComponent
// } from 'echarts/components'
// // 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
// import { CanvasRenderer } from 'echarts/renderers'
// 注册必须的组件
// echarts.use([
//   TitleComponent,
//   TooltipComponent,
//   GridComponent,
//   BarChart,
//   CanvasRenderer,
//   LegendComponent,
//   LineChart,
//   PieChart,
//   ToolboxComponent,
//   MarkLineComponent
// ])
export default {
  name: 'BaseChart',
  props: {
    option: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      styles: ''
    }
  },
  watch: {
    option(val) {
      this.chart.setOption(val)
    }
  },
  created() {
    this.resize = debounce(this.resize, 300)
  },
  mounted() {
    this.chart = echarts.init(this.$refs.chart)
    addListener(this.$refs.chart, this.resize)
  },
  beforeDestroy() {
    removeListener(this.$refs.chart, this.resize)
    this.chart.dispose()
  },
  methods: {
    renderChart(option) {
      var that = this
      if (option.selfStyle) {
        this.styles = option.selfStyle
      }
      this.chart.setOption(option)

      // legend分页阻止冒泡
      this.chart.on('legendScroll', function () {
        event.stopPropagation()
      })
      // 阻止legend事件冒泡
      this.chart.on('legendselectchanged', function (params) {
        console.log('legendselectchanged', params)
        that.$emit('legendselectchanged', params)
        event.stopPropagation()
      })
      this.chart.on('click', function (params) {
        console.log('echartClick', params)
        that.$emit('echartClick', params)
      })
    },
    resize() {
      this.chart.resize()
    }
  }
}
</script>
<style lang="sass" scoped></style>
