const DYNAMICTYPE = {
  1: {
    action: '发表了新评论：',
    icon: ''
  },
  2: {
    action: '添加了任务',
    icon: 'icon-add-round'
  },
  3: {
    action: '修改了任务名称',
    icon: 'icon-edit-simple'
  },
  4: {
    action: '修改了任务状态为',
    icon: 'icon-edit-simple'
  },
  5: {
    action: '添加了【姓名】为负责人',
    icon: 'icon-edit-simple'
  },
  6: {
    action: '修改了任务开始时间',
    icon: 'icon-edit-simple'
  },
  7: {
    action: '修改了任务截止时间',
    icon: 'icon-edit-simple'
  },
  8: {
    action: '修改了任务重复时间',
    icon: 'icon-edit-simple'
  },
  9: {
    action: '修改了任务提醒时间',
    icon: 'icon-edit-simple'
  },
  10: {
    action: '修改了任务优先级为',
    icon: 'icon-edit-simple'
  },
  11: {
    action: '删除了协作人【姓名】',
    icon: 'icon-delete-simple'
  },
  12: {
    action: '添加了【姓名】为协作人',
    icon: 'icon-add-round'
  },
  13: {
    action: '修改了任务描述',
    icon: 'icon-edit-simple'
  },
  14: {
    action: '将任务关联了关键结果',
    icon: 'icon-taskshu'
  },
  15: {
    action: '删除了任务',
    icon: 'icon-delete-simple'
  },
  16: {
    action: '删除了任务开始时间',
    icon: 'icon-delete-simple'
  },
  17: {
    action: '删除了任务截止时间',
    icon: 'icon-delete-simple'
  },
  18: {
    action: '任务公开状态变更',
    icon: 'icon-taskshu'
  },
  19: {
    action: '',
    icon: 'icon-edit-simple'
  },
  20: {
    action: '',
    icon: 'icon-delete-simple'
  }
}

export default {
  DYNAMICTYPE
}
