<template>
  <!-- eslint-disable -->
  <el-dialog
    :title="title"
    :visible.sync="visible"
    :width="700"
    :append-to-body="true"
    :close-on-click-modal="true"
    :destroy-on-close="true"
    :before-close="closeCycleRangle"
    v-on="$listeners"
    custom-class="set-permissions-dialog"
  >
    <div class="management">
      <div class="management_content">
        <div class="management-ul-box">
          <el-form
            ref="cycleForm"
            :rules="rules"
            :model="form"
            label-width="0px"
          >
            <el-form-item
              label
              prop="cycleTimelength"
              label-width="0"
              class="float_lf"
            >
              <el-radio-group v-model="form.cycleTimelength">
                <el-radio
                  :label="item.value"
                  :key="item.value"
                  v-if="item.state"
                  v-for="item in isCreateNewUser"
                >
                  {{ item.label }}&nbsp;&nbsp;&nbsp;
                  <p class="p-radio">{{ item.text }}</p>
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <div class="management-ul-bottom clearfix">
              <div
                style="cursor: pointer"
                class="save_btns common_btn_next common_btn_next_unables"
                @click="closeCycleRangle"
              >
                取消
              </div>
              <div
                class="save_btns common_btn_next common_btn_next_ables"
                @click="saveBtn('cycleForm')"
              >
                保存
              </div>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    // 标题
    title: {
      type: String,
      required: false,
      default: '编辑任务可见范围'
    },
    collect_show_back: {
      type: Boolean,
      required: false,
      default: true
    },
    collect_show_object: {
      type: Object,
      required: false
    },
    permissionId: {
      type: String,
      required: false
    },
    isShare: {
      type: String,
      required: false,
      default: '0'
    },
    permissionsType: {
      type: String,
      required: false,
      default: '0' // 0 代表 任务  1 代表其他
    },
    visible: {
      type: Boolean
    }
  },
  data() {
    return {
      form: {
        cycleTimelength: '0'
      },
      isCreateNewUser: [
        {
          label: '设为公开',
          value: '0',
          text: '企业内所有人可见',
          state: true
        },
        {
          label: '设为私密',
          value: '1',
          text: '仅自己和目标共享人可见',
          state: false
        },
        {
          label: '设为私密',
          value: '1',
          text: '仅自己和任务相关人可见（包含任务负责人、协作人以及关联的目标创建人和共享人）',
          state: false
        }
      ],
      rules: {
        cycleTimelength: [
          { required: false, message: '未选择', trigger: 'change' }
        ]
      }
    }
  },
  created() {
    if (this.permissionsType === '0') {
      this.isCreateNewUser[1].state = false
      this.isCreateNewUser[2].state = true
    } else {
      this.isCreateNewUser[1].state = true
      this.isCreateNewUser[2].state = false
    }
    this.form.cycleTimelength = String(this.collect_show_object.isShare)
    this.form = JSON.parse(JSON.stringify(this.form))
  },
  mounted() {},
  watch: {
    collect_show_object(val) {
      console.log(1131212, val)
    }
  },
  computed: {},
  methods: {
    // 请求关注人员列表=========================================
    async getCollect(obj_data) {
      const that = this
      // 路径替换为 切换权限接口==========================================================================zhang
      if (this.permissionsType === '0') {
        const beans = {
          id: this.permissionId,
          isShare: this.form.cycleTimelength
        }
        await this.$apis.TASKSHAREUPDATE(beans).then((res) => {
          if (res.status === 200) {
            this.$emit('submitPermissions')
            this.$message.success('修改成功')
            this.closeCycleRangle()
          } else {
            that.$message({
              msg: '网络错误,稍后重试!',
              type: 'error'
            })
          }
        })
      } else {
        const beans = {
          id: this.collect_show_object.id,
          isShare: this.form.cycleTimelength
        }
        await this.$apis.OKRPUBLICSTATECHANGE(beans).then((res) => {
          if (res.status === 200) {
            this.$emit('submitPermissions')
            this.$message.success('修改成功')
            this.closeCycleRangle()
          } else if (res.status === 500) {
            that.$message({
              msg: res.message,
              type: 'warn'
            })
          } else {
            that.$message({
              msg: res.message,
              type: 'error'
            })
          }
        })
      }
    },
    // 关闭弹框
    closeCycleRangle() {
      this.visible = false
      this.$emit('closePermissions', false)
    },
    saveBtn(formName) {
      var that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const obj_data = {}
          obj_data.permission = that.form.cycleTimelength
          that.getCollect(obj_data)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__header {
  padding-bottom: 0px;
}
.management-wrap {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 9998;
  filter: Alpha(opacity=50); /* IE */
  -moz-opacity: 0.5; /* FF 主要是为了兼容老版本的FF */
  opacity: 0.5; /* FF */
  background: rgba(0, 0, 0, 0.5);
}
.management {
  .management_content {
    .p-radio {
      display: inline-block;
      width: 60%;
      word-break: break-word;
    }
    .save_btns {
      border: 0;
      width: 110px;
      margin-right: 8px;
    }
    .common_btn_next {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .common_btn_next_ables {
      background: #3a78f6;
      cursor: pointer;
      border: 1px solid #3a78f6;
      color: #fff;
    }
    .common_btn_next_ables:hover {
      background: rgba(58, 120, 246, 0.87);
      border: 1px solid #3a78f6;
      color: #fff;
    }
    .common_btn_next_unables {
      background: #fff;
      border: 1px solid #3a78f6;
      color: #3a78f6 !important;
    }
    .common_btn_next_unables:hover {
      background: rgba(58, 120, 246, 0.06);
      border: 1px solid #3a78f6;
      color: #3a78f6 !important;
    }
    .management-ul-bottom {
      margin-top: 60px;
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
<style lang="scss">
.set-permissions-dialog.el-dialog {
  width: 700px;
}
.management_content .management-ul-box .el-radio {
  display: block;
}
.management_content .management-ul-box .el-radio__label {
  display: inline-block;
  height: 40px;
  line-height: 40px;
}
.management_content .management-ul-box .el-radio__label {
  display: inline-block;
  height: 46px;
  line-height: 46px;
}
.popup-range {
  width: 430px !important;
}
</style>
>
