var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      ref: "moreMenuPopRef",
      attrs: {
        placement: "bottom-start",
        trigger: "click",
        "popper-class": "member-menu-popover",
        disabled: !_vm.canEdit,
      },
    },
    [
      _vm.canEdit
        ? _c(
            "div",
            { staticClass: "list-wrap" },
            _vm._l(_vm.labelObj, function (item) {
              return _c(
                "div",
                {
                  key: item.value,
                  staticClass: "list",
                  on: {
                    click: function ($event) {
                      return _vm.changeType(item)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "name" }, [
                    _vm._v(_vm._s(item.label)),
                  ]),
                  item.value === _vm.okrData.labelType
                    ? _c("Icon", {
                        staticClass: "icon",
                        attrs: { "icon-class": "icon-duigou" },
                      })
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _vm.pageType === "okrDetail"
        ? _vm._t("default", null, { slot: "reference" })
        : _c(
            "div",
            {
              staticClass: "obj-label",
              class: `can-edit-${_vm.canEdit}`,
              style: _vm.setStyle(),
              attrs: { slot: "reference" },
              slot: "reference",
            },
            [
              _c(
                "el-tooltip",
                {
                  attrs: {
                    content: "点击修改目标类型",
                    disabled: !_vm.canEdit,
                    placement: "top",
                  },
                },
                [
                  _c("div", { staticClass: "label-text" }, [
                    _vm._v(
                      _vm._s(_vm.labelTypeObj[_vm.labelType]) +
                        _vm._s(_vm.shareList.length > 1 ? "共享" : "")
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }