import store from '@/store/index'
/* eslint-disable */
export default {
  props: {
    // 页面是否显示
    visible: {
      type: Boolean
    },
    // id
    id: {
      required: false
    },
    periodState: {
      required: false
    },
    viewType: {
      required: false
    },
    ifdraft: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      form: {}, // 表单
      originalForm: {}, // 未经修改的表单
      disabledSubmit: true, // 禁用表单提交
      message: '保存成功', // 保存成功的提示
      loading: false, // loading
      formLoading: false, // 表单loading
      drawerTitleTick: false // 为了显示drawer里面的svg图片
    }
  },
  mounted() {
    this.drawerTitleTick = true
  },
  computed: {
    // 是否禁止编辑
    isDisableEdit() {
      return this.form.chargeUserId !== store.state.user.info.id
    }
  },
  methods: {
    // 保存(调用服务器接口),params为false直接返回code
    async $save(path, params = {}) {
      this.loading = true
      const code = await this.$postCode(path, this.form)
      this.loading = false
      if (params === false) {
        return code
      }
      if (code === true) {
        this.$message.success(this.message)
        this.$dialogClose()
        this.$emit('save')
        // 发送刷新事件
        if (params.refreshName) {
          this.$emit(`global:${params.refreshName}`)
        }
      }
    },
    // 更新
    async $update(path, params) {
      if (!params.id) {
        this.$message.error('id不能为空')
        return
      }
      const status = await this.$element.confirm(params.msg)
      if (!status) {
        return false
      }
      this.formLoading = true
      const code = await this.$postCode(path, { id: params.id })
      this.formLoading = false
      if (code === true && params.close !== false) {
        if (params.close === false) return
        this.$drawerClose()
        this.$emit('save')
      }
      return code
    },
    // 查询详情
    async $getDetail(path, params) {
      if (!params) {
        params = { id: this.id }
      }
      this.formLoading = true
      const data = await this.$getData(path, params)
      this.formLoading = false
      if (data) {
        this.form = data
        this.originalForm = this.$utils.clone(this.form)
      }
    },
    // 查询详情
    $getDetailTask(path, params) {
      if (!params) {
        params = { id: this.id }
      }
      // this.formLoading = true
      return new Promise((resolve, reject) => {
        this.$apis.getDetailTasks(path, params).then((rest) => {
          if (rest) {
            this.formLoading = false
            let beans = {}
            this.form = rest
            this.originalForm = this.$utils.clone(this.form)
            beans.from = this.form
            beans.originalForm = this.originalForm
            resolve(beans)
          }
        })
      })
    },
    //查询主任务 子任务详情
    $getDetailTasksMainSub(path, params) {
      if (!params) {
        params = { id: this.id }
      }
      // path = path+'id='+this.id
      // this.formLoading = true
      return new Promise((resolve, reject) => {
        this.$api.getDetailTasksMainSub(path, params).then((rest) => {
          if (rest) {
            this.formLoading = false
            let beans = {}
            this.form = rest
            this.originalForm = this.$utils.clone(this.form)
            beans.from = this.form
            beans.originalForm = this.originalForm
            resolve(beans)
          }
        })
      })
    },

    // 参数判断
    $doom(msgMap, params) {
      let form = params || this.form
      for (let item in msgMap) {
        if (!form[item]) {
          params !== false && this.$message.error(msgMap[item])
          return false
        }
      }
      return true
    },
    // dialog关闭
    $dialogClose() {
      this.$emit('update:visible', false)
    },
    // drawer关闭
    $drawerClose(freshName) {
      if (freshName) {
        this.$emit(`global:${freshName}`)
      }
      this.$emit('update:visible', 0)
    }
  }
}
