export default {
  path: '/feedBack/help',
  component: () => {
    /* webpackChunkName: "feedBackHelp" */
    return import('../../views/feedBack/helpCenter/index')
  },
  name: 'help',
  redirect: '/feedBack/help/account_1', // 页面重定向
  children: [
    {
    path: '/feedBack/help/account_1',
    component: () => {
      /* webpackChunkName: "feedBackHelpAccount1" */
      return import('../../views/feedBack/helpCenter/account/account_1')
    },
    name: 'account_1'
  },
  {
    path: '/feedBack/help/account_2',
    component: () => {
      /* webpackChunkName: "feedBackHelpAccount2" */
      return import('../../views/feedBack/helpCenter/account/account_2')
    },
    name: 'account_2'
  },
  {
    path: '/feedBack/help/account_3',
    component: () => {
      /* webpackChunkName: "feedBackHelpAccount3" */
      return import('../../views/feedBack/helpCenter/account/account_3')
    },
    name: 'account_3'
  },
  {
    path: '/feedBack/help/prefer_1',
    component: () => {
      /* webpackChunkName: "feedBackHelpPrefer1" */
      return import('../../views/feedBack/helpCenter/setPrefer/prefer_1')
    },
    name: 'prefer_1'
  },
  {
    path: '/feedBack/help/prefer_2',
    component: () => {
      /* webpackChunkName: "feedBackHelpPrefer2" */
      return import('../../views/feedBack/helpCenter/setPrefer/prefer_2')
    },
    name: 'prefer_2'
  },
  {
    path: '/feedBack/help/prefer_3',
    component: () => {
      /* webpackChunkName: "feedBackHelpPrefer3" */
      return import('../../views/feedBack/helpCenter/setPrefer/prefer_3')
    },
    name: 'prefer_3'
  },
  {
    path: '/feedBack/help/target_1',
    component: () => {
      /* webpackChunkName: "feedBackHelpTarget1" */
      return import('../../views/feedBack/helpCenter/target/target_1')
    },
    name: 'target_1'
  },
  {
    path: '/feedBack/help/target_2',
    component: () => {
      /* webpackChunkName: "feedBackHelpTarget2" */
      return import('../../views/feedBack/helpCenter/target/target_2')
    },
    name: 'target_2'
  },
  {
    path: '/feedBack/help/target_3',
    component: () => {
      /* webpackChunkName: "feedBackHelpTarget3" */
      return import('../../views/feedBack/helpCenter/target/target_3')
    },
    name: 'target_3'
  },
  {
    path: '/feedBack/help/target_4',
    component: () => {
      /* webpackChunkName: "feedBackHelpTarget4" */
      return import('../../views/feedBack/helpCenter/target/target_4')
    },
    name: 'target_4'
  },
  {
    path: '/feedBack/help/target_5',
    component: () => {
      /* webpackChunkName: "feedBackHelpTarget5" */
      return import('../../views/feedBack/helpCenter/target/target_5')
    },
    name: 'target_5'
  },
  {
    path: '/feedBack/help/team_1',
    component: () => {
      /* webpackChunkName: "feedBackHelpTeam1" */
      return import('../../views/feedBack/helpCenter/team/team_1')
    },
    name: 'team_1'
  },
  {
    path: '/feedBack/help/team_2',
    component: () => {
      /* webpackChunkName: "feedBackHelpTeam2" */
      return import('../../views/feedBack/helpCenter/team/team_2')
    },
    name: 'team_2'
  },
  {
    path: '/feedBack/help/team_3',
    component: () => {
      /* webpackChunkName: "feedBackHelpTeam3" */
      return import('../../views/feedBack/helpCenter/team/team_3')
    },
    name: 'team_3'
  }
  ]
}
