<template>
  <div class="file-upload" :class="'file-upload-' + formPage">
    <upload-file-list
      :form-page="formPage"
      :preview="true"
      :disabled="disabled"
      :oss-file-entity-list="uploadFileList"
      @delete="fileDelete"
    />
    <!-- drag -->
    <el-upload
      :ref="`commentUpload${infoItem.id}`"
      class="upload-demo"
      :show-file-list="false"
      action
      :on-change="change"
      :http-request="uploadFileHttp"
    >
      <div slot="trigger" class="upload-btn-wrap" @click="resetFileCount()">
        <div class="upload-btn">
          <Icon
            icon-class="icon-tianjia-xuxian"
            :class="disabled ? 'icon-upload-disabled' : 'icon-upload'"
          />
        </div>
      </div>
    </el-upload>
  </div>
</template>

<script>
import uploadFileList from '@/components/global/FileUpload/uploadFileList.vue' // 评论动态展示
import baseUrl from '@/api/env' // 基础url
export default {
  components: {
    uploadFileList
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    infoItem: {
      type: Object,
      default: function () {
        return {}
      }
    },
    value: {
      type: Object,
      default: () => {
        return
      }
    },
    fileList: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 任务id
    width: {
      type: Number,
      required: false,
      default: 710
    },
    formPage: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      baseUrl: baseUrl,
      headers: {},
      files: [],
      uploadFileList: this.fileList,
      showFileList: false,
      fileTotalSize: 0, //文件总大小
      fileCount: 0,
      oldFileList: []
    }
  },
  watch: {
    value: {
      handler(val) {
        this.uploadFileList = val ? val : []
      },
      deep: true
    }
  },
  computed: {},
  created() {
    this.headers = {
      Authorization: this.token
    }
  },
  methods: {
    resetFileCount() {
      this.fileCount = 0
    },
    //上传文件用到的两个方法
    async uploadFileHttp() {
      console.log(1111, this.files)
      let formData = new FormData()
      if (this.files.length > 0) {
        this.files.forEach((item) => {
          this.fileTotalSize = Number(this.fileTotalSize) + Number(item.size)
        })
        // 超出50M
        if (this.fileTotalSize > 52428800) {
          this.$message.warn(`上传文件大小超出限制！`)
          this.$refs.commentUpload.clearFiles() //上传成功之后清除历史记录
          this.files = []
          this.fileTotalSize = 0
          return
        }
        this.files.forEach((file) => {
          formData.append('multipartFiles', file.raw)
        })
        console.log('文件数量-', this.files, formData)
        if (this.files.length > 0) {
          await this.$apis.BATCHFILEUPLOAD(formData).then((res) => {
            if (res.status === 200) {
              if (this.fileList.length > 0) {
                this.uploadFileList = this.fileList.concat(res.data)
              } else {
                this.uploadFileList = this.uploadFileList.concat(res.data)
              }
              this.$emit('fileFresh', this.uploadFileList, this.infoItem)
              this.$emit('change', this.uploadFileList)
              this.$refs[`commentUpload${this.infoItem.id}`].clearFiles() //上传成功之后清除历史记录
              this.files = []
              this.fileTotalSize = 0
            } else if (res.status === 413) {
              this.$refs[`commentUpload${this.infoItem.id}`].clearFiles() //上传成功之后清除历史记录
              this.files = []
              this.$message.warn(`上传文件大小超出限制！`)
              this.fileTotalSize = 0
            } else {
              this.$message.warn(`文件上传异常，请稍后再试哦！`)
              this.$refs[`commentUpload${this.infoItem.id}`].clearFiles() //上传成功之后清除历史记录
              this.files = []
              this.fileData = []
              this.fileTotalSize = 0
            }
          })
          .catch((err) => {
            this.$refs[`commentUpload${this.infoItem.id}`].clearFiles() // 上传成功之后清除历史记录
          })
        }
      }
    },
    change(file, fileList) {
      // 第一次on-change获取fileList
      if (this.fileCount === 0) {
        // 多个文件fileList会变化
        this.oldFileList = Object.assign(this.files)
      }

      // file.raw // 文件内容
      // 改文件是否已经存在上传列表中  some判断是否满足要求
      let isTrue = this.oldFileList.some((f) => {
        console.log(f.name, file.name)
        return f.name === file.name
      })

      if (isTrue) {
        this.$message.warn('请勿重复上传文件！')
        fileList.pop()
        return
      }
      this.files = fileList

      this.fileCount++
      console.log('文件', this.files)
    },
    fileDelete(list) {
      this.$nextTick(() => {
        this.uploadFileList = list
        this.$emit('change', this.uploadFileList)
      })
      this.$emit('fileFresh', list, this.infoItem)
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep {
  .file-list-wrap {
    margin-top: 6px;
  }
}
.upload-demo {
  width: 100%;
}
.upload-btn-wrap {
  width: 100%;
  position: relative;
  height: 30px;
  .drag-text {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    height: 30px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    font-size: 14px;
    color: #444558;
    line-height: 30px;
    opacity: 0;
  }
  &:hover {
    .upload-btn {
      color: #3a78f6;
    }
  }
}
.upload-btn {
  height: 24px;
  font-size: 24px;
  .flex-l();
  color: #a0a3bd;
  width: 24px;
  text-align: center;
  .icon-upload {
    width: 24px;
  }
  .icon-upload-disabled {
    width: 24px;
    cursor: not-allowed !important;
    &:hover {
      color: #a0a3bd;
    }
  }
}
.file-upload {
  display: flex;
  align-items: center;
  flex-wrap: wrap-reverse;
}
::v-deep .file-upload-okrList .el-upload-list {
  flex-wrap: wrap;
  display: flex;
  align-content: flex-start;
  align-items: center;
  .el-upload-list__item {
    width: 365px !important;
    margin-left: 20px;
  }
}
::v-deep .el-upload {
  padding-right: 50px;
  box-sizing: border-box;
}
::v-deep .upload-comment-wrap .el-upload-dragger.is-dragover {
  background-color: #fff;
}
::v-deep .el-upload-dragger.is-dragover .drag-text {
  opacity: 1;
}
::v-deep .file-upload-okrList {
  .upload-comment-wrap {
    margin-top: -48px;
  }
  .el-upload {
    width: 30px;
    .el-upload-dragger {
      width: 30px;
      .upload-btn-wrap {
        .upload-btn {
          width: 30px;
        }
      }
    }
  }
}
</style>
<style>
.mainTaskDetail .comment-box {
  position: relative !important;
}
.comment-box .el-upload-dragger {
  /* position: absolute;
  top:0px;
  bottom:0px; */
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  border: none;
  box-sizing: border-box;
}
.file-upload-okrList .el-upload-list {
  width: 100% !important;
  flex-wrap: wrap;
  display: flex;
  align-content: flex-start;
  align-items: center;
  margin-right: 20px;
}
.file-upload-okrList .el-upload-list__item {
  width: 365px !important;
  margin-left: 20px;
}
</style>
