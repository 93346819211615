<template>
  <button class="load-btn" @click.stop="clickBtn" v-preventReClick>
    <span :style="loading ? 'visibility: hidden !important;' : ''">
      <slot></slot>
    </span>
    <div v-if="loading" class="ball-clip-rotate">
      <div :style="`border-color: ${color}`"></div>
    </div>
  </button>
</template>
<script>
export default {
  props: {
    width: {
      // TODO
      type: Number,
      default: 96
    },
    loading: {
      type: Boolean,
      default: false
    },
    // 加载动画颜色
    color: {
      type: String,
      default: '#fff'
    }
  },
  data() {
    return {}
  },
  methods: {
    clickBtn() {
      if (this.loading) {
        return
      } else {
        this.$emit('clickEvt')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.load-btn {
  position: relative;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ball-clip-rotate {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    border: 2px solid #fff;
    border-bottom-color: transparent !important;
    background: transparent !important;
    display: inline-block;
    animation: rotate 0.75s 0s linear infinite;
  }
}
</style>
