<template>
  <el-popover
    ref="moreMenuPopRef"
    placement="bottom-start"
    trigger="click"
    popper-class="member-menu-popover"
    :disabled="!canEdit"
  >
    <div class="list-wrap" v-if="canEdit">
      <div class="list" v-for="item in labelObj" :key="item.value" @click="changeType(item)">
        <div class="name">{{ item.label }}</div>
        <Icon icon-class="icon-duigou" class="icon" v-if="item.value === okrData.labelType" />
      </div>
    </div>

    <slot slot="reference" v-if="pageType === 'okrDetail'" :class="`can-edit-${canEdit}`"></slot>
    <div
      class="obj-label"
      :style="setStyle()"
      :class="`can-edit-${canEdit}`"
      slot="reference"
      v-else
    >
      <el-tooltip content="点击修改目标类型" :disabled="!canEdit" placement="top">
        <div class="label-text">{{ labelTypeObj[labelType] }}{{ shareList.length > 1 ? '共享' : '' }}</div>
      </el-tooltip>
    </div>
  </el-popover>
</template>
<script>
export default {
  props: {
    shareList: {
      type: Array,
      default: []
    },
    heightType: {
      type: Number,
      default: 24
    },
    pageType: {
      // list目标列表 map目标地图页 align对齐 okrDetail目标详情
      type: String,
      default: 'list'
    },
    labelType: {
      // 0个人 1部门 2公司 3团队
      type: String,
      default: ''
    },
    canEdit: {
      default: false
    },
    okrData: {}
  },
  data() {
    return {
      labelTypeObj: {
        400: '个人',
        200: '部门',
        100: '公司',
        300: '团队'
      },
      labelObj: [
        {
          value: 100,
          label: '公司',
          img: require('@/assets/imgs/okr/objAddCompany.png'),
          color: '#3A70FF'
        },
        {
          value: 200,
          label: '部门',
          img: require('@/assets/imgs/okr/objAddDepart.png'),
          color: '#FF9900'
        },
        {
          value: 300,
          label: '团队',
          img: require('@/assets/imgs/okr/objAddTeam.png'),
          color: '#ED2E7E'
        },
        {
          value: 400,
          label: '个人',
          img: require('@/assets/imgs/okr/objAddPerson.png'),
          color: '#00BA88'
        }
      ],
      labelTypeVal: 400
    }
  },
  computed: {},
  created() {},
  methods: {
    setStyle() {
      let style
      if (this.pageType == 'list') {
        if (this.labelType == 400) {
          // 个人
          if (this.shareList.length > 1) {
            style = `background: linear-gradient(180deg, #49DBB0 0%, #11C38F 100%);width:72px;height:${this.heightType}px;border-top-left-radius: 12px;border-bottom-right-radius: 12px;`
          } else {
            style = `background: linear-gradient(180deg, #49DBB0 0%, #11C38F 100%);width:48px;height:${this.heightType}px;border-top-left-radius: 12px;border-bottom-right-radius: 12px;`
          }
        } else if (this.labelType == 200) {
          // 部门
          if (this.shareList.length > 1) {
            style = `background: linear-gradient(180deg, #FFC472 0%, #F89501 100%);width:72px;height:${this.heightType}px;border-top-left-radius: 12px;border-bottom-right-radius: 12px;`
          } else {
            style = `background: linear-gradient(180deg, #FFC472 0%, #F89501 100%);width:48px;height:${this.heightType}px;border-top-left-radius: 12px;border-bottom-right-radius: 12px;`
          }
        } else if (this.labelType == 100) {
          // 公司
          if (this.shareList.length > 1) {
            style = `background: linear-gradient(180deg, #6295FE 0%, #1F63EC 100%);width:72px;height:${this.heightType}px;border-top-left-radius: 12px;border-bottom-right-radius: 12px;`
          } else {
            style = `background: linear-gradient(180deg, #6295FE 0%, #1F63EC 100%);width:48px;height:${this.heightType}px;border-top-left-radius: 12px;border-bottom-right-radius: 12px;`
          }
        } else {
          // 团队
          if (this.shareList.length > 1) {
            style = `background: linear-gradient(180deg, #FF9DC6 0%, #ED2E7E 100%);width:72px;height:${this.heightType}px;border-top-left-radius: 12px;border-bottom-right-radius: 12px;`
          } else {
            style = `background: linear-gradient(180deg, #FF9DC6 0%, #ED2E7E 100%);width:48px;height:${this.heightType}px;border-top-left-radius: 12px;border-bottom-right-radius: 12px;`
          }
        }
      } else if (this.pageType == 'map') {
        if (this.labelType == '400') {
          // 个人
          if (this.shareList.length > 1) {
            style = `background: linear-gradient(180deg, #49DBB0 0%, #11C38F 100%);width:72px;height:${this.heightType}px;border-top-right-radius: 12px;border-bottom-left-radius: 12px;`
          } else {
            style = `background: linear-gradient(180deg, #49DBB0 0%, #11C38F 100%);width:48px;height:${this.heightType}px;border-top-right-radius: 12px;border-bottom-left-radius: 12px;`
          }
        } else if (this.labelType == '200') {
          // 部门
          if (this.shareList.length > 1) {
            style = `background: linear-gradient(180deg, #FFC472 0%, #F89501 100%);width:72px;height:${this.heightType}px;border-top-right-radius: 12px;border-bottom-left-radius: 12px;`
          } else {
            style = `background: linear-gradient(180deg, #FFC472 0%, #F89501 100%);width:48px;height:${this.heightType}px;border-top-right-radius: 12px;border-bottom-left-radius: 12px;`
          }
        } else if (this.labelType == '100') {
          // 公司
          if (this.shareList.length > 1) {
            style = `background: linear-gradient(180deg, #6295FE 0%, #1F63EC 100%);width:72px;height:${this.heightType}px;border-top-right-radius: 12px;border-bottom-left-radius: 12px;`
          } else {
            style = `background: linear-gradient(180deg, #6295FE 0%, #1F63EC 100%);width:48px;height:${this.heightType}px;border-top-right-radius: 12px;border-bottom-left-radius: 12px;`
          }
        } else {
          // 团队
          if (this.shareList.length > 1) {
            style = `background: linear-gradient(180deg, #FF9DC6 0%, #ED2E7E 100%);width:72px;height:${this.heightType}px;border-top-right-radius: 12px;border-bottom-left-radius: 12px;`
          } else {
            style = `background: linear-gradient(180deg, #FF9DC6 0%, #ED2E7E 100%);width:48px;height:${this.heightType}px;border-top-right-radius: 12px;border-bottom-left-radius: 12px;`
          }
        }
      } else if (this.pageType == 'align') {
        if (this.labelType == '400') {
          // 个人
          style = `background: linear-gradient(180deg, #49DBB0 0%, #11C38F 100%);width:48px;height:${this.heightType}px;border-top-right-radius: 12px;border-bottom-left-radius: 12px;`
        } else if (this.labelType == '200') {
          // 部门
          style = `background: linear-gradient(180deg, #FFC472 0%, #F89501 100%);width:48px;height:${this.heightType}px;border-top-right-radius: 12px;border-bottom-left-radius: 12px;`
        } else if (this.labelType == '100') {
          // 公司
          style = `background: linear-gradient(180deg, #6295FE 0%, #1F63EC 100%);width:48px;height:${this.heightType}px;border-top-right-radius: 12px;border-bottom-left-radius: 12px;`
        } else {
          // 团队
          style = `background: linear-gradient(180deg, #FF9DC6 0%, #ED2E7E 100%);width:48px;height:${this.heightType}px;border-top-right-radius: 12px;border-bottom-left-radius: 12px;`
        }
      }
      return style
    },
    changeType(item) {
      if (item.value !== this.okrData.labelType) {
        const beans = {
          id: this.okrData.id,
          cycleInfoId: this.okrData.cycleInfoId,
          labelType: item.value
        }
        this.$apis
          .OKRUPDATELABEL(beans)
          .then((rest) => {
            if (rest.status === 200) {
              this.$mixpanelUtil.track('Update OKR Type')
              this.$message.success('修改成功')
              var okrData = this.okrData
              okrData.labelType = item.value
              this.$emit('refresh', okrData)
              if (this.$refs.moreMenuPopRef) {
                this.$refs.moreMenuPopRef.doClose()
              }
              if (localStorage.getItem('approveRemindState') !== 'true' && this.okrData.processState === 1 && this.$store.state.user.approvalCheckData.onOff === 0 && this.userInfo.id == this.okrData.createUserid) {
                this.$store.commit('okr/approveRemindObj', {
                  visible: true,
                  objDetail: {
                    id: this.okrData.id,
                    ...this.okrData
                  }
                })
              }
            } else {
              this.$message.error(rest.message)
            }
          })
          .catch((err) => {})
      }
    }
  }
}
</script>
<style lang="less">
.can-edit-true {
  cursor: pointer;
}
.list-wrap {
  padding: 8px 0;
  .list {
    height: 40px;
    font-size: 14px;
    color: #14142b;
    line-height: 40px;
    padding: 0 8px;
    margin: 0 4px;
    .flex-bt();
    cursor: pointer;
    .name {
    }
    .icon {
      font-size: 20px;
      color: #3a70ff;
    }
    &:hover {
      background: rgba(245, 246, 250, 0.54);
      border-radius: 8px;
    }
  }
}
.obj-label {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  height: 22px;
  line-height: 22px;
  // position: absolute;
  .label-text {
    font-size: 12px;
    // position: absolute;
    width: 100%;
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
<style>
.member-menu-popover {
  border-radius: 16px;
  border: 0;
  padding: 0;
  overflow: hidden;
}
</style>
