import Vue from 'vue'
import 'vuetify/dist/vuetify.min.css'
import Vuetify, { VApp, VCalendar, VTabs, VTab } from 'vuetify/lib'

Vue.use(Vuetify, {
  components: {
    VApp,
    VCalendar,
    VTabs,
    VTab
  }
})
const opts = {
  theme: {
    themes: {
      light: {
        primary: '#3a78F6',
        secondary: '#b0bec5',
        accent: '#8c9eff',
        error: '#b71c1c'
      }
    }
  }
}
export default new Vuetify(opts)
