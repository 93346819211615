// 工作台
// 统一管理所有的基础请求
import apis from '../apis_moudles/index'
import req from '../request'

// 修改工作台视图条件
const WORKBENCHEDITCONDITION = (params) => {
  return req.post(apis.workBenchCondition, params)
}
// 查询工作台视图条件
const WORKBENCHQUERYCONDITION = (appendUrl, params) => {
  return req.get(`${apis.workBenchCondition}${appendUrl}`, {
    params
  })
}
// 查询任务自定义条件字典
const WORKBENCHCONDITIONLIST = (params) => {
  return req.get(apis.workBenchConditionList, {
    params
  })
}
// 获取用户个人视图列表
const WORKBENCHMYVIEW = (params) => {
  return req.get(apis.workBenchMyview, {
    params
  })
}
// 获取全部视图模板
const WORKBENCHTEMPLATELIST = (params) => {
  return req.get(apis.workBenchTemplateList, {
    params
  })
}
// 获取视图内任务列表
const WORKBENCHVIEWTASKS = (params) => {
  return req.get(apis.workBenchViewTasks, {
    params
  })
}

// 获取视图内目标列表
const WORKBENCHVIEWOBJS = (params) => {
  return req.get(apis.workBenchViewObjs, {
    params
  })
}
// 添加、编辑视图
const WORKBENCHADDVIEW = (params) => {
  return req.post(apis.workBenchAddView, params)
}
// 删除视图
const WORKBENCHDELVIEW = (appendUrl, params) => {
  return req.delete(`${apis.workBenchDelView}${appendUrl}`, params)
}

// 风险预警提醒列表（我收到的）
const RECEIVELIST = (params) => {
  return req.get(apis.receiveList, {
    params
  })
}

// 风险预警提醒历史
const RECEIVEHISTORYLIST = (params) => {
  return req.get(apis.receiveHistoryList, {
    params
  })
}
// 工作台用户视图列表
const WORKBENCH = (params) => {
  return req.get(apis.workbench, {
    params
  })
}
// 工作台角色列表
const WORKBENCHROLE = (params) => {
  return req.get(apis.workbenchRole, {
    params
  })
}
// 切换角色(切换完成替换重新生成用户工作台视图)
const PUTWORKBENCHROLE = (params) => {
  return req.put(apis.workbenchRole, params)
}
// 保存工作台视图
const POSTWORKBENCHROLE = (params) => {
  return req.post(apis.workbench, params)
}
//  工作台视图模版列表(编辑工作台添加模版调用此接口)
const WORKBENCHVIEW = (params) => {
  return req.get(apis.workbenchView, {
    params
  })
}
// 工作台关注项目列表
const FOLLOWPROJECT = (params) => {
  return req.get(apis.followProject, {
    params
  })
}

// 工作台关注任务列表
const FOLLOWTASK = (params) => {
  return req.get(apis.followTask, {
    params
  })
}
// 工作台关注目标列表
const FOLLOWOKR = (params) => {
  return req.get(apis.followOkr, {
    params
  })
}

// 工作台目标列表
const WORKOKRLIST = (params) => {
  return req.get(apis.workOkrList, {
    params
  })
}
// 工作台我的项目
const MYPROJECT = (params) => {
  return req.get(apis.myProject, {
    params
  })
}

// 导出基础请求函数
//  工作台工作日历
const WORKBENCHCALENDER = (params) => {
  return req.get(apis.workbenchCalendar, {
    params
  })
}
//  工作台未规划任务
const WORKBENCHUNPLANNEDTASK = (params) => {
  return req.get(apis.workbenchUnplannedTask, {
    params
  })
}
//  分页查询未显示的项目
const MILEPOSTWORKBENCHPROJECTPAGELIST = (params) => {
  return req.get(apis.milepostWorkbenchProjectPageList, {
    params
  })
}
//  工作台工作任务
const WORKBENCHWORKTASK = (params) => {
  return req.get(apis.workbenchWorkTask, {
    params
  })
}
// 查询里程碑工作台列表
const MILEPOSTWORKBENCHLIST = (params) => {
  return req.get(apis.milepostWorkbenchList, {
    params
  })
}
// 添加 项目的里程碑工作台显示
const MILEPOSTWORKBENCHSAVE = (params) => {
  return req.post(apis.milepostWorkbenchSave, params)
}
// 删除 项目的里程碑工作台显示
const MILEPOSTWORKBENCHDELETE = (params) => {
  return req.post(apis.milepostWorkbenchDelete, params)
}
// 导出基础请求函数
export default [
  {
    MILEPOSTWORKBENCHSAVE
  },
  {
    MILEPOSTWORKBENCHDELETE
  },
  {
    MILEPOSTWORKBENCHLIST
  },
  {
    MILEPOSTWORKBENCHPROJECTPAGELIST
  },
  {
  MYPROJECT
},
{
  WORKOKRLIST
},
{
  FOLLOWOKR
},
{
  FOLLOWTASK
},
{
  FOLLOWPROJECT
},
{
  WORKBENCHWORKTASK
},
{
  WORKBENCHUNPLANNEDTASK
},
{
  WORKBENCHCALENDER
},
{
  RECEIVEHISTORYLIST
},
{
  RECEIVELIST
},
{
  WORKBENCHEDITCONDITION
},
{
  WORKBENCHQUERYCONDITION
},
{
  WORKBENCHCONDITIONLIST
},
{
  WORKBENCHMYVIEW
},
{
  WORKBENCHTEMPLATELIST
},
{
  WORKBENCHVIEWTASKS
},
{
  WORKBENCHVIEWOBJS
},
{
  WORKBENCHADDVIEW
},
{
  WORKBENCHDELVIEW
},
{
  WORKBENCH
},
{
  WORKBENCHROLE
},
{
  PUTWORKBENCHROLE
},
{
  POSTWORKBENCHROLE
},
{
  WORKBENCHVIEW
}
]
