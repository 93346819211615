<template>
  <el-dialog
    title="OKR示例"
    :visible.sync="dialogVisible"
    width="660px"
    center
    :before-close="getOkrFlashListClose"
    :append-to-body="true"
  >
    <div class="okr-flash-list" v-loading="loading">
      <div class="okr-flash-list-select">
        <el-select v-model="okrListValue" placeholder="请选择" @change="getOkrFlashListSelect">
          <el-option v-for="item in gradeList" :key="item.id" :label="item.value" :value="item.id" />
        </el-select>
      </div>

      <div class="okr-flash-list-overflow">
        <div v-for="(item, index) in okrListBeans" :key="index" class="okr-flash-list-ul">
          <div class="okr-flash-list-ul-name">
            <img
              class="okr-flash-list-ul-name-icon"
              src="../../../../assets/imgs/okr/objective.png"
            />
            <div class="okr-flash-list-ul-name-text">{{ item.content }}</div>
          </div>

          <div
            v-for="(itemz, indexz) in item.keyresultsList"
            :key="indexz"
            class="okr-flash-list-ul-li"
            :class="indexz === 0 ? 'okr-flash-list-ul-li-style' : ''"
          >
            <div class="okr-flash-list-ul-li-text">KR{{ indexz + 1 }}</div>
            <div class="okr-flash-list-ul-li-text2">{{ itemz.title }}</div>
          </div>

          <div class="okr-flash-list-ul-button" @click="getOkrFlashListSubmit(item)">使用示例OKR</div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
// const okrList = require('./exampleList.json')
export default {
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  props: {
    dialogShow: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      gradeList: [],
      dialogVisible: this.dialogShow,
      okrListValue: '',
      okrListBeans: [],
      loading: false
    }
  },
  computed: {},
  watch: {
    dialogShow: {
      handler(val) {
        this.dialogVisible = val
      }
    }
  },
  created() {
    this.getGradeList()
  },
  methods: {
    getGradeList() {
      this.loading = true
      this.$apis.QUERYINDUSTRYROLES().then((res) => {
        if (res.status === 200) {
          console.log('object', res.data)
          if (res.data && res.data.length > 0) {
            this.okrListValue = res.data[0].id
          }
          this.gradeList = res.data
          this.loading = false
          this.getOkrFlashListData(this.okrListValue)
        } else {
          this.$message({
            msg: res.message,
            type: 'warn'
          })
          this.loading = false
        }
      })
    },
    // 根据角色id获取示例列表
    async getOkrFlashListData(id) {
      this.loading = true
      this.$apis.OKRGUIDETEMPLETES(id).then((res) => {
        if (res.status === 200) {
          this.okrListBeans = res.data
          this.loading = false
        } else {
          this.loading = false
          this.$message({
            msg: res.message,
            type: 'warn'
          })
        }
      })
    },
    getOkrFlashListSelect(val) {
      this.getOkrFlashListData(val)
    },
    // 关闭内容
    getOkrFlashListClose() {
      this.dialogVisible = false
      this.$emit('change', false)
    },
    // 使用示例OKR
    getOkrFlashListSubmit(data) {
      this.$emit('getOkrFlashListSubmit', data)
      this.getOkrFlashListClose()
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../assets/styles/dialog.less';
::v-deep {
  .el-dialog__header {
    display: block !important;
    padding: 24px 16px !important;
  }
  .el-dialog__body {
    padding: 0px !important;
  }
  .el-dialog__header .el-dialog__title {
    font-size: 18px;
  }
  .el-dialog__headerbtn {
    top: 26px !important;
  }
  .okr-flash-list .okr-flash-list-select .el-input__inner {
    width: 135px;
  }
}
.okr-flash-list {
  .okr-flash-list-select {
    width: 100%;
    padding-left: 30px;
  }
  .okr-flash-list-overflow {
    width: 100%;
    height: 470px;
    overflow-x: hidden;
    padding: 0 30px 30px 30px;
  }
  .okr-flash-list-ul {
    width: 100%;
    margin-top: 24px;
    .okr-flash-list-ul-name {
      width: 573px;
      height: 21px;
      display: flex;
      align-items: center;
      .okr-flash-list-ul-name-icon {
        width: 21px;
      }
      .okr-flash-list-ul-name-text {
        height: 100%;
        line-height: 21px;
        font-size: 14px;
        font-weight: 700;
        color: #333333;
        margin-left: 8px;
        width: 546px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:before {
          content: '';
          display: block;
        }
      }
    }
    .okr-flash-list-ul-li {
      width: 573px;
      height: 35px;
      display: flex;
      align-items: center;
      .okr-flash-list-ul-li-text {
        height: 100%;
        width: 33px;
        line-height: 35px;
        font-size: 11px;
        color: rgba(163, 189, 255, 1);
      }
      .okr-flash-list-ul-li-text2 {
        width: 538px;
        height: 100%;
        font-size: 12px;
        color: #333333;
        line-height: 35px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:before {
          content: '';
          display: block;
        }
      }
    }
    .okr-flash-list-ul-li-style {
      margin-top: 15px;
    }
    .okr-flash-list-ul-button {
      width: 120px;
      height: 22px;
      margin-top: 14px;
      font-size: 14px;
      font-weight: 700;
      color: rgba(48, 104, 255, 1);
      cursor: pointer;
    }
    .okr-flash-list-ul-button:hover {
      color: rgba(48, 104, 255, 0.7);
    }
  }
  .okr-flash-list-height {
    height: 30px;
  }
}
</style>
