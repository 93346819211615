var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return (_vm.objDetails &&
    (_vm.objDetails.processState || 1) === 1 &&
    (_vm.$store.state.user.approvalCheckData.onOff === 0 ||
      (_vm.$store.state.user.approvalCheckData.onOff === 1 &&
        _vm.businessType !== 0))) ||
    _vm.objDetails.submitterType
    ? _c(
        "div",
        [
          _vm.businessType || _vm.businessType === 0
            ? _c(
                "div",
                {
                  class: `type-wrap ${_vm.disabled}`,
                  style: {
                    background: _vm.stateList[_vm.businessType].backgroundColor,
                    color: _vm.stateList[_vm.businessType].fontColor,
                    borderColor: _vm.stateList[_vm.businessType].borderColor,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.approveDetail.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.stateList[_vm.businessType].name))]
              )
            : _vm._e(),
          _vm.approveDetailVisible
            ? _c("aproveDetail", {
                attrs: { detailsId: _vm.objDetails.id },
                model: {
                  value: _vm.approveDetailVisible,
                  callback: function ($$v) {
                    _vm.approveDetailVisible = $$v
                  },
                  expression: "approveDetailVisible",
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }