<template>
  <div class="auth-index-box">
    <div class="auth-index-top">
      <img :src="require('../../../assets/imgs/common/ic_logo.png')" alt />
    </div>
    <div class="auth-index-main">
      <transition name="fade-transform" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isRegister: false
    }
  },
  watch: {},
  created() {},
  mounted() {}
}
</script>
<style lang="less" scoped>
.auth-index-box {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  .auth-index-top {
    padding-left: 4%;
    padding-top: 10px;
    img {
      width: 136px;
    }
  }
  .auth-index-main {
    flex: 1;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    padding: 0 7% 24px;
    width: 100%;
    box-sizing: border-box;
    div {
      width: 100%;
    }
  }
}
</style>
