<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="480px"
    :append-to-body="true"
    @close="closeDialog"
  >
    <div slot="title" class="dialog-title">新功能上线</div>
    <div class="remind-wrap">
      <img src="../../../../../../assets/imgs/header/upGradeImg.png" alt />
      <div class="up-grade-wrap">
        <img src="../../../../../../assets/imgs/header/upGradeHand.png" class="up-grade-hand" alt />
        <div class="title">新版绩效重磅上线，超多功能等你来体验～</div>
        <ul>
          <li>1.更丰富的考核流程：指标制定、指标确认、结果校准、绩效沟通等超多流程，每个环节都可指定角色处理，考核流程更加严谨</li>
          <li>2.支持更多内容设置：指标库、等级设置、考评关系、考核模板、字段管理等均可设置</li>
          <li>3.更加灵活的考评关系，逐级评分、360度环评、还可定义可见考评范围</li>
          <li>4.支持更多评分方式：按指标评分、整体评分、整体评等级</li>
        </ul>
      </div>
      <button v-preventReClick type="button" class="edit-btn" @click="confirm">查看更多</button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  props: {
  },
  data() {
    return {
      dialogVisible: true,
      activityStep: 1
    }
  },
  computed: {},
  watch: {
    dialogShow: {
      handler(val) {
        this.dialogVisible = true
      }
    }
  },
  created() {
  },
  methods: {
    closeDialog() {
      // const activityLocalStorage = JSON.parse(localStorage.getItem('activityLocalStorage')) || ''
      // activityLocalStorage.visible = false
      // localStorage.setItem('activityLocalStorage', JSON.stringify(activityLocalStorage))
      this.dialogVisible = false
      this.$emit('change', false)
    },
    confirm() {
      this.closeDialog()
      window.open('https://alidocs.dingtalk.com/i/p/6w0oXWbL4dMz1Jkvw0oXWAyxq1BjDG1J', '_blank')
    }
  }
}
</script>
<style lang="less" scoped>
.dialog-title {
  height: 32px;
  font-size: 24px;
  font-weight: bold;
  color: #14142b;
  line-height: 24px;
  margin: 8px 0px 20px 0px;
  text-align: center;
}
.up-grade-wrap {
  max-height: calc(100vh - 500px);
  overflow-y: auto;
  .title {
    // height: 24px;
    font-size: 16px;
    font-weight: 500;
    color: #444558;
    line-height: 24px;
    margin: 12px 0px 12px 0px;
    text-align: left;
  }
  position: relative;
  .up-grade-hand {
    position: absolute;
    width: 32px;
    top: 10px;
    right: 50px;
  }
  ul {
    li {
      font-size: 14px;
      font-weight: 400;
      color: #444558;
      line-height: 26px;
      .flex-l();
      // .flex-c();
      // margin-left: 52px;
      text-align: left;
      align-items: flex-start;
      span {
        min-width: 6px;
        height: 6px;
        border-radius: 3px;
        background: #d9dbe9;
        margin-right: 12px;
        margin-top: 10px;
      }
    }
  }
}
.edit-btn {
  margin: 32px auto 0px auto;
  width: 200px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  border-radius: 18px;
  font-weight: 500;
  border: 1px solid #3a78f6;
  color: #fff;
  background: #3a78f6;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: #005eff;
  }
}
.remind-wrap {
  margin: 0px auto;
  text-align: center;
  img {
    max-width: 100%;
    width: auto;
  }
}
::v-deep .el-dialog__body {
  padding: 0px 32px 32px 32px;
}
::v-deep .el-dialog__header {
  // display: none;
  padding: 16px 0px 0px 0px;
}
::v-deep .el-dialog__headerbtn {
  top: 20px;
}
::v-deep .el-dialog {
  border-radius: 24px !important;
}
</style>
