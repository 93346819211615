<template>
  <div class="task-label" @click.stop>
    <template v-if="type == 'default'">
      <div
        v-if="source == 'stopdoing'"
        :class="source == 'stopdoing' ? 'label-item-stopdoing-div' : ''"
      >
        <div
          v-for="(item, index) in selectOptions"
          :key="item.id"
          class="label-item"
          :style="`color:${item.colour};border:1px solid ${item.colour}`"
        >
          {{ item.name }}
          <i
            v-if="!disabled"
            class="el-icon-close del-btn"
            :style="overState ? `color:${item.colour};` : ''"
            @mouseenter="overState = true"
            @mouseleave="overState = false"
            @click="delLabel(index, item)"
          ></i>
        </div>
      </div>
      <template v-else>
        <div
          v-for="(item, index) in selectOptions"
          :key="item.id"
          class="label-item"
          :style="`color:${item.colour};border:1px solid ${item.colour}`"
        >
          {{ item.name }}
          <i
            v-if="!disabled"
            class="el-icon-close del-btn"
            :style="overState ? `color:${item.colour};` : ''"
            @mouseenter="overState = true"
            @mouseleave="overState = false"
            @click="delLabel(index, item)"
          ></i>
        </div>
      </template>
    </template>
    <el-popover
      :teleported="false"
      ref="taskLabelRef"
      placement="bottom-start"
      trigger="click"
      :disabled="disabled"
      :content="disabled ? '没有权限编辑' : '点击编辑' + fieldItem.name"
      popper-class="task-label-pop"
      @show="type == 'default' || type == 'custom' ? popoverShow() : ''"
      @hide="type == 'default' || type == 'custom' ? hide() : ''"
    >
      <div v-if="allLabels.length > 0">
        <div class="label-groups">
          <div v-for="(group, index) in allLabels" :key="index" class="group-item">
            <div class="group-name">{{ group.type === 0 ? `企业标签 *${group.name}` : '项目标签' }}</div>
            <div
              v-for="(item, lIndex) in group.labelList"
              :key="item.id"
              class="label-select"
              @click.stop="changeSelect(index, lIndex)"
            >
              <div class="label-dot" :style="`background: ${item.colour}`"></div>
              <div class="label-text">{{ item.name }}</div>
              <i v-if="item.checked" class="el-icon-check checked-icon"></i>
            </div>
          </div>
        </div>
        <div class="confirm-btn" @click.stop="confirmSelect">确定</div>
      </div>
      <div v-else class="no-data">
        <img src="@/assets/imgs/common/commentNull.png" class="kr-count-empty-img" />
        <p class="kr-count-p">暂无内容</p>
      </div>
      <span
        slot="reference"
        class="reference-div"
        v-if="type == 'default'"
        :class="
          source == 'stopdoing' && selectOptions.length > 0
            ? 'reference-stopdoing-div'
            : ''
        "
      >
        <Icon icon-class="icon-tianjia-xuxian" :class="disabled ? 'add-btn-disabled' : 'add-btn'" />
        <span v-if="source == 'stopdoing'" class="tag-text">
          {{
          selectOptions.length > 0 ? '' : '添加标签'
          }}
        </span>
      </span>
      <slot slot="reference" name="labelcontent" v-else></slot>
    </el-popover>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: 'default'
    },
    source: {
      type: String,
      default: ''
    },
    // 标签字典
    labelOptions: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 已选中的标签列表
    selectOptions: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 是否禁用编辑
    disabled: {
      type: Boolean,
      default: false
    },
    fieldItem: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      overState: false,
      userVisible: true
    }
  },
  computed: {
    allLabels() {
      const selectedObj = {}
      this.selectOptions.forEach((item) => {
        selectedObj[item.id] = true
      })
      const options = []
      this.labelOptions.forEach((item) => {
        if (item.labelList && item.labelList.length > 0) {
          item.labelList.forEach((label) => {
            label.checked = false
            if (selectedObj[label.id]) {
              label.checked = true
            }
          })
          options.push(item)
        }
      })
      options.sort((a, b) => b.type - a.type)
      return JSON.parse(JSON.stringify(options))
    }
  },
  destroyed() {
    this.$store.commit('common/setScrollBgVisible', false)
  },
  methods: {
    hide() {
      console.log('hide')
      this.$store.commit('common/setScrollBgVisible', false)
      if (this.userVisible) {
        if (this.$refs.taskLabelRef) {
          this.$refs.taskLabelRef.doClose()
        }
      }
    },
    popoverShow() {
      this.$store.commit('common/setScrollBgVisible', true)
      this.userVisible = true
    },
    delLabel(index, item) {
      const selected = JSON.parse(JSON.stringify(this.selectOptions))
      selected.splice(index, 1)
      // 判断必填项
      if (this.fieldItem.required === 0 && selected.length === 0) {
        this.$message({
          msg: this.fieldItem.name + '字段为必填项！',
          type: 'warn'
        })
        return
      }
      this.$emit('update', selected)
    },
    // 更改选中状态
    changeSelect(index, lIndex) {
      this.allLabels[index].labelList[lIndex].checked =
        !this.allLabels[index].labelList[lIndex].checked
      this.$forceUpdate()
    },
    confirmSelect() {
      const selected = []
      this.allLabels.forEach((item) => {
        item.labelList.forEach((label) => {
          if (label.checked) {
            selected.push(label)
          }
        })
      })
      this.$emit('save', selected, this.fieldItem)
      if (this.$refs.taskLabelRef) {
        this.$refs.taskLabelRef.doClose()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.reference-stopdoing-div {
  margin-left: 12px;
  .add-btn {
    font-size: 20px !important;
  }
}
.reference-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tag-text {
  font-size: 14px;
  font-weight: 400;
  color: #a0a3bd;
  margin-left: 8px;
}
.task-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  .label-item {
    flex-shrink: 0;
    height: 24px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    font-size: 14px;
    margin: 3px 8px 3px 0;
    border-radius: 16px;
    &:hover {
      .del-btn {
        display: block;
      }
    }
    .del-btn {
      cursor: pointer;
      color: #a0a3bd;
      font-size: 14px;
      margin-left: 4px;
      display: none;
      &:hover {
        color: #ff9900;
      }
    }
  }
  .label-item-stopdoing-div {
    flex: 1;
    height: 38px;
    display: flex;
    flex-wrap: nowrap;
    max-width: 240px;
    overflow-x: auto;
    overflow-y: hidden;
    .label-item {
      margin-right: 8px;
      margin-top: 9px;
      height: 20px;
      line-height: 20px;
    }
  }
  .add-btn {
    font-size: 24px;
    cursor: pointer;
    color: #a0a3bd;
    &:hover {
      color: #3a78f6;
    }
  }
  .add-btn-disabled {
    cursor: not-allowed !important;
    font-size: 24px;
    cursor: pointer;
    color: #a0a3bd;
  }
}
</style>
<style lang="scss">
.task-label-pop {
  width: 200px;
  padding: 12px;
  .label-groups {
    max-height: 280px;
    overflow-y: auto;
    min-height: 160px;
    .group-item {
      .group-name {
        font-size: 12px;
        color: #a0a3bd;
        line-height: 16px;
        margin-bottom: 4px;
      }
      .label-select {
        height: 32px;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #14142b;
        line-height: 16px;
        padding: 0 4px 0 8px;
        margin: 4px 0;
        border-radius: 8px;
        .label-dot {
          width: 6px;
          height: 6px;
          background: #3a78f6;
          border-radius: 2px;
          margin-right: 12px;
        }
        .label-text {
          flex: 1;
          min-width: 0;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .checked-icon {
          color: #3a78f6;
          margin-left: auto;
        }
        &:hover {
          background: #f5f6fa;
        }
      }
    }
  }
  .no-data {
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img.kr-count-empty-img {
      width: 80px;
      align-items: center;
      align-self: center;
    }
    p.kr-count-p {
      line-height: 36px;
      text-align: center;
      font-size: 14px;
    }
  }
  .confirm-btn {
    margin-top: 4px;
    cursor: pointer;
    width: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    background: #3a78f6;
    border-radius: 12px;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    margin: 4px auto 0;
    &:hover {
      background: #3a78f6;
    }
  }
}
</style>
