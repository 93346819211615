var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "message-box-wrap" },
    [
      _c(
        "el-dialog",
        _vm._g(
          {
            staticClass: "message-box-drawer",
            attrs: {
              visible: _vm.messageNotificationShowz,
              "append-to-body": true,
              "destroy-on-close": true,
            },
            on: {
              "update:visible": function ($event) {
                _vm.messageNotificationShowz = $event
              },
              close: _vm.getMessageNotificationClose,
            },
          },
          _vm.$listeners
        ),
        [
          _c(
            "div",
            {
              staticClass: "message-top",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _vm.informNumber > 0
                ? _c("span", { staticClass: "infor-num" }, [
                    _vm._v(
                      _vm._s(_vm.informNumber > 99 ? "99+" : _vm.informNumber)
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "message-top-left" },
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: {
                        "background-color": "none",
                        height: "58",
                        "slider-color": "#005EFF",
                      },
                    },
                    [
                      _c(
                        "v-tab",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.tabClick("")
                            },
                          },
                        },
                        [_vm._v("全部")]
                      ),
                      _c(
                        "v-tab",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.tabClick(0)
                            },
                          },
                        },
                        [_vm._v("未读")]
                      ),
                      _c(
                        "v-tab",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.tabClick(1)
                            },
                          },
                        },
                        [_vm._v("已读")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "message-top-right",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { on: { click: _vm.readAll } },
                    [
                      _c("Icon", {
                        staticClass: "icon",
                        attrs: { "icon-class": "icon-quanduyidu" },
                      }),
                      _vm._v("标记为全部已读 "),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { on: { click: _vm.personSet } },
                    [
                      _c("Icon", {
                        staticClass: "icon",
                        attrs: { "icon-class": "icon-jiaruqiyehoutai" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { on: { click: _vm.getMessageNotificationClose } },
                    [
                      _c("Icon", {
                        staticClass: "icon-close icon",
                        attrs: { "icon-class": "icon-close" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "vueloading",
                  rawName: "v-vueloading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "notice-list-content",
            },
            [
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.dataList.length > 0
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "scroll-bottom",
                            rawName: "v-scroll-bottom",
                            value: _vm.$loadMoreList,
                            expression: "$loadMoreList",
                          },
                        ],
                        staticClass: "message-list-wrap",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      },
                      [
                        _c(
                          "transition-group",
                          { attrs: { name: "fade" } },
                          _vm._l(_vm.dataList, function (item) {
                            return _c(
                              "div",
                              {
                                key: item.id,
                                staticClass: "message-list",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.getMessageDetails(item)
                                  },
                                },
                              },
                              [
                                item.status === 0
                                  ? _c("div", { staticClass: "noRead" })
                                  : _vm._e(),
                                _c("div", { staticClass: "message-list-top" }, [
                                  _c("div", { staticClass: "detail-wrap" }, [
                                    _c("img", {
                                      staticClass: "border-radius-20",
                                      attrs: {
                                        src:
                                          item.avatar ||
                                          require("@/assets/imgs/common/avatar.png"),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      [
                                        _c("at-content", {
                                          attrs: {
                                            type: "dynamic",
                                            html: item.content,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("div", { staticClass: "time" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$utils.globalTime(item.createDate)
                                      )
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "message-list-content" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "img" },
                                      [
                                        item.busicType === 0
                                          ? _c("Icon", {
                                              staticClass: "icon",
                                              attrs: {
                                                "icon-class": "icon-mubiao",
                                              },
                                            })
                                          : item.busicType === 1
                                          ? _c("Icon", {
                                              staticClass: "icon",
                                              attrs: {
                                                "icon-class": "icon-zhouqi",
                                              },
                                            })
                                          : item.busicType === 2
                                          ? _c("Icon", {
                                              staticClass: "icon",
                                              attrs: {
                                                "icon-class": "icon-renwu",
                                              },
                                            })
                                          : item.busicType === 3
                                          ? _c("Icon", {
                                              staticClass: "icon",
                                              attrs: {
                                                "icon-class": "icon-a-Group725",
                                              },
                                            })
                                          : item.busicType === 4
                                          ? _c("Icon", {
                                              staticClass: "icon",
                                              attrs: {
                                                "icon-class": "icon-xiangmu",
                                              },
                                            })
                                          : item.busicType === 5
                                          ? _c("Icon", {
                                              staticClass: "icon",
                                              attrs: {
                                                "icon-class": "icon-jixiao",
                                              },
                                            })
                                          : item.busicType === 6
                                          ? _c("Icon", {
                                              staticClass: "icon",
                                              attrs: {
                                                "icon-class":
                                                  "icon-taskcalendars",
                                              },
                                            })
                                          : item.busicType === 7
                                          ? _c("Icon", {
                                              staticClass: "icon",
                                              attrs: {
                                                "icon-class": "icon-huiyi",
                                              },
                                            })
                                          : _c("Icon", {
                                              staticClass: "icon",
                                              attrs: {
                                                "icon-class":
                                                  "icon-mainpage_tab_okr",
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                    _c("AtContent", {
                                      attrs: {
                                        type: "dynamic",
                                        html: item.title,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _vm.dataList.length == 0 && _vm.dataListState
            ? _c(
                "div",
                { staticClass: "contentNull" },
                [
                  _c("Img", { attrs: { src: "/common/commentNull.png" } }),
                  _c("p", [_vm._v("暂无数据")]),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm.taskdetailVisible &&
      _vm.messageNotificationShowz &&
      parseInt(_vm.notifyId) > 0
        ? _c("task-detail", {
            attrs: {
              "task-id": _vm.notifyId,
              "form-page": _vm.formPage,
              "view-type": _vm.formPage,
            },
            model: {
              value: _vm.taskdetailVisible,
              callback: function ($$v) {
                _vm.taskdetailVisible = $$v
              },
              expression: "taskdetailVisible",
            },
          })
        : _vm._e(),
      _vm.subdetailNoticeVisible && _vm.messageNotificationShowz
        ? _c("sub-task-detail", {
            attrs: {
              "task-id": _vm.subdetailVisibleId,
              "sub-object-father-id": _vm.subObjectFatherId,
            },
            model: {
              value: _vm.subdetailNoticeVisible,
              callback: function ($$v) {
                _vm.subdetailNoticeVisible = $$v
              },
              expression: "subdetailNoticeVisible",
            },
          })
        : _vm._e(),
      _vm.homeDetailsState
        ? _c("okr-detail", {
            attrs: { "obj-id": _vm.detailsId },
            on: { refresh: _vm.getHomeDetailsClose },
            model: {
              value: _vm.homeDetailsState,
              callback: function ($$v) {
                _vm.homeDetailsState = $$v
              },
              expression: "homeDetailsState",
            },
          })
        : _vm._e(),
      _vm.targetAddShow
        ? _c("add-target", {
            attrs: { "target-details-align": _vm.targetDetailsAlign },
            on: {
              gettargetAddClose: _vm.gettargetAddClose,
              getTargetAddSubmit: _vm.getTargetAddSubmit,
            },
            model: {
              value: _vm.targetAddShow,
              callback: function ($$v) {
                _vm.targetAddShow = $$v
              },
              expression: "targetAddShow",
            },
          })
        : _vm._e(),
      _vm.approveDetailVisible
        ? _c("aproveDetail", {
            attrs: { detailsId: _vm.aproveDetailId },
            model: {
              value: _vm.approveDetailVisible,
              callback: function ($$v) {
                _vm.approveDetailVisible = $$v
              },
              expression: "approveDetailVisible",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }