import store from '@/store'
// 处理 indexdb 打开(0)，增删改查(crud)
// 数据库实例
let db = null
const indexedDB =
  window.indexedDB ||
  window.webkitindexedDB ||
  window.msIndexedDB ||
  mozIndexedDB
// 初始化一个数据
const initRedPointDB = (callback) => {
  let initState = true
  // 打开数据库请求
  const openDatabaseReq = indexedDB.open('redPointsDB', 1)
  // 打开成功
  openDatabaseReq.onsuccess = (res) => {
    // 成功打开数据库
    db = res.target.result
    if (callback && typeof callback === 'function') {
      callback(db)
    }
  }

  // 打开失败
  openDatabaseReq.onerror = (res) => {
    initState = false
    console.log('index打开失败', res)
  }

  // 建表
  openDatabaseReq.onupgradeneeded = function (e) {
    db = e.target.result
    let store = null
    // 如果不存在红点这张表，就去创建,此处索引为type
    if (!db.objectStoreNames.contains('redpoints')) {
      store = db.createObjectStore('redpoints', {
        keyPath: 'type'
      })
      // 创建索引
      store.createIndex('type', 'type', {
        unique: true
      })
    }
  }

  return initState
}

// 关闭数据库
const closeRedPointDB = () => {
  db.close()
}

// 新增数据
const add = (type, arr) => {
  initRedPointDB(function () {
    const points = arr
    // 开启事务读写
    const req = db
      .transaction(['redpoints'], 'readwrite')
      .objectStore('redpoints')
      .add({
        type,
        points
      })

    req.onsuccess = function (event) {
      console.log('红点写入成功')
    }

    req.onerror = function (event) {
      console.log('红点写入失败', event)
    }
  })
}

// 读取数据
const read = (key, fn, companyId) => {
  const storeData = store
  initRedPointDB(function () {
    let res = null
    const transaction = db.transaction(['redpoints'], 'readonly')
    const store = transaction.objectStore('redpoints')
    const req = store.get(key)
    req.onerror = function (event) {
      console.log('红点读取失败')
    }
    req.onsuccess = function (event) {
      if (req.result) {
        res = req.result
      } else {
        console.log('查无此数据！')
      }
    }
    transaction.oncomplete = function (event) {
      if (res) {
        const points = res.points.filter((item) => {
          return (
            item.companyId ===
            (companyId || storeData.state.user.companyInfo.id)
          )
        })
        fn(points)
      }
    }
  })
}
// 更新数据
const update = (type, arr) => {
  initRedPointDB(function () {
    const points = arr
    var req = db
      .transaction(['redpoints'], 'readwrite')
      .objectStore('redpoints')
      .put({
        type,
        points
      })
    req.onsuccess = function (event) {
      //console.log('推送数据更新成功')
    }

    req.onerror = function (event) {
      //console.log('推送数据更新失败', event)
    }
  })
}

export default {
  initRedPointDB,
  add,
  read,
  update,
  closeRedPointDB
}
