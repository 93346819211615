<template>
  <div class="message-box-wrap">
    <el-drawer
      title="新建项目"
      :visible.sync="apiRequestData.visible"
      direction="btt"
      :destroy-on-close="true"
      class="request-box-drawer"
      :close-on-press-escape="false"
      v-on="$listeners"
      @close="getMessageNotificationClose"
    >
      <div
        v-if="
          userInfo.companyId != '1339443199637032960' &&
          apiRequestData.type !== 52018 &&
          apiRequestData.type !== 54003
        "
        class="classroom-menu"
        @click="goClassroom"
      >训练营</div>
      <div
        class="person-menu"
        v-show="apiRequestData.type !== 52018 && apiRequestData.type !== 54003"
      >
        <person-menu-list ref="menu" :type="2" @companyMore="companyMore" />
      </div>
      <div class="request-wrap">
        <!-- 52012：超出人数限制  52014：企业已删除 或被管理员移除企业 52016:加⼊企业，等待管理员审核 52017:申请加⼊被拒绝 52018:版本到期
52019:版本到期被停用
        52020:账号被管理员停用-->
        <!-- 超出人数限制 完成-->
        <div class="content-wrap" v-if="apiRequestData.type === 52012 || apiRequestData.type === 1">
          <div class="more-people-wrap">
            <div
              class="company-name"
              v-if="apiRequestData.type === 1"
            >{{ apiRequestData.companyName }}</div>
            <div class="company-name" v-else>{{ userInfo.companyName }}</div>
            <div class="infor-list">
              <Icon class="svg-img" icon-class="icon-zhongzhi" />
              <p class="title">超出人数限制，请联系管理员或切换企业</p>
            </div>
            <div class="user-wrap" v-if="adminList.length > 0">
              <div class="user-list">
                <div class="user-wrap-left">
                  <img
                    class="border-radius-20"
                    :src="adminList[0].avatar || require('@/assets/imgs/common/avatar.png')"
                    alt
                  />
                </div>
                <div class="user-wrap-right">
                  <div class="name">
                    <wx-open-data :id="adminList[0].username" :nameData="{name:adminList[0].name}" />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="btn"
              @click="exitCompany(apiRequestData.type)"
              v-if="commonFromType === 'saas' && !$isDD && !$isQw"
            >退出企业</div>
          </div>
        </div>
        <!-- 企业已删除 或被管理员移除企业  完成-->
        <div class="content-wrap" v-if="apiRequestData.type === 52014">
          <div class="more-people-wrap people-refuse">
            <div class="company-name">{{ userInfo.companyName }}</div>
            <div class="infor-list">
              <Icon class="svg-img" icon-class="icon-zhongzhi" />
              <p class="title">您已被管理员移出企业 或 企业已被删除</p>
            </div>
            <div
              class="btn"
              @click="exitBtnClick(apiRequestData.type)"
              v-if="commonFromType === 'saas' && !$isDD && !$isQw"
            >重新登录</div>
          </div>
        </div>
        <!-- 加⼊企业，等待管理员审核 完成-->
        <div class="content-wrap" v-if="apiRequestData.type === 52016">
          <div class="more-people-wrap">
            <div class="svg-wrap">
              <Icon class="svg-img" icon-class="icon-taskNull" />
            </div>
            <p class="title">您已申请加入「{{ userInfo.companyName }}」，需等待管理员审核</p>
            <div
              class="btn"
              @click="switchEmptyHandle(apiRequestData.type)"
              v-if="commonFromType === 'saas' && !$isDD && !$isQw"
            >切换企业</div>
          </div>
        </div>
        <!-- 版本到期 -->
        <!-- <div
          class="content-wrap"
          v-if="apiRequestData.type === 52018 && userInfo.isSuperAdmin == 1"
        >
          <div class="dialog-close" @click="dialogClose()"></div>
          <div class="more-people-wrap people-refuse">
            <div class="company-name">{{ userInfo.companyName }}</div>
            <div class="infor-list">
              <Icon class="svg-img" icon-class="icon-zhongzhi" />
              <p class="title">版本到期</p>
            </div>
            <div class="infor-title">
              <p>
                到期时间为{{
                apiRequestData.list.expireDate
                }}，已经降级为免费版，最多{{
                apiRequestData.list.grantCount
                }}人使用。超出的用户将停用账户，请联系客服进行续费方可继续使用
              </p>
            </div>
            <div class="click-infor" @click="webviewClick">查看版本说明</div>
            <div class="btn-list">
              <div class="btn" @click="switchEmptyHandle(apiRequestData.type)">立即续费</div>
              <div class="btn" @click="personManage(apiRequestData.type)">帐号管理</div>
            </div>
          </div>
        </div>-->
        <!-- 停用 拒绝 -->
        <div
          v-if="
            apiRequestData.type === 52017 ||
            apiRequestData.type === 52019 ||
            apiRequestData.type === 52020
          "
          class="content-wrap"
        >
          <div class="more-people-wrap people-refuse">
            <div class="company-name">{{ userInfo.companyName }}</div>
            <div class="infor-list refuse-infor-list">
              <Icon class="svg-img" icon-class="icon-zhongzhi" />
              <p class="title">账户已被停用</p>
            </div>
            <div class="infor-title">
              <p>无法进入该组织，账号已被拒绝加入或停用，</p>
              <p>请联系该企业管理员或切换其他企业</p>
            </div>
            <div v-if="adminList.length > 0" class="user-wrap">
              <div class="user-list">
                <div class="user-wrap-left">
                  <img
                    class="border-radius-20"
                    :src="adminList[0].avatar || require('@/assets/imgs/common/avatar.png')"
                    alt
                  />
                </div>
                <div class="user-wrap-right">
                  <div class="name">
                    <wx-open-data :id="adminList[0].username" :nameData="{name:adminList[0].name}" />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="btn"
              @click="exitCompany(apiRequestData.type)"
              v-if="commonFromType === 'saas' && !$isDD && !$isQw"
            >退出企业</div>
          </div>
        </div>
        <!-- 免费到期-其他成员 -->
        <!-- <div class="content-wrap" @click.stop v-if="apiRequestData.type === 54003">
          <div class="expire-div">
            <div class="company-name">{{ userInfo.companyName }}</div>
            <div class="infor-list refuse-infor-list">
              <Icon class="svg-img" icon-class="icon-zhongzhi" />
              <p class="title">已到试用期限，请升级为付费版本</p>
            </div>
            <div class="infor-text">
              到期后
              <span>30天</span>
              企业数据将被删除，请联系我们升级，以便继续使用
            </div>
            <div
              class="btn"
              @click="exitCompany(apiRequestData.type)"
              v-if="commonFromType ==='saas'"
            >退出企业</div>
          </div>
        </div>-->
        <!-- 版本到期 -->
        <div
          class="content-wrap"
          @click.stop
          v-if="
            apiRequestData.type === 52018 ||
            apiRequestData.type === 54003 ||
            apiRequestData.type === 54002
          "
        >
          <div :class="`expire-div expire-div-${userInfo.isSuperAdmin === 0}`">
            <div class="expire-top">
              <img :src="require('@/assets/imgs/advertisement/applyImg.png')" class="top-img" alt />
              <div class="top-title">您可以选择</div>
              <img
                src="@/assets/imgs/header/schoolImg.png"
                alt
                class="school-img"
                @click="expireClick('去训练营')"
              />
            </div>
            <div class="handle-list">
              <!-- :style="`background: url('${item.icon}') no-repeat center center;background-size: cover;`" -->
              <!-- v-show="!$isQw || item.name==='去训练营'" -->
              <div
                v-for="(item, index) in expireList"
                :key="index"
                class="list"
                v-if="
                  userInfo.isSuperAdmin != 0 ||
                  (userInfo.isSuperAdmin == 0 && index > 2)
                "
              >
                <img :src="item.icon" alt class="img" @click="expireClick(item.name, item)" />
                <!-- <div class="list-name">{{ item.name }}</div> -->
                <!-- <div class="list-title">{{ item.title }}</div> -->
                <!-- <div v-if="item.tips == 'activity'" class="list-title list-content">
                  {{ item.content1 }}
                  <div class="content-line"></div>
                </div>
                <div class="list-title list-content" v-if="item.content2">{{ item.content2 }}</div>-->
                <!-- <div class="btn-wrap">
                  <div
                    :class="
                      item.tips && item.tips == 'activity'
                        ? 'btn btn-activity'
                        : 'btn'
                    "
                    @click="expireClick(item.name, item)"
                  >{{ item.btnTitle }}</div>
                </div>-->
              </div>
            </div>
            <div class="btn-list" v-if="commonFromType === 'saas' && !$isDD && !$isQw">
              <div @click="switchEmptyHandle(apiRequestData.type)">切换企业</div>
              <div @click="exitCompany(apiRequestData.type)">退出企业</div>
            </div>
          </div>
        </div>
        <!-- <div class="expire-div" v-if="apiRequestData.type == 20220901">
          <div class="activity-div">
            <img
              class="activity-bg-img"
              src="@/assets/imgs/advertisement/activity_4.png"
              alt
            />
            <div
              class="btn btn-activity-apply"
              @click="applyFreeVisible = true"
            >
              立即申请
            </div>
            <img
              @click="dialogClose"
              src="@/assets/imgs/advertisement/activity_close.png"
              class="close-activity"
              alt
            />
          </div>
        </div>-->
      </div>
    </el-drawer>
    <!-- 切换企业 statusPassSms 2：切换企业  3:创建企业-->
    <switch-enterprises
      v-if="switch_enterprises"
      :visible.sync="switch_enterprises"
      :status-pass-sms="statusPassSms"
      :from-page="fromPage"
      @closeSwitchCompany="closeSwitchCompany"
    />
    <!-- 版本升级-->
    <customer-service
      v-if="customer_service_show"
      v-model="customer_service_show"
      :show-clo="showClo"
      :page="'home'"
      :visible.sync="customer_service_show"
      @closeDialg="customerSeriverCloseDialg"
    />
    <!-- 预约演示 -->
    <applyDialog v-if="applyDialogVisible" v-model="applyDialogVisible" />
    <!-- 申请延期 -->
    <applyDelay v-if="applyDelayVisible" v-model="applyDelayVisible" />
    <!-- 获取报价 -->
    <applyPrice v-if="applyPriceVisible" v-model="applyPriceVisible" />
    <!-- 双十一-->
    <doubleEleven
      v-if="doubleElevenVisible"
      v-model="doubleElevenisible"
      @close="openDoubleEleven(false)"
    />
  </div>
</template>
<script>
/* eslint-disable */
import { mapState } from 'vuex'
import switchEnterprises from '@/views/global/layout/header/components/switchEnterprises'
import personMenuList from '@/views/global/layout/header/components/personMenuList'
import applyDialog from '@/views/global/layout/header/components/applyDemo/applyDialog'
import applyDelay from '@/views/global/layout/header/components/applyDelay/applyDialog'
import applyPrice from '@/views/global/layout/header/components/applyPrice/applyDialog'
import doubleEleven from '@/views/global/layout/header/components/activity/doubleEleven.vue' //落地活动 （十一活动）
export default {
  data() {
    return {
      statusPassSms: 2,
      fromPage: 1,
      switch_enterprises: false, // 切换企业
      adminList: [],
      showClo: true, //显示
      customer_service_show: false,
      expireList: [
        {
          icon: require('@/assets/imgs/header/applyImg1.png'),
          title: '联系我们申请延期，给您更优质的体验时长',
          btnTitle: '立即申请',
          name: '申请延期'
        },
        // {
        //   icon: require('@/assets/imgs/advertisement/applyImg_activity.png'),
        //   title: '百人以下源目标全部功能',
        //   content1: '免费使用',
        //   btnTitle: '名额已满',
        //   name: '"源动力" 计划',
        //   tips: 'activity',
        //   content2: '助力企业成功落地OKR'
        // },
        {
          icon: require('@/assets/imgs/header/applyImg2.png'),
          title: '现在预约，获取免费1V1的在线演示服务',
          btnTitle: '立即预约',
          name: '预约演示'
        },
        {
          icon: require('@/assets/imgs/header/applyImg3.png'),
          title: '现在申请，获得专属定制方案',
          btnTitle: '立即获取',
          name: '获取报价'
        },
        // {
        //   icon: require('@/assets/imgs/advertisement/applyImg4.png'),
        //   title: '来训练营学习更多OKR知识～',
        //   btnTitle: '去训练营',
        //   name: '去训练营'
        // }
      ],
      applyDialogVisible: false,
      applyDelayVisible: false,
      applyPriceVisible: false,
      doubleElevenVisible: false
    }
  },
  computed: {
    ...mapState('common', {
      apiRequestData: 'apiRequestData'
    }),
    ...mapState('user', {
      userInfo: 'userInfo'
    })
  },
  props: {},
  watch: {
    apiRequestData: function (newVal, oldVal) {
      if (
        this.apiRequestData.type === 52012 ||
        this.apiRequestData.type === 52017 ||
        this.apiRequestData.type === 52019 ||
        this.apiRequestData.type === 52020 ||
        this.apiRequestData.type === 1
      ) {
        this.getAllAdminList()
      }
    }
  },
  components: {
    switchEnterprises,
    personMenuList,
    applyDialog,
    applyDelay,
    applyPrice,
    doubleEleven
  },
  mounted() {
    document.body &&
      document.body.addEventListener('click', (e) => {
        if (
          e &&
          e.target.className &&
          e.target.className !== 'request-box-drawer'
        ) {
          if (this.apiRequestData.type == 99) {
            this.apiRequestData.visible = false
          } else if (this.apiRequestData.type == 22) {
            this.apiRequestData.visible = false
          }
        }
      })
    // this.systemDateGet()
  },
  created() {
    // this.apiRequestData = this.$store.state.common.apiRequestData
    if (
      this.apiRequestData.type === 52012 ||
      this.apiRequestData.type === 52017 ||
      this.apiRequestData.type === 52019 ||
      this.apiRequestData.type === 52020 ||
      this.apiRequestData.type === 1
    ) {
      this.getAllAdminList()
    }
  },
  destroyed() {
    this.$store.commit('common/apiRequestData', {
      visible: false,
      type: 0,
      companyId: this.userInfo.companyId,
      companyName: this.userInfo.companyName
    })
  },
  methods: {
    openDoubleEleven(state) {
      this.doubleElevenVisible = state
    },
    // 活动判断
    systemDateGet() {
      this.$apis
        .SYSTEMDATE()
        .then((rest) => {
          const version = this.userInfo.version // 0 免费 1:企业 2:旗舰
          const endDate = '2022/11/11 23:59:59'
          const currentDate = rest.data.dateString
          const endDateTime = new Date(endDate)
          const currentDateTime = new Date(currentDate.replace(/\-/g, '/'))
          const expireDateTime = new Date(
            this.companyInfo.expireDate.replace(/\-/g, '/')
          )
          var day = this.$day.diffDay(endDateTime, currentDateTime)
          if (endDateTime.getTime() >= currentDateTime.getTime()) {
            this.expireList[0].title = '满一年送3个月，2年送1年'
            this.expireList[0].btnTitle = '立即咨询'
            this.expireList[0].name = '双十一狂欢🎉'
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    closeActivity() {},
    expireClick(name, item) {
      if (this.commonFromType === 'dingding' && name !== '去训练营') {
        window.open(`https://page.dingtalk.com/wow/dingtalk/act/serviceconversation?ddtab=true&wh_biz=tm&showmenu=false&goodsCode=DT_GOODS_881655784491241&corpId=${this.commonCorpId}&token=199f876c080bd26313560b8515f10454`, '_blank')
        return
      }
      if (name === '申请延期') {
        this.applyDelayVisible = true
      } else if (name === '预约演示') {
        this.applyDialogVisible = true
      } else if (name === '获取报价') {
        this.applyPriceVisible = true
      } else if (name === '去训练营') {
        this.goClassroom()
      } else if (name === '双十一狂欢🎉') {
        this.openDoubleEleven(true)
      } else if (item.tips && item.tips == 'activity') {
        //this.applyFreeVisible = true
      }
    },
    getMessageNotificationClose() {
      if (this.apiRequestData.type == 99) {
        this.$store.commit('common/apiRequestData', {
          visible: false,
          type: 0
        })
      } else if (this.apiRequestData.type == 22) {
        this.apiRequestData.visible = false
      }
    },
    //退出企业
    async exitCompany() {
      const status = await this.$element.confirm(
        '退出后将无法进入该企业，请谨慎操作',
        '是否退出企业'
      )
      if (!status) {
        return false
      }
      const params = {
        companyId: this.apiRequestData.companyId || this.companyInfo.id,
        userId: this.userInfo.id
      }
      this.$apis.COMPANYQUIT(params).then((res) => {
        if (res.status === 200) {
          this.exitBtnClick()
        } else {
          this.$messsege.warning(res.message)
        }
      })
    },
    personManage() {
      this.$router.push('/admin/teams')
      this.dialogClose()
    },
    exitBtnClick() {
      this.$refs.menu.exitBtn()
    },
    customerSeriverCloseDialg() {
      this.customer_service_show = false
    },
    dialogClose() {
      this.$store.commit('common/apiRequestData', {
        visible: false,
        type: 0,
        activity: true
      })
    },
    topMenuTab(path) {
      this.$store.commit('common/apiRequestData', {
        visible: false,
        type: ''
      })
      this.$router.push(path)
    },
    //获取所有管理员
    async getAllAdminList() {
      var that = this
      var data = {
        companyId: this.companyInfo.id
      }
      await this.$apis.ADMINGETADMINLIST(data).then((res) => {
        if (res.status == '200') {
          that.$nextTick(() => {
            that.adminList = res.data || []
          })
        }
      })
    },
    closeSwitchCompany() {
      this.$store.commit('common/apiRequestData', {
        visible: false,
        type: ''
      })
      this.switch_enterprises = false
    },
    companyMore() {
      this.statusPassSms = 2
      this.switch_enterprises = true
    },
    goClassroom() {
      this.$router.push('/classroom')
      this.$store.commit('common/apiRequestData', {
        visible: false,
        type: ''
      })
    },
    webviewClick() {
      window.open('https://www.okrt.com/price.html')
    },
    // 创建企业
    async switchEmptyHandle(type) {
      this.statusPassSms = 2
      this.fromPage = 1
      if (type !== 52018 && type !== 54003) {
        this.$store.commit('common/apiRequestData', {
          visible: false,
          type: ''
        })
      }
      if (type == 99) {
        const toDay = this.$day.getDate()
        const days = this.$day.diffDay(this.companyInfo.expireDate, toDay)
        this.$store.commit('common/apiRequestData', {
          visible: true,
          type: '99',
          days: days
        })
      }
      if (type === 52018) {
        if (this.commonFromType === 'dingding') {
          window.open(`https://page.dingtalk.com/wow/dingtalk/act/serviceconversation?ddtab=true&wh_biz=tm&showmenu=false&goodsCode=DT_GOODS_881655784491241&corpId=${this.commonCorpId}&token=199f876c080bd26313560b8515f10454`, '_blank')
        } else {
          this.customer_service_show = true
        }
      }
      setTimeout(() => {
        if (type !== 52018) {
          this.switch_enterprises = true
        }
      }, 500)
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .request-box-drawer {
  z-index: 9999999 !important;
}
// 源动力活动
.activity-div {
  width: 416px;
  height: 574px;
  position: relative;
  .btn {
    cursor: pointer;
    margin: 0px auto;
    text-align: center;
    .w-n(180);
    .h-n(40);
    .lh-n(40);
    .bd-r-n(16);
    background: #3a78f6;
    color: #fff;
    font-size: 16px;
    position: absolute;
    bottom: 32px;
    left: 50%;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    &:hover {
      background: #005eff;
    }
  }
  .close-activity {
    position: absolute;
    color: #eaeaf5;
    bottom: -60px;
    left: 50%;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  .btn-activity-apply {
    background: -webkit-linear-gradient(to top, #cac5ff, #7b8dff) !important;
    background: linear-gradient(to top, #cac5ff, #7b8dff) !important;
  }
  .activity-bg-img {
    width: 416px;
    height: 574px;
  }
}
.expire-div-true {
  .handle-list,
  .btn-list {
    justify-content: center !important;
  }
}
// 到期
.contact-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  .contact-telphone {
    font-size: 14px;
    color: #444558;
    margin-left: 55px;
    span {
      font-size: 18px;
      color: #3a78f6;
      font-weight: 700;
    }
  }
  .look-price {
    font-size: 12px;
    color: #6e7491;
    cursor: pointer;
    margin-left: 32px;
    text-decoration: underline;
    &:hover {
      color: #3a78f6;
    }
  }
}
.contact-vx {
  margin-top: 16px;
}
.el-button.expire-submit-btn {
  width: 100%;
  height: 40px;
  padding: 0;
  margin: 0;
  line-height: 40px;
  border-radius: 16px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  background: #3a78f6;
  border: none;
}
.el-button.expire-submit-btn:hover {
  background: rgba(58, 120, 246, 0.8);
}
.dialog-close {
  position: absolute;
  right: 24px;
  top: 18px;
  width: 28px;
  height: 28px;
  background: url(../../../assets/icons/common/dialogClose.png);
  background-size: 100% 100%;
  cursor: pointer;
  &:before {
    content: '';
  }
  &:hover {
    border-radius: 4px;
    background-color: #f5f6fa;
  }
}
.click-infor {
  color: #3a78f6;
  line-height: 22px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  margin: -24px 0px 18px 0px;
}
.logo-wrap {
  z-index: 99;
  position: absolute;
  .logo-img {
    width: 136px;
    margin-left: 30px;
    cursor: pointer;
    background: #fff;
    border-radius: 8px;
  }
}
.person-menu {
  z-index: 99;
  position: absolute;
  padding: 12px 20px;
  background: #fff;
  right: 20px;
  top: 0px;
  border-radius: 8px;
}
.classroom-menu {
  z-index: 99;
  position: absolute;
  padding: 18px 20px;
  background: #fff;
  left: 720px;
  top: 0px;
  border-radius: 8px;
  font-size: 18px;
}
.request-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
  text-align: center;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  .content-wrap {
    position: relative;
    .svg-wrap {
      width: 140px;
      height: 140px;
      margin: 0px auto;
      text-align: center;
      border-radius: 70px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      .svg-img {
        width: 85px;
        height: 85px;
        font-size: 85px;
      }
    }
    p.title {
      margin: 24px 0px;
      font-weight: 500;
      font-size: 18px;
      color: #444558;
      line-height: 22px;
    }
    .btn {
      cursor: pointer;
      margin: 0px auto;
      text-align: center;
      .w-n(180);
      .h-n(40);
      .lh-n(40);
      .bd-r-n(16);
      background: #3a78f6;
      color: #fff;

      font-size: 16px;
      &:hover {
        background: #005eff;
      }
    }
    .btn.btn-activity {
      //background: #3a70ff linear-gradient(90deg, #2efffd 0%, #294cef 100%) !important;
      background: #bbbfc4;
      cursor: not-allowed;
      // &:hover {
      //   background: #005eff;
      // }
    }
  }
}

.people-refuse {
  .infor-title {
    text-align: center;
    margin: -13px 47px 34px 47px;
    p {
      color: #6e7491;
      line-height: 22px;
    }
  }
}
.more-people-wrap {
  background: #fff;
  width: 520px;
  border-radius: 16px;
  padding-bottom: 32px;
  .company-name {
    font-size: 14px;
    font-weight: 500;
    text-align: LEFT;
    color: #444558;
    line-height: 22px;
    padding: 24px 0px 0px 24px;
  }
  .infor-list {
    .flex-c();
    text-align: left;
    margin: 0px 40px;
    .svg-img {
      width: 30px;
      height: 30px;
      font-size: 30px;
      color: #ed2e7e;
      margin-right: 12px;
    }
    .title {
      line-height: 22px;
    }
  }
}
.expire-div {
  // background: rgba(20, 20, 43, 0.45);
  min-width: 720px;
  padding: 24px 32px 40px 24px;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  .flex-l();
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
  .handle-list,
  .btn-list {
    max-width: 1648px !important;
  }
  .expire-top {
    width: 100%;
    .top-img {
      width: 360px;
      height: auto;
    }
    .top-title {
      font-size: 24px;
      font-weight: bold;
      color: #ffffff;
      line-height: 42px;
      margin-top: -24px;
    }
    .school-img {
      width: 100%;
      max-width: 520px;
      margin-top: 24px;
    }
  }
  .handle-list {
    position: relative;
    .flex-bt();
    margin-top: 16px;
    width: 100%;
    justify-content: center;
    .list {
      width: calc(33.33% - 16px);
      max-width: 330px;
      // padding: 0px 24px;
      text-align: left;
      margin: 0px 12px;
      border-radius: 16px;
      .flex-l();
      justify-content: space-between;
      // flex-direction: column;
      position: relative;
      .img {
        width: 100%;
        cursor: pointer;
      }
      .activity-1 {
        position: absolute;
        right: 0px;
        top: -40px;
        width: 140px;
        // .w(140);
      }
      .activity-2 {
        position: absolute;
        right: 40px;
        width: 120px;
        bottom: -28px;
        // .w(120);
      }
      .list-name {
        font-size: 28px;
        font-weight: bold;
        color: #14142b;
      }
      .list-title {
        font-size: 14px;
        font-weight: 500;
        color: rgba(20, 20, 43, 0.8);
        max-width: 170px;
        line-height: 20px;
        min-height: 40px;
      }
      .list-content {
        margin-top: 4px;
        position: relative;
        z-index: 0;
        .content-line {
          z-index: -1;
          position: absolute;
          left: -2px;
          bottom: 0;
          width: 61px;
          height: 8px;
          border-radius: 12px;
          background: #3a70ff linear-gradient(90deg, #2efffd 0%, #294cef 100%) !important;
        }
      }
      .btn {
        width: 120px !important;
        // margin-top: 48px !important;
      }
      .btn.btn-activity {
        // margin-top: 22px !important;
      }
    }
  }
  .btn-wrap {
    width: 120px;
  }
  .btn-list {
    width: 100%;
    margin: 42px 0px 24px 0px;
    text-align: center;
    .flex-c();
    justify-content: flex-end;
    padding-right: 12px;
    box-sizing: border-box;
    div {
      font-size: 14px;
      font-weight: bold;
      color: #f5f6fa;
      cursor: pointer;
      margin-left: 16px;
    }
    &:hover {
      color: #3a70ff;
    }
  }
  .company-name {
    font-size: 14px;
    font-weight: 500;
    text-align: LEFT;
    color: #444558;
    line-height: 22px;
    display: flex;
    flex-direction: row;

    div.company-name-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:before {
        content: '';
        display: block;
      }
      .svg-img {
        // width: 24px;
        // height: 24px;
        color: #ed2e7e;
        font-size: 36px;
        margin-right: 6px;
      }
    }

    span {
      display: inline-block;
      width: 70px;
      text-align: right;
      font-size: 14px;
      font-weight: 400;
      color: #3a78f6;
      cursor: pointer;
    }
  }
  .infor-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    height: 45px;
    padding-left: 20px;
    margin-top: 20px;
    .svg-img {
      color: #ed2e7e;
      font-size: 30px;
      margin-right: 10px;
    }
    .title {
      line-height: 22px;
    }
  }
  .notice-infor-list {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .infor-text {
    margin-left: 53px;
    width: 374px;
    text-align: left;
    font-size: 14px;
    color: #6e7491;
    font-weight: 400;
    line-height: 22px;
    span {
      color: #3a78f6;
    }
  }
  .btn {
    // margin-top: 50px !important;
  }
  .expire-form-div {
    margin-top: 32px;
    margin-left: 32px;
    width: 70%;
  }
}
.user-wrap {
  .flex-c();
  margin-bottom: 27px;
  .user-list {
    width: 210px;
    background: rgba(238, 246, 253, 0.54);
    border: 1px dashed rgba(50, 150, 250, 0.56);
    border-radius: 17px;
    height: 72px;
    .flex-c();
    .user-wrap-left {
      img {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        margin-right: 12px;
      }
    }
    .user-wrap-right {
      .name {
        font-weight: 500;
        text-align: LEFT;
        color: #14142b;
        line-height: 19px;
        font-size: 16px;
        max-width: 130px;
        .text-overOne();
      }
      .tel {
        color: #a0a3bd;
        line-height: 16px;
        font-size: 14px;
        margin-top: 4px;
      }
    }
  }
}
::v-deep .el-popper {
  z-index: 9999 !important;
}
</style>
<style>
.request-box-drawer .el-drawer__header {
  display: none;
}
.request-box-drawer .el-drawer {
  top: 0px;
  bottom: 0px;
  width: 100%;
  height: 100% !important;
  background: none;
  overflow: hidden;
}
.version-single-dialog {
  z-index: 999999 !important;
}
</style>
