import Vue from 'vue'
import debounce from 'lodash/debounce' // 局部引入防抖

// 滚动到底部触发
export default () => {
  Vue.directive('select-scroll-bottom', {
    bind(el, binding) {
      // 获取element，定义scroll
      const select_dom = el.querySelector(
        '.el-select-dropdown .el-select-dropdown__wrap'
      )
      select_dom.addEventListener('scroll', function () {
        if (this.scrollHeight - this.scrollTop - this.clientHeight < 50) {
          binding.value()
        }
      })
    }
  })
}
