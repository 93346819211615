<template>
  <div class="apply-content">
    <div class="field-list-wrap field-list-flex">
      <div class="username">
        <el-input v-model="userName" type="text" placeholder="您的姓名" :maxlength="30" />
      </div>
      <div class="job-title">
        <el-select v-model="jobTitle" placeholder="请选择" popper-class="apply-select-index">
          <el-option v-for="item in jobTitleList" :key="item" :label="item" :value="item" />
        </el-select>
      </div>
    </div>
    <div class="login-form-div">
      <div class="login-form">
        <fieldset
          :class="loginBorderShow ? 'login-fieldset login-fieldset-border' : 'login-fieldset'"
        >
          <input
            v-model="telephone"
            type="text"
            name="tel"
            maxlength="11"
            class="login-input"
            oninput="value=value.replace(/[^\d]/g,'')"
            :autocomplete="isAllowSavePassword == 1 ? 'off' : 'on'"
            placeholder="请输入手机号"
            :disabled="mobileDisabled"
            @blur="telBlur"
            @focus="inputFocus(0)"
          />
          <!-- <template v-if="telephone !== ''">
            <i class="el-icon-error login-clear-icon" @click="clearInput(0)"></i>
          </template>-->
        </fieldset>
      </div>
    </div>
    <div class="field-list-wrap">
      <div class="field-title">希望进一步使用的功能模块：</div>
      <div class="module-list">
        <div
          class="module-name"
          :class="`module-name-${selectModule.indexOf(item) !== -1}`"
          v-for="item in moduleList"
          :key="item"
          @click="moduleClick(item)"
        >{{item}}</div>
      </div>
    </div>
    <div class="field-list-wrap">
      <div class="field-title">希望通过 OKR 解决的问题：</div>
      <div class="module-list">
        <div
          class="module-name"
          :class="`module-name-${selectSolution.indexOf(item) !== -1}`"
          v-for="item in solutionList"
          :key="item"
          @click="solutionClick(item)"
        >{{item}}</div>
      </div>
    </div>
    <div class="apply-button bottom-step-buttons">
      <L-button :loading="loading" class="confirm" @clickEvt="confirm">申请延期</L-button>
    </div>
    <div class="remind-title">我们将在一个工作日内联系您</div>
    <process-dialog
      v-if="processDialogVisible"
      v-model="processDialogVisible"
      :type="'applyDemo'"
      btn="关闭"
      dialog-txt="<p>提交成功</p><p>我们将在一个工作日内联系您</p>"
      @close="processDialogClose"
    />
  </div>
</template>
<script>
import processDialog from '@/components/global/ProcessDialog' // 复制弹窗
export default {
  components: {
    processDialog
  },
  props: {
    type: {
      default: 'dialog'
    }
  },
  data() {
    return {
      loginBorderShow: false, // 登录input框鼠标获取焦点样式
      telephone: '', // 手机号
      userName: '',
      yzmDisabled: false,
      yzmCode: '', // 验证码
      jobTitle: '总经理/CEO',
      jobTitleList: ['总经理/CEO', '人事总监/经理', '采购负责人', 'HR', 'IT 负责人', '产品负责人', '研发负责人', '业务部负责人', '其他'],
      userNumber: '30人以下',
      userNumberList: ['30人以下', '30-100人', '100-200人', '200-500人', '500-1000人', '1000人以上'],
      moduleList: ['OKR', '项目', 'Todo', '咪厅', '总结', '绩效', '统计分析', 'OKR 辅导与培训'],
      selectModule: [],
      timerCount: '', // 计时文案
      timerShow: undefined, // 计时器显示
      loading: false,
      processDialogVisible: false,
      solutionList: ['目标不聚焦', '跨部门协作', '提升管理效率', '目标无法分解', 'OKR 如何落地', 'OKR 与 KPI 结合', '执行力效率低'],
      selectSolution: [],
      companyId: '',
      companyName: '',
      mobileDisabled: false
    }
  },
  watch: {
    telephone: function () {
      var that = this
      that.isStatusMsg = false
      that.messageText = ''
      that.yzmDisabled = false
      that.nextDisabled = false
    },
    yzmCode: function () {
      var that = this
      that.isCodeMsg = false
      that.codeText = ''
      that.nextDisabled = false
    }
  },
  created() {
    this.companyId = (this.getUrlKey('companyId') || this.userInfo.companyId || '')
    this.companyName = (this.getUrlKey('companyName') || this.userInfo.companyName || '')
    if ((this.telephone || '') !== '') {
      this.mobileDisabled = true
    }
  },
  methods: {
    // 获取url参数 公共方法
    getUrlKey(name) {
      return (
        decodeURIComponent(
          (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
            location.href
          ) || [[], ''])[1].replace(/\+/g, '%20')
        ) || null
      )
    },
    processDialogClose() {
      this.$emit('close')
    },
    confirm() {
      var reg = /^1(3|4|5|6|7|8|9)\d{9}$/
      if (this.telephone.length === 0) {
        this.$message.warn('手机号不能为空!')
        return
      }
      if (!reg.test(this.telephone)) {
        this.$message.warn('手机号格式不正确！')
        return
      }
      if ((this.userName || '') === '') {
        this.$message.warn('姓名不能为空哦！')
        return
      } else if ((this.selectModule || []).length === 0) {
        this.$message.warn('“希望进一步使用的功能模块” 不能为空哦！')
        return
      } else if ((this.selectSolution || []).length === 0) {
        this.$message.warn('“希望通过 OKR 解决的问题” 不能为空哦！')
        return
      }
      var selectModule = ''
      this.selectModule.forEach((item, index) => {
        selectModule += `${item}${(index + 1) !== this.selectModule.length ? ',' : ''}`
      })
      var selectProblem = ''
      this.selectSolution.forEach((item, index) => {
        selectProblem += `${item}${(index + 1) !== this.selectSolution.length ? ',' : ''}`
      })
      var params = {
        jobTitle: this.jobTitle,
        name: this.userName,
        phone: this.telephone,
        selectModule,
        selectProblem,
        verificationCode: this.yzmCode,
        companyId: (this.companyId || ''),
        companyName: (this.companyName || ''),
        clientType: this.$isQw ? 'qw' : this.$isDD ? 'dd' : 'saas'
      }
      this.loading = true
      this.$apis.COMMONAPPOINTMENTDELAY(params).then((res) => {
        if (res.status === 200) {
          if (this.type !== 'dialog') {
            this.$message.success('提交成功')
          } else {
            this.processDialogVisible = true
          }
          this.initData()
          this.loading = false
        } else {
          this.$message.error(res.message)
        }
        this.loading = false
      })
    },
    initData() {
      this.userName = ''
      this.telephone = ''
      this.jobTitle = '老板/CEO/总经理'
      this.userNumber = '1-50人'
      this.yzmCode = ''
      this.selectModule = []
      this.selectSolution = []
      this.yzmDisabled = false
      this.timerCount = '' // 计时文案
      this.timerShow = undefined // 计时器显示
    },
    moduleClick(item) {
      var moduleState = this.selectModule.indexOf(item)
      if (moduleState === -1) {
        this.selectModule.push(item)
      } else {
        this.selectModule.splice(moduleState, 1)
      }
    },
    solutionClick(item) {
      var moduleState = this.selectSolution.indexOf(item)
      if (moduleState === -1) {
        this.selectSolution.push(item)
      } else {
        this.selectSolution.splice(moduleState, 1)
      }
    },
    yzmBlur() {
      this.yzmBorderShow = false
    },
    // 验证手机号 失去焦点
    telBlur() {
      this.loginBorderShow = false
    },
    // 获取焦点 显示清空按钮
    inputFocus(num) {
      this.pwdBorderShow = false
      this.loginBorderShow = false
      this.yzmBorderShow = false
      if (num === 0) {
        this.loginBorderShow = true
        // this.clear_show_hide = '0'
        // this.clear_show_hide_pass = '-1'
      } else if (num === 1) {
        this.yzmBorderShow = true
        // this.clear_show_hide_pass = '1'
        // this.clear_show_hide = '-1'
      } else if (num === 2) {
        this.pwdBorderShow = true
      }
    },
    // 清空指定内容
    clearInput(num) {
      if (num === 0) {
        this.telephone = '' // 清空手机号
      } else if (num === 1) {
        this.password = '' // 清空密码
      }
    },
    // 验证码回车
    yzmEnter() {
      this.smsNext()
    },
    // 获取验证码
    getYzm() {
      var reg = /^1(3|4|5|6|7|8|9)\d{9}$/
      if (this.telephone.length === 0) {
        this.isStatusMsg = true
        this.messageText = '手机号不能为空!'
        this.nextDisabled = true
      }
      if (!reg.test(this.telephone)) {
        this.isStatusMsg = true
        this.messageText = '手机号格式不正确!'
        this.nextDisabled = true
      }
      if (this.nextDisabled == true) {
        return
      }
      const obj = {}
      obj.username = this.telephone
      obj.type = '6' // 1 获取验证码登陆登陆时
      this.sendSms(obj, 0)
    },
    // 发送验证码
    sendSms(obj, type) {
      this.loginLoading = true
      this.$apis.GETCODE(obj).then((res) => {
        if (res.status === 200) {
          // this.$mixpanelUtil.track('Send Vcode', { phone: obj.username, link: this.semSeoType })
          if (type === 0) {
            // this.pageStatus = 1
            // 暂时调取倒计时
            this.getCode()
          }
          this.loginLoading = false
        } else {
          this.$message.error(res.message)
          this.loginLoading = false
        }
      })
    },
    // 倒计时  在发送验证码 点击时调用
    getCode() {
      const TIME_COUNT = 60
      if (!this.timer) {
        this.timerCount = TIME_COUNT
        this.timerShow = false
        this.timer = setInterval(() => {
          if (this.timerCount > 0 && this.timerCount <= TIME_COUNT) {
            this.timerCount--
            this.yzmDisabled = true
          } else {
            this.timerShow = true
            this.yzmDisabled = false
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    },
    // 再次发送验证码
    tryAgin() {
      // this.$refs.codeInput.emptyCode()
      this.code = []
      const obj = {}
      obj.username = this.telephone
      obj.type = '6' // 1 获取验证码登陆登陆时
      this.sendSms(obj, 0)
    },
    // 验证码
    smsNext() {
      var that = this
      var reg = /^1(3|4|5|6|7|8|9)\d{9}$/
      if (that.telephone.length === 0) {
        console.log(11111)
        that.isStatusMsg = true
        that.messageText = '手机号不能为空!'
        that.nextDisabled = true
      }
      if (!reg.test(that.telephone)) {
        that.isStatusMsg = true
        that.messageText = '手机号格式不正确!'
        that.nextDisabled = true
      }
      if (that.yzmCode.length === 0) {
        console.log(2222)
        that.isCodeMsg = true
        that.codeText = '验证码不能为空!'
        that.nextDisabled = true
      }
      if (that.yzmCode.length < 6) {
        that.isCodeMsg = true
        that.codeText = '验证码是6位数字!'
        that.nextDisabled = true
      }
      if (that.nextDisabled == true) {
        return
      }
      // 失去焦点时判断 验证码是否 有空值？
      let boolSms = false
      if (that.yzmCode.length === 0 || that.yzmCode.length < 6) {
        boolSms = true
      } else {
        boolSms = false
      }
      if (boolSms) {
        that.isCodeMsg = true // 验证码登录提示
        that.codeText = '验证码有误,请重新输入' // 验证码登录提示文案
      } else {
        // 手机号 + 登录验证码 登录
        const obj = {}
        obj.identifying = that.yzmCode
        obj.username = that.telephone
        that.codeLogin(obj)
      }
    },
  }
}
</script>
<style lang="less" scoped>
@import '../../../../../../../assets/styles/dialog.less';
.apply-content {
  .field-list-wrap {
    margin-bottom: 16px;
    .username,
    .job-title {
      width: calc(50% - 4px);
    }
    .username {
      margin-right: 8px;
    }
    .user-number {
      .el-select {
        width: 100%;
      }
    }
    .job-title {
      .el-select {
        width: 100%;
      }
    }
    .field-title {
      font-size: 14px;
      color: #29334d;
      margin: 20px 0px 16px 0px;
    }
    .module-list {
      .flex-l();
      flex-wrap: wrap;
      .module-name {
        cursor: pointer;
        line-height: 32px;
        height: 32px;
        border-radius: 8px;
        padding: 0px 12px;
        margin: 0px 8px 8px 0px;
        border: 1px solid rgba(217, 219, 233, 0.54);
        font-size: 12px;
        color: #444558;
        &:hover {
          background: #ebf1ff;
          border: 1px solid #9cb7ff;
          color: #3a70ff;
        }
      }
      .module-name-true {
        background: #ebf1ff;
        border: 1px solid #9cb7ff;
        color: #3a70ff;
      }
    }
  }
  .apply-button {
    height: 40px;
    .confirm {
      width: 100%;
      height: 40px;
      line-height: 38px;
    }
  }
  .remind-title {
    font-size: 12px;
    color: #767f99;
    margin: 12px 0px 0px 0px;
    text-align: center;
  }
  .field-list-flex {
    .flex-l();
  }
  .login-form-div {
    flex: 1;
    display: flex;
    flex-direction: column;
    .login-form {
      flex: 1;
      // 登录
      .login-fieldset {
        // height: 480px !important;
        padding: 0;
        border: 1px solid rgba(217, 219, 233, 0.54) !important;
        background: rgba(58, 120, 246, 0) !important;
        border-radius: 12px;
        font-size: 14px;
        // overflow: hidden;
        position: relative;
        .login-clear-icon {
          font-size: 18px;
          color: rgba(217, 219, 233, 1);
          width: 14px;
          cursor: pointer;
          position: absolute;
          right: 15px;
          top: 11px;
        }
        .login-clear-icon:hover {
          color: #3068ff;
        }
        .login-pwd-show {
          display: inline-block;
          color: rgba(217, 219, 233, 1);
          cursor: pointer;
          position: absolute;
          right: 36px;
          top: 8px;
          font-size: 20px;
        }
        .login-pwd-show:hover {
          color: #3068ff;
        }
        .login-pwd-hide {
          display: inline-block;
          color: rgba(217, 219, 233, 1);
          cursor: pointer;
          position: absolute;
          right: 36px;
          top: 8px;
          font-size: 20px;
        }
        .login-pwd-hide:hover {
          color: #3068ff;
        }
        label {
          margin: 0 15px;
          color: #3068ff;
          font-size: 16px !important;
          display: block;
          line-height: 40px;
          float: left;
        }
        .login-input {
          border: 0;
          padding: 14px 0 14px 16px;
          height: 40px;
          flex: 1;
          font-size: 16px !important;
          width: 74%;
          -webkit-box-shadow: none !important;
          box-shadow: none !important;
          background-color: none !important;
          background: transparent !important;
          border-radius: 12px !important;
        }
        .login-input:focus {
          -webkit-box-shadow: none !important;
          box-shadow: none !important;
          background-color: none !important;
        }
      }
      .login-fieldset-border {
        border: 1px #3068ff solid !important;
        input {
          color: #3068ff !important;
        }
      }
      .login-fieldset:hover {
        border: 1px rgba(58, 120, 246, 0.12) solid !important;
        background: #fff !important;
      }
      .login-fieldset:focus {
        border: 1px #3068ff solid !important;
      }
      .login-message {
        margin-top: 10px;
        font-size: 14px;
        color: #f93300;
        line-height: 20px;
        padding: 0 5px;
      }
      // 验证码
      .login-fieldset-sms {
        border: none;
        padding: 0;
        input {
          width: 46px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          margin-left: 5px;
          border-radius: 4px;
          border: 1px solid rgba(150, 159, 190, 1);
        }
        input.border-input-blue {
          border: 1px solid #3067fe !important;
        }
        input.border-input {
          box-shadow: none;
          box-shadow: none !important;
          background: transparent;
          filter: alpha(opacity=100); /* IE */
          -moz-opacity: 1; /* 老版Mozilla */
          -khtml-opacity: 1; /* 老版Safari */
          opacity: 1; /* 支持opacity的浏览器*/
        }
        input:focus {
          border: 1px solid;
        }
        .fieldset-sms-line {
          display: inline-block;
          width: 40px;
          height: 2px;
          border-bottom: 1px solid #ddd;
          align-self: center;
          margin-left: 10px;
        }
      }
      .login-text-div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 8px;
        .login-text {
          margin-bottom: 8px;
        }
      }
      // 密码
      .forget-pwd-div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 5px;
        margin-top: 10px;
        .el-button.go-register-btn {
          font-size: 14px;
          color: #3068ff;
          padding: 0 !important;
          margin: 0 !important;
        }
        .el-button.go-register-btn:hover {
          border-bottom: 1px #3068ff solid;
        }
      }
      //创建团队
      .login-fieldset-ctrate {
        border: 0;
        div {
          position: relative;
          // overflow: hidden;
        }
        div.type-radio {
          margin-top: 10px;
          height: 40px;
          display: flex;
          flex-direction: row;
          align-items: center;
          .radio-label {
            width: 60px !important;
            text-align: left;
            font-size: 14px;
            font-weight: 400;
            color: #14142b;
            margin-right: 24px;
          }
          .radio-content {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 0px;
            ::v-deep {
              .el-radio.is-checked .el-radio__inner {
                border-width: 2px;
              }
              .el-radio__inner::after {
                width: 10px;
                height: 10px;
                border-radius: 100%;
                background-color: #3a78f6;
              }
            }
          }
          .el-radio {
            margin-right: 16px;
          }
        }
        .register-clear-icon {
          font-size: 18px;
          color: rgba(217, 219, 233, 1);
          width: 14px;
          cursor: pointer;
          position: absolute;
          right: 15px;
          top: 14px;
        }
        .register-clear-icon-create {
          font-size: 18px;
          color: rgba(217, 219, 233, 1);
          width: 14px;
          cursor: pointer;
          position: absolute;
          right: 15px;
          top: 11px;
        }
        .register-clear-icon:hover {
          color: #3068ff;
        }
        input {
          width: 100%;
          font-size: 16px;
          line-height: 40px;
          height: 40px;
          text-indent: 10px;
          color: #14142b;
          -webkit-box-shadow: none !important;
          box-shadow: none !important;
          background-color: none !important;
          border-radius: 12px;
          padding: 0 34px 0 6px;
          border: 1px solid rgba(58, 120, 246, 0.06);
          background: rgba(58, 120, 246, 0.06);
        }
        input.login-input-border {
          border: 1px #3068ff solid !important;
          color: #3068ff !important;
        }
        input:hover {
          border: 1px rgba(58, 120, 246, 0.12) solid !important;
          background: #fff !important;
        }
      }
      //待审核
      .login-examine {
        height: 160px;
        overflow-y: auto;
        margin-top: 20px;
        .login-examine-list {
          display: flex;
          flex-direction: row;
          height: 40px;
          line-height: 40px;
          align-items: center;
          img {
            width: 24px;
            height: 24px;
          }
          .login-examine-text {
            flex: 1;
            margin-left: 12px;
            max-width: 400px;
            word-break: break-word;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -moz-box-orient: vertical;
            -webkit-line-clamp: 1;
            -moz-line-clamp: 1;
            overflow: hidden;
            float: left;
            &:before {
              content: '';
              display: block;
            }
          }
        }
      }
    }
    .login-button-div {
      width: 100%;
      .go-register-div {
        font-size: 14px;
        color: #6e7491;
        margin-top: 14px;
      }
    }
  }
  .yzm-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 16px;
    .login-fieldset {
      flex: 1;
    }
    .el-button.next-button.get-code-button {
      width: 95px !important;
      margin-left: 8px;
    }
  }
  .el-button.next-button.is-disabled {
    background: rgba(58, 120, 246, 0.54) !important;
  }
}
</style>
