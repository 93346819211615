<template>
  <div>
    <el-dialog
      title="设置填写提醒"
      :visible.sync="dialogVisible"
      width="550px"
      :append-to-body="true"
      :before-close="handleClose"
      class="comm-check-form"
    >
      <div class="dialog-content comm-input">
        <div class="remind-day-form">
          <div class="form-item">
            <div class="item-title">开始前第</div>
            <day-select v-model="form.startBeforeDay" class="form-select" /> 天
          </div>
          <div class="form-item">
            <div class="item-title">开始后第</div>
            <day-select v-model="form.startAfterDay" class="form-select" /> 天
          </div>
        </div>
        <div class="form-item">
          <div class="item-title">时间</div>
          <el-time-select
            v-model="form.remindTime"
            class="form-select"
            :editable="false"
            :picker-options="{
              start: '00:00',
              step: '00:30',
              end: '23:30'
            }"
            placeholder="选择时间"
            :clearable="false"
          />
        </div>
        <div class="remind-text">
          提醒时间：{{ remindText }}
          <span class="remind-time">{{ form.remindTime }}</span>
        </div>

        <div class="bottom-step-buttons">
          <button @click="handleClose" class="left-button">取消</button>
          <button @click="save">确定</button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import daySelect from '../DaySelect'
export default {
  components: {
    daySelect
  },
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  props: {
    dialogShow: {
      type: Boolean,
      default: false
    },
    remindData: {
      type: Array
    }
  },
  watch: {
    dialogShow: {
      handler(val) {
        this.dialogVisible = val
      }
    }
  },
  data() {
    return {
      dialogVisible: this.dialogShow,
      form: {
        startId: '',
        endId: '',
        startBeforeDay: [1, 2, 3],
        startAfterDay: [1, 2, 3],
        remindTime: '10:30'
      }
    }
  },
  computed: {
    remindText() {
      const startList = this.form.startBeforeDay.sort()
      const endList = this.form.startAfterDay.sort()
      return `${
        startList.length > 0 ? `周期开始前第${startList.join('、')}天` : ''
      } ${startList.length > 0 && endList.length > 0 ? '和' : ''} ${
        endList.length > 0 ? `周期开始后第${endList.join('、')}天` : ''
      }`
    }
  },
  created() {
    this.initData()
  },
  methods: {
    // 初始化数据
    initData() {
      if (this.remindData.length > 0) {
        this.remindData.forEach((item) => {
          if (item.notifyTimeType === 1) {
            this.form.startId = item.id
            this.form.startBeforeDay = item.notifyDataNum
              .split(',')
              .map((item) => Number(item))
            this.form.remindTime = item.notifyDataTimeNum
          }
          if (item.notifyTimeType === 2) {
            this.form.endId = item.id
            this.form.startAfterDay = item.notifyDataNum
              .split(',')
              .map((item) => Number(item))
            this.form.remindTime = item.notifyDataTimeNum
          }
        })
      }
    },
    // 保存
    save() {
      const data = []
      if (this.form.startBeforeDay.length > 0) {
        data.push({
          id: this.form.startId,
          notifyType: 0, // 提醒类型（0填写提醒 1更新进度提醒 2复盘提醒 ）
          notifyTimeType: 1, // 提醒时间类型（0周期进行中 1周期开始前 2周期开始后 3周期结束前 4周期结束后 ）
          notifyDataType: 0, // 提醒数据类型（0 天 1周 2月 ）
          notifyDataNum: this.form.startBeforeDay.join(','), // 提醒数据值 列如 ”2,3“ 正序排列
          notifyDataTimeNum: this.form.remindTime // 提醒数据时间值
        })
      }
      if (this.form.startBeforeDay.length > 0) {
        data.push({
          id: this.form.endId,
          notifyType: 0, // 提醒类型（0填写提醒 1更新进度提醒 2复盘提醒 ）
          notifyTimeType: 2, // 提醒时间类型（0周期进行中 1周期开始前 2周期开始后 3周期结束前 4周期结束后 ）
          notifyDataType: 0, // 提醒数据类型（0 天 1周 2月 ）
          notifyDataNum: this.form.startAfterDay.join(','), // 提醒数据值 列如 ”2,3“ 正序排列
          notifyDataTimeNum: this.form.remindTime // 提醒数据时间值
        })
      }
      if (data.length === 0) {
        this.$message.warn('请设置提醒时间！')
        return
      }
      this.$emit('remindSet', data)
      this.handleClose()
    },
    handleClose() {
      this.dialogVisible = false
      this.$emit('change', false)
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../../assets/styles/dialog.less';
.dialog-content {
  padding: 32px 16px 16px;
  .form-item {
    display: flex;
    align-items: center;
    color: #14142b;
    line-height: 16px;
    ::v-deep {
      .el-tag__close {
        display: none;
      }
      .el-select__tags {
        flex-wrap: nowrap;
        overflow-x: auto;
        @include scroll-beautify;
      }
    }
    .item-title {
      width: 70px;
      margin-right: 8px;
      text-align: right;
    }
    .form-select {
      width: 120px;
      margin-right: 8px;
    }
  }
  .remind-day-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .remind-text {
    height: 32px;
    font-size: 12px;
    line-height: 16px;
    margin-top: 8px;
    color: #a0a3bd;
    margin-left: 16px;
    .remind-time {
      white-space: nowrap;
    }
  }
}
</style>
