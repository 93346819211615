<template>
  <div :class="`share-user-avatar-wrap number-${list.length > 9 ? 9 : list.length}`">
    <div
      class="avatar-list"
      v-for="item in list.length > 9 ? list.slice(0, 9) : list"
      :key="item.user.id"
    >
      <el-image
        :src="(type === 'map' ? getUserAvatar(item.user.avatar) : item.user.avatar) || require('@/assets/imgs/common/avatar.png')"
        fit="cover"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
    },
    type: {
      default: ''
    }
  },
  methods: {
    getUserAvatar(url) {
      var currentUrl = url
      if (!this.$isQw) {
        if (url.indexOf('static-legacy.dingtalk.com') !== -1) {
          var splitDdUrl = url.split('/media')
          currentUrl = `${window.location.protocol}//${window.location.host}/static/avatar/media${splitDdUrl[1]}`
        } else {
          var splitUrl = url.split('/oss')
          currentUrl = `${window.location.protocol}//${window.location.host}/static/avatar/oss${splitUrl[1]}`
        }
      }
      return currentUrl
    }
  }
}
</script>
<style lang="less" scoped>
.share-user-avatar-wrap {
  width: 100%;
  height: 100%;
  background: #e9ebee;
  .flex-c();
  flex-wrap: wrap;
  flex-flow: wrap-reverse;
  .avatar-list {
    margin: 0.5px;
    .el-image {
      width: 100%;
      height: 100%;
      vertical-align: top;
    }
  }
}
.share-user-avatar-wrap.number-2,
.share-user-avatar-wrap.number-3,
.share-user-avatar-wrap.number-4 {
  .avatar-list {
    height: calc(50% - 1px);
    width: calc(50% - 1px);
  }
}
.share-user-avatar-wrap.number-5,
.share-user-avatar-wrap.number-6 {
  .avatar-list {
    height: calc(33.33% - 1px);
    width: calc(33.33% - 1px);
    &:nth-child(n + 4) {
      margin-top: 16%;
    }
    &:nth-child(-n + 3) {
      margin-bottom: 16%;
    }
  }
}
.share-user-avatar-wrap.number-7,
.share-user-avatar-wrap.number-8,
.share-user-avatar-wrap.number-9 {
  .avatar-list {
    width: calc(33.33% - 1px);
    height: calc(33.33% - 1px);
  }
}
</style>
