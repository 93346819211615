var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "okr-detail-wrap" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "okr-detail-dialog",
          attrs: {
            visible: _vm.dialogVisible,
            "append-to-body": true,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "vueloading",
                    rawName: "v-vueloading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                staticClass: "objective-detail",
                on: { click: _vm.clickNoFocus },
              },
              [
                _c("div", { staticClass: "objective-detail-header" }, [
                  _c(
                    "div",
                    { staticClass: "flex-item cycle-info" },
                    [
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content: "点击修改目标类型",
                            disabled:
                              _vm.userInfo.id === _vm.objectiveObj.createUserid
                                ? false
                                : true,
                            placement: "top",
                          },
                        },
                        [
                          _c(
                            "objective-label",
                            {
                              attrs: {
                                "page-type": "okrDetail",
                                "okr-data": _vm.objectiveObj,
                                "can-edit":
                                  _vm.userInfo.id ===
                                  _vm.objectiveObj.createUserid
                                    ? true
                                    : false,
                                "label-type": _vm.objectiveObj.labelType,
                                "share-list":
                                  _vm.objectiveObj.objectivesSharedUserList,
                              },
                              on: {
                                refresh: function ($event) {
                                  return _vm.queryObjectiveDetail(
                                    _vm.objectiveObj.id,
                                    "",
                                    "refresh"
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "info-box" },
                                [
                                  _c("Icon", {
                                    staticClass: "target-icon",
                                    attrs: {
                                      "icon-class": "icon-mainpage_tab_okr",
                                    },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.labelTypeObj[
                                          _vm.objectiveObj.labelType
                                        ]
                                      ) +
                                      "目标 "
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "info-box" }, [
                        _vm._v(_vm._s(_vm.showCycleTitle)),
                      ]),
                      _vm.objPathData.length > 1
                        ? _c(
                            "div",
                            { staticClass: "okr-path" },
                            [
                              _c("okr-detail-path", {
                                attrs: { "obj-path-data": _vm.objPathData },
                                on: { getTargetReturn: _vm.getTargetReturn },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex-item obj-actions" },
                    [
                      _vm.objectiveObj.processState === 1 &&
                      _vm.objectiveObj.objectivesProcessDataDto
                        ? _c("approveTypeLabel", {
                            attrs: {
                              "obj-details": _vm.objectiveObj,
                              "business-type":
                                _vm.objectiveObj.objectivesProcessDataDto &&
                                _vm.objectiveObj.objectivesProcessDataDto
                                  .businessType,
                            },
                          })
                        : _vm._e(),
                      _vm.objectiveObj.canBeScored === 1 &&
                      (_vm.objectiveObj.score || _vm.objectiveObj.score === 0)
                        ? _c("div", { staticClass: "action-item score-item" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/imgs/okr/okrScore.png"),
                              },
                            }),
                            _vm._v(
                              " " + _vm._s(`${_vm.objectiveObj.score}分`) + " "
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "action-item" },
                        [
                          _c("confidence-select", {
                            attrs: { "top-members-beans": _vm.objectiveObj },
                            on: { change: _vm.changeConfidence },
                            model: {
                              value: _vm.objectiveObj.confidence,
                              callback: function ($$v) {
                                _vm.$set(_vm.objectiveObj, "confidence", $$v)
                              },
                              expression: "objectiveObj.confidence",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "action-item",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.showKpiIndex()
                            },
                          },
                        },
                        [
                          _c("Icon", {
                            staticClass: "icon-kpi",
                            attrs: { "icon-class": "icon-jixiao" },
                          }),
                          _c("div", { staticClass: "laud-count" }, [
                            _vm._v(
                              _vm._s(_vm.objectiveObj.performanceNum || 0)
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "action-item list-animocon-wrap" },
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "icobutton icobutton--thumbs-up opacityNumber",
                              class: "okrDetailId" + _vm.objectiveObj.id,
                              on: {
                                click: function ($event) {
                                  return _vm.getTargetLink(_vm.objectiveObj)
                                },
                              },
                            },
                            [
                              _c("span", {
                                staticClass: "fa fa-thumbs-up",
                                class:
                                  _vm.objectiveObj.isLaud === 1
                                    ? "fa_style_state"
                                    : "fa_style_state_2",
                              }),
                            ]
                          ),
                          _vm.objectiveObj.laudUserAvatar
                            ? _c("el-image", {
                                staticClass: "kr-right-avatar border-radius-20",
                                attrs: {
                                  fit: "cover",
                                  src:
                                    _vm.objectiveObj.laudUserAvatar ||
                                    require("@/assets/imgs/common/avatar.png"),
                                },
                              })
                            : _vm._e(),
                          _vm.showLikeRedpoint
                            ? _c("div", {
                                staticClass: "common-red-point",
                                class: _vm.objectiveObj.laudUserAvatar
                                  ? ""
                                  : "no-avatar-point",
                              })
                            : _vm._e(),
                          _c("div", { staticClass: "laud-count" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("numberHandle")(
                                  _vm.objectiveObj.laudCount
                                )
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "action-item list-target-wrap" },
                        [
                          _c("view-target", {
                            attrs: {
                              icon: "icon-chakan",
                              "obj-info": _vm.objectiveObj,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          class: `okr-care okr-care-type-${_vm.objectiveObj.careState}`,
                          on: {
                            click: function ($event) {
                              return _vm.okrCareClick(_vm.objectiveObj)
                            },
                          },
                        },
                        [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                placement: "top",
                                "open-delay": 300,
                                content:
                                  _vm.objectiveObj.careState === 1
                                    ? "已关注OKR"
                                    : "关注OKR",
                              },
                            },
                            [
                              _c("Icon", {
                                staticClass: "icon",
                                attrs: {
                                  "icon-class":
                                    _vm.objectiveObj.careState === 1
                                      ? "icon-collected"
                                      : "icon-collect",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      (
                        _vm.userInfo.id === _vm.objectiveObj.createUserid
                          ? true
                          : false
                      )
                        ? _c(
                            "div",
                            { staticClass: "action-item close-icon-box" },
                            [
                              _c(
                                "more-menu",
                                {
                                  ref: "okrDetailMenu",
                                  attrs: {
                                    items: _vm.menuItems,
                                    placement: "bottom-start",
                                  },
                                  on: { menuClick: _vm.operateTarget },
                                },
                                [
                                  _c("Icon", {
                                    staticClass: "kr-right-icon",
                                    attrs: { "icon-class": "icon-more-row" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "action-item close-icon-box" },
                        [
                          _c("Icon", {
                            staticClass: "kr-right-icon icon-close",
                            attrs: { "icon-class": "icon-close" },
                            on: { click: _vm.handleClose },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "objective-detail-content" }, [
                  !_vm.objDeleteState
                    ? _c(
                        "div",
                        {
                          staticClass: "obj-content",
                          on: { scroll: _vm.objContentScroll },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "top-content",
                              class:
                                _vm.objectiveObj.automationState === 1
                                  ? "progress-auto"
                                  : "",
                            },
                            [
                              _c("top-members", {
                                ref: "topMember",
                                staticClass: "top-member",
                                attrs: {
                                  "top-members-beans": _vm.objectiveObj,
                                  "top-members-state": "1",
                                  "details-id": _vm.objectiveObj.id,
                                },
                                on: {
                                  backData: _vm.backData,
                                  delteAlignObj: function ($event) {
                                    return _vm.queryObjectiveDetail(
                                      _vm.objectiveObj.id,
                                      "",
                                      "refresh"
                                    )
                                  },
                                  updateObjTitle: function ($event) {
                                    return _vm.queryObjectiveDetail(
                                      _vm.objectiveObj.id
                                    )
                                  },
                                  showShareList: _vm.showShareList,
                                  mapRefresh: function ($event) {
                                    return _vm.mapRefreshState(true, $event)
                                  },
                                },
                              }),
                              _c(
                                "top-progress",
                                {
                                  attrs: {
                                    "top-members-beans": _vm.objectiveObj,
                                    "top-members-state": "3",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "top-progress-auto" },
                                    [
                                      _vm.objectiveObj.automationState === 1
                                        ? _c(
                                            "autoProgressPopver",
                                            {
                                              attrs: {
                                                "okr-data": _vm.objectiveObj,
                                                disabled:
                                                  _vm.objectiveObj
                                                    .automationState !== 1,
                                                type: 1,
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "top-progress-content blue",
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass: "icon",
                                                    attrs: {
                                                      src: require("../../../../assets/imgs/common/circle-progress.gif"),
                                                      alt: "",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm.userInfo.id ===
                                _vm.objectiveObj.createUserid &&
                              (_vm.objectiveObj.objectivesProcessDataDto &&
                                _vm.objectiveObj.objectivesProcessDataDto
                                  .businessType) !== 1
                                ? _c(
                                    "div",
                                    { staticClass: "add-keyresult" },
                                    [
                                      _c(
                                        "transition",
                                        {
                                          attrs: {
                                            name: "bottom-slide",
                                            mode: "out-in",
                                          },
                                        },
                                        [
                                          _vm.leftAddkeyState
                                            ? _c("key-results", {
                                                attrs: {
                                                  "top-members-bean":
                                                    _vm.objectiveObj,
                                                },
                                                on: {
                                                  getAddkeyList:
                                                    _vm.getAddkeyList,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "transition",
                                        {
                                          attrs: {
                                            name: "bottom-slide",
                                            mode: "out-in",
                                          },
                                        },
                                        [
                                          !_vm.leftAddkeyState
                                            ? _c("key-results-Add", {
                                                attrs: {
                                                  "is-share":
                                                    _vm.objectiveObj.isShare,
                                                  "obj-id": _vm.objectiveObj.id,
                                                  "okr-data-obj":
                                                    _vm.objectiveObj,
                                                },
                                                on: {
                                                  getkeyResultsAddRefresh:
                                                    _vm.getkeyResultsAddRefresh,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "okr-kr-list" },
                            [
                              _c(
                                "draggable",
                                {
                                  attrs: {
                                    handle: ".key_result_dragger_icon_box",
                                    disabled: !(
                                      _vm.userInfo.id ===
                                        _vm.objectiveObj.createUserid &&
                                      _vm.draggableStatus
                                    ),
                                  },
                                  on: { update: _vm.drop },
                                  model: {
                                    value: _vm.keyResultsList,
                                    callback: function ($$v) {
                                      _vm.keyResultsList = $$v
                                    },
                                    expression: "keyResultsList",
                                  },
                                },
                                _vm._l(
                                  _vm.keyResultsList,
                                  function (item, index) {
                                    return _c("div", { key: item.id }, [
                                      _c(
                                        "div",
                                        { staticClass: "okr-kr-item" },
                                        [
                                          _c("key-results-list", {
                                            attrs: {
                                              "key-results-li": item,
                                              "target-object": _vm.objectiveObj,
                                              "key-results-li-number": index,
                                              "show-border": true,
                                              "align-member": true,
                                            },
                                            on: {
                                              draggableSetStatus:
                                                _vm.draggableSetStatus,
                                              showTaskByKey: function ($event) {
                                                return _vm.clickSelectedKr(
                                                  item.id
                                                )
                                              },
                                              getTargetDetailsKeywordDetele:
                                                _vm.getTargetDetailsKeywordDetele,
                                              getKeyResultsListTitles:
                                                function ($event) {
                                                  return _vm.getKeyResultsListTitles(
                                                    index
                                                  )
                                                },
                                              changeObjectiveTitle:
                                                _vm.changeObjectiveTitle,
                                              getKeyResultsListWeights:
                                                function ($event) {
                                                  return _vm.getKeyResultsListWeights(
                                                    index
                                                  )
                                                },
                                              getKeyHandleChange:
                                                _vm.getKeyHandleChange,
                                              getKeyHandleChangeBlur:
                                                _vm.getKeyHandleChangeBlur,
                                              showProcessList:
                                                _vm.showProcessList,
                                              setAutoProcess: function (
                                                $event
                                              ) {
                                                return _vm.keyResultsAutoClick(
                                                  item
                                                )
                                              },
                                              showKeyResultScore: function (
                                                $event
                                              ) {
                                                return _vm.showKeyResultScore(
                                                  index
                                                )
                                              },
                                              getKeyScoreChangeBlur:
                                                _vm.getKeyScoreChangeBlur,
                                              getKeyScoreChange:
                                                _vm.getKeyScoreChange,
                                              okrDetail:
                                                _vm.getTopMembersAlignmentSelect,
                                              toOKRList:
                                                _vm.getTopMembersFatherList,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _c(
                        "div",
                        { staticClass: "obj-content" },
                        [_c("noData")],
                        1
                      ),
                  _c("div", { staticClass: "obj-tabs" }, [
                    _c(
                      "div",
                      [
                        _c("detail-tabs", {
                          attrs: {
                            "show-comment-redpoint": _vm.showCommentRedpoint,
                            "comment-user-avatar":
                              _vm.objectiveObj.commentUserAvatar,
                          },
                          on: { change: _vm.getTargetSelect },
                          model: {
                            value: _vm.selectTabValue,
                            callback: function ($$v) {
                              _vm.selectTabValue = $$v
                            },
                            expression: "selectTabValue",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.selectTabValue === 1
                      ? _c(
                          "div",
                          { staticClass: "tab-item" },
                          [
                            _c("div", { staticClass: "top-select-options" }, [
                              _c(
                                "div",
                                { staticClass: "blod-select" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "select_Kr_Option",
                                      staticStyle: { width: "116px" },
                                      on: {
                                        change: function ($event) {
                                          return _vm.getTargetTaskList()
                                        },
                                        "visible-change": _vm.closePop,
                                      },
                                      model: {
                                        value: _vm.selectKrOptionValue,
                                        callback: function ($$v) {
                                          _vm.selectKrOptionValue = $$v
                                        },
                                        expression: "selectKrOptionValue",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "全部任务", value: "" },
                                      }),
                                      _vm._l(
                                        _vm.objectiveObj.keyresultsList,
                                        function (val, key) {
                                          return _c("el-option", {
                                            key: val.id,
                                            attrs: {
                                              label: "KR" + (key + 1) + " 任务",
                                              value: val.id,
                                            },
                                          })
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "normal-select" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "140px" },
                                      on: {
                                        change: function ($event) {
                                          return _vm.getTargetTaskList()
                                        },
                                        "visible-change": _vm.closePop,
                                      },
                                      model: {
                                        value: _vm.createTimeOptionValue,
                                        callback: function ($$v) {
                                          _vm.createTimeOptionValue = $$v
                                        },
                                        expression: "createTimeOptionValue",
                                      },
                                    },
                                    _vm._l(
                                      _vm.createTimeOption,
                                      function (val, key) {
                                        return _c("el-option", {
                                          key: key,
                                          attrs: {
                                            label: val,
                                            value: parseInt(key),
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                  _vm.userInfo.id ===
                                  _vm.objectiveObj.createUserid
                                    ? _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100px" },
                                          on: {
                                            change: function ($event) {
                                              return _vm.getTargetTaskList()
                                            },
                                            "visible-change": _vm.closePop,
                                          },
                                          model: {
                                            value: _vm.roleTypeOptionValue,
                                            callback: function ($$v) {
                                              _vm.roleTypeOptionValue = $$v
                                            },
                                            expression: "roleTypeOptionValue",
                                          },
                                        },
                                        _vm._l(
                                          _vm.roleTypeOption,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                            (_vm.objectiveObj.objectivesProcessDataDto &&
                              _vm.objectiveObj.objectivesProcessDataDto
                                .businessType) !== 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "task-add-box",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                      },
                                    },
                                  },
                                  [
                                    _vm.userInfo.id ===
                                      _vm.objectiveObj.createUserid ||
                                    _vm.isSharer
                                      ? _c("add-target-task", {
                                          ref: "addTask",
                                          attrs: {
                                            "select-kr-array-value":
                                              _vm.selectKrOptionValue,
                                            "kr-option": _vm.selectKrArray,
                                          },
                                          on: {
                                            addTargetTask:
                                              _vm.addTargetTaskRequest,
                                            addTargetView:
                                              _vm.addTargetViewStateChange,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.selectKrOptionValue &&
                                    !_vm.addTargetViewState
                                      ? _c("attach-project", {
                                          staticClass: "bottom-attach",
                                          attrs: {
                                            "is-edit":
                                              _vm.userInfo.id ===
                                              _vm.objectiveObj.createUserid,
                                            "key-id": _vm.selectKrOptionValue,
                                            "obj-id": _vm.objectiveObj.id,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "transition",
                              { attrs: { name: "fade", mode: "out-in" } },
                              [
                                !_vm.taskArrayState &&
                                _vm.taskArray.length === 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "no-data-show-wrap no-data-show-wrap1",
                                      },
                                      [
                                        _vm.userInfo.id ===
                                        _vm.objectiveObj.createUserid
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "no-data-show-wrap",
                                              },
                                              [
                                                _c("Img", {
                                                  staticClass:
                                                    "no-data-show-icon",
                                                  attrs: {
                                                    src: "/todo/todoNull.png",
                                                  },
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "no-data-show-text",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "马上添加工作任务推进OKR的达成吧，你也可以关联项目哦!"
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _c("no-data"),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            [
                              _c(
                                "transition-group",
                                {
                                  directives: [
                                    {
                                      name: "scroll-bottom",
                                      rawName: "v-scroll-bottom",
                                      value: _vm.loadMore,
                                      expression: "loadMore",
                                    },
                                  ],
                                  ref: "task",
                                  staticClass: "draggable-box task_content",
                                  attrs: {
                                    id: "transitionGroup",
                                    name: "fade",
                                    tag: "div",
                                    "data-scroll-type": "task",
                                  },
                                },
                                _vm._l(_vm.taskArray, function (items, index) {
                                  return _c("task-item", {
                                    key: items.id,
                                    staticClass: "draggable-item",
                                    attrs: {
                                      row: items,
                                      "data-id": items.id,
                                      "priority-visible": true,
                                      "len-arr-length": _vm.taskArray.length,
                                      "index-num": index,
                                    },
                                    on: {
                                      deleteCard: function ($event) {
                                        return _vm.queryObjectiveDetail(
                                          _vm.objectiveObj.id
                                        )
                                      },
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                          ],
                          2
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.selectTabValue === 2,
                            expression: "selectTabValue === 2",
                          },
                          {
                            name: "vueloading",
                            rawName: "v-vueloading",
                            value: _vm.trendArrayState,
                            expression: "trendArrayState",
                          },
                        ],
                        staticClass: "tab-item",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "top-select-options" },
                          [
                            _c(
                              "el-select",
                              {
                                key: "trend",
                                staticClass:
                                  "select-bar select-dynamic blod-select",
                                on: {
                                  change: function ($event) {
                                    return _vm.getTrendList()
                                  },
                                  "visible-change": _vm.closePop,
                                },
                                model: {
                                  value: _vm.trendOptionValue,
                                  callback: function ($$v) {
                                    _vm.trendOptionValue = $$v
                                  },
                                  expression: "trendOptionValue",
                                },
                              },
                              _vm._l(_vm.trendOption, function (val, key) {
                                return _c("el-option", {
                                  key: key,
                                  attrs: {
                                    label: val,
                                    value: parseInt(key),
                                    disabled:
                                      key == 0 && _vm.trendOptionPermission(),
                                  },
                                })
                              }),
                              1
                            ),
                            _vm.trendTotal > 10
                              ? _c("div", [
                                  _vm.trendTotal - _vm.trendArray.length != 0 &&
                                  !_vm.oldVisible
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "dynamic_menu",
                                          on: { click: _vm.showOld },
                                        },
                                        [
                                          _c("Icon", {
                                            staticClass: "trend_change_icon",
                                            attrs: {
                                              "icon-class": "icon-more-row",
                                            },
                                          }),
                                          [
                                            _vm._v(
                                              " 显示较早 " +
                                                _vm._s(
                                                  _vm.trendTotal -
                                                    _vm.trendArray.length
                                                ) +
                                                " 条 "
                                            ),
                                          ],
                                        ],
                                        2
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass: "dynamic_menu",
                                          on: { click: _vm.showOld },
                                        },
                                        [
                                          _c("Icon", {
                                            staticClass: "trend_change_icon",
                                            attrs: {
                                              "icon-class": "icon-more-row",
                                            },
                                          }),
                                          [_vm._v("隐藏较早的动态")],
                                        ],
                                        2
                                      ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "transition",
                          { attrs: { name: "fade", mode: "out-in" } },
                          [
                            !_vm.trendArrayState && _vm.trendArray.length === 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "no-data-show-wrap no-data-show-wrap2",
                                  },
                                  [_c("no-data")],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                        [
                          _c(
                            "transition-group",
                            {
                              directives: [
                                {
                                  name: "scroll-bottom",
                                  rawName: "v-scroll-bottom",
                                  value: _vm.loadMoreTrendArray,
                                  expression: "loadMoreTrendArray",
                                },
                              ],
                              ref: "trendItem",
                              staticClass: "draggable-box trend_box",
                              attrs: {
                                id: "transitionGroupTrend",
                                name: "fade",
                                tag: "div",
                                "data-scroll-type": "trendItem",
                              },
                            },
                            _vm._l(_vm.trendArray, function (item) {
                              return _c("trend-item", {
                                key: item.id,
                                staticClass: "trend_item",
                                attrs: {
                                  "trend-info": item,
                                  "obj-id": _vm.objId,
                                },
                                on: { refreshTrend: _vm.refreshTrend },
                              })
                            }),
                            1
                          ),
                        ],
                        _vm.selectTabValue === 2
                          ? _c(
                              "div",
                              {
                                staticClass: "comment_box",
                                class: _vm.blurComment ? "blur-box" : "",
                              },
                              [
                                _c("comment-box", {
                                  ref: "trendCommentBox",
                                  attrs: {
                                    placeholder: "填写对目标的评论  ↵",
                                    "blur-release": false,
                                    "line-feed": true,
                                  },
                                  on: {
                                    release: _vm.addTrendComment,
                                    focus: function ($event) {
                                      return _vm.focusComment(true)
                                    },
                                    blur: function ($event) {
                                      return _vm.focusComment(false)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                    _vm.selectTabValue === 3
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "vueloading",
                                rawName: "v-vueloading",
                                value: _vm.processArrayState,
                                expression: "processArrayState",
                              },
                            ],
                            staticClass: "tab-item",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "top-select-options" },
                              [
                                _c(
                                  "el-select",
                                  {
                                    key: "process",
                                    staticClass: "select-bar blod-select",
                                    on: {
                                      change: function ($event) {
                                        return _vm.getProcessList()
                                      },
                                      "visible-change": _vm.closePop,
                                    },
                                    model: {
                                      value: _vm.krOptionValue,
                                      callback: function ($$v) {
                                        _vm.krOptionValue = $$v
                                      },
                                      expression: "krOptionValue",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "全部进度", value: "" },
                                    }),
                                    _vm._l(_vm.selectKrArray, function (val) {
                                      return _c("el-option", {
                                        key: val.id,
                                        attrs: {
                                          label: val.name,
                                          value: val.id,
                                        },
                                      })
                                    }),
                                  ],
                                  2
                                ),
                                (_vm.userInfo.id ===
                                  _vm.objectiveObj.createUserid ||
                                  _vm.isSharer) &&
                                _vm.krOptionValue !== ""
                                  ? _c(
                                      "div",
                                      { staticClass: "top-select-right" },
                                      [
                                        _c(
                                          "key-results-alert",
                                          {
                                            attrs: {
                                              "obj-data": _vm.selectKRInfo,
                                              type: "okr",
                                              "okr-type": "map",
                                            },
                                            on: {
                                              getKeyResultsAlertClose:
                                                _vm.updateKrProcess,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "okr-process-add",
                                              },
                                              [_vm._v("+ 手动更新进度")]
                                            ),
                                          ]
                                        ),
                                        _vm.selectKRInfo.quantificationType !==
                                          1 &&
                                        _vm.userInfo.id ===
                                          _vm.objectiveObj.createUserid
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "okr-process-add okr-process-auto",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.keyResultsAutoClick(
                                                      _vm.selectKRInfo
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("Icon", {
                                                  staticClass: "icon-auto",
                                                  attrs: {
                                                    "icon-class":
                                                      "icon-zhinenggengxinjindu",
                                                  },
                                                }),
                                                _vm._v("智能更新进度 "),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "transition",
                              { attrs: { name: "fade", mode: "out-in" } },
                              [
                                !_vm.processArrayState &&
                                _vm.processArray.length === 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "no-data-show-wrap no-data-show-wrap3",
                                      },
                                      [_c("no-data")],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm.processArray.length > 0
                              ? _c(
                                  "div",
                                  { staticClass: "process_box" },
                                  [
                                    _c(
                                      "transition-group",
                                      { attrs: { name: "fade" } },
                                      _vm._l(_vm.processArray, function (item) {
                                        return _c("process-item", {
                                          key: item.id,
                                          staticClass: "trend_item",
                                          attrs: { "process-info": item },
                                          on: {
                                            refreshProcess: _vm.getProcessList,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.selectTabValue === 4
                      ? _c(
                          "div",
                          { staticClass: "filelist_box" },
                          [
                            _c("FileList", {
                              attrs: {
                                "obj-id": _vm.objId,
                                "cycle-info-id": _vm.cycleInfoId,
                                "is-dialog": false,
                                "is-sharer": _vm.isSharer,
                                disabled:
                                  _vm.userInfo.id ===
                                  _vm.objectiveObj.createUserid
                                    ? false
                                    : true,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
      _c("task-detail", {
        attrs: {
          type: 2,
          "task-id": _vm.taskId,
          "notice-type": "okrDetail",
          "emit-state": true,
        },
        on: { todoSubTask: _vm.emitTaskTodoSubTask },
        model: {
          value: _vm.detailVisible,
          callback: function ($$v) {
            _vm.detailVisible = $$v
          },
          expression: "detailVisible",
        },
      }),
      _c("sub-task-detail", {
        attrs: {
          "append-to-body": true,
          type: 2,
          "task-id": _vm.subTaskId,
          "sub-object-father-id": _vm.subObjectFatherId,
          "notice-type": "okrDetail",
        },
        model: {
          value: _vm.subdetailVisible,
          callback: function ($$v) {
            _vm.subdetailVisible = $$v
          },
          expression: "subdetailVisible",
        },
      }),
      _vm.copyObjectiveVisible
        ? _c("copy-objective", {
            attrs: { data: _vm.objectiveObj },
            on: {
              startLoading: function ($event) {
                return _vm.startLoading($event, "目标复制")
              },
            },
            model: {
              value: _vm.copyObjectiveVisible,
              callback: function ($$v) {
                _vm.copyObjectiveVisible = $$v
              },
              expression: "copyObjectiveVisible",
            },
          })
        : _vm._e(),
      _vm.moveObjectiveVisible
        ? _c("move-objective", {
            attrs: { data: _vm.objectiveObj },
            on: {
              startLoading: function ($event) {
                return _vm.startLoading($event, "目标移动")
              },
            },
            model: {
              value: _vm.moveObjectiveVisible,
              callback: function ($$v) {
                _vm.moveObjectiveVisible = $$v
              },
              expression: "moveObjectiveVisible",
            },
          })
        : _vm._e(),
      _vm.makeOverObjectiveVisible
        ? _c("make-over-objective", {
            attrs: { data: _vm.objectiveObj },
            on: {
              refreshData: _vm.closeTip,
              startLoading: function ($event) {
                return _vm.startLoading($event, "转让目标")
              },
            },
            model: {
              value: _vm.makeOverObjectiveVisible,
              callback: function ($$v) {
                _vm.makeOverObjectiveVisible = $$v
              },
              expression: "makeOverObjectiveVisible",
            },
          })
        : _vm._e(),
      _vm.copyMoveloading
        ? _c("process-dialog", {
            attrs: {
              "dialog-txt": _vm.loadingTxt,
              "loading-param": _vm.loadingParam,
            },
            on: { toFinishPage: _vm.toFinishPage, close: _vm.closeTip },
            model: {
              value: _vm.copyMoveloading,
              callback: function ($$v) {
                _vm.copyMoveloading = $$v
              },
              expression: "copyMoveloading",
            },
          })
        : _vm._e(),
      _vm.closePermissionsStatus
        ? _c("obj-permission", {
            attrs: { "okr-id": _vm.objectiveObj.id },
            on: {
              refresh: function ($event) {
                return _vm.queryObjectiveDetail(_vm.objectiveObj.id)
              },
            },
            model: {
              value: _vm.closePermissionsStatus,
              callback: function ($$v) {
                _vm.closePermissionsStatus = $$v
              },
              expression: "closePermissionsStatus",
            },
          })
        : _vm._e(),
      _vm.keyResultsAutoProcessVisible
        ? _c("keyResultsAutoProcess", {
            attrs: { "okr-data": _vm.objectiveObj, item: _vm.autoProcessKey },
            on: {
              refresh: function ($event) {
                return _vm.queryObjectiveDetail(_vm.objectiveObj.id)
              },
            },
            model: {
              value: _vm.keyResultsAutoProcessVisible,
              callback: function ($$v) {
                _vm.keyResultsAutoProcessVisible = $$v
              },
              expression: "keyResultsAutoProcessVisible",
            },
          })
        : _vm._e(),
      _c("OkrShare", {
        attrs: {
          "obj-id": _vm.objectiveObj.id,
          disabled:
            _vm.userInfo.id === _vm.objectiveObj.createUserid ? false : true,
        },
        on: {
          refresh: function ($event) {
            return _vm.queryObjectiveDetail(_vm.objectiveObj.id)
          },
        },
        model: {
          value: _vm.isShowShare,
          callback: function ($$v) {
            _vm.isShowShare = $$v
          },
          expression: "isShowShare",
        },
      }),
      _vm.kpiIndexVisible
        ? _c("kpiIndex", {
            attrs: { okrId: _vm.objectiveObj.id },
            on: {
              refresh: function ($event) {
                return _vm.queryObjectiveDetail(_vm.objectiveObj.id)
              },
            },
            model: {
              value: _vm.kpiIndexVisible,
              callback: function ($$v) {
                _vm.kpiIndexVisible = $$v
              },
              expression: "kpiIndexVisible",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }