<template>
  <div
    v-if="((objDetails && (objDetails.processState || 1) === 1 && ($store.state.user.approvalCheckData.onOff === 0 || ($store.state.user.approvalCheckData.onOff === 1 && businessType !== 0))) || objDetails.submitterType)"
  >
    <div
      v-if="businessType || businessType === 0"
      :class="`type-wrap ${disabled}`"
      :style="{
      background: stateList[businessType].backgroundColor,
      color: stateList[businessType].fontColor,
      borderColor: stateList[businessType].borderColor,
    }"
      @click.stop="approveDetail"
    >{{stateList[businessType].name}}</div>
    <aproveDetail
      v-if="approveDetailVisible"
      v-model="approveDetailVisible"
      :detailsId="objDetails.id"
    />
  </div>
</template>
<script>
import aproveDetail from '@/views/okr/dialog/approveDetail'
export default {
  components: {
    aproveDetail
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    objDetails: {
    },
    businessType: {
      default: 0
    }
  },
  data() {
    return {
      approveDetailVisible: false,
      stateList: {
        0: {
          name: '待提交审批',
          fontColor: '#3A70FF',
          borderColor: 'rgba(58,112,255,0.5)',
          backgroundColor: 'rgba(58,112,255,0.08)',
        },
        1:{
          name: '审批中',
          fontColor: '#FF9900',
          borderColor: 'rgba(255,153,0,0.5)',
          backgroundColor: 'rgba(255,153,0,0.08)',
        },
        3:{
          name: '通过审批',
          fontColor: '#00BA88',
          borderColor: 'rgba(0,186,136,0.5)',
          backgroundColor: 'rgba(0,186,136,0.08)',
        },
        2:{
          name: '未通过审批',
          fontColor: '#FF572B',
          borderColor: 'rgba(255,87,43,0.5)',
          backgroundColor: 'rgba(255,87,43,0.08)',
        },
        4:{
          name: '已撤回',
          fontColor: '#A0A3BD',
          borderColor: 'rgba(160,163,189,0.5)',
          backgroundColor: 'rgba(160,163,189,0.08)',
        }
      }
    }
  },
  computed: {},
  created() {
  },
  methods: {
    approveDetail() {
      if (this.disabled === false) {
        this.approveDetailVisible = true
      }
    }
  }
}
</script>
<style lang="less" scoped>
.type-wrap {
  font-size: 12px;
  height: 22px;
  line-height: 20px;
  border-radius: 11px;
  padding: 0px 12px;
  border-width: 1px;
  border-style: solid;
  text-align: center;
}
.type-wrap.false {
  cursor: pointer;
}
</style>
