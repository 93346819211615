<template>
  <div class="stage-set">
    <div class="stage-name">{{ stageDate.name }}</div>
    <div class="stage-set-form">
      <div class="stage-steps">
        <steps />
      </div>
      <div class="stage-items">
        <div class="stage-item">
          <div class="item-name">填写阶段</div>
          <div class="item-form">
            截止于周期开始后<el-input-number
              v-model="form.startDay"
              :precision="0"
              :controls="false"
              :min="1"
              class="form-input"
              @change="changeFormData"
            /><el-select
              v-model="form.startType"
              class="form-select"
              @change="changeFormData"
            >
              <el-option
                v-for="option in monthDateType"
                :key="option.val"
                :value="option.val"
                :label="option.label"
              />
            </el-select>
          </div>
          <div class="item-tip">建议不少于3天，不超过1个月</div>
        </div>
        <div class="stage-item push-stage">
          <div class="item-name">推进阶段</div>
          <div class="item-form">
            周期开始后<span class="form-num"> {{ form.startDay }}</span>
            {{ dateTypeObj[form.startType] }}至周期结束前第<span
              class="form-num"
              >{{ form.endDay }}</span
            >{{ dateTypeObj[form.endType] }}
          </div>
        </div>
        <div class="stage-item review-stage">
          <div class="item-name">复盘阶段</div>
          <div class="item-form">
            开始于周期结束前<el-input-number
              v-model="form.endDay"
              :precision="0"
              :controls="false"
              :min="1"
              class="form-input"
              @change="changeFormData"
            /><el-select
              v-model="form.endType"
              class="form-select"
              @change="changeFormData"
            >
              <el-option
                v-for="option in monthDateType"
                :key="option.val"
                :value="option.val"
                :label="option.label"
              />
            </el-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import steps from './step.vue'
export default {
  components: { steps },
  props: {
    stageDate: {
      type: Object,
      default: () => {
        return {}
      }
    },
    dateTypes: {
      type: Array,
      default: () => {
        return []
      }
    },
    cycleInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    type: {
      type: String,
      default: 'month'
    }
  },
  data() {
    return {
      dateType: [
        { val: 0, label: '天' },
        { val: 1, label: '周' },
        { val: 2, label: '月' }
      ],
      monthDateType: [
        { val: 0, label: '天' },
        { val: 1, label: '周' }
      ],
      dateTypeObj: {
        0: '天',
        1: '周',
        2: '月'
      },
      form: {
        startDay: 1,
        startType: 1,
        endDay: 1,
        endType: 1,
        type: 'month'
      }
    }
  },
  created() {
    if (this.cycleInfo.interval !== 1 || this.type === 'year') {
      this.monthDateType = this.dateType
    }
    this.form = {
      startDay: this.stageDate.writeStage.stageDataEndNum,
      startType: this.stageDate.writeStage.stageDataEndNumType,
      endDay: this.stageDate.reviewStage.stageDataStartNum,
      endType: this.stageDate.reviewStage.stageDataStartNumType,
      type: this.type
    }
    this.changeFormData()
  },
  methods: {
    changeFormData() {
      this.$emit('changeData', this.form)
    }
  }
}
</script>
<style lang="scss" scoped>
.stage-set {
  .stage-name {
    font-size: 16px;
    font-weight: 500;
    color: #14142b;
  }
  .stage-set-form {
    margin-bottom: 24px;
    &:last-of-type {
      margin-bottom: 40px;
    }
    display: flex;
    .stage-steps {
      height: 234px;
      margin-right: 24px;
      margin-top: -8px;
    }
    .stage-items {
      margin-top: 24px;
      .stage-item {
        font-size: 14px;
        .item-name {
          font-weight: 500;
          color: #14142b;
        }
        .item-form {
          color: #6e7491;
          display: flex;
          align-items: center;
          .item-num {
            color: #14142b;
          }
          .form-input {
            width: 58px;
            margin: 0 12px 0 16px;
          }
          .form-select {
            width: 100px;
          }
        }
        .item-tip {
          font-size: 12px;
          color: #a0a3bd;
          margin-left: 100px;
          margin-top: 4px;
        }
      }
      .push-stage {
        margin-top: 4px;
        .item-form {
          margin-top: 12px;
        }
      }
      .review-stage {
        margin-top: 36px;
      }
    }
  }
}
</style>
