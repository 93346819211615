<template>
  <div class="message-box-wrap">
    <el-dialog
      :visible.sync="messageNotificationShowz"
      :append-to-body="true"
      :destroy-on-close="true"
      class="message-box-drawer"
      v-on="$listeners"
      @close="getMessageNotificationClose"
    >
      <div class="message-top" @click.stop>
        <span
          v-if="informNumber > 0"
          class="infor-num"
        >{{ informNumber > 99 ? '99+' : informNumber }}</span>
        <div class="message-top-left">
          <v-tabs background-color="none" height="58" slider-color="#005EFF">
            <v-tab @click="tabClick('')">全部</v-tab>
            <v-tab @click="tabClick(0)">未读</v-tab>
            <v-tab @click="tabClick(1)">已读</v-tab>
          </v-tabs>
        </div>
        <div class="message-top-right" @click.stop>
          <div @click="readAll">
            <Icon class="icon" icon-class="icon-quanduyidu" />标记为全部已读
          </div>
          <div @click="personSet">
            <Icon class="icon" icon-class="icon-jiaruqiyehoutai" />
          </div>
          <div @click="getMessageNotificationClose">
            <Icon class="icon-close icon" icon-class="icon-close" />
          </div>
        </div>
      </div>
      <div v-vueloading="loading" class="notice-list-content">
        <transition name="fade">
          <div
            v-if="dataList.length > 0"
            v-scroll-bottom="$loadMoreList"
            class="message-list-wrap"
            @click.stop
          >
            <transition-group name="fade">
              <div
                v-for="item in dataList"
                :key="item.id"
                class="message-list"
                @click.stop="getMessageDetails(item)"
              >
                <div v-if="item.status === 0" class="noRead"></div>
                <div class="message-list-top">
                  <div class="detail-wrap">
                    <img
                      class="border-radius-20"
                      :src="item.avatar || require('@/assets/imgs/common/avatar.png')"
                    />
                    <div>
                      <at-content :type="'dynamic'" :html="item.content" />
                    </div>
                  </div>
                  <div class="time">{{ $utils.globalTime(item.createDate) }}</div>
                </div>
                <div class="message-list-content">
                  <div class="img">
                    <Icon v-if="item.busicType === 0" icon-class="icon-mubiao" class="icon" />
                    <Icon v-else-if="item.busicType === 1" icon-class="icon-zhouqi" class="icon" />
                    <Icon v-else-if="item.busicType === 2" icon-class="icon-renwu" class="icon" />
                    <Icon
                      v-else-if="item.busicType === 3"
                      icon-class="icon-a-Group725"
                      class="icon"
                    />
                    <Icon v-else-if="item.busicType === 4" icon-class="icon-xiangmu" class="icon" />
                    <Icon v-else-if="item.busicType === 5" icon-class="icon-jixiao" class="icon" />
                    <Icon
                      v-else-if="item.busicType === 6"
                      icon-class="icon-taskcalendars"
                      class="icon"
                    />
                    <Icon v-else-if="item.busicType === 7" icon-class="icon-huiyi" class="icon" />
                    <Icon v-else icon-class="icon-mainpage_tab_okr" class="icon" />
                  </div>
                  <AtContent :type="'dynamic'" :html="item.title"></AtContent>
                </div>
              </div>
            </transition-group>
          </div>
        </transition>
      </div>
      <div v-if="dataList.length == 0 && dataListState" class="contentNull">
        <!-- <img src="../../../assets/img/target_score_icon2.png" alt /> -->
        <Img src="/common/commentNull.png" />
        <p>暂无数据</p>
      </div>
    </el-dialog>
    <!-- 任务详情 -->
    <task-detail
      v-if="
        taskdetailVisible && messageNotificationShowz && parseInt(notifyId) > 0
      "
      v-model="taskdetailVisible"
      :task-id="notifyId"
      :form-page="formPage"
      :view-type="formPage"
    />
    <!-- 子任务详情 -->
    <sub-task-detail
      v-if="subdetailNoticeVisible && messageNotificationShowz"
      v-model="subdetailNoticeVisible"
      :task-id="subdetailVisibleId"
      :sub-object-father-id="subObjectFatherId"
    />
    <!-- 目标详情 -->
    <okr-detail
      v-if="homeDetailsState"
      v-model="homeDetailsState"
      :obj-id="detailsId"
      @refresh="getHomeDetailsClose"
    />
    <!-- 添加目标 -->
    <add-target
      v-if="targetAddShow"
      v-model="targetAddShow"
      :target-details-align="targetDetailsAlign"
      @gettargetAddClose="gettargetAddClose"
      @getTargetAddSubmit="getTargetAddSubmit"
    />
    <aproveDetail
      v-if="approveDetailVisible"
      v-model="approveDetailVisible"
      :detailsId="aproveDetailId"
    />
  </div>
</template>
<script>
/* eslint-disable */
import okrDetail from '@/views/okr/dialog/okrDetail/index' //目标详情
import aproveDetail from '@/views/okr/dialog/approveDetail'
export default {
  model: {
    prop: 'messageNotificationShow',
    event: 'change'
  },
  data() {
    return {
      targetAddShow: false, //添加目标显示
      targetDetailsAlign: {}, //目标详情对齐
      formPage: 'project',
      subdetailVisibleId: '',
      notifyId: '',
      homeDetailsState: false,
      taskdetailVisible: false,
      notifyId: '',
      total: 0,
      status: '',
      pageNum: 1,
      pageSize: 10,
      dataList: [],
      dataListState: false,
      subdetailNoticeVisible: false,
      informNumber: 0,
      loadMoreState: true,
      messageNotificationShowz: this.messageNotificationShow,
      approveDetailVisible: false,
      aproveDetailId: ''
    }
  },
  computed: {},
  components: {
    okrDetail,
    aproveDetail
  },
  props: {
    messageNotificationShow: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    messageNotificationShow: function (newVal, oldVal) {
      this.messageNotificationShowz = newVal
    }
  },
  created() {
    this.getMessageNotificationShow()
    this.getCurrCycleInfoList()
  },
  mounted() {
    // 打开父任务详情
    this.bus(this).$on('detailVisible', (id) => {
      this.taskdetailVisible = true
      this.notifyId = id
    })
    // 监听由主任务详情到子任务详情
    this.bus(this).$on('global:todoSubTask', (item) => {
      this.TaskTodoSubTask(item)
    })
    // 监听由子任务详情到主任务详情
    this.bus(this).$on('global:taskMainDetail', (item) => {
      if (this.subdetailNoticeVisible) {
        if (this.subObjectFatherId === -1 || item === -1) {
          this.subdetailNoticeVisible = false
          this.taskdetailVisible = false
          this.tabClick(this.status)
        } else {
          this.TaskTodomainTask(item)
        }
      }
    })
    // 子任务关闭任务刷新
    this.bus(this).$on('global:subContenttaskRefresh', () => {
      this.subdetailNoticeVisible = false
    })
  },
  methods: {
    personSet() {
      this.$router.push('/setting/notify')
      this.getMessageNotificationClose()
    },
    // 所有已读
    readAll() {
      const obj_ = {}
      this.$apis.SETNOTICEREADALL(obj_).then((res) => {
        if (res.status == 200) {
          this.dataList = []
          this.pageNum = 1
          this.$emit('global:setNotifyMsgToReaded')
          this.loading = true
          this.getMessageList(1)
          this.getCurrCycleInfoList()
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    $loadMoreList() {
      if (
        parseInt(this.dataList.length) < parseInt(this.total) &&
        this.loadMoreState
      ) {
        this.loading = true
        this.getMessageList(this.pageNum + 1)
      }
    },
    tabClick(type) {
      this.dataList = []
      this.pageNum = 1
      this.status = type
      this.dataListState = false
      this.loading = true
      this.getMessageList(1)
    },
    // 获取消息显示状态
    getMessageNotificationShow() {
      this.getMessageNotificationClear()
      this.dataList = []
      this.pageNum = 1
      this.loading = true
      this.getMessageList(1)
    },
    // 获取消息关闭状态
    getMessageNotificationClose() {
      this.notifyId = ''
      this.subdetailVisibleId = ''
      this.messageNotificationShowz = false
      this.taskdetailVisible = false
      this.subdetailNoticeVisible = false
      this.$emit('change', false)
      this.$emit('global:setNotifyMsgToReaded')
      this.$emit('global:getTargetDetailsClose')
      this.$emit('getMessageNotificationClose')
    },
    // 获取消息列表接口
    getMessageList(pageNum) {
      const dataBeans = {
        status: this.status,
        pageNum,
        pageSize: this.pageSize
      }
      this.pageNum = pageNum
      this.$apis
        .GETNOTICELIST(dataBeans)
        .then((rest) => {
          this.loadMoreState = true
          this.total = rest.data.total
          const dataList = rest.data.rows
          for (let i = 0; i < dataList.length; i++) {
            dataList[i].title = this.$utils.changeToHtml(dataList[i].title)
            dataList[i].content = this.$utils.changeToHtml(dataList[i].content)
          }
          this.dataList = this.dataList.concat(dataList)
          this.dataListState = true
          if (this.status === 0) {
            this.informNumber = rest.data.total
          }
          this.loading = false
        })
        .catch((err) => {
          this.loadMoreState = true
          this.dataListState = true
          this.loading = false
        })
    },
    async getMessageDetails(item) {
      if (item.busicType === 0) {
        // 目标 okr
        if (item.actionType === 3 && item.noticeType === 0) {
          // 删除okr
          this.$message.warn('目标已被删除哦！')
        } else if (item.actionType === 0 && item.noticeType === 0 || item.target.targetType === 23) {
          this.getOkrDetails(item)
        } else if (item.target.targetType === 22) {
          this.getKeyOkrDetails(item)
        } else {
          this.detailsId = item.target.busicId
          this.homeDetailsState = true
        }
      }
      if (item.busicType === 1) {
        // 周期
        if (item.actionType === 16 && item.noticeType === 0) {
          // 删除周期成员
          this.$message.warn('您已被删除哦！')
        } else if (item.actionType === 17 && item.noticeType === 0) {
          // 删除周期
          this.$message.warn('周期已被删除哦！')
        } else {
          // 打开okr主页
          var path = '/okr'
          var quarterId = ''
          // this.$emit('global:getTargetDetailsPath',item.busicId)
          var cycleArrs = this.$store.state.user.cycleArrs
          cycleArrs.forEach((cycle, index) => {
            cycle.cycleInfoList.forEach((cycleInfo, indexs) => {
              if (item.busicId == cycleInfo.id) {
                quarterId = cycleInfo.cycleId
              }
            })
          })
          let loadingParam = {
            periodId: item.busicId,
            quarterId
          }
          this.$router.push({ path: '/okr', query: loadingParam })
          this.$emit('global:getCloseHomeDetails')
          this.getMessageNotificationClear()
          this.getMessageNotificationClose()
        }
      }
      if (item.busicType === 2) {
        // 任务
        if (
          (item.actionType === 7 || item.actionType === 9) &&
          item.noticeType === 0
        ) {
          // 删除任务 删除子任务
          this.$message.warn('任务已被删除哦！')
        } else {
          this.getTaskDetail(item.busicId)
          // this.getMessageNotificationClear()
          // this.getMessageNotificationClose()
        }
      }
      if (item.busicType === 3) {
        if(item.actionType === 157) { // 写总结 1365091329286410240
          this.$router.push({
            path: '/conclusion/add',
            query: {
              moudleId: item.target.busicId
            }
          })
        } else if(item.target.targetType === 25) { // 评论
          this.getConclusionTemId(item.target.busicId)
        } else {
          // 小结详情
          this.$router.push('/conclusion')
        }
        this.getMessageNotificationClear()
        this.getMessageNotificationClose()
        // this.$router.push({ path: '/conclusion',query:{type:1,item:{summaryid:item.id}}})
      }
      if (item.busicType === 4) {
        // 项目
        if (item.actionType === 27 && item.noticeType === 0) {
          // 删除人员
          this.$message.warn('您已被删除哦！')
        } else if (item.actionType === 28 && item.noticeType === 0) {
          // 删除项目
          this.$message.warn('项目已被删除哦！')
        } else {
          // 项目详情
          this.getProjectDescData(item.busicId)
        }
      }
      if (item.busicType === 5) {
        // 绩效
        if (item.actionType === 18 && item.noticeType === 0) {
          // 发起考核人员 我的考核
          this.$router.push('/kpi')
        } else if (item.actionType === 25 && item.noticeType === 0) {
          // 发起考核人员 绩效公示
          this.$router.push('/kpi/publicCheck')
        } else if (
          (item.actionType === 19 ||
            item.actionType === 20 ||
            item.actionType === 21) &&
          item.noticeType === 0
        ) {
          // 发起考核人员 绩效公示
          this.$router.push('/kpi/myHandle')
        } else {
          // 绩效详情
          this.$router.push('/kpi')
        }
        this.getMessageNotificationClear()
        this.getMessageNotificationClose()
      }
      if (item.busicType === 6) {
        // 日程
        if (
          (item.actionType === 32 ||
            item.actionType === 33 ||
            item.actionType === 36 ||
            item.actionType === 37) &&
          item.noticeType === 0
        ) {
          // 32，33邀请参加日程 36同意37拒绝
          this.$router.push({
            path: '/calendar',
            query: { id: item.target.busicId }
          })
        } else {
          // 日程
          this.$router.push('/calendar')
        }
        this.getMessageNotificationClear()
        this.getMessageNotificationClose()
      }
      if (item.busicType === 7) {
        // 会议
        //  && item.noticeType ===0
        if (item.target.targetType == 14) {
          this.$router.push({
            path: '/meeting/roundTableMeeting',
            query: { infoId: item.target.busicId }
          })
        } else if (item.target.targetType == 15) {
          this.$router.push({
            path: '/meeting/commonViewMeeting',
            query: { infoId: item.target.busicId }
          })
        } else if (item.target.targetType == 16) {
          this.$router.push({
            path: '/meeting/progressMeeting',
            query: { infoId: item.target.busicId }
          })
        } else if (item.target.targetType == 17) {
          this.$router.push({
            path: '/meeting/resumptionMeeting',
            query: { infoId: item.target.busicId }
          })
        } else if (item.target.targetType == 18) {
          this.$router.push('/meeting')
        } else {
          this.$router.push('/meeting')
        }
        this.getMessageNotificationClear()
        this.getMessageNotificationClose()
      }
      if (item.busicType === 9 || item.busicType === 8) {
        this.$router.push('/classroom')
        this.getMessageNotificationClear()
        this.getMessageNotificationClose()
      }
      if (item.busicType === 11) {
        if (item.target.targetType == 28) {
          this.getTaskDetail(item.target.busicId)
        } else {
          this.aproveDetailId = item.target.busicId
          this.approveDetailVisible = true
        }
      }
      if (item.busicType === 12) {
        if (item.target.targetType === 29 || item.target.targetType === 30) {
          var loadingParam = {
            performanceId: item.target.targetType === 29 ? item.target.busicId : '',
            performanceTodoId: item.target.targetType === 30 ? item.target.busicId : '',
            kpiType: item.target.targetType === 30 ? 0 : '-1'
          }
          this.$router.push({
            path: `/kpiV2/myWait/kpiDetail`,
            query: loadingParam
          })
          this.getMessageNotificationClear()
          this.getMessageNotificationClose()
        }
      }
      if (item.status === 0) {
        // 变为已读
        this.$apis
          .SETNOTICEREADBYID({ id: item.id })
          .then((rest) => {
            this.$emit('global:setNotifyMsgToReaded')
            this.dataList = []
            this.pageNum = 1
            this.loading = true
            this.getMessageList(1)
            this.getCurrCycleInfoList()
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    // 获取全部的目标通知
    getCurrCycleInfoList() {
      this.$apis
        .HEADERGETNOREADCOUNT()
        .then((rest) => {
          // console.log(rest)
          if (rest) {
            this.informNumber = rest.data
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 获取任务详情
    getTaskDetail(id) {
      this.$apis.TASKDETAILNEW({}, id).then((res) => {
        if (res.status === 200) {
          if (parseInt(res.data.fatherTid) > 0) {
            this.TaskTodoSubTask(
              {
                id,
                fatherTid: -1
              },
              1
            )
          } else {
            this.taskdetailVisible = true
            this.notifyId = id
          }
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 获取项目概述
    getProjectDescData(id) {
      let obj_ = {
        id
      }
      this.$apis.GETPRODESC(id, obj_).then((res) => {
        if (res.status === 200) {
          this.$store.commit('pm/tabSelectId', 0)
          this.$store.commit('pm/selectProjectId', id)
          var selectlist = {
            id,
            indexf: 0,
            indexS: 0,
            list: res.data
          }
          this.$store.commit('pm/selectProjectData', selectlist)
          this.$router.push('/pm')
          this.getMessageNotificationClear()
          this.getMessageNotificationClose()
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    getConclusionTemId(summarizeId){
      let obj_ = {
        summarizeId
      }
      this.$apis.CONCLUSIONTEMID(obj_).then((res) => {
        if (res.status === 200) {
          this.$router.push({
            path: '/conclusion',
            query: {
              summarizeTemplateId: res.data.id,
              summarizeId
            }
          })
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    getMessageNotificationClear() {
      this.targetDetailsStatez = false
    },
    getHomeDetailsClose(flag) {
      this.homeDetailsState = false
    },
    // 打开子任务抽屉
    TaskTodoSubTask(item, type) {
      this.subdetailVisibleId = item.id
      this.subObjectFatherId = item.fatherTid
      this.subdetailNoticeVisible = true
      setTimeout(function () {
        this.taskdetailVisible = false // 任务详情显示
        if (type === 1) {
          this.subObjectFatherId = -1
        }
      }, 200)
    },
    // 主任务
    TaskTodomainTask(item) {
      this.notifyId = item
      this.taskdetailVisible = true // 任务详情显示
      setTimeout(function () {
        this.subdetailNoticeVisible = false // 任务详情显示
      }, 200)
      this.$emit('global:taskDetailRefresh')
    },
    // 目标关闭回调
    gettargetAddClose() {
      this.targetDetailsAlign = {}
      this.targetAddShow = false
      //重新刷新header列表
      this.$emit('global:refreshHeaderList')

      // 如果在OKR模块，OKR列表刷新
      this.bus.$emit('noticeAddTarget')
    },
    // 添加目标内容提交刷新
    getTargetAddSubmit(data) {
      this.gettargetAddClose()
      this.getOkrDetails(this.messageNotificationData)
    },
    //邀请对齐目标详情
    getOkrDetails(item) {
      let dataBeans = {
        businessId: item.target.busicId,
        type: item.target.targetType === 23 ? 1 : 0
      }
      this.$apis
        .HEADEROBJALIGNINFO(dataBeans)
        .then((rest) => {
          if (rest.status == 200) {
            rest.data.time = this.$utils.globalTime(rest.data.createDate)
            this.targetDetailsIdz = item.busicId
            rest.data.content = item.content
            rest.data.objContent = this.$utils.changeToHtml(
              rest.data.objContent
            )
            this.InvitationBeans = rest.data
            this.targetDetailsAlign = this.InvitationBeans
            if (rest.data.isAlign === 1) {
              this.$message.warn('您已经添加对齐了哦！')
            } else {
              this.targetAddShow = true
              // this.getMessageNotificationClear()
              // this.getMessageNotificationClose()
            }
          } else {
            this.$message.warn(rest.message)
          }
        })
        .catch((err) => {
          this.$message.error(rest.message)
        })
    },
    //关键结果获取目标详情
    getKeyOkrDetails(item) {
      let dataBeans = {
        keyId: item.target.busicId
      }
      this.$apis
        .KEYOKRALIGNOBJDETAIL(item.target.busicId, dataBeans)
        .then((rest) => {
          if (rest.status == 200) {
            this.detailsId = rest.data.id
            this.homeDetailsState = true
          } else {
            this.$message.warn(rest.message)
          }
        })
        .catch((err) => {
          this.$message.error(rest.message)
        })
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../../assets/styles/common.less';
.contentNull {
  height: calc(100vh - 130px);
  max-height: 530px;
  .flex-c();
  flex-direction: column;
  img {
    width: 116px;
    margin-bottom: 16px;
  }
  p {
    color: #6e7491;
  }
}
.message-box-drawer {
  .message-top {
    .flex-bt();
    height: 56px;
    line-height: 56px;
    border-bottom: 1px solid #d9dbe9;
    width: 960px;
    position: relative;
    .message-top-left {
      padding-left: 20px;
      .v-tabs-slider {
        background-color: #005eff !important;
        border-radius: 4px;
      }
      ::v-deep {
        .v-tabs-slider-wrapper {
          bottom: 1px !important;
          .v-tabs-slider {
            border-radius: 5px !important;
          }
        }
      }
    }
    .infor-num {
      font-size: 14px;
      font-weight: 700;
      padding: 0px 6px;
      background: #ed2e7e;
      position: absolute;
      height: 20px;
      line-height: 16px;
      border-radius: 10px;
      color: #fff;
      left: 130px;
      top: 13px;
      border: 2px solid #fff;
      z-index: 9;
    }
    .message-top-right {
      .flex-r();
      div {
        .flex-l();
        font-size: 14px;
        color: #6e7491;
        cursor: pointer;
        .icon {
          margin-left: 22px;
          border-radius: 4px;
          width: 28px;
          height: 28px;
          font-size: 24px;
          justify-content: center;
          color: #444558;
          &:hover {
            background: #f5f6fa;
            color: @hover-color;
          }
        }
        .icon-close {
          margin-right: 22px;
          font-size: 18px;
        }
      }
      div:first-child {
        img {
          margin-right: 10px;
        }
      }
    }
  }
  .message-list-wrap {
    padding: 12px;
    height: calc(100vh - 130px);
    max-height: 530px;
    overflow-y: auto;
    width: 960px;
    .s-b-y();
    .message-list {
      cursor: pointer;
      padding: 14px;
      border-radius: 8px;
      border-bottom: 1px solid #f5f6fa;
      position: relative;
      .noRead {
        width: 6px;
        height: 6px;
        background: #ed2e7e;
        border-radius: 3px;
        position: absolute;
        right: 12px;
        top: 10px;
      }
      .message-list-top {
        .flex-bt();
        .detail-wrap {
          .flex-l();
          img {
            width: 28px;
            height: 28px;
            margin-right: 12px;
            border-radius: 14px;
          }
          div {
            font-size: 14px;
            font-weight: 500;
            color: #14142b;
            line-height: 24px;
            text-align: left;
            word-break: break-word;
          }
        }
        .time {
          font-size: 12px;
          color: #a0a3bd;
          min-width: 150px;
          text-align: right;
        }
      }
      .message-list-content {
        .flex-l();
        margin-top: 5px;
        .img .icon {
          font-size: 18px;
          height: 26px;
          margin: 0px 16px 0px 4px;
          color: #6e7491;
        }
        .title {
          font-size: 14px;
          color: #6e7491;
          line-height: 26px;
          text-align: left;
          word-break: break-word;
        }
      }
      &:hover {
        background: #f5f6fa;
      }
    }
  }
}
</style>
<style>
.message-box-drawer .el-dialog__header {
  display: none;
}
.message-box-drawer .el-dialog {
  padding: 0px !important;
  top: 50%;
  transform: translateY(-50%);
  width: 960px;
  max-height: 600px;
  height: calc(100vh - 60px) !important;
  margin: 0px auto !important;
  text-align: center;
  border-radius: 16px;
  background: #fff;
  box-shadow: -3px 2px 2px 0px rgba(255, 255, 255, 0.1) inset;
}
.message-box-drawer .el-dialog__body {
  padding: 0px !important;
}
.message-top-left .v-tabs-slider-wrapper {
  border-radius: 4px;
  height: 3px !important;
  width: 68px !important;
}
.message-top-left .v-tab {
  font-size: 16px;
  color: #6e7491;
  min-width: 68px !important;
}
.message-top-left .v-tab--active {
  color: #444558;
}
.message-top-left .v-tabs-bar {
  background: none !important;
  display: block !important;
}
.message-top-left .v-slide-group__wrapper {
  height: 58px !important;
}
</style>
