<template>
  <div>
    <Icon
      v-if="disabled"
      icon-class="icon-zhongfu1"
      class="cycle-icon"
      :class="{ disabled: disabled }"
    />
    <el-dropdown
      v-else
      trigger="click"
      @visible-change="dropdownVisibleChange"
      @command="typeChange"
    >
      <div>
        <Icon
          icon-class="icon-zhongfu1"
          :class="{ active: isActive }"
          class="cycle-icon"
          :style="
            taskAddObject.type == 1 || (taskAddObject.type == 7 && !id)
              ? 'color:#a0a3bd'
              : ''
          "
        />
      </div>
      <el-dropdown-menu slot="dropdown" class="el-dropdown-menu-class-clock">
        <el-dropdown-item
          v-for="(val, key) in cycleTypeDict"
          :key="key"
          :command="key"
          :class="{ active: parseInt(form.type) === parseInt(key) }"
          >{{ val }}</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
    <!-- 自定义周期时间 -->
    <task-custom-cycle
      :custom-cycle-visible="customCycleVisible"
      :task-id="taskId"
      :row="form"
      @closeTaskCustomCycle="closeTaskCustomCycle"
      @save="customCycleSave"
    />
  </div>
</template>

<script>
/* eslint-disable */
import taskUtil from '@/utils/task.js' // 任务util
import { mapState } from 'vuex'
import formMixin from '@/utils/mixins/form'
import repeatEnum from '@/utils/enums/repeat.js'
import TaskCustomCycle from './TaskCustomCycle' // 自定义周期时间
const cycleTypeEnum = repeatEnum.CYCLENUMTYPE // 周期类型枚举
export default {
  mixins: [formMixin],
  components: { TaskCustomCycle },
  props: {
    // 任务id
    taskId: {
      required: false
    },
    // 是否禁用
    disabled: {
      required: false
    }
  },
  data() {
    return {
      cycleTypeDict: repeatEnum.REPEATTYPE, // 周期类型字典
      customCycleVisible: false, // 自定义重复弹框显示
      id: ''
    }
  },
  created() {
    // 获取详情
    // this.taskAddObject = this.$store.state.commom.taskAddObject
    this.id && this.getDetail()
    // 默认重复类型(不重复)
    if (!this.taskAddObject.default) {
      this.$set(this.form, 'type', cycleTypeEnum.not)
    } else {
      this.$set(this.form, 'type', this.taskAddObject.type)
    }
    console.log(11111, this.taskAddObject)
  },
  computed: {
    ...mapState('common', {
      taskAddObject: 'taskAddObject'
    }),
    // 是否是自定义周期
    isCustomCycle() {
      return cycleTypeEnum.custom === this.form.type
    },
    // 是否有值
    isActive() {
      const type = this.form.type
      if (type && type !== cycleTypeEnum.not) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    closeTaskCustomCycle() {
      this.customCycleVisible = false
    },
    // 获取详情
    getDetail() {
      this.$apis
        .GETTASKCYCLE({
          id: this.id
        })
        .then((rest) => {})
    },
    // 保存
    async save() {
      if (this.taskId) {
        this.form.taskId = this.taskId
      }
      this.form.id = this.id
      this.form = JSON.parse(JSON.stringify(this.form))
      const path = this.id ? 'TASKREPEATUPDATE' : 'TASKREPEATADD'
      // const data = await this.$postData(path, this.form)
      this.submitDetail(path, this.form)
      // if (data) {
      //   this.id = data.id
      //   this.$emit('save', { id: this.id })
      // }
    },
    async submitDetail(path, _obj) {
      console.log('object', _obj)
      this.$apis[path](_obj).then((res) => {
        if (res.status === 200) {
          if (res.data) {
            this.id = res.data.id
            let msg = ''
            // 自定义时间
            msg = taskUtil.cycleDateFmt(this.row)
            this.$emit('save', { id: this.id, msg: msg, data: res.data })
          }
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 自定义周期保存成功
    customCycleSave(row) {
      console.log(row)
      this.id = row.id
      // 获取详情
      this.getDetail()
      this.$emit('save', { id: row.id, data: row })
    },
    // 下拉面板出现
    dropdownVisibleChange() {},
    // 周期选择
    typeChange(val) {
      console.log('选择', val)
      this.form.type = val
      console.log('选择1', this.taskAddObject)
      this.taskAddObject.type = val
      console.log('选择1', this.taskAddObject.type)
      this.taskAddObject = JSON.parse(JSON.stringify(this.taskAddObject))
      this.$store.commit('common/saveTaskAddObject', this.taskAddObject)
      // 自定义重复
      if (parseInt(cycleTypeEnum.custom) === parseInt(val)) {
        this.customCycleVisible = true
      } else {
        this.id = ''
        // 保存
        this.save()
      }
    },
    // 周期类型点击事件
    typeClick(val) {
      if (cycleTypeEnum.custom === parseInt(val)) {
        this.customCycleVisible = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cycle-icon {
  cursor: pointer;
  margin-left: 10px;
  width: 22px;
  font-size: 22px;
  height: 22px;
  color: #a0a3bd;
  padding-top: 10px;
  &.active {
    color: #ff9900;
  }
  &:hover {
    color: #3a78f6;
  }
  &.disabled {
    cursor: not-allowed;
    &.active {
      color: #999;
    }
    &:hover {
      color: #999;
    }
  }
}
</style>
<style lang="scss">
.el-dropdown-menu-class-clock {
  z-index: 3001 !important;
}
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #f5f6fa;
  color: #14142b;
}
</style>
