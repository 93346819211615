<template>
  <div
    class="classify-select"
    :style="classifyVal !== 'project' ? '' : 'cursor: default;'"
  >
    <el-dropdown v-if="source == 'group' && !isSonTask" trigger="click">
      <div class="classify-box">
        <Icon :icon-class="activeObj.icon" class="classify-icon" />
        <span>{{ activeObj.name }}</span>
        <Icon icon-class="icon-xiangxia" class="arrow-icon" />
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="item in classifyList"
          :key="item.val"
          @click.native="selectConfidence(item)"
          :class="activeObj.val == item.val ? 'el-dropdown-item-active' : ''"
        >
          <div class="classify-box classify-box-select">
            <Icon :icon-class="item.icon" class="classify-icon" />
            <span class="name">{{ item.name }}</span>
            <Icon
              v-show="activeObj.val == item.val"
              icon-class="icon-duigou"
              class="icon-checked"
            />
          </div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <template v-else>
      <div v-if="classifyVal == 'kr'" class="el-dropdown">
        <div class="el-dropdown-link classify-box project-box">
          <span>KR任务</span>
        </div>
      </div>
      <div v-if="classifyVal == 'project'" class="el-dropdown">
        <div class="el-dropdown-link classify-box project-box">
          <span>项目任务</span>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  model: {
    prop: 'classifyVal',
    event: 'change'
  },
  props: {
    classifyVal: {
      // kr,todo,project
      type: String,
      default: 'todo'
    },
    source: {
      type: String,
      default: 'group'
    },
    isSonTask: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      classifyValue: this.classifyVal,
      topMembersBean: {
        createUserid: ''
      },
      classifyList: [
        { name: '待办任务', val: 'todo', icon: 'icon-caidanlan-daiban' },
        { name: 'KR任务', val: 'kr', icon: 'icon-caidanlan-KRrenwu' },
        {
          name: '项目任务',
          val: 'project',
          icon: 'icon-caidanlan-xiangmurenwu'
        }
      ],
      todoObj: { name: '待办任务', val: 'todo', icon: 'icon-caidanlan-daiban' },
      krObj: { name: 'KR任务', val: 'kr', icon: 'icon-caidanlan-KRrenwu' },
      projectObj: {
        name: '项目任务',
        val: 'project',
        icon: 'icon-caidanlan-xiangmurenwu'
      },
      activeObj: {
        name: '待办任务',
        val: 0,
        icon: 'icon-caidanlan-daiban'
      }
    }
  },
  watch: {
    classifyVal: {
      handler(val) {
        this.classifyValue = val
        if (this.source == 'group') {
          this.activeObj = this[`${val}Obj`]
          this.activeObj = JSON.parse(JSON.stringify(this[`${val}Obj`]))
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    selectConfidence(item) {
      this.activeObj = item
      this.confidenceIndex = item.val
      this.$emit('change', item.val)
    },
    dragstartEvent(e) {
      // 禁止拖拽
      e.preventDefault()
    }
  }
}
</script>
<style lang="scss" scoped>
.el-dropdown-menu {
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 180px;
}
.el-dropdown-menu__item {
  align-items: center;
}
.el-dropdown-item-active {
  background: #f5f6fa !important;
}
.classify-box {
  display: flex;
  width: 120px;
  height: 28px;
  background: rgba(50, 150, 250, 0.1);
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  color: #005eff;
  .classify-icon {
    font-size: 16px;
    margin-right: 8px;
  }
  .arrow-icon {
    font-size: 20px;
    margin-left: 6px;
  }
  .confidence-text {
    font-weight: 500;
    font-size: 14px;
  }
}
.project-box {
  width: 90px;
}
.classify-box-select {
  background: transparent;
  justify-content: flex-start;
  color: #6e7491;
  height: 46px;
  width: 100%;
  .name {
    flex: 1;
  }
  .icon-checked {
    font-size: 20px;
    font-weight: bold;
    color: #3a70ff;
  }
  &:hover {
    color: #444558;
  }
}
.el-dropdown-menu__item {
  display: flex;
  font-size: 14px;
  color: #6e7491;
  line-height: 24px;
  padding: 2px 12px;
  align-items: center;
  &:hover {
    background: #f5f6fa;
    color: #444558 !important;
  }
}
</style>
