const versionNumber = 'v1' // 版本
const versionNumber2 = 'v2' // 版本
// 周期模块api
export default [
  {
  // 周期列表
  cyclist: `/api/ranloWeb/${versionNumber}/setcycle/list/info/list`
},
{
  // 查询周期内容
  cyclistInfoByid: `/api/ranloWeb/${versionNumber}/cycleinfo/`
},
{
  // 设置默认周期
  cycleSetDefault: `/api/admin/${versionNumber}/user/updateUserDefaultCycle`
},
{
  // 根据部门或成员名称获取周期成员
  cycleMemberListByDepart: `/api/ranloWeb/${versionNumber}/cycleinfouser/list/depart/`
},
{
  // 获取周期内我关注的成员
  cycleAttentionMemberList: `/api/ranloWeb/${versionNumber}/cycleInfoUserCare/list/`
},
{
  // 添加周期内关注成员
  cycleAddAttentionMember: `/api/ranloWeb/${versionNumber}/cycleInfoUserCare/save/`
},
{
  // 取消关注
  cycleDelAttentionMember: `/api/ranloWeb/${versionNumber}/cycleInfoUserCare/delete/`
},
{
  // 删除周期内部门或成员
  cycleDelMemberDepartOrId: `/api/ranloWeb/${versionNumber}/cycleinfouser/delete/`
},
{
  // 获取周期内成员列表
  cycleMemberList: `/api/ranloWeb/${versionNumber}/cycleinfouser/select/user`
},
{
  // 部门加入周期
  cycleInviteDepart: `/api/ranloWeb/${versionNumber}/cycleinfouser/save/depart`
},
{
  // 成员加入周期
  cycleInviteUser: `/api/ranloWeb/${versionNumber}/cycleinfouser/save`
},
{
  // 删除邀请成员
  cycleDeleteUser: `/api/ranloWeb/${versionNumber}/cycleinfouser/delete/`
},
{
  // 获取周期内可@的成员
  cycleAtMember: `/api/ranloWeb/${versionNumber2}/cycleinfouser/list/`
},
{
  // 周期内查询部门人员列表
  cycleDepartUserList: `/api/ranloWeb/${versionNumber}/cycleinfouser/depart/user/list/`
}
]
