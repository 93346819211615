var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.ossFileEntityList.length > 0
    ? _c(
        "div",
        { staticClass: "file-list-wrap", class: "file-list-" + _vm.formPage },
        _vm._l(_vm.ossFileEntityList, function (item, index) {
          return _c("div", { key: item.documentId, staticClass: "file-list" }, [
            _c(
              "div",
              {
                staticClass: "file-list-left",
                on: {
                  click: function ($event) {
                    return _vm.fileListClick(item)
                  },
                },
              },
              [
                item.mimeType == "png" ||
                item.mimeType == "jpg" ||
                item.mimeType == "jpeg" ||
                item.mimeType == "gif" ||
                item.mimeType == "svg"
                  ? _c("el-image", {
                      staticClass: "file-img",
                      attrs: { fit: "contain", src: item.downloadUrl, alt: "" },
                    })
                  : item.mimeType == "docx" ||
                    item.mimeType == "doc" ||
                    item.mimeType == "DOCX" ||
                    item.mimeType == "DOC"
                  ? _c("Icon", {
                      staticClass: "file-svg",
                      attrs: { "icon-class": "icon-DOCX" },
                    })
                  : item.mimeType == "mp3" || item.mimeType == "MP3"
                  ? _c("Icon", {
                      staticClass: "file-svg",
                      attrs: { "icon-class": "icon-MP3" },
                    })
                  : item.mimeType == "mp4" || item.mimeType == "MP4"
                  ? _c("Icon", {
                      staticClass: "file-svg",
                      attrs: { "icon-class": "icon-MP4" },
                    })
                  : item.mimeType == "pdf" || item.mimeType == "PDF"
                  ? _c("Icon", {
                      staticClass: "file-svg",
                      attrs: { "icon-class": "icon-PDF" },
                    })
                  : item.mimeType == "ppt" || item.mimeType == "PPT"
                  ? _c("Icon", {
                      staticClass: "file-svg",
                      attrs: { "icon-class": "icon-PPT" },
                    })
                  : item.mimeType == "txt" || item.mimeType == "TXT"
                  ? _c("Icon", {
                      staticClass: "file-svg",
                      attrs: { "icon-class": "icon-filetxt" },
                    })
                  : item.mimeType == "XLS" ||
                    item.mimeType == "xls" ||
                    item.mimeType == "XLSX" ||
                    item.mimeType == "xlsX"
                  ? _c("Icon", {
                      staticClass: "file-svg",
                      attrs: { "icon-class": "icon-XLS" },
                    })
                  : _c("Icon", {
                      staticClass: "file-svg",
                      attrs: { "icon-class": "icon-fileCommon" },
                    }),
                _c("div", { staticClass: "name" }, [_vm._v(_vm._s(item.name))]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "file-list-right" },
              [
                _vm._v(" " + _vm._s(item.fileSizeNew) + "KB "),
                !_vm.disabled
                  ? _c("Icon", {
                      staticClass: "close",
                      attrs: { "icon-class": "icon-close" },
                      on: {
                        click: function ($event) {
                          return _vm.filedelete(index, item.name)
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ])
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }