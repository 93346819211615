<template>
  <div class="task-select">
    <el-select
      v-model="inputVal"
      placeholder="未选择"
      :disabled="disabled"
      :clearable="fieldInfo.required === 1"
      @change="inputChange"
    >
      <el-option
        v-for="item in fieldInfo.fieldsCustomfieldsChoices"
        :key="item.id"
        :label="item.value"
        :value="item.value"
      />
    </el-select>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    fieldInfo: {
      type: String,
      default: () => {
        return {}
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inputVal: ''
    }
  },
  watch: {
    value: {
      handler(val) {
        this.inputVal = val
      },
      immediate: true
    }
  },
  methods: {
    inputChange(val) {
      this.$emit('change', val)
    }
  }
}
</script>
<style lang="scss" scoped>
.task-select {
  ::v-deep {
    .el-input__inner {
      border: none !important;
      padding-left: 0;
      width: 250px;
    }
    .el-input__inner::-webkit-input-placeholder {
      font-size: 14px;
      color: #a0a3bd !important;
    }

    .el-input__inner::-moz-placeholder {
      font-size: 14px;
      color: #a0a3bd !important;
    }

    .el-input__inner::-ms-input-placeholder {
      font-size: 14px;
      color: #a0a3bd !important;
    }
    .el-select .el-input .el-select__caret {
      color: #a0a3bd !important;
    }
    &:hover {
      .el-input__inner::-webkit-input-placeholder {
        font-size: 14px;
        color: #3a78f6 !important;
      }

      .el-input__inner::-moz-placeholder {
        font-size: 14px;
        color: #3a78f6 !important;
      }

      .el-input__inner::-ms-input-placeholder {
        font-size: 14px;
        color: #3a78f6 !important;
      }
      .el-select .el-input .el-select__caret {
        color: #3a78f6 !important;
      }
    }
  }
}
</style>
