<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="560px"
    class="apply-demo-dialog"
    :append-to-body="true"
    :modal-append-to-body="true"
    @close="closeDialog"
    title="转免费版本"
  >
    <div class="remind-wrap">
      <div class="free-text">
        <p>您的应用已过试用期，现已自动转为免费版本。</p>
        <p>10人及以内可免费使用 OKR、Todo、训练营等功能模块</p>
        <p>您原来的数据将会保留，若想继续使用更多模块可联系我们升级</p>
      </div>
      <div class="center">
        <el-button type="primary" class="update" @click="closeDialog">立即使用免费版</el-button>
        <div class="or">or</div>
        <a
          :href="`https://page.dingtalk.com/wow/dingtalk/act/serviceconversation?ddtab=true&wh_biz=tm&showmenu=false&goodsCode=DT_GOODS_881655784491241&corpId=${commonCorpId}&token=199f876c080bd26313560b8515f10454`"
          target="_blank"
          @click="closeDialog"
        >联系我们升级版本</a>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  props: {
  },
  data() {
    return {
      dialogVisible: true
    }
  },
  computed: {},
  watch: {
    dialogShow: {
      handler(val) {
        this.dialogVisible = true
      }
    }
  },
  mounted() {
    localStorage.setItem('freeVersionVisible', true)
  },
  methods: {
    closeDialog() {
      this.$emit('change', false)
      this.dialogVisible = false
    }
  }
}
</script>
<style lang="less" scoped>
.remind-wrap {
  margin: 0px 12px;
  .free-text {
    p {
      font-size: 14px;
      font-weight: 400;
      color: #444558;
      line-height: 22px;
      margin-bottom: 4px;
    }
  }
  .center {
    margin: 0px auto;
    text-align: center;
  }
  .update {
    width: 263px;
    height: 40px;
    border-radius: 20px;
    border: none;
    margin: 62px 0px 32px 0px;
    cursor: pointer;
    box-shadow: 0px 10px 24px -7px rgba(58, 120, 246, 0.5);
    &:hover {
      opacity: 0.74;
    }
  }
  .or {
    font-size: 16px;
    margin-bottom: 32px;
    color: #a0a3bd;
  }
  a {
    font-size: 14px;
    font-weight: 500;
    color: #3a70ff;
    text-decoration: none;
    &:hover {
      color: #3a78f6;
    }
  }
}
</style>
