<template>
  <div>
    <el-dropdown :trigger="trigger" class="task-el-dropdown" @command="command">
      <div class="pointer">
        <span>{{ localText || '全部' }}</span>
        <i class="el-icon-arrow-down el-icon--right"></i>
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item v-if="all" :command="null">全部</el-dropdown-item>
        <el-dropdown-item
          v-for="(val, key) in options"
          :key="val"
          :command="key"
        >
          {{ val }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  props: {
    // 展示的文字
    text: {
      type: String,
      required: true
    },
    // 选项数据
    options: {
      required: true
    },
    // 是否显示全部
    all: {
      type: Boolean,
      required: false
    },
    // 触发方式
    trigger: {
      type: String,
      default: 'click',
      required: false
    }
  },
  data() {
    return {
      localText: '' // 展示的文字
    }
  },
  created() {
    this.localText = this.text
  },
  methods: {
    // 点击菜单项触发的事件回调
    command(val) {
      this.localText = this.options[val]
      this.$emit('command', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.pointer {
  &:hover {
    color: #0d84eb;
  }
}
</style>
