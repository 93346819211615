<template>
  <transition name="fade">
    <div :key="Math.random()" class="no-data-show">
      <img v-if="type === 1" :src="imgUrl" class="no-data-show-icon" />
      <img
        v-else
        src="@/assets/imgs/common/taskNull.png"
        class="no-data-show-icon img-2"
      />
      <div v-if="text" class="no-data-show-text" :style="`color:${textColor}`">
        {{ text }}
      </div>
      <div v-if="searchText" class="search-text">{{ searchText }}</div>
    </div>
  </transition>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      default: '暂无数据'
    },
    type: {
      type: Number,
      default: 1
    },
    textColor: {
      default: '#6e7491'
    },
    imgUrl: {
      default: require('@/assets/imgs/common/commentNull.png')
    },
    searchText: {
      default: ''
    }
  }
}
</script>
<style lang="less" scoped>
.no-data-show {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .no-data-show-icon {
    margin: 16px auto 0px auto;
    width: 116px;
  }
  .img-2 {
    width: 80px;
  }
  .no-data-show-text {
    text-align: center;
    color: #6e7491;
    // margin-top: 8px;
  }
  .search-text {
    font-size: 14px;
    font-weight: 400;
    color: #0077ff;
    line-height: 20px;
    margin-top: 8px;
  }
}
</style>
