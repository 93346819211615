// 头部header模块方法库
import apis from '../apis_moudles/index'
import req from '../request'
// 获取成长过程接口
const HEADERUSERPROGRESSLIST = (params) => {
  return req.get(apis.headerUserProgressList, {
    params
  })
}
// 获取极光数据
const HEADERGETAUTHPAYLOAD = (params) => {
  return req.get(apis.headerGetAuthPayload, {
    params
  })
}
// 消息变为已读
const HEADERSETNOTIFYMSGTOREADED = (params) => {
  return req.post(apis.headerSetNotifyMsgToReaded, params)
}
// 消息数量
const HEADERGETNOREADCOUNT = (params) => {
  return req.get(apis.headerGetNoReadCount, {
    params
  })
}
// 头部搜索获取目标数据
const HEADEROBJLISTSEARCH = (params) => {
  return req.get(apis.headerObjListSearch, {
    params
  })
}
// 头部搜索获取任务数据
const HEADERTASKLISTSEARCH = (params) => {
  return req.get(apis.headerTaskListSearch, {
    params
  })
}
// 获取对齐目标详情
const HEADEROBJALIGNINFO = (params) => {
  return req.get(apis.headerObjAlignInfo, {
    params
  })
}

// 获取项目概述
const HEADERPROJECTDESC = (id, params) => {
  return req.get(`${apis.headerProjectDesc}${id}`, {
    params
  })
}
// 响应日程
const HEADERSCHEDULEDECISION = (params) => {
  return req.put(apis.headerScheduleDecision, params)
}
// 获取企业列表
const HEADERGETCOMPANYLIST = (params) => {
  return req.get(apis.headerGetCompanyList, {
    params
  })
}
// 根据企业ID获取企业信息
const HEADERGETCOMPANYDETAIL = (id, params) => {
  return req.get(`${apis.headerGetCompanyDetail}${id}`, {
    params
  })
}
// 提交意见反馈
const HEADERSUBMITFEEDBACK = (params) => {
  return req.post(apis.headerSubmitFeedBack, params)
}
// 提交意见反馈
const HEADERCOMPANYADD = (params) => {
  return req.post(apis.headerCompanyAdd, params)
}
// 创建周期
const HEADERADDCYCLE = (params) => {
  return req.post(apis.headerAddCycle, params)
}
// 创建默认任务
const HEADERADDINITUSERTASK = (params) => {
  return req.get(apis.headerAddInitUserTask, {
    params
  })
}
// 创建默认通知设置
const HEADERADDINITNOTIFYSET = (params) => {
  return req.post(apis.headerAddInitNotifySet, params)
}
// 查询历史企业列表
const HEADERAGETCOMHISTORYLIST = (params) => {
  return req.get(apis.headerGetComHistoryList, {
    params
  })
}
// 删除企业
const HEADERDELCOMBYID = (id, params) => {
  return req.get(`${apis.headerDelComById}${id}`, {
    params
  })
}
// 修改个人信息
const HEADERUSERUPDATE = (params) => {
  return req.post(apis.headerUserUpdate, params)
}
// 文件上传
const HEADERUSERFILEUPLOAD = (params) => {
  return req.post(apis.userFileUpload, params)
}
// 修改手机号
const HEADERUPDATEMOBILE = (params) => {
  return req.post(apis.headerUpdateMobile, params)
}
// 修改密码
const HEADERUPDATEPASSWORD = (url, params) => {
  return req.post(`${apis.headerUpdatePassword}${url}`, params)
}
// 修改邮箱
const HEADERUPDATEEMIAL = (params) => {
  return req.post(`${apis.headerUpdateEmail}`, params)
}
// 修改通知设置
const HEADERSETNOTIFYUPDATE = (params) => {
  return req.post(apis.headerSetNotifyUpdate, params)
}
// 获取通知设置
const HEADERGETNOTIFYUPDATE = (params) => {
  return req.get(apis.headerGetNotifyUpdate, {
    params
  })
}
// 获取更新日志
const GETSYSTEMLOGLIST = (params) => {
  return req.get(apis.getSystemLogList, params)
}
// 获取邀请二维码信息
const GETUSERSETINVITE = (params) => {
  return req.post(apis.getUserSetInvite, params)
}
// 获取消息列表
const GETNOTICELIST = (params) => {
  return req.get(apis.getNoticeList, {
    params
  })
}
// 所有消息已读
const SETNOTICEREADALL = (params) => {
  return req.put(apis.setNoticeReadAll, {
    params
  })
}
// 消息已读id
const SETNOTICEREADBYID = (params) => {
  return req.post(apis.setNoticeReadById, params)
}
export default [
  {
    HEADERUSERPROGRESSLIST
  },
  {
    HEADERGETAUTHPAYLOAD
  },
  {
    HEADERSETNOTIFYMSGTOREADED
  },
  {
    HEADERGETNOREADCOUNT
  },
  {
    HEADEROBJLISTSEARCH
  },
  {
    HEADERTASKLISTSEARCH
  },
  {
    HEADEROBJALIGNINFO
  },
  {
    HEADERPROJECTDESC
  },
  {
    HEADERSCHEDULEDECISION
  },
  {
    HEADERGETCOMPANYLIST
  },
  {
    HEADERGETCOMPANYDETAIL
  },
  {
    HEADERSUBMITFEEDBACK
  },
  {
    HEADERCOMPANYADD
  },
  {
    HEADERADDCYCLE
  },
  {
    HEADERADDINITUSERTASK
  },
  {
    HEADERADDINITNOTIFYSET
  },
  {
    HEADERAGETCOMHISTORYLIST
  },
  {
    HEADERDELCOMBYID
  },
  {
    HEADERUSERUPDATE
  },
  {
    HEADERUSERFILEUPLOAD
  },
  {
    HEADERUPDATEMOBILE
  },
  {
    HEADERUPDATEPASSWORD
  },
  {
    HEADERUPDATEEMIAL
  },
  {
    HEADERSETNOTIFYUPDATE
  },
  {
    HEADERGETNOTIFYUPDATE
  },
  {
    GETSYSTEMLOGLIST
  },
  {
    GETUSERSETINVITE
  },
  {
    GETNOTICELIST
  },
  {
    SETNOTICEREADALL
  },
  {
    SETNOTICEREADBYID
  }
]
