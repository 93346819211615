<template>
  <el-dialog
    title="自定义可见范围"
    :visible.sync="dialogVisible"
    width="520px"
    :append-to-body="true"
    :before-close="handleClose"
    :model="false"
    class="custom-range-dialog"
    @click.stop
  >
    <div class="range-content" @click.stop>
      <div class="type-title" @click.stop>
        指定可见部门
        <departTree
          @getPart="getPart"
          :checkedKeys="departIdList"
          :expandedKeys="departIdList"
          :type="0"
          :slotType="1"
          @click.stop
        >
          <Icon icon-class="icon-a-tianjia2" class="add-icon" slot="slot" />
        </departTree>
      </div>
      <div class="select-list-wrap" v-if="departList.length > 0">
        <avatar-box
          v-for="(user, userIndex) in departList"
          :key="user"
          :avatarInfo="user"
          type="2"
          @click.stop
          @deleteAvatar="deleteAvatar(user, 1, userIndex)"
        />
      </div>
      <div class="type-title" @click.stop>
        指定可见人
        <user-picker-checked
          :select-people-list="peopleList"
          page-type="taskSummary"
          ref="teamPerson"
          @click.stop
          @pick="toriginatorPicker"
        >
          <Icon icon-class="icon-a-tianjia2" class="add-icon" />
        </user-picker-checked>
      </div>
      <div class="select-list-wrap" v-if="peopleList.length > 0">
        <avatar-box
          v-for="(user, userIndex) in peopleList"
          :key="user.id"
          :avatarInfo="user"
          type="1"
          @click.stop
          @deleteAvatar="deleteAvatar(user, 2, userIndex)"
        />
      </div>
    </div>
    <div class="bottom-step-buttons">
      <el-button class="left-button" @click.stop="handleClose">取消</el-button>
      <el-button @click.stop="getTargetAligningSubmit('提交')">确认</el-button>
    </div>
  </el-dialog>
</template>
<script>
import avatarBox from './avatarBox.vue'
import departTree from '@/views/analyse/page/tab/departSelectTree'
export default {
  components: { avatarBox, departTree },
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  props: {
    dialogShow: {
      type: Boolean,
      default: true
    },
    okrId: {
      type: String,
      default: ''
    },
    customPersonList: {
    },
    customDepartList: {
    }
  },
  data() {
    return {
      dialogVisible: this.dialogShow,
      peopleList: [],
      departList: [],
      departIdList: []
    }
  },
  watch: {
    dialogShow: {
      handler(val) {
        this.dialogVisible = val
      }
    },
    customPersonList: function (val) {
      this.peopleList = this.customPersonList
    },
    customDepartList: function (val) {
      this.dataInit()
    }
  },
  created() {
    this.peopleList = this.customPersonList
    this.dataInit()
  },
  methods: {
    dataInit() {
      this.departList = this.customDepartList
      const id = []
      this.customDepartList.forEach((item) => {
        id.push(item.id)
      })
      this.departIdList = id
    },
    deleteAvatar(item, type, index) {
      if (type === 1) {
        this.departList.splice(index, 1)
      } else {
        this.peopleList.splice(index, 1)
      }
    },
    getPart(id, list) {
      var data = []
      this.departList = []
      this.departIdList = id
      list.forEach((item) => {
        item.name = item.label
        data.push(item)
      })
      this.$nextTick(() => {
        this.departList = data
        console.log('getPart', this.departList)
      })
    },
    // 选择成员
    toriginatorPicker(data) {
      this.peopleList = data
    },
    // 关闭启动状态
    handleClose() {
      this.dialogVisible = false
      this.$emit('change', false)
    },

    // 对齐目标提交内容
    getTargetAligningSubmit() {
      this.$emit('confirm', {
        customDepartList: this.departList,
        customPersonList: this.peopleList
      })
      this.handleClose()
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../assets/styles/dialog.less';
.custom-range-dialog {
  // background: rgba(0, 0, 0, .5);
}
::v-deep {
  .v-modal,
  .el-dialog__wrapper,
  .user-picker-atdialog-pop {
    z-index: 9999 !important;
  }
  .el-input__inner {
    border-radius: 8px;
  }
  .el-dialog__header {
    display: block !important;
  }
}
.range-content {
  min-height: 280px;
  margin-bottom: 24px;
  .type-title {
    .flex-l();
    font-size: 16px;
    font-weight: 500;
    color: #14142b;
    margin: 26px 0px 10px 0px;
    .add-icon {
      width: 24px;
      height: 24px;
      font-size: 24px;
      margin-left: 8px;
      border-radius: 50%;
      font-weight: 500;
      color: #3a78f6;
      cursor: pointer;
      &:hover {
        color: #005eff;
      }
    }
  }
  .select-list-wrap {
    .flex-l();
    flex-wrap: wrap;
  }
}
</style>
