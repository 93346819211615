<template>
  <el-dialog
    title="复制目标"
    :visible.sync="dialogVisible"
    width="520px"
    :append-to-body="true"
    :before-close="handleClose"
    class="comm-check-form"
  >
    <div class="dialog-content">
      <div class="form-title">
        复制内容
        <el-tooltip
          :open-delay="300"
          placement="top-start"
          :offset="3"
          content="将原有目标重新复制一份到所选周期内，不包含目标对齐关系、目标进度、KR进度、目标动态及点赞评论等。勾选KR任务及子任务后，将会把KR下的任务和子任务一起复制一份。"
        >
          <i class="el-icon-warning-outline info-icon"></i>
        </el-tooltip>
      </div>
      <p class="info">复制的目标不包含目标对齐关系</p>
      <div class="comm-check">
        <el-checkbox v-model="hasTask">KR任务及子任务</el-checkbox>
      </div>
      <div class="form-title">
        复制到周期
        <el-cascader
          class="cycle-select"
          v-model="cycleId"
          :options="cycleList"
          :props="{
            expandTrigger: 'hover',
            value: 'id',
            label: 'name',
            children: 'cycleInfoList'
          }"
        />
      </div>
      <div class="bottom-step-buttons">
        <button @click="handleClose" class="left-button">取消</button>
        <button @click="save">确定</button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  props: {
    dialogShow: {
      type: Boolean,
      default: true
    },
    data: {
      type: Object
    }
  },
  watch: {
    dialogShow: {
      handler(val) {
        this.dialogVisible = val
      }
    }
  },
  data() {
    return {
      dialogVisible: this.dialogShow,
      hasTask: false,
      cycleList: [],
      cycleId: ''
    }
  },
  computed: {},
  created() {
    // 获取周期列表
    this.queryCycleList()
  },
  methods: {
    // 获取周期列表
    async queryCycleList() {
      await this.$apis.CYCLIST().then((rest) => {
        if (rest.data.length > 0) {
          (rest.data || []).forEach((list) => {
            (list.cycleInfoList || []).forEach((item) => {
              if ((list.type === 1 || list.type === 2) && item.type === 0) {
                item.name = `${item.startDate.substring(0, 4)}年 ${item.name}`
              }
            })
          })
          this.cycleList = rest.data
        }
      })
    },
    handleClose() {
      this.dialogVisible = false
      this.$emit('change', false)
    },

    async save() {
      if (!this.cycleId || this.cycleId.length === 0) {
        this.$message.warn('周期不能为空')
        return
      }
      const obj = {
        id: this.data.id, // 目标id
        cycleInfoId: this.cycleId[1], // 周期id
        containTask: this.hasTask ? 1 : 0 // 0 不复制 ， 1 复制
      }
      await this.$apis.OKRCOPY(obj).then((res) => {
        if (res.status === 200) {
          this.$emit('startLoading', this.cycleId)
          this.$nextTick(() => {
            this.handleClose()
          })
        }
      })
    }
  },
  components: {}
}
</script>
<style lang="less" scoped>
@import '../../../../assets/styles/dialog.less';
.dialog-content {
  padding-bottom: 8px;
  padding-left: 24px;
  .form-title {
    font-size: 16px;
    color: #444558;
    margin-top: 24px;
    font-weight: 500;
    .info-icon {
      font-size: 14px;
      color: #acaec5;
      cursor: pointer;
      &:hover {
        color: #5b5c6d;
      }
    }
  }
  .info {
    font-size: 12px;
    color: #ff9900;
    font-weight: 300;
    margin: 8px 0 16px;
  }
  .cycle-select {
    margin-left: 8px;
    width: 200px;
    ::v-deep {
      .el-input__inner {
        border: 1px solid #f5f6fa;
        height: 40px;
        line-height: 40px;
        border-radius: 8px;
      }
    }
  }
}
.bottom-step-buttons {
  margin-top: 40px;
}
::v-deep .el-dialog__header {
  padding: 32px 32px 0px 32px;
}
</style>
<style lang="scss">
.el-cascader__dropdown {
  border: 0;
  border-radius: 16px;
  max-height: 210px;
}
.el-cascader-panel {
  max-height: 210px;
}
</style>
