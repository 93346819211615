import INDEX from '../../views/global/protocol/index.vue'
export default {
  path: '/protocol',
  redirect: '/protocol/serviceAgreement',
  name: 'Auth',
  component: INDEX,
  children: [
    {
    path: '/protocol/serviceAgreement',
    name: 'serviceAgreement',
    component: () => {
      return import(
        /* webpackChunkName: "protocolServiceAgreement" */
        '../../views/global/protocol/serviceAgreement.vue'
      )
    }
  },
  {
    path: '/protocol/privacyAgreement',
    name: 'privacyAgreement',
    component: () => {
      return import(
        /* webpackChunkName: "protocolPrivacyAgreement" */
        '../../views/global/protocol/privacyAgreement.vue'
      )
    }
  },
  {
    path: '/protocol/sdkInfo',
    name: 'sdkInfo',
    component: () => {
      return import(
        /* webpackChunkName: "protocolSdkInfo" */
        '../../views/global/protocol/sdkInfo.vue'
      )
    }
  }
  ]
}
