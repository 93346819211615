var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "task-date" },
    [
      _c(
        "div",
        {
          staticClass: "more-btn",
          class: _vm.selectTime ? "date-btn" : "",
          on: { click: _vm.selectShowTime },
        },
        [
          _vm._v(" " + _vm._s(_vm.selectTime || "设置时间") + " "),
          _vm.selectTime
            ? _c("Icon", {
                staticClass: "close",
                attrs: { "icon-class": "icon-close" },
                nativeOn: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.timeClear()
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("el-date-picker", {
        ref: `settime${_vm.fieldInfo.id}`,
        staticClass: "hide-date-border",
        attrs: {
          format: "yyyy-MM-dd HH:mm",
          "value-format": "yyyy-MM-dd HH:mm",
          type: "datetime",
          placeholder: "设置日期",
          "popper-class": "addTimeClass",
        },
        on: { change: _vm.dateChange },
        model: {
          value: _vm.selectTime,
          callback: function ($$v) {
            _vm.selectTime = $$v
          },
          expression: "selectTime",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }