var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新建目标",
            visible: _vm.dialogVisible,
            width: "880px",
            "append-to-body": true,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "add-target-dialog" }, [
            _c(
              "div",
              [
                _c("add-step", {
                  attrs: { steps: _vm.steps, "active-step": _vm.activeStep },
                }),
                _vm.targetDetailsAlignState && _vm.activeStep !== 3
                  ? _c(
                      "div",
                      { staticClass: "align-obj" },
                      [
                        _c("objective-show", {
                          attrs: {
                            "align-hide": _vm.addType === "2" ? true : false,
                            "obj-data": _vm.alignObjectiveData,
                            alignType: _vm.alignType,
                            type: "align",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeStep === 1,
                        expression: "activeStep === 1",
                      },
                    ],
                  },
                  [
                    _c("div", { staticClass: "objective-add-input" }, [
                      _c(
                        "div",
                        { staticClass: "input-box" },
                        [
                          _c(
                            "AdviceInput",
                            {
                              attrs: {
                                html: _vm.objectiveData.content,
                                type: 1,
                                "ignore-rule-id-list":
                                  _vm.objectiveData.ignoreRuleIdList,
                              },
                              on: { addIgnore: _vm.addIgnoreRule },
                              model: {
                                value: _vm.titlePopShow,
                                callback: function ($$v) {
                                  _vm.titlePopShow = $$v
                                },
                                expression: "titlePopShow",
                              },
                            },
                            [
                              _vm.activeStep === 1
                                ? _c("at-input", {
                                    ref: "titleInpurRef",
                                    attrs: {
                                      html: _vm.objectiveData.content,
                                      "enter-able": true,
                                      isBgColor: false,
                                      atstate: true,
                                      limit: "500",
                                      "quarter-id": _vm.quarterId,
                                      placeholder: _vm.objPlaceholder,
                                    },
                                    on: {
                                      enter: _vm.getTargetAddNext,
                                      input: _vm.getTargetAddComment,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "bottom-actions" },
                        [
                          _c(
                            "at-user-dialog",
                            {
                              attrs: { "quarter-id": _vm.quarterId },
                              on: { selectPerson: _vm.atPerson },
                            },
                            [
                              _c("div", { staticClass: "example-text" }, [
                                _vm._v("@ 邀请对齐"),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "example-text",
                              on: { click: _vm.showExampleList },
                            },
                            [_vm._v("OKR示例")]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "cycle-text" }, [
                      _vm.addType !== "2"
                        ? _c("div", { staticClass: "cycle-info-box" }, [
                            _vm._v(" 所属周期 "),
                            _c("div", { staticClass: "cycle-box" }, [
                              _vm._v(_vm._s(_vm.periodTextContent)),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeStep === 2,
                        expression: "activeStep === 2",
                      },
                    ],
                    staticClass: "add-key-result",
                  },
                  [
                    _c("objective-show", {
                      attrs: {
                        "obj-data": _vm.objectiveData,
                        "edit-state": true,
                        "align-hide": _vm.addType === "2" ? true : false,
                        step: _vm.activeStep,
                      },
                      on: {
                        keyResultChange: _vm.keyResultChange,
                        deleteKr: _vm.deleteKr,
                        ignorekr: _vm.ignorekr,
                        addIgnoreRule: _vm.addIgnoreRule,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeStep === 3,
                        expression: "activeStep === 3",
                      },
                    ],
                    staticClass: "add-key-result",
                  },
                  [
                    _c("objective-show", {
                      attrs: {
                        "align-obj-data": _vm.alignObjectiveData,
                        "obj-data": _vm.objectiveData,
                        "align-hide": _vm.addType === "2" ? true : false,
                        "align-state":
                          !_vm.targetDetailsAlignState && _vm.addType != 2,
                        step: _vm.activeStep,
                      },
                      on: {
                        changeKrWeight: _vm.changeKrWeight,
                        changeAlignData: _vm.changeAlignData,
                        ignorekr: _vm.ignorekr,
                        addIgnoreRule: _vm.addIgnoreRule,
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "bottom-content" }, [
                  _c("div", [
                    _vm.activeStep === 1
                      ? _c("div", { staticClass: "look-add-wrap" }, [
                          _c(
                            "span",
                            {
                              staticClass: "look-add-okr",
                              attrs: { type: "text" },
                              on: { click: _vm.openVideo },
                            },
                            [
                              _c("Icon", {
                                staticClass: "add-view-img",
                                attrs: { "icon-class": "icon-Video" },
                              }),
                              _vm._v("30秒了解如何添加OKR "),
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "look-add-okr look-add-school",
                              attrs: { type: "text" },
                              on: { click: _vm.openSchool },
                            },
                            [
                              _c("Icon", {
                                staticClass: "add-view-img",
                                attrs: { "icon-class": "icon-xunlianying" },
                              }),
                              _vm._v("OKR线下训练营 "),
                              _c("Icon", {
                                staticClass: "add-view-img icon-hot",
                                attrs: { "icon-class": "icon-HOT" },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeStep === 2,
                            expression: "activeStep === 2",
                          },
                        ],
                        staticClass: "kr-tips-list",
                      },
                      [
                        _c("div", { staticClass: "kr-tips" }, [
                          _c("div", { staticClass: "dot red-dot" }),
                          _vm._v("至少一个关键结果 "),
                        ]),
                        _c("div", { staticClass: "kr-tips" }, [
                          _c("div", { staticClass: "dot" }),
                          _vm._v("不要超过5个关键结果 "),
                        ]),
                        _c("div", { staticClass: "kr-tips" }, [
                          _c("div", { staticClass: "dot" }),
                          _vm._v("通过指标量化关键结果 "),
                        ]),
                      ]
                    ),
                    _vm.activeStep === 3
                      ? _c(
                          "div",
                          { staticClass: "okr-type-wrap" },
                          [
                            _vm._v(" 目标类型: "),
                            _c("label-select", {
                              staticClass: "label-select",
                              model: {
                                value: _vm.labelType,
                                callback: function ($$v) {
                                  _vm.labelType = $$v
                                },
                                expression: "labelType",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.approveDetailVisible
                      ? _c("div", { staticClass: "approve-detail-wrap" }, [
                          _c("img", {
                            staticClass: "info-icon",
                            attrs: {
                              src: require("@/assets/imgs/meeting/addTargetInfo.png"),
                            },
                          }),
                          _c("div", { staticClass: "descripe" }, [
                            _vm._v(
                              "OKR 已开启审批，请制定完成后提交给上级或主管进行审批"
                            ),
                          ]),
                          _vm.approveDetailData &&
                          _vm.approveDetailData.appointUserName
                            ? _c(
                                "div",
                                { staticClass: "user" },
                                [
                                  _vm._v(" 审批人： "),
                                  _c("wx-open-data", {
                                    attrs: {
                                      id: _vm.approveDetailData.appointUserName,
                                      nameData: {
                                        name: _vm.approveDetailData
                                          .appointUserName,
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]),
                  _c(
                    "div",
                    { staticClass: "bottom-step-buttons medium-btns" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "left-button",
                          on: { click: _vm.handleClose },
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.activeStep !== 1,
                              expression: "activeStep !== 1",
                            },
                          ],
                          staticClass: "left-button",
                          on: { click: _vm.prevStep },
                        },
                        [_vm._v("上一步")]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.activeStep !== 3,
                              expression: "activeStep !== 3",
                            },
                          ],
                          on: { click: _vm.nextStep },
                        },
                        [_vm._v("下一步")]
                      ),
                      _c(
                        "L-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.activeStep === 3,
                              expression: "activeStep === 3",
                            },
                          ],
                          staticStyle: { "margin-left": "8px" },
                          attrs: { loading: _vm.addloading },
                          on: { clickEvt: _vm.finishAdd },
                        },
                        [_vm._v("完成")]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ]),
          _vm.exampleListVisible
            ? _c("example-list", {
                on: { getOkrFlashListSubmit: _vm.getOkrFlashListSubmit },
                model: {
                  value: _vm.exampleListVisible,
                  callback: function ($$v) {
                    _vm.exampleListVisible = $$v
                  },
                  expression: "exampleListVisible",
                },
              })
            : _vm._e(),
          _c("look-add-okr", {
            attrs: { "video-dialog-show": _vm.videoDialogShow },
            on: { closeVideoDialog: _vm.closeVideoDialog },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }