import Vue from 'vue'
import guards from './router.config'
import VueRouter from 'vue-router'
// 会议
import meeting from './modules/meeting'
// 统计分析
import analyse from './modules/analyse'
// 项目管理
import pm from './modules/pm'
// 小结
import conclusion from './modules/conclusion'
// 绩效
import kpi from './modules/kpi'
// okr
import okr from './modules/okr'
// todo
import todo from './modules/todo'
// 工作台
import workbench from './modules/workbench'
// 帐号设置
import setting from './modules/setting'
// 后台管理
import admin from './modules/admin'
// 头部
import header from './modules/header'
// 登录，注册等权限相关
import login from './modules/login'
// 日历
import calendar from './modules/calendar'
// 登录，注册等权限相关
import auth from './modules/auth'
import authOld from './modules/auth_old'
// 协议相关
import protocol from './modules/protocol'
// 步骤相关
import step from './modules/step'
// 移动端相关
import mobile from './modules/mobile'
// 意见反馈
import feedBack from './modules/feedBack'
// 布局
import Layout from '../views/global/layout/index.vue'
// 视频
import video from './modules/video'

// 训练营
import classroom from './modules/classroom'
// 登录异常纯文字
import loginErrorText from './modules/loginErrorText'
// 绩效新版
import kpiV2 from './modules/kpiV2'

Vue.use(VueRouter)
var isQwUrl = '/login'
var loginUrl = login
if (
  window.location.host.indexOf('qywx') === -1 &&
  window.location.host.indexOf('qw') === -1
) {
  isQwUrl = '/workbench'
  loginUrl = [auth]
}
const routes = [{
    path: '/',
    component: Layout,
    children: [{
        path: '/',
        redirect: isQwUrl // 页面重定向
        // redirect: '/login' // 页面重定向
      },
      ...workbench,
      analyse,
      ...pm,
      ...conclusion,
      kpi,
      ...okr,
      todo,
      ...meeting,
      setting,
      admin,
      ...header,
      calendar,
      feedBack,
      ...classroom,
      ...kpiV2
    ]
  },
  ...loginUrl,
  authOld,
  protocol,
  step,
  ...mobile,
  video,
  ...loginErrorText,
  // 404页面
  {
    path: '*',
    name: '404',
    component: () => {
      return import( /* webpackChunkName: "user" */ '../404.vue')
    }
  }
]

const router = new VueRouter({
  mode: window.location.host.includes('qywx') || window.location.host.includes('qw') ?
    'hash' : 'history',
  base: process.env.BASE_URL,
  routes
})
// 解决路由报错
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err)
}
VueRouter.prototype.replace = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err)
}
// 路由守卫配置
guards(router)

export default router
