var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    _vm._g(
      {
        class: `el-drawer-header-visible-${_vm.isShowDetails}`,
        attrs: {
          "append-to-body": true,
          visible: _vm.dialogVisible,
          "before-close": _vm.handleClose,
          size: "800px",
          title: `OKR指标`,
          direction: "rtl",
        },
        on: {
          "update:visible": function ($event) {
            _vm.dialogVisible = $event
          },
        },
      },
      _vm.$listeners
    ),
    [
      _c("div", { staticClass: "field-content-wrap" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "vueloading",
                rawName: "v-vueloading",
                value: _vm.pageLoading,
                expression: "pageLoading",
              },
            ],
            staticClass: "kr-list-wrap",
          },
          _vm._l(_vm.okrData, function (item, index) {
            return _c(
              "div",
              { key: item.id, staticClass: "kr-list" },
              [
                _c("kr-item", {
                  attrs: { krItem: item, krIndex: index, okrData: item },
                  on: { refresh: _vm.queryObjectiveDetail },
                }),
                _vm.userInfo.id === item.createUserid
                  ? _c("div", { staticClass: "add-index-wrap" }, [
                      _c(
                        "div",
                        {
                          staticClass: "add-wrap",
                          on: {
                            click: function ($event) {
                              return _vm.syncKpiClick(index, item)
                            },
                          },
                        },
                        [
                          _c("Icon", {
                            staticClass: "icon",
                            attrs: { "icon-class": "icon-tianjia3" },
                          }),
                          _vm._v("同步至绩效 "),
                        ],
                        1
                      ),
                      _c("div", [
                        _vm._v(
                          "可将 OKR 指标同步至「制定指标」流程的绩效考核中"
                        ),
                      ]),
                    ])
                  : _vm._e(),
                item.performanceCustomIndexDtoList &&
                (item.performanceCustomIndexDtoList || []).length > 0
                  ? _c(
                      "div",
                      { staticClass: "kpi-list-wrap" },
                      _vm._l(
                        item.performanceCustomIndexDtoList,
                        function (kpiItem, kpiIndex) {
                          return _c(
                            "kpi-item",
                            {
                              key: kpiItem.examineId,
                              attrs: {
                                item:
                                  kpiItem.performanceDto &&
                                  kpiItem.performanceDto.examineDto,
                                "kpi-detail": kpiItem.performanceDto,
                                "from-type": "myKpi",
                              },
                            },
                            [
                              _vm.userInfo.id === item.createUserid &&
                              kpiItem.performanceDto &&
                              kpiItem.performanceDto.currentProcessStep === 0
                                ? _c("template", { slot: "kpiItemRight" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "border-button open-kpi",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.closeSyncKpi(
                                              item,
                                              kpiItem
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("取消同步")]
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            2
                          )
                        }
                      ),
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
          0
        ),
      ]),
      _vm.syncKpiVisible
        ? _c("syncKpi", {
            attrs: {
              krId: _vm.syncKpiObj.krId,
              defaultList: _vm.syncKpiObj.defaultList,
            },
            on: { refresh: _vm.syncKpiRefresh },
            model: {
              value: _vm.syncKpiVisible,
              callback: function ($$v) {
                _vm.syncKpiVisible = $$v
              },
              expression: "syncKpiVisible",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }