var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "layout-overflow-box" },
    [
      _c(
        "div",
        { staticClass: "layout-box" },
        [
          _c("Header"),
          _c(
            "div",
            { staticClass: "layout-main" },
            [
              _c(
                "transition",
                { attrs: { name: "fade-transform", mode: "out-in" } },
                [_c("router-view")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "evaluate-div" },
        [
          _c("evaluate", {
            attrs: {
              evaluateList: _vm.evaluateList,
              "evaluate-visible": _vm.evaluateVisible,
            },
            on: {
              submitStatus: _vm.submitStatus,
              closeStatus: _vm.closeStatus,
            },
          }),
        ],
        1
      ),
      _vm.companyInfo.belonger == _vm.userInfo.id &&
      _vm.companyInfo.version == 0 &&
      _vm.companyInfo.expireDate &&
      !_vm.$isDD &&
      !_vm.$isQw
        ? _c("applyDemo")
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }