// 总结
// 统一管理所有的基础请求
import apis from '../apis_moudles/index'
import req from '../request'
var $isQw =
  window.location.host.includes('qywx') || window.location.host.includes('qw')
var $isDD =
  window.location.host.includes('dd') ||
  window.location.host.includes('dingtalkcloud') ||
  window.location.href.includes('dingding')
// 获取模版管理列表
const CONCLUSIONMANAGELIST = (params) => {
  return req.get(`${apis.conclusionManageList}`, {
    params
  })
}
// 新增模版
const CONCLUSIONADDTEMPLATE = (params) => {
  return req.post(apis.conclusionAddTemplate, params)
}
// 复制模版
const CONCLUSIONCOPYTEMPLATE = (id, params) => {
  return req.put(`${apis.conclusionCopyTemplate}${id}`, params)
}
// 删除模版
const CONCLUSIONDELETETEMPLATE = (id, params) => {
  return req.delete(`${apis.conclusionDeleteTemplate}${id}`, {
    params
  })
}
// 保存模版权限
const CONCLUSIONTEMPLATEPERCONFIRM = (params) => {
  return req.post(apis.conclusionTemplatePerConfirm, params)
}
// 获取模版权限
const CONCLUSIONTEMPLATEPERGET = (id, params) => {
  return req.get(`${apis.conclusionTemplatePerGet}${id}`, {
    params
  })
}
// 使用模版列表
const CONCLUSIONTEMPLATEPECHOICELIST = (params) => {
  return req.get(`${apis.conclusionTemplateChoiceList}`, {
    params
  })
}
// 获取模版详情
const CONCLUSIONTEMPLATEPEDETAIL = (params) => {
  return req.get(`${apis.conclusionTemplateDetail}`, {
    params
  })
}

// 薛 - 总结统计规则
// 获取规则开始时间列表
const RULESTARTLIST = (params) => {
  return req.get(apis.ruleStartList, {
    params
  })
}
// 获取规则结束时间列表
const RULEENdLIST = (params) => {
  return req.get(apis.ruleEndList, {
    params
  })
}

// 总结模版规则列表
const CONCLUSIONRULELIST = (params) => {
  return req.get(apis.conclusionRuleList, {
    params
  })
}
// 总结模版规则列表
const CONCLUSIONRULEADMINLIST = (params) => {
  return req.get(apis.conclusionRuleAdminList, {
    params
  })
}

// 总结规则详情
const CONLUSIONRULEDETAILS = (params) => {
  return req.get(apis.conclusionRuleDetails, {
    params
  })
}

// 查询总结列表（分页）
const ALLSUMMARIZELIST = (params) => {
  return req.get(apis.allSummarizeList, {
    params
  })
}
// 添加总结共享人
const ADDSUMMARIZESHARE = (params) => {
  return req.post(apis.addSummarizeShare, params)
}
// 撤回总结
const UNDOSUMMARIZE = (id) => {
  return req.put(`${apis.undoSummarize}${id}`, {})
}
// 删除总结
const DELSUMMARIZE = (id) => {
  return req.delete(`${apis.delSummarize}${id}`, {})
}
// 查询总结详情
const SUMMARIZEDETAIL = (id) => {
  return req.get(`${apis.summarizeDetail}${id}`, {})
}
// 添加总结
const ADDSUMMARIZE = (params) => {
  return req.post(apis.addSummarize, params)
}
// 添加总结评论
const ADDSUMMARIZECOMMENT = (params) => {
  return req.post(apis.addSummarizeComment, params)
}
// 查询总结评论列表（分页）
const SUMMARIZECOMMENTLIST = (params) => {
  return req.get(apis.summarizeCommentList, {
    params
  })
}
// 查询某人的总结列表
const USERSUMMARIZELIST = (params) => {
  return req.get(apis.userSummarizeList, {
    params
  })
}
// 总结选择任务列表
const CONCLUSIONSUMMARIZETASKLIST = (url, params) => {
  return req.post(`${apis.conclusionSummarizeTaskList}${url}`, params)
}
// 选择模版列表
const SELTEMPLATELIST = (params) => {
  return req.get(apis.selTemplateList, {
    params
  })
}
// 总结新增规则
const ADDCONCLUSIONRULE = (params) => {
  return req.post(apis.addConclusionRule, params)
}
// 总结编辑规则
const EDITCONCLUSIONRULE = (params) => {
  return req.put(apis.addConclusionRule, params)
}
// 总结规则删除
const DELCONCLUSIONRULE = (id, params) => {
  return req.delete(`${apis.delConclusionRule}${id}`, params)
}

// 总结统计编辑回显
const EDITCONCLUSIONRULEINFO = (params) => {
  return req.get(`${apis.editConclusionRule}`, {
    params
  })
}
// 总结统计-根据条件查询统计总结
const CONCLUSIONANALYSELIST = (params) => {
  return req.post(`${apis.conclusionAnalyseList}`, params)
}
// 总结统计-根据条件统计导出总结
const CONCLUSIONEXPORTALL = (params) => {
  return req.post(`${apis.conclusionExportAll}`, params, {
    responseType: ''
  })
}
// 总结统计-根据总结统计列表中勾选数据导出表格
const CONCLUSIONEXPORTSELECT = (params) => {
  return req.post(`${apis.conclusionExportSelect}`, params, {
    responseType: ''
  })
}
// 总结列表 筛选
const CONCLUSIONSCRREN = (params) => {
  return req.get(`${apis.conclusionScreen}`, {
    params
  })
}
// 根据总结id获取模版id
const CONCLUSIONTEMID = (params) => {
  return req.get(`${apis.conclusionAddTemplate}`, {
    params
  })
}
// 删除总结评论
const CONCLUSIONDELETECOMMENT = (id, params) => {
  return req.delete(`${apis.conclusionDeleteComment}${id}`, params)
}
// 评论我的
const COMMENTPAGELISTUSER = (params) => {
  return req.get(apis.commentPageListUser, {
    params
  })
}
// 退回
const SUMMARIZEFALLBACK = (params) => {
  return req.post(apis.summarizeFallback, params)
}
// 总结设置查询
const SUMMARIZESETSELECT = (params) => {
  return req.get(apis.summarizeSetSelect, {
    params
  })
}
// 总结设置保存/修改
const SUMMARIZESETUPDATE = (params) => {
  return req.post(apis.summarizeSetUpdate, params)
}
// 总结统计规则--复制
const SUMMARIZESTATISTICSRULECOPY = (params) => {
  return req.post(apis.summarizeStatisticsRuleCopy, params)
}
// 总结取消点赞
const SUMMARIZELAUDDELETE = (params) => {
  return req.post(apis.summarizeLaudDelete, params)
}
// 总结点赞
const SUMMARIZELAUDSAVE = (params) => {
  return req.post(apis.summarizeLaudSave, params)
}
// 查询总结点赞列表
const SUMMARIZELAUDLIST = (params) => {
  return req.get(apis.summarizeLaudList, {
    params
  })
}
// 总结统计规则--批量发送提醒
const SUMMARIZESTATISTICSRULESENDNOTIFY = (params) => {
  return req.post(apis.summarizeStatisticsRuleSendNotify, params)
}
// 根据模版id,查询总结草稿
const SUMMARIZEINFOTEMPLATE = (params) => {
  return req.get(`${apis.summarizeInfoTemplate}`, {
    params
  })
}
// 查询是否是总结管理员
const SUMMARIZESETSELECTISADMIN = (params) => {
  return req.get(`${apis.summarizeSetSelectIsAdmin}`, {
    params
  })
}
// 我需要提交数量
const SUMMARIZERULETEMPLATECOUNT = (params) => {
  return req.get(`${apis.summarizeRuleTemplateCount}`, {
    params
  })
}
// (new)总结提交规则-根据选中明细导出
const SUBMITRULEINFOIDSEXPORT = (params) => {
  return req.post(`${apis.submitRuleInfoIdsExport}`, params, {
    responseType: $isQw || $isDD ? '' : 'arraybuffer'
  })
}
// (new)总结提交规则-根据选中明细导出
const SUBMITRULEINFOEXPORT = (params) => {
  return req.post(`${apis.submitRuleInfoExport}`, params, {
    responseType: $isQw || $isDD ? '' : 'arraybuffer'
  })
}
// (new)总结提交规则-明细查询
const SUBMITRULEINFOList = (params) => {
  return req.post(apis.submitRuleInfoList, params)
}

// 导出基础请求函数
export default [
  {
  SUBMITRULEINFOList
},
{
  SUBMITRULEINFOEXPORT
},
{
  SUBMITRULEINFOIDSEXPORT
},
{
  SUMMARIZERULETEMPLATECOUNT
},
{
  SUMMARIZESETSELECTISADMIN
},
{
  SUMMARIZEINFOTEMPLATE
},
{
  SUMMARIZESTATISTICSRULESENDNOTIFY
},
{
  SUMMARIZELAUDLIST
},
{
  SUMMARIZELAUDSAVE
},
{
  SUMMARIZELAUDDELETE
},
{
  SUMMARIZESTATISTICSRULECOPY
},
{
  SUMMARIZESETUPDATE
},
{
  SUMMARIZESETSELECT
},
{
  SUMMARIZEFALLBACK
},
{
  COMMENTPAGELISTUSER
},
{
  CONCLUSIONDELETECOMMENT
},

{
  CONCLUSIONTEMID
},
{
  CONCLUSIONSCRREN
},
{
  CONCLUSIONEXPORTSELECT
},
{
  CONCLUSIONEXPORTALL
},
{
  CONCLUSIONANALYSELIST
},
{
  CONCLUSIONSUMMARIZETASKLIST
},
{
  CONCLUSIONMANAGELIST
},
{
  CONCLUSIONADDTEMPLATE
},
{
  CONCLUSIONCOPYTEMPLATE
},
{
  CONCLUSIONDELETETEMPLATE
},
{
  CONCLUSIONTEMPLATEPERCONFIRM
},
{
  CONCLUSIONTEMPLATEPERGET
},
{
  CONCLUSIONTEMPLATEPECHOICELIST
},
{
  CONCLUSIONTEMPLATEPEDETAIL
},
// 薛 - 总结统计规则
{
  EDITCONCLUSIONRULE
},
{
  EDITCONCLUSIONRULEINFO
},
{
  DELCONCLUSIONRULE
},
{
  ADDCONCLUSIONRULE
},
{
  RULEENdLIST
},
{
  SELTEMPLATELIST
},
{
  CONLUSIONRULEDETAILS
},
{
  CONCLUSIONRULEADMINLIST
},
{
  CONCLUSIONRULELIST
},
{
  RULESTARTLIST
},
{
  ALLSUMMARIZELIST
},
{
  ADDSUMMARIZESHARE
},
{
  UNDOSUMMARIZE
},
{
  DELSUMMARIZE
},
{
  SUMMARIZEDETAIL
},
{
  ADDSUMMARIZE
},
{
  ADDSUMMARIZECOMMENT
},
{
  SUMMARIZECOMMENTLIST
},
{
  USERSUMMARIZELIST
}
]
