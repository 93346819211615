var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "comm-dialog-kpi",
      attrs: {
        title: "选择成员",
        visible: _vm.dialogVisible,
        "append-to-body": true,
        "destroy-on-close": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _c("div", { staticClass: "dialog-con" }, [
          _c("div", { staticClass: "invite-friends" }, [
            _c("div", { staticClass: "invite-friends-box" }, [
              _c("div", { staticClass: "left-content" }, [
                !_vm.parentId
                  ? _c(
                      "div",
                      { staticClass: "invite-friends-search" },
                      [
                        _c("Icon", {
                          staticClass: "search-icon",
                          attrs: { "icon-class": "icon-header_search" },
                        }),
                        _c("el-input", {
                          staticClass: "search-input",
                          attrs: { placeholder: "搜索成员" },
                          on: { input: _vm.inputSearch },
                          model: {
                            value: _vm.inputValue,
                            callback: function ($$v) {
                              _vm.inputValue = $$v
                            },
                            expression: "inputValue",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", { staticClass: "invite-friends-title clearfix" }, [
                  _c(
                    "div",
                    {
                      staticClass: "friends-title-font",
                      on: {
                        click: function ($event) {
                          _vm.parentId
                            ? ""
                            : _vm.getInviteFriendsPage("首页", _vm.index)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.companyInfo.name))]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "friends-title-font-text",
                      attrs: { id: "friends-title-font-text" },
                    },
                    _vm._l(_vm.inviteFriendsList, function (item, index) {
                      return _c(
                        "span",
                        {
                          key: item.name,
                          on: {
                            click: function ($event) {
                              return _vm.getInviteFriendsPage("page", index)
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass:
                              "el-icon-arrow-right friends_content_list_name_right_i",
                          }),
                          _c("wx-open-data", {
                            attrs: {
                              id: item.name,
                              type: 2,
                              "name-data": { name: item.name },
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "invite-friends-content",
                    attrs: { id: "invite-friends-content" },
                  },
                  [
                    _vm.inviteFriendsBeans.departs.length > 0 ||
                    _vm.inviteFriendsBeans.userInfos.length > 0
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "scroll-bottom",
                                rawName: "v-scroll-bottom",
                                value: _vm.$loadMoreList,
                                expression: "$loadMoreList",
                              },
                            ],
                            staticClass:
                              "friends-content-list friends-content-list-all",
                          },
                          [
                            _vm._l(
                              _vm.inviteFriendsBeans.departs,
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: item.id,
                                    staticClass:
                                      "friends-content-list-name clearfix",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.getInviteFriendsListSelect(
                                          index,
                                          item.status
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "friends-content-list-name-wrap",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "friends-content-list-name-left depart-info",
                                          },
                                          [
                                            _c("wx-open-data", {
                                              attrs: {
                                                id: item.departmentTitle,
                                                type: 2,
                                                "name-data": {
                                                  name: item.departmentTitle,
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "friends-content-list-name-text",
                                          },
                                          [
                                            _c("wx-open-data", {
                                              attrs: {
                                                id: item.name,
                                                type: 2,
                                                "name-data": {
                                                  name: item.name,
                                                },
                                              },
                                            }),
                                            _vm._v(
                                              " * " + _vm._s(item.userNum) + " "
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    item.status === 0 && _vm.type !== "person"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "friends-content-list-name-button friends-content-list-name-right",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.getInviteFriendsAdd(
                                                  item,
                                                  "添加",
                                                  index,
                                                  "depart"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("添加")]
                                        )
                                      : _vm._e(),
                                    item.status === 1 && _vm.type !== "person"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "friends-content-list-name-button-font friends-content-list-name-right",
                                          },
                                          [_vm._v("已添加")]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              }
                            ),
                            _vm._l(
                              _vm.inviteFriendsBeans.userInfos,
                              function (item) {
                                return _c(
                                  "div",
                                  {
                                    key: item.id,
                                    staticClass:
                                      "friends-content-list-name clearfix",
                                    on: { mouseover: _vm.getMouseOver },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "friends-content-list-name-wrap",
                                      },
                                      [
                                        _c("el-image", {
                                          staticClass:
                                            "friends-content-list-name-left border-radius-20",
                                          attrs: {
                                            fit: "cover",
                                            src:
                                              item.avatar ||
                                              require("@/assets/imgs/common/avatar.png"),
                                          },
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "friends-content-list-name-text",
                                          },
                                          [
                                            _c("wx-open-data", {
                                              attrs: {
                                                id: item.username,
                                                "name-data": {
                                                  name: item.name,
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        item.departName
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "user-depart-name",
                                              },
                                              [
                                                _c("wx-open-data", {
                                                  attrs: {
                                                    id: item.departName,
                                                    type: 2,
                                                    "name-data": {
                                                      name: item.departName,
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    item.status === 0 &&
                                    _vm.page == 1 &&
                                    (_vm.isIncludeMe === 0 ||
                                      (_vm.isIncludeMe === 1 &&
                                        item.id != _vm.userInfo.id)) &&
                                    !_vm.disabledUserId.includes(item.id)
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "friends-content-list-name-button",
                                            on: {
                                              click: function ($event) {
                                                return _vm.getInviteFriendsAdd(
                                                  item,
                                                  "添加"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("添加")]
                                        )
                                      : _vm._e(),
                                    item.status === 1 && _vm.page == 1
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "friends-content-list-name-button-font",
                                          },
                                          [_vm._v("已添加")]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        )
                      : !_vm.pageLoading
                      ? _c("div", [_c("noData")], 1)
                      : _vm._e(),
                  ]
                ),
              ]),
              _c("div", { staticClass: "right-content" }, [
                _c("div", { staticClass: "right-content-title" }, [
                  _vm._v(" 已选： "),
                  _vm.type !== "person" && _vm.type !== "departPerson"
                    ? _c("label", [
                        _vm._v(_vm._s(_vm.memberDepartCount) + "个部门，"),
                      ])
                    : _vm._e(),
                  _vm._v(" " + _vm._s(_vm.memberSumCount) + "个成员 "),
                ]),
                _c("div", { staticClass: "invite-people-list" }, [
                  _c("div", { staticClass: "invite-friends-content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "friends-content-list friends-content-box friends-content-list-part",
                      },
                      _vm._l(_vm.editPeopleList, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: item.userId,
                            staticClass: "friends-content-list-name clearfix",
                            on: { mouseover: _vm.getMouseOver },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "friends-content-list-name-wrap" },
                              [
                                item.departmentTitle
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "friends-content-list-name-left depart-info",
                                      },
                                      [
                                        _c("wx-open-data", {
                                          attrs: {
                                            id: item.departmentTitle,
                                            type: 2,
                                            "name-data": {
                                              name: item.departmentTitle,
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c("el-image", {
                                      staticClass:
                                        "friends-content-list-name-left border-radius-20",
                                      attrs: {
                                        fit: "cover",
                                        src:
                                          item.avatar ||
                                          require("@/assets/imgs/common/avatar.png"),
                                      },
                                    }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "friends-content-list-name-text",
                                  },
                                  [
                                    _c("wx-open-data", {
                                      attrs: {
                                        id: item.name || item.departmentTitle,
                                        type: item.departmentTitle ? 2 : 1,
                                        "name-data": {
                                          name:
                                            item.name || item.departmentTitle,
                                        },
                                      },
                                    }),
                                    item.isAdmin === 1
                                      ? _c("Icon", {
                                          staticClass:
                                            "friends-content-list-name-isAdmin",
                                          attrs: {
                                            "icon-class": "icon-huangguan",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                item.departName
                                  ? _c(
                                      "div",
                                      { staticClass: "user-depart-name" },
                                      [
                                        _c("wx-open-data", {
                                          attrs: {
                                            id: item.departName,
                                            type: 2,
                                            "name-data": {
                                              name: item.departName,
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm.canDelete && item.disabled !== true
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "friends-content-list-name-button-font",
                                    on: {
                                      click: function ($event) {
                                        return _vm.getInviteFriendsAdd(
                                          item,
                                          "移除",
                                          index
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("移除")]
                                )
                              : _vm._e(),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "bottom-step-buttons" }, [
              _c(
                "button",
                { staticClass: "left-button", on: { click: _vm.handleClose } },
                [_vm._v("取消")]
              ),
              _c("button", { on: { click: _vm.save } }, [_vm._v("确定")]),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }