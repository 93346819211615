<template>
  <div class="single_process_content" :class="processShowType === '1' ? 'no_hover' : ''">
    <div class="process_top_info">
      <div class="process_kr_name" v-html="processInfo.krName"></div>
      <div v-if="processInfo.quantificationType !== 1" class="process_num">
        <span class="process_old_num">
          {{
          processInfo.originalQuantificationActualValue === null
          ? 0
          : processInfo.originalQuantificationActualValue
          }}{{ processInfo.quantificationUnit }} -
        </span>
        <span
          class="process_changed_num"
          :class="
            processInfo.type == '2'
              ? 'down'
              : processInfo.type == '3'
              ? 'nochange'
              : ''
          "
        >
          {{ processInfo.quantificationActualValue
          }}{{ processInfo.quantificationUnit }}
        </span>
      </div>
      <div v-if="processInfo.quantificationType !== 1">
        <Icon
          v-if="
            Number(processInfo.originalQuantificationActualValue) <
            Number(processInfo.quantificationActualValue)
          "
          icon-class="icon-arrow-up-line"
          class="process_icon up-icon"
        />
        <Icon
          v-if="
            Number(processInfo.originalQuantificationActualValue) >
            Number(processInfo.quantificationActualValue)
          "
          icon-class="icon-arroe-down-line"
          class="process_icon down-icon"
        />
      </div>
      <div v-if="processInfo.quantificationType === 1" class="process-status">
        <div v-if="processInfo.quantificationStatus === 0" class="unselect-label">
          <Icon icon-class="icon-not-finish" class="select-icon" />未完成
        </div>
        <div v-if="processInfo.quantificationStatus === 1">
          <Icon icon-class="icon-finished" class="select-icon" />已完成
        </div>
      </div>
      <div class="process_change_time">{{ processInfo.createDate }}</div>
    </div>
    <div v-if="processInfo.explain !== ''" class="process_title">
      <div class="process_title_icon_div">
        <Icon icon-class="icon-process" class="process_title_icon" />
      </div>
      <div v-html="processInfo.explain">{{ processInfo.explain }}</div>
    </div>
    <div class="process_comment_list">
      <trend-item
        v-for="item in processInfo.commentList"
        :key="item.id"
        class="trend_item"
        :trend-info="item"
        :only-comment="true"
        @refreshTrend="refreshComment"
      />
    </div>
    <div class="process_reply">
      <transition name="fade" mode="out-in">
        <div v-if="!replyView" class="process_btn_reply" @click="showReplyBox">回复...</div>
        <!-- </transition>
        <transition name="fade" mode="out-in">-->
        <div v-if="replyView" class="process_input_reply">
          <comment-box
            ref="commentBox"
            :at-able="false"
            height="auto"
            placeholder="填写对进度的评论  ↵"
            :blur-release="true"
            :file-upload-disabled="true"
            :lineFeed="true"
            @hideComment="hideComment"
            @release="addProcessComment"
          />
        </div>
      </transition>
      <!-- <div v-if="processShowType==='2'&&replyView">
                <list-comment-box ref="commentInput" :limit="500" :atAble="false" :placeholder="processPlaceholder" @postInputData="addProcessComment"></list-comment-box>
      </div>-->
    </div>
  </div>
</template>
<script>
import commentBox from '@/components/common/CommentBox.vue' // 评论框
import trendItem from './TrendItem' // 评论展示
export default {
  components: {
    commentBox,
    trendItem
  },
  props: {
    processInfo: {
      type: Object,
      default: () => {
        return {
          status: 0, // 进度类型 0.上升1.下降2.不变
          oldNum: '', // 原进度
          newNum: '', // 新进度
          commentList: [] // 评论列表
        }
      }
    },
    processShowType: {
      type: String, // 1.详情2.列表进度
      default: '1'
    }
  },

  data() {
    return {
      replyView: false, // 是否显示回复框
      processPlaceholder: '+ 填写对进度的评论 ↵'
    }
  },
  computed: {},
  methods: {
    refreshComment() {
      this.$emit('refreshProcess')
    },
    // 显示回复框
    showReplyBox() {
      this.replyView = true
    },
    // 发布回复
    async addProcessComment(comment, userId, fileList) {
      if (comment === '' || (comment.trim() === '' && fileList.length === 0)) {
        this.hideComment()
        return
      }

      const dataBean = {
        progressId: this.processInfo.id,
        type: 0,
        comment: comment,
        ossFileEntityList: fileList && fileList.length > 0 ? fileList : null
      }
      console.log('object', dataBean)
      this.$apis
        .OKRADDPROCESSCOMMENT(dataBean)
        .then((rest) => {
          // this.$message({
          //   msg: '发布成功',
          //   type: 'success'
          // })
          this.bus(this).$emit('inquireStatus', {
            type: 1,
            name: 'OKR'
          })
          this.$emit('refreshProcess')
          this.hideComment()
          this.bus.$emit('clearFile')
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 收起回复框
    hideComment() {
      this.$nextTick(() => {
        this.$refs.commentBox.clearComment()
      })
      // if(this.processShowType === '1') {

      // }
      // if(this.processShowType === '2') {
      //   this.$refs.commentInput.clearData();
      // }
      this.replyView = false
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .el-textarea,
  .el-textarea__inner {
    resize: none !important;
  }
}
.single_process_content {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 8px 16px;
  &:hover {
    background: #fff;
    .process_reply {
      .process_btn_reply {
        display: block;
      }
    }
  }
  .process_top_info {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    width: 100%;
    .process_kr_name {
      flex-shrink: 0;
      width: 40px;
      line-height: 24px;
      margin-right: 4px;
      font-size: 14px;
      white-space: nowrap;
      text-align: left;
      color: #a0a3bd;
    }
    .process_num {
      line-height: 24px;
      .process_old_num {
        color: #444558;
        font-size: 14px;
      }
      .process_changed_num {
        color: #ed2e7e;
        font-size: 18px;
      }
      .down {
        color: #00ba88;
      }
      .nochange {
        color: #444558;
      }
    }
    .process_icon {
      font-size: 18px;
      width: 18px;
      height: 18px;
    }
    .up-icon {
      color: #ed2e7e;
    }
    .down-icon {
      color: #00ba88;
    }
    .process_change_time {
      flex: 1;
      font-size: 12px;
      line-height: 24px;
      text-align: right;
      color: #a0a3bd;
    }
    .process-status {
      display: flex;
      align-items: center;
      .unselect-label {
        display: flex;
        flex-direction: row;
      }
      .select-icon {
        float: left;
        font-size: 18px;
        width: 18px;
        height: 18px;
        margin-right: 4px;
      }
    }
  }
  .process_title {
    color: #6e7491;
    line-height: 24px;
    margin-bottom: 8px;
    word-break: break-word;
    display: flex;
    .process_title_icon_div {
      width: 40px;
      height: 20px;
      padding-left: 4px;
      display: flex;
      justify-content: flex-start;
      margin-right: 4px;
    }
    .process_title_icon {
      font-size: 20px;
      color: #a0a3bd;
      width: 20px;
      height: 20px;
      float: right;
    }
  }
  .process_comment_list {
    .trend_item {
      margin-bottom: 16px;
    }
    ::v-deep .trend-single-box {
      padding: 0 !important;
    }
  }
  .process_reply {
    min-height: 20px;
    margin-top: 4px;
    .process_btn_reply {
      color: #3a78f6;
      cursor: pointer;
      font-weight: 500;
      margin-left: 44px;
      display: none;
    }
    .process_input_reply {
      width: 100%;
      border-radius: 8px;
      background: #fff;
      border: 1px solid #3a78f6;
      box-shadow: 0px -3px 10px 0px rgba(50, 150, 250, 0.14) inset;
    }
  }

  ::v-deep .trend-single-box {
    &:hover {
      background: #fff;
    }
  }
}
.no_hover {
  &:hover {
    background: #f5f6fa;
  }
  ::v-deep .trend-single-box {
    &:hover {
      background: #f5f6fa !important;
    }
  }
}
</style>
