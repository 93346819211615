<template>
  <el-dialog
    title="转让目标"
    :visible.sync="dialogVisible"
    width="520px"
    :append-to-body="true"
    :before-close="handleClose"
    class="comm-check-form"
  >
    <div class="dialog-content">
      <div class="form-title">
        <span>转让接受人</span>
        <div class="dialog-content-input">
          <user-picker @pick="toriginatorPicker">
            <div class="move-over-name">
              <span class="move-over-name-span" :title="moveOverData.username">
                <wx-open-data
                  :id="moveOverData.username"
                  :nameData="{name:moveOverData.name}"
                  v-if="moveOverData.username"
                />
                <span v-else class="gray">请选择</span>
              </span>
              <Icon icon-class="icon-xiangyou" class="aub-add-icon" />
            </div>
          </user-picker>
        </div>
      </div>
      <p class="info">转让内容将包含目标, KR, 以及KR任务等内容, 请谨慎操作</p>
      <div class="bottom-step-buttons">
        <button @click="handleClose" class="left-button">取消</button>
        <button @click="save">确定</button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { mapState } from 'vuex'
export default {
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  props: {
    dialogShow: {
      type: Boolean,
      default: true
    },
    data: {
      type: Object
    }
  },
  watch: {
    dialogShow: {
      handler(val) {
        this.dialogVisible = val
      }
    }
  },
  data() {
    return {
      dialogVisible: this.dialogShow,
      hasTask: false,
      moveOverData: {}
    }
  },
  computed: {
    ...mapState('user', {
      userInfo: 'userInfo', // 用户信息
      versionNumber: 'versionNumber' // 版本控制
    })
  },
  created() {
    // 监听审批提交
    this.bus(this).$on('approveRemindRefresh', (data) => {
      this.handleClose()
    })
  },
  methods: {
    handleClose() {
      this.dialogVisible = false
      this.$emit('change', false)
    },
    toriginatorPicker(row) {
      const form = {
        originatorId: row.id
      }
      this.moveOverData = row
    },
    async save() {
      var obj = {
        id: this.data.id,
        chargeUserId: this.moveOverData.id
      }
      if (this.data.objectivesProcessDataDto && this.data.objectivesProcessDataDto.businessType === 1) {
        this.$message.warn('审批中的目标无法移动哦！')
        return
      }
      if (this.data.processState === 1 && this.$store.state.user.approvalCheckData.onOff === 0 && this.data.objectivesProcessDataDto.businessType !== 1 && this.data.objectivesProcessDataDto.businessApprovalPassHistory === 1) {
        this.$store.commit('okr/approveRemindObj', {
          visible: true,
          objDetail: {
            id: this.data.id,
            ...this.data
          },
          businessData: JSON.stringify(obj),
          businessActionType: 6
        })
      } else {
        this.$apis
          .OKRTRANSFER(obj)
          .then((res) => {
            if (res.status == 200) {
              this.$message({
                msg: res.message,
                type: 'success'
              })
              this.dialogVisible = false
              this.$emit('refreshData')
            } else {
              this.$message({
                msg: res.message,
                type: 'error'
              })
            }
          })
      }
    }
  },
  components: {}
}
</script>
<style lang="less" scoped>
@import '../../../../assets/styles/dialog.less';
.dialog-content {
  padding-bottom: 8px;
  padding-left: 24px;

  .form-title {
    font-size: 16px;
    color: #444558;
    margin-top: 24px;
    font-weight: 500;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
  }
  .dialog-content-input {
    display: inline-block;
    width: 300px;
    .move-over-name {
      position: relative;
      width: 120px;
      height: 35px;
      line-height: 35px;
      margin-left: 10px;
      padding-left: 20px;
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #f5f6fa;
      color: #14142b;
      cursor: pointer;
      .move-over-name-span {
        display: inline-block;
        width: 75px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .gray {
          color: #a0a3bd;
        }
      }
      .aub-add-icon {
        width: 10px;
        height: 10px;
        display: inline-block;
        position: absolute;
        right: 20px;
      }
    }
  }

  .info {
    font-size: 12px;
    color: #ff9900;
    font-weight: 300;
    line-height: 18px;
  }
  .bottom-step-buttons {
    margin-top: 100px;
  }
}
</style>
