// admin模块
// 统一管理所有的基础请求
import apis from '../apis_moudles/index'
import req from '../request'

// 获取公司版本
const ADMINGETCOMPANYVERSION = (params) => {
  return req.get(apis.adminGetCompanyVersion, {
    params
  })
}
// 满意度字典数据
const SATISFACTIONDICTIONARY = (params) => {
  return req.get(`${apis.satisfactionDictionary}`, {
    params
  })
}
// 版本升级提交
const ADMINGETCOMPANYSAVE = (params) => {
  return req.post(apis.adminGetVersionSave, params)
}
// 企业信息修改提交
const ADMINGETCOMPANYUPDATEINFO = (params) => {
  return req.post(apis.adminCompanyUpdateInfo, params)
}
// 行业信息一级
const ADMINDICTIONARYLISTONE = (id, params) => {
  return req.get(`${apis.adminDictionaryListOne}${id}`, {
    params
  })
}
// 行业信息二级
const ADMINDICTIONARYLISTTWO = (params) => {
  return req.get(`${apis.adminDictionaryListTwo}`, {
    params
  })
}
// 企业规模人数
const ADMINDICTIONARYUSERCOUNT = (params) => {
  return req.get(`${apis.adminDictionaryUserCount}`, {
    params
  })
}
// 企业logo图片上传
const ADMINGETCOMPANYUPDATELOGO = (params) => {
  return req.post(`${apis.adminCompanyUpdateLogo}`, params)
}
// 周期停用
const ADMINSETUPCYCLE = (params) => {
  return req.post(apis.adminSetUpCycle, params)
}
// 周期删除
const ADMINCYCLEDELETE = (params) => {
  return req.post(apis.adminCycleDelete, params)
}
// 获取周期列表
const ADMINGETCYCLELIST = (params) => {
  return req.get(apis.adminGetCycleList, {
    params
  })
}
// 新建周期
const ADMINADDCYCLE = (params) => {
  return req.post(apis.adminAddCycle, params)
}
// 提交引导步骤
const COMMITPROCESS = (params) => {
  return req.post(apis.commitProcess, params)
}
// 获取订单列表
const ADMINGETAORDERLIST = (params) => {
  return req.get(apis.adminGetOrderList, {
    params
  })
}
// 获取管理员列表
const ADMINGETADMINLIST = (params) => {
  return req.get(apis.adminGetAdminList, {
    params
  })
}
// 获取成员列表ById
const ADMINGETMEMLISTBYID = (id, params) => {
  return req.get(`${apis.adminGetMemListById}${id}`, {
    params
  })
}
// 获取成员列表左侧
const ADMINGETMEMLISTLEFT = (params) => {
  return req.get(apis.adminGetMemListLeft, {
    params
  })
}
// 获取所有成员
const ADMINGETMEMLISTALL = (params) => {
  return req.get(apis.adminGetMemListAll, {
    params
  })
}
// 获取待申请加入成员
const ADMINGETAPPROVELIST = (params) => {
  return req.get(apis.adminGetApprovelist, {
    params
  })
}
// 获取所有成员
const ADMINGETSEARCHINFOBYID = (id, params) => {
  return req.get(`${apis.adminGetSearchInfoById}${id}`, {
    params
  })
}
// 人员同意拒绝加入
const ADMINSETINVITEAPPROVE = (params) => {
  return req.post(apis.adminSetInviteApprove, params)
}
// 获取子部门
const SUBDEPARTBYID = (id, params) => {
  return req.get(`${apis.subDepartById}${id}`, {
    params
  })
}
// 删除管理员
const DELADMIN = (params) => {
  return req.post(apis.delAdmin, params)
}
// 获取部门人员个数
const GETDEPARTNUM = (id, params) => {
  return req.get(`${apis.getDepartNum}${id}`, {
    params
  })
}
// 人员更新部门
const DEPARTMOVEUSER = (params) => {
  return req.post(apis.departMoveUser, params)
}
// 删除部门
const DELDEPART = (id, params) => {
  return req.post(`${apis.delDepart}${id}`, params)
}
// 用户停用
const COMPANYUSERDISABLE = (params) => {
  return req.post(`${apis.companyUserDisable}`, params)
}
// 删除部分成员
const DEPARTREMOVEUSER = (params) => {
  return req.post(`${apis.departRemoveUser}`, params)
}
// 删除人员转移权限
const COMPANYDIMISSINON = (params) => {
  return req.post(`${apis.companyDimission}`, params)
}
// 新增部门
const ADDDEPART = (params) => {
  return req.post(`${apis.addDepart}`, params)
}
// 新增部门
const EDITDEPART = (params) => {
  return req.post(`${apis.editDepart}`, params)
}
// 修改部门主管
const DEPARTUPDATEADMIN = (params) => {
  return req.post(`${apis.departUpdateAdmin}`, params)
}
// 邀请设置
const COMPANYINVITESET = (params) => {
  return req.get(`${apis.companyInviteSet}`, {
    params
  })
}
// 邀请设置提交
const COMPANYINVITEADD = (params) => {
  return req.post(`${apis.companyInviteAdd}`, params)
}
// 获取全部部门层级
const DEPARTALL = (params) => {
  return req.get(`${apis.departAll}`, {
    params
  })
}
// 获取全部部门层级用户
const DEPARTALLUSER = (params) => {
  return req.get(`${apis.departAllUser}`, {
    params
  })
}
// 搜索部门byid
const DEPARTBYID = (id, params) => {
  return req.get(`${apis.departById}${id}`, {
    params
  })
}
// 退出企业
const COMPANYQUIT = (params) => {
  return req.post(`${apis.companyQuit}`, params)
}
// 移交企业所属人
const TRANSFEROWNER = (params) => {
  return req.post(`${apis.transferOwner}`, params)
}
// 删除企业
const DELCOMPANY = (id, params) => {
  return req.put(`${apis.delCompany}${id}`, params)
}
// 获取部门和人（更改上级时使用）
const DEPARTUSER = (params) => {
  return req.get(`${apis.departUser}`, {
    params
  })
}
// 更新上级
const UPDATESUPERIOR = (params) => {
  return req.post(`${apis.updateSuperior}`, params)
}
// 改变设置公共方法
const NOTIFYSET = (params) => {
  return req.post(`${apis.notifySet}`, params)
}
// 获取通知类型列表
const NOTIFYSETLIST = (params) => {
  return req.get(`${apis.notifySetList}`, {
    params
  })
}
// 查询周期可见范围
const GETCYLCERANGE = (params) => {
  return req.get(`${apis.getCycleRange}`, {
    params
  })
}
// 设置周期可见范围
const SETCYLCERANGE = (params) => {
  return req.put(`${apis.setCycleRange}`, params)
}
// 设置周期成员
const SETCYLCEPERSON = (params) => {
  return req.put(`${apis.setCyclePerson}`, params)
}
// 设置周期成员
const SETCYLCEPERSONPERMISSION = (params) => {
  return req.put(`${apis.getCyclePersonPermission}`, params)
}
// 查询周期提醒设置列表
const CYCLESETREMINDLIST = (params) => {
  return req.get(`${apis.cycleSetRemindList}`, {
    params
  })
}
// 周期提醒设置
const CYCLESETREMIND = (params) => {
  return req.post(`${apis.cycleSetRemind}`, params)
}
// 获取角色列表
const ALLROLELIST = (params) => {
  return req.get(`${apis.allRoleList}`, {
    params
  })
}
// 新增角色
const ROLEADDITEM = (params) => {
  return req.post(`${apis.roleAddItem}`, params)
}
// 修改角色
const ROLEEDITITEM = (params) => {
  return req.post(`${apis.roleEditItem}`, params)
}
// 删除角色
const ROLEDELITEM = (id, params) => {
  return req.post(`${apis.roleDelItem}${id}`, params)
}
// 批量删除角色
const ROLEBATCHDEL = (params) => {
  return req.post(`${apis.roleBatchDel}`, params)
}
// 修改角色下成员列表
const ROLEUPDATEMEMBER = (params) => {
  return req.post(`${apis.roleUpdateMember}`, params)
}
// 查询角色下成员列表
const ROLEMEMBERLIST = (params) => {
  return req.get(`${apis.roleMemberList}`, {
    params
  })
}
// 获取当前企业优先级配置
const PRIORITYLIST = (params) => {
  return req.get(`${apis.priorityList}`, {
    params
  })
}
// 优先级保存
const SAVEPRIORITY = (params) => {
  return req.post(`${apis.savePriority}`, params)
}
// 查看优先级列表任务总数
const TASKCOUNT = (params) => {
  return req.post(`${apis.taskcount}`, params)
}

// 获取标签列表
const LABELLIST = (params) => {
  return req.get(`${apis.labelList}`, {
    params
  })
}
// 新建标签分组
const ADDLABELGROUP = (params) => {
  return req.post(`${apis.addLabelGroup}`, params)
}
// 修改标签分组
const CHANGELABELGROUP = (params) => {
  return req.put(`${apis.changeLabelGroup}`, params)
}

// 删除标签分组
const DELETELABELGROUP = (id, params) => {
  return req.delete(`${apis.deleteLabelGroup}${id}`, params)
}

// 删除标签
const DELETELABEL = (id, params) => {
  return req.delete(`${apis.deleteLabel}${id}`, params)
}

// 新建标签
const ADDLABEL = (params) => {
  return req.post(`${apis.addLabel}`, params)
}

// 标签详情
const LABELINFO = (id, params) => {
  return req.get(`${apis.labelInfo}${id}`, {
    params
  })
}

// 修改标签
const CHANGELABEL = (params) => {
  return req.put(`${apis.changeLabel}`, params)
}

// 查询任务分类列表-企业
const CATEGORIESCOMPANY = (params) => {
  return req.get(`${apis.categoriesCompany}`, {
    params
  })
}
// 查询任务分类列表-项目
const CATEGORIESPROJECT = (params) => {
  return req.get(`${apis.categoriesProject}`, {
    params
  })
}
// 查询分类字段详情
const CATEGORIESDETAIL = (params) => {
  return req.get(`${apis.categoriesDetail}`, {
    params
  })
}
// 修改分类
const EDITCATEGORIES = (id, params) => {
  return req.put(`${apis.editCategories}${id}`, params)
}
// 任务字段分类编辑
const EDITPROJECTCATEGORIES = (params) => {
  return req.put(`${apis.editProjectCategories}`, params)
}
// 分类详情字段保存
const SAVECATEGORIESDETAIL = (params) => {
  return req.post(`${apis.saveCategoriesDetail}`, params)
}
// 查询字段分组列表
const FIELDGROUPS = (params) => {
  return req.get(`${apis.fieldGroups}`, {
    params
  })
}
// 自定义字段搜索
const FIELDSEARCHLIST = (params) => {
  return req.get(`${apis.fieldSearchList}`, {
    params
  })
}
// 字段分类列表（含字段）
const FIELDGROUPTREE = (params) => {
  return req.get(`${apis.fieldGroupTree}`, {
    params
  })
}
// 新建分组
const ADDFIELDGROUP = (params) => {
  return req.post(`${apis.addFieldGroup}`, params)
}
// 修改分组
const UPDATEFIELDGROUP = (params) => {
  return req.put(`${apis.updateFieldGroup}`, params)
}
// 删除分组
const DELFIELDGROUP = (id, params) => {
  return req.delete(`${apis.delFieldGroup}${id}`, params)
}
// 删除自定义字段
const DELCUSTOMFIELD = (id, params) => {
  return req.delete(`${apis.delCustomField}${id}`, params)
}
// 添加自定义字段
const ADDCUSTOMFIELD = (params) => {
  return req.post(`${apis.addCustomField}`, params)
}
// 修改自定义字段
const UPDATECUSTOMFIELD = (params) => {
  return req.put(`${apis.updateCustomField}`, params)
}
// 查询字段使用情况-删除前调用
const CUSTOMFIELDUSED = (params) => {
  return req.get(`${apis.customFieldUsed}`, {
    params
  })
}
// 查询企业日历信息
const GETCOMPANYCALENDAR = (params) => {
  return req.get(`${apis.getCompanyCalendar}`, {
    params
  })
}
// 保存企业日历信息
const EDITCOMPANYCALENDAR = (params) => {
  return req.post(`${apis.getCompanyCalendar}/`, params)
}
// 查询okr进度占比配置
const QUERYPROCESSCONFIG = (params) => {
  return req.get(`${apis.queryProcessConfig}`, {
    params
  })
}
// 保存okr进度占比配置
const UPDATEPROCESSCONFIG = (params) => {
  return req.post(`${apis.queryProcessConfig}`, params)
}
// 周期阶段设置列表查询
const QUERYCYCLESTAGE = (params) => {
  return req.get(`${apis.queryCycleStage}`, {
    params
  })
}
// 周期阶段设置
const CYCLESTAGESET = (params) => {
  return req.post(`${apis.cycleStageSet}`, params)
}
// 周期评分设置
const CYCLESCORESET = (params) => {
  return req.put(`${apis.cycleScoreSet}`, params)
}
// 同级部门排序
const DEPARTSORTORDER = (params) => {
  return req.post(`${apis.departSortOrder}`, params)
}
// 查询手机号是否已在公司
const QUERYUSEREXIST = (params) => {
  return req.get(`${apis.queryUserExist}`, {
    params
  })
}
// 查询周期提醒设置列表
const QUERYSETTINGSTATEINFO = (params) => {
  return req.get(`${apis.querySettingStateInfo}`, {
    params
  })
}
// 周期提醒设置状态修改
const QUERYSETCYCLESTATE = (params) => {
  return req.post(`${apis.querySetCycleState}`, params)
}

// 企业微信/钉钉资源转移
const TRANSFERRESOURCE = (params) => {
  return req.post(`${apis.transferResource}`, params)
}
const ADMINDEPARTTREELIST = (params) => {
  return req.get(`${apis.adminDepartTreeList}`, {
    params
  })
}
// 同步组织
const SYNCHCOMPANYINFO = (params) => {
  return req.post(`${apis.synchCompanyInfo}`, params)
}
// 同步组织
const SYNCHALLCOMPANYINFO = (params) => {
  return req.post(`${apis.synchAllCompanyInfo}`, params)
}
// 添加满意度
const SATISFACTIONSAVE = (params) => {
  return req.post(`${apis.satisfactionSave}`, params)
}
// 同步任务设置更新
const APPROVALSETTINGUPDATE = (params) => {
  return req.post(`${apis.approvalSettingUpdate}`, params)
}
// 同步任务设置更新
const APPROVALSETTINGBUSINESSTYPE = (params) => {
  return req.get(`${apis.approvalSettingBusinessType}`, {
    params
  })
}
// 同步任务设置更新企微
const APPROVALSETTINGUPDATEQW = (params) => {
  return req.post(`${apis.approvalSettingUpdateQw}`, params)
}
// 同步任务设置更新企微
const APPROVALSETTINGBUSINESSTYPEQW = (params) => {
  return req.get(`${apis.approvalSettingBusinessTypeQw}`, {
    params
  })
}
// 修改周期名字
const SETCYCLENAME = (params) => {
  return req.post(`${apis.setcycleName}`, params)
}
// 钉钉提交授权状态
const DDAUTHSTATUS = (params) => {
  return req.post(`${apis.ddAuthStatus}`, params)
}
// 导出基础请求函数
export default [
  {
  DDAUTHSTATUS
},
{
  SETCYCLENAME
},
{
  APPROVALSETTINGBUSINESSTYPEQW
},
{
  APPROVALSETTINGUPDATEQW
},
{
  APPROVALSETTINGBUSINESSTYPE
},
{
  APPROVALSETTINGUPDATE
},
{
  SYNCHCOMPANYINFO
},
{
  SYNCHALLCOMPANYINFO
},
{
  ADMINDEPARTTREELIST
},
{
  SATISFACTIONDICTIONARY
},
{
  SATISFACTIONSAVE
},
{
  TRANSFERRESOURCE
},
{
  QUERYSETCYCLESTATE
},
{
  QUERYSETTINGSTATEINFO
},
{
  QUERYUSEREXIST
},
{
  DEPARTSORTORDER
},
{
  QUERYCYCLESTAGE
},
{
  CYCLESTAGESET
},
{
  CYCLESCORESET
},
{
  QUERYPROCESSCONFIG
},
{
  UPDATEPROCESSCONFIG
},
{
  EDITPROJECTCATEGORIES
},
{
  EDITCOMPANYCALENDAR
},
{
  GETCOMPANYCALENDAR
},
{
  CUSTOMFIELDUSED
},
{
  DELFIELDGROUP
},

{
  UPDATECUSTOMFIELD
},
{
  ADDCUSTOMFIELD
},
{
  DELCUSTOMFIELD
},
{
  UPDATEFIELDGROUP
},
{
  ADDFIELDGROUP
},
{
  FIELDGROUPTREE
},
{
  FIELDSEARCHLIST
},
{
  FIELDGROUPS
},
{
  CHANGELABEL
},
{
  LABELINFO
},
{
  ADDLABEL
},
{
  DELETELABEL
},
{
  DELETELABELGROUP
},
{
  CHANGELABELGROUP
},
{
  ADDLABELGROUP
},
{
  LABELLIST
},
{
  TASKCOUNT
},
{
  SAVEPRIORITY
},
{
  PRIORITYLIST
},
{
  SETCYLCEPERSONPERMISSION
},
{
  SETCYLCEPERSON
},
{
  SETCYLCERANGE
},
{
  GETCYLCERANGE
},
{
  COMPANYQUIT
},
{
  DEPARTBYID
},
{
  ADMINGETCOMPANYVERSION
},
{
  ADMINGETCOMPANYSAVE
},
{
  ADMINGETCOMPANYUPDATEINFO
},
{
  ADMINDICTIONARYLISTONE
},
{
  ADMINDICTIONARYLISTTWO
},
{
  ADMINDICTIONARYUSERCOUNT
},
{
  ADMINGETCOMPANYUPDATELOGO
},
{
  ADMINSETUPCYCLE
},
{
  ADMINCYCLEDELETE
},
{
  ADMINGETCYCLELIST
},
{
  ADMINADDCYCLE
},
{
  ADMINGETAORDERLIST
},
{
  ADMINGETADMINLIST
},
{
  ADMINGETMEMLISTBYID
},
{
  ADMINGETMEMLISTLEFT
},
{
  ADMINGETMEMLISTALL
},
{
  ADMINGETAPPROVELIST
},
{
  ADMINGETSEARCHINFOBYID
},
{
  ADMINSETINVITEAPPROVE
},
{
  SUBDEPARTBYID
},
{
  DELADMIN
},
{
  GETDEPARTNUM
},
{
  DEPARTMOVEUSER
},
{
  DELDEPART
},
{
  COMPANYUSERDISABLE
},
{
  DEPARTREMOVEUSER
},
{
  COMPANYDIMISSINON
},
{
  ADDDEPART
},
{
  EDITDEPART
},
{
  DEPARTUPDATEADMIN
},
{
  COMPANYINVITESET
},
{
  COMPANYINVITEADD
},
{
  DEPARTALL
},
{
  DEPARTALLUSER
},
{
  TRANSFEROWNER
},
{
  DELCOMPANY
},
{
  DEPARTUSER
},
{
  UPDATESUPERIOR
},
{
  NOTIFYSET
},
{
  NOTIFYSETLIST
},
{
  COMMITPROCESS
},
{
  CYCLESETREMINDLIST
},
{
  CYCLESETREMIND
},
{
  ALLROLELIST
},
{
  ROLEADDITEM
},
{
  ROLEEDITITEM
},
{
  ROLEDELITEM
},
{
  ROLEBATCHDEL
},
{
  ROLEUPDATEMEMBER
},
{
  ROLEMEMBERLIST
},
{
  CATEGORIESCOMPANY
},
{
  CATEGORIESPROJECT
},
{
  CATEGORIESDETAIL
},
{
  EDITCATEGORIES
},
{
  SAVECATEGORIESDETAIL
}
]
