var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "align-user-box flex" }, [
    _c("div", { staticClass: "align-box left-box" }, [
      _c("div", { staticClass: "align-title" }, [
        _vm._v(_vm._s(_vm.unreadList.length) + "人未读"),
      ]),
      _c("div", { staticClass: "align-user-cont" }, [
        _c(
          "ul",
          { staticClass: "user-list" },
          _vm._l(_vm.unreadList, function (item) {
            return _c(
              "li",
              {
                key:
                  _vm.type === "conclusion"
                    ? item.receiverUser.name
                    : item.name,
                staticClass: "user-item flex-a-c-center f-z-14 rel",
              },
              [
                _c("el-image", {
                  staticClass: "avatar-img border-radius-20",
                  attrs: {
                    fit: "cover",
                    src:
                      _vm.type === "conclusion"
                        ? item.receiverUser.avatar ||
                          require("@/assets/imgs/common/avatar.png")
                        : item.avatar ||
                          require("@/assets/imgs/common/avatar.png"),
                  },
                }),
                _c(
                  "div",
                  { staticClass: "user-name" },
                  [
                    _vm.type === "conclusion"
                      ? _c("wx-open-data", {
                          attrs: {
                            id: item.receiverUser.name,
                            nameData: { name: item.receiverUser.name },
                          },
                        })
                      : _c("wx-open-data", {
                          attrs: {
                            id: item.name,
                            nameData: { name: item.name },
                          },
                        }),
                  ],
                  1
                ),
                _c(
                  "el-tooltip",
                  { attrs: { "open-delay": 300, content: "Ta还未读" } },
                  [
                    _c("div", {
                      staticClass: "dot abs",
                      staticStyle: { display: "none" },
                    }),
                  ]
                ),
                item.receiverUserRole === 2
                  ? _c("Icon", {
                      staticClass: "share-icon",
                      attrs: { "icon-class": "icon-fenxiang" },
                    })
                  : _vm._e(),
              ],
              1
            )
          }),
          0
        ),
      ]),
    ]),
    _c("div", { staticClass: "align-box right-box" }, [
      _c("div", { staticClass: "align-title" }, [
        _vm._v(_vm._s(_vm.readList.length) + "人已读"),
      ]),
      _c("div", { staticClass: "align-user-cont" }, [
        _c(
          "ul",
          { staticClass: "user-list" },
          _vm._l(_vm.readList, function (item) {
            return _c(
              "li",
              {
                key:
                  _vm.type === "conclusion"
                    ? item.receiverUser.name
                    : item.name,
                staticClass: "user-item flex-a-c-center f-z-14 rel",
              },
              [
                _c("el-image", {
                  staticClass: "avatar-img border-radius-20",
                  attrs: {
                    fit: "cover",
                    src:
                      _vm.type === "conclusion"
                        ? item.receiverUser.avatar ||
                          require("@/assets/imgs/common/avatar.png")
                        : item.avatar ||
                          require("@/assets/imgs/common/avatar.png"),
                  },
                }),
                _c(
                  "div",
                  { staticClass: "user-name" },
                  [
                    _vm.type === "conclusion"
                      ? _c("wx-open-data", {
                          attrs: {
                            id: item.receiverUser.name,
                            nameData: { name: item.receiverUser.name },
                          },
                        })
                      : _c("wx-open-data", {
                          attrs: {
                            id: item.name,
                            nameData: { name: item.name },
                          },
                        }),
                  ],
                  1
                ),
                item.receiverUserRole === 2
                  ? _c("Icon", {
                      staticClass: "share-icon",
                      attrs: { "icon-class": "icon-fenxiang" },
                    })
                  : _vm._e(),
              ],
              1
            )
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }