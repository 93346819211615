var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "custom-range-dialog",
      attrs: {
        title: "自定义可见范围",
        visible: _vm.dialogVisible,
        width: "520px",
        "append-to-body": true,
        "before-close": _vm.handleClose,
        model: false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "range-content",
          on: {
            click: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "type-title",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _vm._v(" 指定可见部门 "),
              _c(
                "departTree",
                {
                  attrs: {
                    checkedKeys: _vm.departIdList,
                    expandedKeys: _vm.departIdList,
                    type: 0,
                    slotType: 1,
                  },
                  on: {
                    getPart: _vm.getPart,
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _c("Icon", {
                    staticClass: "add-icon",
                    attrs: { slot: "slot", "icon-class": "icon-a-tianjia2" },
                    slot: "slot",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.departList.length > 0
            ? _c(
                "div",
                { staticClass: "select-list-wrap" },
                _vm._l(_vm.departList, function (user, userIndex) {
                  return _c("avatar-box", {
                    key: user,
                    attrs: { avatarInfo: user, type: "2" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                      deleteAvatar: function ($event) {
                        return _vm.deleteAvatar(user, 1, userIndex)
                      },
                    },
                  })
                }),
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "type-title",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _vm._v(" 指定可见人 "),
              _c(
                "user-picker-checked",
                {
                  ref: "teamPerson",
                  attrs: {
                    "select-people-list": _vm.peopleList,
                    "page-type": "taskSummary",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                    pick: _vm.toriginatorPicker,
                  },
                },
                [
                  _c("Icon", {
                    staticClass: "add-icon",
                    attrs: { "icon-class": "icon-a-tianjia2" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.peopleList.length > 0
            ? _c(
                "div",
                { staticClass: "select-list-wrap" },
                _vm._l(_vm.peopleList, function (user, userIndex) {
                  return _c("avatar-box", {
                    key: user.id,
                    attrs: { avatarInfo: user, type: "1" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                      deleteAvatar: function ($event) {
                        return _vm.deleteAvatar(user, 2, userIndex)
                      },
                    },
                  })
                }),
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        { staticClass: "bottom-step-buttons" },
        [
          _c(
            "el-button",
            {
              staticClass: "left-button",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleClose.apply(null, arguments)
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.getTargetAligningSubmit("提交")
                },
              },
            },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }