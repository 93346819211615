<template>
  <el-dialog
    :title="`按「我的任务」完成情况自动更新`"
    :visible.sync="dialogVisible"
    width="880px"
    :append-to-body="true"
    :before-close="handleClose"
    :show-close="false"
    @click.stop
  >
    <div class="set-content" v-if="selectTaskList.length > 0 && !dataState" @click.stop>
      <div class="select-title">
        <div class="title-left">
          <el-checkbox v-model="checkedAll" @change="checkedAllChange">全选</el-checkbox>
        </div>
        <div class="title-right">
          <p>进度目标值</p>
          <p>(剩余可设置{{$utils.floatToFixedDown(maxPercentage - selectPercentage)}}{{item.quantificationUnit}})</p>
        </div>
      </div>
      <div class="select-list-wrap">
        <div
          class="select-list"
          v-for="(taskItem, index) in selectTaskList"
          :key="taskItem.id + random"
        >
          <div class="list-left">
            <el-checkbox v-model="taskItem.checked" @change="checkedChange(taskItem, index)" />
            <img
              class="border-radius-20"
              :src="taskItem.originatorAvatar || require('@/assets/imgs/common/avatar.png')"
              alt
            />
            <p>{{taskItem.title}}</p>
          </div>
          <div class="list-right" v-show="taskItem.checked">
            <el-input
              placeholder="0"
              v-model="taskItem.pushFrom"
              class="input-focus"
              :min="0"
              @change="getSelectList"
            >
              <template slot="append">{{item.quantificationUnit}}</template>
            </el-input>
          </div>
        </div>
      </div>
    </div>
    <div class="set-content set-content-null" v-else>
      <noData :type="2" />
    </div>
    <div class="bottom-step-buttons" v-if="selectTaskList.length>0 && !dataState">
      <div class="percentage-text">
        <span>{{$utils.floatToFixedDown(selectPercentage)}}{{item.quantificationUnit}}</span>的进度由
        <span>{{selectList.length}}</span>个任务完成后自动更新
      </div>
      <div class="btn-list" @click.stop>
        <el-button class="left-button" @click="handleClose">取消</el-button>
        <el-button @click="submit('提交')">确认</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
// import permission from './permission.vue'
import noData from '@/views/okr/components/noData.vue'
export default {
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  components: { noData},
  props: {
    dialogShow: {
      type: Boolean,
      default: true
    },
    okrData: {
    },
    item: {
    },
    maxPercentage: { // 最大可设置的值
      type: Number
    },
    taskList: {
    }
  },
  data() {
    return {
      checkedAll: false,
      dialogVisible: this.dialogShow,
      taskAveragePercentage: 0,
      selectPercentage: 0,
      selectList: [], // 选择的任务列表
      selectTaskList: [], // 任务所有列表
      submitState: true,
      dataState: true
    }
  },
  watch: {
    dialogShow: {
      handler(val) {
        this.dialogVisible = val
      }
    }
  },
  created() {
    this.selectTaskList = this.taskList
    this.getSelectList()
    this.dataState = false
  },
  methods: {
    // 关闭启动状态
    handleClose() {
      this.dialogVisible = false
      this.$emit('change', false)
    },
    checkedChange() {
      this.random = Math.random()
      this.getSelectList()
    },
    checkedAllChange() {
      this.random = Math.random()
      this.selectTaskList.forEach((item) => {
        item.checked = this.checkedAll
      })
      this.selectTaskList = JSON.parse(JSON.stringify(this.selectTaskList))
      this.getSelectList()
    },
    getSelectList(type) {
      var value = 0
      const list = []
      this.submitState = true
      this.selectTaskList.forEach((item) => {
        if (item.checked) {
          var re = /^[0-9]+.?[0-9]*/
          item.pushFrom = (Number(item.pushFrom) || 0).toFixed(2)
      　　 if (!re.test(item.pushFrom)) {
            if (type === 1) {
              this.$message.warn('请输入正确格式的进度目标值')
            }
            this.submitState = false
      　　 }
          // item.pushFrom = ((item.pushFrom || '') + '').replace(/\D/g, '')
          if ((item.pushFrom || 0) <= this.item.quantificationStart) {
            this.submitState = false
            if (type === 1) {
              this.$message.warn(`进度目标值不能小于等于${this.item.quantificationStart}`)
            }
          }
          value += parseFloat(item.pushFrom - this.item.quantificationStart)
          list.push({
            automationId: this.item.id,
            automationRuleId: item.automationRuleId || '',
            businessId: item.id,
            businessType: 2, // 业务类型（0目标 ，1 KR ，2 任务（自定义）  3 项目*）
            currentValue: item.status !== 3 ? 0 : (parseInt(item.pushFrom - this.item.quantificationStart) !== 0 ? this.$utils.floatToFixedDown(item.pushFrom - this.item.quantificationStart) : item.pushFrom - this.item.quantificationStart),
            id: item.ruleId || '',
            pushFrom: parseInt(item.pushFrom - this.item.quantificationStart) !== 0 ? this.$utils.floatToFixedDown(item.pushFrom - this.item.quantificationStart) : item.pushFrom - this.item.quantificationStart,
            pushTo: (parseInt(item.pushFrom - this.item.quantificationStart) !== 0 ? this.$utils.floatToFixedDown(item.pushFrom - this.item.quantificationStart) : item.pushFrom - this.item.quantificationStart),
            pushRate: parseInt(item.pushFrom - this.item.quantificationStart) !== 0 ? this.$utils.floatToFixedDown(((this.$utils.floatToFixedDown(item.pushFrom - this.item.quantificationStart) / (this.item.quantificationEnd - this.item.quantificationStart)) * 100)) : 0,
            objectId: this.okrData.id,
            createUserid: item.setCreateUserid || '',
            createDate: item.setCreateDate || '',
            isDel: item.setIsDel,
            companyId: item.setCompanyId || ''
          })
        }
      })
      this.selectPercentage = (value || 0)
      this.selectList = list
      this.$nextTick(() => {
        this.selectTaskList = JSON.parse(JSON.stringify(this.selectTaskList))
      })
      if (list.length === this.selectTaskList.length) {
        this.checkedAll = true
      } else {
        this.checkedAll = false
      }
      if ((value || 0) > this.maxPercentage) {
        this.submitState = false
        this.$message.warn(`进度总和不能大于最大值${this.maxPercentage}哦！`)
      }
    },
    submit() {
      if (this.submitState) {
        this.$emit('refresh', {
          selectTaskList: this.selectTaskList,
          selectList: this.selectList,
          maxValue: this.selectPercentage
        })
        this.handleClose()
      } else {
        this.getSelectList(1)
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../../../assets/styles/dialog.less';
.set-content-null {
  .flex-c();
  flex-direction: column;
}
.set-content {
  margin-left: 16px;
  min-height: 200px;
  .select-title {
    margin: 16px 0px;
    .flex-bt();
    .title-left {
      .el-checkbox__label {
        padding-left: 16px;
      }
    }
    .title-right {
      color: #6e7491;
      line-height: 16px;
      margin-bottom: 2px;
      text-align: center;
    }
  }
  .select-list-wrap {
    min-height: 100px;
    max-height: calc(85vh - 260px);
    overflow-y: auto;
    .select-list {
      padding: 6px 0px;
      .flex-bt();
      .list-left {
        .flex-l();
        flex: 1;
        min-width: 0;
        img {
          width: 36px;
          height: 36px;
          border-radius: 100%;
          margin: 0px 16px;
        }
        p {
          font-weight: 400;
          color: #444558;
          line-height: 26px;
          font-size: 14px;
          margin-right: 16px;
          .text-n(1);
        }
      }
      .list-right {
        .el-input {
          width: 75px;
          margin-right: 8px;
          ::v-deep .el-input__inner {
            height: 28px;
            color: #14142b;
            font-weight: 500;
            padding: 0px;
            text-align: center;
            line-height: 28px;
          }
          ::v-deep .el-input-group__append {
            padding: 0px 7px;
            background: #f5f6fa;
            border-color: #f5f6fa;
          }
        }
      }
    }
  }
}
.bottom-step-buttons {
  .flex-bt();
  margin-left: 16px;
  .percentage-text {
    line-height: 40px;
    font-size: 12px;
    font-weight: 400;
    color: #a0a3bd;
    span {
      color: #3a78f6;
    }
  }
  .btn-list {
    .flex-bt();
    justify-content: flex-end;
    .el-button {
      height: 32px;
      min-width: auto;
    }
  }
}
</style>
