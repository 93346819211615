export default {
  path: '/admin',
  redirect: '/admin/control',
  name: 'admin',
  component: () => {
    return import(/* webpackChunkName: "adminControl" */ '../../views/admin/index.vue')
  },
  children: [
    {
    path: '/admin/control',
    name: '/admin/control',
    component: () => {
      return import(
        /* webpackChunkName: "adminControl" */
        '../../views/admin/control/index.vue'
      )
    }
  }, // 控制台
  {
    path: '/admin/informations',
    name: '/admin/informations',
    component: () => {
      return import(
        /* webpackChunkName: "adminInformations" */
        '../../views/admin/informations/index'
      )
    }
  }, // 基本信息
  {
    path: '/admin/teams',
    name: '/admin/teams',
    component: () => {
      return import(
        /* webpackChunkName: "adminTeams" */
        '../../views/admin/teams/index'
      )
    }
  }, // 成员管理
  {
    path: '/admin/cycle',
    name: '/admin/cycle',
    component: () => {
      return import(
        /* webpackChunkName: "adminCycle" */
        '../../views/admin/cycle/index'
      )
    }
  }, // 周期管理
  {
    path: '/admin/function',
    name: '/admin/function',
    redirect: '/admin/function/priority',
    component: () => {
      return import(
        /* webpackChunkName: "adminFunction" */
        '../../views/admin/function/index'
      )
    },
    children: [
        {
      path: '/admin/function/priority',
      name: '/admin/function/priority',
      component: () => {
        return import(
          /* webpackChunkName: "adminFunction" */
          '../../views/admin/function/priority.vue'
        )
      }
    }, // 优先级设置
    {
      path: '/admin/function/label',
      name: '/admin/function/label',
      component: () => {
        return import(
          /* webpackChunkName: "adminFunction" */
          '../../views/admin/function/label.vue'
        )
      }
    }, // 标签设置
    {
      path: '/admin/function/taskClassify',
      name: '/admin/function/taskClassify',
      component: () => {
        return import(
          /* webpackChunkName: "adminFunction" */
          '../../views/admin/function/taskClassify'
        )
      }
    }, // 分类设置
    {
      path: '/admin/function/companyCalendar',
      name: '/admin/function/companyCalendar',
      component: () => {
        return import(
          /* webpackChunkName: "adminFunction" */
          '../../views/admin/function/companyCalendar'
        )
      }
    }, // 企业日历
    {
      path: '/admin/function/customField',
      name: '/admin/function/customField',
      component: () => {
        return import(
          /* webpackChunkName: "adminFunction" */
          '../../views/admin/function/customField'
        )
      }
    }, // 自定义字段
    {
      path: '/admin/function/okrconfig',
      name: '/admin/function/okrconfig',
      component: () => {
        return import(
          /* webpackChunkName: "adminFunction" */
          '../../views/admin/function/okrconfig'
        )
      }
    }, // OKR配置
    {
      path: '/admin/function/okrApproveSet',
      name: '/admin/function/okrApproveSet',
      component: () => {
        return import(
          /* webpackChunkName: "adminFunction" */
          '../../views/admin/function/okrApproveSet'
        )
      }
    }, // OKR审批配置
    {
      path: '/admin/function/taskSyncSet',
      name: '/admin/function/taskSyncSet',
      component: () => {
        return import(
          /* webpackChunkName: "adminFunction" */
          '../../views/admin/function/taskSyncSet'
        )
      }
    } // 任务同步配置
    ]
  }, // 功能配置
  {
    path: '/admin/order',
    name: '/admin/order',
    component: () => {
      return import(
        /* webpackChunkName: "adminOrder" */
        '../../views/admin/orderManage/index'
      )
    }
  }, // 订单列表
  {
    path: '/admin/updates',
    name: '/admin/updates',
    component: () => {
      return import(
        /* webpackChunkName: "adminUpdates" */
        '../../views/admin/updates/index'
      )
    }
  }, // 更新日志
  {
    path: '/admin/orderDetail',
    name: '/admin/orderDetail',
    component: () => {
      return import(
        /* webpackChunkName: "adminOrderDetail" */
        '../../views/admin/orderManage/detail/index'
      )
    }
  }, // 订单详情
  {
    path: '/admin/moreSet',
    name: '/admin/moreSet',
    component: () => {
      return import(
        /* webpackChunkName: "adminMoreSet" */
        '../../views/admin/moreSet/index'
      )
    }
  }, // 更多设置
  {
    path: '/admin/role',
    name: '/admin/role',
    component: () => {
      return import(
        /* webpackChunkName: "adminRole" */
        '../../views/admin/role/index'
      )
    }
  } // 角色管理
  ]
}
