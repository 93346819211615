var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: `审批意见`,
        visible: _vm.dialogVisible,
        width: "640px",
        "append-to-body": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c("div", { staticClass: "main-content" }, [
        _c(
          "div",
          { staticClass: "detail-content-wrap" },
          [
            _c("div", { staticClass: "title" }, [_vm._v("驳回原因")]),
            _c("el-input", {
              attrs: {
                type: "textarea",
                placeholder: "请描述驳回原因",
                maxlength: 500,
                rows: 3,
              },
              model: {
                value: _vm.approveRxplain,
                callback: function ($$v) {
                  _vm.approveRxplain = $$v
                },
                expression: "approveRxplain",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "bottom-step-buttons" },
          [
            _c(
              "button",
              { staticClass: "left-button", on: { click: _vm.handleClose } },
              [_vm._v("取消")]
            ),
            _c(
              "L-button",
              {
                attrs: { loading: _vm.saveLoading },
                on: {
                  clickEvt: function ($event) {
                    return _vm.approvePassSubmit(2)
                  },
                },
              },
              [_vm._v("确定驳回")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }