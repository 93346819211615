// 优先级
const EMERGENCYSTATE = {
  1: '非常紧急',
  2: '紧急',
  3: '普通'
}

// 任务角色
const TASKROLE = {
  0: '我负责的',
  1: '我委派的',
  2: '我协作的'
}

// 时间顺序
const TIMESTATETYPE = {
  1: '按开始时间最晚',
  2: '按开始时间最早',
  3: '按截止时间最近',
  4: '按更新时间最晚',
  5: '按更新时间最早',
  6: '按创建时间最晚',
  7: '按创建时间最早'
}

// 状态
const TASKSTATUS = {
  1: '待处理',
  2: '进行中',
  3: '已完成',
  4: '搁置' // 钉钉上的  源目标不用暂时取消
}
// 状态字体颜色
const TASKSTATUSCOLOR = {
  1: '#A0A3BD',
  2: '#005EFF',
  3: '#00BA88',
  4: '#ff572b' // 钉钉上的  源目标不用暂时取消
}
// 状态背景颜色
const TASKSTATUSBGCOLOR = {
  1: '#F7F7FC',
  2: '#EEF6FD',
  3: '#E9FBF5',
  4: '#fff2ee' // 钉钉上的  源目标不用暂时取消
}

// 优先级
const PRIOTITY = [
  {
  3: '普通'
},
{
  2: '紧急'
},
{
  1: '非常紧急'
}
]
// 评论类型
const COMMENTTYPEMAP = {
  1: '所有动态',
  2: '更新动态',
  3: '仅评论'
}
// 完成状态
const FINISHTYPE = {
  not: 1, // 未完成
  be: 2 // 已完成
}
// 状态
const STATETYPE = {
  pending: 1, // 待处理
  underway: 2, // 进行中
  finish: 3, // 完成
  stale: 4 // 搁置
}
// 优先级
const PRIORITYTYPE = {
  hight: 1, // 高,
  normal: 2, // 中,
  low: 3 // 低
}
// 已读未读
const READTYPE = {
  read: 0, // 已读
  unread: 1 // 未读
}
// 开始时间
const SHOWDATETYPE = {
  begin: '1', // 已开始
  not: '2' // 未开始
}
// 评论类型
const COMMENTTYPE = {
  all: 1, // 所有动态
  update: 2, // 更新动态
  comment: 3 // 仅评论
}
// 优先级颜色
const PRIOTITYCOLOR = {
  hight: 1, // 高,
  normal: 2, // 中,
  low: 3 // 低
}
// 项目角色
const COOPERATEROLETYPE = {
  // 3: '所有角色',
  1: '我负责的',
  2: '我协作的',
  3: '我委派的'
}
// 协作 开始结束时间字段
const COOPERATESELECTLIST = {
  0: '请选择',
  1: '今天',
  2: '今天以前',
  3: '过去3天',
  4: '过去15天',
  5: '过去30天',
  6: '明天',
  7: '未来3天',
  8: '未来7天',
  9: '本周',
  10: '下周',
  11: '本月',
  12: '上月'
}
// 协作 完成情况
const COMPLETERADIOLIST = {
  1: '未完成',
  2: '已完成',
  3: '逾期'
}
// 协作 新建项目隐私
const PRIVATEVALUELIST = {
  0: '公司所有人可见',
  1: '只对加入项目成员可见'
}
// 协作 筛选任务层级
const WORKTYPE = {
  0: '全部任务',
  1: '仅父任务',
  2: '仅子任务'
}
// 协作 看板筛选任务层级
const BOARDWORKTYPE = {
  1: '仅父任务'
}
// 优先级
const PRIORITYTYPEALL = {
  0: '所有优先级',
  1: '非常紧急',
  2: '紧急',
  3: '普通'
}
// 状态
const STATUSTYPEALL = {
  0: '所有状态',
  1: '待处理',
  2: '进行中',
  3: '已完成',
  4: '搁置' // 钉钉上的  源目标不用暂时取消
}
// 项目状态
const PMSTATUSLIST = {
  0: '进度正常',
  1: '存在风险',
  2: '进度失控',
  3: '暂定'
}
const CONCLUSIONTIMESTATETYPE = {
  6: '按创建时间倒序',
  1: '按开始时间倒序',
  8: '按截止时间倒序',
  4: '按更新时间倒序'
}
// 状态
const TASKSTATUSWAIT = {
  1: '待处理',
  3: '已完成'
  // 4: '搁置' // 钉钉上的  源目标不用暂时取消
}
// 捷径分组icon颜色
const TASKSHORTICONLIST = {
  endDateoverdue: {
    icon: 'icon-kanban-yuqi',
    color: '#FE6D64'
  },
  endDatetoday: {
    icon: 'icon-kanban-jintian',
    color: '#00BA88'
  },
  endDatetomorrow: {
    icon: 'icon-kanban-mingtian',
    color: '#3A70FF'
  },
  endDatewithin7Days: {
    icon: 'icon-kanban-qitian',
    color: '#3A70FF'
  },
  endDatewithin15Days: {
    icon: 'icon-kanban-guoqu',
    color: '#3A70FF'
  },
  endDatenoset: {
    icon: 'icon-kanban-weishezhi',
    color: '#A0A3BD'
  },
  endDatefuture: {
    icon: 'icon-kanban-guoqu',
    color: '#3A70FF'
  },
  wait: {
    icon: 'icon-kanban-daichuli',
    color: '#A0A3BD'
  },
  complete: {
    icon: 'icon-kanban-yiwancheng',
    color: '#00BA88'
  },
  type1: {
    icon: 'icon-biaoge-furenwu',
    color: '#1CC8EE'
  },
  type2: {
    icon: 'icon-kanban-xiangmurenwu',
    color: '#FFA502'
  },
  type3: {
    icon: 'icon-kanban-krrenwu',
    color: '#3A70FF'
  },
  status1: {
    icon: 'icon-biaoge-shijian',
    color: '#A0A3BD'
  },
  status2: {
    icon: 'icon-kanban-jinhangzhong',
    color: '#3A70FF'
  },
  status3: {
    icon: 'icon-kanban-yiwancheng',
    color: '#00BA88'
  },
  noFinish1: {
    icon: 'icon-weiwancheng',
    color: '#A0A3BD'
  },
  isFinish2: {
    icon: 'icon-kanban-yiwancheng',
    color: '#00BA88'
  },
  createDatetoday: {
    icon: 'icon-kanban-jintian',
    color: '#00BA88'
  },
  createDateyesterday: {
    icon: 'icon-kanban-guoqu',
    color: '#FFDA19'
  },
  createDatelast7Days: {
    icon: 'icon-kanban-qitian',
    color: '#FFDA19'
  },
  createDatelast15Days: {
    icon: 'icon-kanban-guoqu',
    color: '#FFDA19'
  },
  createDateearlier: {
    icon: 'icon-kanban-guoqu',
    color: '#FFDA19'
  },
  updateDatetoday: {
    icon: 'icon-kanban-jintian',
    color: '#00BA88'
  },
  updateDateyesterday: {
    icon: 'icon-kanban-guoqu',
    color: '#FFDA19'
  },
  updateDatelast7Days: {
    icon: 'icon-kanban-qitian',
    color: '#FFDA19'
  },
  updateDatelast15Days: {
    icon: 'icon-kanban-guoqu',
    color: '#FFDA19'
  },
  updateDateearlier: {
    icon: 'icon-kanban-guoqu',
    color: '#FFDA19'
  },
  finishDatetoday: {
    icon: 'icon-kanban-jintian',
    color: '#00BA88'
  },
  finishDateyesterday: {
    icon: 'icon-kanban-guoqu',
    color: '#FFDA19'
  },
  finishDatelast7Days: {
    icon: 'icon-kanban-qitian',
    color: '#FFDA19'
  },
  finishDatelast15Days: {
    icon: 'icon-kanban-guoqu',
    color: '#FFDA19'
  },
  finishDateearlier: {
    icon: 'icon-kanban-guoqu',
    color: '#FFDA19'
  },
  finishDatenoFinish: {
    icon: 'icon-weiwancheng',
    color: '#A0A3BD'
  },
  isFinishno: {
    icon: 'icon-weiwancheng',
    color: '#A0A3BD'
  },
  isFinishyes: {
    icon: 'icon-kanban-yiwancheng',
    color: '#00BA88'
  },
  searchProject: {
    icon: 'icon-kanban-xiangmurenwu',
    color: '#FFA502'
  },
  objnull: {
    icon: 'icon-caidanlan-wodeOKR',
    color: '#3A70FF'
  },
  flownull: {
    icon: 'icon-biaoge-furenwu',
    color: '#548AF7'
  },
  '': {
    icon: 'icon-biaoge-furenwu',
    color: '#A0A3BD'
  }
}
export default {
  TASKSHORTICONLIST,
  TASKSTATUSWAIT,
  CONCLUSIONTIMESTATETYPE,
  PMSTATUSLIST,
  TASKSTATUSCOLOR,
  TASKSTATUSBGCOLOR,
  STATUSTYPEALL,
  PRIORITYTYPEALL,
  BOARDWORKTYPE,
  WORKTYPE,
  PRIVATEVALUELIST,
  COMPLETERADIOLIST,
  COOPERATESELECTLIST,
  COOPERATEROLETYPE,
  EMERGENCYSTATE,
  TASKROLE,
  TIMESTATETYPE,
  TASKSTATUS,
  PRIOTITY,
  COMMENTTYPE,
  COMMENTTYPEMAP,
  PRIOTITYCOLOR,
  FINISHTYPE,
  STATETYPE,
  PRIORITYTYPE,
  READTYPE,
  SHOWDATETYPE
}
