<template>
  <transition name="bottom-slide" mode="out-in">
    <div
      :key="`${addTargetView}`"
      class="task-add-box-inner"
      :class="addTargetView ? '' : 'task-add-box-inner-fix'"
    >
      <div v-if="!addTargetView" class="task_add_box" @click="addTargetTask()">
        <div>
          <span>+ 添加任务</span>
        </div>
      </div>

      <div v-if="addTargetView" class="task_add_input_box">
        <el-input
          ref="CommentBoxinput1"
          v-model="task_form.name"
          size="medium"
          placeholder="添加任务，例如：完成产品说明手册"
          @input="inputBack"
        />
        <div class="to_edit_task_time">
          <div class="left_set_item">
            <div class="set-end-time" @click="endPicker">
              <Icon icon-class="icon-calendar" class="task_end_time_icon" />
              <span class="time-text">
                {{ task_form.endTime || '设置结束时间' }}
                <Icon
                  v-if="task_form.endTime"
                  icon-class="icon-guanbianniu"
                  class="close"
                  @click.native.stop="timeClear()"
                />
              </span>
            </div>
            <el-date-picker
              ref="endDate"
              v-model="task_form.endTime"
              class="hide-date-border"
              :disabled="disabled"
              :picker-options="pickerOptionsStart"
              type="datetime"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              :clearable="false"
              :default-time="defaultEnd"
              @change="changeDate()"
            />

            <Icon
              icon-class="icon-remind"
              class="task_cycle_time_icon"
              :class="task_form.remindTime ? 'is-remind' : ''"
              @click="setRemindTime"
            />

            <div class="task_select_kr">
              <kr-select
                v-model="task_form.selectKrArrayValue"
                :kr-option="krOption"
              />
            </div>
          </div>
          <L-button
          :loading="loading"
          :class="
              task_form.name && task_form.selectKrArrayValue
                ? ''
                : 'btn_disabled'
            "
            class="add-task-btn"
            @clickEvt="addTargetTaskRequest">新建</L-button>
        </div>
      </div>
      <!-- 设置提醒时间dialog -->
      <task-remind-time-detail
        v-if="remindTimeVisible"
        :id="task_form.remindTime"
        :visible.sync="remindTimeVisible"
        @save="remindTimeSave"
      />
    </div>
  </transition>
</template>

<script>
import krSelect from './KrSelect' // kr下拉框
import TaskRemindTimeDetail from '@/views/todo/components/TaskTimeSet/TaskRemindTimeDetail' // 设置时间提醒
export default {
  components: {
    krSelect,
    TaskRemindTimeDetail
  },
  props: {
    krOption: {
      type: Array,
      default: () => {
        return []
      }
    },
    selectKrArrayValue: {
      default: ''
    }
  },

  data() {
    return {
      loading: false,
      addTargetView: false,
      remindTimeVisible: false, // 设置时间提醒弹窗
      task_form: {
        // 新建任务对象
        name: '', // 标题
        endTime: '', // 截止日期
        selectKrArrayValue: '', // 关联kr
        remindTime: '' // 重复提醒时间
      },
      topMembersBean: {
        createUserid: ''
      },
      disabled: false,
      defaultEnd: ''
    }
  },
  computed: {},
  created() {
    this.bus(this).$on('InitializeData', (magDate) => {
      if (!magDate) {
        return
      }
      this.topMembersBean = magDate
    })
    if (this.companyCalendarInfo.pmEndTime) {
      this.defaultEnd =
        this.companyCalendarInfo.pmEndTime.substring(11, 19) || ''
    }
  },
  watch: {
    selectKrArrayValue() {
      this.task_form.selectKrArrayValue = this.selectKrArrayValue
    }
  },
  methods: {
    // 点击新增关键任务框
    addTargetTask() {
      this.addTargetView = true
      this.$emit('addTargetView', this.addTargetView)

      setTimeout(() => {
        this.$refs.CommentBoxinput1.focus()
      }, 200)
      if (this.companyCalendarInfo.pmEndTime) {
        this.task_form.endTime = `${this.$utils.getDateStr(0)} ${
          this.companyCalendarInfo.pmEndTime.substring(11, 16) || '18:00'
        }`
      }
    },
    // 设置截止日期
    endPicker() {
      this.$refs.endDate.$el.focus()
      const el = this.$refs.endDate.$el
      el.children[0].focus()

      // 埋点
      // this.$mixpanelUtil.track('点击结束时间')
    },
    // 字数超出显示提示
    inputBack() {
      const message_ = this.task_form.name.replace(/\s*/g, '') || ''
      if (message_.replace(/\s*/g, '').length > 150) {
        this.$message({
          msg: '任务名称不能超过 150 个字符哦!',
          type: 'warn'
        })
        this.task_form.name = message_.substring(0, 150)
      }
    },
    // 清空截止日期
    timeClear() {
      this.task_form.endTime = null
    },
    // 变更结束日期
    changeDate() {},
    // 打开设置提醒弹窗
    setRemindTime() {
      this.remindTimeVisible = true
    },
    // 发送新增请求
    addTargetTaskRequest() {
      if (!this.task_form.name) {
        return
      }
      this.loading = true
      if (
        !this.task_form.name ||
        this.task_form.name.replace(/\s*/g, '').length === 0
      ) {
        this.$message.error('任务标题不能为空!')
        this.loading = false
        return
      } else if (this.task_form.name.replace(/\s*/g, '').length > 150) {
        this.$message.warn('标题最多可输入 150 个字符哦!')
        this.loading = false
        return
      }

      if (!this.task_form.selectKrArrayValue) {
        this.$message.error('关联KR不能为空!')
        this.loading = false
        return
      }
      this.$emit('addTargetTask', this.task_form)
    },
    // 清空
    clearData() {
      this.loading = false
      this.addTargetView = false
      this.$emit('addTargetView', this.addTargetView)
      this.task_form.selectKrArrayValue = this.selectKrArrayValue
      this.task_form.endTime = ''
      this.task_form.name = ''
      this.task_form.remindTime = ''
    },
    // 关闭新增窗口
    closeAddTaskBox() {
      if (
        this.task_form.name &&
        this.task_form.name.replace(/\s*/g, '').length !== 0
      ) {
        this.addTargetTaskRequest()
        return
      }
      this.clearData()
    },
    // 保存提醒时间
    // 提醒时间保存成功事件
    remindTimeSave(row) {
      this.task_form.remindTime = row.id
    },
    // 更改KR选择
    changeKrSelectValue(val) {
      this.selectKrArrayValue = val
      this.task_form.selectKrArrayValue = val
    }
  }
}
</script>

<style lang="scss" scoped>
.task-add-box-inner {
  width: 100%;
}

.task-add-box-inner-fix {
  width: 100px;
}

.task_add_box {
  width: 100px;
  height: 36px;
  line-height: 26px;
  padding: 4px 12px;
  border-radius: 12px;
  cursor: pointer;
  font-weight: 500;
  color: #ff9900;
  border: 1px solid #fff;
  .box_txt_ex {
    color: #a0a3bd;
  }
  &:hover {
    border: 1px solid #fef8ed;
    background: #fef8ed;
  }
  &:active {
    background: #fff;
    border: 1px solid #ffe9bf;
  }
}

.task_add_input_box {
  padding: 8px 20px 16px;
  width: 100%;
  height: 120px;
  box-sizing: border-box;
  opacity: 1;
  background: #fff;
  border: 1px solid #3a78f6;
  box-shadow: 0px -3px 10px 0px rgba(50, 150, 250, 0.14) inset;
  border-radius: 16px;
  color: #a0a3bd;
  position: relative;
  .task_close_box {
    cursor: pointer;
    position: absolute;
    width: 16px;
    height: 16px;
    right: 24px;
    top: 16px;
    z-index: 1;
  }
  ::v-deep .el-input__inner {
    border: none !important;
    padding: 0;
    color: #14142b;
  }
  ::v-deep input {
    box-shadow: 0 0 20px 20px #fff inset;
  }
  .to_edit_task_time {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    .left_set_item {
      display: flex;
      justify-content: flex-start;
      align-content: flex-start;
      position: relative;
      .set-end-time {
        color: #a0a3bd;
        display: flex;
        align-items: center;
        &:hover {
          color: #14142b;
        }
        .task_end_time_icon {
          font-size: 18px;
        }
      }
      .task_select_kr {
        margin-left: 16px;
        min-height: 28px;
      }
      .task_cycle_time_icon {
        font-size: 18px;
        margin-left: 16px;
        cursor: pointer;
        width: 18px;
        color: #a0a3bd;
        &:hover {
          color: #14142b;
        }
      }
      .is-remind {
        color: #ff9900;
        &:hover {
          color: #ff9900;
        }
      }
      .time-text {
        display: inline-block;
        position: relative;
        cursor: pointer;
        height: 28px;
        line-height: 28px;
        padding: 0px 8px;
        border: 1px solid transparent;
        .close {
          display: none;
          position: absolute;
          right: -5px;
          top: -7px;
          color: #a0a3bd;
        }
        &:hover {
          .close {
            display: block;
            &:hover {
              color: #005eff;
            }
          }
        }
        &:active {
          .close {
            display: block;
          }
        }
      }
      &.disabled {
        .time-text {
          cursor: not-allowed;
          &:hover {
            color: #999;
          }
        }
      }
      .hide-date-border {
        left: -20px;
        opacity: 0;
        position: absolute;
        top: -20px;
        width: 20px;
        z-index: -10;
      }
    }
    .add-task-btn {
      width: 78px;
      height: 26px;
      padding: 0 15px;
      border-radius: 8px;
      background: #3068ff;
      border: 0;
      cursor: pointer;
      color: #fff;
    }
    .btn_disabled {
      cursor: not-allowed !important;
      background: #97c8fa !important;
      color: #f7f7fc !important;
    }
  }
  .btn_disabled {
    cursor: not-allowed !important;
    background: #97c8fa !important;
    color: #f7f7fc !important;
  }
}
</style>
