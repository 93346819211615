<template>
  <el-dialog
    title="添加成员"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :destroy-on-close="true"
    class="comm-dialog-kpi"
    @close="handleClose"
  >
    <transition name="fade" mode="out-in">
      <div class="dialog-con">
        <div class="invite-friends">
          <div class="invite-friends-box">
            <div class="left-content">
              <div class="invite-friends-search">
                <el-input
                  v-model="inputValue"
                  prefix-icon="el-icon-search"
                  class="search-input"
                  placeholder="搜索成员"
                  @input="inputSearch"
                />
              </div>
              <div class="invite-friends-title clearfix">
                <div
                  class="friends-title-font"
                  @click="getInviteFriendsPage('首页', index)"
                >{{ companyInfo.name }}</div>
                <div class="friends-title-font-text" id="friends-title-font-text">
                  <span
                    :key="item.name"
                    v-for="(item, index) in inviteFriendsList"
                    @click="getInviteFriendsPage('page', index)"
                  >
                    <wx-open-data :type="2" :id="item.name" :nameData="{name:item.name}" />
                    <i
                      class="el-icon-arrow-right friends_content_list_name_right_i"
                      v-if="inviteFriendsList.length > index + 1"
                    ></i>
                  </span>
                </div>
              </div>
              <!-- 选择个人 -->
              <div class="invite-friends-content" id="invite-friends-content">
                <div
                  class="friends-content-list"
                  v-if="
                    inviteFriendsBeans.departs.length > 0 ||
                    inviteFriendsBeans.userInfos.length > 0
                  "
                  v-scroll-bottom="$loadMoreList"
                >
                  <div
                    :key="item.id"
                    class="friends-content-list-name clearfix"
                    v-for="(item, index) in inviteFriendsBeans.departs"
                    @click.stop="getInviteFriendsListSelect(index, item.status)"
                  >
                    <div class="friends-content-list-name-wrap">
                      <div class="friends-content-list-name-left depart-info">
                        <wx-open-data
                          :type="2"
                          :id="item.departmentTitle"
                          :nameData="{name:item.departmentTitle}"
                        />
                      </div>
                      <div class="friends-content-list-name-text">
                        <wx-open-data :type="2" :id="item.name" :nameData="{name:item.name}" />
                        * {{ item.userNum }}
                      </div>
                    </div>
                    <div
                      class="friends-content-list-name-button friends-content-list-name-right"
                      @click.stop="getInviteFriendsAdd(item, '添加', '', 'person')"
                      v-if="item.status === 0"
                    >添加</div>
                    <div
                      class="friends-content-list-name-button-font friends-content-list-name-right"
                      v-if="item.status === 1"
                    >已添加</div>
                  </div>
                  <div
                    :key="item.id"
                    class="friends-content-list-name clearfix"
                    v-for="item in inviteFriendsBeans.userInfos"
                    @mouseover="getMouseOver"
                  >
                    <div class="friends-content-list-name-wrap">
                      <el-image
                        fit="cover"
                        :src="item.avatar || require('@/assets/imgs/common/avatar.png')"
                        class="friends-content-list-name-left border-radius-20"
                      />
                      <div class="friends-content-list-name-text">
                        <wx-open-data
                          :id="item.username || item.name"
                          :nameData="{name:item.name}"
                        />
                      </div>
                      <div class="user-depart-name" v-if="item.departName">
                        <wx-open-data
                          :id="item.departName"
                          :nameData="{name:item.departName}"
                          :type="2"
                        />
                      </div>
                    </div>
                    <div
                      class="friends-content-list-name-button"
                      @click="getInviteFriendsAdd(item, '添加', '', 'person')"
                      v-if="item.status === 0 && page == 1"
                    >添加</div>
                    <div
                      class="friends-content-list-name-button-font"
                      v-if="item.status === 1 && page == 1"
                    >已添加</div>
                  </div>
                </div>
                <div v-else-if="!pageLoading">
                  <noData />
                </div>
              </div>
            </div>
            <div class="right-content">
              <div class="right-content-title">已选：{{ memberDepartCount }}个部门，{{ memberSumCount }}个成员</div>
              <div class="invite-people-list">
                <div class="invite-friends-content">
                  <div class="friends-content-list friends-content-box">
                    <div
                      :key="item.userId"
                      class="friends-content-list-name clearfix"
                      v-for="(item, index) in editPeopleList"
                      @mouseover="getMouseOver"
                    >
                      <div class="friends-content-list-name-wrap">
                        <div
                          v-if="item.departmentTitle"
                          class="friends-content-list-name-left depart-info"
                        >
                          <wx-open-data
                            :type="2"
                            :id="item.departmentTitle"
                            :nameData="{name:item.departmentTitle}"
                          />
                        </div>
                        <el-image
                          fit="cover"
                          v-if="!item.departmentTitle"
                          :src="item.avatar || require('@/assets/imgs/common/avatar.png')"
                          class="friends-content-list-name-left border-radius-20"
                        />
                        <div class="friends-content-list-name-text">
                          <wx-open-data
                            :type="item.departmentTitle ?  2 : 1"
                            :id="item.name || item.departmentTitle"
                            :nameData="{name:item.name || item.departmentTitle}"
                          />
                          <Icon
                            icon-class="icon-huangguan"
                            class="friends-content-list-name-isAdmin"
                            v-if="item.isAdmin === 1"
                          />
                        </div>
                        <div class="user-depart-name" v-if="item.departName">
                          <wx-open-data
                            :type="2"
                            :id="item.departName"
                            :nameData="{name:item.departName}"
                          />
                        </div>
                      </div>
                      <permission-select
                        v-if="ownerUserId !== item.userId"
                        v-model="item.businessPower"
                        class="select-box"
                        @delItem="getInviteFriendsAdd(item, '移除', index)"
                      />
                      <div class="select-box owner" v-else>所有者</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottom-step-buttons">
            <button @click="handleClose" class="left-button">取消</button>
            <button @click="save">确定</button>
          </div>
        </div>
      </div>
    </transition>
  </el-dialog>
</template>
<script>
import debounce from 'lodash/debounce' // 局部引入防抖
import permissionSelect from '../components/permissionSelect'
export default {
  data() {
    return {
      dialogVisible: this.dialogShow,
      inputValue: '',
      inviteFriendsList: [], // 返回上一级内容
      inviteFriendsBeans: { departs: [], userInfos: [] }, // 保存数据
      inviteFriendsId: '-1',
      inviteFriendsCode: false,
      invitationBeans: null,
      periodMemberList: [],
      editPeopleList: [],
      groupList: [], // 部门列表
      pageNum: 1,
      pageSize: 20,
      total: 0,
      getDataState: true,
      pageLoading: true
    }
  },
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  components: { permissionSelect },
  props: {
    dialogShow: {
      type: Boolean,
      default: true
    },
    selectedPerson: {
      // 已选中人员
      type: Array,
      default: []
    },
    range: {
      type: Array,
      default: []
    },
    page: {
      type: Number,
      default: 1
    },
    ownerUserId: {
      type: Number
    }
  },
  watch: {
    dialogShow: {
      handler(val) {
        this.dialogVisible = val
      }
    }
  },
  computed: {
    memberSumCount() {
      let num = 0
      if (this.editPeopleList && this.editPeopleList.length > 0) {
        this.editPeopleList.forEach((item) => {
          if (item.avatar) {
            num += 1
          }
        })
      }
      return num
    },
    memberDepartCount() {
      let num = 0
      if (this.editPeopleList && this.editPeopleList.length > 0) {
        this.editPeopleList.forEach((item) => {
          if (!item.avatar) {
            num += 1
          }
        })
      }
      return num
    }
  },
  created() {},
  mounted() {
    this.initData()
  },
  methods: {
    inputSearch: debounce(function () {
      this.searchObjectiveOrTask(1)
    }, 200),
    initData() {
      // 获取列表
      this.editPeopleList = []
      this.getInviteFriendsList()
    },
    selectPeopleFilter() {
      if (!this.editPeopleList || this.editPeopleList.length === 0) {
        return
      }
      this.editPeopleList.forEach((item, index) => {
        const state = false
        if (item.departmentTitle) {
          // 部门
          this.inviteFriendsBeans.departs.forEach((items, indexs) => {
            if (items.id === item.userId && !state) {
              items.status = 1
            } else if (items.status !== 1) {
              items.status = 0
            }
          })
        } else {
          this.inviteFriendsBeans.userInfos.forEach((items, indexs) => {
            if (items.id === item.userId && !state) {
              items.status = 1
            } else if (items.status !== 1) {
              items.status = 0
            }
          })
          this.inviteFriendsBeans.departs.forEach((items, indexs) => {
            if (items.id === item.userId && !state) {
              items.status = 1
            } else if (items.status !== 1) {
              items.status = 0
            }
          })
        }
      })
    },
    // 删除元素
    selectPeopleFilterDelete(events) {
      this.inviteFriendsBeans.departs.forEach((items, indexs) => {
        if (items.id === events.userId) {
          items.status = 0
        }
      })
      this.inviteFriendsBeans.userInfos.forEach((items, indexs) => {
        if (items.id === events.userId) {
          items.status = 0
        }
      })
    },
    // 关闭内容
    handleClose() {
      this.dialogShow = false
      this.$emit('change', false)
    },
    $loadMoreList() {
      if (parseInt(this.inviteFriendsBeans.userInfos.length) < parseInt(this.total) && this.getDataState && this.inputValue) {
        this.getDataState = false
        this.searchObjectiveOrTask()
      }
    },
    // 搜索人员内容
    async searchObjectiveOrTask(type) {
      if (this.inputValue.replace(/\s*/g, '').length > 20) {
        this.$message({
          msg: '成员名称最多可以输入 20 个字符哦!',
          type: 'warning'
        })
        this.inputValue = this.inputValue.substring(0, 20)
      }
      if (!this.inputValue) {
        this.getInviteFriendsList(false)
      } else {
        if (type === 1) {
          this.pageNum = 1
          this.inviteFriendsBeans.userInfos = []
        }
        var _obj = {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          name: this.inputValue,
          searchType: 0
        }
        this.$apis
          .QUERYALLMEMBERSINCOMPANYV2('', _obj)
          .then((rest) => {
            this.total = rest.data.total
            this.pageNum = this.pageNum + 1
            this.getDataState = true
            this.inviteFriendsBeans.userInfos = this.inviteFriendsBeans.userInfos.concat(rest.data.rows || [])
            if (rest.data.departs != null) {
              for (let i = 0; i < rest.data.departs.length; i++) {
                rest.data.departs[i].departmentTitle = rest.data.departs[
                  i
                ].name
              }
              this.inviteFriendsBeans.departs = [...rest.data.departs] // 张20210306
            } else {
              this.inviteFriendsBeans.departs = [] // 张20210306
            }
            this.userInfoDepart()
            this.selectPeopleFilter()
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    // 搜索部门内容
    async getInviteFriendsList(type) {
      await this.$apis
        .ADMINDEPARTTREELIST({ parentId: this.inviteFriendsId || -1, name: this.inputValue })
        .then((rest) => {
          for (let i = 0; i < rest.data.departs.length; i++) {
            rest.data.departs[i].departmentTitle = rest.data.departs[
              i
            ].name
            rest.data.departs[i].status = 0
          }
          this.inviteFriendsBeans = rest.data
          if (type !== false) { // 搜索不再初始化选择的人
            this.initSelectedMembers()
          }
          this.userInfoDepart()
          this.selectPeopleFilter()
          // if ((this.inviteFriendsId || -1) === '-1') {
          //   this.inviteFriendsId = rest.data.departs[0].id
          //   this.getInviteFriendsList()
          // }
          this.pageLoading = false
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 计算div高度
    clientHeight() {
      let inviteFriendsContentHeight, friendsTitleFontTextHeight
      const inviteFriendsContent = document.getElementById(
        'invite-friends-content'
      )
      const friendsTitleFontText = document.getElementById(
        'friends-title-font-text'
      )
      if (inviteFriendsContent && friendsTitleFontText) {
        inviteFriendsContentHeight = inviteFriendsContent.clientHeight
        friendsTitleFontTextHeight = friendsTitleFontText.clientHeight
        if (friendsTitleFontTextHeight > 50) {
          inviteFriendsContent.style.height =
            inviteFriendsContentHeight - friendsTitleFontTextHeight + 'px'
          friendsTitleFontText.style.lineHeight = 36 + 'px'
          friendsTitleFontText.style.marginTop = 16 + 'px'
        }
      }
    },
    // 点击部门显示内容
    async getInviteFriendsListSelect(index, status) {
      if (status === 1) {
        // 已添加不展示子部门
        return
      }
      const beansDate = this.inviteFriendsBeans.departs[index]
      this.inviteFriendsList.push(beansDate)
      this.inviteFriendsId = this.inviteFriendsBeans.departs[index].id
      await this.$apis
        .ADMINDEPARTTREELIST({ parentId: this.inviteFriendsId, name: this.inputValue })
        .then((rest) => {
          for (let i = 0; i < rest.data.departs.length; i++) {
            rest.data.departs[i].departmentTitle = rest.data.departs[
              i
            ].name
          }
          this.inviteFriendsBeans = rest.data
          this.userInfoDepart()
          this.selectPeopleFilter()
          setTimeout(() => {
            this.clientHeight()
          }, 500)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 分页搜索内容
    async getInviteFriendsPage(type, index) {
      if (type === '首页') {
        this.inputValue = ''
        this.inviteFriendsList = []
        this.inviteFriendsId = -1
        await this.$apis
          .ADMINDEPARTTREELIST({ parentId: this.inviteFriendsId, name: this.inputValue })
          .then((rest) => {
            for (let i = 0; i < rest.data.departs.length; i++) {
              rest.data.departs[i].departmentTitle = rest.data.departs[
                i
              ].name
            }
            this.inviteFriendsBeans = rest.data
            this.userInfoDepart()
            this.selectPeopleFilter()
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        this.inputValue = ''
        const list = this.inviteFriendsList
        this.inviteFriendsList = list.slice(0, index + 1)
        this.inviteFriendsId = this.inviteFriendsList[index].id
        await this.$apis
          .ADMINDEPARTTREELIST({ parentId: this.inviteFriendsId, name: this.inputValue })
          .then((rest) => {
            for (let i = 0; i < rest.data.departs.length; i++) {
              rest.data.departs[i].departmentTitle = rest.data.departs[
                i
              ].name
            }
            this.inviteFriendsBeans = rest.data
            this.userInfoDepart()
            this.selectPeopleFilter()
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    // 添加修改成员
    async getInviteFriendsAdd(item, type, index) {
      if (type === '添加全部') {
        // console.log('添加全部')
      } else if (type === '添加') {
        // 添加元素前删除右侧当前部门下的人员和子部门
        // this.delItemSelectFilter(this.editPeopleList, item)
        const obj = {
          userId: item.id,
          isAdmin: 0,
          departmentTitle: item.departmentTitle,
          avatar: item.avatar,
          name: this.$isQw?item.username : item.name,
          departPath: item.departPath,
          userCount: item.userNum,
          businessPower: item.id === this.ownerUserId ? 1 : 4,
          departName: (item.departName || '')
        }
        if (item.id === this.ownerUserId) {
          this.editPeopleList.unshift(obj)
        } else {
          this.editPeopleList.push(obj)
        }

        this.selectPeopleFilter()
      } else if (type === '移除') {
        this.selectPeopleFilterDelete(this.editPeopleList[index])
        this.editPeopleList.splice(index, 1)
        this.selectPeopleFilter()
      }
    },
    // 添加元素前删除右侧当前部门下的人员和子部门
    delItemSelectFilter(arrs, evt) {
      // console.log(arrs)
      // console.log(evt)
      // arrs  等同于没有添加新增元素前的数组   在此进行循环遍历 删除 item下的子部门以及人员   用来判断删除人员时的条件
      var teamsArr = []
      var userArr = []
      var teamsSpliceArr = []
      var userSpliceArr = []
      // 循环遍历进行部门 人员分组
      for (let i = 0; i < arrs.length; i++) {
        if (arrs[i].departmentTitle) {
          teamsArr.push(arrs[i])
          teamsSpliceArr.push(arrs[i])
        } else {
          userArr.push(arrs[i])
          userSpliceArr.push(arrs[i])
        }
      }
      for (let s = 0; s < teamsArr.length; s++) {
        if (teamsArr[s].departPath && evt.departPath) {
          if (
            teamsArr[s].departPath.indexOf(evt.departPath) === 0 &&
            teamsArr[s].departPath.length > evt.departPath.length
          ) {
            teamsSpliceArr = this.delItem(teamsSpliceArr, teamsArr[s].id)
          }
        }
      }
      for (let s = 0; s < userArr.length; s++) {
        if (userArr[s].departPath && evt.departPath) {
          if (
            userArr[s].departPath.indexOf(evt.departPath) === 0 &&
            ((userArr[s].departPath.length > evt.departPath.length &&
              evt.avatar) ||
              !evt.avatar)
          ) {
            userSpliceArr = this.delItem(
              userSpliceArr,
              userArr[s].userId,
              'userId'
            )
          }
        }
      }
      this.editPeopleList = [...teamsSpliceArr, ...userSpliceArr]
    },
    // 删除元素
    delItem(arrs_, id, prop) {
      if (!prop) {
        prop = 'id'
      }
      const arr = arrs_ || []
      for (let s = 0; s < arr.length; s++) {
        if (arr[s][prop] === id) {
          arr.splice(s, 1)
        }
      }
      return arr
    },
    // 初始化已选择成员列表
    initSelectedMembers() {
      this.editPeopleList = JSON.parse(JSON.stringify(this.selectedPerson))
    },
    // 数据回填
    userInfoDepart() {
      for (let j = 0; j < this.inviteFriendsBeans.departs.length; j++) {
        this.inviteFriendsBeans.departs[j].status = 0
      }
      for (let j = 0; j < this.inviteFriendsBeans.userInfos.length; j++) {
        this.inviteFriendsBeans.userInfos[j].status = 0
      }
      // 数组去重
      const arrsDistinects = [...this.editPeopleList]
      this.editPeopleList = [...this.distincts(arrsDistinects)]
    },
    // 数组对象去重
    distincts(arr, field = 'userId') {
      const res = new Map()
      return arr.filter(
        (item) => !res.has(item[field]) && res.set(item[field], 1)
      )
    },

    // 移入显示内容
    async getMouseOver(index, item) {},
    async save() {
      this.$emit('saveResult', this.editPeopleList)
      this.handleClose() // 关闭周期成员弹窗
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .el-input__inner {
    background: #f5f6fa;
  }
  .search-input .el-input__inner {
    font-size: 16px;
    border: none;
    -webkit-box-shadow: 0 0 20px 20px #f5f6fa inset !important;
    box-shadow: 0 0 20px 20px #f5f6fa inset !important;
  }
}
.dialog-con {
  padding: 24px 0 16px;
}
</style>
<style lang="less" scoped>
@import '../../../../assets/styles/dialog.less';
.user-depart-name {
  font-size: 12px;
  color: #a0a3bd;
  margin: 0px 8px;
  .text-n(1);
  max-width: 80px;
}
.invite-friends {
  .invite-friends-title {
    font-size: 24px;
    color: #14142b;
    .mg-b(20);
  }
  .friends-content-box {
    max-height: calc(100vh - 50vh) !important;
    overflow-y: auto;
    .s-b-y();
  }
  .invite-friends-box {
    box-sizing: border-box;
    .flex-bt();
    align-items: flex-start;

    .invite-friends-content {
      width: 100%;
      height: calc(100% - 189px);
      overflow-x: hidden;
      margin-top: 8px;
      .friends-content-list {
        width: 100%;
        min-height: 45px;
        height: calc(100vh - 57vh);
        overflow-y: auto;
        .s-b-y();
        .friends-content-list-name {
          position: relative;
          overflow-y: hidden;
          width: 100%;
          .h(56);
          .lh(56);
          cursor: pointer;
          .bd-r-n(8);
          display: flex;
          justify-content: space-between;
          .friends-content-list-name-wrap {
            flex: 1;
            display: flex;
            justify-content: flex-start;
          }
          .friends-content-list-name-left {
            min-width: 38px;
            .w(38);
            .h(38);
            background: #fff;
            border-radius: 100%;
            float: left;
            .mg-t(10);
            .mg-l(9);
            font-size: 17px;
            font-family: 'STYuanti-SC-Bold,STYuanti-SC';
            font-weight: bold;
            color: rgba(255, 255, 255, 1);
            text-align: center;
            .lh(38);
          }
          .depart-info {
            background: rgba(48, 104, 255, 1);
          }
          .friends-content-list-name-text {
            font-size: 14px;
            color: rgba(51, 51, 51, 1);
            float: left;
            .mg-l(10);
            .max-w(200) !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
            white-space: nowrap !important;
            &:before {
              content: '';
              display: block;
            }
          }
          .friends-content-list-name-isAdmin {
            font-size: 16px;
            .mg-l(9);
          }
          .friends-content-list-name-right {
            font-size: 20px;
            float: right;
            color: rgba(224, 227, 236, 1);
            .mg-r(17);
            .lh(56);
          }
          .friends_content_list_name_right_i {
            font-size: 20px;
            float: right;
            color: rgba(224, 227, 236, 1);
            margin-right: 14px;
            margin-top: 18px;
          }
          .friends-content-list-name-button {
            .h(28);
            .lh(28);
            border-radius: 14px;
            .pa(0, 15, 0, 15);
            border: 1px solid rgba(48, 104, 255, 1);
            font-size: 14px;
            color: rgba(48, 104, 255, 1);
            text-align: center;
            float: right;
            .mg-r(17);
            .mg-t(14);
            display: none;
            // position: absolute;
            right: -10px;
            width: 66px;
            min-width: 66px;
          }
          .select-box {
            .mg-r(24);
            // position: absolute;
            right: -10px;
          }
          .owner {
            .mg-r(24);
            right: 32px;
          }
          .friends-content-list-name-button-font {
            .h(28);
            .lh(28);
            font-size: 14px;
            .mg-r(17);
            .mg-t(14);
            // position: absolute;
            right: -10px;
            color: #969fbe;
            float: right;
            border-radius: 14px;
            .pa(0, 15, 0, 15);
            border: 1px solid @b-c;
          }
        }
        .friends-content-list-name:hover {
          background: #f5f6fa;
          .friends-content-list-name-button {
            display: block;
          }
          .friends_content_list_name_right_i {
            display: none;
          }
        }
      }
    }
    .left-content {
      .w(448);
      .pr(24);
      border-right: 1px solid @b-c;
      box-sizing: border-box;
      .invite-friends-search {
        width: 100%;
        height: 40px;
        line-height: 40px;
        background: #f5f6fa;
        .flex-l();
        .bd-r-n(9);
        .search-icon {
          width: 20px;
          height: 20px;
          color: #a0a3bd;
          .mg-l-n(12);
        }
        .search-input {
          border: none;
          width: 90%;
          font-size: 16px;
        }
      }
      .invite-friends-title {
        .mg-t(20);
        display: flex;
        height: 18px;
        align-items: center;
        // line-height: 16px;
        .friends-title-font {
          font-size: 14px;
          color: #a0a3bd;
          max-width: 27%;
          height: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
          &:before {
            content: '';
            display: block;
          }
        }
        .friends-title-font-text {
          .mg-l(10);
          font-size: 14px;
          color: #a0a3bd;
          cursor: pointer;
        }
      }
      .member-group-list {
        .member-group-item {
          width: 100%;
          height: 56px;
          display: flex;
          align-content: center;
          justify-content: space-between;
        }
      }
    }
    .right-content {
      .w(424);
      height: 100%;
      .right-content-title {
        color: #a0a3bd;
        font-size: 14px;
        .mg(8);
      }
      .friends-content-list-name-button-font:hover {
        color: #ed2e7e !important;
        border: 1px solid #ed2e7e !important;
      }
    }
  }
  .btn-list {
    .flex-bt();
    .mg-t(12);
    .mg-b(40);
    .invite-friends-link {
      .flex-l();
      cursor: pointer;
      .invite-friends-link-icon {
        .w(24);
        .h(24);
        .mg-r(8);
      }
      .invite-friends-link-name {
        font-size: 16px;
        color: #3a78f6;
      }
    }
    .btn-list-button {
      .flex-r();
      .list {
        .w(108);
        .h(40);
        .lh(40);
        border: 1px solid #005eff;
        color: #005eff;
        .bd-r-n(20);
        margin: 0px auto;
        text-align: center;
        .mg-l(8);
        cursor: pointer;
      }
      .list:last-child {
        background: #005eff;
        color: #fff;
      }
    }
  }
  .invite-friends-code {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    background: rgba(0, 0, 0, 0.15);
    .friends-code-box {
      width: 435px;
      min-height: 465px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 15px 0px rgba(0, 24, 87, 0.1);
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -218px;
      margin-top: -238px;
      padding-bottom: 30px;
      .friends-box-top {
        width: 100%;
        height: 45px;
        border-radius: 1px;
        text-align: center;
        line-height: 45px;
        font-size: 17px;
        color: rgba(51, 51, 51, 1);
        border-bottom: 1px solid rgba(224, 227, 236, 1);
        position: relative;
        .friends-box-top-font {
          font-size: 20px;
          color: rgba(150, 159, 190, 1);
          position: absolute;
          right: 17px;
          top: 14px;
          cursor: pointer;
        }
      }
      .friends-code-box-img {
        width: 100%;
        height: 150px;
        margin-top: 40px;
        .friends-code-box-img-icon {
          width: 150px;
          height: 150px;
          display: block;
          margin: 0px auto 0px auto;
        }
      }
      .friends-code-box-text {
        width: 372px;
        min-height: 90px;
        background: rgba(243, 243, 245, 0.6);
        border-radius: 3px;
        border: 1px solid rgba(224, 227, 236, 0.8);
        margin: 21px auto 0px auto;
        padding-bottom: 10px;
        .friends-code-box-text1 {
          width: 100%;
          font-size: 12px;
          font-family: 'OPPOSans-R,OPPOSans';
          font-weight: normal;
          color: rgba(92, 93, 97, 1);
          text-align: center;
          line-height: 21px;
          word-break: break-word;
          padding-left: 10px;
          padding-right: 10px;
        }
        .friends-code-box-text1:first-child {
          margin-top: 13px;
        }
      }
      .friends-code-box-button {
        width: 375px;
        height: 41px;
        background: #5a87ff;
        border-radius: 3px;
        margin: 36px auto 0px auto;
        border: none;
        display: block;
        color: #ffffff;
      }
    }
  }
}
</style>
