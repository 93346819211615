<template>
  <div class="detail-path">
    <div class="detail-path-return" @click="getTargetReturn">
      <Icon icon-class="icon-drawer_return" class="return-icon" />
      <div>返回上级</div>
    </div>
    <div class="split"></div>
    <div class="path-okrs">
      <div class="target_top_left4_return" @click="getTargetReturn" v-html="objPathData[1].content"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    objPathData: {
      type: Array
    }
  },
  data() {},
  methods: {
    getTargetReturn() {
      this.$emit('getTargetReturn')
    }
  }
}
</script>
<style lang="less" scoped>
.detail-path {
  display: flex;
  align-content: center;
  font-size: 16px;
  color: #444558;
  align-items: center;
  .detail-path-return {
    display: flex;
    align-content: center;
    cursor: pointer;
  }
  .return-icon {
    font-size: 20px;
    margin-right: 8px;
  }
  .split {
    height: 12px;
    width: 1px;
    background: #6e7491;
    margin: 0 16px 0 8px;
  }
  .path-okrs {
    color: #6e7491;
    cursor: pointer;
  }
  .target_top_left4_return {
    width: 383px;
    height: 32px;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: #6e7491;
    float: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 32px;
    cursor: pointer;
    &:before {
      content: "";
      display: block;
    }
  }
}
</style>
