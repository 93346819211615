<template>
  <div class="task-item" @click.stop="selectClick" :class="item.checked ? 'select' : ''">
    <div class="task-top">
      <div class="task-left">
        <div class="task-status" :class="`status-con${item.status}`">
          <state-picker :type="2" :status="item.status" :disabled="true">
            <div class="content flex-new">
              <div
                :style="`border: 1px solid ${stateDictColor[item.status]} !important`"
                class="pane to-edit-input-not"
              >
                <i v-if="item.status == 3" class="el-icon-check checked"></i>
                <i
                  v-else-if="!isDisabledEdit"
                  class="el-icon-caret-bottom hover"
                  :style="`color: ${stateDictColor[item.status]}`"
                ></i>
              </div>
              <p class="left-box-todo-p" :class="statusModalColor">{{ statusDict[item.status] }}</p>
            </div>
          </state-picker>
        </div>
        <div class="task-content">
          <el-tooltip :open-delay="300" :content="`${item.title}`">
            <span>{{item.title}}</span>
          </el-tooltip>
        </div>
      </div>
      <div class="task-avatar">
        <div class="task-title-right" v-if="item.status !== 3 && item.status !== 4">
          <div class="date-box minHeight" v-if="isSetTime">
            <div v-if="(item.startDate || '') != '' || (item.endDate || '') != ''" class="flex-1">
              <div
                :class="item.status != 3 ? 'dateColor' + $utils.getDateBgColor(
                item.startDate,
                item.endDate
              ) : ''"
                class="date-txt"
              >
                <template
                  v-if="
                        (item.startDate || '') === '' && (item.endDate || '') != ''
                      "
                >
                  {{ $utils.formatRecentTime(
                  item.endDate,
                  'page',
                  1
                  ) }} 截止
                </template>
                <template
                  v-if="
                        (item.startDate || '') != '' && (item.endDate || '') != ''
                      "
                >
                  {{ $utils.formatRecentTime(
                  item.startDate,
                  'page',
                  1
                  ) }} -
                  {{ $utils.formatRecentTime(
                  item.endDate,
                  'page',
                  1
                  ) }}
                </template>
                <template
                  v-if="
                        (item.startDate || '') != '' && (item.endDate || '') === ''
                      "
                >
                  {{ $utils.formatRecentTime(
                  item.startDate,
                  'page',
                  1
                  ) }} 开始
                </template>
              </div>
            </div>
          </div>
        </div>
        <el-image
          fit="cover"
          class="border-radius-20"
          :src="item.originatorAvatar || require('@/assets/imgs/pm/head-icon.png')"
        />
        <div @click.stop v-if="canDelete">
          <Icon icon-class="icon-close" class="delete-icon" @click="taskDel" />
        </div>
      </div>
    </div>
    <div class="task-type-detail-wrap">
      <div class="task-type-detail">
        <div
          class="task-cycle-wrap"
          v-if="(item.cycleName && item.keyTitle) || item.keyTitle || item.projectName"
        >
          <div class="cycle-name" v-if="item.cycleName && item.keyTitle">
            {{
            $utils.tagFilter(item.cycleName)
            }}
          </div>
          <div class="task-type" v-if="item.keyTitle || item.projectName">
            <Icon icon-class="icon-suoshu" class="belong-icon" />
            <span v-if="item.processId" class="kr-box-kr">项目：</span>
            <span v-else class="kr-box-kr">KR：</span>
            <span class="kr-text" v-if="item.processId">
              <el-tooltip
                :open-delay="300"
                :content="$utils.tagFilter(item.projectName + '/' + item.processName)"
              >
                <span>
                  {{ $utils.tagFilter(item.projectName) }}/{{
                  item.processName
                  }}
                </span>
              </el-tooltip>
            </span>
            <span v-else class="kr-text">
              <el-tooltip
                :open-delay="300"
                :content="$utils.tagFilter($utils.changeToHtml(item.keyTitle))"
              >
                <span slot="content" v-html="$utils.tagFilter($utils.changeToHtml(item.keyTitle))"></span>
                <span v-html="$utils.tagFilter($utils.changeToHtml(item.keyTitle))"></span>
              </el-tooltip>
            </span>
          </div>
        </div>
        <div class="task-children-wrap" v-if="item.fatherTaskTitle != null">
          <Icon icon-class="icon-furenwu" class="belong-icon" />
          <span class="kr-box-kr">父任务：</span>
          <el-tooltip :open-delay="300" :content="item.fatherTaskTitle">
            <span class="kr-text">{{ item.fatherTaskTitle }}</span>
          </el-tooltip>
        </div>
      </div>
      <div class="select-wrap" v-if="item.checked">
        <el-checkbox v-model="item.checked" />
      </div>
    </div>
  </div>
</template>
<script>
import taskEnums from '@/utils/enums/task.js'
import statePicker from '@/views/todo/components/TaskStatePicker'
export default {
  props: {
    // 数据
    item: {
      type: Object,
      required: true
    },
    fieldIndex: {},
    valueList: {},
    canDelete: {
      default: false
    },
    index: {}
  },
  data() {
    return {
      statusDict: taskEnums.TASKSTATUS, // 状态字典
      stateDictColor: taskEnums.TASKSTATUSCOLOR,
      isDisabledEdit: true
    }
  },
  methods: {
    // 是否设置时间
    isSetTime() {
      const { startDate, endDate } = this.item
      return startDate || endDate
    },
    // 详情
    selectClick() {
      this.$emit('selectClick', this.index, this.item)
    },
    taskDel() {
      (this.valueList).splice(this.index, 1)
      this.$emit('taskDel', this.valueList, this.fieldIndex)
    },
    statusModalColor() {
      if (this.item.status === 1) {
        return 'left-box-todo-p-not'
      } else if (this.item.status === 2) {
        return 'left-box-todo-p-ing'
      } else if (this.item.status === 3) {
        return 'left-box-todo-p-done'
      } else {
        return ''
      }
    }
  }
}
</script>
<style lang="less" scoped>
.task-item {
  padding: 8px 16px;
  border-bottom: 1px solid #f5f6fa;
  .task-type-detail-wrap {
    .flex-l();
    justify-content: space-between;
    .select-wrap {
      margin: 8px 0px 0px 8px;
    }
  }
  .task-status {
    position: relative;
    padding: 8px 20px 8px 0px !important;
    border-radius: 8px;
    line-height: 18px;
    font-size: 14px;
    font-weight: 500;
    height: 32px;
    .content {
      .flex-l();
    }
    .pane {
      border: 1px solid #a0a3bd;
      position: relative;
      height: 18px;
      width: 18px;
      border-radius: 5px;
      margin-right: 9px;
      text-align: center;
      .state-modal-normal {
        .status-txt {
          background: #f4f4f4;
          border-radius: 4px;
        }
      }
      .state-modal-ing {
        .status-txt {
          color: #1066ff;
          background: #e7efff;
          border-radius: 4px;
        }
      }
      .state-modal-done {
        .status-txt {
          color: #00bf4c;
          background: #edfcf6;
          border-radius: 4px;
        }
      }
      .hover {
        display: none;
      }
      &:hover {
        .hover {
          display: block;
          margin-top: 1px;
        }
      }
    }
    .status-box {
      width: 130px;
      left: 18px;
      top: -2px;
      font-size: 13px;
      font-weight: 400;
    }
    .bottoms_position {
      top: -76px;
    }
  }
  .left-box-todo-not {
    margin-top: 0px;
    position: relative;
    padding-top: 4px;
    border-radius: 4px;
    .content {
      .pane {
        border: 1px solid #444558;
      }
      p.left-box-todo-p-not {
        color: #444558 !important;
        font-size: 14px;
        margin-left: 4px;
        line-height: 18px;
      }
    }
  }
  .left-box-todo-ing {
    margin-top: 0px;
    position: relative;
    padding-top: 4px;
    border-radius: 4px;
    .content {
      .pane {
        border: 1px solid #1066ff;
      }
      p.left-box-todo-p-ing {
        color: #1066ff !important;
        font-size: 14px;
        margin-left: 4px;
        line-height: 18px;
      }
    }
  }
  .left-box-todo-done {
    margin-top: 0px;
    position: relative;
    padding-top: 4px;
    border-radius: 4px;
    .content {
      .pane {
        border: 1px solid #00bf4c !important;
        text-align: center;
        .checked {
          width: 90%;
          color: #00ba88;
          font-weight: bold;
        }
      }
      p.left-box-todo-p-done {
        color: #00bf4c !important;
        font-size: 14px;
        margin-left: 4px;
        line-height: 18px;
      }
    }
  }
  .task-type-detail {
    margin-left: 89px;
    color: #a0a3bd;
    font-size: 12px;
    flex: 1;
    .task-cycle-wrap {
      .flex-l();
      margin-top: 4px;
      .cycle-name {
        border: 1px solid #3a78f6;
        border-radius: 12px;
        font-weight: 500;
        padding: 0px 8px;
        color: #3a78f6;
        margin-right: 16px;
        height: 20px;
        line-height: 18px;
        font-size: 10px;
      }
      .task-type {
        .flex-l();
        flex: 1;
      }
    }
    .task-children-wrap {
      .flex-l();
      margin-top: 4px;
    }
    .belong-icon {
      font-size: 16px;
    }
    .kr-box-kr {
      font-size: 12px;
      margin-left: 4px;
    }
    .kr-text {
      flex: 1;
      .text-n(1);
      max-width: calc(100% - 40px);
    }
  }
  .task-top {
    cursor: pointer;
    font-size: 14px;
    color: #444558;
    .flex-bt();
  }
  &:hover {
    .delete-icon {
      display: block;
    }
    background: rgba(245, 246, 250, 0.7);
    border-bottom: 1px solid rgba(245, 246, 250, 0.7);
    border-radius: 12px;
  }
  .date-box {
    display: flex;
    margin-right: 24px;
    align-items: center;
    .date-txt {
      border-radius: 4px;
      display: inline-block;
      height: 24px;
      font-size: 14px;
      line-height: 24px;
      // padding: 0 10px;
    }
    .dateColor2 {
      color: #00ba88 !important;
    }
    .dateColor3 {
      color: #f93300 !important;
    }
  }
  .delete-icon {
    width: 18px;
    height: 18px;
    line-height: 18px;
    font-size: 18px;
    color: #a0a3bd;
    display: none;
    margin-left: 8px;
    cursor: pointer;
    &:hover {
      color: #3a70ff;
    }
  }
  .task-left {
    .flex-l();
    flex: 1;
    margin-right: 12px;
    .status-con2 {
      color: #005eff;
    }
    .status-con3 {
      color: #00ba88;
    }
    .status-con4 {
      color: #ff572b;
    }
    .el-checkbox {
      margin-right: 8px;
    }
    .task-content {
      flex: 1;
      min-width: 0;
      font-weight: 500;
      .text-n(1);
    }
  }

  .task-avatar {
    .flex-l();
    justify-content: flex-end;
    .el-image {
      width: 28px;
      height: 28px;
      border-radius: 100% 100%;
      vertical-align: middle;
    }
  }
}
.task-item.select {
  background: #e7effe;
  border-radius: 12px;
}
</style>
