import eventBus from '@/utils/bus'
import db from '../database'
import store from '@/store'
// 红点
const pointmap = {
  2003: [], // okr/ 邀请对齐目标
  2002: [], // okr/ 评论
  2001: [], // okr/ 点赞
  2000: [], // 周期
  3000: [], // todo/任务列表
  4000: [] // 总结未读
}

// 提醒
const msgmap = {
  0: '系统类',
  1: '广告类',
  2: '召回类'
}

// 红点消息推送
const mapRedpoint = (arr) => {
  // 每次都是新数据，先清空数组
  for (const key in pointmap) {
    if (Object.hasOwnProperty.call(pointmap, key)) {
      pointmap[key] = []
    }
  }
  // 对应类型清空
  arr.forEach((item) => {
    for (const key in pointmap) {
      if (
        Object.hasOwnProperty.call(pointmap, key) &&
        item.behaviorType.toString() === key
      ) {
        pointmap[key].push(item)
      }
    }
  })
  // db存储到okr邀请对齐目标
  db.setData('2003', pointmap['2003'])
  // db存储到okr目标评论
  db.setData('2002', pointmap['2002'])
  // db存储到okr目标点赞
  db.setData('2001', pointmap['2001'])
  // db存储到okr目标周期
  db.setData('2000', pointmap['2000'])
  // db存储到todo任务列表
  db.setData('3000', pointmap['3000'])
  // db存储到总结未读
  db.setData('4000', pointmap['4000'])
  // 实时刷新todoList组件红点
  eventBus.$emit('todoListRedpoint', pointmap['3000'])
  // 实时刷新okr邀请对齐目标红点
  eventBus.$emit('okrAlignRedpoint', pointmap['2003'])
  // 实时刷新okr目标评论红点
  eventBus.$emit('okrCommentRedpoint', pointmap['2002'])
  // 实时刷新okr目标点赞红点
  eventBus.$emit('okrLikeRedPoint', pointmap['2001'])
  // 实时刷新okr目标周期红点
  eventBus.$emit('okrCycleRedPoint', pointmap['2000'])
  // 实时刷新总结未读红点
  eventBus.$emit('summaryListRedPoint', pointmap['4000'])

  // db存储到todo列表导航
  // 列表有数据，则todo渲染红点-暂时无用
  let todoList = false
  if (redFilter(pointmap[3000]).length > 0) {
    todoList = true
  }
  eventBus.$emit('todoRedPoint', todoList)
  // 渲染OKR红点
  let okrList = false
  if (redFilter(pointmap[2000]).length > 0) {
    okrList = true
  }
  eventBus.$emit('okrRedPoint', okrList)
  // 渲染总结
  const summaryRedPoint = redFilter(pointmap[4000]).length
  eventBus.$emit('summaryRedPoint', summaryRedPoint)
}

// 公司红点过滤
const redFilter = (arr) => {
  return arr.filter((item) => {
    return item.companyId === store.state.user.companyInfo.id
  })
}

// 查询当前头部消息
const headerRedpoint = (companyId) => {
  db.read(
    '3000',
    (arr) => {
      // console.log('3000todoRedPoint？', todoList)
      eventBus.$emit('todoRedPoint', arr.length > 0)
    },
    companyId
  )
  db.read(
    '2000',
    (arr) => {
      eventBus.$emit('okrRedPoint', arr.length > 0)
    },
    companyId
  )
}

// 提醒
const notification = (item) => {}
export default {
  mapRedpoint,
  notification,
  headerRedpoint
}
