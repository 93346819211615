export default {
  path: '/setting',
  redirect: '/setting/information',
  name: 'setting',
  component: () => {
    return import(
      /* webpackChunkName: "settingIndex" */
      '../../views/setting/index.vue'
    )
  },
  children: [
    {
    path: '/setting/information',
    name: 'setting/information',
    component: () => {
      return import(
        /* webpackChunkName: "settingInformation" */
        '../../views/setting/information/index.vue'
      )
    }
  },
  {
    path: '/setting/accountPassword',
    name: 'setting/accountPassword',
    component: () => {
      return import(
        /* webpackChunkName: "settingAccountPassword" */
        '../../views/setting/accountPassword/index.vue'
      )
    }
  }, // 团队管理
  {
    path: '/setting/notify',
    name: 'setting/notify',
    component: () => {
      return import(
        /* webpackChunkName: "settingNotify" */
        '../../views/setting/notify/index.vue'
      )
    }
  }, // 通知设置
  {
    path: '/setting/update',
    name: 'setting/update',
    component: () => {
      return import(
        /* webpackChunkName: "settingUpdate" */
        '../../views/setting/update/index.vue'
      )
    }
  } // 更新日志
  ]
}
