var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: `type-wrap`,
      style: {
        background:
          _vm.classType === "kpiPlan"
            ? _vm.kpiPlanStatusList[_vm.type].bgColor
            : _vm.kpiStatusList[_vm.type].bgColor,
      },
    },
    [
      _vm._v(
        _vm._s(
          _vm.classType === "kpiPlan"
            ? _vm.kpiPlanStatusList[_vm.type].name
            : _vm.kpiStatusList[_vm.type].name
        )
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }