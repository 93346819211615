<template>
  <el-popover
    ref="popoverSetting"
    placement="bottom"
    trigger="click"
    :popper-class="`depart-select ${className}`"
    @show="popoverShow(true)"
    @hide="popoverShow(false)"
    :disabled="disabled"
  >
    <!-- v-vueloading="loading" -->
    <div class="select-content">
      <transition name="fade" mode="out-in">
        <div class="select-tree" v-if="treeData.length > 0">
          <el-tree
            class="set-tree"
            :data="treeData"
            highlight-current
            show-checkbox
            node-key="id"
            :default-expanded-keys="expandedKeys"
            :default-checked-keys="checkedKeys"
            :check-on-click-node="true"
            @check-change="handleCheckChange"
            ref="departTreeRef"
            :key="random"
          >
            <div class="custom-tree-node" slot-scope="{ node }">
              <div class="tree-label">
                <wx-open-data :type="2" :id="node.data.label" :nameData="{name:node.data.label}" />
              </div>
            </div>
          </el-tree>
        </div>
      </transition>
      <no-data v-if="!loading && treeData.length === 0" />
      <div class="btn-list">
        <button v-preventReClick type="button" class="edit-btn cancel" @click="cancel(1)">取消</button>
        <button v-preventReClick type="button" class="edit-btn confirm" @click="confirm">确定</button>
      </div>
    </div>
    <div slot="reference" :class="`select-btn select-btn-${disabled}`">
      <template v-if="slotType === 0">
        <span>部门:</span>
        <template v-if="checkedKeys.length === 0">
          <template v-if="type === 0">全部</template>
          <template v-else>
            <template v-if="userInfo.departName">
              <wx-open-data
                :type="2"
                :id="userInfo.departName"
                :nameData="{name:userInfo.departName}"
              />
            </template>
            <template v-else>未设置</template>
          </template>
        </template>
        <template v-else>{{`已选${checkedKeys.length}个部门`}}</template>
        <i class="el-select__caret el-input__icon el-icon-arrow-up"></i>
      </template>
      <template v-else>
        <slot name="slot"></slot>
      </template>
    </div>
  </el-popover>
</template>
<script>
/* eslint-disable */
import noData from '@/views/okr/components/noData.vue'
export default {
  components: {
    noData
  },
  props: {
    expandedKeys: {
      default: []
    },
    checkedKeys: {
      default: []
    },
    type: { // 0 : 企业 1:部门
      default: 1
    },
    disabled: {
      type: Boolean,
      default: false
    },
    slotType: { // 0: 统计筛选 1
      type: Number,
      default: 0
    },
    title: {
      default: '部门：'
    },
    className:{
      default: ''
    }
  },
  data() {
    return {
      selectHeadDialogState: false,
      props: {
        children: 'children',
        label: 'name'
      },
      treeData: [],
      loading: true,
      initCheckedKeys: []
    }
  },
  created() {
  },
  methods: {
    popoverShow(state) {
      this.selectHeadDialogState = state
      this.initCheckedKeys = this.checkedKeys
      if (state){
        this.loading = true
        this.treeData = []
        this.queryAllDepartData()
      }
    },
    doClose() {
      this.$refs.popoverSetting.doClose()
    },
    cancel(type) {
      if (this.selectHeadDialogState) {
        if (this.$refs.popoverSetting) {
          this.$refs.popoverSetting.doClose()
        }
      }
      if (type === 1 && this.initCheckedKeys !== this.checkedKeys) {
        this.checkedKeys = this.initCheckedKeys
      }
    },
    queryAllDepartData() {
      // 获取部门层级
      var id
      this.treeData = []
      if (this.type === 0) { // 全部
        id = -1
      } else { // 登录人当前部门
        id = this.userInfo.departId
        if ((id || '') === '') {
          id = -1
        }
      }
      this.$apis.DEPARTBYID(id).then((res) => {
        if (res.status === 200) {
          this.$nextTick(() => {
            this.treeData = res.data
          })
          this.$nextTick(() => {
            this.handleDepartData(this.treeData, this.companyInfo.name)
          })
          this.random = Math.random()
        }
        this.loading = false
      })
    },
    // 部门数据处理
    handleDepartData(data, parentData) {
      if (data && data.length > 0) {
        data.forEach((item) => {
          item.parentName = parentData
          if (item.id === this.activeDepartId) {
            this.activeDepartExit = true
          }
          if (item.children && item.children.length > 0) {
            this.handleDepartData(item.children, item.label)
          }
        })
      }
    },
    handleCheckChange(data, checked, indeterminate) {
      this.getCheckedKeys()
    },
    getCheckedKeys() {
      this.expandedKeys = this.$refs.departTreeRef.getCheckedKeys()
      this.checkedKeys = this.$refs.departTreeRef.getCheckedKeys()
      console.log('getCheckedKeys', this.checkedKeys)
    },
    confirm() {
      this.getCheckedKeys()
      this.$emit('getPart', this.checkedKeys, this.$refs.departTreeRef.getCheckedNodes())
      this.cancel()
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .no-data-show-text {
  margin: 0px 0px 20px 0px !important;
}
.select-content {
  min-width: 260px;
  .select-tree {
    max-height: calc(70vh - 120px);
    overflow-y: auto;
    min-height: 100px;
  }
  .btn-list {
    .flex-l();
    justify-content: flex-end;
    .edit-btn {
      padding: 0px 16px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      font-size: 14px;
      color: #fff;
      border: 0;
      border-radius: 12px;
      margin-top: 4px;
    }
    .cancel {
      border: 1px solid #3a78f6;
      color: #3a78f6;
      background: #fff;
      margin-right: 8px;
      &:hover {
        background: #f3f7fe;
      }
    }
    .confirm {
      border: 1px solid #3a78f6;
      color: #fff;
      background: #3a78f6;
      &:hover {
        background: #005eff;
      }
    }
  }
}
.select-btn {
  cursor: pointer;
  span {
    margin-right: 8px;
  }
  color: #6e7491;
  .el-select__caret {
    transform: rotate(180deg);
  }
}
.select-btn-true {
  cursor: not-allowed;
}
::v-deep {
  .el-tree-node__content {
    height: 40px;
    border-radius: 8px;
    color: #6e7491;
  }
  .el-tree-node__expand-icon {
    color: #6e7491;
    font-size: 14px;
  }
  .el-tree-node__expand-icon.is-leaf {
    color: transparent;
    cursor: default;
  }
  .el-tree__empty-block {
    display: none;
  }
}
</style>
