<template>
  <div :class="tabState == true ? 'list-item-list' : 'list-item-detail'">
    <div class="list-item" @click.stop="detail">
      <div class="left-box" :class="bgColorstate" @click.stop>
        <state-picker :status="row.status" :disabled="isDisabledEdit" @changeStatus="updateStatus" />
      </div>
      <div
        class="right-box"
        :class="[
          { 'right-box-todo': tabState == true },
          { 'grey-item': row.status == '3' }
        ]"
      >
        <div class="content-box">
          <div class="task-title">
            <div class="task-title-box">
              <el-tooltip :open-delay="300" :content="row.title">
                <div class="ellipsis-task-1">{{ row.title }}</div>
              </el-tooltip>
            </div>
            <div v-show="!tabState" class="avatar-img-box border-radius-20">
              <i class="head-icon" v-if="row.originatorId === null"></i>
              <avatar-locked
                v-else
                :avatar="row.originatorAvatar"
                :size="24"
                :locked="row.isShare === 1"
                class="avatar-img"
              />
            </div>
          </div>
          <div class="bottom-info flex-new">
            <div class="flex-new">
              <template v-if="!isFinishOrStale">
                <div v-if="isSetTime || showProgress" class="date-box minHeight">
                  <div v-if="row.startDate || row.endDate" class="flex-1">
                    <div
                      :class="
                        row.status != 3
                          ? 'dateColor' +
                            $utils.getDateBgColor(row.startDate, row.endDate)
                          : ''
                      "
                      class="date-txt"
                    >
                      <template v-if="!row.startDate && row.endDate">
                        {{ $utils.formatRecentTime(row.endDate, 'page', 1) }}
                        截止
                      </template>
                      <template v-if="row.startDate && row.endDate">
                        {{ $utils.formatRecentTime(row.startDate, 'page', 1) }}
                        -
                        {{ $utils.formatRecentTime(row.endDate, 'page', 1) }}
                      </template>
                      <template v-if="row.startDate && !row.endDate">
                        {{ $utils.formatRecentTime(row.startDate, 'page', 1) }}
                        开始
                      </template>
                    </div>
                  </div>
                </div>
                <div v-if="isCycle || isRemindTime || !row.keyTitle" class="remind-box minHeight">
                  <div v-if="isCycle" class="cycle">{{ cycleFmt(row.cycleDate) }}</div>
                </div>
              </template>
            </div>
            <div class="flex-new">
              <div v-if="isRemindTime" class="remind-time-box">
                <Icon v-if="isRemindTime" icon-class="icon-remind" class="remind-time-icon" />
              </div>
              <div class="kr-counts-right">
                <div
                  v-if="
                    row.taskCollaboratorList &&
                    row.taskCollaboratorList.length > 0 &&
                    row.status != 3
                  "
                  class="task-num task-num-read"
                >
                  <el-popover
                    :ref="`popover-${row.id}`"
                    placement="bottom-start"
                    trigger="hover"
                    popper-class="read-popover"
                    :visible-arrow="false"
                    :open-delay="300"
                  >
                    <ReadList
                      ref="readList"
                      :read-arr="row.taskCollaboratorList"
                      class="read-list-div"
                    />
                    <div slot="reference" class="task-num-read-left">
                      <el-progress
                        class="read-progress"
                        type="circle"
                        :percentage="percentage(row)"
                        :show-text="false"
                        :stroke-width="30"
                      ></el-progress>
                      <!-- <div class="round" :class="'round' + row.id">
                        {{ percentage(row) }}
                      </div>-->
                      <span
                        class="unread-span"
                        v-if="unReadCount(row) != 0"
                      >{{ unReadCount(row) }}人未读</span>
                      <span v-else class="unread-span">全部已读</span>
                    </div>
                  </el-popover>
                </div>

                <div
                  v-if="parseInt(row.allSonCount) > 0"
                  class="task-num"
                  style="margin-left: 20px; display: flex"
                >
                  <div class="task-num-img">
                    <Icon class="remind-work-icon set-svg" icon-class="icon-tasksubicon" />
                  </div>
                  <div v-if="parseInt(row.allSonCount) > 99" class="task-num-value">99+</div>
                  <div v-else class="task-num-value">{{ row.allSonCount }}</div>
                </div>
              </div>
              <div v-show="tabState" class="avatar-img-box border-radius-20">
                <avatar-locked
                  :avatar="row.originatorAvatar"
                  :size="34"
                  :locked="row.isShare === 1"
                  class="avatar-img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 优先级边框 -->
      <div v-if="priorityVisible" :style="priorityStyle" class="priority-box"></div>
    </div>
  </div>
</template>
<script>
import taskEnum from '@/utils/enums/task.js' // 任务字典
import repeatEnum from '@/utils/enums/repeat.js' // 重复周期字典
import cycleEnum from '@/utils/enums/cycle.js' // 重复周期字典
import statePicker from '@/views/todo/components/TaskStatePicker'
import ReadList from '../../../todo/components/TaskItem/readList.vue' // 已读未读列表
export default {
  components: {
    statePicker,
    ReadList
  },
  props: {
    // 数据
    row: {
      type: Object,
      required: true
    },
    // 显示优先级
    priorityVisible: {
      type: Boolean,
      required: false
    },
    // tab切换状态
    tabState: {
      type: Boolean,
      required: false,
      default: false
    },
    lenArrLength: {
      type: Number,
      required: false
    },
    indexNum: {
      type: Number,
      required: false
    },
    type: {
      type: String
    }
  },
  data() {
    return {
      id: null, // id
      readEnterActive: false, // 鼠标是否悬浮在已读未读上
      readStyle: {}, // 已读未读样式
      readVisible: false, // 已读未读显示
      statusPickerVisible: false, // 状态选择是否显示
      panelPickerVisible: false, // 面板
      taskStyle: {}, // 子任务样式
      taskVisible: false, // 子任务显示
      taskEnterActive: false // 鼠标是否悬浮在子任务上
    }
  },
  computed: {
    // 背景颜色的改变
    bgColorstate() {
      if (this.row.status === '1') {
        return 'left-box-todo-not'
      } else if (this.row.status === '2') {
        return 'left-box-todo-ing'
      } else if (this.row.status === '3') {
        return 'left-box-todo-done'
      }
      return ''
    },
    // 是否禁止编辑
    isDisabledEdit() {
      const permission = `${this.row.taskOperationPermission}`
      if (permission === '0' || this.row.submitterType === 1) {
        // 查看者
        return true
      } else if (permission === '1') {
        // 任务协作人
        return false
      } else if (permission === '2') {
        // 任务负责人
        return false
      } else if (permission === '3') {
        // 任务创建人
        return false
      } else if (permission === '4') {
        // 目标创建人 共享人
        return false
      }
      return true
    },
    // 状态是否为完成或搁置
    isFinishOrStale() {
      const isStale = this.row.conditionsIsDefault === 0 // conditionsIsDefault 1 默认不展开  0 展开
      return isStale
    },
    // 是否设置时间
    isSetTime() {
      const { startDate, endDate } = this.row
      return startDate || endDate
    },
    // 是否设置提醒时间
    isRemindTime() {
      if (this.row.remindDate) {
        if (this.row.remindDate.dateType === 0) {
          return false
        }
      }
      return this.row.remindDate && this.row.remindDate.id
        ? this.row.remindDate.id
        : ''
    },
    // 优先级样式
    priorityStyle() {
      if (this.row.priority && this.row.priority.colour) {
        return `background:${this.row.priority.colour}`
      }
      // const priorityEnum = taskEnum.PRIORITYTYPE
      // const map = {}
      // map[priorityEnum.hight] = 'hight'
      // map[priorityEnum.normal] = 'normal'
      // map[priorityEnum.low] = 'low'
      // if (this.row.priority) {
      //   return map[this.row.priority.id]
      // } else {
      //   return map[3]
      // }
    },
    // 是否设置重复周期
    isCycle() {
      const cycleDate = this.row.cycleDate
      if (cycleDate) {
        return cycleDate.type !== cycleEnum.TYPE.not
      } else {
        return false
      }
    },
    // 显示已读未读
    showProgress() {
      const chargeUserList = this.row.chargeUserList
      return chargeUserList && chargeUserList.length
    }
  },
  created() {
    // 监听隐藏状态picker事件
    this.bus(this).$on('global:hideStatusPicker', () => {
      this.statusPickerVisible = false
    })
    // 监听滚动事件，关闭弹窗
    window.addEventListener(
      'scroll',
      () => {
        this.statusPickerVisible = false
      },
      true
    )
  },
  mounted() {
    // 点击空白处添加人员列表关闭
    document.documentElement.addEventListener(
      'click',
      () => {
        this.statusPickerVisible = false
      },
      false
    )
  },
  methods: {
    // percentage(row) {
    //   const style = document.createElement('style')
    //   document.head.appendChild(style)
    //   const { sheet } = style
    //   var count
    //   var readlist
    //   if (row.taskCollaboratorList && row.taskCollaboratorList.length > 0) {
    //     readlist = row.taskCollaboratorList.filter((item) => {
    //       return item.status === 2
    //     })
    //   }
    //   var unreadlist
    //   if (row.taskCollaboratorList && row.taskCollaboratorList.length > 0) {
    //     unreadlist = row.taskCollaboratorList.filter((item) => {
    //       return item.status === 1
    //     })
    //   }
    //   if (!readlist || readlist.length === 0) {
    //     sheet.addRule(`.round${row.id}`, `background-color: #fff !important`)
    //     return
    //   }
    //   if (!unreadlist || unreadlist.length === 0) {
    //     console.log(0)
    //     sheet.addRule(
    //       `.round${row.id}`,
    //       `background-color: #3068ff !important;transform: rotate(1turn) !important`
    //     )
    //     sheet.addRule(
    //       `.round${row.id}::before`,
    //       `animation-delay:-${0}s !important;`
    //     )
    //     return
    //   }

    //   count = (
    //     parseInt(unreadlist.length) / parseInt(row.taskCollaboratorList.length)
    //   ).toFixed(2)
    //   sheet.addRule(`.round${row.id}`, `background-color: #3068ff !important`)
    //   sheet.addRule(
    //     `.round${row.id}::before`,
    //     `animation-delay:-${count}s !important;`
    //   )
    // },
    percentage(row) {
      var count
      var readlist
      if (row.taskCollaboratorList && row.taskCollaboratorList.length > 0) {
        readlist = row.taskCollaboratorList.filter((item) => {
          return item.status === 2
        })
      }
      var unreadlist
      if (row.taskCollaboratorList && row.taskCollaboratorList.length > 0) {
        unreadlist = row.taskCollaboratorList.filter((item) => {
          return item.status === 1
        })
      }
      count =
        (
          parseInt(readlist.length) / parseInt(row.taskCollaboratorList.length)
        ).toFixed(2) * 100
      return count
    },
    unReadCount(row) {
      this.$nextTick(() => {
        this.$refs.readList.listInit()
      })
      var unreadlist
      if (row.taskCollaboratorList && row.taskCollaboratorList.length > 0) {
        unreadlist = row.taskCollaboratorList.filter((item) => {
          return item.status === 1
        })
        return unreadlist.length
      }
    },
    statusModalColor(key) {
      if (key === '1') {
        return 'state-modal-normal'
      } else if (key === '2') {
        return 'state-modal-ing'
      } else if (key === '3') {
        return 'state-modal-done'
      }
    },
    // 显示状态picker
    showStatusPicker() {
      this.$emit('global:hideStatusPicker')
      if (!this.isDisabledEdit) {
        this.statusPickerVisible = true
      }
    },
    updateStatus: async function (status) {
      var that = this
      that.$emit('global:hideStatusPicker')
      if (that.isDisabledEdit) {
        return
      }

      const _obj = {}
      _obj.id = that.row.id
      _obj.title = that.row.title
      _obj.status = status
      this.$apis.TASKSTATECHANGE(_obj).then((res) => {
        if (res.status === 200) {
          const obj_delete = {}
          obj_delete.id = that.row.id // 当前列的id
          obj_delete.cycleDateId = that.row.cycleDateId || ''
          if (_obj.status === '1') {
            that.$message.success('状态已修改为待处理!')
          } else if (_obj.status === '2') {
            that.$message.success('状态已修改为进行中!')
          } else if (_obj.status === '3') {
            that.$message.success('状态已修改为已完成!')
          } else if (_obj.status === '4') {
            that.$message.success('状态已修改为搁置!')
          }
          that.$emit('deleteCard', obj_delete) // 传递给父组件  子组件向父组件传值
          this.bus.$emit('getTaskItemKeyResults')
        } else if (res.status === 71001) {
          that.$message.error(res.message)
        } else if (res.status === 71005) {
          that.$message({ msg: res.message, type: 'warn' })
        } else {
          that.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 详情
    detail() {
      this.id = this.row.id
      // 监听打开主任务详情页面
      if (parseInt(this.row.fatherTid) > 0) {
        if (this.type === 'okrList') {
          this.$emit(
            'todoSubTask',
            {
              id: this.id,
              fatherTid: -1
            },
            'okrDetail'
          ) // 子任务
        } else {
          this.$emit(
            'global:todoSubTask',
            {
              id: this.id,
              fatherTid: -1
            },
            'okrDetail'
          ) // 子任务
        }
      } else {
        if (this.type === 'okrList') {
          this.$emit('taskMainDetail', this.id, 'okrDetail')
        } else {
          this.$emit('global:taskMainDetail', this.id, 'okrDetail')
        }
      }
    },
    // 重复周期格式化
    cycleFmt(row) {
      if (row && row.type) {
        const typeDict = repeatEnum.REPEATTYPE
        return typeDict[row.type]
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../../../assets/styles/variable.scss';
.task-num {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // padding-top: 10px;
  .unread-span {
    font-size: 14px;
    font-weight: 400;
    color: #a0a3bd;
    display: inline-block;
    margin-left: 8px;
  }
  .unread-span:hover {
    color: #3a78f6;
  }
  .task-num-img {
    font-size: 16px;
    color: #a0a3bd;
    .set-svg {
      &:hover {
        color: #3a78f6;
      }
    }
    .svg-icon {
      width: 16px;
      height: 16px;
      margin-right: 14px;
    }
  }
  .task-num-value {
    width: 28px;
    color: #a0a3bd;
    font-size: 16px;
    text-align: center;
    line-height: 14px;
  }
}
::v-deep {
  .el-progress.read-progress {
    .el-progress-circle {
      width: 20px !important;
      height: 20px !important;
    }
  }
}
.flex-new {
  align-items: center !important;
}
.round {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px #3068ff solid;
  background: #3068ff;
  background-image: linear-gradient(to right, transparent 50%, #fff 0);
}

.round::before {
  content: '';
  display: block;
  margin-left: 50%;
  height: 100%;
  background-color: inherit;
  border-radius: 0 100% 100% 0 /50%;
  transform-origin: left;
  transform: rotate(0turn);
  animation: spin 0.5s linear infinite, changeBG 1s step-end infinite;
  animation-play-state: paused; /* 设置动画为暂停状态 */
}
@keyframes spin {
  to {
    transform: rotate(0.5turn);
  }
}

@keyframes changeBG {
  50% {
    background-color: #fff;
  }
}
.kr-counts-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
  // margin-top: 8px;
  .task-num-read {
    flex: 1;
    position: relative;
    .read-list-div {
      position: absolute;
      top: 45px;
      right: 0;
      z-index: 1;
    }
    .task-num-read-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      min-width: 100px;
      justify-content: flex-end;
    }
    .unread-span {
      display: inline-block;
      margin-left: 6px;
    }
  }
}
.list-item-detail {
  .list-item {
    cursor: pointer;
    background-color: #fff;
    // height: 92px;
    width: calc(100% + 16px);
    margin-bottom: 4px;
    border-radius: 8px;
    display: flex;
    align-items: flex-start;
    padding: 8px 16px;
    position: relative;
    .left-box {
      position: relative;
      .color-icon-check-working {
        color: #444558 !important;
        position: absolute;
        left: 16px;
        top: 13px;
      }
      .pane {
        background: #fff;
        border: 1px solid #444558;
        position: relative;
        height: 16px;
        width: 16px;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          .checked {
            display: none;
          }
        }
        .checked {
          width: 8px;
          height: 8px;
        }
        .status-txt {
          border-radius: 8px;
          height: 36px;
          line-height: 36px;
          width: 92px;
          padding: 0px;
          //  text-align: center;
        }
        .status-txt span {
          padding: 0px 8px;
          border-radius: 4px;
          border: 1px solid #444558;
          margin: 0px 8px;
        }
        .state-modal-normal {
          .status-txt {
            background: #f4f4f4;
          }
        }
        .state-modal-ing {
          .status-txt {
            color: #1066ff;
            background: #e7efff;
          }
          .color-icon-check-working {
            color: #1066ff !important;
          }
        }
        .state-modal-done {
          .status-txt {
            color: #00bf4c;
            background: #edfcf6;
          }
          .color-icon-check-working {
            color: #00bf4c !important;
          }
        }
        .state-modal-normal {
          .status-txt span {
            border: 1px solid #444558;
          }
        }
        .state-modal-ing {
          .status-txt span {
            border: 1px solid #005eff;
          }
        }
        .state-modal-done {
          .status-txt span {
            // border:1px solid #00BA88;
          }
        }
      }
      .status-box {
        width: 100px;
        // left: 18px;
        // top: -2px;
        .status-item {
          display: flex;
          align-items: center;
          position: relative;
        }
      }
      .bottoms_position {
        top: -76px;
      }
    }
    .left-box-todo-not {
      margin-top: 0px;
      position: relative;
      padding-top: 4px;
      border-radius: 4px;
      .content {
        .pane {
          border: 1px solid #a0a3bd;
          &::before {
            border-top: 7px solid #a0a3bd;
          }
        }
      }
    }
    .left-box-todo-ing {
      margin-top: 0px;
      position: relative;
      padding-top: 4px;
      border-radius: 4px;
      .content {
        .pane {
          border: 1px solid #66a2ff;
          &::before {
            border-top: 7px solid #66a2ff;
          }
        }
      }
    }
    .left-box-todo-done {
      margin-top: 0px;
      position: relative;
      padding-top: 4px;
      border-radius: 4px;
      .content {
        .pane {
          border: 1px solid #70dcbf;
          &::before {
            border-top: 7px solid #70dcbf;
          }
        }
      }
    }
    .left-box-todo-not,
    .left-box-todo-done,
    .left-box-todo-ing {
      .content {
        .pane {
          position: relative;
          &::before {
            content: '';
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 7px solid transparent;
            position: absolute;
            top: 5px;
            left: 2px;
            display: none;
          }
          &:hover {
            &::before {
              display: block;
            }
          }
        }
        .state-modal-ing {
          .status-txt {
            color: #1066ff;
            background: #e7efff;
          }
        }
        .state-modal-done {
          .status-txt {
            color: #00bf4c;
            background: #edfcf6;
          }
        }
      }
    }
    .right-box {
      flex: 1;
      min-width: 0;
      padding-left: 10px;
      &.grey-item {
        opacity: 0.5;
        filter: alpha(opacity=50); //filter 过滤器   兼容IE678
        .pane {
          border: 1px solid #444558;
        }
      }
      .content-box {
        width: 100%;
        .task-title {
          display: flex;
          justify-content: space-between;
          min-height: 36px;
          align-items: center;
          .task-title-box {
            margin-right: 20px;
            min-width: 0;
            .ellipsis-task-1 {
              line-height: 22px;
              font-size: 14px;
              color: #6e7491;
            }
          }
        }

        .bottom-info {
          justify-content: space-between;
          margin-top: 8px;
          .remind-time-box {
            width: 24px;
            margin-right: 3px;
            .remind-time-icon {
              font-size: 20px;
              color: #ff9900;
              cursor: pointer;
              margin-top: 3px;
              width: 20px;
              height: 20px;
            }
          }
        }
        .avatar-img-clock {
          width: 22px;
          height: 22px;
        }
        .avatar-img-box {
          position: relative;
          height: 24px;
          width: 24px;
          border-radius: 50%;
          overflow: hidden;
          flex-shrink: 0;
          .head-icon {
            display: inline-block;
            width: 26px;
            height: 26px;
            background: url('~@/assets/imgs/pm/head-icon.png') no-repeat;
            background-size: 100% 100%;
          }
          .avatar-img {
            position: absolute;
            right: 0;
            height: 24px;
            width: 24px;
          }
        }
      }
      .date-box {
        display: flex;
        flex-shrink: 0;
        margin-right: 8px;
        align-items: center;
        .date-txt {
          border-radius: 8px;
          display: inline-block;
          height: 24px;
          font-size: 12px;
          line-height: 24px;
          padding: 0 10px;
          color: #444558;
        }
        .dateColor2 {
          color: #00ba88 !important;
        }
        .dateColor3 {
          color: #f93300 !important;
        }
      }
      .progress {
        margin-right: 7px;
      }
      .remind-box {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        .cycle {
          background-color: #ed2e7e;
          border-radius: 8px;
          color: #fff;
          font-size: 12px;
          height: 24px;
          margin-right: 8px;
          line-height: 24px;
          text-align: center;
          padding: 0 10px;
        }
      }
      .kr-box {
        color: #444558;
        display: flex;
        font-size: 12px;
        .kr-text {
        }
        .kr-box-kr {
          padding: 3px 8px;
        }
      }
      .temp-box {
        background-color: #f0f0f0;
        color: #444558;
        display: inline-block;
        font-size: 12px;
        height: 20px;
        line-height: 20px;
        margin: 5px -16px 0px 0;
        padding: 0 12px 0 2px;
        position: relative;
        .triangle {
          border: 10px solid transparent;
          border-right-color: #f0f0f0;
          left: -20px;
          position: absolute;
        }
      }
      .temp-box_task {
        background-color: #fff;
        color: #444558;
        display: inline-block;
        font-size: 12px;
        height: 20px;
        line-height: 20px;
        padding: 0 10px 0 2px;
        .triangle_task {
          width: 26px;
          height: 26px;
          cursor: pointer;
        }
      }
    }
    .priority-box {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 6px;
      &.hight {
        background-color: #eb0d0d;
      }
      &.normal {
        background-color: #ffb10b;
      }
      &.low {
        display: none;
        background-color: #999999;
      }
    }
    &:hover {
      background: rgba(245, 246, 250, 0.54);
      .priority-box {
        display: block;
        width: 6px;
      }
    }
    .minHeight {
      min-height: 18px;
    }
    .kr-counts-position {
      position: relative;
      .kr-box {
        .kr-text {
        }
        .kr-text-name {
          background: #3068ff;
          border-radius: 8px;
          font-weight: bold;
          padding: 3px 8px;
          color: #fff;
        }
        .kr-text-color {
          color: #666;
          padding: 3px 8px 3px 0;
          max-width: 270px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &:before {
            content: '';
            display: block;
          }
        }
      }
    }
    .kr-counts-position-inner {
      .kr-box {
        float: left;
      }
    }
  }
  .read-user-box {
    width: 190px;
    background: #fff;
    box-shadow: 0px 2px 5px 0px rgba(51, 51, 51, 0.3);
    border-radius: 4px;
    position: fixed;
    z-index: 1;
    .read-user-nav {
      border-bottom: 1px solid #dedede;
      display: flex;
      line-height: 36px;
      text-align: center;
      .nav-item {
        flex: 1;
        color: #999999;
      }
      .active {
        border-bottom: 1px solid #25abee;
        color: #25abee;
      }
    }
    .read-user-cont {
      max-height: 440px;
      padding: 15px 0;
      // @include scroll-beautify;
      .user-item {
        line-height: 40px;
        &:hover {
          background: #f4f8fc;
        }
      }
    }
    .avatar-img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-right: 15px;
    }
  }
  .to-edit-input-allow {
    cursor: pointer;
  }
  .to-edit-input-not {
    cursor: not-allowed;
  }
}
.list-item-list {
  .list-item {
    background-color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px 24px 10px 32px;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    width: 100%;
    .left-box {
      position: relative;
      width: 90px;
      flex-shrink: 0;
      .pane {
        background: transparent;
        border: 2px solid #a0a3bd;
        position: relative;
        height: 18px;
        width: 18px;
        border-radius: 3px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          .checked {
            display: none;
          }
        }
        .checked {
          width: 9px;
          height: 9px;
        }
        .state-modal-normal {
          .status-txt {
            background: #f4f4f4;
            border-radius: 8px;
          }
        }
        .state-modal-ing {
          .status-txt {
            color: #1066ff;
            background: #e7efff;
            border-radius: 4px;
          }
        }
        .state-modal-done {
          .status-txt {
            color: #00bf4c;
            background: #edfcf6;
            border-radius: 4px;
          }
        }
      }
      .status-box {
        width: 120px;
        // left: 18px;
        // top: -2px;
      }
      .bottoms_position {
        top: -76px;
      }
    }
    .left-box-todo-not {
      margin-top: 0px;
      position: relative;
      padding: 5px 6px;
      border-radius: 4px;
      .content {
        .pane {
          border: 1px solid #a0a3bd;
          &::before {
            border-top: 7px solid #a0a3bd;
          }
        }
        p.left-box-todo-p-not {
          color: #444558;
          font-size: 16px;
          margin-left: 8px;
          line-height: 18px;
        }
      }
    }
    .left-box-todo-ing {
      margin-top: 0px;
      position: relative;
      padding: 5px 6px;
      border-radius: 4px;
      .content {
        .pane {
          border: 1px solid #66a2ff;
          &::before {
            border-top: 7px solid #66a2ff;
          }
        }
        p.left-box-todo-p-ing {
          color: #005eff;
          font-size: 16px;
          margin-left: 8px;
          line-height: 18px;
        }
      }
    }
    .left-box-todo-done {
      margin-top: 0px;
      position: relative;
      padding: 5px 6px;
      border-radius: 4px;
      .content {
        .pane {
          border: 1px solid #70dcbf;
          &::before {
            border-top: 7px solid #70dcbf;
          }
        }
        p.left-box-todo-p-done {
          color: #00ba88;
          font-size: 16px;
          margin-left: 8px;
          line-height: 18px;
        }
      }
    }
    .left-box-todo-not,
    .left-box-todo-done,
    .left-box-todo-ing {
      .content {
        .pane {
          position: relative;
          &::before {
            content: '';
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 7px solid transparent;
            position: absolute;
            top: 5px;
            left: 3px;
            display: none;
          }
          &:hover {
            &::before {
              display: block;
            }
          }
        }
        .state-modal-ing {
          .status-txt {
            color: #1066ff;
            background: #e7efff;
          }
        }
        .state-modal-done {
          .status-txt {
            color: #00bf4c;
            background: #edfcf6;
          }
        }
      }
    }
    .right-box {
      flex: 1;
      min-width: 0;
      padding-left: 10px;
      &.grey-item {
        opacity: 0.5;
        filter: alpha(opacity=50); //filter 过滤器   兼容IE678
        .pane {
          border: 1px solid #999999;
        }
      }
      .content-box {
        display: flex;
        justify-content: space-between;
        .task-title {
          min-width: 0;
          margin-right: 8px;
          .ellipsis-task-1 {
            line-height: 36px;
            color: #1b1b4e;
            font-size: 15px;
          }
        }
        .bottom-info {
          flex-shrink: 0;
          display: flex;
          align-items: center;
          // max-width: 395px;
          .remind-time-icon {
            width: 22px;
            height: 34px;
            font-size: 20px;
            color: #ff9900;
            cursor: pointer;
          }
        }
        .avatar-img-clock {
          width: 22px;
          height: 22px;
        }
        .avatar-img-box {
          position: relative;
          width: 34px;
          height: 34px;
          border-radius: 50%;
          overflow: hidden;
          margin-left: 8px;
          .avatar-img {
            height: 34px;
            width: 34px;
          }
        }
      }
      .date-box {
        display: flex;
        flex-shrink: 0;
        margin-right: 8px;
        align-items: center;
        .date-txt {
          border-radius: 8px;
          color: #444558;
          display: inline-block;
          height: 24px;
          font-size: 12px;
          line-height: 24px;
          padding-right: 10px;
        }
        .dateColor2 {
          color: #00ba88 !important;
        }
        .dateColor3 {
          color: #f93300 !important;
        }
      }
      .progress {
        margin-right: 7px;
      }
      .remind-box {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        .cycle {
          background-color: #0064ff;
          border-radius: 8px;
          color: #fff;
          font-size: 12px;
          height: 24px;
          margin-right: 8px;
          line-height: 24px;
          text-align: center;
          padding: 0 10px;
        }
      }
      .kr-box {
        color: #444558;
        display: flex;
        font-size: 12px;
        .kr-text {
        }
        .kr-box-kr {
          padding: 3px 8px;
        }
      }
      .temp-box {
        background-color: #f0f0f0;
        color: #444558;
        display: inline-block;
        font-size: 12px;
        height: 20px;
        line-height: 20px;
        margin: 5px -16px 0px 0;
        padding: 0 12px 0 2px;
        position: relative;
        .triangle {
          border: 10px solid transparent;
          border-right-color: #f0f0f0;
          left: -20px;
          position: absolute;
        }
      }
      .temp-box_task {
        background-color: #fff;
        color: #444558;
        display: inline-block;
        font-size: 12px;
        height: 20px;
        line-height: 20px;
        padding: 0 10px 0 2px;
        .triangle_task {
          width: 26px;
          height: 26px;
          cursor: pointer;
        }
      }
    }
    .priority-box {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 5px;
      &.hight {
        background-color: #eb0d0d;
      }
      &.normal {
        background-color: #ffb10b;
      }
      &.low {
        display: none;
        background-color: #999999;
      }
    }
    &:hover {
      background: #fff;
      box-shadow: 0px 8px 16px 0px rgba(17, 17, 17, 0.04);
      .priority-box {
        display: block;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        width: 8px;
      }
      .left-box-todo-not {
        background: #f7f7fc;
      }
      .left-box-todo-ing {
        background: #eef6fd;
      }
      .left-box-todo-done {
        background: #e9fbf5;
      }
    }
    .minHeight {
      min-height: 18px;
    }
    .kr-counts-position {
      position: relative;
      .kr-box {
        .kr-text {
        }
        .kr-text-name {
          background: #3068ff;
          border-radius: 8px;
          font-weight: bold;
          padding: 3px 8px;
          color: #fff;
        }
        .kr-text-color {
          color: #666;
          padding: 3px 8px 3px 0;
          max-width: 350px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &:before {
            content: '';
            display: block;
          }
        }
      }
    }
    .kr-counts-position-inner {
      .kr-box {
        float: left;
      }
    }
  }
  .read-user-box {
    width: 190px;
    background: #fff;
    box-shadow: 0px 2px 5px 0px rgba(51, 51, 51, 0.3);
    border-radius: 4px;
    position: fixed;
    z-index: 1;
    .read-user-nav {
      border-bottom: 1px solid #dedede;
      display: flex;
      line-height: 36px;
      text-align: center;
      .nav-item {
        flex: 1;
        color: #999999;
      }
      .active {
        border-bottom: 1px solid #25abee;
        color: #25abee;
      }
    }
    .read-user-cont {
      max-height: 440px;
      padding: 15px 0;
      // @include scroll-beautify;
      .user-item {
        line-height: 40px;
        &:hover {
          background: #f4f8fc;
        }
      }
    }
    .avatar-img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-right: 15px;
    }
  }
  .to-edit-input-allow {
    cursor: pointer;
  }
  .to-edit-input-not {
    cursor: not-allowed;
  }
}
.status-box {
  width: 120px;
  position: relative;
  .status-item {
    display: flex;
    align-items: center;
    position: relative;
  }
  .state-modal-normal {
    .status-txt {
      background: #f4f4f4;
      border-radius: 8px;
    }
  }
  .state-modal-ing {
    .status-txt {
      color: #1066ff;
      background: #e7efff;
      border-radius: 4px;
    }
  }
  .state-modal-done {
    .status-txt {
      color: #00bf4c;
      background: #edfcf6;
      border-radius: 4px;
    }
  }
}
</style>
<style lang="scss">
.el-popover.status-picker-popover {
  height: 0;
  border: 0 !important;
  padding: 0 !important;
}
.color-icon-check-working {
  color: #1066ff !important;
  line-height: 20px;
}
.read-popover {
  padding: 0;
}
</style>
>
