<template>
  <el-dialog
    title="申请试用延期"
    :visible.sync="dialogVisible"
    width="432px"
    :modal="false"
    :append-to-body="true"
    :modal-append-to-body="true"
    :show-close="true"
    :before-close="handleClose"
    class="apply-demo-dialog"
  >
    <div class="apply-demo-wrap">
      <applyContent @close="handleClose" />
    </div>
  </el-dialog>
</template>
<script>
import applyContent from '@/views/global/layout/header/components/applyDelay/applyContent'
export default {
  components: {
    applyContent
  },
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  props: {
    dialogShow: {
      type: Boolean,
      default: true
    },
    type: {
      default: 'dialog'
    }
  },
  data() {
    return {
      dialogVisible: this.dialogShow,
      processDialog: false
    }
  },
  watch: {
    dialogShow: {
      handler(val) {
        this.dialogVisible = val
      }
    }
  },
  created() {
  },
  methods: {
    // 关闭弹框
    handleClose() {
      this.$emit('change', false)
      this.dialogVisible = false
    }
  }
}
</script>
<style lang="less" scoped>
.apply-demo-dialog {
  justify-content: center !important;
  z-index: 9999999 !important;
  background: rgba(0, 0, 0, 0.5);
}
::v-deep {
  .el-dialog {
    margin: auto 24px !important;
  }
  .el-dialog__header {
    padding: 32px 24px 0px 24px;
  }
  .el-dialog__body {
    padding: 20px 24px 24px 24px;
  }
}
</style>
