// 企信迁移后新增 楚
const state = {
  token: '',
  userInfo: JSON.parse(localStorage.getItem('okrt-userInfo')), // 用户信息
  companyInfo: JSON.parse(localStorage.getItem('okrt-companyInfo')), // 公司信息
  roleIdsObj: [], // 角色
  isFirstBtn: 1, // 引导按钮 1:显示  0 隐藏
  versionNumber: 'v1', // 版本
  applyCompanyList: [], // 待审核的企业列表
  routerChange: true, // 存储路由变化
  code: '', // 存储code变化
  wxRedictData: '44',
  cycleArrs: [], // 周期数组
  analyseFilter: {
    quarterId: '',
    periodId: '',
    departListId: []
  },
  h5PayInfo: JSON.parse(localStorage.getItem('okrtApp-h5PayInfo')), // h5支付信息
  ddRedictData: '',
  satisfactionDictionary: [],
  redirectUrlState: false,
  approvalCheckData: '',
  conclusionSetData: ''
}
const actions = {}
const mutations = {
  setConclusionSetData(state, data) {
    state.conclusionSetData = data
  },
  setApprovalCheckData(state, data) {
    state.approvalCheckData = data
  },
  setH5PayInfo(state, h5PayInfo) {
    localStorage.setItem('okrtApp-h5PayInfo', JSON.stringify(h5PayInfo))
    state.h5PayInfo = h5PayInfo
  },
  setRedirectUrlState(state, data) {
    state.redirectUrlState = data
  },
  // 设置token
  setToken(state, token) {
    localStorage.setItem('okrt-token', token)
    state.token = token
  },
  // 存储用户信息
  setUserInfo(state, userInfo) {
    localStorage.setItem('okrt-userInfo', JSON.stringify(userInfo))
    state.userInfo = userInfo
  },
  // 存储公司信息
  setCompanyInfo(state, companyInfo) {
    localStorage.setItem('okrt-companyInfo', JSON.stringify(companyInfo))
    state.companyInfo = companyInfo
  },
  // 存储用户角色数据
  setRoleIdsObj(state, list) {
    state.roleIdsObj = JSON.parse(decodeURIComponent(list)) || []
  },
  // 存储用户引导按钮显示
  setIsFirstBtn(state, number) {
    state.isFirstBtn = number
  },
  // 存储待审核的企业列表
  setApplyCompanyList(state, list) {
    state.applyCompanyList = list
  },
  // 存储路由变化
  setRouterChange(state, list) {
    state.routerChange = list
  },
  // 存储code变化
  setCode(state, code) {
    state.code = code
  },
  // 存储微信跳转的消息
  setWxRedictData(state, data) {
    state.wxRedictData = data
  },
  // 存储周期数组
  setCycleArrs(state, list) {
    state.cycleArrs = list
  },
  // 存储统计筛选条件
  setAnalyseFilter(state, list) {
    state.analyseFilter = list
  },
  setDdRedictData(state, data) {
    state.ddRedictData = data
  },
  // 满意度字典
  setSatisfactionDictionary(state, list) {
    state.satisfactionDictionary = list
  }
}
export default {
  namespaced: true,
  state,
  actions,
  mutations
}
