var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "step-box" }, [
    _c(
      "div",
      { staticClass: "step-index-top" },
      [
        _c("Img", {
          staticClass: "logo-img",
          attrs: { src: "/common/ic_logo.png" },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "step-index-main" }, [
      _vm.isFirstBtn == 0
        ? _c(
            "div",
            { staticClass: "step-left-img" },
            [
              _c("Img", {
                staticClass: "step1Img",
                attrs: { src: "/common/step1_tooltip.png" },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "step-index-left" }, [
        _c("div", { staticClass: "step-title" }, [_vm._v("创建OKR周期")]),
        _c("div", { staticClass: "step-text" }, [
          _vm._v("创建你的OKR周期，帮助组织聚焦目标"),
        ]),
        _c(
          "div",
          { staticClass: "step-radio-box" },
          [
            _c(
              "el-radio-group",
              {
                attrs: { size: "medium ", type: "vertical" },
                model: {
                  value: _vm.radioRole,
                  callback: function ($$v) {
                    _vm.radioRole = $$v
                  },
                  expression: "radioRole",
                },
              },
              _vm._l(_vm.isCreateNewUser, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "radio-step" },
                  [
                    _c("el-radio", { attrs: { label: item.ids } }, [
                      _vm._v(_vm._s(item.label)),
                    ]),
                  ],
                  1
                )
              }),
              0
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "step-button-div" },
          [
            _c(
              "L-button",
              {
                staticClass: "finish-button",
                attrs: { loading: _vm.finishLoading, color: "#3068ff" },
                on: { clickEvt: _vm.finish },
              },
              [_vm._v("完成")]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "step-index-right" }, [
        _c(
          "div",
          { staticClass: "step-right-img" },
          [
            _c("Img", {
              staticClass: "step1Img",
              attrs: { src: "/common/step1Img.png" },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "step-cycle" },
          [_c("CycleSelect", { attrs: { type: 1 } })],
          1
        ),
        _c(
          "div",
          [
            _c("StepOkr", {
              attrs: {
                comment: _vm.isFirstBtn == 0 ? _vm.comments : _vm.commentsOld,
                "keyresults-list":
                  _vm.isFirstBtn == 0 ? _vm.formList : _vm.formListOld,
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }