// 此文件注册全局组件
import Vue from 'vue'
import Img from './image/Image.vue'
import useElement from './element' // element-ui独立修改
import Icon from './global/Icon/index.vue'
import MoreMenu from './global/MoreMenu/moreMenuPop.vue'
import At from './global/At/index.vue'
import GuidePopover from './global/GuidePopover'
import HomeProgress from './global/HomeProgress'
import Select from './global/Select'
import AvatarImg from './global/AvatarImg'
import AvatarLocked from './global/AvatarLocked'
import MemberCard from './global/MemberCard'
import OkrDetail from '../views/okr/dialog/okrDetail'
import AddTarget from '../views/okr/dialog/addTarget/addTarget'
import UserPicker from './global/UserPicker'
import UserPickerChecked from './global/UserPickerChecked'
import taskAdd from '../views/todo/dialog/taskAdd'
import TaskDetail from '../views/todo/dialog/taskDetail'
import SubTaskDetail from '../views/todo/dialog/subTaskDetail'
import Editor from './global/Editor'
import BaseChat from './charts/index.vue'
import FileUpload from './global/FileUpload'
import singDropdown from './global/singDropdown'
import InviteByCode from './global/InviteByCode'
import InviterPersonOuter from './global/InviterPersonOuter'
// import CustomerService from './global/CustomerService'
import CustomerService from '@/views/global/layout/header/components/applyPrice/applyDialog'
import InviteFriends from './global/InviteFriends'
import ObjectiveLabel from './global/ObjectiveLabel'
import WxOpenData from './global/WxOpenData'
import AtContent from './global/AtContent'
import ImgLookDialog from './global/imgLookDialog'
import FilePreview from './global/FilePreview'
import LButton from './global/ButtonLoading'
import NoData from '@/views/okr/components/noData.vue'
import InsertLink from './global/InsertLink'
import DepartPicker from './global/DepartPicker'
import AlignMemberCard from './global/AlignMemberCard'
import EditorNew from './global/EditorNew'
export default () => {
  // 图片
  Vue.component('Img', Img)
  // 使用element
  useElement() // element-ui独立修改
  // 全局注册Icon
  Vue.component('Icon', Icon)
  // 全局注册下拉菜单
  Vue.component('MoreMenu', MoreMenu)
  // at输入框
  Vue.component('AtInput', At)
  // 引导起泡
  Vue.component('GuidePopover', GuidePopover)
  // 二次封装的Select
  Vue.component('Select', Select)
  // 头像含名字
  Vue.component('AvatarImg', AvatarImg)
  // 私密头像上锁
  Vue.component('AvatarLocked', AvatarLocked)
  // 个人名片
  Vue.component('MemberCard', MemberCard)
  // 目标详情
  Vue.component('OkrDetail', OkrDetail)
  // 目标新增
  Vue.component('AddTarget', AddTarget)
  // 选择成员-单选
  Vue.component('UserPicker', UserPicker)
  // 选择成员-多选
  Vue.component('UserPickerChecked', UserPickerChecked)
  // 任务新增
  Vue.component('taskAdd', taskAdd)
  // 主任务详情
  Vue.component('TaskDetail', TaskDetail)
  // 子任务详情
  Vue.component('SubTaskDetail', SubTaskDetail)
  // 富文本编辑器 ---目前适用任务详情，待扩展
  Vue.component('Editor', Editor)
  // 图表基础封装
  Vue.component('Echart', BaseChat)
  // 文件上传
  Vue.component('FileUpload', FileUpload)
  // 下拉菜单
  Vue.component('singDropdown', singDropdown)
  // 进度
  Vue.component('HomeProgress', HomeProgress)
  // 通过链接邀请
  Vue.component('InviteByCode', InviteByCode)
  // 外部人员邀请
  Vue.component('InviterPersonOuter', InviterPersonOuter)
  // 版本升级
  Vue.component('CustomerService', CustomerService)
  // 邀请人员
  Vue.component('InviteFriends', InviteFriends)
  // 目标标签
  Vue.component('ObjectiveLabel', ObjectiveLabel)
  // 微信通讯录
  Vue.component('WxOpenData', WxOpenData)
  // 微信@展示
  Vue.component('AtContent', AtContent)
  // 图片预览
  Vue.component('ImgLookDialog', ImgLookDialog)
  // 文件预览
  Vue.component('FilePreview', FilePreview)
  // 提交按钮-加载
  Vue.component('LButton', LButton)
  // 暂无数据
  Vue.component('NoData', NoData)
  // 富文本插入链接
  Vue.component('InsertLink', InsertLink)
  // 选择成员范围（部门及成员）
  Vue.component('DepartPicker', DepartPicker)
  // 对齐目标经过弹窗
  Vue.component('AlignMemberCard', AlignMemberCard)
  // 新编辑框
  Vue.component('EditorNew', EditorNew)
}
