var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "dropdown-wrap" },
        [
          _c(
            "el-dropdown",
            {
              ref: "dropdown",
              attrs: { trigger: "click", "hide-on-click": false },
            },
            [
              _c(
                "span",
                { staticClass: "permission-text" },
                [
                  _c("Icon", {
                    staticClass: "icon-privite",
                    attrs: {
                      iconClass:
                        _vm.item && _vm.item.lookRulesType == 0
                          ? "icon-public"
                          : "icon-simi",
                    },
                  }),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.lookRulesName[
                          (_vm.item && _vm.item.lookRulesType) || 0
                        ]
                      ) +
                      " "
                  ),
                  _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
                ],
                1
              ),
              !_vm.customRangeVisible
                ? _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c("div", { staticClass: "permission-select" }, [
                        _c(
                          "div",
                          { staticClass: "select-content" },
                          [
                            _vm._l(_vm.lookRulesName, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "select-type-list",
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectTypeClick(index)
                                    },
                                  },
                                },
                                [
                                  _c("div", {
                                    class: `select-type select-type-${
                                      ((_vm.selectItem &&
                                        _vm.selectItem.lookRulesType) ||
                                        0) == index.toString()
                                    }`,
                                  }),
                                  _vm._v(" " + _vm._s(item) + " "),
                                ]
                              )
                            }),
                            _vm.selectItem &&
                            _vm.selectItem.lookRulesType == "2"
                              ? _c(
                                  "div",
                                  { staticClass: "person-select-wrap" },
                                  [
                                    _c("div", { staticClass: "range-select" }, [
                                      _c("div", [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "select-item",
                                            on: {
                                              click: function ($event) {
                                                return _vm.personTypeClick(
                                                  "departSelect"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(" 本部门成员可见 "),
                                            _c("Icon", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.selectForm.departSelect,
                                                  expression:
                                                    "selectForm.departSelect",
                                                },
                                              ],
                                              staticClass: "checked-img",
                                              attrs: {
                                                "icon-class":
                                                  "icon-draw_checked",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm.selectForm.departSelect
                                          ? _c(
                                              "div",
                                              {
                                                class: `person-son-type person-son-type-${_vm.selectForm.subCheck}`,
                                              },
                                              [
                                                _c("el-checkbox", {
                                                  model: {
                                                    value:
                                                      _vm.selectForm.subCheck,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.selectForm,
                                                        "subCheck",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectForm.subCheck",
                                                  },
                                                }),
                                                _vm._v("包含所有子部门成员 "),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]),
                                      _c("div", [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "select-item",
                                            on: {
                                              click: function ($event) {
                                                return _vm.personTypeClick(
                                                  "upSelect"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(" 直属上级可见 "),
                                            _c("Icon", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.selectForm.upSelect,
                                                  expression:
                                                    "selectForm.upSelect",
                                                },
                                              ],
                                              staticClass: "checked-img",
                                              attrs: {
                                                "icon-class":
                                                  "icon-draw_checked",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm.selectForm.upSelect
                                          ? _c(
                                              "div",
                                              {
                                                class: `person-son-type person-son-type-${_vm.selectForm.colCheck}`,
                                              },
                                              [
                                                _c("el-checkbox", {
                                                  model: {
                                                    value:
                                                      _vm.selectForm.colCheck,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.selectForm,
                                                        "colCheck",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectForm.colCheck",
                                                  },
                                                }),
                                                _vm._v("相同上级的同事可见 "),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "select-item",
                                          on: {
                                            click: function ($event) {
                                              return _vm.personTypeClick(
                                                "chargeSelect"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(" 部门负责人可见 "),
                                          _c("Icon", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.selectForm.chargeSelect,
                                                expression:
                                                  "selectForm.chargeSelect",
                                              },
                                            ],
                                            staticClass: "checked-img",
                                            attrs: {
                                              "icon-class": "icon-draw_checked",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "person-add",
                                        on: { click: _vm.addRange },
                                      },
                                      [_vm._v("+ 自定义可见范围")]
                                    ),
                                    _vm.customDepartList.length > 0 ||
                                    _vm.customPersonList.length > 0
                                      ? _c(
                                          "div",
                                          { staticClass: "customList" },
                                          [
                                            _vm._v(" 已选： "),
                                            _vm._l(
                                              _vm.customDepartList,
                                              function (item, index) {
                                                return _c(
                                                  "span",
                                                  { key: item.id },
                                                  [
                                                    _c("wx-open-data", {
                                                      attrs: {
                                                        id: item.name,
                                                        nameData: {
                                                          name: item.name,
                                                        },
                                                        type: 2,
                                                      },
                                                    }),
                                                    index !==
                                                      _vm.customPersonList
                                                        .legnth -
                                                        1 ||
                                                    _vm.customPersonList
                                                      .length > 0
                                                      ? _c("span", [
                                                          _vm._v("、"),
                                                        ])
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            _vm._l(
                                              _vm.customPersonList,
                                              function (item, index) {
                                                return _c(
                                                  "span",
                                                  { key: item.id },
                                                  [
                                                    _c("wx-open-data", {
                                                      attrs: {
                                                        id: item.username,
                                                        nameData: {
                                                          name: item.name,
                                                        },
                                                      },
                                                    }),
                                                    index !==
                                                    _vm.customPersonList
                                                      .length -
                                                      1
                                                      ? _c("span", [
                                                          _vm._v("、"),
                                                        ])
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "confirm-btn",
                            on: { click: _vm.confirmSave },
                          },
                          [_vm._v("确定")]
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.customRangeVisible
        ? _c("customRange", {
            attrs: {
              customPersonList: _vm.customPersonList,
              customDepartList: _vm.customDepartList,
            },
            on: {
              refresh: _vm.okrRefresh,
              confirm: _vm.customRangeConfirm,
              click: function ($event) {
                $event.stopPropagation()
              },
            },
            model: {
              value: _vm.customRangeVisible,
              callback: function ($$v) {
                _vm.customRangeVisible = $$v
              },
              expression: "customRangeVisible",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }