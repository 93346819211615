const versionNumber = 'v1' // 版本
// 日历
export default [
  {
    // 获取订阅列表
    subscribeList: `/api/schedule/${versionNumber}/schedule/subscribe`
  },
  {
    // 获取时间范围内事件
    scheduleDateList: `/api/schedule/${versionNumber}/schedule`
  },
  {
    // 日程删除
    scheduleDel: `/api/schedule/${versionNumber}/schedule/`
  },
  {
    // 响应日程
    scheduleDecision: `/api/schedule/${versionNumber}/schedule/decision`
  },
  {
    // 日程详情
    scheduleDetail: `/api/schedule/${versionNumber}/schedule/`
  },
  {
    // 日程新增编辑
    scheduleAddEdit: `/api/schedule/${versionNumber}/schedule`
  },
  {
    // 获取日程展示类型
    scheduleShowTypes: `/api/schedule/${versionNumber}/schedule/getScheduleSet`
  },
  {
    // 设置日程展示类型
    scheduleSetTypes: `/api/schedule/${versionNumber}/schedule/updateScheduleSet`
  },
  {
    // 获取日程内任务列表
    scheduleTaskList: `/api/ranloTask/${versionNumber}/task/getScheduleTaskList`
  }
]
