<template>
  <div>
    <el-dialog
      title="周期阶段设置"
      :visible.sync="dialogVisible"
      width="630px"
      :append-to-body="true"
      :before-close="handleClose"
      class="comm-check-form"
    >
      <div class="dialog-content comm-input">
        <stageForm
          :stage-date="stageSetInfo[0]"
          :date-types="monthDateType"
          :cycle-info="cycleInfo"
          type="month"
          @changeData="changeStageData($event, 0)"
        />
        <stageForm
          :stage-date="stageSetInfo[1]"
          :date-types="dateType"
          :cycle-info="cycleInfo"
          type="year"
          @changeData="changeStageData($event, 1)"
        />
        <div class="bottom-step-buttons">
          <button class="left-button" @click="handleClose">取消</button>
          <button @click="save">确定</button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import stageForm from './stageForm.vue'
export default {
  components: { stageForm },
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  props: {
    dialogShow: {
      type: Boolean,
      default: false
    },
    stageSetInfo: {
      type: Array,
      default: () => {
        return []
      }
    },
    cycleInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      dialogVisible: this.dialogShow,
      stageInfo: [{}, {}],
      monthInterval: 0
    }
  },
  computed: {},
  watch: {
    dialogShow: {
      handler(val) {
        this.dialogVisible = val
      }
    }
  },
  created() {
    this.initData()
    // console.log('周期信息', this.cycleInfo)
  },
  methods: {
    // 初始化数据
    initData() {
      this.stageInfo = JSON.parse(JSON.stringify(this.stageSetInfo))
    },
    validStageTime(data, interval) {
      let intervalDay = 31 * interval
      // 自定义周期
      if (interval === 0) {
        const startDay = this.cycleInfo.cycleInfoList[0].startDate
        const endDay = this.cycleInfo.cycleInfoList[0].endDate
        intervalDay =
          (this.$moment(endDay).valueOf() - this.$moment(startDay).valueOf()) /
            (24 * 60 * 60 * 1000) +
          1
        interval = intervalDay / 29 + 1
      }
      const name = data.type === 'month' ? '阶段设置' : '年度阶段设置'
      if (
        (data.startType === 0 && data.startDay > intervalDay) ||
        (data.startType === 1 && data.startDay > intervalDay / 7) ||
        (data.startType === 2 && data.startDay > interval - 1)
      ) {
        this.$message.warn(name + '：填写阶段时间超出了周期范围')
        return true
      }
      if (
        (data.endType === 0 && data.endDay > intervalDay) ||
        (data.endType === 1 && data.endDay > intervalDay / 7) ||
        (data.endType === 2 && data.endDay > interval - 1)
      ) {
        this.$message.warn(name + '：复盘阶段时间超出了周期范围')
        return true
      }
      // const unitDay = data.endType === 0 ? 1 : data.endType === 1 ? 7 : 31
      // // 填写阶段时间范围
      // const startTimeRange = unitDay * data.startDay
      // // 复盘阶段时间范围
      // const endTimeRange = unitDay * data.endDay
      // if (startTimeRange + endTimeRange > intervalDay) {
      //   this.$message.warn(name + '：填写阶段时间与复盘阶段时间有冲突')
      //   return true
      // }
      return false
    },
    changeStageData(data, index) {
      this.stageInfo[index] = data
    },
    // 保存
    save() {
      // 判断各阶段时间是否冲突
      const valid1 = this.validStageTime(
        this.stageInfo[0],
        this.cycleInfo.interval
      )
      if (valid1) return
      const valid2 = this.validStageTime(this.stageInfo[1], 12)
      if (valid2) return
      const data = []
      this.stageInfo.forEach((item) => {
        const index = item.type === 'month' ? 0 : 1
        data.push(
          Object.assign(this.stageSetInfo[index].writeStage, {
            stageDataEndNum: item.startDay,
            stageDataEndNumType: item.startType
          })
        )
        data.push(
          Object.assign(this.stageSetInfo[index].pushStage, {
            stageDataStartNum: item.startDay,
            stageDataStartNumType: item.startType,
            stageDataEndNum: item.endDay,
            stageDataEndNumType: item.endType
          })
        )
        data.push(
          Object.assign(this.stageSetInfo[index].reviewStage, {
            stageDataStartNum: item.endDay,
            stageDataStartNumType: item.endType
          })
        )
      })
      this.$emit('stageSet', data)
    },
    handleClose() {
      this.dialogVisible = false
      this.$emit('change', false)
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../../assets/styles/dialog.less';
.dialog-content {
  padding: 24px 16px 0;
}
</style>
