<template>
  <div :class="type === 'circle' ? 'bar-wrap circle-bar' : 'bar-wrap mini-out-bar'">
    <!-- <Icon icon-class="icon-zhinengtuijin" class="icon" v-if="automationState === 1" /> -->
    <img
      src="../../../../assets/imgs/common/circle-progress.gif"
      class="icon"
      v-if="automationState === 1"
      alt
    />
    <el-progress
      type="circle"
      :percentage="rate <= 0 ? 0 : rate"
      :width="width"
      :stroke-width="strokeWidth"
      :color="color"
      :show-text="false"
      v-if="type === 'circle'"
    />
    <div class="mini-inner-bar" :style="`width:${rate}%`" v-else></div>
  </div>
</template>
<script>
export default {
  props: {
    rate: {
      type: Number,
      default: 50
    },
    color: {
      type: String,
      default: '#3296fa'
    },
    automationState: {
      type: Number
    },
    type: {
      type: String,
      default: 'line'
    },
    width: {
      type: Number,
      default: 20
    },
    strokeWidth: {
      type: Number,
      default: 2
    }
  }
}
</script>
<style lang="less" scoped>
.bar-wrap {
  position: relative;
  .flex-c();
  margin-right: 3px;
  .icon {
    color: #3a78f6;
    position: absolute;
    font-size: 10px;
    .flex-c();
    width: 24px;
    height: auto;
  }
}
.mini-out-bar {
  width: 100%;
  height: 100%;
  background: #ecedf4;
  border-radius: 8px;
  .mini-inner-bar {
    width: 50%;
    height: 100%;
    background: #3a78f6;
    border-radius: 8px;
  }
}
</style>
