export default {
  path: '/analyse',
  component: () => {
    return import(
      /* webpackChunkName: "analyseTab" */
      '../../views/analyse/page/tab'
    )
  },
  children: [
    {
    path: '/',
    name: 'analyse',
    component: () => {
      return import(
        /* webpackChunkName: "departAnalyse" */
        // '../../views/analyse/index.vue'
        '../../views/analyse/page/departAnalyse'
      )
    }
  },
  {
    path: '/companyAnalyse',
    name: 'companyAnalyse',
    component: () => {
      return import(
        /* webpackChunkName: "companyAnalyse" */
        '../../views/analyse/page/companyAnalyse'
      )
    }
  },
  {
    path: '/analyse-detail',
    name: 'analyseDetail',
    component: () => {
      return import(
        /* webpackChunkName: "analyseDetails" */
        '../../views/analyse/details'
      )
    }
  },
  {
    path: '/companyAnalyse/detail',
    name: 'companyAnalyseDetail',
    component: () => {
      return import(
        /* webpackChunkName: "companyAnalyseDetail" */
        '../../views/analyse/page/companyAnalyseDetail'
      )
    }
  },
  {
    path: '/departAnalyse',
    name: 'departAnalyse',
    component: () => {
      return import(
        /* webpackChunkName: "departAnalyse" */
        '../../views/analyse/page/departAnalyse'
      )
    }
  }
  ]
}
