var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "kr-item-box",
      class: _vm.item.checked ? "selected" : "",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.krClickTask(_vm.item, "recommended")
        },
      },
    },
    [
      _c("div", { staticClass: "flex-new flex-new-direction-column flex-1" }, [
        _c("div", { staticClass: "kr-title flex-new flex-1" }, [
          _c("span", { staticClass: "kr-span" }, [_vm._v("KR:")]),
          _c("p", {
            staticClass: "task-back-p ellipsis-1-0",
            domProps: { innerHTML: _vm._s(_vm.item.title) },
          }),
        ]),
        _c("div", { staticClass: "flex-new task-back-txt-box" }, [
          _c("p", { staticClass: "task-back-txt ellipsis-1-0" }, [
            _vm._v(" O： "),
            _c("span", { domProps: { innerHTML: _vm._s(_vm.item.content) } }, [
              _vm._v(_vm._s(_vm.item.content)),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "checked-div" },
        [
          _vm.item.checked
            ? _c("Icon", {
                staticClass: "task-svg-icon",
                attrs: { "icon-class": "icon-draw_checked" },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }