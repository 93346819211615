import {
  onConnect,
  onbindAccount,
  CIMPushManager
} from './websocket/cim.web.sdk.js'
import store from '@/store'
import pointmap from './websocket/pointmap.js'
import bus from '@/utils/bus'
import db from './database' // indexdb
var JSONbig = require('json-bigint')
// 发起请求
const sendBody = (obj, key) => {
  if (!obj instanceof Object) {
    throw new Error('type err,参数必须是一个对象')
  } else {
    // 主动发起请求
    var body = new proto.com.farsunset.cim.sdk.web.model.SentBody()
    if (!key) {
      body.setKey('client_okrt_notify')
    } else {
      body.setKey(key)
    }
    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        body.getDataMap().set(key, obj[key])
      }
    }
    CIMPushManager.sendRequest(body)
  }
}
// 退出
const stop = () => {
  CIMPushManager.stop()
  // 关闭indexDB
  db.close()
}
// 初始化
const init = () => {
  // 打开链接
  onConnect()
  // 登录
  const onConnectFinished = () => {
    // setTimeout(() => {
    onbindAccount(
      store.state.user.userInfo.id,
      returnCitySN && returnCitySN['cip']
    )
    // }, 200)
    // 注册推送
    sendBody({
      ReplyRed: `{ "userId": "${store.state.user.userInfo.id}", "companyId": "${store.state.user.companyInfo.id}" }`
    })
    // 连接成功后注册卸载事件
    // 浏览器卸载-断开socket链接
    window.onbeforeunload = stop
  }
  // 链接状态响应
  const onReplyReceived = (reply) => {
    // 绑定key
    if (reply.key === 'client_bind' && reply.code === '200') {
      getReplay(reply) // 绑定帐号暂不做处理
    }
    // 主动请求请求返回的key
    else if (reply.key === 'client_okrt_notify' && reply.code === '200') {
      getReplay(reply)
    } else {
      // console.log('链接失败', reply)
    }
  }
  // 接收消息
  const onMessageReceived = (message) => {
    // console.log('onMessageReceived通知消息处理', message)
    if (message.action === '0') {
      // CIM推送
      showMsgWindow(message)
    }
    // 账号在其他设备登陆
    if (message.action === 'ACTION_999') {
      // 关闭连接
      stop()
      throw new Error('账号其他设备登录,已退出当前链接')
    }
    getMessage(message)
  }

  // 展示推送弹窗
  const showMsgWindow = (message) => {
    bus.$emit('cimMsgReceive', message)
  }

  // 全局注册
  window.onConnectFinished = onConnectFinished
  window.onReplyReceived = onReplyReceived
  window.onMessageReceived = onMessageReceived

  // 获取replay信息
  const getReplay = (res) => {
    if (res.code === '200') {
      let dataRes = []
      if (res.data.ReplyRed) {
        // 此处出现了long精度丢失用了第三方json转换插件
        const data = JSONbig.parse(res.data.ReplyRed)

        dataRes = data.map((item) => {
          for (const key in item) {
            if (Object.hasOwnProperty.call(item, key)) {
              // 如果存在说明是个转换后的bigint对象
              if (item[key]['s']) {
                item[key] = item[key].toString()
              }
            }
          }
          return item
        })
        // 这是todo列表红点
      }
      console.log('getReplay', dataRes)
      pointmap.mapRedpoint(dataRes)
    } else {
      // console.log('ReplyRed', res)
      throw new Error('推送数据错误!')
    }
  }
  // 获取message
  const getMessage = (res) => {
    // console.log('获取message', res)
    // 处理具体业务 -> 根据map 找组件渲染 监听 bus.$emit('组件key',数据)
    // 组件监听数据bus.$on(data), 记得销毁防止内存泄漏
  }
}
// 消除红点
const removePoint = (type, busicId, that) => {
  if (type && busicId) {
    const point = {
      behaviorType: type,
      busicId, // 业务id
      companyId: store.state.user.companyInfo.id, // 企业id
      createUserid: store.state.user.userInfo.id // 用户id
    }
    that.$apis.CLEARREDPOINT(point).then((res) => {
      // console.log('消除红点', res) // 这里根据状态码，只拦截错误提示
    })
  }
}
export default {
  sendBody,
  init,
  stop,
  removePoint
}
