<template>
  <div class="layout-overflow-box">
    <div class="layout-box">
      <Header />
      <div class="layout-main">
        <transition name="fade-transform" mode="out-in">
          <router-view />
        </transition>
      </div>
    </div>
    <!-- <online-service
      v-if="
        companyInfo.belonger == userInfo.id &&
        companyInfo.version == 0 &&
        companyInfo.expireDate
      "
      :class="rocketsShow ? 'contact-icon' : 'rockets'"
    />-->
    <!-- <rockets
      v-if="rocketsShow"
      class="rockets"
      :guide-list="guideList"
      :guide-rate="guideRate"
      @refreshGuide="queryGuidesInfo"
    />-->
    <div class="evaluate-div">
      <!-- 第一次新增okr成功显示用户建议收集-->
      <evaluate
        :evaluateList="evaluateList"
        :evaluate-visible="evaluateVisible"
        @submitStatus="submitStatus"
        @closeStatus="closeStatus"
      />
    </div>
    <!-- v-if="
        companyInfo.belonger == userInfo.id &&
        companyInfo.version == 0 &&
        companyInfo.expireDate
    "-->
    <applyDemo
      v-if="
        companyInfo.belonger == userInfo.id &&
        companyInfo.version == 0 &&
        companyInfo.expireDate && !$isDD && !$isQw
    "
    />
  </div>
</template>
<script>
import Header from './header'
// import rockets from '@/views/global/layout/header/components/Rockets'
import evaluate from '@/views/global/layout/header/components/Evaluate'
// import onlineService from './header/components/OnlineService'
import applyDemo from './header/components/applyDemo'
export default {
  components: {
    applyDemo,
    // rockets,
    evaluate,
    Header
    // onlineService
  },
  data() {
    return {
      rocketsShow: false,
      guideList: [],
      guideRate: 0,
      evaluateVisible: false, // 用户收集显隐
      evaluateList: {}
    }
  },
  created() {
  },
  mounted() {
    this.queryGuidesInfo()
    this.bus(this).$on('inquireStatus', (val) => {
      var satisfactionDictionary = (this.$store.state.user.satisfactionDictionary || [])
      satisfactionDictionary.forEach((item) => {
        if (item.satisfactionType === val.type && item.satisfactionStatus === 0) {
          val.satisfactionDictionaryDtoList = item.satisfactionDictionaryDtoList
          val.satisfactionVersion = item.satisfactionVersion
          this.evaluateList = val
          this.evaluateVisible = true
          return
        }
      })
    })
  },
  watch: {},
  methods: {
    // 关闭提交【不参与】
    closeStatus() {
      this.evaluateVisible = false
    },
    // 获取成长旅程列表
    async queryGuidesInfo() {
      await this.$apis
        .USERPROCESSLIST()
        .then((rest) => {
          this.guideRate = 0
          const list = rest.data.itemList.sort((a, b) => {
            return a.progressStep - b.progressStep
          })
          list.forEach((item) => {
            if (!item.isFinish) {
              this.rocketsShow = true
            } else {
              this.guideRate += item.progressRate
            }
            item.progressValue = item.progressValue.replace(/\n/gm, '<br/>')
          })
          this.rocketsShow = list.some((item) => {
            return !item.isFinish
          })
          if (this.rocketsShow) {
            this.guideList = list
          } else {
            // 完成
            this.$mixpanelUtil.track('Complete Rocket')
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>
<style lang="less" scoped>
.layout-overflow-box {
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: auto;
  .s-b-x();
}
.layout-box {
  min-width: 1280px;
  .layout-main {
    // padding-bottom: 52px;
    background: #f7f7fc;

    min-height: calc(100vh - 54px);
    // .s-b-x();
  }
}
.rockets {
  position: fixed;
  right: 20px;
  bottom: 20px;
}
.contact-icon {
  position: fixed;
  right: 12px;
  bottom: 72px;
}
.evaluate-div {
  position: fixed;
  right: 30px;
  bottom: 24px;
  z-index: 9999;
}
</style>
