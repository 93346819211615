var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "message-box-wrap" },
    [
      _c(
        "el-drawer",
        _vm._g(
          {
            staticClass: "request-box-drawer",
            attrs: {
              title: "新建项目",
              visible: _vm.apiRequestData.visible,
              direction: "btt",
              "destroy-on-close": true,
              "close-on-press-escape": false,
            },
            on: {
              "update:visible": function ($event) {
                return _vm.$set(_vm.apiRequestData, "visible", $event)
              },
              close: _vm.getMessageNotificationClose,
            },
          },
          _vm.$listeners
        ),
        [
          _vm.userInfo.companyId != "1339443199637032960" &&
          _vm.apiRequestData.type !== 52018 &&
          _vm.apiRequestData.type !== 54003
            ? _c(
                "div",
                {
                  staticClass: "classroom-menu",
                  on: { click: _vm.goClassroom },
                },
                [_vm._v("训练营")]
              )
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.apiRequestData.type !== 52018 &&
                    _vm.apiRequestData.type !== 54003,
                  expression:
                    "apiRequestData.type !== 52018 && apiRequestData.type !== 54003",
                },
              ],
              staticClass: "person-menu",
            },
            [
              _c("person-menu-list", {
                ref: "menu",
                attrs: { type: 2 },
                on: { companyMore: _vm.companyMore },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "request-wrap" }, [
            _vm.apiRequestData.type === 52012 || _vm.apiRequestData.type === 1
              ? _c("div", { staticClass: "content-wrap" }, [
                  _c("div", { staticClass: "more-people-wrap" }, [
                    _vm.apiRequestData.type === 1
                      ? _c("div", { staticClass: "company-name" }, [
                          _vm._v(_vm._s(_vm.apiRequestData.companyName)),
                        ])
                      : _c("div", { staticClass: "company-name" }, [
                          _vm._v(_vm._s(_vm.userInfo.companyName)),
                        ]),
                    _c(
                      "div",
                      { staticClass: "infor-list" },
                      [
                        _c("Icon", {
                          staticClass: "svg-img",
                          attrs: { "icon-class": "icon-zhongzhi" },
                        }),
                        _c("p", { staticClass: "title" }, [
                          _vm._v("超出人数限制，请联系管理员或切换企业"),
                        ]),
                      ],
                      1
                    ),
                    _vm.adminList.length > 0
                      ? _c("div", { staticClass: "user-wrap" }, [
                          _c("div", { staticClass: "user-list" }, [
                            _c("div", { staticClass: "user-wrap-left" }, [
                              _c("img", {
                                staticClass: "border-radius-20",
                                attrs: {
                                  src:
                                    _vm.adminList[0].avatar ||
                                    require("@/assets/imgs/common/avatar.png"),
                                  alt: "",
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "user-wrap-right" }, [
                              _c(
                                "div",
                                { staticClass: "name" },
                                [
                                  _c("wx-open-data", {
                                    attrs: {
                                      id: _vm.adminList[0].username,
                                      nameData: { name: _vm.adminList[0].name },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.commonFromType === "saas" && !_vm.$isDD && !_vm.$isQw
                      ? _c(
                          "div",
                          {
                            staticClass: "btn",
                            on: {
                              click: function ($event) {
                                return _vm.exitCompany(_vm.apiRequestData.type)
                              },
                            },
                          },
                          [_vm._v("退出企业")]
                        )
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
            _vm.apiRequestData.type === 52014
              ? _c("div", { staticClass: "content-wrap" }, [
                  _c("div", { staticClass: "more-people-wrap people-refuse" }, [
                    _c("div", { staticClass: "company-name" }, [
                      _vm._v(_vm._s(_vm.userInfo.companyName)),
                    ]),
                    _c(
                      "div",
                      { staticClass: "infor-list" },
                      [
                        _c("Icon", {
                          staticClass: "svg-img",
                          attrs: { "icon-class": "icon-zhongzhi" },
                        }),
                        _c("p", { staticClass: "title" }, [
                          _vm._v("您已被管理员移出企业 或 企业已被删除"),
                        ]),
                      ],
                      1
                    ),
                    _vm.commonFromType === "saas" && !_vm.$isDD && !_vm.$isQw
                      ? _c(
                          "div",
                          {
                            staticClass: "btn",
                            on: {
                              click: function ($event) {
                                return _vm.exitBtnClick(_vm.apiRequestData.type)
                              },
                            },
                          },
                          [_vm._v("重新登录")]
                        )
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
            _vm.apiRequestData.type === 52016
              ? _c("div", { staticClass: "content-wrap" }, [
                  _c("div", { staticClass: "more-people-wrap" }, [
                    _c(
                      "div",
                      { staticClass: "svg-wrap" },
                      [
                        _c("Icon", {
                          staticClass: "svg-img",
                          attrs: { "icon-class": "icon-taskNull" },
                        }),
                      ],
                      1
                    ),
                    _c("p", { staticClass: "title" }, [
                      _vm._v(
                        "您已申请加入「" +
                          _vm._s(_vm.userInfo.companyName) +
                          "」，需等待管理员审核"
                      ),
                    ]),
                    _vm.commonFromType === "saas" && !_vm.$isDD && !_vm.$isQw
                      ? _c(
                          "div",
                          {
                            staticClass: "btn",
                            on: {
                              click: function ($event) {
                                return _vm.switchEmptyHandle(
                                  _vm.apiRequestData.type
                                )
                              },
                            },
                          },
                          [_vm._v("切换企业")]
                        )
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
            _vm.apiRequestData.type === 52017 ||
            _vm.apiRequestData.type === 52019 ||
            _vm.apiRequestData.type === 52020
              ? _c("div", { staticClass: "content-wrap" }, [
                  _c("div", { staticClass: "more-people-wrap people-refuse" }, [
                    _c("div", { staticClass: "company-name" }, [
                      _vm._v(_vm._s(_vm.userInfo.companyName)),
                    ]),
                    _c(
                      "div",
                      { staticClass: "infor-list refuse-infor-list" },
                      [
                        _c("Icon", {
                          staticClass: "svg-img",
                          attrs: { "icon-class": "icon-zhongzhi" },
                        }),
                        _c("p", { staticClass: "title" }, [
                          _vm._v("账户已被停用"),
                        ]),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "infor-title" }, [
                      _c("p", [
                        _vm._v("无法进入该组织，账号已被拒绝加入或停用，"),
                      ]),
                      _c("p", [_vm._v("请联系该企业管理员或切换其他企业")]),
                    ]),
                    _vm.adminList.length > 0
                      ? _c("div", { staticClass: "user-wrap" }, [
                          _c("div", { staticClass: "user-list" }, [
                            _c("div", { staticClass: "user-wrap-left" }, [
                              _c("img", {
                                staticClass: "border-radius-20",
                                attrs: {
                                  src:
                                    _vm.adminList[0].avatar ||
                                    require("@/assets/imgs/common/avatar.png"),
                                  alt: "",
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "user-wrap-right" }, [
                              _c(
                                "div",
                                { staticClass: "name" },
                                [
                                  _c("wx-open-data", {
                                    attrs: {
                                      id: _vm.adminList[0].username,
                                      nameData: { name: _vm.adminList[0].name },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.commonFromType === "saas" && !_vm.$isDD && !_vm.$isQw
                      ? _c(
                          "div",
                          {
                            staticClass: "btn",
                            on: {
                              click: function ($event) {
                                return _vm.exitCompany(_vm.apiRequestData.type)
                              },
                            },
                          },
                          [_vm._v("退出企业")]
                        )
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
            _vm.apiRequestData.type === 52018 ||
            _vm.apiRequestData.type === 54003 ||
            _vm.apiRequestData.type === 54002
              ? _c(
                  "div",
                  {
                    staticClass: "content-wrap",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        class: `expire-div expire-div-${
                          _vm.userInfo.isSuperAdmin === 0
                        }`,
                      },
                      [
                        _c("div", { staticClass: "expire-top" }, [
                          _c("img", {
                            staticClass: "top-img",
                            attrs: {
                              src: require("@/assets/imgs/advertisement/applyImg.png"),
                              alt: "",
                            },
                          }),
                          _c("div", { staticClass: "top-title" }, [
                            _vm._v("您可以选择"),
                          ]),
                          _c("img", {
                            staticClass: "school-img",
                            attrs: {
                              src: require("@/assets/imgs/header/schoolImg.png"),
                              alt: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.expireClick("去训练营")
                              },
                            },
                          }),
                        ]),
                        _c(
                          "div",
                          { staticClass: "handle-list" },
                          _vm._l(_vm.expireList, function (item, index) {
                            return _vm.userInfo.isSuperAdmin != 0 ||
                              (_vm.userInfo.isSuperAdmin == 0 && index > 2)
                              ? _c("div", { key: index, staticClass: "list" }, [
                                  _c("img", {
                                    staticClass: "img",
                                    attrs: { src: item.icon, alt: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.expireClick(item.name, item)
                                      },
                                    },
                                  }),
                                ])
                              : _vm._e()
                          }),
                          0
                        ),
                        _vm.commonFromType === "saas" &&
                        !_vm.$isDD &&
                        !_vm.$isQw
                          ? _c("div", { staticClass: "btn-list" }, [
                              _c(
                                "div",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.switchEmptyHandle(
                                        _vm.apiRequestData.type
                                      )
                                    },
                                  },
                                },
                                [_vm._v("切换企业")]
                              ),
                              _c(
                                "div",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.exitCompany(
                                        _vm.apiRequestData.type
                                      )
                                    },
                                  },
                                },
                                [_vm._v("退出企业")]
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ]
      ),
      _vm.switch_enterprises
        ? _c("switch-enterprises", {
            attrs: {
              visible: _vm.switch_enterprises,
              "status-pass-sms": _vm.statusPassSms,
              "from-page": _vm.fromPage,
            },
            on: {
              "update:visible": function ($event) {
                _vm.switch_enterprises = $event
              },
              closeSwitchCompany: _vm.closeSwitchCompany,
            },
          })
        : _vm._e(),
      _vm.customer_service_show
        ? _c("customer-service", {
            attrs: {
              "show-clo": _vm.showClo,
              page: "home",
              visible: _vm.customer_service_show,
            },
            on: {
              "update:visible": function ($event) {
                _vm.customer_service_show = $event
              },
              closeDialg: _vm.customerSeriverCloseDialg,
            },
            model: {
              value: _vm.customer_service_show,
              callback: function ($$v) {
                _vm.customer_service_show = $$v
              },
              expression: "customer_service_show",
            },
          })
        : _vm._e(),
      _vm.applyDialogVisible
        ? _c("applyDialog", {
            model: {
              value: _vm.applyDialogVisible,
              callback: function ($$v) {
                _vm.applyDialogVisible = $$v
              },
              expression: "applyDialogVisible",
            },
          })
        : _vm._e(),
      _vm.applyDelayVisible
        ? _c("applyDelay", {
            model: {
              value: _vm.applyDelayVisible,
              callback: function ($$v) {
                _vm.applyDelayVisible = $$v
              },
              expression: "applyDelayVisible",
            },
          })
        : _vm._e(),
      _vm.applyPriceVisible
        ? _c("applyPrice", {
            model: {
              value: _vm.applyPriceVisible,
              callback: function ($$v) {
                _vm.applyPriceVisible = $$v
              },
              expression: "applyPriceVisible",
            },
          })
        : _vm._e(),
      _vm.doubleElevenVisible
        ? _c("doubleEleven", {
            on: {
              close: function ($event) {
                return _vm.openDoubleEleven(false)
              },
            },
            model: {
              value: _vm.doubleElevenisible,
              callback: function ($$v) {
                _vm.doubleElevenisible = $$v
              },
              expression: "doubleElevenisible",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }