/* eslint-disable */
export default {
  data() {
    return {
      pmApprovalCheckData: ''
    }
  },
  methods: {
    // 获取项目审批设置状态
    async getPmApprovalCheck(params, type) {
      return await this.$apis
        .APPROVALCHECK(params)
        .then((res) => {
          this.pmApprovalCheckData = res.data
          var approvalOperate = (this.pmApprovalCheckData && this.pmApprovalCheckData.approvalOperate)
          var state = false
          if (this.pmApprovalCheckData.onOff === 0 && approvalOperate.includes(type)) {
            state = true
          }
          return {
            data: this.pmApprovalCheckData,
            state
          }
        })
        .catch(() => {
          this.$message.error(`获取项目审批设置失败哦`)
        })
    },
  }
}
