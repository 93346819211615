<template>
  <!-- 企信迁移后新增 -->
  <div class="top_members">
    <div class="box_construction_li_top clearfix box-construction-li-top-align">
      <div v-if="topMembersBean.userAvatar" class="box_construction_li_top_headImg">
        <div class="box_construction_li_top_headImg_top">
          <Icon icon-class="icon-okrline-up" />
        </div>
        <div class="box_construction_li_top_line"></div>
      </div>
      <div class="box_construction_li_top_content">
        <div
          class="construction_li_top_content_1"
          :class="
            topMembersBean.userRoleType == 0 || topMembersBean.userRoleType == 1
              ? 'hemoScoreIconCursor'
              : ''
          "
        >
          <div
            v-if="topMembersBean.fatherType !== ''"
            class="construction_li_title_box"
            :class="
              topMembersBean.userRoleType == 0 &&
              (topMembersBean.fatherType === 'O' ||
                topMembersBean.fatherType === 'KR')
                ? hoverClose
                  ? 'construction_li_top_content_1_style hover-close'
                  : 'construction_li_top_content_1_style'
                : ''
            "
          >
            <div
              class="father-one-wrap"
              v-if="
                topMembersBean.fatherType === 'O' ||
                topMembersBean.fatherType === 'KR'
              "
            >
              <div style="position: relative" class="clearfix construction_li_box">
                <div class="construction_li_top_content_2_img_box border-radius-20">
                  <member-card
                    :user-id="topMembersBean.fatherObjectivesAssociationUserList[0].userDto.id"
                    :is-okr="topMembersState === '2'"
                    @toOKRList="getTopMembersFatherList({
                      userId: topMembersBean.fatherObjectivesAssociationUserList[0].userDto.id,
                      userAvatar: topMembersBean.fatherObjectivesAssociationUserList[0].userDto.avatar || require('@/assets/imgs/common/avatar.png'),
                      userName: topMembersBean.fatherObjectivesAssociationUserList[0].userDto.name
                    })"
                  >
                    <el-image
                      fit="cover"
                      class="construction_li_top_content_2_img border-radius-20"
                      :class="
                        topMembersState === '2'
                          ? 'construction_li_top_content_2_avator'
                          : ''
                      "
                      :src="topMembersBean.fatherObjectivesAssociationUserList[0].userDto.avatar || require('@/assets/imgs/common/avatar.png')"
                    />
                  </member-card>
                </div>
                <div
                  :class="`father-type father-type-${topMembersBean.fatherType}`"
                >{{ topMembersBean.fatherType }}</div>
                <el-tooltip
                  placement="top"
                  :open-delay="300"
                  content="查看ta的目标"
                  :disabled="fromType==='approvalDetail'"
                >
                  <div
                    class="construction_li_top_content_2_text"
                    style="cursor: pointer"
                    @click="getTopMembersAlignmentSelect({
                      id: topMembersBean.fatherObjectivesAssociationUserList[0].objectivesAssociationList[0].beAlignedObjId,
                      userId: topMembersBean.fatherObjectivesAssociationUserList[0].userDto.id,
                      cycleId: topMembersBean.fatherObjectivesAssociationUserList[0].cycleId,
                      cycleInfoId: topMembersBean.fatherObjectivesAssociationUserList[0].cycleInfoId,
                      userAvatar: topMembersBean.fatherObjectivesAssociationUserList[0].userDto.avatar || require('@/assets/imgs/common/avatar.png'),
                      userName: topMembersBean.fatherObjectivesAssociationUserList[0].userDto.name
                      })"
                  >
                    <div
                      class="content"
                      v-html="$utils.changeToHtml(topMembersBean.fatherObjectivesAssociationUserList[0].businessTitle)"
                    ></div>
                    <privateOkr
                      :createUser="
                        userInfo.id ===
                        (topMembersBean
                        .fatherObjectivesAssociationUserList || []).length > 0 && topMembersBean
                          .fatherObjectivesAssociationUserList[0]
                          .userDto.id
                      "
                      :visible="
                        (topMembersBean
                        .fatherObjectivesAssociationUserList || []).length > 0&& topMembersBean
                          .fatherObjectivesAssociationUserList[0]
                          .isVisible === 1
                      "
                      :content="
                        (topMembersBean
                        .fatherObjectivesAssociationUserList || []).length > 0 && topMembersBean
                          .fatherObjectivesAssociationUserList[0]
                          .isVisible === 1
                          ? '对方已设置为不可见'
                          : ''
                      "
                      :type="1"
                    />
                  </div>
                </el-tooltip>
              </div>
              <el-tooltip placement="top" :open-delay="300" content="点击取消对齐">
                <div
                  v-if="topMembersBean.userRoleType === 0"
                  class="construction_li_top_content_2_icon"
                  @mouseenter="mouseenterClose(true)"
                  @mouseleave="mouseenterClose(false)"
                >
                  <i class="iconfont ymbguanbianniu" @click="getTopMembersAlignmentDetele"></i>
                </div>
              </el-tooltip>
            </div>
            <div class="father-align-list-wrap" v-else>
              <div
                class="align-list"
                v-for="item in topMembersBean.fatherObjectivesAssociationUserList"
                :key="`${item.userDto.id}`"
              >
                <align-member-card
                  :is-okr="topMembersState === '2'"
                  :deleteAlign="true"
                  :userInfoList="item.userDto"
                  @toOKRList="getTopMembersFatherList({
                      userId: item.userDto.id,
                      userAvatar: item.userDto.avatar || require('@/assets/imgs/common/avatar.png'),
                      userName: item.userDto.name
                    })"
                  @okrDetail="getTopMembersAlignmentSelect"
                  @deleteAlign="getTargetAligningSubmit"
                  :objectivesAssociationList="(item.objectivesAssociationList || [])"
                >
                  <div class="align-detail">
                    <div class="advar">
                      <member-card
                        :user-id="item.userDto.id"
                        :is-okr="topMembersState === '2'"
                        @toOKRList="getTopMembersFatherList({
                      userId: item.userDto.id,
                      userAvatar: item.userDto.avatar || require('@/assets/imgs/common/avatar.png'),
                      userName: item.userDto.name
                    })"
                      >
                        <el-image
                          :src="item.userDto.avatar || require('@/assets/imgs/common/avatar.png')"
                          fit="cover"
                          class="img border-radius-20"
                        />
                      </member-card>
                    </div>
                    <div class="name">
                      <wx-open-data :id="item.userDto.name" :nameData="{name:item.userDto.name}" />
                    </div>
                    <div class="num" v-if="item.alignedCount > 1">{{ item.alignedCount }}</div>
                  </div>
                </align-member-card>
              </div>
            </div>
          </div>
          <div
            slot="guidepop"
            class="construction_li_top_content_1_text"
            style="width: 120px"
            :class="
              topMembersBean.userRoleType != 0 &&
              topMembersBean.userRoleType != 1
                ? `construction_li_top_content_1_text_color construction_li_top_content_1_text-${
                    (topMembersBean.fatherObjectivesAssociationUserList || []).length === 0
                  }`
                : `construction_li_top_content_1_text-${
                    (topMembersBean.fatherObjectivesAssociationUserList || []).length === 0
                  }`
            "
            @click="getTopMembersAlignmentTarget"
          >
            <div
              v-if="
                topMembersBean.userRoleType == 0 ||
                topMembersBean.userRoleType == 1
              "
            >
              <target-aligning-new
                ref="targetAligning"
                :target-aligning-show="targetAligningShow"
                :target-aligning-obj="targetAligningObj"
                :target-aligning-list="targetAligningObj && targetAligningObj.fatherObjectivesAssociationUserList"
                :disabled="
                  topMembersBean.userRoleType == 0 ||
                  topMembersBean.userRoleType == 1
                    ? false
                    : true
                "
                :placement="
                  index + 1 === listLen && listLen > 1 ? 'top' : 'bottom'
                "
                :okr-list-index="index"
                @getTargetAligningClose="getTargetAligningClose"
                @getTargetAligningSubmit="getTargetAligningSubmit"
              >
                <div slot="guidepop" class="add-align" style="width: 700px">+ 对齐目标</div>
              </target-aligning-new>
            </div>
            <div
              v-if="
                (topMembersBean
                        .fatherObjectivesAssociationUserList || []).length === 0 &&
                (topMembersBean.userRoleType !== 0 ||
                  topMembersBean.userRoleType == 1)
              "
            >还未对齐目标</div>
          </div>
        </div>
      </div>
    </div>
    <div class="box_construction_li_top clearfix box-construction-li-top-content">
      <!-- 左侧内容 -->
      <div v-if="topMembersBean.userAvatar" class="box_construction_li_top_headImg">
        <!-- <div class="box_construction_li_top_headImg_top">
          <Icon icon-class="icon-okrline-up" />
        </div>-->
        <div class="construction_li_top_headImg_img_box construction_li_top_headImg_img_box">
          <!--  (userInfo.companyId === '1165952161026109440' ||
          userInfo.companyId === '1166538611434151936')-->
          <el-tooltip
            v-if="
              topMembersBeans.objectivesSharedUserList &&
              topMembersBeans.objectivesSharedUserList.length > 1
            "
            class="item"
            effect="dark"
            content="点击查看共享人"
            placement="top-start"
          >
            <div class="share-okr-avatar-div border-radius-20" @click="showShareList">
              <okr-share-user-avatar :list="topMembersBeans.objectivesSharedUserList" />
            </div>
          </el-tooltip>

          <member-card
            v-else
            :user-id="topMembersBean.chargeUserId"
            :is-okr="topMembersState === '2'"
            @toOKRList="getTopMembersFatherList(topMembersBean)"
          >
            <avatar-locked
              :avatar="topMembersBean.userAvatar || require('@/assets/imgs/common/advar.png')"
              :size="40"
              :locked="
                topMembersBean.isVisible === 1 ||
                (topMembersBean.objectivesPower &&
                  topMembersBean.objectivesPower.lookRulesType !== 0)
              "
              :can-click="topMembersState === '2'"
              :content="
                privateOkrContent(
                  userInfo.id === topMembersBean.createUserid,
                  topMembersBean,
                  1
                )
              "
              :class="topMembersState === '2' ? 'headImg_img_click' : ''"
            />
          </member-card>
        </div>
        <div
          v-if="
            (topMembersBean.childObjectivesAssociationUserList || []).concat(
              topMembersBean.objectivesCollaboratorList
            ).length > 0
          "
          class="box_construction_li_top_line"
        ></div>
        <div
          v-if="
            (topMembersBean.childObjectivesAssociationUserList || []).concat(
              topMembersBean.objectivesCollaboratorList
            ).length > 0
          "
          class="box_construction_li_top_headImg_bottom"
        >
          <Icon icon-class="icon-okrline-down" />
        </div>
      </div>
      <!-- 右侧内容 -->
      <div class="box_construction_li_top_content">
        <div class="construction_li_top_content_text clearfix">
          <div
            v-if="topMembersNameEditor"
            class="construction_li_top_content_text_1"
            @click="getTopMembersNameEditor"
          >
            <div
              style="width: 100%"
              :class="
                userInfo.id === topMembersBean.createUserid
                  ? 'cursor-text'
                  : 'cursor-no'
              "
            >
              <el-tooltip
                placement="top"
                :open-delay="300"
                content="点击编辑"
                :disabled="topMembersBean.userRoleType !== 0"
              >
                <advice-html
                  :html="
                    $utils.changeToHtmlUser(
                      $utils.changeToOkrtFormat(form.comment),
                      topMembersBean,
                      (topMembersBean.childObjectivesAssociationUserList || []).concat(
                        topMembersBean.objectivesCollaboratorList
                      )
                    )
                  "
                  :show="userInfo.id === topMembersBean.createUserid"
                  :dict-info="topMembersBean.objectivesRuleMatchDto"
                  :listType="true"
                  :isOkr="topMembersState === '2'"
                  :businessType="0"
                  :dataDetail="topMembersBean"
                  @ignore="getTopMembers"
                  @okrDetail="getTopMembersAlignmentSelect"
                  @toOKRList="getTopMembersFatherList"
                />
              </el-tooltip>
              <privateOkr
                :createUser="userInfo.id === topMembersBean.createUserid"
                :visible="topMembersBean.isVisible === 1"
                :content="
                  topMembersBean.isVisible === 1 ? '对方已设置为不可见' : ''
                "
                :type="1"
              />
            </div>
          </div>
          <div v-else class="construction_li_top_content_text_1_input">
            <AdviceInput
              v-model="titlePopShow"
              :html="$utils.changeToHtml(form.comment)"
              :type="1"
              :edit-ignore="true"
              :dict-info="topMembersBean"
              :ignore-rule-id-list="form.ignoreRuleIdList"
              :kr-length="topMembersBean.keyresultsList.length"
              @addIgnore="addIgnoreRule"
            >
              <at-input
                ref="myatinput"
                limit="500"
                :isBgColor="false"
                :html="$utils.changeToHtml(form.comment)"
                :editable="true"
                :enter-able="true"
                @focus="objTitleFocus"
                @blur="changeObjectiveTitle($event)"
              />
            </AdviceInput>
          </div>
        </div>

        <div class="construction_li_top_content_personnel">
          <div
            v-for="(
              itemObjList, indexListIndex
            ) in topMembersBean.childNameObjList"
            v-show="indexListIndex + 1 < 9"
            :key="itemObjList.id"
            class="content_personnel_li clearfix"
            @mouseover="showChildHoverBox(itemObjList)"
          >
            <align-member-card
              :userInfoList="itemObjList.userDto"
              :is-okr="itemObjList.isAlign !== 0 && topMembersState === '2'"
              :is-align="itemObjList.isAlign !== 0"
              :objectivesAssociationList="(itemObjList.objectivesAssociationList || [])"
              alignType="childAlign"
              :dataDetail="topMembersBean"
              @okrDetail="getTopMembersAlignmentSelect"
              @toOKRList="getTopMembersFatherList({
                userId: itemObjList.userDto.id,
                userAvatar: itemObjList.userDto.avatar || require('@/assets/imgs/common/advar.png'),
                userName: itemObjList.userDto.name
              })"
            >
              <member-card
                :user-id="itemObjList.userDto.id"
                :is-okr="topMembersState === '2'"
                @toOKRList="getTopMembersFatherList({
                  userId: itemObjList.userDto.id,
                  userAvatar: itemObjList.userDto.avatar || require('@/assets/imgs/common/advar.png'),
                  userName: itemObjList.userDto.name
                })"
              >
                <div class="content_personnel_li_style" style="margin-top: 2px">
                  <el-image
                    fit="cover"
                    class="content_personnel_li_style_img border-radius-20"
                    :src="itemObjList.userDto.avatar || require('@/assets/imgs/common/avatar.png')"
                    :class="itemObjList.isAlign === 0 ? 'img-gray' : ''"
                  />
                  <div
                    class="content_personnel_li_style_img avatar-modal border-radius-20"
                    v-if="itemObjList.isAlign === 0"
                  ></div>
                </div>
              </member-card>
            </align-member-card>
          </div>
          <div
            v-if="
              (topMembersBean.childObjectivesAssociationUserList || []).concat(
                topMembersBean.objectivesCollaboratorList
              ).length > 8
            "
            class="more-avator"
          >
            <Icon icon-class="icon-more-round" class="more-avator-icon" />
          </div>
          <div slot="guidepop">
            <el-tooltip placement="top" :open-delay="300" content="点击查看对齐ta的目标">
              <div
                v-if="
                  topMembersState === '2' &&
                  (topMembersBean.childObjectivesAssociationUserList || []).concat(
                    topMembersBean.objectivesCollaboratorList
                  ).length > 0
                "
                class="content_personnel_li_style_next"
                @click="toAlignSelfTarget"
              >
                <Icon class="icon" icon-class="icon-right" />
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import targetAligningNew from '@/views/okr/components/targetAligningNew/targetAligningNew' // 对齐目标
import adviceHtml from '../AdviceHtml'
import AdviceInput from '../AdviceInput'
import privateOkr from '../PrivateOkr'
import okrShareUserAvatar from '@/views/okr/components/singleOkrItem/okrShareUserAvatar' // 共享人头像
export default {
  components: {
    targetAligningNew,
    adviceHtml,
    AdviceInput,
    privateOkr,
    okrShareUserAvatar
  },
  props: {
    topMembersBeans: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
    topMembersState: {
      type: String,
      required: false,
      default: '1' // 1.目标详情，2.目标列表
    },
    index: {
      // 排序
      type: Number,
      default: 0
    },
    listLen: {
      // 列表长度
      type: Number,
      default: 0
    },
    detailsId: {
      type: Number,
      default: 0
    },
    editState: {
      default: false // true: 可以编辑
    },
    fromType: {
      default: ''
    }
  },
  data() {
    // img:require('./icon_setting_0.png')
    return {
      // userAvatar: require('../../../../../../assets/img/icon_setting_0.png'),
      form: {
        // 评论内容
        comment: '', // 评论
        comment2: '', // 评论
        userId: null, // at的人id
        ignoreRuleIdList: []
      },
      atstate: true,
      detailsId: '',
      topMembersBean: {
        createUserid: '',
        userAvatar: '',
        childObjList: [],
        userRoleType: 0,
        content: '',
        fatherObjectives: null
      },
      topMembersNameEditor: true,
      // 对齐状态字段
      targetAligningShow: false, // 对齐内容显示
      topMembersSelectBeans: [],
      topMembersSelectState: true,

      hoverTargetData: {
        alignData: {}
      }, // hover头像时显示对齐关系
      hoverClose: false, // 移入删除对齐icon

      activeHoverId: null, // hover对齐id，防止重复调用
      ignoreStateChange: false,
      objDetail: {}
    }
  },
  computed: {},
  watch: {
    topMembersBeans: {
      handler(val) {
        this.initData(this.topMembersBeans)
        this.form.comment = this.topMembersBean.content
        this.form.comment2 = this.$utils.changeToHtml(
          this.topMembersBean.content
        )
        if (this.topMembersBean.ignoreObjectivesRuleMatchDtoList.length > 0) {
          this.form.ignoreRuleIdList =
            this.topMembersBean.ignoreObjectivesRuleMatchDtoList.map(
              (item) => item.writeRuleId
            ) || []
          this.detailsId = this.topMembersBean.id
        }
      }
    }
  },
  created() {
    if (this.topMembersState === '1') {
      const that = this
      this.bus(this).$on('targetDetailsInitialize', (targetId) => {
        console.log(targetId, '头像部分内容')
        that.detailsId = targetId
      })
      this.bus(this).$on('InitializeData', (magDate) => {
        // that.topMembersBean = magDate
        that.initData(magDate)
        this.form.comment = this.topMembersBean.content
        this.form.comment2 = this.$utils.changeToHtml(
          this.topMembersBean.content
        )
        if (this.topMembersBean.ignoreObjectivesRuleMatchDtoList.length > 0) {
          this.form.ignoreRuleIdList =
            this.topMembersBean.ignoreObjectivesRuleMatchDtoList.map(
              (item) => item.writeRuleId
            ) || []
        }

        if (that.topMembersSelectState === true) {
          that.topMembersSelectState = false
          that.topMembersSelectBeans.push(that.topMembersBean)
        }
      })
      this.bus(this).$on('toFatherOkr', (dataBeans) => {
        that.getTopMembersAlignmentSelect(dataBeans)
      })
    } else if (this.topMembersState === '2') {
      // this.topMembersBean = this.topMembersBeans
      this.initData(this.topMembersBeans)
      this.detailsId = this.topMembersBean.id
    }
    this.form.comment = this.topMembersBean.content
    this.form.comment2 = this.$utils.changeToHtml(this.topMembersBean.content)

    if (
      this.topMembersBean.ignoreObjectivesRuleMatchDtoList &&
      this.topMembersBean.ignoreObjectivesRuleMatchDtoList.length > 0
    ) {
      this.form.ignoreRuleIdList =
        this.topMembersBean.ignoreObjectivesRuleMatchDtoList.map(
          (item) => item.writeRuleId
        ) || []
    }
  },
  mounted() {},
  methods: {
    initData(data) {
      // 判断是否是创建人 后台把这个字段删除了 0:创建人 1:共享人 2:查看者
      this.targetAligningObj = data
      if (this.fromType !== 'approvalDetail' && data.createUserid === this.userInfo.id && (data.objectivesProcessDataDto && data.objectivesProcessDataDto.businessType) !== 1) {
        data.userRoleType = 0
      } else {
        data.userRoleType = 2
      }
      if (this.editState === true) {
        data.userRoleType = 0
      }
      var topMembersBeansDetail = JSON.parse(JSON.stringify(data))
      var fatherType = ''
      if (topMembersBeansDetail.fatherObjectivesAssociationUserList.length >= 1) {
        if (topMembersBeansDetail.fatherObjectivesAssociationUserList.length === 1 && topMembersBeansDetail.fatherObjectivesAssociationUserList[0].alignedCount === 1) {
          fatherType = 'KR'
          if (topMembersBeansDetail.fatherObjectivesAssociationUserList[0].businessType === 0) {
            fatherType = 'O'
          }
        } else {
          fatherType = 'list'
        }
      }
      topMembersBeansDetail.fatherType = fatherType
      var childNameObjList = [] // 存放子目标数据
      var childNameList = {} // 存放用户父目标数据是否创建
      var childObjectivesAssociationObjectivesList =
        (topMembersBeansDetail.childObjectivesAssociationUserList || []).concat(
          topMembersBeansDetail.objectivesCollaboratorList
        )
      childObjectivesAssociationObjectivesList.forEach((oItem) => {
        var userId = oItem.isAlign === 0 ? oItem.userid : oItem.userDto.id
        if (!childNameList[userId]) {
          // 未创建
          childNameObjList.push(oItem)
          childNameList[userId] = userId
        }
      })
      topMembersBeansDetail.childNameObjList = childNameObjList
      this.topMembersBean = topMembersBeansDetail
    },
    // 显示共享列表
    showShareList() {
      this.$emit('showShareList', this.topMembersBeans.id)
    },
    privateOkrContent(create, item, type) {
      // type:1 带锁
      var content = ''
      if (item.objectivesPower && item.objectivesPower.lookRulesType === 1) {
        content = '目标所属人可见'
      } else if (
        item.objectivesPower &&
        item.objectivesPower.lookRulesType === 2
      ) {
        content = '部分人可见'
      }
      return content
    },
    addIgnoreRule(id) {
      this.ignoreStateChange = true
      this.form.ignoreRuleIdList.push(id)
    },
    mouseenterClose(val) {
      this.hoverClose = val
    },
    // 点击对齐头像
    getTopMembersHead() {
      if (this.topMembersState === '1') {
        // 详情
      } else if (this.topMembersState === '2') {
        // 列表
      }
    },
    // 对齐我的人员移入方法
    getHomeListMouseover() {
      if (this.topMembersState === '1') {
        // 详情
      } else if (this.topMembersState === '2') {
        // 列表
      }
    },
    // 编辑内容
    getTopMembersNameEditor() {
      if (this.topMembersBean.userRoleType === 0) {
        this.form.comment = this.topMembersBean.content
        this.form.comment2 = this.$utils.changeToHtml(
          this.topMembersBean.content
        )
        this.topMembersNameEditor = false
        this.$nextTick(() => { // 输入框自动聚焦
          if (this.$refs.myatinput) {
            this.$refs.myatinput.cursorInPosition()
          }
        })
      }
    },
    // 获取焦点事件
    objTitleFocus(obj, index) {
      console.log('obj')
      console.log('obj')
      this.$emit('focusObjTitle')
    },
    // 修改关键结果内容
    async changeObjectiveTitle(obj, index) {
      this.form.comment = this.$utils.changeToOkrtFormat(obj.html)
      this.form.userId = obj.atIds.toString()
      // if (this.topMembersBean.isShare === 1 && obj.atIds.toString() !== '') {
      //   this.$message({
      //     msg: '您的目标已设为私密，不能@他人哦',
      //     type: 'warn'
      //   })
      //   this.topMembersNameEditor = true
      //   return
      // }
      if (this.form.comment2 !== obj.html) {
        if (this.form.comment.trim() !== '') {
          const dataList = {
            collaboratorStr: this.form.userId,
            content: this.$utils.changeToOkrtFormat(this.form.comment),
            contentSearch: this.$utils.tagFilter(
              this.$utils.changeToHtml(this.form.comment)
            ),
            id: this.detailsId
          }
          await this.$apis
            .OKRUPDATETITLE(dataList)
            .then(async (rest) => {
              // console.log(rest)
              this.$message({
                msg: '修改成功',
                type: 'success'
              })
              await this.getTopMembers()
              if (this.topMembersState === '2') {
                this.$emit('updateObjTitle')
              }
              this.bus.$emit('getKeyResultsAlertCloseBus')
              this.topMembersNameEditor = true
              if (localStorage.getItem('approveRemindState') !== 'true' && this.topMembersBean.processState === 1 && this.$store.state.user.approvalCheckData.onOff === 0 && this.userInfo.id == this.topMembersBean.createUserid) {
                this.$store.commit('okr/approveRemindObj', {
                  visible: true,
                  objDetail: {
                    id: this.topMembersBean.id,
                    ...this.topMembersBean
                  }
                })
              }
              this.$emit('mapRefresh', this.objDetail)
            })
            .catch((err) => {
              console.log(err)
            })
        } else {
          this.$message({
            msg: '目标内容不能为空哦',
            type: 'warn'
          })
          this.form.comment = this.topMembersBean.content
          this.form.comment2 = this.$utils.changeToHtml(
            this.topMembersBean.content
          )
          this.topMembersNameEditor = true
        }
      } else if (this.ignoreStateChange) {
        this.getTopMembers()
      } else {
        this.topMembersNameEditor = true
      }
    },
    // 获取内容头部接口
    async getTopMembers() {
      if ((this.detailsId || '') === '') {
        return
      }
      await this.$apis
        .OKROBJINFO(this.detailsId)
        .then((rest) => {
          if (rest.status === 200) {
            this.$emit('backData', rest.data)
            const objInfo = rest.data
            this.objDetail = objInfo
            objInfo.keyresultsList.forEach((item) => {
              item.title = this.$utils.changeToHtml(item.title)
            })
            // if (objInfo.fatherObjectives) {
            //   objInfo.fatherObjectives.content = this.$utils.changeToHtml(
            //     objInfo.fatherObjectives.content
            //   )
            // }
            this.initData(objInfo)
            this.bus.$emit('InitializeData', objInfo)
          } else {
            this.$message.error(rest.message)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },

    getTopMembersAlignmentDetele() {
      // 多对齐修改
      var data = {
        id: this.topMembersBean.fatherObjectivesAssociationUserList[0]
          .objectivesAssociationList[0].businessId,
        objId:
          this.topMembersBean.fatherObjectivesAssociationUserList[0]
            .objectivesAssociationList[0].beAlignedObjId
      }
      var state = {
        businessType:
          this.topMembersBean.fatherObjectivesAssociationUserList[0]
            .objectivesAssociationList[0].businessType,
        type: 'delete'
      }
      this.getTargetAligningSubmit(data, state)
    },
    // 弹框对齐显示内容提交
    async getTargetAligningSubmit(data, state) {
      if (this.topMembersBean.userRoleType !== 0) {
        this.$message.warn('暂无修改权限哦')
        return
      }
      var url = this.$apis.OKRADDFATHERALIGN
      // 多对齐修改
      var params = {
        activeAlignObjId: this.topMembersBean.id,
        beAlignedObjId: state.businessType === 0 ? data.id : data.objId,
        businessType: state.businessType,
        businessId: data.id
      }
      if (state.type === 'delete') {
        url = this.$apis.OKRDELETEFATHERALIGN
        // params = data.childObjectivesAssociationList[0]
      }
      url(params)
        .then((rest) => {
          if (rest.status === 200) {
            if (state.type === 'add') {
              this.$mixpanelUtil.track('Complete  Invite OKR', {
                view_type: this.topMembersState === '1' ? 'map' : 'list'
              })
            }
            this.$message.success(rest.message)
            this.$emit('delteAlignObj')
            this.targetAligningShow = false
            this.getTopMembers()
            if (localStorage.getItem('approveRemindState') !== 'true' && this.topMembersBean.processState === 1 && this.$store.state.user.approvalCheckData.onOff === 0 && this.userInfo.id == this.topMembersBean.createUserid) {
              this.$store.commit('okr/approveRemindObj', {
                visible: true,
                objDetail: {
                  id: this.topMembersBean.id,
                  ...this.topMembersBean
                },
                businessData: JSON.stringify(params),
                businessActionType: 4
              })
            }
          } else {
            this.$message.error(rest.message)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 点击目标对齐头像
    getTopMembersFatherList(dataBeans) {
      if (this.topMembersState === '1') {
        // 详情
        if (dataBeans.isAlign === 1) {
          this.getTopMembersAlignmentSelect(dataBeans)
        }
      } else if (this.topMembersState === '2') {
        // 列表
        this.bus.$emit('toFatherOkrList', dataBeans)
      }
    },

    // 点击目标对齐名称
    async getTopMembersAlignmentSelect(dataBeans) {
      // if (this.topMembersBean.userRoleType !== 0) {
      //   this.$message.warn('暂无权限修改哦！')
      //   return
      // }
      const that = this
      if (this.topMembersNameEditor === true) {
        if (this.topMembersState === '1') {
          // 详情
          if ((dataBeans.id || '') === '' || this.fromType === 'approvalDetail') {
            return
          }
          this.$apis
            .OKROBJINFO(dataBeans.id)
            .then((rest) => {
              this.$mixpanelUtil.track('View Superior OKR')
              const objInfo = rest.data
              objInfo.content = this.$utils.changeToHtml(objInfo.content)
              objInfo.keyresultsList.forEach((item) => {
                item.title = this.$utils.changeToHtml(item.title)
              })
              if (objInfo.fatherObjectives) {
                objInfo.fatherObjectives.content = this.$utils.changeToHtml(
                  objInfo.fatherObjectives.content
                )
              }
              that.topMembersSelectBeans.push(objInfo)
              this.bus.$emit(
                'topMembersSelectBeans',
                that.topMembersSelectBeans
              )
              this.initData(objInfo)
              this.bus.$emit('InitializeData', rest.data)
            })
            .catch((err) => {
              console.log(err)
            })
        } else if (this.topMembersState === '2') {
          // 列表
          this.bus.$emit('toFatherOkr', dataBeans)
        }
      } else {
        this.$message({
          msg: '您还有操作未完成',
          type: 'warn'
        })
      }
    },

    async showChildHoverBox(data) {
      const that = this
      if (that.activeHoverId === data.id) {
        return
      }

      that.activeHoverId = data.id
      that.hoverTargetData = {
        alignData: {}
      }
      if (data.isAlign === 0) {
        that.hoverTargetData = data
        that.hoverTargetData.objId = this.detailsId
        that.hoverTargetData.isAlignMe =
          this.userInfo.id === this.topMembersBean.createUserid
      } else {
        if ((data.id || '') === '') {
          return
        }
        this.$apis
          .OKROBJINFO(data.id)
          .then((rest) => {
            if (rest.status === 200) {
              const objInfo = rest.data
              objInfo.content = this.$utils.changeToHtml(objInfo.content)
              objInfo.keyresultsList.forEach((item) => {
                item.title = this.$utils.changeToHtml(item.title)
              })
              if (objInfo.fatherObjectives) {
                objInfo.fatherObjectives.content = this.$utils.changeToHtml(
                  objInfo.fatherObjectives.content
                )
              }
              that.hoverTargetData = Object.assign(data, {
                alignData: objInfo
              })
            } else {
              this.$message({
                msg: rest.message,
                type: 'error'
              })
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    // 进入对齐XXX的目标列表
    toAlignSelfTarget() {
      this.bus.$emit('toChildOkrList', this.topMembersBean)
    },
    // 对齐目标按钮
    getTopMembersAlignmentTarget() {
      // if (this.userInfo.id === this.topMembersBean.createUserid) {
      //   if (this.topMembersBean.isShare === 0) {
      this.targetAligningObj = this.topMembersBean
      this.targetAligningShow = true
      this.bus.$emit('getTopMembersAlignmentTarget', this.topMembersBean)
      //   } else {
      //     this.$message({
      //       msg: '您的目标已设为私密，不能添加对齐目标哦',
      //       type: 'warn'
      //     })
      //   }
      // }
    },
    // 弹框对齐显示内容
    getTargetAligningClose() {
      this.targetAligningShow = false
      this.$refs.targetAligning.getTargetAligningClose()
    }
  }
}
</script>
<style lang="less" scoped>
.father-align-list-wrap {
  .flex-l();
  flex-wrap: wrap;
  margin-top: -8px;
  .align-list {
    padding: 4px;
    border-radius: 40px;
    margin: 0px;
    &:hover {
      background: #f5f6fa;
    }
  }
  .align-list,
  .align-detail {
    .flex-l();
    margin-right: 8px;
    cursor: pointer;
    .img {
      width: 32px;
      height: 32px;
      border-radius: 16px;
    }
    .name {
      color: #6e7491;
      max-width: 60px;
      .text-n(1);
      margin-left: 4px;
    }
    .num {
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      border-radius: 12px;
      background: #f5f6fa;
      margin-left: 4px;
      font-weight: bold;
      color: #444558;
    }
  }
}
.box-construction-li-top-align {
  .box_construction_li_top_headImg_top {
    margin: 12px 9px 0 15px !important;
  }
  .box_construction_li_top_line {
    margin-top: -3px;
  }
}
</style>
<style lang="scss" scoped>
.top_members {
  width: 100%;
  padding-top: 1px;
  .box-construction-li-top-content {
    // min-height: 85px;
    // .box_construction_li_top_headImg {
    //   min-height: 85px;
    // }
  }
  .box_construction_li_top {
    width: 100%;
    display: flex;
    // margin: 33px auto 0px auto;
    .box_construction_li_top_headImg {
      display: flex;
      flex-direction: column;
      width: 62px;
      // border: 1px solid red;
      .construction_li_top_headImg_img_box {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        // overflow: hidden !important;
        z-index: 1;
        // 共享目标头像 - 样式
        .share-okr-avatar-div {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          overflow: hidden;
          .share-okr-avatar-two {
            width: 40px;
            height: 40px;
            display: flex;
            flex-direction: row;
            cursor: pointer;
            .two-avatar-left {
              width: 20px;
              overflow: hidden;
              height: 40px;
              position: relative;
              img {
                width: 46px;
                height: 46px;
                position: absolute;
                left: -12px;
                top: -5px;
              }
            }
            .two-avatar-right {
              width: 20px;
              overflow: hidden;
              height: 40px;
              position: relative;
              img {
                width: 40px;
                height: 40px;
                position: absolute;
                left: -14px;
                top: 0;
              }
            }
          }
          .share-okr-avatar-three {
            cursor: pointer;
            width: 40px;
            height: 40px;
            display: flex;
            flex-direction: row;
            .share-okr-creator {
              width: 20px;
              overflow: hidden;
              height: 40px;
              position: relative;
              img {
                width: 46px;
                height: 46px;
                position: absolute;
                left: -12px;
                top: -5px;
              }
            }
            .share-okr-user {
              width: 20px;
              height: 40px;
              display: flex;
              flex-direction: column;
              .avatar-top {
                width: 20px;
                height: 20px;
                position: relative;
                overflow: hidden;
                img {
                  width: 40px;
                  height: 40px;
                  position: absolute;
                  left: -14px;
                  top: -10px;
                }
              }
              .avatar-btm {
                width: 20px;
                height: 20px;
                overflow: hidden;
                position: relative;
                img {
                  width: 40px;
                  height: 40px;
                  position: absolute;
                  left: -14px;
                  top: -14px;
                }
              }
            }
          }
        }

        .construction_li_top_headImg_img {
          width: 40px;
          height: 40px;
        }
        .headImg_img_click {
          cursor: pointer;
        }
      }
      .box_construction_li_top_headImg_top {
        font-size: 30px;
        color: #d9dbe9;
        margin: 17px 9px 0 19px;
      }
      .box_construction_li_top_line {
        width: 20px;
        flex: 1;
        border-right: 1px solid #d9dbe9;
      }
      .box_construction_li_top_headImg_bottom {
        font-size: 30px;
        color: #d9dbe9;
        margin: 0px 9px 10px 15px;
      }
    }

    .box_construction_li_top_content {
      width: calc(100% - 62px);
      height: 100%;
      display: flex;
      flex-direction: column;
      .construction_li_top_content_1 {
        width: 100%;
        min-height: 34px;
        display: flex;
        &:hover {
          .construction_li_top_content_1_text {
            display: block;
          }
        }
        .construction_li_top_content_1_text-true {
          display: block !important;
        }
        .construction_li_top_content_1_text {
          min-width: 130px;
          height: 34px;
          font-size: 14px;
          font-weight: 400;
          text-align: LEFT;
          color: #ff9900;
          line-height: 34px;
          padding: 4px 0px 4px 8px;
          cursor: pointer;
          display: none;
          &:hover {
            color: #ffcc80;
          }
        }
        .construction_li_top_content_1_text_color {
          cursor: default;
          color: #1b1b4e;
          &:hover {
            color: #1b1b4e;
          }
        }
        .construction_li_top_content_2_img_box {
          width: 30px;
          height: 30px;
          flex-shrink: 0;
          border-radius: 50%;
          overflow: hidden;
          .construction_li_top_content_2_img {
            width: 30px;
            height: 30px;
          }
        }
        .father-type {
          min-width: 24px;
          height: 24px;
          line-height: 24px;
          font-weight: 500;
          color: #fff;
          background: #548af7;
          text-align: center;
          border-radius: 12px;
          font-size: 12px;
          margin-left: 8px;
        }
        .father-type-KR {
          padding: 0px 8px;
          color: #548af7;
          background: #e7effe;
        }
        .construction_li_top_content_2_avator {
          cursor: pointer;
        }

        .construction_li_top_content_2_text {
          height: 100%;
          font-size: 14px;
          font-weight: 400;
          text-align: left;
          color: #6e7491;
          margin-left: 8px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          line-height: 33px;
          margin-right: 8px;
          position: relative;
          max-width: 500px;
          .content {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            // min-width: 400px;
          }
          .flur-wrap {
            right: 0px;
            top: 3px;
            height: 26px;
          }
          &:before {
            content: '';
            display: block;
          }
        }
        .construction_li_title_box {
          position: relative;
          max-width: 100%;
          border: 1px solid transparent;
          display: flex;
          min-width: 0;
          padding: 4px 0px 4px 4px;
          margin-right: 8px;
          .father-one-wrap {
            width: 100%;
          }
          .construction_li_box {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex: 1;
            overflow: hidden;
          }
          .construction_li_top_content_2_icon {
            width: 18px;
            height: 18px;
            background: #fff;
            border-radius: 50%;
            position: absolute;
            right: -4px;
            top: -4px;
            display: none;
            i {
              cursor: pointer;
              font-size: 18px;
              color: #a0a3bd;
            }
            i:hover {
              color: #ff9900;
            }
          }
        }
        .construction_li_top_content_1_style {
          margin-right: 16px;
        }
        .construction_li_top_content_1_style:hover {
          background: #f5f6fa;
          border: 1px solid #f5f6fa;
          border-radius: 22px;
          .construction_li_top_content_2_icon {
            display: block;
          }
        }
        .hover-close:hover {
          border: 1px solid #ff9900;
          background: #fff9ef !important;
        }
      }

      .construction_li_top_content_text {
        width: 100%;
        // height: 38px;
        // margin-top: 9px;
        margin-right: 9px;
        color: #14142b;
        // border: 1px solid red;
        .construction_li_top_content_text_1:hover {
          background: #f5f6fa;
          border-radius: 8px;
          color: #14142b;
        }
        .construction_li_top_content_text_1 {
          width: calc(100% - 43px);
          height: 100%;
          float: left;
          border: 1px solid rgba(255, 255, 255, 0);
          // overflow:hidden;
          // white-space:pre-wrap;
          word-break: break-word;
          // text-overflow: ellipsis;
          position: relative;
          padding: 8px;
          font-size: 16px;
          font-weight: 700;
          text-align: left;
          line-height: 23px;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          .construction_li_top_content_text_1_pos {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0px;
            top: 0px;
          }
        }
        .construction_li_top_content_text_1_input {
          width: calc(100% - 48px);
          float: left;
          background: #fff;
          border: 1px solid #3a78f6;
          border-radius: 8px;
          height: 100%;
          box-shadow: 0px -3px 10px 0px rgba(50, 150, 250, 0.14) inset;
          ::v-deep .at-container {
            min-height: 0;
            line-height: 23px;
            height: 100%;
            // word-break: break-word;
            // overflow-x: hidden;
            font-size: 16px;
            font-weight: 700;
            color: #14142b;
          }
        }
      }

      .construction_li_top_content_personnel {
        width: 100%;
        // height: 38px;
        margin-top: 9px;
        display: flex;
        align-items: center;
        // border: 1px solid yellow;
        .content_personnel_li {
          width: 38px;
          height: 38px;
          float: left;
          margin-left: 13px;
          cursor: pointer;
          .content_personnel_li_style {
            width: 30px;
            height: 30px;
            margin-left: 16px;
            position: relative;
            .content_personnel_li_style_img {
              width: 30px;
              height: 30px;
              display: block;
              margin: 0px auto 0px auto;
              border-radius: 100%;
            }
            .avatar-modal {
              position: absolute;
              top: 0;
              left: 0;
              opacity: 0.24;
              background: #14142b;
            }
            &:hover {
              .avatar-modal {
                display: none;
              }
            }
          }

          .content_personnel_li_style:first-child {
            margin-left: 0px;
          }
        }
        .more-avator {
          width: 30px;
          height: 30px;
          padding: 3px;
          margin-left: 5px;
          .more-avator-icon {
            font-size: 24px;
            color: #a0a3bd;
            width: 24px;
            height: 24px;
          }
          &:hover {
            background: #f5f6fa;
            border-radius: 4px;
          }
        }
        .content_personnel_li_style_next {
          width: 24px;
          height: 24px;
          float: left;
          margin-left: 10px;
          font-size: 20px;
          color: #a0a3bd;
          border: 1px solid #a0a3bd;
          border-radius: 50%;
          cursor: pointer;
          background-size: 100% 100%;
          .icon {
            width: 22px;
            height: 22px;
          }
          &:hover {
            color: #0035b7;
            border: 1px solid #0035b7;
          }
        }
        .content_personnel_li:first-child {
          margin-left: 0px;
        }
        .content_personnel_li_icon {
          width: 13px;
          height: 23px;
          background-size: 100% 100%;
          float: left;
          margin-left: 15px;
          cursor: pointer;
        }
      }
    }
  }
}
.construction_li_top_content_text_1:hover {
  color: #0035b7;
}
.cursor-text {
  cursor: text;
}
.cursor-no {
  cursor: default;
}
</style>
