var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "editor-box",
      class: _vm.detailStyle ? "detail-editor" : "",
    },
    [
      _c("tinymce-editor", {
        ref: "editor",
        attrs: {
          id: "Editor",
          "api-key": "6zmiw20kgeupc46et5f2r7zblaf93pqaj3s98qucufgqe63y",
          init: _vm.init,
          disabled: _vm.disabled,
        },
        on: {
          onChange: _vm.onChange,
          input: _vm.onInput,
          onFocus: _vm.onFocus,
          onBlur: _vm.onBlur,
        },
        model: {
          value: _vm.information,
          callback: function ($$v) {
            _vm.information = $$v
          },
          expression: "information",
        },
      }),
      _vm._m(0),
      _vm._m(1),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择粘贴的形式",
            modal: false,
            visible: _vm.dialogVisible,
            width: "460px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "excel-content" }, [
            _c("img", { attrs: { src: _vm.excelImg, alt: "excel" } }),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { staticClass: "change-img", on: { click: _vm.changeImg } },
                [_vm._v("转为图片")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "change-img",
                  attrs: { type: "primary" },
                  on: { click: _vm.retainString },
                },
                [_vm._v("保留文字")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("form", { attrs: { id: "imageForm" } }, [
      _c("input", {
        staticClass: "file-upload-input",
        attrs: {
          id: "uploadImage",
          type: "file",
          accept:
            "image/gif, image/jpeg, image/png, image/jeg, image/svg, image/bmp",
          name: "picture",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("form", { attrs: { id: "fileForm" } }, [
      _c("input", {
        staticClass: "file-upload-input",
        attrs: {
          id: "uploadFile",
          type: "file",
          name: "file",
          accept:
            ".xls, .doc, .txt, .pdf, .docx, .xlsx, .ppt, .xls, .wps, .pps, .pptx, .dll",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }