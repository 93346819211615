export default [
  {
  path: '/okr',
  name: 'okr',
  component: () => {
    return import(/* webpackChunkName: "okr" */ '../../views/okr')
  }
},
{
  path: '/okrListNull',
  name: 'okrListNull',
  component: () => {
    return import(/* webpackChunkName: "okrListNull" */ '../../views/okr/components/okrListNull')
  }
}
]
