<template>
  <div
    class="detail-list"
    :class="`detail-list-${keyItem.handleType || keyItem.businessType === 0}`"
  >
    <div class="detail-left">
      <div
        class="type"
        :class="`status-con status-con${keyItem.taskState}`"
        v-if="itemType === 'advanceFromTask'"
      >{{ statusDict[keyItem.taskState] }}</div>
      <div
        :class="`type type-${keyItem.handleType || keyItem.businessType === 0}`"
        v-else
      >{{keyItem.businessType === 0 || keyItem.handleType ? `O${index + 1}` : `KR${index + 1}`}}</div>
      <div
        class="content"
        v-html="keyItem.handleType ? $utils.changeToHtml(keyItem.groupObjectTitle) : $utils.changeToHtml(keyItem.title)"
      ></div>
    </div>
    <div class="detail-right">
      <div class="push-to">
        将推进
        <div v-if="itemType === 'advanceTo'" class="user-name">
          <wx-open-data :id="keyItem.userName" :nameData="{name:keyItem.userName}" />
        </div>
        <div v-else class="user-name">
          <wx-open-data :id="okrData.userName" :nameData="{name:okrData.userName}" />
        </div>
        {{keyItem.pushTo}}{{keyItem.unit}}
      </div>
      <div
        class="current-value"
        v-if="!keyItem.handleType"
      >已推进{{keyItem.currentValue}}{{unit || item.quantificationUnit}}</div>
      <div
        class="cycle-name"
        v-if="keyItem.handleType || keyItem.businessType === 0"
      >{{keyItem.cycleInfoName}}</div>
    </div>
  </div>
</template>
<script>
import taskEnums from '@/utils/enums/task.js'
export default {
  props: {
    okrData: {},
    item: {},
    type: {
    },
    keyItem: {},
    index: {},
    unit: {},
    itemType: {
      default: 'advanceTo'
    } // advanceTo 我推进 advanceFrom: 推进我
  },
  data() {
    return {
      statusDict: taskEnums.TASKSTATUS, // 状态字典
      stateDictColor: taskEnums.TASKSTATUSCOLOR
    }
  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
.detail-list-true {
  .content {
    font-weight: 500;
    color: #444558;
  }
}
.detail-list {
  .flex-bt();
  padding: 8px 0px;
  font-size: 14px;
  font-weight: 400;
  color: #444558;
  .detail-left {
    .flex-l();
    .status-con1 {
      color: #6e7491 !important;
      background: #f7f7fc !important;
    }
    .status-con2 {
      background: #eef6fd !important;
      color: #005eff !important;
    }
    .status-con3 {
      background: #e9fbf5 !important;
      color: #00ba88 !important;
    }
    .status-con4 {
      background: #fff2ee !important;
      color: #ff572b !important;
    }
    ::v-deep .type-true {
      width: 24px;
      min-width: 24px !important;
      line-height: 24px !important;
      height: 24px !important;
      background: #548af7 !important;
      padding: 0px !important;
      color: #fff !important;
    }
    .type {
      min-width: 35px;
      height: 20px;
      background: rgba(58, 120, 246, 0.08);
      border-radius: 13px;
      margin-right: 12px;
      line-height: 20px;
      text-align: center;
      color: #3a70ff;
      font-weight: 500;
      font-size: 12px;
      padding: 0px 10px;
    }
    .content {
      .text-n(1);
    }
    flex: 1;
    min-width: 50%;
  }
  .cycle-name {
    height: 24px;
    font-size: 10px;
    padding: 0px 8px;
    font-weight: 500;
    color: #548af7;
    border: 1px solid #548af7;
    border-radius: 12px;
    line-height: 22px;
  }
  .detail-right {
    .flex-l();
    justify-content: flex-end;
    .push-to {
      .flex-l();
      .user-name {
        .text-n(1);
        margin-left: 0px;
        max-width: 120px;
      }
    }
    .current-value {
      color: #3a70ff;
      margin-left: 12px;
      .text-n(1);
      min-width: 100px;
    }
  }
}
</style>
