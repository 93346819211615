// 任务重复类型
const REPEATTYPE = {
  1: '不重复',
  2: '每天重复',
  3: '每周重复',
  4: '每月重复',
  5: '每年重复',
  6: '工作日重复',
  7: '自定义重复'
}
// 自定义重复类型
const CUSTOMTYPE = {
  1: '每天重复',
  2: '每周重复',
  3: '每月重复'
}

// 日程重复类型
const SCHEDULETYP = {
  0: '不重复',
  1: '每天重复',
  2: '每周重复',
  3: '每月重复',
  4: '每年重复',
  5: '工作日重复',
  6: '自定义重复'
}

// 重复类型
const CYCLENUMTYPE = {
  not: 1, // 不重复
  custom: 7 // 自定义时间
}
// 自定义重复类型
const CYCLECUSTOMTYPE = {
  // not: 1, // 不重复
  daily: 1, // 每天重复
  weekly: 2, // 每周重复
  month: 3 // 每月重复
}

export default {
  REPEATTYPE,
  CUSTOMTYPE,
  SCHEDULETYP,
  CYCLENUMTYPE,
  CYCLECUSTOMTYPE
}
