<template>
  <div class="keyResults" @click="getAddkeyList">
    <Icon icon-class="icon-a-tianjia2" class="keyResultsImg" />
    <div class="keyResultsText">关键结果</div>
  </div>
</template>
<script>
export default {
  props: {
    topMembersBean: {
      type: Object,
      required: false
    },
    needLimit: {
      // 需要限制
      type: Boolean,
      default: true
    }
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {
    getAddkeyList() {
      if (!this.needLimit || this.topMembersBean.keyresultsList.length < 10) {
        this.$emit('getAddkeyList', '编辑')
      } else {
        this.$message({
          msg: '关键结果不能大于10个',
          type: 'warn'
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.keyResults {
  width: 100px;
  height: 42px;
  display: flex;
  align-items: center;
  color: #3296fa;
  cursor: pointer;
  .keyResultsImg {
    font-size: 24px;
    background-size: 100% 100%;
  }
  .keyResultsText {
    height: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: 42px;
    margin-left: 8px;
  }
  &:hover {
    color: #005eff;
  }
}
</style>
