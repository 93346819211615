// admin模块
// 统一管理所有的基础请求
import apis from '../apis_moudles/index'
import req from '../request'
// 会议列表
const MEETINGLIST = (params) => {
  return req.get(`${apis.meetingList}`, {
    params
  })
}
// 会议新增
const MEETINGADD = (params) => {
  return req.post(`${apis.meetingAdd}`, params)
}
// 会议删除
const MEETINGDELETE = (id, params) => {
  return req.delete(`${apis.meetingList}/${id}`, {
    params
  })
}
// 会议开启 跳过
const MEETINGSKIPCLOSE = (url, params) => {
  return req.put(`${apis.meetingSkipClose}${url}`, params)
}
// 会议设置
const MEETINGSETTING = (params) => {
  return req.put(`${apis.meetingSetting}`, params)
}
// 会议变更参会人员
const MEETINGUSERUPDATE = (params) => {
  return req.put(`${apis.meetingUserUpdate}`, params)
}
// 获取会议提醒设置
const MEETINGSETTINGDETAIL = (id, params) => {
  return req.get(`${apis.meetingSetting}/${id}`, {
    params
  })
}
// 会议周期列表
const MEETINGCYCLELIST = (params) => {
  return req.get(`${apis.meetingCycleList}`, {
    params
  })
}
// 获取会议信息
const MEETINGINFO = (params) => {
  return req.get(`${apis.meetingInfo}`, {
    params
  })
}
// 获取会议纪要列表
const MINUTESLIST = (params) => {
  return req.get(`${apis.minutesList}`, {
    params
  })
}
// 获取会议概述/附件
const MEETINGCONTENT = (params) => {
  return req.get(`${apis.meetingContent}`, {
    params
  })
}
// 获取综合目标列表 (当前)
const TARGETDATA = (params, type) => {
  if (type === 0) {
    return req.get(`${apis.targetData}`, {
      params
    })
  } else {
    return req.get(`${apis.targetDataHistory}`, {
      params
    })
  }
}
// 获取个人目标列表（当前）
const USERTARGETDATA = (params, type) => {
  console.log('239249234', params, type)
  if (type === 0) {
    return req.get(`${apis.userTargetData}`, {
      params
    })
  } else {
    return req.get(`${apis.userTargetDataHistory}`, {
      params
    })
  }
}
// 获取目标概览
const TARGETCHARTDATA = (params) => {
  return req.get(`${apis.targetChartData}`, {
    params
  })
}
// 获取任务概览数据
const TASKCHARTDATA = (params) => {
  return req.get(`${apis.taskChartData}`, {
    params
  })
}
// 任务燃尽图
const TASKECHART = (params) => {
  return req.get(`${apis.taskEchart}`, {
    params
  })
}
// 上期进度与本期进度对比
const PROGRESSECHART = (params) => {
  return req.get(`${apis.progressEchart}`, {
    params
  })
}
// 获取综合数据概览
const ALLTARTDATA = (params) => {
  return req.get(`${apis.allTargetData}`, {
    params
  })
}
// 获取目标进度趋势图
const TARGETTRENDCHARTDATA = (params) => {
  return req.get(`${apis.targetTrendChartData}`, {
    params
  })
}
// 获取总结详情
const SUMMARYDETAILS = (summaryId, params) => {
  return req.get(`${apis.summaryDetails}${summaryId}`, {
    params
  })
}
// 获取已读人员列表
const READUSERLIST = (params) => {
  return req.get(`${apis.readUserList}`, {
    params
  })
}
// 获取问题详情
const PROBLEMINFO = (params) => {
  return req.get(`${apis.problemInfo}`, {
    params
  })
}
// 获取问题建议
const PROBLEMADVISELIST = (params) => {
  return req.get(`${apis.problemAdviseList}`, {
    params
  })
}
// 提交问题建议
const SUBMITPROBLEMADVISE = (params) => {
  return req.post(`${apis.submitProblemAdvise}`, params)
}
// 会议问题采纳建议
const ADOPTADVISE = (params) => {
  return req.post(`${apis.adoptAdvise}`, params)
}
// 提交会议自述
const SUBMITSELFACCOUNT = (params) => {
  return req.post(`${apis.submitSelfAccount}`, params)
}
// 添加会议问题
const ADDQUESTION = (params) => {
  return req.post(`${apis.addQuestion}`, params)
}
// 会议问题列表（上期）
const OLDPROBLEMLIST = (params) => {
  return req.get(`${apis.oldProblemList}`, {
    params
  })
}
// 会议问题列表（本期）
const NEWPROBLEMLIST = (params) => {
  return req.get(`${apis.newProblemList}`, {
    params
  })
}
// 获取问题列表（未解决）
const UNSOLVEDLIST = (params) => {
  return req.get(`${apis.unsolvedList}`, {
    params
  })
}
// 保存会议纪要
const SAVERECORT = (params) => {
  return req.post(`${apis.saveRecord}`, params)
}
// 提交会议纪要
const SUBMITRECORT = (params) => {
  return req.post(`${apis.submitRecord}`, {
    params
  })
}
// 获取会议纪要详情
const MINUTESDETAILS = (params) => {
  return req.get(`${apis.minutesDetails}`, {
    params
  })
}
// 结束会议
const CLOSEMEETING = (params) => {
  return req.put(`${apis.closeMeeting}`, params)
}
// 会议概述
const MEETINGINFOCONTENT = (params) => {
  return req.get(`${apis.meetingInfoContent}`, {
    params
  })
}
// 更改会议详情信息
const MEETINGINFOCONTENTUPDATE = (params) => {
  return req.post(`${apis.meetingInfoContent}/`, params)
}
// 获取周期内okr列表
const CYCLEOKRLIST = (id, params) => {
  return req.get(`${apis.cycleOkrList}${id}`, {
    params
  })
}
// 添加目标提交
const MEETINGADDTARGET = (params) => {
  return req.post(`${apis.meetingAddTarget}`, params)
}
// 获取okr主目标列表
const MEETINGMAINOKRLIST = (params) => {
  return req.get(`${apis.meetingMainOkrList}`, {
    params
  })
}
// 获取上级目标列表
const MEETINGLEADEROKRLIST = (params) => {
  return req.get(`${apis.meetingLeaderOkrList}`, {
    params
  })
}
// 分组查询目标列表
const GROUPOKRLIST = (params) => {
  return req.get(`${apis.groupOkrList}`, {
    params
  })
}
// 分组查询目标kr列表
const GROUPOKRKRLIST = (params) => {
  return req.get(`${apis.groupOkrKrList}`, {
    params
  })
}
// 删除目标
const MEETINGOKRDEL = (params) => {
  return req.delete(`${apis.meetingOkrDel}`, {
    params
  })
}
// 开始关键结果讨论
const OPENDISCUSSION = (id, params) => {
  return req.put(`${apis.openDiscussion}${id}`, params)
}
// 结束会议
const MEETINGCLOSE = (params) => {
  return req.put(`${apis.meetingClose}`, params)
}
// 同步至OKR
const SYNCHRONIZEOKR = (params) => {
  return req.put(`${apis.synchronizeOkr}`, params)
}
// 设置分组
const GROUPSET = (params) => {
  return req.post(`${apis.groupSet}`, params)
}
// 修改目标提交
const MEETINGOKRUPDATE = (params) => {
  return req.put(`${apis.meetingOkrUpdate}`, params)
}
// 采纳目标
const MEETINGOKRADOPT = (params) => {
  return req.put(`${apis.meetingOkrAdopt}`, params)
}
// 催一下
const MEETINGOKRURGE = (params) => {
  return req.put(`${apis.meetingOkrUrge}`, params)
}
// 编辑关键结果
const MEETINGKEYRESULTUPDATE = (params) => {
  return req.put(`${apis.meetingKeyresultUpdate}`, params)
}
// 删除关键结果
const MEETINGKEYRESULTDELETE = (id, params) => {
  return req.delete(`${apis.meetingKeyresultDelete}${id}`, {
    params
  })
}
// 采纳关键结果
const MEETINGKEYRESULTADOPT = (params) => {
  return req.put(`${apis.meetingKeyresultAdopt}`, params)
}
// 添加关键结果
const MEETINGKEYRESULTADD = (params) => {
  return req.post(`${apis.meetingKeyresultAdd}`, params)
}
// 修改量化条件
const MEETINGKEYRESULTQUANTIFICATION = (params) => {
  return req.put(`${apis.meetingKeyresultQuantification}`, params)
}
// 修改信心值
const MEETINGCONFIDENCE = (params) => {
  return req.put(`${apis.meetingConfidence}`, params)
}
// 修改权重
const MEETINGKEYRESULTWEIGHT = (params) => {
  return req.put(`${apis.meetingKeyresultWeight}`, params)
}
// 关键结果排序
const MEETINGKEYRESULTSORT = (params) => {
  return req.put(`${apis.meetingKeyresultSort}`, params)
}
// 达成共识
const MEETINGOKRCONSENSUS = (params) => {
  return req.put(`${apis.meetingOkrConsensus}`, params)
}
// 忽略目标
const MEETINGOKRHIDE = (params) => {
  return req.put(`${apis.meetingOkrHide}`, params)
}
// 获取okr子目标列表
const MEETINGOKRFATHER = (id, params) => {
  return req.get(`${apis.meetingOkrFather}${id}`, {
    params
  })
}
// 获取导入的总结列表
const SELSUMMARYLIST = (params) => {
  return req.get(`${apis.selSummaryList}`, {
    params
  })
}
// 导入总结
const SAVESUMMARYLIST = (params) => {
  return req.post(`${apis.saveSummaryList}`, params)
}
// 获取总结列表
const SUMMARYLIST = (params) => {
  return req.get(`${apis.summaryList}`, {
    params
  })
}
// 获取已导入列表
const SUMMARYSELLIST = (params) => {
  return req.get(`${apis.summarySelList}`, {
    params
  })
}
// 删除导入总结
const DELSUMMARYLIST = (meetingInfoSummaryId, params) => {
  return req.delete(`${apis.delSummaryList}${meetingInfoSummaryId}`, params)
}
// 删除文件
const FILEDELETE = (id, params) => {
  return req.delete(`${apis.fileDelete}${id}`, params)
}
// 获取进推进会列表
const MEETINGPROCESSLIST = (params) => {
  return req.get(`${apis.meetingProcessList}`, {
    params
  })
}
// 获取我的问题列表
const MEETINGMYQUESTIONLIST = (params) => {
  return req.get(`${apis.meetingMyQuestionList}`, {
    params
  })
}
// 新版推进会接口
// 回复评论
const PROGRESSNEWREPLAYCOMMENT = (params) => {
  return req.post(`${apis.progressNewReplayComment}`, params)
}
// 添加评论
const PROGRESSNEWADDCOMMENT = (params) => {
  return req.post(`${apis.progressNewAddComment}`, params)
}
// 添加工作说明/问题
const PROGRESSNEWADDEXPLAINSOLUTION = (params) => {
  return req.post(`${apis.progressNewAddExplainSolution}/all`, params)
}
// 隐藏/展现 字段
const PROGRESSNEWCHANGEPERMISSION = (params) => {
  return req.post(`${apis.progressNewChangePermission}`, params)
}
// 添加会议人员会议记录数据
const PROGRESSNEWSAVEUSERCONTENT = (params) => {
  return req.post(`${apis.progressNewSaveUserContent}`, params)
}
// 查询推进会的综合目标列表
const PROGRESSNEWGETCOMPREHENSIVE = (params) => {
  return req.get(`${apis.progressNewGetComprehensive}`, {
    params
  })
}
// 查询会议人员记录内容
const PROGRESSNEWGETUSERCONTENT = (params) => {
  return req.get(`${apis.progressNewGetUserContent}`, {
    params
  })
}
// 分页查询评论列表
const PROGRESSNEWGETCOMMENTCONTENT = (params) => {
  return req.get(`${apis.progressNewGetCommentContent}`, {
    params
  })
}
// 删除问题解决方案
const PROGRESSNEWDELETESOLUTION = (id, params) => {
  return req.delete(`${apis.progressNewDeleteSolution}${id}`, params)
}
// 删除评论
const PROGRESSNEWDELETECOMMENT = (id, params) => {
  return req.delete(`${apis.progressNewDeleteComment}${id}`, params)
}
// 删除工作说明/问题
const PROGRESSNEWDELETEEXPLAINSOLUTION = (params) => {
  return req.post(`${apis.progressNewDeleteExplainSolution}`, params)
}
// 添加问题解决方案
const PROGRESSNEWADDSOLUTION = (params) => {
  return req.post(`${apis.progressNewAddSolution}`, params)
}
// 修改问题解决方案
const PROGRESSNEWEDITSOLUTION = (params) => {
  return req.put(`${apis.progressNewAddSolution}`, params)
}
// 修改工作说明/问题
const PROGRESSNEWEDITEXPLAINSOLUTION = (params) => {
  return req.put(`${apis.progressNewAddExplainSolution}`, params)
}
// 修改会议记内容
const PROGRESSNEWEDITUSERCONTENT = (params) => {
  return req.put(`${apis.progressNewGetUserContent}`, params)
}
// 修改会议记录模式
const PROGRESSNEWEDITTYPE = (params) => {
  return req.put(`${apis.progressNewEditType}`, params)
}
// 修改进度会主目标id
const PROGRESSNEWEDITHOSTOBJ = (params) => {
  return req.put(`${apis.progressNewEditHostObj}`, params)
}
// 查询推进会人员列表+评论数量
const PROGRESSUSERLIST = (params) => {
  return req.get(`${apis.progressUserList}`, {
    params
  })
}
// 获取推进会读取状态
const PROGRESSREADSTATUS = (params) => {
  return req.get(`${apis.progressReadStatus}`, {
    params
  })
}
// 设置推进会已读状态
const PROGRESSSETREADSTATUS = (meetingInfoId, params) => {
  return req.post(`${apis.progressSetReadStatus}`, params)
}
// 推进会已读未读列表
const PROGRESSREADLIST = (params) => {
  return req.get(`${apis.progressReadList}`, {
    params
  })
}
// 查询推进会会议议程
const PROGRESSAGENDAINFO = (params) => {
  return req.get(`${apis.progressAgendaInfo}`, {
    params
  })
}
// 保存推进会会议议程
const SAVEPROGRESSAGENDA = (params) => {
  return req.post(`${apis.saveProgressAgenda}`, params)
}
// 会议附件列表
const PROGRESSFILELIST = (meetingInfoId, params) => {
  return req.get(`${apis.progressFileList}${meetingInfoId}`, {
    params
  })
}
// 会议附件绑定
const SAVEPROGRESSFILE = (params) => {
  return req.post(`${apis.saveProgressAgenda}`, params)
}
// 会议附件删除
const DELETEPROGRESSFILES = (params) => {
  return req.post(`${apis.deleteProgressFiles}`, params)
}
// 会议纪要关联任务
const PROGRESSRELEVANCETASK = (params) => {
  return req.post(`${apis.progressRelevanceTask}`, params)
}
// 添加/修改-工作说明 okr模式
const PROGRESSNEWEDITEXPLAINSOLUTIONV2 = (params) => {
  return req.post(`${apis.progressNewAddExplainSolutionV2}`, params)
}
// 删除工作说明 okr模式
const PROGRESSNEWDELETEEXPLAINSOLUTIONV2 = (params) => {
  return req.post(`${apis.progressNewDeleteExplainSolutionV2}`, params)
}
// 设置主持人/取消主持人接口
const MEETINGUSERSETPOWER = (params) => {
  return req.post(`${apis.meetingUserSetPower}`, params)
}
// 删除会议人员接口
const MEETINGUSERDELETE = (params) => {
  return req.post(`${apis.meetingUserDelete}`, params)
}
// 会议纪要删除关联任务
const MEETINGINFORECORDDELETETASK = (params) => {
  return req.delete(`${apis.meetingInfoRecordDeleteTask}`, {
    params
  })
}
const SAVEPROGRESSFILES = (params) => {
  return req.post(apis.saveProgressFiles, params, {
    timeout: 120000,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// 导出基础请求函数
export default [
  {
  SAVEPROGRESSFILES
},
{
  MEETINGINFORECORDDELETETASK
},
{
  MEETINGUSERDELETE
},
{
  MEETINGUSERSETPOWER
},
{
  PROGRESSNEWDELETEEXPLAINSOLUTIONV2
},
{
  PROGRESSNEWEDITEXPLAINSOLUTIONV2
},
{
  PROGRESSRELEVANCETASK
},
{
  DELETEPROGRESSFILES
},
{
  PROGRESSFILELIST
},
{
  SAVEPROGRESSFILE
},
{
  SAVEPROGRESSAGENDA
},
{
  PROGRESSAGENDAINFO
},
{
  PROGRESSREADLIST
},
{
  PROGRESSSETREADSTATUS
},
{
  PROGRESSREADSTATUS
},
{
  PROGRESSUSERLIST
},
{
  PROGRESSNEWEDITHOSTOBJ
},
{
  PROGRESSNEWEDITTYPE
},
{
  PROGRESSNEWEDITUSERCONTENT
},
{
  PROGRESSNEWEDITEXPLAINSOLUTION
},
{
  PROGRESSNEWEDITSOLUTION
},
{
  PROGRESSNEWADDSOLUTION
},
{
  PROGRESSNEWDELETEEXPLAINSOLUTION
},
{
  PROGRESSNEWDELETECOMMENT
},
{
  PROGRESSNEWDELETESOLUTION
},
{
  PROGRESSNEWGETCOMMENTCONTENT
},
{
  PROGRESSNEWGETUSERCONTENT
},
{
  PROGRESSNEWGETCOMPREHENSIVE
},
{
  PROGRESSNEWSAVEUSERCONTENT
},
{
  PROGRESSNEWCHANGEPERMISSION
},
{
  PROGRESSNEWADDEXPLAINSOLUTION
},
{
  PROGRESSNEWADDCOMMENT
},
{
  PROGRESSNEWREPLAYCOMMENT
},
{
  MEETINGMYQUESTIONLIST
},
{
  MEETINGPROCESSLIST
},
{
  MEETINGOKRFATHER
},
{
  MEETINGOKRHIDE
},
{
  MEETINGOKRCONSENSUS
},
{
  MEETINGKEYRESULTSORT
},
{
  MEETINGKEYRESULTWEIGHT
},
{
  MEETINGCONFIDENCE
},
{
  MEETINGKEYRESULTQUANTIFICATION
},
{
  MEETINGKEYRESULTADD
},
{
  MEETINGKEYRESULTADOPT
},
{
  MEETINGKEYRESULTDELETE
},
{
  MEETINGKEYRESULTUPDATE
},
{
  MEETINGOKRURGE
},
{
  MEETINGOKRADOPT
},
{
  MEETINGOKRUPDATE
},
{
  GROUPSET
},
{
  SYNCHRONIZEOKR
},
{
  MEETINGCLOSE
},
{
  OPENDISCUSSION
},
{
  MEETINGOKRDEL
},
{
  GROUPOKRKRLIST
},
{
  GROUPOKRLIST
},
{
  MEETINGLEADEROKRLIST
},
{
  MEETINGMAINOKRLIST
},
{
  MEETINGADDTARGET
},
{
  CYCLEOKRLIST
},
{
  MEETINGINFOCONTENTUPDATE
},
{
  MEETINGINFOCONTENT
},
{
  MEETINGCYCLELIST
},
{
  MEETINGSETTINGDETAIL
},
{
  MEETINGUSERUPDATE
},
{
  MEETINGSETTING
},
{
  MEETINGSKIPCLOSE
},
{
  MEETINGDELETE
},
{
  MEETINGADD
},
{
  MEETINGLIST
},
{
  MEETINGINFO
},
{
  MEETINGCONTENT
},
{
  TARGETDATA
},
{
  USERTARGETDATA
},
{
  TARGETCHARTDATA
},
{
  TASKCHARTDATA
},
{
  TASKECHART
},
{
  PROGRESSECHART
},
{
  SUMMARYDETAILS
},
{
  READUSERLIST
},
{
  PROBLEMINFO
},
{
  PROBLEMADVISELIST
},
{
  SUBMITPROBLEMADVISE
},
{
  ADOPTADVISE
},
{
  SUBMITSELFACCOUNT
},
{
  ADDQUESTION
},
{
  OLDPROBLEMLIST
},
{
  NEWPROBLEMLIST
},
{
  SAVERECORT
},
{
  SUBMITRECORT
},
{
  MINUTESDETAILS
},
{
  CLOSEMEETING
},
{
  SELSUMMARYLIST
},
{
  SAVESUMMARYLIST
},
{
  SUMMARYLIST
},
{
  SUMMARYSELLIST
},
{
  DELSUMMARYLIST
},
{
  FILEDELETE
},
{
  MINUTESLIST
},
{
  UNSOLVEDLIST
},
{
  ALLTARTDATA
},
{
  TARGETTRENDCHARTDATA
}
]
