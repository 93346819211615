var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      ref: "memberCardPopRef",
      attrs: {
        placement: "bottom",
        trigger: "hover",
        "popper-class": "member-card-popover",
        disabled: _vm.disabled,
        "open-delay": 300,
      },
      on: { hide: _vm.popoverHide, show: _vm.getUserInfo },
    },
    [
      _c("div", { staticClass: "member-card-pop" }, [
        _c("div", { staticClass: "align-top" }, [
          _c(
            "div",
            { staticClass: "member-avatar" },
            [
              _vm.userInfo.name
                ? _c("el-image", {
                    staticClass: "img border-radius-20",
                    class: !_vm.isAlign ? "img-gray" : "",
                    attrs: {
                      src:
                        _vm.userInfo.avatar ||
                        require("@/assets/imgs/common/avatar.png"),
                      fit: "cover",
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "avatar-info-box" },
                [
                  _vm.userInfo.name
                    ? _c(
                        "div",
                        { staticClass: "avatar-name" },
                        [
                          _c("wx-open-data", {
                            attrs: {
                              id: _vm.userInfo.name,
                              nameData: { name: _vm.userInfo.name },
                            },
                          }),
                        ],
                        1
                      )
                    : _c("div", { staticClass: "avatar-name" }, [
                        _vm._v("对方已删除"),
                      ]),
                  _c("el-tooltip", { attrs: { placement: "top" } }, [
                    _c("div", {
                      attrs: { slot: "content" },
                      domProps: {
                        innerHTML: _vm._s(
                          `${
                            _vm.$isQw && _vm.cardType === "list"
                              ? `${_vm.departNameAllSplit(
                                  _vm.userInfo.departNameAll
                                )}`
                              : `${_vm.userInfo.departNameAll}`
                          }`
                        ),
                      },
                      slot: "content",
                    }),
                    _c(
                      "div",
                      { staticClass: "avatar-job" },
                      [
                        _c("wx-open-data", {
                          attrs: {
                            type: 2,
                            id: _vm.userInfo.departName,
                            nameData: { name: _vm.userInfo.departName },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isAlign && _vm.isOkr
            ? _c(
                "div",
                { staticClass: "go-okr", on: { click: _vm.toOKRList } },
                [_vm._v("查看 Ta 周期内OKR")]
              )
            : _vm._e(),
        ]),
        _vm.targetAligningList.length > 0 && _vm.isAlign
          ? _c(
              "div",
              { staticClass: "okr-list-wrap" },
              _vm._l(_vm.targetAligningList, function (okr, okrIndex) {
                return _c(
                  "div",
                  { key: okr.id, staticClass: "okr-list" },
                  [
                    _c("objective-label", {
                      staticClass: "obj-label-position",
                      attrs: {
                        "page-type": "list",
                        okrData: okr,
                        canEdit: false,
                        "label-type": okr.labelType,
                        shareList: okr.objectivesSharedUserList,
                      },
                    }),
                    okr.cycleInfoId !== _vm.periodId && okr.cycleInfoDto
                      ? _c("div", { staticClass: "cycle-name" }, [
                          _vm._v(_vm._s(okr.cycleInfoDto.name)),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "okr-wrap" }, [
                      _c(
                        "div",
                        { staticClass: "okr-left" },
                        [
                          _c(
                            "div",
                            { staticClass: "okr-num" },
                            [
                              _vm._v(" O "),
                              _c("privateOkr", {
                                attrs: {
                                  createUser:
                                    _vm.userInfo.id === okr.createUserid,
                                  visible: okr.isVisible === 1,
                                  content:
                                    okr.isVisible === 1
                                      ? "对方已设置为不可见"
                                      : "",
                                  type: 4,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: _vm.$utils.tagFilter(
                                  _vm.$utils.changeToHtml(okr.content)
                                ),
                                "open-delay": 300,
                                manual: "",
                              },
                              model: {
                                value: okr.tooltipDisabled,
                                callback: function ($$v) {
                                  _vm.$set(okr, "tooltipDisabled", $$v)
                                },
                                expression: "okr.tooltipDisabled",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "okr-content",
                                  on: {
                                    mouseenter: function ($event) {
                                      return _vm.tooltipShow(
                                        "O",
                                        okrIndex,
                                        "",
                                        true
                                      )
                                    },
                                    mouseleave: function ($event) {
                                      return _vm.tooltipShow(
                                        "O",
                                        okrIndex,
                                        "",
                                        false
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("div", {
                                    class: `content content-${_vm.okrDetail}`,
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.changeToHtml(okr.content)
                                      ),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toOkrDetail(okr)
                                      },
                                    },
                                  }),
                                  _c("privateOkr", {
                                    attrs: {
                                      createUser:
                                        _vm.userInfo.id === okr.createUserid,
                                      visible: okr.isVisible === 1,
                                      content:
                                        okr.isVisible === 1
                                          ? "对方已设置为不可见"
                                          : "",
                                      type: 1,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "okr-right" }, [
                        okr.alignState
                          ? _c(
                              "div",
                              {
                                staticClass: "align-state",
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteAlignClick(okr, "O")
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "hover-hidden" }, [
                                  _vm._v("已对齐"),
                                ]),
                                _c("div", { staticClass: "hover-block" }, [
                                  _vm._v("取消对齐"),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "progress-rate" },
                          [
                            _c("mini-process", {
                              attrs: {
                                type: "circle",
                                automationState: okr.automationState,
                                rate: okr.progressRete,
                              },
                            }),
                            _vm._v(" " + _vm._s(okr.progressRete) + "% "),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "key-list-wrap" },
                      _vm._l(okr.keyresultsList, function (keyItem, keyIndex) {
                        return _c(
                          "div",
                          { key: keyItem.id, staticClass: "key-list" },
                          [
                            _c("div", { staticClass: "key-left" }, [
                              _c(
                                "div",
                                { staticClass: "key-num" },
                                [
                                  _vm._v(" KR" + _vm._s(keyIndex + 1) + " "),
                                  _c("privateOkr", {
                                    attrs: {
                                      createUser:
                                        _vm.userInfo.id ===
                                        keyItem.createUserid,
                                      visible: keyItem.isVisible === 1,
                                      content:
                                        keyItem.isVisible === 1
                                          ? "对方已设置为不可见"
                                          : "",
                                      type: 3,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "key-content" },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content: _vm.$utils.tagFilter(
                                          _vm.$utils.changeToHtml(keyItem.title)
                                        ),
                                        "open-delay": 300,
                                        manual: "",
                                      },
                                      model: {
                                        value: keyItem.tooltipDisabled,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            keyItem,
                                            "tooltipDisabled",
                                            $$v
                                          )
                                        },
                                        expression: "keyItem.tooltipDisabled",
                                      },
                                    },
                                    [
                                      _c("div", {
                                        staticClass: "content",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$utils.changeToHtml(
                                              keyItem.title
                                            )
                                          ),
                                        },
                                        on: {
                                          mouseenter: function ($event) {
                                            return _vm.tooltipShow(
                                              "KR",
                                              okrIndex,
                                              keyIndex,
                                              true
                                            )
                                          },
                                          mouseleave: function ($event) {
                                            return _vm.tooltipShow(
                                              "KR",
                                              okrIndex,
                                              keyIndex,
                                              false
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _c("privateOkr", {
                                    attrs: {
                                      createUser:
                                        _vm.userInfo.id ===
                                        keyItem.createUserid,
                                      visible: keyItem.isVisible === 1,
                                      content:
                                        keyItem.isVisible === 1
                                          ? "对方已设置为不可见"
                                          : "",
                                      type: 2,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "key-right" }, [
                              keyItem.alignState
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "align-state",
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteAlignClick(
                                            keyItem,
                                            "KR"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "hover-hidden" },
                                        [_vm._v("已对齐")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "hover-block" },
                                        [_vm._v("取消对齐")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "progress-rate" },
                                [
                                  _c("mini-process", {
                                    attrs: {
                                      type: "circle",
                                      automationState: keyItem.automationState,
                                      rate: keyItem.progressRete,
                                    },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        keyItem.quantificationActualValue
                                      ) +
                                      _vm._s(keyItem.quantificationUnit) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
        !_vm.isAlign && _vm.userInfo.name
          ? _c("div", { staticClass: "align-null" }, [_vm._v("还未对齐")])
          : _vm._e(),
      ]),
      _vm._t("default", null, { slot: "reference" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }