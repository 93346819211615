// 基础模块方法库
import apis from '../apis_moudles/index'
import req from '../request'

// 示例：获取用户信息
const GETUSERINFO = (params) => {
  return req.get(apis.getUserInfo, {
    params,
    headers: {
      'Web-User-Agent': 'OKRt/1.0.0 (iPhone; IOS 13.6.1)'
    }
  })
}
// 获取验证码
const GETCODE = (params) => {
  return req.post(apis.sendSms, params, {
    headers: {
      'Web-User-Agent': 'OKRt/1.0.0 (iPhone; IOS 13.6.1)'
    }
  })
}
// 验证码登录
const CODELOGIN = (params) => {
  return req.post(apis.codeLogin, params, {
    headers: {
      'Web-User-Agent': 'OKRt/1.0.0 (iPhone; IOS 13.6.1)'
    }
  })
}
// 密码登录
const PWDLOGIN = (params) => {
  return req.post(apis.pwdLogin, params, {
    headers: {
      'Web-User-Agent': 'OKRt/1.0.0 (iPhone; IOS 13.6.1)'
    }
  })
}
// 注册
const REGISTER = (params) => {
  return req.post(apis.register, params, {
    headers: {
      'Web-User-Agent': 'OKRt/1.0.0 (iPhone; IOS 13.6.1)'
    }
  })
}
// 重置密码发送账号和验证码
const RESETPWDCODE = (params) => {
  return req.post(apis.resetPwdCode, params, {
    headers: {
      'Web-User-Agent': 'OKRt/1.0.0 (iPhone; IOS 13.6.1)'
    }
  })
}
// 重置密码提交
const RESETPWDSUBMIT = (params) => {
  return req.post(apis.resetPwdSubmit, params, {
    headers: {
      'Web-User-Agent': 'OKRt/1.0.0 (iPhone; IOS 13.6.1)'
    }
  })
}
// 版本升级请求
const RELEASECONSULT = (params) => {
  return req.post(apis.releaseConsult, params, {
    headers: {
      'Web-User-Agent': 'OKRt/1.0.0 (iPhone; IOS 13.6.1)'
    }
  })
}
// 获取版本人员数量
const QUERYMEMBERLIMIT = (params) => {
  return req.get(apis.queryMemberLimit, {
    params,
    headers: {
      'Web-User-Agent': 'OKRt/1.0.0 (iPhone; IOS 13.6.1)'
    }
  })
}
// 获取登录方式
const LOGINTYPE = (params) => {
  return req.get(apis.loginType, params, {
    headers: {
      'Web-User-Agent': 'OKRt/1.0.0 (iPhone; IOS 13.6.1)'
    }
  })
}
// 查询最后操作的可用企业信息及待审核的企业信息
const SCREENLIST = (params) => {
  return req.get(apis.screenlist, params, {
    headers: {
      'Web-User-Agent': 'OKRt/1.0.0 (iPhone; IOS 13.6.1)'
    }
  })
}
// 查询指定企业信息
const COMPANYINFO = (companyId, params) => {
  return req.get(`${apis.companyInfo}${companyId}`, {
    params,
    headers: {
      'Web-User-Agent': 'OKRt/1.0.0 (iPhone; IOS 13.6.1)'
    }
  })
}
// 查看引导流程
const USERROLEPROGRESS = (params) => {
  return req.get(apis.userRoleProgress, params, {
    headers: {
      'Web-User-Agent': 'OKRt/1.0.0 (iPhone; IOS 13.6.1)'
    }
  })
}
// 创建企业
const ADDCOMPANY = (params) => {
  return req.post(apis.addCompany, params, {
    headers: {
      'Web-User-Agent': 'OKRt/1.0.0 (iPhone; IOS 13.6.1)'
    }
  })
}
// 邀请页获取邀请信息（有token）
const INVITATIONINFOTOKEN = (invitationId, params) => {
  return req.get(`${apis.invitationInfoToken}${invitationId}`, {
    params,
    headers: {
      'Web-User-Agent': 'OKRt/1.0.0 (iPhone; IOS 13.6.1)'
    }
  })
}
// 邀请页获取邀请信息（无token）
const INVITATIONINFONOTOKEN = (invitationId, params) => {
  return req.get(`${apis.invitationInfoNoToken}${invitationId}`, {
    params,
    headers: {
      'Web-User-Agent': 'OKRt/1.0.0 (iPhone; IOS 13.6.1)'
    }
  })
}
// 邀请直接加入团队
const APPLYADDCOMPANY = (params) => {
  return req.post(apis.applyAddCompany, params, {
    headers: {
      'Web-User-Agent': 'OKRt/1.0.0 (iPhone; IOS 13.6.1)'
    }
  })
}
// 邀请登录
const INVITATIONLOGIN = (params) => {
  return req.post(apis.invitationLogin, params, {
    headers: {
      'Web-User-Agent': 'OKRt/1.0.0 (iPhone; IOS 13.6.1)'
    }
  })
}
// 新建周期
const ADDCYCLE = (params) => {
  return req.post(apis.addCycle, params, {
    headers: {
      'Web-User-Agent': 'OKRt/1.0.0 (iPhone; IOS 13.6.1)'
    }
  })
}
// 企业微信code
const LOGINWXCODE = (params) => {
  return req.get(apis.loginWxCode, {
    params
  })
}
// 获取js应用签名
const GETTICKETAGENT = (params) => {
  return req.get(apis.getTicketAgent, {
    params
  })
}
// 获取微信通讯录
const GETWXUSERLIST = (params) => {
  return req.get(apis.getWxUserList, {
    params
  })
}
const LOGINWXSCANCODE = (params) => {
  return req.get(apis.loginWxScanCode, {
    params
  })
}

// 新注册
const NEWREGISTER = (params) => {
  return req.post(apis.newRegister, params, {
    headers: {
      'Web-User-Agent': 'OKRt/1.0.0 (iPhone; IOS 13.6.1)'
    }
  })
}
const SENDIMGSMS = (params) => {
  return req.get(apis.sendImgSms, {
    params,
    responseType: 'blob',
    headers: {
      'Web-User-Agent': 'OKRt/1.0.0 (iPhone; IOS 13.6.1)'
    }
  })
}
export default [
  {
  NEWREGISTER
},
{
  LOGINWXSCANCODE
},
  {
  GETWXUSERLIST
},
{
  GETTICKETAGENT
},
{
  LOGINWXCODE
},
{
  SENDIMGSMS
},
{
  GETUSERINFO
},
{
  GETCODE
},
{
  CODELOGIN
},
{
  PWDLOGIN
},
{
  REGISTER
},
{
  RESETPWDCODE
},
{
  RESETPWDSUBMIT
},
{
  RELEASECONSULT
},
{
  QUERYMEMBERLIMIT
},
{
  LOGINTYPE
},
{
  SCREENLIST
},
{
  COMPANYINFO
},
{
  USERROLEPROGRESS
},
{
  ADDCOMPANY
},
{
  INVITATIONLOGIN
},
{
  INVITATIONINFOTOKEN
},
{
  INVITATIONINFONOTOKEN
},
{
  APPLYADDCOMPANY
},
{
  ADDCYCLE
}
]
