<template>
  <div class="okr-type">
    <div
      :class="`okr-type-list`"
      v-for="item in labelObj"
      :key="item.value"
      @click="selectActiveLabel(item)"
    >
      <div :class="`icon-wrap`" :style="{ '--color': item.color }">
        <Icon icon-class="icon-draw_checked" :class="`icon icon-${labelTypeVal === item.value}`" />
      </div>
      <div
        :class="`name name-${labelTypeVal === item.value}`"
        :style="{ '--color': item.color }"
      >{{item.label}}</div>
    </div>
  </div>
</template>
<script>
export default {
  model: {
    prop: 'labelType',
    event: 'change'
  },
  props: {
    labelType: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      labelObj: [
        {
          value: 2,
          label: '公司',
          img: require('@/assets/imgs/okr/objAddCompany.png'),
          color: '#3A70FF'
        },
        {
          value: 1,
          label: '部门',
          img: require('@/assets/imgs/okr/objAddDepart.png'),
          color: '#FF9900'
        },
        {
          value: 3,
          label: '团队',
          img: require('@/assets/imgs/okr/objAddTeam.png'),
          color: '#ED2E7E'
        },
        {
          value: 0,
          label: '个人',
          img: require('@/assets/imgs/okr/objAddPerson.png'),
          color: '#00BA88'
        }
      ],
      activeLabel: {
        value: 0,
        label: '个人',
        img: require('@/assets/imgs/okr/objAddPerson.png')
      },
      labelTypeVal: this.labelType
    }
  },
  methods: {
    selectActiveLabel(val) {
      this.activeLabel = val
      this.labelTypeVal = val.value
      this.$emit('change', val.value)
    }
  }
}
</script>
<style lang="less" scoped>
.okr-type {
  .flex-l();
  .okr-type-list {
    .flex-l();
    cursor: pointer;
    .icon-wrap {
      margin: 0px 6px 0px 16px;
      background: var(--color);
      border-radius: 100%;
      padding: 4px;
      .icon {
        font-size: 0px;
        color: #fff;
        transition: 0.3s linear;
      }
      .icon-true {
        font-size: 12px;
      }
    }
    .name-true {
      color: var(--color);
    }
  }
}
.label-content {
  display: flex;
  align-items: center;
  color: #14142b;
  height: 36px;
  .label-icon {
    width: 24px;
    margin-right: 6px;
  }
  i {
    font-size: 10px;
    color: #a0a3bd;
    margin-left: 14px;
  }
}
.label-box {
  width: 120px;
  height: 34px;
  border: 1px solid #f5f6fa;
  border-radius: 8px;
  padding: 0 16px;
}
</style>
