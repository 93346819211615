var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class:
        _vm.type === "circle" ? "bar-wrap circle-bar" : "bar-wrap mini-out-bar",
    },
    [
      _vm.automationState === 1
        ? _c("img", {
            staticClass: "icon",
            attrs: {
              src: require("../../../../assets/imgs/common/circle-progress.gif"),
              alt: "",
            },
          })
        : _vm._e(),
      _vm.type === "circle"
        ? _c("el-progress", {
            attrs: {
              type: "circle",
              percentage: _vm.rate <= 0 ? 0 : _vm.rate,
              width: _vm.width,
              "stroke-width": _vm.strokeWidth,
              color: _vm.color,
              "show-text": false,
            },
          })
        : _c("div", {
            staticClass: "mini-inner-bar",
            style: `width:${_vm.rate}%`,
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }