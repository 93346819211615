var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "task-text" },
    [
      !_vm.showContent
        ? _c("div", { staticClass: "show-btn", on: { click: _vm.clickShow } }, [
            _vm._v("未填写"),
          ])
        : _vm._e(),
      _vm.showContent
        ? _c("el-input", {
            ref: `input${_vm.fieldInfo.id}`,
            attrs: {
              autosize: { minRows: 1, maxRows: 4 },
              type: "textarea",
              maxlength: 150,
              disabled: _vm.disabled,
            },
            on: { input: _vm.inputChange, blur: _vm.blurInput },
            model: {
              value: _vm.inputVal,
              callback: function ($$v) {
                _vm.inputVal = $$v
              },
              expression: "inputVal",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }