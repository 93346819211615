<template>
  <el-dialog
    :title="`是否提交 任务 审批？`"
    :visible.sync="dialogVisible"
    width="640px"
    @close="handleClose"
  >
    <div class="main-content">
      <div class="list-wrap">
        <div
          class="title"
        >{{businessActionTypeName[$store.state.task.taskApproveRemindObj.businessActionType || 1]}}需要提交审批，审批通过后将自动生效</div>
        <div
          class="user"
          v-if="$store.state.task.taskApproveRemindObj.approvalCheckData && $store.state.task.taskApproveRemindObj.approvalCheckData.appointUserName"
        >
          审批人：
          <wx-open-data
            :id="$store.state.task.taskApproveRemindObj.approvalCheckData.appointUserName"
            :nameData="{name: $store.state.task.taskApproveRemindObj.approvalCheckData.appointUserName}"
          />
        </div>
      </div>
      <div
        :class="`bottom-btn-wrap btn-type-${($store.state.task.taskApproveRemindObj.businessActionType || 1)}`"
      >
        <!-- <div
          class="checkbox-wrap"
          v-if="($store.state.task.taskApproveRemindObj.businessActionType || 2) !== 3 && ($store.state.task.taskApproveRemindObj.businessActionType || 2) !== 5 && ($store.state.task.taskApproveRemindObj.businessActionType || 2) !== 6"
        >
          <el-checkbox v-model="remindState">下次不再提示</el-checkbox>
        </div>-->
        <div class="bottom-step-buttons">
          <button class="left-button" @click="handleClose(false)">取消</button>
          <L-button :loading="saveLoading" @clickEvt="save(1)">提交审批</L-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  props: {
    dialogShow: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dialogVisible: this.dialogShow,
      remindState: false,
      businessActionTypeName: {
        1: '完成任务',
        2: '变更工作流',
        3: '删除任务'
      }
    }
  },
  mounted() {
  },
  created() {},
  methods: {
    // 关闭内容
    handleClose(type) {
      if (type === false && this.$store.state.task.taskApproveRemindObj.cancelRefresh === true) {
        var params = {
          businessId: this.$store.state.task.taskApproveRemindObj.taskDetail.projectId,
          businessExtId: this.$store.state.task.taskApproveRemindObj.taskDetail.id,
          businessData: this.$store.state.task.taskApproveRemindObj.businessData || '',
          businessActionType: this.$store.state.task.taskApproveRemindObj.businessActionType || 1,
          submitterType: 1,
          appointRemark: '',
          cancel: true
        }
        this.bus(this).$emit('taskApproveRemindRefresh', params)
      }
      this.dialogShow = false
      this.$emit('change', false)
    },
    // 审批提交撤回
    save(submitterType) {
      var params = {
        businessId: this.$store.state.task.taskApproveRemindObj.taskDetail.projectId,
        businessExtId: this.$store.state.task.taskApproveRemindObj.taskDetail.id,
        businessData: this.$store.state.task.taskApproveRemindObj.businessData || '',
        businessActionType: this.$store.state.task.taskApproveRemindObj.businessActionType || 1,
        submitterType,
        appointRemark: ''
        // approvalProcessId: this.$store.state.task.taskApproveRemindObj.objDetail.objectivesProcessDataDto && this.$store.state.task.taskApproveRemindObj.objDetail.objectivesProcessDataDto.approvalProcessId || '',
        // approvalProcessNumber: this.$store.state.task.taskApproveRemindObj.objDetail.objectivesProcessDataDto && this.$store.state.task.taskApproveRemindObj.objDetail.objectivesProcessDataDto.approvalProcessNumber || ''
      }
      this.$apis
        .PMPROCESSDATASUBMITT(params)
        .then((rest) => {
          if (rest.status === 200) {
            this.$message.success('成功')
            this.bus(this).$emit('taskApproveRemindRefresh', params)
            this.handleClose()
          } else {
            this.$message.error(rest.message)
          }
        })
    }
  }
}
</script>
<style scoped lang="less">
@import '../../../../../../assets/styles/dialog.less';
.main-content {
  padding: 24px 0px 0px 0px;
  margin: 0px auto;
  text-align: center;
  .list-wrap {
    margin: 0px 0px 34px 0px;
    text-align: left;
    .title {
      font-size: 16px;
      font-weight: 500;
      color: #444558;
      line-height: 24px;
    }
    .user {
      margin-top: 8px;
      font-size: 14px;
      font-weight: 400;
      color: #444558;
      line-height: 24px;
    }
  }
  .bottom-btn-wrap {
    .flex-bt();
    justify-content: flex-end;
  }
  .bottom-btn-wrap.btn-type-3,
  .bottom-btn-wrap.btn-type-5,
  .bottom-btn-wrap.btn-type-6 {
    justify-content: flex-end;
  }
}
::v-deep {
  .el-dialog__header {
    display: block !important;
    padding: 24px 32px 0 32px;
  }
  .el-dialog__headerbtn {
    top: 22px;
  }
}
</style>
