var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
    _c(
      "div",
      [
        _c(
          "el-dialog",
          {
            staticClass: "comm-check-form",
            attrs: {
              title: "周期设置",
              visible: _vm.dialogVisible,
              width: "680px",
              "append-to-body": true,
              "before-close": _vm.handleClose,
            },
            on: {
              "update:visible": function ($event) {
                _vm.dialogVisible = $event
              },
            },
          },
          [
            _c("div", { staticClass: "dialog-content" }, [
              _c("div", { staticClass: "form-item form-item-cycle" }, [
                _c(
                  "div",
                  { staticClass: "form-title", staticStyle: { margin: "0" } },
                  [_vm._v("周期名称:")]
                ),
                _c(
                  "div",
                  { staticClass: "cycle-name-input" },
                  [
                    _c("el-input", {
                      staticClass: "inp",
                      attrs: {
                        "auto-complete": "off",
                        placeholder: "请输入周期名称",
                      },
                      on: { blur: _vm.cycleNameBlue },
                      model: {
                        value: _vm.cycleName,
                        callback: function ($$v) {
                          _vm.cycleName = $$v
                        },
                        expression: "cycleName",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "form-item" }, [
                _c("div", { staticClass: "form-title" }, [
                  _vm._v("可见范围设置"),
                ]),
                _c("div", { staticClass: "set-radios" }, [
                  _c(
                    "div",
                    {
                      staticClass: "radio-item",
                      on: {
                        click: function ($event) {
                          return _vm.changeRangeType(0)
                        },
                      },
                    },
                    [
                      _c("div", {
                        staticClass: "radio-type-icon",
                        class: _vm.form.dataRange === 0 ? "select-radio" : "",
                      }),
                      _vm._v("全部成员可见 "),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "radio-item",
                      on: {
                        click: function ($event) {
                          return _vm.changeRangeType(1)
                        },
                      },
                    },
                    [
                      _c("div", {
                        staticClass: "radio-type-icon",
                        class: _vm.form.dataRange === 1 ? "select-radio" : "",
                      }),
                      _vm._v("自定义范围 "),
                    ]
                  ),
                ]),
                _vm.form.dataRange === 1
                  ? _c("div", [
                      _c(
                        "div",
                        { staticClass: "range-btns" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "add-btn",
                              on: { click: _vm.showUserDialog },
                            },
                            [_vm._v("+ 按组织架构添加")]
                          ),
                          _c(
                            "el-popover",
                            {
                              ref: "roleCyclePop",
                              attrs: {
                                "popper-class": "cycle-setting-rolepop",
                              },
                              on: { show: _vm.queryRoleList },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "role-list" },
                                _vm._l(_vm.roleLists, function (role) {
                                  return _c(
                                    "div",
                                    {
                                      key: role.id,
                                      staticClass: "role-item",
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectRole(role)
                                        },
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "role-div" }, [
                                        _vm._v(_vm._s(role.name.slice(0, 1))),
                                      ]),
                                      _vm._v(
                                        " " +
                                          _vm._s(role.name) +
                                          "（" +
                                          _vm._s(role.useNum || 0) +
                                          "人） "
                                      ),
                                      role.checked
                                        ? _c("Icon", {
                                            staticClass: "check-icon",
                                            attrs: {
                                              "icon-class": "icon-draw_checked",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "confirm-btn",
                                  on: { click: _vm.confirmAddRoleRange },
                                },
                                [_vm._v("确认")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "add-btn",
                                  attrs: { slot: "reference" },
                                  slot: "reference",
                                },
                                [_vm._v("+ 按角色添加")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "user-content" },
                        [
                          _c("div", { staticClass: "user-info" }, [
                            _vm._v(
                              " 企业内 " +
                                _vm._s(_vm.rangeInfo.departNum) +
                                "部门， " +
                                _vm._s(_vm.rangeInfo.userNum) +
                                "成员 "
                            ),
                          ]),
                          _vm._l(_vm.rangeUsers, function (item, index) {
                            return _c(
                              "div",
                              { key: item.id, staticClass: "user-item" },
                              [
                                item.type === 0
                                  ? [
                                      (item.avatar || "") !== ""
                                        ? _c("img", {
                                            staticClass:
                                              "user-img border-radius-20",
                                            attrs: { src: item.avatar },
                                          })
                                        : _c("img", {
                                            staticClass:
                                              "user-img border-radius-20",
                                            attrs: {
                                              src: require("@/assets/imgs/common/avatar.png"),
                                            },
                                          }),
                                    ]
                                  : _c(
                                      "div",
                                      {
                                        staticClass: "user-img depart-img",
                                        class:
                                          item.type === 2 ? "role-img" : "",
                                      },
                                      [
                                        _c("wx-open-data", {
                                          attrs: {
                                            type: 2,
                                            id: item.name,
                                            nameData: { name: item.name },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                _c(
                                  "div",
                                  { staticClass: "user-name" },
                                  [
                                    item.type !== 2
                                      ? _c("wx-open-data", {
                                          attrs: {
                                            type: item.type === 0 ? 1 : 2,
                                            id: item.userName || item.name,
                                            nameData: {
                                              name: item.userName || item.name,
                                            },
                                          },
                                        })
                                      : _c("span", [_vm._v(_vm._s(item.name))]),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "permission" },
                                  [
                                    item.owner === 0
                                      ? _c("permission-select", {
                                          on: {
                                            change: function ($event) {
                                              return _vm.updateCyclePermission(
                                                $event,
                                                item
                                              )
                                            },
                                            delItem: function ($event) {
                                              return _vm.delItem(index)
                                            },
                                          },
                                          model: {
                                            value: item.businessPower,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "businessPower",
                                                $$v
                                              )
                                            },
                                            expression: "item.businessPower",
                                          },
                                        })
                                      : _c(
                                          "div",
                                          { staticClass: "owner-text" },
                                          [_vm._v("所有者")]
                                        ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            )
                          }),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "div",
                { staticClass: "form-item stage-set" },
                [
                  _c("div", { staticClass: "form-title" }, [
                    _vm._v(" 周期阶段设置 "),
                    _c(
                      "span",
                      {
                        staticClass: "edit-btn edit-btn-true",
                        on: { click: _vm.editCycleStage },
                      },
                      [_vm._v("编辑")]
                    ),
                  ]),
                  _vm._l(_vm.stageSetInfo, function (item) {
                    return _c(
                      "div",
                      { key: item.name, staticClass: "stage-item" },
                      [
                        _c("div", { staticClass: "stage-name" }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                        _c("div", { staticClass: "stage-item-name" }, [
                          _c("span", { staticClass: "satge-text" }, [
                            _vm._v("填写阶段"),
                          ]),
                          _vm._v(
                            " " + _vm._s(item.writeStage.remindText) + " "
                          ),
                        ]),
                        _c("div", { staticClass: "stage-item-name" }, [
                          _c("span", { staticClass: "satge-text" }, [
                            _vm._v("推进阶段"),
                          ]),
                          _vm._v(" " + _vm._s(item.pushStage.remindText) + " "),
                        ]),
                        _c("div", { staticClass: "stage-item-name" }, [
                          _c("span", { staticClass: "satge-text" }, [
                            _vm._v("复盘阶段"),
                          ]),
                          _vm._v(
                            " " + _vm._s(item.reviewStage.remindText) + " "
                          ),
                        ]),
                      ]
                    )
                  }),
                ],
                2
              ),
              _c("div", { staticClass: "form-item" }, [
                _c("div", { staticClass: "form-title" }, [_vm._v("评分设置")]),
                _c("div", { staticClass: "score-config" }, [
                  _c(
                    "div",
                    { staticClass: "config-switch" },
                    [
                      _c("span", [_vm._v("评分")]),
                      _c("el-switch", {
                        attrs: { "active-color": "#3a78f6" },
                        on: { change: _vm.cycleScoreSet },
                        model: {
                          value: _vm.scoreConfig,
                          callback: function ($$v) {
                            _vm.scoreConfig = $$v
                          },
                          expression: "scoreConfig",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "config-info" }, [
                    _vm._v("可在OKR复盘阶段给OKR打分"),
                  ]),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "form-item remind-form" },
                [
                  _c("div", { staticClass: "form-title" }, [
                    _vm._v("提醒设置"),
                  ]),
                  _vm._l(_vm.reminArr, function (item) {
                    return _c(
                      "div",
                      { key: item.name, staticClass: "remind-item" },
                      [
                        _c(
                          "div",
                          { staticClass: "remind-title" },
                          [
                            _c("div", { staticClass: "left" }, [
                              _vm._v(" " + _vm._s(item.name) + " "),
                              _c(
                                "span",
                                {
                                  class: `edit-btn edit-btn-${
                                    item.notifyState === true
                                  }`,
                                  on: {
                                    click: function ($event) {
                                      return _vm.openRemind(item)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                            ]),
                            _c("el-switch", {
                              attrs: { "active-color": "#3a78f6" },
                              on: {
                                change: function ($event) {
                                  return _vm.remindswitchSet(item)
                                },
                              },
                              model: {
                                value: item.notifyState,
                                callback: function ($$v) {
                                  _vm.$set(item, "notifyState", $$v)
                                },
                                expression: "item.notifyState",
                              },
                            }),
                          ],
                          1
                        ),
                        item.notifyState === true
                          ? _c("div", { staticClass: "remind-time" }, [
                              _vm._v(
                                "提醒时间：" +
                                  _vm._s(item.remindText || "暂未设置提醒")
                              ),
                            ])
                          : _vm._e(),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]),
            _vm.userMemberVisible
              ? _c("cycle-member", {
                  attrs: {
                    "selected-person": _vm.editUserList,
                    "owner-user-id": _vm.ownerUserId,
                  },
                  on: { saveResult: _vm.saveUser },
                  model: {
                    value: _vm.userMemberVisible,
                    callback: function ($$v) {
                      _vm.userMemberVisible = $$v
                    },
                    expression: "userMemberVisible",
                  },
                })
              : _vm._e(),
            _vm.addRemindVisible
              ? _c("add-remind", {
                  attrs: { "remind-data": _vm.reminArr[0].remindObj },
                  on: { remindSet: _vm.setCycleRemind },
                  model: {
                    value: _vm.addRemindVisible,
                    callback: function ($$v) {
                      _vm.addRemindVisible = $$v
                    },
                    expression: "addRemindVisible",
                  },
                })
              : _vm._e(),
            _vm.updateRemindVisible
              ? _c("update-remind", {
                  attrs: { "remind-data": _vm.reminArr[1].remindObj },
                  on: { remindSet: _vm.setCycleRemind },
                  model: {
                    value: _vm.updateRemindVisible,
                    callback: function ($$v) {
                      _vm.updateRemindVisible = $$v
                    },
                    expression: "updateRemindVisible",
                  },
                })
              : _vm._e(),
            _vm.feedbackVisible
              ? _c("feedback-remind", {
                  attrs: { "remind-data": _vm.reminArr[2].remindObj },
                  on: { remindSet: _vm.setCycleRemind },
                  model: {
                    value: _vm.feedbackVisible,
                    callback: function ($$v) {
                      _vm.feedbackVisible = $$v
                    },
                    expression: "feedbackVisible",
                  },
                })
              : _vm._e(),
            _vm.stageVisible
              ? _c("cycleStage", {
                  attrs: {
                    "stage-set-info": _vm.stageSetInfo,
                    "cycle-info": _vm.cycleInfo,
                  },
                  on: { stageSet: _vm.stageInfoSet },
                  model: {
                    value: _vm.stageVisible,
                    callback: function ($$v) {
                      _vm.stageVisible = $$v
                    },
                    expression: "stageVisible",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }