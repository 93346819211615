<template>
  <div class="editor-box" :class="detailStyle ? 'detail-editor' : ''">
    <tinymce-editor
      id="Editor"
      ref="editor"
      v-model="information"
      api-key="6zmiw20kgeupc46et5f2r7zblaf93pqaj3s98qucufgqe63y"
      :init="init"
      :disabled="disabled"
      @onChange="onChange"
      @input="onInput"
      @onFocus="onFocus"
      @onBlur="onBlur"
    />

    <form id="imageForm">
      <input
        id="uploadImage"
        class="file-upload-input"
        type="file"
        accept="image/gif, image/jpeg, image/png, image/jeg, image/svg, image/bmp"
        name="picture"
      />
    </form>
    <form id="fileForm">
      <input
        id="uploadFile"
        class="file-upload-input"
        type="file"
        name="file"
        accept=".xls, .doc, .txt, .pdf, .docx, .xlsx, .ppt, .xls, .wps, .pps, .pptx, .dll"
      />
    </form>
    <el-dialog title="选择粘贴的形式" :modal="false" :visible.sync="dialogVisible" width="460px">
      <div class="excel-content">
        <img :src="excelImg" alt="excel" />
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button class="change-img" @click="changeImg">转为图片</el-button>
        <el-button class="change-img" type="primary" @click="retainString">保留文字</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
/* eslint-disable */
// import tinymce from 'tinymce/tinymce'

import tinymceEditor from '@tinymce/tinymce-vue' // tinymce-vue富文本编辑器
// 引入富文本编辑器主题的js和css
// import 'tinymce/themes/silver/theme.min.js'
// import 'tinymce/skins/ui/oxide/skin.min.css'
// // 引入插件
// import 'tinymce/icons/default/icons'

// import 'tinymce/plugins/image'
// import 'tinymce/plugins/table'
// import 'tinymce/plugins/lists'
// import 'tinymce/plugins/advlist'
// import 'tinymce/plugins/link'
// import 'tinymce/plugins/wordcount'
// import 'tinymce/plugins/fullscreen'
// import 'tinymce/plugins/autoresize'
// // import 'tinymce/plugins/upfile'
// import '../../../../public/tinymce/plugins/attachment'
// import '../../../../public/tinymce/plugins/paste'
export default {
  components: {
    tinymceEditor
  },
  model: {
    prop: 'inputValue',
    event: 'change'
  },
  props: {
    inputValue: {
      type: String,
      default: ''
    },
    width: {
      type: [String, Number],
      default: 572
    },
    height: {
      type: [String, Number],
      default: 250
    },
    // 限制长度
    maxLength: {
      type: [String, Number],
      default: 500
    },
    // 文件上传地址
    uploadUrl: {
      type: String
    },
    // 不需要层级在顶部（用于多层弹窗）
    noIndex: {
      type: Boolean
    },
    // 详情样式
    detailStyle: {
      type: Boolean,
      default: true
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    initData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    limit: {
      type: Number,
      default: 5000
    }
  },
  data() {
    return {
      information: this.inputValue,
      init: {},
      linkForm: {},
      dialogVisible: false,
      excelImg: '',
      excelString: '',
      isShowInsertLink: false //是否是添加链接
    }
  },
  watch: {
    inputValue: {
      handler(val) {
        this.information = val
      }
    }
  },
  created() {
    this.information = this.inputValue == null ? '' : this.inputValue
    this.htmlText = this.inputValue
    const upthat = this
    this.init = Object.assign(
      {
        content_style: 'img {max-width:90%;height:auto;}',
        selector: this.$refs.editor,
        inline: false,
        language_url: '/tinymce/langs/zh_CN.js', // 使用中文包
        language: 'zh_CN', // 语言设置为中文
        skin_url: '/tinymce/skins/ui/oxide', // 皮肤包
        // 该配置添加在Init中
        extended_valid_elements:
          'a[class|target=_blank|href|onclick],div[class|onclick|id|style],link[rel|href|target=_blank]',

        height: upthat.height, // 高度
        width: upthat.width,
        branding: false,
        readonly: !upthat.disabled,
        default_link_target: '_blank', // 超链接默认打开方式 advlist
        plugins:
          'link autolink lists advlist charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount emoticons image imagetools attachment',
        // toolbar:
        //   'insertImage image insertLink attachment  bold italic underline strikethrough bullist numlist ',
        toolbar:
          'insertImage insertLink  insertFile  bold italic underline strikethrough bullist numlist ',
        menubar: false,
        paste_data_images: true,
        attachment_max_size: 52428800, // 最大50M
        images_file_types:
          'jpeg,jpg,png,gif,bmp,webp,svg,JPEG,JPG,PNG,GIF,BMP,SVG,WEBP',
        images_upload_handler: (blobInfo, success, failure) => {
          const formData = new FormData()
          //判断复制的是图片还是excel格式的图片
          if (blobInfo.blob().name) {
            formData.append('file', blobInfo.blob())
          } else {
            formData.append(
              'file',
              this.dataURLtoFile(this.excelImg, 'excel.png')
            )
          }

          if (blobInfo.blob().size / 1024 / 1024 > 50) {
            failure('上传失败，图片大小请控制在 50M 以内')
            return
          }
          this.$apis.FILEUPLOAD(formData).then((res) => {
            if (res.status === 200) {
              success(res.data.downloadUrl)
            } else {
              failure('图片上传失败,联系开发人员')
            }
          })
        },
        file_picker_types: 'file media',
        file_picke_callback: function (file, succFun) {
          upthat.upload(file, succFun)
        },
        attachment_upload_handler(file, succFun, failure, progressCallback) {
          upthat.upload(file, succFun, failure, progressCallback)
        },
        charLimit: 10,
        setup: function (editor) {
          // 注册icon
          editor.ui.registry.addIcon(
            'insertLink',
            `<i style="font-size:26px;color:#999;" class="iconfont icon-lianjie"></i>`
          )
          // 注册icon
          editor.ui.registry.addIcon(
            'imgupload',
            `<i style="font-size:28px;color:#999;" class="iconfont icon-tupian"></i>`
          )
          // 注册icon
          editor.ui.registry.addIcon(
            'fileupload',
            `<i style="font-size:28px;color:#999;" class="iconfont icon-wenjian4"></i>`
          )

          // 监听点击
          editor.on('click', (e) => {
            if (e.target.nodeName === 'SPAN') {
              if (e.target.childNodes && e.target.childNodes.length > 0) {
                e.target.childNodes.forEach((item) => {
                  if (item.href) {
                    const fileUrl = item.href
                    upthat.lookEditorImg(fileUrl, 'file')
                  }
                })
              }
            }
            if (
              e.target.nodeName === 'A' &&
              e.target.className == 'insertfile'
            ) {
              const fileUrl = e.target.href
              upthat.lookEditorImg(fileUrl, 'file')
            } else if (e.target.nodeName === 'IMG') {
              const imgURl = e.target.currentSrc
              upthat.lookEditorImg(imgURl, 'img')
            } else if (
              (e.target.nodeName === 'A' &&
                e.target.className == 'insertlink') ||
              (e.target.nodeName === 'A' &&
                e.target.className != 'insertlink' &&
                e.target.className != 'insertfile')
            ) {
              if (e.target.href) {
                window.open(e.target.href, '_blank')
              }
            } else {
              if (e.target.href) {
                window.open(e.target.href, '_blank')
              }
            }
          })
          //粘贴事件
          editor.on('paste', function (e) {
            //
            let isCopyImg = false //判断复制的是图片
            for (let i = 0, len = e.clipboardData.items.length; i < len; i++) {
              let item = e.clipboardData.items[i]
              if (item.kind === 'string' && item.type === 'text/html') {
                isCopyImg = true //复制的excel
              }
            }
            for (let i = 0, len = e.clipboardData.items.length; i < len; i++) {
              let item = e.clipboardData.items[i]
              if (item.kind === 'string' && item.type === 'text/plain') {
                //处理文本
                item.getAsString(function (str) {
                  upthat.$nextTick(() => {
                    upthat.excelString = str
                    if (!upthat.dialogVisible) {
                      upthat.$nextTick(() => {
                        tinymce.activeEditor.selection.setContent(str)
                      })
                    }
                  })
                })
                // 阻止复制的默认事件
                e.preventDefault()
              } else if (item.kind === 'file') {
                if (isCopyImg) {
                  upthat.dialogVisible = true
                  //阻止复制的默认事件
                  e.preventDefault()
                }
                const blob = item.getAsFile()
                const reader = new FileReader()
                reader.readAsDataURL(blob)
                reader.onload = function (event) {
                  //获取base64流
                  const base64_str = event.target.result
                  upthat.excelImg = base64_str
                }
              }
            }
          })
          editor.on('keydown', function (e) {
            upthat.wordLimit(e, 'keydown')
          })
          editor.on('input', function (e) {
            upthat.wordLimit(e, 'input')
          })
          editor.on('compositionstart', function (e) {
            upthat.isChinese = false
          })
          editor.on('compositionend', function (e) {
            upthat.isChinese = true
          })
          // 注册自定义的按钮
          editor.ui.registry.addButton('insertLink', {
            icon: `insertLink`,
            title: 'OKR',
            onAction: function () {
              upthat.$refs.editor.editor.editorManager.get('Editor').focus()
              upthat.isShowInsertLink = true
              upthat.bus.$emit('global:InsertLink', '', 'editor')
            }
          })
          // 注册自定义的按钮
          editor.ui.registry.addButton('insertImage', {
            icon: `imgupload`,
            title: 'OKR',
            onAction: function () {
              upthat.isShowInsertLink = false
              upthat.$refs.editor.editor.editorManager.get('Editor').focus()
              let fileUploadDom = document.getElementById('uploadImage')
              document.getElementById('imageForm').reset()
              fileUploadDom.click()
              fileUploadDom.onchange = function (e) {
                let file = e.target.files[0]
                if (file.type.indexOf('image/') >= 0) {
                  upthat.uploadImg(file)
                } else {
                  upthat.uploadImg(file, 'file')
                }
                e.target.files = []
              }
            }
          })
          // 注册自定义的按钮
          editor.ui.registry.addButton('insertFile', {
            icon: `fileupload`,
            title: 'OKR',
            onAction: function () {
              upthat.isShowInsertLink = false
              upthat.$refs.editor.editor.editorManager.get('Editor').focus()
              let fileUploadDom = document.getElementById('uploadFile')
              document.getElementById('fileForm').reset()
              fileUploadDom.click()
              fileUploadDom.onchange = function (e) {
                let file = e.target.files[0]
                upthat.uploadImg(file, 'file')
              }
            }
          })
        }
      },
      this.initData
    )
  },
  mounted() {
    tinymce.init({})
    this.htmlText = this.inputValue
    this.bus(this).$on('getLinkForm', (val) => {
      if (val.insertLinkType === `editor`) {
        this.isShowInsertLink = false
        this.linkForm = val
        let linkTab = `<a class="insertlink" style="cursor: pointer;color:#3a78f6 !important;" href="${this.linkForm.link}" name="insertlink">${this.linkForm.name}</a>`
        tinymce.activeEditor.selection.setContent(linkTab)
        this.information = tinymce.activeEditor.getContent()
        this.onInput()
      }
    })
    this.bus(this).$on('closeLinkForm', (val) => {
      this.isShowInsertLink = false
    })
  },
  methods: {
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    //转为图片
    changeImg() {
      //把服务器图片地址加载到富文本img标签中
      const formData = new FormData()
      //判断复制的是图片还是excel格式的图片
      formData.append('file', this.dataURLtoFile(this.excelImg, 'excel.png'))
      this.$apis.FILEUPLOAD(formData).then((res) => {
        if (res.status === 200) {
          this.dialogVisible = false
          // this.information = `${this.information}<p><img src="${res.data.downloadUrl}"/></p>`
          tinymce.activeEditor.selection.setContent(`<p><img src="${res.data.downloadUrl}"/></p>`)
          this.information = tinymce.activeEditor.getContent()
          this.$emit('change', this.information)
        } else {
          //failure('图片上传失败,联系开发人员')
        }
      })
    },
    //保留文字
    retainString() {
      this.dialogVisible = false
      // this.information = `${this.information}<p>${this.excelString}</p>`
      tinymce.activeEditor.selection.setContent(`<p>${this.excelString}</p>`)
      this.information = tinymce.activeEditor.getContent()
      this.$emit('change', this.information)
    },
    //转义html字符
    chengeReplace(val) {
      //"项目1\t项目2\t项目3\t项目4\r\nw\tz\tv\tx\r\nw\tz\tv\tx\r\nw\tz\tv\tx\r\nw\tz\tv\tx\r\n"
      return val.replace(/\r?\n/g, '<br />')
    },
    //字数限制
    wordLimit(e, type) {
      var keyCode = e.keyCode
      const vm = this
      if (e.target.innerText.length <= this.limit) {
        vm.htmlText = e.target.innerHTML
      }
      if (
        e.target.innerText.replace(/\s/g, '').length > this.limit &&
        keyCode !== 8 &&
        keyCode !== 46 &&
        type === 'keydown'
      ) {
        e.target.innerHTML = vm.htmlText
        this.$message.warn(`输入最大长度不能超过${this.limit}个字符哦！`)
        //防止插入键入的字符
        e.preventDefault()
        e.stopPropagation()
      }
      if (
        e.target.innerText.replace(/\s/g, '').length > this.limit &&
        type === 'input'
      ) {
        //防止插入键入的字符
        e.preventDefault()
        e.stopPropagation()
      }
    },
    onChange(e) {
      this.$emit('change', this.information)
      this.$emit('onChange', e)
    },
    onInput(e) {
      this.$emit('change', this.information)
      this.$emit('onInput', e)
    },
    onFocus(e) {
      this.$emit('onFocus', e, true)
    },
    onBlur(e) {
      this.$emit('onBlur', e, this.isShowInsertLink)
    },
    upload(file, succFun, failure, progressCallback) {
      const formData = new FormData()
      formData.append('file', file, file.name)
      this.$apis.FILEUPLOAD(formData).then((res) => {
        if (res.status === 200) {
          if (res.data.previewUrl) {
            succFun(res.data.previewUrl, {
              text: file.name + ' '
            })
          } else {
            succFun(res.data.downloadUrl, {
              text: file.name + ' '
            })
          }
          // 判断icon的位置
          // const attachments = document.getElementsByClassName('attachment')
        } else {
          this.$message({
            msg: res.message,
            type: 'warn'
          })
          failure(res.message, { remove: true })
        }
      })
    },
    uploadImg(file, type) {
      const formData = new FormData()
      formData.append('file', file, file.name)
      this.$apis.FILEUPLOAD(formData).then((res) => {
        if (res.status === 200) {
          if (type == 'file') {
            var o = [
              {
                ext: 'txt',
                icon: 'file_type_text'
              },
              {
                ext: 'doc',
                icon: 'file_type_word2'
              },
              {
                ext: 'docx',
                icon: 'file_type_word2'
              },
              {
                ext: 'pdf',
                icon: 'file_type_pdf2'
              },
              {
                ext: 'xls',
                icon: 'file_type_excel2'
              },
              {
                ext: 'xlsx',
                icon: 'file_type_excel2'
              },
              {
                ext: 'ppt',
                icon: 'file_type_powerpoint2'
              },
              {
                ext: 'pptx',
                icon: 'file_type_powerpoint2'
              },
              {
                ext: 'ai',
                icon: 'file_type_ai2'
              },
              {
                ext: 'psd',
                icon: 'file_type_photoshop2'
              },
              {
                ext: 'zip',
                icon: 'file_type_zip'
              },
              {
                ext: 'rar',
                icon: 'file_type_zip'
              },
              {
                ext: 'jpg',
                icon: 'file_type_image'
              },
              {
                ext: 'jpeg',
                icon: 'file_type_image'
              },
              {
                ext: 'png',
                icon: 'file_type_image'
              },
              {
                ext: 'gif',
                icon: 'file_type_image'
              },
              {
                ext: 'js',
                icon: 'file_type_js'
              },
              {
                ext: 'xml',
                icon: 'file_type_xml'
              },
              {
                ext: 'htm',
                icon: 'file_type_html'
              },
              {
                ext: 'html',
                icon: 'file_type_html'
              },
              {
                ext: 'json',
                icon: 'file_type_light_json'
              },
              {
                ext: 'fon',
                icon: 'file_type_light_font'
              },
              {
                ext: 'font',
                icon: 'file_type_light_font'
              },
              {
                ext: 'svg',
                icon: 'file_type_image'
              },
              {
                ext: 'exe',
                icon: 'file_type_binary'
              },
              {
                ext: 'bin',
                icon: 'file_type_binary'
              },
              {
                ext: 'dll',
                icon: 'file_type_binary'
              },
              {
                ext: 'pkg',
                icon: 'file_type_package'
              },
              {
                ext: 'wav',
                icon: 'file_type_audio'
              },
              {
                ext: 'mp3',
                icon: 'file_type_audio'
              },
              {
                ext: 'mp4',
                icon: 'file_type_video'
              },
              {
                ext: 'mov',
                icon: 'file_type_video'
              },
              {
                ext: 'avi',
                icon: 'file_type_video'
              },
              {
                ext: 'java',
                icon: 'file_type_java'
              },
              {
                ext: 'cs',
                icon: 'file_type_csharp'
              },
              {
                ext: 'css',
                icon: 'file_type_css'
              },
              {
                ext: 'py',
                icon: 'file_type_python'
              },
              {
                ext: 'sln',
                icon: 'file_type_vscode3'
              },
              {
                ext: 'go',
                icon: 'file_type_go'
              },
              {
                ext: '7z',
                icon: 'file_type_zip'
              },
              {
                ext: 'sketch',
                icon: 'file_type_sketch'
              },
              {
                ext: 'patch',
                icon: 'file_type_patch'
              },
              {
                ext: 'org',
                icon: 'file_type_org'
              },
              {
                ext: 'md',
                icon: 'file_type_markdown'
              },
              {
                ext: 'jar',
                icon: 'file_type_jar'
              },
              {
                ext: 'dmg',
                icon: 'file_type_dmg'
              },
              {
                ext: 'accdb',
                icon: 'file_type_access'
              },
              {
                ext: 'apk',
                icon: 'file_type_apk'
              },
              {
                ext: 'eps',
                icon: 'file_type_eps'
              },
              {
                ext: 'ico',
                icon: 'file_type_ico'
              },
              {
                ext: 'iso',
                icon: 'file_type_iso'
              },
              {
                ext: 'key',
                icon: 'file_type_keynote'
              },
              {
                ext: 'numbers',
                icon: 'file_type_number'
              },
              {
                ext: 'reg',
                icon: 'file_type_reg'
              },
              {
                ext: 'rp',
                icon: 'file_type_rp'
              },
              {
                ext: 'rtf',
                icon: 'file_type_rtf'
              },
              {
                ext: 'sketch',
                icon: 'file_type_sketch'
              },
              {
                ext: 'swf',
                icon: 'file_type_swf'
              },
              {
                ext: 'url',
                icon: 'file_type_url'
              },
              {
                ext: 'wma',
                icon: 'file_type_wma'
              },
              {
                ext: 'xmind',
                icon: 'file_type_xmind'
              },
              {
                ext: 'cat',
                icon: 'file_type_cat'
              },
              {
                ext: 'bmp',
                icon: 'default_file'
              }
            ]
            var typeIcon = ''
            o.forEach((item) => {
              if (item.ext == res.data.mimeType) {
                typeIcon = item.icon
              }
            })
            var fileSize = this.$utils.formatFileSize(res.data.fileSize)
            console.log('324234234242343', typeIcon, res.data)
            let linkTab = `<span class="attachment" contenteditable="false">
            <img src="https://unpkg.com/@npkg/tinymce-plugins/plugins/attachment/icons/${typeIcon}.svg" width="30px">
            <a class="insertfile" style="cursor: pointer;color:#3a78f6 !important;" href="${
              res.data.previewUrl ? res.data.previewUrl : res.data.downloadUrl
            }">${res.data.name}（${fileSize}）</a>
            </span>`
            tinymce.activeEditor.selection.setContent(linkTab)
            this.$refs.editor.editor.editorManager.get('Editor').focus()
          } else {
            let imgSrc = res.data.downloadUrl
            let imgTag = `<img @click.stop="lookEditorImg" class="insertimage" src="${imgSrc}" height="auto" width="auto" style="cursor: pointer;" />`
            tinymce.activeEditor.selection.setContent(imgTag)
            this.$refs.editor.editor.editorManager.get('Editor').focus()
          }
        } else {
          this.$message({
            msg: '图片上传失败,联系开发人员',
            type: 'warn'
          })
        }
      })
    },
    lookEditorImg(imgURl, type) {
      this.bus(this).$emit('global:Editor:filePreview', imgURl, type)
    }
  }
}
</script>
<style lang="less" scoped>
.file-upload-input {
  display: none;
}
.editor-box {
  height: 100%;
}
::v-deep {
  .tox-tinymce-aux {
    z-index: 9999 !important;
  }
  .tox .tox-edit-area__iframe {
    padding: 0 8px;
  }
  .index-no-editor {
    .tox-tinymce-aux {
      z-index: 1300 !important; // 默认z-index
    }
  }
}
.detail-editor {
  ::v-deep {
    .tox-tinymce {
      border: 0;
    }
    .tox .tox-tbtn svg {
      fill: #999;
    }
    .tox .tox-toolbar,
    .tox .tox-toolbar__overflow,
    .tox .tox-toolbar__primary {
      background: none;
    }
    .tox .tox-edit-area__iframe {
      border-radius: 16px;
      background: #f5f6fa;
    }
    .tox-tinymce :focus,
    .tox-tinymce-aux :focus {
      background: #fff;
      border: 1px solid #3a78f6;
      box-shadow: 0px -3px 10px 0px rgba(50, 150, 250, 0.14) inset;
    }
  }
  .excel-content {
    width: 400px;
    height: 280px;
    overflow: auto;
  }
}
.dialog-footer {
  .change-img {
    padding: 10px 15px;
  }
}
</style>
<style lang="less">
.tox-pop__dialog {
  display: none !important;
}
.tox .tox-pop.tox-pop--bottom::after,
.tox .tox-pop.tox-pop--bottom::before {
  display: none !important;
}
</style>
