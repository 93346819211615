<template>
  <div>
    <el-drawer
      :append-to-body="true"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :size="800"
      :title="`智能推进详情`"
    >
      <div class="title-list-wrap" slot="title">
        <div class="title-name">智能推进详情</div>
        <div class="title-edit">
          <Icon
            v-if="type === 0 && userInfo.id === okrData.createUserid"
            icon-class="icon-edit-simple"
            class="icon-edit"
            @click="progressSetClick()"
          />
        </div>
      </div>
      <div class="brife-detail" v-vueloading="loading">
        <!-- 内容 -->
        <div class="auto-type">
          <template v-if="type == 0">
            <div class="type">KR</div>
            <div class="content" v-html="$utils.changeToHtml(item.title)"></div>
          </template>
          <template v-else>
            <div class="type">O</div>
            <div class="content" v-html="$utils.changeToHtml(okrData.content)"></div>
          </template>
        </div>
        <div class="main-okr">
          <div class="detail-content-wrap">
            <div class="progress-list-wrap" v-if="data.advanceTo.length>0">
              <div class="progress-type">
                <div class="type-icon">
                  <Icon icon-class="icon-xiangxiatuidong" />
                </div>
                <!-- advanceTo -->
                <div class="name">
                  <wx-open-data :id="okrData.userName" :nameData="{name:okrData.userName}" />
                  推进其他同事*{{data.advanceTo.length}}
                </div>
              </div>
              <div class="progress-list-content">
                <div class="progress-list">
                  <div class="infor-list" v-html="listData(4, data.advanceTo)"></div>
                </div>
              </div>
              <div
                class="detail-list-wrap"
                v-for="(listItem, listIndex) in data.advanceTo"
                :key="listIndex"
              >
                <div class="detail-title">
                  <el-image :src="listItem[0].userAvatar" />
                  <div class="name">
                    <wx-open-data
                      :id="listItem[0].userName"
                      :nameData="{name:listItem[0].userName}"
                    />
                  </div>
                </div>
                <div v-for="(keyItem, index) in listItem" :key="keyItem.id">
                  <okrItem
                    :okrData="okrData"
                    :item="item"
                    :keyItem="keyItem"
                    :type="type"
                    :index="index"
                    :unit="okrItemUnit"
                    :itemType="'advanceTo'"
                  />
                </div>
              </div>
            </div>
            <div
              class="progress-list-wrap progress-list-span-none"
              v-if="data.advanceFrom.length>0"
            >
              <div class="progress-type">
                <span class="type-icon">
                  <Icon icon-class="icon-xiangxiatuidong" />
                </span>
                <div class="name">
                  同事推进
                  <wx-open-data :id="okrData.userName" :nameData="{name:okrData.userName}" />
                  * {{data.advanceFrom.length}}
                </div>
              </div>
              <div class="progress-list-content">
                <div class="progress-list">
                  <div
                    class="infor-list"
                    v-if="data.advanceFrom"
                    v-html="listData(1, data.advanceFrom)"
                  ></div>
                </div>
              </div>
              <div
                class="detail-list-wrap"
                v-for="(listItem, listIndex) in data.advanceFromList"
                :key="listIndex"
              >
                <div class="detail-title">
                  <el-image :src="listItem[0].userAvatar" />
                  <div class="name">
                    <wx-open-data
                      :id="listItem[0].userName"
                      :nameData="{name:listItem[0].userName}"
                    />
                  </div>
                </div>
                <div v-for="(keyItem, index) in listItem" :key="keyItem.id">
                  <div v-if="keyItem.handleType">
                    <okrItem
                      :okrData="okrData"
                      :item="item"
                      :keyItem="keyItem"
                      :type="type"
                      :index="index"
                      :itemType="'advanceFrom'"
                    />
                    <div
                      v-for="(keyResultItem, keyResultIndex) in keyItem.keyResultsList"
                      :key="keyResultItem.id"
                      class="kr-list-wrap"
                    >
                      <okrItem
                        :okrData="okrData"
                        :item="item"
                        :keyItem="keyResultItem"
                        :type="type"
                        :index="keyResultIndex"
                        :itemType="'advanceFrom'"
                      />
                    </div>
                  </div>

                  <okrItem
                    :okrData="okrData"
                    :item="item"
                    :keyItem="keyItem"
                    :type="type"
                    :index="index"
                    :itemType="'advanceFrom'"
                    v-else
                  />
                </div>
              </div>
            </div>
            <div
              class="progress-list-wrap progress-list-span-none"
              v-if="data.advanceFromTask.length>0 || (data.taskMaxValue || 0) > 0"
            >
              <div class="progress-type">
                <span class="line"></span>
                <div class="name">由任务推进 * {{data.advanceFromTask.length}}</div>
              </div>
              <div class="progress-list-content">
                <div class="progress-list">
                  <div
                    class="infor-list"
                    v-if="data.advanceFromTask.length>0"
                    v-html="listData(2, data.advanceFromTask)"
                  ></div>
                  <div
                    class="infor-list"
                    v-if="data.advanceFromTask.length === 0 && (data.taskMaxValue || 0) >0"
                    v-html="listData(3, data.advanceFromTask)"
                  ></div>
                </div>
              </div>
              <div class="detail-list-wrap">
                <div v-for="(keyItem, index) in data.advanceFromTask" :key="keyItem.id">
                  <okrItem
                    :okrData="okrData"
                    :item="item"
                    :keyItem="keyItem"
                    :type="type"
                    :index="index"
                    :itemType="'advanceFromTask'"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="!loading && dataNullState" class="brife-item-default-box">
            <noData />
          </div>
        </div>
      </div>
    </el-drawer>
    <keyResultsAutoProcess
      v-if="keyResultsAutoProcessVisible"
      v-model="keyResultsAutoProcessVisible"
      :okrData="okrData"
      :item="item"
      @refresh="queryObjectiveDetail"
    />
  </div>
</template>
<script>
import okrItem from './okrItem.vue'
import keyResultsAutoProcess from '@/views/okr/components/singleOkrItem/keyResultsAutoProcess'
export default {
  components: { okrItem, keyResultsAutoProcess },
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  props: {
    dialogShow: {
      type: Boolean
    },
    okrData: {},
    item: {},
    type: {
    },
    data: {}
  },
  data() {
    return {
      dialogVisible: this.dialogShow,
      loading: false,
      dataNullState: false,
      keyResultsAutoProcessVisible: false,
      okrItemUnit: ''
    }
  },
  computed: {},
  watch: {
    dialogShow: {
      handler(val) {
        this.dialogVisible = val
      }
    }
  },
  created() {
    // 获取OKR列表
    this.dataNull()
  },
  mounted() {
    // this.dataNull()
  },
  methods: {
    // 获取列表
    dataNull() {
      var state = false
      if (this.data.advanceTo.length === 0 && this.data.advanceFrom.length === 0 && (this.data.taskMaxValue || 0) ===0) {
        state = true
      }
      this.dataNullState = state
      var advanceTo = JSON.parse(JSON.stringify(this.data.advanceTo))
      this.data.advanceToList = this.getInitList(advanceTo)
      var advanceFrom = JSON.parse(JSON.stringify(this.data.advanceFrom))
      this.data.advanceFromList = this.getInitList(advanceFrom)
    },
    getInitList(dataList) {
      dataList.forEach((userItem) => {
        var objIdList = {}
        var handleList = []
        userItem.forEach((listItem, listIndex) => {
          if (Number(listItem.businessType) === 1) {
            if ((objIdList[listItem.groupObjectId] || '') !== '') { // 已保存目标
              handleList.forEach((handleItem) => {
                if (handleItem.groupObjectId === listItem.groupObjectId) {
                  listItem.handleType = false
                  handleItem.keyResultsList.push(JSON.parse(JSON.stringify(listItem)))
                  userItem.splice(listIndex, 1)
                }
              })
            } else {
              objIdList[listItem.groupObjectId] = listItem.groupObjectId
              listItem.keyResultsList = [JSON.parse(JSON.stringify(listItem))]
              listItem.handleType = true
              handleList.push(listItem)
            }
          } else {
            handleList.push(listItem)
          }
        })
        userItem = JSON.parse(JSON.stringify(handleList))
      })
      return JSON.parse(JSON.stringify(dataList))
    },
    accAdd(arg1,arg2) {
      var r1,r2,m;
      try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
      try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
      m=Math.pow(10,Math.max(r1,r2))
      return (arg1*m+arg2*m)/m
    },
    listData(type, data) {
      var autoType = 'O'
      var unint = '%'
      if (this.type === 0) {
        autoType = 'KR'
        unint = this.item.quantificationUnit
      }
      if (type === 1) {
        // 推进我 目标 kr
        var html = `${this.okrData.userName}的 ${autoType} `
        if (this.$isQw) {
         html = `<ww-open-data type="userName" openid="${this.okrData.userName}"></ww-open-data>的 ${autoType} `
        }
        var pushTo = 0
        var userListHtml = ''
        data.forEach((listItem) => {
          var userName = ''
          var krNum = 0
          var oNum = 0
          listItem.forEach((item) => {
            if (item.businessType === 0 || item.businessType === 1) {
              pushTo += item.pushTo
              userName = this.$isQw?`<ww-open-data type="userName" openid="${item.userName}"></ww-open-data>`:item.userName
            }
            if (item.businessType === 0) {
              ++oNum
            } else {
              ++krNum
            }
          })
          if (krNum > 0) {
            userListHtml += `【${userName}的 ${krNum} 个 KR】`
          }
          if (oNum > 0) {
            userListHtml += `【${userName}的 ${oNum} 个 O】`
          }
        })
        html += `<span>${pushTo}${unint}</span>进度由<span>${userListHtml}</span>推进`
        return html
      } else if (type === 2) {
        // 推进我 任务
        var pushTo = 0
        data.forEach((item) => {
          pushTo = this.accAdd(pushTo, item.pushTo)
        })
        if (this.$isQw) {
          return `<ww-open-data type="userName" openid="${this.okrData.userName}"></ww-open-data>的 ${autoType}<span>${this.$utils.floatToFixedDown(pushTo)}${unint}</span>进度由<span>【${data.length}个KR任务】</span>推进`
        }else{
          return `${this.okrData.userName}的 ${autoType}<span>${this.$utils.floatToFixedDown(
          pushTo
        )}${unint}</span>进度由<span>【${data.length}个KR任务】</span>推进`
        }
      } else if (type === 3) {
        //任务 平均
        if (this.$isQw) {
          return `<ww-open-data type="userName" openid="${this.okrData.userName}"></ww-open-data>的 ${autoType}<span>${this.data.taskMaxValue}${unint}</span>进度由 KR任务 自动平均推进`

        } else {
          return `${this.okrData.userName}的 ${autoType}<span>${this.data.taskMaxValue}${unint}</span>进度由 KR任务 自动平均推进`
        }
        
      } else if (type === 4) {
        // 我推进
        if (this.$isQw) {
          return `<ww-open-data type="userName" openid="${this.okrData.userName}"></ww-open-data>的 ${autoType} 推进<span>【<ww-open-data type="userName" openid="${data[0][0].userName}"></ww-open-data> 1 个 KR 】 </span>${data[0][0].pushTo}${data[0][0].unit} 进度`

        } else {
          return `${this.okrData.userName}的 ${autoType} 推进<span>【${data[0][0].userName} 1 个 KR 】 </span>${data[0][0].pushTo}${data[0][0].unit} 进度`
        }
      }
    },
    handleClose() {
      this.dialogVisible = false
      this.$emit('change', false)
    },
    progressSetClick() {
      this.keyResultsAutoProcessVisible = true
    },
    queryObjectiveDetail() {
      this.$emit('refresh')
    }
  }
}
</script>
<style lang="less" scoped>
.kr-list-wrap .detail-list {
  margin-left: 36px;
}
.auto-type {
  .flex-l();
  margin: 16px 28px 4px 28px;
  .type {
    width: 42px;
    height: 26px;
    line-height: 26px;
    background: rgba(58, 120, 246, 0.08);
    border-radius: 13px;
    font-size: 14px;
    font-weight: 500;
    color: #3a70ff;
    text-align: center;
    margin-right: 12px;
  }
  .content {
    flex: 1;
    font-size: 14px;
    .text-n(1);
    font-weight: 400;
    color: #444558;
  }
}
.detail-list-wrap {
  .detail-title {
    .flex-l();
    margin-bottom: 8px;
    .el-image {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      margin-right: 8px;
    }
    .name {
      .text-n(1);
      font-size: 14px;
      font-weight: 500;
      color: #14142b;
    }
  }
}
.progress-list-wrap {
  padding: 16px;
  border: 1px solid #f5f6fa;
  border-radius: 12px;
  position: relative;
  margin-top: 12px;
  .progress-type {
    font-weight: 500;
    color: #14142b;
    font-size: 14px;
    .flex-l();
    margin-bottom: 16px;
    .name {
      .text-n(1);
      flex: 1;
    }
    .line {
      width: 2px;
      height: 16px;
      background: #3a70ff;
      border-radius: 2px;
      margin-right: 8px;
      display: block;
    }
    .type-icon {
      font-size: 20px;
      margin-right: 8px;
    }
  }
  .progress-list-content {
    .progress-list {
      .infor-list {
        font-size: 14px;
        font-weight: bold;
        color: #434458;
        line-height: 22px;
        padding: 12px 24px;
        background: rgba(58, 120, 246, 0.08);
        border-radius: 12px;
        margin-bottom: 12px;
        ::v-deep span {
          color: #3a78f6;
          margin: 0px 8px;
        }
      }
    }
  }
}
::v-deep {
  .el-drawer {
    border-radius: 16px 0px 0px 16px;
  }
  .el-drawer__header {
    height: 52px;
    padding: 0px 32px;
    border-bottom: 1px solid rgba(217, 219, 233, 0.54);
  }
}
.title-list-wrap {
  .flex-bt();
  .title-name {
    font-size: 24px;
    font-weight: 700;
    color: #14142b;
    line-height: 32px;
  }
  .icon-edit {
    color: #a0a3bd;
    margin-left: 16px;
    font-size: 16px;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      color: #3a78f6;
      background: rgba(58, 120, 246, 0.08);
    }
  }
}
.brife-detail {
  height: 100%;
  display: flex;
  flex-direction: column;

  .main-okr {
    // flex: 1;
    max-height: calc(100vh - 140px);
    overflow-y: auto;
    margin: 0px 28px;
  }
}
.brife-item-default-box {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
