var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "iframeWrap", class: `iframeWrap ${_vm.editorBorder}` },
    [
      _c("iframe", {
        ref: `${_vm.editorId}`,
        attrs: {
          src: _vm.srcUrl,
          frameborder: "0",
          scrolling: "auto",
          width: _vm.width,
          height: _vm.bodyHeight,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }