<template>
  <div
    class="comment-box-wrap-new"
    :class="`comment-box-${dropVisible}`"
    @dragover="dragevent(true)"
    @mouseleave="dragevent(false)"
    @click="dragevent(false)"
    @drop="upLoadFile"
    :contenteditable="dropVisible"
  >
    <div class="comment-box bottom-box">
      <div class="at-input-box comment-at-box" style="padding-left: 10px">
        <at-input
          ref="atCommentBoxinput"
          class="f-z-14"
          :at-all="atAll"
          :taskdetail-boolean="taskdetailBoolean"
          :html="form.comment"
          :limit="limit"
          :placeholder="placeholder"
          :sub-task="subTask"
          :enter-able="true"
          :atDisable="atDisable"
          :editable="isEditInput"
          :autoFocus="autoFocus"
          :lineFeed="lineFeed"
          @input="getComment"
          @enter="addComment"
          @fileUpload="fileUpload"
        />
      </div>
      <div class="upload-comment-wrap">
        <div class="file-upload-wrap">
          <file-upload
            v-if="source != 'stopdoing'"
            :input-drag-file-list="dragFile"
            :imgVisible="true"
            @fileFresh="fileFreshList"
          />
        </div>
        <div class="comment-release-btn">
          <div class="release-btn">
            <span
              v-if="!form.comment && uploadFileList.length === 0"
              class="disabled"
              :loading="loading"
            >发布</span>
            <span v-else :loading="loading" @click.stop="addComment">发布</span>
            <Icon
              :class="
                form.comment || uploadFileList.length > 0
                  ? 'release-icon release-icon-blue'
                  : 'release-icon'
              "
              icon-class="icon-arrow-down"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="drop-wrap" v-if="dropVisible">拖拽文件到这里，直接上传</div>
  </div>
</template>

<script>
import formMixin from '@/utils/mixins/form'
export default {
  mixins: [formMixin],
  props: {
    source: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    // 任务id
    taskId: {
      type: String,
      required: true
    },
    subTask: {
      type: Boolean,
      required: false
    },
    isEditInput: {
      type: Boolean,
      default: true
    },
    taskdetailBoolean: {
      type: Boolean,
      required: false
    },
    placeholder: {
      default: '想法，意见都可以提出哦，@提及他人 ↵'
    },
    atAll: {
      default: true
    },
    limit: {
      default: 500
    },
    atDisable: {
      default: false
    },
    autoFocus: {
      default: true
    },
    lineFeed: {
      default: false
    }
  },
  data() {
    return {
      form: {
        comment: '', // 评论
        userId: null // at的人id
      },
      uploadFileList: [],
      dragFile: {},
      dropVisible: false
    }
  },
  mounted() {
    this.bus(this).$on('meeting/clearComment', (val) => {
      this.commentFefresh()
    })
    // var upload = this.$refs['drag-content']
    // // 监听拖拽释放事件
    // upload.addEventListener('drop', this.dragDropEvent)
    // // 监听拖拽进入区域
    // upload.addEventListener('dragenter', this.dragEnter)
    // // 监听拖拽移出区域
    // upload.addEventListener('dragleave', this.dragLeave)
  },
  methods: {
    hideBox() {},
    commentFefresh() {
      this.uploadFileList = []
      this.bus.$emit('clearFile')
      this.form.comment = ''
      this.$refs.atCommentBoxinput.clearComment()
    },
    dragevent(state) {
      this.dropVisible = state
    },
    upLoadFile(e) {
      this.dropVisible = false
      // 阻止默认事件
      e.preventDefault()
      e.stopPropagation()
      const df = e.dataTransfer
      let dragFiles = {} // 存储拖拽的文件对象
      if (df.items !== undefined) {
        // 谷歌
        const item = df.items[0]
        if (item.kind === 'file' && item.webkitGetAsEntry().isFile) {
          const file = item.getAsFile()
          dragFiles = file
        }
      } else {
        // safari 以及其他浏览器
        dragFiles = df.files[0]
      }
      try {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(dragFiles.slice(0, 3))
        fileReader.addEventListener('load', (e) => {
          // 上传
          const formData = new FormData()
          formData.append('file', dragFiles)
          this.$apis.FILEUPLOAD(formData).then((res) => {
            if (res.status === 200) {
              // 上传成功
              this.dragFile = res.data
              // this.uploadFileList.push(res.data)
            } else {
              // 上传失败
              this.$message('上传失败，请重试！')
            }
          })
        })
        fileReader.addEventListener(
          'error',
          (r) => {
            this.$message.warn('不能上传文件夹')
          },
          false
        )
      } catch {
        this.$message.warn('不能上传文件夹')
        return
      }
    },
    fileUpload(formData) {
      this.$apis.FILEUPLOAD(formData).then((res) => {
        if (res.status === 200) {
          // 上传成功
          this.dragFile = res.data
          // this.uploadFileList.push(res.data)
        } else {
          // 上传失败
          this.$message('上传失败，请重试！')
        }
      })
    },
    async fileFreshList(val) {
      this.uploadFileList = val
    },
    // 发布评论
    async addComment() {
      if (!this.form.comment && this.uploadFileList.length === 0) {
        this.$message.error('请输入评论')
        return
      } else if (
        this.form.comment.replace(/\s*/g, '').length === 0 &&
        this.uploadFileList.length === 0
      ) {
        this.$message.error('评论不能为空!')
        return
      }
      this.form.taskId = this.taskId
      var ossFileEntityList =
        this.uploadFileList.length > 0 ? this.uploadFileList : null
      if (this.type && this.type === 'advise') {
        console.log('advise')
        this.bus.$emit('meeting/submitComment', this.form.comment)
        this.bus.$emit('meeting/showProblemDetails', true)
        this.form.comment = ''
        return
      } else if (this.type === 'returnCommit') {
        this.$emit('submitComment', this.form, ossFileEntityList)
        return
      }
      let commentValue = ''
      if ((this.form.comment || '') === '' && this.uploadFileList.length > 0) {
        commentValue = '查看附件'
      } else {
        commentValue = this.$utils.changeToOkrtFormat(this.form.comment)
      }
      this.$apis
        .TASKADDCOMMENT({
          ossFileEntityList: ossFileEntityList,
          taskId: this.taskId,
          comment: commentValue,
          userId: this.form.userId,
          type: 0
        })
        .then((res) => {
          if (res.status === 200) {
            this.uploadFileList = []
            this.bus.$emit('clearFile')
            this.form.comment = ''
            this.$emit('save')
            this.commentFefresh()
            // 数据埋点c
            this.$mixpanelUtil.track('Update Task', {
              type: '任务评论新增',
              task_id: this.taskId
            })
            this.$mixpanelUtil.track('Add Task Comment')
          } else {
            this.$message({
              msg: res.message,
              type: 'error'
            })
          }
        })
    },
    // 获取输入的评论
    getComment(obj) {
      var str = obj.html
      var regbr = new RegExp('<br>', 'g')
      var regspan = new RegExp('<span></span>', 'g')
      str = str.replace(regbr, '')
      str = str.replace(regspan, '')
      this.form.comment = str
      this.form.userId = obj.atIds.toString()
    }
  }
}
</script>
<style lang="scss">
.comment-box-wrap-new {
  position: relative;
}
.comment-box-true {
  caret-color: rgba(0, 0, 0, 0);
}
.drop-wrap {
  position: absolute;
  top: 0px;
  left: -1px;
  bottom: 0px;
  width: calc(100% + 2px);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #14142b;
  background: rgba(247, 247, 252, 0.92);
}
.upload-comment-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 16px 20px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
}
.upload-comment-wrap .upload-demo {
  display: flex;
  align-content: space-between;
  align-items: center;
  flex-wrap: wrap-reverse;
  width: 76px !important;
}
.upload-comment-wrap .el-upload {
  padding-right: 0 !important;
}
.upload-comment-wrap .el-upload-list {
  width: 710px;
  max-height: 180px;
  overflow-y: auto;
}
.upload-comment-wrap .el-upload-list__item {
  height: 44px;
  line-height: 44px;
  border: 1px solid #f5f6fa;
  border-radius: 9px;
  margin: 0px 0px 8px 0px;
}
.upload-comment-wrap .el-upload-list__item-name {
  padding-left: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.upload-comment-wrap .el-icon-close {
  font-size: 16px;
  top: 0px;
  right: 10px;
  line-height: 44px;
}
.upload-comment-wrap .el-icon-close:hover {
  color: #3a78f6;
}
.upload-comment-wrap {
  .el-upload-list__item-status-label {
    right: 10px;
    .el-icon-circle-check {
      font-size: 16px;
    }
  }
  .el-icon-document {
    width: 30px;
    height: 44px;
    // background: url(../../../../assets/icons/common/fileCommon.png) center
    // center no-repeat;
    background-size: 30px auto;
    &:before {
      content: '';
    }
  }
  .el-progress--line {
    top: auto;
    bottom: -5px;
  }
}
</style>
<style lang="scss" scoped>
.file-upload-wrap {
  width: 100%;
}
.comment-box {
  // border-top: 1px solid #d9dbe9;
  // padding: 10px;
  // position: relative;
  background: #ffffff;
  border-radius: 0px 0px 0px 16px;
  box-shadow: 0px -8px 16px 0px rgba(0, 0, 44, 0.02);
  .at-input-box {
    // background: $wild-sand;
    padding: 12px 10px 10px 20px !important;
  }
  // 底部时调整@成员列表位置
  .at-container .atwho-view {
    bottom: 0;
    top: auto;
  }
  .save-box {
    padding: 10px 10px 0 0;
    text-align: right;
  }
}
.comment-release-btn {
  // padding: 0 20px;
  // display: flex;
  // justify-content: space-between;
  // margin-top: 8px;
  color: #3a78f6;
  font-size: 14px;
  position: absolute;
  right: 20px;
  bottom: 14px;
  .disabled {
    color: #6e7491;
  }
  .release-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    .release-hover-icon {
      display: none;
    }

    .release-icon {
      font-size: 18px;
      color: #a0a3bd;
      &:hover {
        color: #6e7491;
      }
    }
    .release-icon-blue {
      color: #005eff !important;
    }
    &:hover {
      color: #005eff;
    }
  }
}
</style>
<style>
.global-box-wrap .at-container .atwho-wrap .placeholder {
  line-height: 18px !important;
}
.mainTaskDetail .comment-box-wrap-new .placeholder-text {
  font-size: 16px;
  color: #6e7491;
  top: 12px;
  line-height: 30px;
}
.mainTaskDetail .comment-box-wrap-new .editor {
  border: none !important;
  padding: 0px !important;
  /* margin-top: -7px; */
}
</style>
