<template>
  <div id="okrFileDiv" class="okr-file-div">
    <div class="table-div" :class="isDialog == true ? 'table-height-bg' : 'table-height-sm'">
      <div
        v-if="source == 'meeting' && multipleSelection.length > 0"
        class="batch-operation-div button-meeting"
      >
        <el-button
          v-if="tableData.length > 0"
          type="primary"
          class="meeting-batch-delete-button"
          @click="batchDelete()"
          :disabled="multipleSelection.length === 0"
        >批量删除</el-button>
      </div>
      <div
        class="batch-operation-div"
        :style="
          isDialog == true || source !== 'meeting' ? 'top:26px !important' : 'top:10px !important'
        "
      >
        <div
          v-if="multipleSelection.length > 0"
          class="operation-tips"
        >已选择{{ multipleSelection.length }}项目</div>
        <div
          v-if="multipleSelection.length <= 0 && tableData.length > 0"
          class="operation-tips operation-tips-all"
        >全选</div>
        <div v-if="source == 'okr'" class="operation-button">
          <el-button
            v-if="!$utils.isWxMac()"
            type="primary"
            class="batch-download-button"
            @click="batchDownload()"
          >
            <Icon class="file-svg" icon-class="icon-xiazai1" />
          </el-button>
          <el-button
            v-show="!disabled"
            type="danger"
            class="batch-delete-button"
            @click="batchDelete()"
          >
            <Icon class="file-svg" icon-class="icon-shanchu1" />
          </el-button>
        </div>
      </div>
      <!--  :before-upload="onBeforeUpload" -->
      <el-upload
        v-if="source == 'okr'"
        ref="Fileupload"
        :show-file-list="false"
        action
        class="upload-demo"
        :on-change="onchange"
        multiple
        :http-request="uploadFile"
      >
        <el-button
          v-show="!isDialog && (!disabled || isSharer)"
          type="primary"
          class="batch-upload-button"
        >
          <Icon
            class="file-svg"
            icon-class="icon-task_cooperation_add_yes_not_icon"
            @click="clickUpload"
          />上传文件
        </el-button>
      </el-upload>

      <el-table
        v-if="tableData.length > 0"
        ref="multipleTable"
        class="okr-file-table"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 99.9%"
        height="100%"
        :row-class-name="tableRowClassName"
        :row-style="setRowStyle"
        @selection-change="handleSelectionChange"
        @cell-mouse-enter="cellHover"
        @cell-mouse-leave="cellOut"
      >
        <el-table-column fixed="left" type="selection" width="38" :selectable="checkboxSelect" />
        <el-table-column label :width="source == 'okr' ? 180 : 50" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div class="file-list" @click="lookImg(scope.row)">
              <!-- <Icon class="file-svg" icon-class="icon-MP4" /> -->
              <Icon
                v-if="
                  scope.row.fileType == 'docx' ||
                  scope.row.fileType == 'doc' ||
                  scope.row.fileType == 'DOCX' ||
                  scope.row.fileType == 'DOC'
                "
                class="file-svg"
                icon-class="icon-DOCX"
              />
              <Icon
                v-else-if="
                  scope.row.fileType == 'mp3' || scope.row.fileType == 'MP3'
                "
                class="file-svg"
                icon-class="icon-MP3"
              />
              <Icon
                v-else-if="
                  scope.row.fileType == 'mp4' || scope.row.fileType == 'MP4'
                "
                class="file-svg"
                icon-class="icon-MP4"
              />
              <Icon
                v-else-if="
                  scope.row.fileType == 'pdf' || scope.row.fileType == 'PDF'
                "
                class="file-svg"
                icon-class="icon-PDF"
              />
              <Icon
                v-else-if="
                  scope.row.fileType == 'ppt' || scope.row.fileType == 'PPT'
                "
                class="file-svg"
                icon-class="icon-PPT"
              />
              <Icon
                v-else-if="
                  scope.row.fileType == 'txt' || scope.row.fileType == 'TXT'
                "
                class="file-svg"
                icon-class="icon-filetxt"
              />
              <Icon
                v-else-if="
                  scope.row.fileType == 'XLS' ||
                  scope.row.fileType == 'xls' ||
                  scope.row.fileType == 'XLSX' ||
                  scope.row.fileType == 'xlsX' ||
                  scope.row.fileType == 'xlsx'
                "
                class="file-svg"
                icon-class="icon-XLS"
              />
              <Icon v-else class="file-svg" icon-class="icon-fileCommon" />
              <div
                v-if="source == 'okr'"
                style="
                  flex: 1;
                  min-width: 0;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                "
              >{{ scope.row.fileName }}</div>
            </div>
          </template>
        </el-table-column>
        <template v-if="source == 'meeting'">
          <el-table-column label prop :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <div class="meeting-file-div" @click="lookImg(scope.row)">
                <div class="file-name">{{ scope.row.document.name }}</div>
                <div class="file-content">
                  <span class="file-size">{{ $utils.formatFileSize(scope.row.document.fileSize) }}</span>
                  <span class="file-createdate">{{ scope.row.createDate }}</span>
                  <span class="file-user-name">
                    <wx-open-data
                      :id="
                        scope.row.createUser.name
                          ? scope.row.createUser.name
                          : scope.row.createUser.name
                      "
                      :name-data="{ name: scope.row.createUser.name }"
                    />
                  </span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label width="50">
            <template slot-scope="scope">
              <div class="operation-div">
                <div
                  v-if="userInfo.id == scope.row.createUserid || canDelete"
                  class="delete-meeting-file"
                  @click="deleteFile(scope.row)"
                >
                  <Icon class="file-svg" icon-class="icon-shanchu1" />
                </div>
              </div>
            </template>
          </el-table-column>
        </template>
        <template v-if="source == 'okr'">
          <el-table-column label prop width="80" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <div>
                <wx-open-data
                  :id="
                    scope.row.user.name
                      ? scope.row.user.name
                      : scope.row.user.name
                  "
                  :name-data="{ name: scope.row.user.name }"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column label prop width="165" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <div>{{ scope.row.createDate }}</div>
            </template>
          </el-table-column>
          <el-table-column label width="100">
            <template slot-scope="scope" :show-overflow-tooltip="true">
              <div
                style="
                  flex: 1;
                  min-width: 0;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                "
              >{{ $utils.formatFileSize(scope.row.fileSize) }}</div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label width="90">
            <template slot-scope="scope">
              <!-- v-show="scope.row.isShowOperate" -->
              <div v-if="scope.row.id == activeRowId" class="operate-row">
                <el-button
                  v-show="scope.row.status == 0"
                  type="text"
                  class="download-button"
                  @click="fileDownload(scope.row, scope.row)"
                >
                  <Icon icon-class="icon-xiazai1" class="okr-operate" />
                </el-button>
                <el-popover
                  v-if="!disabled || isSharer"
                  :ref="`operatePopover${scope.row.id}`"
                  placement="bottom"
                  title
                  width="180"
                  trigger="click"
                  @show="popoverShow(scope.row)"
                  @hide="popoverHide()"
                >
                  <div class="operation-div">
                    <div class="operation-row" @click="fileIsTop(scope.row)">
                      <!-- 置顶状态 0 取消置顶 1置顶 -->
                      {{
                      scope.row.topStatus == 1 ? '取消置顶文件' : '置顶文件'
                      }}
                    </div>
                    <div
                      v-if="
                        (isSharer && userInfo.id == scope.row.user.id) ||
                        !disabled
                      "
                      class="operation-row"
                    >
                      开启下载
                      <el-switch
                        v-model="scope.row.status"
                        style="margin-left: 52px"
                        :active-value="0"
                        :inactive-value="1"
                        @change="changeIsDownload(scope.row, scope.row.status)"
                      />
                    </div>
                    <div
                      v-if="
                        (isSharer && userInfo.id == scope.row.user.id) ||
                        !disabled
                      "
                      class="operation-row delete-file"
                      @click="deleteFile(scope.row)"
                    >删除文件</div>
                  </div>
                  <el-button slot="reference" type="text" class="more-button">
                    <Icon icon-class="icon-more-row" class="okr-operate" />
                  </el-button>
                </el-popover>
              </div>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <div v-if="tableData.length <= 0" class="no-data-div">
        <no-data />
      </div>
    </div>
    <process-dialog
      v-if="loading"
      v-model="loading"
      :type="'upload'"
      :dialog-txt="loadingTxt"
      :rate="percentage"
      :loading-param="loadingParam"
      @close="closePage"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import baseUrl from '../../../api/env' // 基础url
import noData from './noData.vue'
import processDialog from '@/components/global/ProcessDialog' // 复制弹窗
export default {
  components: {
    noData,
    processDialog
  },
  props: {
    source: {
      type: String,
      default: 'okr'
    },
    isSharer: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    // 是否需要上传后预览
    preview: {
      type: Boolean,
      default: false
    },
    cycleInfoId: {
      type: String
    },
    objId: {
      type: String
    },
    isDialog: {
      type: Boolean,
      default: false
    },
    meetingInfoId: {
      type: String,
      default: ''
    },
    canDelete: {
      default: false
    }
  },
  data() {
    return {
      isDownload: false,
      okrFileVisible: false,
      tableData: [],
      multipleSelection: [],
      isShowPopover: false,
      fileData: [], // 文件上传数据（多文件合一）
      // fileList: [], // upload多文件数组
      actionurl: '',
      filesLength: '',
      length: 0,
      fileList: [], // 文件存储中间量
      activeRowId: '',
      isShowOperate: false,
      fileTotalSize: 0, // 文件总大小
      percentage: 0,
      loading: false,
      loadingTxt: ''
    }
  },
  watch: {
    visible(val) {
      this.okrFileVisible = val
      if (this.okrFileVisible) {
      }
    }
  },
  created() {
    this.actionurl = `${baseUrl}/api/oss/${this.versionNumber}/common/files`
    if (this.source === 'okr') {
      this.getOkrFileList()
    } else {
      this.getMeetingFileList()
    }
  },
  mounted() {},
  methods: {
    checkboxSelect(row, rowIndex) {
      var state = true
      if (this.source === 'meeting') {
        if (this.userInfo.id !== row.createUserid && !this.canDelete) {
          state = false
        }
      }
      return state
    },
    closePage() {
      this.$emit('refreshData')
    },
    clickUpload() {
      this.fileTotalSize = 0
    },
    tableRowClassName({ row, rowIndex }) {
      return 'row-style'
    },
    setRowStyle({ row, rowIndex }) {
      const styleRes = {
        background: '#F5F6FA !important',
        border: '12px !important'
      }
      if (row.topStatus === 1) {
        return styleRes
      }
    },
    lookImg(row) {
      this.bus(this).$emit('global:OKRFILE:filePreview', row)
    },
    selectable(row) {
      if (this.disabled) {
        if (row.status == 0) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },
    // 上传文件用到的两个方法
    async uploadFile() {
      const formData = new FormData()
      if (this.fileList.length > 0) {
        this.fileList.forEach((item) => {
          this.fileTotalSize = Number(this.fileTotalSize) + Number(item.size)
        })
        console.log(
          '文件总大小---',
          this.fileList,
          this.fileTotalSize,
          this.$utils.formatFileSize(this.fileTotalSize)
        )
        // 超出50M
        if (this.fileTotalSize > 52428800) {
          this.$message.warn(`上传文件大小超出限制！`)
          this.$refs.Fileupload.clearFiles() // 上传成功之后清除历史记录
          this.fileList = []
          this.fileTotalSize = 0
          return
        }
        this.fileList.forEach((file) => {
          formData.append('multipartFiles', file)
        })
        if (
          formData.getAll('multipartFiles').length == this.length &&
          this.length > 0
        ) {
          this.loadingTxt = '正在上传文件，请稍后'
          this.loading = true
          this.percentage += 10
          //	走接口
          await this.$apis.BATCHFILEUPLOAD(formData).then((res) => {
            if (res.status === 200) {
              const fileArray = []
              res.data.forEach((item) => {
                fileArray.push({
                  fileId: item.documentId,
                  fileName: item.name,
                  fileType: item.mimeType,
                  fileUrl: item.downloadUrl,
                  fileSize: item.fileSize,
                  status: 0 // 是否开启下载 0 开启 1隐藏下载
                })
              })
              const params = {
                objId: this.objId,
                cycleInfoId: this.cycleInfoId,
                fileArray: fileArray
              }

              this.$apis.OKRFILESSAVE(params).then((res) => {
                if (res.status === 200) {
                  this.$refs.Fileupload.clearFiles() // 上传成功之后清除历史记录
                  this.fileList = []
                  this.$message.success(`文件上传成功！`)
                  this.percentage = 100
                  this.getOkrFileList()
                  setTimeout(() => {
                    this.loading = false
                    this.percentage = 0
                  }, 1000)
                }
              })
              this.fileTotalSize = 0
            } else if (res.status === 413) {
              this.$refs.Fileupload.clearFiles() // 上传成功之后清除历史记录
              this.fileList = []
              this.$message.warn(`上传文件大小超出限制！`)
              this.loading = false
              this.percentage = 0
              this.fileTotalSize = 0
            } else {
              this.$message.warn(`文件上传异常，请稍后再试哦！`)
              this.$refs.Fileupload.clearFiles() // 上传成功之后清除历史记录
              this.fileList = []
              this.loading = false
              this.percentage = 0
              this.fileData = []
              this.fileTotalSize = 0
            }
          })
        }
      }
    },
    onchange() {
      // 判断上传文件数量
      this.length = document.querySelector('input[type=file]').files.length
      if (this.length > 0) {
        Array.from(document.querySelector('input[type=file]').files).forEach(
          (file) => {
            if (this.fileList.indexOf(file) == -1) {
              this.fileList.push(file)
            }
          }
        )
      }
      return false
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleClose() {
      this.$emit('closeOkrFile')
    },
    // 获取会议文件列表
    getMeetingFileList() {
      this.$apis.PROGRESSFILELIST(this.meetingInfoId).then((res) => {
        if (res.status === 200) {
          this.tableData = (res.data || [])
          const map = {}
          this.tableData.forEach(function (item) {
            item.fileUrl = item.document.downloadUrl
            item.fileName = item.document.name
            item.fileType = item.document.mimeType
            item.createDate = item.document.createDate
            item.fileSize = item.document.fileSize
            item.createName = item.createUser.name
            map[item.fileName] =
              map[item.fileName] == null ? 0 : map[item.fileName] + 1
            if (map[item.fileName]) {
              var reg = /\.\w+$/
              var filename = item.fileName.replace(reg, '')
              var filetype = item.fileName.substring(
                item.fileName.lastIndexOf('.') + 1
              )
              item.fileName = filename += map[item.fileName] + '.' + filetype
            }
          })
          this.$emit('refreshOkrFile', this.tableData)
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 获取文件列表
    getOkrFileList() {
      const params = {
        objId: this.objId
      }
      this.$apis.OKRFILELIST(params).then((res) => {
        if (res.status === 200) {
          this.tableData = res.data
          const map = {}
          this.tableData.forEach(function (item) {
            map[item.fileName] =
              map[item.fileName] == null ? 0 : map[item.fileName] + 1
            if (map[item.fileName]) {
              var reg = /\.\w+$/
              var filename = item.fileName.replace(reg, '')
              var filetype = item.fileName.substring(
                item.fileName.lastIndexOf('.') + 1
              )
              item.fileName = filename += map[item.fileName] + '.' + filetype
            }
          })
          this.$emit('refreshOkrFile')
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 文件置顶
    fileIsTop(row) {
      const params = {
        id: row.id,
        topStatus: row.topStatus == 1 ? 0 : 1
      }
      this.$apis.OKRFILETOP(params).then((res) => {
        if (res.status === 200) {
          this.$message.success(res.message)
          this.$refs[`operatePopover${row.id}`].doClose()
          this.clear()
          this.getOkrFileList()
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 是否开启下载
    changeIsDownload(row, val) {
      const params = {
        id: row.id,
        status: val
      }
      this.$apis.OKRFILESTATUS(params).then((res) => {
        if (res.status === 200) {
          this.$message.success(res.message)
          this.$refs[`operatePopover${row.id}`].doClose()
          this.clear()
          this.getOkrFileList()
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 删除文件
    deleteFile(row) {
      console.log('删除-', row, this.userInfo)
      if (this.isSharer) {
        if (row.user.id !== this.userInfo.id) {
          this.$message({
            msg: '请选择需要下载的文件!',
            type: 'warn'
          })
        }
      }
      this.$confirm('是否确认删除文件？', '删除文件', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'confirmBox'
      })
        .then(() => {
          var ids = []
          ids.push({
            id: row.id
          })
          var url = this.$apis.DELETEOKRFILE
          if (this.source === 'meeting') {
            url = this.$apis.DELETEPROGRESSFILES
            ids = [{
              meetingInfoDocumentId: row.meetingInfoDocumentId
            }]
          }
          url(this.source === 'meeting' ? ids : this.objId, this.source === 'meeting' ? '' : ids).then((res) => {
            if (res.status === 200) {
              this.$message.success(res.message)
              if (this.source === 'meeting') {
                this.getMeetingFileList()
              } else {
                this.getOkrFileList()
              }
            } else {
              this.$message({
                msg: res.message,
                type: 'error'
              })
            }
          })
        })
        .catch(() => {})
    },
    // 批量下载
    batchDownload() {
      if (this.multipleSelection.length <= 0) {
        this.$message({
          msg: '请选择需要下载的文件!',
          type: 'warn'
        })
        return
      } else {
        const ids = []
        if (this.multipleSelection.length > 0) {
          const mulArr = this.multipleSelection.filter((item) => {
            return item.status == 0
          })
          const noMulArr = this.multipleSelection.filter((item) => {
            return item.status == 1
          })
          if (mulArr.length > 0) {
            if (noMulArr.length > 0) {
              this.$message({
                type: 'warn',
                msg: `有${noMulArr.length}个文件无法下载，下载权限未开启`
              })
            }
            this.fileTotalSize = 0
            mulArr.forEach((item) => {
              this.fileTotalSize =
                Number(this.fileTotalSize) + Number(item.fileSize)
              ids.push({
                name: item.fileName,
                fileCategory: item.fileType,
                mimeType: item.fileType,
                fileSize: item.fileSize,
                downloadUrl: item.fileUrl
              })
            })
            console.log('下载文件总数-', this.fileTotalSize)
            // 超出50M
            if (this.fileTotalSize > 52428800) {
              this.$message.warn(`下载文件大小超出限制！`)
              this.$refs.Fileupload.clearFiles() // 上传成功之后清除历史记录
              this.fileList = []
              this.fileTotalSize = 0
              return
            }
            this.fileBatchDownload(ids)
          } else {
            this.loading = false
            this.percentage = 0
            this.fileTotalSize = 0
            this.$message({
              type: 'warn',
              msg: '无法下载文件，下载权限未开启'
            })
          }
        }
      }
    },
    // 下载
    fileBatchDownload(params) {
      this.loadingTxt = '正在下载文件，请稍后'
      this.loading = true
      this.percentage += 10
      const link = document.createElement('a')
      this.$apis.FILEDOWNLOAD(params).then((res) => {
        console.log(11111, res)
        if (res.data === 'downErr') {
          this.fileTotalSize = 0
          this.$message({
            type: 'error',
            msg: '下载失败!'
          })
          this.loading = false
          this.percentage = 0
        } else {
          const blob = new Blob([res.data], {
            type: 'application/zip'
          })
          const objectUrl = URL.createObjectURL(blob) // 创建URL
          link.href = objectUrl
          link.download = res.location ? res.location.split(',')[0] : '' // 自定义文件名
          link.click() // 下载文件
          this.$refs.multipleTable.clearSelection()
          URL.revokeObjectURL(objectUrl) // 释放内存
          this.percentage = 100
          this.fileTotalSize = 0
          setTimeout(() => {
            this.loading = false
            this.percentage = 0
          }, 1000)
        }
      })
    },
    fileDownload(row) {
      if (this.$utils.isWxMac()) {
        window.location.href = row.fileUrl
      } else {
        const data = {}
        data['fileUrl'] = row.fileUrl
        const link = document.createElement('a')
        this.$apis.FILEDOWNLOADONE(data).then((res) => {
          if (res.data === 'downErr') {
            this.$message({
              type: 'error',
              msg: '下载失败!'
            })
          } else {
            const blob = new Blob([res])
            const objectUrl = URL.createObjectURL(blob) // 创建URL
            link.href = objectUrl
            link.download = row.fileName // 自定义文件名
            link.click() // 下载文件
            URL.revokeObjectURL(objectUrl) // 释放内存
          }
        })
      }
    },
    // 批量删除
    batchDelete() {
      if (this.multipleSelection.length <= 0) {
        this.$message({
          msg: '请选择需要删除的文件!',
          type: 'warn'
        })
        return
      } else {
        this.$confirm('是否确认删除文件？', '删除文件', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'confirmBox'
        })
          .then(() => {
            const ids = []
            this.multipleSelection.forEach((item) => {
              if (this.source === 'meeting') {
                ids.push({
                  meetingInfoDocumentId: item.meetingInfoDocumentId
                })
              } else {
                ids.push({
                  id: item.id
                })
              }
            })
            var url = this.$apis.DELETEOKRFILE
            if (this.source === 'meeting') {
              url = this.$apis.DELETEPROGRESSFILES
            }
            url(this.source === 'meeting' ? ids : this.objId, ids).then((res) => {
              if (res.status === 200) {
                this.$message.success(res.message)
                this.$refs.multipleTable.clearSelection()
                if (this.source === 'meeting') {
                  this.getMeetingFileList()
                } else {
                  this.getOkrFileList()
                }
              } else {
                this.$message({
                  msg: res.message,
                  type: 'error'
                })
              }
            })
          })
          .catch(() => {})
      }
    },
    cellHover(row, column, cell, event) {
      this.isShowOperate = true
      this.activeRowId = row.id
    },
    cellOut(row, column, cell, event) {
      if (this.$refs[`operatePopover${row.id}`]) {
        if (this.$refs[`operatePopover${row.id}`].showPopper == true) {
          this.isShowOperate = true
          this.activeRowId = row.id
        } else {
          this.isShowOperate = false
          this.activeRowId = ''
        }
      }
    },
    clear() {
      this.isShowOperate = false
      this.activeRowId = ''
    },
    popoverShow(row) {
      this.isShowOperate = true
      this.activeRowId = row.id
    },
    popoverHide() {
      this.isShowOperate = false
      this.activeRowId = ''
    }
  }
}
</script>
<style scoped lang="less">
@import '../../../assets/styles/dialog.less';
::v-deep {
  .el-button.meeting-batch-delete-button {
    border-radius: 8px;
    margin-top: 6px;
    height: 32px;
    line-height: 32px;
    padding: 0px 24px;
  }
  .el-table th,
  .el-table tr {
    border-radius: 12px !important;
  }
  .el-table--enable-row-transition .el-table__body tr > td:first-of-type {
    border-radius: 12px 0 0 12px !important;
  }
  .el-table--enable-row-transition .el-table__body tr > td:last-of-type {
    border-radius: 0 12px 12px 0 !important;
  }
  .no-data-show .no-data-show-icon {
    width: 116px !important;
    margin: 0px !important;
  }
  .el-table-column--selection .cell {
    padding-right: 0 !important;
    padding-left: 10px !important;
  }
  .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar {
    // display: none !important; /* Chrome Safari */
  }
  .operate-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    & > span {
      margin-top: 8px !important;
    }
  }
  .table-div {
    width: 100%;
    position: relative;
    border-radius: 12px;
    .batch-operation-div {
      left: 48px;
      z-index: 1;
      position: absolute;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      z-index: 9999999;
      .operation-tips {
        width: 120px;
        font-size: 14px;
        color: #a0a3bd;
        font-weight: 500;
        line-height: 30px;
      }
      .operation-tips-all {
        width: 50px;
        line-height: 30px;
        color: rgba(160, 163, 189, 1);
      }
    }
    .button-meeting {
      right: 0px;
      left: auto;
    }
  }
  .table-height-bg {
    height: calc(100vh - 70px) !important;
  }
  .table-height-sm {
    height: calc(100vh - 240px) !important;
  }
  .no-data-div {
    height: calc(100vh - 240px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .el-button.batch-upload-button {
    position: absolute;
    right: 0px;
    top: 26px;
    z-index: 9999;
    display: flex;
    align-items: center;
    height: 30px !important;
    line-height: 30px !important;
    text-align: center !important;
    padding: 0 12px !important;
    border: none !important;
    border-radius: 8px;
    background: transparent !important;
    color: #3a78f6 !important;
    .file-svg {
      font-size: 18px;
      color: #3a78f6 !important;
      float: left;
      vertical-align: middle;
      margin-top: 6px;
    }
    &:focus,
    &:hover,
    &:active {
      height: 30px !important;
      line-height: 30px !important;
      border: none !important;
      background: rgba(58, 120, 246, 0.08) !important;
      color: #3a78f6 !important;
      .file-svg {
        color: #3a78f6 !important;
      }
    }
  }
  .el-button.batch-download-button {
    height: 30px !important;
    line-height: 30px !important;
    width: 30px !important;
    text-align: center !important;
    border: none !important;
    border-radius: 8px;
    background: transparent !important;
    .file-svg {
      font-size: 18px;
      color: #6e7391 !important;
    }
    &:focus,
    &:hover,
    &:active {
      height: 30px !important;
      line-height: 30px !important;
      width: 30px !important;
      border: none !important;
      background: rgba(58, 120, 246, 0.08) !important;
      color: #3a78f6 !important;
      .file-svg {
        color: #3a78f6 !important;
      }
    }
  }
  .el-button.batch-delete-button {
    height: 30px !important;
    line-height: 30px !important;
    width: 30px !important;
    text-align: center !important;
    color: #fff !important;
    border: none !important;
    border-radius: 8px;
    background: transparent !important;
    .file-svg {
      font-size: 18px;
      color: #6e7391 !important;
    }
    &:focus,
    &:hover,
    &:active {
      height: 30px !important;
      line-height: 30px !important;
      width: 30px !important;
      border: none !important;
      background: rgba(255, 87, 43, 0.08) !important;
      .file-svg {
        color: #ff572b !important;
      }
    }
  }
  .el-dialog__body {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    position: relative;
  }
  .el-dialog__headerbtn {
    top: 36px;
  }
  .okr-file-table {
    border-radius: 12px !important;
  }
  .okr-file-table .el-table__fixed,
  .okr-file-table .el-table__fixed-right,
  .okr-file-table .el-table__fixed-left {
    box-shadow: none !important;
  }
  .okr-file-table.el-table th > .cell {
    padding-left: 10px !important;
  }
  .okr-file-table td,
  .okr-file-table th {
    border-bottom: none !important;
    height: 50px;
    color: #6e7391 !important;
  }

  .okr-file-table.el-table .el-table__cell {
    padding: 0 !important;
  }
  .okr-file-table.el-table::before {
    height: 0;
  }
  .el-table__fixed-right::before,
  .el-table__fixed::before {
    height: 0;
  }
  .el-button.download-button {
    margin-right: 10px;
  }
  .el-button.download-button,
  .el-button.more-button {
    font-size: 18px;
    font-weight: 700;
    padding: 0;
    border: none;
    .okr-operate {
      color: #6e7491 !important;
    }
    &:focus,
    &:hover,
    &:active {
      border: none !important;
      background: none !important;
      .okr-operate {
        color: #3a78f6 !important;
      }
    }
  }
}
.downloadImg {
  .flex-l();
  position: absolute;
  height: 64px;
  line-height: 64px;
  top: 0px;
  right: 74px;
  cursor: pointer;
  width: 70px;
  &:before {
    content: '';
  }
  .icon-download {
    transform: rotate(90deg);
    font-size: 24px;
    width: 30px;
    height: 30px;
    padding: 4px;
    border-radius: 4px;
  }
  &:hover {
    .icon-download {
      background: #f5f6fa;
    }
  }
}
.img-wrap-box {
  margin: 0px auto;
  text-align: center;
  vertical-align: middle;
  .dialog-img {
    width: auto;
    max-width: 100%;
    max-height: calc(80vh - 150px);
    overflow-y: auto;
  }
}
.dialog-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .dialog-title {
    line-height: 32px;
    font-size: 24px;
    color: #14142b;
    font-weight: 700;
  }
  .dialog-button {
    padding-right: 14px;
  }
}
.okr-file-div {
  padding: 0 20px;
  height: 100%;
}
.operation-div {
  .delete-meeting-file {
    cursor: pointer;
    .file-svg {
      font-size: 20px;
      color: #6e7491;
    }
    &:hover {
      .file-svg {
        color: #3a78f6;
      }
    }
  }
  .operation-row {
    height: 40px;
    line-height: 40px;
    border-bottom: 1px #f5f7f5 solid;
    cursor: pointer;
  }
  .operation-row.delete-file {
    color: #ff2828;
  }
}
.file-list {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  .file-svg {
    width: 30px;
    height: 30px;
    font-size: 30px;
    margin-right: 10px;
  }
  .file-title {
    flex: 1;
    .file-text {
      font-size: 16px;
      font-weight: 700;
      color: #444558;
    }
    .file-tips {
      display: flex;
      flex-direction: row;
    }
  }
}
.meeting-file-div {
  .file-name {
    font-size: 16px;
    color: #444558;
  }
  .file-content {
    .file-size {
      font-size: 12px;
      color: #a0a3bd;
    }
    .file-createdate {
      font-size: 12px;
      color: #a0a3bd;
      margin-left: 8px;
    }
    .file-user-name {
      font-size: 12px;
      color: #a0a3bd;
      margin-left: 8px;
    }
  }
}
</style>
