var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "apply-demo-dialog",
      attrs: {
        visible: _vm.dialogVisible,
        width: "560px",
        "append-to-body": true,
        "modal-append-to-body": true,
        title: "转免费版本",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c("div", { staticClass: "remind-wrap" }, [
        _c("div", { staticClass: "free-text" }, [
          _c("p", [_vm._v("您的应用已过试用期，现已自动转为免费版本。")]),
          _c("p", [_vm._v("10人及以内可免费使用 OKR、Todo、训练营等功能模块")]),
          _c("p", [
            _vm._v("您原来的数据将会保留，若想继续使用更多模块可联系我们升级"),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "center" },
          [
            _c(
              "el-button",
              {
                staticClass: "update",
                attrs: { type: "primary" },
                on: { click: _vm.closeDialog },
              },
              [_vm._v("立即使用免费版")]
            ),
            _c("div", { staticClass: "or" }, [_vm._v("or")]),
            _c(
              "a",
              {
                attrs: {
                  href: `https://page.dingtalk.com/wow/dingtalk/act/serviceconversation?ddtab=true&wh_biz=tm&showmenu=false&goodsCode=DT_GOODS_881655784491241&corpId=${_vm.commonCorpId}&token=199f876c080bd26313560b8515f10454`,
                  target: "_blank",
                },
                on: { click: _vm.closeDialog },
              },
              [_vm._v("联系我们升级版本")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }