<template>
  <el-tooltip
    placement="top"
    :open-delay="500"
    :content="content"
    :disabled="content === '' ? true : false"
  >
    <div class="flur-wrap" v-if="visible" :class="`flur-wrap-${createUser} flur-wrap-${type}`">
      <!-- <Icon icon-class="icon-lock" class="icon-private" v-if="type === 3 || type === 4" /> -->
      <div class="icon-private" v-if="type === 3 || type === 4">
        <img src="../../../../assets/imgs/common/private-close.png" />
      </div>
    </div>
  </el-tooltip>
</template>
<script>
export default {
  props: {
    createUser: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Number,
      default: 0
    },
    type: {
      type: Number,
      default: 1 // 1:okr 2:kr 3:kr带锁 4:okr头像带锁
    },
    content: {
      type: String,
      default: '对方已设置为不可见' // 1:okr 2:kr 3:kr带锁 4:okr头像带锁
    }
  },
  data() {
    return {}
  }
}
</script>
<style lang="less" scoped>
.flur-wrap-true {
  &:hover {
    background: none;
    // .icon-private {
    //   display: none;
    // }
  }
}
.flur-wrap-4 {
  border-radius: 50% !important;
  .icon-private {
    font-size: 20px;
  }
}
.flur-wrap-3,
.flur-wrap-4 {
  img {
    margin: 0px -4px -4px 0px;
  }
}
.flur-wrap-1,
.flur-wrap-2 {
  background: url('../../../../assets/imgs/meeting/meetingDialogBg.png') center
    center no-repeat;
  background-size: cover;
}
.flur-wrap {
  display: flex;
  margin-bottom: 16px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  border-radius: 8px;
  .icon-private {
    .flex-c();
    align-items: flex-end;
    justify-content: flex-end;
    color: #3a78f6;
    width: 90%;
    height: auto;
    img {
      width: 100%;
      max-width: 16px;
      margin: 0px -8px -4px 0px;
    }
  }
}
</style>
