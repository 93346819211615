import {
  mapState
} from 'vuex'
// this.bus
import bus from '../bus'
export default {
  data() {
    return {
      bus: bus
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
      companyInfo: (state) => state.user.companyInfo,
      cycle: (state) => state.common.cycle,
      token: (state) => state.user.token,
      versionNumber: (state) => state.user.versionNumber,
      isFirstBtn: (state) => state.user.isFirstBtn,
      companyCalendarInfo: (state) => state.common.companyCalendarInfo,
      analyseFilter: (state) => state.user.analyseFilter,
      commonFromType: (state) => state.common.commonFromType,
      commonCorpId: (state) => state.common.commonCorpId,
      kpiCycleTypeList: (state) => state.common.kpiCycleTypeList,
      userStateList: (state) => state.common.userStateList
    })
  }
}
