var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "kr_select" },
    [
      _c(
        "el-dropdown",
        { attrs: { trigger: "click" } },
        [
          _c("div", { staticClass: "el-dropdown-link" }, [
            _c("div", { staticClass: "confidence_icon" }, [_vm._v("KR")]),
            _c(
              "div",
              { staticClass: "kr-type" },
              [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.krSelectedValue,
                        expression: "krSelectedValue",
                      },
                    ],
                  },
                  [_vm._v(_vm._s(_vm.krSelectedValue))]
                ),
                _c("Icon", {
                  staticClass: "icon-arrow-down",
                  attrs: { "icon-class": "icon-arrow-down" },
                }),
              ],
              1
            ),
          ]),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            _vm._l(_vm.krOption, function (item, index) {
              return _c(
                "el-dropdown-item",
                {
                  key: item.id,
                  staticClass: "confidence_content",
                  nativeOn: {
                    click: function ($event) {
                      return _vm.selectKrvalue(item.id, index)
                    },
                  },
                },
                [_c("span", [_vm._v("KR " + _vm._s(index + 1))])]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }