<template>
  <div :class="`iframeWrap ${editorBorder}`" ref="iframeWrap">
    <iframe
      :src="srcUrl"
      frameborder="0"
      scrolling="auto"
      :width="width"
      :height="bodyHeight"
      :ref="`${editorId}`"
    ></iframe>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: [String, Number],
      default: '100%'
    },
    height: {
      type: [String, Number],
      default: 300
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 5000
    },
    content: {
      default: ''
    },
    maxHeight: { // 是否自适应高度 auto
      default: 300
    },
    editorBorder: {
      default: false
    },
    editorId: {
      default: 'iframeContainer'
    },
    editorType: {
      default: 'default'
    },
    editorBg: {
      default: 'white'
    },
    placeholder: {
      default: '请输入内容'
    }
  },
  data() {
    return {
      srcUrl: '',
      bodyHeight: '',
      loadStatus: false
    }
  },
  computed: {},
  watch: {
    height: {
      handler() {
        this.bodyHeight = this.height || this.bodyHeight
      },
      immediate: true
    }
  },
  created() {
    var that = this
    this.srcUrl = `${window.location.protocol}//${window.location.host}/editor.html?fromType=pc&editorId=${this.editorId}`
    // 监听子页面传过来的值
    window.addEventListener('message', function(e) {
      that.$nextTick(() => {
        if (e.data.funcType && e.data.editorId === that.editorId) {
          console.log('接收子页面传值:', e.data, that.editorId)
          that[e.data.funcType](e.data)
        }
      })
    }, false)
    this.$nextTick(() => {
      var iframe = this.$refs[`${this.editorId}`]
      if (!iframe.addEventListener) {
        iframe.attachEvent('onload', this.iframeLoad)
      }
      iframe.addEventListener('load', this.iframeLoad, true)
    })
  },
  mounted() {
  },
  methods: {
    iframeLoadStatus(e) {
      this.loadStatus = true
      this.iframeLoad(e)
    },
    // iframe加载完成
    iframeLoad(e) {
      console.log('iframeLoad', this.content, e)
      this.$nextTick(() => {
        this.postMessage({
          funcType: 'init',
          content: this.content,
          token: this.token,
          disabled: this.disabled,
          height: this.height,
          maxHeight: this.maxHeight,
          editorType: this.editorType,
          editorId: this.editorId,
          editorBg: this.editorBg,
          placeholder: this.placeholder
        })
      })
    },
    contentRefresh(content) {
      this.postMessage({
        funcType: 'contentRefresh',
        content
      })
    },
    insertContent(data) {
      this.postMessage({
        funcType: 'insertContent',
        content: data
      })
    },
    // 向子页面传值
    postMessage(value) {
      var iframe = this.$refs[`${this.editorId}`]
      console.log('向子页面传值', value)
      value.editorId = this.editorId
      iframe.contentWindow.postMessage(value, '*')
    },
    contentChange(data) {
      this.$nextTick(() => {
        this.$emit('onChange', data)
      })
    },
    contentHeightChange(data) {
      this.$nextTick(() => {
        if (this.maxHeight === 'auto' && this.bodyHeight !== data.bodyHeight) {
          this.bodyHeight = data.bodyHeight
        }
      })
    },
    onInput(data) {
      this.$emit('onInput', data)
    },
    // 查看大图
    lookEditorImg(data) {
      console.log('lookEditorImg', data)
      this.bus(this).$emit('global:Editor:filePreview', data.url, data.type)
    },
    onFocus(data) {
      this.$emit('onFocus', data)
    },
    onBlur(data) {
      this.$emit('onBlur', data)
    }
  }
}
</script>

<style scoped lang="less">
.iframeWrap.true {
  border: 1px solid #eaebf4;
  border-radius: 16px;
  overflow: hidden;
}
</style>
