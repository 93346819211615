<template>
  <el-popover
    ref="taskKrRef"
    v-model="visible"
    trigger="click"
    :disabled="disabled"
    popper-class="task-kr-pop"
    :placement="placement"
    @show="showHandle"
  >
    <div class="kr-list">
      <span class="list-title">{{
        source == 'stopdoing' ? '变更为关键结果KR任务' : '关键结果'
      }}</span>
      <div v-if="!isOkrShortcut" class="filter-div">
        <div class="filter-okr">
          <el-select
            v-if="smallCycleId !== ''"
            v-model="smallCycleId"
            class="select-cycle"
            popper-class="popover-okr-align"
            @visible-change="cycleVisibleChange"
          >
            <div class="select-cycle-wrap" @click.stop>
              <div class="cycle-large">
                <div
                  v-for="(val, key) in okrCycleList.allCycleList"
                  :key="val.id"
                  :label="val.name"
                  :value="val.id"
                  :class="largeCycleId === val.id ? 'select' : ''"
                  @click.stop="selectLargeCycleChange(key)"
                >
                  {{ val.name }}
                </div>
              </div>
              <div class="cycle-small">
                <el-option
                  v-for="(val, key) in okrCycleList.smallCycle"
                  :id="`smallCycleIdOkr${val.id}`"
                  :key="val.id"
                  :label="'周期：' + val.name"
                  :value="val.id"
                  @click.native="selectCycleChange(key)"
                  >{{ val.name }}</el-option
                >
              </div>
            </div>
            <!-- <slot slot="prefix">更改</slot> -->
          </el-select>
        </div>
        <div class="filter-search">
          <el-input
            v-model="searchVal"
            class="search-input"
            placeholder="搜索目标或KR名称"
            prefix-icon="el-icon-search"
            @input="changeSearch"
          />
        </div>
      </div>
      <div
        v-if="okrToKeyList.length > 0 && isShow"
        v-loading="loading"
        class="kr-list-content"
      >
        <div
          v-for="(oItem, oIndex) in okrToKeyList"
          :key="oIndex"
          class="okr-list-div"
        >
          <div class="okr-title-o">
            <div class="okr-title-icon-div">
              <img
                class="okr-title-icon"
                src="@/assets/imgs/okr/objective.png"
              />
            </div>

            <div
              class="title-content"
              v-html="$utils.changeToHtml(oItem.content)"
            ></div>
          </div>
          <div
            v-for="(keyItem, keyIndex) in oItem.keyresultsList"
            :key="keyIndex"
            class="key-list-div"
            @click="checkedKey(keyItem)"
          >
            <Icon icon-class="icon-zhixiangxian" class="line-icon" />
            <div class="key-content">
              <div
                class="content"
                v-html="$utils.changeToHtml(keyItem.title)"
              ></div>
              <Icon
                v-if="selectKrId == keyItem.id"
                icon-class="icon-duigou"
                class="checked-icon"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-else class="no-data">
        <img
          src="@/assets/imgs/common/commentNull.png"
          class="kr-count-empty-img"
        />
        <p class="kr-count-p">暂无内容</p>
      </div>
      <div class="kr-cont-footer">
        <button
          class="cancel-primary common_btn_next_add"
          @click.stop="cancelHidden"
        >
          取 消
        </button>
        <button class="next-primary common_btn_next_add" @click.stop="saveKr">
          完 成
        </button>
      </div>
    </div>
    <div v-if="!customSlot" slot="reference" class="task-reference">
      <el-tooltip
        v-if="selectKr.title"
        :open-delay="300"
        :content="disabled ? '禁止编辑' : '点击编辑'"
        :class="disabled ? 'task-kr-disabled' : ''"
      >
        <div
          class="top-item-right-box"
          :class="closeBoolean ? 'top-item-right-box-txt-blue' : ''"
        >
          <p class="top-item-right-box-txt">
            <span v-if="!selectOkrOrProject" class="top-item-span">KR</span>
            <span v-if="selectOkrOrProject" class="top-item-span">任务</span>
            <span
              v-html="
                $isQw ? selectKr.title : $utils.changeToHtml(selectKrObj.title)
              "
            ></span>
          </p>
          <div
            v-if="type != 'details'"
            class="task-cen-icon"
            @mouseenter="cancelenter"
            @mouseleave="blueLeave"
          >
            <Icon
              icon-class="icon-guanbianniu"
              class="close-icon"
              @click.native.stop="TaskDetailMiaoshuDelete()"
            />
          </div>
        </div>
      </el-tooltip>
      <div v-else class="top-item-right-box-kr">
        <!-- 关联kr 有气泡-->
        <guide-popover
          v-if="!todoAdd"
          title="关联KR"
          pop-key="keyResult"
          inner-text="这里可以将任务和KR关联起来，试试看～"
          placement="top"
        >
          <span slot="guidepop">
            <el-tooltip
              :open-delay="300"
              :content="disabled ? '禁止编辑' : '点击编辑'"
            >
              <span>+选择关联KR</span>
            </el-tooltip>
          </span>
        </guide-popover>
      </div>
    </div>
    <div v-else slot="reference">
      <slot></slot>
    </div>
  </el-popover>
</template>
<script>
import krItem from './krItem.vue'
export default {
  components: {
    krItem
  },
  props: {
    // 来源 为了区分待办
    source: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    // 目前关联的KR信息
    selectKr: {
      type: Object,
      default: () => {
        return {}
      }
    },
    todoAdd: {
      type: Boolean,
      default: false
    },
    customSlot: {
      // 自定义slot内容
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      default: 'bottom-start'
    },
    isOkrShortcut: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      title: '',
      requesting: false,
      selectKrId: '',
      selectKrObj: {},
      closeBoolean: false,
      recentUsePage: 1,
      recentUseCount: 0,
      recentKrList: [], // 最近KR
      recommendedPage: 1,
      recommendedCount: 0,
      recommendKrList: [], // 推荐KR
      visible: false,
      largeCycleId: '',
      largaeCycle: {},
      okrType: '',
      smallCycleId: '',
      searchVal: '',
      okrToKeyList: [],
      isShow: true,
      loading: false
    }
  },
  watch: {
    selectKr: {
      handler(val) {
        console.log(44, val)
        this.selectKrObj = JSON.parse(JSON.stringify(val))
        this.selectKrId = this.selectKrObj.id
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.selectKrObj = JSON.parse(JSON.stringify(this.selectKr))
    this.selectKrId = this.selectKrObj.id || ''
    if (this.isOkrShortcut) {
      this.getOkrToKrList()
    } else {
      this.init()
    }
  },
  methods: {
    getOkrToKrList() {
      const params = {}
      const appendUrl = `${this.selectKrObj.businessId}`
      this.$apis.OKRKRLISTBYOBJID(appendUrl, params).then((res) => {
        if (res.status == 200) {
          this.okrToKeyList = [res.data]
          this.isShow = false
          this.$nextTick(() => {
            this.isShow = true
          })
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    changeSearch(val) {
      this.searchVal = val
      this.getOkrSearchTitleList()
    },
    checkedKey(keyItem) {
      this.selectKrObj = keyItem
      this.selectKrId = keyItem.id
    },
    getOkrSearchTitleList() {
      this.loading = true
      if (!this.smallCycleId) {
        this.loading = false
        return
      }
      const params = {
        cycleInfoId: this.smallCycleId,
        title: this.searchVal
      }
      this.$apis.OKRSEACHTITLELIST(params).then((res) => {
        if (res.status == 200) {
          this.okrToKeyList = res.data
          this.isShow = false
          this.$nextTick(() => {
            this.isShow = true
          })
          this.loading = false
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
          this.loading = false
        }
      })
    },
    init() {
      this.okrCycleList = this.$store.state.common.okrCycleList
      this.okrCycleList = JSON.parse(JSON.stringify(this.okrCycleList))
      if (
        this.okrCycleList.smallCycle &&
        this.okrCycleList.smallCycle.length > 0
      ) {
        this.okrCycleList.smallCycle.forEach((item) => {
          if (item.defSelected === 1) {
            this.selectSmallCycle = item
            this.smallCycleId = item.id
            this.largeCycleId = item.cycleId
            this.largaeCycle = this.okrCycleList.largaeCycle
          }
        })
      }
      this.cyclePower =
        this.$store.state.common.okrCycleList.largaeCycle &&
        this.$store.state.common.okrCycleList.largaeCycle.currentUserPower
      // 20230330-修改重复调用
      // this.getOkrSearchTitleList()
    },
    selectCycleChange(index) {
      this.selectSmallCycle = this.okrCycleList.smallCycle[index]
      this.smallCycleId = this.selectSmallCycle.id
      this.okrCycleList.largaeCycle = this.largaeCycle
      this.getOkrSearchTitleList()
    },
    selectLargeCycleChange(index) {
      this.okrCycleList.smallCycle =
        this.okrCycleList.allCycleList[index].cycleInfoList
      this.largeCycleId = this.okrCycleList.allCycleList[index].id
      this.largaeCycle = this.okrCycleList.allCycleList[index]
      this.cyclePower = this.okrCycleList.allCycleList[index].currentUserPower
      this.okrCycleList = JSON.parse(JSON.stringify(this.okrCycleList))
    },
    showHandle() {
      if (this.visible) {
        if (this.isOkrShortcut) {
          this.getOkrToKrList()
        } else {
          this.getOkrSearchTitleList()
        }
      }
    },
    cancelHidden() {
      this.searchVal = ''
      if (this.$refs.taskKrRef) {
        this.$refs.taskKrRef.doClose()
      }
    },
    // 删除Kr
    TaskDetailMiaoshuDelete() {
      // 清除选择的对象
      this.closeBoolean = false
      this.selectKrId = ''
      this.selectKrObj = {}
      this.clearCheckState()
      this.$emit('save', { objs: null, type: null })
    },
    // 删除kr 图标灰色显示 划过
    cancelenter() {
      this.closeBoolean = true
    },
    // 删除kr 图标蓝色显示 划出
    blueLeave() {
      this.closeBoolean = false
    },
    clearCheckState() {
      this.recentKrList.forEach((itemX) => {
        itemX.checked = false
      })
      this.recommendKrList.forEach((itemX) => {
        itemX.checked = false
      })
    },
    // 保存选择KR
    saveKr() {
      const _this = this
      if (!_this.selectKrObj.id) {
        _this.title = ''
      } else {
        _this.title = _this.selectKrObj.title
      }
      if (_this.selectKrObj.id) {
        _this.cancelHidden()
      } else {
        _this.$message.warn('请选择KR！')
      }
      let obj = {
        cycle_id: null,
        objId: null,
        keyId: null,
        keyTitle: null,
        selectBooleans: 1,
        projectId: null,
        type: 1,
        checked: false
      }
      if (_this.selectKrId) {
        obj = {
          cycle_id: _this.smallCycleId,
          objId: _this.selectKrObj.objId,
          keyId: _this.selectKrObj.id,
          keyTitle: _this.title,
          selectBooleans: 1,
          projectId: null,
          type: 1,
          checked: true
        }
      }
      console.log(23232, obj)
      _this.$emit('save', obj)
    }
  }
}
</script>
<style lang="less" scoped>
.select-cycle-wrap {
  width: 400px;
  .flex-l();
  height: 240px;
  align-items: flex-start;
  padding: 6px 0px;
  .cycle-large,
  .cycle-small {
    max-height: 240px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .cycle-small {
    flex: 1;
  }
  .cycle-large {
    div {
      height: 40px;
      line-height: 40px;
      margin: 0px 8px;
      min-width: 100px;
      padding: 0px 16px;
      max-width: 160px;
      .text-n(1);
      cursor: pointer;
      &:hover {
        color: #3a70ff;
        background: #f5f6fa;
        border-radius: 8px;
      }
    }
    div.select {
      color: #3a70ff;
      background: #f5f6fa;
      border-radius: 8px;
    }
  }
  .cycle-small {
  }
}
.task-kr-disabled {
  cursor: not-allowed !important;
}
.task-reference {
  margin-bottom: 6px;
  margin-top: 6px;
  .add-kr-lf {
    width: 32px;
  }
  .add-kr-content-txt {
    line-height: 36px;
    color: #333;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:before {
      content: '';
      display: block;
    }
  }
  .top-item-right-box-kr {
    color: rgb(58, 120, 246);
    line-height: 16px;
    cursor: pointer;
    .aub-add-icon {
      font-size: 16px;
      display: inline;
    }
    &:hover {
      color: #3296fa;
    }
  }
  .top-item-right-box {
    display: inline-block;
    padding: 8px 16px 8px 10px;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
    border: 1px solid #fff;
    margin-top: 4px;
    &:hover {
      background: #f5f6fa;
      color: #005eff;
      .task-cen-icon {
        display: block;
      }
    }
    p.top-item-right-box-txt {
      max-width: 530px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 16px;
      &:before {
        content: '';
        display: block;
      }
      .top-item-span {
        font-size: 12px;
        background: #3296fa;
        width: 42px;
        height: 20px;
        line-height: 20px;
        display: inline-block;
        text-align: center;
        color: #fff;
        border-radius: 12px;
        margin-right: 9px;
      }
    }
    .task-cen-icon {
      display: none;
      cursor: pointer;
      position: absolute;
      right: -5px;
      top: -5px;
      width: 18px;
      height: 18px;
      border-radius: 100% 100%;
      background: #fff;
      .close-icon {
        font-size: 18px;
        color: #a0a3bd;
        &:hover {
          color: #ff9900;
        }
      }
    }
  }
  .top-item-right-box-txt-blue {
    border: 1px solid #ff9900 !important;
  }
}
</style>
<style lang="scss" scoped>
::v-deep {
  .select-cycle.el-select {
    .el-input .el-input__inner,
    .el-input__inner:hover {
      border: none !important;
      padding-left: 0 !important;
    }
  }
  .search-input {
    .el-input__inner {
      background: #f5f6fa;
    }
  }
}
.task-kr-pop {
  width: 700px;
  min-height: 300px;
  padding: 0;
  .kr-list {
    .list-title {
      display: inline-block;
      color: #444558;
      font-size: 20px;
      font-weight: 600;
      line-height: 42px;
      // border-bottom: 2px solid #005eff;
      margin-left: 12px;
    }
    .filter-div {
      margin-top: 8px;
      margin-bottom: 16px;
      padding: 0 12px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .filter-okr {
      }
    }
    .kr-list-content {
      width: 100%;
      min-height: 240px;
      max-height: 240px;
      border-top: 1px solid rgba(17, 17, 17, 0.08);
      overflow-y: auto;
      .okr-list-div {
        margin-top: 8px;
        margin-bottom: 8px;
        .okr-title-o {
          display: flex;
          flex-direction: row;
          .okr-title-icon-div {
            background: #fff;
            height: 46px;
            z-index: 99;
          }
          .okr-title-icon {
            width: 36px;
            height: 36px;
            flex-shrink: 0;
            margin-right: 16px;
            z-index: 99;
          }
          .title-content {
            font-size: 16px;
            font-weight: bold;
            color: #14142b;
            line-height: 26px;
            display: flex;
            flex-direction: row;
            align-items: center;
            border-left: 1px #c4c4c4 solid;
            margin-left: -32px;
            padding-left: 37px;
            margin-top: 4px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
          }
        }
        .key-list-div {
          display: flex;
          border-left: 1px #c4c4c4 solid;
          margin-left: 20px;
          align-items: flex-start;
          .line-icon {
            color: #c4c4c4;
            font-size: 28px;
            margin-right: 4px;
            margin-top: -2px;
            margin-left: -2px;
          }
          .key-content {
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px 8px;
            border-radius: 10px;
            // height: 36px;
            line-height: 36px;
            .content {
              flex: 1;
              cursor: pointer;
            }
            .checked-icon {
              font-size: 20px;
              color: #3a78f6;
              margin-right: 12px;
            }
            &:hover {
              background: #e7effe;
            }
          }
        }
      }
      .kr-type-list {
        .type-title {
          margin: 12px 0 4px 16px;
          font-size: 14px;
          font-weight: 700;
          color: #444558;
          line-height: 20px;
        }
      }
      .okr-list-div .key-list-div:nth-last-child(1) {
        border-left: none;
        .line-icon {
          margin-top: -4px;
          margin-left: -1px;
        }
      }
    }

    .no-data {
      width: 100%;
      height: 240px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img.kr-count-empty-img {
        width: 110px;
        align-items: center;
        align-self: center;
      }
      p.kr-count-p {
        line-height: 36px;
        text-align: center;
        font-size: 14px;
      }
    }
    .kr-cont-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 12px 20px;
      border-top: 1px solid rgba(17, 17, 17, 0.08);
      .cancel-primary {
        height: 32px;
        width: 96px;
        border-radius: 12px;
        margin-right: 8px;
        background: #fff;
        color: #3a78f6;
        border: 1px rgba(58, 120, 246, 1) solid;
        &:hover {
          background: #f3f7fe !important;
          color: #548af7 !important;
        }
      }
      .next-primary {
        height: 32px;
        width: 96px;
        border-radius: 12px;
        background: rgba(58, 120, 246, 1) !important;
        border: 1px rgba(58, 120, 246, 1) solid;
        &:hover {
          background: rgba(58, 120, 246, 0.87) !important;
          border: 1px rgba(58, 120, 246, 0.87) solid;
        }
      }
    }
  }
}
</style>
