<template>
  <!-- 企信迁移后新增 -->
  <div class="trend-single-box">
    <div class="trend-content">
      <div class="avatar-img-box">
        <el-image
          v-if="onlyComment || trendInfo.type == '3'"
          class="avatar-img"
          :src="trendInfo.userAvatar"
        />
        <Icon v-if="isAddIcon" icon-class="icon-add-round" class="trend-opera-icon" />
        <Icon v-if="isEditIcon" icon-class="icon-edit-simple" class="trend-opera-icon" />
        <Icon v-if="isDelIcon" icon-class="icon-delete-simple" class="trend-opera-icon" />
        <Icon v-if="isLikeIcon" icon-class="icon-like-mini" class="trend-opera-icon" />
        <Icon v-if="isZrIcon" icon-class="icon-zhuanrang" class="trend-opera-icon" />
      </div>

      <div class="single-trend">
        <div class="trend-top-info">
          <div class="trend-item-name">
            <div class="user-name">
              <wx-open-data :id="trendInfo.userName" :nameData="{name:trendInfo.userName}" />
            </div>
            <div v-if="trendInfo.type == 3 || trendInfo.type == 0" class="user-click-wrap">
              <div
                v-if="
                  (trendInfo.fileCommon || '') !== '' && trendInfo.fileCommon !== null
                "
                class="download-click"
                @click="fileDownload(trendInfo.fileCommon)"
              >下载附件</div>
              <div
                v-if="userInfo.id == trendInfo.createUserid"
                class="comment-delete"
                @click="deleteComment(trendInfo.id)"
              >删除</div>
            </div>
          </div>
          <div class="trend-item-time">{{ $utils.globalTime(trendInfo.createDate) }}</div>
        </div>
        <div class="trend-main-txt" v-html="$utils.changeToHtml(trendInfo.comment)"></div>
        <dynamic-file-list
          v-if="trendInfo.fileCommon !== '' && trendInfo.fileCommon !== null"
          :oss-file-entity-list="trendInfo.fileCommon"
        />
      </div>
    </div>
    <!-- 回复 -->
    <div v-if="trendInfo.children && trendInfo.children.length > 0">
      <div v-for="item in trendInfo.children" :key="item.id" class="child-comment-box">
        <div class="avatar-img-box">
          <!-- <el-image
            v-show="onlyComment || trendInfo.type == '3'"
            class="avatar-img"
            :src="item.userAvatar"
          ></el-image>-->
        </div>
        <div class="child-comment">
          <div class="child-top-info">
            <div class="child-left">
              <div class="child-item-name">
                <wx-open-data :id="item.userName" :nameData="{name:item.userName}" />
              </div>
              <div
                v-if="userInfo.id == item.createUserid"
                class="comment-delete"
                @click="deleteComment(item.id)"
              >删除</div>
            </div>

            <div class="child-item-time">{{ $utils.globalTime(item.createDate) }}</div>
          </div>
          <div class="child-main-txt" v-html="$utils.changeToHtml(item.comment)"></div>
          <dynamic-file-list
            v-if="item.fileCommon !== '' && item.fileCommon !== null"
            :oss-file-entity-list="item.fileCommon"
          />
        </div>
      </div>
    </div>
    <div v-show="trendInfo.type == '3'" class="reply-box">
      <div v-if="!replyView" class="trend-btn-reply" @click.stop="showReplyBox">回复...</div>
      <div v-if="replyView" class="trend-input-reply" @click.stop>
        <comment-box
          ref="commentBox"
          :file-upload-disabled="true"
          placeholder="输入回复的内容"
          :blur-release="true"
          :at-able="false"
          height="auto"
          :lineFeed="true"
          @release="addReplayComment"
          @hideComment="hideComment"
        />
      </div>
    </div>
  </div>
</template>
<script>
import commentBox from '@/components/common/CommentBox.vue' // 评论框
import DynamicFileList from '@/components/global/FileUpload/DynamicFileList' // 评论动态展示
export default {
  components: {
    commentBox,
    DynamicFileList
  },
  props: {
    trendInfo: {
      type: Object,
      default: () => {
        return {
          type: '', // 动态类型
          originatorAvatar: '' // 头像
        }
      }
    },
    onlyComment: {
      type: Boolean,
      default: false
    },
    objId: {
      // 目标id
      type: Number,
      require: true
    }
  },
  data() {
    return {
      addTypes: [4, 6, 9, 16, 17], // 使用新增icon
      editTypes: [5, 7, 10, 12, 13, 15], // 使用修改icon
      delTypes: [8, 11, 14], // 使用删除icon
      likeTypes: [1, 2], // 使用点赞icon
      zrTypes: [21], // 转让icon
      isAddIcon: false,
      isEditIcon: false,
      isDelIcon: false,
      isLikeIcon: false,
      isZrIcon: false,
      replyView: false // 是否展示回复框
    }
  },
  computed: {},
  mounted() {
    if (this.addTypes.includes(this.trendInfo.type)) {
      this.isAddIcon = true
    }
    if (this.editTypes.includes(this.trendInfo.type)) {
      this.isEditIcon = true
    }
    if (this.delTypes.includes(this.trendInfo.type)) {
      this.isDelIcon = true
    }
    if (this.likeTypes.includes(this.trendInfo.type)) {
      this.isLikeIcon = true
    }
    if (this.zrTypes.includes(this.trendInfo.type)) {
      this.isZrIcon = true
    }
    document.body &&
      document.body.addEventListener('click', (e) => {
        if (this.replyView) {
          this.hideComment()
        }
      })
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      var fileCommon =
        (this.trendInfo.fileCommon || '') !== '' &&
        this.trendInfo.fileCommon !== 'null'
          ? JSON.parse(this.trendInfo.fileCommon)
          : []
      var map = {}
      if (fileCommon.length > 0) {
        fileCommon.forEach(function (item) {
          map[item.name] = map[item.name] == null ? 0 : map[item.name] + 1
          if (map[item.name]) {
            var reg = /\.\w+$/
            var filename = item.name.replace(reg, '')
            var filetype = item.name.substring(item.name.lastIndexOf('.') + 1)
            item.name = filename += map[item.name] + '.' + filetype
          }
        })
      }
      if (fileCommon.length > 0) {
        this.trendInfo.fileCommon = fileCommon
        this.trendInfo = JSON.parse(JSON.stringify(this.trendInfo))
      }
    },
    // 文件下载
    fileDownload(fileList) {
      const link = document.createElement('a')
      let dataBean
      if (typeof fileList === 'string') {
        dataBean = JSON.parse(fileList)
      } else {
        dataBean = fileList || []
      }
      this.$apis
        .FILEDOWNLOAD(dataBean)
        .then((res) => {
          if (res.data === 'downErr') {
            this.$message({
              type: 'error',
              msg: '下载失败!'
            })
          } else {
            const blob = new Blob([res.data], {
              type: 'application/zip'
            })
            const objectUrl = URL.createObjectURL(blob) // 创建URL
            link.href = objectUrl
            link.download = res.location.split(',')[0] // 自定义文件名
            link.click() // 下载文件
            URL.revokeObjectURL(objectUrl) // 释放内存
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 评论删除
    async deleteComment(id) {
      var url = this.$apis.OKRDELDYNAMICCOMMENT
      if (this.trendInfo.type === 0) {
        url = this.$apis.KRPROGRESSCOMMENTDELETE
      }
      url(id).then((res) => {
        if (res.status === 200) {
          this.$message({
            msg: '评论删除成功',
            type: 'success'
          })
          this.$emit('refreshTrend')
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 显示回复框
    showReplyBox() {
      this.replyView = true
    },
    // 发布回复
    async addReplayComment(comment, userId, fileList) {
      if (comment === '' || (comment.trim() === '' && fileList.length === 0)) {
        this.hideComment()
        return
      }

      const dataBean = {
        objId: this.objId,
        parentId: this.trendInfo.id,
        type: 0, // 0：文本 1图片 2附件 3:回复
        objComment: comment,
        collaboratorStr: userId,
        ossFileEntityList: fileList.length > 0 ? fileList : null
      }
      this.$apis
        .OKRADDDYNAMICREPLY(dataBean)
        .then((rest) => {
          // this.$message({
          //   msg: '发布成功',
          //   type: 'success'
          // })
          this.$emit('refreshTrend')
          this.hideComment()
          this.bus.$emit('clearFile')
          this.bus(this).$emit('inquireStatus', {
            type: 1,
            name: 'OKR'
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 收起回复框
    hideComment() {
      this.replyView = false
      this.$refs.commentBox.clearComment()
    }
  }
}
</script>
<style lang="scss" scoped>
.trend-content {
  display: flex;
  width: 100%;

  .avatar-img-box {
    position: relative;
    width: 28px;
    margin-right: 16px;

    .avatar-img {
      border-radius: 50%;
      position: absolute;
      right: 0;
      height: 28px;
      width: 28px;
    }

    .trend-opera-icon {
      font-size: 20px;
      color: #a0a3bd;
      height: 20px;
      width: 20px;
      margin-top: 4px;
    }
  }

  .single-trend {
    flex: 1;
    max-width: calc(100% - 45px);
    .trend-top-info {
      display: flex;
      justify-content: space-between;
      color: #a0a3bd;
      line-height: 28px;

      .trend-item-name {
        font-size: 14px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        .user-name {
          max-width: 150px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .user-click-wrap {
          min-width: 120px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: #a0a3bd;
          line-height: 28px;
          font-size: 14px;
          cursor: pointer;
          .download-click {
            margin-left: 14px;
          }
          .comment-delete {
            margin-left: 14px;
          }
          div {
            &:hover {
              color: #3a78f6;
            }
          }
        }
      }

      .trend-item-time {
        font-size: 12px;
      }
    }

    .trend-main-txt {
      font-size: 14px;
      color: #6e7491;
      line-height: 24px;
      word-break: break-word;
    }

    .reply-box {
      margin: 4px 0 8px;

      .trend-btn-reply {
        color: #3a78f6;
        cursor: pointer;
        font-weight: 500;
        display: none;
      }

      .trend-input-reply {
        width: 100%;
        height: 130px;
        border-radius: 8px;
        background: #fff;
        border: 1px solid #3a78f6;
        box-shadow: 0px -3px 10px 0px rgba(50, 150, 250, 0.14) inset;
      }
    }
  }
}
.trend-single-box {
  border-radius: 8px;
  padding: 4px 16px 4px;
  width: 100%;
  box-sizing: border-box;
  &:hover {
    background: #fff;
    .reply-box {
      .trend-btn-reply {
        display: block;
      }
    }
  }
}

.child-comment-box {
  display: flex;
  .avatar-img-box {
    position: relative;
    width: 28px;
    margin-right: 16px;

    .avatar-img {
      border-radius: 50%;
      position: absolute;
      right: 0;
      height: 28px;
      width: 28px;
    }
  }

  .child-comment {
    flex: 1;
    .child-top-info {
      display: flex;
      justify-content: space-between;
      color: #a0a3bd;
      line-height: 28px;
      .child-left {
        display: flex;
        justify-content: flex-start;
        .comment-delete {
          cursor: pointer;
          margin-left: 14px;
          &:hover {
            color: #3a78f6;
          }
        }
      }
      .child-item-name {
        font-size: 14px;
      }

      .child-item-time {
        font-size: 12px;
      }
    }

    .child-main-txt {
      font-size: 14px;
      color: #6e7491;
      line-height: 24px;
      word-break: break-word;
    }
  }
}

.reply-box {
  margin: 4px 0 8px 44px;
  min-height: 16px;
  .trend-btn-reply {
    color: #3a78f6;
    cursor: pointer;
    font-weight: 500;
    display: none;
  }

  .trend-input-reply {
    width: 100%;
    border-radius: 8px;
    background: #fff;
    border: 1px solid #3a78f6;
    box-shadow: 0px -3px 10px 0px rgba(50, 150, 250, 0.14) inset;
  }
}
</style>
