const versionNumber = 'v1' // 版本
// var baseUrlMock =
//   'https://mockapi.eolink.com/MJ7BP1Pc84c4bfef045f1832908183de2e8534168355f62/{{okrt-performance-server}}'
// var baseUrlMock2 =
//   'https://mockapi.eolink.com/MJ7BP1Pc84c4bfef045f1832908183de2e8534168355f62'
var baseUrl = '/api/performance'
var baseUrlMock = '/api/performance'
var baseUrlMock2 = '/api/performance'
export default [
  // 设置相关
  // 查询字段列表
  {
    templateFieldListV2: `${baseUrl}/${versionNumber}/template/field/list`
  },
  // 查询字段详情
  {
    templateFieldDetailV2: `${baseUrl}/${versionNumber}/template/field/`
  },
  // 字段删除
  {
    templateFieldDeleteV2: `${baseUrl}/${versionNumber}/template/field/delete`
  },
  // 字段添加
  {
    templateFieldSaveV2: `${baseUrl}/${versionNumber}/template/field/save`
  },
  // 字段修改
  {
    templateFieldUpdateV2: `${baseUrl}/${versionNumber}/template/field/update`
  },
  // 查询字段选项值
  {
    templateFieldValuesListV2: `${baseUrl}/${versionNumber}/template/field/values/list`
  },
  // 添加字段选项值
  {
    templateFieldValuesSaveV2: `${baseUrl}/${versionNumber}/template/field/values/save`
  },
  // 查询指标分类列表
  {
    templateIndexGroupListV2: `${baseUrl}/${versionNumber}/template/index/group/list`
  },
  // 添加指标分类
  {
    templateIndexGroupSaveV2: `${baseUrl}/${versionNumber}/template/index/group/save`
  },
  // 修改指标分类
  {
    templateIndexGroupUpdateV2: `${baseUrl}/${versionNumber}/template/index/group/update`
  },
  // 删除指标分类
  {
    templateIndexGroupDeleteV2: `${baseUrl}/${versionNumber}/template/index/group/delete`
  },
  // 统计每个流程阶段的绩效数量
  {
    processTypeNumV2: `${baseUrl}/${versionNumber}/performance/todo/examineProcessStep/num`
  },
  // 我的待办列表
  {
    myKpiListV2: `${baseUrl}/${versionNumber}/performance/todo/list`
  },
  // 绩效流程信息
  {
    kpiProcessInfoV2: `${baseUrl}/${versionNumber}/performance/info`
  },
  // 添加指标
  {
    templateIndexSaveV2: `${baseUrl}/${versionNumber}/template/index/save`
  },
  // 查询指标/维度 默认添加的字段
  {
    templateIndexSaveDefV2: `${baseUrl}/${versionNumber}/template/field/values/defaultField`
  },
  // 修改指标
  {
    templateIndexUpdateV2: `${baseUrl}/${versionNumber}/template/index/update`
  },
  // 查询指标详情
  {
    templateIndexDetailV2: `${baseUrl}/${versionNumber}/template/index/`
  },
  // 分页查询指标列表
  {
    templateIndexListV2: `${baseUrl}/${versionNumber}/template/index/list`
  },
  // 删除指标
  {
    templateIndexDeleteV2: `${baseUrl}/${versionNumber}/template/index/delete`
  },
  // 导入指标-具体业务再定-目前看无法导入-因为为自定义字段
  {
    templateIndexImportV2: `${baseUrl}/${versionNumber}/template/index/import`
  },
  // 导出指标
  {
    templateIndexExportV2: `${baseUrl}/${versionNumber}/template/index/export`
  },
  // 批量删除指标
  {
    templateIndexDeleteBatchV2: `${baseUrl}/${versionNumber}/template/index/delete/batch`
  },
  // 根据指标id导出指标
  {
    templateIndexExportSelectV2: `${baseUrl}/${versionNumber}/template/index/export/select`
  },
  // 分页查询指标计算公式
  {
    templateIndexComputeListV2: `${baseUrl}/${versionNumber}/template/index/compute/list`
  },
  // 添加指标公式
  {
    templateIndexComputeSaveV2: `${baseUrl}/${versionNumber}/template/index/compute/save`
  },
  // 修改指标公式
  {
    templateIndexComputeUpdateV2: `${baseUrl}/${versionNumber}/template/index/compute/update`
  },
  // 查询计算公式详情
  {
    templateIndexComputeDetailV2: `${baseUrl}/${versionNumber}/template/index/compute/`
  },
  // 删除计算公式
  {
    templateIndexComputeDeleteV2: `${baseUrl}/${versionNumber}/template/index/compute/delete`
  },
  // 添加考核等级
  {
    gradeSaveV2: `${baseUrl}/${versionNumber}/grade/save`
  },
  // 查询考核等级详情
  {
    gradeDetailV2: `${baseUrl}/${versionNumber}/grade/`
  },
  // 修改考核等级
  {
    gradeUpdateV2: `${baseUrl}/${versionNumber}/grade/update`
  },
  // 删除考核等级
  {
    gradeDeleteV2: `${baseUrl}/${versionNumber}/grade/delete`
  },
  // 分页查询考核
  {
    gradeListV2: `${baseUrl}/${versionNumber}/grade/list`
  },
  // 添加考评关系
  {
    evaluationGroupSaveV2: `${baseUrl}/${versionNumber}/evaluation/group/save`
  },
  // 查询考评关系
  {
    evaluationGroupDetailV2: `${baseUrl}/${versionNumber}/evaluation/group/`
  },
  // 修改考评关系
  {
    evaluationGroupUpdateV2: `${baseUrl}/${versionNumber}/evaluation/group/update`
  },
  // 删除考评关系
  {
    evaluationGroupDeleteV2: `${baseUrl}/${versionNumber}/evaluation/group/delete`
  },
  // 分页查询考评关系
  {
    evaluationGroupListV2: `${baseUrl}/${versionNumber}/evaluation/group/list`
  },
  // 添加模板分类
  {
    templateGroupSaveV2: `${baseUrl}/${versionNumber}/template/group/save`
  },
  // 修改模板分类
  {
    templateGroupUpdateV2: `${baseUrl}/${versionNumber}/template/group/update`
  },
  // 删除模板分类
  {
    templateGroupDeleteV2: `${baseUrl}/${versionNumber}/template/group/delete`
  },
  // 查询模板分类
  {
    templateGroupListV2: `${baseUrl}/${versionNumber}/template/group/list`
  },
  // 添加模板
  {
    templateSaveV2: `${baseUrl}/${versionNumber}/template/save`
  },
  // 修改模板
  {
    templateUpdateV2: `${baseUrl}/${versionNumber}/template/update`
  },
  // 删除模板
  {
    templateDeleteV2: `${baseUrl}/${versionNumber}/template/delete`
  },
  // 查询模板列表
  {
    templateListV2: `${baseUrl}/${versionNumber}/template/list`
  },
  // 查询模板
  {
    templateDetailV2: `${baseUrl}/${versionNumber}/template/`
  },
  // 考核计划相关
  // 考核计划--基本信息--查询
  {
    examineInfoGetV2: `${baseUrl}/${versionNumber}/examine/info/get`
  },
  // 考核计划--基本信息--添加/修改
  {
    examineInfoSaveV2: `${baseUrl}/${versionNumber}/examine/info/save`
  },
  // 考核计划--考核人员与模版--查询
  {
    examineTemplateV2: `${baseUrl}/${versionNumber}/examine/template/`
  },
  // 考核计划--考核流程-查询
  {
    examineProcessInfoV2: `${baseUrl}/${versionNumber}/examine/process/list`
  },
  // 考核计划--考核流程-保存/修改
  {
    examineProcessV2: `${baseUrl}/${versionNumber}/examine/process/update`
  },
  // 考核计划--考核流程-指标制定-查询
  {
    examineProcessIndexMakeV2: `${baseUrl}/${versionNumber}/examine/process/index/make/`
  },
  // 考核计划--考核流程-指标确认-查询
  {
    examineProcessIndexConfirmV2: `${baseUrl}/${versionNumber}/examine/process/index/confirm/`
  },
  // 考核计划--考核流程-完成值-查询
  {
    examineProcessFieldEnterV2: `${baseUrl}/${versionNumber}/examine/process/field/enter/`
  },
  // 考核计划--考核流程-员工自评-查询
  {
    examineProcessSelfV2: `${baseUrl}/${versionNumber}/examine/process/self/`
  },
  // 考核计划--考核流程-成员评定-查询
  {
    examineProcessMemberV2: `${baseUrl}/${versionNumber}/examine/process/member/`
  },
  // 考核计划--考核流程-结果校准-查询
  {
    examineProcessCalibrationV2: `${baseUrl}/${versionNumber}/examine/process/calibration/`
  },
  // 考核计划--考核流程-绩效沟通-查询
  {
    examineProcessCommunicationV2: `${baseUrl}/${versionNumber}/examine/process/communication/`
  },
  // 考核计划--考核流程-员工结果确认-查询
  {
    examineProcessSelfConfirmV2: `${baseUrl}/${versionNumber}/examine/process/self/confirm/`
  },
  // 考核计划--考核流程-最终结果确认-查询
  {
    examineProcessLastConfirmV2: `${baseUrl}/${versionNumber}/examine/process/last/confirm/`
  },
  // 绩效--考核计划--开启考核
  {
    examineStartV2: `${baseUrl}/${versionNumber}/examine/start`
  },
  // 绩效--考核计划--归档
  {
    examineArchivingV2: `${baseUrl}/${versionNumber}/examine/archiving`
  },
  // 绩效--考核计划--删除
  {
    examineDelV2: `${baseUrl}/${versionNumber}/examine/del`
  },
  // 绩效--考核计划--导出
  // {
  //   examineExportV2: `${baseUrl}/${versionNumber}/examine/export`
  // },
  {
    examineExportV2: `/api/analysis/${versionNumber}/report/performance/examine/export/`
  },
  // 绩效--考核计划--复制
  {
    examineCopyV2: `${baseUrl}/${versionNumber}/examine/copyExamine`
  },
  // 绩效--考核计划--批量终止/单个终止
  {
    examineCloseV2: `${baseUrl}/${versionNumber}/examine/close`
  },
  // 绩效--考核计划--批量/单个提醒
  {
    examineRemindV2: `${baseUrl}/${versionNumber}/performance/process/remind`
  },
  // 考核计划--考核列表数量
  {
    examineListCountV2: `${baseUrl}/${versionNumber}/examine/list/count`
  },
  // 考核计划--考核列表查询
  {
    examineListV2: `${baseUrl}/${versionNumber}/examine/list`
  },
  // 绩效--开启流程(完成值/自评/成员评/校准/沟通)
  {
    performanceProcessStartV2: `${baseUrl}/${versionNumber}/performance/start/process`
  },
  // 1绩效--驳回
  {
    performanceRejectV2: `${baseUrl}/${versionNumber}/performance/examine/process/step/reject`
  },
  // 考核计划--考核详情查询
  {
    examineInfoV2: `${baseUrl}/${versionNumber}/examine/info`
  },
  // 绩效--查询考核整体结果校准*
  {
    performanceGradeDistributionListV2: `${baseUrl}/${versionNumber}/performance/grade/distribution/list`
  },
  // 绩效--考核整体结果校准--查询不参与人员
  {
    performanceAdjustUserV2: `${baseUrl}/${versionNumber}/performance/adjust/user/list`
  },
  // 1绩效--考核整体结果校准--添加/修改不参与人员
  {
    performanceAdjustUserUpdateV2: `${baseUrl}/${versionNumber}/performance/adjust/user/addOrUpdate`
  },
  // 1绩效--考核整体结果校准--调整分布
  {
    performanceGradeAdjustV2: `${baseUrl}/${versionNumber}/performance/grade/adjust`
  },
  // 我的部门我的绩效
  {
    performanceListV2: `${baseUrl}/${versionNumber}/performance/list`
  },
  // 绩效--考核整体结果校准--发放
  {
    performanceCalibrationStartV2: `${baseUrl}/${versionNumber}/performance/result/release`
  },
  // 绩效--考核计划--查询绩效模版组
  {
    examineTemplateListV2: `${baseUrl}/${versionNumber}/examine/template/list`
  },
  // 绩效--考核计划--添加新考核人
  {
    examineTemplateUserAddV2: `${baseUrl}/${versionNumber}/examine/template/user/add`
  },
  // 1绩效--考核计划--调整处理人-查询
  {
    performanceEvaluationUesrV2: `${baseUrl}/${versionNumber}/performance/evaluation/uesr`
  },
  // 绩效--考核计划--驳回处理人-查询
  {
    performanceProcessStepMemberRejectV2: `${baseUrl}/${versionNumber}/performance/process/step/member/reject`
  },
  // 查看绩效考核动态详情
  {
    performanceCommentListV2: `${baseUrl}/${versionNumber}/performance/comment/list`
  },
  // 绩效指标制定和指标确认 提交SUBMITKPIDEAFTCONFIRM
  {
    submitKpiDeaftConfirm: `${baseUrl}/${versionNumber}/performance/process/save/examineProcessIndexMakeEntity`
  },
  // 绩效-驳回流程
  {
    performanceRejectProcessV2: `${baseUrl}/${versionNumber}/performance/process/step/reject`
  },
  // 绩效-保存/提交员工评分
  {
    submitPerformanceScore: `${baseUrl}/${versionNumber}/performance/save/performanceScore`
  },
  // 绩效-获取等级列表
  {
    kpiGradeInfo: `${baseUrl}/v1/grade/:gradeId`
  },
  // 绩效-成员评定添加考评人
  {
    kpiPerformanceUserList: `${baseUrl}/v1/performance/processMember/judges`
  },
  // 绩效-保存考评人
  {
    saveEvaluationUsers: `${baseUrl}/${versionNumber}/performance/processMember/judges/save`
  },
  // 查询字段列表分页
  {
    templateFieldPageListV2: `${baseUrl}/${versionNumber}/template/field/pageList`
  },
  // 添加维度
  {
    templateDimensionSaveV2: `${baseUrl}/${versionNumber}/template/dimension/save`
  },
  // 修改维度
  {
    templateDimensionUpdateV2: `${baseUrl}/${versionNumber}/template/dimension/update`
  },
  // 查询维度详情
  {
    templateDimensionDetailV2: `${baseUrl}/${versionNumber}/template/dimension/`
  },
  // 绩效-提交结果校准
  {
    submitResultAdjust: `${baseUrl}/${versionNumber}/performance/save/examineProcessCalibration`
  },
  // 绩效-提交绩效沟通
  {
    submitCommunication: `${baseUrl}/${versionNumber}/performance/save/examineProcessCommunication`
  },
  // 绩效-转交其他人
  {
    submitTransfer: `${baseUrl}/${versionNumber}/performance/process/step/transfer`
  },
  // 绩效-跳过当前绩效流程
  {
    submitSkipProcess: `${baseUrl}/${versionNumber}/performance/process/step/skip`
  },
  // 查询examineId 下的模版中所有的字段
  {
    selectExamineFieldV2: `${baseUrl}/${versionNumber}/examine/process/field/enter/selectExamineField`
  },
  // 查询考核计划下等级列表
  {
    gradeExamineListV2: `${baseUrl}/${versionNumber}/grade/examine/list`
  },
  // 批量添加行业指标到 -- 指标库
  {
    addTemplateIndexBatchV2: `${baseUrl}/${versionNumber}/template/industry/addTemplateIndexBatch`
  },
  // 查询行业指标详情
  {
    templateIndustryDetailV2: `${baseUrl}/${versionNumber}/template/industry/`
  },
  // 行业指标库
  {
    industryPagesListV2: `${baseUrl}/${versionNumber}/template/industry/industryPagesList`
  },
  // 考核计划--考核计划列表查询
  {
    performanceDetailsV2: `${baseUrl}/${versionNumber}/performance/performanceDetails`
  },
  // 查询考核计划-所有被考核人列表
  {
    examineUserListV2: `${baseUrl}/${versionNumber}/examine/userList`
  },
  // 进度追踪-详情-分页
  {
    trackingInfoPage: `/api/analysis/${versionNumber}/report/performance/progress/tracking/info/page/`
  },
  // 指标分析-查询指标
  {
    analysisQueryIndex: `/api/analysis/${versionNumber}/report/performance/index/analysis/queryIndex/`
  },
  // 考核报表-根据考核计划查询考核组
  {
    examineGroupList: `/api/analysis/${versionNumber}/report/performance/examine/group/list/`
  },
  // 考核报表-查询所有可筛选的考核计划
  {
    performanceExamineList: `/api/analysis/${versionNumber}/report/performance/examine/list/`
  },
  // 结果报表-查询考核等级对应人员详情(分页)
  {
    resultInfoGradePage: `/api/analysis/${versionNumber}/report/performance/result/info/grade/page/`
  },
  // 指标分析-导出下载数据
  {
    indexAnalysisDownload: `/api/analysis/${versionNumber}/report/performance/index/analysis/download/`
  },
  // 指标分析-查询数据列表(分页)
  {
    indexAnalysisInfoPage: `/api/analysis/${versionNumber}/report/performance/index/analysis/info/page/`
  },
  // 指标分析-查询详情
  {
    indexAnalysisInfo: `/api/analysis/${versionNumber}/report/performance/index/analysis/info/`
  },
  // 【待定】员工档案-导出下载数据
  {
    memberArchivesDownload: `/api/analysis/${versionNumber}/report/performance/member/archives/download/`
  },
  // 员工档案-查询详情(分页)
  {
    memberArchivesPage: `/api/analysis/${versionNumber}/report/performance/member/archives/page/`
  },
  // 【待定】结果报表-导出下载数据
  {
    resultInfoDownload: `/api/analysis/${versionNumber}/report/performance/result/info/download/`
  },
  // 结果报表-查询数据列表分页
  {
    resultInfoPage: `/api/analysis/${versionNumber}/report/performance/result/info/page/`
  },
  // 结果报表-按部门等级查询详情
  {
    resultDepartmentInfo: `/api/analysis/${versionNumber}/report/performance/result/department/info/`
  },
  // 结果报表-按等级查询详情
  {
    resultGradeInfo: `/api/analysis/${versionNumber}/report/performance/result/grade/info/`
  },
  // 绩效 - 考核结果确认
  {
    submitSelfConfirmStatus: `${baseUrl}/${versionNumber}/performance/selfConfirmStatus`
  },
  // 绩效 - 最终结果确认
  {
    submitLastConfirmStatus: `${baseUrl}/${versionNumber}/performance/lastConfirmStatus`
  },
  // 考核计划周期
  {
    examineCycle: `${baseUrl}/${versionNumber}/examine/cycle`
  },
  // 绩效--绩效--终止
  {
    performanceClose: `${baseUrl}/${versionNumber}/performance/close`
  },
  // 绩效-考核计划-考核结果调整记录
  {
    performanceGradeList: `${baseUrl}/${versionNumber}/performance/grade/list`
  },
  // 进度追踪-顶部异常预警项目数量
  {
    trackingAbnormalCount: `/api/analysis/${versionNumber}/report/performance/progress/tracking/abnormal/count/`
  },
  // 考核计划-计划列表-最终结果确认
  {
    performanceResultConfirm: `${baseUrl}/${versionNumber}/performance/result/confirm`
  },
  // 考核计划--绩效列表--驳回处理人
  {
    performanceExamineProcessStepMemberReject: `${baseUrl}/${versionNumber}/performance/examine/process/step/member/reject`
  },
  // 绩效详情驳回处理人
  {
    kpiRejectProcrssMember: `${baseUrl}/${versionNumber}/performance/process/step/member/reject`
  },
  // 员工档案-查询部门人员
  {
    adminDepartmentUserList: `/api/analysis/${versionNumber}/admin/department/user/list/`
  },
  // 根据userID 获取用户下级人员
  {
    underlingsUserList: `/api/admin/${versionNumber}/user/underlings`
  },
  // 查询绩效流程列表详情-(调整人)
  {
    performanceProcessListInfo: `${baseUrl}/${versionNumber}/performance/process/list/info`
  },
  // 进度追踪-正态分布详情-分页
  {
    abnormalGradePage: `/api/analysis/${versionNumber}/report/performance/progress/tracking/info/abnormalGrade/page/`
  },
  // 绩效流程调整处理人
  {
    performanceProcessListOperatorAdjustment: `${baseUrl}/${versionNumber}/performance/process/list/operator/adjustment`
  },
  // 考核计划菜单权限
  {
    examinePowerGetUserPower: `${baseUrl}/${versionNumber}/examine/power/getUserPower`
  },
  // 同步kr 至 绩效指标
  {
    syncPerformanceCustomIndexKr: `${baseUrl}/${versionNumber}/performance/synchronize/performanceCustomIndex/kr`
  },
  // 取消同步kr至绩效指标
  {
    syncPerformanceCustomIndexKrCancel: `${baseUrl}/${versionNumber}/performance/synchronize/performanceCustomIndex/kr/cancel`
  },
  // 根据kr-id,查询kr已经同步的绩效
  {
    syncPerformanceKr: `${baseUrl}/${versionNumber}/performance/synchronize/performance/kr`
  },
  // 根据objId,查询kr已经同步的绩效
  {
    syncPerformanceKrList: `${baseUrl}/${versionNumber}/performance/synchronize/performance/kr/list`
  },
  // 查询被考核人可以同步的考核列表
  {
    synchronizeKr: `${baseUrl}/${versionNumber}/performance/list/synchronize/kr`
  },
  // 成员评定查询操作人详情
  {
    processMemberInfo: `${baseUrl}/${versionNumber}/performance/process/member/info`
  },
  // 指标指定-分页查询指标列表
  {
    templateIndexIndexMakeList: `${baseUrl}/${versionNumber}/template/index/indexMake/list`
  },
  // 查询考核绩效所有评分人--总评分列表
  {
    totalJudgesScoreList: `${baseUrl}/${versionNumber}/performance/process/score/user/getPerformanceJudgesScore`
  },
  // 考核计划--考核详情信息权限查询（考核动态、考核流程、结果调整记录）
  {
    performanceVisibilityRange: `${baseUrl}/${versionNumber}/performance/visibilityRange`
  },
  // 考核计划--考核详情--考核流程
  {
    performanceFlow: `${baseUrl}/${versionNumber}/performance/performanceFlow`
  },
  // 保存评价位置中上传附件接口
  {
    performanceProcessScoreUserInfoDocument: `${baseUrl}/${versionNumber}/performance/process/score/user/info/document`
  },
  // 删除评价位置中上传附件接口
  {
    performanceProcessScoreUserInfoDocumentDel: `${baseUrl}/${versionNumber}/performance/process/score/user/info/document/del`
  }
]
