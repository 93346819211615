// 公用模块方法库
import apis from '../apis_moudles/index'
import req from '../request'
// 文件上传
const FILEUPLOAD = (params) => {
  return req.post(apis.fileUpload, params, {
    timeout: 120000,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
// 文件上传base64
const FILEUPLOADBASE = (params) => {
  return req.post(apis.fileUploadBase, params, {
    timeout: 120000,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
// 批量文件上传
const BATCHFILEUPLOAD = (params) => {
  return req.post(apis.batchFileUpload, params, {
    timeout: 120000,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
// 文件下载
const FILEDOWNLOAD = (params) => {
  return req.post(apis.fileDownLoad, params, {
    responseType: 'arraybuffer',
    timeout: 120000
  })
}
// 文件下载
const FILEDOWNLOADONE = (params) => {
  return req.get(apis.fileDownLoadOne, {
    params,
    responseType: 'arraybuffer'
  })
}

// 文件预览
const FILEPREVIEW = (params) => {
  return req.get(apis.filePreview, {
    params
  })
}
// 获取文件上传URL
const FILEUPLOADURL = apis.fileUpload
// 根据用户id获取用户信息
const USERINFOBYID = (appendUrl, params) => {
  return req.get(`${apis.userInfoById}${appendUrl}`, {
    params
  })
}
// 获取默认企业信息
const GETDEFAULTCOMPANYINFO = (params) => {
  return req.get(apis.getDefaultCompanyInfo, {
    params
  })
}
// 获取邀请信息（二维码及链接）
const INVITECODEINFO = (params) => {
  return req.post(apis.inviteCodeInfo, params)
}
// 获取指定周期邀请信息（二维码及链接）
const INVITECODEINFOBYCYCLEID = (appendUrl, params) => {
  return req.get(`${apis.inviteCodeInfoByCycle}${appendUrl}`, {
    params
  })
}
// 邀请成员
const INVITEMEMBER = (params) => {
  return req.post(apis.inviteMember, params)
}
// 获取行业角色列表
const QUERYINDUSTRYROLES = (params) => {
  return req.get(apis.queryIndustryRoles, {
    params
  })
}
// 获取用户token
const GETUSERTOKEN = (params) => {
  return req.get(apis.getUserToken, {
    params
  })
}
// 根据用户手机号获取用户信息
const USERINFOBYMOBILE = (params) => {
  return req.get(apis.userInfoByMobile, {
    params
  })
}
// 获取引导流程状态 userSendSms
const GETCOMCURRENTPROCESS = (params) => {
  return req.get(apis.getComCurrentProcess, {
    params
  })
}
// 获取验证码
const USERSENDSMS = (params) => {
  return req.post(apis.userSendSms, params)
}
// 搜索人员
const SELECTUSERS = (params) => {
  return req.get(apis.selectUsers, {
    params
  })
}
// 公用get方法
const GET = (url, params) => {
  return req.get(url, {
    params
  })
}

// 获取公司所有成员列表
const QUERYALLMEMBERSINCOMPANY = (appendUrl, params) => {
  return req.get(`${apis.queryAllMembersInCompany}${appendUrl}`, {
    params
  })
}
// 获取版本是否到期
const QUERYVERSIONISVALID = (params) => {
  return req.get(apis.queryVersionIsValid, {
    params
  })
}
// 查询是否需要提交意见
const ADMINOPINIONSTATE = (params) => {
  return req.get(apis.adminOpinionState, {
    params
  })
}
// 提交满意度
const ADMINOPINIONCOMMIT = (params) => {
  return req.post(apis.adminOpinionCommit, params)
}
// 获取成长旅程列表
const USERPROCESSLIST = (params) => {
  return req.get(apis.userProcessList, {
    params
  })
}
// 获取小火箭第几步
const ROCKETSTEP = (step, params) => {
  return req.post(`${apis.rocketStep}${step}`, params)
}

// 查看服务器时间
const SYSTEMDATE = (params) => {
  return req.get(apis.systemDate, {
    params
  })
}
// 设置消息为已读
const CLEARREDPOINTMSG = (params) => {
  return req.post(apis.clearRedpointMsg, params)
}
// 消除红点
const CLEARREDPOINT = (params) => {
  return req.post(apis.clearRedpoint, params)
}
// 公共数据查询接口
const COMMONDATA = (params) => {
  return req.get(apis.commonData, {
    params
  })
}
// 预约演示
const COMMONAPPOINTMENT = (params) => {
  return req.post(apis.commonAppointment, params)
}
// 申请延期
const COMMONAPPOINTMENTDELAY = (params) => {
  return req.post(apis.commonAppointmentDelay, params)
}
// 申请报价
const COMMONAPPOINTMENTPRICE = (params) => {
  return req.post(apis.commonAppointmentPrice, params)
}
// 钉钉登录换平台token
const GETDDTOKEN = (params) => {
  return req.post(`${apis.getDdToken}`, params)
}
// 钉钉登录换平台token
const GETJSAPICONFIG = (params) => {
  return req.get(`${apis.getJspApiConfig}`, {
    params
  })
}
// 获取公司所有成员列表
const QUERYALLMEMBERSINCOMPANYV2 = (appendUrl, params) => {
  return req.get(`${apis.queryAllMembersInCompanyV2}${appendUrl}`, {
    params
  })
}
export default [
  {
    GETJSAPICONFIG
  },
  {
    GETDDTOKEN
  },
  {
  QUERYALLMEMBERSINCOMPANYV2
},
{
  COMMONAPPOINTMENTPRICE
},
{
  COMMONAPPOINTMENTDELAY
},
{
  COMMONAPPOINTMENT
},
{
  FILEUPLOADBASE
},
{
  COMMONDATA
},
{
  ROCKETSTEP
},
{
  USERPROCESSLIST
},
{
  ADMINOPINIONCOMMIT
},
{
  ADMINOPINIONSTATE
},
{
  FILEUPLOAD
},
{
  BATCHFILEUPLOAD
},
{
  FILEDOWNLOAD
},
{
  FILEDOWNLOADONE
},
{
  FILEUPLOADURL
},
{
  FILEPREVIEW
},
{
  USERINFOBYID
},
{
  GETDEFAULTCOMPANYINFO
},
{
  INVITECODEINFO
},
{
  INVITECODEINFOBYCYCLEID
},
{
  INVITEMEMBER
},
{
  QUERYINDUSTRYROLES
},
{
  QUERYALLMEMBERSINCOMPANY
},
{
  GET
},
{
  GETUSERTOKEN
},
{
  USERINFOBYMOBILE
},
{
  GETCOMCURRENTPROCESS
},
{
  USERSENDSMS
},
{
  SELECTUSERS
},
{
  GET
},
{
  QUERYALLMEMBERSINCOMPANY
},
{
  QUERYVERSIONISVALID
},
{
  SYSTEMDATE
},
{
  CLEARREDPOINTMSG
},
{
  CLEARREDPOINT
}
]
