var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "at-content-box" },
    _vm._l(_vm.dataList, function (item, index) {
      return _c(
        "span",
        { key: index, style: item.type === "@" ? `color:${item.color}` : "" },
        [
          item.type === "text"
            ? _c("span", { domProps: { innerHTML: _vm._s(item.content) } })
            : [
                _vm.type === "@"
                  ? [
                      _vm._v(" @ "),
                      _c("wx-open-data", {
                        attrs: {
                          id: item.content,
                          nameData: { name: item.content },
                        },
                      }),
                    ]
                  : _vm._l(item.content, function (itemContent, itemIndex) {
                      return _c(
                        "span",
                        { key: itemIndex },
                        [
                          _c("wx-open-data", {
                            attrs: {
                              id: itemContent,
                              nameData: { name: itemContent },
                            },
                          }),
                          item.content.length > 1 &&
                          itemIndex !== item.content.length - 1
                            ? [_vm._v(",")]
                            : _vm._e(),
                        ],
                        2
                      )
                    }),
              ],
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }