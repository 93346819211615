import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import mutations from './mutations'
import state from './state'
// 状态保持，防止数据刷新丢失
import createPersistedState from 'vuex-persistedstate'

// 项目足够大时，将store进行拆分成若干moudle
// 存放个人信息
import user from './modules/user'
// 存放公司信息
import company from './modules/company'
// 存放通用信息
import common from './modules/common'
// 存放项目信息
import pm from './modules/pm'
// 存放OKR信息
import okr from './modules/okr'
// 存放workbench信息
import workbench from './modules/workbench'
// 存放task信息
import task from './modules/task'
// 注意：只有程序级状态/数据方可使用store
Vue.use(Vuex)

export default new Vuex.Store({
  state,
  mutations,
  actions,
  modules: {
    namespaced: true,
    user,
    company,
    common,
    pm,
    okr,
    task,
    workbench
  },
  plugins: [createPersistedState({
    reducer: state => {
      return {
        namespaced: state.namespaced,
        user: state.user,
        company: state.company,
        common: state.common,
        pm: state.pm,
        task: state.task,
        workbench: state.workbench
      };
    }
  })]
})
