<template>
  <transition name="fade" mode="out-in">
    <div v-if="inviteFriendsShowStatez" class="invite_friends" @click.self="getInvitationClose">
      <div class="invite_friends_box">
        <div class="friends_box_top">
          邀请成员加入
          <i class="el-icon-close friends_box_top_font" @click="getInvitationClose"></i>
        </div>
        <div class="invite_friends_link clearfix">
          <Icon class="invite_friends_link_icon" icon-class="icon-connect" />
          <div
            class="invite_friends_link_name"
            @click="getInviteFriendsCodeShowState('show')"
          >邀请外部人员加入</div>
        </div>
        <div class="invite_friends_search">
          <el-input
            v-model="inputValue"
            prefix-icon="el-icon-search"
            class="search-input"
            placeholder="输入关键词搜索成员"
            @input="searchObjectiveOrTask"
          />
        </div>
        <div class="invite_friends_title clearfix">
          <div
            class="friends_title_font"
            @click="getInviteFriendsPage('首页', index)"
          >{{ companyInfo.name }}</div>
          <div id="friends_title_font_text" class="friends_title_font_text">
            <span
              v-for="(item, index) in inviteFriendsList"
              :key="item.id"
              @click="getInviteFriendsPage('page', index)"
            >
              {{ item.name }}
              <i
                v-if="inviteFriendsList.length > index + 1"
                class="el-icon-arrow-right friends_content_list_name_right"
              ></i>
            </span>
          </div>
        </div>

        <div id="invite_friends_content" class="invite_friends_content">
          <div
            v-if="
              inviteFriendsBeans.departs.length > 0 ||
              inviteFriendsBeans.userInfos.length > 0
            "
            class="friends_content_list"
          >
            <div
              v-for="(item, index) in inviteFriendsBeans.departs"
              :key="item.id"
              class="friends_content_list_name clearfix"
              @click="getInviteFriendsListSelect(index)"
            >
              <div class="friends_content_list_name_left">{{ item.departmentTitle }}</div>
              <div class="friends_content_list_name_text">{{ item.name }} * {{ item.userNum }}</div>
              <i class="el-icon-arrow-right friends_content_list_name_right"></i>
              <div
                class="friends_content_list_name_button"
                @click.stop="getInviteFriendsAdd(item, '添加全部')"
              >添加全部</div>
              <!-- <div class="friends_content_list_name_button_font" v-if="item.status === 1">已添加</div> -->
            </div>
            <div
              v-for="item in inviteFriendsBeans.userInfos"
              :key="item.id"
              class="friends_content_list_name clearfix"
              @mouseover="getMouseOver"
            >
              <!-- <div class="friends_content_list_name_left">技</div> -->
              <img :src="item.avatar" class="friends_content_list_name_left border-radius-20" />
              <div class="friends_content_list_name_text">{{ item.name }}</div>
              <div
                v-if="item.status === 0"
                class="friends_content_list_name_button"
                @click="getInviteFriendsAdd(item, '添加')"
              >添加</div>
              <!-- <div class="friends_content_list_name_button" @click="getInviteFriendsAdd(item,'移出')" v-if="item.status === 1">移出</div> -->
              <div v-if="item.status === 1" class="friends_content_list_name_button_font">已添加</div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="inviteFriendsCode" class="invite_friends_code">
        <div class="friends_code_box">
          <div class="friends_box_top">
            通过链接邀请
            <i
              class="el-icon-close friends_box_top_font"
              @click="getInviteFriendsCodeShowState('close')"
            ></i>
          </div>
          <div class="friends_code_box_img">
            <img class="friends_code_box_img_icon" :src="invitationBeans.shortUrlQrCode" />
          </div>

          <div class="friends_code_box_text">
            <div class="friends_code_box_text1">我和“{{ invitationBeans.companyName }}”的小伙伴都在源目标等你，</div>
            <div class="friends_code_box_text1">用这个专属链接加入我们吧！</div>
            <div class="friends_code_box_text1">{{ invitationBeans.shortUrl }}</div>
          </div>

          <button
            v-clipboard:copy="invitationBeans.shortUrl"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            class="friends_code_box_button"
          >复制邀请链接</button>
        </div>
      </div>
      <invite-by-code
        v-if="inviteByCodeVisible"
        v-model="inviteByCodeVisible"
        @closeInviterCode="closeInviterCode"
      />
      <customer-service v-if="customerServiceVisible" v-model="customerServiceVisible" />
    </div>
  </transition>
</template>
<style lang="scss" scoped>
::v-deep .el-input__inner {
  height: 36px;
  line-height: 32px;
  background: none;
  box-shadow: none !important;
  border: none;
}
.invite_friends {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 1800;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  .invite_friends_box {
    border-radius: 16px;
    width: 360px;
    height: 480px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 15px 0px rgba(0, 24, 87, 0.1);
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -180px;
    margin-top: -240px;
    .friends_box_top {
      width: 100%;
      height: 45px;
      border-radius: 1px;
      text-align: center;
      line-height: 45px;
      font-size: 17px;
      font-family: 'OPPOSans-B,OPPOSans';
      font-weight: normal;
      color: rgba(51, 51, 51, 1);
      border-bottom: 1px solid rgba(224, 227, 236, 1);
      position: relative;
      .friends_box_top_font {
        font-size: 20px;
        color: rgba(150, 159, 190, 1);
        position: absolute;
        right: 17px;
        top: 14px;
        cursor: pointer;
      }
    }
    .invite_friends_link {
      width: 100%;
      height: 20px;
      margin-top: 18px;
      .invite_friends_link_icon {
        width: 20px;
        height: 20px;
        float: left;
        margin-left: 220px;
        margin-top: 2.5px;
        background: rgba(90, 135, 255, 1);
        color: #fff;
        border-radius: 50%;
        padding: 5px;
      }
      .invite_friends_link_name {
        height: 100%;
        line-height: 20px;
        font-size: 12px;
        color: rgba(90, 135, 255, 1);
        float: left;
        margin-left: 8px;
        cursor: pointer;
      }
    }

    .invite_friends_search {
      width: 330px;
      height: 36px;
      background: rgba(244, 247, 250, 1);
      border-radius: 5px;
      margin: 10px auto 0px auto;
      .search-input {
        border: none;
        width: 90%;
        font-size: 14px;
      }
    }
    .invite_friends_title {
      width: 100%;
      height: 50px;
      border-bottom: 1px solid rgba(224, 227, 236, 1);
      padding-top: 1px;
      .friends_title_font {
        font-size: 15px;
        font-weight: 700;
        color: rgba(51, 51, 51, 1);
        float: left;
        margin-left: 16px;
        margin-top: 17px;
        max-width: 27%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-bottom: 2px;
        cursor: pointer;
        &:before {
          content: '';
          display: block;
        }
      }
      .friends_title_font_text {
        margin-left: 10px;
        font-size: 15px;
        font-weight: normal;
        color: rgba(51, 51, 51, 1);
        line-height: 50px;
        float: left;
        cursor: pointer;
      }
    }

    .invite_friends_content {
      width: 100%;
      height: calc(100% - 179px);
      // border: 1px solid red;
      overflow-x: hidden;
      // @include scroll-beautify;
      .friends_content_list {
        width: 100%;
        min-height: 45px;
        border-bottom: 1px solid rgba(216, 216, 216, 1);
        .friends_content_list_name {
          width: 100%;
          height: 45px;
          cursor: pointer;
          .friends_content_list_name_left {
            width: 30px;
            height: 30px;
            background: rgba(48, 104, 255, 1);
            border-radius: 15px;
            float: left;
            margin-left: 17px;
            margin-top: 8px;
            font-size: 15px;
            font-weight: bold;
            color: rgba(255, 255, 255, 1);
            text-align: center;
            line-height: 30px;
          }
          .friends_content_list_name_text {
            font-size: 14px;
            color: rgba(51, 51, 51, 1);
            height: 100%;
            line-height: 45px;
            float: left;
            margin-left: 11px;
            width: 110px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &:before {
              content: '';
              display: block;
            }
          }
          .friends_content_list_name_right {
            font-size: 20px;
            float: right;
            color: rgba(224, 227, 236, 1);
            margin-right: 14px;
            margin-top: 13px;
          }
          .friends_content_list_name_button {
            width: 74px;
            height: 27px;
            border-radius: 3px;
            border: 1px solid rgba(48, 104, 255, 1);
            font-size: 12px;
            color: rgba(48, 104, 255, 1);
            text-align: center;
            line-height: 27px;
            float: right;
            margin-right: 15px;
            margin-top: 9px;
            display: none;
          }
          .friends_content_list_name_button_font {
            height: 45px;
            font-size: 12px;
            color: #969fbe;
            float: right;
            line-height: 45px;
            margin-right: 27px;
          }
        }
        .friends_content_list_name:hover {
          background: #f4f7fa;
          .friends_content_list_name_button {
            display: block;
          }
        }
        .friends_content_list_ul {
          width: 100%;
          .friends_content_list_li {
            width: 100%;
            height: 45px;
            margin-top: 5px;
            cursor: pointer;
            position: relative;
            .friends_content_list_li_icon {
              width: 30px;
              height: 30px;
              margin-top: 8px;
              margin-left: 55px;
              float: left;
            }
            .friends_content_list_li_name {
              height: 100%;
              line-height: 45px;
              float: left;
              margin-left: 11px;
              font-size: 14px;
              color: rgba(51, 51, 51, 1);
            }
            .friends_content_list_li_button {
              width: 59px;
              height: 22px;
              border-radius: 2px;
              border: 1px solid rgba(48, 104, 255, 1);
              float: right;
              font-size: 10px;
              color: rgba(48, 104, 255, 1);
              text-align: center;
              line-height: 22px;
              cursor: pointer;
              margin-top: 11px;
              margin-right: 16px;
              position: absolute;
              right: 16px;
              top: 1px;
              display: none;
            }
            .friends_content_list_name_font {
              font-size: 10px;
              color: rgba(150, 159, 190, 1);
              height: 100%;
              line-height: 45px;
              float: right;
              margin-right: 43px;
            }
          }
          .friends_content_list_li:hover {
            .friends_content_list_li_button {
              display: block;
            }
            .friends_content_list_name_font {
              display: none;
            }
          }
        }
      }
    }
  }

  .invite_friends_code {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    background: rgba(0, 0, 0, 0.15);
    .friends_code_box {
      width: 435px;
      min-height: 465px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 15px 0px rgba(0, 24, 87, 0.1);
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -218px;
      margin-top: -238px;
      padding-bottom: 30px;
      .friends_box_top {
        width: 100%;
        height: 45px;
        border-radius: 1px;
        text-align: center;
        line-height: 45px;
        font-size: 17px;
        color: rgba(51, 51, 51, 1);
        border-bottom: 1px solid rgba(224, 227, 236, 1);
        position: relative;
        .friends_box_top_font {
          font-size: 20px;
          color: rgba(150, 159, 190, 1);
          position: absolute;
          right: 17px;
          top: 14px;
          cursor: pointer;
        }
      }
      .friends_code_box_img {
        width: 100%;
        height: 150px;
        margin-top: 40px;
        .friends_code_box_img_icon {
          width: 150px;
          height: 150px;
          display: block;
          margin: 0px auto 0px auto;
        }
      }
      .friends_code_box_text {
        width: 372px;
        min-height: 90px;
        background: rgba(243, 243, 245, 0.6);
        border-radius: 3px;
        border: 1px solid rgba(224, 227, 236, 0.8);
        margin: 21px auto 0px auto;
        padding-bottom: 10px;
        .friends_code_box_text1 {
          width: 100%;
          font-size: 12px;
          color: rgba(92, 93, 97, 1);
          text-align: center;
          line-height: 21px;
          word-break: break-word;
          padding-left: 10px;
          padding-right: 10px;
        }
        .friends_code_box_text1:first-child {
          margin-top: 13px;
        }
      }
      .friends_code_box_button {
        width: 375px;
        height: 41px;
        background: #5a87ff;
        border-radius: 3px;
        margin: 36px auto 0px auto;
        border: none;
        display: block;
        color: #ffffff;
      }
    }
  }
}
</style>
<script>
export default {
  props: {
    // 标题
    inviteFriendsShowState: {
      type: Boolean,
      required: false
      // default:'全部成员'
    }
  },
  data() {
    return {
      inviteByCodeVisible: false, // 二维码，链接邀请弹窗
      customerServiceVisible: false, // 人员受限弹窗
      inputValue: '',
      inviteFriendsList: [], // 返回上一级内容
      inviteFriendsBeans: { departs: [], userInfos: [] }, // 保存数据
      inviteFriendsId: '-1',
      inviteFriendsState: '部门',
      urls: 'https://go.ranlo.com/JLDM7WN/',
      inviteFriendsCode: false,
      invitationBeans: null,
      inviteFriendsShowStatez: false,
      periodMemberList: [],
      isLimitBoolean: false, // 人员是否达到上
      quarterId: null
    }
  },
  watch: {
    // $route.path == this.$route.path
    inviteFriendsShowState: function (newVal, oldVal) {
      this.inputValue = ''
      this.inviteFriendsId = ''
      this.getInviteFriendsShowState()
      this.getPersonLimit() // 获取版本人员数量
    }
  },
  created() {
    // 初始化周期
    this.quarterId = this.$store.state.common.quarterId
  },
  mounted() {
    // this.getInviteFriendsList('初始化')
    // this.getInviteFriendsList('初始化')
  },
  methods: {
    closeInviterCode() {
      this.inviteByCodeVisible = false
    },
    // 显示状态
    getInviteFriendsShowState: function () {
      this.inviteFriendsShowStatez = this.inviteFriendsShowState
      if (this.inviteFriendsShowState) {
        this.getInviteFriendsList('初始化')
      }
    },
    // 关闭内容
    getInvitationClose() {
      this.$emit('getInvitationClose')
    },
    // 搜索人员内容
    async searchObjectiveOrTask() {
      if (this.inputValue.replace(/\s*/g, '').length > 20) {
        this.$message({
          msg: '成员名称最多可以输入 20 个字符哦!',
          type: 'warn'
        })
        this.inputValue = this.inputValue.substring(0, 20)
      }
      if (!this.inputValue) {
        this.getInviteFriendsList('搜索')
      } else {
        this.$apis
          .CYCLEMEMBERLIST({
            departId: this.inviteFriendsId,
            cycleInfoId: this.cycle.periodId,
            userName: this.inputValue
          })
          .then((rest) => {
            this.inviteFriendsState = '人员'
            this.inviteFriendsBeans.userInfos = rest.data.userInfos || []
            if (rest.data.departs != null) {
              for (let i = 0; i < rest.data.departs.length; i++) {
                rest.data.departs[i].departmentTitle = rest.data.departs[
                  i
                ].name.slice(0, 1)
              }
              this.inviteFriendsBeans.departs = [...rest.data.departs] // 张20210306
            } else {
              this.inviteFriendsBeans.departs = [] // 张20210306
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    // 搜索部门内容
    async getInviteFriendsList(type) {
      // if(type === '初始化'){
      // this.inviteFriendsList = [];
      // let beansDate = {
      //     id:'-1',
      //     name:'首级'
      // };
      // this.inviteFriendsList.push(beansDate);
      // }

      this.$apis
        .CYCLEMEMBERLIST({
          departId: this.inviteFriendsId,
          cycleInfoId: this.cycle.periodId,
          userName: this.inputValue
        })
        .then((rest) => {
          this.inviteFriendsState = '部门'
          for (let i = 0; i < rest.data.departs.length; i++) {
            rest.data.departs[i].departmentTitle = rest.data.departs[
              i
            ].name.slice(0, 1)
          }
          this.inviteFriendsBeans = rest.data
          // departs   部门内容
          // userInfos 人员内容
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 计算div高度
    clientHeight() {
      let inviteFriendsContentHeight, friendsTitleFontTextHeight
      const inviteFriendsContent = document.getElementById(
        'invite_friends_content'
      )
      const friendsTitleFontText = document.getElementById(
        'friends_title_font_text'
      )
      if (inviteFriendsContent && friendsTitleFontText) {
        inviteFriendsContentHeight = inviteFriendsContent.clientHeight
        friendsTitleFontTextHeight = friendsTitleFontText.clientHeight
        if (friendsTitleFontTextHeight > 50) {
          inviteFriendsContent.style.height =
            inviteFriendsContentHeight - friendsTitleFontTextHeight + 'px'
          friendsTitleFontText.style.lineHeight = 36 + 'px'
          friendsTitleFontText.style.marginTop = 16 + 'px'
        }
      }
    },
    // 点击部门显示内容
    async getInviteFriendsListSelect(index) {
      const beansDate = this.inviteFriendsBeans.departs[index]
      this.inviteFriendsList.push(beansDate)
      this.inviteFriendsId = this.inviteFriendsBeans.departs[index].id
      this.$apis
        .CYCLEMEMBERLIST({
          departId: this.inviteFriendsId,
          cycleInfoId: this.cycle.periodId,
          userName: this.inputValue
        })
        .then((rest) => {
          this.inviteFriendsState = '部门'
          for (let i = 0; i < rest.data.departs.length; i++) {
            rest.data.departs[i].departmentTitle = rest.data.departs[
              i
            ].name.slice(0, 1)
          }
          this.inviteFriendsBeans = rest.data
          setTimeout(() => {
            this.clientHeight()
          }, 500)

          // departs   部门内容
          // userInfos 人员内容
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 分页搜索内容
    async getInviteFriendsPage(type, index) {
      if (type === '首页') {
        this.inputValue = ''
        this.inviteFriendsList = []
        this.inviteFriendsId = -1
        this.$apis
          .CYCLEMEMBERLIST({
            departId: this.inviteFriendsId,
            cycleInfoId: this.cycle.periodId,
            userName: this.inputValue
          })
          .then((rest) => {
            this.inviteFriendsState = '部门'
            for (let i = 0; i < rest.data.departs.length; i++) {
              rest.data.departs[i].departmentTitle = rest.data.departs[
                i
              ].name.slice(0, 1)
            }
            this.inviteFriendsBeans = rest.data
            // departs   部门内容
            // userInfos 人员内容
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        this.inputValue = ''
        const list = this.inviteFriendsList
        this.inviteFriendsList = list.slice(0, index + 1)
        this.inviteFriendsId = this.inviteFriendsList[index].id
        this.$apis
          .CYCLEMEMBERLIST({
            departId: this.inviteFriendsId,
            cycleInfoId: this.cycle.periodId,
            userName: this.inputValue
          })
          .then((rest) => {
            this.inviteFriendsState = '部门'
            for (let i = 0; i < rest.data.departs.length; i++) {
              rest.data.departs[i].departmentTitle = rest.data.departs[
                i
              ].name.slice(0, 1)
            }
            this.inviteFriendsBeans = rest.data
            // departs   部门内容
            // userInfos 人员内容
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    // 添加修改成员
    async getInviteFriendsAdd(item, type) {
      if (type === '添加全部') {
        this.$apis
          .CYCLEINVITEDEPART({
            cycleInfoId: this.cycle.periodId,
            departId: item.id
          })
          .then((rest) => {
            this.$message({
              msg: '所属部门添加成功',
              type: 'success'
            })
            this.searchObjectiveOrTask()
          })
          .catch((err) => {
            console.log(err)
          })
      } else if (type === '添加') {
        this.$apis
          .CYCLEINVITEUSER({
            cycleInfoId: this.cycle.periodId,
            userId: item.id
          })
          .then((rest) => {
            this.$message({
              msg: '人员添加成功',
              type: 'success'
            })
            if (this.inviteFriendsState === '部门') {
              this.getInviteFriendsList('搜索')
            } else if (this.inviteFriendsState === '人员') {
              this.searchObjectiveOrTask()
            }
          })
          .catch((err) => {
            console.log(err)
          })
      } else if (type === '移出') {
        this.$apis
          .CYCLEDELETEUSER(item.id)
          .then((rest) => {
            this.$message({
              msg: '人员移出成功',
              type: 'success'
            })
            if (this.inviteFriendsState === '部门') {
              this.getInviteFriendsList('搜索')
            } else if (this.inviteFriendsState === '人员') {
              this.searchObjectiveOrTask()
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },

    //
    getInviteFriendsCodeShowState(data) {
      if (!this.isLimitBoolean) {
        this.inviteByCodeVisible = true
      } else {
        this.customerServiceVisible = true
      }
    },
    // 获取code信息内容
    async getInviteFriendsCodeList() {
      this.$apis
        .INVITECODEINFOBYCYCLEID(this.quarterId)
        .then((rest) => {
          if (rest.status === 200) {
            this.invitationBeans = rest.data
          } else if (rest.status === 500) {
            this.$message({
              msg: rest.message,
              type: 'warn'
            })
          } else {
            this.$message({
              msg: rest.message,
              type: 'error'
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 模拟浏览器复制链接
    onCopy() {
      this.$message({
        msg: `复制成功！`,
        type: 'success'
      })
    },
    onError() {
      this.$message({
        msg: `复制失败！`,
        type: 'success'
      })
    },

    // 移入显示内容
    async getMouseOver(index, item) {},
    // 获取版本人员数量
    async getPersonLimit() {
      this.$apis
        .QUERYMEMBERLIMIT()
        .then((rest) => {
          // console.log(rest,'aaa')
          if (rest.data.isLimit === '1') {
            this.isLimitBoolean = true
          } else {
            this.isLimitBoolean = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>
