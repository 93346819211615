<template>
  <div>
    <el-dialog
      title="新建目标"
      :visible.sync="videoDialogShow"
      width="880px"
      :append-to-body="true"
      :before-close="handleClose"
      custom-class="add-okr-dialog"
      @close="closeDialog"
    >
      <span class="el-icon-close" @click="closeDialog"></span>
      <div class="video-div">
        <video id="video" controls autoplay disablepictureinpicture>
          <source type="audio/mp4" :src="videoUrl || require(`@/assets/mp4/add_okr_vedio.mp4`)" />
        </video>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    videoDialogShow: {
      type: Boolean,
      default: false
    },
    videoUrl: {
      default: ''
    },
    videoName: {
      default: ''
    },
    recordTime: { // 是否记录时长
      default: false
    }
  },
  data() {
    return {
      timeSetInterval: '',
      currentTime: 0
    }
  },
  watch: {
    videoDialogShow: {
      handler(val) {
        var video = document.getElementById('video')
        if (video) {
          video.currentTime = 0
          video.play()
        }
      }
    }
  },
  mounted() {
    this.$nextTick(function () {
      document.addEventListener('keyup', function (e) {
        // 此处填写你的业务逻辑即可
        if (e.keyCode === 27) {
          var video = document.getElementById('video')
          if (video) {
            video.currentTime = 0
            video.pause() // 暂停控制
          }
        }
      })
      if (this.recordTime) {
        clearInterval(this.timeSetInterval)
        this.getRecordTime()
      }
    })
  },
  methods: {
    closeDialog() {
      clearInterval(this.timeSetInterval)
      this.videoDialogShow = false
      var video = document.getElementById('video')
      if (video) {
        video.currentTime = 0
        video.pause() // 暂停控制
      }
      this.$emit('closeVideoDialog')
    },
    getRecordTime() {
      var that = this
      // 开启定时器，来自定每5秒提交播放历史
      this.timeSetInterval = setInterval(function () {
        var video = document.getElementById('video')
        if (video) {
          that.currentTime = Math.ceil(video.currentTime)
        }
        that.submitVideoPlayRecord()
      }, 5000)
    },
    submitVideoPlayRecord() {
      const params = {
        historyTime: this.currentTime,
        mediaUrl: this.videoUrl,
        mediaName: this.videoName,
        userId: this.userInfo.id
      }
      this.$apis.MEDIAWATCHHISTORY(params).then((res) => {
        if (res.status === 200) {
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__header {
  display: none;
}
::v-deep .el-dialog__body {
  padding: 0 !important;
  min-width: 880px;
  min-height: 436px;
}
::v-deep .el-dialog {
  min-width: 880px;
  min-height: 436px;
}
</style>
<style lang="scss" scoped>
video:focus {
  outline: none !important;
  object-fit: fill;
}
.el-icon-close {
  font-size: 24px;
  font-weight: 700;
  color: #f7f7fc;
  position: absolute;
  top: -15px;
  right: -30px;
  cursor: pointer;
}
.el-icon-close:hover {
  color: #fff;
}
.video-div {
  padding: 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 16px;
  min-width: 880px;
  min-height: 436px;
  video {
    width: 885px;
    margin-left: -3px;
    margin-top: -4px;
    margin-bottom: -4px;
    height: 100%;
    object-fit: fill !important;
    border-radius: 16px;
    outline: none !important;
  }
}
</style>
