var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "success-notice-box" }, [
    _c("div", { staticClass: "success-notice-main" }, [
      _c(
        "div",
        { staticClass: "success-notice-img" },
        [
          _c("Img", {
            staticClass: "logo-img",
            attrs: { src: "/common/logo_icon.png" },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "success-notice-title" }, [_vm._v("源目标")]),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "button-bottom" },
        [
          _c(
            "el-button",
            {
              staticClass: "next-button",
              attrs: { type: "primary" },
              on: { click: _vm.next_pass_reset },
            },
            [_vm._v("下载或打开源目标客户端")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "success-notice-text" }, [
      _c("p", [
        _vm._v(" 源自目标   聚焦协作 "),
        _c("br"),
        _vm._v("让每个人的工作简单、快乐、有价值 "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }