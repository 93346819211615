<template>
  <el-dialog
    :visible.sync="centerDialogVisible"
    :width="width"
    :top="top"
    :append-to-body="true"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    class="apply-demo-dialog"
    :show-close.sync="showClo"
    v-on="$listeners"
  >
    <div slot="title" class="dialog-title-box">
      <div class="flex-a-c-center-one">
        <div v-if="companyHistoryState" class="return" @click="companyHistoryClick(false)">
          <Icon icon-class="icon-fanhuijiantou" class="icon" />
        </div>
        <p class="title-txt" v-text="companyHistoryState ? historyTitle : title"></p>
      </div>
    </div>
    <!-- 切换企业 -->
    <transition name="fade" mode="out-in">
      <div v-if="!companyHistoryState" :key="companyHistoryState">
        <div class="home_box">
          <div v-if="statusPassSms == 2" class="register_box">
            <p class="register_text">
              你的帐号已加入以下
              <label style="color: #4c86ff">{{ enterpriseArrslength }}</label>个企业，点击可直接进入
            </p>
            <div v-vueloading="!enterpriseArrslengthState" class="register_enterprise_out">
              <transition name="fade-transform" mode="out-in">
                <ul v-if="enterpriseArrslength > 0">
                  <li
                    v-for="(item, index) in enterpriseArrs"
                    :key="item.id"
                    :class="{ li_hover: index == hoverIndex }"
                    class="ellipsis-1"
                    @click.stop="focusTogether(item.id, item.name, 1)"
                    @mouseover="hoverIndex = index"
                    @mouseout="hoverIndex = -1"
                  >
                    <div class="left">
                      <div class="logo">
                        <el-image
                          :src="item.logo || require('@/assets/imgs/common/avatar.png')"
                          fit="cover"
                          class="img"
                        />
                        <div v-if="type !== 2 && item.notifyNoReadCount > 0" class="number">
                          {{
                          item.notifyNoReadCount > 99
                          ? '···'
                          : item.notifyNoReadCount
                          }}
                        </div>
                      </div>
                      <div
                        class="name"
                        :class="item.userState === 0 ? 'name-color' : ''"
                      >{{ item.name }}</div>
                      <!-- <div v-if="item.version && item.version == 3" class="activity-free-div">
                        <img src="@/assets/imgs/common/activity_free.png" alt />
                      </div>-->
                    </div>
                    <div class="right">
                      {{
                      item.userState === 0
                      ? '待激活'
                      : item.userState === 2
                      ? '已停用'
                      : item.userState === 3
                      ? '不在可见范围'
                      : item.userState === 4
                      ? '已离职'
                      : '已加入'
                      }}
                    </div>
                  </li>
                </ul>
              </transition>
            </div>
            <div v-if="enterpriseArrslength <= 0 && enterpriseArrslengthState" class="companyNull">
              <!-- <div class="svg-wrap">
                <svg-icon class="svg-img" name="message/companyNull"></svg-icon>
              </div>-->
              <noData />
              <p class="title">当前还未加入任何组织，您可以创建属于自己的组织</p>
            </div>
          </div>
          <div v-if="statusPassSms == 3 || statusPassSms == 4" class="register_box">
            <p v-if="fromPage !== 2" class="register_text_back" @click="next_go_back(2)">
              <Icon icon-class="icon-left" class="icon-left" />返回
            </p>
            <p class="register_title">创建团队</p>
            <p class="register_text">团队可以是公司，也可以是项目组或其他协作组织。为方便大家与你联系，请务必填写真实信息。</p>
            <fieldset class="register_fieldset_ctrate">
              <input
                v-model="realGroupName"
                type="text"
                class="register_input"
                placeholder="请输入团队名称"
                @input="teamInput"
                @blur="realGroupNameBlur"
              />
              <p
                v-show="status_groupName == true"
                class="register_message"
              >{{ status_message_groupName }}</p>
            </fieldset>
          </div>
        </div>
        <div slot="footer" style="overflow: hidden; padding-top: 10px">
          <!-- <slot name="footer"> -->
          <div class="foot-title">
            <span>OR</span>
          </div>
          <button
            v-show="statusPassSms == 2"
            type="button"
            class="register_next common_btn_next common_btn_next_able"
            @click="create_enterprise_btn"
          >创建企业</button>
          <button
            v-show="statusPassSms == 3"
            type="button"
            class="register_next common_btn_next common_btn_next_able"
            @click="enter_ranle_btn"
          >创建团队并进入</button>
          <button
            v-show="statusPassSms == 4"
            type="button"
            class="register_next common_btn_next common_btn_next_unable"
          >创建团队并进入</button>
          <!-- </slot> -->
          <div
            v-show="statusPassSms == 2"
            class="company-history"
            @click="companyHistoryClick(true)"
          >申请加入企业历史</div>
        </div>
      </div>
      <!-- 历史企业 -->
      <!-- <transition name="fade" mode="out-in"> -->
      <div
        v-if="companyHistoryState"
        class="home_box company-history-wrap"
        :key="companyHistoryState"
      >
        <div class="register_box">
          <div
            v-if="companyHistoryList.length > 0"
            v-loading="loading"
            v-scroll-bottom="$loadMoreList"
            class="register_enterprise_out"
          >
            <ul>
              <li v-for="item in companyHistoryList" :key="item.id" class="ellipsis-1">
                <div class="left">
                  <div class="logo">
                    <el-image
                      :src="item.logo || require('@/assets/imgs/common/avatar.png')"
                      fit="cover"
                      class="img"
                    />
                  </div>
                  <div class="name">{{ item.companyName }}</div>
                </div>
                <div class="right">
                  <div class="date">{{ item.createDate }}</div>
                  <div class="state" :class="`state${item.approveType}`">
                    {{
                    item.approveType === 0
                    ? '待审核'
                    : item.approveType === 1
                    ? '已通过'
                    : item.approveType === 2
                    ? '已拒绝'
                    : '已加入'
                    }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div v-if="companyHistoryList.length <= 0 && companyHistoryListState" class="companyNull">
            <!-- <div class="svg-wrap">
              <svg-icon class="svg-img" name="message/companyNull"></svg-icon>
            </div>-->
            <noData />
            <p class="title">暂无申请加入历史记录</p>
          </div>
        </div>
      </div>
    </transition>

    <!-- </transition> -->
  </el-dialog>
</template>

<script>
/* eslint-disable */
import formMixin from '@/utils/mixins/form'
import redpointFun from '@/utils/info-center'
export default {
  mixins: [formMixin],
  data() {
    return {
      // 标题
      title: '切换企业',
      historyTitle: '申请加入企业历史',
      // icon
      icon: '',
      // 宽度
      width: '580px',
      // 是否追加到body上
      appendToBody: false,
      // 是否显示取消按钮图标
      showClo: true,
      //关闭图层
      centerDialogVisible: true,
      // 距离顶部高度
      top: '15vh',

      enterpriseArrs: [], //企业个数
      status_message: false, //提示信息显示隐藏
      //   realName:'',//真实姓名
      realGroupName: '', //团队真实姓名
      //   status_message_realName:'',//
      //   status_realName:false,//用户真实名称
      status_groupName: false, //组织名称
      status_message_groupName: '', //
      enterpriseArrslength: 0,
      enterpriseArrslengthState: false,
      companyHistoryList: [],
      companyHistoryListState: false,
      companyHistoryTotal: 0,
      hoverIndex: '-1', //滑过li的序号
      index_hover: '-1',
      companyHistoryState: false
    }
  },
  computed: {},
  created() {
    this.centerDialogVisible = true
    if (this.fromPage === 2) {
      this.title = '创建企业'
    } else {
      this.getList()
    }
  },
  props: {
    statusPassSms: {
      type: Number,
      require: false,
      default: 2
    },
    fromPage: {
      //1:头部切换企业 2:全局异常
      type: Number,
      require: false,
      default: 1
    }
  },
  methods: {
    companyHistoryClick(state) {
      this.companyHistoryState = state
      if (state) {
        this.pageNum = 1
        this.pageSize = 10
        this.companyHistoryList = []
        this.getCompanyHistoryList()
      } else {
        this.getList()
      }
    },
    $loadMoreList() {
      if (
        this.companyHistoryList.length < this.companyHistoryTotal &&
        this.companyHistoryListState
      ) {
        this.companyHistoryListState = false
        this.getCompanyHistoryList()
      }
    },
    async getCompanyHistoryList() {
      let param = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      await this.$apis
        .HEADERAGETCOMHISTORYLIST(param)
        .then((res) => {
          if (res.status == 200) {
            this.companyHistoryList = this.companyHistoryList.concat(
              res.data.rows
            )
            this.companyHistoryTotal = res.data.total
            this.pageNum = ++this.pageNum
          } else {
            this.$message({
              msg: res.message,
              type: 'error'
            })
          }
          this.companyHistoryListState = true
        })
        .catch(() => {
          this.$message.error(res.message)
          this.companyHistoryListState = true
        })
    },
    //鼠标点击列表
    focusTogether(_id, companyName, type, state) {
      //点击直接进入首页 ===============================================================
      //根据id获取（查询）具体企业信息
      if (type === 1 || (state === 3 && type === 2)) {
        this.$mixpanelUtil.track('Change Company')
        this._specifyQuery(_id, companyName)
      } else {
        this.$message.warn(`未加入企业，不能切换企业哦！`)
      }
    },
    //返回手机号码tab
    next_go_back(num) {
      this.statusPassSms = num
    },
    //点击创建跳转创建页面
    create_enterprise_btn() {
      //点击创建企业
      this.statusPassSms = 3
    },
    teamInput() {
      if (this.realGroupName.replace(/\s*/g, '').length > 30) {
        this.$message({
          msg: '团队名称不能大于 30 个字符哦!',
          type: 'warn'
        })
        this.realGroupName = this.realGroupName.substring(0, 30)
      }
    },
    realGroupNameBlur() {
      if (this.realGroupName == '') {
        this.status_message_groupName = '团队名称不能为空'
        this.status_groupName = true
      } else {
        this.status_message_groupName = ''
        this.status_groupName = false
      }
    },
    //进入源目标
    enter_ranle_btn() {
      if (
        !this.realGroupName ||
        this.realGroupName.replace(/\s*/g, '').length == 0
      ) {
        this.status_message_groupName = '团队名称不能为空'
        this.status_groupName = true
        return
      } else if (this.realGroupName.replace(/\s*/g, '').length > 30) {
        this.$message({
          msg: '团队名称不能大于 30 个字符哦!',
          type: 'warn'
        })
        return
      } else {
        this.status_message_groupName = ''
        this.status_groupName = false
      }

      //暂时    新用户注册成功后跳转引导页面============================================================
      let _obj = {}
      _obj.name = this.realGroupName
      this.statusPassSms = 4
      this._teamMessage(_obj)
    },
    //获取企业列表
    getList() {
      //查询个人下所有企业列表
      this._getDefaultList()
    },
    //删除当前创建企业
    delCompany(item, index) {
      let that = this
      let comfirm_text =
        item.isSuperAdmin == 1
          ? '解散企业将导致企业的所有目标以及成员都将被移除，且无法恢复，请谨慎操作!'
          : '退出此企业后，您将被移出此企业，且您在此企业创建的目标、任务等数据都将被删除，请谨慎操作!'
      let comfirm_sure =
        item.isSuperAdmin == 1 ? '确定解散并删除企业' : '确认要退出企业'
      that
        .$confirm(comfirm_text, comfirm_sure, {
          confirmButtonText: '确定',
          type: 'none',
          center: true,
          customClass: 'confirmBox'
        })
        .then(() => {
          //下面url换为 删除创建企业的接口 api/admin/v1/company/delete/1181933381333508096===========================================================================
          this.$apis.HEADERDELCOMBYID().then((rest) => {
            if (rest.status == 200) {
              this.enterpriseArrs.splice(index, 1)
              // this.enterpriseArrslength = this.enterpriseArrs.length || 0;
              if (item.isSuperAdmin == 1) {
                this.$store.commit('user/setToken', '')
                if (this.companyInfo.id == item.id) {
                  this.$router.push({ path: '/login' })
                }

                this.$message.success('解散企业成功!')
              } else {
                this.$message.success('删除企业成功!')
              }
            } else {
              this.$message({
                msg: rest.message,
                type: 'error'
              })
            }
          })
        })
        .catch(() => {})
    },
    // =========================   API ===========================
    //后台请求 所在企业列表
    async _getDefaultList(param) {
      let that = this
      await this.$apis.HEADERGETCOMPANYLIST(param).then((res) => {
        if (res.status == 200) {
          that.enterpriseArrs.splice(0, that.enterpriseArrs.length) //每次请求时提前清空数组
          if (res.data.rows.length > 0) {
            that.enterpriseArrs = [...res.data.rows]
            // that.enterpriseArrslength = ;
            that.ssss(res.data.rows.length)
            //显示企业列表
            if (this.fromPage != 2) {
              that.statusPassSms = 2
            }
          } else {
            //没有默认企业  企业列表则显示创建团队 面板
            that.statusPassSms = 3 // 调试专用
          }
          this.enterpriseArrslengthState = true
        } else {
          that.$message({
            msg: res.message,
            type: 'error'
          })
          this.enterpriseArrslengthState = true
        }
      })
    },
    ssss(numberas) {
      this.enterpriseArrslength = numberas
    },
    //获取（查询）具体企业信息
    async _specifyQuery(id, companyName) {
      var that = this
      await this.$apis
        .HEADERGETCOMPANYDETAIL(id, {
          apiStatus: 1
        })
        .then((res) => {
          if (res.status == 200) {
            if (res.data) {
              //对具体企业信息进行存储
              this.$store.commit('user/setCompanyInfo', res.data)
              this.updateTokenRefresh(res.data.token)
              // this.updateToken(res.data, 0) //企业列表点击 0
              let objs_ = {}
              objs_.id = res.data.id
              objs_.name = res.data.name
              //创建企业埋点 数据埋点c
              // this.$mixpanelUtil.register_(objs_);
              this.centerDialogVisible = false
              this.$emit('closeSwitchCompany')
            } else {
              this.$message({
                msg: '当前企业不存在!',
                type: 'error'
              })
            }
          } else if (
            res.status == 52012 ||
            res.status == 52017 ||
            res.status == 52019 ||
            res.status == 52020
          ) {
            // this.$message.warn(res.message)
            that.$store.commit('common/apiRequestData', {
              visible: true,
              type: res.status,
              companyId: id,
              companyName
            })
            this.centerDialogVisible = false
            this.$emit('closeSwitchCompany')
          } else {
            this.$message({
              msg: res.message,
              type: 'error'
            })
          }
        })
    },
    //后台请求 个人信息
    async _userInfo(param, type_btn) {
      await this.$apis.USERINFOBYMOBILE(param).then((res) => {
        if (res.status == 200) {
          //判断个人信息中是否有值  有值则正常存储
          this.$store.commit('user/setUserInfo', res.data)
          //数据埋点 超级属性 用户 初始化
          this.$mixpanelUtil.reset_()
          this.$mixpanelUtil.init()
          this.$mixpanelUtil.identify_(res.data.mobile)
          this.$mixpanelUtil.alias_(res.data.mobile)
          this.$mixpanelUtil.set_()
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    //提交团队信息给后台
    async _teamMessage(_obj) {
      await this.$apis.HEADERCOMPANYADD(_obj).then((res) => {
        if (res.status == 200) {
          this.$mixpanelUtil.track('Create Company')
          if (res.data) {
            this.getCompanyInfo(res.data.id)
            // this.$store.commit('user/setCompanyInfo', res.data)
            let objs_ = {}
            objs_.id = res.data.id
            objs_.name = res.data.name
            //创建企业埋点 数据埋点c
            //    this.$mixpanelUtil.register_(objs_);
            // this.updateTokenRefresh(res.data.token)
            // this.updateToken(res.data, 1) //创建企业 1
            //关闭主页目标详情
            // this.$emit('global:closeHomeDetail')
            // this.$emit('closeSwitchCompany')
            this.centerDialogVisible = false
            setTimeout(() => {
              this.statusPassSms = 3
            }, 1000)
          } else {
            this.$message({
              msg: '当前企业不存在!',
              type: 'error'
            })
            setTimeout(() => {
              this.statusPassSms = 3
            }, 1000)
          }
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 查看企业信息
    getCompanyInfo(companyId) {
      this.$apis.COMPANYINFO(companyId).then((res) => {
        if (res.status === 200) {
          console.log('查询企业信息', res)
          if (res.data) {
            this.updateTokenRefresh(res.data.token)
            this.$store.commit('user/setCompanyInfo', res.data)
            this.readRedpoint(res.data.id)
          }
        } else if (res.status === 52012) {
          // if (this.IsPC() === false) {
          //   this.$router.push('/successNotice')
          //   return
          // }
          this.$router.push({ path: '/' })
        } else {
          this.$message.error(res.message)
        }
      })
    },
    //刷新TOKEN
    // async updateToken(event, type_) {
    //   // type_：0 点击 1:创建
    //   if (type_ === 1) {
    //     await this.$apis.GETUSERTOKEN().then((res) => {
    //       if (res.status == '200') {
    //         //存储Token到本地 Cookie
    //         this.updateTokenRefresh(res.data)
    //       } else {
    //         this.$message.error(res.message)
    //       }
    //     })
    //   } else {
    //     this.updateTokenRefresh(event.token)
    //   }
    // },
    async updateTokenRefresh(token) {
      this.$store.commit('user/setToken', token)
      this.getUserRoleProgress()
      let obj_ = {}
      obj_.username = this.userInfo.mobile
      await this._userInfo(obj_)
      this.$emit('global:homeListContent') //首页
      //个人设置
      //this.$emit('global:settingPass');
      this.$emit('global:settingInformation')
      this.$emit('global:settingNotify')
      // this.$emit('global:settingUpdate');
      //企业后台
      this.$emit('global:backCycle')
      this.$emit('global:backInformations')
      this.$emit('global:backTeams')
      this.$emit('global:backUpdate')
      //关闭主页目标详情
      this.$emit('global:closeHomeDetail')
      this.$emit('closeSwitchCompany')
      this.$emit('global:closeSwitchCompany')
      //跳转到首页
      // this.$router.replace({ path: '/' })
      //关闭弹框
      this.$dialogClose()
    },
    //创建周期
    async createClye() {
      await this.$apis.HEADERADDCYCLE().then((res) => {
        if (res.status == '200') {
          //更新首页数据=======================================================
          this.$emit('global:homeListContent')
          //存储Token到本地 Cookie
          console.log('周期创建完成')
        } else {
          this.$message.error(res.message)
        }
      })
    },
    //创建默认任务
    async ranloTask() {
      await this.$apis.HEADERADDINITUSERTASK().then((res) => {
        if (res.status == '200') {
          //存储Token到本地 Cookie
          console.log('创建完成')
        } else {
          this.$message.error(res.message)
        }
      })
    },
    //创建通知设置
    async settingNotting() {
      await this.$apis.HEADERADDINITNOTIFYSET().then((res) => {
        if (res.status == '200') {
          //存储Token到本地 Cookie
          console.log('创建通知设置完成')
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 查看引导流程
    getUserRoleProgress() {
      this.loginLoading = true
      this.$apis.USERROLEPROGRESS().then((res) => {
        if (res.status === 200) {
          console.log('引导流程', res)
          if (res.data && res.data.finish === true) {
            this.$router.push('/')
          } else if (res.data.step === 1) {
            // 创建okr
            this.$router.push({ path: '/stepOne' })
          }
          this.loginLoading = false
        } else {
          this.$message.error(res.message)
          this.loginLoading = false
        }
      })
    },
    readRedpoint(companyId) {
      redpointFun.sendBody({
        ReplyRed: `{ "userId": "${this.userInfo.id}", "companyId": "${companyId}" }`
      })
    }
    //查看引导流程是否结束
    // async getUserRoleProgress() {
    //   await this.$apis.GETCOMCURRENTPROCESS().then((res) => {
    //     if (res.status == 200) {
    //       if (res.data) {
    //         let _obj = {}
    //         _obj = res.data
    //         _obj.userId = this.userInfo.id
    //         _obj.companyId = this.companyInfo.id
    //         if (this.roleIdsObj.length == 0) {
    //           this.roleIdsObj.push(_obj)
    //         } else {
    //           let _booleans_ = true
    //           for (let i = 0; i < this.roleIdsObj.length; i++) {
    //             if (
    //               this.roleIdsObj[i].userId == this.userInfo.id &&
    //               this.roleIdsObj[i].companyId == this.companyInfo.id
    //             ) {
    //               this.roleIdsObj.splice(i, 1, _obj)
    //               _booleans_ = false
    //             }
    //           }
    //           if (_booleans_ == true) {
    //             this.roleIdsObj.push(_obj)
    //           }
    //         }
    //         //存到仓库中
    //         this.$store.commit('user/setRoleIdsObj', this.roleIdsObj)
    //         //判断流程是否结束
    //         // this._isFirstBtn(1)//首次跳过按钮的显示
    //         if (res.data.isFirst === 1) {
    //           this.$store.commit('user/setIsFirstBtn', 0)
    //         } else {
    //           this.$store.commit('user/setIsFirstBtn', 1)
    //         }
    //         if (res.data.finish == true) {
    //           // if(res.data.finish == true || res.data.isFirst === 0) {//isFirst 1：首次 0:非首次
    //           this.$router.push({ path: '/' })
    //         } else if (res.data.step == 0) {
    //           //角色选择
    //           this.statusPassSms = 6
    //           //角色字典请求 === 放在页面名字填写之后 20210324
    //           this.getUserRole()
    //         } else if (res.data.step == 1) {
    //           //创建okr
    //           this.$router.push({ path: '/step1' })
    //         } else if (res.data.step == 2) {
    //           if (this.stepObject.objsArr.length > 0) {
    //             //检测是否之前存储过相应的字段
    //             let boolean_ = true
    //             for (let i = 0; i < this.stepObject.objsArr.length; i++) {
    //               if (
    //                 this.stepObject.objsArr[i].userId == this.userInfo.id &&
    //                 this.stepObject.objsArr[i].companyId == this.companyInfo.id
    //               ) {
    //                 if (this.stepObject.objsArr[i].step == 1) {
    //                   //创建目标
    //                   this.$router.push({ path: '/step2' })
    //                 } else if (this.stepObject.objsArr[i].step == 2) {
    //                   //创建目标
    //                   this.$router.push({ path: '/step2' })
    //                 } else if (this.stepObject.objsArr[i].step == 3) {
    //                   //创建目标
    //                   this.$router.push({ path: '/step3' })
    //                 } else if (this.stepObject.objsArr[i].step == 4) {
    //                   //创建目标
    //                   this.$router.push({ path: '/step4' })
    //                 } else if (this.stepObject.objsArr[i].step == 5) {
    //                   //创建目标
    //                   this.$router.push({ path: '/step5' })
    //                 }
    //                 boolean_ = false
    //               }
    //             }
    //             if (boolean_ == true) {
    //               //创建目标
    //               this.$router.push({ path: '/step2' })
    //             }
    //           } else {
    //             //创建目标
    //             this.$router.push({ path: '/step2' })
    //           }
    //         }
    //       }
    //     } else {
    //       if (res.status == 62206) {
    //         this.$message.warn('周期不存在，请联系管理员!')
    //       } else {
    //         this.$message({
    //           msg: res.message,
    //           type: 'error'
    //         })
    //       }
    //     }
    //   })
    // }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../../assets/styles/variable.scss'; // 定义的变量
.apply-demo-dialog {
  z-index: 9999999 !important;
}
::v-deep .el-dialog__wrapper {
  overflow: hidden !important;
}
.activity-free-div {
  width: 58px;
  vertical-align: middle;
  margin-left: 20px;
  margin-top: 10px;
  img {
    width: 58px;
    height: 18px;
  }
}
.company-history-wrap {
  .right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .date {
      color: #6e7491;
      margin-right: 8px;
    }
    .state {
      font-weight: 500;
    }
    .state0 {
      color: #ff9900;
    }
    .state1,
    .state3 {
      color: #6e7491;
    }
    .state2 {
      color: #ed2e7e;
    }
  }
  .register_enterprise_out {
    margin-top: 0px !important;
    min-height: 200px;
    height: calc(85vh - 180px) !important;
  }
  li {
    cursor: default !important;
    &:hover {
      background: none !important;
    }
    .name {
      max-width: 180px !important;
    }
  }
}
.flex-a-c-center-one {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  .return {
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 8px;
    color: #14142b;
    .icon {
      font-size: 30px;
      width: 40px;
      height: 40px;
    }
    &:hover {
      background: #f5f6fa;
      color: #3a78f6;
    }
  }
}
.company-history {
  display: inline-block;
  margin: 12px 0px 0px 16px;
  color: #3a78f6;
  line-height: 16px;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
  border-bottom: 1px #fff solid;
  float: left;
  &:hover {
    border-bottom: 1px #3a78f6 solid;
  }
}
.foot-title {
  width: 150px;
  text-align: center;
  border-bottom: 1px solid #d9dbe9;
  position: relative;
  color: #6e7491;
  margin: 0px auto 30px auto;
  span {
    background: #fff;
    position: absolute;
    width: 50px;
    margin: 0px auto;
    text-align: center;
    left: 0px;
    right: 0px;
    top: -7px;
  }
}
::v-deep .el-dialog {
  padding: 32px;
  max-height: 79vh;
  .el-dialog__body {
    padding: 0px !important;
  }
  .el-dialog__footer {
    padding: 0px 0px 0px 0px;
  }
}
::v-deep .el-dialog__header {
  border-bottom: none;
  padding: 0px 0px 12px 0px;
  .title-txt {
    color: #14142b;
    line-height: 40px;
    font-size: 24px;
    font-weight: 700;
    margin: 0px;
  }
}
::v-deep .el-dialog__headerbtn {
  width: 24px;
  height: 24px;
  // background: url(../../../../../assets/icons/common/dialogClose.png);
  background-size: 100% 100%;
  cursor: pointer;
  &:before {
    content: '';
  }
  &:hover {
    border-radius: 4px;
    background-color: #f5f6fa;
  }
  .el-icon-close {
    font-size: 24px;
    color: #14142b;
    // font-weight: 500;
    // content: '';
  }
}
.companyNull {
  margin: 0px auto;
  text-align: center;
  ::v-deep .no-data-show-text {
    display: none;
  }
  .svg-wrap {
    width: 140px;
    height: 140px;
    margin: 0px auto;
    text-align: center;
    border-radius: 70px;
    background: #f5f6fa;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .svg-img {
    width: 85px;
    height: 85px;
  }
  p.title {
    margin: 24px 0px;
    font-weight: 500;
    font-size: 18px;
    color: #444558;
    line-height: 22px;
  }
}
.home_box {
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-bottom: 16px;
  overflow-y: auto;
  .register_box {
    width: 100%;
    height: 100%;
    position: relative;
    .register_enterprise_out {
      margin-top: 28px;
      max-height: calc(85vh - 380px);
      min-height: 200px;
      overflow-y: auto;
      li {
        border-radius: 10px;
        height: 48px;
        line-height: 48px;
        color: #666; // eslint-disable-line
        cursor: pointer;
        width: 100%;
        padding: 0px 16px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // overflow: auto;
        .left {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .logo {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .img {
              width: 20px;
              height: 20px;
              border-radius: 4px;
              margin-right: 8px;
            }
            .number {
              width: 18px;
              height: 18px;
              line-height: 16px;
              background: #ed2e7e;
              border: 2px solid #ffffff;
              border-radius: 10px;
              position: absolute;
              top: -10px;
              right: 0px;
              font-size: 12px;
              text-align: center;
              color: #fff;
            }
          }
          .name {
            max-width: 300px;
            word-break: break-word;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -moz-box-orient: vertical;
            -webkit-line-clamp: 1;
            -moz-line-clamp: 1;
            overflow: hidden;
            &:before {
              content: '';
              display: block;
            }
          }
          .name-color {
            color: #ed2e7e;
          }
        }
        .right {
          color: #a0a3bd;
          font-size: 14px;
        }
        img.login_icon_no_select {
          width: 24px;
          align-self: center;
          margin-right: 5px;
          position: relative;
          top: 4px;
        }
        button {
          float: right;
          margin-right: 16px;
          padding: 6px 15px;
          font-size: 14px;
          border-radius: 4px;
          display: inline-block;
          line-height: 1;
          border: 1px solid #3068ff;
          background: #fff;
          color: #3068ff;
        }
        &:hover {
          background: #f5f6fa;
        }
      }
      li:last-child {
        border: 0;
      }
      .li_hover {
        background: #f4f7fa;
        border: 0 !important;
        line-height: 30px;
        color: #333 !important;
        img.login_icon_no_select {
          width: 24px;
          color: #333;
        }
      }
    }
    .register_fieldset_ctrate {
      border: 0;
      margin-top: 20px;
      input {
        width: 100%;
        font-size: 12px;
        line-height: 40px;
        height: 40px;
        text-indent: 10px;
        border-radius: 10px;
        border: 1px solid #ddd;
        margin-bottom: 6px;
        color: #969fbe;
      }
    }
    .register_fieldset {
      border: 1px solid;
      border-radius: 5px;
      font-size: 14px;
      line-height: 40px;
      label {
        margin: 0 5px;
        color: #333;
        font-size: 12px;
      }
      .register_input {
        border: 0;
        font-size: 12px;
        color: $black-sms-input;
      }
      .register_clear_icon {
        width: 20px;
        align-self: center;
        cursor: pointer;
        margin: 0 5px;
      }
    }
    p {
      &.register_title {
        font-weight: 600;
        line-height: 48px;
        font-size: 28px;
        color: #333;
      }
      &.register_text_back {
        font-size: 16px;
        line-height: 24px;
        color: #666; // eslint-disable-line
        margin-bottom: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        .register_back {
          display: inline-block;
          width: 20px;
          align-self: center;
          position: relative;
          top: 5px;
          margin-right: 6px;
        }
      }
      &.register_text {
        font-size: 14px;
        line-height: 24px;
        color: #666; // eslint-disable-line
        .register_link {
          color: $denim;
        }
        .register_link_tel {
          color: #333;
        }
      }
      &.register_message {
        font-size: 12px;
        line-height: 14px;
        color: $red-message;
        margin-bottom: 6px;
      }
    }
    .register_fieldset_pass {
      display: block;
      input {
        width: 100%;
        line-height: 50px;
        border-radius: 10px;
        border: 1px solid #ddd;
        text-indent: 10px;
      }
    }
    .register_fieldset_sms {
      padding: 20px 0;
      input {
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        margin-left: 10px;
        border: 1px solid $black-sms-input;
      }
      input:focus {
        border: 1px solid $denim;
      }
      .fieldset_sms_line {
        display: inline-block;
        width: 50px;
        height: 2px;
        border-bottom: 1px solid #ddd;
        align-self: center;
        margin-left: 10px;
      }
    }
  }
}
.el-button--primary.is-plain {
  display: block;
  margin: 0 auto !important;
}
.register_next {
  width: 170px;
  height: 48px;
  background: rgba(90, 135, 255, 1);
  border-radius: 16px;
  font-weight: 500;
  text-align: center;
  line-height: 48px;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  margin: 0px auto;
  cursor: pointer;
  display: block;
  padding: 0px;
  box-shadow: 0px 10px 24px -7px rgba(58, 120, 246, 0.5);
}
</style>
