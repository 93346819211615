<template>
  <div class="kr-content-wrap">
    <div class="kr-content">
      <div class="kr-content-left">
        <div class="kr-tag">KR{{krIndex + 1}}</div>
        <el-tooltip :content="$utils.changeToHtml(krItem.title)" :open-delay="300" placement="top">
          <div slot="content" v-html="$utils.changeToHtml(krItem.title)"></div>
          <div class="kr-title" v-html="$utils.changeToHtml(krItem.title)"></div>
        </el-tooltip>
      </div>
      <div class="kr-weight">
        权重：
        <span>{{krItem.weight}}%</span>
      </div>
    </div>
    <div class="kr-info" @click.stop>
      <div
        class="kr-info-list false"
        v-if="krItem.quantificationType === 1"
      >完成情况：{{krItem.quantificationStatus === 0 ? '未完成' : '已完成'}}</div>
      <template v-else>
        <div class="kr-info-list">
          量化单位：
          <span>{{krItem.quantificationUnit}}</span>
        </div>
        <div class="kr-info-list">
          初始值：
          <span>{{krItem.quantificationStart}}</span>
        </div>
        <div class="kr-info-list">
          目标值：
          <span>{{krItem.quantificationEnd}}</span>
        </div>
        <div class="kr-info-list false">
          完成值：
          <span>{{krItem.quantificationActualValue}}</span>
        </div>
      </template>
      <div class="kr-update">
        <key-results-alert
          :obj-data="krItem"
          type="okr"
          okrType="list"
          @getKeyResultsAlertClose="changeObjectiveTitle('修改成功')"
        >
          <div v-if="userInfo.id === okrData.createUserid" class="okr-process-add">手动更新</div>
        </key-results-alert>
      </div>
    </div>
  </div>
</template>
<script>
import keyResultsAlert from '@/views/okr/components/singleOkrItem/keyResultsAlert.vue'
export default {
  components: {
    keyResultsAlert
  },
  props: {
    krIndex: {
      default: 0
    },
    krItem: {
      type: Object,
      default: () => {
        return {}
      }
    },
    okrData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      isSharer: false // 是否是共享人
    }
  },
  watch: {
    okrData: {
      handler(val) {
        // this.isSharer = this.okrData.objectivesSharedUserList && this.okrData.objectivesSharedUserList.some(
        //   (item) => item.user.id === this.userInfo.id
        // )
      },
      immediate: true
    }
  },
  created() {
  },
  methods: {
    // 处理关键结果内容保存展现
    changeObjectiveTitle(state) {
      if (state === '修改成功') {
        this.$emit('refresh')
      }
    }
  }
}
</script>
<style lang="less" scoped>
.kr-content-wrap {
  .kr-content {
    .flex-bt();
    align-items: flex-start;
    .kr-content-left {
      .flex-l();
      align-items: flex-start;
      .kr-tag {
        min-width: 32px;
        height: 26px;
        border-radius: 100px;
        font-weight: 500;
        color: #3a70ff;
        line-height: 26px;
        text-align: center;
        margin-right: 8px;
        font-size: 16px;
      }
      .kr-title {
        flex: 1;
        min-width: 0;
        font-size: 16px;
        font-weight: bold;
        line-height: 26px;
      }
    }
    .kr-weight {
      font-size: 16px;
      color: #a0a3bd;
      margin-left: 60px;
      span {
        color: #444558;
      }
    }
  }
  .kr-info {
    .flex-l();
    font-size: 14px;
    margin: 20px 0px 20px 40px;
    .kr-info-list {
      margin-right: 42px;
      color: #a0a3bd;
      span {
        color: #444558;
      }
    }
    .kr-info-list.false {
      margin-right: 0px;
    }
    .kr-update {
      color: #3a70ff;
      margin-left: 12px;
      cursor: pointer;
      &:hover {
        opacity: 0.54;
      }
    }
  }
}
</style>
