const state = {
  companyInfo: JSON.parse(localStorage.getItem('okrt-companyInfo')), // 公司信息
  isFirstBtn: 1 // 引导按钮 1:显示  0 隐藏
}
const actions = {
  // 当前前项目id
  _isFirstBtn(context, val) {
    context.commit('isFirstBtn', val)
  }
}
const mutations = {
  // 存储公司信息
  setCompanyInfo(state, companyInfo) {
    localStorage.setItem('okrt-companyInfo', JSON.stringify(companyInfo))
    state.companyInfo = companyInfo
  },
  isFirstBtn(state, val) {
    state.isFirstBtn = val
  }
}
export default {
  namespaced: true,
  state,
  actions,
  mutations
}
