export default [
  {
    path: '/loginErrorText',
    name: 'loginErrorText',
    component: () => {
      return import(
        /* webpackChunkName: "loginErrorText" */
        '../../views/global/auth/loginErrorText.vue'
      )
    }
  }
]
