var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "目标权限设置",
        visible: _vm.dialogVisible,
        width: "880px",
        "append-to-body": true,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "vueloading",
              rawName: "v-vueloading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "okr-content",
        },
        [
          _c("div", { staticClass: "okr-title" }, [
            _c("div", { staticClass: "title-content" }, [
              _c("img", {
                staticClass: "okr-title-icon",
                attrs: { src: require("@/assets/imgs/okr/objective.png") },
              }),
              _c("div", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$utils.changeToHtml(_vm.objData.content)
                  ),
                },
              }),
            ]),
            _c(
              "div",
              { staticClass: "permission" },
              [
                _c("permission", {
                  attrs: { item: _vm.objData.objectivesPower },
                  on: { confirm: _vm.permissionConfirm },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "kr-title-list" },
            _vm._l(_vm.objData.keyresultsList, function (keyResult, index) {
              return _c(
                "div",
                { key: keyResult.index, staticClass: "kr-item" },
                [
                  _c(
                    "div",
                    { staticClass: "up-line" },
                    [
                      _c("div", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              !(
                                index ===
                                _vm.objData.keyresultsList.length - 1
                              ) && index !== 9,
                            expression:
                              "\n              !(index === objData.keyresultsList.length - 1) && index !== 9\n            ",
                          },
                        ],
                        staticClass: "down-left-line",
                      }),
                      _c("Icon", {
                        staticClass: "line-icon",
                        attrs: { "icon-class": "icon-okrline-down" },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "kr-title-box" }, [
                    _c("div", { staticClass: "kr-index" }, [
                      _vm._v("KR" + _vm._s(index + 1)),
                    ]),
                    _c("div", {
                      staticClass: "kr-title",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$utils.changeToHtml(keyResult.title)
                        ),
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "kr-weight" },
                      [
                        _c("permission", {
                          attrs: {
                            item: keyResult.objectivesPower,
                            type: 2,
                            index: index + 1,
                          },
                          on: { confirm: _vm.permissionConfirm },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              )
            }),
            0
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "bottom-step-buttons" },
        [
          _c(
            "el-button",
            { staticClass: "left-button", on: { click: _vm.handleClose } },
            [_vm._v("取消")]
          ),
          _c(
            "L-button",
            {
              attrs: { loading: _vm.addLoading },
              on: {
                clickEvt: function ($event) {
                  return _vm.getTargetAligningSubmit("提交")
                },
              },
            },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }