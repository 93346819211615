// 日历
// 统一管理所有的基础请求
import apis from '../apis_moudles/index'
import req from '../request'

// 获取订阅列表
const SUBSCRIBELIST = (params) => {
  return req.get(`${apis.subscribeList}`, {
    params
  })
}
// 获取时间范围内事件
const SCHEDULEDATELIST = (params) => {
  return req.get(`${apis.scheduleDateList}`, {
    params
  })
}
// 日程删除
const SCHEDULEDEL = (id, params) => {
  return req.delete(`${apis.scheduleDel}${id}`, {
    params
  })
}
// 响应日程
const SCHEDULEDECISION = (params) => {
  return req.put(`${apis.scheduleDecision}`, params)
}
// 日程详情
const SCHEDULEDETAIL = (id, params) => {
  return req.get(`${apis.scheduleDetail}${id}`, { params })
}
// 日程新增编辑
const SCHEDULEADDEDIT = (type, params) => {
  if (type === 'post') {
    return req.post(`${apis.scheduleAddEdit}`, params)
  } else {
    return req.put(`${apis.scheduleAddEdit}`, params)
  }
}
// 获取日程展示类型
const SCHEDULESHOWTYPES = (params) => {
  return req.get(`${apis.scheduleShowTypes}`, {
    params
  })
}
// 设置日程展示类型
const SCHEDULESETTYPES = (params) => {
  return req.put(`${apis.scheduleSetTypes}`, params)
}
// 获取日程内任务列表
const SCHEDULETASKLIST = (params) => {
  return req.get(`${apis.scheduleTaskList}`, {
    params
  })
}
// 导出基础请求函数
export default [
  {
    SUBSCRIBELIST
  },
  {
    SCHEDULEDATELIST
  },
  {
    SCHEDULEDEL
  },
  {
    SCHEDULEDECISION
  },
  {
    SCHEDULEDETAIL
  },
  {
    SCHEDULEADDEDIT
  },
  {
    SCHEDULESHOWTYPES
  },
  {
    SCHEDULESETTYPES
  },
  {
    SCHEDULETASKLIST
  }
]
