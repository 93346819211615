<template>
  <el-drawer
    :append-to-body="true"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    size="800px"
    :title="`OKR指标`"
    direction="rtl"
    :class="`el-drawer-header-visible-${isShowDetails}`"
    v-on="$listeners"
  >
    <div class="field-content-wrap">
      <div class="kr-list-wrap" v-vueloading="pageLoading">
        <div class="kr-list" v-for="(item, index) in okrData" :key="item.id">
          <kr-item :krItem="item" :krIndex="index" :okrData="item" @refresh="queryObjectiveDetail" />
          <div class="add-index-wrap" v-if="userInfo.id === item.createUserid">
            <div class="add-wrap" @click="syncKpiClick(index, item)">
              <Icon icon-class="icon-tianjia3" class="icon" />同步至绩效
            </div>
            <div>可将 OKR 指标同步至「制定指标」流程的绩效考核中</div>
          </div>
          <div
            class="kpi-list-wrap"
            v-if="item.performanceCustomIndexDtoList && (item.performanceCustomIndexDtoList || []).length > 0"
          >
            <kpi-item
              v-for="(kpiItem, kpiIndex) in item.performanceCustomIndexDtoList"
              :key="kpiItem.examineId"
              :item="kpiItem.performanceDto && kpiItem.performanceDto.examineDto"
              :kpi-detail="kpiItem.performanceDto"
              from-type="myKpi"
            >
              <template
                slot="kpiItemRight"
                v-if="userInfo.id === item.createUserid && kpiItem.performanceDto && kpiItem.performanceDto.currentProcessStep === 0"
              >
                <div class="border-button open-kpi" @click.stop="closeSyncKpi(item, kpiItem)">取消同步</div>
              </template>
            </kpi-item>
          </div>
        </div>
      </div>
      <!-- <div class="bottom-list-wrap">
        <div class="bottom-step-buttons">
          <button @click="handleClose" class="left-button">取消</button>
          <el-button v-preventReClick @click="saveBtn()">保存</el-button>
        </div>
      </div>-->
    </div>
    <syncKpi
      v-if="syncKpiVisible"
      v-model="syncKpiVisible"
      :krId="syncKpiObj.krId"
      :defaultList="syncKpiObj.defaultList"
      @refresh="syncKpiRefresh"
    />
  </el-drawer>
</template>
<script>
import krItem from '@/views/okr/components/kpiIndex/krItem'
import kpiItem from '@/views/kpiV2/components/kpiItem' // 计划
import syncKpi from '@/views/okr/components/kpiIndex/syncKpi'
export default {
  components: {
    krItem,
    kpiItem,
    syncKpi
  },
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  props: {
    dialogShow: {
      type: Boolean,
      default: true
    },
    okrId: {
      default: ''
    }
  },
  data() {
    return {
      dialogVisible: this.dialogShow,
      pageLoading: true,
      okrData: '',
      syncKpiVisible: false,
      syncKpiObj: '',
      isShowDetails: false
    }
  },
  created() {
  },
  watch: {
    dialogShow: {
      handler(val) {
        this.dialogVisible = val
        if (val) {
          this.queryObjectiveDetail()
        }
      },
      immediate: true
    }
  },
  mounted() {
  },
  methods: {
    syncKpiRefresh() {
      this.$emit('refresh')
      this.queryObjectiveDetail()
    },
    closeSyncKpi(krItem, kpiItem) {
      const params = {
        performanceCustomIndexId: kpiItem.performanceCustomIndexId,
        performanceId: kpiItem.performanceId,
        templateId: kpiItem.templateId,
        templateDimensionId: kpiItem.templateDimensionId,
        templateIndexId: kpiItem.templateIndexId,
        examineId: kpiItem.examineId,
        lastUpdateStep: kpiItem.lastUpdateStep,
        sort: kpiItem.sort,
        businessType: kpiItem.businessType,
        businessId: krItem.businessId
      }
      this.$apis.SYNCPERFORMANCECUSTOMINDEXKRCANCEL(params).then((rest) => {
        if (rest.status === 200) {
          this.$message.success('取消同步成功')
          this.queryObjectiveDetail()
        } else {
          this.$message.error(rest.message)
        }
      })
    },
    syncKpiClick(index, item) {
      this.syncKpiObj = {
        krId: item.id,
        index,
        defaultList: item.performanceCustomIndexDtoList || []
      }
      this.syncKpiVisible = true
    },
    syncKpiConfirm(list) {
      this.okrData.keyresultsList[this.syncKpiObj.index].performanceCustomIndexDtoList = list
      this.okrData = JSON.parse(JSON.stringify(this.okrData))
    },
    // 获取目标详情
    queryObjectiveDetail() {
      this.$apis.SYNCPERFORMANCEKRLIST({ objId: this.okrId }).then((rest) => {
        this.pageLoading = false
        this.okrData = rest.data
        this.$nextTick(() => {
          this.isShowDetails = true
        })
      }).catch((err) => {
        this.pageLoading = false
        this.$message.error('目标已被删除或不存在')
        this.$nextTick(() => {
          this.isShowDetails = true
        })
      })
    },
    handleClose() {
      this.dialogVisible = false
      this.$emit('change', false)
    },
    saveBtn() {
      this.$refs[`ruleForm`].validate((valid) => {
        if (!valid) {
          return
        }
      })
      var params = JSON.parse(JSON.stringify(this.dataList))
      if (params.name.trim() === '') {
        this.$message.warn('名称不能为空哦！')
        return
      }
      if (params.scoreWay === 0) {
        if (((params.scoreRangeStart || '') === '' && params.scoreRangeStart !== 0) || ((params.scoreRangeEnd || '') === '' && params.scoreRangeEnd !== 0 )) {
          this.$message.warn('分值范围不能为空哦！')
          return
        }
      }
      if (params.okrWeightWay === 0 && params.type === 2 && ((params.weight || '') === '' && params.weight !== 0)) {
        this.$message.warn('您设置的是「平均调整权重」，维度权重必须填写才行哦～')
        return
      }
      var state = true
      if (params.scoreWay === 1) {
        if (params.templateDimensionScoringDtoList.length === 0) {
          this.$message.warn('选项不能为空哦！')
          state = false
          return
        }
        params.templateDimensionScoringDtoList.forEach((item, index) => {
          item.sort = index + 1
          if (item.name.trim() === '') {
            this.$message.warn('选项名称不能为空哦！')
            state = false
            return
          }
          if ((item.score || '') === '' && item.score !== 0) {
            this.$message.warn('对应分值不能为空哦！')
            state = false
            return
          }
        })
      }
      if (!state) {
        return
      }
      params.scoreRangeExtUpPower = (params.scoreRangeExtUpPower ? 1 : 0)
      params.templateDimensionFieldDtoList = this.templateDimensionFieldDtoList[params.type].fieldList
      params.templateDimensionFieldDtoList.forEach((item, index) => {
        item.sort = index + 1
      })
      if (this.ajaxUrl) {
        this.ajaxUrl(params).then((res) => {
          if (res && res.status === 200) {
            res.data.templateDimensionFieldDtoList.forEach((item) => {
              // item.name = item.templateFieldDto.name
              // item.type = item.templateFieldDto.type
              // item.typeIdentifying = item.templateFieldDto.typeIdentifying
              // item.fieldAttribute = item.templateFieldDto.fieldAttribute
            })
            res.data.templateDimensionFieldDtoList = params.templateDimensionFieldDtoList
            this.$emit('confirm', res.data)
            this.handleClose()
          } else {
            this.$message({
              msg: res && res.message,
              type: 'error'
            })
          }
        })
      } else {
        this.$emit('confirm', params)
        this.handleClose()
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../assets/styles/dialog.less';
.field-content-wrap {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 54px);
  .kr-list-wrap {
    flex: 1;
    overflow-y: auto;
    padding: 0px 24px 24px 24px;
    .kr-list {
      margin-top: 16px;
      border-radius: 12px;
      background: #f7f8fb;
      padding: 24px;
      .add-index-wrap {
        border-top: 1px solid rgba(217, 219, 233, 0.68);
        padding: 24px 0px 0px 0px;
        .flex-l();
        color: #a0a3bd;
        .add-wrap {
          .flex-l();
          color: #3a70ff;
          cursor: pointer;
          margin-right: 8px;
          .icon {
            font-size: 18px;
            margin-right: 8px;
          }
          &:hover {
            opacity: 0.54;
          }
        }
      }
      .kpi-list-wrap {
        ::v-deep {
          .kpi-item-wrap {
            background: #e9edfa;
            border-radius: 12px;
            padding: 16px 24px;
            box-shadow: none;
            .list-count,
            .list-user {
              display: none;
            }
            .title-wrap {
              font-size: 16px;
              padding-left: 0px;
              .name {
                max-width: 400px;
                &::before {
                  width: 0px;
                  display: none;
                }
              }
            }
            .title-wrap-true {
              &::after {
                width: 0px;
                display: none;
              }
            }
            .open-kpi {
              padding: 0px;
              &:hover {
                background: rgba(58, 120, 246, 0.12);
              }
            }
            &:hover {
              background: #e9edfa;
              -webkit-transform: translateY(0px);
              transform: translateY(0px);
            }
          }
          .kpi-item-wrap:nth-child(1) {
            margin-top: 20px;
          }
        }
      }
    }
  }
  .bottom-list-wrap {
    .bottom-step-buttons {
      height: 80px;
      padding: 0px 32px;
      background: #fff;
      box-shadow: 0px -5px 16px rgba(212, 213, 224, 0.2);
      .flex-l();
      justify-content: flex-end;
      .left-button,
      .el-button {
        border-radius: 8px;
        height: 32px;
      }
    }
  }
}
::v-deep {
  .el-drawer__header {
    border-bottom: 1px solid #d9dbe9;
  }
}
</style>
