var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "task-select" },
    [
      _c(
        "el-select",
        {
          attrs: {
            multiple: "",
            placeholder: "未选择",
            disabled: _vm.disabled,
            clearable: _vm.fieldInfo.required === 1,
          },
          on: { change: _vm.inputChange },
          model: {
            value: _vm.inputVal,
            callback: function ($$v) {
              _vm.inputVal = $$v
            },
            expression: "inputVal",
          },
        },
        _vm._l(_vm.fieldInfo.fieldsCustomfieldsChoices, function (item) {
          return _c("el-option", {
            key: item.id,
            attrs: { label: item.value, value: item.value },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }