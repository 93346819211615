const state = {
  taskTodoOrKrDict: {}, // 临时任务及Kr任务字段模板
  taskApproveRemindObj: {
    visible: false,
    taskDetail: {},
    businessData: '',
    businessActionType: 1, // 业务当次动作类型 : 1:完成任务 2:变更工作流 3:删除任务
    businessActionName: '完成任务'
  }
}
const actions = {
  // 临时任务字段模板
  _taskTodoOrKrDict(context, val) {
    context.commit('taskTodoOrKrDict', val)
  }
}
const mutations = {
  taskTodoOrKrDict(state, val) {
    state.taskTodoOrKrDict = val
  },
  taskApproveRemindObj(state, data) {
    state.taskApproveRemindObj = data
  }
}
export default {
  namespaced: true,
  state,
  actions,
  mutations
}
