var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "attach-project",
      on: {
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c(
        "el-popover",
        {
          ref: "attachProjectRef",
          attrs: {
            "popper-class": "attach-project-pop",
            "visible-arrow": false,
          },
          on: { show: _vm.queryProjectList, hide: _vm.hide },
        },
        [
          _c("div", { staticClass: "list-title" }, [_vm._v("关联项目")]),
          _c(
            "div",
            { staticClass: "project-list" },
            [
              _vm._l(_vm.projectList, function (item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "project-item",
                    on: {
                      click: function ($event) {
                        return _vm.addSelect(
                          item,
                          _vm.selectProjects.length === 0
                        )
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "project-content" }, [
                      _c(
                        "div",
                        {
                          staticClass: "project-icon",
                          style: `background:${item.colour}`,
                        },
                        [
                          _c("el-image", {
                            attrs: {
                              src:
                                item.logo ||
                                require("@/assets/imgs/common/avatar.png"),
                              fit: "cover",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "project-name" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ]),
                    _vm.selectProjects.length > 0 && _vm.isEdit
                      ? _c(
                          "div",
                          {
                            staticClass: "del-btn",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.delSelect(item)
                              },
                            },
                          },
                          [_c("i", { staticClass: "el-icon-close" })]
                        )
                      : _vm._e(),
                  ]
                )
              }),
              _vm.projectList.length === 0 && _vm.loading
                ? _c("div", [_c("no-data")], 1)
                : _vm._e(),
            ],
            2
          ),
          _vm.selectProjects.length > 0
            ? _c("add-attach", {
                ref: "addAttachRef",
                attrs: {
                  isEdit: _vm.isEdit,
                  "all-project-list": _vm.allProjectList,
                  "select-projects": _vm.selectProjects,
                },
                on: {
                  addSelect: function ($event) {
                    return _vm.addSelect($event, true)
                  },
                },
              })
            : _vm._e(),
          _c("div", { attrs: { slot: "reference" }, slot: "reference" }, [
            _vm.fromType === "meetingOkr"
              ? _c("div", [_vm._t("custom")], 2)
              : _vm.selectProjects.length === 0 && _vm.isEdit
              ? _c(
                  "div",
                  { staticClass: "attach-btn-text" },
                  [
                    _c("Icon", {
                      staticClass: "attach-icon",
                      attrs: { "icon-class": "icon-attachPro" },
                    }),
                    _c("div", [_vm._v("关联项目")]),
                  ],
                  1
                )
              : _vm.selectProjects.length === 1
              ? _c("div", [
                  _c("div", { staticClass: "project-content" }, [
                    _c(
                      "div",
                      {
                        staticClass: "project-icon",
                        style: `background:${_vm.selectProjects[0].projectBaseDto.colour}`,
                      },
                      [
                        _c("el-image", {
                          attrs: {
                            src:
                              _vm.selectProjects[0].projectBaseDto.logo ||
                              require("@/assets/imgs/common/avatar.png"),
                            fit: "cover",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "project-name" }, [
                      _vm._v(_vm._s(_vm.selectProjects[0].projectBaseDto.name)),
                    ]),
                  ]),
                ])
              : _c("div", { staticClass: "project-content" }, [
                  _c(
                    "div",
                    { staticClass: "project-icons" },
                    [
                      _vm._l(_vm.showProjects, function (item) {
                        return _c(
                          "div",
                          {
                            key: item.projectBaseDto.id,
                            staticClass: "project-icon",
                            style: `background:${item.projectBaseDto.colour}`,
                          },
                          [
                            _c("el-image", {
                              attrs: {
                                src:
                                  item.projectBaseDto.logo ||
                                  require("@/assets/imgs/common/avatar.png"),
                                fit: "cover",
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isEdit,
                              expression: "isEdit",
                            },
                          ],
                          staticClass: "project-name",
                        },
                        [
                          _vm._v(
                            "已关联" +
                              _vm._s(_vm.selectProjects.length) +
                              "个项目"
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
                ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }