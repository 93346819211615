const versionNumber = 'v1' // 版本
const versionNumber2 = 'v2' // 版本
// 训练营模块api
export default [
  {
  // 获取课程详情（首页）
  classroomInfo: `api/school/${versionNumber}/course/info`
},
{
  // 课程评论列表
  classroomComment: `/api/school/${versionNumber}/course/comment/list`
},
{
  // 视频播放
  videoPlay: `/api/school/${versionNumber}/course/play/`
},
{
  // 提交评论
  courseCommentSubmit: `/api/school/${versionNumber}/course/comment/commit`
},
{
  // 创建订单号
  createOrder: `/api/school/${versionNumber}/course/order/training/create`
},
{
  // 查询订单信息
  orderInfo: `/api/school/${versionNumber}/course/order/info`
},
{
  // 获取付款信息
  payInfo: `/api/school/${versionNumber2}/course/pay/order/create`
},
{
  // 提交视频播放记录
  videoPlayRecord: `/api/school/${versionNumber}/course/play/history/`
},
{
  // 广告提交视频播放记录
  mediaWatchHistory: `/api/school/${versionNumber}/media/watch/history/`
},
{
  // 训练营解锁邀请消息
  courseRemindSend: `/api/notify/${versionNumber}/notify/course/study/remind/send`
},
{
  // 训练营首页
  courseTrainingIndex: `/api/school/${versionNumber}/course/training`
},
{
  // 学习设置回显
  getStudySet: `/api/school/${versionNumber}/course/study/set/echo`
},
{
  // 学习设置
  courseStudySet: `/api/school/${versionNumber}/course/study/set/`
},
{
  // 待学习/逾期待学习课时列表
  courseWaitStudy: `/api/school/${versionNumber}/course/lesson`
},
{
  // 课程作业列表
  getHomeworkList: `/api/school/${versionNumber}/course/lesson/work`
},
{
  // 课程作业提交
  homeworkCommit: `/api/school/${versionNumber}/course/lesson/work/commit`
},
{
  // 课程作业操作类提交
  homeworkPlayCommit: `/api/school/${versionNumber}/course/lesson/work/play/commit`
},
{
  // 开始考试获取试卷详情
  getExamPaperInfo: `/api/school/${versionNumber}/course/exam/user/paper/info`
},
{
  // 提交试卷
  examPaperInfoCommit: `/api/school/${versionNumber}/course/exam/user/paper/commit`
},
{
  // 查看考试结果
  examPaperResult: `/api/school/${versionNumber}/course/exam/user/paper/result`
},
{
  // 考试统计
  examAnalyse: `/api/school/${versionNumber}/course/statistics`
},
{
  // 统计导出
  courseExport: `/api/school/${versionNumber}/course/statistics/export`
},
{
  // 优惠码校验
  payOrderVerify: `/api/school/${versionNumber}/course/pay/order/verify/promo`
}
]
