<template>
  <el-popover
    ref="popoverSetting"
    placement="bottom"
    trigger="click"
    popper-class="cooperate-select"
    @show="popoverShow(true)"
    @hide="popoverShow(false)"
  >
    <div class="select-more">
      <div class="dialog-list">
        <div class="list">
          <div class="left">角色:</div>
          <div class="right">
            <el-select
              v-model="cooperateVuexList.selectDialogList.roleOptionValue"
              multiple
              popper-class="cooperate-select"
            >
              <el-option
                v-for="(val, key) in cooperateVuexList.selectDialogList
                  .roleOption"
                :key="key"
                :label="val"
                :value="parseInt(key)"
              />
            </el-select>
          </div>
        </div>
        <div class="list">
          <div class="left">开始时间:</div>
          <div class="right">
            <!-- 开始时间算术运算符 -->
            <el-select
              v-model="
                cooperateVuexList.selectDialogList.startTimeArithmeticOperator
              "
              class="task-add-select-small-sm"
              placeholder="请选择"
            >
              <el-option
                v-for="(val, key) in cooperateVuexList.selectDialogList
                  .arithmeticOperator"
                :key="key"
                :label="val"
                :value="parseInt(key)"
              />
            </el-select>
          </div>
        </div>
        <div class="list">
          <div class="left">截止时间:</div>
          <div class="right">
            <el-select
              v-model="
                cooperateVuexList.selectDialogList.endTimeArithmeticOperator
              "
              class="task-add-select-small-sm"
              placeholder="请选择"
            >
              <el-option
                v-for="(val, key) in cooperateVuexList.selectDialogList
                  .arithmeticOperator"
                :key="key"
                :label="val"
                :value="parseInt(key)"
              />
            </el-select>
          </div>
        </div>
        <div class="list">
          <div class="left">状态:</div>
          <div class="right">
            <el-checkbox-group v-model="cooperateVuexList.selectDialogList.checkListValue">
              <el-checkbox
                v-for="(val, key) in cooperateVuexList.selectDialogList
                  .checkOptionList"
                :key="key"
                :label="key"
              >{{ val }}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="list">
          <div class="left">完成情况:</div>
          <div class="right">
            <el-radio-group v-model="cooperateVuexList.selectDialogList.completeValue">
              <el-radio
                v-for="(val, key) in cooperateVuexList.selectDialogList
                  .completeRadioList"
                :key="key"
                :label="key"
                :value="parseInt(key)"
                @click.native.prevent="radioClick(key)"
              >{{ val }}</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="btn-list">
          <div class="left" @click="reset()">重置</div>
          <div class="right">
            <div class="border-button" @click="cancel()">取消</div>
            <div class="blue-button" @click="confirm()">保存</div>
          </div>
        </div>
      </div>
    </div>
    <el-button slot="reference" class="select-btn">
      筛选
      <i class="el-select__caret el-input__icon el-icon-arrow-up"></i>
    </el-button>
  </el-popover>
</template>
<script>
/* eslint-disable */
import taskDict from '@/utils/enums/task' // 任务字典
import taskUtil from '@/utils/task' // 任务util
export default {
  components: {},
  props: {
    dialogList: {
      required: false,
      type: Object,
      default: {
        roleOptionValue: '', //角色
        roleOption: taskDict.TASKROLE,
        startTimeArithmeticOperator: 0, //开始时间
        endTimeArithmeticOperator: 0, //截止时间
        arithmeticOperator: taskDict.COOPERATESELECTLIST,
        checkListValue: [],
        checkOptionList: taskDict.TASKSTATUS,
        firstOptionValue: 0, //优先级
        firstOptionlist: taskDict.PRIORITYTYPEALL,
        completeValue: '',
        completeRadioList: taskDict.COMPLETERADIOLIST
      }
    },
    fromType: {
      default: 'pmDetail'
    }
  },
  data() {
    return {
      selectHeadDialogState: false,
      cooperateVuexList: ''
    }
  },
  created() {
    if (this.fromType === 'copyTask') {
      this.cooperateVuexList = {
        selectDialogList: this.$store.state.pm.copyTaskSelectDialogList
      }
    } else {
      this.cooperateVuexList = JSON.parse(JSON.stringify(this.$store.state.pm.cooperateVuexList))
    }
  },
  computed: {},
  mounted() {
    this.bus(this).$on('dialogCancel', (list) => {
      this.cancel()
    })
  },
  watch: {},
  methods: {
    // 优先级颜色
    priorityColor(val) {
      return taskUtil.priorityColor(val)
    },
    reset() {
      this.cooperateVuexList.selectDialogList.roleOptionValue = '' //角色
      this.cooperateVuexList.selectDialogList.startTimeArithmeticOperator = 0 //开始时间
      this.cooperateVuexList.selectDialogList.endTimeArithmeticOperator = 0 //截止时间
      this.cooperateVuexList.selectDialogList.checkListValue = []
      this.cooperateVuexList.selectDialogList.firstOptionValue = 0 //优先级
      this.cooperateVuexList.selectDialogList.completeValue = ''
    },
    popoverShow(state) {
      if (state) {
        if (this.fromType === 'copyTask') {
          this.cooperateVuexList = {
            selectDialogList: this.$store.state.pm.copyTaskSelectDialogList
          }
        } else {
          this.cooperateVuexList = JSON.parse(JSON.stringify(this.$store.state.pm.cooperateVuexList))
        }
      }
      this.selectHeadDialogState = state
      this.bus.$emit('selectHeadDialogState', state)
    },
    cancel() {
      if (this.selectHeadDialogState) {
        if (this.$refs.popoverSetting) {
          this.$refs.popoverSetting.doClose()
        }
      }
    },
    confirm() {
      if (this.fromType === 'copyTask') { 
        this.$store.commit('pm/copyTaskSelectDialogList', this.cooperateVuexList.selectDialogList)
        this.$emit('change')
      } else {
        this.$store.commit('pm/selectDialogList', this.cooperateVuexList.selectDialogList)
        if (this.cooperateVuexList.tabSelectId === 1) {
          this.bus.$emit('getProjectTaskList') //列表刷新数据
        } else if (this.cooperateVuexList.tabSelectId === 2) {
          this.bus.$emit('refreshGetProjectProcessList') //看板刷新数据
        }
      }
      if (this.$refs.popoverSetting) {
        this.$refs.popoverSetting.doClose()
      }
    },
    radioClick(e) {
      e === this.cooperateVuexList.selectDialogList.completeValue
        ? (this.cooperateVuexList.selectDialogList.completeValue = '')
        : (this.cooperateVuexList.selectDialogList.completeValue = e)
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../assets/styles/common.less';
::v-deep .el-select__tags {
  width: 275px !important;
  max-width: 275px !important;
}

.task-add-select-small-sm {
  margin-left: 0px !important;
}
.cooperate-select {
  z-index: 9999;
}
::v-deep .el-input__suffix {
  display: none;
}
.select-btn {
  font-size: 16px !important;
  color: #6e7491;
  font-weight: 400;
  &:hover {
    color: #005eff;
  }
}
.dialog-list {
  font-size: 14px;
  color: #a0a3bd;
  .pa-n(12, 0, 0, 12);
  .list {
    .min-h-n(36);
    .lh-n(36);
    .flex-l();
    .mg-b-n(4);
    .left {
      .w-n(100);
      .min-w-n(100);
    }
    .right {
      .w-n(290);
      .el-input__inner {
        .mg-b-n(10);
      }
      .el-checkbox {
        .mg-r-n(24);
      }
      .el-select {
        .w-n(275);
      }
    }
  }
  .btn-list {
    .mg-t-n(60);
    .flex-bt();
    .left {
      font-size: 14px;
      color: #3a78f6;
      cursor: pointer;
      &:hover {
        color: @hover-color;
      }
    }
    .right {
      .flex-r();
      font-size: 14px;
      .mg-l-n(8);

      // div {
      //   .pa-n(0, 65, 0, 65);
      //   .h-n(40);
      //   .lh-n(40);
      //   color: #fff;
      //   background: #005eff;
      //   .bd-r-n(20);
      //   .mg-l-n(8);
      //   cursor: pointer;
      // }
      // div:nth-child(1) {
      //   border: 1px solid #005eff;
      //   color: #005eff;
      //   background: none;
      // }
    }
  }
}
.select-more .el-radio,
.select-more .el-checkbox {
  .mg-r-n(24);
}
::v-deep .select-more .el-input__inner {
  width: 300px;
  min-width: 300px !important;
}
::v-deep .border-button {
  margin-right: 8px;
}
::v-deep .el-input__inner {
  &:hover {
    color: #3a78f6;
  }
}
</style>
<style>
.select-more .el-input__inner {
  padding: 0px;
  border: none;
}

.cooperate-select .el-input__inner::-webkit-input-placeholder {
  color: #444558;
}
.cooperate-select .el-input__inner::-moz-placeholder {
  color: #444558;
  opacity: 1;
}
.cooperate-select .el-input__inner::-ms-input-placeholder {
  color: #444558;
}
</style>
