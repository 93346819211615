<template>
  <!-- 企信pc迁移后新增 -->
  <div>
    <el-dialog
      title="智能更新进度设置"
      :visible.sync="dialogVisible"
      width="880px"
      :append-to-body="true"
      :before-close="handleClose"
      :show-close="false"
      @click.stop
    >
      <div class="adver-wrap">
        <advertisement type="autoProgress" :styleType="2" />
      </div>
      <div class="set-content" v-vueloading="loading" @click.stop>
        <div class="set-type-wrap">
          <div class="set-type-title">
            <span class="line"></span>按「对齐我的OKR」完成情况自动更新
            <el-switch v-model="alignOkrSwitch" width="42" active-color="#005EFF" />
          </div>
          <div v-if="alignOkrSwitch">
            <div class="select-okr-wrap" v-if="selectOkrList.length > 0">
              <div class="select-okr-left" v-html="selectOkrHtml()"></div>
              <div class="select-okr-right">
                <Icon
                  icon-class="icon-bianji"
                  class="icon-edit"
                  @click="setTypeClick('alignOkrVisible', true)"
                />
              </div>
            </div>
            <div class="set-type-button" v-else @click="setTypeClick('alignOkrVisible', true)">设置条件</div>
          </div>
        </div>
        <div class="set-type-wrap">
          <div class="set-type-title">
            <span class="line"></span>按「我的任务」完成情况自动更新
            <el-switch v-model="taskTypeSwitch" width="42" active-color="#005EFF" />
          </div>
          <div class="set-task-content" v-if="taskTypeSwitch">
            <div class="set-task-type">
              <el-radio v-model="taskTypeRadio" :label="1">平均分配</el-radio>
              <el-radio v-model="taskTypeRadio" :label="2">自定义分配</el-radio>
            </div>
            <div v-if="taskTypeRadio === 1" class="task-average-wrap">
              <el-input
                placeholder="0"
                v-model="taskAveragePercentage"
                class="input-focus"
                @change="taskAveragePercentageChange"
              >
                <template slot="append">
                  {{
                  item.quantificationUnit
                  }}
                </template>
              </el-input>的进度将由任务完成后自动更新
            </div>
            <div
              class="set-type-button"
              @click="setTypeClick('taskFinishVisible', true)"
              v-else
            >设置条件</div>
          </div>
        </div>
      </div>
      <div class="bottom-step-buttons" @click.stop>
        <div class="percentage-text">
          剩余可设置自动更新进度目标值
          <span>{{ percentageAll }}{{ item.quantificationUnit }}</span>
        </div>
        <div class="btn-list" @click.stop>
          <el-button class="left-button" @click="handleClose">取消</el-button>
          <L-button :loading="addLoading" @clickEvt="submit('提交')">确认</L-button>
        </div>
      </div>
    </el-dialog>
    <alignOkr
      v-if="alignOkrVisible"
      v-model="alignOkrVisible"
      :list="selectOkrList"
      :okrData="okrData"
      :item="item"
      :maxPercentage="alignOkrMaxPercentage()"
      @refresh="alignOkrRefresh"
    />
    <taskFinish
      v-if="taskFinishVisible"
      v-model="taskFinishVisible"
      :taskList="taskList"
      :okrData="okrData"
      :item="item"
      :maxPercentage="percentageAll - alignOkrPercentage"
      @refresh="taskFinishRefresh"
    />
  </div>
</template>
<script>
import alignOkr from './alignOkr'
import taskFinish from './taskFinish'
import advertisement from '@/views/global/components/advertisement'
export default {
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  components: { alignOkr, taskFinish, advertisement },
  props: {
    dialogShow: {
      type: Boolean,
      default: true
    },
    item: {
      // 当前关键结果
    },
    okrData: {
      // 当前目标信息
    }
  },
  data() {
    return {
      loading: false,
      addLoading: false,
      dialogVisible: this.dialogShow,
      alignOkrSwitch: false,
      taskTypeSwitch: false,
      taskTypeRadio: 1, // 默认平均分配
      alignOkrPercentage: 0, // 对齐目标  选择的值
      taskAveragePercentage: 100, // 任务 平均分配选择的值
      taskCustomPercentage: 10, // 任务 自定义分配选择的值
      percentageAll: 80,
      alignOkrVisible: false,
      taskFinishVisible: false,
      selectOkrList: [],
      setDataInit: {},
      okrProgressInit: {},
      alignAtMeAutomationRule: {},
      taskAutomationRule: {},
      taskList: [],
      selectOkrData: {
        maxValue: 0,
        okrLen: 0,
        keyLen: 0
      }
    }
  },
  watch: {
    dialogShow: {
      handler(val) {
        this.dialogVisible = val
      }
    }
  },
  created() {
    this.getData() // 获取配置信息
  },
  methods: {
    selectOkrHtml() {
      var html = `<span>${this.selectOkrData.maxValue}${this.item.quantificationUnit}</span>进度由`
      html +=
        this.selectOkrData.keyLen > 0
          ? `<span>${this.selectOkrData.keyLen}</span>个关键结果`
          : ''
      html +=
        this.selectOkrData.okrLen > 0
          ? `<span>${this.selectOkrData.okrLen}</span>个目标`
          : ''
      html += `的完成情况自动更新`
      return html
    },
    // 计算对齐okr可用最大值
    alignOkrMaxPercentage() {
      var value = this.percentageAll
      if (this.taskTypeSwitch) {
        if (this.taskTypeRadio === 1) {
          value =
            this.percentageAll -
            (this.taskAveragePercentage - this.item.quantificationStart || 0)
        } else {
          value = this.percentageAll - (this.taskCustomPercentage || 0)
        }
      }
      return value
    },
    taskAveragePercentageChange() {
      this.taskAveragePercentage = (Number(this.taskAveragePercentage) || 0).toFixed(2)
      if (
        this.taskAveragePercentage >
          this.percentageAll -
            this.alignOkrPercentage +
            this.item.quantificationStart ||
        this.taskAveragePercentage <= this.item.quantificationStart
      ) {
        this.taskAveragePercentage =
          this.percentageAll -
          this.alignOkrPercentage +
          this.item.quantificationStart
        this.$message.warn(
          `平均分配的进度值不能小于等于${
            this.item.quantificationStart
          }并且不能大于最大值${
            this.percentageAll -
            this.alignOkrPercentage +
            this.item.quantificationStart
          }哦！`
        )
      }
    },
    setTypeClick(name, state) {
      var isAlignState = 0
      if (name === 'alignOkrVisible' && state) {
        if ((this.okrData.childObjectivesAssociationUserList || []).length > 0) {
          isAlignState = 1
        }
        if (isAlignState === 0) {
          this.$message.warn('还未有对齐你的OKR，对齐你以后才可设置')
          return
        }
      }
      if (name === 'taskFinishVisible' && state) {
        if (this.taskList.length === 0) {
          this.$message.warn('还没有任务哦，新建以后才可设置')
          return
        }
      }
      this[name] = state
    },
    alignOkrRefresh(val) {
      this.selectOkrList = val.selectList
      this.setDataInit.alignAtMeAutomationRule.keyresultsProgressAutomationRuleInfos =
        val.selectList
      this.setDataInit.alignAtMeAutomationRule.maxValue = val.maxValue
      this.alignOkrPercentage = val.maxValue
      this.selectOkrData = val
    },
    // 关闭启动状态
    handleClose() {
      this.dialogVisible = false
      this.$emit('change', false)
    },
    getData() {
      const beans = {
        automationBusinessId: this.item.id,
        automationBusinessType: 0
      }
      this.$apis.OKRGETAUTOPROGRESS(beans).then((rest) => {
        this.okrProgressInit = JSON.parse(JSON.stringify(rest.data))
        this.percentageAll = rest.data.krMaxValue
        this.alignOkrSwitch = rest.data.alignSwitch === 1 ? true : false
        this.taskAveragePercentage =
          this.percentageAll -
          ((rest.data.alignAtMeAutomationRule &&
            rest.data.alignAtMeAutomationRule.maxValue) ||
            0) +
          this.item.quantificationStart
        // 对齐okr
        if ((rest.data.alignAtMeAutomationRule || '') === '') {
          rest.data.alignAtMeAutomationRule = {
            automationId: rest.data.krId,
            currentValue: this.item.quantificationActualValue,
            id: '',
            keyresultsProgressAutomationRuleInfos: [],
            maxValue: this.percentageAll,
            type: 1 //（0手动,1按对齐我的OKR完成情况自动更新,2按我的任务完成情况自动更新)
          }
          this.selectOkrList = []
        } else {
          this.selectOkrList =
            rest.data.alignAtMeAutomationRule.keyresultsProgressAutomationRuleInfos
          var keyLen = 0
          var okrLen = 0
          this.selectOkrList.forEach((item) => {
            if (item.businessType === 0) {
              ++okrLen
            } else {
              ++keyLen
            }
          })
          this.selectOkrData.maxValue =
            rest.data.alignAtMeAutomationRule.maxValue
          this.selectOkrData.okrLen = okrLen
          this.selectOkrData.keyLen = keyLen
          this.alignOkrPercentage = rest.data.alignAtMeAutomationRule.maxValue
        }
        // 任务
        if ((rest.data.taskAutomationRule || '') === '') {
          this.taskAutomationRule = {
            automationId: rest.data.krId,
            // currentValue: rest.data.manualRule.currentValue,
            currentValue: this.item.quantificationActualValue,
            id: '',
            keyresultsProgressAutomationRuleInfos: [],
            // maxValue: (rest.data.krMaxValue - rest.data.manualRule.currentValue),
            maxValue: this.percentageAll,
            type: 2, //（0手动,1按对齐我的OKR完成情况自动更新,2按我的任务完成情况自动更新）
            taskRule: 1 // 任务推进方式 （type = 2）【1.平均分配，2.自定义分配 】
          }
        } else {
          this.taskAutomationRule = rest.data.taskAutomationRule
          this.taskAveragePercentage =
            rest.data.taskAutomationRule.maxValue +
            this.item.quantificationStart
          this.taskCustomPercentage = rest.data.taskAutomationRule.maxValue
          if (rest.data.taskAutomationRule.taskRule === 2) {
            this.taskTypeSwitch = true
            this.taskTypeRadio = 2
          }
        }
        if (rest.data.isOpen === 1) {
          this.taskTypeSwitch = true
          this.taskTypeRadio = 1
        }
        this.setDataInit = rest.data
        this.getTaskList() // 获取任务列表
      })
    },
    // 获取任务列表
    getTaskList() {
      const beans = {
        keyId: this.item.id,
        dateSort: 1,
        objId: this.item.objId,
        roleType: ''
      }
      this.$apis.OKRAUTOTASKLIST(beans).then((rest) => {
        rest.data.forEach((item) => {
          item.checked = false
          item.value = 0
        })
        const keyresultsProgressAutomationRuleInfos =
          this.taskAutomationRule.keyresultsProgressAutomationRuleInfos
        // 设置选择的任务数据 绑定到任务列表中 自定义分配
        if (keyresultsProgressAutomationRuleInfos.length > 0) {
          keyresultsProgressAutomationRuleInfos.forEach((selectItem) => {
            rest.data.forEach((item) => {
              if (item.id === selectItem.businessId) {
                item.checked = true
                if (this.taskAutomationRule.taskRule === 2) { // 自定义任务分配 回显
                  item.pushFrom = selectItem.pushFrom + this.item.quantificationStart
                  item.pushTo = selectItem.pushTo
                  item.pushRate = selectItem.pushRate
                }
                // item.pushFrom = this.item.quantificationStart
                // item.pushTo = 0
                // item.pushRate = 0
                item.automationRuleId = selectItem.automationRuleId
                item.ruleId = selectItem.id
                item.setCreateUserid = selectItem.createUserid || ''
                item.setCreateDate = selectItem.createDate || ''
                item.setIsDel = selectItem.isDel
                item.setCompanyId = selectItem.companyId || ''
              }
            })
          })
        }
        this.taskList = rest.data
      })
    },
    // 平均分配获取任务列表数组
    keyresultsProgressAutomationRuleInfos() {
      var list = []
      this.taskList.forEach((item) => {
        var automationRuleId = ''
        const keyresultsProgressAutomationRuleInfos =
          this.taskAutomationRule.keyresultsProgressAutomationRuleInfos
        keyresultsProgressAutomationRuleInfos.forEach((selectItem) => {
          if (item.id === selectItem.businessId) {
            automationRuleId = selectItem.id
          }
        })
        list.push({
          automationId: this.item.id,
          automationRuleId: automationRuleId,
          businessId: item.id,
          businessType: 2, // 业务类型（0目标 ，1 KR ，2 任务（自定义）  3 项目*）
          currentValue:
            item.status !== 3
              ? 0
              : this.$utils.floatToFixedDown(
                  (this.taskAveragePercentage - this.item.quantificationStart) /
                    this.taskList.length
                ),
          id: item.ruleId || '',
          pushFrom: this.$utils.floatToFixedDown(
            (this.taskAveragePercentage - this.item.quantificationStart) /
              this.taskList.length
          ),
          pushTo: this.$utils.floatToFixedDown(
            (this.taskAveragePercentage - this.item.quantificationStart) /
              this.taskList.length
          ),
          pushRate: this.$utils.floatToFixedDown(
            ((this.taskAveragePercentage - this.item.quantificationStart) /
              this.taskList.length /
              (this.item.quantificationEnd - this.item.quantificationStart)) *
              100
          ),
          objectId: this.okrData.id,
          createUserid: item.setCreateUserid || '',
          createDate: item.setCreateDate || '',
          isDel: item.setIsDel,
          companyId: item.setCompanyId || ''
        })
      })
      if (this.$utils.floatToFixedDown((this.taskAveragePercentage - this.item.quantificationStart) / this.taskList.length, 1)) {
        var index = list.length - 1
        var value = this.$utils.floatToFixedDown((this.taskAveragePercentage - this.item.quantificationStart) - this.$utils.floatToFixedDown((this.taskAveragePercentage - this.item.quantificationStart) / this.taskList.length) * index)
        list[index].currentValue = (list[index].currentValue !== 0 ? value : 0)
        list[index].pushFrom = value
        list[index].pushTo = value
        list[index].pushRate = this.$utils.floatToFixedDown(
            (((this.taskAveragePercentage - this.item.quantificationStart) - this.$utils.floatToFixedDown((this.taskAveragePercentage - this.item.quantificationStart) / this.taskList.length) * index) /
              (this.item.quantificationEnd - this.item.quantificationStart)) *
              100
          )
      }
      return list
    },
    taskFinishRefresh(list) {
      this.taskAutomationRule.keyresultsProgressAutomationRuleInfos =
        list.selectList
      this.taskAutomationRule.maxValue = list.maxValue
      this.taskList = list.selectTaskList
    },
    submit() {
      this.addLoading = true
      const beans = JSON.parse(JSON.stringify(this.setDataInit))
      const taskAutomationRule = this.taskAutomationRule
      // 是否开启按我的任务完成情况自动更新（0 关闭 1开启）
      beans.isOpen = this.taskTypeRadio === 1 && this.taskTypeSwitch ? 1 : 0
      beans.alignSwitch = this.alignOkrSwitch ? 1 : 0
      if (this.taskTypeRadio === 1) {
        // 任务平均分配
        if (this.taskTypeSwitch) {
          console.log(
            'fff',
            this.percentageAll -
              this.alignOkrPercentage +
              this.item.quantificationStart
          )
          if (
            this.taskAveragePercentage >
              this.percentageAll -
                this.alignOkrPercentage +
                this.item.quantificationStart ||
            this.taskAveragePercentage <= this.item.quantificationStart
          ) {
            this.taskAveragePercentage =
              this.percentageAll -
              this.alignOkrPercentage +
              this.item.quantificationStart
            this.$message.warn(
              `平均分配的进度值不能小于等于${
                this.item.quantificationStart
              }并且不能大于最大值${
                this.percentageAll -
                this.alignOkrPercentage +
                this.item.quantificationStart
              }哦！`
            )
            this.addLoading = false
            return
          }
        }
        taskAutomationRule.taskRule = 1
        taskAutomationRule.keyresultsProgressAutomationRuleInfos =
          this.keyresultsProgressAutomationRuleInfos()
        taskAutomationRule.maxValue =
          this.taskAveragePercentage - this.item.quantificationStart
      } else {
        taskAutomationRule.taskRule = 2
      }
      if (!this.taskTypeSwitch) {
        taskAutomationRule.keyresultsProgressAutomationRuleInfos = []
        taskAutomationRule.maxValue = 0
      }
      beans.taskAutomationRule = taskAutomationRule
      if (
        (beans.alignAtMeAutomationRule &&
        beans.alignAtMeAutomationRule.keyresultsProgressAutomationRuleInfos
          .length === 0) || !this.alignOkrSwitch
      ) {
        beans.alignAtMeAutomationRule = null
      }
      if (!this.taskTypeSwitch) {
        beans.taskAutomationRule = null // 清空任务配置
      }
      if (this.alignOkrSwitch && !this.taskTypeSwitch && beans.alignAtMeAutomationRule === null && this.okrProgressInit.taskAutomationRule === null && this.okrProgressInit.alignAtMeAutomationRule === null) {
        this.handleClose()
        return
      }
      this.$apis
        .OKRAUTOPROGRESSSAVE(beans)
        .then((rest) => {
          if (rest.status === 200) {
            if (beans.alignAtMeAutomationRule !== null) {
              this.$mixpanelUtil.track('Add Smart Update', {
                type: 0
              })
            }
            if (beans.taskAutomationRule !== null) {
              this.$mixpanelUtil.track('Add Smart Update', {
                type: 1
              })
            }
            this.$message.success('设置成功')
            this.$emit('refresh')
            this.handleClose()
          } else {
            this.$message.error(rest.message)
          }
          this.addLoading = false
        })
        .catch(() => {
          this.addLoading = false
        })
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../../assets/styles/dialog.less';
.adver-wrap {
  position: absolute;
  top: 32px;
  right: 32px;
  height: 28px;
}
.set-content {
  margin-left: 16px;
  .set-type-wrap {
    margin: 32px 0px 16px 0px;
    .select-okr-wrap {
      font-size: 14px;
      font-weight: bold;
      color: #6e7491;
      line-height: 22px;
      padding: 12px 16px;
      border-radius: 10px;
      margin: 16px 0px 0px -16px;
      .flex-bt();
      cursor: pointer;
      ::v-deep .select-okr-left {
        span {
          color: #3a78f6;
          margin: 0px 4px;
        }
        span:first-child {
          margin: 0px 4px 0px 0px;
        }
      }
      .select-okr-right {
        .icon-edit {
          color: #a0a3bd;
          margin-left: 16px;
          font-size: 16px;
          border-radius: 8px;
          cursor: pointer;
          display: none;
          &:hover {
            color: #444558;
          }
        }
      }
      &:hover {
        background: rgba(245, 246, 250, 0.5);
        .icon-edit {
          display: block;
        }
      }
    }
    .set-type-title {
      .flex-l();
      font-weight: 500;
      color: #14142b;
      font-size: 16px;
      .el-switch {
        margin-left: 16px;
      }
      span.line {
        width: 4px;
        height: 16px;
        background: #444558;
        border-radius: 2px;
        margin-right: 8px;
      }
    }
    .set-type-button {
      font-size: 14px;
      font-weight: 500;
      color: #3a78f6;
      background: rgba(58, 120, 246, 0.059);
      border-radius: 12px;
      border: 1px solid rgba(58, 120, 246, 0.87);
      height: 32px;
      line-height: 30px;
      width: 123px;
      text-align: center;
      cursor: pointer;
      margin-top: 16px;
      &:hover {
        background: #f3f7fe !important;
        color: #548af7 !important;
        border: 1px solid #548af7;
      }
    }
    .set-task-content {
      .set-task-type {
        margin: 20px 0px 0px 0px;
        .el-radio {
          margin-right: 48px;
          .el-radio__label {
            font-weight: 400;
            color: #444558;
            font-size: 14px;
          }
        }
      }
      .task-average-wrap {
        .flex-l();
        margin-top: 16px;
        min-height: 32px;
        .el-input {
          width: 120px;
          margin-right: 8px;
          ::v-deep .el-input__inner {
            color: #14142b;
            font-weight: 500;
            padding: 0px;
            text-align: center;
          }
          ::v-deep .el-input-group__append {
            padding: 0px 7px;
            background: #f5f6fa;
            border-color: #f5f6fa;
          }
        }
      }
    }
  }
}
.bottom-step-buttons {
  .flex-bt();
  margin-left: 16px;
  .percentage-text {
    line-height: 40px;
    font-size: 12px;
    font-weight: 400;
    color: #a0a3bd;
    span {
      color: #3a78f6;
    }
  }
  .btn-list {
    .flex-bt();
    justify-content: flex-end;
    ::v-deep {
      button {
        height: 32px;
        min-width: auto;
      }
    }
  }
}
</style>
