import INDEX from '../../views/global/step/index.vue'
export default {
  path: '/step',
  redirect: '/stepOne',
  name: 'Step',
  component: INDEX,
  children: [
    {
      path: '/stepOne',
      name: 'stepOne',
      component: () => {
        return import(
          /* webpackChunkName: "stepOne" */
          '../../views/global/step/stepOne.vue'
        )
      }
    }
  ]
}
