var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "comm-check-form",
      attrs: {
        title: "转让目标",
        visible: _vm.dialogVisible,
        width: "520px",
        "append-to-body": true,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-content" }, [
        _c("div", { staticClass: "form-title" }, [
          _c("span", [_vm._v("转让接受人")]),
          _c(
            "div",
            { staticClass: "dialog-content-input" },
            [
              _c("user-picker", { on: { pick: _vm.toriginatorPicker } }, [
                _c(
                  "div",
                  { staticClass: "move-over-name" },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "move-over-name-span",
                        attrs: { title: _vm.moveOverData.username },
                      },
                      [
                        _vm.moveOverData.username
                          ? _c("wx-open-data", {
                              attrs: {
                                id: _vm.moveOverData.username,
                                nameData: { name: _vm.moveOverData.name },
                              },
                            })
                          : _c("span", { staticClass: "gray" }, [
                              _vm._v("请选择"),
                            ]),
                      ],
                      1
                    ),
                    _c("Icon", {
                      staticClass: "aub-add-icon",
                      attrs: { "icon-class": "icon-xiangyou" },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ]),
        _c("p", { staticClass: "info" }, [
          _vm._v("转让内容将包含目标, KR, 以及KR任务等内容, 请谨慎操作"),
        ]),
        _c("div", { staticClass: "bottom-step-buttons" }, [
          _c(
            "button",
            { staticClass: "left-button", on: { click: _vm.handleClose } },
            [_vm._v("取消")]
          ),
          _c("button", { on: { click: _vm.save } }, [_vm._v("确定")]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }