<template>
  <el-select
    ref="permissionSelectRef"
    v-model="optionValue"
    class="permission-select"
    @change="changeValue"
  >
    <el-option
      v-for="item in options"
      :key="item.value"
      :value="item.value"
      :label="item.label"
      class="permission-select-option"
    >
      <div class="option-label">{{ item.label }}</div>
      <div class="option-describe">{{ item.describe }}</div>
    </el-option>
    <div class="permission-select-del-btn-box">
      <div class="del-btn" @click="delItem">删除</div>
    </div>
  </el-select>
</template>
<script>
export default {
  model: {
    prop: 'selectValue',
    event: 'change'
  },
  props: {
    selectValue: {
      type: Number,
      default: 4
    }
  },
  data() {
    return {
      optionValue: this.selectValue,
      options: [
        { label: '可管理', value: 1, describe: '可管理周期/可对OKR增删改查' },
        { label: '可编辑', value: 4, describe: '可对OKR增删改查' },
        { label: '仅查看', value: 3, describe: '可查看OKR' },
        { label: '不可见', value: 2, describe: '' }
      ]
    }
  },
  methods: {
    delItem() {
      this.$emit('delItem')
      this.$refs.permissionSelectRef.blur()
    },
    changeValue(val) {
      this.$emit('change', val)
    }
  }
}
</script>
<style lang="scss" scoped>
.permission-select {
  width: 100px !important;
  height: 32px;
  .option-content {
    height: 48px;
  }
}
::v-deep {
  .el-input__inner {
    border: 0;
    background: transparent !important;
    box-shadow: none;
  }
}
</style>
<style lang="scss">
.permission-select-option {
  height: 48px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .option-label {
    color: #14142b;
    line-height: 16px;
  }
  .option-describe {
    color: #a0a3bd;
    font-size: 10px;
    line-height: 16px;
  }
}
.permission-select-del-btn-box {
  border-top: 1px solid #f0f1f6;
  height: 40px;
  padding: 0 20px;
  line-height: 40px;
  .del-btn {
    cursor: pointer;
    color: #ff572b;
  }
  &:hover {
    background: rgba(255, 87, 43, 0.06);
  }
}
</style>
