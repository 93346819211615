const versionNumber = 'v1' // 版本
const versionNumber2 = 'v2' // 版本
// todo模块api
export default [
  // 新版任务新增
  {
    taskAddE: `/api/ranloTask/${versionNumber2}/task/addE`
  },
  // 任务详情（新）
  {
    taskDetailNew: `/api/ranloTask/v2/task/detail/`
  },
  // 修改任务状态
  {
    taskStateChange: `/api/ranloTask/${versionNumber}/task/updateStatus`
  },
  // 修改任务标题
  {
    taskTitleUpdate: `/api/ranloTask/${versionNumber}/task/title/update`
  },
  // 修改任务优先级
  {
    taskPriorityUpdate: `/api/ranloTask/${versionNumber}/task/priority/update`
  },
  // 修改任务描述
  {
    taskInformationUpdate: `/api/ranloTask/${versionNumber}/task/information/update`
  },
  // 删除任务与KR关联
  {
    taskDeleteKr: `/api/ranloTask/${versionNumber}/task/deleteKr/`
  },
  // 修改任务负责人及协作人
  {
    taskChargeUserUpdate: `/api/ranloTask/${versionNumber}/task/chargeUser/update`
  },
  // 修改和删除任务负责人
  {
    taskLeader: `/api/ranloTask/${versionNumber}/task/leader/`
  },
  // 修改任务时间
  {
    taskTimeUpdate: `/api/ranloTask/${versionNumber}/task/date/update`
  },
  // 删除任务时间
  {
    taskTimeDelete: `/api/ranloTask/${versionNumber}/task/date/delete`
  },
  // 修改任务所属
  {
    taskBelongUpdate: `/api/ranloTask/${versionNumber}/project/task`
  },
  // 删除任务协作人
  {
    taskChargeUserDelete: `/api/ranloTask/${versionNumber}/task/chargeUser/delete`
  },
  // 删除任务
  {
    taskDelete: `/api/ranloTask/${versionNumber}/task/delete`
  },
  // 查询任务最新动态
  {
    taskNewDynamicList: `/api/ranloTask/${versionNumber}/taskComment/firstThreeList`
  },
  // 查询任务较早的动态
  {
    taskOldDynamicList: `/api/ranloTask/${versionNumber}/taskComment/taskCommentList`
  },
  // 删除任务评论
  {
    taskDelComment: `/api/ranloTask/${versionNumber}/taskComment/`
  },
  // 新增任务重复时间
  {
    taskRepeatAdd: `/api/ranloTask/${versionNumber}/taskCycle/add`
  },
  // 修改任务重复时间
  {
    taskRepeatUpdate: `/api/ranloTask/${versionNumber}/taskCycle/update`
  },
  // 新增任务提醒时间
  {
    taskRemindAdd: `/api/ranloTask/${versionNumber}/remindFeedbackDate/remind/add`
  },
  // 修改任务提醒时间
  {
    taskRemindUpdate: `/api/ranloTask/${versionNumber}/remindFeedbackDate/remind/update`
  },
  // 获取TODO任务列表（新）
  {
    taskTodoListNew: `/api/ranloTask/${versionNumber}/task/getTodoTaskListE`
  },
  // 获取TODO任务列表-查看团队某个人的
  {
    taskSomeTodoList: `/api/ranloTask/${versionNumber}/task/getTaskListByFollowUser`
  },
  // 获取TODO任务列表-查看团队某个人的
  {
    taskSomeTodoListNew: `/api/ranloTask/${versionNumber}/task/getTaskListByFollowUserE`
  },
  // 获取TODO各类型任务数量
  {
    taskCountByType: `/api/ranloTask/${versionNumber}/task/getTaskCountByTodo`
  },
  // 获取所有关注团队人员id列表
  {
    taskFollowUserIdList: `/api/ranloTask/${versionNumber}/taskFollow/getFollowUserList`
  },
  // 获取关注人员列表
  {
    taskFollowUserList: `/api/ranloTask/${versionNumber}/taskFollow/getFollowList`
  },
  // 批量添加关注
  {
    taskAddFollowUsers: `/api/ranloTask/${versionNumber}/taskFollow/addFollowList`
  },
  // 删除关注
  {
    taskDelFollowUser: `/api/ranloTask/${versionNumber}/taskFollow/delFollow/`
  },
  // 任务列表查询
  {
    taskAllList: `/api/ranloTask/${versionNumber}/task/`
  },
  // 获取任务已读未读列表
  {
    taskReadOrNotList: `/api/ranloTask/${versionNumber}/task/getTaskListByFatherTid`
  },
  // 添加任务评论
  {
    taskAddComment: `/api/ranloTask/${versionNumber}/taskComment/add`
  },
  // 获取任务重复
  {
    getTaskCycle: `/api/ranloTask/${versionNumber}/taskCycle/getCycle`
  },
  // 获取任务提醒
  {
    getTaskRemind: `/api/ranloTask/${versionNumber}/remindFeedbackDate/remind`
  },
  // 任务可见范围修改
  {
    taskShareUpdata: `/api/ranloTask/${versionNumber}/task/share/update`
  },
  // 任务新增字典-kr,临时
  {
    taskAddParamTodo: `/api/ranloTask/${versionNumber}/task/field/dictionary/`
  },
  // 任务新增字典-项目
  {
    taskAddParamProject: `/api/ranloTask/${versionNumber}/project/field/dictionary/`
  },
  // 任务字典-全局
  {
    taskParamDict: `/api/ranloTask/v2/task/common/field/dictionary`
  },
  // 新版-任务详情任务字典(子任务需要查询父任务字典)
  {
    taskDictionary: `/api/ranloTask/v2/task/field/dictionary`
  },
  // 新版-任务修改聚合接口 不支持任务归属不支持任务公开隐私状态不支持任务重复和提醒时间
  {
    taskUpdate: `/api/ranloTask/${versionNumber}/task/update/`
  },
  // 修改标签
  {
    updateTaskLabel: `/api/ranloTask/${versionNumber}/task/label/update`
  },
  {
    // 根据任务id获取甘特图依赖数量(如果数量大于0 需要提示清空开始开始时间or结束时间 将会删除依赖关系)
    taskGanttRelation: `/api/ranloTask/${versionNumber}/project/ganttchart/relation/task/`
  },
  {
    // 关注和取消关注任务
    taskCareChange: `/api/ranloTask/${versionNumber}/task/follow/task`
  },
  {
    // 待办列表
    taskWaitList: `/api/ranloTask/${versionNumber}/task/todo`
  },
  {
    // 添加工时
    addTaskWorkHours: `/api/ranloTask/${versionNumber}/keyresults/task/working/hours/info/save`
  },
  {
    // 更新修改工时
    updateTaskWorkHours: `/api/ranloTask/${versionNumber}/keyresults/task/working/hours/info/update`
  },
  {
    // 删除工时
    deleteTaskWorkHours: `/api/ranloTask/${versionNumber}/keyresults/task/working/hours/info/delete`
  },
  {
    // 查询工时明细列表
    taskworkHoursInfoList: `/api/ranloTask/${versionNumber}/keyresults/task/working/hours/info/list`
  },
  {
    // 查询工时明细列表总数
    taskworkHoursInfoListTotal: `/api/ranloTask/${versionNumber}/keyresults/task/working/hours/info/list/total`
  },
  {
    // 项目工时统计
    workingHoursInfoStatistics: `/api/ranloTask/${versionNumber}/keyresults/task/working/hours/info/statistics`
  },
  {
    // 获取工时能够添加的全部操作人
    workingHoursTaskAllUser: `/api/ranloTask/${versionNumber}/keyresults/task/working/hours/info/task/allUser`
  },
  {
    // 获取捷径条件模版
    todoConditionDictionary: `/api/ranloTask/${versionNumber}/shortcut/condition/dictionary`
  },
  {
    // 获取用户捷径
    getUserShortCutList: `/api/ranloTask/${versionNumber}/shortcut`
  },
  {
    // 根据捷径id获取表格字段
    getTodoShortcutField: `/api/ranloTask/${versionNumber}/shortcut/field/`
  },
  {
    // 获取捷径分组字典
    getTodoShortcutGroupList: `/api/ranloTask/${versionNumber}/shortcut/group/shortcutGroupDictionary`
  },
  {
    // 根据分组标识获取分组详细
    getTodoGroupDetailByGroupIdentification: `/api/ranloTask/${versionNumber}/shortcut/group/getGroupDetailByGroupIdentification`
  },
  {
    // 根据捷径id获取捷径详细信息
    getTodoShortcutDetail: `/api/ranloTask/${versionNumber}/shortcut/`
  },
  {
    // 修改捷径名称
    getTodoShortcutName: `/api/ranloTask/${versionNumber}/shortcut/updateShortcutName`
  },
  {
    // 删除捷径
    getTodoShortcutDelete: `/api/ranloTask/${versionNumber}/shortcut/`
  },
  {
    // 保存捷径表格字段
    getTodoShortcutFieldEdit: `/api/ranloTask/${versionNumber}/shortcut/field`
  },
  {
    // 任务搜索
    getTodoShortcutTaskSearch: `/api/ranloTask/${versionNumber}/shortcut/task/search`
  },
  {
    // 获取任务全部动态
    allTaskCommentList: `/api/ranloTask/${versionNumber}/taskComment/getAllTaskCommentList`
  },
  {
    // 新建捷径
    todoAddShortcut: `/api/ranloTask/${versionNumber}/shortcut/addShortcut`
  },
  {
    // 修改捷径
    todoUpdateShortcut: `/api/ranloTask/${versionNumber}/shortcut/updateShortcut`
  },
  {
    // 根据分组标识获取分组详细
    getTodoGroupDetailByGroupIdentificationV2: `/api/ranloTask/${versionNumber}/shortcut/group/getGroupDetailByGroupIdentification`
  },
  {
    // 修改任务回收状态
    updateTaskIsDelListByIds: `/api/ranloTask/${versionNumber}/task/updateTaskIsDelListByIds/`
  },
  {
    // 修改任务归档状态
    updateTaskIsCloseListByIds: `/api/ranloTask/${versionNumber}/task/updateTaskIsCloseListByIds/`
  }
]
