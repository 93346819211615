<template>
  <div class="apply-content">
    <div class="field-list-content">
      <div class="field-list-wrap field-list-flex">
        <div class="username">
          <el-input v-model="userName" type="text" placeholder="您的姓名" :maxlength="30" />
        </div>
        <div class="username">
          <el-input
            v-model="telephone"
            type="text"
            name="tel"
            maxlength="11"
            class="login-input"
            oninput="value=value.replace(/[^\d]/g,'')"
            :autocomplete="isAllowSavePassword == 1 ? 'off' : 'on'"
            placeholder="请输入手机号"
          />
        </div>
      </div>
      <div class="field-list-wrap">
        <div class="field-title">使用人数：</div>
        <div class="field-list">
          <div class="price-two-rg-user-range flex-new">
            <div class="inputValue">
              <input
                v-model="countNumber"
                placeholder="填写使用人数，最低20人起"
                type="text"
                class="input-value"
                oninput="value=value.replace(/[^\d]/g,'');"
                autocomplete="off"
              />
            </div>
            <div class="price-two-rg-user-range-inner flex-new flex-new-direction-column">
              <div
                class="price-two-rg-user-range-inner-top flex-new"
                @mouseenter="numAddState = true"
                @mouseleave="numAddState = false"
                @click="add"
              >
                <Icon icon-class="icon-arrow-up" class="price-two-arrow-img-add" />
              </div>
              <div
                class="price-two-rg-user-range-inner-bottom flex-new"
                @mouseenter="numReduceState = true"
                @mouseleave="numReduceState = false"
                @click="reduce"
              >
                <Icon icon-class="icon-arrow-down" class="price-two-arrow-img-add" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="field-list-wrap">
        <div class="field-title">版本：</div>
        <div class="version-list-wrap">
          <div class="version-select">
            <div
              :class="`version-content version-content-${version === 1}`"
              @click="versionClick(1)"
            >
              <div class="version-title">企业版</div>
              <div class="version-list">
                <div
                  class="version-name"
                  v-for="(item, index) in versionList"
                  :key="item"
                  v-if="index < 6"
                  @click="moduleClick(item)"
                >{{item}}</div>
              </div>
            </div>
            <div
              :class="`version-content version-content-${version === 2}`"
              @click="versionClick(2)"
            >
              <div class="version-title">旗舰版</div>
              <div class="version-list">
                <div
                  class="version-name"
                  v-for="item in versionList"
                  :key="item"
                  @click="moduleClick(item)"
                >{{item}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="field-list-wrap service-title">
        <div class="field-title">所需服务：</div>
      </div>
      <div class="field-list-wrap field-list-flex">
        <div class="service-list">
          <div
            class="module-name"
            :class="`module-name-${selectModule.indexOf(item) !== -1}`"
            v-for="(item, index) in moduleList"
            :key="item"
            @click="moduleClick(item)"
          >
            {{item}}
            <el-tooltip
              :content="moduleListTitle[index]"
              placement="top"
              popper-class="apply-tooltip-wrap"
            >
              <Icon icon-class="icon-tishi" class="icon-tishi" />
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div class="apply-button bottom-step-buttons">
      <L-button :loading="loading" class="confirm" @clickEvt="confirm">获取报价方案</L-button>
    </div>
    <div class="remind-title">我们将在一个工作日内联系您</div>
    <process-dialog
      v-if="processDialogVisible"
      v-model="processDialogVisible"
      :type="'applyDemo'"
      btn="关闭"
      dialog-txt="<p>提交成功</p><p>我们将在一个工作日内联系您</p>"
      @close="processDialogClose"
    />
  </div>
</template>
<script>
import processDialog from '@/components/global/ProcessDialog' // 复制弹窗
export default {
  components: {
    processDialog
  },
  props: {
    type: {
      default: 'dialog'
    }
  },
  data() {
    return {
      countNumber: '',
      moduleList: ['系统线上培训', '定期 OKR 落地跟踪报告', '专属客户成功经理', '专家老师入企辅导', '定制化开发和私有化部署', '明星导师直播在线答疑'],
      moduleListTitle: ['账号开通后，通过视频会议进行培训', '定期提供OKR使用情况报告，帮助企业OKR落地执行', '匹配专属的客户成功经理，助您成功', '支持专家导师入企辅导', '技术定制化开发和私有化本地化部署', '在直播间与明星导师在线互动，为您答疑解惑'],
      selectModule: [],
      loading: false,
      processDialogVisible: false,
      numReduceState: false,
      numAddState: false,
      version: '',
      versionList: ['OKR', '任务', '总结', '分析', '咪厅', '日程', '绩效', '项目'],
      userName: '',
      telephone: ''
    }
  },
  created() {
    this.companyId = (this.getUrlKey('companyId') || this.userInfo.companyId || '')
    this.companyName = (this.getUrlKey('companyName') || this.userInfo.companyName || '')
  },
  methods: {
    // 获取url参数 公共方法
    getUrlKey(name) {
      return (
        decodeURIComponent(
          (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
            location.href
          ) || [[], ''])[1].replace(/\+/g, '%20')
        ) || null
      )
    },
    versionClick(type) {
      this.version = type
    },
    inputNumbers() {
      if (Number(this.countNumber) < 20) {
        this.countNumber = 20
        this.$message.warn('用户数最小为 20 人 !')
      } else if (Number(this.countNumber) > 100000000) {
        this.countNumber = 100000000
        this.$message.warn('用户数最大为 100000000 人 !')
      }
    },
    add() {
      if (Number(this.countNumber) < 100000000) {
        this.countNumber = Number(this.countNumber) + 1
      } else {
        this.$message.warn('用户数最大为 100000000 人 !')
      }
    },
    reduce() {
      if (this.countNumber > 20) {
        this.countNumber = Number(this.countNumber) - 1
      } else {
        this.$message.warn('用户数最小为 20 人 !')
      }
    },
    processDialogClose() {
      this.$emit('close')
    },
    confirm() {
      var reg = /^1(3|4|5|6|7|8|9)\d{9}$/
      if ((this.userName || '') === '') {
        this.$message.warn('姓名不能为空哦！')
        return
      }
      if (this.telephone.length === 0) {
        this.$message.warn('手机号不能为空!')
        return
      }
      if (!reg.test(this.telephone)) {
        this.$message.warn('手机号格式不正确')
        return
      }
      if (this.countNumber === '') {
        this.$message.warn('使用人数不能为空哦！')
        return
      } else if ((this.countNumber || 0) < 20) {
        this.$message.warn('用户数最小为 20 人 !')
        return
      } else if ((this.countNumber || 0) > 100000000) {
        this.$message.warn('用户数最大为 100000000 人 !')
        return
      } else if (this.version === '') {
        this.$message.warn('“版本” 不能为空哦！')
        return
      } else if ((this.selectModule || []).length === 0) {
        this.$message.warn('“所需服务” 不能为空哦！')
        return
      }
      var selectModule = 'OKR,任务,总结,分析,咪厅,日程'
      if (this.version === 2) {
        selectModule = 'OKR,任务,总结,分析,咪厅,日程,绩效,项目'
      }
      var selectServer = ''
      this.selectModule.forEach((item, index) => {
        selectServer += `${item}${(index + 1) !== this.selectModule.length ? ',' : ''}`
      })
      var params = {
        name: this.userName,
        mobile: this.telephone,
        useCount: this.countNumber,
        version: this.version,
        selectModule,
        selectServer,
        companyId: (this.companyId || ''),
        companyName: (this.companyName || ''),
        clientType: this.$isQw ? 'qw' : this.$isDD ? 'dd' : 'saas'
      }
      this.loading = true
      this.$apis.COMMONAPPOINTMENTPRICE(params).then((res) => {
        if (res.status === 200) {
          if (this.type !== 'dialog') {
            this.$message.success('提交成功')
          } else {
            this.processDialogVisible = true
          }
          this.initData()
          this.loading = false
        } else {
          this.$message.error(res.message)
        }
        this.loading = false
      })
    },
    initData() {
      this.userName = ''
      this.telephone = ''
      this.countNumber = 20
      this.selectModule = []
    },
    moduleClick(item) {
      var moduleState = this.selectModule.indexOf(item)
      if (moduleState === -1) {
        this.selectModule.push(item)
      } else {
        this.selectModule.splice(moduleState, 1)
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../../../../assets/styles/dialog.less';
.field-list-content {
  max-height: calc(70vh - 200px);
  overflow-y: auto;
}
.service-title {
  margin-bottom: 0px !important;
  ::v-deep .field-title {
    font-size: 14px;
    font-weight: 400;
    color: #29334d;
  }
}
.version-list-wrap {
  .version-select {
    .flex-bt();
    margin: 4px 0px;

    .version-content {
      width: calc(50% - 6px);
      padding: 12px;
      border-radius: 8px;
      box-sizing: border-box;
      border: 1px solid rgba(217, 219, 233, 0.54);
      cursor: pointer;
      .version-title {
        font-size: 16px;
        font-weight: 400;
        color: #444558;
      }
      .version-name {
        height: 18px;
        background: rgba(156, 183, 255, 0.4);
        line-height: 16px;
        padding: 0px 6px;
        font-size: 12px;
        color: #fff;
        font-weight: 500;
        margin: 0px 8px 8px 0px;
        border-radius: 9px;
        &:hover {
          background: rgba(156, 183, 255, 0.4);
          color: #fff;
        }
      }
    }
    .version-content-true {
      border: 1px solid #9cb7ff;
      background: #ebf1fe
        url(../../../../../../../assets/imgs/advertisement/applyPriceImg4.png)
        top right no-repeat;
      background-size: auto 100%;
      .version-name {
        background: #7a9eff;
      }
    }
  }
  .version-list {
    margin: 8px 0px 0px 0px;
  }
}
.price-two-rg-user-range {
  border: 1px solid rgba(217, 219, 233, 0.54);
  .flex-bt();
  padding: 0px 12px;
  height: 40px;
  border-radius: 12px;
  max-width: 320px;
  .price-two-arrow-img-add {
    cursor: pointer;
  }
  .inputValue {
    flex: 1;
    .input-value {
      width: 100%;
      text-align: center;
    }
  }
}
.apply-content {
  .field-list-wrap {
    margin-bottom: 16px;
    .username,
    .job-title {
      width: calc(50% - 4px);
    }
    .username {
      margin-right: 8px;
    }
    .user-number {
      .el-select {
        width: 100%;
      }
    }
    .job-title {
      .el-select {
        width: 100%;
      }
    }
    .field-title {
      line-height: 40px;
      min-width: 70px;
      text-align: left;
      font-size: 16px;
      font-weight: 500;
      color: #444558;
    }
    .module-list,
    .version-list,
    .service-list {
      .flex-l();
      flex-wrap: wrap;
    }
    .module-name {
      cursor: pointer;
      line-height: 30px;
      height: 32px;
      border-radius: 8px;
      padding: 0px 12px;
      margin: 0px 8px 8px 0px;
      border: 1px solid rgba(217, 219, 233, 0.54);
      .flex-l();
      font-size: 12px;
      color: #444558;
      .icon-tishi {
        margin-left: 4px;
        color: #a0a3bd;
      }
      &:hover {
        background: #ebf1ff;
        border: 1px solid #9cb7ff;
      }
    }
    .module-name-true {
      background: #ebf1ff;
      border: 1px solid #9cb7ff;
      color: #3a70ff;
      .icon-tishi {
        color: #3a70ff;
      }
    }
  }
  .apply-button {
    height: 40px;
    .confirm {
      width: 100%;
      height: 40px;
      line-height: 38px;
    }
  }
  .remind-title {
    font-size: 12px;
    color: #767f99;
    margin: 12px 0px 0px 0px;
    text-align: center;
  }
  .field-list-flex {
    .flex-l();
    align-items: start;
  }
  .login-form-div {
    flex: 1;
    display: flex;
    flex-direction: column;
    .login-form {
      flex: 1;
      // 登录
      .login-fieldset {
        // height: 480px !important;
        padding: 0;
        border: 1px solid rgba(217, 219, 233, 0.54) !important;
        background: rgba(58, 120, 246, 0) !important;
        border-radius: 12px;
        font-size: 14px;
        // overflow: hidden;
        position: relative;
        .login-clear-icon {
          font-size: 18px;
          color: rgba(217, 219, 233, 1);
          width: 14px;
          cursor: pointer;
          position: absolute;
          right: 15px;
          top: 11px;
        }
        .login-clear-icon:hover {
          color: #3068ff;
        }
        .login-pwd-show {
          display: inline-block;
          color: rgba(217, 219, 233, 1);
          cursor: pointer;
          position: absolute;
          right: 36px;
          top: 8px;
          font-size: 20px;
        }
        .login-pwd-show:hover {
          color: #3068ff;
        }
        .login-pwd-hide {
          display: inline-block;
          color: rgba(217, 219, 233, 1);
          cursor: pointer;
          position: absolute;
          right: 36px;
          top: 8px;
          font-size: 20px;
        }
        .login-pwd-hide:hover {
          color: #3068ff;
        }
        label {
          margin: 0 15px;
          color: #3068ff;
          font-size: 16px !important;
          display: block;
          line-height: 40px;
          float: left;
        }
        .login-input {
          border: 0;
          padding: 14px 0 14px 16px;
          height: 40px;
          flex: 1;
          font-size: 16px !important;
          width: 74%;
          -webkit-box-shadow: none !important;
          box-shadow: none !important;
          background-color: none !important;
          background: transparent !important;
          border-radius: 12px !important;
        }
        .login-input:focus {
          -webkit-box-shadow: none !important;
          box-shadow: none !important;
          background-color: none !important;
        }
      }
      .login-fieldset-border {
        border: 1px #3068ff solid !important;
        input {
          color: #3068ff !important;
        }
      }
      .login-fieldset:hover {
        border: 1px rgba(58, 120, 246, 0.12) solid !important;
        background: #fff !important;
      }
      .login-fieldset:focus {
        border: 1px #3068ff solid !important;
      }
      .login-message {
        margin-top: 10px;
        font-size: 14px;
        color: #f93300;
        line-height: 20px;
        padding: 0 5px;
      }
      // 验证码
      .login-fieldset-sms {
        border: none;
        padding: 0;
        input {
          width: 46px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          margin-left: 5px;
          border-radius: 4px;
          border: 1px solid rgba(150, 159, 190, 1);
        }
        input.border-input-blue {
          border: 1px solid #3067fe !important;
        }
        input.border-input {
          box-shadow: none;
          box-shadow: none !important;
          background: transparent;
          filter: alpha(opacity=100); /* IE */
          -moz-opacity: 1; /* 老版Mozilla */
          -khtml-opacity: 1; /* 老版Safari */
          opacity: 1; /* 支持opacity的浏览器*/
        }
        input:focus {
          border: 1px solid;
        }
        .fieldset-sms-line {
          display: inline-block;
          width: 40px;
          height: 2px;
          border-bottom: 1px solid #ddd;
          align-self: center;
          margin-left: 10px;
        }
      }
      .login-text-div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 8px;
        .login-text {
          margin-bottom: 8px;
        }
      }
      // 密码
      .forget-pwd-div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 5px;
        margin-top: 10px;
        .el-button.go-register-btn {
          font-size: 14px;
          color: #3068ff;
          padding: 0 !important;
          margin: 0 !important;
        }
        .el-button.go-register-btn:hover {
          border-bottom: 1px #3068ff solid;
        }
      }
      //创建团队
      .login-fieldset-ctrate {
        border: 0;
        div {
          position: relative;
          // overflow: hidden;
        }
        div.type-radio {
          margin-top: 10px;
          height: 40px;
          display: flex;
          flex-direction: row;
          align-items: center;
          .radio-label {
            width: 60px !important;
            text-align: left;
            font-size: 14px;
            font-weight: 400;
            color: #14142b;
            margin-right: 24px;
          }
          .radio-content {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 0px;
            ::v-deep {
              .el-radio.is-checked .el-radio__inner {
                border-width: 2px;
              }
              .el-radio__inner::after {
                width: 10px;
                height: 10px;
                border-radius: 100%;
                background-color: #3a78f6;
              }
            }
          }
          .el-radio {
            margin-right: 16px;
          }
        }
        .register-clear-icon {
          font-size: 18px;
          color: rgba(217, 219, 233, 1);
          width: 14px;
          cursor: pointer;
          position: absolute;
          right: 15px;
          top: 14px;
        }
        .register-clear-icon-create {
          font-size: 18px;
          color: rgba(217, 219, 233, 1);
          width: 14px;
          cursor: pointer;
          position: absolute;
          right: 15px;
          top: 11px;
        }
        .register-clear-icon:hover {
          color: #3068ff;
        }
        input {
          width: 100%;
          font-size: 16px;
          line-height: 40px;
          height: 40px;
          text-indent: 10px;
          color: #14142b;
          -webkit-box-shadow: none !important;
          box-shadow: none !important;
          background-color: none !important;
          border-radius: 12px;
          padding: 0 34px 0 6px;
          border: 1px solid rgba(58, 120, 246, 0.06);
          background: rgba(58, 120, 246, 0.06);
        }
        input.login-input-border {
          border: 1px #3068ff solid !important;
          color: #3068ff !important;
        }
        input:hover {
          border: 1px rgba(58, 120, 246, 0.12) solid !important;
          background: #fff !important;
        }
      }
      //待审核
      .login-examine {
        height: 160px;
        overflow-y: auto;
        margin-top: 20px;
        .login-examine-list {
          display: flex;
          flex-direction: row;
          height: 40px;
          line-height: 40px;
          align-items: center;
          img {
            width: 24px;
            height: 24px;
          }
          .login-examine-text {
            flex: 1;
            margin-left: 12px;
            max-width: 400px;
            word-break: break-word;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -moz-box-orient: vertical;
            -webkit-line-clamp: 1;
            -moz-line-clamp: 1;
            overflow: hidden;
            float: left;
            &:before {
              content: '';
              display: block;
            }
          }
        }
      }
    }
    .login-button-div {
      width: 100%;
      .go-register-div {
        font-size: 14px;
        color: #6e7491;
        margin-top: 14px;
      }
    }
  }
  .yzm-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 16px;
    .login-fieldset {
      flex: 1;
    }
    .el-button.next-button.get-code-button {
      width: 95px !important;
      margin-left: 8px;
    }
  }
  .el-button.next-button.is-disabled {
    background: rgba(58, 120, 246, 0.54) !important;
  }
}
</style>
<style>
.el-tooltip__popper.apply-tooltip-wrap {
  z-index: 99999999 !important;
}
</style>
