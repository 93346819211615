// 提醒枚举

// 任务提醒
const DATETYPE = {
  0: '不提醒',
  1: '任务开始时',
  2: '任务开始前',
  3: '任务开始后',
  4: '任务截止时',
  5: '任务截止前',
  6: '任务截止后',
  7: '自定义时间'
}
// 提醒时间 分钟
const NUMBER = {
  1: '1',
  2: '5',
  3: '10',
  4: '15',
  5: '20',
  6: '25',
  7: '30'
}
// 时间单位
const NUMBERTYPE = {
  1: '分钟',
  2: '小时',
  3: '天'
}

// 日程提醒时间
const STARTREMINDTYPE = {
  0: '不提醒',
  1: '开始时',
  2: '5分钟前',
  3: '15分钟前',
  4: '30分钟前',
  5: '1小时前',
  6: '1天前'
}

// 日程全天提醒
const ALLDAYREMINDTYPE = {
  0: '不提醒',
  1: '当天（9:00）',
  2: '1天前（9:00）',
  3: '2天前（9:00）',
  4: '一周前（9:00）'
}

// 提醒类型
const DATETYPENUM = {
  not: 0, // 不提醒
  start: 1, // 任务开始时
  beforeStart: 2, // 任务开始前
  end: 4, // 任务截止时
  afterEnd: 6, // 任务截止后
  custom: 7 // 自定义时间
}

// 时间类型
const NUMBERTYPENUM = {
  minute: 1, // 分钟
  hour: 2, // 小时
  day: 3 // 天
}

export default {
  DATETYPE,
  NUMBER,
  NUMBERTYPE,
  STARTREMINDTYPE,
  ALLDAYREMINDTYPE,
  DATETYPENUM,
  NUMBERTYPENUM
}
