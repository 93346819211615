<template>
  <div class="task-date">
    <div
      class="more-btn"
      :class="selectTime ? 'date-btn' : ''"
      @click="selectShowTime"
    >
      {{ selectTime || '设置时间' }}
      <Icon
        v-if="selectTime"
        icon-class="icon-close"
        class="close"
        @click.native.stop="timeClear()"
      />
    </div>
    <el-date-picker
      :ref="`settime${fieldInfo.id}`"
      v-model="selectTime"
      format="yyyy-MM-dd HH:mm"
      value-format="yyyy-MM-dd HH:mm"
      type="datetime"
      placeholder="设置日期"
      class="hide-date-border"
      popper-class="addTimeClass"
      @change="dateChange"
    />
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fieldInfo: {
      type: String,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      selectTime: '',
      showContent: false
    }
  },
  watch: {
    value: {
      handler(val) {
        this.inputVal = val
      }
    }
  },
  methods: {
    selectShowTime() {
      const refname = `settime${this.fieldInfo.id}`
      this.$refs[refname].$el.focus()
      const el = this.$refs[refname].$el
      el.children[0].focus()
    },
    timeClear() {
      this.selectTime = ''
      this.$emit('change', '')
    },
    dateChange(val) {
      if (!val) val = ''
      this.$emit('change', val)
    }
  }
}
</script>
<style lang="scss" scoped>
.task-date {
  position: relative;
  display: flex;
  align-items: center;
  .more-btn {
    height: 28px;
    line-height: 28px;
    border: 1px solid transparent;
    cursor: pointer;
    color: #a0a3bd;
    position: relative;
    &:hover {
      color: #3a78f6;
      .close {
        display: block;
      }
    }
    .close {
      color: #a0a3bd;
      font-size: 14px;
      display: none;
      position: absolute;
      right: 6px;
      top: -1px;
      cursor: pointer;
    }
  }
  .date-btn {
    color: #14142b;
    padding: 0 24px 0 12px;
    &:hover {
      color: #14142b;
      background: rgba(160, 163, 189, 0.02);
      border: 1px solid #f5f6fa;
      border-radius: 16px;
    }
  }
  .hide-date-border {
    left: 18px;
    opacity: 0;
    position: absolute;
    top: -5px;
    width: 20px;
    z-index: -10;
  }
}
</style>