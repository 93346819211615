<template>
  <div :class="`state-detail-wrap state-detail-type-3`">
    <div class="detail-content">
      <div class="approve-type">
        <Icon
          :iconClass="progressList.process.approvalStatus === 3 ? 'icon-shenpitongguo' : progressList.process.approvalStatus === 2 ? 'icon-shenpiweitongguo' : 'icon-shenpijinggao'"
          class="remind"
        />
        <div class="approve-type-content">{{progressList.processDynamic.actionContent}}</div>
        <approve-type-label
          :objDetails="taskDetail"
          :businessType="progressList.process.approvalStatus"
          :disabled="true"
        />
      </div>
      <div class="approve-user">
        <div class="state-text">
          <span class="name">
            <wx-open-data
              :id="progressList.processDynamic.sponsorUserName"
              :nameData="{name: progressList.processDynamic.sponsorUserName}"
            />
          </span>
          提交了{{progressList.processDynamic.actionContent}}
        </div>
        <!-- v-if="progressList.processDynamic.appointUserName" -->
        <div class="user" v-if="progressList.processDynamic.appointUserName">
          审批人：
          <span class="name">
            <wx-open-data
              :id="progressList.processDynamic.appointUserName"
              :nameData="{name: progressList.processDynamic.appointUserName}"
            />
          </span>
        </div>
      </div>
    </div>
    <div class="approve-btn">
      <div
        class="blue-button yellow-button"
        @click="approvalCancel(2)"
        v-if="(progressList.process && progressList.process.approvalStatus === 1 && userInfo.id  == progressList.processDynamic.sponsorUserId)"
      >撤回审批</div>
      <template
        v-if="(progressList.process && progressList.process.approvalStatus === 1 && userInfo.id  == progressList.processDynamic.appointUserId)"
      >
        <div class="blue-button refuse-button" @click="approvePassTypeClick(2)">驳回</div>
        <div class="blue-button" @click="approvePassTypeClick(1)">通过审批</div>
      </template>
    </div>
    <approve-refuse
      :progressList="progressList"
      :taskDetail="taskDetail"
      v-if="approveRefuseVisible"
      v-model="approveRefuseVisible"
      @approvalRefresh="approvalRefresh"
    />
  </div>
</template>
<script>
/* eslint-disable */
import approveTypeLabel from '@/views/okr/components/approveTypeLabel'
import approveRefuse from '@/views/todo/components/approveRefuse'
export default {
  data() {
    return {
      approveRefuseVisible: false
    }
  },
  props: {
    progressList: {},
    taskDetail: {}
  },
  components: {
    approveTypeLabel,
    approveRefuse
  },
  mounted() {
  },
  created() {
  },
  methods: {
     // type: 1: 通过 2:驳回
    approvePassTypeClick(type) {
      if (type === 1) {
        this.approvePassClick()
      } else {
        this.approveRefuseVisible = true
      }
    },
    // 撤回审批
    approvalCancel() {
      this.$confirm('已撤回后需要再次提交审批', '是否要撤回审批？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'confirmBox'
      }).then(() => {
        this.approvalSubmitCancel(4)
      })
    },
    // 审批提交撤回
    approvalSubmitCancel(submitterType) {
      var url = this.$apis.PMPROCESSDATASUBMITT // 撤回/提交审批
      url({
          businessId: this.taskDetail.projectId,
          businessExtId: this.taskDetail.id,
          businessData: '',
          businessActionType: this.progressList.processDynamic.action || 1,
          submitterType,
          appointRemark: ''
        })
        .then((rest) => {
          if (rest.status === 200) {
            this.$message.success('修改成功')
            this.$emit('approvalRefresh', '', true)
            this.bus.$emit('approvalRedCountRefresh')
          } else {
            this.$message.error(rest.message)
          }
        })
    },
    approvePassClick() {
      if (this.progressList.processDynamic && this.progressList.processDynamic.action === 3) {
        this.$confirm('通过后将删除对方的任务', '是否通过审批?', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          customClass: 'confirmBox'
        }).then(() => {
          this.approvePassSubmit(3, 'delete')
        })
      } else {
        this.$confirm('', '是否通过审批？', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'confirmBox'
        }).then(() => {
          this.approvePassSubmit(3)
        })
      }
    },
    // 审批通过驳回
    approvePassSubmit(operationStatus, deleteState) {
      if (operationStatus === 2 && this.approveRxplain.replace(/(\s*$)/g, "") === '') {
        this.$message.warn('驳回说明不能为空哦！')
        return
      }
      this.$apis
        .APPROVALPROCESSOPERATION({
          spNo: this.progressList.processDynamic.spNo,
          businessType: 3,
          businessId: this.taskDetail.projectId,
          operationStatus,
          operationContent: operationStatus === 2 ? '驳回了审批' : `通过了审批 ，${this.progressList.processDynamic.actionContent}`,
          content: '',
          businessExtId: this.taskDetail.id
        })
        .then((rest) => {
          if (rest.status === 200) {
            this.$message.success('修改成功')
            this.$emit('approvalRefresh', deleteState, true)
            this.bus.$emit('approvalRedCountRefresh')
          } else {
            this.$message.error(rest.message)
          }
        })
    },
    approvalRefresh() {
      this.$emit('approvalRefresh', '', true)
      this.bus.$emit('approvalRedCountRefresh')
    }
  }
}
</script>
<style lang="less" scoped>
.state-detail-wrap {
  padding: 16px 24px 16px 24px;
  box-shadow: 0px 6px 15px rgba(0, 94, 255, 0.06);
  .flex-bt();
  .detail-content {
    .approve-type {
      .flex-l();
      .remind {
        font-size: 18px;
      }
      .approve-type-content {
        font-size: 14px;
        font-weight: 400;
        color: #14142b;
        margin: 0 8px;
        max-width: 300px;
        .text-n(1);
      }
    }
    .approve-user {
      .flex-l();
      font-size: 12px;
      color: #a0a3bd;
      margin-top: 8px;
      .state-text,
      .user {
        .flex-l();
      }
      .name {
        .text-n(1);
        max-width: 160px;
      }
      .user {
        margin-left: 8px;
      }
    }
  }
  .approve-btn {
    .flex-l();
    .blue-button {
      font-size: 14px;
      padding: 0px 20px;
      border-radius: 8px;
      min-width: 99px;
      margin-left: 16px;
      &:hover {
        opacity: 0.85;
      }
    }
    .yellow-button {
      background: #ff9900;
      border: 1px solid #ff9900;
    }
    .refuse-button {
      background: rgba(255, 87, 43, 0.12);
      border: 1px solid #ff572b;
      color: #ff572b;
    }
  }
}
</style>
