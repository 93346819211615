<template>
  <div>
    <el-dialog
      title="设置更新进度提醒"
      :visible.sync="dialogVisible"
      width="550px"
      :append-to-body="true"
      :before-close="handleClose"
      class="comm-check-form"
    >
      <div class="dialog-content comm-input">
        <div class="time-form">
          <div>
            <div class="form-item">
              <div class="item-title">条件</div>
              <el-select v-model="form.startBeforeDay" class="form-select">
                <el-option
                  v-for="item in dayOption"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                />
              </el-select>
              <div class="day-options" v-show="form.startBeforeDay === 1">
                <div class="week-days">
                  <div
                    v-for="item in weekdays"
                    :key="item.name"
                    class="week-item"
                    :class="item.check ? 'selected-item' : ''"
                    @click="item.check = !item.check"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </div>
            <div class="form-item">
              <div class="item-title">时间</div>
              <el-time-select
                v-model="form.remindTime"
                class="interval-select"
                :editable="false"
                :picker-options="{
                  start: '00:00',
                  step: '00:30',
                  end: '23:30'
                }"
                placeholder="选择时间"
                :clearable="false"
              />
              <div class="remind-text" v-show="form.startBeforeDay !== 2">
                {{ remindText }}
                <span class="remind-time"> {{ form.remindTime }} 提醒</span>
              </div>
            </div>
            <div class="remind-text" v-show="form.startBeforeDay === 2">
              {{ remindText }}
              <span class="remind-time"> {{ form.remindTime }} 提醒</span>
            </div>
          </div>
          <div class="day-options" v-show="form.startBeforeDay === 2">
            <div class="week-days month-days">
              <div
                class="week-item"
                v-for="item in monthDays"
                :key="item.name"
                :class="item.check ? 'selected-item' : ''"
                @click="item.check = !item.check"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>

        <div class="bottom-step-buttons">
          <button @click="handleClose" class="left-button">取消</button>
          <button @click="save">确定</button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  components: {},
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  props: {
    dialogShow: {
      type: Boolean,
      default: false
    },
    remindData: {
      type: Array
    }
  },
  watch: {
    dialogShow: {
      handler(val) {
        this.dialogVisible = val
      }
    }
  },
  data() {
    return {
      dialogVisible: this.dialogShow,
      form: {
        id: '',
        startBeforeDay: 0,
        remindTime: '10:30'
      },
      dayOption: [
        {
          label: '每天提醒',
          value: 0
        },
        {
          label: '每周提醒',
          value: 1
        },
        {
          label: '每月提醒',
          value: 2
        }
      ],
      weekdays: [
        { name: '一', check: false, value: 2 },
        { name: '二', check: false, value: 3 },
        { name: '三', check: false, value: 4 },
        { name: '四', check: false, value: 5 },
        { name: '五', check: true, value: 6 },
        { name: '六', check: false, value: 7 },
        { name: '日', check: false, value: 1 }
      ],
      monthDays: [],
      selectDays: [], // 选中的天数
      selectWeeks: [] // 选中的星期数
    }
  },
  computed: {
    remindText() {
      const type = this.form.startBeforeDay
      if (type === 0) {
        return '每天'
      } else if (type === 1) {
        const days = []
        this.weekdays.forEach((item) => {
          if (item.check) {
            days.push(`周${item.name}`)
          }
        })
        return `每${days.join('、')}`
      } else {
        const days = []
        this.monthDays.forEach((item) => {
          if (item.check) {
            days.push(`${item.name}日`)
          }
        })
        return `每月${days.join('、')}`
      }
    }
  },
  created() {
    for (let i = 1; i < 32; i++) {
      this.monthDays.push({
        name: i,
        check: false
      })
    }
    this.initData()
  },
  methods: {
    // 初始化数据
    initData() {
      console.log(this.remindData)
      if (this.remindData.length > 0) {
        this.remindData.forEach((item) => {
          if (item.notifyTimeType === 0) {
            this.form.id = item.id
            this.form.startBeforeDay = item.notifyDataType
            this.form.remindTime = item.notifyDataTimeNum
            const list = item.notifyDataNum.split(',')
            if (item.notifyDataType === 1) {
              this.weekdays.forEach((day) => {
                list.forEach((dayItem) => {
                  if (dayItem === day.value) {
                    this.$set(day, 'check', true)
                  }
                })
              })
            }
            if (item.notifyDataType === 2) {
              list.forEach((item) => {
                const index = Number(item) - 1
                this.$set(this.monthDays[index], 'check', true)
              })
            }
          }
        })
      }
    },
    // 保存
    save() {
      const data = []
      let dayList = []
      if (this.form.startBeforeDay === 1) {
        dayList = this.weekdays
          .filter((item) => {
            return item.check
          })
          .map((item) => item.value)
      }
      if (this.form.startBeforeDay === 2) {
        dayList = this.monthDays
          .filter((item) => {
            return item.check
          })
          .map((item) => item.name)
      }
      dayList.sort()
      if (this.form.startBeforeDay !== 0 && dayList.length === 0) {
        this.$message.warn('请设置提醒时间！')
        return
      }
      data.push({
        id: this.form.id,
        notifyType: 1, // 提醒类型（0填写提醒 1更新进度提醒 2复盘提醒 ）
        notifyTimeType: 0, // 提醒时间类型（0周期进行中 1周期开始前 2周期开始后 3周期结束前 4周期结束后 ）
        notifyDataType: this.form.startBeforeDay, // 提醒数据类型（0 天 1周 2月 ）
        notifyDataNum: dayList.join(','), // 提醒数据值 列如 ”2,3“ 正序排列
        notifyDataTimeNum: this.form.remindTime // 提醒数据时间值
      })
      this.$emit('remindSet', data)
      this.handleClose()
    },
    handleClose() {
      if (this.changeState) {
        this.$emit('changeUpdate')
      }
      this.dialogVisible = false
      this.$emit('change', false)
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../../assets/styles/dialog.less';
.dialog-content {
  padding: 32px 16px 16px;
  .time-form {
    display: flex;
    .day-options {
      flex-shrink: 0;
      width: 260px;
      margin-left: 16px;
      .week-days {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .week-item {
          width: 32px;
          height: 32px;
          border: 1px solid #f5f6fa;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 4px;
          color: #6e7491;
          cursor: pointer;
          &:hover {
            background: rgba(58, 120, 246, 0.06);
            border: 1px solid rgba(58, 120, 246, 0.87);
            color: #3a78f6;
          }
        }
        .selected-item {
          color: #3a78f6;
          border: 1px solid rgba(58, 120, 246, 0.87) !important;
        }
      }
      .month-days {
        .week-item {
          border: 1px solid transparent;
          margin-bottom: 4px;
        }
      }
    }
  }
  .form-item {
    display: flex;
    align-items: center;
    color: #000;
    line-height: 16px;
    .item-title {
      width: 38px;
    }
    .form-select {
      width: 140px;
    }
    .interval-select {
      width: 90px;
    }
    .remind-text {
      margin-top: 12px;
      margin-left: 8px;
    }
    ::v-deep {
      .el-input--suffix .el-input__inner {
        padding-right: 10px !important;
      }
    }
    &:first-of-type {
      margin-bottom: 24px;
    }
  }
  .remind-text {
    height: 32px;
    font-size: 12px;
    line-height: 16px;
    margin-top: 8px;
    color: #a0a3bd;
    margin-left: 24px;
    .remind-time {
      white-space: nowrap;
    }
  }
}
</style>
