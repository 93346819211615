var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "keyResults", on: { click: _vm.getAddkeyList } },
    [
      _c("Icon", {
        staticClass: "keyResultsImg",
        attrs: { "icon-class": "icon-a-tianjia2" },
      }),
      _c("div", { staticClass: "keyResultsText" }, [_vm._v("关键结果")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }