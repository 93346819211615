<template>
  <div class="task-text">
    <div v-if="!showContent" class="show-btn" @click="clickShow">未填写</div>
    <el-input
      v-if="showContent"
      :ref="`input${fieldInfo.id}`"
      v-model="inputVal"
      :autosize="{ minRows: 1, maxRows: 4 }"
      type="textarea"
      :maxlength="150"
      :disabled="disabled"
      @input="inputChange"
      @blur="blurInput"
    />
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fieldInfo: {
      type: String,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      inputVal: '',
      showContent: false
    }
  },
  watch: {
    value: {
      handler(val) {
        this.inputVal = val
      }
    }
  },
  methods: {
    clickShow() {
      if (this.disabled) return
      this.showContent = true
      this.$nextTick(() => {
        const refname = `input${this.fieldInfo.id}`
        this.$refs[refname].focus()
      })
    },
    inputChange(val) {
      this.$emit('change', val)
    },
    blurInput() {
      if (!this.inputVal || this.inputVal === '') {
        this.showContent = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.task-text {
  min-height: 33px;
  line-height: 33px;
  .show-btn {
    color: #a0a3bd;
    cursor: pointer;
    &:hover {
      color: #3a78f6;
    }
  }
  ::v-deep {
    .el-textarea,
    .el-textarea__inner {
      border: none !important;
      resize: none;
      color: #a0a3bd;
    }
    .el-textarea__inner {
      width: 420px;
      padding: 8px 16px;
    }
  }
}
</style>
