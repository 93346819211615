<template>
  <el-dialog
    :visible.sync="centerDialogVisibles"
    :width="width"
    :top="top"
    :append-to-body="appendToBody"
    :close-on-click-modal="true"
    :destroy-on-close="true"
    class="single-dialog"
    :show-close.sync="showClo"
    :before-close="handleDialogClose"
    v-on="$listeners"
  >
    <div slot="title" class="dialog-title-box">
      <div class="flex-a-c-center">
        <p class="title-txt" v-text="title"></p>
      </div>
    </div>
    <div class="guid_out">
      <div v-show="guidNext == 1" class="guid_top">
        <ul class="inviter_ul">
          <li class="inviter_li">
            <p class="guid_top_title">选择加入的部门周期及权限</p>
            <div class="inviter_li_lf flex-new">
              <p class="inviter_li_lf_p">加入部门</p>
              <div class="add_person" style="position: relative; flex: 1">
                <div
                  v-show="inviteFriendsStateShow"
                  class="add_person_shadow"
                  @click.stop="closeShadow"
                ></div>
                <div class="add_person_out" @click.stop="selectDepts">
                  <p
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      width: 226px;
                    "
                  >{{ select_value.name || '请选择' }}</p>
                  <i
                    class="el-icon-arrow-right friends_content_list_name_right el-icon-arrow-right-name"
                    :class="
                      inviteFriendsStateShow == true ? 'el-icon-router' : ''
                    "
                  ></i>
                </div>
                <div v-show="inviteFriendsStateShow" class="add_person_div">
                  <div class="management_arrow"></div>
                  <div class="add_person_div_inner">
                    <div v-if="inviteFriendsList.length > 0" class="invite_friends_title clearfix">
                      <div
                        class="friends_title_font"
                        @click.stop="getInviteFriendsPage('首页', index)"
                      >{{ companyInfo.name }}</div>
                      <div class="friends_title_font_text">
                        <span
                          v-for="(item, index) in inviteFriendsList"
                          :key="index"
                          @click.stop="getInviteFriendsPage('page', index)"
                        >
                          {{ item.label }}
                          <i
                            v-if="inviteFriendsList.length > index + 1"
                            class="el-icon-arrow-right friends_content_list_name_right"
                          ></i>
                        </span>
                      </div>
                    </div>

                    <div class="invite_friends_content">
                      <div
                        v-if="inviteFriendsBeans.departs.length > 0"
                        class="friends_content_list"
                      >
                        <div
                          v-for="(item, index) in inviteFriendsBeans.departs"
                          :key="index"
                          class="friends_content_list_name clearfix"
                          @click.stop="selectDepts_(item)"
                        >
                          <div class="friends_content_list_name_left">{{ item.departmentTitle }}</div>
                          <div class="friends_content_list_name_text">{{ item.label }}</div>
                          <i
                            v-if="item.id != '-1'"
                            v-show="item.children.length != 0"
                            class="el-icon-arrow-right friends_content_list_name_right"
                            @click.stop="getInviteFriendsListSelect_(index)"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="inviter_li_rg flex-new">
              <p class="inviter_li_lf_p">权限</p>
              <el-select v-model="jurisdiction" placeholder="请选择" :disabled="selectDisabled">
                <el-option
                  v-for="item in jurisdictionList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </div>
          </li>
          <li class="inviter_li">
            <div class="inviter_li_lfs flex-new">
              <p class="inviter_li_lf_p">加入周期</p>
              <ul class="inviter_li_lf_uls">
                <li v-for="(item, index) in cycleListPanel" :key="index">
                  {{ item.name }}
                  <Icon icon-class="icon-close" class="clear-img" @click="delCycle(item)" />
                </li>
                <li>
                  <Icon
                    icon-class="icon-task_cooperation_add_yes_not_icon"
                    class="inviter_guid_add"
                    @click="getInviteFriendsCycleShowState('show')"
                  />
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <p class="guid_top_title">
          填写手机号或邮箱
          <span @click="getInviteFriendsCodeShowState('show')">通过链接或者二维码邀请</span>
        </p>
        <ul class="guid_top_ul flex-new-two">
          <li>
            <span></span> &nbsp;姓名
          </li>
          <li class="guid_top_ul_li">
            <span>*</span> &nbsp;手机号
          </li>
          <li>
            <span></span> &nbsp;邮箱
          </li>
        </ul>
        <el-form ref="ManageForm" :model="form">
          <ul class="guid_top_ul guid_top_ul_bottom">
            <li v-for="(item, index) in form.dynamicItem" :key="index" class>
              <el-form-item
                label
                :prop="'dynamicItem.' + index + '.name'"
                :rules="[
                  { required: false, message: '姓名不能为空', trigger: 'blur' }
                ]"
              >
                <el-input
                  v-model="item.name"
                  placeholder="请输入姓名"
                  @input="invitationName(item.name, index)"
                />
              </el-form-item>
              <el-form-item
                id="el-form-item"
                label
                :prop="'dynamicItem.' + index + '.mobile'"
                :rules="[
                  {
                    required: item.mobileRules,
                    message: '手机号不能为空',
                    trigger: 'blur'
                  },
                  {
                    pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
                    message: '手机格式不正确'
                  }
                ]"
              >
                <el-input v-model="item.mobile" placeholder="请输入手机号" @blur="mobileBlur(index)" />
              </el-form-item>
              <el-form-item
                label
                :prop="'dynamicItem.' + index + '.email'"
                :rules="[
                  { required: false, message: '邮箱不能为空', trigger: 'blur' },
                  {
                    pattern: /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/,
                    message: '邮箱格式有误'
                  }
                ]"
              >
                <el-input v-model="item.email" placeholder="请输入邮箱" @blur="emailBlur(index)" />
              </el-form-item>
              <Icon
                v-show="index == 0"
                icon-class="icon-task_cooperation_add_yes_not_icon"
                class="guid_add list-icon-add inviter_guid_add"
                @click="addItem"
              />
              <Icon
                v-show="index != 0"
                icon-class="icon-close"
                class="guid_add list-icon-close"
                @click="deleteItem(item, index)"
              />
            </li>
          </ul>
        </el-form>
        <el-checkbox v-model="emailChecked">通过短信或邮件发送邀请</el-checkbox>
        <div class="guid_footer flex-new-four">
          <button
            class="login_next common_btn_next common_btn_next_able inviter-btns"
            @click="fishEnd('ManageForm')"
          >完成</button>
        </div>
      </div>
    </div>
    <!-- 二维码链接邀请好友 -->
    <div v-if="inviteFriendsCode" class="invite_friends_code">
      <div class="friends_code_box">
        <div class="friends_box_top">
          通过链接邀请
          <i
            class="el-icon-close friends_box_top_font"
            @click="getInviteFriendsCodeShowState('close')"
          ></i>
        </div>
        <div class="friends_code_box_img">
          <img class="friends_code_box_img_icon" :src="invitationBeans.shortUrlQrCode" />
        </div>
        <div class="friends_code_box_text">
          <div class="friends_code_box_text1">我和“{{ invitationBeans.companyName }}”的小伙伴都在源目标等你，</div>
          <div class="friends_code_box_text1">用这个专属链接加入我们吧！</div>
          <div class="friends_code_box_text1">{{ invitationBeans.shortUrl }}</div>
        </div>
        <button
          v-clipboard:copy="invitationBeans.shortUrl"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          class="friends_code_box_button"
        >复制邀请链接</button>
      </div>
    </div>
    <!-- 选择周期 -->
    <div v-if="inviteFriendsCycle" class="invite_friends_code">
      <div class="friends_code_box_cycle">
        <div class="friends_box_top">
          选择周期
          <i
            class="el-icon-close friends_box_top_font"
            @click="getInviteFriendsCycleShowState('close')"
          ></i>
        </div>
        <div class="friends_code_box_text_">
          <input
            v-model="cycle_value"
            type="text"
            placeholder="请输入周期"
            class="friends_code_box_input"
            @input="getInviteFriendsCycleLists"
          />
          <el-checkbox-group
            v-model="cycleList"
            class="friends_code_box_check flex-new-direction-column flex-new"
          >
            <el-checkbox
              v-for="item in list"
              :key="item.id"
              :label="item.id"
              :disabled="item.disabled"
              :checked="item.checked"
              :class="item.checked == true ? 'gray' : ''"
            >{{ item.name }}</el-checkbox>
          </el-checkbox-group>
        </div>
        <button
          class="friends_code_box_button"
          @click="getInviteFriendsCycleShowState('close_sure')"
        >确认</button>
      </div>
    </div>
    <div slot="footer" class="dialog-footer-box">
      <slot name="footer"></slot>
    </div>
  </el-dialog>
</template>
<script>
/* eslint-disable */
export default {
  props: {
    progress: {
      required: true,
      type: Boolean
    },
    showClo: {
      required: false,
      default: true,
      type: Boolean
    },
    centerDialogVisibles: {
      required: false,
      default: true,
      type: Boolean
    }
  },
  data() {
    return {
      form: {
        name: '',
        mobile: '',
        dynamicItem: [
          {
            name: '',
            mobile: '',
            email: '',
            invitationWays: 1,
            mobileRules: true,
            emailRules: false
          }
        ]
      },
      // 标题
      title: '邀请成员加入',
      // icon
      icon: '',
      // 宽度
      width: '700px',
      // 是否追加到body上
      appendToBody: false,
      // 是否显示取消按钮图标
      //关闭图层
      centerDialogVisibles: true,
      // 距离顶部高度
      top: '15vh',
      guidNext: 1, //切花下一步
      emailChecked: true, //通过邮箱复选框

      inputValue: '',
      inviteFriendsList: [], //返回上一级内容
      inviteFriendsBeans: { departs: [] }, //保存数据
      inviteFriendsId: '-1',
      inviteFriendsState: '部门',
      inviteFriendsStateShow: false,
      invitationBeans: { shortUrlQrCode: '', shortUrl: '', companyName: '' }, //邀请链接
      inviteFriendsCode: false, //二维码链接
      inviteFriendsCycle: false, //周期弹框
      cycleList: [], //季度列表
      cycle_value: '', //周期搜索时内容
      cycleLists: [], //季度列表
      cycleListPanel: [], //面板显示列表
      select_value: {
        //部门
        value: '-1',
        name: ''
      },
      jurisdiction: 0, //权限
      jurisdictionList: [
        { id: 0, name: '成员' },
        { id: 1, name: '管理员' }
      ],
      yetCycleList: [],
      cycleArrs: [],
      selectDisabled: false
    }
  },
  computed: {
    // 列表数据
    list() {
      if (this.cycle_value) {
        // console.log(this.cycleList)
        let changeList = this.cycleLists.filter((item) => {
          return item.value.indexOf(this.cycle_value) >= 0
        })
        this.cycleList = changeList.map((item) => {
          if (item.checked) {
            return item.id
          }
        })
        return changeList
      } else {
        return this.cycleLists
      }
    }
  },
  created() {
    this.centerDialogVisibles = true
    this.select_value.name = this.companyInfo.name
    this.getUser()
  },
  watch: {
    jurisdiction: function (newVal, oldVal) {
      if (newVal == 1 && this.userInfo.isSuperAdmin != 1) {
        this.$message({
          msg: `当前账号没有权限设置邀请人为管理员！`,
          type: 'error'
        })
        // this.selectDisabled = true
        this.jurisdiction = 0
      } else {
        // this.selectDisabled = false
      }
    }
  },
  methods: {
    //名字添加长度提醒
    invitationName(evt, index) {
      if (evt.length > 20) {
        this.$message({
          msg: '成员名称最多可以输入 20 个字符哦!',
          type: 'warn'
        })
        this.form.dynamicItem[index].name = evt.substring(0, 20)
        // this.inputValue = this.inputValue.substring(0,20);
      }
    },
    //验证手机号码是否可用 将另一个验证取消
    mobileBlur(num_) {
      let that = this
      let reg = /^1[34578]\d{9}$/
      if (
        reg.test(that.form.dynamicItem[num_].mobile) &&
        that.form.dynamicItem[num_].mobile.length > 10
      ) {
        that.form.dynamicItem[num_].emailRules = false
      }
    },
    emailBlur(num_) {
      let that = this
      let reg = /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/
      if (reg.test(that.form.dynamicItem[num_].email)) {
        that.form.dynamicItem[num_].mobileRules = false
      }
    },
    //添加表单
    addItem() {
      this.form.dynamicItem.push({
        name: '',
        mobile: '',
        email: '',
        invitationWays: 1,
        mobileRules: true,
        emailRules: true
      })
    },
    //删除表单
    deleteItem(item, index) {
      this.form.dynamicItem.splice(index, 1)
    },
    //完成
    fishEnd(formName) {
      this.$refs.ManageForm.validate((valid) => {
        if (valid) {
          let data_arr = this.form.dynamicItem
          if (this.emailChecked) {
            for (let i = 0; i < data_arr.length; i++) {
              data_arr[i].invitationWays = 1
            }
          } else {
            for (let i = 0; i < data_arr.length; i++) {
              data_arr[i].invitationWays = 0
            }
          }

          this._fished(data_arr)
        } else {
          //未通过验证
          return false
        }
      })
    },
    onError() {
      this.$message({
        msg: `复制失败！`,
        type: 'error'
      })
    },
    onCopy() {
      this.$message({
        msg: `复制成功！`,
        type: 'success'
      })
    },
    //邀请人填写电话和邮箱接口
    async _fished(obj) {
      var that = this
      let _objs = {},
        cycleArr_ = []
      for (let i = 0; i < this.cycleListPanel.length; i++) {
        cycleArr_.push(this.cycleListPanel[i].id)
      }
      _objs.departId = this.select_value.value
      _objs.permission = this.jurisdiction
      _objs.cycleId = cycleArr_.toString()
      _objs.userInvitationFroms = obj
      await this.$apis.INVITEMEMBER(_objs).then((res) => {
        if (res.status == '200') {
          //关闭弹框
          that.$store.commit('user/setCompanyInfo', that.companyInfo)
          that.$emit('closeInviterPerson')
        } else {
          that.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    //创建页面 请求原始数据 列表接口
    async getUser() {
      let para = {
        pageNum: 1,
        pageSize: 1
      }
      this.loading = true
      await this.$apis.ADMINGETCYCLELIST(para).then((res) => {
        if (res.status == 200) {
          // console.log(localStorage.getItem('quarterObject'),'asas')
          if (res.data.rows.length > 0) {
            //默认周期 不可更改
            // res.data.rows[0].disabled = false;
            // res.data.rows[0].checked = true;
            // res.data.rows[0].value = res.data.rows[0].name;
            // this.yetCycleList.push(res.data.rows[0]);
            if (localStorage.getItem('quarterObject')) {
              let yetObj = JSON.parse(localStorage.getItem('quarterObject'))
              yetObj.disabled = false
              yetObj.checked = true
              yetObj.value = yetObj.name
              // if(yetObj.id != res.data.rows[0].id){
              this.yetCycleList.push(yetObj)
              // }
            }
            this.cycleListPanel = this.yetCycleList
            this.getTargetPeriod()
          } else if (res.data.rows.length == 0) {
            //没有默认周期
            if (localStorage.getItem('quarterObject')) {
              let yetObj = JSON.parse(localStorage.getItem('quarterObject'))
              yetObj.disabled = false
              yetObj.checked = true
              yetObj.value = yetObj.name
              this.yetCycleList.push(yetObj)
            }
            this.cycleListPanel = this.yetCycleList
            // console.log(this.cycleListPanel)
            this.getTargetPeriod()
          }
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    //监听弹框右上角关闭按钮被点击
    handleDialogClose() {
      //  console.log('aaa')
      this.centerDialogVisibles = false
      this.$emit('closeInviterPerson')
    },

    //删除已添加周期
    delCycle(item) {
      if (this.cycleListPanel.length == 1) {
        this.$message({ msg: '至少保留一个周期!', type: 'warn' })
      } else {
        for (let i = 0; i < this.cycleList.length; i++) {
          if (this.cycleList[i] == item.id) {
            this.cycleList.splice(i, 1)
          }
        }
        for (let j = 0; j < this.cycleListPanel.length; j++) {
          if (this.cycleListPanel[j].id == item.id) {
            this.cycleListPanel.splice(j, 1)
          }
        }
      }
    },
    //二维码邀请
    getInviteFriendsCodeShowState(data) {
      if (data === 'close') {
        this.inviteFriendsCode = false
      } else if (data === 'show') {
        this.inviteFriendsCode = true
        this.getInviteFriendsCodeList()
      }
    },
    async getTargetPeriod() {
      let that = this
      that.cycleArrs = []
      await this.$apis.CYCLIST().then((rest) => {
        if (rest.data.length > 0) {
          (rest.data || []).forEach((list) => {
            (list.cycleInfoList || []).forEach((item) => {
              if ((list.type === 1 || list.type === 2) && item.type === 0) {
                item.name = `${item.startDate.substring(0, 4)}年 ${item.name}`
              }
            })
          })
          that.cycleArrs = rest.data || []
        }
      })
    },
    //周期弹框
    getInviteFriendsCycleShowState(data) {
      if (data === 'close_sure') {
        this.inviteFriendsCycle = false
        this.cycleListPanel = []
        for (let i = 0; i < this.cycleList.length; i++) {
          for (let j = 0; j < this.cycleLists.length; j++) {
            if (
              this.cycleList[i] == this.cycleLists[j].id &&
              !this.cycleLists[j].disabled
            ) {
              this.cycleListPanel.push(this.cycleLists[j])
            }
          }
        }
        if (this.cycleListPanel.length == 0) {
          this.$message({ msg: '至少有一个周期!', type: 'warn' })
          this.inviteFriendsCycle = true
        } else {
          let arrs = this.noRepeat([
            ...this.yetCycleList,
            ...this.cycleListPanel
          ])
          this.cycleListPanel = arrs
        }

        // this.cycleListPanel = [...this.cycleListPanel]
      } else if (data === 'close') {
        if (this.cycleList.length == 0) {
          this.$message({ msg: '至少有一个周期!', type: 'warn' })
        } else {
          this.inviteFriendsCycle = false
        }
      } else if (data === 'show') {
        this.inviteFriendsCycle = true
        this.getInviteFriendsCycleList()
      }
    },
    //数组去重
    noRepeat(_arr) {
      for (let n = 0; n < _arr.length - 1; n++) {
        //循环数组的所有元素
        for (let i = n + 1; i < _arr.length; i++) {
          //拿下标为n的元素和它后面的所有元素进行比较
          if (_arr[n].id == _arr[i].id) {
            //如果第i位上的元素和第n上的元素相等就删除
            _arr.splice(i--, 1) //使用splice方法删除重复的元素
          }
        }
      }
      return _arr
    },
    // 获取周期信息内容
    async getInviteFriendsCycleList() {
      for (let i = 0; i < this.cycleArrs.length; i++) {
        for (let j = 0; j < this.yetCycleList.length; j++) {
          if (this.cycleArrs[i].id == this.yetCycleList[j].id) {
            this.cycleArrs[i].disabled = false
            this.cycleArrs[i].checked = true
            this.cycleArrs[i].value = this.cycleArrs[i].name
            break
          } else if (this.cycleArrs[i].id != this.yetCycleList[j].id) {
            this.cycleArrs[i].disabled = false
            this.cycleArrs[i].checked = false
            this.cycleArrs[i].value = this.cycleArrs[i].name
          }
        }
      }
      this.cycleLists = []
      this.cycleLists = this.cycleArrs
      this.cycleLists = [...this.cycleLists]
      for (let i = 0; i < this.cycleList.length; i++) {
        for (let j = 0; j < this.cycleLists.length; j++) {
          if (
            this.cycleList[i] == this.cycleLists[j].id &&
            !this.cycleList[i].disabled
          ) {
            this.cycleLists[j].checked = true
          }
        }
      }
      this.cycleLists = [...this.cycleLists]
      this.cycleList = []
    },
    getInviteFriendsCycleLists() {
      let name_ = this.cycle_value || ''
      if (name_.replace(/\s*/g, '').length > 10) {
        this.$message({
          msg: '周期名称最多输入 10 个字符哦!',
          type: 'warn'
        })
        this.cycle_value = name_.substring(0, 10)
      }
    },
    // 获取code信息内容
    async getInviteFriendsCodeList() {
      let _objs = {},
        cycleArr_ = []
      for (let i = 0; i < this.cycleListPanel.length; i++) {
        cycleArr_.push(this.cycleListPanel[i].id)
      }
      _objs.departId = this.select_value.value
      _objs.permission = this.jurisdiction
      _objs.cycleId = cycleArr_.toString()
      await this.$apis
        .INVITECODEINFO(_objs)
        .then((rest) => {
          if (rest.status == 200) {
            this.invitationBeans = rest.data
          } else if (rest.status == 500) {
            this.$message({
              msg: rest.message,
              type: 'warn'
            })
          } else {
            this.$message({
              msg: rest.message,
              type: 'error'
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 搜索部门内容
    async getInviteFriendsList(type) {
      await this.$apis
        .DEPARTBYID(this.inviteFriendsId)
        .then((rest) => {
          this.inviteFriendsState = '部门'
          this.inviteFriendsStateShow = true
          rest.data.splice(0, 0, {
            label: this.companyInfo.name,
            id: this.inviteFriendsId,
            children: []
          })
          for (let i = 0; i < rest.data.length; i++) {
            rest.data[i].departmentTitle = rest.data[i].label.slice(0, 1)
          }
          // console.log(rest.data)
          this.inviteFriendsBeans.departs = rest.data
          // departs   部门内容
          // userInfos 人员内容
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 点击部门显示内容
    async getInviteFriendsListSelect_(index) {
      let beansDate = this.inviteFriendsBeans.departs[index]
      this.inviteFriendsList.push(beansDate)
      this.inviteFriendsId = this.inviteFriendsBeans.departs[index].id
      await this.$apis
        .DEPARTBYID(this.inviteFriendsId)
        .then((rest) => {
          this.inviteFriendsState = '部门'
          for (let i = 0; i < rest.data.length; i++) {
            rest.data[i].departmentTitle = rest.data[i].label.slice(0, 1)
          }
          this.inviteFriendsBeans.departs = []
          this.inviteFriendsBeans.departs = rest.data
          // console.log(this.inviteFriendsBeans)
          // departs   部门内容
          // userInfos 人员内容
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 分页搜索内容
    async getInviteFriendsPage(type, index) {
      if (type === '首页') {
        this.inputValue = ''
        this.inviteFriendsList = []
        this.inviteFriendsId = -1
        await this.$apis
          .DEPARTBYID(this.inviteFriendsId)
          .then((rest) => {
            this.inviteFriendsState = '部门'
            rest.data.splice(0, 0, {
              label: this.companyInfo.name,
              id: this.inviteFriendsId,
              children: []
            })
            for (let i = 0; i < rest.data.length; i++) {
              rest.data[i].departmentTitle = rest.data[i].label.slice(0, 1)
            }
            this.inviteFriendsBeans.departs = rest.data
            // departs   部门内容
            // userInfos 人员内容
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        this.inputValue = ''
        let list = this.inviteFriendsList
        this.inviteFriendsList = list.slice(0, index + 1)
        this.inviteFriendsId = this.inviteFriendsList[index].id
        await this.$apis
          .DEPARTBYID(this.inviteFriendsId)
          .then((rest) => {
            this.inviteFriendsState = '部门'
            for (let i = 0; i < rest.data.length; i++) {
              rest.data[i].departmentTitle = rest.data[i].label.slice(0, 1)
            }
            this.inviteFriendsBeans.departs = rest.data
            // departs   部门内容
            // userInfos 人员内容
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    //点击展示弹框显示弹框内容
    selectDepts() {
      this.inviteFriendsList = []
      this.inviteFriendsId = '-1'
      this.getInviteFriendsList('初始化')
    },
    //选择部门
    selectDepts_(item) {
      this.select_value.name = item.label
      this.select_value.value = item.id
      this.value = item.id
      this.inviteFriendsStateShow = false
    },
    //关闭遮罩层
    closeShadow() {
      this.inviteFriendsStateShow = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/variable.scss';
::v-deep .el-dialog__header {
  padding: 20px 20px 10px;
  border-bottom: 1px solid #dedede;
}
::v-deep .el-dialog__body {
  padding: 20px !important;
}
::v-deep .el-dialog__headerbtn {
  top: 10px;
}
.el-checkbox:last-of-type {
  margin-top: 10px !important;
}
.guid_out {
  padding: 20px 30px 0 30px;
  .guid_top {
    .guid_title {
      color: #ff9800;
      font-size: 21px;
      font-family: OPPOSans-B, OPPOSans;
      line-height: 32px;
      margin-bottom: 10px;
    }
    .guid_top_title {
      line-height: 18px;
      color: $mine-shaft;
      margin-bottom: 16px;
      font-weight: 600;
      span {
        font-size: 12px;
        color: #409eff;
        cursor: pointer;
        margin-left: 6px;
        line-height: 14px;
      }
    }
    .inviter_ul {
      margin-bottom: 16px;
    }
    .inviter_li:after {
      content: '';
      display: block;
      clear: both;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }
    .inviter_li {
      margin-bottom: 6px;
      .inviter_li_lf_p {
        line-height: 30px;
        margin-right: 6px;
        align-items: center;
        align-self: center;
      }
      .inviter_guid_add {
        width: 20px;
        height: 20px;
        align-self: center;
        cursor: pointer;
        position: relative;
        top: 3px;
        font-size: 14px;
        background: #3068ff;
        border-radius: 50%;
        &:hover {
          background: $hover-color;
        }
      }
      .inviter_li_lf_uls:after {
        content: '';
        display: block;
        clear: both;
        height: 0;
        overflow: hidden;
      }
      .inviter_li_lf_uls {
        float: left;
        width: 82%;
        @include scroll-beautify;
        max-height: 100px;
        li {
          float: left;
          line-height: 14px;
          margin-right: 6px;
          margin-left: 6px;
          margin-top: 5px;
          padding: 4px 6px;
          background: #3068ff;
          color: #fff;
          border-radius: 3px;
          position: relative;
          .clear-img {
            width: 16px;
            position: absolute;
            top: -8px;
            left: -8px;
            cursor: pointer;
            display: none;
            float: right;
          }
        }
        li:hover {
          .clear-img {
            display: block;
          }
        }
        li:last-child {
          padding: 0;
          background: #fff;
        }
      }
      .inviter_li_lfs {
        float: left;
        width: 100%;
      }
      .inviter_li_lf {
        width: 49%;
        float: left;
      }
      .inviter_li_rg {
        width: 49%;
        float: right;
        .inviter_li_lf_p {
          line-height: 30px;
          margin-right: 6px;
        }
      }
    }
    .flex-new-two {
      li {
        float: left;
        width: 33%;
      }
    }
    .flex-new-two::after {
      content: '';
      clear: both;
      display: block;
    }
    .guid_top_ul {
      justify-content: space-between;
      line-height: 18px;
      font-size: 16px;
      // padding: 0 36px;
      li {
        flex: 1;
        &.guid_top_ul_li {
          // margin: 0 -6px;
        }
      }
      span {
        color: $red-message;
      }
    }
    // .el-input__inner {
    //   width:170px !important;
    // }
    .guid_top_ul_bottom {
      @include scroll-beautify;
      overflow-x: hidden;
      height: 100px;
      li:after {
        content: '';
        display: block;
        clear: both;
      }
      li {
        justify-content: space-between;
        padding-top: 20px;
        .guid_add {
          width: 20px;
          align-self: center;
          cursor: pointer;
          position: relative;
          top: 8px;
          left: 2px;
          color: #6e7491;
        }
        #el-form-item {
          margin: 0 3% !important;
        }
        .el-form-item {
          float: left;
          width: 30% !important;
        }
        .list-icon-add {
          width: 20px;
          height: 20px;
          cursor: pointer;
          font-size: 14px;
          background: #3068ff;
          border-radius: 50%;
          color: #fff;
          &:hover {
            background: $hover-color;
          }
        }
        .list-icon-close {
          &:hover {
            color: $hover-color;
          }
        }
      }
    }
    .guid_top_content {
      margin-bottom: 20px;
      p {
        text-align: center;
        font-size: 16px;
        color: $dusty-gray;
        line-height: 28px;
        margin: 0 30px;
        background: #f3f3f5;
        padding: 10px;
        border-radius: 6px;
      }
      .guid_form {
        justify-content: center;
        margin-top: 24px;
      }
      .guid_btns {
        width: 300px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #ff9900;
        color: #ff9900;
        display: block;
        margin: 0 auto;
      }
    }
    .guid_text {
      font-size: 12px;
      line-height: 16px;
      color: $dove-gray;
      margin-bottom: 44px;
    }
  }
  .guid_footer {
    margin-top: 40px;
    button {
      display: block;
      margin: 0 auto;
      width: 200px;
    }
  }
  .guid_content {
    li {
      line-height: 32px;
      margin-bottom: 26px;
      margin-left: -14%;
      p {
        width: 100px;
        text-align: right;
        margin-right: 24px;
      }
      .guid_label {
        color: $red-message;
        display: inline-block;
      }
      .guid_filedset {
        height: 36px;
        line-height: 36px;
        width: 300px;
        border: 1px solid $botderColor;
        border-radius: 6px;
        overflow: hidden;
        input {
          flex: 1;
          border: 0;
        }
        .guid_babel_com {
          width: 100px;
          text-align: center;
          line-height: 36px;
          background: $botderColor;
          color: $mine-shaft;
        }
      }
      .guid_why {
        width: 16px;
        align-self: center;
        margin-left: 5px;
      }
    }
  }
}
.invite_friends_code {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  background: rgba(0, 0, 0, 0.15);
  .friends_code_box {
    width: 435px;
    min-height: 465px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 15px 0px rgba(0, 24, 87, 0.1);
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -218px;
    margin-top: -238px;
    padding-bottom: 30px;
    .friends_box_top {
      width: 100%;
      height: 45px;
      border-radius: 1px;
      text-align: center;
      line-height: 45px;
      font-size: 17px;
      font-family: 'OPPOSans-B,OPPOSans';
      font-weight: normal;
      color: rgba(51, 51, 51, 1);
      border-bottom: 1px solid rgba(224, 227, 236, 1);
      position: relative;
      .friends_box_top_font {
        font-size: 20px;
        color: rgba(150, 159, 190, 1);
        position: absolute;
        right: 17px;
        top: 14px;
        cursor: pointer;
      }
    }
    .friends_code_box_img {
      width: 100%;
      height: 150px;
      margin-top: 40px;
      .friends_code_box_img_icon {
        width: 150px;
        height: 150px;
        display: block;
        margin: 0px auto 0px auto;
      }
    }
    .friends_code_box_text {
      width: 372px;
      min-height: 90px;
      background: rgba(243, 243, 245, 0.6);
      border-radius: 3px;
      border: 1px solid rgba(224, 227, 236, 0.8);
      margin: 21px auto 0px auto;
      padding-bottom: 10px;
      .friends_code_box_text1 {
        width: 100%;
        font-size: 12px;
        font-family: 'OPPOSans-R,OPPOSans';
        font-weight: normal;
        color: rgba(92, 93, 97, 1);
        text-align: center;
        line-height: 21px;
        word-break: break-word;
        padding-left: 10px;
        padding-right: 10px;
      }
      .friends_code_box_text1:first-child {
        margin-top: 13px;
      }
    }
    .friends_code_box_button {
      width: 375px;
      height: 41px;
      background: #5a87ff;
      border-radius: 3px;
      margin: 36px auto 0px auto;
      border: none;
      display: block;
      color: #ffffff;
      cursor: pointer;
    }
  }
  .friends_code_box_cycle {
    width: 250px;
    min-height: 265px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 15px 0px rgba(0, 24, 87, 0.1);
    position: absolute;
    left: 50%;
    top: 61%;
    margin-left: -125px;
    margin-top: -238px;
    padding-bottom: 30px;
    .friends_box_top {
      width: 100%;
      height: 45px;
      border-radius: 1px;
      text-align: center;
      line-height: 45px;
      font-size: 17px;
      font-family: 'OPPOSans-B,OPPOSans';
      font-weight: normal;
      color: rgba(51, 51, 51, 1);
      border-bottom: 1px solid rgba(224, 227, 236, 1);
      position: relative;
      .friends_box_top_font {
        font-size: 20px;
        color: rgba(150, 159, 190, 1);
        position: absolute;
        right: 17px;
        top: 14px;
        cursor: pointer;
      }
    }
    .friends_code_box_input {
      border: 1px solid #dedede;
      padding: 11px 0;
      flex: 1;
      text-indent: 10px;
      font-size: 16px;
      width: 100%;
    }
    .friends_code_box_img {
      width: 100%;
      height: 150px;
      margin-top: 40px;
      .friends_code_box_img_icon {
        width: 150px;
        height: 150px;
        display: block;
        margin: 0px auto 0px auto;
      }
    }
    .friends_code_box_text {
      width: 372px;
      min-height: 90px;
      background: rgba(243, 243, 245, 0.6);
      border-radius: 3px;
      border: 1px solid rgba(224, 227, 236, 0.8);
      margin: 21px auto 0px auto;
      padding-bottom: 10px;
      .friends_code_box_text1 {
        width: 100%;
        font-size: 12px;
        font-family: 'OPPOSans-R,OPPOSans';
        font-weight: normal;
        color: rgba(92, 93, 97, 1);
        text-align: center;
        line-height: 21px;
        word-break: break-word;
        padding-left: 10px;
        padding-right: 10px;
      }
      .friends_code_box_text1:first-child {
        margin-top: 13px;
      }
    }
    .friends_code_box_button {
      width: 200px;
      height: 41px;
      background: #5a87ff;
      border-radius: 3px;
      margin: 36px auto 0px auto;
      border: none;
      display: block;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
.el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin: 0 !important;
}
.el-button--small,
.el-button--small.is-round {
  width: 260px !important;
  line-height: 40px !important;
  padding: 0 !important;
}
.el-input--small .el-input__inner {
  height: 36px !important;
  line-height: 36px;
  width: 220px !important;
}
.dialog-title-box {
  .title-icon {
    width: 16px;
    height: 16px;
  }
  .title-txt {
    font-size: 16px;
    margin-left: 12px;
    text-align: center;
    flex: 1;
  }
}
.dialog-footer-box {
  button {
    padding: 0 20px;
    height: 32px;
    padding: 0 23px;
    font-size: 14px;
  }
  .cancel-btn {
    background-color: transparent;
    border: 0;
    color: $mine-shaft;
  }
  .next-btn {
    border: 1px solid $azure-radiance;
    &[disabled] {
      border: 1px solid $dusty-gray;
    }
  }
}

.add_p {
  font-size: 16px;
  color: #333;
  line-height: 24px;
  margin-bottom: 8px;
  span.add_span {
    color: #fa972b;
    font-size: 14px;
  }
}
.add_person_shadow {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 999;
}
.add_person_div {
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 5px 0;
  position: absolute;
  left: 0;
  top: 36px;
  width: 100%;
  z-index: 9999;
}
.add_person_div_inner {
  width: 100%;
  overflow-x: hidden;
}
.add_person_out::after {
  content: '';
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
}
.add_person_out {
  cursor: pointer;
  border: 1px solid #dedede;
  border-radius: 4px;
  // position: relative;
  p {
    line-height: 38px;
    height: 38px;
    color: #333;
    padding: 0 16px;
    font-size: 14px;
    float: left;
  }
  i.el-icon-arrow-right-name {
    position: absolute;
    top: 36%;
    right: 16px;
    transition: all 1s; /* 过渡写再本身是 谁做动画写再谁身上  s  ms*/
  }
}
.add_bottom {
  padding-top: 20px;
  .add_bottom_erweima {
    width: 150px;
    display: block;
    margin: 0 auto;
  }
  p.add_bottom_p {
    text-align: center;
    line-height: 24px;
    font-size: 14px;
    color: #333;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .guid_btns {
    width: 300px;
    height: 40px;
    border-radius: 4px;
    // border:1px solid #ff9900;
    color: #fff;
    display: block;
    margin: 0 auto;
    cursor: pointer;
  }
}
.invite_friends_title {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid rgba(224, 227, 236, 1);
  padding-top: 1px;
  .friends_title_font {
    font-size: 15px;
    font-family: 'OPPOSans-B,OPPOSans';
    font-weight: 700;
    color: rgba(51, 51, 51, 1);
    float: left;
    margin-left: 16px;
    margin-top: 17px;
    max-width: 27%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    &:before {
      content: '';
      display: block;
    }
  }
  .friends_title_font_text {
    margin-left: 10px;
    font-size: 15px;
    font-family: 'OPPOSans-B,OPPOSans';
    font-weight: normal;
    color: rgba(51, 51, 51, 1);
    line-height: 50px;
    float: left;
    cursor: pointer;
  }
}

.invite_friends_content {
  width: 104%;
  max-height: 160px;
  min-height: 160px;
  overflow-x: hidden;
  .friends_content_list {
    width: 100%;
    min-height: 45px;
    // border-bottom: 1px solid rgba(216,216,216,1);
    .friends_content_list_name {
      width: 100%;
      height: 45px;
      cursor: pointer;
      .friends_content_list_name_left {
        width: 30px;
        height: 30px;
        background: rgba(48, 104, 255, 1);
        border-radius: 15px;
        float: left;
        margin-left: 17px;
        margin-top: 8px;
        font-size: 15px;
        font-family: 'STYuanti-SC-Bold,STYuanti-SC';
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        text-align: center;
        line-height: 30px;
      }
      .friends_content_list_name_text {
        font-size: 14px;
        font-family: 'OPPOSans-M,OPPOSans';
        font-weight: normal;
        color: rgba(51, 51, 51, 1);
        height: 100%;
        line-height: 45px;
        float: left;
        margin-left: 11px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 50%;
        &:before {
          content: '';
          display: block;
        }
      }
      .friends_content_list_name_right {
        font-size: 20px;
        float: right;
        color: rgba(224, 227, 236, 1);
        margin-right: 14px;
        margin-top: 13px;
      }
      .friends_content_list_name_button {
        width: 74px;
        height: 27px;
        border-radius: 3px;
        border: 1px solid rgba(48, 104, 255, 1);
        font-size: 12px;
        font-family: 'OPPOSans-M,OPPOSans';
        font-weight: normal;
        color: rgba(48, 104, 255, 1);
        text-align: center;
        line-height: 27px;
        float: right;
        margin-right: 15px;
        margin-top: 9px;
        display: none;
      }
      .friends_content_list_name_button_font {
        height: 45px;
        font-size: 12px;
        font-family: 'OPPOSans-M,OPPOSans';
        font-weight: normal;
        color: #969fbe;
        float: right;
        line-height: 45px;
        margin-right: 27px;
      }
    }
    .friends_content_list_name:hover {
      background: #f4f7fa;
      .friends_content_list_name_button {
        display: block;
      }
    }
    .friends_content_list_ul {
      width: 100%;
      .friends_content_list_li {
        width: 100%;
        height: 45px;
        margin-top: 5px;
        cursor: pointer;
        position: relative;
        .friends_content_list_li_icon {
          width: 30px;
          height: 30px;
          margin-top: 8px;
          margin-left: 55px;
          float: left;
        }
        .friends_content_list_li_name {
          height: 100%;
          line-height: 45px;
          float: left;
          margin-left: 11px;
          font-size: 14px;
          font-family: 'OPPOSans-M,OPPOSans';
          font-weight: normal;
          color: rgba(51, 51, 51, 1);
        }
        .friends_content_list_li_button {
          width: 59px;
          height: 22px;
          border-radius: 2px;
          border: 1px solid rgba(48, 104, 255, 1);
          float: right;
          font-size: 10px;
          font-family: 'OPPOSans-M,OPPOSans';
          font-weight: normal;
          color: rgba(48, 104, 255, 1);
          text-align: center;
          line-height: 22px;
          cursor: pointer;
          margin-top: 11px;
          margin-right: 16px;
          position: absolute;
          right: 16px;
          top: 1px;
          display: none;
        }
        .friends_content_list_name_font {
          font-size: 10px;
          font-family: 'OPPOSans-M,OPPOSans';
          font-weight: normal;
          color: rgba(150, 159, 190, 1);
          height: 100%;
          line-height: 45px;
          float: right;
          margin-right: 43px;
        }
      }
      .friends_content_list_li:hover {
        .friends_content_list_li_button {
          display: block;
        }
        .friends_content_list_name_font {
          display: none;
        }
      }
    }
  }
}

.management_arrow:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  top: -20px;
  left: 24px;
  margin-left: -6px;
  border-top-width: 0;
  border-bottom-color: #e4e7ed;
  border-width: 10px;
  offset-rotate: 90;
  transform: rotate(0deg);
}
.el-icon-router {
  transform: rotate(90deg); /*顺序有关系 先 移动后缩放*/
}
</style>
<style lang="scss">
.float_rg {
  .el-form-item__content {
    margin-left: 6px !important;
    line-height: 30px;
  }
}
.friends_code_box_check {
  max-height: 200px;
  overflow-x: hidden;
  .el-checkbox:last-of-type {
    margin-top: 20px !important;
  }
  .el-checkbox {
    margin-top: 20px !important;
    margin-left: 30px !important;
  }
}
.gray .el-checkbox__input.is-disabled + span.el-checkbox__label {
  color: #c0c4cc !important;
}
</style>
>
