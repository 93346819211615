const versionNumber = 'v1' // 版本
const versionNumber2 = 'v2' // 版本
// 公共模块api
export default [
  {
  // 文件上传
  fileUpload: `/api/oss/${versionNumber}/common/file`
},
{
  // 文件上传
  fileUploadBase: `/api/oss/${versionNumber}/file/base64`
},
{
  // 批量文件上传
  batchFileUpload: `/api/oss/${versionNumber}/common/files`
},
{
  // 批量文件下载
  fileDownLoad: `/api/oss/${versionNumber}/batchDownLoad/file`
},
{
  // 单个文件下载
  fileDownLoadOne: `/api/oss/${versionNumber}/downLoad/file`
},
{
  // 文件预览
  filePreview: `/api/oss/${versionNumber}/preview/file`
},
{
  // 根据用户id获取用户信息
  userInfoById: `/api/admin/${versionNumber}/user/`
},
{
  // 获取默认企业信息
  getDefaultCompanyInfo: `/api/admin/${versionNumber}/company/getDefault`
},
{
  // 获取邀请信息（二维码及链接）
  inviteCodeInfo: `/api/admin/${versionNumber}/user/invitation`
},
{
  // 获取指定周期邀请信息（二维码及链接）
  inviteCodeInfoByCycle: `/api/admin/${versionNumber}/user/invitation/`
},
{
  // 邀请成员
  inviteMember: `/api/admin/${versionNumber}/user/addUnifiedInvitationUser`
},
{
  // 获取行业角色列表
  queryIndustryRoles: `/api/ranloWeb/${versionNumber}/dictionary/list/role`
},
{
  // 获取token
  getUserToken: `/api/auth/jwt/refresh`
},
{
  // 根据用户手机号获取用户信息
  userInfoByMobile: `/api/admin/${versionNumber}/user/info`
},
{
  // 获取引导流程状态
  getComCurrentProcess: `/api/admin/${versionNumber}/guidance/queryCurrentProcess`
},
{
  // 获取验证码
  userSendSms: `/api/admin/verifiCode/${versionNumber}/sendSms`
},
{
  // 搜索人员
  selectUsers: `/api/admin/${versionNumber}/user/selectUsers`
},
{
  // 获取公司内所有成员列表
  queryAllMembersInCompany: `/api/admin/${versionNumber}/user/selectUsers/`
},
// 钉钉登录换平台token
{
  getDdToken: `/api/initialize/home/login`
},
// 获取签名数据
{
  getJspApiConfig: `/api/initialize/home/jspApiConfig`
},
{
  // 获取公司内所有成员列表
  queryAllMembersInCompanyV2: `/api/admin/${versionNumber2}/user/selectUsers`
},
{
  // 获取版本是否到期
  queryVersionIsValid: `/api/admin/${versionNumber}/company/user/expire`
},
{
  // 查询是否需要提交意见
  adminOpinionState: `/api/admin/${versionNumber}/opinion/state`
},
{
  // 提交满意度
  adminOpinionCommit: `/api/admin/${versionNumber}/opinion/commit`
},
{
  // 获取成长旅程列表
  userProcessList: `/api/admin/${versionNumber}/userProgress/list`
},
{
  // 获取小火箭第几步
  rocketStep: `/api/admin/${versionNumber}/userProgress/commitUserProgress/`
},
{
  // 查看服务器时间
  systemDate: `/api/auth/${versionNumber}/systemDate`
},
// 设置消息为已读
{
  clearRedpointMsg: `/api/notify/${versionNumber}/notify/busic/read`
},
// 消除红点
{
  clearRedpoint: `/api/notify/${versionNumber}/notify/behavior/user/wipe`
},
// 公共数据查询接口
{
  commonData: `/api/admin/${versionNumber}/common/data`
},
// 预约演示
{
  commonAppointment: `/api/admin/${versionNumber}/appointment/`
},
// 申请延期
{
  commonAppointmentDelay: `/api/admin/${versionNumber}/appointment/delay/`
},
// 申请报价
{
  commonAppointmentPrice: `/api/admin/${versionNumber}/consult/price/`
}
]
