<template>
  <div>
    <el-dialog
      :title="fileObj.fileName"
      :visible.sync="isShow"
      :append-to-body="true"
      :modal-append-to-body="true"
      :destroy-on-close="true"
      :close-on-click-modal="true"
      class="file-preview"
      :show-close="false"
      :before-close="closePreview"
    >
      <div class="preview-title" slot="title">
        <div class="title-left">
          <div class="title" v-show="fileObj.fileName">{{ fileObj.fileName }}</div>
          <div
            class="time"
            v-show="fileObj.createName && fileObj.fileTime"
          >{{ fileObj.createName }} 上传于 {{ fileObj.fileTime }}</div>
        </div>
        <div class="title-right">
          <el-button type="primary" class="download-button" @click="fileDownload" v-if="isDownload">
            <Icon class="file-svg" icon-class="icon-xiazai1" />下载
          </el-button>
          <el-button
            class="copy-button"
            type="primary"
            v-clipboard:copy="fileObj.fileUrl"
            v-clipboard:success="copyUrl"
            v-clipboard:error="onError"
          >
            <Icon class="file-svg" icon-class="icon-fuzhilianjie1" />复制链接
          </el-button>
          <div class="title-icon-div" @click="closePreview">
            <Icon icon-class="icon-guanbi1" class="title-icon" />
          </div>
        </div>
      </div>
      <div class="file-main">
        <template
          v-if="
            isShowView &&
            (fileObj.fileType === 'png' ||
              fileObj.fileType == 'bmp' ||
              fileObj.fileType === 'jpg' ||
              fileObj.fileType === 'jpeg' ||
              fileObj.fileType === 'gif' ||
              fileObj.fileType === 'svg' ||
              fileObj.fileType === 'PNG' ||
              fileObj.fileType === 'JPG' ||
              fileObj.fileType === 'JPEG' ||
              fileObj.fileType === 'GIT' ||
              fileObj.fileType === 'SVG') &&
            isPreview
          "
        >
          <viewer @inited="inited" ref="viewer" :options="options">
            <img :src="fileObj.fileUrl" />
          </viewer>
        </template>

        <template v-else>
          <iframe
            v-show="isPreview && filePreviewMessage"
            :src="filePreviewUrl"
            width="100%"
            height="100%"
            border="0"
            id="iframe"
            style="border: none !important"
          ></iframe>
          <div class="no-preview" v-if="!isPreview">
            <div class="svg-div">
              <Icon class="file-svg" icon-class="icon-fileCommon" />
            </div>
            <div class="title">{{ fileObj.fileName }}</div>
            <el-button type="primary" @click="fileDownload" class="upload-button" v-if="!disabled">
              下载文件
              <template
                v-if="fileObj.fileSize"
              >（ {{ $utils.formatFileSize(fileObj.fileSize) }} ）</template>
            </el-button>
          </div>
          <div
            class="preview-password-wrap"
            v-if="filePreviewPassword"
          >很抱歉，由于此文档使用不受支持的功能，所以无法在浏览器中打开;</div>
        </template>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// import Vue from 'vue'
// import Viewer from 'v-viewer'
// import 'viewerjs/dist/viewer.css'
// Vue.use(Viewer)

import Vue from 'vue'
import Viewer from './viewer/v-viewer'
Vue.use(Viewer)

export default {
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    },
    fileObj: {
      type: Object
    },
    isPreview: {
      type: Boolean,
      default: true
    },
    isDownload: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    visible: {
      handler(val, old) {
        console.log('预览显隐', val)
        this.isShow = val
      },
      deep: true
    }
  },
  created() {
  },
  mounted() {
    if (this.fileObj.previewUrl) {
      let preUrl = this.fileObj.previewUrl
      let url = preUrl.slice(4)
      this.filePreviewUrl = 'https' + url
    }
    if (this.fileObj.fileUrl) {
      let urlArr = this.fileObj.fileUrl.split('://')
      if (urlArr.length > 0) {
        if (urlArr[0] == 'https') {
          this.fileObj.fileUrl = 'http://' + urlArr[1]
        }
      }
    }
    var that = this
    window.addEventListener('message', function(e) {
      that.filePreviewMessage = true
      that.getFilePreviewPassword()
    }, false)
    setTimeout(() => {
      this.getFilePreviewPassword()
    }, 1000)
  },
  data() {
    return {
      filePreviewUrl: '', //文件预览
      isShowView: true,
      isShow: this.visible,
      url: '',
      options: {
        inline: true,
        button: true,
        navbar: false,
        title: false,
        toolbar: true,
        tooltip: true,
        movable: true,
        zoomable: true,
        rotatable: true,
        scalable: true,
        transition: true,
        fullscreen: true,
        keyboard: true
      },
      fileDownloadUrl: '',
      filePreviewMessage: false,
      filePreviewPassword: false
    }
  },
  methods: {
    getFilePreviewPassword() {
      if (this.isPreview && !this.filePreviewMessage) {
        this.filePreviewPassword = true
      } else {
        this.filePreviewPassword = false
      }
    },
    copyUrl() {
      this.$message({
        msg: `复制成功！`,
        type: 'success'
      })
    },
    onError() {
      this.$message({
        msg: `复制失败！`,
        type: 'error'
      })
    },
    closePreview() {
      this.visible = false
      this.$emit('change', false)
    },
    fileDownload() {
      if (this.$utils.isWxMac()) {
        window.location.href = this.fileObj.fileUrl
      } else {
        const data = {}
        data['fileUrl'] = this.fileObj.fileUrl
        const link = document.createElement('a')
        this.$apis.FILEDOWNLOADONE(data).then((res) => {
          if (res.data === 'downErr') {
            this.$message({
              type: 'error',
              msg: '下载失败!'
            })
          } else {
            const blob = new Blob([res])
            const objectUrl = URL.createObjectURL(blob) // 创建URL
            link.href = objectUrl
            link.download = this.fileObj.fileName // 自定义文件名
            link.click() // 下载文件
            URL.revokeObjectURL(objectUrl) // 释放内存
          }
        })
      }
    },
    inited(viewer) {
      this.$viewer = viewer
    }
  },
  beforeDestroy() {
    this.visible = false
  }
}
</script>

<style>
@import './viewer/viewer.css';
.preview-password-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  background: #f7f7fc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.el-dialog__wrapper.file-preview {
  overflow: hidden !important;
}
.file-preview .el-dialog {
  width: 100% !important;
  height: 100% !important;
  margin-top: 0px !important;
  background: #f7f7fc !important;
  border-radius: 0 !important;
  position: relative !important;
}
.file-preview .el-dialog .el-dialog__header {
  background: #fff !important;
  padding: 0 !important;
  height: 64px !important;
  line-height: 64px !important;
}
.file-preview .el-dialog .el-dialog__body {
}
</style>

<style lang="scss" scoped>
::v-deep {
  .copy-button.el-button {
    height: 30px !important;
    line-height: 30px !important;
    text-align: center !important;
    border: none !important;
    border-radius: 8px;
    background: transparent !important;
    color: #6e7391 !important;
    padding: 0 14px 0 10px;
    span {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .file-svg {
      font-size: 20px;
      color: #6e7391 !important;
      float: left;
      margin-top: 0px;
      margin-right: 6px;
    }
    &:hover {
      height: 30px !important;
      line-height: 30px !important;
      border: none !important;
      background: rgba(58, 120, 246, 0.08) !important;
      color: #3a78f6 !important;
      .file-svg {
        color: #3a78f6 !important;
      }
    }
  }
  .upload-button.el-button {
    width: 246px;
    height: 48px;
    line-height: 48px;
    padding: 0;
    margin-top: 48px;
    background: #3a78f6;
    border-radius: 16px;
    &:hover,
    &:focus,
    &:active {
      background-color: rgba(58, 120, 246, 0.87) !important;
    }
  }
  .viewer-canvas {
    overflow-y: auto !important;
  }
  .viewer-toolbar > ul > li:focus,
  .viewer-toolbar > ul > li:hover {
    background-color: #3a78f6 !important;
  }
  .viewer-toolbar {
    .viewer-prev,
    .viewer-next {
      display: none !important;
    }
  }
  .file-main > div > img {
    visibility: hidden !important;
    display: none !important;
  }
  .viewer-backdrop {
    background: none !important;
  }
}
.el-button.download-button {
  height: 30px !important;
  line-height: 30px !important;
  text-align: center !important;
  border: none !important;
  border-radius: 8px;
  background: transparent !important;
  color: #6e7391 !important;
  padding: 0 14px;
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .file-svg {
    font-size: 18px;
    color: #6e7391 !important;
    float: left;
    margin-top: 5px;
    margin-right: 6px;
  }
  &:hover {
    height: 30px !important;
    line-height: 30px !important;
    border: none !important;
    background: rgba(58, 120, 246, 0.08) !important;
    color: #3a78f6 !important;
    .file-svg {
      color: #3a78f6 !important;
    }
  }
}
.preview-title {
  width: 100%;
  display: flex;
  line-height: 64px;
  padding: 0 30px;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  .title-left {
    flex: 1;
    height: 64px;
    display: flex;
    min-width: 0;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    .title {
      color: #14142b;
      font-size: 16px;
      margin-right: 16px;
      margin-left: 16px;
      font-weight: 400;
    }
    .time {
      font-size: 14px;
      color: #a0a3bd;
      font-weight: 400;
    }
  }
  .title-right {
    width: 240px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    .title-icon-div {
      width: 30px;
      height: 30px;
      line-height: 30px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 16px;
      cursor: pointer;
      .title-icon {
        font-size: 18px;
        color: #6e7391;
        font-weight: 700;
      }
      &:hover {
        background: #f5f6fa;
      }
    }
  }
}
.file-main {
  margin: 0px;
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.operate-button-div {
  height: 45px;
  position: absolute;
  left: 40%;
  bottom: 20px;
  width: 400px;
  background: rgba(20, 20, 43, 0.87);
  border-radius: 8px;
}
.no-preview {
  width: 246px;
  margin: 0 auto;
  text-align: center;
  .svg-div {
    margin: 0 auto;
    width: 120px;
    height: 120px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border-radius: 50%;
    .file-svg {
      width: 46px;
      height: 56px;
      font-size: 40px;
    }
  }
  .title {
    font-weight: bold;
    color: #14142b;
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
  }
}
</style>
