<template>
  <div>
    <div class="box">
      <!-- <transition name="fade"> -->

      <div v-if="type === 'add'" :key="'add' + Math.random()">
        <el-date-picker
          v-model="form.startDate"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm"
          type="datetime"
          placeholder="开始日期"
          class="date-box"
          :picker-options="pickerOptionsStart"
          popper-class="addTimeClass"
          @change="startDateChange"
        />
        <span class="m-l-r-10">—</span>
        <el-date-picker
          v-model="form.endDate"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm"
          type="datetime"
          placeholder="截止日期"
          class="date-box"
          :picker-options="pickerOptionsEnd"
          popper-class="addTimeClass"
          @change="endDateChange"
        />
      </div>
      <div v-else :key="'else' + Math.random()">
        <date-time-picker
          :obj="form"
          :source="source"
          :fieldItem="fieldItem"
          :disabled="disabled"
          @change="dateTimeChange"
        />
      </div>
      <span v-if="source != 'stopdoing'" class="line"></span>
      <!-- </transition> -->
      <!-- 设置任务周期 -->
      <!-- v-if="row.fatherTid == null || row.fatherTid == '0'" -->
      <el-tooltip :content="cycleDateFmt" v-if="source != 'stopdoing'">
        <task-cycle-detail
          :id="row.cycleDate && row.cycleDate.id ? row.cycleDate.id : ''"
          :task-id="row.id"
          :row="row"
          :disabled="disabled"
          @save="cycleSave"
        />
      </el-tooltip>
      <!-- 设置提醒时间 -->
      <span v-if="source != 'stopdoing'" class="line"></span>
      <el-tooltip
        v-if="source != 'stopdoing'"
        :content="remindDateFmt"
        :open-delay="300"
        placement="top"
        effect="light"
      >
        <Icon
          v-if="type_remind === 0 || !row.remindDate || !row.remindDate.id"
          icon-class="icon-remind"
          :class="disabled ? 'disabled' : 'change-hover'"
          class="time-icon"
          @click="remindTimeClick"
        />
        <Icon
          v-else
          icon-class="icon-remind"
          :class="disabled ? 'disabled' : 'change-hover'"
          class="time-icon active-icon"
          @click="remindTimeClick"
        />
      </el-tooltip>
    </div>
    <!-- 设置提醒时间dialog -->
    <transition name="fade" v-if="source != 'stopdoing'">
      <task-remind-time-detail
        v-if="remindTimeVisible"
        :id="row.remindDate && row.remindDate.id ? row.remindDate.id : ''"
        :visible.sync="remindTimeVisible"
        :task-id="row.id"
        :row="row"
        @save="remindTimeSave"
      />
    </transition>
  </div>
</template>

<script>
import formMixin from '@/utils/mixins/form'
import taskUtil from '@/utils/task.js'
import DateTimePicker from './DateTimePicker.vue'
import TaskCycleDetail from './TaskCycleDetail' // 任务重复周期
import TaskRemindTimeDetail from './TaskRemindTimeDetail' // 设置提醒时间
export default {
  components: { DateTimePicker, TaskCycleDetail, TaskRemindTimeDetail },
  mixins: [formMixin],
  props: {
    source: {
      type: String,
      default: '' //stopdoing 待办详情
    },
    fieldItem: {
      type: Object
    },
    // 表单对象
    row: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
    // 类型
    type: {
      type: String,
      required: false
    },
    // 是否禁用
    disabled: {
      required: false
    }
  },
  data() {
    const _this = this
    return {
      pickerOptionsStart: {
        // 开始日期范围限制
        disabledDate(time) {
          let disabled
          if (_this.endDateType === 1) {
            if (_this.form.endDate) {
              disabled =
                true ||
                time.getTime() < _this.$moment().startOf('day').format('x') ||
                time.getTime() >
                  _this.$moment(_this.form.endDate).startOf('day').format('x')
            } else {
              disabled =
                time.getTime() < _this.$moment().startOf('day').format('x')
            }
            return false
          } else {
            return disabled
          }
        }
      },
      pickerOptionsEnd: {
        // 结束日期范围限制
        disabledDate(time) {
          let disabled
          if (_this.form.startDate) {
            disabled =
              time.getTime() <
              _this.$moment(_this.form.startDate).startOf('day').format('x')
          } else {
            disabled =
              time.getTime() < _this.$moment().startOf('day').format('x')
          }
          return disabled
        }
      },
      remindTimeVisible: false, // 设置提醒时间显示
      type_remind: '',
      endDateType: 0,
      form: {}
    }
  },
  computed: {
    // 提醒时间转化
    remindDateFmt() {
      if (this.disabled) {
        return '没有权限编辑'
      }
      return taskUtil.remindDateFmt(this.row.remindDate)
    },
    // 重复周期转化
    cycleDateFmt() {
      if (this.disabled) {
        return '没有权限编辑'
      }
      return taskUtil.cycleDateFmt(this.row.cycleDate)
    },
    // 是否设置时间
    isSetTime() {
      const { startDate, endDate } = this.form
      return startDate && endDate
    }
  },
  watch: {
    row: {
      handler(val) {
        this.init()
      }
    }
  },
  created() {
    // 初始化
    this.init()
  },
  methods: {
    // 初始化
    init() {
      if (this.companyCalendarInfo.amStartTime) {
        this.defaultStart =
          this.companyCalendarInfo.amStartTime.substring(11, 19) || ''
      }
      if (this.companyCalendarInfo.pmEndTime) {
        this.defaultEnd =
          this.companyCalendarInfo.pmEndTime.substring(11, 19) || ''
      }
      console.log(this.defaultStart, this.defaultEnd)
      this.form = JSON.parse(JSON.stringify(this.row))
      if (this.type === 'edit' && this.form.remindDate) {
        this.type_remind = this.form.remindDate.dateType
      }
    },
    // 开始时间变化
    startDateChange() {
      this.row.startDate = this.form.startDate
    },
    // 结束时间变化
    endDateChange() {
      this.row.endDate = this.form.endDate
    },
    // 设置提醒时间点击事件
    remindTimeClick() {
      if (this.disabled) {
        return
      }
      this.remindTimeVisible = true
    },
    // 任务重复周期保存成功事件
    cycleSave(row) {
      console.log('任务重复---', row)
      this.form.cycleDateId = row.id
      this.row.cycleDateId = row.id
      this.row.cycleDate = row.data
      this.$emit('global:taskDetailRefresh')
      this.$emit('refresh')
    },
    // 提醒时间保存成功事件
    remindTimeSave(row) {
      console.log('提醒时间---', row)
      this.form.remindDate = row.data
      this.form.remindDate.id = row.id
      this.row.remindDate = row.data
      this.type_remind = row.dateType

      this.$emit('global:taskDetailRefresh')
      this.$emit('refresh')
    },
    // 日期时间选择
    dateTimeChange(row) {
      this.$emit('change', row, this.fieldItem)
    }
  }
}
</script>

<style lang="scss" scoped>
.line {
  border-left: 1px solid #d9dbe9;
  display: inline-block;
  width: 1px;
  height: 12px;
  margin: 0 12px;
}
.box {
  display: flex;
  align-items: center;
  .date-box {
    width: 170px !important;
  }
  .time-icon {
    cursor: pointer;
    font-size: 18px;
    width: 18px;
    height: 18px;
    color: #a0a3bd;
  }
  .active-icon {
    color: #ff9900;
  }
  .cycle-icon {
    cursor: pointer;
    margin-left: 4px;
    width: 22px;
    height: 22px;
    font-size: 22px;
    margin-top: 4px;
  }
}
.change-hover {
  &:hover {
    color: #3a78f6;
  }
}
.disabled {
  cursor: not-allowed !important;
}
</style>
<style lange="scss">
.addTimeClass {
  z-index: 3000 !important;
}
</style>
