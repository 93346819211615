<template>
  <!-- 企信迁移后新增 楚 -->
  <div v-if="isShowEvaluate" class="evaluate-box">
    <div class="evaluate-title-row">
      <div class="evaluate-title-text">
        {{ step === 1 ? '满意度调查' : '' }}
      </div>
      <div class="evaluate-title-icon">
        <i class="el-icon-close" @click="closetaskRefresh"></i>
      </div>
    </div>
    <div v-if="step === 1" class="evaluate-main">
      <div class="step-title">
        <div class="step-title-left"><span>1</span>/3</div>
        <div class="step-title-right">
          您对{{ moduleName[evaluateList.type] }}模块是否满意？
        </div>
      </div>
      <div class="evaluate-emj-list">
        <div
          v-for="item in evaluateList.satisfactionDictionaryDtoList"
          :key="item.id"
          :class="
            emjActive == item.id
              ? 'evaluate-emj-row evaluate-emj-row-active'
              : 'evaluate-emj-row'
          "
          @click="emjClick(item)"
        >
          <div>
            <img
              :src="
                require(`@/assets/imgs/header/emj_${item.satisfactionScore}.png`)
              "
            />
          </div>
          <div class="evaluate-emj-text">{{ item.satisfactionTitle }}</div>
        </div>
      </div>
    </div>
    <div v-if="step === 2" class="evaluate-main step-two">
      <div class="step-title">
        <div class="step-title-left"><span>2</span>/3</div>
        <div class="step-title-right">您认为待优化的方面是？(可多选)</div>
      </div>
      <div class="module-list">
        <div
          v-for="item in moduleList"
          :key="item"
          class="module-name"
          :class="`module-name-${selectModule.indexOf(item) !== -1}`"
          @click="moduleClick(item)"
        >
          {{ item }}
        </div>
      </div>
      <div class="step-title step-three">
        <div class="step-title-left"><span>3</span>/3</div>
        <div class="step-title-right">请输入您的建议（非必填）</div>
      </div>
      <div class="form-input">
        <el-input
          v-model="satisfactionSuggest"
          type="textarea"
          :maxlength="500"
          placeholder="我们会虚心接受您的建议并做得更好"
        />
      </div>
      <div class="submit-wrap">
        <el-button
          type="primary"
          class="button"
          :disabled="selectModule.length === 0 ? true : false"
          @click="submit"
          >提交</el-button
        >
      </div>
    </div>
    <div v-if="step === 3" class="evaluate-main step-end">
      <img class="check-img" src="@/assets/imgs/common/success.png" alt />
      <p>问卷已提交</p>
      <div>感谢您的评分！您的反馈会让我们做得更好</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    evaluateList: {},
    evaluateVisible: {
      type: Boolean
    }
  },
  data() {
    return {
      isShowEvaluate: false, // 显隐
      emjActive: -1,
      imgList: [
        {
          id: 0,
          img: require('@/assets/imgs/header/emj_1.png'),
          name: '很差'
        },
        {
          id: 1,
          img: require('@/assets/imgs/header/emj_2.png'),
          name: '不佳'
        },
        {
          id: 2,
          img: require('@/assets/imgs/header/emj_3.png'),
          name: '一般'
        },
        {
          id: 3,
          img: require('@/assets/imgs/header/emj_4.png'),
          name: '满意'
        },
        {
          id: 4,
          img: require('@/assets/imgs/header/emj_5.png'),
          name: '非常满意'
        }
      ],
      step: 1,
      moduleList: [],
      selectModule: [],
      satisfactionSuggest: '',
      moduleName: {
        1: 'OKR',
        2: '工作台',
        3: '训练营'
      },
      id: '',
      stepOneSelect: {}
    }
  },
  watch: {
    evaluateVisible(val) {
      this.isShowEvaluate = val
      this.step = 1
      this.selectModule = []
      this.satisfactionSuggest = ''
    }
  },
  created() {},
  methods: {
    // 关闭
    closetaskRefresh() {
      this.$emit('closeStatus')
    },
    // 点击
    emjClick(item) {
      this.stepOneSelect = item
      if (item.satisfactionScore < 3) {
        this.moduleList = item.satisfactionLabel.split(',')
        this.step = 2
      }
      this.submit()
    },
    moduleClick(item) {
      var moduleState = this.selectModule.indexOf(item)
      if (moduleState === -1) {
        this.selectModule.push(item)
      } else {
        this.selectModule.splice(moduleState, 1)
      }
    },
    submit() {
      var satisfactionLabel = ''
      this.selectModule.forEach((item, index) => {
        satisfactionLabel += `${item}${
          index + 1 !== this.selectModule.length ? ',' : ''
        }`
      })
      var params = {
        id: this.id,
        userId: this.userInfo.id,
        sourceLabel: 1,
        satisfactionVersion: this.evaluateList.satisfactionVersion,
        satisfactionTitle: this.stepOneSelect.satisfactionTitle,
        satisfactionScore: this.stepOneSelect.satisfactionScore,
        satisfactionType: this.evaluateList.type,
        satisfactionLabel,
        satisfactionSuggest: this.satisfactionSuggest
      }
      this.$apis.SATISFACTIONSAVE(params).then((res) => {
        if (res.status === 200) {
          if (
            this.stepOneSelect.satisfactionScore > 2 ||
            satisfactionLabel !== ''
          ) {
            this.step = 3
            setTimeout(() => {
              this.closetaskRefresh()
            }, 3000)
          }
          var satisfactionDictionary =
            this.$store.state.user.satisfactionDictionary
          satisfactionDictionary.forEach((item) => {
            if (item.satisfactionType === this.evaluateList.type) {
              item.satisfactionStatus = 1
              this.$store.commit(
                'user/setSatisfactionDictionary',
                satisfactionDictionary
              )
            }
          })
          this.id = res.data.id
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
.evaluate-box {
  width: 520px;
  padding: 24px;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 4px 16px 0px rgba(17, 17, 17, 0.06);
  box-sizing: border-box;
  .evaluate-title-row {
    display: flex;
    flex-direction: row;
    .evaluate-title-text {
      flex: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-word;
      font-size: 24px;
      font-weight: bold;
      color: #14142b;
      &:before {
        content: '';
        display: block;
      }
    }
    .evaluate-title-icon {
      display: inline-flex;
      width: 26px;
      height: 26px;
      justify-content: center;
      align-items: center;
      background: transparent;
      border-radius: 4px;
      &:hover {
        background: #f5f7fa;
      }
      .el-icon-close {
        font-size: 22px;
        font-weight: 700;
        cursor: pointer;
        color: #333;
      }
    }
  }
  .step-two {
    margin-top: -20px !important;
  }
  .step-three {
    margin-top: 24px;
  }
  .step-end {
    margin: 0px auto;
    text-align: center;
    img {
      width: 68px;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: #6f7183;
      margin: 8px 0px 18px 0px;
    }
    div {
      font-size: 16px;
      font-weight: 600;
      color: #0f1331;
      padding-bottom: 32px;
    }
  }
  .evaluate-main {
    margin-top: 14px;
    .step-title {
      .flex-l();
      margin-bottom: 16px;
      .step-title-left {
        font-weight: bold;
        font-size: 12px;
        color: #a0a3bd;
        margin-right: 16px;
        span {
          color: #444558;
          font-size: 16px;
        }
      }
      .step-title-right {
        font-size: 16px;
        font-weight: 600;
        color: #444558;
      }
    }
    .module-list {
      .flex-l();
      flex-wrap: wrap;
      margin-left: 24px;
      .module-name {
        cursor: pointer;
        line-height: 30px;
        height: 32px;
        border-radius: 8px;
        padding: 0px 12px;
        margin: 0px 8px 8px 0px;
        border: 1px solid rgba(217, 219, 233, 0.54);
        font-size: 12px;
        color: #444558;
        min-width: 90px;
        text-align: center;
        &:hover {
          background: #ebf1ff;
          border: 1px solid #9cb7ff;
          color: #3a70ff;
        }
      }
      .module-name-true {
        background: #ebf1ff;
        border: 1px solid #9cb7ff;
        color: #3a70ff;
      }
    }
    ::v-deep {
      .el-textarea__inner {
        height: 112px;
        padding: 20px 24px;
      }
      .el-textarea,
      .el-textarea__inner {
        border: none !important;
      }
    }
    .submit-wrap {
      margin: 16px auto 0px auto;
      text-align: center;
      .button {
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        height: 40px;
        width: 174px;
        background: #3a78f6;
        border-radius: 16px;
        &:hover {
          background: #3a78f6;
        }
      }
      .button.is-disabled {
        color: #6e7491 !important;
        background: #d2d2d2 !important;
        border-color: #d2d2d2 !important;
        cursor: not-allowed;
        &:hover {
          color: #6e7491;
          background: #d2d2d2;
        }
      }
    }
    .evaluate-emj-list {
      display: flex;
      flex-direction: row;
      padding: 16px 24px 12px 24px;
      justify-content: space-between;
      .evaluate-emj-row {
        width: 65px;
        text-align: center;
        cursor: pointer;
        position: relative;
        padding: 10px 4px;
        color: #6e7491;
        img {
          width: 48px;
          height: 48px;
          margin-bottom: 7px;
        }
        video {
          width: 65px;
          opacity: 0.66;
        }
        .check-img {
          // margin-top: 12px;
          // margin-left: 10px;
          margin-bottom: 7px;
          width: 48px;
          height: 48px;
        }
        .evaluate-emj-text {
          text-align: center;
          font-size: 14px;
          font-weight: 500;
        }
        &:hover {
          background: #f2f7ff;
          border-radius: 16px;
          color: #14142b;
        }
      }
      .evaluate-emj-row.evaluate-emj-row-active {
        opacity: 1 !important;
      }

      .evaluate-emj-row:hover {
        opacity: 1;
      }
      .evaluate-emj-row:hover .evaluate-emj-text {
        opacity: 1;
      }
      .evaluate-emj-row:hover img {
        opacity: 1;
      }
      .evaluate-emj-row:hover video {
        opacity: 1;
      }
    }
  }
}
</style>
