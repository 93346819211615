const url =
  window.location.host.includes('qywx') || window.location.host.includes('qw')
  ? '../../views/global/auth/loginSaas.vue'
  : '../../views/global/auth/login.vue'
export default [
  {
  path: '/login',
  name: 'login',
  component: () => {
    return import(
      /* webpackChunkName: "login" */
      '../../views/global/auth/login.vue'
    )
  }
},
{
  path: '/loginError',
  name: 'loginError',
  component: () => {
    return import(
      /* webpackChunkName: "loginError" */
      '../../views/global/auth/login.vue'
    )
  }
}
]
