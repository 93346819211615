<template>
  <el-dialog
    title="目标权限设置"
    :visible.sync="dialogVisible"
    width="880px"
    :append-to-body="true"
    :before-close="handleClose"
  >
    <div class="okr-content" v-vueloading="loading">
      <div class="okr-title">
        <div class="title-content">
          <img class="okr-title-icon" src="@/assets/imgs/okr/objective.png" />
          <!-- <img v-else class="okr-title-icon avatar" :src="objData.userAvatar" /> -->
          <div v-html="$utils.changeToHtml(objData.content)"></div>
        </div>
        <div class="permission">
          <permission :item="objData.objectivesPower" @confirm="permissionConfirm" />
        </div>
      </div>
      <div class="kr-title-list">
        <div
          v-for="(keyResult, index) in objData.keyresultsList"
          :key="keyResult.index"
          class="kr-item"
        >
          <div class="up-line">
            <div
              v-show="
                !(index === objData.keyresultsList.length - 1) && index !== 9
              "
              class="down-left-line"
            ></div>
            <Icon icon-class="icon-okrline-down" class="line-icon" />
          </div>
          <div class="kr-title-box">
            <div class="kr-index">KR{{ index + 1 }}</div>
            <div class="kr-title" v-html="$utils.changeToHtml(keyResult.title)"></div>
            <div class="kr-weight">
              <permission
                :item="keyResult.objectivesPower"
                :type="2"
                :index="index + 1"
                @confirm="permissionConfirm"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-step-buttons">
      <el-button class="left-button" @click="handleClose">取消</el-button>
      <L-button :loading="addLoading" @clickEvt="getTargetAligningSubmit('提交')">确认</L-button>
    </div>
  </el-dialog>
</template>
<script>
import permission from './permission.vue'
export default {
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  components: { permission },
  props: {
    dialogShow: {
      type: Boolean,
      default: true
    },
    okrId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialogVisible: this.dialogShow,
      objData: {},
      loading: true,
      addLoading: false,
      permissionList: []
    }
  },
  watch: {
    dialogShow: {
      handler(val) {
        this.dialogVisible = val
      }
    }
  },
  created() {
    this.queryOkrData()
  },
  methods: {
    // 获取okr详情
    queryOkrData() {
      this.$apis.OKRPOWERDETAIL(this.okrId).then((res) => {
        if (res.status === 200) {
          this.objData = res.data
          var list = []
          if ((this.objData.objectivesPower || '') === '') {
            var objObjectivesPowerList = {
              businessType: 1,
              lookRulesType: 0,
              businessId: this.objData.id,
              objectivesPowerCustomizeList: []
            }
            this.objData.objectivesPower = objObjectivesPowerList
            list.push(objObjectivesPowerList)
          } else {
            list.push(this.objData.objectivesPower)
          }
          this.objData.keyresultsList.forEach((item) => {
            if ((item.objectivesPower || '') === '') {
              var krObjectivesPowerList = {
                businessType: 2,
                lookRulesType: 0,
                businessId: item.id,
                objectivesPowerCustomizeList: []
              }
              item.objectivesPower = krObjectivesPowerList
              list.push(krObjectivesPowerList)
            } else {
              list.push(item.objectivesPower)
            }
          })
          this.permissionList = list
        } else {
          this.$message.error(res.message)
        }
        this.loading = false
      })
    },
    // 关闭启动状态
    handleClose() {
      this.dialogVisible = false
      this.$emit('change', false)
    },
    permissionConfirm(data) {
      this.permissionList[data.index].lookRulesType = data.lookRulesType
      this.permissionList[data.index].objectivesPowerCustomizeList =
        data.objectivesPowerCustomizeList
      if (data.type === 1) {
        // okr
        this.objData.objectivesPower.lookRulesType = data.lookRulesType
        this.objData.objectivesPower.objectivesPowerCustomizeList =
          data.objectivesPowerCustomizeList
      } else {
        this.objData.keyresultsList[
          data.index - 1
        ].objectivesPower.lookRulesType = data.lookRulesType
        this.objData.keyresultsList[
          data.index - 1
        ].objectivesPower.objectivesPowerCustomizeList =
          data.objectivesPowerCustomizeList
      }
    },
    // 权限提交
    getTargetAligningSubmit() {
      this.addLoading = true
      var permissionList = this.permissionList
      permissionList.forEach((item) => {
        (item.objectivesPowerCustomizeList || []).forEach((listItem) => {
          var businessIdList = []
          var businessIdListState = false
          var objectivesPowerCustomizeDataList = (listItem.objectivesPowerCustomizeDataList || [])
          objectivesPowerCustomizeDataList.forEach((dataItem) => {
            if ((dataItem.user || '') !== '') {
              businessIdListState = true
              businessIdList.push(dataItem.user.id)
            }
            if ((dataItem.depart || '') !== '') {
              businessIdListState = true
              businessIdList.push(dataItem.depart.id)
            }
          })
          if (businessIdListState) {
            listItem.businessIdList = businessIdList
          }
        })
      })
      this.$apis
        .OKRPOWERUPDATE(this.permissionList)
        .then((res) => {
          if (res.status === 200) {
            this.$message.success('修改成功')
            this.$emit('refresh')
            this.handleClose()
          } else {
            this.$message.error(res.message)
          }
          this.addLoading = false
          this.loading = false
        })
        .catch(() => {
          this.addLoading = false
        })
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../assets/styles/dialog.less';
::v-deep {
  .el-input__inner {
    border-radius: 8px;
  }
  .el-dialog__header {
    display: block !important;
    padding-bottom: 16px !important;
  }
}
.okr-content {
  min-height: 280px;
  margin-bottom: 24px;
  .okr-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    color: #14142b;
    font-size: 16px;
    font-weight: 700;
    padding: 4px 8px 4px 0;
    .title-content {
      display: flex;
      line-height: 20px;
      align-items: flex-start;
      flex: 1;
      min-width: 0;
    }
    .okr-title-icon {
      width: 36px;
      flex-shrink: 0;
      margin-right: 24px;
    }
    .avatar {
      height: 36px;
      border-radius: 50%;
      overflow: hidden;
    }
    .permission {
      margin-left: auto;
    }
  }
  .kr-title-list {
    font-size: 14px;
    .kr-item {
      display: flex;
      color: #444558;
      .kr-index {
        flex-shrink: 0;
        color: #a0a3bd;
        font-weight: 700;
        width: 46px;
        line-height: 32px;
        padding-left: 8px;
      }
      .kr-title-box {
        min-height: 34px;
        display: flex;
        width: 750px;
        justify-content: space-between;
        padding: 4px 8px 4px 0;
        margin-top: 4px;
        align-items: flex-start;
        .key_result_dragger_icon {
          margin-left: 6px;
          visibility: hidden;
          font-size: 20px;
          color: #a0a3bd;
          height: 32px;
        }
      }
      .kr-title {
        flex: 1;
        line-height: 24px;
        padding: 4px 0;
        // margin-top: 12px;
      }
      .kr-weight {
        height: 30px;
        line-height: 30px;
        margin-left: auto;
      }
    }
  }
  .up-line {
    display: flex;
    align-items: flex-start;
    .line-icon {
      font-size: 35px;
      color: #d9dbe9;
      margin-right: 8px;
    }
    .down-left-line {
      height: 100%;
      width: 1px;
      background: #d9dbe9;
    }
    margin-left: 23px;
  }
  .down-line {
    i {
      font-size: 25px;
    }
    width: 25px;
  }
}
</style>
