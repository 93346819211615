<template>
  <div class="attach-project" @click.stop>
    <el-popover
      popper-class="attach-project-pop"
      ref="attachProjectRef"
      :visible-arrow="false"
      @show="queryProjectList"
      @hide="hide"
    >
      <div class="list-title">关联项目</div>
      <div class="project-list">
        <div
          v-for="item in projectList"
          :key="item.id"
          class="project-item"
          @click="addSelect(item, selectProjects.length === 0)"
        >
          <div class="project-content">
            <div class="project-icon" :style="`background:${item.colour}`">
              <!-- <img :src="item.logo" /> -->
              <el-image :src="item.logo || require('@/assets/imgs/common/avatar.png')" fit="cover" />
            </div>
            <div class="project-name">{{ item.name }}</div>
          </div>
          <div
            v-if="selectProjects.length > 0 && isEdit"
            class="del-btn"
            @click.stop="delSelect(item)"
          >
            <i class="el-icon-close"></i>
          </div>
        </div>
        <div v-if="projectList.length === 0 && loading">
          <no-data />
        </div>
      </div>
      <add-attach
        v-if="selectProjects.length > 0"
        ref="addAttachRef"
        :isEdit="isEdit"
        :all-project-list="allProjectList"
        :select-projects="selectProjects"
        @addSelect="addSelect($event, true)"
      />
      <div slot="reference">
        <div v-if="fromType === 'meetingOkr'">
          <slot name="custom"></slot>
        </div>
        <div v-else-if="selectProjects.length === 0 && isEdit" class="attach-btn-text">
          <Icon icon-class="icon-attachPro" class="attach-icon" />
          <div>关联项目</div>
        </div>
        <div v-else-if="selectProjects.length === 1">
          <div class="project-content">
            <div
              class="project-icon"
              :style="`background:${selectProjects[0].projectBaseDto.colour}`"
            >
              <!-- <img :src="selectProjects[0].projectBaseDto.logo" /> -->
              <el-image
                :src="selectProjects[0].projectBaseDto.logo || require('@/assets/imgs/common/avatar.png')"
                fit="cover"
              />
            </div>
            <div class="project-name">{{ selectProjects[0].projectBaseDto.name }}</div>
          </div>
        </div>
        <div v-else class="project-content">
          <div class="project-icons">
            <div
              v-for="item in showProjects"
              :key="item.projectBaseDto.id"
              class="project-icon"
              :style="`background:${item.projectBaseDto.colour}`"
            >
              <!-- <img :src="item.projectBaseDto.logo" /> -->
              <el-image
                :src="item.projectBaseDto.logo || require('@/assets/imgs/common/avatar.png')"
                fit="cover"
              />
            </div>
            <div class="project-name" v-show="isEdit">已关联{{ selectProjects.length }}个项目</div>
          </div>
        </div>
      </div>
    </el-popover>
  </div>
</template>
<script>
import addAttach from './addAttach.vue'
import noData from '../noData.vue'
export default {
  props: {
    isEdit: {
      type: Boolean,
      default: true
    },
    keyId: {
      type: String
    },
    objId: {
      type: String
    },
    fromType: {
      default: 'okr'
    }
  },
  data() {
    return {
      projectList: [],
      allProjectList: [],
      selectProjects: [],
      showProjects: [],
      loading: false
    }
  },
  watch: {
    keyId: {
      handler(val) {
        if (!val) {
          this.selectProjects = []
        } else {
          this.querySelectProject()
        }
      },
      immediate: true
    }
  },
  components: { addAttach, noData },
  methods: {
    hide() {
      this.$store.commit('common/setScrollBgVisible', false)
      if (this.$refs.attachProjectRef) {
        this.$refs.attachProjectRef.doClose()
      }
    },
    clickHide() {
      if (this.$refs.attachProjectRef) {
        if (this.$refs.attachProjectRef) {
          this.$refs.attachProjectRef.doClose()
        }
        if (this.$refs.addAttachRef) {
          this.$refs.addAttachRef.backFun()
        }
      }
    },
    // 获取项目列表
    async queryProjectList() {
      this.$store.commit('common/setScrollBgVisible', true)
      if (this.selectProjects.length > 0) {
        this.projectList = this.selectProjects.map((item) => {
          return Object.assign(item.projectBaseDto, {
            attachId: item.keyresultsProjectDto.id
          })
        })
        this.showProjects = this.selectProjects.slice(0, 3)
      }
      this.$apis
        .KRATTACHALLPROJECTS()
        .then((res) => {
          this.loading = true
          if (res.status === 200) {
            this.allProjectList = res.data.filter((item) => {
              return item.status === 0 // 状态（0 正常 1 归档）
            })
            if (this.selectProjects.length === 0) {
              this.projectList = this.allProjectList
            }
          } else {
            this.$message({
              msg: '网络错误,稍后重试!',
              type: 'error'
            })
          }
        })
        .catch(() => {
          this.loading = true
        })
    },
    // 获取已关联的项目
    async querySelectProject() {
      const params = {
        objId: this.objId,
        keyId: this.keyId
      }
      await this.$apis.KRATTACHEDPROJECTS(params).then((res) => {
        if (res.status === 200) {
          res.data.forEach(item => {
            item.projectBaseDto.attachId = item.keyresultsProjectDto.id
          })
          this.$emit('projectChange', res.data)
          this.selectProjects = res.data.filter((item) => {
            if (item.projectBaseDto) {
              return true
            }
            return false
          })
          this.showProjects = this.selectProjects.slice(0, 3)
        } else {
          this.$message({
            msg: '网络错误,稍后重试!',
            type: 'error'
          })
        }
      })
    },
    // 选择/取消 项目
    selectProject(item) {},
    // 添加关联项目
    async addSelect(item, isAdd) {
      if (!isAdd) {
        // 跳转
        if (this.userInfo.isSuperAdmin != 1) {
          this.$apis.PROJECTCHECK(item.id).then(async (res) => {
            if (res.status === 200 && res.data === true) {
              this.$store.commit('pm/selectProjectId', item.id)
              this.$store.commit('pm/selectProjectData', {
                id: item.id,
                permission: 0,
                list: item
              })
              this.bus.$emit('global:selectProjectId', item.id)
              this.bus.$emit('selectDialogListInit')
              this.$store.commit('pm/tabSelectId', 3)
              this.$router.push({
                path: '/pm/pmDetail',
                query: {
                  id: item.id
                }
              })
            } else {
              this.$message({
                msg: res.message,
                type: 'error'
              })
            }
          })
        } else {
          this.$store.commit('pm/selectProjectId', item.id)
          this.$store.commit('pm/selectProjectData', {
            id: item.id,
            permission: 0,
            list: item
          })
          this.bus.$emit('global:selectProjectId', item.id)
          this.bus.$emit('selectDialogListInit')
          this.$store.commit('pm/tabSelectId', 3)
          this.$router.push({
            path: '/pm/pmDetail',
            query: {
              id: item.id
            }
          })
        }
      } else {
        const params = {
          keyId: this.keyId,
          objId: this.objId,
          projectId: item.id
        }
        this.$apis.KRADDATTACH(params).then(async (res) => {
          if (res.status === 200) {
            this.$mixpanelUtil.track('Add Relation Project')
            await this.querySelectProject()
            this.dataHandle()
          } else {
            this.$message({
              msg: '网络错误,稍后重试!',
              type: 'error'
            })
          }
        })
      }
    },
    // 删除已选择的项目
    async delSelect(item) {
      this.$apis.KRDELATTACH(item.attachId).then(async (res) => {
        if (res.status === 200) {
          await this.querySelectProject()
          this.dataHandle()
        } else {
          this.$message({
            msg: '网络错误,稍后重试!',
            type: 'error'
          })
        }
      })
    },
    // 页面状态
    dataHandle() {
      if (this.selectProjects.length > 0) {
        this.projectList = this.selectProjects.map((item) => {
          return Object.assign(item.projectBaseDto, {
            attachId: item.keyresultsProjectDto.id
          })
        })
        this.showProjects = this.selectProjects.slice(0, 3)
      }
      if (this.selectProjects.length === 0) {
        this.projectList = this.allProjectList
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.attach-project {
  margin-left: 16px;
}
.attach-btn-text {
  color: #ff9900;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  width: 90px;
  display: flex;
  align-items: center;
  .attach-icon {
    width: 18px;
    height: 18px;
    line-height: 18px;
    font-size: 18px;
    margin-right: 4px;
  }
  &:hover {
    color: #ffcc80;
  }
}
.project-content {
  margin-right: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #14142b;
  .project-name {
    max-width: 160px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:before {
      content: '';
      display: block;
    }
  }
}
.project-icons {
  display: flex;
  align-items: center;
}
.project-icon {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  overflow: hidden;
  .el-image {
    width: 32px;
    height: 32px;
    border-radius: 4px;
  }
  img {
    width: 24px;
  }
  margin-right: 8px;
}
</style>
<style lang="less">
.attach-project-pop {
  height: 320px;
  width: 260px;
  padding: 0;
  .list-title {
    color: #a0a3bd;
    margin: 16px;
  }
  .back-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 55px;
    margin: 16px;
    .back-icon {
      font-size: 16px;
      margin-right: 4px;
      color: #6e7491;
    }
    color: #14142b;
  }
  .project-list {
    max-height: 220px;
    overflow-y: auto;
    .s-b-y();
    .project-item {
      height: 44px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      .project-content {
        display: flex;
        align-items: center;
        color: #14142b;
        .project-icon {
          width: 32px;
          height: 32px;
          border-radius: 4px;
          img {
            width: 32px;
          }
          margin-right: 8px;
        }
        .project-name {
          width: 140px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &:before {
            content: '';
            display: block;
          }
        }
      }
      .del-btn {
        display: none;
        font-size: 20px;
        color: #a0a3bd;
        font-weight: 700;
        &:hover {
          color: #ff572b;
        }
      }
      .check-icon {
        font-size: 20px;
        color: #3a78f6;
      }
      &:hover {
        .del-btn {
          display: block;
        }
        background: #fafafc;
      }
    }
  }
  .confirm-btn {
    width: 228px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #3a78f6;
    border-radius: 16px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
  }
  .add-attach-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 12px;
    .add-icon {
      width: 22px;
      height: 22px;
      margin-right: 8px;
    }
    color: #3a78f6;
  }
}
</style>
