<template>
  <!-- 企信迁移后新增 -->
  <!-- trigger="hover" -->
  <el-popover
    ref="memberCardPopRef"
    placement="bottom"
    trigger="hover"
    popper-class="member-card-popover"
    :disabled="disabled"
    :open-delay="300"
    @hide="popoverHide"
    @show="getUserInfo"
  >
    <!-- @mouseleave="popoverHide(true)" -->
    <div class="member-card-pop">
      <div class="align-top">
        <div class="member-avatar">
          <el-image
            :src="userInfo.avatar || require('@/assets/imgs/common/avatar.png')"
            fit="cover"
            class="img border-radius-20"
            :class="!isAlign ? 'img-gray' : ''"
            v-if="userInfo.name"
          />
          <div class="avatar-info-box">
            <div class="avatar-name" v-if="userInfo.name">
              <wx-open-data :id="userInfo.name" :nameData="{name:userInfo.name}" />
            </div>
            <div class="avatar-name" v-else>对方已删除</div>
            <el-tooltip placement="top">
              <div
                slot="content"
                v-html="`${$isQw && cardType === 'list' ? `${departNameAllSplit(userInfo.departNameAll)}` : `${userInfo.departNameAll}`}`"
              ></div>
              <div class="avatar-job">
                <wx-open-data
                  :type="2"
                  :id="userInfo.departName"
                  :nameData="{name:userInfo.departName}"
                />
              </div>
            </el-tooltip>
          </div>
        </div>
        <div @click="toOKRList" v-if="isAlign && isOkr" class="go-okr">查看 Ta 周期内OKR</div>
      </div>
      <div class="okr-list-wrap" v-if="targetAligningList.length > 0 && isAlign">
        <div class="okr-list" v-for="(okr, okrIndex) in targetAligningList" :key="okr.id">
          <objective-label
            page-type="list"
            class="obj-label-position"
            :okrData="okr"
            :canEdit="false"
            :label-type="okr.labelType"
            :shareList="okr.objectivesSharedUserList"
          />
          <!--  -->
          <div
            class="cycle-name"
            v-if="okr.cycleInfoId !== periodId && okr.cycleInfoDto"
          >{{ okr.cycleInfoDto.name }}</div>
          <div class="okr-wrap">
            <div class="okr-left">
              <div class="okr-num">
                O
                <privateOkr
                  :createUser="userInfo.id === okr.createUserid"
                  :visible="okr.isVisible === 1"
                  :content="okr.isVisible === 1 ? '对方已设置为不可见' : ''"
                  :type="4"
                />
              </div>
              <el-tooltip
                :content="$utils.tagFilter($utils.changeToHtml(okr.content))"
                :open-delay="300"
                manual
                v-model="okr.tooltipDisabled"
              >
                <div
                  class="okr-content"
                  @mouseenter="tooltipShow('O', okrIndex, '', true)"
                  @mouseleave="tooltipShow('O', okrIndex, '', false)"
                >
                  <div
                    :class="`content content-${okrDetail}`"
                    v-html="$utils.changeToHtml(okr.content)"
                    @click="toOkrDetail(okr)"
                  ></div>

                  <privateOkr
                    :createUser="userInfo.id === okr.createUserid"
                    :visible="okr.isVisible === 1"
                    :content="okr.isVisible === 1 ? '对方已设置为不可见' : ''"
                    :type="1"
                  />
                </div>
              </el-tooltip>
            </div>
            <div class="okr-right">
              <div class="align-state" v-if="okr.alignState" @click="deleteAlignClick(okr, 'O')">
                <div class="hover-hidden">已对齐</div>
                <div class="hover-block">取消对齐</div>
              </div>
              <div class="progress-rate">
                <mini-process
                  type="circle"
                  :automationState="okr.automationState"
                  :rate="okr.progressRete"
                />
                {{ okr.progressRete }}%
              </div>
            </div>
          </div>
          <div class="key-list-wrap">
            <div
              class="key-list"
              v-for="(keyItem, keyIndex) in okr.keyresultsList"
              :key="keyItem.id"
            >
              <div class="key-left">
                <div class="key-num">
                  KR{{ keyIndex + 1 }}
                  <privateOkr
                    :createUser="userInfo.id === keyItem.createUserid"
                    :visible="keyItem.isVisible === 1"
                    :content="
                      keyItem.isVisible === 1 ? '对方已设置为不可见' : ''
                    "
                    :type="3"
                  />
                </div>
                <div class="key-content">
                  <el-tooltip
                    :content="
                      $utils.tagFilter($utils.changeToHtml(keyItem.title))
                    "
                    :open-delay="300"
                    manual
                    v-model="keyItem.tooltipDisabled"
                  >
                    <div
                      class="content"
                      @mouseenter="tooltipShow('KR', okrIndex, keyIndex, true)"
                      @mouseleave="tooltipShow('KR', okrIndex, keyIndex, false)"
                      v-html="$utils.changeToHtml(keyItem.title)"
                    ></div>
                  </el-tooltip>
                  <privateOkr
                    :createUser="userInfo.id === keyItem.createUserid"
                    :visible="keyItem.isVisible === 1"
                    :content="
                      keyItem.isVisible === 1 ? '对方已设置为不可见' : ''
                    "
                    :type="2"
                  />
                </div>
              </div>
              <div class="key-right">
                <div
                  class="align-state"
                  v-if="keyItem.alignState"
                  @click="deleteAlignClick(keyItem, 'KR')"
                >
                  <div class="hover-hidden">已对齐</div>
                  <div class="hover-block">取消对齐</div>
                </div>
                <div class="progress-rate">
                  <mini-process
                    type="circle"
                    :automationState="keyItem.automationState"
                    :rate="keyItem.progressRete"
                  />
                  {{ keyItem.quantificationActualValue
                  }}{{ keyItem.quantificationUnit }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="align-null" v-if="!isAlign && userInfo.name">还未对齐</div>
    </div>
    <slot slot="reference"></slot>
  </el-popover>
</template>
<script>
import privateOkr from '@/views/okr/components/PrivateOkr'
import miniProcess from '@/views/okr/components/singleOkrItem/miniProcess' // 进度条
export default {
  components: {
    privateOkr,
    miniProcess
  },
  props: {
    userId: {
      type: String
    },
    isAlign: {
      // 是否已对齐
      default: true
    },
    isOkr: {
      type: Boolean,
      default: false
    },
    okrDetail: {
      type: Boolean,
      default: true
    },
    deleteAlign: {
      //  是否删除对齐
      default: false
    },
    targetAligningList: {
      // 对齐目标列表
      type: Array,
      default: () => {
        return []
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placement: {
      default: 'bottom'
    },
    userInfoList: {
      // 人员
      type: Object,
      default: () => {
        return {}
      }
    },
    objectivesAssociationList: {
      // 对齐目标关系
      type: Array,
      default: () => {
        return []
      }
    },
    cardType: {
      default: 'list' // list: 非新增 add： 新增
    },
    alignType: {
      default: 'fatherAlign' // fatherAlign: 向上对齐 childAlign：向下对齐
    },
    businessType: {
      default: '' // 0:目标 1:kr
    },
    dataDetail: {

    }
  },
  data() {
    return {
      userInfo: {},
      periodId: '',
      tooltipShowState: false,
      saveInterval: null // 自动保存定时器
    }
  },
  mounted() {
    this.bus(this).$on('alignMemberCardHide', () => {
      if (this.$refs.memberCardPopRef) {
        this.$refs.memberCardPopRef.doClose()
      }
    })
  },
  methods: {
    departNameAllSplit(departName) {
      var departNameAll = departName
      if (this.$isQw && departNameAll) {
        departNameAll = departNameAll.replace('/', ',')
        if (departNameAll.includes(',')) {
          var departNameAllLen = departNameAll.split(',')
          departNameAll = ''
          departNameAllLen.forEach((item, index) => {
            departNameAll += `<ww-open-data type="departmentName" openid="${item}"></ww-open-data> ${
              index < departNameAllLen.length - 1 ? ',' : ''
            }`
          })
        } else if (departNameAll) {
          departNameAll = `<ww-open-data type="departmentName" openid="${departNameAll}"></ww-open-data>`
        }
      }
      return departNameAll
    },
    tooltipShow(type, objIndex, keyIndex, state) {
      if (type === 'O') {
        this.targetAligningList[objIndex].tooltipDisabled = state
      } else {
        this.targetAligningList[objIndex].keyresultsList[
          keyIndex
        ].tooltipDisabled = state
      }
      this.tooltipShowState = true
      this.targetAligningList = JSON.parse(
        JSON.stringify(this.targetAligningList)
      )
      clearInterval(this.saveInterval)
      this.saveInterval = setTimeout(() => {
        this.tooltipShowState = false
      }, 400)
    },
    popoverHide(type) {
      if (type) {
        if (this.$refs.memberCardPopRef) {
          this.$refs.memberCardPopRef.doClose()
        }
      } else {
        // if (this.tooltipShowState) {
        //   this.$refs.memberCardPopRef.doShow()
        // }
      }
    },
    dataHandel() {
      this.periodId = this.$store.state.common.cycle.periodId
      if (this.cardType === 'add') {
        var targetAligningList = this.targetAligningList || []
        targetAligningList.forEach((alignItem) => {
          if ((alignItem.childObjectivesAssociationList || []).length > 0) {
            alignItem.childObjectivesAssociationList.forEach((childItem) => {
              if (childItem.businessId === alignItem.id) {
                alignItem.alignState = true
              }
            })
            alignItem.keyresultsList.forEach((krItem) => {
              alignItem.childObjectivesAssociationList.forEach((childItem) => {
                if (childItem.businessId === krItem.id) {
                  krItem.alignState = true
                }
              })
            })
          }
        })
        this.targetAligningList = JSON.parse(JSON.stringify(targetAligningList))
      } else if (this.isAlign) {
        if (this.alignType === 'fatherAlign') { // 向上对齐
          this.getFatherAlignList(this.$apis.GETFATHERALIGNOBJLIST, {
            userId: this.objectivesAssociationList[0].businessUser.id,
            activeAlignObjId: this.objectivesAssociationList[0].activeAlignObjId
          })
        } else { // 向下对齐
          this.getFatherAlignList(this.$apis.GETCHILDALIGNOBJLIST, {
            beAlignedObjId: this.objectivesAssociationList[0].beAlignedObjId,
            userId: this.objectivesAssociationList[0].businessUser.id,
            businessType: this.businessType,
            businessId: this.businessType === '' ? '' : this.dataDetail.id
          })
        }
      }
    },
    async getUserInfo() {
      if ((this.userId || '') !== '') {
        this.$apis
          .USERINFOBYID(this.userId)
          .then((rest) => {
            this.userInfo = rest.data
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        this.userInfo = this.userInfoList
      }
      this.dataHandel()
    },
    getFatherAlignList(url, params) {
      url(params).then((rest) => {
        this.handleDataAlign(rest.data)
      }).catch((err) => {
        console.log(err)
      })
    },
    handleDataAlign(dataList) {
      dataList.forEach((alignItem) => {
        if ((alignItem.childObjectivesAssociationList || []).length > 0) {
          this.objectivesAssociationList.forEach((childItem) => {
            if (childItem.businessId === alignItem.id) {
              alignItem.alignState = true
            }
          })
          alignItem.keyresultsList.forEach((krItem) => {
            this.objectivesAssociationList.forEach((childItem) => {
              if (childItem.businessId === krItem.id) {
                krItem.alignState = true
              }
            })
          })
        }
      })
      this.targetAligningList = JSON.parse(JSON.stringify(dataList))
    },
    // 进入OKR列表
    toOKRList() {
      this.$mixpanelUtil && this.$mixpanelUtil.track('View All Member OKR')
      this.$emit('toOKRList')
      if (this.$refs.memberCardPopRef) {
        this.$refs.memberCardPopRef.doClose()
      }
    },
    toOkrDetail(objItem) {
      this.$emit('okrDetail', objItem)
      if (this.$refs.memberCardPopRef) {
        this.$refs.memberCardPopRef.doClose()
      }
    },
    deleteAlignClick(data, type) {
      var state = {
        businessType: type === 'O' ? 0 : 1,
        type: 'delete'
      }
      this.$emit('deleteAlign', data, state)
      if (this.$refs.memberCardPopRef) {
        this.$refs.memberCardPopRef.doClose()
      }
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .obj-label {
  height: 20px !important;
  width: auto !important;
  padding: 0px 10px;
}
.member-card-pop {
  padding: 24px 8px;
  min-width: 260px;
  .align-null {
    margin: 12px 0px 0px 60px;
    color: #444558;
    font-size: 14px;
  }
  .align-top {
    .flex-bt();
    padding: 0px 16px;
    .member-avatar {
      .flex-l();
      .img {
        width: 32px;
        height: 32px;
        border-radius: 16px;
        margin-right: 12px;
      }
      .img-gray {
        // -webkit-filter: grayscale(100%);
        /* Chrome, Safari, Opera */
        // filter: grayscale(100%);
      }
      .avatar-info-box {
        line-height: 20px;
        width: 360px;
        .flex-l();
        .avatar-name {
          max-width: 150px;
          .text-n(1);
          font-size: 14px;
          font-weight: bold;
          color: #14142b;
        }
        .avatar-job {
          max-width: 150px;
          .text-n(1);
          margin-left: 8px;
          color: #a0a3bd;
          font-size: 12px;
        }
      }
    }
    .go-okr {
      color: #548af7;
      cursor: pointer;
      &:hover {
        color: #3a70ff;
      }
    }
  }
  .okr-list-wrap {
    overflow-y: auto;
    max-height: 300px;
    min-height: 200px;
    width: 580px;
    .okr-list {
      padding: 24px 16px;
      border-radius: 16px;
      border: 1px solid #f5f6fa;
      margin-top: 8px;
      position: relative;
      .cycle-name {
        position: absolute;
        height: 22px;
        border-radius: 13px;
        background: rgba(58, 120, 246, 0.08);
        font-size: 12px;
        font-weight: 500;
        color: #3a78f6;
        margin-right: 16px;
        padding: 0px 8px;
        line-height: 22px;
        text-align: center;
        top: 4px;
        right: 0px;
      }
      .obj-label-position {
        position: absolute;
        left: 0px;
        top: 0px;
      }
      .okr-wrap,
      .key-list {
        height: 40px;
        .flex-bt();
        .okr-left,
        .key-left {
          .flex-l();
          min-width: 45px;
          margin-right: 16px;
          flex: 1;
        }
      }
      .okr-right,
      .key-right {
        font-size: 14px;
        color: #a0a3bd;
        .flex-l();
        justify-content: flex-end;
        .progress-rate {
          .flex-l();
          min-width: 80px;
        }
        .align-state {
          font-size: 12px;
          color: #ff9900;
          .hover-hidden {
            display: block;
          }
          margin-right: 8px;
          .hover-block {
            display: none;
          }
          cursor: pointer;
          min-width: 50px;
          text-align: right;
          &:hover {
            color: #ff572b;
            .hover-hidden {
              display: none;
            }
            .hover-block {
              display: block;
            }
          }
        }
      }
      .key-right {
        color: #a0a3bd;
      }
      .okr-wrap {
        .okr-num {
          min-width: 24px;
          height: 24px;
          background: #548af7;
          border-radius: 100%;
          font-weight: bold;
          font-size: 12px;
          line-height: 24px;
          color: #fff;
          margin: 0px 25px 0px 8px;
          position: relative;
          text-align: center;
        }
        .okr-content {
          position: relative;
          flex: 1;
          min-width: 0;
        }
        .content-true {
          cursor: pointer;
        }
        .content {
          font-size: 16px;
          font-weight: bold;
          color: #444558;
          line-height: 26px;
          // .text-n(1);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          &:hover {
            color: #ff9900;
            ::v-deep .at-name {
              color: #ff9900 !important;
            }
          }
        }
        &:hover {
          border-radius: 12px;
        }
      }
      .key-list-wrap {
        .key-list {
          .key-num {
            position: relative;
            height: 26px;
            border-radius: 13px;
            background: rgba(58, 120, 246, 0.08);
            font-size: 12px;
            font-weight: 500;
            color: #3a78f6;
            margin-right: 16px;
            min-width: 40px;
            line-height: 26px;
            text-align: center;
          }
          .key-content {
            position: relative;
            color: #444558;
            line-height: 26px;
            font-size: 14px;
            .content {
              .text-n(1);
            }
          }
          &:hover {
            border-radius: 20px;
          }
        }
      }
    }
  }
}
</style>
<style>
.member-card-popover {
  border-radius: 16px;
  border: 0;
  padding: 0;
  overflow: hidden;
  box-shadow: 0px 4px 16px 0px rgba(17, 17, 17, 0.06);
  min-height: 200px;
}
</style>
