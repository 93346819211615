<template>
  <el-dialog
    title="同步至绩效"
    :visible.sync="dialogVisible"
    class="peoject-add-dialog"
    width="680px"
    :close-on-click-modal="false"
    :append-to-body="true"
    :before-close="handleClose"
    v-on="$listeners"
  >
    <div class="add-content-wrap">
      <div class="add-content">
        <div class="add-content-left">
          <div class="search-list-content">
            <div class="search-list-wrap">
              <div class="select-wrap select-date-wrap">
                年份:
                <el-date-picker
                  v-model="cycleYear"
                  type="year"
                  placeholder="请选择年份"
                  format="yyyy 年"
                  value-format="yyyy"
                  :clearable="false"
                  @change="searchInput"
                />
                <div class="user-arrow el-icon-arrow-down"></div>
              </div>
              <div class="select-wrap">
                周期:
                <el-select
                  v-model="cycleType"
                  placeholder="请选择"
                  collapse-tags
                  @change="searchInput"
                >
                  <el-option
                    v-for="item in kpiCycleTypeList"
                    :key="item.cycleType"
                    :label="item.name"
                    :value="item.cycleType"
                  />
                </el-select>
              </div>
            </div>
            <el-input
              v-model="examineName"
              prefix-icon="el-icon-search"
              placeholder="搜索考核计划名称"
              class="user-el-input input-focus"
              @input="searchInput"
            />
          </div>
          <div
            class="kpi-list-wrap-content"
            v-vueloading="pageLoading"
            v-if="kpiList.length > 0 && !pageLoading"
          >
            <kpi-item
              v-for="(item, index) in kpiList"
              :class="`select-${item.selectTemplateDimensionId ? true : false}`"
              @toDetail="checkboxChange(item, index)"
              :key="item.examineId"
              :item="item.examineDto"
              :kpi-detail="item"
              from-type="myKpi"
            >
              <template slot="kpiItemRight" v-if="item.selectTemplateDimensionId">
                <Icon icon-class="icon-duigou" class="icon-check" />
              </template>
              <div
                slot="kpiProcess"
                class="dimension-list-wrap"
                v-if="item.templateDto.templateDimensionDtoList && item.selectTemplateDimensionId"
                @click.stop
              >
                <div class="dimension-list">选择维度：</div>
                <el-radio-group v-model="item.selectTemplateDimensionId">
                  <el-tooltip
                    v-for="dimensionItem in item.templateDto.templateDimensionDtoList"
                    :key="dimensionItem.templateDimensionId"
                    :content="`已设为「不允许导入」或okr维度,不可同步`"
                    :disabled="dimensionItem.importIndexPower === 1 && dimensionItem.type !== 2 ? true : false"
                  >
                    <el-radio
                      :label="dimensionItem.templateDimensionId"
                      :value="dimensionItem.name"
                      :disabled="dimensionItem.importIndexPower === 1 && dimensionItem.type !== 2 ? false : true"
                    >{{ dimensionItem.name }}</el-radio>
                  </el-tooltip>
                </el-radio-group>
              </div>
            </kpi-item>
          </div>
          <div class="kpi-list-wrap-content" v-if="kpiList.length === 0 && !pageLoading">
            <no-data />
          </div>
        </div>
      </div>
      <div class="bottom-step-buttons">
        <button @click="handleClose" class="left-button">取消</button>
        <el-button v-preventReClick @click="saveBtn()">同步</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import debounce from 'lodash/debounce' // 局部引入防抖
import kpiItem from '@/views/kpiV2/components/kpiItem' // 新建指标
export default {
  components: {
    kpiItem
  },
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  props: {
    dialogShow: {
      type: Boolean,
      default: true
    },
    multipleChoice: {
      default: true
    },
    krId: {
      default: ''
    },
    defaultList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      dialogVisible: this.dialogShow,
      selectList: [],
      examineName: '',
      cycleYear: '2023',
      cycleType: '',
      checkboxAll: false,
      pageLoading: true,
      kpiList: []
    }
  },
  watch: {
    dialogShow: {
      handler(val) {
        this.dialogVisible = val
        if (val) {
          this.cycleYear = (new Date().getFullYear()).toString()
          this.selectList = JSON.parse(JSON.stringify(this.defaultList))
          this.$nextTick(() => {
            this.getKpiList()
          })
        }
      },
      immediate: true
    }
  },
  methods: {
    searchInput: debounce(function () {
      this.pageLoading = true
      this.getKpiList()
    }, 200),
    checkboxAllChange() {
      if (this.checkboxAll) {
        this.selectList = JSON.parse(JSON.stringify(this.kpiList))
      } else {
        this.selectList = []
      }
      this.getCheckList(this.kpiList)
    },
    selectDelete(index, type) {
      if (type === 'clear') {
        this.selectList = []
      } else {
        this.selectList.splice(index, 1)
      }
      this.getCheckList(this.kpiList)
    },
    checkboxChange(item, index) {
      if (!this.multipleChoice) {
        if (item.checked !== true) {
          this.selectList = []
          const selectTemplateDimensionId = this.getTemplateDimensionId(item)
          this.kpiList[index].checked = true
          this.kpiList[index].selectTemplateDimensionId = selectTemplateDimensionId
          item.selectTemplateDimensionId = selectTemplateDimensionId
          this.selectList.push(item)
        } else {
          this.kpiList[index].checked = false
          this.kpiList[index].selectTemplateDimensionId = ''
          this.selectList = []
        }
      } else {
        if (item.checked !== true) {
          const selectTemplateDimensionId = this.getTemplateDimensionId(item)
          this.kpiList[index].checked = true
          this.kpiList[index].selectTemplateDimensionId = selectTemplateDimensionId
          item.selectTemplateDimensionId = selectTemplateDimensionId
          this.selectList.push(item)
          this.selectList = [...this.distincts(this.selectList)]
        } else {
          this.kpiList[index].checked = false
          this.kpiList[index].selectTemplateDimensionId = ''
          this.selectList.forEach((selectItem, selectIndex) => {
            if (selectItem.examineId === item.examineId) {
              this.selectList.splice(selectIndex, 1)
            }
          })
        }
      }
      this.getCheckList(this.kpiList)
    },
    getTemplateDimensionId(item) {
      var selectTemplateDimensionId = ''
      var insertState = true
      item.templateDto.templateDimensionDtoList.forEach((dimensionItem) => {
        if (insertState && dimensionItem.importIndexPower === 1 && dimensionItem.type !== 2) {
          insertState = false
          selectTemplateDimensionId = dimensionItem.templateDimensionId
        }
      })
      return selectTemplateDimensionId
    },
    // 数组对象去重
    distincts(arr, field = 'examineId') {
      const res = new Map()
      return arr.filter(
        (item) => !res.has(item[field]) && res.set(item[field], 1)
      )
    },
    getKpiList() {
      const params = {
        keyresultsId: this.krId,
        userId: this.userInfo.id,
        cycleYear: this.cycleYear || '2023',
        cycleType: this.cycleType,
        examineName: this.examineName
      }
      this.$apis.SYNCHRONIZEKR(params).then((rest) => {
        this.pageLoading = false
        this.getCheckList(rest.data)
      })
    },
    getCheckList(list) {
      if (this.defaultList.length > 0) {
        this.defaultList.forEach((selectItem) => {
          list.forEach((rowItem) => {
            if (selectItem.examineId === rowItem.examineId) {
              rowItem.selectTemplateDimensionId = selectItem.templateDimensionId
            }
          })
        })
      }
      this.kpiList = JSON.parse(JSON.stringify(list))
    },
    handleClose() {
      this.dialogVisible = false
      this.$emit('change', false)
    },
    saveBtn() {
      var list = []
      var state = false
      var selectList = JSON.parse(JSON.stringify(this.selectList))
      selectList.forEach((item) => {
        if (item.selectTemplateDimensionId) {
          var templateDimensionDtoList = []
          item.templateDto.templateDimensionDtoList.forEach((dimensionItem) => {
            if (dimensionItem.templateDimensionId === item.selectTemplateDimensionId) {
              dimensionItem.templateIndexDtoList = [{
                type: 3,
                objValue: this.krId
              }]
              templateDimensionDtoList.push(dimensionItem)
            }
          })
          item.templateDto.templateDimensionDtoList = templateDimensionDtoList
          state = true
          list.push(item)
        }
      })
      if (!state) {
        this.$message.warn('同步数据不能为空哦！')
        return
      }
      const params = list
      this.$apis.SYNCPERFORMANCECUSTOMINDEXKR(params).then((rest) => {
        if (rest.status === 200) {
          this.$message.success(rest.message)
          this.$emit('refresh')
          this.handleClose()
        } else {
          this.$message.error(rest.message)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../../assets/styles/dialog.less';
.add-content-wrap {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  height: calc(80vh - 90px);
  min-height: 300px;
  .add-content {
    flex: 1;
    .flex-bt();
    align-items: flex-start;
    overflow: hidden;
    .add-content-left {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      flex: 1;
      height: 100%;
      ::v-deep {
        .user-el-input.el-input {
          width: 248px;
          .el-input__inner {
            background: #f5f6fa;
          }
        }
      }
      .search-list-content {
        .flex-bt();
      }
      .search-list-wrap {
        .flex-l();
        color: #6e7491;
        margin: 2px 0px 8px 0px;
        font-size: 14px;
        line-height: 40px;
        .select-date-wrap {
          width: 130px;
        }
        .select-wrap {
          .flex-l();
          position: relative;
          cursor: pointer;
          &:hover {
            color: #3a70ff;
          }
          ::v-deep {
            .el-date-editor {
              position: absolute;
              left: 30px;
              z-index: 1;
            }
            .el-icon-arrow-down {
              position: absolute;
              right: 24px;
              font-size: 14px;
            }
            .el-select__caret {
              color: #6e7491 !important;
            }
          }
        }
        ::v-deep {
          .el-input,
          .el-select {
            margin-left: 4px;
            width: 180px;
          }
          .el-input__prefix {
            display: none;
          }
          .el-input__inner {
            border: none;
            padding: 0px;
            color: #6e7491;
            font-size: 14px;
            background: none;
            &:hover {
              color: #3a78f6;
            }
          }
          .el-tag.el-tag--info {
            color: #6e7491 !important;
            font-size: 12px;
          }
          .el-date-editor.el-input {
            width: 80px;
            padding-right: 24px;
          }
        }
      }
      .kpi-list-wrap-content {
        flex: 1;
        overflow-y: auto;
        ::v-deep {
          .kpi-item-content {
            align-items: flex-start;
            .icon-check {
              font-size: 24px;
              color: #3a70ff;
            }
          }
          .kpi-item-wrap {
            border: 1px solid #e7e8ef;
            border-radius: 12px;
            padding: 16px 24px;
            .list-count,
            .list-user {
              display: none;
            }
            .title-wrap {
              font-size: 16px;
              padding-left: 0px;
              .name {
                max-width: 400px;
                &::before {
                  width: 0px;
                  display: none;
                }
              }
            }
            .title-wrap-true {
              &::after {
                width: 0px;
                display: none;
              }
            }
            &:hover {
              border: 1px solid #95b6fa;
              box-shadow: none;
              // background: #e9edfa;
              -webkit-transform: translateY(0px);
              transform: translateY(0px);
              .name {
                color: #3a70ff;
              }
            }
          }
          .kpi-item-wrap.select-true {
            border: 1px solid #95b6fa;
            background: #e9edfa;
          }
          .dimension-list-wrap {
            border-top: 1px solid #d9dbe9;
            .flex-l();
            flex-wrap: wrap;
            margin-top: 16px;
            position: relative;
            padding-left: 80px;
            .dimension-list:nth-child(1) {
              color: #3a70ff;
            }
            .dimension-list {
              position: absolute;
              top: 16px;
              line-height: 20px;
              left: 0px;
            }
            .el-radio {
              margin-top: 16px;
              .el-radio__inner {
                background: none !important;
                &::after {
                  background: #3a78f6;
                }
              }
            }
          }
        }
      }
    }
    .kpi-list {
      .flex-bt();
      height: 36px;
      line-height: 36px;
      margin-top: 8px;
      .name {
        flex: 1;
        .text-n(1);
      }
      .delete {
        cursor: pointer;
        color: #a0a3bd;
        font-size: 16px;
        min-width: 16px;
        &:hover {
          color: #3a70ff;
        }
      }
      ::v-deep {
        .el-checkbox__label {
          padding-left: 8px;
          font-size: 14px;
          color: #14142b;
        }
      }
    }
  }
  .bottom-step-buttons {
    margin: 32px 0px 8px 0px;
    height: 40px;
    .left-button,
    .el-button {
      height: 40px;
      line-height: 38px;
      border-radius: 8px;
      width: 128px;
    }
  }
}
</style>
