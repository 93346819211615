<template>
  <el-popover
    ref="popRef"
    :placement="placement"
    width="280"
    trigger="manual"
    :popper-class="
      animateState
        ? `guide-popover animate-style-${placement}`
        : `guide-popover`
    "
    :disabled="disabled"
  >
    <div class="guide-pop-box">
      <p v-if="animateState" class="pop-box-title">{{ title }}</p>
      {{ innerText }}
      <div class="guide-pop-btn" @click="hidePopView">我知道了</div>
    </div>
    <slot slot="reference" name="guidepop"></slot>
  </el-popover>
</template>
<script>
export default {
  props: {
    innerText: {
      // 气泡文字
      type: String
    },
    showBtn: {
      // 是否显示按钮
      type: Boolean
    },
    title: {
      type: String
    },
    placement: {
      // 方位
      type: String,
      default: 'top'
    },
    popKey: {
      type: String
    },
    morePop: {
      // 存在多个popover
      type: Array
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideClickEmit: {
      type: Boolean,
      default: false
    },
    animateState: {
      // 动画
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      userPopState: ''
    }
  },
  computed: {},
  watch: {
    popKey: {
      handler(val) {
        this.userPopState = this.$store.state.common.userPopState
        if (val && !this.userPopState[val] && !this.morePop) {
          this.$nextTick(() => {
            setTimeout(() => {
              if (this.$refs.popRef) {
                this.$refs.popRef.doShow()
              }
            }, 600)
            if (this.$refs.popRef) {
              this.$refs.popRef.updatePopper() // 注意主要是这里
            }
          })
        }
        if (this.morePop) {
          this.checkListPop()
        }
      },
      immediate: true
    }
  },
  beforeDestroy() {
    if (this.popKey) {
      this.$store.commit('common/changeUserPopState', this.popKey)
    }
  },
  mounted() {
    // console.dir(this.userPopState)
    if (this.morePop) {
      this.checkListPop()
    }
  },
  methods: {
    hidePopView() {
      this.$store.commit('common/changeUserPopState', this.popKey)
      if (this.morePop) {
        this.checkListPop()
      }
      if (this.hideClickEmit) {
        this.$emit('hideClick')
      }
      this.$refs.popRef.doClose()
    },
    checkListPop() {
      this.morePop.some((item) => {
        console.log(!this.userPopState[item], '-------------', item)
        if (!this.userPopState[item]) {
          console.log(item)
          if (this.$refs.popRef) {
            this.$refs.popRef.doShow()
          }
          this.$emit('showpop', item)
          return true
        }
      })
    },
    showNextPop() {
      if (this.popKey) {
        this.$refs.popRef.doShow()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.guide-pop-box {
  display: flex;
  flex-direction: column;
  .guide-pop-btn {
    margin-top: 16px;
    width: 74px;
    text-align: center;
    color: #3a78f6;
    background: #fff;
    font-size: 12px;
    border-radius: 8px;
    line-height: 22px;
    margin-left: auto;
    cursor: pointer;
  }
  .pop-box-title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
  }
}
</style>
<style>
.animate-style-bottom {
  animation: shake 2000ms ease-in-out infinite;
}
@keyframes shake {
  /* 水平抖动，核心代码 */
  10%,
  90% {
    transform: translate3d(0, -1px, 0);
  }
  20%,
  80% {
    transform: translate3d(0, +2px, 0);
  }
  30%,
  70% {
    transform: translate3d(0, -4px, 0);
  }
  40%,
  60% {
    transform: translate3d(0, +4px, 0);
  }
  50% {
    transform: translate3d(0, -4px, 0);
  }
}
.guide-popover {
  background: #3a78f6;
  color: #fff;
  border-radius: 8px;
  font-weight: 400;
  border: 0;
}
.guide-popover.el-popper[x-placement^='bottom'] .popper__arrow {
  border-bottom-color: #3a78f6 !important;
}
.guide-popover.el-popper[x-placement^='bottom'] .popper__arrow::after {
  border-bottom-color: #3a78f6 !important;
}
.guide-popover.el-popper[x-placement^='top'] .popper__arrow::after {
  border-top-color: #3a78f6 !important;
}
.guide-popover.el-popper[x-placement^='left'] .popper__arrow::after {
  border-left-color: #3a78f6 !important;
}
.guide-popover.el-popper[x-placement^='right'] .popper__arrow::after {
  border-right-color: #3a78f6 !important;
}
</style>
