var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: `share-user-avatar-wrap number-${
        _vm.list.length > 9 ? 9 : _vm.list.length
      }`,
    },
    _vm._l(
      _vm.list.length > 9 ? _vm.list.slice(0, 9) : _vm.list,
      function (item) {
        return _c(
          "div",
          { key: item.user.id, staticClass: "avatar-list" },
          [
            _c("el-image", {
              attrs: {
                src:
                  (_vm.type === "map"
                    ? _vm.getUserAvatar(item.user.avatar)
                    : item.user.avatar) ||
                  require("@/assets/imgs/common/avatar.png"),
                fit: "cover",
              },
            }),
          ],
          1
        )
      }
    ),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }