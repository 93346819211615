<template>
  <div class="radial-indicator-container" :style="{ width: size + 'px', height: size + 'px' }">
    <el-progress
      type="circle"
      :width="size"
      :stroke-width="2"
      :show-text="false"
      style="position: absolute; left: 0px; top: 0px"
      :color="color"
      :percentage="percentNum"
    />
    <img class="content-img" :src="icon" alt />
  </div>
</template>
<style lang="scss" scoped>
.radial-indicator-container {
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center; /*垂直居中*/
  justify-content: center; /*水平居中*/

  .content-img {
    width: 80%;
    height: 80%;
    border-radius: 100% 100%;
    display: block;
  }
}
</style>
<script>
// Events: animationFinished

export default {
  name: 'RadialIndicator',

  props: {
    size: {
      type: [String, Number],
      default: 155
    },
    icon: {
      type: String
      // default: require('../../../assets/img/icon_setting_1.png')
    },
    content: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: '#00b3ef'
    },
    backgroundColor: {
      type: String,
      default: '#7fd9f7'
    },
    percentNum: {
      type: [String, Number],
      default: 0
    },
    speed: {
      // suggest 1~3
      type: [String, Number],
      default: 1
    }
  },

  data() {
    return {
      percent: 0,
      initDeg: 0,
      timeId: null,
      animationing: false,
      leftcontentRotate: 0,
      rightcontentRotate: 0
    }
  },

  watch: {},

  created() {},

  methods: {}
}
</script>
