<template>
  <div>
    <el-dialog
      :title="dialogImgTitle"
      :visible.sync="isShow"
      :append-to-body="true"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      :close-on-click-modal="true"
      class="file-img-box"
      :before-close="handleClose"
    >
      <div class="downloadImg">
        <Icon
          icon-class="icon-tuichudenglu"
          class="icon-download"
          @click="downloadImg(dialogImageUrl)"
        />
      </div>
      <div class="img-wrap-box">
        <el-image
          v-if="dialogPreviewVisible"
          :src="dialogImageUrl"
          class="dialog-img"
        />
        <div v-else class="preview-null">
          <Icon class="file-svg" icon-class="icon-fileCommon" />
          <p>此格式不支持预览，请下载后查看</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    dialogImgTitle: {
      type: String,
      default: ''
    },
    dialogImgVisible: {
      type: Boolean,
      default: false
    },
    dialogImageUrl: {
      type: String,
      default: ''
    },
    dialogPreviewVisible: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    dialogImgVisible(val) {
      this.isShow = val
    }
  },
  data() {
    return {
      isShow: false
    }
  },
  methods: {
    downloadImg(dialogImageUrl) {
      this.$emit('downloadImg', dialogImageUrl)
    },
    handleClose() {
      this.$emit('closeLookImg')
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../assets/styles/dialog.less';
::v-deep {
  .file-img-box .el-dialog__header {
    padding: 32px 32px 0 32px !important;
  }
  .el-dialog__title {
    display: inline-block;
    width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
  }
  .el-dialog_wrapper .dialog-fade-leave-active {
    animation-fill-mode: forwards !important;
  }
  .file-img-box .el-dialog {
    width: 80%;
    margin: 52px 10% !important;
  }
  .downloadImg {
    .flex-l();
    position: absolute;
    height: 64px;
    line-height: 64px;
    top: 17px;
    right: 60px;
    cursor: pointer;
    width: 40px;
    &:before {
      content: '';
    }
    .icon-download {
      transform: rotate(90deg);
      font-size: 24px;
      width: 30px;
      height: 30px;
      padding: 4px;
      border-radius: 4px;
    }
    &:hover {
      .icon-download {
        background: #f5f6fa;
      }
    }
  }
  .img-wrap-box {
    margin: 30px auto 20px;
    text-align: center;
    vertical-align: middle;
    .dialog-img {
      width: auto;
      max-width: 100%;
      max-height: calc(80vh - 150px);
      overflow-y: auto;
    }
  }
}
</style>
