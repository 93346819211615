export default {
  path: '/calendar',
  name: 'calendar',
  component: () => {
    return import(
      /* webpackChunkName: "calendar" */
      '../../views/calendar/index'
    )
  }
}
