// 工作台
// 统一管理所有的基础请求
import apis from '../apis_moudles/index'
import req from '../request'
var $isQw =
  window.location.host.includes('qywx') || window.location.host.includes('qw')
// 设置相关
// 查询字段列表
const TEMPLATEFIELDLISTV2 = (params) => {
  return req.get(apis.templateFieldListV2, {
    params
  })
}
// 查询字段详情
const TEMPLATEFIELDDETAILV2 = (id, params) => {
  return req.get(`${apis.templateFieldDetailV2}${id}`, {
    params
  })
}
// 字段删除
const TEMPLATEFIELDDELETEV2 = (params) => {
  return req.post(`${apis.templateFieldDeleteV2}`, params)
}
// 字段添加
const TEMPLATEFIELDSAVEV2 = (params) => {
  return req.post(`${apis.templateFieldSaveV2}`, params)
}
// 字段修改
const TEMPLATEFIELDUPDATEV2 = (params) => {
  return req.post(`${apis.templateFieldUpdateV2}`, params)
}
// 查询字段选项值
const TEMPLATEFIELDVALUESLISTV2 = (params) => {
  return req.get(apis.templateFieldValuesListV2, {
    params
  })
}
// 添加字段选项值
const TEMPLATEFIELDVALUESSAVEV2 = (params) => {
  return req.post(`${apis.templateFieldValuesSaveV2}`, params)
}
// 查询指标分类列表
const TEMPLATEINDEXGROUPLISTV2 = (params) => {
  return req.get(apis.templateIndexGroupListV2, {
    params
  })
}
// 添加指标分类
const TEMPLATEINDEXGROUPSAVEV2 = (params) => {
  return req.post(apis.templateIndexGroupSaveV2, params)
}
// 修改指标分类
const TEMPLATEINDEXGROUPUPDATEV2 = (params) => {
  return req.post(apis.templateIndexGroupUpdateV2, params)
}
// 删除指标分类
const TEMPLATEINDEXGROUPDELETEV2 = (params) => {
  return req.post(apis.templateIndexGroupDeleteV2, params)
}
// 添加指标
const TEMPLATEINDEXSAVEV2 = (params) => {
  return req.post(apis.templateIndexSaveV2, params)
}
// 查询指标/维度 默认添加的字段
const TEMPLATEINDEXSAVEDEFV2 = (params) => {
  return req.get(apis.templateIndexSaveDefV2, {
    params
  })
}
// 修改指标
const TEMPLATEINDEXUPDATEV2 = (params) => {
  return req.post(apis.templateIndexUpdateV2, params)
}
// 查询指标详情
const TEMPLATEINDEXDETAILV2 = (id, params) => {
  return req.get(`${apis.templateIndexDetailV2}${id}`, {
    params
  })
}
// 分页查询指标列表
const TEMPLATEINDEXLISTV2 = (params) => {
  return req.get(apis.templateIndexListV2, {
    params
  })
}
// 删除指标
const TEMPLATEINDEXDELETEV2 = (params) => {
  return req.post(apis.templateIndexDeleteV2, params)
}
// 导入指标-具体业务再定-目前看无法导入-因为为自定义字段
const TEMPLATEINDEXIMPORTV2 = (url, params) => {
  return req.post(`${url}`, params, {
    timeout: 120000,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
// 导出指标
const TEMPLATEINDEXEXPORTV2 = (params) => {
  return req.post(apis.templateIndexExportV2, params, {
    responseType: 'arraybuffer'
  })
}
// 批量删除指标
const TEMPLATEINDEXDELETEBATCHV2 = (params) => {
  return req.post(apis.templateIndexDeleteBatchV2, params)
}
// 根据指标id导出指标
const TEMPLATEINDEXEXPORTSELECTV2 = (params) => {
  return req.get(apis.templateIndexExportSelectV2, {
    params
  })
}
// 分页查询指标计算公式
const TEMPLATEINDEXCOMPUTELISTV2 = (params) => {
  return req.get(apis.templateIndexComputeListV2, {
    params
  })
}
// 添加指标公式
const TEMPLATEINDEXCOMPUTESAVEV2 = (params) => {
  return req.post(apis.templateIndexComputeSaveV2, params)
}
// 修改指标公式
const TEMPLATEINDEXCOMPUTEUPDATEV2 = (params) => {
  return req.post(apis.templateIndexComputeUpdateV2, params)
}
// 查询计算公式详情
const TEMPLATEINDEXCOMPUTEDETAILV2 = (id, params) => {
  return req.get(`${apis.templateIndexComputeDetailV2}${id}`, {
    params
  })
}
// 删除计算公式
const TEMPLATEINDEXCOMPUTEDELETEV2 = (params) => {
  return req.post(apis.templateIndexComputeDeleteV2, params)
}
// 添加考核等级
const GRADESAVEV2 = (params) => {
  return req.post(apis.gradeSaveV2, params)
}
// 查询考核等级详情
const GRADEDETAILV2 = (id, params) => {
  return req.get(`${apis.gradeDetailV2}${id}`, {
    params
  })
}
// 修改考核等级
const GRADEUPDATEV2 = (params) => {
  return req.post(apis.gradeUpdateV2, params)
}
// 删除考核等级
const GRADEDELETEV2 = (params) => {
  return req.post(apis.gradeDeleteV2, params)
}
// 分页查询考核
const GRADELISTV2 = (params) => {
  return req.get(apis.gradeListV2, {
    params
  })
}
// 添加考评关系
const EVALUATIONGROUPSAVEV2 = (params) => {
  return req.post(apis.evaluationGroupSaveV2, params)
}
// 查询考评关系
const EVALUATIONGROUPDETAILV2 = (id, params) => {
  return req.get(`${apis.evaluationGroupDetailV2}${id}`, {
    params
  })
}
// 修改考评关系
const EVALUATIONGROUPUPDATEV2 = (params) => {
  return req.post(apis.evaluationGroupUpdateV2, params)
}
// 删除考评关系
const EVALUATIONGROUPDELETEV2 = (params) => {
  return req.post(apis.evaluationGroupDeleteV2, params)
}
// 分页查询考评关系
const EVALUATIONGROUPLISTV2 = (params) => {
  return req.get(apis.evaluationGroupListV2, {
    params
  })
}
// 添加模板分类
const TEMPLATEGROUPSAVEV2 = (params) => {
  return req.post(apis.templateGroupSaveV2, params)
}
// 修改模板分类
const TEMPLATEGROUPUPDATEV2 = (params) => {
  return req.post(apis.templateGroupUpdateV2, params)
}
// 删除模板分类
const TEMPLATEGROUPDELETEV2 = (params) => {
  return req.post(apis.templateGroupDeleteV2, params)
}
// 查询模板分类
const TEMPLATEGROUPLISTV2 = (params) => {
  return req.get(apis.templateGroupListV2, {
    params
  })
}
// 添加模板
const TEMPLATESAVEV2 = (params) => {
  return req.post(apis.templateSaveV2, params)
}
// 修改模板
const TEMPLATEUPDATEV2 = (params) => {
  return req.post(apis.templateUpdateV2, params)
}
// 删除模板
const TEMPLATEDELETEV2 = (params) => {
  return req.post(apis.templateDeleteV2, params)
}
// 查询模板列表
const TEMPLATELISTV2 = (params) => {
  return req.get(apis.templateListV2, {
    params
  })
}
// 查询模板
const TEMPLATEDETAILV2 = (id, params) => {
  return req.get(`${apis.templateDetailV2}${id}`, {
    params
  })
}
// 考核计划--基本信息--查询
const EXAMINEINFOGETV2 = (params) => {
  return req.get(apis.examineInfoGetV2, {
    params
  })
}
// 考核计划--基本信息--添加/修改
const EXAMINEINFOSAVEV2 = (params) => {
  return req.post(apis.examineInfoSaveV2, params)
}
// 考核计划--考核人员与模版--查询
const EXAMINETEMPLATEDETAILV2 = (params) => {
  return req.get(`${apis.examineTemplateV2}`, {
    params
  })
}
// 考核计划--考核人员与模版--添加/修改
const EXAMINETEMPLATESAVEV2 = (params) => {
  return req.post(apis.examineTemplateV2, params)
}
// 考核计划--考核流程-查询
const EXAMINEPROCESSINFOV2 = (params) => {
  return req.get(apis.examineProcessInfoV2, {
    params
  })
}
// 考核计划--考核流程-保存/修改
const EXAMINEPROCESSV2 = (params) => {
  return req.post(apis.examineProcessV2, params)
}
// 考核计划--考核流程-指标制定-查询
const EXAMINEPROCESSINDEXMAKEV2 = (params) => {
  return req.get(apis.examineProcessIndexMakeV2, {
    params
  })
}
// 考核计划--考核流程-指标确认-查询
const EXAMINEPROCESSINDEXCONFIRMV2 = (params) => {
  return req.get(apis.examineProcessIndexConfirmV2, {
    params
  })
}
// 考核计划--考核流程-完成值-查询
const EXAMINEPROCESSFIELDENTERV2 = (params) => {
  return req.get(apis.examineProcessFieldEnterV2, {
    params
  })
}
// 考核计划--考核流程-员工自评-查询
const EXAMINEPROCESSSELFV2 = (params) => {
  return req.get(apis.examineProcessSelfV2, {
    params
  })
}
// 考核计划--考核流程-成员评定-查询
const EXAMINEPROCESSMEMBERV2 = (params) => {
  return req.get(apis.examineProcessMemberV2, {
    params
  })
}
// 考核计划--考核流程-结果校准-查询
const EXAMINEPROCESSCALIBRATIONV2 = (params) => {
  return req.get(apis.examineProcessCalibrationV2, {
    params
  })
}
// 考核计划--考核流程-绩效沟通-查询
const EXAMINEPROCESSCOMMUNICATIONV2 = (params) => {
  return req.get(apis.examineProcessCommunicationV2, {
    params
  })
}
// 考核计划--考核流程-员工结果确认-查询
const EXAMINEPROCESSSELFCONFIRMV2 = (params) => {
  return req.get(apis.examineProcessSelfConfirmV2, {
    params
  })
}
// 考核计划--考核流程-最终结果确认-查询
const EXAMINEPROCESSLASTCONFIRMV2 = (params) => {
  return req.get(apis.examineProcessLastConfirmV2, {
    params
  })
}
// 考核计划--考核流程-指标制定-保存/修改
const EXAMINEPROCESSINDEXMAKESAVEV2 = (params) => {
  return req.post(apis.examineProcessIndexMakeV2, params)
}
// 考核计划--考核流程-指标确认-保存/修改
const EXAMINEPROCESSINDEXCONFIRMSAVEV2 = (params) => {
  return req.post(apis.examineProcessIndexConfirmV2, params)
}
// 考核计划--考核流程-完成值-保存/修改
const EXAMINEPROCESSFIELDENTERSAVEV2 = (params) => {
  return req.post(apis.examineProcessFieldEnterV2, params)
}
// 考核计划--考核流程-员工自评-保存/修改
const EXAMINEPROCESSSELFSAVEV2 = (params) => {
  return req.post(apis.examineProcessSelfV2, params)
}
// 考核计划--考核流程-成员评定-保存/修改
const EXAMINEPROCESSMEMBERSAVEV2 = (params) => {
  return req.post(apis.examineProcessMemberV2, params)
}
// 考核计划--考核流程-结果校准-保存/修改
const EXAMINEPROCESSCALIBRATIONSAVEV2 = (params) => {
  return req.post(apis.examineProcessCalibrationV2, params)
}
// 考核计划--考核流程-绩效沟通-保存/修改
const EXAMINEPROCESSCOMMUNICATIONSAVEV2 = (params) => {
  return req.post(apis.examineProcessCommunicationV2, params)
}
// 考核计划--考核流程-员工结果确认-保存/修改
const EXAMINEPROCESSSELFCONFIRMSAVEV2 = (params) => {
  return req.post(apis.examineProcessSelfConfirmV2, params)
}
// 考核计划--考核流程-最终结果确认-保存/修改
const EXAMINEPROCESSLASTCONFIRMSAVEV2 = (params) => {
  return req.post(apis.examineProcessLastConfirmV2, params)
}
// 考核计划--等级设置-查询(用设置接口+examineId)
const EXAMINEPROCESSGRADEGETV2 = (examineId, params) => {
  return req.get(`${apis.gradeDetailV2}/${examineId}`, params)
}
// 考核计划--等级设置-保存(用设置接口+examineId)
const EXAMINEPROCESSGRADESAVEV2 = (examineId, params) => {
  return req.post(`${apis.gradeUpdateV2}/${examineId}`, params)
}
// 绩效--考核计划--开启考核
const EXAMINESTARTV2 = (params) => {
  return req.post(`${apis.examineStartV2}`, params)
}
// 绩效--考核计划--归档
const EXAMINEARCHIVINGV2 = (params) => {
  return req.post(`${apis.examineArchivingV2}`, params)
}
// 绩效--考核计划--删除
const EXAMINEDELV2 = (params) => {
  return req.post(`${apis.examineDelV2}`, params)
}
// 绩效--考核计划--导出
const EXAMINEEXPORTV2 = (params) => {
  return req.post(`${apis.examineExportV2}`, params, {
    responseType: $isQw || true ? '' : 'arraybuffer'
  })
}
// 绩效--考核计划--复制
const EXAMINECOPYV2 = (params) => {
  return req.post(`${apis.examineCopyV2}`, params)
}
// 绩效--考核计划--批量终止/单个终止
const EXAMINECLOSEV2 = (params) => {
  return req.post(`${apis.examineCloseV2}`, params)
}
// 绩效--考核计划--批量/单个提醒
const EXAMINEREMINDV2 = (params) => {
  return req.post(`${apis.examineRemindV2}`, params)
}
// 考核计划--考核列表数量
const EXAMINELISTCOUNTV2 = (params) => {
  return req.get(`${apis.examineListCountV2}`, {
    params
  })
}
// 考核计划--考核列表查询
const EXAMINELISTV2 = (params) => {
  return req.get(`${apis.examineListV2}`, {
    params
  })
}
// 绩效--开启流程(完成值/自评/成员评/校准/沟通)
const PERFORMANCEPROCESSSTARTV2 = (params) => {
  return req.post(`${apis.performanceProcessStartV2}`, params)
}
// 1绩效--驳回
const PERFORMANCEREJECTV2 = (params) => {
  return req.post(`${apis.performanceRejectV2}`, params)
}
// 考核计划--考核详情查询
const EXAMINEINFOV2 = (params) => {
  return req.get(`${apis.examineInfoV2}`, {
    params
  })
}
// 绩效--查询考核整体结果校准*
const PERFORMANCEGRADEDISTRIBUTIONLISTV2 = (params) => {
  return req.get(`${apis.performanceGradeDistributionListV2}`, {
    params
  })
}
// 绩效--考核整体结果校准--查询不参与人员
const PERFORMANCEADJUSTUSERV2 = (params) => {
  return req.get(`${apis.performanceAdjustUserV2}`, {
    params
  })
}
// 1绩效--考核整体结果校准--添加/修改不参与人员
const PERFORMANCEADJUSTUSERUPDATEV2 = (params) => {
  return req.post(`${apis.performanceAdjustUserUpdateV2}`, params)
}
// 1绩效--考核整体结果校准--调整分布
const PERFORMANCEGRADEADJUSTV2 = (params) => {
  return req.post(`${apis.performanceGradeAdjustV2}`, params)
}
// 我的部门我的绩效
const PERFORMANCELISTV2 = (params) => {
  return req.get(`${apis.performanceListV2}`, {
    params
  })
}
// 绩效--考核整体结果校准--发放
const PERFORMANCECALIBRATIONSTARTV2 = (params) => {
  return req.post(`${apis.performanceCalibrationStartV2}`, params)
}
// 绩效--考核计划--查询绩效模版组
const EXAMINETEMPLATELISTV2 = (params) => {
  return req.get(`${apis.examineTemplateListV2}`, {
    params
  })
}
// 绩效--考核计划--添加新考核人
const EXAMINETEMPLATEUSERADDV2 = (params) => {
  return req.post(`${apis.examineTemplateUserAddV2}`, params)
}
// 1绩效--考核计划--调整处理人-查询
const PERFORMANCEEVALUATIONUESRV2 = (params) => {
  return req.get(`${apis.performanceEvaluationUesrV2}`, {
    params
  })
}
// (新)绩效--考核计划--调整处理人-添加/修改
const PERFORMANCEEVALUATIONUESRSAVEV2 = (params) => {
  return req.post(`${apis.performanceEvaluationUesrV2}`, params)
}
// 绩效--考核计划--驳回处理人-查询
const PERFORMANCEPROCESSSTEPMEMBERREJECTV2 = (params) => {
  return req.get(`${apis.performanceProcessStepMemberRejectV2}`, {
    params
  })
}
// 统计每个流程阶段的绩效数量
const PROCESSTYPENUMV2 = (params) => {
  return req.get(apis.processTypeNumV2, {
    params
  })
}
// 绩效 我的待办列表
const MYKPILISTV2 = (params) => {
  return req.get(apis.myKpiListV2, {
    params
  })
}
// 绩效流程信息
const KPIPROCESSINFOV2 = (params) => {
  return req.get(apis.kpiProcessInfoV2, {
    params
  })
}
// 绩效指标制定和指标确认 提交
const SUBMITKPIDEAFTCONFIRM = (params) => {
  return req.post(apis.submitKpiDeaftConfirm, params)
}
// 绩效-驳回流程
const PERFORMANCEREJECTPROCESSV2 = (params) => {
  return req.post(apis.performanceRejectProcessV2, params)
}
// 绩效-保存/提交员工评分
const SUBMITPERFORMANCESCORE = (params) => {
  return req.post(apis.submitPerformanceScore, params)
}
// 绩效-获取等级列表
const KPIGRADEINFO = (params) => {
  return req.get(apis.kpiGradeInfo, {
    params
  })
}
// 绩效-成员评定添加考评人
const KPIPERFORMANCEUSERLIST = (params) => {
  return req.get(apis.kpiPerformanceUserList, {
    params
  })
}
// 绩效-保存考评人saveEvaluationUsers
const SAVEEVALUATIONUSERS = (params) => {
  return req.post(apis.saveEvaluationUsers, params)
}
// 查询字段列表分页
const TEMPLATEFIELDPAGELISTV2 = (params) => {
  return req.get(apis.templateFieldPageListV2, {
    params
  })
}
// 添加维度
const TEMPLATEDIMENSIONSAVEV2 = (params) => {
  return req.post(`${apis.templateDimensionSaveV2}`, params)
}
// 修改维度
const TEMPLATEDIMENSIONUPDATEV2 = (params) => {
  return req.post(`${apis.templateDimensionUpdateV2}`, params)
}
// 查询维度详情
const TEMPLATEDIMENSIONDETAILV2 = (id, params) => {
  return req.get(apis.templateDimensionDetailV2, {
    params
  })
}
// 绩效-提交结果校准
const SUBMITRESULTADJUST = (params) => {
  return req.post(apis.submitResultAdjust, params)
}
// 绩效-提交绩效沟通
const SUBMITCOMMUNICATION = (params) => {
  return req.post(apis.submitCommunication, params)
}
// 绩效-提交转交其他人
const SUBMITTRANSFER = (params) => {
  return req.post(apis.submitTransfer, params)
}
// 绩效-提交跳过当前流程阶段
const SUBMITSKPIPROCESS = (params) => {
  return req.post(apis.submitSkipProcess, params)
}
// 查询examineId 下的模版中所有的字段
const SELECTEXAMINEFIELDV2 = (params) => {
  return req.get(apis.selectExamineFieldV2, {
    params
  })
}
// 查询考核计划下等级列表
const GRADEEXAMINELISTV2 = (params) => {
  return req.get(apis.gradeExamineListV2, {
    params
  })
}
// 批量添加行业指标到 -- 指标库
const ADDTEMPLATEINDEXBATCHV2 = (params) => {
  return req.post(`${apis.addTemplateIndexBatchV2}`, params)
}
// 查询行业指标详情
const TEMPLATEINDUSTRYDETAILV2 = (id, params) => {
  return req.get(`${apis.templateIndustryDetailV2}${id}`, {
    params
  })
}
// 行业指标库
const INDUSTRYPAGESLISTV2 = (params) => {
  return req.get(`${apis.industryPagesListV2}`, {
    params
  })
}
// 考核计划--考核计划列表查询
const PERFORMANCEDETAILSV2 = (params) => {
  return req.get(`${apis.performanceDetailsV2}`, {
    params
  })
}
// 查询考核计划-所有被考核人列表
const EXAMINEUSERLISTV2 = (params) => {
  return req.get(`${apis.examineUserListV2}`, {
    params
  })
}
// 进度追踪-详情-分页
const TRACKINGINFOPAGE = (params) => {
  return req.get(`${apis.trackingInfoPage}`, {
    params
  })
}
// 指标分析-查询指标
const ANALYSISQUERYINDEX = (params) => {
  return req.post(`${apis.analysisQueryIndex}`, params)
}
// 考核报表-根据考核计划查询考核组
const EXAMINEGROUPLIST = (params) => {
  return req.get(`${apis.examineGroupList}`, {
    params
  })
}
// 考核报表-查询所有可筛选的考核计划
const PERFORMANCEEXAMINELIST = (params) => {
  return req.post(`${apis.performanceExamineList}`, params)
}
// 结果报表-查询考核等级对应人员详情(分页)
const RESULTINFOGRADEPAGE = (params) => {
  return req.post(`${apis.resultInfoGradePage}`, params)
}
// 指标分析-导出下载数据
const INDEXANALYSISDOWNLOAD = (params) => {
  return req.post(`${apis.indexAnalysisDownload}`, params, {
    responseType: $isQw ? '' : 'arraybuffer'
  })
}
// 指标分析-查询数据列表(分页)
const INDEXANALYSISINFOPAGE = (params) => {
  return req.post(`${apis.indexAnalysisInfoPage}`, params)
}
// 指标分析-查询详情
const INDEXANALYSISINFO = (params) => {
  return req.post(`${apis.indexAnalysisInfo}`, params)
}
// 员工档案-查询详情(分页)
const MEMBERARCHIVESPAGE = (params) => {
  return req.post(`${apis.memberArchivesPage}`, params)
}
// 【待定】结果报表-导出下载数据
const RESULTINFODOWNLOAD = (params) => {
  return req.post(`${apis.resultInfoDownload}`, params, {
    responseType: $isQw ? '' : 'arraybuffer'
  })
}
// 结果报表-查询数据列表分页
const RESULTINFOPAGE = (params) => {
  return req.post(`${apis.resultInfoPage}`, params)
}
// 结果报表-按部门等级查询详情
const RESULTDEPARTMENTINFO = (params) => {
  return req.post(`${apis.resultDepartmentInfo}`, params)
}
// 结果报表-按等级查询详情
const RESULTGRADEINFO = (params) => {
  return req.post(`${apis.resultGradeInfo}`, params)
}
// 绩效 - 考核结果确认
const SUBMITSELFCONFIRMSTATUS = (params) => {
  return req.post(`${apis.submitSelfConfirmStatus}`, params)
}
// 绩效 - 最终结果确认
const SUBMITLASTCONFIRMSTATUS = (params) => {
  return req.post(`${apis.submitLastConfirmStatus}`, params)
}
// 考核计划--考核计划列表查询
const EXAMINECYCLE = (params) => {
  return req.get(`${apis.examineCycle}`, {
    params
  })
}
// 绩效--绩效--终止
const PERFORMANCECLOSE = (params) => {
  return req.post(`${apis.performanceClose}`, params)
}
// 绩效-考核计划-考核结果调整记录
const PERFORMANCEGRADELIST = (params) => {
  return req.get(`${apis.performanceGradeList}`, {
    params
  })
}
// 进度追踪-顶部异常预警项目数量
const TRACKINGABNORMALCOUNT = (params) => {
  return req.get(`${apis.trackingAbnormalCount}`, {
    params
  })
}
// 考核计划-计划列表-最终结果确认
const PERFORMANCERESULTCONFIRM = (params) => {
  return req.post(`${apis.performanceResultConfirm}`, params)
}
// 查看绩效考核动态详情
const PERFORMANCECOMMENTLISTV2 = (params) => {
  return req.get(`${apis.performanceCommentListV2}`, {
    params
  })
}
// 绩效详情驳回处理人
const KPIREJECTPROCESSMEMBER = (params) => {
  return req.post(`${apis.kpiRejectProcrssMember}`, params)
}
// 考核计划--绩效列表--驳回处理人
const PERFORMANCEEXAMINEPROCESSSTEPMEMBERREJECT = (params) => {
  return req.post(`${apis.performanceExamineProcessStepMemberReject}`, params)
}
// 员工档案-查询部门人员
const ADMINDEPARTMENTUSERLIST = (params) => {
  return req.post(`${apis.adminDepartmentUserList}`, params)
}
// 【待定】员工档案-导出下载数据
const MEMBERARCHIVESDOWNLOAD = (params) => {
  return req.post(`${apis.memberArchivesDownload}`, params, {
    responseType: $isQw ? '' : 'arraybuffer'
  })
}
// 根据userID 获取用户下级人员
const UNDERLINGSUSERLIST = (params) => {
  return req.get(`${apis.underlingsUserList}`, {
    params
  })
}
// 查询绩效流程列表详情-(调整人)
const PERFORMANCEPROCESSLISTINFO = (params) => {
  return req.get(`${apis.performanceProcessListInfo}`, {
    params
  })
}
// 进度追踪-正态分布详情-分页
const ABNORMALGRADEPAGE = (params) => {
  return req.get(`${apis.abnormalGradePage}`, {
    params
  })
}
// 绩效流程调整处理人
const PERFORMANCEPROCESSLISTOPERATORADJUSTMENT = (params) => {
  return req.post(`${apis.performanceProcessListOperatorAdjustment}`, params)
}
// 考核计划菜单权限
const EXAMINEPOWERGETUSERPOWER = (params) => {
  return req.get(`${apis.examinePowerGetUserPower}`, {
    params
  })
}
// 同步kr 至 绩效指标
const SYNCPERFORMANCECUSTOMINDEXKR = (params) => {
  return req.post(`${apis.syncPerformanceCustomIndexKr}`, params)
}
// 取消同步kr至绩效指标
const SYNCPERFORMANCECUSTOMINDEXKRCANCEL = (params) => {
  return req.post(`${apis.syncPerformanceCustomIndexKrCancel}`, params)
}
// 根据kr-id,查询kr已经同步的绩效
const SYNCPERFORMANCEKR = (params) => {
  return req.get(`${apis.syncPerformanceKr}`, {
    params
  })
}
// 根据objId,查询kr已经同步的绩效
const SYNCPERFORMANCEKRLIST = (params) => {
  return req.get(`${apis.syncPerformanceKrList}`, {
    params
  })
}
// 查询被考核人可以同步的考核列表
const SYNCHRONIZEKR = (params) => {
  return req.get(`${apis.synchronizeKr}`, {
    params
  })
}
// 成员评定查询操作人详情
const PFOCESSMEMBERINFO = (params) => {
  return req.get(`${apis.processMemberInfo}`, {
    params
  })
}
// 指标指定-分页查询指标列表
const TEMPLATEINDEXINDEXMAKELIST = (params) => {
  return req.get(apis.templateIndexIndexMakeList, {
    params
  })
}
// 查询考核绩效所有评分人--总评分列表
const TOTALJUDGESSCORELIST = (params) => {
  return req.get(`${apis.totalJudgesScoreList}`, {
    params
  })
}
// 考核计划--考核详情信息权限查询（考核动态、考核流程、结果调整记录）
const PERFORMANCEVISIBILITYRANGE = (params) => {
  return req.get(`${apis.performanceVisibilityRange}`, {
    params
  })
}
// 考核计划--考核详情--考核流程
const PERFORMANCEFLOW = (params) => {
  return req.get(`${apis.performanceFlow}`, {
    params
  })
}
// 保存评价位置中上传附件接口
const PERFORMANCEPROCESSSCOREUSERINFODOCUMENT = (params) => {
  return req.post(`${apis.performanceProcessScoreUserInfoDocument}`, params)
}
// 删除评价位置中上传附件接口
const PERFORMANCEPROCESSSCOREUSERINFODOCUMENTDEL = (params) => {
  return req.post(`${apis.performanceProcessScoreUserInfoDocumentDel}`, params)
}
export default [{
    PERFORMANCEPROCESSSCOREUSERINFODOCUMENTDEL
  },
  {
    PERFORMANCEPROCESSSCOREUSERINFODOCUMENT
  }, {
    PERFORMANCEFLOW
  },
  {
    PERFORMANCEVISIBILITYRANGE
  },
  {
    TOTALJUDGESSCORELIST
  },
  {
    TEMPLATEINDEXINDEXMAKELIST
  },
  {
    PFOCESSMEMBERINFO
  },
  {
    SYNCHRONIZEKR
  },
  {
    SYNCPERFORMANCEKRLIST
  },
  {
    SYNCPERFORMANCEKR
  },
  {
    SYNCPERFORMANCECUSTOMINDEXKRCANCEL
  },
  {
    SYNCPERFORMANCECUSTOMINDEXKR
  },
  {
    EXAMINEPOWERGETUSERPOWER
  },
  {
    PERFORMANCEPROCESSLISTOPERATORADJUSTMENT
  },
  {
    ABNORMALGRADEPAGE
  },
  {
    PERFORMANCEPROCESSLISTINFO
  },
  {
    UNDERLINGSUSERLIST
  },
  {
    MEMBERARCHIVESDOWNLOAD
  },
  {
    ADMINDEPARTMENTUSERLIST
  },
  {
    KPIREJECTPROCESSMEMBER
  },
  {
    PERFORMANCEEXAMINEPROCESSSTEPMEMBERREJECT
  },
  {
    PERFORMANCECOMMENTLISTV2
  },
  {
    PERFORMANCERESULTCONFIRM
  },
  {
    TRACKINGABNORMALCOUNT
  },
  {
    PERFORMANCEGRADELIST
  },
  {
    PERFORMANCECLOSE
  },
  {
    EXAMINECYCLE
  },
  {
    SUBMITLASTCONFIRMSTATUS
  },
  {
    SUBMITSELFCONFIRMSTATUS
  },
  {
    RESULTGRADEINFO
  },
  {
    RESULTDEPARTMENTINFO
  },
  {
    RESULTINFOPAGE
  },
  {
    RESULTINFODOWNLOAD
  },
  {
    MEMBERARCHIVESPAGE
  },
  {
    INDEXANALYSISINFO
  },
  {
    INDEXANALYSISINFOPAGE
  },
  {
    INDEXANALYSISDOWNLOAD
  },
  {
    RESULTINFOGRADEPAGE
  },
  {
    PERFORMANCEEXAMINELIST
  },
  {
    EXAMINEGROUPLIST
  },
  {
    ANALYSISQUERYINDEX
  },
  {
    TRACKINGINFOPAGE
  },
  {
    EXAMINEUSERLISTV2
  },
  {
    PERFORMANCEDETAILSV2
  },
  {
    INDUSTRYPAGESLISTV2
  },
  {
    TEMPLATEINDUSTRYDETAILV2
  },
  {
    ADDTEMPLATEINDEXBATCHV2
  },
  {
    GRADEEXAMINELISTV2
  },
  {
    SELECTEXAMINEFIELDV2
  },
  {
    SUBMITSKPIPROCESS
  },
  {
    SUBMITTRANSFER
  },
  {
    SUBMITCOMMUNICATION
  },
  {
    SUBMITRESULTADJUST
  },
  {
    TEMPLATEDIMENSIONDETAILV2
  },
  {
    TEMPLATEDIMENSIONUPDATEV2
  },
  {
    TEMPLATEDIMENSIONSAVEV2
  },
  {
    TEMPLATEFIELDPAGELISTV2
  },
  {
    SAVEEVALUATIONUSERS
  },
  {
    KPIPERFORMANCEUSERLIST
  },
  {
    KPIGRADEINFO
  },
  {
    SUBMITPERFORMANCESCORE
  },
  {
    PERFORMANCEREJECTPROCESSV2
  },
  {
    SUBMITKPIDEAFTCONFIRM
  },
  {
    KPIPROCESSINFOV2
  },
  {
    PROCESSTYPENUMV2
  },
  {
    MYKPILISTV2
  },
  {
    TEMPLATEINDEXGROUPDELETEV2
  },
  {
    TEMPLATEINDEXGROUPUPDATEV2
  },
  {
    TEMPLATEINDEXGROUPSAVEV2
  },
  {
    TEMPLATEINDEXGROUPLISTV2
  },
  {
    TEMPLATEFIELDVALUESSAVEV2
  },
  {
    TEMPLATEFIELDVALUESLISTV2
  },
  {
    TEMPLATEFIELDUPDATEV2
  },
  {
    TEMPLATEFIELDSAVEV2
  },
  {
    TEMPLATEFIELDDELETEV2
  },
  {
    TEMPLATEFIELDDETAILV2
  },
  {
    TEMPLATEFIELDLISTV2
  },
  {
    PERFORMANCEPROCESSSTEPMEMBERREJECTV2
  },
  {
    PERFORMANCEEVALUATIONUESRSAVEV2
  },
  {
    PERFORMANCEEVALUATIONUESRV2
  },
  {
    EXAMINETEMPLATEUSERADDV2
  },
  {
    EXAMINETEMPLATELISTV2
  },
  {
    PERFORMANCECALIBRATIONSTARTV2
  },
  {
    PERFORMANCELISTV2
  },
  {
    PERFORMANCEGRADEADJUSTV2
  },
  {
    PERFORMANCEADJUSTUSERUPDATEV2
  },
  {
    PERFORMANCEADJUSTUSERV2
  },
  {
    PERFORMANCEGRADEDISTRIBUTIONLISTV2
  },
  {
    EXAMINEINFOV2
  },
  {
    PERFORMANCEREJECTV2
  },
  {
    PERFORMANCEPROCESSSTARTV2
  },
  {
    EXAMINELISTV2
  },
  {
    EXAMINELISTCOUNTV2
  },
  {
    EXAMINEREMINDV2
  },
  {
    EXAMINECLOSEV2
  },
  {
    EXAMINECOPYV2
  },
  {
    EXAMINEEXPORTV2
  },
  {
    EXAMINEDELV2
  },
  {
    EXAMINEARCHIVINGV2
  },
  {
    EXAMINESTARTV2
  },
  {
    EXAMINEPROCESSGRADESAVEV2
  },
  {
    EXAMINEPROCESSGRADEGETV2
  },
  {
    EXAMINEPROCESSLASTCONFIRMSAVEV2
  },
  {
    EXAMINEPROCESSSELFCONFIRMSAVEV2
  },
  {
    EXAMINEPROCESSCOMMUNICATIONSAVEV2
  },
  {
    EXAMINEPROCESSCALIBRATIONSAVEV2
  },
  {
    EXAMINEPROCESSMEMBERSAVEV2
  },
  {
    EXAMINEPROCESSSELFSAVEV2
  },
  {
    EXAMINEPROCESSFIELDENTERSAVEV2
  },
  {
    EXAMINEPROCESSINDEXCONFIRMSAVEV2
  },
  {
    EXAMINEPROCESSINDEXMAKESAVEV2
  },
  {
    EXAMINEPROCESSLASTCONFIRMV2
  },
  {
    EXAMINEPROCESSSELFCONFIRMV2
  },
  {
    EXAMINEPROCESSCOMMUNICATIONV2
  },
  {
    EXAMINEPROCESSCALIBRATIONV2
  },
  {
    EXAMINEPROCESSMEMBERV2
  },
  {
    EXAMINEPROCESSSELFV2
  },
  {
    EXAMINEPROCESSFIELDENTERV2
  },
  {
    EXAMINEPROCESSINDEXCONFIRMV2
  },
  {
    EXAMINEPROCESSINDEXMAKEV2
  },
  {
    EXAMINEPROCESSV2
  },
  {
    EXAMINEPROCESSINFOV2
  },
  {
    EXAMINETEMPLATESAVEV2
  },
  {
    EXAMINETEMPLATEDETAILV2
  },
  {
    EXAMINEINFOSAVEV2
  },
  {
    EXAMINEINFOGETV2
  },
  {
    TEMPLATEDETAILV2
  },
  {
    TEMPLATELISTV2
  },
  {
    TEMPLATEDELETEV2
  },
  {
    TEMPLATEUPDATEV2
  },
  {
    TEMPLATESAVEV2
  },
  {
    TEMPLATEGROUPLISTV2
  },
  {
    TEMPLATEGROUPDELETEV2
  },
  {
    TEMPLATEGROUPUPDATEV2
  },
  {
    TEMPLATEGROUPSAVEV2
  },
  {
    EVALUATIONGROUPLISTV2
  },
  {
    EVALUATIONGROUPDELETEV2
  },
  {
    EVALUATIONGROUPUPDATEV2
  },
  {
    EVALUATIONGROUPDETAILV2
  },
  {
    EVALUATIONGROUPSAVEV2
  },
  {
    GRADELISTV2
  },
  {
    GRADEDELETEV2
  },
  {
    GRADEUPDATEV2
  },
  {
    GRADEDETAILV2
  },
  {
    GRADESAVEV2
  },
  {
    TEMPLATEINDEXCOMPUTEDELETEV2
  },
  {
    TEMPLATEINDEXCOMPUTEDETAILV2
  },
  {
    TEMPLATEINDEXCOMPUTEUPDATEV2
  },
  {
    TEMPLATEINDEXCOMPUTESAVEV2
  },
  {
    TEMPLATEINDEXCOMPUTELISTV2
  },
  {
    TEMPLATEINDEXEXPORTSELECTV2
  },
  {
    TEMPLATEINDEXDELETEBATCHV2
  },
  {
    TEMPLATEINDEXEXPORTV2
  },
  {
    TEMPLATEINDEXIMPORTV2
  },
  {
    TEMPLATEINDEXDELETEV2
  },
  {
    TEMPLATEINDEXLISTV2
  },
  {
    TEMPLATEINDEXDETAILV2
  },
  {
    TEMPLATEINDEXUPDATEV2
  },
  {
    TEMPLATEINDEXSAVEDEFV2
  },
  {
    TEMPLATEINDEXSAVEV2
  },
  {
    TEMPLATEINDEXGROUPUPDATEV2
  },
  {
    TEMPLATEINDEXGROUPSAVEV2
  },
  {
    TEMPLATEINDEXGROUPLISTV2
  },
  {
    TEMPLATEFIELDVALUESSAVEV2
  },
  {
    TEMPLATEFIELDVALUESLISTV2
  },
  {
    TEMPLATEFIELDUPDATEV2
  },
  {
    TEMPLATEFIELDSAVEV2
  },
  {
    TEMPLATEFIELDDELETEV2
  },
  {
    TEMPLATEFIELDDETAILV2
  },
  {
    TEMPLATEFIELDLISTV2
  }
]
