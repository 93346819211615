<template>
  <el-popover
    ref="memberCardPopRef"
    v-model="visible"
    placement="right"
    trigger="click"
    popper-class="member-card-popover"
    :visible-arrow="false"
    :disabled="disabled"
    @show="getUserInfo"
  >
    <div class="member-card-pop">
      <div class="member-avatar">
        <el-image
          fit="cover"
          :src="userInfoList.avatar || require('@/assets/imgs/common/avatar.png')"
        />
        <div class="avatar-info-box">
          <div class="avatar-info">
            <span class="avatar-name">
              <wx-open-data :id="userInfoList.username" :nameData="{name:userInfoList.name}" />
            </span>
            <span class="avatar-job">{{ userInfoList.jobTitle }}</span>
          </div>
        </div>
      </div>
      <div class="member-info">
        <div class="member-btns" v-if="clickState===true">
          <!-- <el-tooltip placement="top" :open-delay="300" content="进入个人主页">
                    <Icon icon-class="icon-member_mainpage_hover" class="btn-icon"/>
          </el-tooltip>-->
          <el-tooltip placement="top" :open-delay="300" content="分配任务">
            <Icon icon-class="icon-task_toadd" class="btn-icon" @click="addMemberTask" />
          </el-tooltip>
        </div>
        <div class="member-single-info">
          <div class="info-title">部门</div>
          <div class="info-label">
            <wx-open-data
              v-show="userInfoList.departName"
              :type="2"
              :id="userInfoList.departName"
              :nameData="{name:userInfoList.departName}"
            />
            <span v-show="!userInfoList.departName">- -</span>
          </div>
        </div>
        <!-- <div class="member-single-info">
          <div class="info-title">电话</div>
          <div class="info-label">{{ userInfo.mobile }}</div>
        </div>-->
        <div class="member-single-info">
          <div class="info-title">邮箱</div>
          <div class="info-label">{{ userInfoList.email || '未绑定' }}</div>
        </div>
        <div v-if="isOkr && clickState===true" class="member-btn" @click="toOKRList">查看周期内OKR</div>
      </div>
      <task-add
        v-if="addVisible"
        v-model="addVisible"
        :keyId="addId"
        :status="status"
        :propsAddObj="propsAddObj"
      />
    </div>
    <slot slot="reference"></slot>
  </el-popover>
</template>
<script>
export default {
  props: {
    userId: {
      type: String
    },
    isOkr: {
      type: Boolean,
      default: false
    },
    visibleShow: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clickState: {
      default: true
    }
  },
  data() {
    return {
      userInfoList: {},
      visible: false,
      addVisible: false, // 新增任务
      propsAddObj: {
        title: ''
      }
    }
  },
  watch: {
    visibleShow: {
      handler(val) {
        this.visible = val
      }
    }
  },
  methods: {
    async getUserInfo() {
      if (this.userId) {
        this.$apis
          .USERINFOBYID(this.userId)
          .then((rest) => {
            this.userInfoList = rest.data
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    // 进入个人主页
    toPersonalPage() {
      this.$router.push({
        path: '/mainPage',
        query: { id: this.userId }
      })
    },
    // 分配任务
    addMemberTask() {
      console.log();
      this.userInfoList.name =this.$isQw?this.userInfoList.username:this.userInfoList.name
      this.propsAddObj = this.userInfoList
      this.propsAddObj.todoType = 0 // 0代表 todo页面添加
      this.propsAddObj.readyOnly = false
      this.propsAddObj.title = ''
      this.propsAddObj.formPage = 'memberCard'
      this.propsAddObj.selectBooleans = 1
      this.addVisible = true
      if (this.$refs.memberCardPopRef) {
        this.$refs.memberCardPopRef.doClose()
      }
    },
    // 进入OKR列表
    toOKRList() {
      this.$emit('toOKRList', this.userInfoList)
      if (this.$refs.memberCardPopRef) {
        this.$refs.memberCardPopRef.doClose()
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../assets/styles/common.less';
.member-card-pop {
  .member-avatar {
    width: 300px;
    height: 220px;
    position: relative;
    display: flex;
    align-items: flex-start;
    overflow: hidden;
    &::before {
      content: '';
      display: block;
    }
    img {
      flex-shrink: 0;
      width: 100%;
    }
    .avatar-info-box {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: #fff;
      background: -moz-linear-gradient(
        top,
        rgba(164, 164, 164, 0) 0%,
        rgba(30, 34, 40, 0.62) 100%
      );
      background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, rgba(164, 164, 164, 0)),
        color-stop(100%, rgba(30, 34, 40, 0.62))
      );
      background: -webkit-linear-gradient(
        top,
        rgba(164, 164, 164, 0) 0%,
        rgba(30, 34, 40, 0.62) 100%
      );
      background: -o-linear-gradient(
        top,
        rgba(164, 164, 164, 0) 0%,
        rgba(30, 34, 40, 0.62) 100%
      );
      background: -ms-linear-gradient(
        top,
        rgba(164, 164, 164, 0) 0%,
        rgba(30, 34, 40, 0.62) 100%
      );
      background: linear-gradient(
        to bottom,
        rgba(164, 164, 164, 0) 0%,
        rgba(30, 34, 40, 0.62) 100%
      );
      .avatar-info {
        position: absolute;
        width: 260px;
        left: 20px;
        bottom: 16px;
        .avatar-name {
          font-weight: 500;
          font-size: 20px;
          margin-right: 4px;
        }
        .avatar-job {
          font-size: 10px;
        }
      }
    }
  }
  .member-info {
    padding: 16px 24px;
    .member-btns {
      display: flex;
      margin-bottom: 16px;
      .btn-icon {
        font-size: 28px;
        color: #3a78f6;
        &:hover {
          color: #005eff;
        }
        margin-right: 16px;
        cursor: pointer;
      }
    }
    .member-single-info {
      display: flex;
      line-height: 22px;
      margin-bottom: 16px;
      .info-title {
        width: 30px;
        margin-right: 16px;
        color: #a0a3bd;
        font-size: 13px;
      }
      .info-label {
        color: #444558;
        font-weight: 500;
      }
    }
    .member-btn {
      margin: 0 auto;
      width: 180px;
      height: 40px;
      background: #3a78f6;
      color: #fff;
      font-size: 16px;
      text-align: center;
      line-height: 40px;
      font-weight: 500;
      border-radius: 16px;
      cursor: pointer;
      &:hover {
        background: #005eff;
      }
    }
  }
}
</style>
<style>
.member-card-popover {
  border-radius: 16px;
  border: 0;
  padding: 0;
  overflow: hidden;
  box-shadow: 0px 4px 16px 0px rgba(17, 17, 17, 0.06);
}
</style>
