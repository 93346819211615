var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "invite-people-dialog",
      attrs: {
        visible: _vm.inviteFriendsShowState,
        width: "560px",
        title: "邀请成员加入",
        "before-close": _vm.handleDialogClose,
        "append-to-body": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.inviteFriendsShowState = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "vue-loading",
              rawName: "v-vue-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "invite_friends_code",
          on: {
            click: function ($event) {
              if ($event.target !== $event.currentTarget) return null
              return _vm.handleDialogClose()
            },
          },
        },
        [
          _c("div", { staticClass: "friends_code_box" }, [
            !_vm.onlyAdminApprove && !_vm.loading
              ? _c("div", { staticClass: "invite-card" }, [
                  _c(
                    "div",
                    { ref: "inviteCodeRef", staticClass: "invite-code-card" },
                    [
                      _c("div", { staticClass: "change-code-type" }, [
                        _c("img", {
                          staticClass: "code-icon",
                          attrs: {
                            src: require("@/assets/imgs/header/invite_link.png"),
                          },
                          on: { click: _vm.codeVisibleChange },
                        }),
                      ]),
                      _c("div", { staticClass: "code-card-personal" }, [
                        _c(
                          "div",
                          { staticClass: "person-avatar border-radius-20" },
                          [
                            _c("el-image", {
                              attrs: { src: _vm.userInfo.avatar, fit: "cover" },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "person-info" }, [
                          _c("div", { staticClass: "person-name-info" }, [
                            _c("span", { staticClass: "person-name" }, [
                              _vm._v(_vm._s(_vm.userInfo.name)),
                            ]),
                            _c("span", { staticClass: "person-job" }, [
                              _vm._v(_vm._s(_vm.userInfo.jobTitle)),
                            ]),
                          ]),
                          _c("div", { staticClass: "person-company" }, [
                            _vm._v(_vm._s(_vm.companyInfo.name)),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "code-img-box" }, [
                        _c("img", {
                          staticClass: "code-img",
                          attrs: { src: _vm.invitationBeans.shortUrlQrCode },
                        }),
                      ]),
                      _c("div", { staticClass: "code-info" }, [
                        _vm._v("通过扫描二维码加入企业"),
                      ]),
                      _c("div", [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.codeVisible,
                                expression: "codeVisible",
                              },
                            ],
                            staticClass: "code-btn share-btn",
                            on: { click: _vm.downloadImg },
                          },
                          [_vm._v("保存")]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.codeVisible,
                                expression: "!codeVisible",
                              },
                              {
                                name: "clipboard",
                                rawName: "v-clipboard:copy",
                                value: _vm.invitationBeans.shortUrl,
                                expression: "invitationBeans.shortUrl",
                                arg: "copy",
                              },
                              {
                                name: "clipboard",
                                rawName: "v-clipboard:success",
                                value: _vm.onCopy,
                                expression: "onCopy",
                                arg: "success",
                              },
                              {
                                name: "clipboard",
                                rawName: "v-clipboard:error",
                                value: _vm.onError,
                                expression: "onError",
                                arg: "error",
                              },
                            ],
                            staticClass: "code-btn share-btn",
                          },
                          [_vm._v("复制")]
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      ref: "inviteLinkRef",
                      staticClass: "invite-code-card link-card",
                    },
                    [
                      _c("div", { staticClass: "change-code-type" }, [
                        _c("img", {
                          staticClass: "code-icon",
                          attrs: {
                            src: require("@/assets/imgs/header/invite_code.png"),
                          },
                          on: { click: _vm.codeVisibleChange },
                        }),
                      ]),
                      _c("div", { staticClass: "code-card-personal" }, [
                        _c(
                          "div",
                          { staticClass: "person-avatar border-radius-20" },
                          [
                            _c("el-image", {
                              attrs: { src: _vm.userInfo.avatar, fit: "cover" },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "person-info" }, [
                          _c("div", { staticClass: "person-name-info" }, [
                            _c("span", { staticClass: "person-name" }, [
                              _vm._v(_vm._s(_vm.userInfo.name)),
                            ]),
                            _c("span", { staticClass: "person-job" }, [
                              _vm._v(_vm._s(_vm.userInfo.jobTitle)),
                            ]),
                          ]),
                          _c("div", { staticClass: "person-company" }, [
                            _vm._v(_vm._s(_vm.companyInfo.name)),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "code-link" }, [
                        _c("div", { staticClass: "code-link-text" }, [
                          _vm._v(
                            "我和“" +
                              _vm._s(_vm.invitationBeans.companyName) +
                              "”的小伙伴都在源目标等你，"
                          ),
                        ]),
                        _c("div", { staticClass: "code-link-text" }, [
                          _vm._v("用这个专属链接加入我们吧！"),
                        ]),
                        _c("div", { staticClass: "code-link-text" }, [
                          _vm._v(_vm._s(_vm.invitationBeans.shortUrl)),
                        ]),
                      ]),
                      _c("div", [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "clipboard",
                                rawName: "v-clipboard:copy",
                                value: _vm.invitationBeans.shortUrl,
                                expression: "invitationBeans.shortUrl",
                                arg: "copy",
                              },
                              {
                                name: "clipboard",
                                rawName: "v-clipboard:success",
                                value: _vm.onCopy,
                                expression: "onCopy",
                                arg: "success",
                              },
                              {
                                name: "clipboard",
                                rawName: "v-clipboard:error",
                                value: _vm.onError,
                                expression: "onError",
                                arg: "error",
                              },
                            ],
                            staticClass: "code-btn share-btn",
                          },
                          [_vm._v("复制")]
                        ),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm.onlyAdminApprove && !_vm.loading
              ? _c(
                  "div",
                  { staticClass: "friends_code_approve_admin" },
                  [
                    _c("Icon", {
                      staticClass: "svg-img",
                      attrs: { "icon-class": "icon-zhongzhi" },
                    }),
                    _vm._v("仅允许管理员邀请哦 "),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }