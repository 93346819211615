const state = {
  okrWriteRules: [],
  mapStyleData: [],
  deleteData: {},
  isChangeKr: true,
  approveRemindObj: {
    visible: false,
    objDetail: {},
    businessData: '',
    businessActionType: 2 // 业务当次动作类型 : 1:创建okr 2：变更okr(内容变更:) 3: 删除okr 4 ：变更okr(修改对齐)  5：变更okr(移动目标)  6：变更okr(转让目标)
  }
}
const actions = {
  // 修改展开收起kr开关
  _changeIsChangeKr(context, val) {
    context.commit('changeIsChangeKr', val)
  },
  // 填写建议
  _okrWriteRules(context, val) {
    context.commit('okrWriteRules', val)
  },
  // 添加地图卡片高度数据
  _setMapStyleData(context, val) {
    context.commit('setMapStyleData', val)
  },
  // 修改地图卡片高度数据
  _changeMapStyleData(context, val) {
    context.commit('changeMapStyleData', val)
  },
  // 删除地图卡片高度数据
  _deleteMapStyleData(context, val) {
    context.commit('deleteMapStyleData', val)
  },
  // 清空地图卡片数据
  _clearMapStyleData(context, val) {
    context.commit('clearMapStyleData', val)
  },
  // 展开kr修改地图卡片高度
  _openKrMapStyleData(context, val) {
    context.commit('openKrMapStyleData', val)
  },
  // 收起kr修改地图卡片高度
  _closeKrMapStyleData(context, val) {
    context.commit('closeKrMapStyleData', val)
  }
}
const mutations = {
  changeIsChangeKr(state, val) {
    state.isChangeKr = val
  },
  okrWriteRules(state, val) {
    state.okrWriteRules = val
  },
  changeMapStyleData(state, { levelId, cardUuid, top }) {
    state.mapStyleData.forEach((item) => {
      if (item.id === levelId) {
        item.list.forEach((item2) => {
          if (item2.cardUuid === cardUuid) {
            item2.top = top
          }
        })
      }
    })
  },
  setMapStyleData(state, val) {
    const arr = []
    state.mapStyleData.forEach((item) => {
      arr.push(item.id)
    })
    if (!arr.includes(val.id)) {
      state.mapStyleData.push(val)
    } else {
      state.mapStyleData.forEach((item) => {
        if (item.id === val.id) {
          item.list = val.list
        }
      })
    }
  },
  deleteMapStyleData(state, { data, isLeft }) {
    // data是list最后一个的角标 0说明有一条数据，1 说明有两条
    // state.deleteData = data
    if (isLeft) {
      for (let i = 0; i < state.mapStyleData.length; i++) {
        if (state.mapStyleData[i].id < -data - 1) {
          state.mapStyleData.splice(i, 1)
          i = i - 1
        }
      }
    } else {
      for (let i = 0; i < state.mapStyleData.length; i++) {
        if (state.mapStyleData[i].id > data) {
          state.mapStyleData.splice(i, 1)
          i = i - 1
        }
      }
    }
  },
  clearMapStyleData(state, val) {
    // 清除除了第一组以外的数据
    if (val === 1) {
      state.mapStyleData = [state.mapStyleData[0]]
    } else {
      state.mapStyleData = []
    }
  },
  openKrMapStyleData(state, val) {
    state.mapStyleData.forEach((item) => {
      item.list.forEach((item2, index2) => {
        item2.top = item2.top + 150 * index2
      })
    })
  },
  closeKrMapStyleData(state, val) {
    state.mapStyleData.forEach((item) => {
      item.list.forEach((item2, index2) => {
        item2.top = item2.top - 150 * index2
      })
    })
  },
  approveRemindObj(state, val) {
    state.approveRemindObj = val
  }
}
export default {
  namespaced: true,
  state,
  actions,
  mutations
}
