<template>
  <el-dialog
    :visible.sync="centerDialogVisible"
    :width="width"
    :top="top"
    :append-to-body="true"
    :close-on-click-modal="true"
    :destroy-on-close="true"
    class="single-dialog"
    :show-close.sync="showClo"
    v-on="$listeners"
  >
    <div slot="title" class="dialog-title-box">
      <div class="flex-a-c-center-one">
        <p class="title-txt" v-text="title"></p>
      </div>
    </div>
    <div class="home_box">
      <el-form
        ref="moblieEmails"
        label-width="100px"
        :model="form"
        :rules="rule"
      >
        <el-form-item label="联系方式:" prop="validateTellEmail">
          <el-input
            v-model="form.validateTellEmail"
            type="text"
            placeholder="手机号或邮箱"
            class="el_inputs"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label>
          <p>留下你的手机号或邮箱，方便工作人员与你取得联系。</p>
        </el-form-item>
        <el-form-item label="您的反馈:" prop="moblieFree">
          <el-input
            v-model.lazy="form.moblieFree"
            type="textarea"
            :rows="7"
            placeholder="请留下你的宝贵意见，帮助我们改进。带给你更好的体验。"
            class="el_inputs"
            autocomplete="off"
            maxlength="151"
            @input="inputBack"
          />
        </el-form-item>
        <el-form-item label>
          <p class="free-p">
            您还可以前往
            <span @click="goToLearn">学习中心</span>或
            <span @click="goToHelp">帮助和常见问题</span
            >页面，或许能帮助您解决问题。
          </p>
        </el-form-item>
        <button
          type="button"
          class="register_next common_btn_next common_btn_next_able"
          @click="create_enterprise_btn('moblieEmails')"
        >
          提交
        </button>
      </el-form>
    </div>
    <div slot="footer" name="footer">
      <slot name="footer"></slot>
    </div>
  </el-dialog>
</template>

<script>
/* eslint-disable */
import formMixin from '@/utils/mixins/form'
export default {
  mixins: [formMixin],
  data() {
    // 手机号码
    var resTell = new RegExp(/^1(3|4|5|6|7|8|9)\d{9}$/)
    // 邮箱
    var resEmail = new RegExp(
      /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
    )
    var validateTellEmail = (rule, value, callback) => {
      if (!value || value.replace(/\s*/g, '').length == 0) {
        return callback(new Error('不能为空!'))
      }
      setTimeout(() => {
        if (value.indexOf('@') == -1) {
          if (!resTell.test(value)) {
            return callback(new Error('手机号码格式有误!'))
          } else {
            callback()
          }
        } else {
          if (!resEmail.test(value)) {
            return callback(new Error('邮箱格式有误!'))
          } else {
            callback()
          }
        }
      }, 1000)
    }
    var moblieFree = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('不能为空!'))
      } else {
        callback()
      }
    }
    return {
      // 标题
      title: '意见反馈',
      // icon
      icon: '',
      // 宽度
      width: '640px',
      // 是否追加到body上
      appendToBody: false,
      // 是否显示取消按钮图标
      showClo: true,
      //关闭图层
      centerDialogVisible: true,
      // 距离顶部高度
      top: '15vh',
      form: {
        //表单数据验证
        validateTellEmail: '',
        moblieFree: ''
      },
      // 校验规则
      rule: {
        // 校验密码，主要通过validator来指定验证器名称
        validateTellEmail: [
          { required: true, validator: validateTellEmail, trigger: 'blur' }
        ],
        moblieFree: [{ required: true, validator: moblieFree, trigger: 'blur' }]
      }
    }
  },
  computed: {},
  created() {
    this.$store.commit('common/apiRequestData', {
      visible: false,
      type: 0,
      companyName: '',
      list: {
        expireDate: '',
        grantCount: ''
      }
    })
  },
  methods: {
    create_enterprise_btn(formName) {
      var that = this
      that.$refs[formName].validate((valid) => {
        if (valid) {
          //调用后台接口传输数据======================================================================
          let _obj = {}
          _obj.contact = this.form.validateTellEmail //手机号或邮箱
          _obj.content = this.form.moblieFree //意见;
          that.submitFree(_obj)
        }
      })
    },
    //提交后台
    submitFree(_obj) {
      var that = this
      this.$apis.HEADERSUBMITFEEDBACK(_obj).then((res) => {
        if (res.status == 200) {
          that.$message.success('提交成功!')
          that.$emit('closeFreeBack')
        } else {
          that.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    //字数超出显示提示
    inputBack() {
      let message_ = this.form.moblieFree.replace(/\s*/g, '') || ''
      if (message_.replace(/\s*/g, '').length > 150) {
        this.$message({
          msg: '你的宝贵意见最多可以输入 150 个字符哦!',
          type: 'warn'
        })
        this.form.moblieFree = message_.substring(0, 150)
      }
    },
    //页面跳转
    goToLearn() {
      var that = this
      that.$emit('closeFreeBack')
      that.$router.push('/feedBack/okr/whatOkr')
    },
    goToHelp() {
      var that = this
      that.$emit('closeFreeBack')
      that.$router.push('/feedBack/help')
    }
  }
}
</script>

<style lang="scss" scoped>
.title-txt {
  text-align: center;
}
.home_box {
  .free-p {
    span {
      color: #3068ff;
      cursor: pointer;
    }
  }
}
.register_next {
  width: 30% !important;
  display: block;
  margin: 0 auto;
}
</style>
<style lang="scss">
.el_inputs .el-input__count {
  background: rgba(243, 243, 245, 0.6);
}
</style>
