var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "advertisement-wrap" }, [
    _vm.styleType === 2
      ? _c("div", { staticClass: "title-wrap", on: { click: _vm.openVideo } }, [
          _c("img", {
            staticClass: "icon",
            attrs: { src: require(`@/assets/imgs/kpi/videoIcon.png`) },
          }),
          _c("div", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.videoImgList[_vm.type])),
          ]),
        ])
      : _c("div", { staticClass: "img-style-wrap" }, [
          _c("div", { staticClass: "img", on: { click: _vm.openVideo } }, [
            _c("img", { attrs: { src: _vm.videoImgList[_vm.type], alt: "" } }),
          ]),
          _c(
            "div",
            {
              staticClass: "close-btn",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c("Icon", {
                attrs: { "icon-class": "icon-close" },
                nativeOn: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.close.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
        ]),
    _vm.videoDialogShow
      ? _c(
          "div",
          [
            _c("look-add-okr", {
              attrs: {
                videoUrl: _vm.videoUrl,
                videoName: _vm.videoName,
                recordTime: true,
                "video-dialog-show": _vm.videoDialogShow,
              },
              on: { closeVideoDialog: _vm.closeVideoDialog },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }