export default [
  {
  path: '/conclusion',
  name: 'conclusion',
  component: () => {
    return import(
      /* webpackChunkName: "conclusionIndex" */
      '../../views/conclusion'
    )
  }
},
{
  path: '/conclusion/board', // 我需要提交
  name: 'conclusionBoard',
  component: () => {
    return import(
      /* webpackChunkName: "conclusionBoard" */
      '../../views/conclusion/conclusionBoard'
    )
  }
},
{
  path: '/conclusion/moudle', // 模板管理
  name: 'conclusionMoudle',
  component: () => {
    return import(
      /* webpackChunkName: "conclusionMoudle" */
      '../../views/conclusion/conclusionMoudle'
    )
  }
},
{
  path: '/conclusion/moudleDetail', // 模板详情
  name: 'conclusionMoudleDetail',
  component: () => {
    return import(
      /* webpackChunkName: "conclusionMoudleDetail" */
      '../../views/conclusion/moudleDetail'
    )
  }
},
{
  path: '/conclusion/set', // 总结设置
  name: 'conclusionSet',
  component: () => {
    return import(
      /* webpackChunkName: "conclusionSet" */
      '../../views/conclusion/conclusionSet'
    )
  }
},
{
  path: '/conclusion/add', // 写总结
  name: 'conclusionAdd',
  component: () => {
    return import(
      /* webpackChunkName: "conclusionAdd" */
      '../../views/conclusion/addConclusion'
    )
  }
},
{
  path: '/conclusion/analyse', // 统计
  name: 'conclusionAnalyse',
  component: () => {
    return import(
      /* webpackChunkName: "conclusionAnalyse" */
      '../../views/conclusion/conclusionAnalyse'
    )
  }
},
{
  path: '/conclusion/setting', // 设置
  name: 'conclusionSetting',
  component: () => {
    return import(
      /* webpackChunkName: "conclusionAdd" */
      '../../views/conclusion/setting'
    )
  }
}
]
