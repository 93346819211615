const versionNumber = 'v1' // 版本
// 工作台模块api
export default [
  // 查看绩效中考核数量，待处理数量
  {
    kpiQueryCount: `/api/kpi/${versionNumber}/performance/count/process`
  },
  // 考核模板列表
  {
    kpiMoudleList: `/api/kpi/${versionNumber}/template/list`
  },
  // 获取考核模板内考核范围
  {
    kpiMoudleScopeList: `/api/kpi/${versionNumber}/template/user/scope/list`
  },
  // 添加考核
  {
    kpiAddPerformance: `/api/kpi/${versionNumber}/performance/add`
  },
  // 查询考核管理-考核列表
  {
    kpiPerformanceList: `/api/kpi/${versionNumber}/performance/list/page`
  },
  // 重新发起考核
  {
    kpiRestartPerformance: `/api/kpi/${versionNumber}/performance/restart/`
  },
  // 终止考核
  {
    kpiStopPerformance: `/api/kpi/${versionNumber}/performance/stop/`
  },
  // 删除考核
  {
    kpiDelPerformance: `/api/kpi/${versionNumber}/performance/delete/`
  },
  // 查询我的考核-考核列表
  {
    kpiMyPerformanceList: `/api/kpi/${versionNumber}/performance/process/list/page`
  },
  // 我的考核-各流程数量
  {
    kpiMyPerformanceCount: `/api/kpi/${versionNumber}/performance/process/my/statistics`
  },
  // 提醒ta
  {
    kpiNoticeTa: `/api/kpi/${versionNumber}/performance/process/score/notice`
  },
  // 获取个人考核详情
  {
    kpiUserPerformance: `/api/kpi/${versionNumber}/performance/user/process/list/`
  },
  // 查询模板的考核流程
  {
    kpiMoudleProcess: `/api/kpi/${versionNumber}/template/process`
  },
  // 查询模板的考核指标
  {
    kpiMoudleDimension: `/api/kpi/${versionNumber}/performance/user/dimension/`
  },
  // 获取考核中单个流程得分
  {
    kpiPerformanceStepScore: `/api/kpi/${versionNumber}/performance/user/dimension/score`
  },
  // 考核中单个流程权限信息
  {
    kpiPerformanceStepInfo: `/api/kpi/${versionNumber}/performance/process/process/set/info/list`
  },
  // 考核中自评插入OKR
  {
    kpiInsertOkr: `/api/kpi/${versionNumber}/dimension/objectives`
  },
  // 考核中自评删除OKR
  {
    kpiDelOkr: `/api/kpi/${versionNumber}/dimension/objectives/`
  },
  // 考核中更新自评OKR权重
  {
    kpiUpdateOkr: `/api/kpi/${versionNumber}/dimension/objectives/update/weight`
  },
  // 重新发起个人考核流程
  {
    kpiRestartUserPerformance: `/api/kpi/${versionNumber}/performance/user/restart`
  },
  // 驳回当前考核流程
  {
    kpiRejectPerformance: `/api/kpi/${versionNumber}/performance/process/info/score/disagree`
  },
  // 考核-邀请同事打分
  {
    kpiInviteWorker: `/api/kpi/${versionNumber}/performance/process/update/score/invitation`
  },
  // 考核-删除邀请同事
  {
    kpiDelInviteWorker: `/api/kpi/${versionNumber}/performance/process/delete/score/invitation/`
  },
  // 提交打分
  {
    kpiSaveScore: `/api/kpi/${versionNumber}/performance/process/info/score/dimension`
  },
  // 个人考核-审核通过
  {
    kpiPassPerformance: `/api/kpi/${versionNumber}/performance/process/info/score/agree`
  },
  // 查询个人考核驳回信息
  {
    kpiRejectInfo: `/api/kpi/${versionNumber}/performance/user/turn/down/info`
  },
  // 邀请处理-我的处理列表
  {
    kpiMyHandleList: `/api/kpi/${versionNumber}/performance/process/todo/list`
  },
  // 公开考核列表
  {
    kpiPublicPerformance: `/api/kpi/${versionNumber}/performance/public/list/page`
  },
  // 整场考核信息
  {
    kpiPerformanceInfo: `/api/kpi/${versionNumber}/performance/info/`
  },
  // 整场考核-人员考核列表
  {
    kpiPerformanceInfoList: `/api/kpi/${versionNumber}/performance/user/list/page/performance/`
  },
  // 获取模板内等级信息列表
  {
    kpiMoudleGradeList: `/api/kpi/${versionNumber}/scorerules/template/`
  },
  // 整场考核-添加/删除考核人员
  {
    kpiDelPerformanceUser: `/api/kpi/${versionNumber}/performance/user`
  },
  // 公示考核结果
  {
    kpiPublicResult: `/api/kpi/${versionNumber}/performance/update/publicity`
  },
  // 启用/停用考核模板
  {
    kpiStopOrStartMoudle: `/api/kpi/${versionNumber}/template/status`
  },
  // 添加/删除/修改考核模板
  {
    kpiMoudle: `/api/kpi/${versionNumber}/template/`
  },
  // 获取打分规则详情
  {
    kpiRuleDetail: `/api/kpi/${versionNumber}/scorerules`
  },
  // 查询打分规则列表
  {
    kpiRuleList: `/api/kpi/${versionNumber}/scorerules/list`
  },
  // 获取指标模板类型
  {
    kpiIndexTemplateType: `/api/kpi/${versionNumber}/dimension/examples/type`
  },
  // 获取指标模板列表
  {
    kpiIndexTemplateList: `/api/kpi/${versionNumber}/dimension/examples/list`
  },
  // 获取指标模板详情
  {
    kpiIndexTemplateDetail: `/api/kpi/${versionNumber}/dimension/indicator/examples/list`
  },
  // 获取考核默认流程
  {
    kpiDefaultStepList: `/api/kpi/process/examples/list`
  },
  // 获取考核模板详情-指标
  {
    kpiMoudleIndex: `/api/kpi/${versionNumber}/template/dimension`
  }
]
