// axios二次封装
import axios from 'axios' // 引入 axios
import baseUrl from './env' // 基础url
import Message from '../components/global/Message' // 全局信息提示
import code from './state_code' // 状态码维护
import store from '@/store'
import route from '@/router'
// import headerSign from './headerSign' // 头部加密签名
const service = axios.create({
  validateStatus(status) {
    return status >= 200 && status <= 504 // 合法状态码
  },
  baseURL: window.location.href.includes('kpiV2')
    ? ''
    : baseUrl, // 基础请求路径
  // baseURL: baseUrl,
  timeout: 30000 // 请求超时
})
// 重复尝试此时
service.defaults.retry = 3
// 重试延时
service.defaults.retryDelay = 15000
// 开启重试
service.defaults.shouldRetry = true

// 请求拦截
service.interceptors.request.use(
  (config) => {
    config.headers['Content-Type'] = 'application/json;charset=UTF-8'
    config.headers['Accept-Language'] = 'zh-CN'
    // token
    config.headers['Authorization'] = store.state.user.token
    if (config.method === 'post') {
      if (!config.data) {
        config.data = {}
      }
    }
    // 签名
    // var params = ''
    // if (config.method === 'post' || config.method === 'put') {
    //   params = config.data
    // } else {
    //   params = config.params
    // }
    // var signatureGenerateData = headerSign.signatureGenerate(
    //   params,
    //   config.url,
    //   config.method
    // )
    // // 分别将签名、密钥、时间戳 至请求头
    // if (signatureGenerateData.signature) {
    //   config.headers['sign'] = signatureGenerateData.signature
    // }
    // if (signatureGenerateData.timestamp) {
    //   config.headers['ts'] = signatureGenerateData.timestamp
    // }
    return config
  },
  (error) => {
    const {
      response: { status, statusText }
    } = error
    Message({
      code: status,
      msg: statusText,
      type: 'error'
    })
  }
)
// response 拦截器
service.interceptors.response.use(
  (response) => {
    return new Promise((resolve, reject) => {
      if (
        store.state.user.userInfo === '' ||
        store.state.user.companyInfo === ''
      ) {
        // 兼容企微复制浏览器没有用户和企业信息
        var $isQw =
          window.location.host.includes('qywx') ||
          window.location.host.includes('qw')
        if ($isQw) {
          route.replace({
            path: '/login'
          })
        }
      }
      if (response.status !== 200) {
        // 请求失败
        reject(response)
      } else {
        // 判断token有效
        const TOKENSTATE = code.TOKEN_INVLIDE_TO_LOGIN(response.data.status)
        // 全局错误拦截
        const PUBSTATE = code.PUB_ERR(
          response.data.status,
          response.data.message,
          response.config
        )
        // 请求成功，但逻辑或者业务有错，返回具体数据，根据业务决定是否要提示 有token失效全局异常不返回
        if (TOKENSTATE && PUBSTATE) {
          if (
            (response.headers['content-type'] ===
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
              response.headers['content-type'] ===
                'application/octet-stream;charset=utf-8') &&
            response.config.url.indexOf('/batchDownLoad/file') !== -1
          ) {
            const location = response.headers['location']
            resolve({
              data: response.data,
              location
            })
          } else {
            resolve(response.data)
          }
        }
      }
    }).catch((res) => {
      if (res.data.path === '/api/initialize/home/jspApiConfig') {
        Message.error(res.data.message)
      } else {
        Message.error('请求失败！')
      }
    })
  },
  (err, data) => {
    // 失败
    let message = '请求异常,请检测网络!'
    if (err.response) {
      message = err.response.data.message
    }
    Message.error(message)
    return err && err.message === 'Network Error' ? '请求异常,请检测网络!' : err
  }
)

export default service
