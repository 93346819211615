/* eslint-disable */
import dd from 'dingtalk-jsapi'
export default {
  methods: {
    getJsapiTicketAgent() {
      this.$apis.GETJSAPICONFIG({
        corpId: this.commonCorpId,
        url: `https://${window.location.host}/`
      }).then((res) => {
        console.log('获取签名成功', res.data)
        if (res.status === 200) {
          this.getDdConfig(res.data)
        } else {
          this.$message.error(res.message)
        }
      }).catch((err) => {
        console.log('获取签名失败', err)
      })
    },
    // jsapi鉴权
    getDdConfig(data) {
      var that = this
      dd.config({
        agentId: data.agentId, // 必填，微应用ID
        corpId: data.corpId, //必填，企业ID
        timeStamp: data.timeStamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，自定义固定字符串。
        signature: data.signature, // 必填，签名
        type: 0, //选填。0表示微应用的jsapi,1表示服务窗的jsapi；不填默认为0。该参数从dingtalk.js的0.8.3版本开始支持
        jsApiList: data.jsApiList // 必填，需要使用的jsapi列表，注意：不要带dd。
      }).then((res) => {
        console.log('jsapi鉴权成功', res)
      }).catch((err) => {
        console.log('jsapi鉴权失败', err)
      })
      dd.error(function (err) {
        console.log('ddError', err)
        that.$message.error(JSON.stringify(err))
      }) //该方法必须带上，用来捕获鉴权出现的异常信息，否则不方便排查出现的问题
    }
  }
}
