const versionNumber = 'v1' // 版本
const versionNumber2 = 'v2' // 版本
// admin 后台管理
export default [
  {
  // 获取公司版本
  adminGetCompanyVersion: `/api/admin/${versionNumber}/company/version`
},
{
  // 获取 满意度字典数据
  satisfactionDictionary: `/api/admin/${versionNumber}/user/satisfaction/dictionary`
},
{
  // 提交满意度
  satisfactionSave: `/api/admin/${versionNumber}/user/satisfaction/save`
},
{
  // 版本升级提交
  adminGetVersionSave: `/api/admin/${versionNumber}/consult/`
},
{
  // 企业信息修改提交
  adminCompanyUpdateInfo: `/api/admin/${versionNumber}/company/udpateInfo`
},
{
  // 行业信息一级
  adminDictionaryListOne: `/api/admin/${versionNumber}/company/`
},
{
  // 行业信息二级
  adminDictionaryListTwo: `/api/ranloWeb/${versionNumber}/dictionary/list/industry`
},
{
  // 企业规模人数
  adminDictionaryUserCount: `/api/ranloWeb/${versionNumber}/dictionary/list/user/count`
},
{
  // 企业logo图片上传
  adminCompanyUpdateLogo: `/api/admin/${versionNumber}/company/updateLogo`
},
{
  // 周期停用
  adminSetUpCycle: `/api/ranloWeb/${versionNumber}/setcycle/setUpCycle`
},
{
  // 周期删除
  adminCycleDelete: `/api/ranloWeb/${versionNumber}/setcycle/delete`
},
{
  // 获取周期列表
  adminGetCycleList: `/api/ranloWeb/${versionNumber}/setcycle/getCycleInfoList`
},
{
  // 新建周期
  adminAddCycle: `/api/ranloWeb/${versionNumber}/setcycle/addCycle`
},
{
  // 提交引导步骤
  commitProcess: `/api/admin/${versionNumber}/guidance/commitProcess/1`
},
{
  // 获取订单列表
  adminGetOrderList: `/api/admin/${versionNumber}/order/list`
},
{
  // 获取管理员列表
  adminGetAdminList: `/api/admin/${versionNumber}/user/selectAdminUsers`
},
{
  // 获取成员列表ById
  adminGetMemListById: `/api/admin/${versionNumber}/depart/departsUsers/`
},
{
  // 获取成员列表左侧
  adminGetMemListLeft: `/api/admin/${versionNumber}/depart/depth/departsUsers/-1`
},
{
  // 获取所有成员
  adminGetMemListAll: `/api/admin/${versionNumber}/user/companyUsers`
},
{
  // 获取待申请加入成员
  adminGetApprovelist: `/api/admin/${versionNumber}/company/invitations/approve/list`
},
{
  // 获取搜索人员byid
  adminGetSearchInfoById: `/api/admin/${versionNumber}/user/`
},
{
  // 人员同意拒绝加入
  adminSetInviteApprove: `/api/admin/${versionNumber}/company/invitations/approve/`
},
{
  // 人员同意拒绝加入
  adminGetInviteApprove: `/api/admin/${versionNumber}/company/invitations/approve/`
},
{
  // 获取子部门
  subDepartById: `/api/admin/${versionNumber}/depart/departsUsers/`
},
{
  // 删除管理员
  delAdmin: `/api/admin/${versionNumber}/company/updateAdmin`
},
{
  // 获取部门人员个数
  getDepartNum: `/api/admin/${versionNumber}/depart/userCount/`
},
{
  // 人员更新部门
  departMoveUser: `/api/admin/${versionNumber}/depart/batch/move/user`
},
{
  // 删除部门
  delDepart: `/api/admin/${versionNumber}/depart/remove/`
},
{
  // 用户停用
  companyUserDisable: `/api/admin/${versionNumber}/company/user/disable`
},
{
  // 删除部分成员
  departRemoveUser: `/api/admin/${versionNumber}/depart/remove/user`
},
{
  // 删除人员转移权限
  companyDimission: `/api/admin/${versionNumber}/company/dimission/`
},
{
  // 新增部门
  addDepart: `/api/admin/${versionNumber}/depart/add`
},
{
  // 编辑部门
  editDepart: `/api/admin/${versionNumber}/depart/update`
},
{
  // 修改部门主管
  departUpdateAdmin: `/api/admin/${versionNumber}/depart/updateAdmin`
},
{
  // 邀请设置
  companyInviteSet: `/api/admin/${versionNumber}/company/invitations/set/`
},
{
  // 邀请设置提交
  companyInviteAdd: `/api/admin/${versionNumber}/company/invitations/set/add`
},
{
  // 获取全部部门层级
  departAll: `/api/admin/${versionNumber}/depart/tree/-1`
},
{
  // 获取全部部门层级用户
  departAllUser: `/api/admin/${versionNumber}/depart/departsUsers/-1`
},
{
  // 搜索部门byid
  departById: `/api/admin/${versionNumber}/depart/tree/`
},
{
  // 退出企业
  companyQuit: `/api/admin/${versionNumber}/company/user/quit`
},
{
  // 移交企业所属人
  transferOwner: `/api/admin/${versionNumber}/company/transfer`
},
{
  // 删除企业
  delCompany: `/api/admin/${versionNumber}/company/delete/`
},
{
  // 获取部门和人（更改上级时使用）
  departUser: `/api/admin/${versionNumber}/depart/tree/list`
},
{
  // 更新上级
  updateSuperior: `/api/admin/${versionNumber}/user/superior`
},
{
  // 改变设置公共方法
  notifySet: `/api/notify/${versionNumber}/notify/set/user/`
},
{
  // 获取通知类型列表
  notifySetList: `/api/notify/${versionNumber}/notify/set/user/list`
},
{
  // 查询周期可见范围
  getCycleRange: `/api/ranloWeb/${versionNumber}/setcycle/select/cycle/user`
},
{
  // 设置周期可见范围
  setCycleRange: `/api/ranloWeb/${versionNumber}/setcycle/update/cycle/range`
},
{
  // 设置周期可见范围
  setCyclePerson: `/api/ranloWeb/${versionNumber}/setcycle/update/cycle/user`
},
{
  // 设置周期成员
  getCyclePerson: `/api/ranloWeb/${versionNumber}/setcycle/update/cycle/user`
},
{
  // 设置周期成员权限
  getCyclePersonPermission: `/api/ranloWeb/${versionNumber}/setcycle/update/user/power`
},
{
  // 查询周期提醒设置列表
  cycleSetRemindList: `/api/ranloWeb/${versionNumber}/setcycle/notify/list`
},
{
  // 周期提醒设置
  cycleSetRemind: `/api/ranloWeb/${versionNumber}/setcycle/notify/update`
},
{
  // 获取角色列表
  allRoleList: `/api/admin/${versionNumber}/group/list`
},
{
  // 新增角色
  roleAddItem: `/api/admin/${versionNumber}/group/add`
},
{
  // 修改角色
  roleEditItem: `/api/admin/${versionNumber}/group/update`
},
{
  // 删除角色
  roleDelItem: `/api/admin/${versionNumber}/group/remove/`
},
{
  // 批量删除角色
  roleBatchDel: `/api/admin/${versionNumber}/group/batch/remove`
},
{
  // 修改角色下成员列表
  roleUpdateMember: `/api/admin/${versionNumber}/group/member/change`
},
{
  // 查询角色下成员列表
  roleMemberList: `/api/admin/${versionNumber}/group/user/list`
},
{
  // 获取当前企业优先级配置
  priorityList: `/api/ranloTask/${versionNumber}/priority/getPriorityList`
},
{
  // 优先级保存
  savePriority: `/api/ranloTask/${versionNumber}/priority/save`
},
{
  // 查看优先级列表任务总数
  taskcount: `/api/ranloTask/${versionNumber}/priority/taskcount`
},
{
  // 获取标签列表
  labelList: `/api/ranloTask/${versionNumber}/label`
},
{
  // 新建标签分组
  addLabelGroup: `/api/ranloTask/${versionNumber}/label/group`
},
{
  // 修改标签分组
  changeLabelGroup: `/api/ranloTask/${versionNumber}/label/group`
},
{
  // 删除标签分组
  deleteLabelGroup: `/api/ranloTask/${versionNumber}/label/group/`
},
{
  // 删除标签
  deleteLabel: `/api/ranloTask/${versionNumber}/label/`
},
{
  // 新建标签
  addLabel: `/api/ranloTask/${versionNumber}/label`
},
{
  // 标签详情
  labelInfo: `/api/ranloTask/${versionNumber}/label/`
},
{
  // 修改标签
  changeLabel: `/api/ranloTask/${versionNumber}/label`
},
{
  // 查询任务分类列表-企业
  categoriesCompany: `/api/project/${versionNumber}/fields/categories/company/list`
},
{
  // 查询任务分类列表-项目
  categoriesProject: `/api/project/${versionNumber}/fields/categories/project/list`
},
{
  // 查询分类字段详情
  categoriesDetail: `/api/project/${versionNumber}/fields/categories/field/info/details`
},
{
  // 修改分类
  editCategories: `/api/project/${versionNumber}/fields/company/categories/`
},
{
  // 任务字段分类编辑
  editProjectCategories: `/api/project/${versionNumber}/fields/categories/`
},
{
  // 分类详情字段保存
  saveCategoriesDetail: `/api/project/${versionNumber}/fields/categories/field/info/details/save`
},
{
  // 查询字段分组列表
  fieldGroups: `/api/ranloTask/${versionNumber}/fields/groups/list`
},
{
  // 自定义字段搜索
  fieldSearchList: `/api/ranloTask/${versionNumber}/fields/info/list`
},
{
  // 字段分类列表（含字段）
  fieldGroupTree: `/api/ranloTask/${versionNumber}/fields/groups/list/fields`
},
{
  // 新建分组
  addFieldGroup: `/api/ranloTask/${versionNumber}/fields/groups/save`
},
{
  // 修改分组
  updateFieldGroup: `/api/ranloTask/${versionNumber}/fields/groups/update`
},
{
  // 删除分组
  delFieldGroup: `/api/ranloTask/${versionNumber}/fields/groups/`
},
{
  // 添加自定义字段
  addCustomField: `/api/ranloTask/${versionNumber}/fields/info/save`
},
{
  // 修改自定义字段
  updateCustomField: `/api/ranloTask/${versionNumber}/fields/info/update`
},
{
  // 删除自定义字段
  delCustomField: `/api/ranloTask/${versionNumber}/fields/info/`
},
{
  // 查询字段使用情况-删除前调用
  customFieldUsed: `/api/ranloTask/${versionNumber}/fields/info/use`
},
{
  // 查询企业日历信息
  getCompanyCalendar: `/api/admin/${versionNumber}/company/calendar`
},
{
  // okr进度占比配置列表
  queryProcessConfig: `/api/ranloWeb/${versionNumber}/objectives/progress/config`
},
{
  // 周期阶段设置列表查询
  queryCycleStage: `/api/ranloWeb/${versionNumber}/setcycle/stage/list`
},
{
  // 周期阶段设置
  cycleStageSet: `/api/ranloWeb/${versionNumber}/setcycle/stage`
},
{
  // 周期评分设置
  cycleScoreSet: `/api/ranloWeb/${versionNumber}/setcycle/score/status`
},
{
  // 同级部门排序
  departSortOrder: `/api/admin/${versionNumber}/depart/order`
},
{
  // 查询手机号是否已在公司
  queryUserExist: `/api/admin/${versionNumber}/company/user/exist`
},
{
  // 周期提醒设置状态查询
  querySettingStateInfo: `/api/ranloWeb/${versionNumber}/setcycle/notify/state/list`
},
{
  // 周期提醒设置状态修改
  querySetCycleState: `/api/ranloWeb/${versionNumber}/setcycle/notify/state/update`
},
{
  // 企业微信/钉钉资源转移
  transferResource: `/api/admin/${versionNumber}/company/dimission/transferResource`
},
{
  // 获取子部门 + 部门下面的人员列表
  adminDepartTreeList: `/api/admin/${versionNumber2}/depart/tree/list`
},
{
  // 同步组织
  synchCompanyInfo: `/api/admin/${versionNumber}/qw/synchCompanyInfo`
},
{
  // 同步组织
  synchAllCompanyInfo: `/api/admin/${versionNumber}/qw/synchAllCompanyInfo`
},
{
  // 同步任务设置更新
  approvalSettingUpdate: `/api/admin/${versionNumber}/approval/setting/update`
},
{
  // 同步任务设置
  approvalSettingBusinessType: `/api/admin/${versionNumber}/approval/setting/select/businessType`
},
{
  // 同步任务设置更新企微
  approvalSettingUpdateQw: `/api/admin/${versionNumber}/schedule/set`
},
{
  // 同步任务设置企微
  approvalSettingBusinessTypeQw: `/api/admin/${versionNumber}/schedule/detail`
},
{
  // 修改周期名字
  setcycleName: `/api/ranloWeb/${versionNumber}/setcycle/name`
},
{
  // 钉钉提交授权状态
  ddAuthStatus: `/api/initialize/home/authStatus`
}
]
