<template>
  <div class="step-box">
    <div class="step-index-top">
      <Img :src="'/common/ic_logo.png'" class="logo-img" />
    </div>
    <div class="step-index-main">
      <div v-if="isFirstBtn == 0" class="step-left-img">
        <Img :src="'/common/step1_tooltip.png'" class="step1Img" />
      </div>
      <div class="step-index-left">
        <div class="step-title">创建OKR周期</div>
        <div class="step-text">创建你的OKR周期，帮助组织聚焦目标</div>
        <div class="step-radio-box">
          <el-radio-group v-model="radioRole" size="medium " type="vertical">
            <div
              v-for="(item, index) in isCreateNewUser"
              :key="index"
              class="radio-step"
            >
              <el-radio :label="item.ids">{{ item.label }}</el-radio>
            </div>
          </el-radio-group>
        </div>
        <div class="step-button-div">
          <L-button
            :loading="finishLoading"
            color="#3068ff"
            class="finish-button"
            @clickEvt="finish"
            >完成</L-button
          >
          <!-- <el-button
            type="primary"
            class="finish-button"
            :disabled="finishDisabled"
            @click="finish"
            v-preventReClick
            >完成</el-button
          > -->
        </div>
      </div>
      <div class="step-index-right">
        <div class="step-right-img">
          <Img :src="'/common/step1Img.png'" class="step1Img" />
        </div>
        <div class="step-cycle">
          <CycleSelect :type="1" />
        </div>
        <div>
          <StepOkr
            :comment="isFirstBtn == 0 ? comments : commentsOld"
            :keyresults-list="isFirstBtn == 0 ? formList : formListOld"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CycleSelect from '../../okr/components/cycleHeader/cycleSelect.vue'
import StepOkr from '../../../components/common/stepOkr'
export default {
  components: {
    CycleSelect,
    StepOkr
  },
  data() {
    return {
      finishLoading: false,
      finishDisabled: false,
      radioRole: '0',
      isCreateNewUser: [
        { label: '单月', id: '1', interval: '1', ids: '0' },
        { label: '双月', id: '1', interval: '2', ids: '1' },
        { label: '季度', id: '2', interval: '3', ids: '2' },
        { label: '半年', id: '1', interval: '6', ids: '3' }
        // { label: '年度', id: '3', interval: '1', ids: '4' }
      ],
      commentsOld: '',
      comments:
        '欢迎👏🏻👏🏻来到「源目标OKR」 ，7天跟着小源一起学习📚与实践OKR，帮助企业落地战略，实现飞跃增长🚀🚀🚀',
      formListOld: [
        {
          title: '',
          title_: ''
        },
        {
          title: '',
          title_: ''
        },
        {
          title: '',
          title_: ''
        }
      ],
      formList: [
        {
          title: '第一步：学习OKR知识，并掌握如何制定OKR',
          title_: ''
        },
        {
          title:
            '第二步：召集小伙伴，瞻前顾后抓核心，三两结对再提炼，制定企业的周期目标',
          title_: ''
        },
        {
          title: '第三步：7月20日全员OKR共识，并成功完成对齐OKR',
          title_: ''
        },
        {
          title: '第四步：7月20日完成分解我的关键结果(KR),并形成工作任务',
          title_: ''
        }
      ]
    }
  },
  methods: {
    finish() {
      this.finishDisabled = true
      this.finishLoading = true
      const obj = {}
      for (let i = 0; i < this.isCreateNewUser.length; i++) {
        console.log(2)
        if (this.isCreateNewUser[i].ids === this.radioRole) {
          obj.type = this.isCreateNewUser[i].id
          obj.interval = this.isCreateNewUser[i].interval
          //   this.newCycle(obj)
        }
      }
      // 数据埋点c
      this.$mixpanelUtil.track('Complete GuideCycle', { Cycle: '' })
      this.$apis.ADDCYCLE(obj).then((res) => {
        if (res.status === 200) {
          this.postRanleBtnRoles()
        } else {
          this.$message.error(res.message)
        }
        this.finishDisabled = false
        this.finishLoading = false
      })
    },
    postRanleBtnRoles() {
      this.$apis.COMMITPROCESS().then((res) => {
        if (res.status === 200) {
          if (res.data && res.data.finish === true) {
            this.$router.push('/')
          }
        } else {
          this.$message.error(res.message)
          this.loginLoading = false
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep {
  .finish-button {
    padding: 0;
    margin: 0;
    height: 40px;
    line-height: 40px;
    width: 200px;
    color: #fff;
    background: #005eff;
    text-align: center;
    border-radius: 16px;
  }
}
.step-box {
  height: 100vh;
  background: url('../../../assets/imgs/common/step1Bg.png') center center
    no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  .step-index-top {
    margin-left: 24px;
    margin-top: 20px;
    height: 60px;
    line-height: 60px;
    .logo-img {
      width: 136px;
      vertical-align: middle;
    }
  }
  .step-index-main {
    flex: 1;
    width: 1024px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    .step-left-img {
      position: absolute;
      left: 400px;
      top: 0px;
      width: 342px;
      z-index: 999;
      .step1Img {
        width: 100%;
      }
    }
    .step-index-left {
      width: 360px;
      height: 460px;
      opacity: 1;
      background: #ffffff;
      border-radius: 32px;
      box-shadow: 0px 4px 18px -3px rgba(0, 25, 94, 0.08);
      padding: 40px;
      .step-title {
        height: 40px;
        font-size: 30px;
        font-weight: 700;
        text-align: left;
        color: #14142b;
        line-height: 40px;
      }
      .step-text {
        height: 16px;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: #a0a3bd;
        line-height: 16px;
        margin-top: 10px;
      }
      .step-radio-box {
        margin-top: 40px;
        .radio-step {
          margin-bottom: 20px;
        }
      }
      .step-button-div {
        margin-top: 40px;
      }
    }
    .step-index-right {
      flex: 1;
      width: 616px;
      height: 380px;
      padding: 20px 40px;
      opacity: 1;
      border-radius: 0 16px 16px 0;
      background: linear-gradient(
        232deg,
        rgba(255, 255, 255, 0.45) 0%,
        rgba(255, 255, 255, 0.65) 0%,
        rgba(255, 255, 255, 0.54) 100%
      );
      border-image: linear-gradient(
          232deg,
          rgba(224, 228, 242, 0.56) 0%,
          rgba(255, 255, 255, 0) 100%
        )
        1 1;
      box-shadow: 42px 32px 87px 0px rgba(120, 118, 148, 0.1);
      backdrop-filter: blur(18px);
      .step-right-img {
        position: absolute;
        right: 26px;
        top: 0px;
        width: 74px;
        z-index: 999;
        .step1Img {
          width: 100%;
        }
      }
      .step-cycle {
        // border: 1px #f00 solid;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 50px;
      }
    }
  }
}
</style>
