var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "input-number" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.inputNumber,
          expression: "inputNumber",
        },
      ],
      attrs: { disabled: _vm.disabled, type: "number", maxlength: "2" },
      domProps: { value: _vm.inputNumber },
      on: {
        blur: _vm.inputNumberChange,
        input: function ($event) {
          if ($event.target.composing) return
          _vm.inputNumber = $event.target.value
        },
      },
    }),
    _c("div", { staticClass: "right-num-action" }, [
      _c(
        "div",
        { staticClass: "num-action", on: { click: _vm.numPlus } },
        [
          _c("Icon", {
            staticClass: "up-down-icon",
            attrs: { "icon-class": "icon-xialajiantou" },
            on: { click: _vm.hideOrShow },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "num-split" }),
      _c(
        "div",
        { staticClass: "num-action", on: { click: _vm.numDel } },
        [
          _c("Icon", {
            staticClass: "up-down-icon",
            attrs: { "icon-class": "icon-xialajiantou-1" },
            on: { click: _vm.hideOrShow },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }