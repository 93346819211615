<template>
  <div class="success-notice-box">
    <div class="success-notice-main">
      <div class="success-notice-img">
        <Img :src="'/common/logo_icon.png'" class="logo-img" />
      </div>
      <div class="success-notice-title">源目标</div>
      <div class="success-notice-text">
        <p>
          源自目标 &nbsp;&nbsp;聚焦协作 <br />让每个人的工作简单、快乐、有价值
        </p>
      </div>
      <div class="button-bottom">
        <el-button type="primary" class="next-button" @click="next_pass_reset"
          >下载或打开源目标客户端</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      types_: '', // 0 代表 IOS  1 代表 androd
      types_success: 1,
      iosAppUrl: 'https://itunes.apple.com/cn/app/id1550874842?mt=8', // 启动ios APP地址
      androidAppUrl: 'okr://okr.jinred.com/init', // 启动安卓 APP地址
      iosAppStore: 'https://itunes.apple.com/cn/app/id1550874842?mt=8', // AppStore下载地址
      androidApk:
        'http://a.app.qq.com/o/simple.jsp?pkgname=com.deepaq.okrt.android', // 安卓安装包下载地址
      appQQ: 'https://www.pgyer.com/qTee', // QQ应用宝
      isAndorad: true
    }
  },
  computed: {},
  mounted() {
    // this.getUrlData();
    this.fBrowserRedirect()
  },
  methods: {
    infoMore() {
      var browser = {
        versions: (function () {
          var u = navigator.userAgent
          // var app = navigator.appVersion
          return {
            // 移动终端浏览器版本信息
            weixin: u.match(/MicroMessenger/i) === 'MicroMessenger', // 是否为微信浏览器
            trident: u.indexOf('Trident') > -1, // IE内核
            presto: u.indexOf('Presto') > -1, // opera内核
            webKit: u.indexOf('AppleWebKit') > -1, // 苹果、谷歌内核
            gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') === -1, // 火狐内核
            mobile:
              !!u.match(/AppleWebKit.*Mobile.*/) || !!u.match(/AppleWebKit/), // 是否为移动终端
            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
            android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, // android终端或者uc浏览器
            iPhone: u.indexOf('iPhone') > -1 || u.indexOf('Mac') > -1, // 是否为iPhone或者QQHD浏览器
            iPad: u.indexOf('iPad') > -1, // 是否iPad
            webApp: u.indexOf('Safari') === -1 // 是否web应该程序，没有头部与底部
            // qq: u.match(/\sQQ/i) !== null, //是否QQ
            // Safari: u.indexOf('Safari') > -1,//Safari浏览器,
          }
        })(),
        language: (
          navigator.browserLanguage || navigator.language
        ).toLowerCase()
      }
      if (browser.versions.weixin && browser.versions.android) {
        // 微信安卓
        console.log('微信安卓')
        this.openApp(this.androidAppUrl, this.androidApk)
      } else if (browser.versions.android && browser.versions.webKit) {
        // 安卓
        console.log('安卓11')
        this.openApp(this.androidAppUrl, this.androidApk)
      } else if (
        browser.versions.weixin &&
        (browser.versions.ios ||
          browser.versions.iPhone ||
          browser.versions.iPad)
      ) {
        // 微信IOS
        console.log('微信IOS')
        this.openApp(this.iosAppUrl, this.iosAppStore)
      } else if (
        browser.versions.ios ||
        browser.versions.iPhone ||
        browser.versions.iPad
      ) {
        // IOS
        console.log('IOS')
        this.openApp(this.iosAppUrl, this.iosAppStore)
      }
    },
    next_pass_reset() {
      this.infoMore()
    },
    // 判断是ios还是安卓
    fBrowserRedirect() {
      var sUserAgent = navigator.userAgent.toLowerCase() // 匹配信息里的关键字
      var bIsIpad = sUserAgent.match(/ipad/i) === 'ipad'
      var bIsIphoneOs = sUserAgent.match(/iphone os/i) === 'iphone os'
      var bIsAndroid = sUserAgent.match(/android/i) === 'android'

      // var bIsMidp = sUserAgent.match(/midp/i) === 'midp'
      // var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) === 'rv:1.2.3.4'
      // var bIsUc = sUserAgent.match(/ucweb/i) === 'ucweb'
      // var bIsCE = sUserAgent.match(/windows ce/i) === 'windows ce'
      // var bIsWM = sUserAgent.match(/windows mobile/i) === 'windows mobile'
      if (bIsIpad) {
        this.isAndorad = false // 执行Ipad操作
      } else if (bIsIphoneOs) {
        this.isAndorad = false // 执行Iphone操作
      } else if (bIsAndroid) {
        this.isAndorad = true // 执行android操作
      } else {
        this.isAndorad = true
      }
    },
    /**
     * 判断手机上是否安装了app，如果安装直接打开url，如果没安装，执行callback
     * @method openApp 打开App
     * @param {参数类型} url 启动app地址
     * @param {参数类型} callback 回调地址app(下载地址)
     * @return {返回值类型} 返回值说明
     */
    openApp(url, callback) {
      const { isAndroid, isIOS, isIOS9 } = this.detectVersion()
      if (isAndroid || isIOS) {
        var t = 4000
        var hasApp = true
        // 跳到应用宝
        setTimeout(function () {
          if (!hasApp) {
            callback && (window.location.href = callback)
          }
          document.body.removeChild(ifr)
        }, 5000)
        var t1 = Date.now()
        // 唤起app
        var ifr = document.createElement('iframe')
        ifr.setAttribute('src', url)
        ifr.setAttribute('style', 'display:none')
        document.body.appendChild(ifr)
        // 超时2秒钟中没选中算作没有app
        setTimeout(function () {
          var t2 = Date.now()
          if (t2 - t1 < t + 100) {
            hasApp = false
          }
        }, t)
      }
      if (isIOS9) {
        var ua = navigator.userAgent.toLowerCase()
        // 判断是不是在iPhone的Safair浏览器打开的本页面
        if (
          ua.indexOf('applewebkit') > -1 &&
          ua.indexOf('mobile') > -1 &&
          ua.indexOf('safari') > -1 &&
          ua.indexOf('linux') === -1 &&
          ua.indexOf('android') === -1 &&
          ua.indexOf('chrome') === -1 &&
          ua.indexOf('ios') === -1 &&
          ua.indexOf('browser') === -1
        ) {
          callback && (window.location.href = callback)
        } else {
          location.href = url
          setTimeout(function () {
            callback && (window.location.href = callback)
          }, 250)
          setTimeout(function () {
            location.reload()
          }, 1000)
        }
      }
    },
    /**
     * 版本检测
     * @method detectVersion 版本检测
     * @return {返回值类型} isAndroid, isIOS, isIOS9 true或false
     */
    detectVersion() {
      let isAndroid
      let isIOS
      let isIOS9
      let version
      const u = navigator.userAgent
      const ua = u.toLowerCase()
      if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {
        // android终端或者uc浏览器
        // Android系统
        isAndroid = true
      }
      if (ua.indexOf('like mac os x') > 0) {
        // ios
        var regStr_saf = /os [\d._]*/gi
        var verinfo = ua.match(regStr_saf)
        version = (verinfo + '').replace(/[^0-9|_.]/gi, '').replace(/_/gi, '.')
      }
      var version_str = version + ''
      if (version_str !== 'undefined' && version_str.length > 0) {
        version = parseInt(version)
        if (version >= 8) {
          // ios9以上
          isIOS9 = true
        } else {
          isIOS = true
        }
      }
      return { isAndroid, isIOS, isIOS9 }
    }
  }
}
</script>
<style scoped lang="scss">
.success-notice-box {
  display: flex;
  justify-content: center;
  align-items: center;
  .success-notice-main {
    width: calc(100% - 30px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    .success-notice-img {
      width: 100px;
      height: 100px;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .success-notice-title {
      width: 100%;
      text-align: center;
      line-height: 40px;
      font-size: 24px;
    }
    .success-notice-text {
      width: 100%;
      margin-top: 20px;
      text-align: center;
      line-height: 30px;
      font-size: 18px;
      p {
        font-size: 16px;
      }
    }
    .button-bottom {
      width: 100%;
      text-align: center;
      margin-top: 50px;
      .login_next.common_btn_next.common_btn_next_able {
        font-size: 16px !important;
      }
    }
  }
}
</style>
