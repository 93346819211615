import Vue from 'vue'
import store from '@/store'
  // 当前的系统环境
  // let stage = process.env.STAGE
  // let isProd = ['uat', 'prod'].includes(stage)
  /* eslint-disable-next-line */
  //
  ; (function (f, b) {
    if (!b.__SV) {
      var e, g, i, h
      window.mixpanel = b
      b._i = []
      b.init = function (e, f, c) {
        function g(a, d) {
          var b = d.split('.')
          2 == b.length && ((a = a[b[0]]), (d = b[1]))
          a[d] = function () {
            a.push([d].concat(Array.prototype.slice.call(arguments, 0)))
          }
        }
        var a = b
        "undefined" !== typeof c ? a = b[c] = [] : c = 'mixpanel';
        a.people = a.people || []
        a.toString = function (a) {
          var d = 'mixpanel';
          'mixpanel' !== c && (d += '.' + c)
          a || (d += ' (stub)')
          return d
        }
        a.people.toString = function () {
          return a.toString(1) + '.people (stub)'
        }
        i =
          'disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking start_batch_senders people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove'.split(
            ' '
          )
        for (h = 0; h < i.length; h++) g(a, i[h])
        var j = 'set set_once union unset remove delete'.split(' ')
        a.get_group = function () {
          function b(c) {
            d[c] = function () {
              call2_args = arguments
              call2 = [c].concat(Array.prototype.slice.call(call2_args, 0))
              a.push([e, call2])
            }
          }
          for (
            var d = {},
            e = ['get_group'].concat(Array.prototype.slice.call(arguments, 0)),
            c = 0;
            c < j.length;
            c++
          )
            b(j[c])
          return d
        }
        b._i.push([e, f, c])
      }
      b.__SV = 1.2
      e = f.createElement('script')
      e.type = 'text/javascript';
      e.async = !0
      // e.src =
      //   'undefined' !== typeof MIXPANEL_CUSTOM_LIB_URL
      //     ? MIXPANEL_CUSTOM_LIB_URL
      //     : 'file:' === f.location.protocol &&
      //       '//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js'.match(/^\/\//)
      //       ? 'https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js'
      //       : '//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js'
			e.src =
			  'undefined' !== typeof MIXPANEL_CUSTOM_LIB_URL
			    ? MIXPANEL_CUSTOM_LIB_URL
			    : 'file:' === f.location.protocol &&
			      `//${f.location.host}/js/mixpanel-2-latest.min.js`.match(/^\/\//)
			      ? `https://${f.location.host}/js/mixpanel-2-latest.min.js`
			      : `//${f.location.host}/js/mixpanel-2-latest.min.js`
      g = f.getElementsByTagName('script')[0]
      g.parentNode.insertBefore(e, g)
    }
  })(document, window.mixpanel || [])

//
const isPcClientValue = isPcClient()
// 判断app pc 浏览器
const flag =
  navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  ) != null
const Platform = flag ? 'H5' : 'Web'
const mixpanelUtil = {
  // 初始化
  init() {
    if (
      window.location.host === 'new.okrt.com' ||
      window.location.host === 'app.okrt.com' ||
      window.location.host === 'qw.okrt.com'
    ) {
      mixpanel.init('b42983b89d43bce0f4aac8b41709f45b') // 正式
    } else {
      // mixpanel.init('08852b245c8f52d2a69207de0d31c3e8') // 测试新
      mixpanel.init('6983cd200b54a466e3334fb4f6c078fc') // 测试旧
    }
  },
  register_(obj) {
    const userInfo = store.state.user.userInfo
    mixpanel.register({
      Platform: !isPcClientValue ? Platform : 'Client',
      'Company ID': userInfo.companyId || '',
      'Company Name': userInfo.companyName || '',
      Edition: userInfo.version || '',
      Department: userInfo.departName || ''
    })
  },
  // 埋点事件触发
  track(name, data) {
    mixpanel.track(name, data || '')
  },
  // 销毁
  reset_() {
    mixpanel.reset()
  },
  // 发送手机号
  alias_(tel, type) {
    if (type === 2) {
      mixpanel.alias(mixpanel.get_distinct_id(), tel)
    } else {
      mixpanel.alias(tel)
    }
  },
  // 发送个人信息
  set_(obj) {
    const userInfo = store.state.user.userInfo
    // mixpanel.people.set(obj)
    mixpanel.register()
    mixpanel.people.set({
      avatar: userInfo.avatar,
      email: userInfo.email,
      phone: userInfo.mobile,
      name: userInfo.name,
      Company: userInfo.companyName,
      role: userInfo.jobTitle,
      department: userInfo.departName
    })
    mixpanel.register({
      Platform: !isPcClientValue ? Platform : 'Client',
      'Company ID': userInfo.companyId,
      'Company Name': userInfo.companyName,
      Edition: userInfo.version,
      Department: userInfo.departName
    })
  },
  // 登陆成功后
  identify_(tel) {
    // if (!isProd) {
    //   return
    // }
    mixpanel.identify(tel)
  }
}
Vue.prototype.$mixpanelUtil = mixpanelUtil
export default mixpanelUtil
