var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    _vm._g(
      {
        staticClass: "task-detail",
        attrs: {
          visible: _vm.dialogVisible,
          "append-to-body": true,
          direction: "rtl",
          size: 750,
          "modal-append-to-body": true,
          "before-close": _vm.dialogClose,
        },
        on: {
          "update:visible": function ($event) {
            _vm.dialogVisible = $event
          },
        },
      },
      _vm.$listeners
    ),
    [
      !_vm.pageLoading
        ? _c(
            "div",
            {
              staticClass: "dialog-title-wrap",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _vm.progressList && _vm.progressList.processDynamic
                ? _c(
                    "div",
                    { staticClass: "create-user" },
                    [
                      _c("wx-open-data", {
                        attrs: {
                          id: _vm.progressList.processDynamic.sponsorUserName,
                          nameData: {
                            name: _vm.progressList.processDynamic
                              .sponsorUserName,
                          },
                        },
                      }),
                      _vm._v("的OKR审批 "),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.progressList.process
                ? _c(
                    "div",
                    {
                      staticClass: "state-button",
                      style: {
                        background:
                          _vm.stateList[
                            _vm.progressList.process.approvalStatus || 0
                          ].backgroundColor,
                        color:
                          _vm.stateList[
                            _vm.progressList.process.approvalStatus || 0
                          ].fontColor,
                        borderColor:
                          _vm.stateList[
                            _vm.progressList.process.approvalStatus || 0
                          ].borderColor,
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.stateList[
                            _vm.progressList.process.approvalStatus || 0
                          ].name
                        )
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "scroll-bottom",
              rawName: "v-scroll-bottom",
              value: _vm.loadMoreTrendArray,
              expression: "loadMoreTrendArray",
            },
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.pageLoading,
              expression: "!pageLoading",
            },
          ],
          ref: "trendItem",
          staticClass: "main-content-wrap",
        },
        [
          _vm.progressList.process
            ? _c(
                "div",
                {
                  class: `state-detail-wrap state-detail-type-${
                    _vm.progressList.process &&
                    _vm.progressList.process.approvalStatus
                  }`,
                },
                [
                  _c(
                    "div",
                    { staticClass: "detail-content" },
                    [
                      _c("Icon", {
                        staticClass: "remind",
                        attrs: {
                          iconClass:
                            _vm.progressList.process.approvalStatus === 3
                              ? "icon-shenpitongguo"
                              : _vm.progressList.process.approvalStatus === 2
                              ? "icon-shenpiweitongguo"
                              : "icon-shenpijinggao",
                        },
                      }),
                      _vm.progressList && _vm.progressList.processDynamic
                        ? _c("div", { staticClass: "content" }, [
                            _c("div", { staticClass: "state-text" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.progressList.processDynamic.actionContent
                                )
                              ),
                            ]),
                            _vm.progressList.processDynamic.appointUserName
                              ? _c(
                                  "div",
                                  { staticClass: "user" },
                                  [
                                    _vm._v(" 审批人： "),
                                    _c("wx-open-data", {
                                      attrs: {
                                        id: _vm.progressList.processDynamic
                                          .appointUserName,
                                        nameData: {
                                          name: _vm.progressList.processDynamic
                                            .appointUserName,
                                        },
                                      },
                                    }),
                                    _vm._v(
                                      " （" +
                                        _vm._s(
                                          _vm.progressList.processDynamic
                                            .appointRole
                                        ) +
                                        "） "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.progressList.processDynamic &&
                  _vm.progressList.processDynamic.sponsorDate
                    ? _c("div", { staticClass: "date" }, [
                        _vm._v(
                          _vm._s(_vm.progressList.processDynamic.sponsorDate) +
                            " 提交"
                        ),
                      ])
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "content-line" }),
          _c("div", { staticClass: "obj-type-wrap" }, [
            _c(
              "div",
              { staticClass: "obj-type-detail" },
              [
                _c("div", { staticClass: "type-title" }, [
                  _vm._v(_vm._s(_vm.showCycleTitle)),
                ]),
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: "点击修改目标类型",
                      disabled: true,
                      placement: "top",
                    },
                  },
                  [
                    _c(
                      "objective-label",
                      {
                        attrs: {
                          "page-type": "okrDetail",
                          "okr-data": _vm.objectiveObj,
                          "can-edit": false,
                          "label-type": _vm.objectiveObj.labelType,
                          "share-list":
                            _vm.objectiveObj.objectivesSharedUserList,
                        },
                        on: {
                          refresh: function ($event) {
                            return _vm.queryObjectiveDetail(_vm.objectiveObj.id)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "type-title" }, [
                          _vm._v(
                            _vm._s(
                              _vm.labelTypeObj[_vm.objectiveObj.labelType]
                            ) + "目标"
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "obj-cotent-wrap" }, [
            _c("div", { staticClass: "obj-content" }, [
              _c(
                "div",
                {
                  staticClass: "top-content",
                  class:
                    _vm.objectiveObj.automationState === 1
                      ? "progress-auto"
                      : "",
                },
                [
                  _c("top-members", {
                    ref: "topMember",
                    staticClass: "top-member",
                    attrs: {
                      "top-members-beans": _vm.objectiveObj,
                      "top-members-state": "1",
                      "details-id": _vm.objectiveObj.id,
                      editState: !_vm.editDisabled,
                      fromType: "approvalDetail",
                    },
                    on: {
                      backData: _vm.backData,
                      delteAlignObj: function ($event) {
                        return _vm.queryObjectiveDetail(_vm.objectiveObj.id)
                      },
                      updateObjTitle: function ($event) {
                        return _vm.queryObjectiveDetail(_vm.objectiveObj.id)
                      },
                      showShareList: _vm.showShareList,
                    },
                  }),
                  _c(
                    "top-progress",
                    {
                      attrs: {
                        "top-members-beans": _vm.objectiveObj,
                        "top-members-state": "3",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "top-progress-auto" },
                        [
                          _vm.objectiveObj.automationState === 1
                            ? _c(
                                "autoProgressPopver",
                                {
                                  attrs: {
                                    "okr-data": _vm.objectiveObj,
                                    disabled:
                                      _vm.objectiveObj.automationState !== 1,
                                    type: 1,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "top-progress-content blue",
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "icon",
                                        attrs: {
                                          src: require("../../../../assets/imgs/common/circle-progress.gif"),
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  !_vm.editDisabled
                    ? _c(
                        "div",
                        { staticClass: "add-keyresult" },
                        [
                          _c(
                            "transition",
                            { attrs: { name: "bottom-slide", mode: "out-in" } },
                            [
                              _vm.leftAddkeyState
                                ? _c("key-results", {
                                    attrs: {
                                      "top-members-bean": _vm.objectiveObj,
                                    },
                                    on: { getAddkeyList: _vm.getAddkeyList },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "transition",
                            { attrs: { name: "bottom-slide", mode: "out-in" } },
                            [
                              !_vm.leftAddkeyState
                                ? _c("key-results-Add", {
                                    attrs: {
                                      "is-share": _vm.objectiveObj.isShare,
                                      "obj-id": _vm.objectiveObj.id,
                                    },
                                    on: {
                                      getkeyResultsAddRefresh:
                                        _vm.getkeyResultsAddRefresh,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "okr-kr-list" },
                [
                  _c(
                    "draggable",
                    {
                      attrs: {
                        handle: ".key_result_dragger_icon_box",
                        disabled: !(!_vm.editDisabled && _vm.draggableStatus),
                      },
                      on: { update: _vm.drop },
                      model: {
                        value: _vm.keyResultsList,
                        callback: function ($$v) {
                          _vm.keyResultsList = $$v
                        },
                        expression: "keyResultsList",
                      },
                    },
                    _vm._l(_vm.keyResultsList, function (item, index) {
                      return _c(
                        "div",
                        { key: item.id, staticClass: "okr-kr-item-wrap" },
                        [
                          _c(
                            "div",
                            { staticClass: "okr-kr-item" },
                            [
                              _c("key-results-list", {
                                attrs: {
                                  "key-results-li": item,
                                  "target-object": _vm.objectiveObj,
                                  "key-results-li-number": index,
                                  "show-border": true,
                                  "align-member": true,
                                  editState: !_vm.editDisabled,
                                  fromType: "approvalDetail",
                                },
                                on: {
                                  draggableSetStatus: _vm.draggableSetStatus,
                                  getTargetDetailsKeywordDetele:
                                    _vm.getTargetDetailsKeywordDetele,
                                  getKeyResultsListTitles: function ($event) {
                                    return _vm.getKeyResultsListTitles(index)
                                  },
                                  changeObjectiveTitle:
                                    _vm.changeObjectiveTitle,
                                  getKeyResultsListWeights: function ($event) {
                                    return _vm.getKeyResultsListWeights(index)
                                  },
                                  getKeyHandleChange: _vm.getKeyHandleChange,
                                  getKeyHandleChangeBlur:
                                    _vm.getKeyHandleChangeBlur,
                                  showProcessList: _vm.showProcessList,
                                  setAutoProcess: function ($event) {
                                    return _vm.keyResultsAutoClick(item)
                                  },
                                  showKeyResultScore: function ($event) {
                                    return _vm.showKeyResultScore(index)
                                  },
                                  getKeyScoreChangeBlur:
                                    _vm.getKeyScoreChangeBlur,
                                  getKeyScoreChange: _vm.getKeyScoreChange,
                                  okrDetail: _vm.getTopMembersAlignmentSelect,
                                  toOKRList: _vm.getTopMembersFatherList,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "shaodow-bg" }),
          _c("div", { staticClass: "progress-wrap" }, [
            _c("div", { staticClass: "progress-tab-wrap" }, [
              _c(
                "div",
                {
                  staticClass: "tab-title",
                  class: _vm.selectTabValue === 1 ? "active" : "",
                  on: {
                    click: function ($event) {
                      return _vm.selectTabValueChange(1)
                    },
                  },
                },
                [_vm._v("审批流程")]
              ),
              _c(
                "div",
                {
                  staticClass: "tab-title",
                  class: _vm.selectTabValue === 2 ? "active" : "",
                  on: {
                    click: function ($event) {
                      return _vm.selectTabValueChange(2)
                    },
                  },
                },
                [_vm._v("动态")]
              ),
            ]),
            _vm.selectTabValue === 1
              ? _c(
                  "div",
                  { staticClass: "progress-content" },
                  _vm._l(
                    _vm.progressList.processRecordArray,
                    function (item, index) {
                      return _c(
                        "div",
                        {
                          key: item.content,
                          class: `progress-item ${
                            item.approvalStatus === 1 && index === 0
                              ? "dotted"
                              : ""
                          }`,
                        },
                        [
                          _c(
                            "div",
                            {
                              class: `progess-line ${
                                item.approvalStatus === 1 && index === 0
                                  ? "sign"
                                  : ""
                              }`,
                            },
                            [_c("div", { staticClass: "circle" })]
                          ),
                          _c("div", { staticClass: "progess-detail" }, [
                            _c("div", { staticClass: "title-wrap" }, [
                              _c(
                                "div",
                                { staticClass: "title" },
                                [
                                  _vm._v(" " + _vm._s(item.actionRole) + ": "),
                                  _c("wx-open-data", {
                                    attrs: {
                                      id: item.actionUserName,
                                      nameData: { name: item.actionUserName },
                                    },
                                  }),
                                ],
                                1
                              ),
                              item.approvalStatus === 1 &&
                              _vm.objectiveObj.createUserid ==
                                _vm.userInfo.id &&
                              index === 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "blue-button",
                                      on: {
                                        click: function ($event) {
                                          return _vm.approvalRemind(item)
                                        },
                                      },
                                    },
                                    [_vm._v("催办")]
                                  )
                                : _vm._e(),
                            ]),
                            _c("div", { staticClass: "content-wrap" }, [
                              _c("div", { staticClass: "content-text" }, [
                                _c(
                                  "div",
                                  {
                                    class: `title type-${item.approvalStatus}`,
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(item.actionContent) +
                                        " " +
                                        _vm._s(
                                          item.approvalStatus === 1
                                            ? `审批中`
                                            : ""
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                item.content
                                  ? _c("div", { staticClass: "content" }, [
                                      _vm._v("说明:" + _vm._s(item.content)),
                                    ])
                                  : _vm._e(),
                              ]),
                              _c("div", { staticClass: "date" }, [
                                _vm._v(
                                  _vm._s(_vm.$utils.globalTime(item.updateDate))
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      )
                    }
                  ),
                  0
                )
              : _vm._e(),
            _vm.selectTabValue === 2
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "vueloading",
                        rawName: "v-vueloading",
                        value: _vm.trendArrayState,
                        expression: "trendArrayState",
                      },
                    ],
                    staticClass: "tab-item",
                  },
                  [
                    _c("div", { staticClass: "top-select-options" }, [
                      _vm.trendTotal > 10
                        ? _c("div", [
                            _vm.trendTotal - _vm.trendArray.length != 0 &&
                            !_vm.oldVisible
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "dynamic_menu",
                                    on: { click: _vm.showOld },
                                  },
                                  [
                                    _c("Icon", {
                                      staticClass: "trend_change_icon",
                                      attrs: { "icon-class": "icon-more-row" },
                                    }),
                                    [
                                      _vm._v(
                                        " 显示较早 " +
                                          _vm._s(
                                            _vm.trendTotal -
                                              _vm.trendArray.length
                                          ) +
                                          " 条 "
                                      ),
                                    ],
                                  ],
                                  2
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass: "dynamic_menu",
                                    on: { click: _vm.showOld },
                                  },
                                  [
                                    _c("Icon", {
                                      staticClass: "trend_change_icon",
                                      attrs: { "icon-class": "icon-more-row" },
                                    }),
                                    [_vm._v("隐藏较早的动态")],
                                  ],
                                  2
                                ),
                          ])
                        : _vm._e(),
                    ]),
                    _c(
                      "transition",
                      { attrs: { name: "fade", mode: "out-in" } },
                      [
                        !_vm.trendArrayState && _vm.trendArray.length === 0
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "no-data-show-wrap no-data-show-wrap2",
                              },
                              [_c("no-data")],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                    [
                      _c(
                        "transition-group",
                        {
                          staticClass: "draggable-box trend_box",
                          attrs: {
                            id: "transitionGroupTrend",
                            name: "fade",
                            tag: "div",
                            "data-scroll-type": "trendItem",
                          },
                        },
                        _vm._l(_vm.trendArray, function (item) {
                          return _c("trend-item", {
                            key: item.id,
                            staticClass: "trend_item",
                            attrs: {
                              "trend-info": item,
                              "obj-id": _vm.detailsId,
                            },
                            on: { refreshTrend: _vm.refreshTrend },
                          })
                        }),
                        1
                      ),
                    ],
                  ],
                  2
                )
              : _vm._e(),
          ]),
        ]
      ),
      !_vm.pageloading &&
      _vm.objectiveObj.objectivesProcessDataDto &&
      ((_vm.progressList.process &&
        _vm.progressList.process.approvalStatus === 1 &&
        _vm.userInfo.id == _vm.progressList.processDynamic.appointUserId) ||
        (((_vm.progressList.process &&
          _vm.progressList.process.approvalStatus === 0) ||
          (_vm.progressList.process &&
            _vm.progressList.process.approvalStatus === 1)) &&
          _vm.userInfo.id == _vm.objectiveObj.createUserid))
        ? _c("div", { staticClass: "user-button-wrap" }, [
            _c(
              "div",
              { staticClass: "approve-detail-wrap" },
              [
                (_vm.progressList.process &&
                  _vm.progressList.process.approvalStatus === 0 &&
                  _vm.userInfo.id == _vm.objectiveObj.createUserid &&
                  _vm.$store.state.user.approvalCheckData.onOff === 0) ||
                (_vm.progressList.process.approvalStatus === 1 &&
                  _vm.userInfo.id ==
                    _vm.progressList.processDynamic.appointUserId)
                  ? _c("el-input", {
                      attrs: {
                        type: "textarea",
                        placeholder:
                          _vm.progressList.process &&
                          _vm.progressList.process.approvalStatus === 1 &&
                          _vm.userInfo.id ==
                            _vm.progressList.processDynamic.appointUserId
                            ? "请输入审批意见"
                            : "请描述提交审批的内容，例如修改的内容等",
                        maxlength: 500,
                        rows: 3,
                      },
                      model: {
                        value: _vm.approveRxplain,
                        callback: function ($$v) {
                          _vm.approveRxplain = $$v
                        },
                        expression: "approveRxplain",
                      },
                    })
                  : _vm._e(),
                _vm.progressList.process &&
                _vm.progressList.process.approvalStatus === 1 &&
                _vm.userInfo.id == _vm.progressList.processDynamic.appointUserId
                  ? _c("div", { staticClass: "btn-list-wrap" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v("如要驳回，请描述审批意见"),
                      ]),
                      _c("div", { staticClass: "btn-list" }, [
                        _c(
                          "div",
                          {
                            staticClass: "blue-button yellow-button",
                            on: {
                              click: function ($event) {
                                return _vm.approvePassTypeClick(2)
                              },
                            },
                          },
                          [_vm._v("驳回")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "blue-button",
                            on: {
                              click: function ($event) {
                                return _vm.approvePassClick()
                              },
                            },
                          },
                          [_vm._v("通过审批")]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "btn-list-wrap flex-end" }, [
                  _vm.progressList.process &&
                  _vm.progressList.process.approvalStatus === 0 &&
                  _vm.userInfo.id == _vm.objectiveObj.createUserid &&
                  _vm.$store.state.user.approvalCheckData.onOff === 0
                    ? _c(
                        "div",
                        {
                          staticClass: "blue-button",
                          on: {
                            click: function ($event) {
                              return _vm.approvalSubmitCancel(1)
                            },
                          },
                        },
                        [_vm._v("提交审批")]
                      )
                    : _vm._e(),
                  _vm.progressList.process &&
                  _vm.progressList.process.approvalStatus === 1 &&
                  _vm.userInfo.id == _vm.objectiveObj.createUserid
                    ? _c(
                        "div",
                        {
                          staticClass: "blue-button yellow-button",
                          on: {
                            click: function ($event) {
                              return _vm.approvalCancel(2)
                            },
                          },
                        },
                        [_vm._v("撤回审批")]
                      )
                    : _vm._e(),
                ]),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }