<template>
  <div class="menu-list-wrap" @click.stop>
    <el-popover
      ref="accountSetting"
      placement="bottom-end"
      width="250"
      trigger="click"
      :visible-arrow="false"
      :popper-options="{
        boundariesElement: 'viewport',
        removeOnDestroy: true
      }"
      class="avatar-img avatar-popover"
      popper-class="popover-header"
    >
      <ul class="popover-ul">
        <li class="person-info">
          <div class="person-left">
            <el-image
              fit="cover"
              class="border-radius-20"
              :src="userInfo.avatar || require('@/assets/imgs/common/avatar.png')"
              alt
            />
            <div v-if="accountRedpoint" class="red-point"></div>
          </div>
          <div class="person-right">
            <p class="name">{{ userInfo.name }}</p>
            <p class="job-title">
              {{
              (userInfo.departName || '') !== ''
              ? userInfo.departName + ' '
              : ''
              }}{{ userInfo.jobTitle }}
            </p>
          </div>
        </li>
        <li class="my-company">{{ type === 1 ? '我的企业' : '切换企业' }}</li>
        <li
          v-for="item in personCompanyList"
          :key="item.id"
          class="company-list"
          @click.stop="focusTogether(item.id, item.name)"
        >
          <div class="company-list-left">
            <div class="logo">
              <img :src="item.logo || require('@/assets/imgs/common/avatar.png')" alt />
              <div v-if="type !== 2 && item.notifyNoReadCount > 0" class="number">
                {{
                item.notifyNoReadCount > 99 ? '···' : item.notifyNoReadCount
                }}
              </div>
            </div>
            <div class="name" :class="item.userState === 0 ? 'name-color' : ''">{{ item.name }}</div>
          </div>
          <!-- <div class="activity-free-div" v-if="item.version && item.version == 3">
            <img src="@/assets/imgs/common/activity_free.png" alt />
          </div>-->
          <div class="company-list-right">
            <div v-if="item.userState === 0">邀请加入</div>

            <Icon
              v-if="item.id === companyInfo.id"
              icon-class="icon-draw_checked"
              class="svg-company"
            />
          </div>
        </li>
        <li class="company-more" @click="switchEmpty">
          <Icon icon-class="icon-more-row" class="svg-list" />更多企业
        </li>
        <li class="header-ul-center">
          <ul class="header-ul-inner">
            <li v-if="type === 2" class="clickDisabled">
              <Icon icon-class="icon-a-weibiaoti-2_huaban1" class="svg-list" />账号设置
            </li>
            <li v-else @click="topMenuTab('setting')">
              <Icon icon-class="icon-a-weibiaoti-2_huaban1" class="svg-list" />账号设置
            </li>
            <li @click="feedBack">
              <Icon icon-class="icon-jiaruqiyehoutai" class="svg-list" />意见反馈
            </li>
            <li @click="topMenuTab('/helpH5/contactUs')">
              <Icon icon-class="icon-kefu" class="svg-list" />联系客服
            </li>
            <li @click="topMenuTab('help')">
              <Icon icon-class="icon-bangzhu" class="svg-list" />帮助和常见问题
            </li>
            <li @click="topMenuTab('helpCenter')">
              <Icon icon-class="icon-xuexizhongxin" class="svg-list" />学习中心
            </li>
            <li v-if="userInfo.isSuperAdmin == 1 && type === 1" @click="topMenuTab('admin')">
              <Icon icon-class="icon-jiaruqiyehoutai" class="svg-list" />进入企业后台
            </li>
            <li v-if="userInfo.isSuperAdmin == 1 && type === 2" class="clickDisabled">
              <Icon icon-class="icon-jiaruqiyehoutai" class="svg-list" />进入企业后台
            </li>
          </ul>
        </li>
        <li class="header-exit" @click="exitBtn">
          <Icon icon-class="icon-tuichudenglu" class="svg-list" />退出登录
        </li>
      </ul>
      <div slot="reference" class="image-wrap" @click="popoverClick">
        <el-image
          :src="userInfo.avatar || require('@/assets/imgs/common/avatar.png')"
          class="avatar-img border-radius-20"
          fit="cover"
        />
      </div>
    </el-popover>
    <!-- 意见反馈 -->
    <free-back v-if="feedBackState" :visible.sync="feedBackState" @closeFreeBack="closeFreeBack" />
    <switch-enterprises
      v-if="switch_enterprises"
      :visible.sync="switch_enterprises"
      @closeSwitchCompany="closeSwitchCompany"
    />
  </div>
</template>
<script>
/* eslint-disable */
import freeBack from './freeBack.vue' // 意见反馈
import switchEnterprises from './switchEnterprises.vue' // 切换企业
import redpointFun from '@/utils/info-center'
export default {
  data() {
    return {
      feedBackState: false, // 意见反馈
      switch_enterprises: false, // 切换企业
      personCompanyList: [],
      roleIdsObj: [],
      accountRedpoint: false //账户红点显示
    }
  },
  props: {
    type: {
      //1:头部  2:全局异常
      required: false,
      type: Number,
      default: 1
    }
  },
  mounted() {
    document.body.addEventListener('click', (e) => {
      this.$nextTick(() => {
        // this.$refs.accountSetting.doClose()
      })
    })
  },
  created() {},
  components: {
    freeBack,
    switchEnterprises
  },
  computed: {},
  methods: {
    popoverClick() {
      this.getCompanyList()
    },
    // 退出登录  跳转到登录页面
    exitBtn() {
      // 数据埋点c
      this.$router.push({ path: '/login' })
      this.$mixpanelUtil.reset_()
      this.$mixpanelUtil.init()
      this.$store.commit('user/setToken', '')
      this.$store.commit('user/setUserInfo', '')
      this.$store.commit('common/apiRequestData', {
        visible: false,
        type: ''
      })
      this.$store.commit('common/setUnlockPopoverVisible', true)
      // 断开socket链接
      this.$msg.stop()
    },
    // 切换企业
    async switchEmpty() {
      if (this.type === 2) {
        this.$emit('companyMore')
      } else {
        this.switch_enterprises = true
      }
      this.$store.commit('common/apiRequestData', {
        visible: false,
        type: ''
      })
      if (this.$refs.accountSetting) {
        this.$refs.accountSetting.doClose()
      }
    },
    closeSwitchCompany() {
      this.switch_enterprises = false
    },
    // 意见反馈
    feedBack() {
      this.feedBackState = true
      if (this.$refs.accountSetting) {
        this.$refs.accountSetting.doClose()
      }
    },
    // 关闭反馈
    closeFreeBack() {
      this.feedBackState = false
    },
    // 顶部菜单切换
    topMenuTab(val) {
      let path
      if (val === '/') {
        path = `/`
      } else if (val === 'notify') {
        path = `/${val}`
      } else if (val === 'setting') {
        path = `/${val}/information`
      } else if (val === 'admin') {
        path = `/${val}/control`
      } else if (val === 'okr') {
        window.open('http://doc.okrt.com/1957.html', '_blank')
        return
      } else if (val === 'setOkr') {
        window.open(
          'https://www.yuque.com/docs/share/ef8207b8-23e5-4f48-aeea-694205633518?#',
          '_blank'
        )
        return
      } else if (val === 'help') {
        window.open(
          'https://alidocs.dingtalk.com/i/p/w0oXWQEjJEEpDG1J',
          '_blank'
        )
        return
      } else if (val === 'helpCenter') {
        if (this.$isQw || this.$isDD) {
          window.open('https://www.okrt.com/wiki/', '_blank')
        } else {
          openSysBrowser('https://www.okrt.com/wiki/')
        }
        return
      } else if (val === 'working') {
        path = `/${val}`
      } else if (val === 'project' || val === 'meeting') {
        path = `/${val}`
      } else if (val === 'briefSummary') {
        path = `/${val}`
      } else if (val === 'analyse') {
        path = '/analyse'
      } else if (val === 'okr') {
        path = '/okr'
        this.$emit('global:getCloseHomeDetails')
      } else if (val === 'performance') {
        path = `/${val}`
      } else if (val === '/helpH5/contactUs') {
        window.open(
          `https://${window.location.host}${val}`,
          '_blank'
        )
        return
      } else {
        path = `/${val}/me`
      }
      this.objectiveDetailVisible = false
      setTimeout(() => {
        this.targetDetailOpenState = ''
      }, 500)
      this.topMenuActive = val
      this.$router.push(path)
      if (this.$refs.accountSetting) {
        this.$refs.accountSetting.doClose()
      }
      // this.$refs.accountHelp.doClose()
    },
    getCompanyList(meetingId) {
      let url_ = `/api/admin/${this.versionNumber}/company/list`
      let obj_ = {}
      this.$apis
        .HEADERGETCOMPANYLIST(obj_)
        .then((res) => {
          this.$nextTick(() => {
            this.accountRedpoint = res.data.rows.some((item) => {
              return item.notifyNoReadCount > 0
            })
            this.personCompanyList = res.data.rows.slice(0, 3)
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    //鼠标点击列表
    focusTogether(_id, companyName) {
      //点击直接进入首页 ===============================================================
      //根据id获取（查询）具体企业信息
      this.$mixpanelUtil.track('Change Company')
      this._specifyQuery(_id, companyName, _id)
      if (this.$refs.accountSetting) {
        this.$refs.accountSetting.doClose()
      }
    },
    //获取（查询）具体企业信息
    _specifyQuery(url_, companyName, id) {
      var that = this
      this.$apis
        .HEADERGETCOMPANYDETAIL(url_, {
          apiStatus: 1
        })
        .then((res) => {
          if (res.status == 200) {
            if (res.data) {
              console.log(13323, res.data)
              //对具体企业信息进行存储
              this.$store.commit('user/setCompanyInfo', res.data)
              this.updateTokenRefresh(res.data.token)
              // this.updateToken(res.data, 0) //企业列表点击 0
              let objs_ = {}
              objs_.id = res.data.id
              objs_.name = res.data.name
              this.centerDialogVisible = false
              this.$emit('closeSwitchCompany')
              this.$store.commit('common/apiRequestData', {
                visible: false,
                type: ''
              })
              // 红点条件推送
              this.readRedpoint(res.data.id)
            } else {
              this.$message({
                msg: '当前企业不存在!',
                type: 'error'
              })
            }
          } else if (
            res.status == 52012 ||
            res.status == 52017 ||
            res.status == 52019 ||
            res.status == 52020
          ) {
            // this.$message.warn(res.message)
            that.$store.commit('common/apiRequestData', {
              visible: true,
              type: res.status,
              companyId: id,
              companyName
            })
            this.centerDialogVisible = false
            this.$emit('closeSwitchCompany')
          } else {
            this.$message({
              msg: res.message,
              type: 'error'
            })
          }
        })
    },
    //刷新TOKEN
    // updateToken(event, type_) {
    //   // type_：0 点击 1:创建
    //   if (type_ === 1) {
    //     this.$apis.GETUSERTOKEN({}).then((res) => {
    //       if (res.status == '200') {
    //         //存储Token到本地 Cookie
    //         this.updateTokenRefresh(res.data)
    //       } else {
    //         this.$message.error(res.message)
    //       }
    //     })
    //   } else {
    //     this.updateTokenRefresh(event.token)
    //   }
    // },
    async updateTokenRefresh(token) {
      this.$store.commit('user/setToken', token)
      await this.getUserRoleProgress()
      let obj_ = {}
      obj_.username = this.userInfo.mobile
      await this._userInfo(obj_)

      this.$emit('global:homeListContent') //首页
      //个人设置
      //this.$emit('global:settingPass');
      this.$emit('global:settingInformation')
      this.$emit('global:settingNotify')
      // this.$emit('global:settingUpdate');
      //企业后台
      this.$emit('global:backCycle')
      this.$emit('global:backInformations')
      this.$emit('global:backTeams')
      this.$emit('global:backUpdate')
      //关闭主页目标详情
      this.$emit('global:closeHomeDetail')
      this.$emit('closeSwitchCompany')
      this.$emit('global:closeSwitchCompany')

      //跳转到首页
      this.$router.replace({ path: '/workbench' })
      //关闭弹框
      // this.$dialogClose()
    },
    //后台请求 个人信息
    async _userInfo(param, type_btn) {
      await this.$apis.USERINFOBYMOBILE(param).then((res) => {
        if (res.status == 200) {
          //判断个人信息中是否有值  有值则正常存储
          this.$store.commit('user/setUserInfo', res.data)
          //数据埋点 超级属性 用户 初始化
          this.$mixpanelUtil.reset_()
          this.$mixpanelUtil.init()
          this.$mixpanelUtil.identify_(res.data.mobile)
          this.$mixpanelUtil.alias_(res.data.mobile)
          this.$mixpanelUtil.set_()
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    //查看引导流程是否结束
    getUserRoleProgress() {
      this.$apis.GETCOMCURRENTPROCESS({}).then((res) => {
        if (res.status == 200) {
          console.log('切换企业')
          // if (this.companyInfo.version && this.companyInfo.version == 0) {
          //   this.$store.commit('common/apiRequestData', {
          //     visible: true,
          //     type: 20220901,
          //     activity: true
          //   })
          // }
          if (res.data) {
            let _obj = {}
            _obj = res.data
            _obj.userId = this.userInfo.id
            _obj.companyId = this.companyInfo.id
            if (this.roleIdsObj.length == 0) {
              this.roleIdsObj.push(_obj)
            } else {
              let _booleans_ = true
              for (let i = 0; i < this.roleIdsObj.length; i++) {
                if (
                  this.roleIdsObj[i].userId == this.userInfo.id &&
                  this.roleIdsObj[i].companyId == this.companyInfo.id
                ) {
                  this.roleIdsObj.splice(i, 1, _obj)
                  _booleans_ = false
                }
              }
              if (_booleans_ == true) {
                this.roleIdsObj.push(_obj)
              }
            }
            //存到仓库中
            console.log('setRoleIdsObj', this.roleIdsObj)
            this.$store.commit('user/setRoleIdsObj', this.roleIdsObj)
            //判断流程是否结束
            if (res.data.isFirst === 1) {
              // this._isFirstBtn(0)//首次跳过按钮的显示
              this.$store.commit('user/setIsFirstBtn', 0)
            } else {
              // this._isFirstBtn(1)//首次跳过按钮的显示
              this.$store.commit('user/setIsFirstBtn', 1)
            }
            if (res.data.finish == true) {
              // if(res.data.finish == true || res.data.isFirst === 0) {//isFirst 1：首次 0:非首次
              this.$router.push({ path: '/' })
            } else if (res.data.step == 0) {
              //角色选择
              this.statusPassSms = 6
              //角色字典请求 === 放在页面名字填写之后 20210324
              this.getUserRole()
            } else if (res.data.step == 1) {
              //创建okr
              this.$router.push({ path: '/stepOne' })
            } else if (res.data.step == 2) {
              if (this.stepObject.objsArr.length > 0) {
                //检测是否之前存储过相应的字段
                let boolean_ = true
                for (let i = 0; i < this.stepObject.objsArr.length; i++) {
                  if (
                    this.stepObject.objsArr[i].userId == this.userInfo.id &&
                    this.stepObject.objsArr[i].companyId == this.companyInfo.id
                  ) {
                    if (this.stepObject.objsArr[i].step == 1) {
                      //创建目标
                      this.$router.push({ path: '/step2' })
                    } else if (this.stepObject.objsArr[i].step == 2) {
                      //创建目标
                      this.$router.push({ path: '/step2' })
                    } else if (this.stepObject.objsArr[i].step == 3) {
                      //创建目标
                      this.$router.push({ path: '/step3' })
                    } else if (this.stepObject.objsArr[i].step == 4) {
                      //创建目标
                      this.$router.push({ path: '/step4' })
                    } else if (this.stepObject.objsArr[i].step == 5) {
                      //创建目标
                      this.$router.push({ path: '/step5' })
                    }
                    boolean_ = false
                  }
                }
                if (boolean_ == true) {
                  //创建目标
                  this.$router.push({ path: '/step2' })
                }
              } else {
                //创建目标
                this.$router.push({ path: '/step2' })
              }
            }
          }
        } else {
          if (res.status == 62206) {
            this.$message.warn('周期不存在，请联系管理员!')
          } else {
            this.$message({
              msg: res.message,
              type: 'error'
            })
          }
        }
      })
    },
    readRedpoint(companyId) {
      redpointFun.sendBody({
        ReplyRed: `{ "userId": "${this.userInfo.id}", "companyId": "${companyId}" }`
      })
    }
  }
}
</script>
<style lang="less" scoped>
* {
  margin: 0px;
  padding: 0px;
}
.popover-ul > li {
  margin: 0px 16px;
}
.popover-header {
  box-sizing: border-box;
  .popover-ul {
    padding: 0px;
    .person-info {
      margin: 4px 16px 20px 16px;
      width: 100%;
      .flex-l();
      .person-left {
        position: relative;
        width: 40px;
        height: 40px;
        margin-right: 12px;
        .el-image {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
        .red-point {
          position: absolute;
          right: -2px;
          top: -2px;
          width: 10px;
          height: 10px;
          box-sizing: content-box;
          background: #ed2e7e;
          border: 2px solid #fcfcfc;
          border-radius: 50%;
        }
      }
      .person-right {
        max-width: 90px;
        line-height: 16px;
        .name {
          max-width: 90px;
          font-weight: 500;
          font-size: 16px;
          color: #14142b;
          .text-overOne();
        }
        .job-title {
          max-width: 90px;
          .text-overOne();
          font-size: 12px;
          color: #a0a3bd;
        }
      }
    }
    li {
      border-bottom: none;
      .flex-l();
      padding: 0px;
    }
    li.my-company {
      color: #a0a3bd;
      line-height: 20px;
      margin-bottom: 8px;
    }
    li.company-more {
      padding: 8px 16px 8px 16px;
      border-bottom: 1px solid #e0e3ec;
      margin: 0px 0px 8px 0px;
      &:hover {
        color: #14142b;
        background: #f5f6fa;
        border-radius: 8px;
      }
    }
    li.header-exit {
      border-top: 1px solid #e0e3ec;
      padding: 8px 16px 10px 16px;
      margin: 0px;
      &:hover {
        color: #14142b;
        background: #f5f6fa;
        border-radius: 8px;
      }
    }
    li.company-list {
      .flex-bt();
      height: 36px;
      line-height: 36px;
      margin: 0px;
      padding: 0px 16px;
      .company-list-left {
        .flex-l();
        .logo {
          position: relative;
          .flex-l();
          img {
            width: 20px;
            height: 20px;
            border-radius: 4px;
            margin-right: 8px;
          }
          .number {
            width: 18px;
            height: 18px;
            line-height: 18px;
            background: #ed2e7e;
            border: 2px solid #ffffff;
            border-radius: 50%;
            position: absolute;
            top: -10px;
            right: 0px;
            font-size: 12px;
            text-align: center;
            color: #fff;
          }
        }
        .name {
          max-width: 90px;
          .text-overOne();
          color: #14142b;
          line-height: 16px;
        }
        .name-color {
          max-width: 60px;
          color: #ed2e7e;
        }
      }
      .activity-free-div {
        width: 58px;
        height: 18px;
        margin-top: -10px;
        img {
          width: 58px;
          height: 18px;
        }
      }
      .company-list-right {
        .flex-r();
        div {
          font-size: 10px;
          color: #3a78f6;
        }
        .svg-company {
          font-size: 20px;
        }
      }
      &:hover {
        color: #14142b;
        background: #f5f6fa;
        border-radius: 8px;
      }
    }
    .header-ul-center {
      width: 100%;
      margin: 0px;
      .header-ul-inner {
        width: 100%;
      }
      li {
        width: 100%;
        padding: 0px 16px;
        box-sizing: border-box;
        &:hover {
          color: #14142b;
          background: #f5f6fa;
          border-radius: 8px;
        }
      }
    }
    .svg-list {
      font-size: 20px;
      color: #6f7590;
      margin-right: 8px;
    }
    li.clickDisabled {
      color: #14142b;
      background: #f5f6fa;
      border-radius: 8px;
      cursor: not-allowed;
    }
  }
}
.avatar-img {
  border-radius: 50%;
  height: 28px;
  width: 28px;
}
.popover-header {
  z-index: 999;
}
</style>
