var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "file-upload", class: "file-upload-" + _vm.formPage },
    [
      _c(
        "el-upload",
        {
          ref: "commentUpload",
          staticClass: "upload-demo",
          attrs: {
            action: `${_vm.baseUrl}${_vm.$apis.FILEUPLOADURL}`,
            headers: _vm.headers,
            "on-preview": _vm.handlePreview,
            "on-remove": _vm.handleRemove,
            "before-remove": _vm.beforeRemove,
            "before-upload": _vm.beforeUpload,
            "on-progress": _vm.onProgress,
            "on-success": _vm.onSuccess,
            "on-error": _vm.onError,
            "show-file-list": _vm.showFileList,
            accept: "",
            multiple: "",
            drag: "",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "upload-btn-wrap",
              attrs: { slot: "trigger" },
              slot: "trigger",
            },
            [
              _c(
                "div",
                { staticClass: "upload-btn" },
                [
                  _vm.imgVisible
                    ? _c("Icon", {
                        staticClass: "icon-upload icon-upload-img",
                        attrs: { "icon-class": "icon-attachimg" },
                        on: {
                          click: function ($event) {
                            return _vm.uploadType("image/*")
                          },
                        },
                      })
                    : _vm._e(),
                  _c("Icon", {
                    staticClass: "icon-upload",
                    attrs: { "icon-class": "icon-attachfile" },
                    on: {
                      click: function ($event) {
                        return _vm.uploadType("")
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c("upload-file-list", {
        attrs: {
          "form-page": _vm.formPage,
          "oss-file-entity-list": _vm.uploadFileList,
        },
        on: { delete: _vm.fileDelete },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }