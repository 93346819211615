<template>
  <div class="dynamic-container">
    <div class="list-container" v-loading="loading">
      <!-- 动态筛选 -->
      <div class="dynamic-menu-wrap" v-if="source == 'stopdoing'">
        <div class="stopdoing-dynamic-title">评论</div>
        <div class="stopdoing-dynamic-menu">
          <div v-if="topList.length > 2 || nextCount > 0">
            <div v-if="nextCount > 0" class="dynamic-menu" @click="showOld">
              <Icon icon-class="icon-more-row" class="menu-icon" />
              <template>显示较早的 {{ nextCount }} 条动态</template>
            </div>
            <div v-else class="dynamic-menu" @click="showOld">
              <Icon icon-class="icon-more-row" class="menu-icon" />
              <template>隐藏较早的动态</template>
            </div>
          </div>
        </div>
      </div>
      <div class="dynamic-menu-wrap" v-else>
        <div class="dynamic-type-wrap">
          <div :class="`dynamic-type ${tabType === 1 ? 'select': ''}`" @click="tabTypeClick(1)">
            <sing-dropdown
              :options="commentTypeEnum"
              :all="false"
              :text="commentTypeText"
              @command="dynamicCommand"
            />
          </div>
          <div
            :class="`dynamic-type ${tabType === 2 ? 'select': ''}`"
            @click="tabTypeClick(2)"
            v-if="progressList && progressList.processRecordArray && progressList.processRecordArray.length > 0"
          >审批记录</div>
        </div>

        <div v-if="(topList.length > 2 || nextCount > 0) && tabType === 1">
          <div v-if="nextCount > 0" class="dynamic-menu" @click="showOld">
            <Icon icon-class="icon-more-row" class="menu-icon" />
            <template>显示较早的 {{ nextCount }} 条动态</template>
          </div>
          <div v-else class="dynamic-menu" @click="showOld">
            <Icon icon-class="icon-more-row" class="menu-icon" />
            <template>隐藏较早的动态</template>
          </div>
        </div>
        <!-- 动态列表切换显示菜单 -->
      </div>
      <!-- 最新的动态 -->
      <div class="dynamic-content-wrap" v-show="tabType === 1">
        <div
          v-for="item in topList"
          :key="item.id"
          class="list-box rel"
          :class="item.type == 1 ? 'list-box-comment' : 'list-box-dynamic'"
        >
          <div class="flex">
            <el-image
              v-if="item.type === 1"
              class="avatar m-t-5 border-radius-20"
              fit="cover"
              :src="item.createUserAvatar|| require('@/assets/imgs/common/advar.png')"
            />
            <Icon
              v-else
              :icon-class="dynamicEnum[item.type].icon"
              class="m-r-5 m-t-5 dynamic-icon"
            />
            <div class="dynamic-action">
              <span class="name m-r-5">
                <wx-open-data :id="item.createUserName" :nameData="{name:item.createUserName}" />
                <template v-if="item.type === 5">
                  添加了「
                  <wx-open-data :id="item.comment" :nameData="{name:item.comment}" />」为负责人
                </template>
                <template v-else-if="item.type === 11">
                  删除了协作人「
                  <wx-open-data :id="item.comment" :nameData="{name:item.comment}" />」
                </template>
                <template v-else-if="item.type === 12">
                  添加了「
                  <wx-open-data :id="item.comment" :nameData="{name:item.comment}" />」为协作人
                </template>
                <template v-else>{{ getAction(item) }}</template>
              </span>
              <!-- <div v-if="item.type === 1" class="user-click-wrap">
              <div
                v-if="
                  item.ossFileEntityList !== '' &&
                  item.ossFileEntityList !== null &&
                  !$utils.isWxMac()
                "
                class="download-click"
                @click="fileDownload(item.ossFileEntityList)"
              >
                下载附件
              </div>
              </div>-->
            </div>
          </div>
          <div
            v-if="item.type === 1 || item.type === 19 || item.type === 20"
            class="describe m-l-40 m-t-5"
            v-html="$utils.changeToHtml(item.comment)"
          ></div>
          <div
            v-else-if="item.type !== 13 && getContent(item) !== ''"
            class="describe m-l-40 m-t-5"
          >{{ getContent(item) }}</div>

          <!-- </el-tooltip> -->
          <div class="time abs f-z-12">{{ item.createDate.substring(0, 16) }}</div>
          <dynamic-file-list
            v-if="
            item.ossFileEntityList !== null && item.ossFileEntityList !== ''
          "
            :oss-file-entity-list="item.ossFileEntityList"
          />
          <div class="trend-btn-reply-operation" v-if="item.type === 1">
            <div class="operation-left">
              <span
                @click="fileDownload(item.ossFileEntityList)"
                v-if="
                item.ossFileEntityList !== '' && item.ossFileEntityList !== null &&
                  !$utils.isWxMac()
              "
              >下载附件</span>
            </div>
            <div class="operation-right">
              <el-tooltip effect="dark" content="点击评论" placement="top-start">
                <span class="operation-icon-span" @click.stop="showReplyBox(item)">
                  <Icon class="operation-icon" icon-class="icon-pinglun"></Icon>
                </span>
              </el-tooltip>

              <span v-if="userInfo.id === item.createUserid" class="line"></span>
              <el-tooltip effect="dark" content="点击删除评论" placement="top-start">
                <span
                  v-if="userInfo.id === item.createUserid"
                  class="operation-icon-span"
                  @click="deleteComment(item.id)"
                >
                  <Icon class="operation-icon" icon-class="icon-shanchu-renwuxiangqing"></Icon>
                </span>
              </el-tooltip>
            </div>
          </div>
          <div
            class="task-sonCommentArray"
            v-if="
            item.type == 1 &&
            item.sonCommentArray &&
            item.sonCommentArray.length > 0
          "
          >
            <div
              class="son-task-list"
              v-for="(sonItem, sonIndex) in item.sonCommentArray"
              :key="sonIndex"
            >
              <div class="son-task-name">
                <el-image
                  class="avatar"
                  :src="sonItem.createUserAvatar|| require('@/assets/imgs/common/advar.png')"
                />
                <span class="son-name">
                  <wx-open-data
                    :id="sonItem.createUserName"
                    :nameData="{name:sonItem.createUserName}"
                  />回复
                  <wx-open-data :id="item.createUserName" :nameData="{name:item.createUserName}" />
                </span>
                <div class="son-task-time">{{ sonItem.createDate.substring(0, 16) }}</div>
              </div>
              <div class="son-task-row">
                <div class="son-task-title" v-html="$utils.changeToHtml(sonItem.comment)"></div>
              </div>
              <div class="trend-btn-reply-operation" v-if="sonItem.type == 1">
                <div class="operation-left"></div>
                <div class="operation-right">
                  <span
                    class="operation-icon-span"
                    @click="deleteComment(sonItem.id)"
                    v-if="userInfo.id === sonItem.createUserid"
                  >
                    <Icon class="operation-icon" icon-class="icon-shanchu-renwuxiangqing"></Icon>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="reply-box">
            <template v-if="item.type === 1">
              <div v-if="replyView && isActive == item.id" class="trend-input-reply" @click.stop>
                <comment-box
                  ref="commentBox"
                  :file-upload-disabled="true"
                  placeholder="输入回复的内容"
                  :blur-release="true"
                  :at-able="false"
                  height="auto"
                  :lineFeed="true"
                  @release="addReplayComment"
                  @hideComment="hideComment"
                />
              </div>
            </template>
          </div>
        </div>
        <!-- 较早的动态 -->
        <template v-if="list.length > 0">
          <div
            v-for="item in list"
            :key="item"
            class="list-box rel"
            :class="item.type == 1 ? 'list-box-comment' : 'list-box-dynamic'"
          >
            <div class="flex">
              <el-image
                v-if="item.type === 1"
                class="avatar"
                :src="item.createUserAvatar || require('@/assets/imgs/common/advar.png')"
              />
              <Icon
                v-else
                :icon-class="dynamicEnum[item.type].icon"
                class="m-r-5 m-t-5 dynamic-icon"
              />
              <div class="dynamic-action flex-a-c-center">
                <span class="name m-r-5">
                  <wx-open-data :id="item.createUserName" :nameData="{name:item.createUserName}" />
                  <template v-if="item.type === 5">
                    添加了「
                    <wx-open-data :id="item.comment" :nameData="{name:item.comment}" />」为负责人
                  </template>
                  <template v-else-if="item.type === 11">
                    删除了协作人「
                    <wx-open-data :id="item.comment" :nameData="{name:item.comment}" />」
                  </template>
                  <template v-else-if="item.type === 12">
                    添加了「
                    <wx-open-data :id="item.comment" :nameData="{name:item.comment}" />」为协作人
                  </template>
                  <template v-else>{{ getAction(item) }}</template>
                </span>
              </div>
            </div>
            <div
              v-if="item.type === 1 || item.type === 19 || item.type === 20"
              class="describe m-l-40 m-t-5"
              v-html="$utils.changeToHtml(item.comment)"
            ></div>
            <div
              v-else-if="item.type !== 13 && getContent(item) !== ''"
              class="describe m-l-40 m-t-5"
            >{{ getContent(item) }}</div>
            <!-- </el-tooltip> -->
            <div class="time abs f-z-12">{{ item.createDate.substring(0, 16) }}</div>
            <dynamic-file-list
              v-if="
              item.ossFileEntityList !== null && item.ossFileEntityList !== ''
            "
              :oss-file-entity-list="item.ossFileEntityList"
            />
            <div class="trend-btn-reply-operation" v-if="item.type === 1">
              <div class="operation-left">
                <span
                  @click="fileDownload(item.ossFileEntityList)"
                  v-if="
                  item.ossFileEntityList !== '' &&
                  item.ossFileEntityList !== null &&
                  !$utils.isWxMac()
                "
                >下载附件</span>
              </div>
              <div class="operation-right">
                <el-tooltip effect="dark" content="点击评论" placement="top-start">
                  <span class="operation-icon-span" @click.stop="showReplyBox(item)">
                    <Icon class="operation-icon" icon-class="icon-pinglun"></Icon>
                  </span>
                </el-tooltip>

                <span v-if="userInfo.id === item.createUserid" class="line"></span>
                <el-tooltip effect="dark" content="点击删除评论" placement="top-start">
                  <span
                    v-if="userInfo.id === item.createUserid"
                    class="operation-icon-span"
                    @click="deleteComment(item.id)"
                  >
                    <Icon class="operation-icon" icon-class="icon-shanchu-renwuxiangqing"></Icon>
                  </span>
                </el-tooltip>
              </div>
            </div>
            <div
              class="task-sonCommentArray"
              v-if="
              item.type == 1 &&
              item.sonCommentArray &&
              item.sonCommentArray.length > 0
            "
            >
              <div
                class="son-task-list"
                v-for="(sonItem, sonIndex) in item.sonCommentArray"
                :key="sonIndex"
              >
                <div class="son-task-name">
                  <el-image
                    class="avatar"
                    :src="sonItem.createUserAvatar || require('@/assets/imgs/common/advar.png')"
                  />
                  <span class="son-name">
                    <wx-open-data
                      :id="sonItem.createUserName"
                      :nameData="{name:sonItem.createUserName}"
                    />回复
                    <wx-open-data :id="item.createUserName" :nameData="{name:item.createUserName}" />
                  </span>
                  <div class="son-task-time">{{ sonItem.createDate.substring(0, 16) }}</div>
                </div>
                <div class="son-task-row">
                  <div class="son-task-title" v-html="$utils.changeToHtml(sonItem.comment)"></div>
                </div>
                <div class="trend-btn-reply-operation" v-if="sonItem.type == 1">
                  <div class="operation-left"></div>
                  <div class="operation-right">
                    <el-tooltip effect="dark" content="点击删除评论" placement="top-start">
                      <span
                        class="operation-icon-span"
                        @click="deleteComment(sonItem.id)"
                        v-if="userInfo.id === sonItem.createUserid"
                      >
                        <Icon class="operation-icon" icon-class="icon-shanchu-renwuxiangqing"></Icon>
                      </span>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
            <div class="reply-box">
              <template v-if="item.type === 1">
                <div v-if="replyView && isActive == item.id" class="trend-input-reply" @click.stop>
                  <comment-box
                    ref="commentBox"
                    :file-upload-disabled="true"
                    placeholder="输入回复的内容"
                    :blur-release="true"
                    :at-able="false"
                    height="auto"
                    :lineFeed="true"
                    @release="addReplayComment"
                    @hideComment="hideComment"
                  />
                </div>
              </template>
            </div>
          </div>
        </template>
        <!-- 暂无动态 -->
        <div v-if="topList.length == 0 && !loading" class="empty-box rel">
          <div class="gravity-center">
            <div class="global-content-null-wrap">
              <img src="@/assets/imgs/common/stopdoingNull.png" class="img" />
            </div>
            <p class="null-text">还没有评论，快来抢占第一个沙发～</p>
          </div>
        </div>
      </div>
      <!-- 审批记录 -->
      <div class="approve-content-wrap" v-if="tabType === 2">
        <div
          :class="`progress-item ${item.approvalStatus === 1 && index === 0 ? 'dotted' : ''}`"
          v-for="(item , index) in progressList.processRecordArray"
          :key="item.content"
        >
          <div :class="`progess-line ${item.approvalStatus === 1 && index === 0 ? 'sign' : ''}`">
            <div class="circle"></div>
          </div>
          <div class="progess-detail">
            <div class="title-wrap">
              <div class="title">
                {{item.actionRole}}:
                <wx-open-data :id="item.actionUserName" :nameData="{name: item.actionUserName}" />
              </div>
              <div
                class="blue-button"
                @click="approvalRemind(item)"
                v-if="item.approvalStatus === 1 && (progressList.processDynamic.sponsorUserId) == userInfo.id && index === 0"
              >催办</div>
            </div>
            <div class="content-wrap">
              <div class="content-text">
                <div :class="`title type-${item.approvalStatus}`">
                  {{item.actionContent
                  }}
                  {{item.approvalStatus === 1 ? `审批中` : '' }}
                </div>
                <div class="content" v-if="item.content">说明:{{item.content}}</div>
              </div>
              <div class="date">{{$utils.globalTime(item.updateDate)}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import listMixin from '@/utils/mixins/form'
import taskEnum from '@/utils/enums/task.js'
import dynamicEnum from '@/utils/enums/dynamic.js'
import DynamicFileList from '@/components/global/FileUpload/DynamicFileList' // 评论动态展示
import commentBox from '@/components/common/CommentBox.vue' // 评论框
export default {
  components: {
    DynamicFileList,
    commentBox
  },
  mixins: [listMixin],
  props: {
    source: {
      type: String,
      default: ''
    },
    // 任务id
    taskId: {
      type: String,
      required: true
    },
    // 页面类型
    viewType: {
      type: String,
      required: false
    },
    progressList: {},
    taskDetail: {}
  },
  watch: {
    taskId: {
      handler(val) {
        if (val) {
          if (this.source == 'stopdoing') {
            this.getTopList(3)
          } else {
            this.getTopList()
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      commentTypeText: '所有动态', // 动态类型显示文本
      commentTypeEnum: taskEnum.COMMENTTYPEMAP, // 评论类型
      dynamicEnum: dynamicEnum.DYNAMICTYPE, // 动态字典
      type: taskEnum.COMMENTTYPE.all, // 动态类型(默认为所有动态)
      topList: [], // 前5条动态
      nextCount: 0, // 剩余动态数
      allCount: 0,
      pageInfo: {
        page: 1
      },
      list: [],
      replyView: false, // 是否展示回复框
      isActive: 0,
      parentId: '', //回复父id
      loading: false,
      page: 1,
      limit: 10,
      tabType: 1,
      progressList: []
    }
  },
  computed: {},
  mounted() {
    document.body &&
      document.body.addEventListener('click', (e) => {
        if (this.replyView) {
          this.hideComment()
        }
      })
  },
  methods: {
    // 催办
    approvalRemind(item) {
      this.$apis
        .PMTASKAPPROVALDATAREMIND({
          approvalProcessId: item.approvalProcessId,
          businessId: this.taskDetail.projectId,
          businessExtId: this.taskId,
          appointUserId: this.progressList.processDynamic.appointUserId,
          approvalProcessNumber: item.spNo,
          businessType: 3
        })
        .then((rest) => {
          if (rest.status === 200) {
            this.$message.success('催办成功')
            this.$emit('approvalRefresh')
          } else {
            this.$message.error(rest.message)
          }
        })
    },
    tabTypeClick(type) {
      console.log(`tabTypeClick:${type}`)
      this.tabType = Number(type)
    },
    getAllTaskCommentList(type) {
      this.loading = true
      const params = {
        id: this.taskId,
        type: type
      }
      this.$apis.ALLTASKCOMMENTLIST(params).then((res) => {
        if (res.status == 200) {
          this.topList = res.data.rows
          this.total = res.data.total
          this.loading = false
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
          this.loading = false
        }
      })
    },
    // 收起回复框
    hideComment() {
      this.$refs.commentBox[0].clearComment()
      this.replyView = false
    },
    addReplayComment(comment, userId, fileList) {
      if (comment === '' || (comment.trim() === '' && fileList.length === 0)) {
        this.hideComment()
        return
      }
      console.log('回复----', comment)
      const params = {
        parentId: this.parentId,
        taskId: this.taskId,
        comment: comment,
        userId: userId,
        ossFileEntityList: fileList.length > 0 ? fileList : null
      }
      this.$apis.TASKADDCOMMENT(params).then((res) => {
        if (res.status == 200) {
          this.$message({
            msg: res.message,
            type: 'success'
          })
          this.hideComment()
          this.bus.$emit('clearFile')
          this.pageInfo.page = 1
          if (this.source == 'stopdoing') {
            this.getTopList(3)
            return
          }
          this.getTopList()
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 显示回复框
    showReplyBox(item) {
      console.log('点击', item)
      this.isActive = item.id
      this.parentId = item.id
      this.taskId = item.taskId
      this.replyView = true
    },
    fileDownload(fileList) {
      const link = document.createElement('a')
      const dataBean = fileList
      this.$apis
        .FILEDOWNLOAD(dataBean)
        .then((res) => {
          if (res.data === 'downErr') {
            this.$message({
              type: 'error',
              msg: '下载失败!'
            })
          } else {
            const blob = new Blob([res.data], {
              type: 'application/zip'
            })
            const objectUrl = URL.createObjectURL(blob) // 创建URL
            link.href = objectUrl
            link.download = res.location.split(',')[0] // 自定义文件名
            link.click() // 下载文件
            URL.revokeObjectURL(objectUrl) // 释放内存
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 获取动态
    getComment() {
      if (this.taskId) {
        // 最新的动态
        if (this.source == 'stopdoing') {
          this.getTopList(3)
          return
        }
        this.getTopList()
      }
    },
    // 最新的动态
    async getTopList(type) {
      this.loading = true
      await this.$apis
        .TASKNEWDYNAMICLIST({ id: this.taskId, type: type ? type : this.type })
        .then((res) => {
          if (res.status === 200) {
            // console.log(res.data.dynamicList)
            if (
              res.data.dynamicList != null &&
              res.data.dynamicList.length > 0
            ) {
              res.data.dynamicList.forEach((item) => {
                item.comment = this.$utils.changeToHtml(item.comment)
                var fileCommon =
                  (item.ossFileEntityList || '') !== '' &&
                  item.ossFileEntityList !== 'null'
                    ? item.ossFileEntityList
                    : []
                if (fileCommon.length > 0) {
                  var map = {}
                  fileCommon.forEach(function (item) {
                    map[item.name] =
                      map[item.name] == null ? 0 : map[item.name] + 1
                    if (map[item.name]) {
                      var reg = /\.\w+$/
                      var filename = item.name.replace(reg, '')
                      var filetype = item.name.substring(
                        item.name.lastIndexOf('.') + 1
                      )
                      item.name = filename += map[item.name] + '.' + filetype
                    }
                  })
                }
                if (fileCommon.length > 0) {
                  item.ossFileEntityList = fileCommon
                }
              })
              // this.topList = [...res.data.dynamicList]
              this.topList = res.data.dynamicList
            } else {
              this.topList = []
            }
            this.list = []
            this.nextCount = Number(res.data.nextCount)
            this.loading = false
          } else {
            this.loading = false
            this.$message({
              msg: res.message,
              type: 'error'
            })
          }
        })
    },
    // 获取较早的动态
    async getList() {
      this.$apis
        .TASKOLDDYNAMICLIST({
          id: this.taskId,
          type: this.type,
          page: this.pageInfo.page,
          limit: 10
        })
        .then((res) => {
          if (res.status === 200) {
            this.getListMessage(res)
          } else {
            this.$message({
              msg: res.message,
              type: 'error'
            })
          }
        })
    },
    getListMessage: async function (res) {
      if (res.data.rows.length) {
        res.data.rows.forEach((item) => {
          var map = {}
          var fileCommon =
            (item.ossFileEntityList || '') !== '' &&
            item.ossFileEntityList !== 'null'
              ? item.ossFileEntityList
              : []
          if (fileCommon.length > 0) {
            fileCommon.forEach(function (item) {
              map[item.name] = map[item.name] == null ? 0 : map[item.name] + 1
              if (map[item.name]) {
                var reg = /\.\w+$/
                var filename = item.name.replace(reg, '')
                var filetype = item.name.substring(
                  item.name.lastIndexOf('.') + 1
                )
                item.name = filename += map[item.name] + '.' + filetype
              }
            })
          }
          if (fileCommon.length > 0) {
            item.ossFileEntityList = fileCommon
          }
        })
        this.list = this.list.concat(res.data.rows)
        this.allCount = Number(res.data.total)
        this.nextCount =
          Number(this.allCount) - (this.topList.length + this.list.length) || 0
        this.pageInfo.page += 1
        // viewType为notify滚动定位到动态
        this.$nextTick(() => {
          if (this.viewType === 'notify') {
            // 动态距离父元素顶部距离
            const offsetTop =
              document.querySelector('.dynamic-container').offsetTop
            // 滚动容器
            const el = document.querySelector('.fixation-box')
            if (!el) return
            // 滚动容器视口高度
            const height = el.offsetHeight
            // scrollHeight为元素总高度，scrollTop为滚动距离
            const distance =
              el.scrollHeight - height < offsetTop
                ? el.scrollHeight - height
                : offsetTop
            const timer = setInterval(() => {
              el.scrollTop += 10
              if (el.scrollTop >= distance) {
                clearInterval(timer)
              }
            }, 10)
          }
        })
      }
    },
    // 评论删除
    async deleteComment(id) {
      const obj_ = {
        id: id
      }
      this.$apis.TASKDELCOMMENT(id, obj_).then((res) => {
        if (res.status === 200) {
          this.$message({
            msg: '评论删除成功',
            type: 'success'
          })
          this.pageInfo.page = 1
          if (this.source == 'stopdoing') {
            this.getTopList(3)
            return
          }
          this.getTopList()
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 显示较早的动态
    showOld() {
      if (this.nextCount > 0) {
        // this.pageInfo.page = 1
        this.getList()
      } else {
        this.list = []
        this.pageInfo.page = 1
        this.nextCount = Number(this.allCount) - 3
        console.log(this.nextCount)
      }
    },
    // 获取动态动作
    getAction(item) {
      const type = item.type
      let text = ''
      switch (type) {
        case 4:
          text = `修改了任务状态为「${item.comment}」`
          break
        case 5:
          text = `添加了「${item.comment}」为负责人`
          break
        case 6:
          text = `修改了任务开始时间为「${item.comment}」`
          break
        case 7:
          text = `修改了任务截止时间为「${item.comment}」`
          break
        case 10:
          text = `修改了任务优先级为「${item.comment}」`
          break
        case 11:
          text = `删除了协作人「${item.comment}」`
          break
        case 12:
          text = `添加了「${item.comment}」为协作人`
          break
        default:
          text = this.dynamicEnum[type].action
      }
      return text
    },
    // 获取动态内容
    getContent(item) {
      // 4：修改任务状态，5:添加负责人，6：任务开始时间，7：任务结束时间，10：修改优先级，11：删除协作人，12：添加协作人
      const arr = [4, 5, 6, 7, 10, 11, 12, 19]
      if (arr.indexOf(item.type) > -1) {
        return ''
      } else {
        if (item.type === 2 || item.type === 3) {
          return item.comment || ''
        } else {
          return this.$utils.tagFilter(item.comment) || ''
        }
      }
    },
    // 筛选动态
    async dynamicCommand(val) {
      this.type = val
      this.commentTypeText = this.commentTypeEnum[val]
      this.pageInfo.page = 1
      this.getComment()
    }
  }
}
</script>
<style lang="less" scoped>
.approve-content-wrap {
  padding-left: 3px;
  .progress-item {
    .flex-l();
    align-items: flex-start;
    position: relative;
    border-left: 1px solid #3296fa;
    .progess-line {
      background: rgba(58, 120, 246, 0);
      width: 16px;
      height: 16px;
      border-radius: 8px;
      margin-left: -8px;
      .circle {
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background: #3296fa;
        position: absolute;
        left: -4px;
      }
    }

    .progess-detail {
      flex: 1;
      margin: -7px 0px 0px 18px;
      .title-wrap {
        .flex-l();
        .title {
          font-size: 14px;
          font-weight: 400;
          color: #6e7491;
          margin-right: 16px;
          line-height: 22px;
        }
        .blue-button {
          font-size: 12px;
          height: 22px;
          line-height: 20px;
          background: #ffa502;
          border: 1px solid #ffa502;
          min-width: 60px;
          max-width: 60px;
          padding: 0px;
          &:hover {
            opacity: 0.85;
          }
        }
      }
      .content-wrap {
        .flex-bt();
        flex-wrap: wrap;
        background: #f5f6fa;
        border-radius: 8px;
        padding: 8px 12px;
        margin: 8px 0px 24px 0px;
        align-items: flex-start;
        .content-text {
          font-size: 12px;
          font-weight: 500;
          color: #444558;
          margin-right: 16px;
          line-height: 16px;
          .content {
            margin-top: 4px;
            word-break: break-all;
          }
        }
        .type-1 {
          color: #ff9900;
        }
        .type-2 {
          color: #ff572b;
        }
        .type-3 {
          color: #00ba88;
        }
        .date {
          font-size: 12px;
          font-weight: 500;
          color: #a0a3bd;
        }
      }
    }
  }
  .progress-item.dotted {
    border-left: 1px dashed #3296fa;
    .progess-line {
      background: #d6eafe;
    }
    .circle {
      top: 4px;
    }
    .progess-detail {
      margin-top: -3px;
    }
  }
  .progress-item:last-child {
    border-left: 1px solid #fff;
  }
}
.dynamic-menu-wrap {
  .flex-bt();
  .dynamic-type-wrap {
    .flex-l();
  }
  .dynamic-type {
    .flex-l();
    ::v-deep .el-dropdown-selfdefine {
      span,
      .el-icon-arrow-down {
        color: #a0a3bd;
      }
    }
    font-size: 18px;
    color: #a0a3bd;
    margin-right: 24px;
    cursor: pointer;
    font-weight: 500;
    &:hover {
      color: #005eff;
    }
  }
  .dynamic-type.select {
    color: #14142b;
    ::v-deep .el-dropdown-selfdefine {
      span,
      .el-icon-arrow-down {
        color: #14142b;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import '../../../assets/styles/variable.scss';
.trend-btn-reply-operation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  .operation-left {
    margin-left: 32px;
    span {
      font-size: 14px;
      font-weight: 400;
      color: #a0a3bd;
      cursor: pointer;
      &:hover {
        color: #3a78f6;
      }
    }
  }
  .operation-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
  .operation-icon-span {
    cursor: pointer;
  }
  .line {
    display: inline;
    width: 1px;
    margin: 0 12px;
    height: 10px;
    border-left: 1px #d9dbe9 solid;
  }
  .operation-icon {
    font-size: 18px;
    color: #a0a3bd;
    &:hover {
      color: #3a78f6;
    }
  }
}
.task-sonCommentArray {
  margin-left: 30px;
  .son-task-list {
    margin-top: 10px;
    .son-task-name {
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
      color: #a0a3bd;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      .avatar {
        margin-right: 8px;
      }
      .son-name {
        font-size: 14px;
        display: inline-block;
        flex: 1;
        padding-right: 16px;
      }
      .son-task-time {
        font-size: 12px;
        color: #a0a3bd;
        margin-right: 10px;
      }
    }
    .son-task-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .son-task-title {
        flex: 1;
        color: #444558;
        line-height: 21px;
        font-size: 14px;
        margin-top: 4px;
        font-weight: 500;
        margin-left: 32px;
      }
    }
  }
}
::v-deep {
  .comment-box .comment-input-box {
    padding: 6px 6px 0px 6px !important;
  }
}
.reply-box {
  margin: 0px 0 8px 30px;
  .trend-btn-reply {
    width: 100%;
    border: 1px #f00 solid;
    color: #3a78f6;
    cursor: pointer;
    font-weight: 500;
    display: none;
    text-align: right;
  }

  .trend-input-reply {
    width: 100%;
    height: 130px;
    border-radius: 8px;
    background: #fff;
    border: 1px solid #3a78f6;
    box-shadow: 0px -3px 10px 0px rgba(50, 150, 250, 0.14) inset;
  }
}
.file-list-wrap {
  padding-left: 33px;
  margin-bottom: 8px;
}
.dynamic-menu-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  border-top: 1px #d9dbe9 solid;
  padding-top: 20px;
  .stopdoing-dynamic-title {
    font-size: 18px;
    font-weight: 500;
    color: #14142b;
  }
}
.list-container {
  background: #f7f7fc;
  padding: 30px 20px 20px 20px;
  .dynamic-menu {
    color: #a0a3bd;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    line-height: 20px;
    .menu-icon {
      font-size: 20px;
      margin-right: 8px;
      width: 20px;
      height: 20px;
    }
    &:hover {
      color: #0d84eb;
    }
  }
}
.list-box {
  margin: 0px 0px 0px 0px;
  .avatar {
    border-radius: 50%;
    height: 24px;
    width: 24px;
  }
  .name {
    color: #3a78f6;
  }
  .dynamic-icon {
    font-size: 20px;
    color: #a0a3bd;
  }
  .time {
    top: 12px;
    right: 12px;
    font-size: 12px;
    color: #a0a3bd;
  }
  .svg-icon {
    width: 20px;
    height: 20px;
  }
  .dynamic-action {
    width: 75%;
    line-height: 20px;
    margin-left: 8px;
    margin-top: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    span {
      font-size: 14px;
      // display: block;
      // font-weight: 500;
      // line-height: 24px;
      // font-size:16px;
      line-height: 22px;
      font-weight: 400;
      color: #a0a3bd;
    }
    .user-click-wrap {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      color: #a0a3bd;
      font-size: 14px;
      min-width: 120px;
      visibility: hidden;
      cursor: pointer;
      line-height: 22px;
      .download-click {
        margin-left: 14px;
      }
      .comment-delete {
        margin-left: 14px;
      }
      div {
        &:hover {
          color: #3a78f6;
        }
      }
    }
    // .name{
    //   line-height:22px;
    //   font-weight:400;
    //   margin:0px 0px 7px 0px;
    //   color:#A0A3BD;
    // }
  }
  .describe {
    // border-left: 2px solid #3a78f6;
    // padding-left: 8px;
    word-break: break-word;
    font-weight: 500;
    text-align: LEFT;
    color: #444558;
    // height: 16px;
    line-height: 21px;
    font-size: 14px;
    margin: 6px 0 6px 33px !important;
    &:hover {
      // background: #F0F0F0;
    }
  }
  &:hover {
    .user-click-wrap {
      visibility: visible;
    }
  }
}
.list-box-comment {
  border-radius: 8px;
  padding: 4px 0px 4px;
  width: 100%;
  box-sizing: border-box;
  .time {
    top: 12px;
    right: 20px;
    font-size: 12px;
    color: #a0a3bd;
  }
  &:hover {
    background: #fff;
    .reply-box {
      .trend-btn-reply {
        display: block;
      }
    }
  }
}
.list-box-dynamic {
  padding: 4px 12px 4px;
  width: 100%;
}
.empty-box {
  padding: 20px 0;
  height: 240px;
  text-align: center;
  .img {
    width: 164px;
    height: 164px;
  }
  .null-text {
    font-size: 14px;
    font-weight: 400;
    color: #a0a3bd;
  }
}
::v-deep .task-el-dropdown {
  .el-dropdown-selfdefine span {
    font-size: 18px;
    font-weight: 500;
    color: #14142b;
  }
  .el-icon-arrow-down {
    font-size: 18px;
    font-weight: 700;
    color: #14142b;
  }
  &:hover {
    .el-dropdown-selfdefine span {
      color: #3a78f6 !important;
    }
    .el-icon-arrow-down {
      color: #3a78f6 !important;
    }
  }
}
</style>
