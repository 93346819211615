var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: `是否提交 任务 审批？`,
        visible: _vm.dialogVisible,
        width: "640px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c("div", { staticClass: "main-content" }, [
        _c("div", { staticClass: "list-wrap" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v(
              _vm._s(
                _vm.businessActionTypeName[
                  _vm.$store.state.task.taskApproveRemindObj
                    .businessActionType || 1
                ]
              ) + "需要提交审批，审批通过后将自动生效"
            ),
          ]),
          _vm.$store.state.task.taskApproveRemindObj.approvalCheckData &&
          _vm.$store.state.task.taskApproveRemindObj.approvalCheckData
            .appointUserName
            ? _c(
                "div",
                { staticClass: "user" },
                [
                  _vm._v(" 审批人： "),
                  _c("wx-open-data", {
                    attrs: {
                      id: _vm.$store.state.task.taskApproveRemindObj
                        .approvalCheckData.appointUserName,
                      nameData: {
                        name: _vm.$store.state.task.taskApproveRemindObj
                          .approvalCheckData.appointUserName,
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
        _c(
          "div",
          {
            class: `bottom-btn-wrap btn-type-${
              _vm.$store.state.task.taskApproveRemindObj.businessActionType || 1
            }`,
          },
          [
            _c(
              "div",
              { staticClass: "bottom-step-buttons" },
              [
                _c(
                  "button",
                  {
                    staticClass: "left-button",
                    on: {
                      click: function ($event) {
                        return _vm.handleClose(false)
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
                _c(
                  "L-button",
                  {
                    attrs: { loading: _vm.saveLoading },
                    on: {
                      clickEvt: function ($event) {
                        return _vm.save(1)
                      },
                    },
                  },
                  [_vm._v("提交审批")]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }