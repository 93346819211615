<template>
  <div class="file-upload" :class="'file-upload-' + formPage">
    <!-- drag -->
    <el-upload
      ref="commentUpload"
      class="upload-demo"
      :action="`${baseUrl}${$apis.FILEUPLOADURL}`"
      :headers="headers"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :before-remove="beforeRemove"
      :before-upload="beforeUpload"
      :on-progress="onProgress"
      :on-success="onSuccess"
      :on-error="onError"
      :show-file-list="showFileList"
      :accept="''"
      multiple
      drag
    >
      <div slot="trigger" class="upload-btn-wrap">
        <div class="upload-btn">
          <Icon
            v-if="imgVisible"
            icon-class="icon-attachimg"
            class="icon-upload icon-upload-img"
            @click="uploadType('image/*')"
          />
          <Icon
            icon-class="icon-attachfile"
            class="icon-upload"
            @click="uploadType('')"
          />
        </div>
      </div>
    </el-upload>
    <upload-file-list
      :form-page="formPage"
      :oss-file-entity-list="uploadFileList"
      @delete="fileDelete"
    />
  </div>
</template>

<script>
import formMixin from '@/utils/mixins/form'
import uploadFileList from './uploadFileList.vue' // 评论动态展示
import baseUrl from '@/api/env' // 基础url
export default {
  components: {
    uploadFileList
  },
  mixins: [formMixin],
  props: {
    // 任务id
    width: {
      type: Number,
      required: false,
      default: 710
    },
    formPage: {
      type: String,
      required: false,
      default: ''
    },
    // 这个是输入框拖拽上传的数据
    inputDragFileList: {
      type: Object,
      default: () => {}
    },
    imgVisible: {
      default: false
    }
  },
  data() {
    return {
      baseUrl: baseUrl,
      headers: {},
      files: [],
      fileList: [],
      uploadFileList: [],
      pasteFileList: {},
      showFileList: true,
      accept: ''
    }
  },
  computed: {},
  watch: {
    inputDragFileList: function (newval, oldval) {
      this.uploadFileList.push(newval)
      this.$emit('fileFresh', this.uploadFileList)
    },
    pasteFileList: function (newVal, oldVal) {
      if ((this.pasteFileList.files || []).length > 0) {
        this.upLoads(this.pasteFileList.files)
      }
    }
  },
  created() {
    this.headers = {
      Authorization: this.token
    }
  },
  mounted() {
    this.bus(this).$on('clearFile', () => {
      this.uploadFileList = []
      this.$emit('fileFresh', [])
      this.$refs.commentUpload.clearFiles()
      this.$refs['commentUpload'].clearFiles()
    })
    this.bus(this).$on('pasteFileUpload', (file) => {
      this.pasteFileList = file
    })
  },
  methods: {
    uploadType(type) {
      var html = document.getElementsByClassName('el-upload__input')
      console.log('html', html)
      html[1].setAttribute('accept', type)
      this.accept = type
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    handlePreview(file) {
      // console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warn(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      )
    },
    beforeRemove(file, fileList) {
      const isLt2M = file.size / 1024 / 1024 < 50
      if (!isLt2M) {
        return this.$confirm(
          `上传文件大小不能超过 50MB!,确定移除 ${file.name}？`
        )
      } else {
        return this.$confirm(`确定移除 ${file.name}？`)
      }
    },
    onProgress(event, file, fileList) {
      // console.log(event);
      // console.log(file);
      // console.log(fileList);
    },
    onSuccess(response, file, fileList) {
      if (response.status === 200) {
        response.data.uid = file.uid
        this.uploadFileList.push(response.data)
        fileList.forEach((item, index) => {
          if (file.uid === item.uid) {
            fileList.splice(index, 1)
          }
        })
        this.$emit('fileFresh', this.uploadFileList)
      } else {
        this.$message.warn(response.message)
      }
    },
    onError(response, file, fileList) {
      fileList.forEach((item, index) => {
        if (file.uid === item.uid) {
          fileList.splice(index, 1)
        }
      })
      this.$message.warn(`文件[${file.name}]上传异常，请稍后再试哦！`)
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 50
      //  if(!isLt2M) {
      //     this.$message({
      //       message: '上传文件大小不能超过 50MB!',
      //       type: 'warn'
      //     });
      //  }
      return isLt2M
    },
    fileDelete(list) {
      this.$nextTick(() => {
        this.uploadFileList = list
      })
      this.$emit('fileFresh', list)
    },
    // 上传图片
    async upLoads(file) {
      const formData = new FormData()
      formData.append('file', file[0])
      this.$apis.FILEUPLOAD(formData).then((res) => {
        if (res.status === '200') {
          this.uploadFileList.push(res.data)
          this.$emit('fileFresh', this.uploadFileList)
        } else {
          this.$message.warn(`文件[${file[0].name}]上传异常，请稍后再试哦！`)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.upload-demo {
  width: 100%;
}
.upload-btn-wrap {
  width: 100%;
  position: relative;
  height: 30px;
  .drag-text {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    height: 30px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    font-size: 14px;
    color: #444558;
    line-height: 30px;
    opacity: 0;
  }
}
.upload-btn {
  height: 30px;
  font-size: 22px;
  .flex-l();
  color: #444558;
  text-align: center;
  .icon-upload {
    width: 30px;
    height: 30px;
    &:hover {
      color: #3a78f6;
      background: rgba(58, 120, 246, 0.1);
      border-radius: 8px;
    }
  }
  .icon-upload-img {
    margin-right: 8px;
  }
}
.file-upload {
  display: flex;
  align-content: space-between;
  align-items: center;
  flex-wrap: wrap-reverse;
}
::v-deep .file-upload-okrList .el-upload-list {
  flex-wrap: wrap;
  display: flex;
  align-content: flex-start;
  align-items: center;
  .el-upload-list__item {
    width: 365px !important;
    margin-left: 20px;
  }
}
::v-deep .el-upload {
  padding-right: 50px;
  box-sizing: border-box;
}
::v-deep .upload-comment-wrap .el-upload-dragger.is-dragover {
  background-color: #fff;
}
::v-deep .el-upload-dragger.is-dragover .drag-text {
  opacity: 1;
}
::v-deep .file-upload-okrList {
  .upload-comment-wrap {
    margin-top: -48px;
  }
  .el-upload {
    width: 30px;
    .el-upload-dragger {
      width: 30px;
      .upload-btn-wrap {
        .upload-btn {
          width: 30px;
        }
      }
    }
  }
}
</style>
<style>
.mainTaskDetail .comment-box {
  position: relative !important;
}
.comment-box .el-upload-dragger {
  /* position: absolute;
  top:0px;
  bottom:0px; */
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  border: none;
  box-sizing: border-box;
}
.file-upload-okrList .el-upload-list {
  width: 100% !important;
  flex-wrap: wrap;
  display: flex;
  align-content: flex-start;
  align-items: center;
  margin-right: 20px;
}
.file-upload-okrList .el-upload-list__item {
  width: 365px !important;
  margin-left: 20px;
}
</style>
