<template>
  <div
    class="confidence_select"
    :style="
      (type === 1 ? userInfo.id === topMembersBean.createUserid : canEdit)
        ? ''
        : 'cursor: default;'
    "
  >
    <el-dropdown
      v-if="type === 1 ? userInfo.id === topMembersBean.createUserid : canEdit"
      trigger="click"
    >
      <el-tooltip placement="top" :open-delay="300" content="信心值">
        <div class="el-dropdown-link confidence-box">
          <img
            :src="
              require(`@/assets/imgs/okr/objective_set_confidence${confidenceIndex}.png`)
            "
            class="confidence_icon"
            @dragstart="dragstartEvent"
          />
        </div>
      </el-tooltip>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="index in 10"
          :key="index"
          class="confidence_content"
          @click.native="selectConfidence(index)"
        >
          <div class="confidence-box">
            <img
              :src="
                require(`@/assets/imgs/okr/objective_set_confidence${index}.png`)
              "
              class="confidence_icon"
              @dragstart="dragstartEvent"
            />
            <div class="unit-text">分</div>
          </div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <div v-else class="el-dropdown">
      <el-tooltip placement="top" :open-delay="300" content="信心值">
        <div class="el-dropdown-link confidence-box">
          <img
            :src="
              require(`@/assets/imgs/okr/objective_set_confidence${confidenceIndex}.png`)
            "
            class="confidence_icon"
            @dragstart="dragstartEvent"
          />
        </div>
      </el-tooltip>
    </div>
  </div>
</template>
<script>
export default {
  model: {
    prop: 'confidenceValue',
    event: 'change'
  },
  props: {
    confidenceValue: {
      type: Number,
      default: 5
    },
    type: {
      type: Number,
      default: 1
    },
    canEdit: {
      // 可编辑
      type: Boolean,
      default: false
    },
    topMembersBeans: {
      type: Object,
      default: () => {
        return {
          createUserid: ''
        }
      }
    }
  },
  data() {
    return {
      confidenceIndex: this.confidenceValue,
      topMembersBean: {
        createUserid: ''
      }
    }
  },
  computed: {},
  watch: {
    confidenceValue: {
      handler(val) {
        this.confidenceIndex = val
      }
    },
    topMembersBeans: {
      handler(val) {
        this.topMembersBean = val
      },
      immediate: true
    }
  },
  created() {
    this.bus(this).$on('InitializeData', (magDate) => {
      if (!magDate) {
        return
      }
      this.topMembersBean = magDate
    })
  },
  methods: {
    selectConfidence(index) {
      this.confidenceIndex = index
      this.$emit('change', index)
    },
    dragstartEvent(e) {
      // 禁止拖拽
      e.preventDefault()
    }
  }
}
</script>
<style lang="scss" scoped>
.el-dropdown-menu {
  height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100px;
  // @include scroll-beautify;
}
.el-dropdown-menu__item {
  align-items: center;
}
.confidence-box {
  display: flex;
  align-items: center;
  // position: relative;
  .confidence_icon {
    width: 26px;
    height: 24px;
    object-fit: fill;
    object-fit: contain;
    object-fit: scale-down;
  }
  .unit-text {
    margin-left: 8px;
  }
  .confidence-text {
    position: absolute;
    font-weight: 700;
    top: -1px;
    left: 1px;
    width: 26px;
    height: 24px;
    line-height: 24px;
    font-size: 15px;
    text-align: center;
    color: #ed2e7e;
  }
}
.confidence_select {
  cursor: pointer;
  .el-dropdown {
    text-align: LEFT;
    color: #444558;
    line-height: 32px;
    .el-dropdown-link {
      display: flex;
      align-items: center;
    }
  }
}
.el-dropdown-menu__item {
  display: flex;
  font-size: 14px;
  color: #6e7491;
  line-height: 24px;
  padding: 4px 12px;
  align-items: center;
  &:hover {
    background: #f5f6fa;
    color: #444558;
  }
}
</style>
