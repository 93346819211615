const versionNumber = 'v1' // 版本
const versionNumber2 = 'v2' // 版本
// 头部
export default [
  {
  // 获取成长过程接口
  headerUserProgressList: `/api/admin/${versionNumber}/company/userProgress/list`
},
{
  // 获取极光数据
  headerGetAuthPayload: `/api/notify/${versionNumber}/jg/auth/getAuthPayload`
},
{
  // 消息已读
  headerSetNotifyMsgToReaded: `/api/notify/${versionNumber}/notify/setNotifyMsgToReaded`
},
{
  // 消息数量
  headerGetNoReadCount: `/api/notify/${versionNumber}/notify/getNoReadCount`
},
{
  // 头部搜索获取目标
  headerObjListSearch: `/api/ranloWeb/${versionNumber}/objectives/list/search`
},
{
  // 头部搜索获取任务
  headerTaskListSearch: `/api/ranloTask/${versionNumber}/task/searchTaskList`
},
{
  // 获取对齐目标详情
  headerObjAlignInfo: `/api/ranloWeb/${versionNumber2}/objectivescollaborator/info`
},
{
  // 获取项目概述
  headerProjectDesc: `/api/project/${versionNumber}/project/`
},
{
  // 响应日程
  headerScheduleDecision: `/api/schedule/${versionNumber}/schedule/decision`
},
{
  // 获取企业列表
  headerGetCompanyList: `/api/admin/${versionNumber2}/company/list`
},
{
  // 根据企业ID获取企业信息
  headerGetCompanyDetail: `/api/admin/${versionNumber}/company/`
},
{
  // 提交意见反馈
  headerSubmitFeedBack: `/api/admin/${versionNumber}/feedback/add`
},
{
  // 创建企业
  headerCompanyAdd: `/api/admin/${versionNumber}/company/add`
},
{
  // 创建周期
  headerAddCycle: `/api/ranloWeb/${versionNumber}/setcycle/addQuarterlyCycle`
},
{
  // 创建默认任务
  headerAddInitUserTask: `/api/ranloTask/${versionNumber}/customConditionsUser/initUserTaskConditions`
},
{
  // 创建默认通知设置
  headerAddInitNotifySet: `/api/notify/${versionNumber}/setNotify/initNotifySet`
},
{
  // 查询历史企业列表
  headerGetComHistoryList: `/api/admin/${versionNumber}/company/invitations/approve/apply/list`
},
{
  // 删除企业
  headerDelComById: `/api/admin/${versionNumber}/company/delete/`
},
{
  // 修改个人信息
  headerUserUpdate: `/api/admin/${versionNumber}/user/update`
},
{
  // 头像文件上传
  userFileUpload: `/api/admin/${versionNumber}/user/updateAvatar`
},
{
  // 修改手机号
  headerUpdateMobile: `/api/admin/${versionNumber}/user/update/mobile`
},
{
  // 修改手机号
  headerUpdatePassword: `/api/admin/${versionNumber}/user/`
},
{
  // 修改邮箱
  headerUpdateEmail: `/api/admin/${versionNumber}/user/update/email`
},
{
  // 修改通知设置
  headerSetNotifyUpdate: `/api/notify/${versionNumber}/setNotify/update`
},
{
  // 获取通知设置
  headerGetNotifyUpdate: `/api/notify/${versionNumber}/setNotify/list`
},
{
  // 获取更新日志
  getSystemLogList: `/api/admin/${versionNumber}/systemLog/getSystemLogList`
},
{
  // 获取邀请二维码信息
  getUserSetInvite: `/api/admin/${versionNumber}/user/set/invitation`
},
{
  // 获取消息列表
  getNoticeList: `/api/notify/${versionNumber}/notify/list`
},
{
  // 所有消息已读
  setNoticeReadAll: `/api/notify/${versionNumber}/notify/noread`
},
{
  // 消息已读id
  setNoticeReadById: `/api/notify/${versionNumber}/notify/setNotifyMsgToReaded`
}
]
