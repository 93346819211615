<template>
  <div class="select">
    <!-- 单个带顶部带label的 -->
    <div v-if="type === 1" class="one">
      <span class="label">{{ label }}</span>
      <el-select
        v-model="val"
        :filterable="isFilterable"
        :multiple="isMultiple"
        placeholder="请选择"
        ref="selectChangeRef"
        @change="selectedChange"
        @visible-change="selectedVisibleChange"
      >
        <el-option v-for="item in data" :key="item.id" :label="item.name" :value="item.id">
          <span style="float: left">
            <template v-if="label === '部门'">
              <wx-open-data :type="2" :id="item.name" :nameData="{name:item.name}" />
            </template>
            <template v-else>{{item.name }}</template>
          </span>
        </el-option>
        <slot slot="prefix" v-if="label === '部门'">
          <wx-open-data :type="2" :id="data[departIndex].name" :nameData="{name:data[departIndex].name}" />
        </slot>
      </el-select>
    </div>
    <!-- 联动 -->
    <div v-if="type === 2" class="two">
      <div class="one">
        <span class="label">{{ groupName }}</span>
        <el-select
          v-model="cascadeA"
          :multiple="isMultiple"
          placeholder="请选择"
          @change="selectedChange"
        >
          <el-option
            v-for="item in cascadeDataA"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
            <span style="float: left">{{ item.name }}</span>
          </el-option>
        </el-select>
      </div>
      <div class="one">
        <span class="label">{{ itemName }}</span>
        <el-select
          v-model="cascadeB"
          :filterable="isFilterable"
          :multiple="isMultiple"
          placeholder="请选择"
          @change="selectedChildChange"
        >
          <el-option
            v-for="item in cascadeDataB"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
            <span style="float: left">{{ item.name }}</span>
          </el-option>
        </el-select>
      </div>
    </div>
    <!-- 带单选组 -->
    <div v-if="type === 3" class="three">
      <div class="type-tips">按部门或个人查看</div>
      <div class="one">
        <el-radio v-model="selectedType" label="0" @change="selectType">部门</el-radio>
        <el-select
          v-model="partVal"
          filterable
          :multiple="isMultiple"
          placeholder="请选择"
          :disabled="selectedType === '1' ? true : false"
          @change="selectedChange"
        >
          <el-option
            v-for="item in cascadeDataA"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
            <span style="float: left">{{ item.name }}</span>
          </el-option>
        </el-select>
      </div>
      <div class="one">
        <el-radio v-model="selectedType" label="1" @change="selectType">个人</el-radio>
        <el-select
          v-model="personalVal"
          filterable
          :disabled="selectedType === '0' ? true : false"
          :multiple="isMultiple"
          placeholder="请选择"
          @change="selectedChildChange"
        >
          <el-option
            v-for="item in cascadeDataB"
            :key="item.id"
            :label="item.employee"
            :value="item.id"
          >
            <span style="float: left">
              {{
              item.employee.length > 4
              ? item.employee.substring(0, 4) + '...'
              : item.employee
              }}
            </span>
            <span style="float: right">
              {{
              item.part.length > 4
              ? item.part.substring(0, 4) + '...'
              : item.part
              }}
            </span>
          </el-option>
        </el-select>
      </div>
    </div>
    <!--大小周期-->
    <transition name="fade" mode="out-in">
      <div v-if="type === 4 && getMajorCycleVal" class="cycle">
        <div class="one">
          <span class="label">周期:</span>
          <el-select
            v-model="majorCycle"
            :multiple="isMultiple"
            placeholder="请选择"
            ref="getMajorCycleRef"
            @change="getMajorCycle"
            @visible-change="getMajorCycleVisible"
          >
            <el-option
              v-for="item in majorCycleList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
              <span style="float: left">{{ item.name }}</span>
            </el-option>
          </el-select>
        </div>
        <div class="one">
          <span class="label">{{ itemName }}</span>
          <el-select
            v-model="minorCycle"
            :filterable="isFilterable"
            :multiple="isMultiple"
            placeholder="请选择"
            ref="getMinorCycleRef"
            @change="getMinorCycle"
            @visible-change="getMinorCycleVisible"
          >
            <el-option
              v-for="item in minorCycleList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
              <span style="float: left">{{ item.name }}</span>
            </el-option>
          </el-select>
        </div>
      </div>
    </transition>

    <!--大周期-->
    <div v-if="type === 5" class="cycle">
      <div class="one">
        <span class="label">周期</span>
        <el-select
          v-model="majorCycle"
          :multiple="isMultiple"
          placeholder="请选择"
          @change="getMajorCycle"
        >
          <el-option
            v-for="item in majorCycleList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
            <span style="float: left">{{ item.name }}</span>
          </el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    cascadeDataA: {
      // 级联数组
      type: Array,
      default: () => []
    },
    cascadeDataB: {
      // 级联二级数组
      type: Array,
      default: () => []
    },
    cascadeA: {
      // 级联数据A
      type: String,
      default: ''
    },
    cascadeB: {
      // 级联数据B
      type: String,
      default: ''
    },
    val: {
      type: String,
      default: ''
    },
    isFilterable: {
      type: Boolean,
      default: false
    },
    isMultiple: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    groupName: {
      type: String,
      default: ''
    },
    itemName: {
      type: String,
      default: ''
    },
    type: {
      // 类型：1：单个带label  2：有联动 3：带单选组
      type: Number,
      default: 3
    },
    data: {
      // 默认数组
      type: Array,
      default: () => []
    },
    selectWay: {
      type: String,
      default: ''
    },
    partVal: {
      type: String,
      default: ''
    },
    personalVal: {
      type: String,
      default: ''
    },
    majorCycle: {
      // 大周期
      type: String,
      default: ''
    },
    minorCycle: {
      // 小周期
      type: String,
      default: ''
    },
    cycleType: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      // simpleVal: '',
      selectedVisibleChangeState: false,
      getMinorCycleState: false,
      getMajorCycleState: false,
      selectedType: '1',
      majorCycleList: [], // 大周期数组
      minorCycleList: [], // 小周期集合
      getMajorCycleVal: false
    }
  },
  watch: {
    selectWay(val) {
      this.selectedType = val.toString()
    },
    majorCycle() {
      if (this.minorCycle !== '') {
        this.getMajorCycle(
          this.majorCycle,
          this.minorCycle !== '' ? true : false
        )
      }
    }
  },
  mounted() {
    if (this.type === 4 || this.type === 5) {
      this.getMajorCycleList()
    }
    var scrollDom = document.getElementById('details-container-wrap')
    scrollDom && scrollDom.addEventListener('scroll', () => {
      this.selectedVisibleChangeHide()
    })
  },
  methods: {
    selectedVisibleChange(val) {
      this.selectedVisibleChangeState = val
    },
    selectedVisibleChangeHide() {
      if (this.selectedVisibleChangeState) {
        this.selectedVisibleChangeState = false
        this.$refs.selectChangeRef.blur()
      }
      if (this.getMinorCycleState) {
        this.getMinorCycleState = false
        this.$refs.getMinorCycleRef.blur()
      }
      if (this.getMajorCycleState) {
        this.getMajorCycleState = false
        this.$refs.getMajorCycleRef.blur()
      }
    },
    // 获取周期
    getMajorCycleList() {
      this.$apis.CYCLIST().then((rest) => {
        // 周期数组存储到仓库 ---TODO---
        (rest.data || []).forEach((list) => {
          (list.cycleInfoList || []).forEach((item) => {
            if ((list.type === 1 || list.type === 2) && item.type === 0) {
              item.name = `${item.startDate.substring(0, 4)}年 ${item.name}`
            }
          })
        })
        this.majorCycleList = rest.data
        if (this.minorCycle !== '') {
          this.getMajorCycle(this.majorCycle, true)
        }
      })
    },
    // 选择大周期
    getMajorCycle(id, flag) {
      if (!flag) {
        this.minorCycle = ''
      }
      this.majorCycleList.forEach((item) => {
        if (item.id === id) {
          this.minorCycleList = item.cycleInfoList
          if (this.cycleType === 1 && this.type === 4 && this.minorCycle === '') {
            this.minorCycle = item.cycleInfoList[0].id
          }
        }
      })
      this.$emit('getMajorCycle', id)
      this.$emit('setMinorCycle', {
        majorCycle: this.majorCycle,
        minorCycle: this.minorCycle
      })
      this.getMajorCycleVal = true
    },
    // 选择小周期
    getMinorCycle() {
      this.$emit('setMinorCycle', {
        majorCycle: this.majorCycle,
        minorCycle: this.minorCycle
      })
    },
    getMinorCycleVisible(val) {
      this.getMinorCycleState = val
    },
    getMajorCycleVisible(val) {
      this.getMajorCycleState = val
    },
    selectType(val) {
      this.selectWay = val
      this.$emit('getSelectWay', val)
    },
    selectedChange(val) {
      this.val = val
      this.$emit('getSelected', val)
    },
    selectedChildChange(val) {
      this.val = val
      this.$emit('getChildSelected', val)
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-radio {
  display: block;
  margin-bottom: 4px;
  font-size: 12px;
}
.select {
  // display: flex;
  display: inline-block;
  .one {
    display: inline-block;
    span {
      display: block;
      margin-bottom: 6px;
      font-size: 12px;
      color: #333333;
    }
    margin-left: 14px;
  }
  .cycle {
    margin-bottom: 20px;
  }
  .three {
    .type-tips {
      padding-left: 14px;
      padding: 20px 14px;
      padding-top: 0;
    }
  }
}
::v-deep .el-select {
  width: 185px;
  height: 34px;
}

@function twice($px) {
  @return $px * 0.67px;
}

@media screen and (max-width: 1280px) {
  ::v-deep .el-radio {
    display: block;
    margin-bottom: twice(4);
    font-size: 12px;
  }
  .select {
    // display: flex;
    display: inline-block;
    .one {
      display: inline-block;
      span {
        display: block;
        margin-bottom: twice(6);
        font-size: 12px;
        color: #333333;
      }
      margin-left: twice(14);
    }
    .two {
    }
    .three {
      .type-tips {
        padding-left: twice(14);
        padding: twice(20) twice(14);
        padding-top: 0;
      }
    }
  }
  ::v-deep .el-select {
    // width: twice(185);
    height: twice(34);
  }
}
</style>
