<template>
  <div class="kpi-item-wrap" @click.stop="toDetail">
    <div class="kpi-item-content">
      <div class="kpi-item-left">
        <div :class="`title-wrap title-wrap-${fromType !== 'kpiPlan'}`">
          <avatar-img
            v-if="fromType === 'myDepart' && kpiDetail.userDto"
            :avatar="kpiDetail.userDto.avatar"
            :can-delete="false"
            :name="kpiDetail.userDto.name"
            :size="[24, 18]"
          />
          <el-tooltip :content="item.name">
            <div class="name">{{ item.name }}</div>
          </el-tooltip>
          <div class="status-wrap">
            <kpi-status
              :type="fromType === 'kpiPlan' ? item.status : kpiDetail.status"
              :classType="fromType === 'kpiPlan' ? 'kpiPlan' : 'kpi'"
            />
          </div>
        </div>
        <div class="kpi-detail-wrap">
          <el-tooltip
            :content="`周期：${item.cycleYear} ${
              item.cycleId && kpiCycleTypeList[item.cycleId].name
            }`"
            :disabled="true"
          >
            <div class="list">
              <Icon icon-class="icon-zhouqi" class="icon" />
              周期：{{ item.cycleYear }}
              {{ item.cycleId && kpiCycleTypeList[item.cycleId].name }}
            </div>
          </el-tooltip>
          <el-tooltip
            :content="`考核时间：${$utils.dateToYYYYMMDD(
              item.startDate
            )}-${$utils.dateToYYYYMMDD(item.endDate)}`"
            :disabled="true"
          >
            <div class="list">
              <Icon icon-class="icon-biaoge-shijian" class="icon" />
              考核时间：{{ $utils.dateToYYYYMMDD(item.startDate) }}-{{
              $utils.dateToYYYYMMDD(item.endDate)
              }}
            </div>
          </el-tooltip>
          <div v-if="fromType === 'kpiPlan'" class="list list-count">
            <Icon icon-class="icon-biaoge-xiezuoren" class="icon" />
            考核人数：{{ item.userNum }}人
          </div>
          <div class="list list-user">
            <Icon icon-class="icon-fuzeren3" class="icon" />负责人：
            <div v-for="(userItem, userIndex) in item.examinePowerDtoList" :key="userItem.userId">
              <wx-open-data
                :id="userItem.userInfo && userItem.userInfo.name"
                :name-data="{ name: userItem.userInfo && userItem.userInfo.name }"
              />
              {{
              userIndex !== item.examinePowerDtoList.length - 1 ? '、' : ''
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="kpi-item-right">
        <slot name="kpiItemRight"></slot>
      </div>
    </div>
    <slot name="kpiProcess"></slot>
  </div>
</template>
<script>
import kpiStatus from '@/views/kpiV2/components/kpiStatus' // 绩效状态
export default {
  components: {
    kpiStatus
  },
  props: {
    item: {},
    fromType: {
      default: 'kpiPlan'
    },
    kpiDetail: {}
  },
  data() {
    return {
    }
  },
  computed: {},
  mounted() {},
  methods: {
    toDetail() {
      this.$emit('toDetail', this.item, this.kpiDetail)
    }
  }
}
</script>
<style lang="less" scoped>
.kpi-item-wrap {
  background: #ffffff;
  padding: 20px 12px 20px 24px;
  margin-top: 12px;
  border-radius: 16px;
  cursor: pointer;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  .kpi-item-content {
    .flex-bt();
    .kpi-item-left {
      .title-wrap {
        position: relative;
        font-size: 18px;
        line-height: 26px;
        color: #14142b;
        .flex-l();
        ::v-deep {
          .avatar-box {
            font-weight: 600;
            margin-right: 8px;
          }
        }
        .name {
          .text-n(1);
          max-width: 863px;
          font-weight: 600;
        }
        .status-wrap {
          margin-left: 12px;
        }
      }
      .title-wrap-true {
        padding-left: 12px;
        &::after {
          content: '';
          width: 4px;
          height: 16px;
          background: #3a70ff;
          position: absolute;
          left: 0px;
          top: 5px;
          bottom: 0px;
          border-radius: 4px;
        }
      }
      .kpi-detail-wrap {
        .flex-l();
        flex-wrap: wrap;
        .list {
          position: relative;
          padding: 12px 12px 0px 12px;
          font-size: 14px;
          line-height: 20px;
          color: #6e7491;
          .flex-l();
          flex-wrap: wrap;
          .icon {
            margin-right: 8px;
          }
          &::after {
            content: '';
            width: 1px;
            height: 12px;
            background: #d9dbe9;
            position: absolute;
            left: 0px;
            top: 16px;
            bottom: 0px;
            border-radius: 4px;
          }
          &:first-child {
            padding: 12px 12px 0px 0px;
            &::after {
              width: 0px;
            }
          }
        }
      }
    }
    .kpi-item-right {
      .flex-l();
      justify-content: flex-end;
      .open-kpi {
        font-size: 14px;
        width: 96px;
        border-radius: 8px !important;
      }
    }
  }

  .kpi-process-wrap {
  }
  &:hover {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    box-shadow: 0px 4px 16px rgba(17, 17, 17, 0.06);
  }
}
</style>
