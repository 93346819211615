var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "wrap",
      staticClass: "atwho-wrap",
      on: {
        compositionstart: _vm.handleCompositionStart,
        compositionend: _vm.handleCompositionEnd,
        input: function ($event) {
          return _vm.handleInput()
        },
        keydown: _vm.handleKeyDown,
      },
    },
    [
      _vm.atwho
        ? _c("div", { staticClass: "atwho-panel", style: _vm.style }, [
            _c("div", { staticClass: "atwho-inner" }, [
              _c("div", { staticClass: "atwho-view" }, [
                _vm.atwho && _vm.atwho.list && _vm.atwho.list.length > 0
                  ? _c(
                      "ul",
                      {
                        directives: [
                          {
                            name: "scroll-bottom",
                            rawName: "v-scroll-bottom",
                            value: _vm.$loadMoreList,
                            expression: "$loadMoreList",
                          },
                        ],
                        staticClass: "atwho-ul",
                        on: { mouseleave: _vm.handleItemLeave },
                      },
                      _vm._l(_vm.atwho.list, function (item, index) {
                        return _c(
                          "li",
                          {
                            key: index + Math.random() + item.id,
                            ref: _vm.isCur(index) && "cur",
                            refInFor: true,
                            staticClass: "atwho-li",
                            class: _vm.isCur(index) && "atwho-cur",
                            attrs: { "data-index": index },
                            on: { click: _vm.handleItemClick },
                          },
                          [
                            _vm._t(
                              "item",
                              function () {
                                return [
                                  _c(
                                    "div",
                                    {
                                      ref: "editor",
                                      refInFor: true,
                                      staticClass: "at-user-item",
                                    },
                                    [
                                      _c("el-image", {
                                        staticClass: "border-radius-20",
                                        attrs: {
                                          fit: "cover",
                                          src:
                                            item.avatar ||
                                            require("@/assets/imgs/common/avatar.png"),
                                        },
                                      }),
                                      _c(
                                        "span",
                                        [
                                          _c("wx-open-data", {
                                            attrs: {
                                              id: item.username,
                                              nameData: { name: item.saasname },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "departName" },
                                        [
                                          _c("wx-open-data", {
                                            attrs: {
                                              id: item.departName,
                                              type: 2,
                                              nameData: {
                                                name: item.departName,
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              { item: item }
                            ),
                          ],
                          2
                        )
                      }),
                      0
                    )
                  : _c(
                      "div",
                      {
                        staticClass: "no-data",
                        staticStyle: { "font-weight": "normal" },
                      },
                      [_c("noData")],
                      1
                    ),
                _c(
                  "div",
                  {
                    staticClass: "depart-add-wrap",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.departAdd.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("Icon", {
                      staticClass: "avatar-img",
                      attrs: { "icon-class": "icon-a-tianjia2" },
                    }),
                    _c("div", [_vm._v("按组织选择")]),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false",
            },
          ],
          ref: "embeddedItem",
        },
        [_vm._t("embeddedItem", null, { current: _vm.currentItem })],
        2
      ),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }