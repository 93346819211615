// 工作台
// 统一管理所有的基础请求
import apis from '../apis_moudles/index'
import req from '../request'

// 查看绩效中考核数量，待处理数量
const KPIQUERYCOUNT = (params) => {
  return req.get(apis.kpiQueryCount, {
    params
  })
}
// 考核模板列表
const KPIMOUDLELIST = (params) => {
  return req.get(apis.kpiMoudleList, {
    params
  })
}
// 获取考核模板内考核范围
const KPIMOUDLESCOPELIST = (params) => {
  return req.get(apis.kpiMoudleScopeList, {
    params
  })
}
// 添加考核
const KPIADDPERFORMANCE = (params) => {
  return req.post(apis.kpiAddPerformance, params)
}
// 查询考核列表
const KPIPERFORMANCELIST = (params) => {
  return req.get(apis.kpiPerformanceList, {
    params
  })
}
// 重新发起考核
const KPIRESTARTPERFORMANCE = (appendUrl, params) => {
  return req.put(`${apis.kpiRestartPerformance}${appendUrl}`, params)
}
// 终止考核
const KPISTOPPERFORMANCE = (appendUrl, params) => {
  return req.put(`${apis.kpiStopPerformance}${appendUrl}`, params)
}
// 删除考核
const KPIDELPERFORMANCE = (appendUrl, params) => {
  return req.delete(`${apis.kpiDelPerformance}${appendUrl}`, {
    params
  })
}
// 查询我的考核-考核列表
const KPIMYPERFORMANCELIST = (params) => {
  return req.get(apis.kpiMyPerformanceList, {
    params
  })
}
// 我的考核-各流程数量
const KPIMYPERFORMANCECOUNT = (params) => {
  return req.get(apis.kpiMyPerformanceCount, {
    params
  })
}
// 提醒ta
const KPINOTICETA = (params) => {
  return req.post(apis.kpiNoticeTa, params)
}
// 获取个人考核详情
const KPIUSERPERFORMANCE = (appendUrl, params) => {
  return req.get(`${apis.kpiUserPerformance}${appendUrl}`, {
    params
  })
}
// 查询模板的考核流程
const KPIMOUDLEPROCESS = (params) => {
  return req.get(apis.kpiMoudleProcess, {
    params
  })
}
// 查询模板的考核指标
const KPIMOUDLEDIMENSION = (appendUrl, params) => {
  return req.get(`${apis.kpiMoudleDimension}${appendUrl}`, {
    params
  })
}
// 获取考核中单个流程得分
const KPIPERFORMANCESTEPSCORE = (params) => {
  return req.get(apis.kpiPerformanceStepScore, {
    params
  })
}
// 考核中单个流程权限信息
const KPIPERFORMANCESTEPINFO = (params) => {
  return req.get(apis.kpiPerformanceStepInfo, {
    params
  })
}
// 考核中自评插入OKR
const KPIINSERTOKR = (params) => {
  return req.post(apis.kpiInsertOkr, params)
}
// 考核中自评删除OKR
const KPIDELOKR = (appendUrl, params) => {
  return req.delete(`${apis.kpiDelOkr}${appendUrl}`, {
    params
  })
}
// 考核中更新自评OKR权重
const KPIUPDATEOKR = (params) => {
  return req.put(apis.kpiUpdateOkr, params)
}
// 重新发起个人考核流程
const KPIRESTARTUSERPERFORMANCE = (params) => {
  return req.put(apis.kpiRestartUserPerformance, params)
}
// 驳回当前考核流程
const KPIREJECTPERFORMANCE = (params) => {
  return req.put(apis.kpiRejectPerformance, params)
}
// 考核-邀请同事打分
const KPIINVITEWORKER = (params) => {
  return req.post(apis.kpiInviteWorker, params)
}
// 考核-删除邀请同事
const KPIDELINVITEWORKER = (appendUrl, params) => {
  return req.delete(`${apis.kpiDelInviteWorker}${appendUrl}`, {
    params
  })
}
// 提交打分
const KPISAVESCORE = (params) => {
  return req.post(apis.kpiSaveScore, params)
}
// 个人考核-审核通过
const KPIPASSPERFORMANCE = (params) => {
  return req.put(apis.kpiPassPerformance, params)
}
// 查询个人考核驳回信息
const KPIREJECTINFO = (params) => {
  return req.get(apis.kpiRejectInfo, {
    params
  })
}
// 邀请处理-我的处理列表
const KPIMYHANDLELIST = (params) => {
  return req.get(apis.kpiMyHandleList, {
    params
  })
}
// 公开考核列表
const KPIPUBLICPERFORMANCE = (params) => {
  return req.get(apis.kpiPublicPerformance, {
    params
  })
}
// 整场考核信息
const KPIPERFORMANCEINFO = (id, params) => {
  return req.get(`${apis.kpiPerformanceInfo}${id}`)
}
// 整场考核-人员考核列表
const KPIPERFORMANCEINFOLIST = (appendUrl, params) => {
  return req.get(`${apis.kpiPerformanceInfoList}${appendUrl}`, {
    params
  })
}
// 获取模板内等级信息列表
const KPIMOUDLEGRADELIST = (appendUrl, params) => {
  return req.get(`${apis.kpiMoudleGradeList}${appendUrl}`, {
    params
  })
}
// 整场考核-删除考核人员
const KPIDELPERFORMANCEUSER = (data) => {
  return req.delete(apis.kpiDelPerformanceUser, {
    data
  })
}
// 整场考核-添加考核人员
const KPIADDPERFORMANCEUSER = (params) => {
  return req.post(apis.kpiDelPerformanceUser, params)
}
// 公示考核结果
const KPIPUBLICRESULT = (params) => {
  return req.put(apis.kpiPublicResult, params)
}
// 启用/停用考核模板
const KPISTOPORSTARTMOUDLE = (params) => {
  return req.put(apis.kpiStopOrStartMoudle, params)
}
// 删除考核模板
const KPIDELMOUDLE = (appendUrl, params) => {
  return req.delete(`${apis.kpiMoudle}${appendUrl}`, {
    params
  })
}
// 添加考核模板
const KPIADDMOUDLE = (params) => {
  return req.post(apis.kpiMoudle, params)
}
// 修改考核模板
const KPIEDITMOUDLE = (params) => {
  return req.put(apis.kpiMoudle, params)
}
// 获取打分规则详情
const KPIRULEDETAIL = (appendUrl, params) => {
  return req.get(`${apis.kpiRuleDetail}/${appendUrl}`, {
    params
  })
}
// 新增，修改打分规则
const KPIRULEUPDATE = (method, params) => {
  return req[method](apis.kpiRuleDetail, params)
}
// 查询打分规则列表
const KPIRULELIST = (params) => {
  return req.get(apis.kpiRuleList, {
    params
  })
}
// 获取指标模板类型
const KPIINDEXTEMPLATETYPE = (params) => {
  return req.get(apis.kpiIndexTemplateType, {
    params
  })
}
// 获取指标模板列表
const KPIINDEXTEMPLATELIST = (params) => {
  return req.get(apis.kpiIndexTemplateList, {
    params
  })
}
// 获取指标模板详情
const KPIINDEXTEMPLATEDETAIL = (params) => {
  return req.get(apis.kpiIndexTemplateDetail, {
    params
  })
}
// 获取考核默认流程
const KPIDEFAULTSTEPLIST = (params) => {
  return req.get(apis.kpiDefaultStepList, {
    params
  })
}
// 获取考核模板详情-指标
const KPIMOUDLEINDEX = (params) => {
  return req.get(apis.kpiMoudleIndex, {
    params
  })
}

// 导出基础请求函数
export default [
  { KPIQUERYCOUNT },
  { KPIMOUDLELIST },
  { KPIMOUDLESCOPELIST },
  { KPIADDPERFORMANCE },
  { KPIPERFORMANCELIST },
  { KPIRESTARTPERFORMANCE },
  { KPISTOPPERFORMANCE },
  { KPIDELPERFORMANCE },
  { KPIMYPERFORMANCELIST },
  { KPIMYPERFORMANCECOUNT },
  { KPINOTICETA },
  { KPIUSERPERFORMANCE },
  { KPIMOUDLEPROCESS },
  { KPIMOUDLEDIMENSION },
  { KPIPERFORMANCESTEPSCORE },
  { KPIPERFORMANCESTEPINFO },
  { KPIINSERTOKR },
  { KPIDELOKR },
  { KPIUPDATEOKR },
  { KPIRESTARTUSERPERFORMANCE },
  { KPIREJECTPERFORMANCE },
  { KPIINVITEWORKER },
  { KPIDELINVITEWORKER },
  { KPISAVESCORE },
  { KPIPASSPERFORMANCE },
  { KPIREJECTINFO },
  { KPIMYHANDLELIST },
  { KPIPUBLICPERFORMANCE },
  { KPIPERFORMANCEINFO },
  { KPIPERFORMANCEINFOLIST },
  { KPIMOUDLEGRADELIST },
  { KPIDELPERFORMANCEUSER },
  { KPIADDPERFORMANCEUSER },
  { KPIPUBLICRESULT },
  { KPISTOPORSTARTMOUDLE },
  { KPIDELMOUDLE },
  { KPIADDMOUDLE },
  { KPIEDITMOUDLE },
  { KPIRULEDETAIL },
  { KPIRULEUPDATE },
  { KPIRULELIST },
  { KPIINDEXTEMPLATETYPE },
  { KPIINDEXTEMPLATELIST },
  { KPIINDEXTEMPLATEDETAIL },
  { KPIDEFAULTSTEPLIST },
  { KPIMOUDLEINDEX }
]
