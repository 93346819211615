var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "bottom-slide", mode: "out-in" } }, [
    _c(
      "div",
      {
        key: `${_vm.addTargetView}`,
        staticClass: "task-add-box-inner",
        class: _vm.addTargetView ? "" : "task-add-box-inner-fix",
      },
      [
        !_vm.addTargetView
          ? _c(
              "div",
              {
                staticClass: "task_add_box",
                on: {
                  click: function ($event) {
                    return _vm.addTargetTask()
                  },
                },
              },
              [_c("div", [_c("span", [_vm._v("+ 添加任务")])])]
            )
          : _vm._e(),
        _vm.addTargetView
          ? _c(
              "div",
              { staticClass: "task_add_input_box" },
              [
                _c("el-input", {
                  ref: "CommentBoxinput1",
                  attrs: {
                    size: "medium",
                    placeholder: "添加任务，例如：完成产品说明手册",
                  },
                  on: { input: _vm.inputBack },
                  model: {
                    value: _vm.task_form.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.task_form, "name", $$v)
                    },
                    expression: "task_form.name",
                  },
                }),
                _c(
                  "div",
                  { staticClass: "to_edit_task_time" },
                  [
                    _c(
                      "div",
                      { staticClass: "left_set_item" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "set-end-time",
                            on: { click: _vm.endPicker },
                          },
                          [
                            _c("Icon", {
                              staticClass: "task_end_time_icon",
                              attrs: { "icon-class": "icon-calendar" },
                            }),
                            _c(
                              "span",
                              { staticClass: "time-text" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.task_form.endTime || "设置结束时间"
                                    ) +
                                    " "
                                ),
                                _vm.task_form.endTime
                                  ? _c("Icon", {
                                      staticClass: "close",
                                      attrs: {
                                        "icon-class": "icon-guanbianniu",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.timeClear()
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("el-date-picker", {
                          ref: "endDate",
                          staticClass: "hide-date-border",
                          attrs: {
                            disabled: _vm.disabled,
                            "picker-options": _vm.pickerOptionsStart,
                            type: "datetime",
                            format: "yyyy-MM-dd HH:mm",
                            "value-format": "yyyy-MM-dd HH:mm",
                            clearable: false,
                            "default-time": _vm.defaultEnd,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.changeDate()
                            },
                          },
                          model: {
                            value: _vm.task_form.endTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.task_form, "endTime", $$v)
                            },
                            expression: "task_form.endTime",
                          },
                        }),
                        _c("Icon", {
                          staticClass: "task_cycle_time_icon",
                          class: _vm.task_form.remindTime ? "is-remind" : "",
                          attrs: { "icon-class": "icon-remind" },
                          on: { click: _vm.setRemindTime },
                        }),
                        _c(
                          "div",
                          { staticClass: "task_select_kr" },
                          [
                            _c("kr-select", {
                              attrs: { "kr-option": _vm.krOption },
                              model: {
                                value: _vm.task_form.selectKrArrayValue,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.task_form,
                                    "selectKrArrayValue",
                                    $$v
                                  )
                                },
                                expression: "task_form.selectKrArrayValue",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "L-button",
                      {
                        staticClass: "add-task-btn",
                        class:
                          _vm.task_form.name && _vm.task_form.selectKrArrayValue
                            ? ""
                            : "btn_disabled",
                        attrs: { loading: _vm.loading },
                        on: { clickEvt: _vm.addTargetTaskRequest },
                      },
                      [_vm._v("新建")]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.remindTimeVisible
          ? _c("task-remind-time-detail", {
              attrs: {
                id: _vm.task_form.remindTime,
                visible: _vm.remindTimeVisible,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.remindTimeVisible = $event
                },
                save: _vm.remindTimeSave,
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }