<template>
  <div class="content">
    <div class="table-top">
      <div class="left" v-if="fromType === 'pmDetail'">今日已完成{{ completeNum }}个任务</div>
      <div class="right">
        <div class="list">
          <div class="select-list" v-if="fromType === 'pmDetail'">
            <el-select
              ref="selectHeadWork"
              v-model="workOptionValue"
              style="width: 95px"
              popper-class="cooperate-select"
              @focus="selectHeadClick(1, true)"
              @blur="selectHeadClick(1, false)"
            >
              <template v-if="cooperateVuexList.tabSelectId === 1">
                <el-option
                  v-for="(val, key) in cooperateVuexList.selectDialogList
                    .workOptionList"
                  :key="key"
                  :label="val"
                  :value="parseInt(key)"
                  @click.native.prevent="workClick(val)"
                >
                  <div class="list">{{ val }}</div>
                </el-option>
              </template>
              <template v-if="cooperateVuexList.tabSelectId === 2">
                <el-option
                  v-for="(val, key) in cooperateVuexList.selectDialogList
                    .boardWorkOptionList"
                  :key="key"
                  :label="val"
                  :value="parseInt(key)"
                  @click.native.prevent="workClick(val)"
                >
                  <div class="list">{{ val }}</div>
                </el-option>
              </template>
              <el-option @click.native.prevent="workClick('hide')">
                <div class="complete-status">
                  <Icon
                    icon-class="icon-draw_checked"
                    class="check-icon check-icon-checked"
                    v-if="cooperateVuexList.selectDialogList.hide === 1"
                  />
                  <span class="check-icon" v-else></span>隐藏已完成
                </div>
              </el-option>
            </el-select>
          </div>
          <div class="select-list">
            <el-select
              ref="selectHeadTime"
              v-model="
                cooperateVuexList.selectDialogList.createTimerOptionValue
              "
              style="width: 140px"
              popper-class="cooperate-select"
              class="time-select"
              @change="getSelect(1)"
              @focus="selectHeadClick(2, true)"
              @blur="selectHeadClick(2, false)"
            >
              <el-option
                v-for="(val, key) in cooperateVuexList.selectDialogList
                  .createTimerOption"
                :key="key"
                :label="val"
                :value="parseInt(key)"
              >
                <div class="list">{{ val }}</div>
              </el-option>
            </el-select>
          </div>
          <div class="select-list">
            <div class="select-popover">
              <select-head-dialog :dialog-list="dialogList" :fromType="fromType" @change="change" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import selectHeadDialog from './selectHeadDialog' // 筛选内容弹窗
export default {
  components: {
    selectHeadDialog
  },
  props: {
    // tabSelectId: {
    //   required: false,
    //   type: Number,
    //   default: 0
    // }
    fromType: {
      default: 'pmDetail'
    }
  },
  data() {
    return {
      selectProjectId: '',
      workOptionValue: '全部任务',
      workOptionValueList: '',
      completeNum: 0,
      selectHeadDialogState: false,
      selectHeadHideList: {
        time: '',
        work: ''
      },
      cooperateVuexList: ''
    }
  },
  created() {
    // 筛选数据
    if (this.fromType === 'copyTask') {
      this.cooperateVuexList = {
        selectDialogList: this.$store.state.pm.copyTaskSelectDialogList
      }
    } else {
      this.cooperateVuexList = this.$store.state.pm.cooperateVuexList
    }
    this.bus(this).$on('dialogList', (val) => {
      this.dialogList = val
    })
    if (this.fromType === 'pmDetail') {
      this.getProjectCompleteNum()
    }
  },
  computed: {},
  mounted() {
    this.bus(this).$on('global:selectProjectId', (list) => {
      this.selectProjectId = this.cooperateVuexList.selectProjectId
    })
    this.bus(this).$on('selectHeadDialogState', (state) => {
      this.selectHeadDialogState = state
    })
    this.bus(this).$on('getProjectCompleteNum', (state) => {
      if (this.fromType === 'pmDetail') {
        this.getProjectCompleteNum()
      }
    })
    //this.bus(this).$on('tabSelectIdRefresh', state => {
    //   if(state===1){
    //     this.workOptionValue = '全部任务'
    //     this.cooperateVuexList.selectDialogList.workOptionValue = '全部任务'
    //   }else if(state===2){
    //     this.workOptionValue = '仅父任务'
    //     this.cooperateVuexList.selectDialogList.workOptionValue = '仅父任务'
    //   }
    // })
  },
  watch: {
    selectProjectId: function (newVal, oldVal) {
      if (this.fromType === 'pmDetail') {
        this.getProjectCompleteNum()
      }
    }
  },
  methods: {
    change() {
      console.log('change')
      this.$emit('change')
    },
    selectHeadClick(type, state) {
      if (this.selectHeadDialogState && state) {
        this.bus.$emit('dialogCancel') //筛选框隐藏
      }
      if (state) {
        this.bus.$emit('tableDialogHide') //table弹窗隐藏
      }
      if (type === 1 && state) {
        this.selectHeadHideList.time = 'selectHeadTime'
      }
      if (type === 1 && state === false) {
        this.selectHeadHideList.time = ''
      }
      if (type === 2 && state) {
        this.selectHeadHideList.work = 'selectHeadWork'
      }
      if (type === 2 && state === false) {
        this.selectHeadHideList.work = ''
      }
    },
    selectHeadHide() {
      if (this.selectHeadHideList.work !== '') {
        let refName = this.selectHeadHideList.work
        this.$refs[refName][0].blur()
        this.selectHeadHideList.work = ''
      }
      if (this.selectHeadHideList.time !== '') {
        let refName = this.selectHeadHideList.time
        this.$refs[refName][0].blur()
        this.selectHeadHideList.time = ''
      }
    },
    workClick(e) {
      if (e === 'hide') {
        if (this.cooperateVuexList.selectDialogList.hide === null) {
          this.cooperateVuexList.selectDialogList.hide = 1
        } else {
          this.cooperateVuexList.selectDialogList.hide = null
        }
        this.workOptionValue =
          this.cooperateVuexList.selectDialogList.workOptionValue
        this.getSelect()
      } else {
        if (this.workOptionValue != e) {
          this.workOptionValue = e
          this.cooperateVuexList.selectDialogList.workOptionValue = e
          this.getSelect()
        }
      }
    },
    // 获取完成数
    async getProjectCompleteNum() {
      let obj_ = {
        projectId: this.cooperateVuexList.selectProjectId
      }
      await this.$apis.PROTASKTODAYFINISNCOUNT(obj_).then((res) => {
        if (res.status === 200) {
          this.completeNum = res.data
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    getSelect(type) {
      if (this.fromType === 'copyTask') {
        this.change()
      } else {
        if (this.cooperateVuexList.tabSelectId === 1) {
          this.bus.$emit('getProjectTaskList') //列表刷新数据
        } else if (this.cooperateVuexList.tabSelectId === 2) {
          this.bus.$emit('refreshGetProjectProcessList') //看板刷新数据
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../assets/styles/common.less';
.content {
  .table-top {
    .flex-bt();
    height: 52px;
    line-height: 52px;
    font-size: 14px;
    color: #a0a3bd;
    .left {
      margin-left: 24px;
    }
    .right {
      .list {
        height: 52px;
        .flex-r();
        .select-list {
          height: 52px;
          .mg-l-n(24);
          .el-input__inner {
            padding: 0px;
            font-weight: 400;
            height: 52px;
            line-height: 52px;
            &:hover {
              color: @hover-color;
            }
          }
        }
      }
    }
  }
}
.complete-status {
  .flex-c();
  border-top: 1px solid #f5f6fa;
  color: #a0a3bd;
  .check-icon {
    width: 18px;
    height: 18px;
    line-height: 18px;
    margin-right: 8px;
    border: 1px solid #a0a3bd;
    border-radius: 4px;
    color: #a0a3bd;
    font-size: 12px;
  }
  .check-icon-checked {
    border: 1px solid #3a78f6;
    color: #fff;
    background: #3a78f6;
  }
  &:hover {
    color: #3a78f6;
    .check-icon {
      color: #3a78f6;
      background: rgba(0, 0, 0, 0);
      border: 1px solid #3a78f6;
    }
  }
}
.complete-status.hide {
  color: #005eff !important;
  span {
    border: 1px solid #005eff;
    color: #005eff !important;
  }
}
::v-deep .table-top .el-input__inner {
  padding: 0px;
  border: none;
  background: none;
  color: #6e7491;
  -webkit-box-shadow: 0 0 20px 20px rgba(0, 0, 0, 0) inset;
  box-shadow: 0 0 20px 20px rgba(0, 0, 0, 0) inset;
  font-size: 14px;
  height: 52px;
  line-height: 52px;
}
::v-deep .table-top .el-input__inner:hover {
  color: #3a78f6;
}
::v-deep .table-top .el-select .el-input .el-select__caret {
  font-size: 16px;
  color: #6e7491;
  height: 52px;
  line-height: 52px;
}
::v-deep .table-top .el-input:hover .el-select__caret {
  color: #3a78f6;
}
::v-deep .select-popover .select-btn {
  font-size: 14px !important;
  background: none;
  border: none;
  margin: 0px;
  padding: 0px;
  height: 52px;
  line-height: 52px;
  .el-input__icon {
    font-size: 16px;
    transform: rotate(180deg);
    line-height: 52px;
  }
  &:hover {
    background: none !important;
  }
}
</style>
