import utils from '../../utils/index'
export function handleObjData(obj) {
  const data = JSON.parse(JSON.stringify(obj))
  data.content = utils.changeToHtml(data.content)
  if (data.keyresultsList) {
    data.keyresultsList.forEach((item) => {
      item.title = utils.changeToHtml(item.title)
    })
  }
  return data
}

const adviceMap = {
  o: {
    a: {
      id: '1',
      text: '目标描述过于简单了，建议10个字以上，明确描述你想达成的结果、想要的成果～'
    }, // "1"
    b: {
      id: '2',
      text: '最好能带上一些「推进、达到、提高、完成」等动作词汇来描述；或者用数值来衡量想要达成的成果'
    }, // "2"
    c: {
      id: '3',
      text: '建议3个KR最为适宜，试着从多角度去拆分目标，去支撑达成目标'
    }, // "3"
    d: {
      id: '4',
      text: 'KR数量过多，建议3个KR最为适宜，一般不超过5个，KR太多会容易过细并且分散注意力。'
    } // "4"
  },
  kr: {
    a: {
      id: '5',
      text: 'KR描述过于简单了，建议10个字以上，描述为了达成目标需要做的关键的动作或量化的结果'
    }, // "5"
    b: {
      id: '6',
      text: 'KR应该是对目标的量化、关键的执行动作，可以用「达到、完成、超过」等动作词汇去表达要做的事情，以及要做成的结果。另外，KR最好是有具体的数值去衡量，拆分成具体的可量化的数值指标会更有说服力'
    } // "6"
  }
}
// 判断低质量
export function handleAdvice(html, type, keyLength, ruleList) {
  if (!html) return
  const content = utils.tagFilter(html)
  if (exitJduge(ruleList, type === 1 ? '1' : '5') &&
    content.split('').length < 8) {
    ruleList = adviceMap[type === 1 ? 'o' : 'kr'].a
    return adviceMap[type === 1 ? 'o' : 'kr'].a
  }
  if (exitJduge(ruleList, type === 1 ? '2' : '6')) {
    const pattern = /达成|完成|提高|达到|满足|实现|推进|超过|交付|做到|(\d)|零|一|二|三|四|五|六|七|八|九|十|百|千|万|亿|壹|贰|叁|肆|伍|陆|柒|捌|玖|拾|提升|增加|减少|控制|改进|改善|完善|确保|至少|所有|全部|加强|突破|冲刺|奋战|成功|优秀|完美|卓越|顶级|创造|创建|实现|重新|推广|获得|获取|取得|努力|加油|定期|超过|超出|超预期|超越|使得|开始|启动|进行|高效|品质|精准|打|做|提|上|完|到|成|定|为|改|全|所|出|于|要|开|通|先|对|推|转|进|冲|得|失|过|发|比|多|少|超|增|减|升|加|降|满|高|低|涨|快|长|有|无|强|大|小|去|动|胜|前|后|好|棒|个|几|精|优|攒|绝|佳|很|特|非|可|尤|重|不|相|认|开|以|第|建|克|要|立|退|接|激|鼓|使|经|收|获|取|修|稳|至|最|业务|模式|复制|模块|流程|进程|进度|案例|方案|方针|方式|方法|指标|规划|规格|方向|调研|研究|研发|开发|策略|战略|引进|引流|引导|引发|引入|指引|引领|管理|负责|带领|潮流|前线|一线|帮助|能力|锻炼|案例|营销|活动|建立|品牌|设计|理念|市场|渠道|体验|需求|制定|响应|领域|范围|范畴|知名度|理想|建议|集体|团队|团体|全部|主要|核心|缩|季|月|年|半|天|日|周|定期|偶|常|勤|每|隔|率|度|实|经|壹|贰|叁|肆|伍|陆|柒|捌|玖|拾/
    if (!pattern.test(content)) {
      return adviceMap[type === 1 ? 'o' : 'kr'].b
    }
  }
  if (type === 1 && keyLength !== 0) {
    if (exitJduge(ruleList, '3') && keyLength < 2) {
      return adviceMap.o.c
    }
    if (exitJduge(ruleList, '4') && keyLength > 5) {
      return adviceMap.o.d
    }
  }
  return {}
}

function exitJduge(ruleList, id) {
  // 判断规则是否已忽略
  const exit = ruleList.some((item) => {
    return item === id
  })
  return !exit
}
