var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tooltip",
    {
      attrs: {
        placement: "top",
        "open-delay": 500,
        content: _vm.content,
        disabled: _vm.content === "" ? true : false,
      },
    },
    [
      _vm.visible
        ? _c(
            "div",
            {
              staticClass: "flur-wrap",
              class: `flur-wrap-${_vm.createUser} flur-wrap-${_vm.type}`,
            },
            [
              _vm.type === 3 || _vm.type === 4
                ? _c("div", { staticClass: "icon-private" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../../../assets/imgs/common/private-close.png"),
                      },
                    }),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }