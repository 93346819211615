<template>
  <el-dialog
    :visible.sync="inviteFriendsShowState"
    class="invite-people-dialog"
    width="560px"
    title="邀请成员加入"
    :before-close="handleDialogClose"
    :append-to-body="true"
  >
    <!-- 二维码链接邀请好友 -->
    <div v-vue-loading="loading" class="invite_friends_code" @click.self="handleDialogClose()">
      <div class="friends_code_box">
        <div v-if="!onlyAdminApprove && !loading" class="invite-card">
          <div ref="inviteCodeRef" class="invite-code-card">
            <div class="change-code-type">
              <img
                src="@/assets/imgs/header/invite_link.png"
                class="code-icon"
                @click="codeVisibleChange"
              />
            </div>
            <div class="code-card-personal">
              <div class="person-avatar border-radius-20">
                <el-image :src="userInfo.avatar" fit="cover" />
              </div>
              <div class="person-info">
                <div class="person-name-info">
                  <span class="person-name">{{ userInfo.name }}</span>
                  <span class="person-job">{{ userInfo.jobTitle }}</span>
                </div>
                <div class="person-company">{{ companyInfo.name }}</div>
              </div>
            </div>
            <div class="code-img-box">
              <img class="code-img" :src="invitationBeans.shortUrlQrCode" />
            </div>
            <div class="code-info">通过扫描二维码加入企业</div>
            <div>
              <div v-show="codeVisible" class="code-btn share-btn" @click="downloadImg">保存</div>
              <div
                v-show="!codeVisible"
                v-clipboard:copy="invitationBeans.shortUrl"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                class="code-btn share-btn"
              >复制</div>
              <!-- <div>分享</div> -->
            </div>
          </div>
          <div ref="inviteLinkRef" class="invite-code-card link-card">
            <div class="change-code-type">
              <img
                src="@/assets/imgs/header/invite_code.png"
                class="code-icon"
                @click="codeVisibleChange"
              />
            </div>
            <div class="code-card-personal">
              <div class="person-avatar border-radius-20">
                <el-image :src="userInfo.avatar" fit="cover" />
              </div>
              <div class="person-info">
                <div class="person-name-info">
                  <span class="person-name">{{ userInfo.name }}</span>
                  <span class="person-job">{{ userInfo.jobTitle }}</span>
                </div>
                <div class="person-company">{{ companyInfo.name }}</div>
              </div>
            </div>
            <div class="code-link">
              <div class="code-link-text">我和“{{ invitationBeans.companyName }}”的小伙伴都在源目标等你，</div>
              <div class="code-link-text">用这个专属链接加入我们吧！</div>
              <div class="code-link-text">{{ invitationBeans.shortUrl }}</div>
            </div>
            <div>
              <div
                v-clipboard:copy="invitationBeans.shortUrl"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                class="code-btn share-btn"
              >复制</div>
              <!-- <div>分享</div> -->
            </div>
          </div>
        </div>
        <div v-if="onlyAdminApprove && !loading" class="friends_code_approve_admin">
          <Icon icon-class="icon-zhongzhi" class="svg-img" />仅允许管理员邀请哦
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
/* eslint-disable */
import formMixin from '@/utils/mixins/form'
export default {
  mixins: [formMixin],
  props: {
    dialogShow: {
      type: Boolean,
      default: true
    }
  },
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  watch: {
    dialogShow: {
      handler(val) {
        this.inviteFriendsShowState = val
      }
    }
  },
  data() {
    return {
      loading: true,
      inviteFriendsShowState: this.dialogShow,
      // 标题
      title: '邀请成员加入',
      select_value: {
        //部门
        value: '-1',
        name: '',
        jurisdiction: 0
      },
      // 距离顶部高度
      top: '15vh',
      invitationBeans: { shortUrlQrCode: '', shortUrl: '', companyName: '' }, //邀请链接
      onlyAdminApprove: null,
      codeVisible: true // 二维码添加
    }
  },
  computed: {},
  created() {
    this.select_value.name = this.companyInfo.name //公司名称

    this.getInviteFriendsCodeList()
  },
  watch: {},
  methods: {
    onError() {
      this.$message({
        msg: `复制失败！`,
        type: 'error'
      })
    },
    onCopy() {
      this.$message({
        msg: `复制成功！`,
        type: 'success'
      })
    },
    codeVisibleChange() {
      // 二维码链接切换
      this.codeVisible = !this.codeVisible
      this.$refs.inviteLinkRef.style.transform = this.codeVisible
        ? 'rotateY(-180deg)'
        : 'rotateY(0deg)'
      this.$refs.inviteCodeRef.style.transform = this.codeVisible
        ? 'rotateY(0deg)'
        : 'rotateY(-180deg)'
    },
    downloadImg() {
      let url = this.invitationBeans.shortUrlQrCode.split('//') // 获取图片地址
      console.log('url', url)
      var a = document.createElement('a') // 创建一个a节点插入的document
      var event = new MouseEvent('click') // 模拟鼠标click点击事件
      a.download = '源目标邀请二维码' // 设置a节点的download属性值
      a.target = '_blank'
      a.href = `${window.location.protocol}//${url[1]}` // 将图片的src赋值给a节点的href
      a.dispatchEvent(event) // 触发鼠标点击事件
    },
    //监听弹框右上角关闭按钮被点击
    handleDialogClose() {
      this.$emit('closeInviterCode')
      this.inviteFriendsShowState = false
    },
    // 获取code信息内容
    getInviteFriendsCodeList() {
      this.loading = true
      this.$apis
        .GETUSERSETINVITE()
        .then((rest) => {
          if (rest.status == 200) {
            this.invitationBeans = rest.data
          } else if (rest.status == 500) {
            this.$message({
              msg: rest.message,
              type: 'warn'
            })
          } else if (rest.status == 52021) {
            this.onlyAdminApprove = true
          } else {
            this.$message({
              msg: rest.message,
              type: 'error'
            })
          }
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          console.log(err)
        })
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../assets/styles/dialog.less';
</style>
<style lang="scss" scoped>
@import '../../../assets/styles/variable.scss';
::v-deep .el-dialog__body {
  padding: 0px;
}
.invite_friends_code {
  display: flex;
  justify-content: center;
  .friends_code_box {
    width: 435px;
    min-height: 465px;
    padding-bottom: 30px;
    .invite-card {
      width: 340px;
      height: 380px;
      border-radius: 16px;
      margin: 40px auto 24px;
      position: relative;
      .invite-code-card {
        box-shadow: 0px 4px 16px 0px rgba(17, 17, 17, 0.06);
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden; /* Chrome 和 Safari */
        -moz-backface-visibility: hidden; /* Firefox */
        -ms-backface-visibility: hidden;
        width: 340px;
        height: 380px;
        border-radius: 16px;
        padding: 24px 24px 32px;
        top: 0;
        left: 0;
        position: absolute;
        overflow: hidden;
        background: #fff;
        .change-code-type {
          cursor: pointer;
          position: absolute;
          width: 114px;
          height: 114px;
          top: -26px;
          right: -26px;
          .code-icon {
            width: 114px;
          }
        }
        .code-card-personal {
          display: flex;
          min-width: 0;
          .person-avatar {
            width: 46px;
            height: 46px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 8px;
            .el-image {
              width: 46px;
              height: 46px;
            }
          }
          .person-info {
            min-width: 0;
            font-size: 16px;
            color: #14142b;
            width: 180px;
            .person-name-info {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              &:before {
                content: '';
                display: block;
              }
            }
            .person-name {
              font-weight: 700;
              margin-right: 8px;
            }
            .person-company {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              font-size: 14px;
              color: #a0a3bd;
              margin-top: 4px;
              &:before {
                content: '';
                display: block;
              }
            }
          }
        }
        .code-link {
          width: 292px;
          min-height: 144px;
          border-radius: 8px;
          background: #f5f6fa;
          color: #a0a3bd;
          text-align: center;
          margin: 30px 0 48px;
          padding: 10px 24px;
          .code-link-text {
            margin: 8px 0;
          }
        }

        .code-img-box {
          width: 170px;
          height: 170px;
          margin: 16px auto 0;
          .code-img {
            width: 160px;
          }
        }
        .code-info {
          text-align: center;
          margin: 16px auto 24px;
          font-size: 16px;
          color: #444558;
          line-height: 22px;
        }
        .code-btn {
          width: 96px;
          height: 32px;
          text-align: center;
          line-height: 32px;
          cursor: pointer;
          border-radius: 14px;
          margin: 0 auto;
        }
        .save-btn {
          color: #005eff;
          border: 1px solid #005eff;
          margin-right: 16px;
          &:hover {
            color: #005eff;
            border: 1px solid #005eff;
          }
        }
        .share-btn {
          background: #3a78f6;
          color: #fff;
          border: 1px solid #3a78f6;
          &:hover {
            background: #005eff;
            border: 1px solid #005eff;
          }
        }

        transition: all 1s;
      }
    }
    .link-card {
      transform: rotateY(-180deg);
    }
    .friends_code_approve_admin {
      display: flex;
      justify-content: center;
      align-items: center;
      .svg-img {
        font-size: 24px;
        color: #ed2e7e;
        margin-right: 8px;
      }
      padding-top: 200px;
      font-size: 18px;
      color: #444558;
      font-weight: 700;
    }
  }
}

.add_p {
  font-size: 16px;
  color: #333;
  line-height: 24px;
  margin-bottom: 8px;
  span.add_span {
    color: #fa972b;
    font-size: 14px;
  }
}
</style>
<style lang="scss">
.float_rg {
  .el-form-item__content {
    margin-left: 6px !important;
  }
}
.friends_code_box_check {
  max-height: 200px;
  overflow-x: hidden;
  .el-checkbox:last-of-type {
    margin-top: 20px !important;
  }
  .el-checkbox {
    margin-top: 20px !important;
    margin-left: 30px !important;
  }
}
.gray .el-checkbox__input.is-disabled + span.el-checkbox__label {
  color: #c0c4cc !important;
}
.invite-people-dialog {
  z-index: 9999 !important;
}
</style>
