<template>
  <div v-if="ossFileEntityList.length > 0" class="file-list-wrap">
    <div
      v-for="item in ossFileEntityList"
      :key="item.downloadUrl"
      class="file-list"
      @click="fileListClick(item)"
    >
      <div class="file-list-left">
        <el-image
          v-if="
            item.mimeType == 'png' ||
            item.mimeType == 'jpg' ||
            item.mimeType == 'jpeg' ||
            item.mimeType == 'gif' ||
            item.mimeType == 'svg' ||
            item.mimeType == 'PNG' ||
            item.mimeType == 'JPG' ||
            item.mimeType == 'JPEG' ||
            item.mimeType == 'GIF' ||
            item.mimeType == 'SVG'
          "
          fit="contain"
          class="file-img"
          :src="item.downloadUrl"
          alt
        />
        <Icon
          v-else-if="
            item.mimeType == 'docx' ||
            item.mimeType == 'doc' ||
            item.mimeType == 'DOCX' ||
            item.mimeType == 'DOC'
          "
          class="file-svg"
          icon-class="icon-DOCX"
        />
        <Icon
          v-else-if="item.mimeType == 'mp3' || item.mimeType == 'MP3'"
          class="file-svg"
          icon-class="icon-MP3"
        />
        <Icon
          v-else-if="item.mimeType == 'mp4' || item.mimeType == 'MP4'"
          class="file-svg"
          icon-class="icon-MP4"
        />
        <Icon
          v-else-if="item.mimeType == 'pdf' || item.mimeType == 'PDF'"
          class="file-svg"
          icon-class="icon-PDF"
        />
        <Icon
          v-else-if="item.mimeType == 'ppt' || item.mimeType == 'PPT'"
          class="file-svg"
          icon-class="icon-PPT"
        />
        <Icon
          v-else-if="item.mimeType == 'txt' || item.mimeType == 'TXT'"
          class="file-svg"
          icon-class="icon-filetxt"
        />
        <Icon
          v-else-if="
            item.mimeType == 'XLS' ||
            item.mimeType == 'xls' ||
            item.mimeType == 'XLSX' ||
            item.mimeType == 'xlsX'
          "
          class="file-svg"
          icon-class="icon-XLS"
        />
        <Icon v-else class="file-svg" icon-class="icon-fileCommon" />
        <div class="name">{{ item.name }}</div>
      </div>
      <div class="file-list-right">{{ item.fileSizeNew }}KB</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    ossFileEntityList: {
      required: false,
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {}
  },
  computed: {},
  watch: {
    ossFileEntityList() {
      let list = []
      if (typeof this.ossFileEntityList === 'string') {
        list = JSON.parse(this.ossFileEntityList)
      } else {
        list = this.ossFileEntityList || []
      }
      this.ossFileEntityList = list
      if (this.ossFileEntityList.length > 0) {
        this.ossFileEntityList.forEach((item) => {
          item.fileSizeNew = (item.fileSize / 1024).toFixed(2)
        })
      }
    }
  },
  created() {
    let list = []
    if (typeof this.ossFileEntityList === 'string') {
      list = JSON.parse(this.ossFileEntityList)
    } else {
      list = this.ossFileEntityList || []
    }
    this.ossFileEntityList = list
    if (this.ossFileEntityList.length > 0) {
      this.ossFileEntityList.forEach((item) => {
        item.fileSizeNew = (item.fileSize / 1024).toFixed(2)
      })
    }
  },
  methods: {
    fileListClick(item) {
      this.bus(this).$emit('global:dynamic:filePreview', item)
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../assets/styles/common.less';
.preview-null {
  margin: 5% auto;
  .file-svg {
    font-size: 88px;
    width: 88px;
    height: 88px;
  }
  p {
    margin-top: 10px;
    font-weight: 500;
    text-align: CENTER;
    color: #6e7491;
    line-height: 24px;
    font-size: 14px;
  }
}
::v-deep .preview {
  background-color: red;
}

::v-deep .el-dialog {
  border-radius: 16px;
  background: #f5f6fa;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #14142b;
  line-height: 64px;
  font-weight: 500;
  max-width: calc(100% - 160px);
  .text-overOne();
  display: block;
}
::v-deep .el-dialog__headerbtn {
  top: 17px;
}
::v-deep .el-dialog__close {
  width: 28px;
  height: 28px;
  background: url(../../../assets/icons/common/dialogClose.png);
  background-size: 28px auto;
  cursor: pointer !important;
  border-radius: 4px;
  &:before {
    content: '';
  }
  &:hover {
    width: 28px !important;
    height: 28px !important;
    background: #f5f6fa url(../../../assets/icons/common/dialogClose.png) !important;
    background-size: 28px auto !important;
  }
}
.downloadImg {
  .flex-l();
  position: absolute;
  height: 64px;
  line-height: 64px;
  top: 0px;
  right: 74px;
  cursor: pointer;
  width: 70px;
  &:before {
    content: '';
  }
  .icon-download {
    transform: rotate(90deg);
    font-size: 24px;
    width: 30px;
    height: 30px;
    padding: 4px;
    border-radius: 4px;
  }
  &:hover {
    .icon-download {
      background: #f5f6fa;
    }
  }
}
.img-wrap-box {
  margin: 0px auto;
  text-align: center;
  vertical-align: middle;
  .dialog-img {
    width: auto;
    max-width: 100%;
    max-height: calc(80vh - 150px);
    overflow-y: auto;
  }
}
::v-deep .marge-img-box .el-dialog {
  width: 80%;
  margin: 52px 10% !important;
}
.file-list-wrap {
  // width: 338px;
  width: 100%;
  box-sizing: border-box;
  .file-list {
    background: #fff;
    cursor: pointer;
    .flex-bt();
    height: 48px;
    line-height: 48px;
    border: 1px solid #f5f6fa;
    border-radius: 9px;
    margin-top: 10px;
    font-size: 14px;
    color: #6e7491;
    font-weight: 500;
    padding: 0px 10px;
    .file-list-left {
      .flex-l();
      max-width: calc(100% - 70px);
      .file-img {
        width: 38px;
        height: 38px;
        border-radius: 4px;
      }
      .file-svg {
        font-size: 30px;
        width: 30px;
        height: 30px;
      }
      .name {
        // max-width: 220px;
        width: calc(100% - 40px);
        .text-overOne();
        margin-left: 10px;
      }
    }
    .file-list-right {
      font-size: 12px;
      color: #a0a3bd;
      min-width: 70px;
      text-align: right;
    }
  }
}
</style>
<style>
.file-list-wrap .el-dialog__wrapper {
  z-index: 9999 !important;
}
.marge-img-box .el-dialog__header {
  height: 64px;
  background: #fff;
  border-radius: 16px 16px 0px 0px;
  padding: 0px 0px 0px 40px;
}
</style>
