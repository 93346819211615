<template>
  <div class="set-task-time" @click.stop>
    <el-dialog
      title="设置提醒时间"
      :visible="true"
      width="35%"
      modal-append-to-body="true"
      append-to-body="true"
      close-on-click-modal="true"
      top="30vh"
      @close="dialogClose"
    >
      <!-- 日期类型 -->
      <transition name="fade" mode="out-in" @click.stop>
        <div class="content-wrap">
          <div class="select-list">
            <el-select
              v-model="form.dateType"
              placeholder="请选择"
              class="m-r-20"
              @change="dateTypeChange"
            >
              <el-option
                v-for="(val, key) in dateTypeDict"
                :key="key"
                :label="val"
                :value="parseInt(key)"
              >
                <div v-if="isCustomDate(key)" class="select-decorate"></div>
                <span>{{ val }}</span>
              </el-option>
            </el-select>
            <!-- 提醒时间 -->
            <template v-if="isShowTime" @click.stop>
              <!-- 自定义时间 -->
              <el-date-picker
                @click.stop
                v-if="customTimeVisible"
                v-model="form.customDate"
                type="datetime"
                format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm"
                placeholder="选择日期"
                popper-class="addTimeClass"
              />
              <!-- 特定时间 -->
              <template v-else>
                <el-select v-model="form.number" class="m-r-20">
                  <el-option
                    v-for="(val, key) in numberDict"
                    :key="key"
                    :label="val"
                    :value="parseInt(key)"
                  />
                </el-select>
                <el-select v-model="form.numberType">
                  <el-option
                    v-for="(val, key) in numberTypeDict"
                    :key="key"
                    :label="val"
                    :value="parseInt(key)"
                  />
                </el-select>
              </template>
            </template>
          </div>
          <span slot="footer" class="flex-new justify-end footer">
            <el-button class="cancel-btn" @click.stop="$dialogClose"
              >取 消</el-button
            >
            <L-button
              class="common_btn_next common_btn_next_able"
              :loading="loading"
              @clickEvt="save"
              >添 加</L-button
            >
          </span>
        </div>
      </transition>
    </el-dialog>
  </div>
</template>

<script>
import formMixin from '@/utils/mixins/form'
import taskUtil from '@/utils/task.js' // 任务util
import remindEnum from '@/utils/enums/remind' // 提醒字典
const dateTypeEnum = remindEnum.DATETYPENUM // 日期类型枚举
export default {
  mixins: [formMixin],
  props: {
    id: {
      required: false
    },
    // 表单对象
    row: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
    // 任务id
    taskId: {
      required: false
    },
    todoAdd: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      form: {
        dateType: dateTypeEnum.beforeStart, // 日期类型(默认为任务开始前)
        number: 1, // 数量(默认为1)
        numberType: remindEnum.NUMBERTYPENUM.minute // 数量类型(默认为分钟)
      }, // 表单
      dateTypeDict: remindEnum.DATETYPE, // 日期类型字典
      numberDict: remindEnum.NUMBER, // 数量字典
      numberTypeDict: remindEnum.NUMBERTYPE, // 数量类型字典
      customTimeVisible: false // 自定义时间显示
    }
  },
  computed: {
    // 是否显示时间选择
    isShowTime() {
      const { not, start, end } = dateTypeEnum
      return ![not, start, end].includes(this.form.dateType)
    }
  },
  created() {
    console.log('e327947e9274937493', this.id)
    // id存在调用获取详情接口
    if (this.id) {
      this.getDetail()
    }
  },
  methods: {
    // 获取详情
    async getDetail() {
      await this.$apis
        .GETTASKREMIND({
          id: this.id
        })
        .then((rest) => {
          // 自定时间选择显示
          this.form = rest.data
          this.customTimeVisible = this.isCustomDate(rest.data.dateType)
        })
    },
    // 保存
    async save() {
      this.loading = true
      if (this.taskId) {
        this.form.taskId = this.taskId
      }
      const path = this.id && this.taskId ? 'TASKREMINDUPDATE' : 'TASKREMINDADD'
      this.submitDetail(path, this.form)
    },
    // 提交提醒
    async submitDetail(path, _obj) {
      console.log('object', path, _obj)
      var that = this
      if (_obj.dateType === 7 && !_obj.customDate) {
        that.$message.warn('自定义时间不能为空')
        this.loading = false
      } else {
        if ((_obj.customDate || '') !== '') {
          _obj.customDate = _obj.customDate + ':00'
        }
        this.$apis[path](_obj).then((res) => {
          if (res.status === 200) {
            if (res.data) {
              that.successHandle(res.data.id, res.data.dateType, res.data)
            }
          } else if (res.status === 70000) {
            that.$message({
              msg: res.message,
              type: 'error'
            })
          } else {
            that.$message({
              msg: res.message,
              type: 'error'
            })
          }
          this.loading = false
        })
      }
    },
    // 保存成功后的处理
    successHandle(id, dateType, data) {
      let msg = ''
      // 自定义时间
      const isCustomDate = this.isCustomDate(this.form.dateType)
      if (isCustomDate) {
        msg = this.form.customDate
      } else {
        msg = taskUtil.remindDateFmt(this.form)
      }
      if (this.todoAdd) {
        this.$emit('saveRemindTime', { id, msg, dateType, data })
      } else {
        this.$emit('save', { id, msg, dateType, data })
      }
      this.$dialogClose()
    },
    dialogClose() {
      this.$dialogClose()
      event.stopPropagation()
    },
    // 日期类型选择
    dateTypeChange(val) {
      this.customTimeVisible = this.isCustomDate(val)
    },
    // 是否是自定义时间
    isCustomDate(val) {
      return dateTypeEnum.custom === parseInt(val)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styles/variable.scss';
::v-deep {
  .el-dialog__headerbtn:focus .el-dialog__close,
  .el-dialog__headerbtn:hover .el-dialog__close {
    color: #444558 !important;
  }
}
.select-list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
.footer {
  button {
    width: 30%;
  }
  .cancel-btn {
    height: 32px;
    line-height: 30px;
    border-radius: 12px;
    margin-right: 8px;
    width: 77px;
    border: 1px solid #3a78f6;
    color: #3a78f6;
    padding: 0px;
  }
  .common_btn_next {
    padding: 0;
    height: 32px;
    line-height: 30px;
    width: 92px;
    border-radius: 12px;
  }
}
</style>
<style lange="scss">
.addTimeClass {
  z-index: 3000 !important;
}
</style>
