var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "auth-index-box" }, [
    _c("div", { staticClass: "auth-index-top" }, [
      _c("img", {
        attrs: {
          src: require("../../../assets/imgs/common/ic_logo.png"),
          alt: "",
        },
      }),
    ]),
    _c(
      "div",
      { staticClass: "auth-index-main" },
      [
        _c(
          "transition",
          { attrs: { name: "fade-transform", mode: "out-in" } },
          [_c("router-view")],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }