<template>
  <div
    class="avatar-box"
    :class="canDelete ? `del-avatar avatar-box-${type}` : `avatar-box-${type}`"
  >
    <img
      class="border-radius-20"
      :src="avatarInfo.avatar || require('@/assets/imgs/common/avatar.png')"
      v-if="type==1"
    />
    <div class="avatar-name">
      <wx-open-data
        :type="type"
        :id="avatarInfo.name || avatarInfo.username"
        :nameData="{name:avatarInfo.name || avatarInfo.username}"
      />
    </div>
    <Icon v-if="canDelete" icon-class="icon-close" class="icon-close" @click="deleteAvatar" />
  </div>
</template>
<script>
/* eslint-disable */
export default {
    props: {
      avatarInfo: {
        type: Object,
        default: () => {
          return {
            name: '',
            avatar: ''
          }
        }
      },
      canDelete: {
        type: Boolean,
        default: true
      },
      type: { // 1:人员 2:部门
        type: Boolean,
        default: 1
      }
    },
    data() {
        return {}
    },
    methods: {
      deleteAvatar() {
        this.$emit("deleteAvatar")
      }
    }
}
</script>
<style lang="scss" scoped>
.avatar-box-1 {
  border-radius: 20px !important;
}
.avatar-box {
  line-height: 24px;
  color: #444558;
  display: flex;
  position: relative;
  align-items: center;
  background: #f5f6fa;
  border-radius: 8px;
  padding: 8px;
  margin: 0px 8px 10px 0px;
  cursor: pointer;
  img {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-right: 8px;
  }
  .avatar-name {
    max-width: 90px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #14142b;
    &:before {
      content: '';
      display: block;
    }
  }
  &:hover {
    background: rgba(245, 246, 250, 0.56);
  }
}
.icon-close {
  color: #a0a3bd;
  font-size: 16px;
  cursor: pointer;
  margin-left: 10px;
  &:hover {
    color: #3a78f6;
  }
}
</style>