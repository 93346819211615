import taskText from './taskText.vue'
import taskSSelect from './taskSSelect.vue'
import taskMSelect from './taskMSelect.vue'
import taskNumber from './taskNumber.vue'
import taskDate from './taskDate.vue'
import taskFile from './taskFile.vue'

export const CUSTOMCOMPONENTS = {
  text: 'task-text', // 文本
  singleChoice: 'task-s-select', // 单选
  multipleChoice: 'task-m-select', // 多选
  number: 'task-number', // 数字
  date: 'task-date', // 日期
  file: 'task-file', // 文件
  singleMember: 'task-s-member', // 成员单选
  multipleMember: 'task-m-member' // 成员多选
}

export const COMPONENTS = {
  taskText,
  taskSSelect,
  taskMSelect,
  taskNumber,
  taskDate,
  taskFile
}
