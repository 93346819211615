export default [
  {
  path: '/meeting',
  name: 'meeting',
  component: () => {
    return import(/* webpackChunkName: "meeting" */ '../../views/meeting')
  }
},
{
  path: '/meeting/roundTableMeeting',
  name: 'roundTableMeeting',
  component: () => {
    return import(
      /* webpackChunkName: "meetingRoundTableMeeting" */
      '../../views/meeting/roundTableMeeting'
    )
  }
},
{
  path: '/meeting/commonViewMeeting',
  name: 'commonViewMeeting',
  component: () => {
    return import(
      /* webpackChunkName: "meetingCommonViewMeeting" */
      '../../views/meeting/commonViewMeeting'
    )
  }
},
{
  path: '/meeting/progressMeeting',
  name: 'progressMeeting',
  component: () => {
    return import(
      /* webpackChunkName: "meetingProgressMeeting" */
      '../../views/meeting/progressMeeting'
    )
  }
},
{
  path: '/meeting/resumptionMeeting',
  name: 'resumptionMeeting',
  component: () => {
    return import(
      /* webpackChunkName: "meetingResumptionMeeting" */
      '../../views/meeting/resumptionMeeting'
    )
  }
}
]
