var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "append-to-body": true,
        center: "",
        size: 360,
        "modal-append-to-body": true,
        "before-close": _vm.dialogClose,
        "custom-class": "workhours-detailed-dialog",
        "show-close": true,
        title: "工时明细",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "workhours-detailed-main" }, [
        _c(
          "div",
          { staticClass: "workhours-detailed-tab" },
          _vm._l(_vm.tabList, function (tItem, tIndex) {
            return _c(
              "div",
              {
                key: tIndex,
                staticClass: "detailed-tab-list",
                class:
                  _vm.hourType == tItem.id ? "detailed-tab-list-active" : "",
                on: {
                  click: function ($event) {
                    return _vm.handleTab(tItem, tIndex)
                  },
                },
              },
              [
                _c("div", { staticClass: "detailed-tab-top" }, [
                  _vm._v(" " + _vm._s(tItem.name) + " "),
                  _c("span", { staticClass: "total" }, [
                    _vm._v(_vm._s(tItem.total)),
                  ]),
                ]),
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.hourType == tItem.id,
                      expression: "hourType == tItem.id",
                    },
                  ],
                  staticClass: "line",
                }),
              ]
            )
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "workhours-detailed-content" },
          [
            _vm.showTable
              ? _c(
                  "vxe-table",
                  {
                    attrs: {
                      border: "",
                      round: "",
                      "show-header-overflow": "",
                      "show-overflow": "",
                      "max-height": "300",
                      align: _vm.allAlign,
                      "row-config": { isHover: true },
                      data: _vm.tableData,
                      "empty-text": "暂无内容",
                    },
                  },
                  [
                    _c("vxe-column", {
                      attrs: {
                        width: "120",
                        field: "hourNum",
                        title: _vm.hourType == 1 ? "预计工时" : "实际工时",
                      },
                    }),
                    _c("vxe-column", {
                      attrs: {
                        width: "120",
                        field: "executor",
                        title: "负责人",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("div", { staticClass: "executor-div" }, [
                                  _c("img", {
                                    staticClass:
                                      "executor-avatar border-radius-20",
                                    attrs: { src: row.userDto.avatar, alt: "" },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "executor-name" },
                                    [
                                      _c("wx-open-data", {
                                        attrs: {
                                          id: row.userDto.username,
                                          "name-data": {
                                            name: row.userDto.username,
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1794984340
                      ),
                    }),
                    _vm.hourType == 2
                      ? _c("vxe-column", {
                          attrs: {
                            width: "300",
                            field: "hourDate",
                            title: "工作时间",
                          },
                        })
                      : _vm._e(),
                    _c("vxe-column", {
                      attrs: { field: "hourDescribe", title: "工作描述" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                row.hourDescribe
                                  ? _c("span", { staticClass: "desc-text" }, [
                                      _vm._v(
                                        " " + _vm._s(row.hourDescribe) + " "
                                      ),
                                    ])
                                  : _c(
                                      "span",
                                      { staticClass: "desc-placeholder" },
                                      [_vm._v("暂无描述")]
                                    ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        918388724
                      ),
                    }),
                    _c("vxe-column", {
                      attrs: {
                        width: "180",
                        field: "createDate",
                        title: "添加时间",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(row.createDate.substring(0, 16)) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3425063402
                      ),
                    }),
                    _c("vxe-column", {
                      attrs: {
                        width: "100",
                        fixed: "right",
                        title: "操作",
                        "show-overflow": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "edit-button",
                                    attrs: {
                                      type: "text",
                                      disabled: _vm.disabled,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.editEvent(row)
                                      },
                                    },
                                  },
                                  [
                                    _c("Icon", {
                                      staticClass: "task-operate",
                                      attrs: {
                                        "icon-class":
                                          "icon-a-xiangmulunkuohuamiaobian-1",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "remove-button",
                                    attrs: {
                                      type: "text",
                                      disabled: _vm.disabled,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeEvent(row)
                                      },
                                    },
                                  },
                                  [
                                    _c("Icon", {
                                      staticClass: "task-operate",
                                      attrs: {
                                        "icon-class":
                                          "icon-a-xiangmulunkuohuamiaobian",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        746425326
                      ),
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }