// 基础模块方法库
import apis from '../apis_moudles/index'
import req from '../request'

// 课程详情（首页）
const CLASSROOMINFO = (params) => {
  return req.get(apis.classroomInfo, {
    params
  })
}
// 课程详情（首页）
const CLASSROOMCOMMENT = (params) => {
  return req.get(apis.classroomComment, {
    params
  })
}

// 视频播放
const VIDEOPLAY = (params) => {
  return req.post(apis.videoPlay, params)
}

// 提交评论
const COURSECOMMENTSUBMIT = (params) => {
  return req.post(apis.courseCommentSubmit, params)
}

// 创建单号
const CREATEORDER = (params) => {
  return req.post(apis.createOrder, params)
}
const CREATEORDERH5 = (params) => {
  return req.post(apis.createOrder, params, {
    headers: {
      'Web-User-Agent': 'OKRt/1.0.0 (iPhone; IOS 13.6.1)'
    }
  })
}

// 查询订单信息
const ORDERINFO = (params) => {
  return req.get(apis.orderInfo, {
    params
  })
}
// 查询订单信息
const ORDERINFOH5 = (params) => {
  return req.get(apis.orderInfo, {
    params,
    headers: {
      'Web-User-Agent': 'OKRt/1.0.0 (iPhone; IOS 13.6.1)'
    }
  })
}

// 获取付款信息
const PAYINFO = (params) => {
  return req.post(apis.payInfo, params)
}
// 获取付款信息 h5
const PAYINFOH5 = (params) => {
  return req.post(apis.payInfo, params, {
    headers: {
      'Web-User-Agent': 'OKRt/1.0.0 (iPhone; IOS 13.6.1)'
    }
  })
}
// 提交视频播放记录
const VIDEOPLAYRECORD = (params) => {
  return req.post(apis.videoPlayRecord, params)
}
// 广告提交视频播放记录
const MEDIAWATCHHISTORY = (params) => {
  return req.post(apis.mediaWatchHistory, params)
}
// 训练营解锁邀请消息
const COURSEREMINDSEND = (params) => {
  return req.post(apis.courseRemindSend, params)
}
// 训练营首页
const COURSETRAININGINDEX = (params) => {
  return req.get(apis.courseTrainingIndex, {
    params
  })
}
// 学习设置回显
const GETSTUDYSET = (params) => {
  return req.get(apis.getStudySet, {
    params
  })
}
// 学习设置
const COURSESTUDYSET = (params) => {
  return req.post(apis.courseStudySet, params)
}
// 学习设置回显
const COURSEWAITSTUDY = (params) => {
  return req.get(apis.courseWaitStudy, {
    params
  })
}
// 课程作业列表
const GETHOMEWORKLIST = (params) => {
  return req.get(apis.getHomeworkList, {
    params
  })
}
// 课程作业提交
const HOMEWORKCOMMIT = (params) => {
  return req.post(apis.homeworkCommit, params)
}
// 课程作业操作类提交
const HOMEWORKPLAYCOMMIT = (params) => {
  return req.post(apis.homeworkPlayCommit, params)
}
// 开始考试获取试卷详情
const GETEXAMPAPERINFO = (params) => {
  return req.get(apis.getExamPaperInfo, {
    params
  })
}
// 提交试卷
const EXAMPAPERINFOCOMMIT = (params) => {
  return req.post(apis.examPaperInfoCommit, params)
}
// 查看考试结果
const EXAMPAPERRESULT = (params) => {
  return req.get(apis.examPaperResult, {
    params
  })
}
// 考试统计
const EXAMANALYSE = (params) => {
  return req.get(apis.examAnalyse, {
    params
  })
}
// 统计导出
const COURSEEXPORT = (params) => {
  return req.post(apis.courseExport, params, {
    responseType: 'arraybuffer'
  })
}
// 优惠码校验
const PAYORDERVERIFY = (params) => {
  return req.post(apis.payOrderVerify, params)
}
export default [
  {
  PAYORDERVERIFY
},
{
  COURSEEXPORT
},
{
  EXAMANALYSE
},
{
  EXAMPAPERRESULT
},
{
  EXAMPAPERINFOCOMMIT
},
{
  GETEXAMPAPERINFO
},
{
  HOMEWORKPLAYCOMMIT
},
{
  HOMEWORKCOMMIT
},
{
  GETHOMEWORKLIST
},
{
  COURSEWAITSTUDY
},
{
  COURSESTUDYSET
},
{
  GETSTUDYSET
},
{
  COURSETRAININGINDEX
},
{
  COURSEREMINDSEND
},
{
  MEDIAWATCHHISTORY
},
{
  VIDEOPLAYRECORD
},
{
  CREATEORDERH5
},
{
  ORDERINFOH5
},
{
  PAYINFOH5
},
{
  CLASSROOMINFO
},
{
  CLASSROOMCOMMENT
},
{
  VIDEOPLAY
},
{
  COURSECOMMENTSUBMIT
},
{
  CREATEORDER
},
{
  ORDERINFO
},
{
  PAYINFO
}
]
