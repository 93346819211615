export default {
  path: '/auth',
  redirect: '/login',
  name: 'auth',
  component: () => {
    return import(
      /* webpackChunkName: "auth" */
      '../../views/global/auth/index.vue'
    )
  },
  children: [
    {
    path: '/login',
    name: 'login',
    component: () => {
      return import(
        /* webpackChunkName: "auth" */
        '../../views/global/auth/loginSaas.vue'
      )
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => {
      return import(
        /* webpackChunkName: "auth" */
        '../../views/global/auth/register.vue'
      )
    }
  },
  {
    path: '/invitation',
    name: 'invitation',
    component: () => {
      return import(
        /* webpackChunkName: "auth" */
        '../../views/global/auth/invitation.vue'
      )
    }
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: () => {
      return import(
        /* webpackChunkName: "auth" */
        '../../views/global/auth/resetPassword.vue'
      )
    }
  },
  {
    path: '/successNotice',
    name: 'successNotice',
    component: () => {
      return import(
        /* webpackChunkName: "auth" */
        '../../views/global/auth/successNotice.vue'
      )
    }
  }
  ]
}
