import mojs from './mo.min.js'

// taken from mo.js demos
function extend(a, b) {
  for (var key in b) {
    if (Object.prototype.hasOwnProperty.call(b, key)) {
      a[key] = b[key]
    }
  }
  return a
}

function Animocon(el, options, checkeds) {
  this.el = el
  // this.el.removeEventListener('click',null)
  this.options = extend({}, this.options)
  extend(this.options, options)

  this.checked = checkeds
  this.timeline = null
  this.timeline = new mojs.Timeline()

  for (var i = 0, len = this.options.tweens.length; i < len; ++i) {
    // console.log(this.options.tweens[i])
    this.timeline.add(this.options.tweens[i])
  }

  var self = this
  if ((this.el || '') !== '') {
    this.el.addEventListener('click', function () {
      //
      // console.log(self.checked)
      if (self.checked) {
        // self.options.onUnCheck();
        // console.log('不会执行动画',self.checked)
      } else {
        // self.options.onCheck();
        // console.log('会执行动画',self.checked)

        self.timeline.start()
        self.el.removeEventListener('click', null)
      }
      self.checked = !self.checked
    })
  }
}

Animocon.prototype.options = {
  tweens: [
    new mojs.Burst({
      shape: 'circle',
      isRunLess: true
    })
  ]
  // onCheck : function() { return false; },
  // onUnCheck : function() { return false; }
}

export default Animocon
