<template>
  <div class="set-task-time">
    <el-dialog
      title="设置提醒时间"
      :visible="true"
      width="35%"
      :append-to-body="true"
      :close-on-click-modal="true"
      top="30vh"
      @close="$dialogClose"
    >
      <!-- 日期类型 -->
      <div v-loading="formLoading" class="select-list">
        <el-select
          v-model="form.dateType"
          placeholder="请选择"
          class="m-r-20"
          @change="dateTypeChange"
        >
          <el-option
            v-for="(val, key) in dateTypeDict"
            :key="key"
            :label="val"
            :value="parseInt(key)"
          >
            <div v-if="isCustomDate(key)" class="select-decorate"></div>
            <span>{{ val }}</span>
          </el-option>
        </el-select>
        <!-- 提醒时间 -->
        <template v-if="isShowTime">
          <!-- 自定义时间 -->
          <el-date-picker
            v-if="customTimeVisible"
            v-model="form.customDate"
            type="datetime"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            placeholder="选择日期"
            popper-class="addTimeClass"
          />
          <!-- 特定时间 -->
          <template v-else>
            <el-select v-model="form.number" class="m-r-20">
              <el-option
                v-for="(val, key) in numberDict"
                :key="key"
                :label="val"
                :value="parseInt(key)"
              />
            </el-select>
            <el-select v-model="form.numberType">
              <el-option
                v-for="(val, key) in numberTypeDict"
                :key="key"
                :label="val"
                :value="parseInt(key)"
              />
            </el-select>
          </template>
        </template>
      </div>
      <span slot="footer" class="flex-new justify-end footer">
        <el-button class="cancel-btn" @click="$dialogClose">取 消</el-button>
        <button
          v-loading="loading"
          class="common_btn_next common_btn_next_able"
          @click="save"
        >
          添 加
        </button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import formMixin from '@/utils/mixins/form'
import taskUtil from '@/utils/task.js' // 任务util
import remindEnums from '@/utils/enums/remind.js'
const dateTypeEnum = remindEnums.DATETYPENUM // 日期类型枚举
export default {
  mixins: [formMixin],
  props: {
    // 任务id
    taskId: {
      required: false
    }
  },
  data() {
    return {
      form: {
        dateType: dateTypeEnum.beforeStart, // 日期类型(默认为任务开始前)
        number: 1, // 数量(默认为1)
        numberType: remindEnums.NUMBERTYPENUM.minute // 数量类型(默认为分钟)
      }, // 表单
      dateTypeDict: remindEnums.DATETYPE, // 日期类型字典
      numberDict: remindEnums.NUMBER, // 数量字典
      numberTypeDict: remindEnums.NUMBERTYPE, // 数量类型字典
      customTimeVisible: false, // 自定义时间显示
      taskAddObject: '',
      id: ''
    }
  },
  computed: {
    // 是否显示时间选择
    isShowTime() {
      const { not, start, end } = dateTypeEnum
      return ![not, start, end].includes(this.form.dateType)
    }
  },
  created() {
    // id存在调用获取详情接口
    this.taskAddObject = this.$store.state.common.taskAddObject
    this.taskId && this.getDetail()
    this.init()
  },
  methods: {
    init() {
      if (this.taskAddObject.default === '1') {
        this.form = this.taskAddObject.taskRemind
      }
    },
    // 获取详情
    async getDetail() {
      await this.$apis
        .GETTASKREMIND({
          id: this.taskId
        })
        .then((rest) => {
          // 自定时间选择显示
          // console.log(rest.from,'asasas')
          this.customTimeVisible = this.isCustomDate(rest.from.dateType)
          if (this.taskAddObject.default === '1') {
            this.form = rest.from
          }
        })
    },
    // 保存
    async save() {
      if (this.taskId) {
        this.form.taskId = this.taskId
      }
      console.log(1111, this.id, this.taskId)
      const path = this.id && this.taskId ? 'TASKREMINDUPDATE' : 'TASKREMINDADD'
      this.submitDetail(path, this.form)
    },
    // 提交提醒
    async submitDetail(path, _obj) {
      var that = this
      if (_obj.dateType === 7 && !_obj.customDate) {
        that.$message.warn('自定义时间不能为空')
      } else {
        if ((_obj.customDate || '') !== '') {
          _obj.customDate = _obj.customDate + ':00'
        }
        this.$apis[path](_obj).then((res) => {
          if (res.status === 200) {
            that.taskAddObject.taskRemind = _obj
            that.$store.commit('common/saveTaskAddObject', that.taskAddObject)
            if (res.data) {
              // 保存成功后的处理
              this.id = res.data.id
              that.successHandle(res.data.id, res.data.dateType, res.data)
              that.loading = false
            }
          } else if (res.status === 70000) {
            that.$message({
              msg: res.message,
              type: 'error'
            })
          } else {
            that.$message({
              msg: res.message,
              type: 'error'
            })
          }
        })
      }
    },
    // 保存成功后的处理
    successHandle(id, dateType, data) {
      let msg = ''
      // 自定义时间
      const isCustomDate = this.isCustomDate(this.form.dateType)
      if (isCustomDate) {
        msg = this.form.customDate
      } else {
        msg = taskUtil.remindDateFmt(this.form)
      }
      this.$emit('save', { id, msg, dateType, data })
      this.$dialogClose()
    },
    // 日期类型选择
    dateTypeChange(val) {
      this.customTimeVisible = this.isCustomDate(val)
    },
    // 是否是自定义时间
    isCustomDate(val) {
      return dateTypeEnum.custom === parseInt(val)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styles/variable.scss';
::v-deep {
  .el-dialog__headerbtn:focus .el-dialog__close,
  .el-dialog__headerbtn:hover .el-dialog__close {
    color: #444558 !important;
  }
}

.select-list {
  display: flex;
  justify-content: space-between;
}
.footer {
  button {
    width: 30%;
  }
  .cancel-btn {
    height: 40px;
    width: 120px;
    border-radius: 18px;
    margin-right: 14px;
  }
  .common_btn_next {
    height: 40px;
    line-height: 40px;
    padding: 0;
    width: 120px;
    border-radius: 18px;
    margin-right: 14px;
  }
}
</style>
<style lange="scss">
.addTimeClass {
  z-index: 3000 !important;
}
</style>
