export default [
  {
  path: '/classroom',
  name: 'classroom',
  component: () => {
    return import(
      /* webpackChunkName: "classroom" */
      '../../views/classroom'
    )
  }
},
{
  path: '/classroom/detail',
  name: 'classroomDetail',
  component: () => {
    return import(
      /* webpackChunkName: "classroomDetail" */
      '../../views/classroom/page/detail'
    )
  }
},
{
  path: '/classroom/analyse',
  name: 'classroomAnalyse',
  component: () => {
    return import(
      /* webpackChunkName: "classroomAnalyse" */
      '../../views/classroom/page/analyse'
    )
  }
},
{
  path: '/classroom/homework',
  name: 'classroomHomework',
  component: () => {
    return import(
      /* webpackChunkName: "classroomHomework" */
      '../../views/classroom/page/homework'
    )
  }
},
{
  path: '/classroom/exam',
  name: 'classroomExam',
  component: () => {
    return import(
      /* webpackChunkName: "classroomExam" */
      '../../views/classroom/page/exam'
    )
  }
},
{
  path: '/classroom/buyDetail',
  name: 'classroomBuyDetail',
  component: () => {
    return import(
      /* webpackChunkName: "classroomBuyDetail" */
      '../../views/classroom/page/buyDetail'
    )
  }
}
]
