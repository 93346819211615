<template>
  <div ref="cycleWrap" class="cycle-box">
    <div class="cycle-box-right" v-if="datas.length > 0">
      <!-- 单双月季度调整 -->
      <div class="cycle-box-left">
        <div>
          <el-popover
            ref="cycleLeftLargePopver"
            placement="bottom"
            width="160"
            :open-delay="300"
            trigger="click"
            popper-class="popover-cycle"
            :visible-arrow="false"
            @after-enter="largeAfterEnter"
            @after-leave="largeAfterLeave"
          >
            <ul class="popover-ul">
              <div
                class="popover-ul-auto"
                :class="
                  type == 0 && userInfo.isSuperAdmin == 1
                    ? 'popover-ul-min'
                    : ''
                "
              >
                <li
                  v-for="(item, index) in datas"
                  :key="index"
                  class="header-change-large header-change-boxs"
                  :class="item.defSelected == 1 ? 'header-change-select' : ''"
                  @click.stop="leftSelectLargeClickFunction(item, index)"
                >
                  <p>
                    <span class="cycle-span">
                      {{
                      item.type === 1
                      ? CYCLETYPE[item.type][item.interval]
                      : CYCLETYPE[item.type]
                      }}
                    </span>
                    <el-tooltip :content="item.name">
                      <span>{{ item.name }}</span>
                    </el-tooltip>
                  </p>
                  <div v-if="item.redPointState && redVisible" class="common-red-point"></div>
                </li>
              </div>
              <li
                v-if="type == 0 && userInfo.isSuperAdmin == 1 && redVisible"
                class="header-change header-change-btn"
                @click="createNewCycle"
              >
                <p class="cycle-header-btn">+&nbsp;新建周期</p>
              </li>
            </ul>
            <div
              slot="reference"
              class="flex-new arrow-icon"
              :class="downBoolean ? 'cycle-box-left-title-weight' : ''"
              @click="downBoolean = !downBoolean"
            >
              <p v-if="largaeObject != null" class="cycle-box-left-title">
                {{
                largaeObject.type === 1
                ? CYCLETYPE[largaeObject.type][largaeObject.interval]
                : CYCLETYPE[largaeObject.type]
                }}
              </p>
              <Icon
                v-show="downBoolean"
                slot="reference"
                icon-class="icon-arrow-up"
                class="cycle-arrow-icon"
              />
              <Icon
                v-show="!downBoolean"
                slot="reference"
                icon-class="icon-arrow-down"
                class="cycle-arrow-icon"
              />

              <div v-if="showLargeRedpoint && redVisible" class="common-red-point"></div>
            </div>
          </el-popover>
        </div>
      </div>
      <el-tooltip
        v-show="
          largaeObject &&
          nowCycle[largaeObject.id] &&
          nowCycle[largaeObject.id].id !== nowSelectCycle.id
        "
        content="回到当前周期"
        placement="top"
        :open-delay="300"
      >
        <div @click="backToNow" class="set-btn back-btn">
          <Icon icon-class="icon-cycleBack" class="back-icon" />
        </div>
      </el-tooltip>
      <!-- 左侧按钮 -->
      <div class="cycle-arrow">
        <el-popover
          ref="cycleLeftPopver"
          placement="bottom"
          width="180"
          :open-delay="300"
          trigger="click"
          popper-class="popover-cycle popover-cycle-left"
          :visible-arrow="false"
          @after-enter="smallLeftAfterEnter"
          @after-leave="smallLeftAfterLeave"
        >
          <ul class="popover-ul">
            <!-- v-show="index < 3" -->
            <li
              v-for="(item, index) in leftArr"
              :key="index"
              class="header-change"
              @click.stop="leftSelectClickFunction(item, index)"
            >
              <p>{{ item.name }}</p>
              <div v-if="item.redPointState && redVisible" class="common-red-point"></div>
            </li>
          </ul>
          <div
            slot="reference"
            class="arrow-icon left-icon"
            @mouseenter="lfBoolean = true"
            @mouseleave="smallLefthoverLeave"
          >
            <Icon
              v-show="leftArr.length > 0"
              slot="reference"
              icon-class="icon-left"
              class="cycle-box-lf"
              :class="lfBoolean ? 'cycle-box-lf-active' : ''"
            />
            <div v-if="showLeftRedpoint && redVisible" class="common-red-point"></div>
          </div>
        </el-popover>
      </div>
      <div class="flex-new">
        <div
          v-for="item in centerArr"
          :key="item.id"
          class="cycle-lf-box cen-box"
          :class="item.defSelected == 1 ? 'cycle-cen-box' : ''"
          @click="leftOneClickFunction(item)"
        >
          {{ item.name }}
          <div v-if="item.redPointState && redVisible" class="common-red-point"></div>
        </div>
      </div>
      <!-- 右侧按钮 -->
      <div class="cycle-arrow">
        <el-popover
          ref="cycleRightPopver"
          placement="bottom"
          width="180"
          :open-delay="300"
          trigger="click"
          popper-class="popover-cycle popover-cycle-right"
          :visible-arrow="false"
          @after-enter="smallRightAfterEnter"
          @after-leave="smallRightAfterLeave"
        >
          <ul class="popover-ul">
            <!-- v-show="index < 3" -->
            <li
              v-for="(item, index) in rightArr"
              :key="index"
              class="header-change"
              @click.stop="rightSelectClickFunction(item, index)"
            >
              <p>{{ item.name }}</p>
              <div v-if="item.redPointState && redVisible" class="common-red-point"></div>
            </li>
          </ul>
          <div
            slot="reference"
            class="arrow-icon right-icon"
            @mouseenter="rgBoolean = true"
            @mouseleave="smallRighthoverLeave"
          >
            <Icon
              v-show="rightArr.length > 0"
              slot="reference"
              icon-class="icon-right"
              class="cycle-box-lf"
              :class="rgBoolean ? 'cycle-box-lf-active' : ''"
            />
            <div v-if="showRightRedpoint && redVisible" class="common-red-point"></div>
          </div>
        </el-popover>
      </div>
      <el-tooltip content="周期设置" placement="top" :open-delay="300" v-show="false">
        <div v-show="largaeObject.currentUserPower === 1" class="set-btn" @click="cycleSet">
          <Icon icon-class="icon-cycleSet" class="back-icon" />
        </div>
      </el-tooltip>
    </div>
    <cycle-setting-view
      v-if="cycleSetVisible"
      v-model="cycleSetVisible"
      :cycle-info="largaeObject"
      @delSelf="permissionChange"
      @changeUpdate="getTargetCyclePeriod"
    />
  </div>
</template>
<script>
import cycleEnum from '@/utils/enums/cycle.js'
import db from '@/utils/info-center/database'
import redpointFun from '@/utils/info-center'
import cycleSettingView from '@/views/admin/cycle/CycleSetting'
export default {
  components: {
    cycleSettingView
  },
  props: {
    type: {
      type: Number,
      default: 0
    },
    pushRoute: {
      // 判断是否跳路由
      type: Boolean,
      default: false
    },
    redVisible: {
      // 红点是否显示
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      cycleSetVisible: false, // 周期设置弹窗
      CYCLETYPE: cycleEnum.CYCLETYPE,
      dialogVisible: false,
      cycleBoxGuideVisible: false, // 指南弹窗开关
      downBoolean: false, // 大周期鼠标滑过
      downBooleanSwitch: false, // 大周期鼠标滑出
      rgBoolean: false, // 右则按钮
      rgBooleanSwitch: false, // 小周期鼠标滑出
      lfBoolean: false, // 左则按钮
      lfBooleanSwitch: false, // 小周期鼠标滑出
      // 大周期对象
      // datas: [],
      smallDatas: [],
      // 大周期对象
      largaeObject: {},
      // 选中下标
      selectIndex: '-1',
      // 左侧数组
      leftArr: [],
      // 右侧数组
      rightArr: [],
      centerArr: [],
      // 新建周期弹框
      homePeriodTroperty: '周期',
      // 上一次选择周期
      lastPeriod: '',
      // 是否是上次小周期
      lastPeriodBoolean: false,
      buleBtnClickBooean: -1,
      datas: [],
      curType: null,
      routeObj: {},

      cycleRedpoints: [],
      largeRedpoints: [], // 存在红点的大周期

      nowCycle: {}, // 当前周期
      nowSelectCycle: {} // 选中周期
    }
  },
  computed: {
    showLeftRedpoint() {
      // 左箭头红点
      return this.leftArr.some((item) => {
        return item.redPointState
      })
    },
    showRightRedpoint() {
      // 右箭头红点
      return this.rightArr.some((item) => {
        return item.redPointState
      })
    },
    showLargeRedpoint() {
      // 大周期红点除去当前大周期
      if (this.largeRedpoints.length > 0 && this.largaeObject.id) {
        return this.largeRedpoints.some((item) => {
          if (item !== this.largaeObject.id) {
            return true
          }
          return false
        })
      }
      return false
    }
  },
  watch: {
    // ---update
    '$route.query': {
      handler(val) {
        if (val && val.periodId || this.$route.query) {
          this.routeObj = (val || this.$route.query)
          this.getTargetCyclePeriod()
        }
      },
      deep: true
    },
    'largaeObject.currentUserPower': {
      handler(val) {
        this.$emit('userCyclePower', val)
      }
    }
  },
  created() {
    // 请求数据
    if (this.$route.query) {
      this.routeObj = this.$route.query
    }
    // 周期红点
    this.$nextTick(() => {
      db.read('2000', (res) => {
        this.cycleRedpoints = res
      })
      this.getTargetCyclePeriod()
    })
  },
  mounted() {
    const _this = this
    // 监听查询周期内容
    _this.$on('global:getTargetDetailsPath', (id) => {
      // 通知查询周期内容
      _this.getInformPeriod(id)
    })
    this.bus(this).$on('global:cycleBoxGuideVisible', (list) => {
      this.cycleBoxGuideVisible = list
    })
    if (this.type === 1) {
      this.leftSelectLargeClickFunction(this.datas[0], 0)
    }
    // 监听红点数据-周期
    this.bus(this).$on('okrCycleRedPoint', (list) => {
      this.cycleRedpoints = list
      this.handleRedpoint()
      this.jsonDataFunction('normal', 'normal', {}, true)
    })
    this.$nextTick(() => {
      setTimeout(() => {
        if (_this.$refs.cycleWrap) {
          _this.$emit('cycleWidth', _this.$refs.cycleWrap.clientWidth)
        }
      }, 500)
    })
  },
  methods: {
    permissionChange() {
      this.cycleSetVisible = false
      this.getTargetCyclePeriod()
    },
    // 大周期弹框显示
    largeAfterEnter() {
      this.downBoolean = true
      this.downBooleanSwitch = true
    },
    // 大周期弹框消失
    largeAfterLeave() {
      this.downBoolean = false
      this.downBooleanSwitch = false
    },
    // 鼠标划出
    largeMouseLeave() {
      if (!this.downBooleanSwitch) {
        this.downBoolean = false
      }
    },
    // 左侧按钮弹框划入
    smallLeftAfterEnter() {
      this.lfBoolean = true
      this.lfBooleanSwitch = true
    },
    // 左侧按钮弹框划出
    smallLeftAfterLeave() {
      this.lfBoolean = false
      this.lfBooleanSwitch = false
    },
    // 左侧按钮鼠标划出
    smallLefthoverLeave() {
      if (!this.lfBooleanSwitch) {
        this.lfBoolean = false
      }
    },
    // 右侧按钮弹框划入
    smallRightAfterEnter() {
      this.rgBoolean = true
      this.rgBooleanSwitch = true
    },
    // 右侧按钮弹框划出
    smallRightAfterLeave() {
      this.rgBoolean = false
      this.rgBooleanSwitch = false
    },
    // 右侧按钮鼠标划出
    smallRighthoverLeave() {
      if (!this.rgBooleanSwitch) {
        this.rgBoolean = false
      }
    },
    // 新建周期弹框
    createNewCycle() {
      this.$emit('newCycleOpen')
    },
    // 关闭新建周期事件
    newCycleClose() {
      // 刷新周期列表
      this.getTargetCyclePeriod()
      // 关闭提示框
      if (this.$refs.cycleLeftLargePopver) {
        this.$refs.cycleLeftLargePopver.doClose()
      }
    },
    // 获取周期内容
    async getTargetCyclePeriod(type) {
      const that = this
      // 在此请求数据赋值给变量
      this.$apis.CYCLIST().then((rest) => {
        if (rest.status === 200) {
          // 判断周期是否可见
          let hasNoPermission = false
          rest.data = rest.data.filter((item) => {
            if (item.currentUserPower === 2 && item.defSelected === 1) {
              hasNoPermission = true
            }
            return item.currentUserPower !== 2
          })
          if (hasNoPermission && rest.data.length > 0) {
            rest.data[0]['defSelected'] = 1
          }
          if (rest.data.length > 0) {
            if (this.pushRoute) {
              that.handleRedpoint(rest.data)
            }
            that.datas = rest.data || []
            that.$store.commit('user/setCycleArrs', that.datas)
            // 周期数组存储到仓库 ---TODO---
            for (let i = 0; i < that.datas.length; i++) {
              that.datas[i].cycleInfoList = this.getTargetPeriodDisposeSublevel(
                that.datas[i].cycleInfoList,
                that.datas[i]
              )
            }
            that.jsonDataFunction('normal', 'normal', '', '', type)
          } else {
            if (this.pushRoute) {
              this.$router.push('/okrListNull')
            }
          }
        } else {
          this.$message.error(rest.message)
        }
      })
    },
    // 处理周期数据， 添加红点状态 ---update start
    handleRedpoint(arr) {
      const that = this
      if (!arr) {
        arr = that.datas
      }
      this.largeRedpoints = []
      arr.forEach((cycle) => {
        let cycleState = false
        cycle.redPointState = false
        cycle.cycleInfoList.forEach((subCycle) => {
          const subobj = this.hasState(subCycle.id)
          subCycle.redPointState = subobj.state
          subCycle.rePointInfo = subobj.point
          if (subobj.state) {
            cycleState = true
          }
        })
        this.$set(cycle, 'redPointState', cycleState)
        if (cycleState) {
          this.largeRedpoints.push(cycle.id)
        }
      })
      that.datas = arr || []
    },
    hasState(id) {
      let point = {}
      const state = this.cycleRedpoints.some((item) => {
        if (item.busicId === id) {
          point = item
          return true
        }
        return false
      })
      return {
        state,
        point
      }
    },
    // 消除红点
    clearRedpoint(item) {
      if (!item) return
      redpointFun.removePoint(
        item.rePointInfo.behaviorType,
        item.rePointInfo.busicId,
        this
      )
    },
    // 处理子周期列表
    getTargetPeriodDisposeSublevel(dataListSublevel, cycleSelection) {
      const toDayTime = new Date()
      for (let z = 0; z < dataListSublevel.length; z++) {
        dataListSublevel[z].scoreStatus = cycleSelection && cycleSelection.scoreStatus
        dataListSublevel[z].timeState = false
        dataListSublevel[z].timeText = ''
        dataListSublevel[z].timeStartTume = ''
        dataListSublevel[z].timeEndTime = ''
        if ((cycleSelection.type === 1 || cycleSelection.type === 2) && dataListSublevel[z].type === 0) {
          dataListSublevel[z].name = `${dataListSublevel[z].startDate.substring(0, 4)}年 ${dataListSublevel[z].name}`
        }
        // 判断当前周期 ---update
        if (dataListSublevel[z].type === 0) {
          // 非年度周期
          const time = new Date().getTime()
          if (
            new Date(
              dataListSublevel[z].startDate.replace(/\-/g, '/')
            ).getTime() < time &&
            new Date(
              dataListSublevel[z].endDate.replace(/\-/g, '/')
            ).getTime() > time
          ) {
            this.nowCycle[dataListSublevel[z].cycleId] = dataListSublevel[z]
            this.$emit(
              'backToNow',
              this.largaeObject,
              this.nowCycle,
              this.nowSelectCycle
            )
          }
        }
        if (
          cycleSelection.type === 1 &&
          `${cycleSelection.defSelected}` === '0'
        ) {
          // 月份
          const startTume = this.getPeriodTime(dataListSublevel[z].startDate, 6)
          const endTime = this.getPeriodTime(dataListSublevel[z].endDate, 6)
          // 当前时间段内设为选中
          if (
            startTume <= toDayTime.getMonth() + 1 &&
            toDayTime.getMonth() + 1 <= endTime &&
            `${dataListSublevel[z].defSelected}` === '0' &&
            dataListSublevel[z].type === 0
          ) {
            dataListSublevel[z].timeState = true
            const nian = toDayTime.getFullYear()
            if (nian === this.getPeriodTime(dataListSublevel[z].startDate, 7)) {
              dataListSublevel[z].timeText = '本周期' // M
            } else {
              dataListSublevel[z].timeText = ''
            }
            dataListSublevel[z].defSelected = 1
            dataListSublevel[z].timeStartTume = this.getPeriodTime(
              dataListSublevel[z].startDate,
              2
            )
            dataListSublevel[z].timeEndTime = this.getPeriodTime(
              dataListSublevel[z].endDate,
              2
            )
          }
        } else if (
          cycleSelection.type === 2 &&
          `${cycleSelection.defSelected}` === '0' &&
          dataListSublevel[z].type === 0
        ) {
          // 季度
          const startTume = this.getPeriodTime(dataListSublevel[z].startDate, 5)
          const endTime = this.getPeriodTime(dataListSublevel[z].endDate, 5)
          if (
            startTume <= toDayTime &&
            toDayTime <= endTime &&
            `${dataListSublevel[z].defSelected}` === '0'
          ) {
            dataListSublevel[z].defSelected = 1
            dataListSublevel[z].timeState = true
            dataListSublevel[z].timeText = '本季度' // Q
            dataListSublevel[z].timeStartTume = this.getPeriodTime(
              dataListSublevel[z].startDate,
              4
            )
            dataListSublevel[z].timeEndTime = this.getPeriodTime(
              dataListSublevel[z].endDate,
              4
            )
          }
        } else if (
          cycleSelection.type === 3 &&
          `${cycleSelection.defSelected}` === '0' &&
          dataListSublevel[z].type === 0
        ) {
          // 年度
          const endTime = this.getPeriodTime(dataListSublevel[z].endDate, 7)
          if (
            toDayTime.getFullYear() === endTime &&
            `${dataListSublevel[z].defSelected}` === '0'
          ) {
            dataListSublevel[z].defSelected = 1
            dataListSublevel[z].timeState = true
            dataListSublevel[z].timeText = '本周期' // Y
            dataListSublevel[z].timeStartTume = this.getPeriodTime(
              dataListSublevel[z].startDate,
              3
            )
            dataListSublevel[z].timeEndTime = this.getPeriodTime(
              dataListSublevel[z].endDate,
              3
            )
          }
        } else if (
          cycleSelection.type === 4 &&
          `${cycleSelection.defSelected}` === '0' &&
          dataListSublevel[z].type === 0
        ) {
          // 自定义
          const startTume = this.getPeriodTime(dataListSublevel[z].startDate, 5)
          const endTime = this.getPeriodTime(dataListSublevel[z].endDate, 5)
          const times_ = toDayTime.getTime()
          if (
            startTume >= times_ &&
            times_ <= endTime &&
            `${dataListSublevel[z].defSelected}` === '0'
          ) {
            dataListSublevel[z].defSelected = 1
            dataListSublevel[z].timeState = true
            dataListSublevel[z].timeText = '本周期' // C
            dataListSublevel[z].timeStartTume = ''
            dataListSublevel[z].timeEndTime = ''
          }
        }
      }
      return dataListSublevel
    },
    getPeriodTime(data, type) {
      // 第一个参数时间，第二个参数状态1：当前时间 ，2：月 3：年 4:季度 5:数据编码时间
      let now
      if (type === 1) {
        now = new Date()
        return now
      } else {
        now = new Date(data.replace(/\-/g, '/'))
        const year = now.getFullYear()
        const month = now.getMonth() + 1
        const date = now.getDate()
        const times = now.getTime() // 时间戳
        if (type === 2) {
          return year + '年' + month + '月' + date + '日'
        } else if (type === 3) {
          return year + '年' + month + '月' + date + '日'
        } else if (type === 4) {
          return year + '年' + month + '月' + date + '日'
        } else if (type === 5) {
          return times
        } else if (type === 6) {
          return month
        } else if (type === 7) {
          return year
        }
      }
    },
    // 请求过来的  大小周期的处理  小周期左右两边下拉的数组  与 大周期下拉的数组 :refreshType: cycleSelectRefresh
    jsonDataFunction(type_, types, items, freshState, refreshType) {
      const that = this
      let arrs = []
      let largeArr = []
      largeArr = JSON.parse(JSON.stringify(that.datas)) // 大周期
      if (largeArr.length > 0) {
        that.homePeriodTroperty = '周期'
      } else {
        that.homePeriodTroperty = null
        // that.$store.dispatch('user/_getUpdataPeriodText', '')
      }
      for (let i = 0; i < largeArr.length; i++) {
        const match = this.routeObj.quarterId
          ? this.routeObj.quarterId === largeArr[i].id
          : `${largeArr[i].defSelected}` === '1'
        if (match) {
          largeArr[i].defSelected = 1
          arrs = largeArr[i].cycleInfoList // 小周期
          // 存储选择的大周期
          this.$store.commit('common/setQuarterId', largeArr[i].id)
          that.smallDatas = [...arrs]
          if (type_ !== 'large') {
            that.largaeObject = largeArr[i]
            that.$emit(
              'backToNow',
              that.largaeObject,
              that.nowCycle,
              that.nowSelectCycle
            )
          }
          this.$store.commit('common/setOkrCycleList', {
            largaeCycle: that.largaeObject,
            smallCycle: that.smallDatas,
            allCycleList: largeArr
          })
        } else {
          largeArr[i].defSelected = 0
        }
      }
      // 小周期判断没有默认选择项
      var arrsDefSelected = 0
      for (let i = 0; i < arrs.length; i++) {
        const match = this.routeObj.periodId
          ? this.routeObj.periodId === arrs[i].id
          : `${arrs[i].defSelected}` === '1'
        if (match) {
          arrsDefSelected = 1
        }
      }
      if (arrsDefSelected === 0) {
        if (arrs.length > 0) {
          arrs[0]['defSelected'] = '1'
        }
      }
      // 小周期
      for (let i = 0; i < arrs.length; i++) {
        const match = this.routeObj.periodId
          ? this.routeObj.periodId === arrs[i].id
          : `${arrs[i].defSelected}` === '1'
        if (match) {
          arrs[i].defSelected = 1
          that.selectIndex = i
          // 上一次小周期
          if (arrs[i].id === that.lastPeriod) {
            that.lastPeriodBoolean = true
          } else {
            that.lastPeriodBoolean = false
          }
          // 存储默认的小周期
          this.$store.commit('common/setPeriodId', arrs[i].id)

          // 存储小周期名称
          localStorage.setItem(
            'periodText',
            `${arrs[i].timeText || ''} ${arrs[i].name}`
          )
          if (!freshState && refreshType !== 'cycleSelectRefresh') {
            this.$emit('getDefaultCycle', arrs[i])
          }
          if (types === 'oneCenter') {
            for (let i = 0; i < that.centerArr.length; i++) {
              if (items.id === that.centerArr[i].id) {
                that.$set(that.centerArr[i], 'defSelected', 1)
              } else {
                that.$set(that.centerArr[i], 'defSelected', 0)
              }
            }
          } else {
            // 左右下拉框数组
            that.leftArr = []
            that.rightArr = []
            if (arrs.length < 4) {
              that.centerArr = [...arrs]
            } else {
              const cenArr = []
              const lfArr = []
              if (i === 0 || i === 1) {
                cenArr.push(arrs[0])
                cenArr.push(arrs[1])
                cenArr.push(arrs[2])
                that.centerArr = [...cenArr]
                that.leftArr = []
                that.rightArr = arrs.slice(3, arrs.length)
              } else if (i === 2) {
                lfArr.push(arrs[0])
                cenArr.push(arrs[1])
                cenArr.push(arrs[2])
                cenArr.push(arrs[3])
                that.centerArr = [...cenArr]
                that.leftArr = [...lfArr]
                that.rightArr = arrs.slice(4, arrs.length)
              } else if (i === arrs.length - 1) {
                cenArr.push(arrs[i - 2])
                cenArr.push(arrs[i - 1])
                cenArr.push(arrs[i])
                that.centerArr = [...cenArr]
                that.leftArr = arrs.slice(0, i - 2).reverse()
                that.rightArr = []
              } else {
                cenArr.push(arrs[i - 1])
                cenArr.push(arrs[i])
                cenArr.push(arrs[i + 1])
                that.centerArr = [...cenArr]
                that.leftArr = arrs.slice(0, i - 1).reverse()
                that.rightArr = arrs.slice(i + 2, arrs.length)
              }
            }
          }
          this.nowSelectCycle = arrs[i]
          this.$emit(
            'backToNow',
            this.largaeObject,
            this.nowCycle,
            this.nowSelectCycle
          )
          const _obj = {}
          _obj.data = that.largaeObject || [] // 大周期对象
          _obj.smallCycleObj = arrs[i] || {} // 对应小周期数组
          _obj.homePeriodTroperty = that.homePeriodTroperty || null // 标题
          _obj.type = type_ // 类型   normal 首次加载  large 切换大周期  small 切换小周期
          _obj.lastPeriod = that.lastPeriod || '' // 上一次小周期
          _obj.lastPeriodBoolean = that.lastPeriodBoolean // 上一次小周期和当前存储周期是否相同
          if (!freshState) {
            that.$emit('cycledown', _obj)
          }
          // console.log(that.leftArr,that.rightArr)
          // 向外抛出方法 比较是否是过去选中的周期
          // that.$emit('cycledown', _obj)
          this.routerClear()
          return
        } else {
          arrs[i].defSelected = 0
          if (types === 'oneCenter') {
            for (let i = 0; i < that.centerArr.length; i++) {
              if (items.id === that.centerArr[i].id) {
                that.$set(that.centerArr[i], 'defSelected', 1)
              } else {
                that.$set(that.centerArr[i], 'defSelected', 0)
              }
            }
          } else {
            // 左右下拉框数组
            if (arrs.length === 1) {
              that.leftArr = []
              that.rightArr = []
              that.centerArr = [...arrs]
            } else if (arrs.length === 2) {
              that.leftArr = []
              that.rightArr = []
              that.centerArr = [...arrs]
            } else if (arrs.length === 3) {
              that.leftArr = []
              that.rightArr = []
              that.centerArr = [...arrs]
            } else {
              // const lfNumber = i - 1
              // const rgNumber = i + 2
              const cenArr = []
              const lfArr = []
              // const rgArr = []
              if (i === 0 || i === 1) {
                cenArr.push(arrs[0])
                cenArr.push(arrs[1])
                cenArr.push(arrs[2])
                that.centerArr = [...cenArr]
                that.leftArr = []
                that.rightArr = arrs.slice(3, arrs.length)
              } else if (i === 2) {
                lfArr.push(arrs[0])
                cenArr.push(arrs[1])
                cenArr.push(arrs[2])
                cenArr.push(arrs[3])
                that.centerArr = [...cenArr]
                that.leftArr = [...lfArr]
                that.rightArr = arrs.slice(4, arrs.length)
              } else if (i === arrs.length - 1) {
                cenArr.push(arrs[i - 2])
                cenArr.push(arrs[i - 1])
                cenArr.push(arrs[i])
                that.centerArr = [...cenArr]
                that.leftArr = arrs.slice(0, i - 2).reverse()
                that.rightArr = []
              } else {
                cenArr.push(arrs[i - 1])
                cenArr.push(arrs[i])
                cenArr.push(arrs[i + 1])
                that.centerArr = [...cenArr]
                that.leftArr = arrs.slice(0, i - 1).reverse()
                that.rightArr = arrs.slice(i + 2, arrs.length)
              }
            }
          }
        }
      }
    },
    routerClear() {
      if (this.$route.query.quarterId) {
        this.$router.replace({ path: '/okr', query: {} })
        this.routeObj = {}
      }
    },
    // 一个按钮时点击
    leftOneClickFunction(item) {
      const that = this
      this.clearRedpoint(item)
      // that.selectIndex = that.selectIndex -1; //轮显
      that.publicFunctionBtns(item)
      that.resetJsonData('oneCenter', item)
    },
    publicFunctionBtns(item) {
      const that = this
      let largeArr = []
      largeArr = JSON.parse(JSON.stringify(that.datas)) // 大周期
      for (let i = 0; i < largeArr.length; i++) {
        if (largeArr[i].defSelected === 1) {
          for (var j = 0; j < largeArr[i].cycleInfoList.length; j++) {
            if (item.id === largeArr[i].cycleInfoList[j].id) {
              that.selectIndex = j
            }
          }
        }
      }
      that.datas = [...largeArr]
    },
    // 重置数据
    resetJsonData(type_, items) {
      const that = this
      const largeArr = [...that.datas]
      for (let i = 0; i < largeArr.length; i++) {
        if (largeArr[i].defSelected === 1) {
          for (var j = 0; j < largeArr[i].cycleInfoList.length; j++) {
            largeArr[i].cycleInfoList[j].defSelected = '0'
          }
          largeArr[i].cycleInfoList[that.selectIndex].defSelected = '1'
          that.datas = [...largeArr]
          that.jsonDataFunction('small', type_, items)
        }
      }
    },
    // 左下拉框点击
    leftSelectClickFunction(item, index) {
      this.clearRedpoint(item)
      this.leftRightSelect(item)
      if (this.$refs.cycleLeftLargePopver) {
        this.$refs.cycleLeftLargePopver.doClose()
      }
      this.$refs.cycleLeftPopver.doClose()
    },
    // 右小周期下拉框点击时重置数据方法
    rightSelectClickFunction(item, index) {
      this.clearRedpoint(item)
      this.leftRightSelect(item)
      if (this.$refs.cycleLeftLargePopver) {
        this.$refs.cycleLeftLargePopver.doClose()
      }
      this.$refs.cycleRightPopver.doClose()
    },
    // 右小周期下拉框点击时重置数据方法
    leftRightSelect(item) {
      const that = this
      let largeArr = []
      largeArr = JSON.parse(JSON.stringify(that.datas)) // 大周期
      for (let i = 0; i < largeArr.length; i++) {
        if (largeArr[i].defSelected === 1) {
          for (var j = 0; j < largeArr[i].cycleInfoList.length; j++) {
            if (item.id === largeArr[i].cycleInfoList[j].id) {
              that.selectIndex = j
            }
          }
        }
      }
      that.datas = [...largeArr]
      that.resetJsonData('select')
    },
    // 左侧大周期
    leftSelectLargeClickFunction(item, index) {
      let largeArr = []
      const that = this
      largeArr = JSON.parse(JSON.stringify(that.datas)) || [] // 大周期
      for (let i = 0; i < largeArr.length; i++) {
        if (largeArr[i].id === item.id) {
          largeArr[i].defSelected = 1
          that.largaeObject = largeArr[i]
          that.$emit(
            'backToNow',
            that.largaeObject,
            that.nowCycle,
            that.nowSelectCycle
          )
          this.$store.commit('common/setOkrCycleList', {
            largaeCycle: that.largaeObject,
            smallCycle: that.smallDatas,
            allCycleList: largeArr
          })
        } else {
          largeArr[i].defSelected = 0
        }
      }
      that.datas = [...largeArr]
      this.handleRedpoint()
      that.jsonDataFunction('large', 'large', item)
      if (that.$refs.cycleLeftLargePopver) {
        that.$refs.cycleLeftLargePopver.doClose()
      }
    },
    // 通知查询周期内容
    async getInformPeriod(id) {
      const that = this
      this.$apis.CYCLEINFOBYID(id).then((res) => {
        if (res.status === 200 && res.data != null) {
          const dataObject = res.data
          let states = false
          let largeArr = []
          let index_ = '-1'
          largeArr = JSON.parse(JSON.stringify(that.datas))
          for (let i = 0; i < largeArr.length; i++) {
            if (largeArr[i].id === dataObject.id) {
              largeArr[i].defSelected = 1
              that.largaeObject = largeArr[i]
              index_ = i // 小周期
              that.$emit(
                'backToNow',
                that.largaeObject,
                that.nowCycle,
                that.nowSelectCycle
              )
              this.$store.commit('common/setOkrCycleList', {
                largaeCycle: that.largaeObject,
                smallCycle: that.smallDatas,
                allCycleList: largeArr
              })
            } else {
              largeArr[i].defSelected = 0
            }
          }
          if (index_ !== '-1') {
            for (let z = 0; z < largeArr[index_].cycleInfoList.length; z++) {
              if (id === largeArr[index_].cycleInfoList[z].id) {
                largeArr[index_].cycleInfoList[z].defSelected = 1
                states = true
                that.largaeObject = largeArr[index_]
                that.$emit(
                  'backToNow',
                  that.largaeObject,
                  that.nowCycle,
                  that.nowSelectCycle
                )
                this.$store.commit('common/setOkrCycleList', {
                  largaeCycle: that.largaeObject,
                  smallCycle: that.smallDatas,
                  allCycleList: largeArr
                })
              } else {
                largeArr[index_].cycleInfoList[z].defSelected = 0
              }
            }
          }
          if (states === true) {
            that.datas = [...largeArr]
            that.handleRedpoint()
            that.jsonDataFunction('large', 'large')
          } else {
            that.$message({
              msg: '该周期未找到',
              type: 'warn'
            })
          }
        } else {
          that.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 返回当前周期
    backToNow() {
      const cycleData = this.nowCycle[this.largaeObject.id]
      this.clearRedpoint(cycleData)
      this.leftRightSelect(cycleData)
    },
    cycleSet() {
      this.cycleSetVisible = true
    }
  }
}
</script>
<style lang="scss" scoped>
.cycle-box {
  display: flex;
  align-items: center;
  .common-red-point {
    position: absolute;
    box-sizing: border-box;
    width: 10px;
    height: 10px;
    background: #ed2e7e;
    border: 2px solid #fcfcfc;
    border-radius: 50%;
    right: -4px;
    top: 0px;
  }
  .set-btn {
    cursor: pointer;
    width: 24px;
    height: 24px;
    font-size: 24px;
    margin: 0 8px;
    color: #a0a3bd;
    .back-icon {
      width: 20px;
      height: 20px;
    }
    &:hover {
      color: #3a78f6;
    }
  }
  .back-btn {
    width: 48px;
    height: 48px;
    padding: 14px;
    margin: 0px;
    background: #fff;
    border-radius: 100% 100%;
  }
  .arrow-icon {
    position: relative;
    outline: none;
    .common-red-point {
      right: -15px;
      top: -6px;
    }
  }
  .left-icon {
    .common-red-point {
      right: -3px;
      top: -2px;
    }
  }
  .right-icon {
    .common-red-point {
      left: -3px;
      top: -2px;
    }
  }
  .flex-new {
    display: flex;
    align-items: center;
  }
  p {
    margin: 0;
  }
  .cycle-box-right {
    min-width: 90px;
    height: 45px;
    margin-left: 12px;
    background: rgba(255, 255, 255, 1);
    border-radius: 26px;
    position: relative;
    font-size: 12px;
    color: rgba(48, 104, 255, 1);
    padding: 0 10px;
    display: flex;
    align-items: center;
    .cycle-arrow {
      cursor: pointer;
      .cycle-box-lf {
        font-size: 20px;
        color: #a0a3bd;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
      }
      .cycle-box-lf-active {
        color: #005eff;
        background: #eef6fd;
      }
    }
    .cycle-arrow-icon {
      font-size: 20px;
      color: #6e7491;
      cursor: pointer;
    }
    .cycle-box-left {
      display: flex;
      align-self: center;
      align-items: center;
      padding: 4px 12px;
      background: #f5f6fa;
      border-radius: 16px;
      font-size: 14px;
      margin-right: 8px;
      .cycle-box-left-title {
        align-items: center;
        align-self: center;
        cursor: pointer;
        color: #6e7491;
        font-size: 14px;
      }
      .cycle-box-left-title-weight {
        .cycle-box-left-title {
          color: #14142b;
        }
        .cycle-arrow-icon {
          color: #14142b;
        }
      }
    }
    .cen-box {
      line-height: 30px;
      text-align: center;
      padding: 0;
      border-radius: 4px;
      align-self: center;
      align-items: center;
      color: #6e7491;
      font-size: 14px;
      margin: 0 10px;
      position: relative;
      &.cycle-cen-box {
        color: #3a78f6;
      }
    }
    .cen-box:hover {
      color: #0035b7;
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss">
.popover-cycle {
  margin-top: 20px !important;
  padding: 8px 0px 8px 0px;
  border-radius: 16px;
  p {
    margin: 0;
  }
  ul.popover-ul {
    list-style: none;
    padding-left: 0 !important;
    margin: 0;
    // max-height: 120px;
    overflow-y: auto;
    li {
      color: #333;
      font-size: 14px;
      line-height: 28px;
      cursor: pointer;
      border-radius: 4px;
      padding: 6px 10px;
      p {
        font-size: 14px;
        line-height: 26px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:before {
          content: '';
          display: block;
        }
      }
      &.header-exit {
        border: 0;
        line-height: 36px;
      }
      &.header-exit:hover {
        background: rgba(108, 169, 255, 1);
        color: #fff;
      }
      &.header-change-select {
        margin-left: 5px;
        background: #f7f7fc;
        color: #005eff;
        p {
          span.cycle-span {
            background: #005eff !important;
            color: #fff !important;
            margin-right: 4px;
          }
        }
      }
      &.header-change-large:hover {
        background: #f7f7fc;
        color: #005eff;
        p {
          span.cycle-span {
            // background: #005EFF!important;
            // color:#fff!important;
          }
        }
      }
      &.header-change:hover {
        background: #005eff;
        p.cycle-header-btn {
          color: #fff !important;
        }
      }
      &.header-change-btn {
        margin-top: 6px;
      }
      &.header-change {
        position: relative;
        text-align: center;
        p.cycle-header-btn {
          color: #005eff;
        }
      }
      &.header-change-boxs {
        position: relative;
        p {
          span.cycle-span {
            // padding: 5px 12px;
            width: 54px;
            text-align: center;
            background: #f5f6fa;
            display: inline-block;
            color: #6e7491;
            border-radius: 14px;
          }
        }
        .common-red-point {
          right: 3px;
        }
      }
      ul.header-ul-inner {
        li {
          border: 0;
          line-height: 36px;
          padding: 0 24px;
          cursor: pointer;
        }
        li:hover {
          background: rgba(108, 169, 255, 1);
          color: #fff;
        }
      }
      &.header-change:hover {
        p {
          color: #fff;
        }
      }
    }
    .header-ul-center {
      cursor: none;
      padding: 0;
    }
  }
  .popover-ul-auto {
    max-height: 120px;
    overflow-y: auto;
    // @include scroll-beautify;
  }
  .popover-ul-min {
    max-height: 110px;
    padding: 0px 4px;
  }
  &.popover-cycle-left {
    ul.popover-ul {
      li {
        &.header-change:hover {
          background: #f7f7fc;
          p {
            color: #1b1b4e;
          }
        }
      }
    }
  }
  &.popover-cycle-right {
    ul.popover-ul {
      li {
        &.header-change:hover {
          background: #f7f7fc;
          p {
            color: #1b1b4e;
          }
        }
      }
    }
  }
  .common-red-point {
    position: absolute;
    box-sizing: border-box;
    width: 10px;
    height: 10px;
    background: #ed2e7e;
    border: 2px solid #fcfcfc;
    border-radius: 50%;
    right: 12px;
    top: 13px;
  }
}
</style>
