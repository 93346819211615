import Vue from 'vue'

export default () => {
  Vue.directive('preventReClick', {
    inserted(el, binding) {
      el.addEventListener('click', () => {
        if (!el.disabled) {
          el.disabled = true
          setTimeout(() => {
            el.disabled = false
          }, binding.value || el.value || 3000)
        }
      })
    }
  })
}
