var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-popover",
        {
          ref: "moreMenuPopRef",
          attrs: {
            placement: _vm.placement,
            trigger: "click",
            "popper-class": "member-menu-popover",
            "visible-arrow": false,
          },
          on: { show: _vm.show, hide: _vm.hide },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "member-card-pop",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            _vm._l(_vm.items, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  class: item.text
                    ? "menu-item menu-item-special"
                    : "menu-item",
                  style: { color: item.color ? item.color : "#14142b" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.menuClick(item.func)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "menu-item-text-div" }, [
                    _c(
                      "div",
                      { staticClass: "menu-item-text" },
                      [
                        (item.icon || "") !== ""
                          ? _c("Icon", {
                              staticClass: "menu-icon",
                              attrs: { "icon-class": item.icon },
                            })
                          : _vm._e(),
                        _vm._v(" " + _vm._s(item.title) + " "),
                      ],
                      1
                    ),
                    item.text
                      ? _c("p", { staticClass: "menu-text" }, [
                          _vm._v(_vm._s(item.text)),
                        ])
                      : _vm._e(),
                  ]),
                ]
              )
            }),
            0
          ),
          _vm._t("default", null, { slot: "reference" }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }