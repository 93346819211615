export default [{
  path: '/workbench',
  name: 'workbench',
  component: () => {
    return import(/* webpackChunkName: "workbench" */ '../../views/workbench')
  }
},
{
  path: '/setWorkbench',
  name: 'setWorkbench',
  component: () => {
    return import(/* webpackChunkName: "setWorkbench" */ '../../views/workbench/setWorkbench.vue')
  }
},
{
  path: '/changeRole',
  name: 'changeRole',
  component: () => {
    return import(/* webpackChunkName: "changeRole" */ '../../views/workbench/changeRole.vue')
  }
},
{
  path: '/addWorkModel',
  name: 'addWorkModel',
  component: () => {
    return import(/* webpackChunkName: "addWorkModel" */ '../../views/workbench/addWorkModel.vue')
  }
}]
