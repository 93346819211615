<template>
  <span class="facade-txt" :class="{ disabled: disabled }">
    <span>
      <el-tooltip
        :open-delay="300"
        :content="disabled ? '没有权限编辑' : '点击编辑任务开始时间'"
      >
        <span
          :class="
            obj.startDateString && !isHoverStartIcon
              ? 'time-text'
              : obj.startDateString && isHoverStartIcon
              ? `time-text time-text-border`
              : `time-text time-text-noborder`
          "
          @click="startPicker"
        >
          <span v-if="obj.startDateString" class="time">
            {{ obj.startDateString }}
          </span>
          <span v-else class="no-time">开始时间</span>
          <Icon
            v-if="(obj.startDateString || '') !== ''"
            icon-class="icon-guanbianniu"
            class="close"
            @mouseenter.native="isHoverStartIcon = true"
            @mouseleave.native="isHoverStartIcon = false"
            @click.native.stop="timeClear('start')"
          />
        </span> </el-tooltip
      >-
      <el-tooltip
        :open-delay="300"
        :content="disabled ? '没有权限编辑' : '点击编辑任务截止时间'"
      >
        <span
          :class="
            obj.endDateString && !isHoverEndIcon
              ? 'time-text'
              : obj.endDateString && isHoverEndIcon
              ? 'time-text time-text-border'
              : 'time-text time-text-noborder'
          "
          @click="endPicker"
        >
          <span v-if="obj.endDateString" class="time">
            {{ obj.endDateString }}
          </span>
          <span v-else class="no-time">截止时间</span>
          <Icon
            v-if="(obj.endDateString || '') !== ''"
            icon-class="icon-guanbianniu"
            class="close"
            @mouseenter.native="isHoverEndIcon = true"
            @mouseleave.native="isHoverEndIcon = fale"
            @click.native.stop="timeClear('end')"
          />
        </span>
      </el-tooltip>
    </span>
    <el-date-picker
      ref="startDate"
      v-model="form.startDate"
      class="hide-date-border"
      :disabled="disabled"
      :picker-options="pickerOptionsStart"
      type="datetime"
      placeholder="开始日期"
      format="yyyy-MM-dd HH:mm"
      value-format="yyyy-MM-dd HH:mm"
      :clearable="false"
      :default-time="defaultStart"
      @change="changeDate('start')"
    />
    <el-date-picker
      ref="endDate"
      v-model="form.endDate"
      class="hide-date-border end-date"
      type="datetime"
      :disabled="disabled"
      :picker-options="pickerOptionsEnd"
      placeholder="截止日期"
      format="yyyy-MM-dd HH:mm"
      value-format="yyyy-MM-dd HH:mm"
      :clearable="false"
      :default-time="defaultEnd"
      @change="changeDate('end')"
    />
  </span>
</template>

<script>
import formMixin from '@/utils/mixins/form'

export default {
  mixins: [formMixin],
  props: {
    fieldItem: {
      type: Object
    },
    // 当前对象
    obj: {
      type: Object,
      required: true
    },
    // 是否可以选择时间
    disabled: {
      required: true,
      type: Boolean
    }
  },
  data() {
    const _this = this
    return {
      endDateType: 0,
      pickerOptionsStart: {
        // 开始日期范围限制
        disabledDate(time) {
          if (_this.endDateType !== 1) {
            let disabled
            if (_this.form.endDate) {
              // disabled =
              //   time.getTime() >
              //   _this.$moment(_this.form.endDate).startOf('day').format('x')
              disabled = new Date(time) > new Date(_this.form.endDate)
            }
            return disabled
          } else {
            let disabled
            return disabled
          }
        }
      },
      pickerOptionsEnd: {
        // 结束日期范围限制
        disabledDate(time) {
          console.log(
            '结束时间',
            _this.endDateType !== 1,
            _this.form.startDate,
            _this.form.endDate
          )
          if (_this.endDateType !== 1) {
            let disabled
            if (_this.form.startDate) {
              disabled =
                time.getTime() <
                _this.$moment(_this.form.startDate).startOf('day').format('x')
              // disabled = new Date(time) < new Date(_this.form.startDate)
            } else {
              disabled = false
            }
            return disabled
          } else {
            let disabled
            return disabled
          }
        }
      },
      form: {
        startDate: '', // 开始日期
        endDate: '' // 结束日期
      },
      defaultStart: '',
      defaultEnd: '',
      isHoverStartIcon: false,
      isHoverEndIcon: false
    }
  },
  created() {
    // console.log(this.obj,'obj')
    if (this.companyCalendarInfo.amStartTime) {
      this.defaultStart =
        this.companyCalendarInfo.amStartTime.substring(11, 19) || ''
    }
    if (this.companyCalendarInfo.pmEndTime) {
      this.defaultEnd =
        this.companyCalendarInfo.pmEndTime.substring(11, 19) || ''
    }
    console.log(this.defaultStart, this.defaultEnd)
    if (this.obj) {
      this.form = {
        startDate: this.obj.startDate,
        endDate: this.obj.endDate
      }
    }
    // if ((this.form.startDate || '') === '') {
    //   this.form.startDate = this.$utils.getDateStr(0) + ' 09:00:00'
    // }
    // if ((this.form.endDate || '') === '') {
    //   this.endDateType = 1
    //   this.form.endDate = this.$utils.getDateStr(0) + ' 18:00:00'
    // } else {
    //   this.endDateType = 0
    // }
  },
  methods: {
    // 出现日期面板（开始日期选择）
    startPicker() {
      this.$refs.startDate.$el.focus()
      const el = this.$refs.startDate.$el
      el.children[0].focus()
    },
    // 出现日期面板（结束日期选择）
    endPicker() {
      this.$refs.endDate.$el.focus()
      const el = this.$refs.endDate.$el
      el.children[0].focus()
    },
    // 更改日期
    changeDate(type) {
      console.log(this.obj)
      const startTime = new Date(
        (this.form.startDate || '').replace(/\-/g, '/') || ''
      )
      const endTime = new Date(
        (this.form.endDate || '').replace(/\-/g, '/') || ''
      )
      const startDate_obj = new Date(
        (this.obj.startDate || '').replace(/\-/g, '/') || ''
      )
      const endTime_obj = new Date(
        (this.obj.endDate || '').replace(/\-/g, '/') || ''
      )
      if (type === 'start' && Number(startTime) > Number(endTime_obj)) {
        const str = '开始时间不能大于截止时间'
        this.$message({ msg: str, type: 'warn' })
        this.form.startDate = this.obj.startDate || ''
      } else if (type === 'end' && Number(startDate_obj) > Number(endTime)) {
        const str = '截止时间不能小于开始时间'
        this.$message({ msg: str, type: 'warn' })
        this.form.endDate = this.obj.endDate || ''
      } else {
        this.form.type = type
        this.$emit('change', this.form)
      }
    },
    // 时间清除
    timeClear(type) {
      this.form.type = type
      if (type === 'start') {
        this.form.startDate = null
      } else {
        this.form.endDate = null
      }
      const form = JSON.parse(JSON.stringify(this.form))
      form.isDelete = true
      this.$emit('change', form, this.fieldItem)
    }
  }
}
</script>

<style lang="scss" scoped>
.facade-txt {
  cursor: pointer;
  display: inline-block;
  position: relative;
  min-width: 100px;
  .time-text {
    display: inline-block;
    padding: 2px 6px;
    position: relative;
    border: 1px solid transparent;
    border-radius: 8px;
    .time {
      font-size: 14px;
      font-weight: 400;
      color: #444558;
    }
    .no-time {
      font-size: 14px;
      font-weight: 400;
      color: #444558;
    }
    .close {
      font-size: 14px;
      display: none;
      position: absolute;
      right: -6px;
      top: -10px;
      color: #a0a3bd;
    }
    &:hover {
      background: #f5f6fa;
      border-radius: 8px;
      .close {
        display: block;
      }
    }
  }
  .time-text-noborder {
    background: none;
    &:hover {
      border: 1px solid #fff;
      color: #3a78f6;
      background: none !important;
      .close {
        display: block;
      }
      .time {
        color: #3a78f6;
      }
      .no-time {
        color: #3a78f6;
      }
    }
    &:active {
      border: 1px solid #fff4;
      color: #3a78f6;
      background: none !important;
      .close {
        display: block;
      }
      .time {
        color: #3a78f6;
      }
      .no-time {
        color: #3a78f6;
      }
    }
  }
  .time-text-border {
    border: 1px #f5f6fa solid !important;
    background: #f5f6fa !important;
    border-radius: 8px !important;
    .close {
      color: #ff9900;
    }
  }
  &.disabled {
    .time-text {
      cursor: not-allowed;
      &:hover {
        color: #999;
      }
    }
  }
}
.hide-date-border {
  left: -20px;
  opacity: 0;
  position: absolute;
  top: -20px;
  width: 20px;
  z-index: -10;
}
.end-date {
  left: auto;
  right: 50px;
}
</style>
