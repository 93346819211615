import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import funcs from './api/funcs_modules'
import 'normalize.css/normalize.css' // 统一基础样式
import './assets/styles/transition.less' // 过度动画
import './assets/styles/self-elementui.less'
import directives from '@/directives'
import useComponents from './components'
import './assets/iconfont/iconfont.css'
import './assets/editor_iconfont/iconfont.css'
import * as lodash from 'lodash'
import * as timehandle from 'moment'
import globalMixin from './utils/mixins'
import infoMsg from './utils/info-center'
import db from '@/utils/info-center/database' // indexdb
// import VueDragscroll from 'vue-dragscroll'
// Vue.use(VueDragscroll)
// 打开indexdb
db.open()
// 消息中心333
Vue.prototype.$msg = infoMsg

// 全局mixins
Vue.mixin(globalMixin)
// 注册指令
directives.forEach((item) => {
  Vue.use(item)
})
// 使用公共组件库
useComponents()
// 公用工具库
import utils from './utils'
// 所有的工具内容从这里输出
Vue.prototype.$utils = utils
// 数据请求，统一操作
Vue.prototype.$apis = funcs
// moment引入 用于时间处理
Vue.prototype.$moment = timehandle
// lodash 常用函数
Vue.prototype.$lodash = lodash
// 数据埋点
import './utils/mixpanel'
// 全局二次确认弹窗 element-ui独立修改
import './utils/element'
// import ElementUI from 'element-ui'
// Vue.use(ElementUI)
// 周期日期插件
import VueDatepickerLocal from 'vue-datepicker-local'
Vue.use(VueDatepickerLocal)
// 复制链接插件
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
// 瀑布流
import waterfall from 'vue-waterfall2'
Vue.use(waterfall)
import vuetify from './assets/js/vuetify.js' // path to vuetify export
// 全局事件发布订阅
import EventProxy from 'vue-event-proxy'
Vue.use(EventProxy)
import ResizeObserver from 'resize-observer-polyfill'
if (window.ResizeObserver === undefined) {
  window.ResizeObserver = ResizeObserver;
}
window.Vue = Vue // 为了新版地图
// 控制台警告隐藏
Vue.config.silent = true
import Message from '@/components/global/Message' // 全局信息提示
Vue.prototype.$message = Message
Vue.prototype.$isQw =
  window.location.host.includes('qywx') || window.location.host.includes('qw')
Vue.prototype.$isDD =
  window.location.host.includes('dd') ||
  window.location.host.includes('dingtalkcloud') ||
  window.location.href.includes('dingding')
Vue.config.productionTip = false
import './assets/js/vxe-table.js' // vxe-table 按需引入
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
