var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      ref: "popoverSetting",
      attrs: {
        placement: "bottom",
        trigger: "click",
        "popper-class": `depart-select ${_vm.className}`,
        disabled: _vm.disabled,
      },
      on: {
        show: function ($event) {
          return _vm.popoverShow(true)
        },
        hide: function ($event) {
          return _vm.popoverShow(false)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "select-content" },
        [
          _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
            _vm.treeData.length > 0
              ? _c(
                  "div",
                  { staticClass: "select-tree" },
                  [
                    _c("el-tree", {
                      key: _vm.random,
                      ref: "departTreeRef",
                      staticClass: "set-tree",
                      attrs: {
                        data: _vm.treeData,
                        "highlight-current": "",
                        "show-checkbox": "",
                        "node-key": "id",
                        "default-expanded-keys": _vm.expandedKeys,
                        "default-checked-keys": _vm.checkedKeys,
                        "check-on-click-node": true,
                      },
                      on: { "check-change": _vm.handleCheckChange },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ node }) {
                              return _c(
                                "div",
                                { staticClass: "custom-tree-node" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "tree-label" },
                                    [
                                      _c("wx-open-data", {
                                        attrs: {
                                          type: 2,
                                          id: node.data.label,
                                          nameData: { name: node.data.label },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            },
                          },
                        ],
                        null,
                        false,
                        277754758
                      ),
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          !_vm.loading && _vm.treeData.length === 0 ? _c("no-data") : _vm._e(),
          _c("div", { staticClass: "btn-list" }, [
            _c(
              "button",
              {
                directives: [
                  { name: "preventReClick", rawName: "v-preventReClick" },
                ],
                staticClass: "edit-btn cancel",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.cancel(1)
                  },
                },
              },
              [_vm._v("取消")]
            ),
            _c(
              "button",
              {
                directives: [
                  { name: "preventReClick", rawName: "v-preventReClick" },
                ],
                staticClass: "edit-btn confirm",
                attrs: { type: "button" },
                on: { click: _vm.confirm },
              },
              [_vm._v("确定")]
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          class: `select-btn select-btn-${_vm.disabled}`,
          attrs: { slot: "reference" },
          slot: "reference",
        },
        [
          _vm.slotType === 0
            ? [
                _c("span", [_vm._v("部门:")]),
                _vm.checkedKeys.length === 0
                  ? [
                      _vm.type === 0
                        ? [_vm._v("全部")]
                        : [
                            _vm.userInfo.departName
                              ? [
                                  _c("wx-open-data", {
                                    attrs: {
                                      type: 2,
                                      id: _vm.userInfo.departName,
                                      nameData: {
                                        name: _vm.userInfo.departName,
                                      },
                                    },
                                  }),
                                ]
                              : [_vm._v("未设置")],
                          ],
                    ]
                  : [_vm._v(_vm._s(`已选${_vm.checkedKeys.length}个部门`))],
                _c("i", {
                  staticClass:
                    "el-select__caret el-input__icon el-icon-arrow-up",
                }),
              ]
            : [_vm._t("slot")],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }