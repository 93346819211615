var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "header_module" },
    [
      _c("div", { staticClass: "top-box flex-a-c-center" }, [
        _c("div", { staticClass: "flex-a-c-center flex-1" }, [
          _c(
            "div",
            { staticClass: "logo-img-box" },
            [
              _c("Img", {
                staticClass: "logo-img",
                attrs: { src: "/common/ic_logo.png" },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "flex-a-c-center_icon_workbench flex-new",
              class:
                _vm.topMenuActive === "workbench"
                  ? "flex-a-c-center_workbench_select"
                  : "",
              on: {
                click: function ($event) {
                  return _vm.topMenuTab("workbench")
                },
              },
            },
            [
              _c("Icon", {
                staticClass: "home-icon",
                attrs: { "icon-class": "icon-gongzuotai" },
              }),
              _c("p", { staticClass: "home-icon-title" }, [_vm._v("工作台")]),
            ],
            1
          ),
          _c("div", { staticClass: "line_coum" }),
          _c("div", { staticClass: "nav-list-div" }, [
            _c(
              "div",
              {
                staticClass: "flex-a-c-center_icon2",
                class:
                  _vm.topMenuActive === "okr"
                    ? "flex-a-c-center_icon2_style"
                    : "",
                on: {
                  click: function ($event) {
                    return _vm.topMenuTab("okr")
                  },
                },
              },
              [
                _c(
                  "el-badge",
                  {
                    staticStyle: { display: "block" },
                    attrs: { hidden: !_vm.okrRedPoint, "is-dot": "" },
                  },
                  [_c("p", { staticClass: "home-icon-title" }, [_vm._v("OKR")])]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "flex-a-c-center_icon2",
                class:
                  _vm.topMenuActive === "todo"
                    ? "flex-a-c-center_icon2_style"
                    : "",
                on: {
                  click: function ($event) {
                    return _vm.topMenuTab("todo")
                  },
                },
              },
              [
                _c(
                  "el-badge",
                  {
                    staticStyle: { display: "block" },
                    attrs: { hidden: !_vm.todoRedPoint, "is-dot": "" },
                  },
                  [
                    _c("p", { staticClass: "home-icon-title" }, [
                      _vm._v("Todo"),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "flex-a-c-center_icon2",
                class:
                  _vm.topMenuActive === "pm"
                    ? "flex-a-c-center_icon2_style"
                    : "",
                on: {
                  click: function ($event) {
                    return _vm.topMenuTab("pm")
                  },
                },
              },
              [_c("p", { staticClass: "home-icon-title" }, [_vm._v("项目")])]
            ),
            _c(
              "div",
              {
                staticClass: "flex-a-c-center_icon2",
                class:
                  _vm.topMenuActive === "analyse" ||
                  _vm.topMenuActive === "companyAnalyse" ||
                  _vm.topMenuActive === "departAnalyse"
                    ? "flex-a-c-center_icon2_style"
                    : "",
                on: {
                  click: function ($event) {
                    return _vm.topMenuTab("analyse")
                  },
                },
              },
              [_c("p", { staticClass: "home-icon-title" }, [_vm._v("统计")])]
            ),
            _c(
              "div",
              {
                staticClass: "flex-a-c-center_icon2",
                class:
                  _vm.topMenuActive === "conclusion"
                    ? "flex-a-c-center_icon2_style"
                    : "",
                on: {
                  click: function ($event) {
                    return _vm.topMenuTab("conclusion")
                  },
                },
              },
              [
                _c(
                  "el-badge",
                  {
                    staticStyle: { display: "block" },
                    attrs: {
                      hidden: _vm.summaryCount === 0,
                      value: _vm.summaryCount,
                      max: 99,
                    },
                  },
                  [
                    _c("p", { staticClass: "home-icon-title" }, [
                      _vm._v("总结"),
                    ]),
                  ]
                ),
              ],
              1
            ),
            (_vm.companyInfo.createDate &&
              new Date(_vm.companyInfo.createDate.replace(/\-/g, "/")) <
                new Date("2023/08/18 00:00:01") &&
              new Date() < new Date("2023/12/01 23:59:59")) ||
            (_vm.companyInfo.createDate || "") === ""
              ? _c(
                  "div",
                  {
                    staticClass: "flex-a-c-center_icon2",
                    class:
                      _vm.topMenuActive === "kpi"
                        ? "flex-a-c-center_icon2_style"
                        : "",
                    on: {
                      click: function ($event) {
                        return _vm.topMenuTab("kpi")
                      },
                    },
                  },
                  [
                    _c("p", { staticClass: "home-icon-title" }, [
                      _vm._v("绩效"),
                    ]),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "flex-a-c-center_icon2 kpi-v2",
                class:
                  _vm.topMenuActive === "kpiV2"
                    ? "flex-a-c-center_icon2_style"
                    : "",
                on: {
                  click: function ($event) {
                    return _vm.topMenuTab("kpiV2")
                  },
                },
              },
              [
                _c("p", { staticClass: "home-icon-title" }, [
                  _vm._v("绩效评估"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "flex-a-c-center_icon2 flex-a-c-center_icon-meeting",
                class:
                  _vm.topMenuActive === "meeting"
                    ? "flex-a-c-center_icon2_style"
                    : "",
                on: {
                  click: function ($event) {
                    return _vm.topMenuTab("meeting")
                  },
                },
              },
              [_c("p", { staticClass: "home-icon-title" }, [_vm._v("咪厅")])]
            ),
            _vm.userInfo.companyId != "1339443199637032960"
              ? _c(
                  "div",
                  {
                    staticClass:
                      "flex-a-c-center_icon2 flex-a-c-center_icon-classroom",
                    class:
                      _vm.topMenuActive === "classroom"
                        ? "flex-a-c-center_icon2_style"
                        : "",
                    on: {
                      click: function ($event) {
                        return _vm.topMenuTab("classroom")
                      },
                    },
                  },
                  [
                    _c("p", { staticClass: "home-icon-title" }, [
                      _vm._v("训练营"),
                    ]),
                    _c("Icon", {
                      staticClass: "classroom-icon",
                      attrs: { "icon-class": "icon-HOT" },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.companyInfo.version == 0 || _vm.companyInfo.version == 3
              ? _c(
                  "div",
                  {
                    staticClass: "banner-img",
                    on: {
                      click: function ($event) {
                        return _vm.openDoubleEleven(true)
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/imgs/common/21Banner.png"),
                        alt: "",
                      },
                    }),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "right-box" },
          [
            _c(
              "el-tooltip",
              {
                attrs: {
                  content: "查找",
                  "open-delay": 300,
                  placement: "bottom",
                  effect: "light",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "msg-box rel msg-rg-10",
                    on: { click: _vm.headerSearchFunction },
                  },
                  [
                    _c("Icon", {
                      class:
                        _vm.searchBoolean == true ||
                        _vm.headerSearchIcon == true
                          ? "menu-select"
                          : "",
                      attrs: { "icon-class": "icon-Search" },
                    }),
                  ],
                  1
                ),
              ]
            ),
            !this.$isQw && !this.$isDD
              ? _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: "日程",
                      "open-delay": 300,
                      placement: "bottom",
                      effect: "light",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "contact-box msg-box rel",
                        on: { click: _vm.calendarFunction },
                      },
                      [
                        _c("Icon", {
                          class:
                            _vm.calendarBoolean ||
                            _vm.$route.path === "/calendar"
                              ? "menu-select"
                              : "",
                          attrs: { "icon-class": "icon-calendar" },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _c(
              "el-tooltip",
              {
                attrs: {
                  content: "通讯录",
                  "open-delay": 300,
                  placement: "bottom",
                  effect: "light",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "contact-box msg-box rel",
                    on: { click: _vm.contactsFunction },
                  },
                  [
                    _c("Icon", {
                      class:
                        _vm.contactsBoolean || _vm.$route.path === "/members"
                          ? "menu-select"
                          : "",
                      attrs: { "icon-class": "icon-contacts_menu" },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c("div", { staticClass: "split-line" }),
            _c(
              "el-tooltip",
              {
                attrs: {
                  content: "审批",
                  "open-delay": 300,
                  placement: "bottom",
                  effect: "light",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "msg-box rel",
                    on: { click: _vm.approveListShow },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("Icon", {
                          class:
                            _vm.$route.path === "/approveList"
                              ? "menu-select"
                              : "",
                          attrs: { "icon-class": "icon-shenpilishi-pc" },
                        }),
                      ],
                      1
                    ),
                    _vm.approvalRedCount > 0
                      ? _c(
                          "div",
                          {
                            staticClass: "right-box_radius_number",
                            class:
                              _vm.approvalRedCount > 99
                                ? "right-box_radius_number_max"
                                : "",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.approvalRedCount > 99
                                  ? "99+"
                                  : _vm.approvalRedCount
                              )
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "msg-box rel",
                class: { active: _vm.notifyUnreadCount },
                on: { click: _vm.getMessageNotificationShow },
              },
              [
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: "通知",
                      "open-delay": 300,
                      placement: "bottom",
                      effect: "light",
                    },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("Icon", {
                          class:
                            _vm.noticeBoolean == true ||
                            _vm.messageNotificationShow == true
                              ? "menu-select"
                              : "",
                          attrs: { "icon-class": "icon-xiaoxi" },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm.informNumber > 0
                  ? _c(
                      "div",
                      {
                        staticClass: "right-box_radius_number",
                        class:
                          _vm.informNumber > 99
                            ? "right-box_radius_number_max"
                            : "",
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.informNumber > 99 ? "99+" : _vm.informNumber
                          )
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
            !this.$isQw && !this.$isDD
              ? _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: "邀请",
                      "open-delay": 300,
                      placement: "bottom",
                      effect: "light",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "msg-box rel msg-rg-10 invite-wrap",
                        class: { active: _vm.notifyUnreadCount },
                        on: {
                          mouseenter: function ($event) {
                            _vm.invitationBoolean = true
                          },
                          mouseleave: function ($event) {
                            _vm.invitationBoolean = false
                          },
                        },
                      },
                      [
                        _c("Icon", {
                          staticClass: "icon-invitationselect",
                          class:
                            _vm.invitationBoolean || _vm.closeInviterCodeBoolean
                              ? "intive-menu-select"
                              : "",
                          attrs: { "icon-class": "icon-invitationselect" },
                          on: { click: _vm.invitationFunction },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _c("div", { staticClass: "msg-box" }, [_c("videoList")], 1),
            _vm.companyInfo.belonger == _vm.userInfo.id &&
            _vm.companyInfo.version == 0 &&
            _vm.companyInfo.expireDate &&
            _vm.expireDays > 0 &&
            _vm.isShowUpgrade &&
            _vm.commonFromType === "saas"
              ? _c(
                  "guide-popover",
                  {
                    attrs: {
                      title: "升级",
                      popKey: "upgrade",
                      innerText:
                        "企业试用还剩" +
                        _vm.expireDays +
                        "天到期，请及时处理以免到期后数据丢失",
                      placement: "top",
                    },
                  },
                  [
                    _c(
                      "span",
                      { attrs: { slot: "guidepop" }, slot: "guidepop" },
                      [
                        _c(
                          "el-tooltip",
                          {
                            directives: [
                              { name: "delTabIndex", rawName: "v-delTabIndex" },
                            ],
                            attrs: {
                              content: "到期升级",
                              "open-delay": "300",
                              placement: "bottom",
                              effect: "light",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "msg-box rel msg-rg-10" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "expiration-upgrade",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.upgrade()
                                      },
                                    },
                                  },
                                  [_vm._v("升级")]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _c(
              "el-tooltip",
              {
                directives: [{ name: "delTabIndex", rawName: "v-delTabIndex" }],
                attrs: {
                  content: "到期升级",
                  "open-delay": "300",
                  placement: "bottom",
                  effect: "light",
                },
              },
              [
                _vm.companyInfo.version == 4
                  ? _c("div", { staticClass: "msg-box rel msg-rg-10" }, [
                      _c(
                        "span",
                        {
                          staticClass: "expiration-upgrade",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.upgrade()
                            },
                          },
                        },
                        [_vm._v("升级")]
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
            !_vm.isPcClientValue && !this.$isQw && !this.$isDD
              ? _c(
                  "el-popover",
                  {
                    attrs: {
                      placement: "bottom-start",
                      width: "200",
                      trigger: "click",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "down-main" },
                      [
                        _c("img", {
                          staticClass: "down-icon",
                          attrs: {
                            src: require("../../../../assets/imgs/header/down_app.png"),
                            alt: "",
                          },
                        }),
                        _c("div", { staticClass: "download-row" }, [
                          _vm._v("体验更流畅"),
                        ]),
                        _c("div", { staticClass: "download-row" }, [
                          _vm._v("消息不遗漏"),
                        ]),
                        _c("div", { staticClass: "download-row" }, [
                          _vm._v("跨平台同步"),
                        ]),
                        _vm.validataOS() == "Mac"
                          ? _c(
                              "el-button",
                              {
                                staticClass: "download-btn",
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadMac()
                                  },
                                },
                              },
                              [_vm._v("下载Mac客户端")]
                            )
                          : _vm._e(),
                        _vm.validataOS() == "Windows"
                          ? _c(
                              "el-button",
                              {
                                staticClass: "download-btn",
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadWindows()
                                  },
                                },
                              },
                              [_vm._v("下载Windows客户端")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "down-btn",
                        attrs: { slot: "reference", type: "primary" },
                        slot: "reference",
                      },
                      [
                        _c("Icon", {
                          staticClass: "icon-kehuduanmorenzhuangtai",
                          attrs: {
                            "icon-class": "icon-kehuduanmorenzhuangtai",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "menu-wrap" },
              [
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: "账号设置",
                      "open-delay": 300,
                      placement: "bottom",
                      effect: "light",
                      "popper-class": "account-set-tooltip",
                    },
                  },
                  [
                    this.$isQw || this.$isDD
                      ? _c("person-menu-list")
                      : _c("person-menu-list-saas"),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm.targetDetailOpenState === "展开"
          ? _c(
              "div",
              [
                _vm.objectiveDetailVisible
                  ? _c("okr-detail", {
                      attrs: { "obj-id": _vm.objId.id },
                      model: {
                        value: _vm.objectiveDetailVisible,
                        callback: function ($$v) {
                          _vm.objectiveDetailVisible = $$v
                        },
                        expression: "objectiveDetailVisible",
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm.messageNotificationShow
        ? _c("notice-list", {
            on: { refreshNum: _vm.refreshNum },
            model: {
              value: _vm.messageNotificationShow,
              callback: function ($$v) {
                _vm.messageNotificationShow = $$v
              },
              expression: "messageNotificationShow",
            },
          })
        : _vm._e(),
      _vm.headerSearchIcon
        ? _c("header-search", {
            on: {
              closeHeaderSearch: _vm.closeHeaderSearch,
              taskDetailVisible: _vm.headerTaskDetailVisible,
              objectiveDetailVisible: _vm.headerObjectiveDetailVisible,
            },
          })
        : _vm._e(),
      _vm.closeInviterCodeBoolean
        ? _c("invite-by-code", {
            on: { closeInviterCode: _vm.closeInviterCode },
          })
        : _vm._e(),
      _vm.taskdetailVisible && parseInt(_vm.notifyId) > 0
        ? _c("task-detail", {
            attrs: {
              taskId: _vm.notifyId,
              visible: _vm.taskdetailVisible,
              "form-page": _vm.formPage,
              "view-type": _vm.formPage,
              noticeType: _vm.noticeType,
            },
            on: {
              "update:visible": function ($event) {
                _vm.taskdetailVisible = $event
              },
            },
            model: {
              value: _vm.taskdetailVisible,
              callback: function ($$v) {
                _vm.taskdetailVisible = $$v
              },
              expression: "taskdetailVisible",
            },
          })
        : _vm._e(),
      _vm.subdetailNoticeVisible
        ? _c("sub-task-detail", {
            attrs: {
              taskId: _vm.subdetailVisibleId,
              visible: _vm.subdetailNoticeVisible,
              "sub-object-father-id": _vm.subObjectFatherId,
              noticeType: _vm.noticeType,
            },
            on: {
              "update:visible": function ($event) {
                _vm.subdetailNoticeVisible = $event
              },
            },
            model: {
              value: _vm.subdetailNoticeVisible,
              callback: function ($$v) {
                _vm.subdetailNoticeVisible = $$v
              },
              expression: "subdetailNoticeVisible",
            },
          })
        : _vm._e(),
      _vm.homeDetailsState
        ? _c("okr-detail", {
            attrs: { "obj-id": _vm.detailsId },
            on: { refresh: _vm.getHomeDetailsClose },
            model: {
              value: _vm.homeDetailsState,
              callback: function ($$v) {
                _vm.homeDetailsState = $$v
              },
              expression: "homeDetailsState",
            },
          })
        : _vm._e(),
      _vm.targetAddShow
        ? _c("add-target", {
            attrs: { targetDetailsAlign: _vm.targetDetailsAlign },
            on: {
              gettargetAddClose: _vm.gettargetAddClose,
              getTargetAddSubmit: _vm.getTargetAddSubmit,
            },
            model: {
              value: _vm.targetAddShow,
              callback: function ($$v) {
                _vm.targetAddShow = $$v
              },
              expression: "targetAddShow",
            },
          })
        : _vm._e(),
      _vm.activityVisible
        ? _c("activity", {
            attrs: { visible: _vm.activityVisible },
            on: {
              "update:visible": function ($event) {
                _vm.activityVisible = $event
              },
            },
            model: {
              value: _vm.activityVisible,
              callback: function ($$v) {
                _vm.activityVisible = $$v
              },
              expression: "activityVisible",
            },
          })
        : _vm._e(),
      _vm.isShowPreview
        ? _c("FilePreview", {
            attrs: {
              fileObj: _vm.fileObj,
              isPreview: _vm.isPreview,
              isDownload: _vm.isDownload,
            },
            model: {
              value: _vm.isShowPreview,
              callback: function ($$v) {
                _vm.isShowPreview = $$v
              },
              expression: "isShowPreview",
            },
          })
        : _vm._e(),
      _vm.isShowInsertLink
        ? _c("InsertLink", {
            attrs: { insertLinkType: _vm.insertLinkType },
            model: {
              value: _vm.isShowInsertLink,
              callback: function ($$v) {
                _vm.isShowInsertLink = $$v
              },
              expression: "isShowInsertLink",
            },
          })
        : _vm._e(),
      _vm.customer_service_show
        ? _c("customer-service", {
            model: {
              value: _vm.customer_service_show,
              callback: function ($$v) {
                _vm.customer_service_show = $$v
              },
              expression: "customer_service_show",
            },
          })
        : _vm._e(),
      _vm.festivalVisible
        ? _c("festival", {
            on: {
              close: function ($event) {
                return _vm.festivalState(false)
              },
            },
            model: {
              value: _vm.festivalVisible,
              callback: function ($$v) {
                _vm.festivalVisible = $$v
              },
              expression: "festivalVisible",
            },
          })
        : _vm._e(),
      _vm.doubleElevenVisible
        ? _c("eleven", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.$store.state.common.apiRequestData.visible,
                expression: "!$store.state.common.apiRequestData.visible",
              },
            ],
            model: {
              value: _vm.doubleElevenVisible,
              callback: function ($$v) {
                _vm.doubleElevenVisible = $$v
              },
              expression: "doubleElevenVisible",
            },
          })
        : _vm._e(),
      _vm.approveDetailVisible
        ? _c("aproveDetail", {
            attrs: { detailsId: _vm.aproveDetailId },
            model: {
              value: _vm.approveDetailVisible,
              callback: function ($$v) {
                _vm.approveDetailVisible = $$v
              },
              expression: "approveDetailVisible",
            },
          })
        : _vm._e(),
      _vm.$store.state.task.taskApproveRemindObj.visible
        ? _c("taskApproveRemind", {
            model: {
              value: _vm.$store.state.task.taskApproveRemindObj.visible,
              callback: function ($$v) {
                _vm.$set(
                  _vm.$store.state.task.taskApproveRemindObj,
                  "visible",
                  $$v
                )
              },
              expression: "$store.state.task.taskApproveRemindObj.visible",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }