<template>
  <el-dialog
    title="按「对齐我的OKR」完成情况自动更新"
    :visible.sync="dialogVisible"
    width="880px"
    :append-to-body="true"
    :before-close="handleClose"
    :show-close="false"
    @click.stop
  >
    <div class="set-content" @click.stop v-vueloading="loading" v-if="okrList.length > 0">
      <div class="kr-infor-list">
        我的KR量化（初始值:
        <span>{{item.quantificationStart}}</span> 目标值:
        <span>{{item.quantificationEnd}}</span>
        单位:
        <span>{{item.quantificationUnit}}</span>
        ）
      </div>
      <div class="select-type">
        <div class="push-from">Ta完成</div>
        <div class="push-to">推动我完成</div>
        <div class="push-rate">自动更新进度</div>
      </div>
      <div
        class="okr-person-list"
        v-for="(okrListItem, okrListIndex) in okrList"
        :key="okrListIndex"
      >
        <div class="okr-person">
          <img :src="okrListItem[0].userAvatar" v-if="okrListItem[0].userAvatar" alt />
          <img :src="require('@/assets/imgs/common/avatar.png')" v-else />
          <p>
            <wx-open-data :id="okrListItem[0].userName" :nameData="{name:okrListItem[0].userName}" />
            {{okrListItem.length}}
          </p>
        </div>
        <div class="okr-list-wrap">
          <el-collapse accordion>
            <el-collapse-item v-for="(okrItem, okrIndex) in okrListItem" :key="okrItem.id">
              <template slot="title">
                <div class="title-left">
                  <el-checkbox
                    v-model="okrItem.checked"
                    :disabled="okrItem.disabled"
                    @change="okrCheckedChange(okrListIndex,okrIndex)"
                  />
                  <div class="okr-num">O{{okrIndex + 1}}</div>
                  <div class="content" v-html="$utils.changeToHtml(okrItem.content)"></div>
                </div>
                <div class="title-right" v-if="okrItem.checked" @click.stop>
                  <div class="push-from push">
                    <el-input
                      placeholder="0"
                      :min="0"
                      :max="100"
                      v-model="okrItem.pushFrom"
                      class="input-focus"
                      @change="getSelectList"
                    >
                      <template slot="append">%</template>
                    </el-input>
                  </div>
                  <div class="push-to push">
                    <el-input
                      placeholder="0"
                      v-model="okrItem.pushTo"
                      class="input-focus"
                      @change="getSelectList"
                    >
                      <template slot="append">{{item.quantificationUnit}}</template>
                    </el-input>
                  </div>
                  <div class="push-rate push">{{okrItem.pushRate}}%</div>
                </div>
              </template>
              <div class="key-list-wrap">
                <div
                  class="key-list"
                  v-for="(keyItem, keyIndex) in okrItem.keyresultsList"
                  :key="keyItem.id"
                >
                  <div class="key-list-left">
                    <el-checkbox
                      v-model="keyItem.checked"
                      :disabled="(keyItem.disabled && okrItem.checkState !==true) || keyItem.quantificationType === 1"
                      @change="keyCheckedChange(okrListIndex, okrIndex, keyIndex)"
                    />
                    <div class="key-num">KR{{keyIndex + 1}}</div>
                    <div class="content-list">
                      <div class="content" v-html="$utils.changeToHtml(keyItem.title)"></div>
                      <div class="infor" v-if="keyItem.quantificationType === 0">
                        初始值:
                        <span>{{keyItem.quantificationStart}}</span>目标值:
                        <span>{{keyItem.quantificationEnd}}</span>单位:
                        <span>{{keyItem.quantificationUnit}}</span>
                      </div>
                      <div class="infor" v-else>量化条件： 完成情况</div>
                    </div>
                  </div>
                  <div
                    class="key-list-right"
                    v-if="keyItem.checked && okrItem.checked !== true"
                    @click.stop
                  >
                    <div class="push-from push">
                      <el-input
                        placeholder="0"
                        v-model="keyItem.pushFrom"
                        :min="0"
                        class="input-focus"
                        @change="getSelectList"
                      >
                        <template slot="append">{{keyItem.quantificationUnit}}</template>
                      </el-input>
                    </div>
                    <div class="push-to push">
                      <el-input
                        placeholder="0"
                        v-model="keyItem.pushTo"
                        class="input-focus"
                        @change="getSelectList"
                      >
                        <template slot="append">{{item.quantificationUnit}}</template>
                      </el-input>
                    </div>
                    <div class="push-rate push">{{keyItem.pushRate}}%</div>
                  </div>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>
    <div class="set-content" v-if="okrList.length === 0 && !loading">
      <noData :type="2" />
    </div>
    <div class="bottom-step-buttons" v-if="okrList.length > 0" @click.stop>
      <div class="percentage-text">
        <span>{{$utils.floatToFixedDown(selectPercentage)}}%</span>的进度由
        <span>{{selectKeyLen}}</span> 个关键结果
        <span>{{selectOkrLen}}</span>
        个目标的完成情况自动更新
      </div>
      <div class="btn-list">
        <el-button class="left-button" @click="handleClose">取消</el-button>
        <el-button @click="submit('提交')">确认</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
// import permission from './permission.vue'
import noData from '@/views/okr/components/noData.vue'
export default {
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  components: { noData },
  props: {
    dialogShow: {
      type: Boolean,
      default: true
    },
    okrData: {
    },
    item: {
    },
    maxPercentage: { // 最大可设置值
      type: Number
    },
    list: {
    }
  },
  data() {
    return {
      dialogVisible: this.dialogShow,
      okrList: [],
      selectPercentage: 0,
      selectKeyLen: 0,
      selectOkrLen: 0,
      selectList: [],
      loading: true,
      submitState: true
    }
  },
  watch: {
    dialogShow: {
      handler(val) {
        this.dialogVisible = val
      }
    }
  },
  created() {
    this.getOkrList()
  },
  methods: {
    // 关闭启动状态
    handleClose() {
      this.dialogVisible = false
      this.$emit('change', false)
    },
    // 获取目标列表
    getOkrList() {
      this.okrList = []
      const beans = {
        objId: this.item.objId
      }
      this.$apis
        .OKRAUTOOKRLIST(this.okrData.cycleInfoId, beans)
        .then((rest) => {
          this.okrList = rest.data
          this.getSelectOkrList()
          this.loading = false
        })
    },
    // 初始化选择的目标
    getSelectOkrList(type) {
      if ((this.list || []).length > 0) {
        var selectPercentage = 0
        var selectKeyLen = 0
        var selectOkrLen = 0
        var okrList = this.okrList
        okrList.forEach((okrListItem) => {
          okrListItem.forEach((okrItem) => {
            this.list.forEach((selectItem) => {
              if (selectItem.businessType === 0 && selectItem.businessId === okrItem.id) { // 目标
                okrItem.checked = true
                okrItem.pushFrom = selectItem.pushFrom
                okrItem.pushTo = selectItem.pushTo + this.item.quantificationStart
                okrItem.pushRate = selectItem.pushRate
                okrItem.ruleId = selectItem.id
                okrItem.setCreateUserid = selectItem.createUserid || ''
                okrItem.setCreateDate = selectItem.createDate || ''
                okrItem.setIsDel = selectItem.isDel
                okrItem.setCompanyId = selectItem.companyId || ''
                okrItem.checkState = true
                okrItem.disabled = false
                ++selectOkrLen
                selectPercentage += selectItem.pushRate
                okrItem.keyresultsList.forEach((keyItem) => {
                  if (keyItem.quantificationType === 1) {
                    keyItem.disabled = true
                  }
                })
              } else {
                // 初始化默认数据
                okrItem.pushFrom = (okrItem.pushFrom || '') !== '' ? okrItem.pushFrom : 100.00
                okrItem.keyresultsList.forEach((keyItem) => {
                  if (selectItem.businessType === 1 && selectItem.businessId === keyItem.id) { // 目标
                    keyItem.checked = true
                    keyItem.pushFrom = selectItem.pushFrom
                    keyItem.pushTo = selectItem.pushTo + this.item.quantificationStart
                    keyItem.pushRate = selectItem.pushRate
                    keyItem.ruleId = selectItem.id
                    keyItem.setCreateUserid = selectItem.createUserid || ''
                    keyItem.setCreateDate = selectItem.createDate || ''
                    keyItem.setIsDel = selectItem.isDel
                    keyItem.setCompanyId = selectItem.companyId || ''
                    keyItem.checkState = true
                    ++selectKeyLen
                    selectPercentage += selectItem.pushRate
                    keyItem.disabled = false
                    okrItem.disabled = false
                  } else {
                    // 解决有选择值会重新赋值默认最大值问题
                    if ((keyItem.pushFrom || '') === '') {
                      keyItem.pushFrom = keyItem.quantificationEnd
                    }
                  }
                  if ((keyItem.isOpt === 1 && keyItem.checked !== true) || keyItem.quantificationType === 1) {
                    keyItem.disabled = true
                    if ((keyItem.isOpt === 1 && keyItem.checked !== true) && okrItem.checked !== true) {
                      okrItem.disabled = true
                    }
                  }
                })
              }
            })
          })
        })
        this.selectPercentage = selectPercentage
        this.selectKeyLen = selectKeyLen
        this.selectOkrLen = selectOkrLen
        this.$nextTick(() => {
          this.okrList = JSON.parse(JSON.stringify(okrList))
        })
        this.getSelectList()
      } else {
        this.okrList.forEach((okrListItem) => {
          okrListItem.forEach((okrItem) => {
            if (okrItem.isOpt === 1) {
              okrItem.disabled = true
            }
            okrItem.pushFrom = 100.00
            okrItem.keyresultsList.forEach((keyItem) => {
              if (keyItem.quantificationType === 1 || keyItem.isOpt === 1) {
                keyItem.disabled = true
              }
              keyItem.pushFrom = keyItem.quantificationEnd
            })
          })
        })
        this.selectPercentage = 0
        this.selectKeyLen = 0
        this.selectOkrLen = 0
        this.$nextTick(() => {
          this.okrList = JSON.parse(JSON.stringify(this.okrList))
        })
        this.getSelectList()
      }
    },
    okrCheckedChange(okrListIndex, index) {
      this.okrList[okrListIndex][index].keyresultsList.forEach((keyItem) => {
        keyItem.checked = this.okrList[index].checked
      })
      this.getSelectList()
    },
    keyCheckedChange(userIndex, okrListIndex, index) {
      // this.okrList[userIndex][okrListIndex].keyresultsList[index].checked = !this.okrList[userIndex][okrListIndex].keyresultsList[index].checked
      this.$nextTick(() => {
        this.okrList = JSON.parse(JSON.stringify(this.okrList))
      })
      this.getSelectList()
    },
    // 初始化选择的数据
    getSelectList (type) {
      var selectPercentage = 0
      var selectKeyLen = 0
      var selectOkrLen = 0
      var list = []
      var maxValue = 0
      this.submitState = true
      this.okrList.forEach((okrListItem) => {
        okrListItem.forEach((okrItem) => {
          if (okrItem.checked) {
            ++selectOkrLen
            okrItem.keyresultsList.forEach((keyItem) => {
              if (keyItem.disabled !== true) {
                keyItem.checked = true
              }
            })
            // okrItem.pushFrom = ((okrItem.pushFrom || '') + '').replace(/\D/g, '')
            // okrItem.pushTo = ((okrItem.pushTo || '') + '').replace(/\D/g, '')
            // const pushRate = (okrItem.pushTo || 0) === 0 ? 0 : (this.$utils.floatToFixedDown((((okrItem.pushTo - this.item.quantificationStart) / (this.item.quantificationEnd - this.item.quantificationStart)) * 100)) || 0)
            // okrItem.pushRate = pushRate
            okrItem.pushFrom = (Number(okrItem.pushFrom) || 0).toFixed(2)
            okrItem.pushTo = (Number(okrItem.pushTo) || 0).toFixed(2)
            const pushRate = (okrItem.pushTo || 0) === 0 ? 0 : ((((okrItem.pushTo - this.item.quantificationStart) / (this.item.quantificationEnd - this.item.quantificationStart)) * 100) || 0)
            okrItem.pushRate = this.$utils.floatToFixedDown(pushRate)
            selectPercentage += parseFloat(pushRate)
            // maxValue += parseInt((okrItem.pushTo - this.item.quantificationStart) || 0)
            maxValue += ((okrItem.pushTo - this.item.quantificationStart) || 0)
            // if (type === 1) { // 提交判断
            if ((okrItem.pushFrom || 0) > 100 || (okrItem.pushFrom || 0) <= 0) {
              this.submitState = false
              if (type === 1) {
                this.$message.warn(`目标Ta目标值不能小于等于0并且不能大于100哦！`)
              }
            }
            if ((okrItem.pushTo || 0) <= this.item.quantificationStart) {
              this.submitState = false
              if (type === 1) {
                this.$message.warn(`目标推动我目标值不能小于等于${this.item.quantificationStart}哦！`)
              }
            }
            // }
            list.push({
              automationId: this.item.id,
              automationRuleId: okrItem.automationRuleId || '',
              businessId: okrItem.id,
              businessType: 0, // 业务类型（0目标 ，1 KR ，2 任务（自定义）  3 项目*）
              // currentValue: this.item.quantificationActualValue,
              currentValue: 0,
              id: okrItem.ruleId || '',
              pushFrom: okrItem.pushFrom,
              pushTo: okrItem.pushTo - this.item.quantificationStart,
              pushRate: okrItem.pushRate,
              objectId: this.okrData.id,
              createUserid: okrItem.setCreateUserid || '',
              createDate: okrItem.setCreateDate || '',
              isDel: okrItem.setIsDel,
              companyId: okrItem.setCompanyId || ''
            })
          } else {
            okrItem.keyresultsList.forEach((keyItem) => {
              if (keyItem.checked) {
                // keyItem.pushFrom = ((keyItem.pushFrom || '') + '').replace(/\D/g, '')
                keyItem.pushFrom = (Number(keyItem.pushFrom) || 0).toFixed(2)
                if ((keyItem.pushFrom || 0) > keyItem.quantificationEnd || (keyItem.pushFrom || 0) <= keyItem.quantificationStart) {
                  if (type === 1) {
                    this.$message.warn(`Kr Ta目标值不能小于等于${keyItem.quantificationStart}并且不能大于最大目标值${keyItem.quantificationEnd}哦！`)
                  }
                  this.submitState = false
                }
                if ((keyItem.pushTo || 0) <= this.item.quantificationStart) {
                  if (type === 1) {
                    this.$message.warn(`目标推动我目标值不能小于等于${this.item.quantificationStart}哦！`)
                  }
                  this.submitState = false
                }
                // keyItem.pushTo = ((keyItem.pushTo || '') + '').replace(/\D/g, '')
                // const pushRate = (keyItem.pushTo || 0) === 0 ? 0 : (this.$utils.floatToFixedDown((((keyItem.pushTo - this.item.quantificationStart) / (this.item.quantificationEnd - this.item.quantificationStart)) * 100)) || 0)
                // keyItem.pushRate = pushRate
                keyItem.pushTo = (Number(keyItem.pushTo) || 0).toFixed(2)
                const pushRate = (keyItem.pushTo || 0) === 0 ? 0 : ((((keyItem.pushTo - this.item.quantificationStart) / (this.item.quantificationEnd - this.item.quantificationStart)) * 100) || 0)
                keyItem.pushRate = this.$utils.floatToFixedDown(pushRate)
                selectPercentage += parseFloat(pushRate)
                // maxValue += parseInt((keyItem.pushTo - this.item.quantificationStart) || 0)
                maxValue += ((keyItem.pushTo - this.item.quantificationStart) || 0)
                ++selectKeyLen
                list.push({
                  automationId: this.item.id,
                  automationRuleId: keyItem.automationRuleId || '',
                  businessId: keyItem.id,
                  businessType: 1, // 业务类型（0目标 ，1 KR ，2 任务（自定义）  3 项目*）
                  currentValue: this.item.quantificationActualValue,
                  id: keyItem.ruleId || '',
                  pushFrom: keyItem.pushFrom,
                  pushTo: keyItem.pushTo - this.item.quantificationStart,
                  pushRate: keyItem.pushRate,
                  objectId: this.okrData.id,
                  createUserid: keyItem.setCreateUserid || '',
                  createDate: keyItem.setCreateDate || '',
                  isDel: keyItem.setIsDel,
                  companyId: keyItem.setCompanyId || ''
                })
              }
            })
          }
        })
      })
      this.selectPercentage = selectPercentage
      this.selectKeyLen = selectKeyLen
      this.selectOkrLen = selectOkrLen
      this.selectList = list
      console.log('selectList', this.selectList)
      this.maxValue = maxValue
      this.$nextTick(() => {
        this.okrList = JSON.parse(JSON.stringify(this.okrList))
      })
      if (maxValue > this.maxPercentage) {
        this.submitState = false
        this.$message.warn(`推动目标值总和不能大于最大值${this.maxPercentage + this.item.quantificationStart}哦！`)
      }
    },
    submit() {
      if (this.submitState) { // 是否可以提交
      console.log('this.selectList', this.selectList)
        this.$emit('refresh', {
          selectList: this.selectList,
          maxValue: this.maxValue,
          keyLen: this.selectKeyLen,
          okrLen: this.selectOkrLen
        })
        this.handleClose()
      } else {
        this.getSelectList(1)
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../../../assets/styles/dialog.less';
::v-deep .el-dialog__body {
  min-height: 235px;
}
.set-content {
  margin-left: 16px;

  .okr-list-wrap {
    min-height: 100px;
    max-height: calc(85vh - 260px);
    overflow-y: auto;
    .okr-num,
    .key-num {
      width: 34px;
      height: 18px;
      line-height: 18px;
      background: rgba(58, 120, 246, 0.1);
      border-radius: 15px;
      text-align: center;
      margin: 3px 8px;
      color: #3a78f6;
    }
    .key-num {
      margin: 3px 8px;
    }
    ::v-deep .el-collapse-item__content {
      padding-bottom: 0px;
    }
    ::v-deep .el-collapse-item__wrap {
      border-bottom: none;
    }
    .okr-num {
      color: #fff;
      background: #3a78f6;
      margin: 0px 8px 0px 36px;
    }
    .content {
      color: #14142b;
      .text-n(1);
      flex: 1;
      min-width: 0;
    }
    ::v-deep .el-collapse {
      border-top: none;
    }
    ::v-deep .el-collapse-item__header {
      position: relative;
      border-bottom: none;
      .flex-bt();
      .el-collapse-item__arrow {
        position: absolute;
        left: 28px;
        width: 20px;
        height: 20px;
        background: url('../../../../../../assets/imgs/pm/tableArrowRight.png')
          center center no-repeat;
        background-size: 100% auto;
        &::before {
          content: '';
        }
      }
      .el-collapse-item__arrow.is-active {
        background: url('../../../../../../assets/imgs/pm/tableArrowRightHover.png')
          center center no-repeat;
        background-size: 100% auto;
      }
      .title-left {
        .flex-l();
      }
      .title-right {
        .flex-bt();
        justify-content: flex-end;
      }
    }
    .key-list-wrap {
      margin: 0px 0px 0px 28px;
      .key-list {
        .flex-bt();
        margin-bottom: 12px;
        .key-list-left {
          .flex-l();
          align-items: flex-start;
          .content-list {
            .infor {
              font-size: 12px;
              color: #a0a3bd;
              span {
                color: #444558;
                margin: 0px 8px;
              }
            }
          }
        }
        .key-list-right {
          .flex-bt();
          justify-content: flex-end;
        }
      }
    }
    .el-input {
      width: 100px;
      ::v-deep .el-input__inner {
        height: 28px;
        color: #14142b;
        font-weight: 500;
        padding: 0px;
        text-align: center;
        border-radius: 8px;
        line-height: 28px;
      }
      ::v-deep .el-input-group__append {
        padding: 0px 7px;
        background: none;
        border-color: rgba(0, 0, 0, 0);
      }
    }
    .push {
      min-width: 120px;
      margin: 0px auto;
      text-align: center;
      color: #14142b;
    }
  }
  .okr-person {
    .flex-l();
    margin-bottom: 8px;
    img {
      width: 28px;
      height: 28px;
      border-radius: 100%;
      margin-right: 12px;
    }
    p {
      flex: 1;
      .text-n(1);
      font-size: 14px;
      font-weight: 500;
      color: #14142b;
    }
  }
  .kr-infor-list {
    padding: 16px 0px;
    border-bottom: 1px solid #f5f6fa;
    color: #444558;
    font-size: 14px;
    span {
      color: #3a78f6;
    }
  }
  .select-type {
    .flex-bt();
    justify-content: flex-end;
    font-weight: 500;
    color: #14142b;
    font-size: 14px;
    padding: 16px 0px 8px 0px;
    div {
      min-width: 120px;
      text-align: center;
    }
  }
}
.bottom-step-buttons {
  .flex-bt();
  margin-left: 16px;
  .percentage-text {
    line-height: 40px;
    font-size: 12px;
    font-weight: 400;
    color: #a0a3bd;
    span {
      color: #3a78f6;
    }
  }
  .btn-list {
    .flex-bt();
    justify-content: flex-end;
    .el-button {
      height: 32px;
      min-width: auto;
    }
  }
}
</style>
