var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ceshi" },
    [
      _vm.disabled
        ? _c(
            "div",
            { staticClass: "info-title" },
            [
              _c("Icon", {
                staticClass: "cycle-icon cycle-icon-te",
                class: { disabled: _vm.disabled },
                style:
                  _vm.row.cycleDate !== null
                    ? _vm.row.cycleDate.type === 1
                      ? ""
                      : "color: #FF9900 !important"
                    : "",
                attrs: { "icon-class": "icon-zhongfu1" },
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isShow,
                      expression: "isShow",
                    },
                  ],
                  staticStyle: { color: "#a0a3bd", margin: "4px 0 0 8px" },
                },
                [_vm._v("设置重复")]
              ),
            ],
            1
          )
        : _c(
            "el-dropdown",
            {
              attrs: { trigger: "click" },
              on: {
                "visible-change": _vm.dropdownVisibleChange,
                command: _vm.typeChange,
              },
            },
            [
              _c(
                "div",
                { staticClass: "info-title" },
                [
                  _c("Icon", {
                    staticClass: "cycle-icon cycle-icon-te",
                    class: { active: _vm.isActive },
                    style: _vm.row.cycleDate
                      ? _vm.row.cycleDate.type === 1
                        ? ""
                        : "color: #FF9900"
                      : "",
                    attrs: { "icon-class": "icon-zhongfu1" },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isShow,
                          expression: "isShow",
                        },
                      ],
                      staticStyle: { color: "#a0a3bd", margin: "4px 0 0 8px" },
                    },
                    [_vm._v("设置重复")]
                  ),
                ],
                1
              ),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "el-dropdown-menu-class-clock",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                _vm._l(_vm.cycleTypeDict, function (val, key) {
                  return _c(
                    "el-dropdown-item",
                    {
                      key: key,
                      class: _vm.row.cycleDate
                        ? { active: _vm.row.cycleDate.type === parseInt(key) }
                        : { active: _vm.row.type === parseInt(key) },
                      attrs: { command: key },
                    },
                    [_vm._v(_vm._s(val))]
                  )
                }),
                1
              ),
            ],
            1
          ),
      _vm.customCycleVisible
        ? _c("task-custom-cycle-detail", {
            attrs: {
              visible: _vm.customCycleVisible,
              "task-id": _vm.taskId,
              row: _vm.row,
            },
            on: {
              "update:visible": function ($event) {
                _vm.customCycleVisible = $event
              },
              save: _vm.customCycleSave,
              getCustomType: _vm.getCustomType,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }