// 维度类型
export const DIMENSIONTYPELIST = {
  0: {
    id: 0,
    name: 'KPI'
  },
  1: {
    id: 1,
    name: '价值评价'
  },
  2: {
    id: 2,
    name: 'OKR'
  }
}
// 维度录入权限
export const DIMENSIONIMPORTTYPELIST = {
  0: {
    id: 0,
    name: '不允许员工从指标库导入'
  },
  1: {
    id: 1,
    name: '允许员工从指标库导入'
  }
}
// 维度指标打分方式
export const DIMENSIONSCORETYPELIST = {
  0: {
    id: 0,
    name: '分值范围'
  },
  1: {
    id: 1,
    name: '选项方式'
  }
}
// 导入OKR
export const DIMENSIONOKRIMPORTTYPELIST = {
  1: {
    id: 1,
    name: '员工手动导入'
  },
  0: {
    id: 0,
    name: '自动导入周期内的OKR'
  }
}
// 多个OKR权重
export const DIMENSIONOKRWEIGHTTYPELIST = {
  0: {
    id: 0,
    name: '平均调整权重'
  },
  1: {
    id: 1,
    name: '被考核人手动调整'
  }
}
// okr打分方式
export const DIMENSIONOKRSCOREWAYTYPELIST = {
  0: {
    id: 0,
    name: '对OKR整体打分'
  },
  1: {
    id: 1,
    name: '对KR打分'
  }
}
// 绩效计划状态列表
export const KPIPLANSTATUSLIST = {
  0: {
    id: 0,
    name: '未启动',
    bgColor: '#A0A3BD',
    count: 0
  },
  1: {
    id: 1,
    name: '考核中',
    bgColor: '#3296FA',
    count: 0
  },
  2: {
    id: 2,
    name: '已终止',
    bgColor: '#caccdc',
    count: 0
  },
  3: {
    id: 3,
    name: '已归档',
    bgColor: '#924FDC',
    count: 0
  },
  4: {
    id: 4,
    name: '考核完成',
    bgColor: '#00BA88',
    count: 0
  }
}
// 绩效流程列表
export const PROCESSDATALIST = {
  0: {
    type: 0,
    fieldName: 'indexbaseDraft',
    name: '指标制定',
    color: '#3296FA'
  },
  1: {
    type: 1,
    fieldName: 'indexbaseConfirm',
    name: '指标确认',
    color: '#3296FA'
  },
  2: {
    type: 2,
    fieldName: 'completeImport',
    name: '完成值录入',
    color: '#1CC8EE'
  },
  3: {
    type: 3,
    fieldName: 'commentSelf',
    name: '员工自评',
    color: '#E85BA4'
  },
  4: {
    type: 4,
    fieldName: 'commentMember',
    name: '成员评定',
    color: '#00CA8F'
  },
  5: {
    type: 5,
    fieldName: 'resultAdjust',
    name: '结果校准',
    color: '#FFA502'
  },
  6: {
    type: 6,
    fieldName: 'kpiCommunicate',
    name: '绩效沟通',
    color: '#924FDC'
  },
  7: {
    type: 7,
    fieldName: 'memberResultConfirm',
    name: '员工结果确认',
    color: '#FF572B'
  },
  8: {
    type: 8,
    fieldName: 'finalResultConfirm',
    name: '最终结果确认',
    color: '#FF572B'
  }
}
// 等级颜色
export const GRADELISTCOLOR = {
  0: '#20CC9E',
  1: '#3A70FF',
  2: '#FF9900',
  3: '#FFDA19',
  4: '#ED2E7E',
  5: '#FF572B',
  6: '#EE98AF'
}
// 流程确认人为空
export const PROCESSCONFIRMUSERNULLLIST = {
  0: {
    id: 0,
    name: '不跳过，暂停流程'
  },
  1: {
    id: 1,
    name: '自动跳过'
  }
}
// 完成值录入开启方式
export const PROCESSOPENTYPELIST = {
  0: {
    id: 0,
    name: '自动，上一个流程完成后'
  },
  1: {
    id: 1,
    name: '手动，计划管理员手动开启'
  }
}
// 流程沟通结果
export const PROCESSCOMMUNICATETYPELIST = {
  0: {
    id: 0,
    name: '沟通记录'
  },
  1: {
    id: 1,
    name: '存在问题'
  },
  2: {
    id: 2,
    name: '改善计划'
  },
  3: {
    id: 3,
    name: '绩效申诉'
  }
}
// 流程沟通结果
export const PROCESSCONFIRMTYPELIST = {
  0: {
    id: 0,
    name: '仅需查阅'
  },
  1: {
    id: 1,
    name: '需手动确认'
  },
  2: {
    id: 2,
    name: '需手写签名'
  }
}
// 考评人内容
export const PROCESSMEMBERRESULTCONFIRMCONTENTTYPELIST = {
  0: {
    id: 0,
    name: '指标评分'
  },
  1: {
    id: 1,
    name: '指标评语'
  },
  2: {
    id: 2,
    name: '总体评分'
  },
  3: {
    id: 3,
    name: '总体评语'
  }
}
// 绩效结果
export const PROCESSMEMBERRESULTCONFIRMRESULTTYPELIST = {
  0: {
    id: 0,
    name: '最终分数'
  },
  1: {
    id: 1,
    name: '最终等级'
  }
}
// 绩效结果
export const KPIFIELDTYPELIST = {
  number: {
    id: 0,
    name: '数值'
  },
  text: {
    id: 2,
    name: '文本'
  },
  weight: {
    id: 3,
    name: '权重'
  },
  singleChoice: {
    id: 3,
    name: '单选'
  }
}
// 保留几位
export const FLOATTYPELIST = {
  0: {
    id: 0,
    name: 0
  },
  1: {
    id: 1,
    name: 1
  },
  2: {
    id: 2,
    name: 2
  },
  3: {
    id: 3,
    name: 3
  },
  4: {
    id: 4,
    name: 4
  },
  5: {
    id: 5,
    name: 5
  },
  6: {
    id: 6,
    name: 6
  }
}
// 考评关系选项1
export const KPIRELATIONUSERTYPELIST = {
  0: {
    id: 0,
    name: '被考核人本人'
  },
  1: {
    id: 1,
    name: '被考核人部门负责人'
  },
  2: {
    id: 2,
    name: '被考核人同部门'
  },
  3: {
    id: 3,
    name: '被考核人上级'
  },
  4: {
    id: 4,
    name: '被考核人下级'
  },
  5: {
    id: 5,
    name: '指定考评人'
  },
  6: {
    id: 6,
    name: '被考核人跨部门'
  }
}
// 考评关系选项2
export const KPIRELATIONRESPONSELIST = {
  0: {
    id: 0,
    name: '所在部门负责人'
  },
  1: {
    id: 1,
    name: '上级部门负责人'
  },
  2: {
    id: 2,
    name: '上上级部门负责人'
  }
}
// 考评关系选项3
export const KPIRELATIONSELECTUSERTYPELIST = {
  3: {
    id: 3,
    name: '被考核人自选'
  },
  4: {
    id: 4,
    name: '部门负责人选'
  },
  5: {
    id: 5,
    name: '上级选'
  },
  6: {
    id: 6,
    name: '系统随机选'
  },
  7: {
    id: 7,
    name: '指定人选'
  }
}
// 流程状态
export const PROCESSSTATUSLIST = {
  0: {
    id: 0,
    name: '未进行'
  },
  1: {
    id: 1,
    name: '进行中'
  },
  2: {
    id: 2,
    name: '已完成'
  },
  3: {
    id: 3,
    name: '被驳回'
  },
  4: {
    id: 4,
    name: '异常'
  },
  5: {
    id: 5,
    name: '待添加考评人'
  }
}
// 考评内容
export const EVALUATIONCONTENT = {
  0: '指标逐条评分',
  1: '考核评分总分'
}
// 考评可见范围
export const EVALUATIONRANGE = {
  0: '仅自己可见',
  1: '仅自己及之前考评人可见',
  2: '仅自己及后续流程考评人可见',
  3: '全部可见'
}
// 等级符号列表
export const PROCESSSSYMBOLLIST = {
  1: {
    id: 1,
    name: '<'
  },
  2: {
    id: 2,
    name: '≤'
  }
}
// 绩效计划状态列表
export const KPISTATUSLIST = {
  0: {
    id: 0,
    name: '进行中',
    bgColor: '#3296FA',
    count: 0
  },
  1: {
    id: 1,
    name: '考核完成',
    bgColor: '#00BA88',
    count: 0
  },
  2: {
    id: 2,
    name: '已终止',
    bgColor: '#caccdc',
    count: 0
  }
}
// 考评关系评分方式列表
export const RELATIONSCOREWAYLIST = {
  0: {
    id: 0,
    name: '按指标逐条评分'
  },
  1: {
    id: 1,
    name: '按考核表整体评'
  }
}
// 考评可见范围
export const RELATIONSHOWRANGELIST = {
  0: {
    id: 0,
    name: '仅自己可见'
  },
  // 1: {
  //   id: 1,
  //   name: '仅自己及之前考评组可见'
  // },
  2: {
    id: 2,
    name: '仅自己及后续考评组可见'
  },
  3: {
    id: 3,
    name: '全部可见'
  }
}
// 考评可见范围
export const RELATIONLIMITSYMBOLLIST = {
  0: {
    id: 0,
    name: '最多'
  },
  1: {
    id: 1,
    name: '等于'
  }
}
// 考核周类型 考核周期类别，默认月度。 0:年度 1半年 2 季度 3双月(1月开始) 4双月(2月开始) 5月度
export const CYCLECLASSIFYLIST = {
  5: {
    id: 5,
    name: '月度'
  },
  3: {
    id: 3,
    name: '双月(1月始)'
  },
  4: {
    id: 4,
    name: '双月(2月始)'
  },
  2: {
    id: 2,
    name: '季度'
  },
  1: {
    id: 1,
    name: '半年'
  },
  0: {
    id: 0,
    name: '年度'
  }
}
export const AJAXSTATUSLIST = {
  process0: [88034],
  process1: [
    88001, 88002, 88003, 88004, 88005, 88007, 88010, 88011, 88012, 88025, 88026,
    88027, 88028, 88033, 88037, 88035, 88036, 88038, 88039, 88040, 88041, 88042,
    88043, 88044
  ],
  process2: [
    [88013, 88045], // 指标指定
    [88014, 88042], // 指标确认
    [88008, 88009, 88015], // 完成值录入
    [88006, 88016], // 员工自评
    [88006, 88017, 88022, 88028, 88030, 88046, 88050], // 成员评定
    [88018], // 结果校准
    [88019], // 绩效沟通
    [88020, 88032, 88043, 88049], // 员工结果确认
    [88021] // 最终结果确认
  ],
  process3: [88007, 88029, 88031]
}
// 行业指标库标签
export const INDUSTRYINDEXLIST = {
  1: {
    id: 1,
    name: 'IT'
  },
  2: {
    id: 2,
    name: '研发'
  },
  3: {
    id: 3,
    name: '产品'
  },
  4: {
    id: 4,
    name: '生产'
  },
  5: {
    id: 5,
    name: '采购'
  },
  6: {
    id: 6,
    name: '市场'
  },
  7: {
    id: 7,
    name: '销售'
  },
  8: {
    id: 8,
    name: '客服'
  },
  9: {
    id: 9,
    name: '人力资源'
  },
  10: {
    id: 10,
    name: '行政'
  },
  11: {
    id: 11,
    name: '仓储'
  },
  12: {
    id: 12,
    name: '价值观'
  }
}
